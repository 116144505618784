import { Marketplace } from "@front/m19-api-client";

const alpha: Map<Marketplace, number> = new Map<Marketplace, number>([
  [Marketplace.DE, 39286],
  [Marketplace.ES, 229937],
  [Marketplace.FR, 14156],
  [Marketplace.IT, 195290],
  [Marketplace.UK, 149381],
  [Marketplace.US, 1119150],
]);

const beta: Map<Marketplace, number> = new Map<Marketplace, number>([
  [Marketplace.DE, -0.12473],
  [Marketplace.ES, -0.50745],
  [Marketplace.FR, -0.1356],
  [Marketplace.IT, -0.44758],
  [Marketplace.UK, -0.34455],
  [Marketplace.US, -0.3599],
]);

export function searchVolumeEstimationFor(rank: number, marketplace: Marketplace): number {
  const estimation = alpha.get(marketplace) * rank ** beta.get(marketplace);
  return estimation ? Math.ceil(estimation) : null;
}
