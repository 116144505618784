<ng-container *transloco="let t">
  <div class="flex justify-between align-top">
    <div class="w-3/5">
      <app-filter-tags [filters]="filters" [filterValues]="initFilterValue" (filterChange)="applyFilter($event)" />
    </div>
    <div class="flex w-2/5 items-center justify-end gap-2">
      <ICheckbox
        class="mr-3"
        label="{{ t('traffic-stats-filter.include_multi-asin_adgroups') }}"
        [value]="this.filter.enableMultiAsin"
        (valueChange)="enableMultiAsin()"
      />
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
