<div #notif [hidden]="!display" *transloco="let t">
  @switch (notification.type) {
    @case (NotificationType.ADS_API_ACCESS_LOST) {
      <app-notification-card
        [notification]="notification"
        [small]="small"
        helplink="https://help.m19.com/no-advertising-seller-or-vendor-linked-to-this-account"
      >
        <span title>
          {{
            t("notification-card-switch.issue_connecting", [
              notification.accountName,
              notification.accountId,
              notification.marketplace,
            ])
          }}
        </span>
        <span action>{{ t("notification-card-switch.cannot_access_api") }}</span>
        <span titleSmall>
          {{ t("notification-card-switch.issue_api_account", [notification.accountName, notification.marketplace]) }}
        </span>
        <span actionSmall>{{ t("notification-card-switch.check_access") }}</span>
      </app-notification-card>
    }
    @case (NotificationType.SELLING_PARTNER_API_ACCESS_LOST) {
      <app-notification-card-invalid-selling-partner
        [notification]="notification"
        [small]="small"
      ></app-notification-card-invalid-selling-partner>
    }
    @case (NotificationType.OVERLAPPING_STRATEGIES) {
      <app-notification-card-strategy-overlap
        [notification]="notification"
        [small]="small"
      ></app-notification-card-strategy-overlap>
    }
    @case (NotificationType.OVERLAPPING_WITH_NON_M19_STRATEGIES) {
      <app-notification-card-campaign-overlap
        [notification]="notification"
        [small]="small"
      ></app-notification-card-campaign-overlap>
    }
    @case (NotificationType.DELETED_BRAND_ASSET) {
      <app-notification-card-deleted-brand-asset [notification]="notification" [small]="small">
      </app-notification-card-deleted-brand-asset>
    }
    @case (NotificationType.BIDDER_ISSUE) {
      <app-notification-card-bidder-issue [notification]="notification" [small]="small">
      </app-notification-card-bidder-issue>
    }
    @default {
      <app-notification-card [notification]="notification" [small]="small">
        <span title [innerHtml]="notification.type"></span>
        <span action [innerHtml]="notification.message"></span>
        <span titleSmall [innerHtml]="notification.type"></span>
        <span actionSmall [innerHtml]="notification.message"></span>
      </app-notification-card>
    }
  }
</div>
