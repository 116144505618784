// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    DspAdgroup,
    DspCampaign,
    DspCreative,
} from '../models';

export interface ListDspAdgroupsRequest {
    dspAdvertiserId: string;
}

export interface ListDspCampaignsRequest {
    dspAdvertiserId: string;
}

export interface ListDspCreativesRequest {
    dspAdvertiserId: string;
}

/**
 * no description
 */
export class DspApi extends BaseAPI {

    /**
     * List DSP Ad groups (aka line items)
     */
    listDspAdgroups({ dspAdvertiserId }: ListDspAdgroupsRequest): Observable<Array<DspAdgroup>>
    listDspAdgroups({ dspAdvertiserId }: ListDspAdgroupsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<DspAdgroup>>>
    listDspAdgroups({ dspAdvertiserId }: ListDspAdgroupsRequest, opts?: OperationOpts): Observable<Array<DspAdgroup> | AjaxResponse<Array<DspAdgroup>>> {
        throwIfNullOrUndefined(dspAdvertiserId, 'dspAdvertiserId', 'listDspAdgroups');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'dspAdvertiserId': dspAdvertiserId,
        };

        return this.request<Array<DspAdgroup>>({
            url: '/dspAdgroup',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * List DSP Campaigns
     */
    listDspCampaigns({ dspAdvertiserId }: ListDspCampaignsRequest): Observable<Array<DspCampaign>>
    listDspCampaigns({ dspAdvertiserId }: ListDspCampaignsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<DspCampaign>>>
    listDspCampaigns({ dspAdvertiserId }: ListDspCampaignsRequest, opts?: OperationOpts): Observable<Array<DspCampaign> | AjaxResponse<Array<DspCampaign>>> {
        throwIfNullOrUndefined(dspAdvertiserId, 'dspAdvertiserId', 'listDspCampaigns');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'dspAdvertiserId': dspAdvertiserId,
        };

        return this.request<Array<DspCampaign>>({
            url: '/dspCampaign',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * List DSP Creatives
     */
    listDspCreatives({ dspAdvertiserId }: ListDspCreativesRequest): Observable<Array<DspCreative>>
    listDspCreatives({ dspAdvertiserId }: ListDspCreativesRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<DspCreative>>>
    listDspCreatives({ dspAdvertiserId }: ListDspCreativesRequest, opts?: OperationOpts): Observable<Array<DspCreative> | AjaxResponse<Array<DspCreative>>> {
        throwIfNullOrUndefined(dspAdvertiserId, 'dspAdvertiserId', 'listDspCreatives');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'dspAdvertiserId': dspAdvertiserId,
        };

        return this.request<Array<DspCreative>>({
            url: '/dspCreative',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

}
