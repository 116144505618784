// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    Marketplace,
    SharedReportType,
} from './';

/**
 * @export
 * @interface SharedReport
 */
export interface SharedReport {
    /**
     * @type {number}
     * @memberof SharedReport
     */
    organizationId?: number;
    /**
     * @type {SharedReportType}
     * @memberof SharedReport
     */
    reportType?: SharedReportType;
    /**
     * @type {Marketplace}
     * @memberof SharedReport
     */
    marketplace?: Marketplace;
    /**
     * @type {string}
     * @memberof SharedReport
     */
    date?: string;
    /**
     * @type {string}
     * @memberof SharedReport
     */
    fileName?: string;
    /**
     * @type {string}
     * @memberof SharedReport
     */
    downloadUrl?: string;
}
