import {
  AccountApi,
  BillingApi,
  BrandApi,
  CatalogApi,
  CatalogBrandApi,
  CustomerApi,
  DashboardConfigApi,
  DspApi,
  FbaStorageFeesApi,
  GraphApi,
  HistoryApi,
  KeywordTrackingApi,
  NotificationApi,
  OrderApi,
  OrganizationApi,
  PdfApi,
  ProductGroupApi,
  ProductTimelineApi,
  SharedDataApi,
  StatsApi,
  StrategyApi,
  StrategyGroupApi,
  TacosStrategyGroupApi,
  TokenApi,
  UserApi,
} from '@front/m19-api-client';
import { AuthService } from './auth.service';

export function provideM19APIs() {
  const apiProviders = [
    AccountApi,
    BillingApi,
    BrandApi,
    CatalogApi,
    CatalogBrandApi,
    CustomerApi,
    DashboardConfigApi,
    DspApi,
    FbaStorageFeesApi,
    GraphApi,
    HistoryApi,
    KeywordTrackingApi,
    NotificationApi,
    OrderApi,
    OrganizationApi,
    PdfApi,
    ProductGroupApi,
    ProductTimelineApi,
    SharedDataApi,
    StatsApi,
    StrategyApi,
    StrategyGroupApi,
    TacosStrategyGroupApi,
    TokenApi,
    UserApi,
  ];

  return apiProviders.map((ApiClass) => ({
    provide: ApiClass,
    useFactory: (authService: AuthService) => new ApiClass(authService.getConfiguration()),
    deps: [AuthService],
  }));
}
