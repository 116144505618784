export const locales: Record<string, string> = {
  'de-DE': 'German (DE)',
  'de-CH': 'German (CH)',
  'en-US': 'English (US)',
  'en-GB': 'English (GB)',
  'en-CA': 'English (CA)',
  'en-AU': 'English (AU)',
  'es-ES': 'Spanish (ES)',
  'fr-FR': 'French (FR)',
  'fr-CA': 'French (CA)',
  'fr-CH': 'French (CH)',
  'it-IT': 'Italian (IT)',
  'it-CH': 'Italian (CH)',
  'ja-JP': 'Japanese (JP)',
};
