import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatMenuModule } from "@angular/material/menu";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { DateRangeSelectionComponent } from "./date-range-selection.component";

@NgModule({
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    FontAwesomeModule,
    MatMenuModule,
    TranslocoRootModule,
  ],
  exports: [DateRangeSelectionComponent],
  providers: [],
  declarations: [DateRangeSelectionComponent],
})
export class DateRangeSelectionModule {}
