<button
  class="focus-visible:ring-main-500 dark:focus-visible:ring-main-400 relative inline-flex flex-shrink-0 rounded-full border-2 border-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-white disabled:cursor-not-allowed disabled:opacity-50 dark:focus-visible:ring-offset-gray-900"
  [class]="containerClass()"
  [ngClass]="{
    'h-5 w-9': size() === 'sm',
    'h-6 w-11': size() === 'md',
    'h-7 w-[3.25rem]': size() === 'lg',
  }"
  tabindex="0"
  type="button"
  role="switch"
  [attr.aria-checked]="checkedValue()"
  [disabled]="disabled()"
  (click)="check()"
>
  <span
    class="pointer-events-none relative inline-block transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out dark:bg-gray-900"
    [ngClass]="{
      'size-4': size() === 'sm',
      'size-5': size() === 'md',
      'size-6': size() === 'lg',
    }"
    [class]="innerClass()"
  ></span>
</button>
