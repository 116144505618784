import { Component, computed, DestroyRef, inject, signal, TemplateRef, viewChild, WritableSignal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import {
  AccountMarketplace,
  BillingApi,
  BillingPlan,
  OnboardingCallStatus,
  Organization,
  Plan,
} from "@front/m19-api-client";
import { OrganizationAccountGroups } from "@front/m19-models";
import { AccountMarketplaceService, BillingService, OrganizationAccountGroupService } from "@front/m19-services";
import { IButtonComponent, ModalRef, ModalService } from "@front/m19-ui";
import { catchAjaxError } from "@front/m19-utils";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { OnboardingService, OnBoardingStepRedirects } from "@m19-board/services/onboarding.service";
import { ToastrService } from "ngx-toastr";
import { combineLatest, filter, switchMap, take } from "rxjs";
import { SelectAccountsPageComponent } from "./select-accounts-page.component";

@Component({
  selector: "app-free-test-page",
  standalone: true,
  templateUrl: "./free-test-subscription-page.component.html",
  imports: [IButtonComponent, TranslocoDirective, SelectAccountsPageComponent],
})
export class FreeTestPageComponent {
  private readonly onboardingService = inject(OnboardingService);
  private readonly organizationAccountGroupService = inject(OrganizationAccountGroupService);
  private readonly translocoService = inject(TranslocoService);
  private readonly billingService = inject(BillingService);
  private readonly accountMarketplaceService = inject(AccountMarketplaceService);
  private readonly billingApi = inject(BillingApi);
  private readonly router = inject(Router);
  private readonly modalService = inject(ModalService);
  private readonly toastrService = inject(ToastrService);
  readonly accountSelectionModalContent = viewChild<TemplateRef<any>>("accountSelection");
  readonly accountSelectionModalRef = signal<ModalRef<any> | undefined>(undefined);
  readonly activatingFreeTest = signal(false);
  readonly accountMarketplaces: WritableSignal<AccountMarketplace[]> = signal([]);
  readonly disabledAccountsMarketplaces = computed<Map<string, string | undefined>>(() =>
    this.getDisabledAccountsMarketplaces(this.accountMarketplaces()),
  );
  readonly selectedAccountMarketplaces = signal<AccountMarketplace[]>([]);
  private readonly destroyRef = inject(DestroyRef);
  private freeTestBillingPlan!: BillingPlan;

  organization?: Organization;

  constructor() {
    combineLatest([
      this.organizationAccountGroupService.allOrganizationAccountGroups$.pipe(
        filter((o): o is OrganizationAccountGroups[] => !!o),
      ),
      this.onboardingService.belowFreeTestAdSpendThreshold$,
    ])
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(([organizationAccountGroups, belowFreeTestAdSpendThreshold]) => {
        if (organizationAccountGroups.length !== 1) {
          // free test activation is not possible in this case
          this.router.navigate(["/dashboard"]);
        }
        if (!this.onboardingService.entitledForFreeTestSubscription(organizationAccountGroups[0])) {
          // free test activation is not possible in this case
          this.router.navigate(["/dashboard"]);
        }
        if (
          !belowFreeTestAdSpendThreshold &&
          organizationAccountGroups[0].organization.onboardingCallStatus !== OnboardingCallStatus.DONE
        ) {
          // free test activation is not possible in this case
          this.router.navigate(["/dashboard"]);
        }
        this.organization = organizationAccountGroups![0].organization;
        this.accountMarketplaces.set(organizationAccountGroups![0].accountGroups.flatMap((g) => g.resources));
      });
    this.billingApi
      .listBillingPlans({ plans: [Plan.FREE_TEST] })
      .pipe(catchAjaxError())
      .subscribe({
        next: (plans) => (this.freeTestBillingPlan = plans[0]),
        error: (error) => {
          this.toastrService.error(error);
          this.router.navigate(["/dashboard"]);
        },
      });
  }

  skipOnboarding() {
    this.onboardingService.skipOnboarding();
    this.onboardingService.currentStep$.pipe(take(1)).subscribe((step) => {
      this.router.navigate([OnBoardingStepRedirects[step]]);
    });
  }

  private getDisabledAccountsMarketplaces(accountMarketplaces: AccountMarketplace[]) {
    const disabledAccountsMarketplaces = new Map<string, string | undefined>();
    for (const accountMarketplace of accountMarketplaces) {
      const key = `${accountMarketplace.accountId}.${accountMarketplace.marketplace}`;
      if (accountMarketplace.state === "BIDDER_ON") {
        disabledAccountsMarketplaces.set(
          key,
          this.translocoService.translate("billing-account-selection.this_account_is_part_of_your_current_plan"),
        );
      }
      if (!accountMarketplace.hasAccessToAdvertising) {
        disabledAccountsMarketplaces.set(key, this.translocoService.translate("billing-account-selection.access_lost"));
      }
      if (accountMarketplace.bidderOrganizationId) {
        disabledAccountsMarketplaces.set(
          key,
          this.translocoService.translate("billing-account-selection.accountanother_org_error"),
        );
      }
      if (!accountMarketplace.activated) {
        disabledAccountsMarketplaces.set(
          key,
          this.translocoService.translate("billing-account-selection.unable_to_automate"),
        );
      }
    }
    return disabledAccountsMarketplaces;
  }

  openFreeTestModal() {
    if (!this.accountMarketplaces()) return;
    const modalRef = this.modalService.openModal(this.accountSelectionModalContent()!, {
      modalTitle: this.translocoService.translate("onboarding.select_accounts_for_ads_automation"),
    });
    this.accountSelectionModalRef.set(modalRef);
  }

  activateFreeTest() {
    this.activatingFreeTest.set(true);
    this.billingService
      .createSubscription({ ...this.organization, billingPlan: this.freeTestBillingPlan })
      .pipe(
        catchAjaxError(),
        switchMap(() =>
          this.accountMarketplaceService.activateBidderV2(this.selectedAccountMarketplaces()).pipe(catchAjaxError()),
        ),
      )
      .subscribe({
        next: () => {
          this.activatingFreeTest.set(false);
          this.toastrService.success("Free test activated successfully");
          this.accountSelectionModalRef()?.close();
          this.router.navigate(["/dashboard"]);
        },
        error: (error) => {
          this.activatingFreeTest.set(false);
          this.toastrService.error(error, "Fail to activate free test");
        },
      });
  }
}
