import { AccountType, AccountMarketplace, Marketplace } from '../api-client';
import { AccountMarketplaceEx } from './AccountMarketplaceEx';

export class AccountGroup {
  id: number;
  organizationId: number;
  name: string;
  type: AccountType;
  resources: AccountMarketplaceEx[];

  constructor(id: number, organizationId: number, name: string, type: AccountType) {
    this.id = id;
    this.organizationId = organizationId;
    this.name = name;
    this.type = type;
    this.resources = [];
  }

  add(e: AccountMarketplaceEx) {
    this.resources.push(e);
  }

  getAccountMarketplaces(): AccountMarketplace[] {
    return this.resources;
  }

  containsMktp(mktp: Marketplace) {
    return this.resources.some((x) => x.marketplace == mktp);
  }
}
