@if (spForm) {
  <div *transloco="let t">
    <form class="main-container mx-auto flex" [formGroup]="spForm" (ngSubmit)="submitForm()">
      <div class="form-inner">
        <div class="card-common mb-3 p-3">
          <h3 class="mb-4">{{ t("sp-strategy-creation.sponsored_products_strategy") }}</h3>
          <div class="content">
            <div class="input-section">
              <label for="strategyName">{{ t("sp-strategy-creation.strategy_name") }}</label>
              <input
                data-testid="strategy-name"
                type="text"
                maxlength="80"
                class="form-control simple-input"
                placeholder="{{ t('sp-strategy-creation.please_enter_a_name_for_your_strategy') }}"
                id="strategyName"
                [formControl]="spForm.controls.strategyName"
                [class.border-danger]="formSubmitted && spForm.controls.strategyName.errors"
              />
              <div class="hint">{{ spForm.controls.strategyName.value?.length }}/80</div>
            </div>
          </div>
        </div>
        <div class="card-common mb-3 p-3">
          <h3 class="mb-4">{{ t("sd-strategy-creation.algorithm") }}</h3>
          <div class="content">
            <div class="mb-3 flex">
              <algo-mode-selection (algoModeConfig)="setAlgoModeConfig($event)" class="w-full" />
            </div>
          </div>
        </div>
        <div class="card-common mb-3 p-3">
          <h3 class="mb-4">{{ t("common.asins") }}</h3>
          <div>
            {{ t("sp-strategy-creation.selected_asins", { count: spForm.controls.asins.value?.length }) }}
          </div>
          <div>
            @if (unavailableAsins() && unavailableAsins()!.size) {
              <IAlert class="my-4" typ="info" [title]="t('common.asin_used_in_tacos_strategies')" [animated]="false">
                <IButton
                  [label]="t('common.display_unavailable_asin')"
                  color="white"
                  size="xs"
                  (onClick)="openAsinUsageModal()"
                />
              </IAlert>
            }
            <div class="h-[45rem]">
              <app-asins-selection
                class="h-full"
                [asins]="spForm.controls.asins.value ?? []"
                [asinEligibility]="asinEligibility"
                [accountId]="accountId"
                [marketplace]="marketplace"
                [disabledAsins]="unavailableAsins()!"
                [csvExport]="false"
                (onAdd)="addStrategyAsins($event)"
                (onDelete)="deleteStrategyAsins($event)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="form-panel card-common ml-3">
        <div>
          <h4>{{ t("sp-strategy-creation.new_sponsored_product") }}</h4>
          <p>{{ t("sp-strategy-creation.account_accountgroupname", [accountGroupName]) }}</p>
          <p>{{ t("sd-strategy-creation.marketplace", [marketplace]) }}</p>
        </div>
        @if (formSubmitted && spForm.invalid) {
          <div class="alert alert-danger" data-testid="error-ctn">
            <span class="py-2">{{ t("sd-strategy-creation.some_of_the_fields_are_invalid") }} </span>
            @for (c of invalidFields; track c) {
              <span>{{ c + (invalidFields.indexOf(c) !== invalidFields.length - 1 ? ", " : "") }}</span>
            }
            @if (spForm.controls.asins.invalid) {
              <div>{{ t("sd-strategy-creation.please_select_at_least_one_asin") }}</div>
            }
          </div>
        }
        <div>
          <IButton label="Create Strategy" type="submit" [block]="true" class="mb-2" testid="btn-create-strategy" />
          <IButton
            [icon]="ICON_ARROW"
            label="{{ t('sp-strategy-creation.back_to_sp_strategies') }}"
            to="/advertising/sponsored-product"
            variant="ghost"
            color="gray"
            [block]="true"
          />
        </div>
      </div>
    </form>
  </div>
}
