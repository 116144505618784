import { Marketplace, Product } from '../api-client';
import { Marketplaces } from './MarketplaceEx';
import moment from 'moment-timezone';

export interface ProductEx extends Product {
  parentAsin?: string;
  imageId?: string;
  imageUrl?: string;
  price?: number;
  basePrice?: number;
  competitivePriceThreshold?: number;
  shippingPrice?: number;
  reviewScore?: number;
  date?: string;
  customField1?: string;
  customField2?: string;
}

export enum FulfillmentChannel {
  Amazon = 'Amazon',
  Merchant = 'Merchant',
  Both = 'Both',
}

// this is an empty object used to iterate over
const DefaultProductEx: Partial<ProductEx> = {
  asin: undefined,
  marketplace: undefined,
  invalid: undefined,
  isPrime: undefined,
  hasCoupon: undefined,
  asinType: undefined,
  title: undefined,
  imageIds: undefined,
  childAsins: undefined,
  parentAsins: undefined,
  categoryId: undefined,
  categoryName: undefined,
  categoryRank: undefined,
  displayGroup: undefined,
  displayGroupRank: undefined,
  priceInCent: undefined,
  basePriceInCent: undefined,
  shippingInCent: undefined,
  competitivePriceThresholdInCent: undefined,
  reviews: undefined,
  reviewScoreTenth: undefined,
  brand: undefined,
  sellerId: undefined,
  nbSeller: undefined,
  isFba: undefined,
  timestamp: undefined,
  parentAsin: undefined,
  imageId: undefined,
  imageUrl: undefined,
  price: undefined,
  basePrice: undefined,
  competitivePriceThreshold: undefined,
  shippingPrice: undefined,
  reviewScore: undefined,
  date: undefined,
  nbSku: undefined,
  spEligibility: undefined,
  spReason: undefined,
  sbEligibility: undefined,
  sbReason: undefined,
  fulfillableQuantity: undefined,
  unsellableQuantity: undefined,
  reservedQuantity: undefined,
  inboundQuantity: undefined,
  fbmStock: undefined,
  customField1: undefined,
  customField2: undefined,
};

type ProductExtended = Product & {
  imageId: string;
  imageUrl: string;
  price: number;
  basePrice: number;
  shippingPrice: number;
  competitivePriceThreshold: number;
  reviewScore: number;
  date: string;
  marketplace: Marketplace;
  parentAsin: string;
};

export function buildProductEx(product: ProductExtended): ProductEx {
  if (product.imageIds) product['imageId'] ??= product.imageIds[0];

  product['imageUrl'] ??= getImageUrl(product['imageId'], product.marketplace);

  if (product.priceInCent) product['price'] ??= product.priceInCent / 100;

  if (product.basePriceInCent) product['basePrice'] ??= product.basePriceInCent / 100;

  if (product.shippingInCent) product['shippingPrice'] ??= product.shippingInCent / 100;
  if (product.competitivePriceThresholdInCent)
    product['competitivePriceThreshold'] ??= product.competitivePriceThresholdInCent / 100;

  if (product.reviewScoreTenth) product['reviewScore'] ??= product.reviewScoreTenth / 10;
  if (product.timestamp)
    product['date'] ??= moment(product.timestamp * 1000)
      .tz(Marketplaces[product.marketplace].timeZone)
      .startOf('day')
      .format('YYYY-MM-DD');
  if (product.parentAsins && product.parentAsins.length > 0) {
    product['parentAsin'] = product.parentAsins[0];
  }
  return product;
}

export function buildProductTimeline(products: Product[]): ProductEx[] {
  if (!products) return [];

  let base = products[0];
  // we need to copy the base product as buildProductEx mutate the object
  const res: ProductEx[] = [buildProductEx({ ...(base as any) })];

  for (let i = 1; i < products.length; i++) {
    base = addProduct(products[i], base);
    res.push(buildProductEx({ ...(base as any) }));
  }

  return res;
}

function addProduct(delta: Product, base: Product): Product {
  return {
    ...base,
    ...delta,

    categoryRank: applyDelta(delta, base, 'categoryRank'),

    displayGroupRank: applyDelta(delta, base, 'displayGroupRank'),

    timestamp: applyDelta(delta, base, 'timestamp'),

    priceInCent: applyDelta(delta, base, 'priceInCent'),

    basePriceInCent: applyDelta(delta, base, 'basePriceInCent'),

    shippingInCent: applyDelta(delta, base, 'shippingInCent'),

    competitivePriceThresholdInCent: applyDelta(delta, base, 'competitivePriceThresholdInCent'),

    reviews: applyDelta(delta, base, 'reviews'),

    reviewScoreTenth: applyDelta(delta, base, 'reviewScoreTenth'),

    nbSeller: applyDelta(delta, base, 'nbSeller'),
  };
}

// type of Product keys mapping a number field
type ProductNumberKeys = {
  [K in keyof Product]: Product[K] extends number ? K : never;
}[keyof Product];

function applyDelta<K>(delta: Product, base: Product, field: string) {
  return (
    ((base[field as unknown as keyof Product] ?? 0) as number) +
    ((delta[field as unknown as keyof Product] ?? 0) as number)
  );
}

function getNoImageUrl(marketplace: Marketplace): string {
  switch (marketplace) {
    case Marketplace.FR:
      return 'https://images-na.ssl-images-amazon.com/images/G/08/x-site/icons/no-img-sm._CB1275507936_.gif';
    case Marketplace.ES:
      return 'https://images-na.ssl-images-amazon.com/images/G/30/x-site/icons/no-img-sm._CB1303168197_.gif';
    case Marketplace.DE:
      return 'https://images-na.ssl-images-amazon.com/images/G/03/x-site/icons/no-img-sm._CB1275617821_.gif';
    case Marketplace.IT:
      return 'https://images-na.ssl-images-amazon.com/images/G/29/x-site/icons/no-img-sm._CB1547651849_._SL75_.gif';
    default:
      return 'https://images-na.ssl-images-amazon.com/images/I/01RmK+J4pJL._SL75_.gif';
  }
}

function getImageUrl(imageId: string, marketplace: Marketplace): string {
  return imageId ? `https://m.media-amazon.com/images/I/${imageId}._SL75_.jpg` : getNoImageUrl(marketplace);
}

export function combineProducts(p1: ProductEx, p2: ProductEx): ProductEx {
  const res: Partial<ProductExtended> = {};
  for (const key in DefaultProductEx) {
    if (p1[key as keyof ProductExtended] || p2[key as keyof ProductExtended]) {
      (res[key as keyof ProductExtended] as any) =
        (p2[key as keyof ProductExtended] as any) ?? (p1[key as keyof ProductExtended] as any);
    }
  }
  return res;
}
