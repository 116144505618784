import { Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AsinSeed, AsinSeedTypeEnum, Marketplace, MatchType, Targeting } from "@front/m19-api-client";
import { AccountSelectionService, AsinService } from "@front/m19-services";
import { Utils } from "@front/m19-utils";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { TranslocoService } from "@jsverse/transloco";

@UntilDestroy()
@Component({
  selector: "app-seeds-edition-modal",
  templateUrl: "./seeds-edition-modal.component.html",
})
export class SeedsEditionModalComponent implements OnInit {
  accountId: string;
  marketplace: Marketplace;
  asin: string;

  @Input()
  set keywordTargetingItems(items: AsinSeed[]) {
    this.splitKw(items);
  }

  @Input()
  set productTargetingItems(items: AsinSeed[]) {
    this.splitProducts(items);
  }

  _whitelistedKeywords: AsinSeed[] = [];
  _blacklistedKeywords: AsinSeed[] = [];
  _whitelistedProducts: AsinSeed[] = [];
  _blacklistedProducts: AsinSeed[] = [];

  errorsAsin = [];
  errorsKeyword = [];
  whitelist = true;

  isReadOnly = false;

  constructor(
    public bsModalRef: BsModalRef,
    private asinService: AsinService,
    private toasterService: ToastrService,
    private accountSelectionService: AccountSelectionService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
  }

  splitKw(items: AsinSeed[]) {
    this._whitelistedKeywords = items.filter((x) => x.type == AsinSeedTypeEnum.WHITELIST);
    this._blacklistedKeywords = items.filter((x) => x.type == AsinSeedTypeEnum.BLACKLIST);
  }

  splitProducts(items: AsinSeed[]) {
    this._whitelistedProducts = items.filter((x) => x.type == AsinSeedTypeEnum.WHITELIST);
    this._blacklistedProducts = items.filter((x) => x.type == AsinSeedTypeEnum.BLACKLIST);
  }

  addProducts(items: Targeting[]) {
    let currentItems = this.whitelist ? this._whitelistedProducts : this._blacklistedProducts;

    let currentItemsSize = currentItems.length;
    let updates = 0;

    this.errorsAsin = [];
    for (const item of items) {
      const asin = item.targetingValue;

      if (currentItemsSize == AsinService.MaxTargetedProductsByAsin) {
        this.errorsAsin.push(
          `You have reached the maximum number of ${AsinService.MaxTargetedProductsByAsin} products.
          ASIN ${asin} (and following) are not added.`,
        );
        break;
      }
      const normalizedProduct = Utils.normalizeASIN(asin);
      if (normalizedProduct === "") continue;
      if (!Utils.isValidAsin(asin)) {
        this.errorsAsin.push(`${asin}: seeds-edition-modal.invalid_asin`);
        continue;
      }
      if (currentItems.map((x) => x.targetingValue).includes(normalizedProduct)) {
        this.errorsAsin.push(`${asin}: seeds-edition-modal.duplicated_asin`);
        continue;
      }
      if (this.whitelist) {
        this._whitelistedProducts = [
          ...this._whitelistedProducts,
          {
            targetingValue: normalizedProduct,
            type: AsinSeedTypeEnum.WHITELIST,
            matchType: MatchType.asinSameAs,
          },
        ];
      } else {
        this._blacklistedProducts = [
          ...this._blacklistedProducts,
          {
            targetingValue: normalizedProduct,
            type: AsinSeedTypeEnum.BLACKLIST,
            matchType: MatchType.asinSameAs,
          },
        ];
      }
      updates++;
      currentItems = this.whitelist ? this._whitelistedProducts : this._blacklistedProducts;
      currentItemsSize++;
    }
    if (updates == 0) {
      return;
    }

    this.save();
  }

  deleteProducts(items: Targeting[]) {
    if (this.whitelist) {
      this._whitelistedProducts = this._whitelistedProducts.filter(
        (p) => !items.map((x) => x.targetingValue).includes(p.targetingValue),
      );
    } else {
      this._blacklistedProducts = this._blacklistedProducts.filter(
        (p) => !items.map((x) => x.targetingValue).includes(p.targetingValue),
      );
    }

    this.save();
  }

  addKeywords(keywords: Targeting[]) {
    let currentItems = this.whitelist ? this._whitelistedKeywords : this._blacklistedKeywords;

    let currentItemsSize = currentItems.length;
    let kwUpdates = 0;

    this.errorsKeyword = [];
    for (const keyword of keywords) {
      if (currentItemsSize == AsinService.MaxKeywordsByAsin) {
        this.errorsKeyword.push(`You have reached the maximum number of ${AsinService.MaxKeywordsByAsin} keywords.
        Keyword "${keyword.targetingValue}" (and following) are not added.`);
        break;
      }

      const normalizedKeyword = Utils.normalizeKeyword(keyword.targetingValue);
      if (normalizedKeyword === "") continue;
      const reason = this.translocoService.translate(Utils.isValidKeyword(normalizedKeyword, keyword.matchType));
      if (reason != "") {
        this.errorsKeyword.push(`${keyword.targetingValue}: ${reason}`);
        continue;
      }
      if (currentItems.map((k) => k.targetingValue).includes(normalizedKeyword)) {
        this.errorsKeyword.push(`${keyword.targetingValue}: seeds-edition-modal.duplicated_keyword`);
        continue;
      }
      if (this.whitelist) {
        this._whitelistedKeywords = [
          ...this._whitelistedKeywords,
          {
            targetingValue: normalizedKeyword,
            type: AsinSeedTypeEnum.WHITELIST,
            matchType: keyword.matchType,
          },
        ];
      } else {
        this._blacklistedKeywords = [
          ...this._blacklistedKeywords,
          {
            targetingValue: normalizedKeyword,
            type: AsinSeedTypeEnum.BLACKLIST,
            matchType: keyword.matchType,
          },
        ];
      }
      currentItems = this.whitelist ? this._whitelistedKeywords : this._blacklistedKeywords;
      currentItemsSize++;
      kwUpdates++;
    }
    if (kwUpdates == 0) {
      return;
    }

    this.save();
  }

  deleteKeywords(items: Targeting[]) {
    if (this.whitelist) {
      this._whitelistedKeywords = this._whitelistedKeywords.filter(
        (p) => !items.map((x) => x.targetingValue).includes(p.targetingValue),
      );
    } else {
      this._blacklistedKeywords = this._blacklistedKeywords.filter(
        (p) => !items.map((x) => x.targetingValue).includes(p.targetingValue),
      );
    }

    this.save();
  }

  save() {
    // call backend to update targeting items
    this.asinService
      .setAsinSeeds(
        this.accountId,
        this.marketplace,
        this.asin,
        this._whitelistedKeywords.concat(this._blacklistedKeywords),
        this._whitelistedProducts.concat(this._blacklistedProducts),
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.toasterService.success(
            `ASIN ${this.asin} seed keywords and products successfully updated`,
            "Seed keywords and products update",
          );
        },
        (errors: any) => {
          this.toasterService.error(JSON.stringify(errors), "Seed keywords and products update");
        },
      );
  }

  close() {
    this.bsModalRef.hide();
  }
}
