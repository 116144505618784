import { Component, EventEmitter, Input, Output } from "@angular/core";
import { StrategyEx } from "@front/m19-models";
import { BulkError, BulkImportService } from "@m19-board/strategies/strategy-bulk-upload-modal/bulk-import.service";
import { ICON_UPLOAD } from "@m19-board/utils/iconsLabels";
import { BsModalRef } from "ngx-bootstrap/modal";
import { AlgoMode } from "@front/m19-api-client";
import { IButtonComponent } from "@front/m19-ui";

export type BoostUpdate = { strategyId: number; boost: number | undefined; lineIndex: number; csvRow: string };

@Component({
  selector: "app-strategy-boost-bulk-upload",
  template: ` <div class="mt-2 flex max-h-[950px] flex-col items-start justify-between gap-4 p-6">
    <p class="font-semibold">Import your promo days boost configuration</p>
    <textarea type="text" #data class="form-control coding-font !h-[500px]" [placeholder]="placeholder"></textarea>
    <div class="flex w-full items-center justify-between">
      <div class="mt-1">
        <input
          #fileInput
          hidden
          type="file"
          accept="text/csv"
          (input)="onCsvUpload(fileInput.files)"
          (click)="fileInput.value = ''"
        />
        <IButton
          [icon]="ICON_UPLOAD"
          tooltipValue="Upload a CSV file"
          color="white"
          [square]="true"
          (click)="fileInput.click()"
        />
      </div>
      <div class="danger">{{ fileUploadError }}</div>
      <div class="flex w-full justify-end gap-2">
        <IButton label="Cancel" color="white" (onClick)="close()" />
        <IButton
          label="Upload"
          tooltipValue="Upload promo days settings"
          [disabled]="isUploadButtonDisabled(data.value)"
          (onClick)="uploadPromoDays(data.value); data.value = ''"
        />
      </div>
    </div>
  </div>`,
  standalone: true,
  imports: [IButtonComponent],
})
export class StrategyBoostBulkUploadComponent {
  @Input() strategyIndex!: Map<number, StrategyEx>;

  @Output() boostUpdates: EventEmitter<{ updates: BoostUpdate[]; errors: BulkError[] }> = new EventEmitter();

  readonly ICON_UPLOAD = ICON_UPLOAD;
  readonly placeholder: string;

  fileUploadError = "";

  constructor(
    public bsModalRef: BsModalRef,
    private bulkImportService: BulkImportService,
  ) {
    this.placeholder = this.bulkImportService.getStrategyBoostTextAreaPlaceholderText();
  }

  onCsvUpload(files: FileList | null): void {
    if (files && files.length > 0) {
      const csvFile = files[0];
      if (csvFile.type !== "text/csv" && csvFile.type !== "application/vnd.ms-excel") {
        this.fileUploadError = `${csvFile.name} is not a CSV file`;
        return;
      }
      const reader: FileReader = new FileReader();
      reader.readAsText(csvFile);
      reader.onload = () => {
        const csv: string = reader.result as string;
        this.uploadPromoDays(csv);
      };
    }
  }

  uploadPromoDays(input: string): void {
    const errors: BulkError[] = [];
    const rows = input.split(/[\n]+/);
    let lineIndex = 0;
    const boostUpdates: BoostUpdate[] = [];
    for (const row of rows) {
      const trimmedRow = row.trim();
      if (trimmedRow.length === 0) {
        // discard empty row
        continue;
      }
      lineIndex++;
      const csvRow = this.bulkImportService.parseStrategyBoostCsvRow(trimmedRow.split(/\t/));
      if (!csvRow) {
        errors.push({ lineIndex: lineIndex, csvRow: trimmedRow, errors: ["Line is not properly formatted"] });
        continue;
      }
      if (csvRow.strategyId === undefined) {
        errors.push({ lineIndex: lineIndex, csvRow: trimmedRow, errors: ["Strategy id is not an integer"] });
        continue;
      }
      if (!this.strategyIndex.has(csvRow.strategyId)) {
        errors.push({ lineIndex, csvRow: trimmedRow, errors: [`Strategy with ID ${csvRow.strategyId} not found`] });
        continue;
      }
      if (boostUpdates.find((u) => u.strategyId == csvRow.strategyId)) {
        errors.push({
          lineIndex,
          csvRow: trimmedRow,
          errors: [`Duplicated update on strategy with ID ${csvRow.strategyId}`],
        });
        continue;
      }
      // check if boost modification is valid
      const strategy = this.strategyIndex.get(csvRow.strategyId);
      if (strategy && strategy.algoMode !== AlgoMode.ACOS_TARGET) {
        errors.push({
          lineIndex,
          csvRow: trimmedRow,
          errors: [`Strategy cannot benefit from the boost if it does not run on ACOS Target algorithm`],
        });
        continue;
      }
      if (strategy?.dailyBudget && strategy.dailyBudget > 0) {
        errors.push({
          lineIndex,
          csvRow: trimmedRow,
          errors: [`Strategy cannot benefit from the boost if there is an average daily budget`],
        });
        continue;
      }
      const validBoosts = new Set([-50, -25, 0, 25, 50, 100]);
      if (csvRow.activatePrimeDayBoost && !validBoosts.has(csvRow.primeDayBoost!)) {
        errors.push({
          lineIndex,
          csvRow: trimmedRow,
          errors: [
            `Invalid ACOSOptimizer value: ${csvRow.primeDayBoost} - supported values are ${Array.from(validBoosts).join(
              ", ",
            )}`,
          ],
        });
        continue;
      }
      if (!csvRow.activatePrimeDayBoost && strategy?.primeDayBoost == null) {
        errors.push({
          lineIndex,
          csvRow: trimmedRow,
          errors: [`No update on this strategy`],
        });
        continue;
      }
      if (csvRow.activatePrimeDayBoost && strategy?.primeDayBoost == csvRow.primeDayBoost) {
        errors.push({
          lineIndex,
          csvRow: trimmedRow,
          errors: [`No update on this strategy`],
        });
        continue;
      }
      if (csvRow.activatePrimeDayBoost) {
        boostUpdates.push({
          strategyId: csvRow.strategyId,
          boost: csvRow.primeDayBoost,
          lineIndex,
          csvRow: trimmedRow,
        });
      } else {
        boostUpdates.push({
          strategyId: csvRow.strategyId,
          boost: undefined,
          lineIndex,
          csvRow: trimmedRow,
        });
      }
    }
    this.boostUpdates.emit({ updates: boostUpdates, errors });
  }

  isUploadButtonDisabled(content: string): boolean {
    return !content || content.trim().length === 0;
  }

  close(): void {
    this.bsModalRef.hide();
  }
}
