import { Component, Input } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { UploadResult } from "./upload-catalog-data-modal.component";

@Component({
  selector: "app-upload-catalog-data-report-modal",
  templateUrl: "./upload-catalog-data-report-modal.component.html",
})
export class UploadCatalogDataReportModalComponent<T> {
  @Input()
  public uploadResult: UploadResult<T>;
  @Input()
  public updatedEntity: string;
  @Input()
  public uploadResultFormater: (u: T) => string;

  successDetailsVisible = false;
  errorDetailsVisible = false;
  warningDetailsVisible = false;

  constructor(public bsModalRef: BsModalRef) {}

  toggleSuccessDetails() {
    this.successDetailsVisible = !this.successDetailsVisible;
  }

  toggleErrorDetails() {
    this.errorDetailsVisible = !this.errorDetailsVisible;
  }

  toggleWarningDetails() {
    this.warningDetailsVisible = !this.warningDetailsVisible;
  }
}
