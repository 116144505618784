// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    Marketplace,
    Order,
} from '../models';

export interface GetDailyOrderQuantitiesPerAsinRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
}

export interface GetMonthlyOrdersRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
}

export interface GetOrderRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
}

/**
 * no description
 */
export class OrderApi extends BaseAPI {

    /**
     */
    getDailyOrderQuantitiesPerAsin({ accountId, marketplace, minDate, maxDate }: GetDailyOrderQuantitiesPerAsinRequest): Observable<Array<Order>>
    getDailyOrderQuantitiesPerAsin({ accountId, marketplace, minDate, maxDate }: GetDailyOrderQuantitiesPerAsinRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<Order>>>
    getDailyOrderQuantitiesPerAsin({ accountId, marketplace, minDate, maxDate }: GetDailyOrderQuantitiesPerAsinRequest, opts?: OperationOpts): Observable<Array<Order> | AjaxResponse<Array<Order>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getDailyOrderQuantitiesPerAsin');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getDailyOrderQuantitiesPerAsin');
        throwIfNullOrUndefined(minDate, 'minDate', 'getDailyOrderQuantitiesPerAsin');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getDailyOrderQuantitiesPerAsin');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<Order>>({
            url: '/order/dailyOrderQuantitiesPerAsin',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    getMonthlyOrders({ accountId, marketplace, minDate, maxDate }: GetMonthlyOrdersRequest): Observable<Array<Order>>
    getMonthlyOrders({ accountId, marketplace, minDate, maxDate }: GetMonthlyOrdersRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<Order>>>
    getMonthlyOrders({ accountId, marketplace, minDate, maxDate }: GetMonthlyOrdersRequest, opts?: OperationOpts): Observable<Array<Order> | AjaxResponse<Array<Order>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getMonthlyOrders');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getMonthlyOrders');
        throwIfNullOrUndefined(minDate, 'minDate', 'getMonthlyOrders');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getMonthlyOrders');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<Order>>({
            url: '/order/monthly',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    getOrder({ accountId, marketplace, minDate, maxDate }: GetOrderRequest): Observable<Array<Order>>
    getOrder({ accountId, marketplace, minDate, maxDate }: GetOrderRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<Order>>>
    getOrder({ accountId, marketplace, minDate, maxDate }: GetOrderRequest, opts?: OperationOpts): Observable<Array<Order> | AjaxResponse<Array<Order>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getOrder');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getOrder');
        throwIfNullOrUndefined(minDate, 'minDate', 'getOrder');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getOrder');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<Order>>({
            url: '/order/all',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

}
