// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    Marketplace,
    Strategy,
    Targeting,
} from './';

/**
 * @export
 * @interface StrategyGroup
 */
export interface StrategyGroup {
    /**
     * Amazon Seller or Vendor accountId
     * @type {string}
     * @memberof StrategyGroup
     */
    accountId?: string;
    /**
     * @type {Marketplace}
     * @memberof StrategyGroup
     */
    marketplace?: Marketplace;
    /**
     * @type {number}
     * @memberof StrategyGroup
     */
    strategyGroupId?: number;
    /**
     * @type {string}
     * @memberof StrategyGroup
     */
    strategyGroupName?: string;
    /**
     * @type {Array<Targeting>}
     * @memberof StrategyGroup
     */
    blacklist?: Array<Targeting>;
    /**
     * @type {Array<Strategy>}
     * @memberof StrategyGroup
     */
    strategies?: Array<Strategy>;
}
