// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    Marketplace,
    MediaType,
} from './';

/**
 * @export
 * @interface BrandAsset
 */
export interface BrandAsset {
    /**
     * @type {string}
     * @memberof BrandAsset
     */
    accountId?: string;
    /**
     * @type {Marketplace}
     * @memberof BrandAsset
     */
    marketplace?: Marketplace;
    /**
     * @type {string}
     * @memberof BrandAsset
     */
    brandEntityId?: string;
    /**
     * @type {string}
     * @memberof BrandAsset
     */
    assetId?: string;
    /**
     * @type {string}
     * @memberof BrandAsset
     */
    url?: string;
    /**
     * @type {MediaType}
     * @memberof BrandAsset
     */
    mediaType?: MediaType;
    /**
     * @type {string}
     * @memberof BrandAsset
     */
    name?: string;
    /**
     * @type {Array<string>}
     * @memberof BrandAsset
     */
    asinList?: Array<string>;
    /**
     * @type {number}
     * @memberof BrandAsset
     */
    width?: number;
    /**
     * @type {number}
     * @memberof BrandAsset
     */
    height?: number;
    /**
     * @type {boolean}
     * @memberof BrandAsset
     */
    deleted?: boolean;
}
