import { Pipe, PipeTransform } from "@angular/core";
import { CustomCampaignNameTokens } from "@front/m19-api-client";

@Pipe({
  name: "customCampaignNameHighlightPipe",
})
export class CustomCampaignNameHighlightPipe implements PipeTransform {
  transform(campaignNamePattern: any): any {
    if (!campaignNamePattern) return campaignNamePattern;
    return campaignNamePattern.replace(/(%[^%]+%)/g, (value) =>
      Object.values(CustomCampaignNameTokens).includes(value as CustomCampaignNameTokens)
        ? `<span class="text-main">${value}</span>`
        : value,
    );
  }
}
