import { Component, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { CampaignType, StrategyAsin, StrategyTactic, TacticType } from "@front/m19-api-client";
import { SegmentEx, StrategyEx } from "@front/m19-models";
import {
  AccountSelectionService,
  AsinService,
  MapPipe,
  SdStrategiesService,
  StrategyService,
} from "@front/m19-services";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { ProductSegmentModalComponent } from "@m19-board/segments/product-segment-modal.component";
import { MoveStrategyAsinsModalComponent } from "@m19-board/strategies/strategy-asins/move-strategy-asins-modal.component";
import { ICON_ADD } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { combineLatest, Observable, Subscription } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { SdStrategyCreationComponent } from "../sd-manager/sd-strategy-creation/sd-strategy-creation.component";
import { SdTargetingAddPopupComponent } from "../sd-targeting/sd-targeting-add-popup.component";
import { StrategyOverlayComponent } from "@m19-board/overlay/strategy-overlay.component";
import { StrategyPageComponent } from "@m19-board/strategies/strategy-page/strategy-page.component";
import { LowInventoryWarningComponent } from "@m19-board/inventory/inventory-rules/low-inventory-warning.component";
import { DropdownToggleIconComponent } from "@m19-board/shared/expand-icon/dropdown-toggle-icon.component";
import { IAccordionComponent, IButtonComponent } from "@front/m19-ui";
import { LimitWarningComponent } from "@m19-board/strategies/limit-warning/limit-warning.component";
import { MatTooltip } from "@angular/material/tooltip";
import { StrategyAsinsComponent } from "@m19-board/strategies/strategy-asins/strategy-asins.component";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { SdTargetingComponent } from "@m19-board/sponsored-display/sd-targeting/sd-targeting.component";
import { AsinsSelectionComponent } from "@m19-board/strategies/strategy-asins/asins-selection.component";

@Component({
  selector: "app-sd-page",
  templateUrl: "./sd-page.component.html",
  standalone: true,
  imports: [
    TranslocoDirective,
    StrategyOverlayComponent,
    StrategyPageComponent,
    LowInventoryWarningComponent,
    DropdownToggleIconComponent,
    IAccordionComponent,
    LimitWarningComponent,
    MatTooltip,
    StrategyAsinsComponent,
    FaIconComponent,
    SdTargetingComponent,
    IButtonComponent,
    AsinsSelectionComponent,
    MapPipe,
  ],
})
@UntilDestroy()
export class SdPageComponent {
  strategy$: Observable<StrategyEx>;
  strategy: StrategyEx | undefined;
  asinsVisible = true;
  targetingsVisible = true;
  asinsList: StrategyAsin[] = [];
  ineligibleAsins: string[] = [];
  asinEligibility: Map<string, { status: boolean; reason: string }> = new Map();
  isReadOnly = false;
  isLastTargeting = false;
  ineligibleAsinsCollapsed = true;
  brands: Set<string> = new Set();
  organizationId!: number;

  readonly faPlus = faPlusCircle;
  readonly ICON_ADD = ICON_ADD;
  readonly CampaignType = CampaignType;

  @ViewChild("productAdd") productAdd!: TemplateRef<any>;
  productModalRef!: BsModalRef;

  constructor(
    private route: ActivatedRoute,
    private toasterService: ToastrService,
    private asinService: AsinService,
    private modalService: BsModalService,
    private toastrService: ToastrService,
    private accountSelectionService: AccountSelectionService,
    private sdStrategiesService: SdStrategiesService,
    private strategyService: StrategyService,
    private translocoService: TranslocoService,
  ) {
    this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.organizationId = am.resourceOrganizationId!;
    });
    this.strategy$ = combineLatest([
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      this.route.paramMap,
    ]).pipe(
      switchMap(([am, params]) => {
        const id = Number(params.get("id"));
        return this.sdStrategiesService
          .getSDStrategies(am.accountId, am.marketplace)
          .pipe(map((strategies) => new StrategyEx(strategies.get(id)!)));
      }),
    );
    this.strategy$.pipe(untilDestroyed(this)).subscribe((s) => {
      this.strategy = s;
      // disableOtherQueries == !autoAlgoExplorationEnabled
      const nbTargetings = this.strategy
        ? (this.strategy.disableOtherQueries ? 0 : 1) +
          this.strategy.tactics.filter((x) => x.tacticType == TacticType.LEGACY).length +
          this.strategy.audienceTargetings.length
        : 0;

      this.isLastTargeting = nbTargetings <= 1;
    });
    const catalog$ = this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(
      switchMap((am) => this.asinService.getCatalog(am.accountId, am.marketplace)),
    );
    combineLatest([this.strategy$, catalog$])
      .pipe(untilDestroyed(this))
      .subscribe(([strategy, catalog]) => {
        this.asinsList = [];
        this.brands.clear();

        this.ineligibleAsins = [];
        if (!strategy) {
          return;
        }
        this.asinsList = strategy.asins ?? [];
        if (catalog) {
          // use the same eligibility as for SP
          this.asinEligibility = catalog.getSPEligibility();
          this.asinsList.forEach((item) => {
            if (!this.asinEligibility.has(item.asin!) || this.asinEligibility.get(item.asin!)?.status == false)
              this.ineligibleAsins.push(item.asin!);
            if (catalog.asinOffers.has(item.asin!)) {
              const brand = catalog.asinOffers.get(item.asin!)?.brand;
              if (brand) this.brands.add(brand);
            }
          });
        }
      });
  }

  filterBlacklist(tactics: Array<StrategyTactic>, isBlacklist: boolean) {
    return tactics.filter((x) =>
      isBlacklist ? x.tacticType === TacticType.BLACKLIST : x.tacticType !== TacticType.BLACKLIST,
    );
  }

  toggleAsinsVisibility() {
    this.asinsVisible = !this.asinsVisible;
  }

  toggleProductAdd() {
    this.productModalRef = this.modalService.show(this.productAdd, {
      class: "modal-xl modal-primary modal-dialog-centered",
    });
  }

  private prettyPrintAsinList(asins: string[]) {
    const maxAsins = 5;
    let message = asins.slice(0, maxAsins).join(", ");
    if (asins.length > maxAsins) {
      message += "…";
    }
    return message;
  }

  deleteStrategyAsins(toDelete: StrategyAsin[]) {
    if (!this.strategy || toDelete.length === 0) {
      return;
    }
    this.strategyService
      .deleteAsinsFromStrategy(
        this.strategy,
        toDelete.map((a) => a.asin!),
      )
      .subscribe({
        next: () => {
          const toDeleteMsg = this.prettyPrintAsinList(toDelete.map((a) => a.asin!));
          this.toasterService.success(
            `ASIN${toDelete.length > 1 ? "s" : ""} ${toDeleteMsg} removed from SD strategy "${this.strategy?.name}"`,
            "Strategy ASIN updated",
          );
        },
        error: (error) => {
          const toDeleteMsg = this.prettyPrintAsinList(toDelete.map((a) => a.asin!));
          this.toasterService.error(
            `Error removing Strategy ASIN${toDelete.length > 1 ? "s" : ""} ${toDeleteMsg}: ${error}`,
            "Strategy ASIN update error",
          );
        },
      });
  }

  moveStrategyAsins(toMove: StrategyAsin[]): void {
    if (!this.strategy || toMove.length === 0) {
      return;
    }
    // cannot move all ASINs
    if (toMove.length === this.asinsList.length) {
      this.toasterService.error("You cannot move all ASINs from a strategy");
      return;
    }
    const asinsToMove = toMove.map((sa) => sa.asin!);
    const modalOptions: ModalOptions = {
      initialState: {
        source: this.strategy,
        asinsToMove: asinsToMove,
      },
      class: "modal-primary",
    };
    const modalRef = this.modalService.show(MoveStrategyAsinsModalComponent, modalOptions);
    let creationModalRef: BsModalRef;

    const subscription = modalRef
      .content!.selectedStrategy.pipe(
        switchMap((target) => this.strategyService.moveAsinsToStrategy(asinsToMove, this.strategy!, target)),
      )
      .subscribe({
        next: () => {
          this.toasterService.success("ASINs succesfully moved to other strategy");
          subscription.unsubscribe();
        },
        error: (error: string) => {
          this.toasterService.error(`Error moving ASINs to other strategy: ${error}`, "Error moving ASINS");
          subscription.unsubscribe();
        },
      });
    subscription.add(
      modalRef
        .content!.newStrategyCreation.pipe(
          switchMap(() => {
            const modalOptions: ModalOptions = {
              initialState: {
                asins: toMove,
              },
              class: "modal-xl",
            };

            creationModalRef = this.modalService.show(SdStrategyCreationComponent, modalOptions);
            return creationModalRef.content.strategyCreated;
          }),
          switchMap(() => this.strategyService.deleteAsinsFromStrategy(this.strategy!, asinsToMove)),
        )
        .subscribe({
          next: () => {
            this.toasterService.success("ASINs succesfully moved to new strategy");
            subscription.unsubscribe();
            creationModalRef.hide();
          },
          error: (error: string) => {
            this.toasterService.error(`Error moving ASINs to new strategy: ${error}`, "Error moving ASINS");
            subscription.unsubscribe();
            creationModalRef.hide();
          },
        }),
    );
  }

  addStrategyAsins(toAdd: StrategyAsin[]) {
    if (!this.strategy || toAdd.length === 0) {
      return;
    }
    this.strategyService
      .addAsinsToStrategy(
        this.strategy,
        toAdd.map((a) => a.asin!),
      )
      .subscribe({
        next: () => {
          const toAddMsg = this.prettyPrintAsinList(toAdd.map((a) => a.asin!));
          this.toasterService.success(
            `ASIN${toAdd.length > 1 ? "s" : ""} ${toAddMsg} added to SD strategy "${this.strategy?.name}"`,
            "Strategy ASIN updated",
          );
        },
        error: (error: string) => {
          const toAddMsg = this.prettyPrintAsinList(toAdd.map((a) => a.asin!));
          this.toasterService.error(
            `Error adding Strategy ASIN${toAdd.length > 1 ? "s" : ""} ${toAddMsg}: ${error}`,
            "Strategy ASIN update error",
          );
        },
      });
  }

  getAsins(strategyAsin: StrategyAsin): string {
    return strategyAsin.asin!;
  }

  toggleIneligibleAsinDetails() {
    this.ineligibleAsinsCollapsed = !this.ineligibleAsinsCollapsed;
  }

  getBrands(): string {
    return Array.from(this.brands).join(" - ");
  }

  createTargeting() {
    if (!this.strategy) {
      return;
    }
    const modalOptions: ModalOptions = {
      initialState: {
        strategy: this.strategy,
        organizationId: this.organizationId,
      },
      class: "modal-lg",
    };
    const modalRef = this.modalService.show(SdTargetingAddPopupComponent, modalOptions);
    const subscription = modalRef
      .content!.segmentCreationRequested.pipe(untilDestroyed(this))
      .subscribe((segmentCreationRequest) => {
        const segmentCreationModalOption: ModalOptions = {
          initialState: {
            accountId: this.strategy!.accountId,
            marketplace: this.strategy!.marketplace,
            organizationId: this.organizationId,
          },
          class: "modal-xl",
        };
        const segmentCreationRef = this.modalService.show(ProductSegmentModalComponent, segmentCreationModalOption);
        this.createTacticAfterSegmentCreation(
          subscription,
          segmentCreationRequest.tacticType,
          segmentCreationRef.content!.segmentCreated,
          segmentCreationRef.content!.segmentEditionCanceled,
        );
      });
    subscription.add(
      modalRef
        .content!.targetingCreationCancelled.pipe(untilDestroyed(this))
        .subscribe(() => subscription.unsubscribe()),
    );
  }

  private createTacticAfterSegmentCreation(
    subscription: Subscription,
    tacticType: TacticType,
    segmentCreated: Observable<SegmentEx>,
    segmentCreationCancelled: Observable<void>,
  ) {
    if (!this.strategy) {
      return;
    }
    subscription.add(
      segmentCreated
        .pipe(
          untilDestroyed(this),
          switchMap((s) => {
            return this.strategyService.addTacticToStrategy(
              this.strategy!.accountId,
              this.strategy!.marketplace,
              this.strategy!.strategyId!,
              s.segmentId,
              tacticType,
            );
          }),
        )
        .subscribe({
          next: () => {
            this.toastrService.success(
              this.translocoService.translate("sd-page.targeting_sucessfully_added_to_strategy"),
            );
            subscription.unsubscribe();
          },
          error: (error) => {
            this.toastrService.error(error, this.translocoService.translate("sd-page.targeting_creation_error"));
            subscription.unsubscribe();
          },
        }),
    );
    subscription.add(segmentCreationCancelled.pipe(untilDestroyed(this)).subscribe(() => subscription.unsubscribe()));
  }
}
