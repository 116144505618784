import { Component } from "@angular/core";

import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { AccountMarketplace } from "@front/m19-api-client";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";

@Component({
  selector: "app-account-name-link",
  standalone: true,
  imports: [FontAwesomeModule, RouterLink, RouterLinkActive, MatTooltipModule],
  template: ` <div class="inline-flex items-center">
    @if (showWarning) {
      <fa-icon class="danger mr-3" [icon]="faExclamationTriangle" [matTooltip]="warningTooltip"></fa-icon>
    }
    <a
      routerLink="/dashboard/advertising"
      class="link"
      [queryParams]="{
        accountId: accountInfo.accountId,
        marketplace: accountInfo.marketplace,
        orgId: accountInfo.resourceOrganizationId,
      }"
      routerLinkActive="active"
    >
      {{ accountInfo.accountName }}
    </a>
  </div>`,
  styleUrls: ["./account-name-link.component.scss"],
})
export class AccountNameLinkComponent implements ICellRendererAngularComp {
  readonly faWarning = faExclamationTriangle;

  accountInfo!: AccountMarketplace; // In params
  showWarning = false;
  warningTooltip!: string;

  agInit(params: ICellRendererParams & { accountMarketplace: AccountMarketplace }): void {
    this.accountInfo = params.accountMarketplace;
    this.showWarning = this.getAccountWarning();
    this.warningTooltip = this.getWarningTooltip();
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  private getAccountWarning(): boolean {
    return !this.accountInfo.state || !this.accountInfo.isValidToken || !this.accountInfo.hasAccessToAdvertising;
  }

  private getWarningTooltip(): string {
    return !this.accountInfo.hasAccessToAdvertising
      ? "Advertising access lost"
      : !this.accountInfo.isValidToken
        ? "Invalid or missing Selling Partner API access"
        : "";
  }

  protected readonly faExclamationTriangle = faExclamationTriangle;
}
