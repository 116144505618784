<ng-container *transloco="let t">
  <app-notification-card
    [notification]="notification"
    [small]="small"
    helplink="https://help.m19.com/old-campaigns-running-in-parallel-with-m19-strategies"
  >
    <span title>
      {{ t("notification-card-strategy-overlap.strategies_overlapping") }}
      @for (overlappingStrategy of overlappingStrategies; track overlappingStrategy; let isLast = $last) {
        <span>
          <span class="break-all"
            ><b
              ><a href="{{ getStrategyLink(overlappingStrategy) }}" target="_blank" class="link">
                {{ overlappingStrategy.name }}</a
              ></b
            >{{ isLast ? "" : ", " }}
          </span>
        </span>
      }
    </span>
    <span action>
      @if (asins.length > 1) {
        <span>{{ t("common.asins") }} </span>
      }
      @if (asins.length === 1) {
        <span>{{ t("common.asin") }} </span>
      }
      @for (asin of asins; track asin; let isLast = $last) {
        <span>
          <span class="break-all"
            ><b
              ><a href="{{ getAsinLink(asin) }}" target="_blank" class="link">{{ asin }}</a></b
            >{{ isLast ? "" : ", " }}
          </span>
        </span>
      }
      <span>{{ t("notification-card-strategy-overlap.present_in_multiple_startegies", { count: asins.length }) }}</span>
      {{ t("notification-card-strategy-overlap.asin_unique_strategy") }}
    </span>
    <span titleSmall>
      {{ t("notification-card-strategy-overlap.strategies_overlapping") }}
      @for (overlappingStrategy of overlappingStrategies; track overlappingStrategy; let isLast = $last) {
        <span>
          <span class="break-all"
            ><b
              ><a href="{{ getStrategyLink(overlappingStrategy) }}" target="_blank" class="link">
                {{ overlappingStrategy.name }}</a
              ></b
            >{{ isLast ? "" : ", " }}
          </span>
        </span>
      }
    </span>
    <span actionSmall> {{ t("notification-card-strategy-overlap.asins_multiple_startegy") }} </span>
  </app-notification-card>
</ng-container>
