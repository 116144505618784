<ng-container *transloco="let t">
  <h2>{{ t("user-management.user_management") }}</h2>

  <div class="ag-theme-quartz mb-5 mt-3">
    <h4 class="mb-2">{{ t("user-management.organizations") }}</h4>
    <ag-grid-angular style="width: 100%; height: 75vh" [gridOptions]="gridOptions"></ag-grid-angular>
  </div>

  <h4 class="my-2">{{ t("v2-sidebar.activities") }}</h4>
  <app-user-activities-grid></app-user-activities-grid>
</ng-container>
