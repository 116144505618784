<div class="modal-header" *transloco="let t">
  <h4 class="modal-title pull-left">{{ t("stripe-create-token.credit_card") }}</h4>
</div>
<form (ngSubmit)="createToken()">
  <div class="StripeElement mx-3">
    <ngx-stripe-elements [stripe]="stripe" [elementsOptions]="elementsOptions">
      <ngx-stripe-card [options]="cardOptions"></ngx-stripe-card>
    </ngx-stripe-elements>
  </div>
  <div class="modal-footer" *transloco="let t">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
      {{ t("common.cancel") }}
    </button>
    <button type="submit" class="btn btn-primary" (click)="bsModalRef.hide()">{{ t("common.save") }}</button>
  </div>
</form>
