import { MatSort, SortDirection } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

/**
 * This Datasource is highly inspired from the Angular Material MatTableDatasource: https://github.com/angular/components/blob/master/src/material/table/table-data-source.ts
 * The modifications dwelled mostly on the paginator part.
 *
 * It exposes a property `isPrimaryData: (row: T) => boolean` to be implemented by the client. If a row is `primary` it can be sorted and paginated.
 * On the other hand, secondary rows are not sorted and paginated. They will always be linked to their closest primary row.
 * It enables datarows to be expanded.
 */
export class ExpandableDataSource<T> extends MatTableDataSource<T> {
  /**
   * Predicate to say whether a row is primary (expandable) or not.
   * If not specified, all rows are primary.
   */
  isPrimaryData: (row: T) => boolean = (_) => true;
  /**
   * Optional predicate to introduce a second level of expandable data
   * If not specified, no second level of expansion will be supported
   */
  isSecondaryData: (row: T) => boolean = null;

  /**
   * Optional accesor to sort data according to two differents criteria
   */
  firstSortCriteria: (row: T) => string | number;

  useEmptyFilter = true;

  /**
   * Gets a sorted copy of the data array based on the state of the MatSort. Called
   * after changes are made to the filtered data or when sort changes are emitted from MatSort.
   * By default, the function retrieves the active sort and its direction and compares data
   * by retrieving data using the sortingDataAccessor. May be overridden for a custom implementation
   * of data ordering.
   * @param data The array of data that should be sorted.
   * @param sort The connected MatSort that holds the current sort state.
   */
  sortData: (data: T[], sort: MatSort) => T[] = (data: T[], sort: MatSort): T[] => {
    if (this.firstSortCriteria && data?.length !== 0) {
      data.sort((a, b) => {
        const valueA = this.firstSortCriteria(a);
        const valueB = this.firstSortCriteria(b);
        if (typeof valueA === "string") return valueA.localeCompare(valueB as string);
        return valueA - (valueB as number);
      });

      const firstVal = this.firstSortCriteria(data[0]);
      const splitIndex = data.findIndex((x) => this.firstSortCriteria(x) !== firstVal);
      if (splitIndex === -1) return this._sortData(data, sort);
      // then split in two and sort them
      const firstPart = data.splice(0, splitIndex);
      return this._sortData(firstPart, sort).concat(this._sortData(data, sort));
    } else {
      return this._sortData(data, sort);
    }
  };

  private _sortData(data: T[], sort: MatSort): T[] {
    const active = sort.active;
    const direction = sort.direction;
    if (!active || direction == "") {
      return data;
    }
    if (data.length == 0 || !this.isPrimaryData(data[0])) {
      return data.sort((a, b) => this.compareData(a, b, active, direction));
    }
    // group data by primary and secondary data
    const groupedData = [{ prim: data[0], children: [] }];
    for (let i = 1; i < data.length; i++) {
      if (this.isPrimaryData(data[i])) {
        groupedData.push({ prim: data[i], children: [] });
      } else if (this.isSecondaryData) {
        const last = groupedData[groupedData.length - 1];
        if (this.isSecondaryData(data[i])) {
          last.children.push({ sec: data[i], children: [] });
        } else if (last.children.length > 0 && last.children[last.children.length - 1].sec) {
          last.children[last.children.length - 1].children.push(data[i]);
        } else {
          last.children.push(data[i]);
        }
      } else {
        const last = groupedData[groupedData.length - 1];
        last.children.push(data[i]);
      }
    }
    return groupedData
      .sort((a, b) => this.compareData(a.prim, b.prim, active, direction))
      .flatMap((x) => [
        x.prim,
        ...x.children
          .sort((a, b) => this.compareData(a.sec ? a.sec : a, b.sec ? b.sec : b, active, direction))
          .flatMap((y) => {
            if (y.sec) {
              return [y.sec, ...y.children.sort((a, b) => this.compareData(a, b, active, direction))];
            } else {
              return [y];
            }
          }),
      ]);
  }

  private compareData(a: T, b: T, active: string, direction: SortDirection) {
    let valueA = this.sortingDataAccessor(a, active);
    let valueB = this.sortingDataAccessor(b, active);

    // If there are data in the column that can be converted to a number,
    // it must be ensured that the rest of the data
    // is of the same type so as not to order incorrectly.
    const valueAType = typeof valueA;
    const valueBType = typeof valueB;

    if (valueAType !== valueBType) {
      if (valueAType === "number") {
        valueA += "";
      }
      if (valueBType === "number") {
        valueB += "";
      }
    }

    // If both valueA and valueB exist (truthy), then compare the two. Otherwise, check if
    // one value exists while the other doesn't. In this case, existing value should come last.
    // This avoids inconsistent results when comparing values to undefined/null.
    // If neither value exists, return 0 (equal).
    let comparatorResult = 0;
    if (valueA != null && valueB != null) {
      // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
      if (valueA > valueB) {
        comparatorResult = 1;
      } else if (valueA < valueB) {
        comparatorResult = -1;
      }
    } else if (valueA != null) {
      comparatorResult = 1;
    } else if (valueB != null) {
      comparatorResult = -1;
    }

    return comparatorResult * (direction == "asc" ? 1 : -1);
  }

  _filterData(data: T[]) {
    // If there is a filter string, filter out data that does not contain it.
    // Each data object is converted to a string using the function defined by filterTermAccessor.
    // May be overridden for customization.
    this.filteredData =
      this.filter == null || (this.useEmptyFilter && this.filter === "")
        ? data
        : data.filter((obj) => this.filterPredicate(obj, this.filter));

    if (this.paginator) {
      this._updatePaginator(this.filteredData.filter((r) => this.isPrimaryData(r)).length);
    }

    return this.filteredData;
  }

  private getPrimaryIndex(data: T[]) {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      if (this.isPrimaryData(data[i])) {
        result.push(i);
      }
    }
    return result;
  }

  /**
   * Returns a paged slice of the provided data array according to the provided MatPaginator's page
   * index and length. If there is no paginator provided, returns the data array as provided.
   */
  _pageData(data: T[]): T[] {
    if (!this.paginator) {
      return data;
    }
    const primaryIndex = this.getPrimaryIndex(data);
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const end =
      startIndex + this.paginator.pageSize >= primaryIndex.length
        ? data.length
        : primaryIndex[startIndex + this.paginator.pageSize];
    const res = data.slice(primaryIndex[startIndex], end);
    return res;
  }
}
