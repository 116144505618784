<ng-container *transloco="let t">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      {{
        isSdTargeting
          ? t("remove-tactic-popup.remove_targeting_from_strategy")
          : t("remove-tactic-popup.remove_tactic_from_strategy")
      }}
    </h4>
  </div>
  <div class="modal-body">
    <p>
      {{
        isSdTargeting
          ? t("remove-tactic-popup.delete_product_page", [segment!.name])
          : t("remove-tactic-popup.delete_tactic", [segment!.name])
      }}
    </p>
  </div>
  <div class="modal-footer">
    <IButton label="{{ t('common.cancel') }}" color="gray" variant="ghost" (onClick)="bsModalRef.hide()" />
    <IButton label="{{ t('common.delete') }}" color="red" (onClick)="delete()" testid="btn-confirm" />
  </div>
</ng-container>
