<ng-container *transloco="let t">
  @if (!loading() && tacosStrategyEx() && !createSbStrategy()) {
    <div class="container mx-auto flex min-h-full flex-col rounded-lg bg-white px-10 py-6">
      <div class="flex flex-col gap-2">
        <a
          [routerLink]="['/advertising/tacos-strategies']"
          queryParamsHandling="merge"
          class="inline-flex items-center gap-2 text-sm text-gray-500 underline-offset-4 hover:text-gray-700"
        >
          <span [class]="ICON_CHEVRON_D + ' h-4 w-4 rotate-90'"></span>
          {{ t("tacos-strategy.back_to_tacos_strategy_list") }}</a
        >
        <div class="flex items-end gap-4">
          @if (spStrategy(); as sp) {
            <span (click)="openEditNameModal()" class="text-2xl font-medium text-gray-800">{{ sp.name }}</span>
            <IButton
              color="white"
              icon="icon-[mdi--pencil]"
              size="xs"
              tooltip="Edit Name"
              (onClick)="openEditNameModal()"
            />
          } @else {
            <div class="mt-1 h-8 w-56 animate-pulse rounded-md bg-gray-200"></div>
          }
          @if (tacosStrategyEx()) {
            <div class="flex items-center gap-2">
              <state-badge
                [state]="tacosStrategyEx()!.state"
                [loading]="stateUpdateLoading()"
                [matTooltip]="
                  tacosStrategyEx()!.state === TacosStrategyGroupState.PAUSED
                    ? t('algo-state-cell.paused_strategy_click_to_activate')
                    : t('algo-state-cell.active_strategy_click_to_pause')
                "
                [matTooltipShowDelay]="300"
                matTooltipPosition="right"
                (onClick)="updateStrategyGroupState($event)"
              />
            </div>
          }
        </div>
      </div>

      <div class="mt-5 flex w-full gap-2 pb-3">
        <strategy-info-bloc (onClick)="openTargetModal()">
          <div title class="text-sm uppercase text-gray-500">{{ t("strategy-table.target") }}</div>
          @if (tacosStrategyEx()) {
            <div body class="ml-0.5 font-medium">{{ tacosStrategyEx()!.tacosTarget! * 100 | number: "1.0-1" }} %</div>
          } @else {
            <div body class="mt-1 h-6 w-14 animate-pulse rounded-md bg-gray-200"></div>
          }
        </strategy-info-bloc>
      </div>

      <ITabs [tabs]="tabs()" [(activeTab)]="activeTab" />

      <div class="flex-1 pt-5">
        @if (selectedSection() === SECTIONS.STATS) {
          @if (tacosStrategyEx() && spStrategy()) {
            <tacos-stats [tacosStrategy]="tacosStrategyEx()!" />
          }
        } @else if (selectedSection() === SECTIONS.PRODUCTS) {
          @if (tacosStrategyEx() && spStrategy() && am()) {
            <tacos-products
              [tacosStrategy]="tacosStrategyEx()!"
              [spStrategy]="spStrategy()!"
              [sdStrategy]="sdStrategy()"
              [sbStrategy]="sbStrategy()"
              [sbAsins]="sbAsins()"
              [sbCreatives]="sbCreatives()"
              [missingAsinsInSb]="missingAsinsInSb()"
              [am]="am()!"
              [isReadOnly]="!!isReadOnly()"
            />
          }
        } @else if (selectedSection() === SECTIONS.CAMPAIGNS) {
          @if (spStrategy() && am() && tacosStrategyEx()) {
            <tacos-ad-types
              [am]="am()!"
              [tacosStrategy]="tacosStrategyEx()!"
              [spStrategy]="spStrategy()!"
              [sdStrategy]="sdStrategy()"
              [sbStrategy]="sbStrategy()"
              [sbCreatives]="sbCreatives()"
              [sbAsins]="sbAsinsString()"
              [missingAsinsInSb]="missingAsinsInSb()"
            />
          }
        } @else if (selectedSection() === SECTIONS.ACTIVITIES) {
          <div class="ag-theme-quartz h-[30rem]">
            <app-activity-component
              [activityFilter]="activityFilter()"
              [hideEntityColumn]="true"
              gridConfigStorageKey="strategyActivities"
            />
          </div>
        }
      </div>
    </div>
  } @else if (loading()) {
    <div class="flex h-full items-center justify-center">
      <app-spinner [display]="true" [main]="false" size="m" type="default" />
    </div>
  }

  <ng-template #targetModal>
    <div class="w-[20vw]">
      <div class="py-5">
        <IInput
          [label]="'Target'"
          [placeholder]="'Target'"
          [type]="'number'"
          [min]="0"
          [max]="100"
          [value]="tacosStrategyEx()?.tacosTarget! * 100 | number: '1.0-1'"
          [autoFocus]="true"
          [trailing]="true"
          icon="icon-[mdi--percent]"
          (valueChange)="tacosTargetInput.set(+$event)"
        />
      </div>
      <div class="mt-4 flex justify-end gap-2">
        <IButton color="white" [label]="'Cancel'" (onClick)="hideModal()" />
        <IButton [label]="'Save'" (onClick)="updateTacosTarget()" [loading]="modalLoading()" />
      </div>
    </div>
  </ng-template>

  <ng-template #editNameModal>
    <div class="w-[20vw]">
      <div class="py-5">
        <IInput
          [label]="'Name'"
          [placeholder]="'Name'"
          [value]="spStrategy()?.name!"
          [autoFocus]="true"
          [trailing]="true"
          (valueChange)="strategyNameInput.set($event)"
        />
      </div>
      <div class="mt-4 flex justify-end gap-2">
        <IButton color="white" [label]="'Cancel'" (onClick)="hideModal()" />
        <IButton [label]="'Save'" (onClick)="updateStrategyName()" [loading]="modalLoading()" />
      </div>
    </div>
  </ng-template>
</ng-container>
