import { Component, Input, OnInit } from "@angular/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { DataSet, DataSetEventAnnotation, UserSelectionService } from "@front/m19-services";
import { Metric } from "@front/m19-metrics";
import { Option } from "@front/m19-ui";
import { ActivityEventType, ActivityService } from "@m19-board/activities/activity.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";
import { BehaviorSubject, combineLatest, debounceTime, map, Observable, of, ReplaySubject, switchMap } from "rxjs";
import { Comparison, DateAggregation, MetricsSelectorLocalStorageKey } from "@front/m19-utils";
import { AdStatsEx } from "@front/m19-models";
import { Marketplace } from "@front/m19-api-client";
import { M19_METRICS } from "@m19-board/agency-board/agency-board.component";

export type ChartData<T> = {
  data: T[];
  previousData?: T[];
  totalData: T;
  totalPreviousData?: T;
};

@UntilDestroy()
@Component({
  selector: "app-chart-renderer",
  templateUrl: "./chart-renderer.component.html",
})
export class ChartRendererComponent<T> implements OnInit {
  readonly faTimes = faTimes;

  @Input() localStorageKey: MetricsSelectorLocalStorageKey;
  @Input() title: string;
  @Input() dataset: DataSet<T>;
  @Input() metrics: Metric<T>[];
  @Input() asin: string;
  @Input() marketplace: Marketplace;
  @Input() chartData$: Observable<ChartData<T> | null>;
  @Input() annotations$: Observable<DataSetEventAnnotation[]> = of([]);
  @Input() selectMetricCallback: (metrics: Metric<T>[]) => Metric<T>[];
  @Input() withEventAnnotations = false;

  // state
  totalData: AdStatsEx;
  totalPreviousData: AdStatsEx;
  loading = true;
  public splitedDisplayMode = false;
  dateAggregation$: BehaviorSubject<DateAggregation> = new BehaviorSubject<DateAggregation>(DateAggregation.daily);
  private chartMetrics$ = new ReplaySubject<Metric<AdStatsEx>[]>(1);
  displayEventAnnotation$ = new BehaviorSubject(false);
  disableEventAnnotation = false;
  readonly allEventAnnotationTypes: Option<ActivityEventType>[] = this.activityService.allActivityEventTypesOptions;
  readonly allUsers: Observable<Option<string>[]> = this.activityService.allUsersOptions$;

  public managedUnmanaged = "Managed/Unmanaged";
  public total = "Total";

  constructor(
    public ref: BsModalRef,
    private userSelectionService: UserSelectionService,
    private activityService: ActivityService,
  ) {}

  ngOnInit(): void {
    this.chartData$.pipe(untilDestroyed(this)).subscribe((chartData) => {
      this.totalData = chartData.totalData;
      this.totalPreviousData = chartData.totalPreviousData;
    });
    combineLatest<[ChartData<T>, Metric<AdStatsEx>[], string[], string[], DateAggregation, DataSetEventAnnotation[]]>([
      this.chartData$,
      this.chartMetrics$,
      this.userSelectionService.dateRange$,
      this.userSelectionService.periodComparison$.pipe(map((x: { type: Comparison; period: string[] }) => x?.period)),
      this.dateAggregation$,
      this.withEventAnnotations
        ? combineLatest([this.dateAggregation$, this.displayEventAnnotation$]).pipe(
            switchMap(([agg, b]) => {
              if (agg == DateAggregation.daily && b) {
                return this.annotations$;
              }
              return of([]);
            }),
          )
        : this.dateAggregation$.pipe(switchMap((agg) => (agg == DateAggregation.daily ? this.annotations$ : of([])))),
    ])
      .pipe(
        untilDestroyed(this),
        debounceTime(100), // adding debounce to avoid sequential refresh which can cause graph not displaying - see https://github.com/m19-dev/main-repo/issues/7206
      )
      .subscribe(([chartData, metrics, dates, periodComparison, dateAggregation, annotations]) => {
        this.loading = false;
        if (this.splitedDisplayMode) {
          const new_metrics: Metric<AdStatsEx>[] = [];
          metrics.forEach((metric: Metric<AdStatsEx>) => {
            if (M19_METRICS.find((m) => m.key === metric) != undefined) {
              new_metrics.push(M19_METRICS.find((m) => m.key === metric)?.op);
              new_metrics.push(M19_METRICS.find((m) => m.key === metric)?.un);
            } else {
              new_metrics.push(metric);
            }
          });
          metrics = new_metrics;
        }
        this.dataset.buildDataSet(
          chartData.data,
          metrics,
          dateAggregation,
          { minDate: dates[0], maxDate: dates[1] },
          chartData.previousData?.length > 0 ? { data: chartData.previousData, period: periodComparison } : undefined,
          annotations,
        );
      });
    this.dateAggregation$.pipe(untilDestroyed(this)).subscribe((agg) => {
      this.disableEventAnnotation = agg !== DateAggregation.daily;
    });
  }

  selectMetrics(metrics: Metric<AdStatsEx>[]) {
    const chartMetrics = this.selectMetricCallback ? this.selectMetricCallback(metrics) : metrics;
    this.chartMetrics$.next(chartMetrics);
  }

  selectAggregation(aggregation: DateAggregation): void {
    this.dateAggregation$.next(aggregation);
  }

  hideChart() {
    this.ref.hide();
  }

  toggleEventAnnotation(change: MatSlideToggleChange): void {
    this.displayEventAnnotation$.next(change.checked);
  }
}
