@if (targetFormatted) {
  @if (addLabel) {
    <span class="mr-1 text-gray-500">{{ getLabel() }}</span>
  }
  <IButton
    [label]="targetFormatted"
    color="gray"
    variant="ghost"
    [trailing]="true"
    [disabled]="readonly"
    [tooltipValue]="getTooltip()"
    (onClick)="openTargetModal(); $event.stopPropagation()"
    [icon]="ICON_EDIT_O"
    [iconOnHover]="true"
  />
}
