import { BrandAsset, Marketplace, SbAsins, SbCreative, SbCreativeType, State, MediaType } from '../api-client';

export const BrandNameMaxLength = 30;
export const HeadlineMaxLength = 50;

export interface SbCreativeBrandAssets {
  creativeTitle: string;
  logoAsset?: BrandAsset;
  videoAsset?: BrandAsset;
  storePage?: BrandAsset;
  customImage?: BrandAsset;
  customImage2?: BrandAsset;
  customImage3?: BrandAsset;
  customImage4?: BrandAsset;
  customImage5?: BrandAsset;
}

export const CreativeTypeStr: { [key in SbCreativeType]: string } = {
  [SbCreativeType.productCollection]: 'Product Collection',
  [SbCreativeType.video]: 'Video',
  [SbCreativeType.brandVideo]: 'Brand Video',
  [SbCreativeType.storeSpotlight]: 'Store Spotlight',
};
