import { Component, OnInit, ViewChild } from "@angular/core";
import {
  AccountSelectionService,
  AsinService,
  AuthService,
  DataSet,
  RankOption,
  UserSelectionService,
} from "@front/m19-services";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy } from "@ngneat/until-destroy";
import { combineLatest, switchMap, tap } from "rxjs";
import { ProductTrackerService } from "../../../services/product-tracker.service";
import { ProductMetric } from "../../../models/Metric";
import { BaseChartDirective } from "ng2-charts";
import {
  CategoryBsrMetric,
  DisplayGroupBsrMetric,
  PriceMetric,
  ReviewScoreMetric,
  ReviewsMetric,
} from "@m19-board/models/ProductMetricsDef";
import { ICON_CHEVRON_DOWN } from "@m19-board/utils/iconsLabels";
import { TranslocoService } from "@jsverse/transloco";
import { Marketplace } from "@front/m19-api-client";
import { Marketplaces, ProductEx } from "@front/m19-models";
import { MetricRegistry } from "@front/m19-metrics";
import { DateAggregation } from "@front/m19-utils";
import { AggregationFunction } from "../../../../../../../libs/m19-services/src/lib/utils/aggregation.utils";

type ProductTimelineMetric = {
  title: string;
  color: string;
  metricPrecision?: number;
  reverseAxis?: boolean;
};

@UntilDestroy()
@Component({
  selector: "app-product-tracker-timeline",
  templateUrl: "./product-tracker-timeline.component.html",
  styleUrls: [
    "./product-tracker-timeline.component.scss",
    "../product-tracker-tracked-keywords/product-tracker-tracked-keywords.component.scss",
  ],
})
export class ProductTrackerTimelineComponent implements OnInit {
  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;
  loading = false;

  marketplace: Marketplace;
  selectedAsin: string;
  selectedProduct: ProductEx;
  productList: string[] = [];
  productListFilter = "";

  dateAggregation: DateAggregation = DateAggregation.daily;
  dateRange: string[];
  selectedMetrics: ProductMetric[];

  readonly dateAggregations = [DateAggregation.daily, DateAggregation.weekly];
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  dataset: DataSet<ProductEx>;

  data: ProductEx[] = [];

  metrics: ProductMetric[] = [];
  productTrackerUrl = "/product-tracker";

  constructor(
    public activatedRoute: ActivatedRoute,
    private asinService: AsinService,
    private accountService: AccountSelectionService,
    private productTrackerService: ProductTrackerService,
    private userSelectionService: UserSelectionService,
    private authService: AuthService,
    private router: Router,
    private translocoService: TranslocoService,
  ) {
    this.authService.loggedUser$.subscribe((user) => {
      if ((user?.uiVersion ?? 0) > 0) {
        this.productTrackerUrl = "/product-center/product-tracker";
      }
    });
  }

  ngOnInit(): void {
    this.loading = true;
    combineLatest([
      this.accountService.singleAccountMarketplaceSelection$,
      this.activatedRoute.params,
      this.userSelectionService.dateRange$,
      this.userSelectionService.selectedCurrency$,
      this.authService.loggedUser$,
    ])
      .pipe(
        tap(([am, params, dateRange, currency, user]) => {
          if (!params.product) {
            this.router.navigate([this.productTrackerUrl]);
          }
          this.dateRange = dateRange;
          this.marketplace = am.marketplace;
          this.metrics = this.initMetrics();
          (this.metrics.find((m) => m.id === "PRICE") as PriceMetric).setCurrency(
            Marketplaces[this.marketplace].currency,
          );
          this.dataset = new DataSet<ProductEx>(
            16 / 9,
            this.metrics,
            [AggregationFunction.combineProducts],
            this.translocoService,
          );
          this.dataset.currency = currency;
          this.dataset.locale = user.locale;
        }),
        switchMap(([am, params, _dateRange, _currency, _user]) =>
          combineLatest([
            this.asinService.getProductTimelineWithMarketplace(params.product, am.marketplace),
            this.asinService.getProductWithMarketplace(params.product, am.marketplace),
          ]),
        ),
      )
      .subscribe(([products, product]: [ProductEx[], ProductEx]) => {
        this.selectedProduct = product;
        this.data = products;
        const category = product.categoryName;
        if (category) {
          this.metrics.find((m) => m.id === "BSR_CATEGORY").setTitle(`BSR ${category}`);
        }

        this.selectedMetrics = [this.metrics[0], this.metrics[2]];
        this.buildDataset();
      });

    this.productTrackerService.productTrackerData$.subscribe((productTracker) => {
      this.productList = productTracker.data.map((p) => p.asin);
    });
  }

  private buildDataset() {
    this.dataset.buildDataSet(this.data, this.selectedMetrics, this.dateAggregation, {
      minDate: this.dateRange[0],
      maxDate: this.dateRange[1],
    });
    this.loading = false;
  }

  private initMetrics() {
    const ids = ["REVIEW_SCORE", "REVIEWS", "BSR_CATEGORY", "BSR_DISPLAY_GROUP", "PRICE"];
    const metrics: ProductMetric[] = [];
    for (const id of ids) {
      const m = MetricRegistry.get(id);
      if (m) metrics.push(m as ProductMetric);
      else metrics.push(this.getNewProductMetricById(id));
    }
    return metrics;
  }

  private getNewProductMetricById(id: string) {
    switch (id) {
      case "REVIEW_SCORE":
        return new ReviewScoreMetric();
      case "REVIEWS":
        return new ReviewsMetric();
      case "BSR_CATEGORY":
        return new CategoryBsrMetric();
      case "BSR_DISPLAY_GROUP":
        return new DisplayGroupBsrMetric();
      case "PRICE":
        return new PriceMetric();
    }
  }

  changeDateAggregation(newAgg: DateAggregation) {
    this.dateAggregation = newAgg;
    this.buildDataset();
  }

  productPassTextFilter(product: string): boolean {
    return product.search(new RegExp(this.productListFilter, "i")) !== -1;
  }

  navigateToTrackedKeywordsDetails(product: string) {
    this.router.navigate([this.productTrackerUrl + "/product-timeline/", product]);
  }

  selectMetric(metric: ProductMetric) {
    if (this.selectedMetrics.length === 1 && (metric === this.selectedMetrics[0] || metric === this.selectedMetrics[1]))
      return;
    if (metric === this.selectedMetrics[0]) this.selectedMetrics = [this.selectedMetrics[1]];
    else if (metric === this.selectedMetrics[1]) this.selectedMetrics = [this.selectedMetrics[0]];
    else if (this.selectedMetrics.length === 1) this.selectedMetrics.push(metric);
    else this.selectedMetrics = [metric, this.selectedMetrics[0]];

    this.buildDataset();
  }

  isMetricSelected(metric: ProductTimelineMetric) {
    return this.selectedMetrics[0] === metric || this.selectedMetrics[1] === metric;
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  protected readonly RankOption = RankOption;
}
