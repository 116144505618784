import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { InventoryRule, Marketplace } from "@front/m19-api-client";
import { ICheckboxComponent } from "@front/m19-ui";
import { MatTooltip } from "@angular/material/tooltip";

@UntilDestroy()
@Component({
  selector: "app-asin-inventory-rules-modal",
  templateUrl: "./asin-inventory-rules-modal.component.html",
  styleUrls: ["./asin-inventory-rules-modal.component.scss"],
  standalone: true,
  imports: [TranslocoDirective, ReactiveFormsModule, ICheckboxComponent, MatTooltip],
})
export class AsinInventoryRuleModalComponent implements OnInit {
  @Input()
  public accountId!: string;

  @Input()
  public marketplace!: Marketplace;

  @Input()
  public asin!: string;

  @Input()
  public multipleAsinsBatchLength?: number;

  @Input()
  public rule?: InventoryRule;

  @Input()
  public warningMessage!: string;

  @Output()
  public saveRule = new EventEmitter<InventoryRule>();

  @Output()
  public deleteRule = new EventEmitter<void>();

  ruleForm: FormGroup<{
    pauseThreshold: any;
    inbound: any;
    noRule: any;
  }> = this.fb.group({
    pauseThreshold: [
      { value: null, disabled: true },
      [Validators.required, Validators.min(1), Validators.pattern(/^[0-9]+$/)],
    ],
    inbound: [false],
    noRule: [true],
  });

  get pauseThreshold(): AbstractControl {
    return this.ruleForm.controls.pauseThreshold;
  }

  get noRule(): AbstractControl {
    return this.ruleForm.controls.noRule;
  }

  get activateWhenInbound(): AbstractControl {
    return this.ruleForm.controls.inbound;
  }

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.noRule.valueChanges.pipe(untilDestroyed(this)).subscribe((b) => {
      if (b) {
        this.pauseThreshold.disable();
        this.activateWhenInbound.disable();
      } else {
        this.pauseThreshold.enable();
        this.activateWhenInbound.enable();
      }
    });
    this.noRule.setValue(false);
    if (this.rule) {
      this.pauseThreshold.setValue(this.rule.advertisingPauseThreshold);
      this.activateWhenInbound.setValue(this.rule.activateAdvertisingWhenInbound);
    }
  }

  save(): void {
    if (this.noRule.value) {
      this.deleteRule.emit();
      this.bsModalRef.hide();
      return;
    }
    this.saveRule.emit({
      accountId: this.accountId,
      marketplace: this.marketplace,
      asin: this.asin,
      advertisingPauseThreshold: this.pauseThreshold.value,
      activateAdvertisingWhenInbound: this.activateWhenInbound.value,
    });
    this.bsModalRef.hide();
  }
}
