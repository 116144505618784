<div class="main-wrapper flex flex-col" *transloco="let t">
  <div class="header-section mb-2 flex w-full justify-between">
    <div class="flex min-w-0 items-center">
      <button class="btn btn-ghost-secondary" [routerLink]="productTrackerUrl">
        {{ t("v2-sidebar.product_tracker") }}
      </button>
      /
      <button class="btn btn-ghost-secondary icon-right" [matMenuTriggerFor]="menu">
        {{ selectedAsin }}
        <span [class]="ICON_CHEVRON_DOWN" class="ml-1 text-lg text-gray-700"></span>
      </button>

      <mat-menu #menu="matMenu">
        <div class="dd-input-container flex items-center" (click)="$event.stopPropagation()">
          <input
            type="text"
            class="simple-input form-control"
            placeholder="{{ t('common.search') }}"
            [value]="productListFilter"
            (input)="productListFilter = getValueFromInputEvent($event)"
            (click)="$event.stopPropagation()"
            #search
          />
        </div>
        <ul class="product-list flex flex-col">
          @for (p of productList; track p) {
            @if (productPassTextFilter(p)) {
              <li class="dd-item" (click)="navigateToTrackedKeywordsDetails(p)">
                {{ p }}
              </li>
            }
          }
        </ul>
      </mat-menu>

      @if ((loading$ | async) === false) {
        <span class="gray-sm ml-2">({{ searchTermRanks.length }})</span>
      }

      <app-spinner [main]="false" type="default" size="s" [display]="loading$ | async" class="ml-3"></app-spinner>

      @if (productRow) {
        <div class="shrink-1 ml-3 flex min-w-0 flex-1">
          @if (selectedAsin && accountMarketplace.marketplace) {
            <app-product-thumbnail
              class="mr-2"
              [asin]="selectedAsin"
              [marketplace]="accountMarketplace.marketplace"
              [smallImg]="true"
            ></app-product-thumbnail>
          }
          <div class="min-w-0 flex-1">
            <IBadge [label]="(productRow.parentAsin ? 'Child' : 'Parent') + t('common.asin')" class="mb-2" size="xs" />
            <div class="max-w-full truncate text-sm text-gray-700" [matTooltip]="productRow.title">
              {{ productRow.title }}
            </div>
          </div>
        </div>
      }
    </div>
    <div class="flex items-center">
      @if (productRow && productRow.parentAsin) {
        <IButton
          (onClick)="switchToParentView()"
          color="white"
          label="{{ t('product-tracker-tracked-keywords.switch_to_parent_asin') }}"
          class="mr-3"
        />
      }
      @if (productRow && !productRow.parentAsin && productRow.childAsins?.length) {
        <ISelect
          [options]="childAsinsOptions()"
          (selectedChange)="navigateToTrackedKeywordsDetails($event.value)"
          placeholder="{{ t('product-tracker-tracked-keywords.switch_to_child_asin') }}"
          class="mr-3"
          popperWidth="250px"
          searchable
        >
          <ng-template #optionSlot let-option>
            <div class="flex items-center">
              <app-product-thumbnail
                class="mr-2"
                [asin]="option.value"
                [marketplace]="accountMarketplace.marketplace"
                [smallImg]="true"
              />
              <span class="truncate">{{ option.label }}</span>
            </div>
          </ng-template>
        </ISelect>
      }
      <app-switch-button
        class="rank-switch"
        [options]="[true, false]"
        [buttonTexts]="[t('common.table'), t('common.chart')]"
        [buttonTooltips]="['', '']"
        [selected]="tableView"
        (buttonClicked)="setTableView($event)"
      ></app-switch-button>
      <app-switch-button
        class="rank-switch ml-3"
        [options]="RANK_OPTIONS_ARRAY"
        [buttonTexts]="RANK_OPTIONS_ARRAY"
        [buttonTooltips]="RANK_OPTIONS_DESC"
        [selected]="rankOption"
        (buttonClicked)="selectRankOption($event)"
      ></app-switch-button>
    </div>
  </div>

  <div class="content-wrapper flex">
    <div class="card-common flex flex-col p-2">
      <div>
        <input
          type="text"
          class="simple-input form-control"
          placeholder="{{ t('keyword-tracker-page.search_term') }}"
          [value]="searchTermFilter"
          (input)="searchTermFilter = getValueFromInputEvent($event)"
          (click)="$event.stopPropagation()"
        />
        <div class="flex w-full justify-between px-1 py-2 font-medium">
          <span>{{ t("search-term-ranks.search_terms") }}</span>
          <div class="flex">
            <span class="mx-3">SFR</span>
            @if (!tableView) {
              <div class="h-1 w-6"></div>
            }
          </div>
        </div>
      </div>

      <div class="st-list flex flex-1 flex-col" #asinListScroll (scroll)="updateScroll('asinListScroll', $event)">
        @if (loading$ | async) {
          <div class="flex h-full w-full items-center justify-center">
            <app-spinner [main]="false" type="default" size="s" [display]="true"></app-spinner>
          </div>
        }
        @if ((loading$ | async) === false) {
          @for (st of searchTermRanks | searchTextFilter: accessValue : searchTermFilter; track st) {
            <div
              class="st-item group shrink-0"
              (click)="toggleSearchTermOnGraph(st.searchTerm)"
              (dblclick)="selectOnlySearchTerm(st.searchTerm)"
              (mouseenter)="setHoveredSearchTerm(st.searchTerm)"
              (mouseleave)="setHoveredSearchTerm(undefined)"
            >
              <div class="st-item-inner flex h-full w-full max-w-full items-center justify-between">
                @if (isSearchTermOnGraph(st.searchTerm) && rankOption !== RankOption.BOTH && !tableView) {
                  <div class="color-pill mr-2" [style.background-color]="getSearchTermColor(st.searchTerm)"></div>
                }
                <div class="w-full truncate">{{ st.searchTerm }}</div>
                <div class="st-sfr mx-3 flex items-center">
                  {{ sfrBySearchTerm.get(st.searchTerm) | number: undefined : locale }}
                </div>
                @if (rankOption !== RankOption.BOTH && !tableView) {
                  <ICheckbox
                    class="opacity-0 group-hover:opacity-100"
                    [value]="isSearchTermOnGraph(st.searchTerm)"
                    [ngClass]="{ 'opacity-0': !isSearchTermOnGraph(st.searchTerm) }"
                  />
                }
                @if (rankOption === RankOption.BOTH && !tableView) {
                  <div
                    [class.checkbox-hidden]="!isSearchTermOnGraph(st.searchTerm)"
                    [class.checked]="isSearchTermOnGraph(st.searchTerm)"
                    class="radio-btn shrink-0"
                  >
                    <div></div>
                  </div>
                }
              </div>
            </div>
          }
        }
      </div>
    </div>
    <div
      class="graph-table-wrapper card-common ml-3 flex-1 overflow-auto p-2"
      #datavizScroll
      (scroll)="updateScroll('datavizScroll', $event)"
    >
      @if (!tableView) {
        <app-product-tracker-keyword-chart
          [loading$]="loading$.asObservable()"
          [accountMarketplace]="accountMarketplace"
          [searchTermRanks]="searchTermRanks"
          [searchTermsOnGraph]="searchTermsOnGraph"
          [hoveredSearchTerm]="hoveredSearchTerm"
          [rankOption]="rankOption"
        ></app-product-tracker-keyword-chart>
      }
      @if (tableView) {
        <app-product-tracker-keyword-table
          [loading$]="loading$.asObservable()"
          [searchTermRanks]="searchTermRanks"
          [accountMarketplace]="accountMarketplace"
          [rankOption]="rankOption"
        ></app-product-tracker-keyword-table>
      }
    </div>
  </div>
</div>
