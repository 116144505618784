import { formatCurrency } from "@angular/common";
import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { StripeCardElement, StripeCardElementOptions, StripeElementsOptions } from "@stripe/stripe-js";
import { StripeCardComponent, StripeElementsDirective, StripeService } from "ngx-stripe";
import { BillingPlan, Customer } from "@front/m19-api-client";
import { CurrencyService } from "@front/m19-services";
import { TranslocoDirective } from "@jsverse/transloco";

@UntilDestroy()
@Component({
  selector: "app-billing-registration-payment",
  templateUrl: "./billing-registration-payment.component.html",
  styleUrls: ["billing-registration-payment.component.scss"],
  standalone: true,
  imports: [TranslocoDirective, StripeElementsDirective, StripeCardComponent],
})
export class BillingRegistrationPaymentComponent {
  @ViewChild(StripeCardComponent) card!: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: "#666EE8",
        color: "#31325F",
        fontWeight: "400",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: "16px",
        "::placeholder": {
          color: "#73818f",
        },
      },
    },
    hideIcon: false, // enable card brand choice https://docs.stripe.com/co-badged-cards-compliance?type=web-elements&ui=card-element
  };

  elementsOptions: StripeElementsOptions = {
    locale: "auto",
  };

  @Input({ required: true }) billingPlan!: BillingPlan;

  @Input() set customer(c: Customer) {
    this._customer = c;
  }

  _customer?: Customer;

  @Input() locale!: string;

  @Output() cardElement = new EventEmitter<StripeCardElement>();

  @Input() stripe!: StripeService;

  constructor(public currencyService: CurrencyService) {}

  emitCard(x: StripeCardElement) {
    if (x) {
      this.cardElement.emit(x);
    }
  }

  taxRate(): number {
    if (this._customer && this._customer.country == "FR") {
      return 0.2;
    }
    return 0;
  }

  getAmount(): number | undefined {
    return this.billingPlan ? this.billingPlan?.flatFee?.amount : undefined;
  }

  formatAmount(amount?: number): string {
    if (!amount) return "";
    return formatCurrency(
      amount,
      this.locale,
      this.currencyService.getCurrencySymbol(this.billingPlan.currency!),
      this.billingPlan.currency,
      "1.0-0",
    );
  }
}
