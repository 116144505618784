<form [formGroup]="registerForm" (ngSubmit)="onSubmit()" *transloco="let t">
  <div class="flex flex-col gap-4">
    <div class="flex w-full flex-col gap-4 sm:flex-row">
      <IInput
        class="flex-1"
        size="lg"
        icon="icon-[mdi--user]"
        id="firstName"
        [label]="t('register.first_name_field')"
        autocomplete="on"
        [control]="registerForm.controls.firstName"
      />
      <IInput
        class="flex-1"
        size="lg"
        [label]="t('register.last_name_field')"
        id="lastName"
        icon="icon-[mdi--user]"
        autocomplete="on"
        [control]="registerForm.controls.lastName"
      />
    </div>

    <IInput
      size="lg"
      type="email"
      [label]="t('common.email')"
      id="email"
      icon="icon-[mdi--email]"
      autocomplete="on"
      [control]="registerForm.controls.email"
      [error]="formErrors().email"
    />
    <IInput
      size="lg"
      type="password"
      [label]="t('register.password_field')"
      id="password"
      icon="icon-[mdi--password]"
      autocomplete="on"
      [control]="registerForm.controls.password"
      [error]="formErrors().password"
    />
    <IInput
      size="lg"
      type="password"
      [label]="t('register.password_confirm_field')"
      autocomplete="on"
      id="confirmPassword"
      icon="icon-[mdi--password]"
      [control]="registerForm.controls.confirmPassword"
      [error]="formErrors().confirmPassword"
    />
  </div>
  <ICheckbox
    class="mt-4 inline-block"
    inputName="termsAndConditions"
    inputId="termsAndConditions"
    [value]="registerForm.controls.termsAndConditions.value"
    (valueChange)="registerForm.controls.termsAndConditions.setValue($event)"
  >
    <ng-template #labelSlot>
      <span
        [innerHTML]="
          t('register.terms_conditions_agree_text', {
            agency: agencyName,
            termsAndConditionLink: termsAndConditionLink
              ? '<a target=&quot;_blank&quot; href=&quot;' +
                termsAndConditionLink +
                '&quot; class=&quot;text-main underline&quot;>' +
                t('register.condition_of_use') +
                '</a>'
              : '<span>' + t('register.condition_of_use') + '</span>',
          })
        "
      >
      </span>
    </ng-template>
  </ICheckbox>
  <IButton
    class="mt-10"
    type="submit"
    size="lg"
    [label]="t('register.register_button')"
    [block]="true"
    [disabled]="registerForm.invalid"
    [loading]="loading()"
  />
</form>
