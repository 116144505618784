// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    AsinType,
    EligibilityStatus,
    Marketplace,
} from './';

/**
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * @type {string}
     * @memberof Product
     */
    asin?: string;
    /**
     * @type {Marketplace}
     * @memberof Product
     */
    marketplace?: Marketplace;
    /**
     * @type {boolean}
     * @memberof Product
     */
    invalid?: boolean;
    /**
     * Indicates whether the buybox offer is eligible to Amazon Prime. Undefined if nobody has the buybox
     * @type {boolean}
     * @memberof Product
     */
    isPrime?: boolean;
    /**
     * @type {boolean}
     * @memberof Product
     */
    hasCoupon?: boolean;
    /**
     * @type {AsinType}
     * @memberof Product
     */
    asinType?: AsinType;
    /**
     * @type {string}
     * @memberof Product
     */
    title?: string;
    /**
     * @type {Array<string>}
     * @memberof Product
     */
    imageIds?: Array<string>;
    /**
     * @type {Array<string>}
     * @memberof Product
     */
    childAsins?: Array<string>;
    /**
     * @type {Array<string>}
     * @memberof Product
     */
    parentAsins?: Array<string>;
    /**
     * @type {string}
     * @memberof Product
     */
    categoryId?: string;
    /**
     * @type {string}
     * @memberof Product
     */
    categoryName?: string;
    /**
     * @type {number}
     * @memberof Product
     */
    categoryRank?: number;
    /**
     * @type {string}
     * @memberof Product
     */
    displayGroup?: string;
    /**
     * @type {number}
     * @memberof Product
     */
    displayGroupRank?: number;
    /**
     * The listing price of the buybox winner for new condition including any promotions that apply.
     * @type {number}
     * @memberof Product
     */
    priceInCent?: number;
    /**
     * The list price of the item as suggested by the manufacturer.
     * @type {number}
     * @memberof Product
     */
    basePriceInCent?: number;
    /**
     * The shipping cost of the buybox winner. Not always available.
     * @type {number}
     * @memberof Product
     */
    shippingInCent?: number;
    /**
     * This price is based on competitive prices from other retailers (excluding other Amazon sellers). The offer may be ineligible for the Buy Box if the seller\'s price + shipping (minus Amazon Points) is greater than this competitive price. 
     * @type {number}
     * @memberof Product
     */
    competitivePriceThresholdInCent?: number;
    /**
     * @type {number}
     * @memberof Product
     */
    reviews?: number;
    /**
     * @type {number}
     * @memberof Product
     */
    reviewScoreTenth?: number;
    /**
     * @type {string}
     * @memberof Product
     */
    brand?: string;
    /**
     * SellerId of the buybox offer. Missing if nobody has the buybox
     * @type {string}
     * @memberof Product
     */
    sellerId?: string;
    /**
     * Number of sellers having a valid offer in new condition for this product.
     * @type {number}
     * @memberof Product
     */
    nbSeller?: number;
    /**
     * Indicates whether the buybox offer is fulfilled by Amazon. Missing if nobody has the buybox
     * @type {boolean}
     * @memberof Product
     */
    isFba?: boolean;
    /**
     * @type {number}
     * @memberof Product
     */
    timestamp?: number;
    /**
     * @type {number}
     * @memberof Product
     */
    nbSku?: number;
    /**
     * @type {EligibilityStatus}
     * @memberof Product
     */
    spEligibility?: EligibilityStatus;
    /**
     * @type {string}
     * @memberof Product
     */
    spReason?: string;
    /**
     * @type {EligibilityStatus}
     * @memberof Product
     */
    sbEligibility?: EligibilityStatus;
    /**
     * @type {string}
     * @memberof Product
     */
    sbReason?: string;
    /**
     * @type {number}
     * @memberof Product
     */
    fulfillableQuantity?: number;
    /**
     * @type {number}
     * @memberof Product
     */
    unsellableQuantity?: number;
    /**
     * @type {number}
     * @memberof Product
     */
    reservedQuantity?: number;
    /**
     * @type {number}
     * @memberof Product
     */
    inboundQuantity?: number;
    /**
     * @type {number}
     * @memberof Product
     */
    fbmStock?: number;
}
