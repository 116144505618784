import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Intensity, StrategyTactic } from "@front/m19-api-client";

@Pipe({ standalone: true, name: "safe" })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({ name: "tacticIntensityPipe" })
export class TacticIntensityPipe implements PipeTransform {
  transform(tactic: StrategyTactic): number {
    switch (tactic.intensity) {
      case Intensity.LOW:
        return 1;
      case Intensity.MEDIUM_LOW:
        return 2;
      case Intensity.NEUTRAL:
        return 3;
      case Intensity.MEDIUM_HIGH:
        return 4;
      case Intensity.HIGH:
        return 5;
      default:
        return 3; // Neutral by default
    }
  }
}
