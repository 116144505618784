import { ChartDataset, ChartOptions, ChartTypeRegistry } from "chart.js";
import moment from "moment-timezone";
import { PALETTE } from "./Metric";
// This import is required to get linear timscale
import "chartjs-adapter-luxon";
import { BaseChartDirective } from "ng2-charts";
import { Marketplace } from "@front/m19-api-client";
import { DateAggregation, Utils } from "@front/m19-utils";
import { Marketplaces } from "@front/m19-models";
import { RankOption } from "@front/m19-services";

interface Point {
  x: number;
  y: number;
}

export class SimpleDataset {
  static hovering = false;
  static singleClick = false;

  private readonly aspectRatio: number;
  private readonly palette: string[];
  private readonly displayLegend: boolean;
  public labels: string[] = [];
  public chartDataSet: ChartDataset[] = [];
  public lineChartOptions: ChartOptions = {};
  public title = "";
  public titleColor: string;
  public rightTitle: string;
  public rightTitleColor: string;
  public reverseYAxis: boolean;
  public reverseYAxisRight: boolean;
  public marketplace: Marketplace = Marketplace.US;
  public timeZone = "";

  constructor(displayLegend = false, aspectRatio = 2, palette: string[] = PALETTE) {
    this.aspectRatio = aspectRatio;
    this.palette = palette;
    this.displayLegend = displayLegend;
    this.lineChartOptions = this.buildLineChartBaseOptions();
    this.chartDataSet = [{ data: [], fill: false }];
  }

  public buildDataSet(
    title: string,
    yAxisData: number | Point[][],
    xAxisData: string[],
    legend: string[],
    legendFilter: Map<string, boolean>,
    reverseYAxis = false,
    xAxisTimeSeries?: boolean,
  ): void {
    this.title = title;
    this.reverseYAxis = reverseYAxis;
    this.lineChartOptions = this.buildLineChartBaseOptions(xAxisTimeSeries);
    this.labels = xAxisData;
    this.chartDataSet = [];

    for (let i = 0; i < legend.length; i++) {
      if (legendFilter.get(legend[i]) === false) continue;
      this.chartDataSet.push({
        data: yAxisData[i],
        backgroundColor: this.palette[i],
        fill: false,
        label: legend[i],
        tension: 0.3,
        borderColor: this.palette[i],
        pointBackgroundColor: this.palette[i],
      });
    }
  }

  private buildLineChartBaseOptions(
    xAxisTimeSeries?: boolean,
    dateAggregation?: DateAggregation,
    displayRightAxes?: boolean,
  ): ChartOptions {
    const marketPlace = this.marketplace;
    return {
      responsive: true,
      aspectRatio: this.aspectRatio,
      scales: {
        xAxis: xAxisTimeSeries
          ? {
              type: "time",
              time: {
                unit: dateAggregation === DateAggregation.weekly ? "week" : "day",
              },
              ticks: {
                source: "auto",
                callback: (label, index, ticks) => {
                  return moment(ticks[index].value).tz(Marketplaces[marketPlace].timeZone).format("MMM D");
                },
              },
              adapters: {
                date: {
                  setZone: true,
                  zone: Marketplaces[marketPlace].timeZone,
                },
              },
            }
          : {
              grid: {
                display: false,
              },
            },
        yAxis: {
          title: {
            display: true,
            text: this.title,
            color: PALETTE[0],
            font: { size: 14, weight: "bold" },
          },
          reverse: this.reverseYAxis,
          position: "left",
          ticks: {
            precision: 0,
          },
        },
        yAxisRight: displayRightAxes
          ? {
              title: {
                display: true,
                text: "Category BSR",
                color: PALETTE[0],
                font: { size: 14, weight: "bold" },
              },
              reverse: this.reverseYAxis,
              position: "right",
            }
          : {
              display: false,
            },
      },
      hover: {
        mode: "index",
      },
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          display: this.displayLegend,
        },
        tooltip: {
          mode: "index",
          intersect: false,
          displayColors: true,
        },
      },
    };
  }

  private buildLineChartTrackingOptions(
    xAxisTimeSeries?: boolean,
    dateAggregation?: DateAggregation,
    displayRightAxes?: boolean,
    leftAxisPrecision?: number,
    rightAxisPrecision?: number,
  ): ChartOptions {
    const marketPlace = this.marketplace;

    const ClickHandler = function (e, legendItem, legend) {
      const index = legendItem.datasetIndex;

      const ci = legend.chart;
      if (index > 1) {
        // click on price or review so hide all datasets
        for (let i = 0; i < ci.data.datasets.length; i++) {
          if (index !== i) ci.getDatasetMeta(i).hidden = true;
          else ci.getDatasetMeta(i).hidden = false;
        }
      } else {
        // click on BSR
        for (let i = 0; i < ci.data.datasets.length; i++) {
          if (i < 2) ci.getDatasetMeta(i).hidden = false;
          else ci.getDatasetMeta(i).hidden = true;
        }
      }
      ci.update();
    };

    return {
      responsive: true,
      aspectRatio: this.aspectRatio,
      scales: {
        xAxis: xAxisTimeSeries
          ? {
              type: "time",
              time: {
                unit: dateAggregation === DateAggregation.weekly ? "week" : "day",
              },
              ticks: {
                source: "auto",
                callback: (label, index, ticks) => {
                  return moment(ticks[index].value).tz(Marketplaces[marketPlace].timeZone).format("MMM D");
                },
              },
              adapters: {
                date: {
                  setZone: true,
                  zone: Marketplaces[marketPlace].timeZone,
                },
              },
            }
          : {
              grid: {
                display: false,
              },
            },
        yAxis: {
          title: {
            display: true,
            text: this.title,
            color: this.titleColor ?? PALETTE[0],
            font: { size: 14, weight: "bold" },
          },
          reverse: this.reverseYAxis,
          position: "left",
          ticks: {
            precision: leftAxisPrecision,
          },
        },
        yAxisRight: displayRightAxes
          ? {
              title: {
                display: true,
                text: this.rightTitle,
                color: this.rightTitleColor ?? PALETTE[1],
                font: { size: 14, weight: "bold" },
              },
              reverse: this.reverseYAxisRight,
              position: "right",
              ticks: {
                precision: rightAxisPrecision,
              },
            }
          : {
              display: false,
            },
      },
      hover: {
        mode: "index",
      },
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          display: this.displayLegend,
          onClick: ClickHandler,
        },
        tooltip: {
          mode: "index",
          intersect: false,
          displayColors: true,
        },
      },
    };
  }

  public buildProductTimelineDataSet(
    title: string,
    yAxisData: Point[][],
    legend: string[],
    legendFilter: Map<string, boolean>,
    reverseYAxis = false,
    xAxisTimeSeries = true,
    dateAggregation = DateAggregation.hourly,
    rightTitle?: string,
    reverseYAxisRight?: boolean,
    leftAxisPrecision?: number,
    rightAxisPrecision?: number,
  ) {
    this.reverseYAxisRight = reverseYAxisRight;
    const aggData = this.aggregateData(title, yAxisData, reverseYAxis, dateAggregation, false);
    this.rightTitle = rightTitle ?? "";
    for (let i = 0; i < legend.length; i++) {
      if (legendFilter.get(legend[i]) === false) continue;
      if (title == legend[i]) {
        this.titleColor = this.palette[i];
      }
      if (rightTitle == legend[i]) {
        this.rightTitleColor = this.palette[i];
      }
    }
    this.lineChartOptions = this.buildLineChartTrackingOptions(
      xAxisTimeSeries,
      dateAggregation,
      true,
      leftAxisPrecision,
      rightAxisPrecision,
    );

    for (let i = 0; i < legend.length; i++) {
      if (legendFilter.get(legend[i]) === false) continue;
      const pos = legend[i] === title ? "yAxis" : "yAxisRight";
      this.chartDataSet.push({
        data: aggData[i],
        backgroundColor: this.palette[i],
        pointBackgroundColor: this.palette[i],
        borderColor: this.palette[i],
        fill: false,
        label: legend[i],
        yAxisID: pos,
        tension: 0,
        stepped: true,
        pointBorderWidth: 0.5,
        pointRadius: 2,
        borderWidth: 1.5,
        spanGaps: false,
        pointStyle: "circle",
      });
    }
  }

  public buildSearchTermRankingDataSet(
    title: string,
    yAxisData: Point[][],
    legend: string[],
    rankOption = RankOption.ORGANIC,
    dateAggregation = DateAggregation.hourly,
  ): void {
    const aggData = this.aggregateData(title, yAxisData, true, dateAggregation);
    this.lineChartOptions = this.buildLineSearchTermRankingChartBaseOptions(rankOption, dateAggregation);

    for (let i = 0; i < legend.length; i++) {
      const color = rankOption === RankOption.BOTH ? this.palette[i % 2] : this.palette[i + 2];
      this.chartDataSet.push({
        data: aggData[i],
        backgroundColor: color,
        pointBackgroundColor: color,
        borderColor: color,
        fill: false,
        label: legend[i],
        tension: 0,
        stepped: true,
        pointBorderWidth: 0.5,
        pointRadius: 2,
        borderWidth: 1.5,
        spanGaps: false,
        pointStyle: "circle",
        hidden: rankOption === RankOption.BOTH ? !(i === 0 || i === 1) : false, // only show the first two one at beginning
      });
    }
  }

  private aggregateData(
    title: string,
    yAxisData: Point[][],
    reverseYAxis: boolean,
    dateAggregation: DateAggregation,
    withGaps = true,
  ) {
    this.title = title;
    this.reverseYAxis = reverseYAxis;

    this.chartDataSet = [];
    const aggData: Point[][] = [];
    const minDate: number[] = [];
    const maxDate: number[] = [];

    yAxisData.forEach((arr) => {
      let min = undefined;
      let max = undefined;
      if (arr)
        for (const x of arr) {
          if (min === undefined || x.x < min) min = x.x;
          if (max === undefined || x.x > max) max = x.x;
        }
      minDate.push(min);
      maxDate.push(max);
    });

    const tz = Marketplaces[this.marketplace].timeZone;
    yAxisData.forEach((originalArr, index) => {
      if (!originalArr) {
        aggData.push([]);
        return;
      }
      const arr: Point[] = [...originalArr.map((p) => ({ ...p }))]; // deep copy to avoid change in place
      switch (dateAggregation) {
        case DateAggregation.hourly:
          arr.forEach((x) => (x.x = Utils.roundTimestampByHour(minDate[index], x.x, tz)));
          break;

        case DateAggregation.daily:
          arr.forEach((x) => (x.x = Utils.roundTimestampByDay(minDate[index], x.x, tz)));
          break;

        case DateAggregation.weekly:
          arr.forEach((x) => (x.x = Utils.roundTimestampByWeek(minDate[index], x.x, tz)));
          break;
      }
      aggData.push(
        Array.from(
          Utils.aggregate(
            arr,
            (p) => p.x.toString(),
            (g) => ({ x: g[0].x, y: Utils.median(g, (p) => p.y) }),
          ).values(),
        ),
      );
    });

    /* if (withGaps) {
      // fill with NaN value to create gap
      if (dateAggregation !== DateAggregation.weekly) {
        for (let i = 0; i < aggData.length; i++) {
          for (let j = 0; j < aggData[i].length - 1; j++) {
            if (aggData[i][j + 1].x - aggData[i][j].x > 86400000) {
              // > 24 h in ms
              aggData[i].splice(j + 1, 0, { y: null, x: null });
              j++;
            }
          }
        }
      }
    }*/
    return aggData;
  }

  private buildLineSearchTermRankingChartBaseOptions(
    rankOption?: RankOption,
    dateAggregation?: DateAggregation,
  ): ChartOptions {
    const marketPlace = this.marketplace;
    return {
      responsive: true,
      aspectRatio: this.aspectRatio,
      scales: {
        xAxis: {
          type: "time",
          time: {
            unit: dateAggregation === DateAggregation.weekly ? "week" : "day",
          },
          ticks: {
            source: "auto",
            callback: (label, index, ticks) => {
              return moment(ticks[index].value).tz(Marketplaces[marketPlace].timeZone).format("MMM D");
            },
          },
          adapters: {
            date: {
              setZone: true,
              zone: Marketplaces[marketPlace].timeZone,
            },
          },
        },
        yAxis: {
          title: {
            display: true,
            text: this.title,
            color: PALETTE[0],
            font: { size: 14, weight: "bold" },
          },
          reverse: this.reverseYAxis,
          ticks: {
            stepSize: 1,
          },
          min: 0,
        },
      },
      interaction: {
        mode: "nearest",
        axis: "x",
        intersect: false,
      },
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          display: false,
          position: "bottom",
          labels: {
            sort: function (a, b, chart) {
              return a.text.localeCompare(b.text);
            },
          },
        },
        tooltip: {
          displayColors: true,
          callbacks: {
            title: function (context) {
              return moment
                .utc((context[0].raw as any).x)
                .tz(Marketplaces[marketPlace].timeZone)
                .format(dateAggregation === DateAggregation.hourly ? "lll" : "ll");
            },
          },
        },
      },
    };
  }

  public highlightDataset(key: string, chart: BaseChartDirective) {
    const index = this.chartDataSet.map((d) => d.label).indexOf(key);

    for (let i = 0; i < this.chartDataSet.length; i++) {
      const dataset = this.chartDataSet[i] as ChartTypeRegistry["line"]["datasetOptions"];

      if (i === index) {
        dataset.borderWidth = 3;
        dataset.pointRadius = 2.5;
      } else {
        dataset.borderWidth = 0.5;
        dataset.pointRadius = 1.5;
        // add alpha channel to colors
        dataset.borderColor += "80";
        dataset.backgroundColor += "80";
        dataset.pointBackgroundColor += "80";
      }
    }
    chart?.update();
  }

  public resetDatasetColors(chart: BaseChartDirective) {
    for (let i = 0; i < this.chartDataSet.length; i++) {
      const dataset = this.chartDataSet[i] as ChartTypeRegistry["line"]["datasetOptions"];

      dataset.borderWidth = 1.5;
      dataset.pointRadius = 2;
      // remove alpha channel from colors
      dataset.borderColor = PALETTE[i + 2];
      dataset.backgroundColor = PALETTE[i + 2];
      dataset.pointBackgroundColor = PALETTE[i + 2];
    }
    chart?.update();
  }
}
