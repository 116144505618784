import { AgGridAngular } from "@ag-grid-community/angular";
import { ColDef, GridApi, GridOptions } from "@ag-grid-community/core";
import { AsyncPipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import {
  AccountMarketplace,
  MatchType,
  ProductTrackerConfig,
  Strategy,
  StrategyStateEnum,
} from "@front/m19-api-client";
import { Catalog, SegmentConfigType, SegmentEx } from "@front/m19-models";
import {
  AccountSelectionService,
  AsinService,
  KeywordTrackingService,
  SegmentService,
  StrategyService,
} from "@front/m19-services";
import { ConfirmPopupComponent, IButtonComponent, ModalService } from "@front/m19-ui";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import {
  ActionButton,
  ActionButtonsComponent,
} from "@m19-board/insights/advertising-stats/action-buttons/action-buttons.component";
import { KeywordTrackerPageQueryParams } from "@m19-board/keyword-tracker/keyword-tracker-page.component";
import { StatsOverlayComponent } from "@m19-board/overlay/stats-overlay.component";
import { CsvExportService, fieldExtractor, simpleField } from "@m19-board/services/csv-export.service";
import { KeywordTrackerService } from "@m19-board/services/keyword-tracker.service";
import { InputModalComponent } from "@m19-board/shared/input-modal/input-modal.component";
import { ExportButtonComponent } from "@m19-board/shared/ui/export-buttons/export-button.component";
import { keywordRankingAvailableFor } from "@m19-board/tracking/KeywordRankingAvailability";
import { ICON_ADD, ICON_EDIT_O, ICON_TRASH_O } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, forkJoin, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { ProductTrackerPageQueryParams } from "../product-tracker/product-tracker-views/product-tracker.component";
import { KeywordSegmentModalComponent } from "./keyword-segment-modal.component";
import { ProductSegmentModalComponent } from "./product-segment-modal.component";
import { SegmentDetailStrategiesModalComponent } from "./segment-detail/segment-detail-strategies-modal.component";

@UntilDestroy()
@Component({
  selector: "app-segment-manager",
  templateUrl: "./segment-manager.component.html",
  standalone: true,
  imports: [
    TranslocoDirective,
    AsyncPipe,
    StatsOverlayComponent,
    IButtonComponent,
    ExportButtonComponent,
    AgGridAngular,
  ],
})
export class SegmentsComponent implements OnInit {
  segments: SegmentEx[] = [];
  accountMarketplace?: AccountMarketplace;
  strategiesBySegmentId: Map<number, Strategy[]> = new Map();
  segmentType$ = new BehaviorSubject(SegmentConfigType.KeywordSegment);
  isReadOnly = false;
  catalog?: Catalog;
  productTrackerConfig?: ProductTrackerConfig[];
  readonly SegmentType = SegmentConfigType;
  readonly ICON_ADD = ICON_ADD;
  readonly ICON_TRASH_O = ICON_TRASH_O;

  gridApi?: GridApi;
  selectedRows: SegmentEx[] = [];

  gridOptions: GridOptions = {
    pagination: true,
    paginationPageSize: 20,
    sideBar: false,
    rowSelection: "multiple",
    suppressRowClickSelection: true,
    onSelectionChanged: (event: any) => {
      this.selectedRows = event.api.getSelectedRows();
    },
    doesExternalFilterPass: (node: any) => {
      const segment: SegmentEx = node.data;
      return segment.segmentType == this.segmentType$.value;
    },
    isExternalFilterPresent: () => true,
  };

  colDef: ColDef[] = [
    {
      field: "name",
      flex: 1.7,
      headerValueGetter: (params) => this.translocoService.translate("segment-manager.segment_name"),
      cellRendererSelector: (params: any) => {
        return {
          component: IButtonComponent,
          params: {
            label: params.value,
            variant: "ghost",
            color: "gray",
            trailing: true,
            tooltipValue: this.translocoService.translate("segment-manager.change_segment_name"),
            icon: ICON_EDIT_O,
            disbaled: this.isReadOnly,
            iconOnHover: true,
            clickAction: () => {
              this.editSegmentName(params.data);
            },
          },
        };
      },
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      resizable: false,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sort: "asc",
    },
    {
      field: "strategies",
      flex: 1,
      cellStyle: { display: "flex", alignItems: "center" },
      headerValueGetter: (params) => this.translocoService.translate("common.strategies"),
      valueGetter: (params: any) => {
        return this.getStrategyCount(params.data);
      },
      comparator: (a: { active: number; paused: number }, b: { active: number; paused: number }) => {
        return a.active + a.paused - (b.active + b.paused);
      },
      valueFormatter: (params: any) => {
        const { active, paused } = params.value;
        const res = [];
        if (active > 0) {
          res.push(`${active} active`);
        }
        if (paused > 0) {
          res.push(`${paused} paused`);
        }
        return res.join(" | ");
      },
      cellRendererSelector: (params: any) => {
        const { active, paused } = params.value;
        const labels = [];
        let label = "-";
        if (active > 0) {
          labels.push(`${active} active`);
        }
        if (paused > 0) {
          labels.push(`${paused} paused`);
        }
        if (labels.length > 0) {
          label = labels.join(" | ");
        }
        return {
          component: IButtonComponent,
          params: {
            label,
            variant: "ghost",
            color: "gray",
            trailing: true,
            disbaled: this.isReadOnly,
            iconOnHover: true,
            clickAction: () => {
              this.modalServiceV2.openModal(SegmentDetailStrategiesModalComponent, {
                modalTitle: `${params.data.name} Strategies`,
                data: {
                  segment: params.data,
                  linkedStrategies: this.strategiesBySegmentId.get(params.data.segmentId) ?? [],
                },
              });
            },
          },
        };
      },
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      // greater or equal default filter
      filterParams: {
        defaultOption: "greaterThanOrEqual",
      },
    },
    {
      field: "products",
      flex: 1,
      headerValueGetter: (params) =>
        this.segmentType$.value === SegmentConfigType.KeywordSegment
          ? this.translocoService.translate("common.keywords")
          : this.translocoService.translate("common.products"),
      valueGetter: (params: any) => {
        return params.data.items.length;
      },
      valueFormatter: (params: any) => {
        if (this.segmentType$.value == SegmentConfigType.ProductSegment) {
          return params.value;
        }
        const segment: SegmentEx = params.data;
        return `${segment.items.filter((i) => i.matchType == MatchType.exact).length} exact | ${segment.items.filter((i) => i.matchType == MatchType.phrase).length} phrase`;
      },
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      // greater or equal default filter
      filterParams: {
        defaultOption: "greaterThanOrEqual",
      },
    },
    {
      flex: 0.2,
      // cell style to push the button to the right of the cell
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      cellRendererSelector: (params: any) => {
        const segment: SegmentEx = params.data;
        const actionButtons: ActionButton[] = [];
        if (this.canSegmentBeDeleted(segment)) {
          actionButtons.push({
            title: "Remove",
            icon: ICON_TRASH_O,
            tooltip: this.translocoService.translate("segment-manager.remove_segment_tt", [segment.name]),
            onClick: () => {
              this.deleteSegments([segment]);
            },
            disabled: this.isReadOnly || !this.canSegmentBeDeleted(segment),
          });
        }
        actionButtons.push({
          title: "Edit",
          icon: ICON_EDIT_O,
          tooltip: this.translocoService.translate("segment-manager.edit_segment_tt", [segment.name]),
          onClick: () => {
            this.editSegment(segment);
          },
          disabled: this.isReadOnly,
        });
        const subItems: ActionButton[] = [];
        if (this.segmentType$.value == SegmentConfigType.KeywordSegment) {
          subItems.push({
            title: this.translocoService.translate("segment-manager.track_keywords"),
            onClick: () => {
              this.trackKeywords(segment);
            },
          });
        } else if (this.segmentType$.value == SegmentConfigType.ProductSegment) {
          subItems.push({
            title: this.translocoService.translate("segment-manager.track_products"),
            onClick: () => {
              this.trackProducts(segment);
            },
          });
        }
        subItems.push({
          title: this.translocoService.translate("segment-manager.view_traffic_stats"),
          onClick: () => {
            this.viewTrafficAnalysis(segment);
          },
        });

        subItems.push({
          title: this.translocoService.translate("segment-manager.see_in_product_tracker"),
          onClick: () => {
            this.viewProductTracker(segment);
          },
        });
        actionButtons.push({
          icon: "icon-[mdi--ellipsis-vertical]",
          tooltip: this.translocoService.translate("segment-manager.more"),
          subItems: subItems,
        });
        return {
          component: ActionButtonsComponent,
          params: {
            actionButtons,
          },
        };
      },
      resizable: false,
    },
  ];

  constructor(
    private accountSelection: AccountSelectionService,
    private segmentService: SegmentService,
    private asinService: AsinService,
    private keywordTrackingService: KeywordTrackingService,
    private modalService: BsModalService,
    private modalServiceV2: ModalService,
    private router: Router,
    private toastrService: ToastrService,
    private keywordTrackerService: KeywordTrackerService,
    private csvExportService: CsvExportService,
    private translocoService: TranslocoService,
    private strategyService: StrategyService,
  ) {}

  ngOnInit(): void {
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        tap((am) => {
          this.accountMarketplace = am;
        }),
        switchMap((am) =>
          combineLatest([
            this.segmentService.getSegments(am.accountId, am.marketplace),
            this.strategyService.getStrategyIndex(am.accountId, am.marketplace),
          ]),
        ),
      )
      .subscribe(([segments, strategies]) => {
        this.segments = Array.from(segments.values());
        this.strategiesBySegmentId = new Map();
        for (const s of strategies.values()) {
          for (const t of s.tactics) {
            if (!this.strategiesBySegmentId.has(t.segmentId)) {
              this.strategiesBySegmentId.set(t.segmentId, []);
            }
            this.strategiesBySegmentId.get(t.segmentId)!.push(s);
          }
        }
      });
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) => this.asinService.getCatalog(am.accountId, am.marketplace)),
      )
      .subscribe((c) => {
        this.catalog = c;
      });
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) => this.keywordTrackingService.getProductTrackerConfig(am.accountId, am.marketplace)),
      )
      .subscribe((c) => {
        this.productTrackerConfig = c;
      });

    this.accountSelection.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));

    if (this.router.url.startsWith("/keyword-center/segments/keyword-segment")) {
      this.segmentType$.next(SegmentConfigType.KeywordSegment);
    } else {
      this.segmentType$.next(SegmentConfigType.ProductSegment);
    }
  }

  toggleSegmentType(segmentType: SegmentConfigType) {
    if (this.segmentType$.value == segmentType) {
      return;
    }
    this.segmentType$.next(segmentType);
    if (segmentType == SegmentConfigType.ProductSegment) {
      this.router.navigate(["/keyword-center/segments/product-segments"]);
    } else {
      this.router.navigate(["/keyword-center/segments/keyword-segments"]);
    }
  }

  downloadCsv() {
    this.csvExportService.exportCsv(
      {
        prefix: `segments_list`,
        marketplace: this.accountMarketplace!.marketplace,
        accountGroupName: this.accountMarketplace!.accountGroupName,
      },
      this.segments,
      [
        simpleField("segmentId"),
        simpleField("name"),
        fieldExtractor("strategies", (s) => {
          const { active, paused } = this.getStrategyCount(s);
          return `${active} active | ${paused} paused`;
        }),
        simpleField("segmentType"),
        fieldExtractor("products", (s) =>
          s.items
            .filter((i) => i.matchType == MatchType.asinSameAs)
            .map((i) => i.targetingValue)
            .join(";"),
        ),
        fieldExtractor("exactKeywords", (s) =>
          s.items
            .filter((i) => i.matchType == MatchType.exact)
            .map((i) => i.targetingValue)
            .join(";"),
        ),
        fieldExtractor("phraseKeywords", (s) =>
          s.items
            .filter((i) => i.matchType == MatchType.phrase)
            .map((i) => i.targetingValue)
            .join(";"),
        ),
      ],
    );
  }

  createNewSegment() {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.accountMarketplace!.accountId,
        marketplace: this.accountMarketplace!.marketplace,
      },
      class: "modal-xl",
    };
    if (this.segmentType$.value == SegmentConfigType.ProductSegment) {
      this.modalService.show(ProductSegmentModalComponent, modalOptions);
    } else if (this.segmentType$.value == SegmentConfigType.KeywordSegment) {
      this.modalService.show(KeywordSegmentModalComponent, modalOptions);
    }
  }

  canSegmentBeDeleted(segment: SegmentEx) {
    // segment can be deleted when there are no strategy using it
    return (
      !this.strategiesBySegmentId.has(segment.segmentId) ||
      this.strategiesBySegmentId.get(segment.segmentId)!.length == 0
    );
  }

  deleteSegments(segments: SegmentEx[]) {
    const ref = this.modalServiceV2.openModal(ConfirmPopupComponent, {
      modalTitle: this.translocoService.translate("segment-manager.confirm_segment_s_deletion"),
      data: {
        message: this.translocoService.translate(
          "segment-manager.are_you_sure_you_want_to_delete_the_selected_segments",
          { count: segments.length },
        ),
        confirmLabel: this.translocoService.translate("common.delete"),
      },
    });

    ref.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        const toDelete: number[] = [];
        for (const s of segments) {
          if (!this.canSegmentBeDeleted(s)) {
            this.toastrService.warning(
              this.translocoService.translate("segment-manager.segment_used_canot_delete", [s.name]),
              this.translocoService.translate("activity-service.segment_deletion"),
            );
            continue;
          }
          toDelete.push(s.segmentId);
        }
        if (toDelete.length > 0) {
          forkJoin(
            toDelete.map((segmentId) =>
              this.segmentService.deleteSegment(
                this.accountMarketplace!.accountId,
                this.accountMarketplace!.marketplace,
                segmentId,
              ),
            ),
          ).subscribe({
            next: () => {
              this.toastrService.success(
                this.translocoService.translate("segment-manager.segments_successfully_deleted"),
                this.translocoService.translate("segment-manager.segment_deleted"),
              );
            },
            error: (error: string) => {
              this.toastrService.error(
                this.translocoService.translate("segment-manager.error_deleting_segment_error", [error]),
                this.translocoService.translate("segment-manager.segment_deletion_error"),
              );
            },
          });
        }
      }
    });
  }

  editSegment(segment: SegmentEx) {
    const segmentType = segment.segmentType;
    if (segmentType == SegmentConfigType.KeywordSegment) {
      const modalOptions: ModalOptions = {
        initialState: {
          segment: segment,
          isReadOnly: this.isReadOnly,
        },
        class: "modal-xl",
      };
      this.modalService.show(KeywordSegmentModalComponent, modalOptions);
    } else if (segmentType == SegmentConfigType.ProductSegment) {
      const modalOptions: ModalOptions = {
        initialState: {
          segment: segment,
          isReadOnly: this.isReadOnly,
        },
        class: "modal-xl",
      };
      this.modalService.show(ProductSegmentModalComponent, modalOptions);
    }
  }

  showActiveStrategies(segment: SegmentEx) {
    const modalOptions: ModalOptions = {
      initialState: {
        segment: segment,
        linkedStrategies: this.strategiesBySegmentId.get(segment.segmentId) ?? [],
      },
      class: "modal-lg modal-dialog modal-dialog-centered",
    };
    this.modalService.show(SegmentDetailStrategiesModalComponent, modalOptions);
  }

  getStrategyCount(segment: SegmentEx): { active: number; paused: number } {
    return (this.strategiesBySegmentId.get(segment.segmentId) ?? []).reduce(
      (acc, s) => {
        if (s.state == StrategyStateEnum.ENABLED) {
          acc.active++;
        } else {
          acc.paused++;
        }
        return acc;
      },
      { active: 0, paused: 0 },
    );
  }

  viewKeywordTracker(segment: SegmentEx) {
    this.router.navigate(["/keyword-center/keyword-tracker"], {
      queryParams: { [KeywordTrackerPageQueryParams.segment]: segment.segmentId },
    });
  }

  viewProductTracker(segment: SegmentEx) {
    this.router.navigate(["/product-center/product-tracker"], {
      queryParams: { [ProductTrackerPageQueryParams.segment]: segment.segmentId },
    });
  }

  viewTrafficAnalysis(segment: SegmentEx) {
    this.router.navigate(["keyword-center/traffic-analysis"], {
      queryParams: { selectedSegment: segment.segmentId },
    });
  }

  canAccessKeywordRanking() {
    return keywordRankingAvailableFor(this.accountMarketplace!.marketplace);
  }

  editSegmentName(segment: SegmentEx) {
    const input = new FormControl<string>(segment.name);
    const ref = this.modalService.show(InputModalComponent, {
      initialState: {
        title: this.translocoService.translate("segment-manager.change_segment_name"),
        inputControl: input,
        maxLength: 80,
      },
      class: "modal-primary",
    });
    ref.content?.emitUpdate
      .pipe(
        untilDestroyed(this),
        switchMap(() => {
          const newName = input.value;
          if (!newName || newName.trim() === "") {
            return of(undefined);
          }
          return this.segmentService.updateSegmentName(
            this.accountMarketplace!.accountId,
            this.accountMarketplace!.marketplace,
            segment.segmentId,
            newName,
          );
        }),
      )
      .subscribe((segment) => {
        if (segment === undefined) {
          this.toastrService.error(
            this.translocoService.translate("segment-manager.segment_name_cannot_be_empty"),
            this.translocoService.translate("common.error"),
          );
          return;
        }
        this.toastrService.success(this.translocoService.translate("segment-manager.segment_name_updated"));
      });
  }

  trackProducts(segment: SegmentEx) {
    if (this.segmentType$.value != SegmentConfigType.ProductSegment) {
      return;
    }

    const ref = this.modalServiceV2.openModal(ConfirmPopupComponent, {
      modalTitle: "Track products",
      data: {
        message: "Are you sure you want to track these products?",
        confimLabel: `Track ${segment.items.length} product${segment.items.length > 1 ? "s" : ""}`,
      },
    });

    ref
      .afterClosed()
      .pipe(
        switchMap(() => {
          const newConfig: ProductTrackerConfig[] = [
            ...this.productTrackerConfig!,
            ...segment.items.map((i) => ({
              accountId: segment.accountId,
              marketplace: segment.marketplace,
              asin: i.targetingValue,
            })),
          ];
          return this.keywordTrackingService.addProductTrackerConfig(segment.accountId, segment.marketplace, newConfig);
        }),
      )
      .subscribe((config) => {
        this.productTrackerConfig = config;
        this.toastrService.success(
          this.translocoService.translate("segment-manager.started_tracking_n_new_product", [segment.items.length]),
          this.translocoService.translate("segment-manager.new_product_tracked"),
        );
      });
  }

  trackKeywords(segment: SegmentEx) {
    if (this.segmentType$.value != SegmentConfigType.KeywordSegment) {
      return;
    }
    this.keywordTrackerService.openAddKwTrackingModal(
      segment.accountId,
      segment.marketplace,
      this.accountMarketplace!.resourceOrganizationId!,
      segment.items.map((i) => i.targetingValue),
    );
  }

  onGridReady(event: any) {
    this.gridApi = event.api;
  }
}
