@if (!edit) {
  <span class="flex items-center" *transloco="let t">
    <IButton
      [label]="value"
      variant="ghost"
      size="lg"
      (onClick)="toggleEditMode()"
      [tooltipValue]="disable ? '' : tooltipText"
      [icon]="disable ? undefined : ICON_EDIT_O"
    />
  </span>
} @else {
  <span class="flex gap-x-2" *transloco="let t">
    <input
      class="simple-input-append form-control input-name edit-name"
      type="text"
      maxlength="200"
      placeholder="{{ placeholder }}"
      [value]="value"
      (keydown.enter)="updateValue(newName.value)"
      (keydown.escape)="toggleEditMode()"
      #newName
    />
    <IButton
      [tooltipValue]="tooltipText"
      (onClick)="updateValue(newName.value)"
      [disabled]="!newName.value"
      [icon]="ICON_CHECK"
    />
    <IButton
      tooltipValue="{{ t('common.cancel') }}"
      (onClick)="toggleEditMode()"
      [disabled]="!newName.value"
      [icon]="ICON_CLOSE"
      color="red"
    />
  </span>
}
