// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Plan {
    LEGACY = 'LEGACY',
    STARTER = 'STARTER',
    GROWTH = 'GROWTH',
    PROFESSIONAL = 'PROFESSIONAL',
    ENTERPRISE = 'ENTERPRISE',
    AGENCY = 'AGENCY',
    WHITELABEL = 'WHITELABEL',
    YEARLY = 'YEARLY',
    TEST = 'TEST',
    MANUAL = 'MANUAL',
    SELF_SERVICE = 'SELF_SERVICE',
    AUTOPILOT = 'AUTOPILOT'
}

