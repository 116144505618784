import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ICON_CHECK, ICON_CLOSE } from "@m19-board/utils/iconsLabels";
import { TooltipPosition } from "@angular/material/tooltip";
import { TranslocoService } from "@jsverse/transloco";

@UntilDestroy()
@Component({
  selector: "app-editable-title",
  templateUrl: "./editable-title.component.html",
  styleUrls: ["./editable-title.component.scss"],
})
export class EditableTitleComponent implements AfterViewInit {
  @Input()
  value: string;
  @Input()
  tooltipText = this.translocoService.translate("editable-title.default_tooltip");
  @Input()
  tooltipPosition: TooltipPosition = "above";
  @Input()
  disable = false;
  @Input()
  placeholder = this.translocoService.translate("editable-title.default_placeholder");
  @Output()
  valueChange = new EventEmitter<string>();

  @ViewChildren("newName") newNameInputField: QueryList<ElementRef<HTMLInputElement>>;

  edit = false;
  readonly faPencil = faPencilAlt;

  readonly ICON_CHECK = ICON_CHECK;
  readonly ICON_CLOSE = ICON_CLOSE;

  constructor(private translocoService: TranslocoService) {}

  ngAfterViewInit(): void {
    this.newNameInputField.changes
      .pipe(untilDestroyed(this))
      .subscribe((el: QueryList<ElementRef<HTMLInputElement>>) => {
        if (this.edit && el.length > 0) {
          el.first.nativeElement.focus();
        }
      });
  }

  updateValue(newValue: string) {
    this.valueChange.emit(newValue);
    this.edit = false;
  }

  toggleEditMode() {
    if (this.disable) {
      return;
    }
    this.edit = !this.edit;
    this.newNameInputField.changes.subscribe;
  }
}
