<ng-container *transloco="let t">
  <div class="modal-header bg-main-500">
    <h4 class="modal-title pull-left">{{ t("vendor-metrics-popup.vendor_metric_selection") }}</h4>
  </div>
  <div class="modal-body">
    <div class="form-group row my-2 items-center">
      <span class="prepend col-6"
        >{{ t("vendor-metrics-popup.select_the_vendor_metrics", [accountMarketplace.accountName]) }}
      </span>
      <div class="col-6">
        <button
          type="submit"
          [class]="accountMarketplace.useSourcingMetrics ? 'btn btn-primary mx-2' : 'btn mx-2'"
          (click)="updateVendorMetrics(true)"
        >
          {{ t("vendor-metrics-popup.sourcing") }}
        </button>
        <button
          type="submit"
          [class]="accountMarketplace.useSourcingMetrics ? 'btn mx-2' : 'btn btn-primary mx-2'"
          (click)="updateVendorMetrics(false)"
        >
          {{ t("vendor-metrics-popup.manufacturing") }}
        </button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" (click)="bsModalRef.hide()">{{ t("common.close") }}</button>
  </div>
</ng-container>
