@if (displayMode === METRIC_EVO_DISPLAY.GRID) {
  <div class="inline-flex items-center justify-center">
    @if (evoValue !== undefined && isFiniteEvo && this.evoChanged) {
      <span
        [matTooltip]="evoTooltip"
        [class]="'text-base ' + evoIcon"
        [ngClass]="{
          'text-green-500': evoColor === 'green',
          'text-red-500': evoColor === 'red',
          'text-gray-500': evoColor === 'gray',
        }"
      >
      </span>
    }
    <span class="ml-2" [matTooltip]="metricTooltip">{{ metricFormatted }}</span>
  </div>
} @else if (evoValue !== undefined && isFiniteEvo && this.evoChanged) {
  <IBadge
    [label]="evoFormatted"
    [icon]="evoIcon"
    [color]="evoColor"
    variant="soft"
    [tooltipValue]="previousMetricFormatted"
  />
}
