// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * flag to activate m19 solution on the account. downloader=ON activates the account on m19 board and the retrival data from Amazon. bidder=ON activates the AI - it can only be activated on accounts with downloader=ON
 * @export
 * @enum {string}
 */
export enum State {
    ON = 'ON',
    OFF = 'OFF'
}

