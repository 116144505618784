import { NgClass } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
  selector: "IMeter",
  standalone: true,
  imports: [NgClass],
  templateUrl: "./imeter.component.html",
})
export class IMeterComponent {
  @Input() label = "";
  @Input() color: "main" | "orange" = "main";
  @Input() description = "";
  @Input() value: number | undefined = 0;
  @Input() max = 10;
  @Input() min = 0;
}
