import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortModule } from "@angular/material/sort";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { IButtonComponent, ICheckboxComponent, ISelectComponent, Option } from "@front/m19-ui";
import { CsvExportService, fieldExtractor, metricField, simpleField } from "@m19-board/services/csv-export.service";
import { ICON_CHART_LINE, ICON_PAUSE, ICON_PLAY, ICON_SEARCH } from "@m19-board/utils/iconsLabels";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { BehaviorSubject, Observable, of } from "rxjs";
import { MetricBucketPipe, MetricRelativeValuePipe, StrategyStats } from "@m19-board/models/Metric";
import { TARGET_ACOS } from "@m19-board/models/MetricsDef";
import { DayPartingPopUpComponent } from "../day-parting-pop-up/day-parting-pop-up.component";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { AdStatsEx, StrategyEx } from "@front/m19-models";
import { AccountMarketplace, CampaignType, Marketplace, StrategyStateEnum } from "@front/m19-api-client";
import { AccountSelectionService, UserSelectionService } from "@front/m19-services";
import { mergeSeveralDates, Utils } from "@front/m19-utils";
import {
  ACOS,
  AD_CONVERSIONS,
  AD_SALES,
  CLICKS,
  CONVERSION_RATE,
  COST,
  CPC,
  Metric,
  MetricFormatPipe,
  MetricType,
  ROAS,
} from "@front/m19-metrics";
import { FormsModule } from "@angular/forms";
import { MatTooltip } from "@angular/material/tooltip";
import { NgClass } from "@angular/common";
import { ExportButtonComponent } from "@m19-board/shared/ui/export-buttons/export-button.component";
import { StrategyLinkComponent } from "@m19-board/strategies/strategy-link/strategy-link.component";

@UntilDestroy()
@Component({
  selector: "app-hourly-table",
  templateUrl: "./hourly-table.component.html",
  styleUrls: ["./hourly-table.component.scss"],
  standalone: true,
  imports: [
    TranslocoDirective,
    FormsModule,
    ISelectComponent,
    ICheckboxComponent,
    MatTooltip,
    NgClass,
    ExportButtonComponent,
    IButtonComponent,
    MatTableModule,
    MatPaginator,
    MatSortModule,
    MetricRelativeValuePipe,
    MetricFormatPipe,
    MetricBucketPipe,
    StrategyLinkComponent,
  ],
})
export class HourlyTableComponent implements OnInit, OnDestroy {
  public readonly MetricType = MetricType;
  readonly ICON_CHART = ICON_CHART_LINE;

  @Input({ required: true }) currency!: string;
  @Input({ required: true }) locale!: string;
  @Input() strategiesMap: Map<number, StrategyEx> = new Map();

  readonly HOURS = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];

  readonly CampaignTypesKeys = {
    [CampaignType.SP]: this.translocoService.translate("v2-sidebar.sponsored_products"),
  };
  readonly DEFAULT_STRATEGY = ["strategyStatus", "strategyName"];

  readonly campaignTypeOptions: Option<CampaignType | null>[] = [
    {
      label: this.translocoService.translate("hourly-table.all_campaign_types"),
      value: null,
    },
    {
      label: this.CampaignTypesKeys[CampaignType.SP],
      value: CampaignType.SP,
    },
  ];
  selectedCampaignType = signal<Option<CampaignType | null>>(this.campaignTypeOptions[0]);

  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<Map<string, StrategyStats>>([]);

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  dataByStrategiesHour: Map<string, StrategyStats>[] = [];
  avgDataByStrategiesHour: Map<string, StrategyStats>[] = [];

  @Input({ required: true }) set statsByHour(data: Map<string, StrategyStats[]>) {
    const tmp: Array<Map<string, StrategyStats>> = [];
    if (data) {
      data.forEach((values, key, map) => {
        tmp.push(new Map(values.map((x) => [x.hour!.toString(), x])));
        tmp[tmp.length - 1].set("total", this.getTotal(values));
      });
    }
    this.dataByStrategiesHour = tmp;
    this.dataSource.data = tmp;
  }

  @Input({ required: true }) set avgByHour(data: Map<string, StrategyStats[]>) {
    const tmp: Array<Map<string, StrategyStats>> = [];
    if (data) {
      data.forEach((values, key, map) => {
        tmp.push(new Map(values.map((x) => [x.hour!.toString(), x])));
        tmp[tmp.length - 1].set("total", this.getTotal(values));
      });
    }
    this.avgDataByStrategiesHour = tmp;
  }

  @Input({ required: true })
  accountMarketplace$!: Observable<AccountMarketplace>;

  accountGroupName: string = "";
  marketplace!: Marketplace;

  @Input({ required: true })
  selectedMetric$!: BehaviorSubject<Metric<AdStatsEx>[]>;

  @Input({ required: true })
  customMetrics$!: BehaviorSubject<Metric<AdStatsEx>[]>;
  customMetrics: Metric<AdStatsEx>[] = [];

  @Input({ required: true })
  chartDisplayed: boolean = false;

  @Output()
  chartDisplayChange = new EventEmitter<boolean>();

  private modal?: BsModalRef;
  smallMode = false;
  showAbsValues = true;
  displayOnlyActiveStrategies = false;
  public filter = "";

  dayPartingHoverHour: number | undefined = undefined;
  dayPartingPauseHour: number | undefined = undefined;
  dayPartingReactivationHour: number | undefined = undefined;
  dayPartingStrategySelected: number | undefined = undefined;
  firstClick: boolean = false;
  clickInside = false;

  init = false;
  isReadOnly = false;

  constructor(
    private userSelectionService: UserSelectionService,
    private modalService: BsModalService,
    private accountSelectionService: AccountSelectionService,
    private csvExportService: CsvExportService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.displayedColumns = this.DEFAULT_STRATEGY.concat(this.HOURS).concat(["total"]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSource.sortingDataAccessor = (item, property): string | number => {
      let stat: Map<string, AdStatsEx> | AdStatsEx = item;
      if (property in this.HOURS) {
        stat = stat.get(property)!;
      } else if (property == "total") {
        stat = stat.get("total")!;
      } else {
        stat = stat.values().next().value;
      }
      if (property == "strategyName") return (stat as StrategyStats).strategyName;
      if (property == "strategyStatus") return (stat as StrategyStats).state!;

      return this.selectedMetric$.value[0] ? this.selectedMetric$.value[0].value(stat as StrategyStats)! : 0;
    };

    // overridding internal function so it triggers even if this.datasource.filter has not changed
    this.dataSource._filterData = (data: Map<string, StrategyStats>[]) => {
      this.dataSource.filteredData = data.filter((obj) => this.dataSource.filterPredicate(obj, this.dataSource.filter));
      return this.dataSource.filteredData;
    };
    this.dataSource.filterPredicate = (row, filter) => {
      const stat = row.values().next().value;
      const regex = new RegExp(filter, "i");
      return (
        (!this.selectedCampaignType().value || stat.campaignType == this.selectedCampaignType().value) &&
        (!this.displayOnlyActiveStrategies || stat.state == StrategyStateEnum.ENABLED) &&
        regex.test(stat.strategyName)
      );
    };

    this.accountMarketplace$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.accountGroupName = am.accountGroupName!;
      this.marketplace = am.marketplace;
    });
    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));

    this.customMetrics$.pipe(untilDestroyed(this)).subscribe((metrics) => {
      this.customMetrics = metrics;
      this.smallMode = this.isSmallMode(window.innerWidth, this.displayedColumns.length);
    });

    this.selectedMetric$.pipe(untilDestroyed(this)).subscribe((x) => {
      if (x.length === 0) this.dataSource.data = [];
      else if (x[0].type === MetricType.RATIO) {
        this.dataSource.data = this.avgDataByStrategiesHour;
      } else {
        this.dataSource.data = this.dataByStrategiesHour;
      }
    });

    this.init = true;
  }

  ngOnDestroy() {
    if (this.modal) this.modal.hide();
  }

  setFilter(filter: string, campaigns: Option<CampaignType | null>, displayActive: boolean) {
    this.filter = filter;
    this.selectedCampaignType.set(campaigns);
    this.displayOnlyActiveStrategies = displayActive;
    this.dataSource.filter = filter;
    this.dataSource._updateChangeSubscription();
  }

  downloadFile() {
    this.csvExportService.exportCsv<StrategyStats>(
      {
        prefix: "strategy_hourly_stats",
        accountGroupName: this.accountGroupName,
        marketplace: this.marketplace,
        dateRange: this.userSelectionService.getDateRangeStr(),
      },
      this.dataSource.data
        .flatMap((x: Map<string, StrategyStats>) => Array.from(x.values()))
        .filter((x) => !!x.accountId),
      [
        fieldExtractor("strategyId", (d) => d.strategyId?.toString() ?? "-"),
        simpleField("strategyName"),
        simpleField("hour"),
        simpleField("currency"),
        metricField(CLICKS),
        metricField(COST),
        metricField(AD_CONVERSIONS),
        metricField(AD_SALES),
        metricField(ACOS),
        metricField(TARGET_ACOS),
        metricField(CONVERSION_RATE),
        metricField(ROAS),
        metricField(CPC),
      ],
    );
  }

  getStripesClass(product: Map<string, StrategyStats>, h: string): string {
    const hour = parseInt(h);
    const stat = product.values().next().value;
    return stat && Utils.isInHourInterval(hour, stat.dayPartingPauseHour, stat.dayPartingReactivationHour)
      ? " stripes"
      : "";
  }

  getSelectedClass(product: Map<string, StrategyStats>, h: string): string {
    const hour = parseInt(h);
    const stat = product.values().next().value;
    return stat &&
      stat.strategyId === this.dayPartingStrategySelected &&
      Utils.isInHourInterval(hour, this.dayPartingPauseHour!, this.dayPartingHoverHour!)
      ? " selected"
      : "";
  }

  getSelectableClass(product: Map<string, StrategyStats>): string {
    const stat = product.values().next().value;
    return stat.strategyId ? " selectable" : "";
  }

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.smallMode = this.isSmallMode(window.innerWidth, this.displayedColumns.length);
  }

  toggleChartDisplay(): void {
    this.chartDisplayChange.emit(!this.chartDisplayed);
  }

  private isSmallMode(innerWidth: number, columnCount: number): boolean {
    return innerWidth / columnCount < 100;
  }

  changeAbsRelValues(): void {
    this.showAbsValues = !this.showAbsValues;
  }

  getTotal(stats: AdStatsEx[]): StrategyStats {
    let res: AdStatsEx = {};
    for (let i = 0; i < stats.length && i < 24; i++) {
      res = mergeSeveralDates(res, stats[i]);
    }
    return res as StrategyStats;
  }

  removePercent(str: string): string {
    if (str.endsWith("%")) return str.substring(0, str.length - 1);
    return str;
  }

  @HostListener("document:keyup.escape", ["$event"])
  @HostListener("document:click", ["$event"])
  clickedOutCell(event: Event) {
    if (!this.clickInside || event.type === "keyup") {
      this.dayPartingPauseHour = undefined;
      this.dayPartingStrategySelected = undefined;
      this.firstClick = true;
    }
    this.clickInside = false;
  }

  hourClicked(product: Map<string, StrategyStats>, h: string): void {
    this.clickInside = true;
    const hour = parseInt(h);
    const strat = product.values().next().value;
    if (!strat.strategyId || !strat.campaignType)
      // we do not want to select non M19 operated campaigns
      return;
    if (
      this.dayPartingStrategySelected === undefined ||
      this.dayPartingStrategySelected !== strat.strategyId ||
      this.firstClick
    ) {
      this.dayPartingPauseHour = hour;
      this.dayPartingStrategySelected = strat.strategyId;
      this.dayPartingReactivationHour = undefined;
      this.firstClick = false;
    } else {
      this.firstClick = true;
      this.dayPartingReactivationHour = hour;

      const modalOptions: ModalOptions = {
        initialState: {
          strategy$: of(this.strategiesMap.get(strat.strategyId)),
          currencyCode: this.currency,
          locale: this.locale,
          dayPartingPauseHour: this.dayPartingPauseHour,
          dayPartingReactivationHour: this.dayPartingReactivationHour + 1,
        },
        class: "modal-lg",
      };
      // back to initial state
      this.dayPartingPauseHour = undefined;
      this.dayPartingReactivationHour = undefined;
      this.dayPartingStrategySelected = undefined;

      this.modalService.show(DayPartingPopUpComponent, modalOptions);
    }
  }

  onHover(h: string): void {
    const hour = parseInt(h);
    this.dayPartingHoverHour = hour;
  }

  onOut(): void {
    this.dayPartingHoverHour = undefined;
  }

  protected readonly ICON_SEARCH = ICON_SEARCH;
  protected readonly ICON_PLAY = ICON_PLAY;
  protected readonly ICON_PAUSE = ICON_PAUSE;
}
