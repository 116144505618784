import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AccountMarketplaceService,
  AuthService,
  BillingService,
  OrganizationAccountGroupService,
} from "@front/m19-services";
import { combineLatest } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { AccountMarketplace, AccountState } from "@front/m19-api-client";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { NgClass } from "@angular/common";
import { IBadgeComponent, IButtonComponent } from "@front/m19-ui";

@UntilDestroy()
@Component({
  selector: "app-billing-account-selection",
  templateUrl: "./billing-account-selection.component.html",
  standalone: true,
  imports: [
    TranslocoDirective,
    MatTableModule,
    FaIconComponent,
    NgClass,
    IBadgeComponent,
    MatPaginator,
    IButtonComponent,
  ],
})
export class BillingAccountSelectionComponent implements OnInit {
  @Output()
  save = new EventEmitter<AccountMarketplace[]>();
  @Output()
  cancel = new EventEmitter();
  @Input() onlyOneAccount = false;

  availableOrganizationResources?: AccountMarketplace[];
  selectableOrganizationresources?: Set<AccountMarketplace>;
  alreadyBidderOn?: Set<AccountMarketplace>;
  alreadyUsedByAnotherOrganization: Set<AccountMarketplace> = new Set();
  selectedOrganizationResources: Set<AccountMarketplace> = new Set();

  readonly displayedColumns: string[] = ["select", "account", "marketplace", "info"];
  dataSource = new MatTableDataSource<AccountMarketplace>();
  @ViewChild(MatPaginator, { static: false }) set paginator(value: MatPaginator) {
    if (this.dataSource) this.dataSource.paginator = value;
  }

  readonly faSquare = faSquare;
  readonly faCheckedSquare = faCheckSquare;
  readonly BidderOn = AccountState.BIDDER_ON;
  readonly activationMessage: string;

  constructor(
    private authService: AuthService,
    private organizationService: OrganizationAccountGroupService,
    private billingService: BillingService,
    private accountMarketplaceService: AccountMarketplaceService,
    private readonly translocoService: TranslocoService,
  ) {
    this.activationMessage = this.translocoService.translate("billing-account-selection.unable_to_automate", {
      amazonAdvertisingConsole:
        '<a href="https://advertising.amazon.com/" target="_blank">' +
        translocoService.translate("common.amazon_advertising_console") +
        "</a>",
    });
  }

  ngOnInit(): void {
    combineLatest([this.authService.user$, this.organizationService.allOrganizationAccountGroups$])
      .pipe(
        untilDestroyed(this),
        filter(([user, organizations]) => !!organizations && organizations.length > 0),
        switchMap(([user, organizations]) => {
          const selectedOrganization = organizations!.find((x) => x.organization.ownerId == user!.userId)!;
          return this.billingService
            .loadAvailableAccountMarketplaces(selectedOrganization.id)
            .pipe(map((r) => ({ selectedOrganization, profileIdsNotYetOn: new Set(r.map((x) => x.profileId!)) })));
        }),
      )
      .subscribe(({ selectedOrganization, profileIdsNotYetOn }) => {
        this.availableOrganizationResources = selectedOrganization?.accountGroups?.flatMap((ag) => ag.resources) ?? [];
        this.alreadyBidderOn = new Set(
          this.availableOrganizationResources.filter((a) => a.state == AccountState.BIDDER_ON),
        );
        this.alreadyUsedByAnotherOrganization = new Set(
          this.availableOrganizationResources.filter((r) => !profileIdsNotYetOn.has(r.profileId!)),
        );
        this.alreadyBidderOn.forEach((x) => this.alreadyUsedByAnotherOrganization.delete(x));

        this.selectableOrganizationresources = new Set(
          this.availableOrganizationResources.filter(
            (o) =>
              o.activated &&
              this.hasFreshStats(o) &&
              !this.alreadyUsedByAnotherOrganization.has(o) &&
              o.hasAccessToAdvertising,
          ),
        );

        this.selectedOrganizationResources = new Set(this.alreadyBidderOn);
        this.dataSource.data = this.availableOrganizationResources;
      });
    this.dataSource.paginator = this.paginator;
  }

  selectAll(): void {
    if (this.selectedOrganizationResources.size === this.selectableOrganizationresources?.size) {
      this.selectedOrganizationResources = new Set(this.alreadyBidderOn);
    } else {
      this.selectableOrganizationresources?.forEach((o) => this.selectedOrganizationResources.add(o));
    }
  }

  hasFreshStats(organizationResource: AccountMarketplace): boolean {
    return this.accountMarketplaceService.hasFreshStats(organizationResource, 3);
  }

  select(organizationResource: AccountMarketplace): void {
    if (this.selectedOrganizationResources.has(organizationResource)) {
      this.selectedOrganizationResources.delete(organizationResource);
    } else if (this.onlyOneAccount) {
      this.selectedOrganizationResources.clear();
      this.selectedOrganizationResources.add(organizationResource);
    } else {
      this.selectedOrganizationResources.add(organizationResource);
    }
  }

  isSelectable(organizationResource: AccountMarketplace): boolean {
    return !!(
      this.selectableOrganizationresources?.has(organizationResource) &&
      !this.alreadyBidderOn?.has(organizationResource)
    );
  }

  isSelected(organizationResource: AccountMarketplace): boolean {
    return this.selectedOrganizationResources.has(organizationResource);
  }

  saveSelection(): void {
    this.save.emit(Array.from(this.selectedOrganizationResources));
  }

  cancelSelection(): void {
    this.cancel.emit();
  }

  isValidSelection(): boolean {
    return this.selectedOrganizationResources.size > 0;
  }
}
