// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    KeywordTrackingFrequency,
    Marketplace,
} from './';

/**
 * @export
 * @interface KeywordTrackerConfig
 */
export interface KeywordTrackerConfig {
    /**
     * @type {string}
     * @memberof KeywordTrackerConfig
     */
    accountId?: string;
    /**
     * @type {number}
     * @memberof KeywordTrackerConfig
     */
    organizationId?: number;
    /**
     * @type {Marketplace}
     * @memberof KeywordTrackerConfig
     */
    marketplace?: Marketplace;
    /**
     * @type {string}
     * @memberof KeywordTrackerConfig
     */
    searchTerm?: string;
    /**
     * @type {KeywordTrackingFrequency}
     * @memberof KeywordTrackerConfig
     */
    frequency?: KeywordTrackingFrequency;
    /**
     * @type {Array<number>}
     * @memberof KeywordTrackerConfig
     */
    strategyIds?: Array<number>;
    /**
     * @type {Array<string>}
     * @memberof KeywordTrackerConfig
     */
    topAsins?: Array<string>;
    /**
     * @type {string}
     * @memberof KeywordTrackerConfig
     */
    lastUpdate?: string;
}
