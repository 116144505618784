// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    Marketplace,
} from './';

/**
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * @type {string}
     * @memberof Order
     */
    accountId?: string;
    /**
     * @type {Marketplace}
     * @memberof Order
     */
    marketplace?: Marketplace;
    /**
     * @type {string}
     * @memberof Order
     */
    day?: string;
    /**
     * @type {string}
     * @memberof Order
     */
    asin?: string;
    /**
     * @type {string}
     * @memberof Order
     */
    sku?: string;
    /**
     * @type {number}
     * @memberof Order
     */
    sumQuantities?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    quantity?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    sales?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    commission?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    fBAPerUnitFulfillmentFee?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    tax?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    taxDiscount?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    promotion?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    shippingAndGiftWrap?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    giftWrap?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    taxGiftWrap?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    promotionGiftWrap?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    giftWrapChargeBack?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    giftWrapCommission?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    shipping?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    taxShipping?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    promotionShipping?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    shippingChargeBack?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    shippingHB?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    exportCharge?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    otherItemPrice?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    otherPromotion?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    otherItemFees?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    refundSales?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    refundCommission?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    returnFees?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    otherTax?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    reimbursement?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    feeAdjustment?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    replacementRefunded?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    amazonVine?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    orderReturnRefunded?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    chargebackRefunded?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    a2zGuaranteeRefunded?: number;
    /**
     * @type {number}
     * @memberof Order
     */
    sellableReturns?: number;
}
