// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SharedReportType {
    keyword_ranking = 'keyword-ranking',
    account_status = 'account-status',
    adgroup_daily_stats = 'adgroup-daily-stats',
    asin_daily_stats = 'asin-daily-stats',
    query_daily_stats = 'query-daily-stats'
}

