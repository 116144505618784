<ng-container *transloco="let t">
  <mat-table mat-table [dataSource]="dataSource" class="responsive-table">
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef class="flex-initial text-left">
        @if (!onlyOneAccount) {
          <span (click)="selectAll()" class="pointer">
            <fa-icon
              [icon]="
                selectedOrganizationResources.size === selectableOrganizationresources?.size
                  ? faCheckedSquare
                  : faSquare
              "
              size="lg"
              class="text-main-500"
            ></fa-icon>
          </span>
        }
      </mat-header-cell>
      <mat-cell *matCellDef="let resource" class="flex-initial text-left">
        <div>
          <span (click)="select(resource)" class="pointer" [ngClass]="{ disabled: !isSelectable(resource) }">
            <fa-icon
              [icon]="isSelected(resource) ? faCheckedSquare : faSquare"
              size="lg"
              class="text-main-500"
            ></fa-icon>
          </span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="account">
      <mat-header-cell *matHeaderCellDef class="flex-1">{{ t("common.account") }}</mat-header-cell>
      <mat-cell *matCellDef="let resource" class="flex-1">
        {{ resource.accountName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="marketplace">
      <mat-header-cell *matHeaderCellDef class="flex-1">{{ t("common.marketplaces") }}</mat-header-cell>
      <mat-cell *matCellDef="let resource" class="flex-1">
        <IBadge [label]="resource.marketplace" size="xs" />
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="info">
      <mat-header-cell *matHeaderCellDef class="flex-2"></mat-header-cell>
      <mat-cell *matCellDef="let resource" class="flex-2">
        @if (resource.state === BidderOn) {
          <div>{{ t("billing-account-selection.this_account_is_part_of_your_current_plan") }}</div>
        }
        @if (alreadyUsedByAnotherOrganization.has(resource)) {
          <div class="bg-red-100 px-4 py-1 text-red-700">
            {{ t("billing-account-selection.accountanother_org_error") }}
          </div>
        }
        @if (!hasFreshStats(resource)) {
          <div class="bg-red-100 px-4 py-1 text-red-700">
            {{ t("billing-account-selection.not_fully_downloaded") }}
          </div>
        }
        @if (!resource.activated && hasFreshStats(resource)) {
          <div class="bg-red-100 px-4 py-1 text-red-700" [innerHTML]="activationMessage"></div>
        }
        @if (!resource.hasAccessToAdvertising && hasFreshStats(resource)) {
          <div class="bg-red-100 px-4 py-1 text-red-700">{{ t("billing-account-selection.access_lost") }}</div>
        }
      </mat-cell>
    </ng-container>

    <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    [pageSizeOptions]="[10, 50, 100]"
    [pageSize]="10"
    showFirstLastButtons
    style="margin-bottom: 2rem"
    [hidden]="dataSource.data && dataSource.data.length === 0"
  ></mat-paginator>
  <div class="modal-footer flex justify-end gap-2">
    <IButton color="white" [label]="t('common.cancel')" (onClick)="cancelSelection()" />
    <IButton
      [label]="onlyOneAccount ? t('common.save') : t('common.next')"
      (onClick)="saveSelection()"
      [disabled]="!isValidSelection()"
    />
  </div>
</ng-container>
