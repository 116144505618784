import { animate, state, style, transition, trigger } from '@angular/animations';

export const reveal = trigger('reveal', [
  state(
    'void',
    style({
      height: '0px',
    }),
  ),
  transition(':enter', [
    animate(
      '100ms ease-in-out',
      style({
        height: '*',
      }),
    ),
  ]),
  transition(':leave', [
    animate(
      '100ms ease-in-out',
      style({
        height: '0px',
      }),
    ),
  ]),
]);
