import { Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BrandAnalyticsService } from "@front/m19-services";
import { BrandAnalyticsMarketplaces } from "@m19-board/brand-analytics/BrandAnalyticsAvailability";
import { Marketplace } from "@front/m19-api-client";

@UntilDestroy()
@Component({
  selector: "app-search-trends-marketplace-selector",
  template: ` <app-marketplace-selector
    [marketplaces]="availableMarketplaces"
    [marketplaceSelected]="selectedMarketplace"
    (marketplaceSelectedChange)="selectMarketplace($event)"
  ></app-marketplace-selector>`,
})
export class SearchTrendsMarketplaceSelectorComponent implements OnInit {
  constructor(private brandAnalyticsService: BrandAnalyticsService) {}

  availableMarketplaces = BrandAnalyticsMarketplaces;
  selectedMarketplace: Marketplace;

  ngOnInit(): void {
    this.brandAnalyticsService.selectedMarketplace$.pipe(untilDestroyed(this)).subscribe((m) => {
      this.selectedMarketplace = m;
    });
    const initMarketplace = Marketplace[localStorage.getItem("currentMarketplace")];
    if (initMarketplace && this.availableMarketplaces.includes(initMarketplace)) {
      this.selectedMarketplace = initMarketplace;
      this.brandAnalyticsService.selectMarketplace(initMarketplace);
    }
  }

  selectMarketplace(marketplace: Marketplace): void {
    this.brandAnalyticsService.selectMarketplace(marketplace);
    localStorage.setItem("currentMarketplace", marketplace);
  }
}
