<ng-container *transloco="let t">
  <div class="mb-4">
    <a href="/login" class="">
      <logo></logo>
    </a>
  </div>
  <h3 class="mb-2 text-2xl font-bold text-gray-800 dark:text-gray-100">{{ t("register.register_header") }}</h3>
  <p class="mb-6 text-sm font-medium text-gray-500 dark:text-gray-400">
    {{ t("register.register_header_description") }}
  </p>
  @if (error()) {
    <IAlert class="mb-6 block" type="error" [title]="error() ?? 'User registration error'" />
  }

  <lib-register-form
    (register)="register($event)"
    [loading]="loading()"
    [agencyName]="agency"
    [termsAndConditionLink]="termsAndConditionLink"
    [withMarketingFields]="shouldDisplayMarketingFields()"
  />

  <p class="mt-6 text-center text-sm text-gray-500 dark:text-gray-400">
    {{ t("register.already_have_account_text") }}
    <a routerLink="/login" class="text-main font-bold"> {{ t("common.login") }}</a>
  </p>
</ng-container>
