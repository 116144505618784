import { CurrencyPipe, NgClass, NgTemplateOutlet } from "@angular/common";
import { Component, inject, Input, OnInit } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AccountMarketplace, Currency, SbAsins, SbCreativeType } from "@front/m19-api-client";
import { Catalog, ProductEx } from "@front/m19-models";
import { AccountSelectionService, AsinService } from "@front/m19-services";
import { ProductThumbnailComponent } from "@m19-board/product-view/product-thumbnail.component";
import { CarouselComponent } from "@m19-board/shared/carousel/carousel.component";
import { ICON_CHEVRON_DOWN } from "@m19-board/utils/iconsLabels";
import { switchMap } from "rxjs";
import { SbCrea } from "../sb-form-creative/sb-form-creative.component";
import { TranslocoDirective } from "@jsverse/transloco";

@Component({
  selector: "app-sb-form-crea-preview",
  templateUrl: "./sb-form-crea-preview.component.html",
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgClass,
    ProductThumbnailComponent,
    CarouselComponent,
    MatTooltipModule,
    CurrencyPipe,
    TranslocoDirective,
  ],
})
export class SbFormCreaPreviewComponent implements OnInit {
  private readonly accountSelection = inject(AccountSelectionService);
  private readonly asinService = inject(AsinService);
  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;
  readonly DEFAULT_AD_LINE_PRODUCTS: (ProductEx | undefined)[][] = [[undefined]];
  readonly range5 = Array.from({ length: 5 }, (_, i) => i);

  @Input({ required: true }) creativeType!: string;

  @Input() set asins(a: SbAsins[]) {
    this._asins = a;
    this._adLineProducts = this.toAdLineProducts(a);
  }

  _asins?: SbAsins[];
  @Input({ required: true }) formCrea?: SbCrea;
  @Input({ required: true }) brandName!: string;
  @Input({ required: true }) isVendor = false;
  @Input({ required: true }) am!: AccountMarketplace;
  @Input({ required: true }) currency!: Currency;

  _adLineProducts: (ProductEx | undefined)[][] = this.DEFAULT_AD_LINE_PRODUCTS;
  asinOffers?: Map<string, ProductEx>;

  ngOnInit(): void {
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(switchMap((am) => this.asinService.getCatalog(am.accountId, am.marketplace)))
      .subscribe((c: Catalog) => {
        this.asinOffers = c.asinOffers;
        if (this._asins) {
          this._adLineProducts = this.toAdLineProducts(this._asins);
        }
      });
  }

  roundToNearestHalf(num: number): number {
    return Math.round(num * 2) / 2;
  }

  private toAdLineProducts(asins: SbAsins[]): (ProductEx | undefined)[][] {
    if (!asins || !this.asinOffers) return this.DEFAULT_AD_LINE_PRODUCTS;
    return asins.map((a) => [
      this.asinOffers?.get(a.asin1),
      this.asinOffers?.get(a.asin2!),
      this.asinOffers?.get(a.asin3!),
    ]);
  }

  readonly SbCreativeType = SbCreativeType;
}
