<div class="flex flex-row">
  <board-sidebar-v2 />

  <div class="flex max-h-screen min-h-screen flex-1 flex-col overflow-hidden" *transloco="let t">
    @if (isTest || isAdminLogin()) {
      <div class="flex w-full justify-between bg-orange-700 px-4 text-white">
        <div class="flex gap-6">
          @if (isTest) {
            <span class="font-bold">Demo environment</span>
            @if (isAdminLogin()) {
              <span class="font-bold">|</span>
            }
          }
          @if (isAdminLogin()) {
            <span>Logged via Admin Board</span>
          }
        </div>
        <a (click)="printDebugInfo()">Debug Info</a>
      </div>
    }
    @if (globalWarning$ | async) {
      <div class="border-sha w-full rounded-sm bg-red-100 px-4 py-1 text-red-700">
        {{ globalWarning$ | async }}
      </div>
    }
    <!-- header -->
    <div class="m-0 flex w-full flex-row items-center justify-between border-b border-gray-200 bg-white p-0 px-4 py-2">
      <div class="flex items-center">
        @if (isSidebarCollapsed()) {
          <IButton
            icon="icon-[lucide--chevrons-right]"
            class="mr-2"
            tooltipValue="Expand sidebar"
            [size]="'sm'"
            color="gray"
            variant="ghost"
            (click)="isSidebarCollapsed.set(false)"
          />
        }
        <a class="w-[155px]" routerLink="/dashboard" queryParamsHandling="merge">
          <logo></logo>
        </a>
        @if (displayedSelector === Selector.AccountSelector) {
          <app-account-selector scope="dashboard" />
        } @else if (displayedSelector === Selector.MultiAccountSelector && allAccountMarketplaces.length > 0) {
          <app-multi-account-selector></app-multi-account-selector>
        } @else if (displayedSelector === Selector.MarketplaceSelector) {
          <app-search-trends-marketplace-selector></app-search-trends-marketplace-selector>
        }

        <div class="flex items-center">
          @if (displayedSelector === Selector.AccountSelector && hasInvalidToken()) {
            <button
              class="btn btn-warning mx-1 h-full"
              matTooltip="{{ t('default-layout.login_tooltip', [logInPlaceForAPIGrantAccess()]) }}"
              (click)="requestGrant()"
            >
              {{ t("default-layout.grant_access") }}
            </button>
          }
          @if (displayedSelector === Selector.AccountSelector && hasPendingVendorIntegration()) {
            <IButton
              class="mx-1"
              color="orange"
              [label]="t('default-layout.validate_catalog')"
              [matTooltip]="t('default-layout.validate_seeling_partner_api')"
              (click)="validateAccountIntegration()"
            />
          }
        </div>
      </div>

      <div class="ml-1 mr-4 flex flex-wrap items-center gap-2">
        <app-currency-selection></app-currency-selection>
        <app-date-range-selector />
      </div>
    </div>

    <div class="flex w-full flex-1 flex-row overflow-hidden">
      <div class="flex-1 overflow-auto">
        <div class="h-full p-4" style="width: max(1200px, 100%)">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
