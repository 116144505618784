import { ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { SbCreative } from '@front/m19-api-client';

@Injectable({
  providedIn: 'root',
})
export class StrategyCacheReloaded {
  public readonly sbCreativeIndexReloaded: ReplaySubject<Map<number, SbCreative[]>> = new ReplaySubject(1);
}
