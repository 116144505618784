// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    Marketplace,
    Response,
    TacosStrategyGroup,
} from '../models';

export interface DeleteTacosStrategyGroupRequest {
    accountId: string;
    marketplace: Marketplace;
    tacosStrategyGroupId: number;
    organizationId: number;
}

export interface GetTacosStrategyGroupByIdRequest {
    accountId: string;
    marketplace: Marketplace;
    tacosStrategyGroupId: number;
}

export interface ListTacosStrategyGroupRequest {
    accountId: string;
    marketplace: Marketplace;
}

export interface UpdateTacosStrategyGroupRequest {
    accountId: string;
    marketplace: Marketplace;
    tacosStrategyGroupId: number;
    tacosTarget?: number;
    state?: string;
}

/**
 * no description
 */
export class TacosStrategyGroupApi extends BaseAPI {

    /**
     * Delete a tacos strategy group
     */
    deleteTacosStrategyGroup({ accountId, marketplace, tacosStrategyGroupId, organizationId }: DeleteTacosStrategyGroupRequest): Observable<Response>
    deleteTacosStrategyGroup({ accountId, marketplace, tacosStrategyGroupId, organizationId }: DeleteTacosStrategyGroupRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    deleteTacosStrategyGroup({ accountId, marketplace, tacosStrategyGroupId, organizationId }: DeleteTacosStrategyGroupRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'deleteTacosStrategyGroup');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'deleteTacosStrategyGroup');
        throwIfNullOrUndefined(tacosStrategyGroupId, 'tacosStrategyGroupId', 'deleteTacosStrategyGroup');
        throwIfNullOrUndefined(organizationId, 'organizationId', 'deleteTacosStrategyGroup');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'organizationId': organizationId,
        };

        return this.request<Response>({
            url: '/tacosStrategyGroup/{accountId}/{marketplace}/{tacosStrategyGroupId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{tacosStrategyGroupId}', encodeURI(tacosStrategyGroupId)),
            method: 'DELETE',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Get a Tacos strategy Group by its id
     */
    getTacosStrategyGroupById({ accountId, marketplace, tacosStrategyGroupId }: GetTacosStrategyGroupByIdRequest): Observable<TacosStrategyGroup>
    getTacosStrategyGroupById({ accountId, marketplace, tacosStrategyGroupId }: GetTacosStrategyGroupByIdRequest, opts?: OperationOpts): Observable<AjaxResponse<TacosStrategyGroup>>
    getTacosStrategyGroupById({ accountId, marketplace, tacosStrategyGroupId }: GetTacosStrategyGroupByIdRequest, opts?: OperationOpts): Observable<TacosStrategyGroup | AjaxResponse<TacosStrategyGroup>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getTacosStrategyGroupById');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getTacosStrategyGroupById');
        throwIfNullOrUndefined(tacosStrategyGroupId, 'tacosStrategyGroupId', 'getTacosStrategyGroupById');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<TacosStrategyGroup>({
            url: '/tacosStrategyGroup/{accountId}/{marketplace}/{tacosStrategyGroupId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{tacosStrategyGroupId}', encodeURI(tacosStrategyGroupId)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * List Tacos Strategy Groups
     * List Tacos Strategy Groups
     */
    listTacosStrategyGroup({ accountId, marketplace }: ListTacosStrategyGroupRequest): Observable<Array<TacosStrategyGroup>>
    listTacosStrategyGroup({ accountId, marketplace }: ListTacosStrategyGroupRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<TacosStrategyGroup>>>
    listTacosStrategyGroup({ accountId, marketplace }: ListTacosStrategyGroupRequest, opts?: OperationOpts): Observable<Array<TacosStrategyGroup> | AjaxResponse<Array<TacosStrategyGroup>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'listTacosStrategyGroup');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'listTacosStrategyGroup');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<TacosStrategyGroup>>({
            url: '/tacosStrategyGroup/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Update single field of a TacosStrategyGroup by its id
     */
    updateTacosStrategyGroup({ accountId, marketplace, tacosStrategyGroupId, tacosTarget, state }: UpdateTacosStrategyGroupRequest): Observable<Response>
    updateTacosStrategyGroup({ accountId, marketplace, tacosStrategyGroupId, tacosTarget, state }: UpdateTacosStrategyGroupRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateTacosStrategyGroup({ accountId, marketplace, tacosStrategyGroupId, tacosTarget, state }: UpdateTacosStrategyGroupRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'updateTacosStrategyGroup');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'updateTacosStrategyGroup');
        throwIfNullOrUndefined(tacosStrategyGroupId, 'tacosStrategyGroupId', 'updateTacosStrategyGroup');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (tacosTarget != null) { query['tacosTarget'] = tacosTarget; }
        if (state != null) { query['state'] = state; }

        return this.request<Response>({
            url: '/tacosStrategyGroup/{accountId}/{marketplace}/{tacosStrategyGroupId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{tacosStrategyGroupId}', encodeURI(tacosStrategyGroupId)),
            method: 'PUT',
            headers,
            query,
        }, opts?.responseOpts);
    };

}
