import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AccountSelectionService, StatsApiClientService } from '.';
import { Marketplace, Order, OrderApi } from '@front/m19-api-client';
import { UserSelectionService } from './user.selection.service';
import { Injectable } from '@angular/core';
import { Utils } from '@front/m19-utils';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  public readonly globalDataByDates$: Observable<Order[]>;
  public readonly previousGlobalDataByDates$: Observable<Order[]>;

  constructor(
    private orderApi: OrderApi,
    private userSelectionService: UserSelectionService,
    private accountSelectionService: AccountSelectionService,
  ) {
    this.globalDataByDates$ = combineLatest([
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      this.userSelectionService.dateRange$,
    ]).pipe(
      switchMap(([am, dateRange]) => {
        return this.getOrders(am.accountId, am.marketplace, dateRange[0], dateRange[1]);
      }),
    );
    this.previousGlobalDataByDates$ = combineLatest([
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      userSelectionService.dateRange$,
      this.userSelectionService.periodComparison$,
    ]).pipe(
      switchMap(([am, dateRange, periodComparison]) => {
        if (!periodComparison?.period) return of([]);
        const dateIntervalInDays = Utils.getDateIntervalInDays(dateRange);
        if (dateIntervalInDays > StatsApiClientService.maxComp) {
          return of([]);
        }
        const dateGap = Utils.getDateIntervalInDays([periodComparison.period[0], dateRange[0]]);
        return this.getOrders(
          am.accountId,
          am.marketplace,
          periodComparison.period[0],
          periodComparison.period[1],
        ).pipe(
          map((orders) => {
            return orders.map((order) => this.alignDate(order, dateGap));
          }),
        );
      }),
    );
  }

  private alignDate(order: Order, dateIntervalInDays: number): Order {
    let date = Utils.toMoment(order.day!);
    date = date.add(dateIntervalInDays, 'days');
    order.day = Utils.formatMomentDate(date);
    return order;
  }

  public getOrders(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<Array<Order>> {
    return this.orderApi.getOrder({
      accountId: accountId,
      marketplace: marketplace,
      minDate: minDate,
      maxDate: maxDate,
    });
  }
}
