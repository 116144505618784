import { combineLatest, forkJoin, map, Observable, ReplaySubject, tap } from 'rxjs';
import {
  CatalogBrand,
  CatalogBrandApi,
  CatalogBrandExtraSegment,
  Marketplace,
  MatchType,
  UpdateCatalogBrandExtraSegmentsActionEnum,
} from '@front/m19-api-client';
import { AsinService } from './asin.service';
import { AccountMarketplaceKey, accountMarketplaceKey, catchAjaxError, Utils } from '@front/m19-utils';
import { Constant } from './constant';
import { Injectable } from '@angular/core';
import { BrandingFilter, CatalogBrandEx } from '@front/m19-models/CatalogBrandEx';
import { SegmentEx } from '@front/m19-models/SegmentEx';

@Injectable({
  providedIn: 'root',
})
export class CatalogBrandService {
  private catalogBrandsCache$ = new Map<AccountMarketplaceKey, ReplaySubject<CatalogBrandEx[]>>();
  private catalogBrandsCache = new Map<AccountMarketplaceKey, CatalogBrandEx[]>();

  constructor(
    private catalogBrandApi: CatalogBrandApi,
    private asinService: AsinService,
  ) {}

  public getCatalogBrands(accountId: string, marketplace: Marketplace): Observable<CatalogBrandEx[]> {
    const key = accountMarketplaceKey(accountId, marketplace);
    if (this.catalogBrandsCache$.has(key)) {
      return this.catalogBrandsCache$.get(key)!;
    }
    const subject = new ReplaySubject<CatalogBrandEx[]>(1);
    this.catalogBrandsCache$.set(key, subject);
    this.catalogBrandApi
      .getCatalogBrands({ accountId, marketplace })
      .pipe(catchAjaxError())
      .subscribe({
        next: (response) => {
          const catalogBrands = this.buildCatalogBrandEx(response);
          subject.next(catalogBrands);
          this.catalogBrandsCache.set(key, catalogBrands);
        },
        error: (e) => subject.error(e),
      });
    return subject;
  }

  public getBrandingFilters(accountId: string, marketplace: Marketplace): Observable<BrandingFilter[]> {
    return combineLatest([
      this.asinService.getCatalog(accountId, marketplace),
      this.getCatalogBrands(accountId, marketplace),
    ]).pipe(
      map(([catalog, catalogBrands]) => {
        const brandAsins = new Map<string, string[]>();
        for (const product of catalog.products) {
          if (!product.brand) {
            continue;
          }
          const brand = product.brand.toLowerCase();
          if (brandAsins.has(brand)) {
            brandAsins.get(brand)!.push(product.asin!);
          } else {
            brandAsins.set(brand, [product.asin!]);
          }
        }
        const brands = new Map<string, BrandingFilter>();
        for (const brand of catalogBrands) {
          const asins = brandAsins.get(brand.brandName!.toLowerCase()) ?? [];
          const productSegment = new SegmentEx({
            accountId,
            segmentId: brand.brandId,
            name: brand.brandName!,
            items: asins.map((a) => ({ matchType: MatchType.asinSameAs, targetingValue: a })),
          });
          const keywordSegment = new SegmentEx({
            accountId,
            segmentId: brand.brandId,
            name: brand.brandName!,
            items: brand.extraSegments!.map((s) => ({ matchType: MatchType.phrase, targetingValue: s.keyword! })),
          });
          // if brand is a parent brand, add brand name in keyword segment
          if (brand.parentBrandId == undefined || brand.parentBrandId == brand.brandId) {
            keywordSegment.items.push({
              matchType: MatchType.phrase,
              targetingValue: Utils.normalizeKeyword(brand.brandName!),
            });
          }
          let brandAlias = brand.brandAlias!;
          if (brand.parentBrandId != undefined && brand.parentBrandId != brand.brandId) {
            brandAlias = brand.parentBrandAlias!;
          }
          if (!brands.has(brandAlias)) {
            brands.set(brandAlias, {
              name: brandAlias,
              asins,
              productSegment,
              keywordSegment,
            });
          } else {
            const existing = brands.get(brandAlias)!;
            existing.asins = existing.asins.concat(asins);
            existing.productSegment.items.push(...productSegment.items);
            existing.keywordSegment.items.push(...keywordSegment.items);
          }
        }

        return Array.from(brands.values());
      }),
    );
  }

  public setBrandAlias(accountId: string, marketplace: Marketplace, brandId: number, brandAlias: string) {
    return this.catalogBrandApi.updateCatalogBrand({ accountId, marketplace, brandId, brandAlias }).pipe(
      catchAjaxError(),
      tap(() => {
        const key = accountMarketplaceKey(accountId, marketplace);
        if (this.catalogBrandsCache$.has(key) && this.catalogBrandsCache.has(key)) {
          const catalogBrands = this.catalogBrandsCache.get(key)!;
          const nextCatalogBrands = this.buildCatalogBrandEx(
            catalogBrands.map((catalogBrand) => {
              if (catalogBrand.brandId == brandId) {
                return { ...catalogBrand, brandAlias: brandAlias };
              }
              return catalogBrand;
            }),
          );
          this.catalogBrandsCache.set(key, nextCatalogBrands);
          this.catalogBrandsCache$.get(key)!.next(nextCatalogBrands);
        }
      }),
    );
  }

  public mergeBrands(accountId: string, marketplace: Marketplace, brandId: number, brandIdToMerge: number) {
    return forkJoin([
      this.catalogBrandApi.updateCatalogBrand({ accountId, marketplace, brandId, parentBrandId: brandIdToMerge }),
      this.catalogBrandApi.updateCatalogBrand({
        accountId,
        marketplace,
        brandId: brandIdToMerge,
        parentBrandId: brandIdToMerge,
      }),
    ]).pipe(
      catchAjaxError(),
      tap(() => {
        const key = accountMarketplaceKey(accountId, marketplace);
        if (this.catalogBrandsCache$.has(key) && this.catalogBrandsCache.has(key)) {
          const catalogBrands = this.catalogBrandsCache.get(key)!;
          const parentBrand = catalogBrands.find((brand) => brand.brandId == brandIdToMerge)!;
          const nextCatalogBrands = catalogBrands.map((catalogBrand) => {
            if (catalogBrand.brandId == brandId) {
              return {
                ...catalogBrand,
                parentBrandId: brandIdToMerge,
                parentBrandAlias: parentBrand?.brandAlias ?? parentBrand.brandName,
              };
            }
            if (catalogBrand.brandId == brandIdToMerge) {
              return {
                ...catalogBrand,
                parentBrandId: brandIdToMerge,
                parentBrandAlias: parentBrand?.brandAlias ?? parentBrand.brandName,
              };
            }
            return catalogBrand;
          });
          this.catalogBrandsCache$.get(key)!.next(nextCatalogBrands);
          this.catalogBrandsCache.set(key, nextCatalogBrands);
        }
      }),
    );
  }

  public unmergeBrands(accountId: string, marketplace: Marketplace, brandId: number) {
    const key = accountMarketplaceKey(accountId, marketplace);
    const brand = this.catalogBrandsCache.get(key)!.find((brand) => brand.brandId == brandId)!;
    const mergedBrands = this.catalogBrandsCache.get(key)!.filter((b) => b.parentBrandId == brand.parentBrandId);
    if (mergedBrands.length > 2) {
      return this.catalogBrandApi.updateCatalogBrand({ accountId, marketplace, brandId, parentBrandId: 0 }).pipe(
        catchAjaxError(),
        tap(() => {
          if (this.catalogBrandsCache$.has(key) && this.catalogBrandsCache.has(key)) {
            const catalogBrands = this.catalogBrandsCache.get(key)!;
            const nextCatalogBrands = catalogBrands.map((catalogBrand) => {
              if (catalogBrand.brandId == brandId) {
                return { ...catalogBrand, parentBrandId: undefined, parentBrandAlias: catalogBrand.brandAlias };
              }
              return catalogBrand;
            });
            this.catalogBrandsCache.set(key, nextCatalogBrands);
            this.catalogBrandsCache$.get(key)!.next(nextCatalogBrands);
          }
        }),
      );
    }
    // if only 2 brands are merged, we need to unmerge them both
    return forkJoin(
      mergedBrands.map((b) =>
        this.catalogBrandApi.updateCatalogBrand({ accountId, marketplace, brandId: b.brandId!, parentBrandId: 0 }),
      ),
    ).pipe(
      catchAjaxError(),
      tap(() => {
        if (this.catalogBrandsCache$.has(key) && this.catalogBrandsCache.has(key)) {
          const catalogBrands = this.catalogBrandsCache.get(key)!;
          const nextCatalogBrands = catalogBrands.map((catalogBrand) => {
            if (catalogBrand.parentBrandId == brand.parentBrandId) {
              return { ...catalogBrand, parentBrandId: undefined, parentBrandAlias: catalogBrand.brandAlias };
            }
            return catalogBrand;
          });
          this.catalogBrandsCache.set(key, nextCatalogBrands);
          this.catalogBrandsCache$.get(key)!.next(nextCatalogBrands);
        }
      }),
      map((r) => r[0]),
    );
  }

  public addKeywordToCatalogBrand(
    accountId: string,
    marketplace: Marketplace,
    brandId: number,
    keywords: CatalogBrandExtraSegment[],
  ) {
    return this.catalogBrandApi
      .updateCatalogBrandExtraSegments({
        accountId,
        marketplace,
        brandId,
        action: UpdateCatalogBrandExtraSegmentsActionEnum.ADD,
        catalogBrandExtraSegment: keywords,
      })
      .pipe(
        catchAjaxError(),
        tap(() => {
          const key = accountMarketplaceKey(accountId, marketplace);
          if (this.catalogBrandsCache$.has(key) && this.catalogBrandsCache.has(key)) {
            const catalogBrands = this.catalogBrandsCache.get(key)!;
            this.catalogBrandsCache$.get(key)!.next(
              catalogBrands.map((catalogBrand) => {
                if (catalogBrand.brandId == brandId) {
                  return {
                    ...catalogBrand,
                    extraSegments: catalogBrand.extraSegments!.concat(keywords),
                  };
                }
                return catalogBrand;
              }),
            );
          }
        }),
      );
  }

  public deleteKeywordFromCatalogBrand(
    accountId: string,
    marketplace: Marketplace,
    brandId: number,
    keywords: CatalogBrandExtraSegment[],
  ) {
    return this.catalogBrandApi
      .updateCatalogBrandExtraSegments({
        accountId,
        marketplace,
        brandId,
        action: UpdateCatalogBrandExtraSegmentsActionEnum.DELETE,
        catalogBrandExtraSegment: keywords,
      })
      .pipe(
        catchAjaxError(),
        tap(() => {
          const key = accountMarketplaceKey(accountId, marketplace);
          if (this.catalogBrandsCache$.has(key) && this.catalogBrandsCache.has(key)) {
            const catalogBrands = this.catalogBrandsCache.get(key)!;
            this.catalogBrandsCache$.get(key)!.next(
              catalogBrands.map((catalogBrand) => {
                if (catalogBrand.brandId == brandId) {
                  return {
                    ...catalogBrand,
                    extraSegments: catalogBrand.extraSegments!.filter(
                      (segment) => !keywords.some((keyword) => keyword.keyword == segment.keyword),
                    ),
                  };
                }
                return catalogBrand;
              }),
            );
          }
        }),
      );
  }

  private buildCatalogBrandEx(catalogBrands: CatalogBrand[]): CatalogBrandEx[] {
    const map = new Map<number, CatalogBrand>();
    for (const catalogBrand of catalogBrands) {
      map.set(catalogBrand.brandId!, catalogBrand);
    }

    return catalogBrands.map((catalogBrand) => ({
      ...catalogBrand,
      brandAlias: catalogBrand.brandAlias ?? catalogBrand.brandName,
      parentBrandAlias: catalogBrand.parentBrandId
        ? (map.get(catalogBrand.parentBrandId)?.brandAlias ?? map.get(catalogBrand.parentBrandId)?.brandName)
        : (catalogBrand.brandAlias ?? catalogBrand.brandName),
    }));
  }

  public checkKeywords(
    keywords: CatalogBrandExtraSegment[],
    existingKeywords: CatalogBrandExtraSegment[],
    max = Constant.maxKeywordBySegment,
  ) {
    const initialSize = existingKeywords.length;
    let count = 0;
    const errors: string[] = [];
    const items = [...existingKeywords];
    const addedKeywords = [];
    let reason = '';
    for (const keyword of keywords) {
      if (initialSize + count < max) {
        const normalizedKeyword = Utils.normalizeKeyword(keyword.keyword!);
        if (normalizedKeyword == '') continue;
        if ((reason = Utils.isValidKeyword(normalizedKeyword, MatchType.phrase)) != '') {
          errors.push(`${keyword.keyword} : ${reason}`);
        } else if (items.find((x) => x.keyword == normalizedKeyword) != undefined)
          errors.push(keyword.keyword + ': Duplicate');
        else {
          const toAdd = {
            keyword: normalizedKeyword,
          };
          items.push(toAdd);
          addedKeywords.push(toAdd);
          count++;
        }
      } else {
        errors.push(
          'You have reached the maximum number of ' +
            max +
            ' other brand names, the last ' +
            (keywords.length - errors.length - count) +
            ' name(s) could not be added.',
        );
        break;
      }
    }
    return {
      keywords: items,
      addedKeywords: addedKeywords,
      errors: errors,
    };
  }
}
