<ng-container *transloco="let t">
  <form class="main-container mx-auto flex" [formGroup]="spForm" (submit)="submitForm()">
    <div class="form-inner">
      <div class="card-common mb-3 p-3">
        <h3 class="mb-4">{{ strategyTypeStr | titlecase }}</h3>
        <div class="content">
          <div class="input-section">
            <label for="strategyName">{{ t("sp-strategy-creation.strategy_name") }}</label>
            <input
              type="text"
              maxlength="80"
              class="form-control"
              placeholder="Please enter a name for your strategy"
              id="strategyName"
              [formControl]="spForm.controls.name"
              [class.border-danger]="formSubmitted && spForm.controls.name.errors"
            />
            <div class="hint">{{ spForm.controls.name.value?.length }}/80</div>
          </div>
        </div>
      </div>
      <div class="card-common mb-3 p-3" [class.border-danger]="formSubmitted && spForm.controls.algoModeConfig.invalid">
        <h3 class="mb-4">{{ t("sd-strategy-creation.algorithm") }}</h3>
        <div class="content">
          <algo-mode-selection (algoModeConfig)="setAlgoModeConfig($event)" />
        </div>
      </div>
      <div class="card-common mb-3 p-3" [class.border-danger]="formSubmitted && spForm.controls.asins.invalid">
        <div class="mb-4">
          <h3>{{ t("common.asins") }}</h3>
          @if (formSubmitted && spForm.controls.asins.invalid) {
            <span class="danger">{{ t("sp-substrategy-creation.select_at_least_one_asin") }}</span>
          }
        </div>
        <div class="h-[45rem]">
          <app-asins-selection
            class="h-full"
            [asins]="spForm.controls.asins.value!"
            [asinEligibility]="asinEligibility"
            [accountId]="accountMarketplace.accountId"
            [marketplace]="accountMarketplace.marketplace"
            [selectionModes]="productSelectionModes!"
            [customAsinList]="strategyType === StrategyType.PRODUCT ? undefined : strategyGroup.asins"
            [notDisplayedAsins]="notDisplayedAsins"
            (onAdd)="addAsins($event)"
            (onDelete)="deleteAsins($event)"
            [csvExport]="false"
          >
          </app-asins-selection>
        </div>
      </div>
      @if (withTargetings) {
        <div
          class="card-common mb-3 p-3"
          [class.border-danger]="
            formSubmitted &&
            (spForm.controls.targetingType.invalid ||
              spForm.controls.keywords.invalid ||
              spForm.controls.productTargetings.invalid)
          "
        >
          <div class="mb-4">
            <h3>{{ t("sd-strategy-creation.targetings") }}</h3>
            @if (strategyType === StrategyType.BRAND) {
              <IButton
                color="white"
                label="{{ t('sp-substrategy-creation.import_from_brand') }}"
                (onClick)="importFromBrandModal()"
              />
            }
            @if (formSubmitted && spForm.controls.keywords.invalid) {
              <span class="danger">{{ t("sp-substrategy-creation.target_at_least_one_keyword") }}</span>
            }
            @if (formSubmitted && spForm.controls.productTargetings.invalid) {
              <span class="danger">{{ t("sp-substrategy-creation.target_at_least_one_product") }}</span>
            }
          </div>
          <div class="mb-3 flex">
            <div
              [ngClass]="{
                'checkbox-card-selected': spForm.controls.targetingType.value === StrategyTargetingType.KEYWORDS,
              }"
              class="checkbox-card"
              (click)="toggleTargetingType(StrategyTargetingType.KEYWORDS)"
            >
              <div class="flex w-full justify-between">
                <div class="text-md">{{ t("common.keywords") }}</div>
                <div
                  [class.checked]="spForm.controls.targetingType.value === StrategyTargetingType.KEYWORDS"
                  class="radio-btn shrink-0"
                >
                  <div></div>
                </div>
              </div>
              <div class="text-gray-700">{{ t("sp-substrategy-creation.target_specific_keywords") }}</div>
            </div>
            <div
              [ngClass]="{
                'checkbox-card-selected': spForm.controls.targetingType.value === StrategyTargetingType.PRODUCTS,
              }"
              class="checkbox-card"
              (click)="toggleTargetingType(StrategyTargetingType.PRODUCTS)"
            >
              <div class="flex w-full justify-between">
                <div class="text-md">{{ t("common.products") }}</div>
                <div
                  [class.checked]="spForm.controls.targetingType.value === StrategyTargetingType.PRODUCTS"
                  class="radio-btn shrink-0"
                >
                  <div></div>
                </div>
              </div>
              <div class="text-gray-700">
                @if (strategyType === StrategyType.BRAND) {
                  <span>{{ t("sp-substrategy-creation.target_specific_products_from_your_catalog") }}</span>
                }
                @if (strategyType === StrategyType.KEYWORD) {
                  <span>{{ t("sp-substrategy-creation.target_specific_amazon_products") }}</span>
                }
              </div>
            </div>
          </div>
          @if (spForm.controls.targetingType.value === StrategyTargetingType.KEYWORDS) {
            <small>{{ t("sp-substrategy.kw_per_start", [maxKwTargetingByStrategy]) }}</small>
            <div class="content strategy-keywords">
              <app-strategy-keyword
                [keywords]="spForm.controls.keywords.value!"
                [marketplace]="accountMarketplace.marketplace"
                (updatedKeywords)="updateKeywords($event)"
              >
              </app-strategy-keyword>
            </div>
          }
          @if (spForm.controls.targetingType.value === StrategyTargetingType.PRODUCTS) {
            <small>{{ t("sp-substrategy.asin_per_start", [maxAsinTargetingByStrategy]) }}</small>
            <div class="content strategy-product-targetings h-[45rem]">
              <app-asins-selection
                class="h-full"
                [asins]="spForm.controls.productTargetings.value!"
                [accountId]="accountMarketplace.accountId"
                [marketplace]="accountMarketplace.marketplace"
                [selectionModes]="productTargetingSelectionModes!"
                [allowAsinFromOtherCatalog]="productTargetingsAllowAsinFromOtherCatalog!"
                (onAdd)="addProductTargeting($event)"
                (onDelete)="deleteProductTargeting($event)"
                [csvExport]="false"
                [maxProducts]="maxAsinTargetingByStrategy"
                bulkPlaceholderText="{{ t('sp-substrategy.targeted_asins_placehoder', [maxAsinTargetingByStrategy]) }} "
              >
              </app-asins-selection>
            </div>
          }
        </div>
      }
      @if (withMultipleTargetings) {
        <div
          class="card-common mb-3 p-3"
          [class.border-danger]="
            formSubmitted && (spForm.controls.keywords.invalid || spForm.controls.productTargetings.invalid)
          "
        >
          <div class="mb-4">
            <div class="flex w-full justify-between">
              <h3>{{ t("sd-strategy-creation.targetings") }}</h3>
              <IButton
                color="white"
                label="{{ t('sp-substrategy-creation.import_from_brand') }}"
                (onClick)="importFromBrandModal()"
              />
            </div>
            @if (formSubmitted && spForm.controls.keywords.invalid) {
              <span class="danger">{{ t("sp-substrategy-creation.target_at_least_one_keyword_or_product") }}</span>
            }
          </div>
          <h4>{{ t("common.keywords") }}</h4>
          <small>{{ t("sp-substrategy.kw_per_start", [maxKwTargetingByStrategy]) }}</small>
          <div class="content strategy-keywords">
            <app-strategy-keyword
              [matchType]="MatchType.phrase"
              [keywords]="spForm.controls.keywords.value!"
              [marketplace]="accountMarketplace.marketplace"
              (updatedKeywords)="updateKeywords($event)"
            >
            </app-strategy-keyword>
          </div>
          <h4>{{ t("common.products") }}</h4>
          <small>{{ t("sp-substrategy.asin_per_start", [maxAsinTargetingByStrategy]) }}</small>
          <div class="content strategy-product-targetings h-[45rem]">
            <app-asins-selection
              class="h-full"
              [asins]="spForm.controls.productTargetings.value!"
              [accountId]="accountMarketplace.accountId"
              [marketplace]="accountMarketplace.marketplace"
              [selectionModes]="productTargetingSelectionModes!"
              [allowAsinFromOtherCatalog]="productTargetingsAllowAsinFromOtherCatalog!"
              (onAdd)="addProductTargeting($event)"
              (onDelete)="deleteProductTargeting($event)"
              [csvExport]="false"
              [maxProducts]="maxAsinTargetingByStrategy"
              bulkPlaceholderText="{{ t('sp-substrategy.targeted_asins_placehoder', [maxAsinTargetingByStrategy]) }} "
            >
            </app-asins-selection>
          </div>
        </div>
      }
    </div>
    <div class="form-panel card-common ml-3">
      <div>
        <h4>{{ t("sp-substrategy-creation.new_strategy", [strategyTypeStr | titlecase]) }}</h4>
      </div>
      @if (formSubmitted && spForm.invalid && invalidFields) {
        <div class="alert alert-danger">
          <span class="py-2">{{ t("account360-dashboard-creation.dashboard_creation_error") }}: </span>
          @for (c of invalidFields; track c) {
            <span>{{ c + (invalidFields.indexOf(c) !== invalidFields.length - 1 ? ", " : "") }}</span>
          }
        </div>
      }
      <div>
        <IButton label="{{ t('common.create') }}" type="submit" [block]="true" class="mb-2" />
        <IButton
          [icon]="ICON_ARROW"
          label="{{ t('sp-substrategy-creation.back_to_strategy_group') }}"
          to=".."
          variant="ghost"
          color="gray"
          [block]="true"
        />
      </div>
    </div>
  </form>
</ng-container>
