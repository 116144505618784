import { Component, Input } from "@angular/core";
import { BulkError } from "@m19-board/strategies/strategy-bulk-upload-modal/bulk-import.service";
import { BoostUpdate } from "./strategy-boost-bulk-upload.component";

@Component({
  selector: "app-strategy-boost-bulk-report",
  template: ` <div class="mt-2 flex max-h-[800px] flex-col items-start justify-between gap-4 overflow-y-scroll p-6">
    <div class="w-rounded-sm bg-main-100 text-main-700 w-full px-4 py-1">
      <div class="font-semibold">Updates</div>
      @for (boostUpdate of boostUpdates; track boostUpdate.strategyId) {
        <div class="m-3">
          <div class="font-mono text-xs">
            <span class="font-semibold">Line {{ boostUpdate.lineIndex }}:</span>{{ boostUpdate.csvRow }}
          </div>
          <div>
            <span class="font-semibold">Strategy ID {{ boostUpdate.strategyId }}:</span> Boost
            {{ boostUpdate.boost ? boostUpdate.boost + "%" : "deactivated" }}
          </div>
        </div>
      } @empty {
        <p class="font-semibold">No boost updates</p>
      }
    </div>
    <div class="w-rounded-sm w-full bg-red-100 px-4 py-1 text-red-700">
      <div class="font-semibold">Errors</div>
      @for (error of errors; track error.lineIndex) {
        <div class="m-3">
          <div class="font-mono text-xs">
            <span class="font-semibold">Line {{ error.lineIndex }}: </span>{{ error.csvRow }}
          </div>
          <div>
            Error:
            {{ error.errors.join("") }}
          </div>
        </div>
      } @empty {
        <p class="font-semibold">No errors found</p>
      }
    </div>
  </div>`,
  standalone: true,
})
export class StrategyBoostBulkReportComponent {
  @Input()
  boostUpdates!: BoostUpdate[];
  @Input()
  errors!: BulkError[];
}
