import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatTooltip } from "@angular/material/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
export enum SwitchButtonType {
  TEXT,
  ICON,
}

@Component({
  selector: "app-switch-button",
  imports: [FontAwesomeModule, MatTooltip, TranslocoRootModule],
  standalone: true,
  templateUrl: "./switch-button.component.html",
  styleUrls: ["./switch-button.component.scss"],
})
export class SwitchButtonComponent<T> {
  readonly SwitchButtonType = SwitchButtonType;

  @Input() type = SwitchButtonType.TEXT;
  @Input() testids: string[] = [];
  @Input() options: T[];
  //takes transloco keys
  @Input() buttonTexts: string[];
  @Input() buttonIcons: string[];
  @Input() buttonTooltips: string[];
  @Input() tooltipTexts: string[];
  @Input() set selected(s: T) {
    if (!s) {
      this._selected = this.options[0];
    }
    this._selected = s;
    this.selectedIndex = this.options.indexOf(s);
  }

  _selected: T;

  selectedIndex = 0;

  @Output() buttonClicked = new EventEmitter<T>();

  selectButton(index: number) {
    this._selected = this.options[index];
    this.selectedIndex = index;
    this.buttonClicked.emit(this._selected);
  }
}
