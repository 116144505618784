import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import { Component, computed, input } from '@angular/core';

@Component({
  selector: 'IAlert',
  standalone: true,
  imports: [NgClass],
  templateUrl: './ialert.component.html',
  animations: [
    trigger('bounce', [
      transition(':enter', [
        animate(
          '100ms',
          keyframes([
            style({ transform: 'scale(0.95)', offset: 0, opacity: 0 }),
            style({ transform: 'scale(1)', offset: 1, opacity: 1 }),
          ]),
        ),
      ]),
    ]),
  ],
})
export class IAlertComponent {
  type = input<'info' | 'success' | 'warning' | 'error'>('info');
  title = input<string>('');
  animated = input(true);

  color = computed(() => {
    switch (this.type()) {
      case 'info':
        return 'gray';
      case 'success':
        return 'green';
      case 'warning':
        return 'orange';
      case 'error':
        return 'red';
    }
  });

  icon = computed(() => {
    switch (this.type()) {
      case 'info':
        return 'icon-[mdi--information-box]';
      case 'success':
        return 'icon-[mdi--check-circle]';
      case 'warning':
      case 'error':
        return 'icon-[mdi--warning-circle]';
    }
  });

  alertClasses = computed(() => {
    return `bg-${this.color()}-50 dark:bg-${this.color()}-400 dark:bg-opacity-10 text-${this.color()}-500 dark:text-${this.color()}-400 w-full relative overflow-hidden p-4 rounded-lg`;
  });
}
