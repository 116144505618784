import { Component, Input } from "@angular/core";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-limit-warning",
  templateUrl: "./limit-warning.component.html",
})
export class LimitWarningComponent {
  @Input()
  current: number;
  @Input()
  limit: number;
  @Input()
  message;

  readonly faWarning = faExclamationTriangle;

  label: string;
}
