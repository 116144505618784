import { Marketplace, Strategy } from '../api-client';

// explanation of this type
// it is a subset of strategy fields (Pick)
// they are optional (Partial)
// and some fields are required (accountId, marketplace...)

export type StrategyUpdateParams = {
  accountId: string;
  marketplace: Marketplace;
  strategyId: number;
  asinsToAdd: string[];
  asinsToDelete: string[];
} & Partial<
  Pick<
    Strategy,
    | 'name'
    | 'state'
    | 'strategyLabel'
    | 'name'
    | 'strategyLabel'
    | 'algoMode'
    | 'acosTarget'
    | 'suggestedBid'
    | 'dailyBudget'
    | 'monthlyBudget'
    | 'nextMonthlyBudget'
    | 'minDailySpend'
    | 'disableOtherQueries'
    | 'disableAutoSegment'
    | 'disableProductSegment'
    | 'primeDayBoost'
    | 'daypartingPauseHour'
    | 'daypartingReactivationHour'
    | 'priority'
  >
>;

export function isUpdating(strategyUpdate: StrategyUpdateParams) {
  for (const key in strategyUpdate) {
    if (key === 'strategyId' || key === 'accountId' || key == 'marketplace') continue;
    if (key === 'asinsToAdd' || key === 'asinsToDelete') {
      if (strategyUpdate[key].length > 0) {
        return true;
      } else {
        continue;
      }
    }
    if (strategyUpdate[key as keyof StrategyUpdateParams] !== undefined) return true;
  }
  return false;
}
