<div class="flex flex-col justify-start gap-y-2" *transloco="let t">
  <div class="flex items-center justify-between">
    <span class="font-medium">{{ t("day-parting-inputs.enable_day_parting") }}</span>
    <app-switch-input
      [disabled]="disabled"
      [checked]="enabledDayParting"
      switchId="disableDayPartingSPSwitch"
      (onInput)="switchDisableDayParting($event)"
    />
  </div>
  @if (enabledDayParting) {
    <div>
      <div class="mt-1 flex content-start items-center pl-0">
        <div>
          <span>{{ t("day-parting-inputs.disable_strategy_from") }} </span>
          <select
            id="inputPauseHourDayParting"
            name="pauseHours"
            [(ngModel)]="daypartingPauseHour"
            (change)="switchDisableDayParting(true)"
          >
            @for (i of HOURS; track i) {
              <option [ngValue]="i" [disabled]="disabled">{{ i }}:00</option>
            }
          </select>
          <span> {{ t("day-parting-inputs.to_hours") }} </span>
          <select
            id="inputReactivationHourDayParting"
            name="reactivationHours"
            [(ngModel)]="daypartingReactivationHour"
            (change)="switchDisableDayParting(true)"
          >
            @for (i of HOURS; track i) {
              <option [ngValue]="i" [disabled]="disabled">{{ i }}:00</option>
            }
          </select>
          @if (timeZone) {
            <small class="mt-1 text-sm">{{
              t("hourly-chart.hours_displayed", [getTimezone(), getTimezonUtcOffset()])
            }}</small>
          }
          @if (daypartingPauseHour === daypartingReactivationHour) {
            <div class="text-danger">{{ t("day-parting-inputs.start_and_end_hour_must_be_different") }}</div>
          }
        </div>
      </div>
      <div class="text-danger mt-2 flex items-center justify-start pl-0">
        <ul>
          <li>
            {{ t("day-parting-inputs.missed_ad_sales") }}
            {{ totalAdSalesOnPeriod | currency: currencyCode : "symbol" : "1.2-2" : locale }}
            @if (totalAdSales !== 0) {
              <span>({{ totalAdSalesOnPeriod / totalAdSales | percent: "1.2-2" }})</span>
            }
          </li>
          <li>
            {{ t("day-parting-inputs.saved_spend") }}
            {{ totalCostOnPeriod | currency: currencyCode : "symbol" : "1.2-2" : locale }}
            @if (totalCost !== 0) {
              <span>({{ totalCostOnPeriod / totalCost | percent: "1.2-2" }})</span>
            }
          </li>
          @if (totalAdSalesOnPeriod !== 0) {
            <li>
              {{ t("day-parting-inputs.acos_on_the_paused_period") }}
              {{ totalCostOnPeriod / totalAdSalesOnPeriod | percent: "1.2-2" }}
            </li>
          }
          @if (strategy.acosTarget) {
            <li>{{ t("day-parting-inputs.target_acos") }} {{ strategy.acosTarget | percent: "1.2-2" }}</li>
          }
        </ul>
      </div>
    </div>
  }
</div>
