import { Component } from "@angular/core";
import { StatsOverlayComponent } from "@m19-board/overlay/stats-overlay.component";
import { RouterOutlet } from "@angular/router";

@Component({
  template: ` <app-stats-overlay class="block h-full">
    <router-outlet></router-outlet>
  </app-stats-overlay>`,
  styleUrls: ["./overlay.component.scss"],
  standalone: true,
  imports: [StatsOverlayComponent, RouterOutlet],
})
export class StatsOverlayWrapperComponent {}
