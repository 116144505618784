<input
  type="text"
  class="form-control"
  [placeholder]="placeholder"
  [attr.aria-label]="placeholder"
  (input)="inputValue$.next(inputField.value.toLowerCase())"
  [value]="inputValue$ | async"
  (keydown)="keydown($event)"
  (focusin)="setFocusIn()"
  (focusout)="setFocusOut()"
  #inputField
/>
@if (suggestions.length > 0 && focus) {
  <div class="dropdown-menu block w-full overflow-auto">
    @for (suggestion of suggestions; track suggestion; let index = $index) {
      <div
        class="autocomplete-dropdown-item dropdown-item flex"
        (click)="selectValue(suggestion)"
        [ngClass]="{ 'hovered-item': hoveredItemIndex === index }"
      >
        <ng-container *ngTemplateOutlet="itemTemplateRef; context: { $implicit: suggestion }"> </ng-container>
      </div>
    }
  </div>
}
