<ng-container *transloco="let t">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      {{
        t("update-max-bid.max_bid") + " - " + accountMarketplace.accountName + " - " + accountMarketplace.marketplace
      }}
    </h4>
  </div>
  <div class="modal-body">
    <div class="form-group row mt-2 items-center">
      <span class="prepend col-2">{{ t("update-max-bid.max_bid") }}</span>
      <div class="col-10">
        <app-decimal-input
          [value]="maxBid"
          [precision]="2"
          [min]="0"
          [trash]="false"
          [withActionButtons]="false"
          [unit]="currencySymbol"
          [placeholder]="'Please enter the new Max Bid value'"
          [size]="8"
          (onChange)="maxBid = $event"
        ></app-decimal-input>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
      {{ t("common.cancel") }}
    </button>
    <button type="submit" class="btn btn-primary" (click)="update()">{{ t("common.update") }}</button>
  </div>
</ng-container>
