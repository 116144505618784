<ng-container *transloco="let t">
  <app-stats-overlay [checkOrganicDataAccess]="true">
    @if (isManualManaging) {
      <div class="alert alert-warning my-2 w-full p-3 text-sm">
        {{ t("asins-selection.missing_an_asin") }}
        <a routerLink="/product-center/catalog" queryParamsHandling="merge" target="_blank">{{
          t("asins-selection.go_to_your_catalog_to_see_if_it_is_a_managed_asin")
        }}</a
        >.
      </div>
    }
    @if (lowInventoryNumber > 0) {
      <div class="inventory-warning alert alert-warning" role="alert">
        <div>
          <b>{{ t("inventory.low_inventory_warning_title", { count: lowInventoryNumber }) }}</b>
          @if (lowInventoryPaused === 0) {
            <span> {{ t("inventory.low_inventory_warning", { count: lowInventoryPaused }) }}</span>
          }
          @if (lowInventoryPaused > 0) {
            <span> {{ t("inventory.low_inventory_paused", { count: lowInventoryPaused }) }}</span>
          }
        </div>
        <div>
          <button
            class="btn {{ onlyLowInventory ? 'btn-warning' : 'btn-outline-warning' }}"
            title="{{
              onlyLowInventory
                ? t('inventory.filter_low_inventory_tooltip_remove')
                : t('inventory.filter_low_inventory_tooltip_only')
            }}"
            (click)="toggleLowInventoryFilter()"
          >
            <fa-icon [icon]="faFilter" size="lg"></fa-icon>
          </button>
        </div>
      </div>
    }
    <div class="rounded-md py-4">
      <div class="mb-2 flex">
        <div class="flex w-full flex-row justify-between">
          <div class="ml-2 flex-1">
            <app-filter [filters]="filters()" (filterChange)="applyFilter($event)" />
          </div>
          <div class="mr-2 flex gap-1">
            <IButton
              label="{{ t('inventory-grid.set_pause_limit_for_all_asins') }}"
              color="white"
              icon="icon-[mdi--pencil]"
              (click)="setupRuleForAllAsins()"
            ></IButton>
            <IButton
              tooltipValue="{{ t('common.restore_default_columns') }}"
              label="{{ t('common.restore_columns') }}"
              color="white"
              (onClick)="restoreDefaultColumns()"
            />
            <app-export-button
              (export)="downloadFile()"
              tooltipValue="{{ t('common.export_as_csv') }}"
            ></app-export-button>
          </div>
        </div>
      </div>
      <ag-grid-angular
        #grid
        class="ag-theme-quartz base-theme m-2 h-[70vh] flex-1"
        [rowData]="dataSource"
        [gridOptions]="gridOptions"
        (gridReady)="onGridReady($event)"
      ></ag-grid-angular>
    </div>
  </app-stats-overlay>
</ng-container>
