<form [formGroup]="form" (ngSubmit)="onSubmit()" *transloco="let t">
  <div class="flex flex-col gap-4">
    <IInput
      size="lg"
      type="email"
      [label]="t('request-password-reset.password_reset_description')"
      id="email"
      icon="icon-[mdi--email]"
      autocomplete="on"
      [control]="form.controls.email"
      [error]="formErrors().email"
    />
  </div>
  <IButton
    class="mt-10"
    type="submit"
    size="lg"
    [label]="t('request-password-reset.send_button')"
    [block]="true"
    [loading]="isLoading"
    [disabled]="isLoading || form.invalid"
  />
</form>
