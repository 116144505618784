<ng-container *transloco="let t">
  <div class="modal-header bg-main-500">
    <h4 class="modal-title pull-left">
      {{ t("product-group-bulk-upload-report-modal.product_group_upload_summary") }}
    </h4>
    <a>
      <h4 class="modal-title pull-right" (click)="bsModalRef.hide()">&times;</h4>
    </a>
  </div>
  <div class="m-4">
    <!-- Product Group creations -->
    <div class="alert alert-success overflow-auto">
      <h5>
        {{
          t("product-group-bulk-upload-report-modal.product_group_number", {
            count: productGroupBulkOperations.creations.length,
          })
        }}
      </h5>
      @if (productGroupBulkOperations.creations.length > 0) {
        <table
          mat-table
          [dataSource]="creationsDataSource"
          class="table-striped-two table-hover mt-4 table"
          multiTemplateDataRows
        >
          <ng-container matColumnDef="lineIndex">
            <td *matCellDef="let creation" class="lineIndex py-0">#{{ creation.lineIndex }}</td>
          </ng-container>
          <ng-container matColumnDef="row">
            <td *matCellDef="let creation" colspan="90%" class="csvRow coding-font py-0">
              {{ creation.csvRow }}
            </td>
          </ng-container>
          <ng-container matColumnDef="select">
            <th *matHeaderCellDef></th>
            <td *matCellDef="let creation">
              <span
                class="select-button-box"
                matTooltip="{{
                  t('product-group-bulk-upload-report-modal.select_this_product_group_creation_operation')
                }}"
              >
                <fa-icon
                  [icon]="isProductGroupCreationSelected(creation) ? faCheckedSquare : faSquare"
                  size="lg"
                  (click)="toggleProductGroupCreation(creation)"
                ></fa-icon>
              </span>
            </td>
          </ng-container>
          @for (f of genericBulkCsvFields; track f) {
            <ng-container [matColumnDef]="f.field">
              <th *matHeaderCellDef>{{ f.title }}</th>
              <td *matCellDef="let creation">
                {{ f.csvField.value(creation.productGroup) }}
              </td>
            </ng-container>
          }
          <ng-container matColumnDef="items">
            <th *matHeaderCellDef>{{ t("common.asins") }}</th>
            <td *matCellDef="let creation" class="flex flex-col text-left">
              @for (asin of creation.productGroup.items.slice(0, 3); track asin) {
                <div>
                  <a [href]="asin | amazonAsinUrlPipe: marketplace" target="_blank">
                    {{ asin }}
                  </a>
                </div>
              }
              @if (creation.productGroup.items.length > 3) {
                <div>{{ t("creative-display.and_creative_more", [creation.productGroup.items.length - 3]) }}</div>
              }
              <div>
                <a href="#" (click)="showProductDetails(creation.productGroup.items, $event)">{{
                  t("product-group-bulk-upload-report-modal.more_details")
                }}</a>
              </div>
            </td>
          </ng-container>
          <tr mat-row *matRowDef="let row; columns: ['lineIndex', 'row']" class="lineIndexRow"></tr>
          <tr mat-header-row *matHeaderRowDef="creationColumns" class="header"></tr>
          <tr mat-row *matRowDef="let row; columns: creationColumns"></tr>
        </table>
      }
    </div>
    <!-- Strategy updates -->
    <div class="alert alert-success overflow-auto">
      <h5>
        {{
          t("product-group-bulk-upload-report-modal.update_product_group", {
            count: productGroupBulkOperations.updates.length,
          })
        }}

        @if (productGroupBulkOperations.updates.length > 0) {
          <span>{{ t("product-group-bulk-upload-report-modal.updated_highlithed") }}</span>
        }
      </h5>
      @if (productGroupBulkOperations.updates.length > 0) {
        <table
          mat-table
          [dataSource]="updateDataSource"
          class="table-striped-two table-hover mt-4 table"
          multiTemplateDataRows
        >
          <ng-container matColumnDef="lineIndex">
            <td *matCellDef="let update" class="lineIndex py-0">#{{ update.lineIndex }}</td>
          </ng-container>
          <ng-container matColumnDef="row">
            <td *matCellDef="let update" colspan="90%" class="csvRow coding-font py-0">
              {{ update.csvRow }}
            </td>
          </ng-container>
          <ng-container matColumnDef="select">
            <th *matHeaderCellDef></th>
            <td *matCellDef="let update">
              <span
                class="select-button-box"
                matTooltip="{{
                  t('product-group-bulk-upload-report-modal.select_this_product_group_update_operation')
                }}"
              >
                <fa-icon
                  [icon]="isProductGroupUpdateSelected(update) ? faCheckedSquare : faSquare"
                  size="lg"
                  (click)="toggleProductGroupUpdate(update)"
                ></fa-icon>
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="productGroupId">
            <th *matHeaderCellDef>{{ t("product-group-bulk-upload-report-modal.product_group_id") }}</th>
            <td *matCellDef="let update">
              {{ update.productGroupToUpdate.productGroupId }}
            </td>
          </ng-container>
          @for (f of genericBulkCsvFields; track f) {
            <ng-container [matColumnDef]="f.field">
              <th *matHeaderCellDef>{{ f.title }}</th>
              <td *matCellDef="let update" [ngClass]="{ selected: update.updatedFields[f.field] !== undefined }">
                @if (update.updatedFields[f.field] !== undefined) {
                  <span
                    ><del>{{ f.csvField.value(update.productGroupToUpdate) }}</del></span
                  >
                }
                {{
                  update.updatedFields[f.field] !== undefined
                    ? f.csvField.value(update.updatedFields)
                    : f.csvField.value(update.productGroupToUpdate)
                }}
              </td>
            </ng-container>
          }
          <ng-container matColumnDef="items">
            <th *matHeaderCellDef>{{ t("common.asins") }}</th>
            <td
              *matCellDef="let update"
              class="flex flex-col text-left"
              [ngClass]="{
                selected: update.updatedFields.asinsToAdd.length > 0 || update.updatedFields.asinsToDelete.length > 0,
              }"
            >
              @if (update.updatedFields.asinsToAdd.length === 0 && update.updatedFields.asinsToDelete.length === 0) {
                <span>-</span>
              }
              @if (update.updatedFields.asinsToAdd.length > 0 || update.updatedFields.asinsToDelete.length > 0) {
                <div>
                  <div>
                    {{ t("common.n_new_asins", { count: update.updatedFields.asinsToAdd.length }) }}
                  </div>
                  <div>
                    {{ t("common.n_deleted_asins", { count: update.updatedFields.asinsToDelete.length }) }}
                  </div>
                </div>
              }
              <div>
                <a href="#" (click)="showProductDetailsForUpdate(update, $event)">{{
                  t("product-group-bulk-upload-report-modal.more_details")
                }}</a>
              </div>
            </td>
          </ng-container>
          <tr mat-row *matRowDef="let row; columns: ['lineIndex', 'row']" class="lineIndexRow"></tr>
          <tr mat-header-row *matHeaderRowDef="updateColumns" class="header"></tr>
          <tr mat-row *matRowDef="let row; columns: updateColumns"></tr>
        </table>
      }
    </div>
    @if (productGroupBulkOperations.errors.length > 0) {
      <div class="alert alert-danger">
        <h5>
          {{
            t("product-group-bulk-upload-report-modal.line_discarded", {
              count: productGroupBulkOperations.errors.length,
            })
          }}
        </h5>
        @for (error of productGroupBulkOperations.errors; track error) {
          <div>
            <div class="csvRowError coding-font">#{{ error.lineIndex }} {{ error.csvRow }}</div>
            <div>
              <ul>
                @for (errorMsg of error.errors; track errorMsg) {
                  <li>{{ errorMsg }}</li>
                }
              </ul>
            </div>
          </div>
        }
      </div>
    }
    <div class="flex justify-end">
      <IButton label="Back" color="white" (onClick)="cancel()" />
      <IButton
        class="ml-2"
        label="{{ t('common.continue') }}"
        tooltipValue="{{ t('product-group-bulk-upload-report-modal.upload_selected_data') }}"
        [disabled]="selectedProductGroupCreation.size === 0 && selectedProductGroupUpdate.size === 0"
        (onClick)="uploadData()"
      />
    </div>
  </div>
</ng-container>
