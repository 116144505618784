import { Component, inject } from "@angular/core";
import { Router } from "@angular/router";
import { faPlusCircle, faRocket } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AuthService, BillingService, KeywordTrackingService, StatsService } from "@front/m19-services";
import { BoardType, LayoutSelectorService } from "@m19-board/layout-selector.service";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { SettingStatus } from "../../models/Billing";
import { ModalService, LinkStripeCardModalComponent, IButtonComponent } from "@front/m19-ui";
import { BillingCustomerComponent } from "./customer/billing-customer.component";
import { toSignal } from "@angular/core/rxjs-interop";
import { map, tap } from "rxjs/operators";
import { AccessLevel, AccountState, DailyAdSpendFee, KeywordTrackerQuota, Plan, Customer } from "@front/m19-api-client";
import { OrganizationAccountGroups } from "@front/m19-models";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { AsyncPipe, TitleCasePipe } from "@angular/common";
import { DropdownToggleIconComponent } from "@m19-board/shared/expand-icon/dropdown-toggle-icon.component";
import { IAccordionComponent } from "@front/m19-ui";
import { SubscriptionComponentCardComponent } from "@m19-board/settings/billing-settings/subscription-card/subscription-card.component";
import { MatTooltip } from "@angular/material/tooltip";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { CreditCardInfoComponent } from "@m19-board/settings/billing-settings/credit-card-info/credit-card-info.component";
import { BillingInvoicesComponent } from "@m19-board/settings/billing-settings/invoices/billing-invoices.component";
import { OnboardingCtaComponent } from "@m19-board/containers/board-layout/board-sidebar-v2/onboarding-cta.component";
import { environment } from "apps/m19-board/src/environments/environment";

@UntilDestroy()
@Component({
  selector: "app-billing-settings",
  templateUrl: "billing-settings.component.html",
  styleUrls: ["./billing-settings.component.scss"],
  standalone: true,
  imports: [
    TranslocoDirective,
    AsyncPipe,
    DropdownToggleIconComponent,
    IAccordionComponent,
    SubscriptionComponentCardComponent,
    MatTooltip,
    FaIconComponent,
    CreditCardInfoComponent,
    BillingInvoicesComponent,
    BillingCustomerComponent,
    OnboardingCtaComponent,
    TitleCasePipe,
    IButtonComponent,
  ],
})
export class BillingSettingsComponent {
  private readonly authService = inject(AuthService);
  private readonly billingService = inject(BillingService);
  private readonly modalService = inject(BsModalService);
  private readonly newModalService = inject(ModalService);
  private readonly statsService = inject(StatsService);
  private readonly layoutSelector = inject(LayoutSelectorService);
  private readonly keywordTrackerService = inject(KeywordTrackingService);
  private readonly toasterService = inject(ToastrService);
  private readonly translocoService = inject(TranslocoService);

  organizationVisible: Map<number, boolean> = new Map();
  invoiceVisible: Map<number, boolean> = new Map();
  infoVisible: Map<number, boolean> = new Map();
  creditCardVisible: Map<number, boolean> = new Map();

  locale$ = this.authService.loggedUser$.pipe(map((u) => u.locale));

  whiteLabel = this.layoutSelector.getBoardType() !== BoardType.M19;
  private user$ = this.authService.user$;
  user = toSignal(this.user$);

  organizationKeywordMap: Map<number, Map<string, KeywordTrackerQuota>> = new Map();

  organizations$ = this.billingService.organizations$.pipe(
    map((organizations: OrganizationAccountGroups[]) => {
      return this.whiteLabel
        ? organizations.filter(
            (x) =>
              x.isParentPpcBoard ||
              (x.hasSubscription() &&
                x.accountGroups
                  .flatMap((ag) => ag.getAccountMarketplaces())
                  .filter((am) => am.state == AccountState.BIDDER_ON).length > 0),
          )
        : organizations;
    }),
    tap((organizations: OrganizationAccountGroups[]) => {
      let visible = true;

      this.organizationVisible.clear();
      this.invoiceVisible.clear();
      this.infoVisible.clear();
      this.creditCardVisible.clear();

      organizations.forEach((item) => {
        this.organizationVisible.set(item.id, visible);
        this.invoiceVisible.set(item.id, true);
        this.infoVisible.set(item.id, !!item.customer);
        this.creditCardVisible.set(item.id, true);
        visible = false;
      });

      this.initDailyHourlyKeywordMap(organizations);
    }),
  );

  dailyAdSpendFeeMap$ = this.statsService.getDailyAdSpendFee().pipe(
    map((data: DailyAdSpendFee[]) => {
      const dailyAdSpendFeeMap = new Map<number, DailyAdSpendFee[]>();
      data.forEach((dailyAdSpend) => {
        // move self service ad spend fee to parent organization
        // billing is by parent organization
        const orgId = dailyAdSpend.parentOrganizationId
          ? dailyAdSpend.parentOrganizationId
          : dailyAdSpend.organizationId!;

        if (!dailyAdSpendFeeMap.has(orgId)) {
          dailyAdSpendFeeMap.set(orgId, []);
        }
        dailyAdSpendFeeMap.get(orgId)!.push(dailyAdSpend);
      });
      return dailyAdSpendFeeMap;
    }),
  );

  readonly faPlusCircle = faPlusCircle;
  readonly faRocket = faRocket;
  readonly SettingStatus = SettingStatus;

  canDisplayBilling(organization: OrganizationAccountGroups): boolean {
    return organization.accessLevel == AccessLevel.ADMIN;
  }

  updateCreditCard(customer: Customer): void {
    this.newModalService.openModal(LinkStripeCardModalComponent, {
      modalTitle: this.translocoService.translate("billing-settings.add_a_credit_card"),
      data: {
        stripePublicKey: environment.stripePublicKey,
        customer: customer,
        user: this.user()!,
      },
    });
  }

  hasACreditCard(organization: OrganizationAccountGroups): boolean {
    return !!organization.customer && !!organization.customer.stripeCardId;
  }

  isOrganizationOwner(organization: OrganizationAccountGroups): boolean {
    return organization.organization.ownerId == this.user()?.userId;
  }

  editCustomer(organization: OrganizationAccountGroups): void {
    const modalOptions: ModalOptions = {
      initialState: {
        settingStatus: SettingStatus.EDITION,
        customer: organization.customer,
        wizard: false,
      },
      class: "modal-primary modal-lg",
    };
    const modalRef = this.modalService.show(BillingCustomerComponent, modalOptions);
    modalRef.content?.onCancel.subscribe(() => modalRef.hide());
    modalRef.content?.onSave.subscribe(() => modalRef.hide());
  }

  createCustomer(organization: OrganizationAccountGroups): void {
    const modalOptions: ModalOptions = {
      initialState: {
        settingStatus: SettingStatus.CREATION,
        customer: organization.customer,
        wizard: false,
      },
      class: "modal-primary modal-lg",
    };
    const modalRef = this.modalService.show(BillingCustomerComponent, modalOptions);
    modalRef.content?.onCancel.subscribe(() => modalRef.hide());
    modalRef.content?.onSave.subscribe(() => modalRef.hide());
  }

  initDailyHourlyKeywordMap(organizations: OrganizationAccountGroups[]) {
    this.organizationKeywordMap = new Map();
    for (const organisation of organizations) {
      if (organisation.accessLevel !== AccessLevel.ADMIN) {
        continue;
      }
      this.keywordTrackerService.getDailyHourlyKeywordNumber(organisation.id).subscribe({
        next: (data) => {
          this.organizationKeywordMap.set(
            organisation.id,
            new Map(
              data.map((x: KeywordTrackerQuota) => {
                return [x.accountId?.toString() + "_" + x.marketplace?.toString() + "_" + x.frequency, x];
              }),
            ),
          );
        },
        error: (err) => {
          this.toasterService.error(err);
        },
      });
    }
  }
}
