// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    Marketplace,
} from './';

/**
 * @export
 * @interface DailyVendorAsinAllSales
 */
export interface DailyVendorAsinAllSales {
    /**
     * Vendor accountId
     * @type {string}
     * @memberof DailyVendorAsinAllSales
     */
    accountId?: string;
    /**
     * @type {Marketplace}
     * @memberof DailyVendorAsinAllSales
     */
    marketplace?: Marketplace;
    /**
     * @type {string}
     * @memberof DailyVendorAsinAllSales
     */
    date?: string;
    /**
     * @type {string}
     * @memberof DailyVendorAsinAllSales
     */
    asin?: string;
    /**
     * @type {number}
     * @memberof DailyVendorAsinAllSales
     */
    manufacturingUnitsSold?: number;
    /**
     * @type {number}
     * @memberof DailyVendorAsinAllSales
     */
    manufacturingSales?: number;
    /**
     * @type {number}
     * @memberof DailyVendorAsinAllSales
     */
    manufacturingCustomerReturns?: number;
    /**
     * @type {number}
     * @memberof DailyVendorAsinAllSales
     */
    manufacturingShippedCogs?: number;
    /**
     * @type {number}
     * @memberof DailyVendorAsinAllSales
     */
    sourcingUnitsSold?: number;
    /**
     * @type {number}
     * @memberof DailyVendorAsinAllSales
     */
    sourcingSales?: number;
    /**
     * @type {number}
     * @memberof DailyVendorAsinAllSales
     */
    sourcingCustomerReturns?: number;
    /**
     * @type {number}
     * @memberof DailyVendorAsinAllSales
     */
    sourcingShippedCogs?: number;
}
