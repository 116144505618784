import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare, faSearch, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { UserLimitationModalComponent } from "@m19-board/settings/user-management/organization-card/user-limitation-modal/user-limitation-modal.component";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { AccessLevel, AuthorizedAccess, OrganizationUser } from "@front/m19-api-client";
import { OrganizationUsersService } from "@front/m19-services";
import { Utils } from "@front/m19-utils";

@UntilDestroy()
@Component({
  templateUrl: "./manage-authorized-users-modal.component.html",
  styleUrls: ["manage-authorized-users-modal.component.scss"],
})
export class ManageAuthorizedUsersModalComponent implements OnInit {
  readonly faTrash = faTrashAlt;
  readonly faSearch = faSearch;
  readonly faSquare = faSquare;
  readonly faCheckSquare = faCheckSquare;
  readonly displayedColumns = ["userName", "email", "statsOnlyAccess", "readonlyMode", "readWriteMode", "revokeAccess"];
  readonly AccessLevel = AccessLevel;
  organizationId: number;
  profileId: number;
  userLimit: number;
  accountName: string;
  marketplace: string;
  email: string;
  accessLevel = AccessLevel.STATS_ONLY;

  organizationUsers: OrganizationUser[];
  authorizedAccess: AuthorizedAccess[];

  dataSource = new MatTableDataSource<AuthorizedAccess>([]);

  @ViewChild("paginator", { static: false }) set paginator(value: MatPaginator) {
    if (this.dataSource) this.dataSource.paginator = value;
  }

  @ViewChild("sort") set sort(value: MatSort) {
    if (this.dataSource) this.dataSource.sort = value;
  }

  constructor(
    private modalRef: BsModalRef,
    private organizationUsersService: OrganizationUsersService,
    private toasterService: ToastrService,
    private modalService: BsModalService,
  ) {
    this.dataSource.filterPredicate = (data, filter) => {
      const regexp = new RegExp(filter, "i");
      return regexp.test(data.userName) || regexp.test(data.email);
    };
  }

  ngOnInit(): void {
    this.organizationUsersService
      .listAuthorizedAccess()
      .pipe(untilDestroyed(this))
      .subscribe((access) => {
        this.authorizedAccess = access;
        this.dataSource.data = access.filter(
          (x) => x.profileId === this.profileId && x.organizationId === this.organizationId,
        );
      });
    this.organizationUsersService
      .listOrganizations()
      .pipe(untilDestroyed(this))
      .subscribe((organizationsUsers) => {
        this.organizationUsers = organizationsUsers;
      });
  }

  setEmail(email: string): void {
    this.email = email.trim();
  }

  setAccessLevelNewUser(accessLevel: AccessLevel) {
    this.accessLevel = accessLevel;
  }

  isValidEmail(): boolean {
    return Utils.isValidEmail(this.email);
  }

  addAuthorizedUser(): void {
    if (!this.isValidEmail()) return;
    if (this.userLimit && this.authorizedAccess.length + this.organizationUsers.length >= this.userLimit) {
      const modalOptions: ModalOptions = {
        initialState: {
          userLimit: this.userLimit,
        },
      };
      this.modalService.show(UserLimitationModalComponent, modalOptions);
      return;
    }
    this.organizationUsersService
      .addAuthorizedAccessAsync([
        {
          profileId: this.profileId,
          organizationId: this.organizationId,
          email: this.email,
          accessLevel: this.accessLevel,
        },
      ])
      .subscribe({
        next: () => {
          this.toasterService.success(
            "Successfully authorized " + this.email + " to access " + this.accountName + " - " + this.marketplace,
            "User authorization updated",
          );
        },
        error: (error) => {
          this.toasterService.error(error, "User authorization update error");
        },
      });
  }

  setAccessLevel(email: string, accessLevel: AccessLevel): void {
    this.organizationUsersService
      .addAuthorizedAccessAsync([
        {
          profileId: this.profileId,
          organizationId: this.organizationId,
          email,
          accessLevel,
        },
      ])
      .subscribe({
        next: () => {
          this.toasterService.success(
            "Successfully updated access of " + email + " to " + this.accountName + " - " + this.marketplace,
            "User authorization updated",
          );
        },
        error: (error) => this.toasterService.error(error, "User authorization update error"),
      });
  }

  revokeAccess(email: string): void {
    const authorizedAccess: AuthorizedAccess = {
      organizationId: this.organizationId,
      profileId: this.profileId,
      email: email,
    };
    this.organizationUsersService.revokeAuthorizedAccessAsync([authorizedAccess]).subscribe({
      next: () => {
        this.toasterService.success(
          "Successfully revoked access from " + email + " to " + this.accountName + " - " + this.marketplace,
          "Access has been revoked",
        );
      },
      error: (error) => this.toasterService.error(error, "User authorization update error"),
    });
  }

  changeAuthorizedUserFilter(query: string): void {
    this.dataSource.filter = (query ?? "").trim();
  }

  close(): void {
    this.modalRef.hide();
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
