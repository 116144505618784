// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    EntityIdType,
    EntityType,
    Marketplace,
} from './';

/**
 * @export
 * @interface History
 */
export interface History {
    /**
     * @type {number}
     * @memberof History
     */
    id?: number;
    /**
     * @type {number}
     * @memberof History
     */
    m19userId?: number;
    /**
     * @type {string}
     * @memberof History
     */
    userName?: string;
    /**
     * @type {string}
     * @memberof History
     */
    accountId?: string;
    /**
     * @type {Marketplace}
     * @memberof History
     */
    marketplace?: Marketplace;
    /**
     * @type {string}
     * @memberof History
     */
    action?: HistoryActionEnum;
    /**
     * @type {EntityType}
     * @memberof History
     */
    entityType?: EntityType;
    /**
     * @type {EntityIdType}
     * @memberof History
     */
    primaryType?: EntityIdType;
    /**
     * @type {string}
     * @memberof History
     */
    secondaryType?: HistorySecondaryTypeEnum;
    /**
     * @type {number}
     * @memberof History
     */
    primaryId?: number;
    /**
     * @type {number}
     * @memberof History
     */
    secondaryId?: number;
    /**
     * @type {string}
     * @memberof History
     */
    property?: string;
    /**
     * @type {string}
     * @memberof History
     */
    newValue?: string;
    /**
     * @type {string}
     * @memberof History
     */
    oldValue?: string;
    /**
     * @type {string}
     * @memberof History
     */
    timestamp: string;
    /**
     * @type {string}
     * @memberof History
     */
    comment?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum HistoryActionEnum {
    create = 'create',
    update = 'update',
    delete = 'delete'
}
/**
 * @export
 * @enum {string}
 */
export enum HistorySecondaryTypeEnum {
    Strategy = 'Strategy',
    Segment = 'Segment'
}

