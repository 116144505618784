import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, Observable, of, throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';

import {
  AccountMarketplace,
  AlgoMode,
  CampaignType,
  CreateStrategyGroupRequest,
  CreateStrategyRequest,
  MatchType,
  Response,
  Strategy,
  StrategyApi,
  StrategyGroup,
  StrategyGroupApi,
  StrategyStateEnum,
  StrategyTactic,
  StrategyType,
  Targeting,
  UpdateStrategyGroupBlacklistActionEnum,
  UpdateStrategyKwTargetingActionEnum,
  UpdateStrategyTopOfSearchRankingsActionEnum,
} from '@front/m19-api-client';
import { ProductGroupEx } from '@front/m19-models/ProductGroupEx';
import { AlgoModeStr, StrategyEx, StrategyTypeStr } from '@front/m19-models/StrategyEx';
import {
  StrategyGroupBlacklistUpdateParams,
  StrategyGroupEx,
  StrategyGroupUpdateParams,
} from '@front/m19-models/StrategyGroupEx';
import { catchAjaxError, currencyRateToEuro, marketplaceCurrency, Utils } from '@front/m19-utils';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AccountSelectionService, OrganizationAccountGroupService } from '.';
import { Constant } from './constant';
import { StrategyCache } from './strategy.cache';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private selectedAccountMarketplace: AccountMarketplace | undefined = undefined;

  private liveStrategiesLimit = 0;

  constructor(
    private strategyCache: StrategyCache,
    private strategyService: StrategyApi,
    accountSelectionService: AccountSelectionService,
    private organizationService: OrganizationAccountGroupService,
    private strategyGroupService: StrategyGroupApi,
  ) {
    accountSelectionService.singleAccountMarketplaceSelection$.subscribe((x) => {
      this.selectedAccountMarketplace = x;
    });
    combineLatest([
      accountSelectionService.singleAccountMarketplaceSelection$,
      this.organizationService.allOrganizationAccountGroups$,
    ]).subscribe(([selectedAccountMarketplace, allOrgs]) => {
      const selectedOrgs = allOrgs
        ? allOrgs.find((x) => x.id == selectedAccountMarketplace.resourceOrganizationId)
        : undefined;
      if (!selectedOrgs?.getBillingPlan()?.plan) {
        this.liveStrategiesLimit = 0;
        return;
      }
      this.liveStrategiesLimit = selectedOrgs.getBillingPlan()?.strategyLimit ?? +Infinity;
    });
  }

  public getNumberOfLiveStrategies(campaignType: CampaignType) {
    return this.strategyCache
      .getAllStrategies()
      .filter((s) => s.campaignType == campaignType && s.state == StrategyStateEnum.ENABLED).length;
  }

  public getLiveStrategyLimit() {
    return this.liveStrategiesLimit;
  }

  private isSBStrategyLimitReached(): boolean {
    const liveSBStrategies = this.getNumberOfLiveStrategies(CampaignType.SB);
    if (liveSBStrategies >= Constant.maxSbStrategies) {
      return true;
    } else {
      switch (this.liveStrategiesLimit) {
        case 0:
          return true;
        case +Infinity:
          return false;
        default:
          return liveSBStrategies >= this.liveStrategiesLimit;
      }
    }
  }

  public isStrategyLimitReached(campaignType: CampaignType): boolean {
    if (campaignType == CampaignType.SB) {
      return this.isSBStrategyLimitReached();
    }
    switch (this.liveStrategiesLimit) {
      case 0:
        return true;
      case +Infinity:
        return false;
      default:
        return this.getNumberOfLiveStrategies(campaignType) >= this.liveStrategiesLimit;
    }
  }

  public getStrategyGroupById(strategyGroupId: number): Observable<StrategyGroupEx | undefined> {
    return this.strategyCache.strategyGroupIndex$.pipe(map((index) => index.get(strategyGroupId)));
  }

  /**
   *
   * @deprecated used only by new strategy service
   */
  updateStrategyTactic(strategyId: number, strategyTactic: StrategyTactic) {
    const strategy = this.strategyCache.get(strategyId)!;
    if (!strategy.tactics.includes(strategyTactic)) {
      strategy.tactics.push(strategyTactic);
    }
    if (strategy.strategyGroupId && strategy.strategyType == StrategyType.PRODUCT) {
      const strategyGroup = this.strategyCache.getStrategyGroupIndex(strategy.strategyGroupId)!;
      strategyGroup.productStrategies = strategyGroup.productStrategies.map((s) =>
        s.strategyId == strategy.strategyId ? strategy : s,
      );
      this.strategyCache.strategyGroupIndexSubjectNext();
    }
    this.strategyCache.buildAccountConfig();
  }

  /**
   *
   * @deprecated used only by new strategy service
   */
  updateStrategyAsins(strategy: Strategy, newAsins: string[], updateNewStrategyCache = false) {
    if (updateNewStrategyCache) {
      this.strategyCache.strategyListCache.update(
        { accountId: strategy.accountId, marketplace: strategy.marketplace },
        (strategies) => {
          const toUpdate = strategies.get(strategy.strategyId!);
          if (!toUpdate) {
            strategies.set(strategy.strategyId!, strategy);
            return strategies;
          }
          toUpdate.asins = newAsins.map((a) => ({ asin: a }));
          return strategies;
        },
      );
    }
    const strategyEx = this.strategyCache.get(strategy.strategyId!)!;
    // create a copy of the StrategyEx object
    const modifiedStrat = Object.assign(
      new StrategyEx(strategyEx, this.strategyCache.getCreativesMap(strategy.strategyId!)),
      strategyEx,
    );
    modifiedStrat.asins = newAsins.flatMap((a) => ({ asin: a }));
    this.strategyCache.updateStrategy(modifiedStrat);
    if (
      modifiedStrat.strategyGroupId! > 0 &&
      this.strategyCache.hasStrategyGroupIndex(modifiedStrat.strategyGroupId!)
    ) {
      const strategyGroup = this.strategyCache.getStrategyGroupIndex(modifiedStrat.strategyGroupId!)!;
      switch (modifiedStrat.strategyType) {
        case StrategyType.BRAND:
          strategyGroup.brandStrategies = strategyGroup.brandStrategies.map((s) =>
            s.strategyId == modifiedStrat.strategyId ? modifiedStrat : s,
          );
          break;
        case StrategyType.KEYWORD:
          strategyGroup.keywordStrategies = strategyGroup.keywordStrategies.map((s) =>
            s.strategyId == modifiedStrat.strategyId ? modifiedStrat : s,
          );
          break;
        case StrategyType.PRODUCT:
          strategyGroup.productStrategies = strategyGroup.productStrategies.map((s) =>
            s.strategyId == modifiedStrat.strategyId ? modifiedStrat : s,
          );
          break;
      }
      const asins = new Set(strategyGroup.productStrategies.flatMap((s) => s.asins.map((a) => a.asin!)));

      strategyGroup!.asins = Array.from(asins.values());
      this.strategyCache.strategyGroupIndexSubjectNext();
    }
  }

  private addAsinsToStrategy(strategy: Strategy, asins: string[]): Observable<Response> {
    if (strategy.asins!.length + asins.length >= ProductGroupEx.MaxProductGroupItems) {
      return throwError(() => `Exceeding the limit of ${ProductGroupEx.MaxProductGroupItems} ASINs per strategy`);
    }
    const existing = strategy.asins!.find((a) => asins.includes(a.asin!));
    if (existing) {
      return throwError(() => `Unable to add ASIN ${existing.asin} as this ASIN is already in the strategy`);
    }
    // asins must be sorted before pushing them on Db
    const allAsins = strategy
      .asins!.flatMap((x) => x.asin!)
      .concat(asins)
      .sort();

    return this.strategyService
      .updateStrategyAsins({
        accountId: strategy.accountId,
        marketplace: strategy.marketplace,
        strategyId: strategy.strategyId!,
        requestBody: allAsins,
      })
      .pipe(
        catchAjaxError('Error adding ASINs to Strategy: '),
        tap(() => this.updateStrategyAsins(strategy, allAsins, true)),
      );
  }

  public addTargetingToStrategy(strategy: Strategy, targetings: Targeting[]): Observable<Response> {
    if (strategy.strategyType != StrategyType.BRAND && strategy.strategyType != StrategyType.KEYWORD) {
      return throwError(() => `Cannot add targetings to ${StrategyTypeStr[strategy.strategyType!]} strategies`);
    }
    if (
      strategy.targetings!.filter((t) => t.matchType === MatchType.asinSameAs).length +
        targetings.filter((t) => t.matchType === MatchType.asinSameAs).length >
      Constant.maxAsinTargetingByStrategy
    ) {
      return throwError(
        () => `Exceeding the limit of ${Constant.maxAsinTargetingByStrategy} product targetings per strategy`,
      );
    }
    if (
      strategy.targetings!.filter((t) => t.matchType === MatchType.exact || t.matchType === MatchType.phrase).length +
        targetings.filter((t) => t.matchType === MatchType.exact || t.matchType === MatchType.phrase).length >
      Constant.maxKwTargetingByStrategy
    ) {
      return throwError(
        () => `Exceeding the limit of ${Constant.maxKwTargetingByStrategy} keyword targetings per strategy`,
      );
    }
    const existing = strategy.targetings!.find(
      (t1) => targetings.findIndex((t2) => t1.matchType == t2.matchType && t1.targetingValue == t2.targetingValue) > -1,
    );
    if (existing) {
      return throwError(
        () =>
          `Unable to add targeting ${existing.targetingValue} [${existing.matchType}] as this targeting is already part of the strategy`,
      );
    }
    return this.strategyService
      .updateStrategyKwTargeting({
        accountId: strategy.accountId,
        marketplace: strategy.marketplace,
        strategyId: strategy.strategyId!,
        action: UpdateStrategyKwTargetingActionEnum.ADD,
        targeting: targetings,
      })
      .pipe(
        catchAjaxError('Error adding targeting to strategy: '),
        switchMap((resp) => {
          // if there are some exact kw and if TOSRO is activated on the strategy
          const exactKw = targetings.filter((t) => t.matchType == MatchType.exact).map((t) => t.targetingValue);
          if (exactKw.length > 0 && strategy.topOfSearchRankings && strategy.topOfSearchRankings.length > 0) {
            return this.updateTopOfSearchRankings(
              strategy,
              this.selectedAccountMarketplace!.resourceOrganizationId!,
              exactKw,
              UpdateStrategyTopOfSearchRankingsActionEnum.ADD,
            );
          }
          return of(resp);
        }),
        tap(() => {
          const strategyEx = this.strategyCache.get(strategy.strategyId!)!;
          // create a copy of the StrategyEx object
          const modifiedStrat = Object.assign(
            new StrategyEx(strategyEx, this.strategyCache.getCreativesMap(strategy.strategyId!)),
            strategyEx,
          );
          const newTargetings = [...modifiedStrat.targetings];
          for (const targeting of targetings) {
            if (
              newTargetings.findIndex(
                (t1) => t1.matchType == targeting.matchType && t1.targetingValue == targeting.targetingValue,
              ) < 0
            ) {
              newTargetings.push(targeting);
            }
          }
          modifiedStrat.targetings = newTargetings;
          this.strategyCache.updateStrategy(modifiedStrat);
          if (
            modifiedStrat.strategyGroupId! > 0 &&
            this.strategyCache.hasStrategyGroupIndex(modifiedStrat.strategyGroupId!)
          ) {
            const strategyGroup = this.strategyCache.getStrategyGroupIndex(modifiedStrat.strategyGroupId!)!;
            switch (modifiedStrat.strategyType) {
              case StrategyType.BRAND:
                strategyGroup.brandStrategies = strategyGroup.brandStrategies.map((s) =>
                  s.strategyId == modifiedStrat.strategyId ? modifiedStrat : s,
                );
                break;
              case StrategyType.KEYWORD:
                strategyGroup.keywordStrategies = strategyGroup.keywordStrategies.map((s) =>
                  s.strategyId == modifiedStrat.strategyId ? modifiedStrat : s,
                );
                break;
            }
            this.strategyCache.strategyGroupIndexSubjectNext();
          }
        }),
      );
  }

  private createStrategyInDb(strategy: Strategy): Observable<StrategyEx> {
    return this.strategyService
      .createStrategy({
        accountId: strategy.accountId,
        marketplace: strategy.marketplace,
        strategyGroupId: strategy.strategyGroupId ?? null,
        strategyType: strategy.strategyType ?? StrategyType.LEGACY,
        campaignType: strategy.campaignType,
        algoMode: strategy.algoMode,
        priority: strategy.priority ?? 0,
        name: strategy.name,
        state: strategy.state,
        acosTarget: strategy.acosTarget,
        suggestedBid: strategy.suggestedBid,
        dailyBudget: strategy.dailyBudget,
        monthlyBudget: strategy.monthlyBudget,
        minDailySpend: strategy.minDailySpend,
        disableOtherQueries: strategy.disableOtherQueries,
        disableAutoSegment: strategy.disableAutoSegment,
        disableProductSegment: strategy.disableProductSegment,
        organizationId: this.selectedAccountMarketplace!.resourceOrganizationId,
        brandEntityId: strategy.brandEntityId,
      } as CreateStrategyRequest)
      .pipe(
        map((response: Response) => {
          const strat = response.entity as Strategy;
          this.strategyCache.strategyListCache.update(
            { accountId: strategy.accountId, marketplace: strategy.marketplace },
            (strategies) => {
              strategies.set(strat.strategyId!, strat);
              return strategies;
            },
          );
          const newStrategy = new StrategyEx(strat, this.strategyCache.getCreativesMap(strategy.strategyId!));
          this.strategyCache.updateStrategy(newStrategy);
          if (newStrategy.strategyGroupId! > 0 && this.strategyCache.hasStrategyGroupIndex(strategy.strategyGroupId!)) {
            const strategyGroup = this.strategyCache.getStrategyGroupIndex(strategy.strategyGroupId!)!;
            strategyGroup.strategies = [...strategyGroup.strategies!, newStrategy];
            if (newStrategy.strategyType == StrategyType.PRODUCT) {
              strategyGroup.productStrategies = [...strategyGroup.productStrategies, newStrategy];
            } else if (newStrategy.strategyType == StrategyType.BRAND) {
              strategyGroup.brandStrategies = [...strategyGroup.brandStrategies, newStrategy];
            } else if (newStrategy.strategyType == StrategyType.KEYWORD) {
              strategyGroup.keywordStrategies = [...strategyGroup.keywordStrategies, newStrategy];
            }
            this.strategyCache.strategyGroupIndexSubjectNext();
          }
          return newStrategy;
        }),
        catchError((createStrategy: AjaxError) => {
          return throwError(
            () =>
              'Error creating Strategy: ' +
              (createStrategy.response ? createStrategy.response.message : createStrategy.message),
          );
        }),
      );
  }

  private checkStrategyParams(strategy: Strategy): string[] {
    const errors: string[] = [];
    // check strategy ASINs
    if (
      (strategy.asins == undefined || strategy.asins.length == 0) &&
      !strategy.defaultStrategy &&
      (strategy.campaignType === CampaignType.SP || strategy.campaignType === CampaignType.SD)
    ) {
      errors.push('Strategy should have a list of ASINs');
    }
    // check strategy state
    if (!strategy.state) {
      errors.push('Invalid strategy status');
    }
    // check strategy name
    try {
      this.checkStrategyName(strategy.name!, strategy.campaignType);
    } catch (error) {
      errors.push(error as string);
    }
    // check strategy label
    if (strategy.strategyLabel) {
      if (!Constant.nameRegexp.test(strategy.strategyLabel)) {
        errors.push('Invalid character used, Strategy Label can only use characters allowed in Amazon campaign names');
      }
      if (
        (strategy.campaignType == CampaignType.SB || strategy.campaignType == CampaignType.SD) &&
        Constant.invalidSBSDNameRegexp.test(strategy.strategyLabel)
      ) {
        errors.push(
          "Invalid character used, Strategy Label can only use characters allowed in Amazon campaign names. Characters: '!%#<>.' are not allowed for SD and SB.",
        );
      }
    }
    if (!strategy.algoMode || !AlgoMode[strategy.algoMode]) {
      errors.push('Invalid algorithm');
    }
    // check ACOS target
    if (strategy.algoMode === AlgoMode.ACOS_TARGET && strategy.acosTarget == undefined) {
      errors.push('ACOS target must be specified for ACOS target algorithm');
    }
    if (
      (strategy.algoMode === AlgoMode.PRODUCT_LAUNCH && strategy.acosTarget !== undefined) ||
      (strategy.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET && strategy.acosTarget !== undefined)
    ) {
      errors.push('Impossible to set ACOS target for ' + StrategyEx.getAlgoModeStrShort(strategy.algoMode));
    }
    if (strategy.algoMode === AlgoMode.ACOS_TARGET && (strategy.acosTarget! < 0.01 || strategy.acosTarget! > 2)) {
      errors.push('Invalid ACOS target, must be between 1% and 200%');
    }
    // check Suggested Bid
    if (
      strategy.algoMode === AlgoMode.PRODUCT_LAUNCH &&
      (strategy.suggestedBid == undefined || strategy.dailyBudget == undefined)
    ) {
      errors.push('A daily budget and a suggested bid should be defined for Product Launch');
    }
    if (strategy.algoMode !== AlgoMode.PRODUCT_LAUNCH && strategy.suggestedBid != undefined) {
      errors.push('Not possible to set a suggested bid for ' + StrategyEx.getAlgoModeStrShort(strategy.algoMode));
    }
    if (
      strategy.algoMode === AlgoMode.PRODUCT_LAUNCH &&
      (strategy.suggestedBid! <= 0 ||
        strategy.dailyBudget! < strategy.suggestedBid! ||
        5 * strategy.suggestedBid! > strategy.dailyBudget! ||
        strategy.dailyBudget! > 1_000_000_000)
    ) {
      errors.push(
        'Invalid suggested bid or daily budget, must satisfy 0 < suggested bid <= daily budget, also suggested bid should not exceed 20% of the Daily Budget',
      );
    }
    // check Monthly budget
    if (strategy.monthlyBudget && strategy.algoMode !== AlgoMode.MONTHLY_BUDGET_TARGET) {
      errors.push('Monthtly budget cannot be defined for ' + AlgoModeStr[strategy.algoMode]);
    }
    if (
      strategy.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET &&
      (strategy.monthlyBudget == undefined || strategy.monthlyBudget <= 0)
    ) {
      errors.push('Monthly budget must be greater than 0');
    }
    if (strategy.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET && strategy.monthlyBudget! > 1_000_000_000) {
      errors.push('Monthly budget should be lower than 1 billion');
    }
    // check daily budget
    if (strategy.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET && strategy.dailyBudget != undefined) {
      errors.push('Impossible to set a daily budget for Monthly budget algorithm');
    }
    if (
      strategy.algoMode === AlgoMode.ACOS_TARGET &&
      strategy.dailyBudget != undefined &&
      strategy.minDailySpend != undefined
    ) {
      if (strategy.dailyBudget < 2 * strategy.minDailySpend) {
        errors.push('Average Daily Budget must be at least 2 times higher than Min Daily Spend');
      } else if (strategy.dailyBudget > 1_000_000_000) {
        errors.push('Average Daily Budget must be less than 1 billion');
      }
    }

    // check min daily spend
    if (strategy.algoMode === AlgoMode.PRODUCT_LAUNCH && strategy.minDailySpend) {
      errors.push('Min daily spend cannot be defined for Force product visibility');
    }
    const currency = marketplaceCurrency(this.selectedAccountMarketplace!.marketplace);
    const minDailyBudgetLimit = Math.round(
      this.selectedAccountMarketplace!.minDailyBudgetLimit! / currencyRateToEuro(currency),
    );
    if (strategy.minDailySpend! > minDailyBudgetLimit) {
      errors.push(`Min daily Spend must be lower than ${minDailyBudgetLimit} ${currency}`);
    }
    if (strategy.minDailySpend! < 0) {
      errors.push('Min daily spend should be greater than 0');
    }

    // check disableOtherQueries aka AI-powered targeting
    if (
      strategy.campaignType === CampaignType.SD &&
      strategy.disableOtherQueries &&
      strategy.tactics.length == 0 &&
      strategy.audienceTargetings.length == 0
    ) {
      errors.push('Not possible to disable AI-powered targeting targeting when the strategy has no targetings');
    } else if (
      strategy.campaignType !== CampaignType.SD &&
      strategy.disableOtherQueries &&
      strategy.tactics.length == 0
    ) {
      errors.push('Not possible to disable AI-powered targeting when the strategy has no tactic');
    }
    // check boost
    if (strategy.primeDayBoost && strategy.algoMode !== AlgoMode.ACOS_TARGET) {
      errors.push('Strategy boost can only be activated on strategy with target ACOS');
    }
    if (strategy.dailyBudget! > 0 && strategy.primeDayBoost) {
      errors.push('Cannot add a target daily budget on a strategy with promo boost');
    }
    // check dayparting inputs
    if (strategy.daypartingPauseHour != null && strategy.daypartingPauseHour == strategy.daypartingReactivationHour) {
      errors.push('Dayparting pause and reactivation hours must be different');
    }

    // check strategy type / strategy group
    if (strategy.strategyGroupId !== undefined) {
      if (strategy.campaignType != CampaignType.SP) {
        errors.push('Strategy group can only be set for SP');
      }
    }
    if (strategy.strategyGroupId && (!strategy.strategyType || strategy.strategyType == StrategyType.LEGACY)) {
      errors.push('When attached to a strategy group, a strategy type has to be defined');
    }
    return errors;
  }

  public checkStrategyCreation(strategy: Strategy): string[] {
    const errors: string[] = [];
    errors.push(...this.checkStrategyParams(strategy));
    // check strategy state
    if (strategy.state == StrategyStateEnum.ENABLED) {
      // check if the limit is reached
      if (strategy.campaignType == CampaignType.SB && this.isSBStrategyLimitReached()) {
        errors.push(
          `Cannot activate a new strategy as the limit of ${Constant.maxSbStrategies} live Sponsored Brands strategies has been reached`,
        );
      }
      if (this.isStrategyLimitReached(strategy.campaignType)) {
        errors.push(
          'Cannot activate a new strategy as the limit of live strategies of your plan has been reached (please contact us)',
        );
      }
      if (strategy.asins && strategy.asins.length! > ProductGroupEx.MaxProductGroupItems) {
        errors.push(`Cannot activate a strategy with more than ${ProductGroupEx.MaxProductGroupItems} ASINs.`);
      }
    }
    // check that we have less than 10 kw strategies and less than 5 brand defense strategies
    if (strategy.strategyGroupId && strategy.strategyType == StrategyType.BRAND) {
      const strategyGroup = this.strategyCache.getStrategyGroupIndex(strategy.strategyGroupId);
      if (strategyGroup && strategyGroup.brandStrategies.length >= Constant.maxBrandDefenseStrategiesByStrategyGroup) {
        errors.push(
          `The same strategy group cannot have more than ${Constant.maxBrandDefenseStrategiesByStrategyGroup} brand defense strategies`,
        );
      }
    } else if (strategy.strategyGroupId && strategy.strategyType == StrategyType.KEYWORD) {
      const strategyGroup = this.strategyCache.getStrategyGroupIndex(strategy.strategyGroupId);
      if (strategyGroup && strategyGroup.keywordStrategies.length >= Constant.maxKeywordStrategiesByStrategyGroup) {
        errors.push(
          `The same strategy group cannot have more than ${Constant.maxKeywordStrategiesByStrategyGroup} focus strategies`,
        );
      }
    }
    return errors;
  }

  public createStrategyGroup(
    strategyGroup: Required<CreateStrategyGroupRequest>,
    productStrategy: Strategy,
  ): Observable<StrategyGroupEx> {
    const errors = this.checkStrategyCreation(productStrategy);
    if (errors.length > 0) {
      return throwError(() => errors.join(', '));
    }

    return this.strategyGroupService.createStrategyGroup(strategyGroup).pipe(
      catchAjaxError('Error creating the strategy group: '),
      switchMap((response) => {
        const strategyGroup = response.entity as StrategyGroup;
        const productStrategyForCreation = {
          ...productStrategy,
          strategyGroupId: strategyGroup.strategyGroupId,
          strategyType: StrategyType.PRODUCT,
        };
        return this.createStrategyAsync(productStrategyForCreation).pipe(
          map((strategy) => {
            const strategyGroupEx = {
              ...strategyGroup,
              strategies: [strategy],
              productStrategies: [strategy],
              brandStrategies: [],
              keywordStrategies: [],
              asins: strategy.asins.map((p) => p.asin!),
              lastUpdate: Utils.historyNow(),
            };
            return strategyGroupEx;
          }),
          tap((strategyGroup) => {
            this.strategyCache.setStrategyGroupIndex(strategyGroup as StrategyGroupEx);
          }),
        );
      }),
    );
  }

  public deleteStrategyGroup(strategyGroupId: number): Observable<void> {
    const strategyGroup = this.strategyCache.getStrategyGroupIndex(strategyGroupId);
    if (!strategyGroup) {
      return of(void 0);
    }
    const strategies = [
      ...strategyGroup.productStrategies,
      ...strategyGroup.brandStrategies,
      ...strategyGroup.keywordStrategies,
    ];
    return (
      strategies.length == 0
        ? of([])
        : forkJoin(
            strategies.map((strategy) =>
              this.strategyService.deleteStrategy({
                accountId: this.selectedAccountMarketplace!.accountId,
                marketplace: this.selectedAccountMarketplace!.marketplace,
                strategyId: strategy.strategyId,
                organizationId: this.selectedAccountMarketplace!.resourceOrganizationId!,
              }),
            ),
          )
    ).pipe(
      switchMap(() =>
        this.strategyGroupService.deleteStrategyGroup({
          accountId: this.selectedAccountMarketplace!.accountId,
          marketplace: this.selectedAccountMarketplace!.marketplace,
          strategyGroupId,
        }),
      ),
      catchAjaxError('Error deleting strategy group: '),
      tap(() => {
        this.strategyCache.deleteStrategyGroupIndex(strategyGroup.strategyGroupId!);
        this.strategyCache.deleteStrategies(strategies);
      }),
      map(() => void 0),
    );
  }

  public createStrategyAsync(strategy: Strategy): Observable<StrategyEx> {
    const errors = this.checkStrategyCreation(strategy);
    if (errors.length > 0) {
      return throwError(() => errors.join(', '));
    }
    return this.createStrategyInDb(strategy).pipe(
      switchMap((s) => {
        if (strategy.campaignType == CampaignType.SP || strategy.campaignType == CampaignType.SD) {
          return this.addAsinsToStrategy(s, strategy.asins!.map((a) => a.asin!)!).pipe(
            map(() => {
              s.asins = strategy.asins!;
              return s;
            }),
            catchAjaxError('Impossible to add ASINs to the strategy: '),
          );
        }
        return of(s);
      }),
      switchMap((s) => {
        if (
          (strategy.strategyType == StrategyType.BRAND || strategy.strategyType == StrategyType.KEYWORD) &&
          strategy.targetings &&
          strategy.targetings.length > 0
        ) {
          return this.addTargetingToStrategy(s, strategy.targetings!).pipe(
            map(() => {
              s.targetings = strategy.targetings!;
              return s;
            }),
            catchAjaxError('Impossible to add Targetings to the strategy: '),
          );
        }
        return of(s);
      }),
    );
  }

  public deleteStrategy(strategy: StrategyEx): Observable<void> {
    if (
      strategy.defaultStrategy &&
      (strategy.campaignType == CampaignType.SP || strategy.campaignType == CampaignType.SD)
    ) {
      return throwError(() => 'The default Strategy cannot be deleted');
    }
    if (!this.strategyCache.get(strategy.strategyId)) {
      return throwError(() => 'Unknown strategy ' + strategy.strategyId);
    }
    return this.strategyService
      .deleteStrategy({
        accountId: strategy.accountId,
        marketplace: strategy.marketplace,
        strategyId: strategy.strategyId,
        organizationId: this.selectedAccountMarketplace!.resourceOrganizationId!,
      })
      .pipe(
        catchAjaxError(),
        switchMap(() => {
          if (strategy.strategyGroupId) {
            const strategyGroup = this.strategyCache.getStrategyGroupIndex(strategy.strategyGroupId)!;
            if (strategy.strategyType == StrategyType.BRAND) {
              strategyGroup.brandStrategies = strategyGroup.brandStrategies.filter(
                (s) => s.strategyId !== strategy.strategyId,
              );
            } else if (strategy.strategyType == StrategyType.KEYWORD) {
              strategyGroup.keywordStrategies = strategyGroup.keywordStrategies.filter(
                (s) => s.strategyId !== strategy.strategyId,
              );
            } else if (strategy.strategyType == StrategyType.PRODUCT) {
              strategyGroup.productStrategies = strategyGroup.productStrategies.filter(
                (s) => s.strategyId !== strategy.strategyId,
              );
              // recompute list of ASINs
              const asins = strategyGroup.productStrategies.flatMap((s) => s.asins.map((p) => p.asin!));
              strategyGroup.asins = asins;
            }
            strategyGroup.strategies = strategyGroup.strategies!.filter((s) => s.strategyId !== strategy.strategyId);
            this.strategyCache.strategyGroupIndexSubjectNext();
          }
          this.strategyCache.deleteStrategies([strategy]);
          return of(void 0);
        }),
        map(() => void 0),
      );
  }

  public checkStrategyName(name: string, campaignType: CampaignType): void {
    if (!Constant.nameRegexp.test(name)) {
      throw 'Invalid character used. Strategy Name can only use characters allowed in Amazon campaign names';
    }
    if (
      (campaignType == CampaignType.SB || campaignType == CampaignType.SD) &&
      Constant.invalidSBSDNameRegexp.test(name)
    ) {
      throw "Invalid character used, Strategy Name can only use characters allowed in Amazon campaign names. Characters: '!%#<>.' are not allowed for SD and SB.";
    }
  }

  public updateStrategyGroup(strategyGroupUpdateParams: StrategyGroupUpdateParams): Observable<StrategyGroupEx> {
    return this.strategyGroupService
      .updateStrategyGroup({
        accountId: strategyGroupUpdateParams.accountId,
        marketplace: strategyGroupUpdateParams.marketplace,
        organizationId: strategyGroupUpdateParams.organizationId,
        strategyGroupId: strategyGroupUpdateParams.strategyGroupId,
        strategyGroupName: strategyGroupUpdateParams.strategyGroupName,
      })
      .pipe(
        catchAjaxError('Error updating Strategy Group: '),
        map((response) => {
          const strategyGroup = response.entity as StrategyGroup;
          const strategyGroupEx = {
            ...this.strategyCache.getStrategyGroupIndex(strategyGroup.strategyGroupId!)!,
            ...strategyGroup,
          };
          this.strategyCache.setStrategyGroupIndex(strategyGroupEx);
          return strategyGroupEx;
        }),
      );
  }

  public updateStrategyGroupBlacklist(
    params: StrategyGroupBlacklistUpdateParams,
  ): Observable<StrategyGroupEx | undefined> {
    if (params.toAdd.length == 0 && params.toDelete.length == 0) {
      return this.getStrategyGroupById(params.strategyGroupId);
    }

    const updateBlacklist: Observable<Response | undefined> =
      params.toDelete && params.toDelete.length > 0
        ? this.strategyGroupService.updateStrategyGroupBlacklist({
            accountId: params.accountId,
            marketplace: params.marketplace,
            strategyGroupId: params.strategyGroupId,
            targeting: params.toDelete,
            action: UpdateStrategyGroupBlacklistActionEnum.DELETE,
          })
        : of(undefined);

    return updateBlacklist.pipe(
      switchMap(() => {
        return params.toAdd && params.toAdd.length > 0
          ? this.strategyGroupService.updateStrategyGroupBlacklist({
              accountId: params.accountId,
              marketplace: params.marketplace,
              strategyGroupId: params.strategyGroupId,
              targeting: params.toAdd,
              action: UpdateStrategyGroupBlacklistActionEnum.ADD,
            })
          : of(void 0);
      }),
      catchAjaxError('Error updating Strategy Group blacklist: '),
      map(() => {
        const strategyGroupEx = this.strategyCache.getStrategyGroupIndex(params.strategyGroupId)!;
        strategyGroupEx.blacklist = strategyGroupEx
          .blacklist!.concat(params.toAdd)
          .filter(
            (t) =>
              params.toDelete.findIndex((d) => d.matchType == t.matchType && d.targetingValue == t.targetingValue) < 0,
          );
        this.strategyCache.setStrategyGroupIndex(strategyGroupEx);
        return strategyGroupEx as unknown as StrategyGroupEx;
      }),
    );
  }

  /**
   * @deprecated
   * @param response
   * @returns
   */
  public parseResponseAndUpdateStrategy(response: Response): StrategyEx {
    const strat = response.entity as Strategy;
    const modifiedStrategy = new StrategyEx(strat, this.strategyCache.getCreativesMap(strat.strategyId!));
    this.strategyCache.updateStrategy(modifiedStrategy);
    if (strat.strategyGroupId) {
      const strategyGroup = this.strategyCache.getStrategyGroupIndex(strat.strategyGroupId)!;
      strategyGroup.strategies = strategyGroup.strategies!.map((s) =>
        s.strategyId == strat.strategyId ? modifiedStrategy : s,
      );
      if (strategyGroup.strategies.length === 1) {
        if (strategyGroup.strategyGroupName !== modifiedStrategy.name) {
          strategyGroup.strategyGroupName = modifiedStrategy.name;
        }
      }
      switch (strat.strategyType) {
        case StrategyType.BRAND:
          strategyGroup.brandStrategies = strategyGroup.brandStrategies.map((s) =>
            s.strategyId == strat.strategyId ? modifiedStrategy : s,
          );
          // sort strategies by priority
          strategyGroup.brandStrategies.sort((s1, s2) => s1.priority! - s2.priority!);
          break;
        case StrategyType.KEYWORD:
          strategyGroup.keywordStrategies = strategyGroup.keywordStrategies.map((s) =>
            s.strategyId == strat.strategyId ? modifiedStrategy : s,
          );
          // sort strategies by priority
          strategyGroup.keywordStrategies.sort((s1, s2) => s1.priority! - s2.priority!);
          break;
        case StrategyType.PRODUCT:
          strategyGroup.productStrategies = strategyGroup.productStrategies.map((s) =>
            s.strategyId == strat.strategyId ? modifiedStrategy : s,
          );
          break;
      }
      this.strategyCache.strategyGroupIndexSubjectNext();
    }
    return modifiedStrategy;
  }

  public updateTopOfSearchRankings(
    strategy: Strategy,
    organizationId: number,
    keywords: string[],
    action: UpdateStrategyTopOfSearchRankingsActionEnum,
  ): Observable<Response> {
    return this.strategyService
      .updateStrategyTopOfSearchRankings({
        accountId: strategy.accountId,
        marketplace: strategy.marketplace,
        strategyId: strategy.strategyId!,
        organizationId,
        action,
        requestBody: keywords,
      })
      .pipe(
        catchAjaxError(),
        tap(() => {
          const strat = this.strategyCache.get(strategy.strategyId!)!;
          if (!strategy.topOfSearchRankings) {
            strategy.topOfSearchRankings = [];
          }
          if (action == UpdateStrategyTopOfSearchRankingsActionEnum.ADD) {
            strategy.topOfSearchRankings.push(...keywords.map((k) => ({ keyword: k })));
          } else {
            strategy.topOfSearchRankings = strategy.topOfSearchRankings.filter((k) => !keywords.includes(k.keyword!));
          }

          if (strat.strategyGroupId) {
            this.strategyCache.strategyGroupIndexSubjectNext();
          }
        }),
      );
  }
}
