<ng-container *transloco="let t">
  <div class="flex h-full w-full flex-row overflow-visible text-pretty align-middle leading-snug">
    <div class="mr-2 flex w-1/6 items-center overflow-visible align-middle">
      <ISelect
        class="flex-1 {{ strategy.primeDayBoost === undefined ? 'hidden' : '' }}"
        [options]="options"
        [selectedValue]="selectedOption?.value"
        (selectedOptionChange)="setIntensity($event)"
      />
    </div>
    <div class="flex w-11/12 flex-1 items-center align-middle">
      @if (strategy.primeDayBoost) {
        <p>
          {{
            t("strategy-boost-table.statey_boost", [
              strategy.acosTarget | percent,
              eventName,
              primeDayPeriod,
              primeDayBoost(strategy.acosTarget, strategy.primeDayBoost) | percent,
              primeDayAfterPeriod,
              primeDayDecrease(strategy.acosTarget) | percent,
              primeDayOverDate,
              strategy.acosTarget | percent,
            ])
          }}
        </p>
      }
      @if (!strategy.primeDayBoost && strategy.algoMode === AlgoMode.ACOS_TARGET && !strategy.dailyBudget) {
        <p>
          {{ t("strategy-boost-table.dont_benefit", [eventName]) }}
        </p>
      }
      @if (
        !strategy.primeDayBoost &&
        strategy.algoMode === AlgoMode.ACOS_TARGET &&
        strategy.dailyBudget &&
        strategy.dailyBudget > 0
      ) {
        <p>{{ t("strategy-boost-table.avg_daily_spend") }}</p>
      }
      @if (strategy.algoMode === AlgoMode.PRODUCT_LAUNCH) {
        <p>{{ t("strategy-boost-table.daily_target") }}</p>
      }
      @if (strategy.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET) {
        <p>{{ t("strategy-boost-table.monthly_budget") }}</p>
      }
    </div>
  </div>
</ng-container>
