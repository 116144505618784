<ng-container *transloco="let t">
  <form [classList]="['flex flex-col ' + (!reviewMode && 'h-full')]" [formGroup]="form" (ngSubmit)="submitForm(true)">
    <div class="flex min-h-0 flex-1 flex-col p-5">
      <h4 class="text-xl font-medium">{{ t("common.asins") }}</h4>
      @if (reviewMode) {
        <div class="mt-5 flex flex-col gap-y-5">
          @for (asins of asinsAsArray; track asins; let i = $index) {
            @if (am) {
              <div class="flex gap-x-5">
                <div class="mb-2 font-medium text-gray-700">{{ t("sb-form-asins.ad_line") }} {{ i + 1 }}</div>
                <div class="flex gap-x-3">
                  @for (a of asins; track a) {
                    @if (a) {
                      <div class="max-w-min rounded-md border p-1">
                        <app-product-thumbnail
                          [matTooltip]="a"
                          class="sensitive-data [&_img]:rounded-sm"
                          [marketplace]="am!.marketplace"
                          [asin]="a"
                          customSizeClass="size-14"
                        />
                      </div>
                    }
                  }
                </div>
              </div>
            }
          }
        </div>
      } @else {
        <p class="m-0 text-gray-500">{{ t("sb-form-asins.select_asins_for_your_creative") }}</p>

        <div class="mt-5 flex min-h-0 flex-1 flex-col space-y-4 overflow-y-auto">
          @if (creativeType === SbCreativeType.storeSpotlight) {
            <app-store-spotlight-pages
              [marketplace]="am.marketplace"
              [storePages]="stores!"
              (emitSbAsins)="form.controls.asins.setValue($event)"
            />
          } @else {
            @if (form.controls.asins.errors && form.controls.asins.errors["duplicate"]) {
              <span class="text-sm text-red-500"> {{ t("sb-form-asins.unique_line") }} </span>
            }
            @if (!tacosStrategy && tacosAsins() && tacosAsins()!.size) {
              <IAlert class="my-4" typ="info" [title]="t('common.asin_used_in_tacos_strategies')">
                <IButton
                  [label]="t('common.display_unavailable_asin')"
                  color="white"
                  size="xs"
                  (onClick)="openAsinUsageModal()"
                />
              </IAlert>
            }
            <app-drag-drop-asins
              [store]="store!"
              class="h-full min-h-0 max-w-full"
              [creativeType]="creativeType!"
              [asins]="form.controls.asins.value!"
              [tacosStrategy]="tacosStrategy!"
              (asinUpdated)="form.controls.asins.setValue($event.length === 0 ? null : $event)"
            />
          }
        </div>
      }
    </div>

    @if (!reviewMode) {
      <div class="flex justify-end border-t border-gray-300 bg-white p-4">
        <div class="flex gap-x-2">
          <IButton
            label="{{ t('common.previous') }}"
            (onClick)="onFormPrevious.emit(); $event.preventDefault()"
            color="white"
            icon="icon-[material-symbols--chevron-left-rounded]"
          />
          <IButton
            [disabled]="form.invalid"
            label="{{ t('common.next') }}"
            type="submit"
            icon="icon-[material-symbols--chevron-right-rounded]"
            testid="next-button"
            [trailing]="true"
          />
        </div>
      </div>
    }
  </form>
</ng-container>
