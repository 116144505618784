export type DisplayGroup = {
  displayGroupTitle: string;
  displayGroupLink: string;
};

export const DisplayGroupsMap = new Map<string, DisplayGroup>([
  [
    "dg_amazon_devices_AE",
    {
      displayGroupTitle: "Amazon Devices & Accessories",
      displayGroupLink: "https://www.amazon.ae/gp/bestsellers/amazon-devices",
    },
  ],
  ["dg_apparel_AE", { displayGroupTitle: "Fashion", displayGroupLink: "https://www.amazon.ae/gp/bestsellers/fashion" }],
  [
    "dg_automotive_AE",
    { displayGroupTitle: "Automotive", displayGroupLink: "https://www.amazon.ae/gp/bestsellers/automotive" },
  ],
  [
    "dg_baby_product_AE",
    { displayGroupTitle: "Baby Products", displayGroupLink: "https://www.amazon.ae/gp/bestsellers/baby" },
  ],
  ["dg_beauty_AE", { displayGroupTitle: "Beauty", displayGroupLink: "https://www.amazon.ae/gp/bestsellers/beauty" }],
  ["dg_book_AE", { displayGroupTitle: "Books", displayGroupLink: "https://www.amazon.ae/gp/bestsellers/books" }],
  [
    "dg_ce_AE",
    { displayGroupTitle: "Electronics", displayGroupLink: "https://www.amazon.ae/gp/bestsellers/electronics" },
  ],
  [
    "dg_electronic_gift_card_AE",
    { displayGroupTitle: "Gift Cards", displayGroupLink: "https://www.amazon.ae/gp/bestsellers/gift-cards" },
  ],
  ["dg_grocery_AE", { displayGroupTitle: "Grocery", displayGroupLink: "https://www.amazon.ae/gp/bestsellers/grocery" }],
  [
    "dg_health_and_beauty_AE",
    { displayGroupTitle: "Health", displayGroupLink: "https://www.amazon.ae/gp/bestsellers/health" },
  ],
  ["dg_home_AE", { displayGroupTitle: "Home", displayGroupLink: "https://www.amazon.ae/gp/bestsellers/home" }],
  [
    "dg_home_improvement_AE",
    {
      displayGroupTitle: "Tools & Home Improvement",
      displayGroupLink: "https://www.amazon.ae/gp/bestsellers/home-improvement",
    },
  ],
  ["dg_kitchen_AE", { displayGroupTitle: "Kitchen", displayGroupLink: "https://www.amazon.ae/gp/bestsellers/kitchen" }],
  [
    "dg_major_appliances_AE",
    { displayGroupTitle: "Appliances", displayGroupLink: "https://www.amazon.ae/gp/bestsellers/appliances" },
  ],
  [
    "dg_office_product_AE",
    { displayGroupTitle: "Office Products", displayGroupLink: "https://www.amazon.ae/gp/bestsellers/office-products" },
  ],
  ["dg_pc_AE", { displayGroupTitle: "Computers", displayGroupLink: "https://www.amazon.ae/gp/bestsellers/computers" }],
  [
    "dg_pet_products_AE",
    { displayGroupTitle: "Pet Supplies", displayGroupLink: "https://www.amazon.ae/gp/bestsellers/pet-products" },
  ],
  [
    "dg_sports_AE",
    { displayGroupTitle: "Sporting Goods", displayGroupLink: "https://www.amazon.ae/gp/bestsellers/sports-goods" },
  ],
  ["dg_toy_AE", { displayGroupTitle: "Toys", displayGroupLink: "https://www.amazon.ae/gp/bestsellers/toys" }],
  [
    "dg_video_games_AE",
    { displayGroupTitle: "Videogames", displayGroupLink: "https://www.amazon.ae/gp/bestsellers/videogames" },
  ],
  [
    "dg_wireless_AE",
    {
      displayGroupTitle: "Mobile Phones & Communication Products",
      displayGroupLink: "https://www.amazon.ae/gp/bestsellers/mobile-phones",
    },
  ],
  [
    "dg_amazon_devices_AU",
    {
      displayGroupTitle: "Amazon Devices & Accessories",
      displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/amazon-devices",
    },
  ],
  [
    "dg_amazon_renewed_AU",
    {
      displayGroupTitle: "Amazon Renewed",
      displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/amazon-renewed",
    },
  ],
  [
    "dg_audible_AU",
    {
      displayGroupTitle: "Audible Books & Originals",
      displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/audible",
    },
  ],
  [
    "dg_automotive_AU",
    { displayGroupTitle: "Automotive", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/automotive" },
  ],
  [
    "dg_baby_product_AU",
    { displayGroupTitle: "Baby", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/baby-products" },
  ],
  [
    "dg_beauty_AU",
    { displayGroupTitle: "Beauty", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/beauty" },
  ],
  ["dg_book_AU", { displayGroupTitle: "Books", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/books" }],
  [
    "dg_boost_AU",
    { displayGroupTitle: "Amazon Launchpad", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/boost" },
  ],
  [
    "dg_ce_AU",
    { displayGroupTitle: "Electronics", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/electronics" },
  ],
  [
    "dg_dvd_AU",
    { displayGroupTitle: "Movies & TV", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/movies-and-tv" },
  ],
  [
    "dg_ebooks_AU",
    { displayGroupTitle: "Kindle Store", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/digital-text" },
  ],
  [
    "dg_electronic_gift_card_AU",
    { displayGroupTitle: "Gift Cards", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/gift-cards" },
  ],
  [
    "dg_fashion_AU",
    {
      displayGroupTitle: "Clothing, Shoes & Accessories",
      displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/fashion",
    },
  ],
  [
    "dg_grocery_AU",
    { displayGroupTitle: "Pantry Food & Drinks", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/grocery" },
  ],
  [
    "dg_health_and_beauty_AU",
    {
      displayGroupTitle: "Health, Household & Personal Care",
      displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/health",
    },
  ],
  ["dg_home_AU", { displayGroupTitle: "Home", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/home" }],
  [
    "dg_home_improvement_AU",
    {
      displayGroupTitle: "Home Improvement",
      displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/home-improvement",
    },
  ],
  [
    "dg_kitchen_AU",
    { displayGroupTitle: "Kitchen & Dining", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/kitchen" },
  ],
  [
    "dg_lawn_and_garden_AU",
    { displayGroupTitle: "Garden", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/garden" },
  ],
  [
    "dg_lighting_AU",
    { displayGroupTitle: "Lighting", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/lighting" },
  ],
  [
    "dg_major_domestic_appliances_AU",
    {
      displayGroupTitle: "Household Appliances",
      displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/appliances",
    },
  ],
  [
    "dg_mobile_application_AU",
    { displayGroupTitle: "Apps & Games", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/mobile-apps" },
  ],
  ["dg_music_AU", { displayGroupTitle: "Music", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/music" }],
  [
    "dg_musical_instruments_AU",
    {
      displayGroupTitle: "Musical Instruments",
      displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/musical-instruments",
    },
  ],
  [
    "dg_office_product_AU",
    {
      displayGroupTitle: "Stationery & Office Products",
      displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/office-products",
    },
  ],
  [
    "dg_pc_AU",
    { displayGroupTitle: "Computers", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/computers" },
  ],
  [
    "dg_pet_products_AU",
    { displayGroupTitle: "Pet Supplies", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/pet-supplies" },
  ],
  [
    "dg_photo_AU",
    { displayGroupTitle: "Camera & Photo", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/photo" },
  ],
  [
    "dg_software_AU",
    { displayGroupTitle: "Software", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/software" },
  ],
  [
    "dg_sports_AU",
    {
      displayGroupTitle: "Sports, Fitness & Outdoors",
      displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/sporting-goods",
    },
  ],
  [
    "dg_toy_AU",
    { displayGroupTitle: "Toys & Games", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/toys" },
  ],
  [
    "dg_video_games_AU",
    { displayGroupTitle: "Video Games", displayGroupLink: "https://www.amazon.com.au/gp/bestsellers/videogames" },
  ],
  [
    "dg_art_and_craft_supply_BE",
    { displayGroupTitle: "Loisirs créatifs", displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/arts-crafts" },
  ],
  [
    "dg_automotive_BE",
    { displayGroupTitle: "Auto et moto", displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/automotive" },
  ],
  [
    "dg_baby_product_BE",
    {
      displayGroupTitle: "Bébé et Puériculture",
      displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/baby-products",
    },
  ],
  [
    "dg_beauty_BE",
    { displayGroupTitle: "Beauté et Parfum", displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/beauty" },
  ],
  [
    "dg_biss_basic_BE",
    {
      displayGroupTitle: "Industrie et science",
      displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/industrial",
    },
  ],
  ["dg_book_BE", { displayGroupTitle: "Livres", displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/books" }],
  [
    "dg_ce_BE",
    { displayGroupTitle: "High-tech", displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/electronics" },
  ],
  [
    "dg_dvd_BE",
    { displayGroupTitle: "Films et TV", displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/movies-tv" },
  ],
  [
    "dg_fashion_BE",
    { displayGroupTitle: "Mode", displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/fashion" },
  ],
  [
    "dg_grocery_BE",
    { displayGroupTitle: "Épicerie", displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/grocery" },
  ],
  [
    "dg_health_and_beauty_BE",
    { displayGroupTitle: "Hygiène et Santé", displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/hpc" },
  ],
  [
    "dg_home_BE",
    { displayGroupTitle: "Cuisine et maison", displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/home" },
  ],
  [
    "dg_home_improvement_BE",
    { displayGroupTitle: "Bricolage", displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/home-improvement" },
  ],
  [
    "dg_lawn_and_garden_BE",
    { displayGroupTitle: "Jardin", displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/lawn-garden" },
  ],
  [
    "dg_music_BE",
    { displayGroupTitle: "CD et Vinyles", displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/music" },
  ],
  [
    "dg_musical_instruments_BE",
    {
      displayGroupTitle: "Instruments de musique",
      displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/musical-instruments",
    },
  ],
  [
    "dg_office_product_BE",
    {
      displayGroupTitle: "Fournitures de bureau",
      displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/office-products",
    },
  ],
  [
    "dg_pet_products_BE",
    { displayGroupTitle: "Animalerie", displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/pet-supplies" },
  ],
  [
    "dg_sports_BE",
    {
      displayGroupTitle: "Sports et plein air",
      displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/sporting-goods",
    },
  ],
  [
    "dg_toy_BE",
    { displayGroupTitle: "Jouets", displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/toys-and-games" },
  ],
  [
    "dg_video_games_BE",
    { displayGroupTitle: "Jeux vidéo", displayGroupLink: "https://www.amazon.com.be/gp/bestsellers/videogames" },
  ],
  [
    "dg_amazon_devices_BR",
    {
      displayGroupTitle: "Dispositivos Amazon e Acessórios",
      displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/amazon-devices",
    },
  ],
  [
    "dg_apparel_BR",
    { displayGroupTitle: "Moda", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/fashion" },
  ],
  [
    "dg_audible_BR",
    { displayGroupTitle: "Audiolivros", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/audible" },
  ],
  [
    "dg_automotive_BR",
    { displayGroupTitle: "Automotivo", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/automotive" },
  ],
  [
    "dg_baby_product_BR",
    { displayGroupTitle: "Bebês", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/baby-products" },
  ],
  [
    "dg_beauty_BR",
    { displayGroupTitle: "Beleza", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/beauty" },
  ],
  ["dg_book_BR", { displayGroupTitle: "Livros", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/books" }],
  [
    "dg_ce_BR",
    { displayGroupTitle: "Eletrônicos", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/electronics" },
  ],
  [
    "dg_dvd_BR",
    { displayGroupTitle: "DVD e Blu-ray", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/dvd" },
  ],
  [
    "dg_ebooks_BR",
    { displayGroupTitle: "Loja Kindle", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/digital-text" },
  ],
  [
    "dg_electronic_gift_card_BR",
    { displayGroupTitle: "Gift Cards", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/gift-cards" },
  ],
  [
    "dg_furniture_BR",
    { displayGroupTitle: "Móveis", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/furniture" },
  ],
  [
    "dg_grocery_BR",
    { displayGroupTitle: "Alimentos e Bebidas", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/grocery" },
  ],
  [
    "dg_health_and_beauty_BR",
    { displayGroupTitle: "Saúde e Bem-Estar", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/hpc" },
  ],
  ["dg_home_BR", { displayGroupTitle: "Casa", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/home" }],
  [
    "dg_home_improvement_BR",
    {
      displayGroupTitle: "Ferramentas e Materiais de Construção",
      displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/hi",
    },
  ],
  [
    "dg_kitchen_BR",
    { displayGroupTitle: "Cozinha", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/kitchen" },
  ],
  [
    "dg_lawn_and_garden_BR",
    {
      displayGroupTitle: "Jardim e Piscina",
      displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/lawn-and-garden",
    },
  ],
  [
    "dg_major_appliances_BR",
    { displayGroupTitle: "Eletrodomésticos", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/appliances" },
  ],
  [
    "dg_mobile_application_BR",
    {
      displayGroupTitle: "Apps e Jogos",
      displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/mobile-apps%3Ftf=1",
    },
  ],
  [
    "dg_music_BR",
    { displayGroupTitle: "CD e Vinil", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/music" },
  ],
  [
    "dg_musical_instruments_BR",
    {
      displayGroupTitle: "Instrumentos Musicais",
      displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/musical-instruments",
    },
  ],
  [
    "dg_office_product_BR",
    {
      displayGroupTitle: "Papelaria e Escritório",
      displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/office",
    },
  ],
  [
    "dg_pc_BR",
    {
      displayGroupTitle: "Computadores e Informática",
      displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/computers",
    },
  ],
  [
    "dg_pet_products_BR",
    { displayGroupTitle: "Pet Shop", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/pet-products" },
  ],
  [
    "dg_premium_beauty_BR",
    {
      displayGroupTitle: "Beleza de Luxo",
      displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/premium-beauty",
    },
  ],
  [
    "dg_sports_BR",
    { displayGroupTitle: "Esporte", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/sports" },
  ],
  [
    "dg_toy_BR",
    { displayGroupTitle: "Brinquedos e Jogos", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/toys" },
  ],
  [
    "dg_video_games_BR",
    { displayGroupTitle: "Games e Consoles", displayGroupLink: "https://www.amazon.com.br/gp/bestsellers/videogames" },
  ],
  [
    "dg_amazon_devices_CA",
    {
      displayGroupTitle: "Amazon Devices & Accessories",
      displayGroupLink: "https://www.amazon.ca/gp/bestsellers/amazon-devices",
    },
  ],
  [
    "dg_amazon_renewed_CA",
    { displayGroupTitle: "Amazon Renewed", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/amazon-renewed" },
  ],
  [
    "dg_apparel_CA",
    { displayGroupTitle: "Clothing & Accessories", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/apparel" },
  ],
  [
    "dg_audible_CA",
    {
      displayGroupTitle: "Audible Books & Originals",
      displayGroupLink: "https://www.amazon.ca/gp/bestsellers/audible",
    },
  ],
  [
    "dg_automotive_CA",
    { displayGroupTitle: "Automotive", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/automotive" },
  ],
  ["dg_baby_product_CA", { displayGroupTitle: "Baby", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/baby" }],
  [
    "dg_beauty_CA",
    { displayGroupTitle: "Beauty & Personal Care", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/beauty" },
  ],
  [
    "dg_biss_basic_CA",
    {
      displayGroupTitle: "Industrial & Scientific",
      displayGroupLink: "https://www.amazon.ca/gp/bestsellers/industrial",
    },
  ],
  ["dg_book_CA", { displayGroupTitle: "Books", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/books" }],
  [
    "dg_ce_CA",
    { displayGroupTitle: "Electronics", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/electronics" },
  ],
  [
    "dg_dvd_CA",
    { displayGroupTitle: "Movies & TV Shows", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/dvd" },
  ],
  [
    "dg_ebooks_CA",
    { displayGroupTitle: "Kindle Store", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/digital-text" },
  ],
  [
    "dg_fashion_CA",
    {
      displayGroupTitle: "Clothing, Shoes & Accessories",
      displayGroupLink: "https://www.amazon.ca/gp/bestsellers/fashion",
    },
  ],
  [
    "dg_gift_card_CA",
    { displayGroupTitle: "Gift Cards", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/gift-cards" },
  ],
  [
    "dg_grocery_CA",
    { displayGroupTitle: "Grocery & Gourmet Food", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/grocery" },
  ],
  [
    "dg_guild_product_CA",
    { displayGroupTitle: "Handmade Products", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/handmade" },
  ],
  [
    "dg_health_and_beauty_CA",
    { displayGroupTitle: "Health & Personal Care", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/hpc" },
  ],
  [
    "dg_home_improvement_CA",
    { displayGroupTitle: "Tools & Home Improvement", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/hi" },
  ],
  [
    "dg_jewelry_CA",
    { displayGroupTitle: "Jewellery", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/jewelry" },
  ],
  ["dg_kitchen_CA", { displayGroupTitle: "Home", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/kitchen" }],
  [
    "dg_lawn_and_garden_CA",
    { displayGroupTitle: "Patio, Lawn & Garden", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/lawn-garden" },
  ],
  [
    "dg_mobile_application_CA",
    {
      displayGroupTitle: "Apps for Android",
      displayGroupLink: "https://www.amazon.ca/gp/bestsellers/mobile-apps%3Ftf=1",
    },
  ],
  ["dg_music_CA", { displayGroupTitle: "Music", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/music" }],
  [
    "dg_musical_instruments_CA",
    {
      displayGroupTitle: "Musical Instruments, Stage & Studio",
      displayGroupLink: "https://www.amazon.ca/gp/bestsellers/musical-instruments",
    },
  ],
  [
    "dg_office_product_CA",
    { displayGroupTitle: "Office Products", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/office" },
  ],
  [
    "dg_pet_products_CA",
    { displayGroupTitle: "Pet Supplies", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/pet-supplies" },
  ],
  [
    "dg_software_CA",
    { displayGroupTitle: "Software", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/software" },
  ],
  [
    "dg_sports_CA",
    { displayGroupTitle: "Sports & Outdoors", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/sports" },
  ],
  ["dg_toy_CA", { displayGroupTitle: "Toys & Games", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/toys" }],
  [
    "dg_video_games_CA",
    { displayGroupTitle: "Video Games", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/videogames" },
  ],
  ["dg_watch_CA", { displayGroupTitle: "Watches", displayGroupLink: "https://www.amazon.ca/gp/bestsellers/watch" }],
  [
    "dg_amazon_devices_DE",
    {
      displayGroupTitle: "Amazon-Geräte & Zubehör",
      displayGroupLink: "https://www.amazon.de/gp/bestsellers/amazon-devices",
    },
  ],
  [
    "dg_amazon_renewed_DE",
    { displayGroupTitle: "Amazon Renewed", displayGroupLink: "https://www.amazon.de/gp/bestsellers/amazon-renewed" },
  ],
  [
    "dg_audible_DE",
    {
      displayGroupTitle: "Audible Hörbücher & Originals",
      displayGroupLink: "https://www.amazon.de/gp/bestsellers/audible",
    },
  ],
  [
    "dg_automotive_DE",
    { displayGroupTitle: "Auto & Motorrad", displayGroupLink: "https://www.amazon.de/gp/bestsellers/automotive" },
  ],
  ["dg_baby_product_DE", { displayGroupTitle: "Baby", displayGroupLink: "https://www.amazon.de/gp/bestsellers/baby" }],
  ["dg_beauty_DE", { displayGroupTitle: "Kosmetik", displayGroupLink: "https://www.amazon.de/gp/bestsellers/beauty" }],
  [
    "dg_biss_basic_DE",
    {
      displayGroupTitle: "Gewerbe, Industrie & Wissenschaft",
      displayGroupLink: "https://www.amazon.de/gp/bestsellers/industrial",
    },
  ],
  ["dg_book_DE", { displayGroupTitle: "Bücher", displayGroupLink: "https://www.amazon.de/gp/bestsellers/books" }],
  [
    "dg_boost_DE",
    { displayGroupTitle: "Amazon Launchpad", displayGroupLink: "https://www.amazon.de/gp/bestsellers/boost" },
  ],
  [
    "dg_ce_DE",
    { displayGroupTitle: "Elektronik & Foto", displayGroupLink: "https://www.amazon.de/gp/bestsellers/ce-de" },
  ],
  [
    "dg_climate_product_DE",
    {
      displayGroupTitle: "Climate Pledge Friendly",
      displayGroupLink: "https://www.amazon.de/gp/bestsellers/climate-pledge",
    },
  ],
  [
    "dg_digital_music_album_DE",
    { displayGroupTitle: "Alben", displayGroupLink: "https://www.amazon.de/gp/bestsellers/digital-music-album" },
  ],
  [
    "dg_digital_music_track_DE",
    { displayGroupTitle: "Songs", displayGroupLink: "https://www.amazon.de/gp/bestsellers/digital-music-track" },
  ],
  [
    "dg_download_movie_DE",
    { displayGroupTitle: "Prime Video", displayGroupLink: "https://www.amazon.de/gp/bestsellers/instant-video" },
  ],
  [
    "dg_dvd_DE",
    { displayGroupTitle: "DVD & Blu-ray", displayGroupLink: "https://www.amazon.de/gp/bestsellers/dvd-de" },
  ],
  [
    "dg_ebooks_DE",
    { displayGroupTitle: "Kindle-Shop", displayGroupLink: "https://www.amazon.de/gp/bestsellers/digital-text" },
  ],
  ["dg_fashion_DE", { displayGroupTitle: "Fashion", displayGroupLink: "https://www.amazon.de/gp/bestsellers/fashion" }],
  [
    "dg_gift_card_DE",
    { displayGroupTitle: "Geschenkgutscheine", displayGroupLink: "https://www.amazon.de/gp/bestsellers/gift-cards" },
  ],
  [
    "dg_grocery_DE",
    { displayGroupTitle: "Lebensmittel & Getränke", displayGroupLink: "https://www.amazon.de/gp/bestsellers/grocery" },
  ],
  [
    "dg_guild_product_DE",
    { displayGroupTitle: "Handmade Produkte", displayGroupLink: "https://www.amazon.de/gp/bestsellers/handmade" },
  ],
  [
    "dg_health_and_beauty_DE",
    {
      displayGroupTitle: "Drogerie & Körperpflege",
      displayGroupLink: "https://www.amazon.de/gp/bestsellers/drugstore",
    },
  ],
  [
    "dg_home_improvement_DE",
    { displayGroupTitle: "Baumarkt", displayGroupLink: "https://www.amazon.de/gp/bestsellers/diy" },
  ],
  [
    "dg_kitchen_DE",
    { displayGroupTitle: "Küche, Haushalt & Wohnen", displayGroupLink: "https://www.amazon.de/gp/bestsellers/kitchen" },
  ],
  [
    "dg_lawn_and_garden_DE",
    { displayGroupTitle: "Garten", displayGroupLink: "https://www.amazon.de/gp/bestsellers/garden" },
  ],
  [
    "dg_lighting_DE",
    { displayGroupTitle: "Beleuchtung", displayGroupLink: "https://www.amazon.de/gp/bestsellers/lighting" },
  ],
  [
    "dg_magazine_DE",
    { displayGroupTitle: "Zeitschriften", displayGroupLink: "https://www.amazon.de/gp/bestsellers/magazines" },
  ],
  [
    "dg_major_appliances_DE",
    { displayGroupTitle: "Elektro-Großgeräte", displayGroupLink: "https://www.amazon.de/gp/bestsellers/appliances" },
  ],
  [
    "dg_mobile_application_DE",
    { displayGroupTitle: "Apps & Spiele", displayGroupLink: "https://www.amazon.de/gp/bestsellers/mobile-apps%3Ftf=1" },
  ],
  [
    "dg_music_DE",
    { displayGroupTitle: "Musik-CDs & Vinyl", displayGroupLink: "https://www.amazon.de/gp/bestsellers/music" },
  ],
  [
    "dg_musical_instruments_DE",
    {
      displayGroupTitle: "Musikinstrumente & DJ-Equipment",
      displayGroupLink: "https://www.amazon.de/gp/bestsellers/musical-instruments",
    },
  ],
  [
    "dg_office_product_DE",
    {
      displayGroupTitle: "Bürobedarf & Schreibwaren",
      displayGroupLink: "https://www.amazon.de/gp/bestsellers/officeproduct",
    },
  ],
  [
    "dg_pc_DE",
    { displayGroupTitle: "Computer & Zubehör", displayGroupLink: "https://www.amazon.de/gp/bestsellers/computers" },
  ],
  [
    "dg_pet_products_DE",
    { displayGroupTitle: "Haustier", displayGroupLink: "https://www.amazon.de/gp/bestsellers/pet-supplies" },
  ],
  [
    "dg_photo_DE",
    { displayGroupTitle: "Kamera & Foto", displayGroupLink: "https://www.amazon.de/gp/bestsellers/photo" },
  ],
  [
    "dg_software_DE",
    { displayGroupTitle: "Software", displayGroupLink: "https://www.amazon.de/gp/bestsellers/software" },
  ],
  [
    "dg_sports_DE",
    { displayGroupTitle: "Sport & Freizeit", displayGroupLink: "https://www.amazon.de/gp/bestsellers/sports" },
  ],
  ["dg_toy_DE", { displayGroupTitle: "Spielzeug", displayGroupLink: "https://www.amazon.de/gp/bestsellers/toys" }],
  [
    "dg_video_games_DE",
    { displayGroupTitle: "Games", displayGroupLink: "https://www.amazon.de/gp/bestsellers/videogames" },
  ],
  ["dg_apparel_EG", { displayGroupTitle: "Fashion", displayGroupLink: "https://www.amazon.eg/gp/bestsellers/fashion" }],
  [
    "dg_automotive_EG",
    { displayGroupTitle: "Automotive", displayGroupLink: "https://www.amazon.eg/gp/bestsellers/automotive" },
  ],
  [
    "dg_baby_product_EG",
    { displayGroupTitle: "Baby Products", displayGroupLink: "https://www.amazon.eg/gp/bestsellers/baby-products" },
  ],
  ["dg_beauty_EG", { displayGroupTitle: "Beauty", displayGroupLink: "https://www.amazon.eg/gp/bestsellers/beauty" }],
  [
    "dg_ce_EG",
    { displayGroupTitle: "Electronics", displayGroupLink: "https://www.amazon.eg/gp/bestsellers/electronics" },
  ],
  ["dg_grocery_EG", { displayGroupTitle: "Grocery", displayGroupLink: "https://www.amazon.eg/gp/bestsellers/grocery" }],
  [
    "dg_health_and_beauty_EG",
    {
      displayGroupTitle: "Health & Household Products",
      displayGroupLink: "https://www.amazon.eg/gp/bestsellers/health",
    },
  ],
  [
    "dg_home_EG",
    { displayGroupTitle: "Home & Kitchen", displayGroupLink: "https://www.amazon.eg/gp/bestsellers/home" },
  ],
  [
    "dg_home_improvement_EG",
    {
      displayGroupTitle: "Tools & Home Improvement",
      displayGroupLink: "https://www.amazon.eg/gp/bestsellers/home-improvement",
    },
  ],
  [
    "dg_lawn_and_garden_EG",
    { displayGroupTitle: "Garden", displayGroupLink: "https://www.amazon.eg/gp/bestsellers/garden" },
  ],
  [
    "dg_musical_instruments_EG",
    {
      displayGroupTitle: "Musical Instruments",
      displayGroupLink: "https://www.amazon.eg/gp/bestsellers/musical-instruments",
    },
  ],
  [
    "dg_office_product_EG",
    { displayGroupTitle: "Office Products", displayGroupLink: "https://www.amazon.eg/gp/bestsellers/office-products" },
  ],
  [
    "dg_pet_products_EG",
    { displayGroupTitle: "Pet Supplies", displayGroupLink: "https://www.amazon.eg/gp/bestsellers/pet-supplies" },
  ],
  [
    "dg_sports_EG",
    { displayGroupTitle: "Sports & Outdoors", displayGroupLink: "https://www.amazon.eg/gp/bestsellers/sporting-goods" },
  ],
  ["dg_toy_EG", { displayGroupTitle: "Toys", displayGroupLink: "https://www.amazon.eg/gp/bestsellers/toys" }],
  [
    "dg_video_games_EG",
    { displayGroupTitle: "Video Games", displayGroupLink: "https://www.amazon.eg/gp/bestsellers/videogames" },
  ],
  [
    "dg_amazon_devices_ES",
    {
      displayGroupTitle: "Dispositivos Amazon y accesorios",
      displayGroupLink: "https://www.amazon.es/gp/bestsellers/amazon-devices",
    },
  ],
  [
    "dg_amazon_renewed_ES",
    { displayGroupTitle: "Amazon Renewed", displayGroupLink: "https://www.amazon.es/gp/bestsellers/amazon-renewed" },
  ],
  [
    "dg_audible_ES",
    {
      displayGroupTitle: "Audible Libros y Originales",
      displayGroupLink: "https://www.amazon.es/gp/bestsellers/audible",
    },
  ],
  [
    "dg_automotive_ES",
    { displayGroupTitle: "Coche y moto", displayGroupLink: "https://www.amazon.es/gp/bestsellers/automotive" },
  ],
  ["dg_baby_product_ES", { displayGroupTitle: "Bebé", displayGroupLink: "https://www.amazon.es/gp/bestsellers/baby" }],
  ["dg_beauty_ES", { displayGroupTitle: "Belleza", displayGroupLink: "https://www.amazon.es/gp/bestsellers/beauty" }],
  [
    "dg_biss_basic_ES",
    {
      displayGroupTitle: "Industria, empresas y ciencia",
      displayGroupLink: "https://www.amazon.es/gp/bestsellers/industrial",
    },
  ],
  ["dg_book_ES", { displayGroupTitle: "Libros", displayGroupLink: "https://www.amazon.es/gp/bestsellers/books" }],
  [
    "dg_boost_ES",
    { displayGroupTitle: "Amazon Launchpad", displayGroupLink: "https://www.amazon.es/gp/bestsellers/boost" },
  ],
  [
    "dg_ce_ES",
    { displayGroupTitle: "Electrónica", displayGroupLink: "https://www.amazon.es/gp/bestsellers/electronics" },
  ],
  [
    "dg_climate_product_ES",
    {
      displayGroupTitle: "Climate Pledge Friendly",
      displayGroupLink: "https://www.amazon.es/gp/bestsellers/climate-pledge",
    },
  ],
  [
    "dg_digital_music_album_ES",
    { displayGroupTitle: "Álbumes", displayGroupLink: "https://www.amazon.es/gp/bestsellers/digital-music-album" },
  ],
  [
    "dg_digital_music_track_ES",
    { displayGroupTitle: "Canciones", displayGroupLink: "https://www.amazon.es/gp/bestsellers/digital-music-track" },
  ],
  ["dg_dvd_ES", { displayGroupTitle: "Películas y TV", displayGroupLink: "https://www.amazon.es/gp/bestsellers/dvd" }],
  [
    "dg_ebooks_ES",
    { displayGroupTitle: "Tienda Kindle", displayGroupLink: "https://www.amazon.es/gp/bestsellers/digital-text" },
  ],
  ["dg_fashion_ES", { displayGroupTitle: "Moda", displayGroupLink: "https://www.amazon.es/gp/bestsellers/fashion" }],
  [
    "dg_gift_card_ES",
    { displayGroupTitle: "Cheques regalo", displayGroupLink: "https://www.amazon.es/gp/bestsellers/gift-cards" },
  ],
  [
    "dg_grocery_ES",
    { displayGroupTitle: "Alimentación y bebidas", displayGroupLink: "https://www.amazon.es/gp/bestsellers/grocery" },
  ],
  [
    "dg_guild_product_ES",
    { displayGroupTitle: "Productos Handmade", displayGroupLink: "https://www.amazon.es/gp/bestsellers/handmade" },
  ],
  [
    "dg_health_and_beauty_ES",
    { displayGroupTitle: "Salud y cuidado personal", displayGroupLink: "https://www.amazon.es/gp/bestsellers/hpc" },
  ],
  [
    "dg_home_improvement_ES",
    { displayGroupTitle: "Bricolaje y herramientas", displayGroupLink: "https://www.amazon.es/gp/bestsellers/tools" },
  ],
  [
    "dg_kitchen_ES",
    { displayGroupTitle: "Hogar y cocina", displayGroupLink: "https://www.amazon.es/gp/bestsellers/kitchen" },
  ],
  [
    "dg_lawn_and_garden_ES",
    { displayGroupTitle: "Jardín", displayGroupLink: "https://www.amazon.es/gp/bestsellers/lawn-garden" },
  ],
  [
    "dg_lighting_ES",
    { displayGroupTitle: "Iluminación", displayGroupLink: "https://www.amazon.es/gp/bestsellers/lighting" },
  ],
  [
    "dg_major_appliances_ES",
    {
      displayGroupTitle: "Grandes electrodomésticos",
      displayGroupLink: "https://www.amazon.es/gp/bestsellers/appliances",
    },
  ],
  [
    "dg_mobile_application_ES",
    { displayGroupTitle: "Apps y Juegos", displayGroupLink: "https://www.amazon.es/gp/bestsellers/mobile-apps%3Ftf=1" },
  ],
  [
    "dg_music_ES",
    { displayGroupTitle: "CDs y vinilos", displayGroupLink: "https://www.amazon.es/gp/bestsellers/music" },
  ],
  [
    "dg_musical_instruments_ES",
    {
      displayGroupTitle: "Instrumentos musicales",
      displayGroupLink: "https://www.amazon.es/gp/bestsellers/musical-instruments",
    },
  ],
  [
    "dg_office_product_ES",
    { displayGroupTitle: "Oficina y papelería", displayGroupLink: "https://www.amazon.es/gp/bestsellers/office" },
  ],
  [
    "dg_pc_ES",
    { displayGroupTitle: "Informática", displayGroupLink: "https://www.amazon.es/gp/bestsellers/computers" },
  ],
  [
    "dg_pet_products_ES",
    {
      displayGroupTitle: "Productos para mascotas",
      displayGroupLink: "https://www.amazon.es/gp/bestsellers/pet-supplies",
    },
  ],
  [
    "dg_software_ES",
    { displayGroupTitle: "Software", displayGroupLink: "https://www.amazon.es/gp/bestsellers/software" },
  ],
  [
    "dg_sports_ES",
    { displayGroupTitle: "Deportes y aire libre", displayGroupLink: "https://www.amazon.es/gp/bestsellers/sports" },
  ],
  [
    "dg_toy_ES",
    { displayGroupTitle: "Juguetes y juegos", displayGroupLink: "https://www.amazon.es/gp/bestsellers/toys" },
  ],
  [
    "dg_video_games_ES",
    { displayGroupTitle: "Videojuegos", displayGroupLink: "https://www.amazon.es/gp/bestsellers/videogames" },
  ],
  [
    "dg_amazon_devices_FR",
    {
      displayGroupTitle: "Appareils Amazon et Accessoires",
      displayGroupLink: "https://www.amazon.fr/gp/bestsellers/amazon-devices",
    },
  ],
  [
    "dg_amazon_renewed_FR",
    { displayGroupTitle: "Amazon Renewed", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/amazon-renewed" },
  ],
  [
    "dg_audible_FR",
    {
      displayGroupTitle: "Livres et œuvres originales Audible",
      displayGroupLink: "https://www.amazon.fr/gp/bestsellers/audible",
    },
  ],
  [
    "dg_automotive_FR",
    { displayGroupTitle: "Auto et Moto", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/automotive" },
  ],
  [
    "dg_baby_product_FR",
    { displayGroupTitle: "Bébé et Puériculture", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/baby" },
  ],
  [
    "dg_beauty_FR",
    { displayGroupTitle: "Beauté et Parfum", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/beauty" },
  ],
  [
    "dg_biss_basic_FR",
    {
      displayGroupTitle: "Commerce, Industrie et Science",
      displayGroupLink: "https://www.amazon.fr/gp/bestsellers/industrial",
    },
  ],
  ["dg_book_FR", { displayGroupTitle: "Livres", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/books" }],
  [
    "dg_boost_FR",
    { displayGroupTitle: "Amazon Launchpad", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/boost" },
  ],
  [
    "dg_ce_FR",
    { displayGroupTitle: "High-Tech", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/electronics" },
  ],
  [
    "dg_climate_product_FR",
    {
      displayGroupTitle: "Climate Pledge Friendly",
      displayGroupLink: "https://www.amazon.fr/gp/bestsellers/climate-pledge",
    },
  ],
  [
    "dg_digital_music_album_FR",
    {
      displayGroupTitle: "Albums à télécharger",
      displayGroupLink: "https://www.amazon.fr/gp/bestsellers/digital-music-album",
    },
  ],
  [
    "dg_digital_music_track_FR",
    {
      displayGroupTitle: "Titres à télécharger",
      displayGroupLink: "https://www.amazon.fr/gp/bestsellers/digital-music-track",
    },
  ],
  ["dg_dvd_FR", { displayGroupTitle: "DVD et Blu-ray", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/dvd" }],
  [
    "dg_ebooks_FR",
    { displayGroupTitle: "Boutique Kindle", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/digital-text" },
  ],
  ["dg_fashion_FR", { displayGroupTitle: "Mode", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/fashion" }],
  [
    "dg_gift_card_FR",
    {
      displayGroupTitle: "Boutique cartes cadeaux",
      displayGroupLink: "https://www.amazon.fr/gp/bestsellers/gift-cards",
    },
  ],
  [
    "dg_grocery_FR",
    { displayGroupTitle: "Epicerie", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/grocery" },
  ],
  [
    "dg_guild_product_FR",
    { displayGroupTitle: "Produits Handmade", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/handmade" },
  ],
  [
    "dg_health_and_beauty_FR",
    { displayGroupTitle: "Hygiène et Santé", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/hpc" },
  ],
  [
    "dg_home_improvement_FR",
    { displayGroupTitle: "Bricolage", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/hi" },
  ],
  [
    "dg_kitchen_FR",
    { displayGroupTitle: "Cuisine et Maison", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/kitchen" },
  ],
  [
    "dg_lawn_and_garden_FR",
    { displayGroupTitle: "Jardin", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/lawn-garden" },
  ],
  [
    "dg_lighting_FR",
    { displayGroupTitle: "Luminaires et Éclairage", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/lighting" },
  ],
  [
    "dg_major_appliances_FR",
    { displayGroupTitle: "Gros électroménager", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/appliances" },
  ],
  [
    "dg_mobile_application_FR",
    {
      displayGroupTitle: "Applis et Jeux",
      displayGroupLink: "https://www.amazon.fr/gp/bestsellers/mobile-apps%3Ftf=1",
    },
  ],
  [
    "dg_music_FR",
    { displayGroupTitle: "CD et Vinyles", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/music" },
  ],
  [
    "dg_musical_instruments_FR",
    {
      displayGroupTitle: "Instruments de musique et Sono",
      displayGroupLink: "https://www.amazon.fr/gp/bestsellers/musical-instruments",
    },
  ],
  [
    "dg_office_product_FR",
    {
      displayGroupTitle: "Fournitures de bureau",
      displayGroupLink: "https://www.amazon.fr/gp/bestsellers/officeproduct",
    },
  ],
  [
    "dg_pc_FR",
    { displayGroupTitle: "Informatique", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/computers" },
  ],
  [
    "dg_pet_products_FR",
    { displayGroupTitle: "Animalerie", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/pet-supplies" },
  ],
  [
    "dg_software_FR",
    { displayGroupTitle: "Logiciels", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/software" },
  ],
  [
    "dg_sports_FR",
    { displayGroupTitle: "Sports et Loisirs", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/sports" },
  ],
  ["dg_toy_FR", { displayGroupTitle: "Jeux et Jouets", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/toys" }],
  [
    "dg_video_games_FR",
    { displayGroupTitle: "Jeux vidéo", displayGroupLink: "https://www.amazon.fr/gp/bestsellers/videogames" },
  ],
  [
    "dg_amazon_renewed_IN",
    { displayGroupTitle: "Amazon Renewed", displayGroupLink: "https://www.amazon.in/gp/bestsellers/amazon-renewed" },
  ],
  [
    "dg_apparel_IN",
    { displayGroupTitle: "Clothing & Accessories", displayGroupLink: "https://www.amazon.in/gp/bestsellers/apparel" },
  ],
  [
    "dg_audible_IN",
    {
      displayGroupTitle: "Audible Books & Originals",
      displayGroupLink: "https://www.amazon.in/gp/bestsellers/audible",
    },
  ],
  [
    "dg_automotive_IN",
    { displayGroupTitle: "Car & Motorbike", displayGroupLink: "https://www.amazon.in/gp/bestsellers/automotive" },
  ],
  [
    "dg_baby_product_IN",
    { displayGroupTitle: "Baby Products", displayGroupLink: "https://www.amazon.in/gp/bestsellers/baby" },
  ],
  ["dg_beauty_IN", { displayGroupTitle: "Beauty", displayGroupLink: "https://www.amazon.in/gp/bestsellers/beauty" }],
  [
    "dg_biss_basic_IN",
    {
      displayGroupTitle: "Industrial & Scientific",
      displayGroupLink: "https://www.amazon.in/gp/bestsellers/industrial",
    },
  ],
  ["dg_book_IN", { displayGroupTitle: "Books", displayGroupLink: "https://www.amazon.in/gp/bestsellers/books" }],
  [
    "dg_boost_IN",
    { displayGroupTitle: "Amazon Launchpad", displayGroupLink: "https://www.amazon.in/gp/bestsellers/boost" },
  ],
  [
    "dg_ce_IN",
    { displayGroupTitle: "Electronics", displayGroupLink: "https://www.amazon.in/gp/bestsellers/electronics" },
  ],
  [
    "dg_dvd_IN",
    { displayGroupTitle: "Movies & TV Shows", displayGroupLink: "https://www.amazon.in/gp/bestsellers/dvd" },
  ],
  [
    "dg_ebooks_IN",
    { displayGroupTitle: "Kindle Store", displayGroupLink: "https://www.amazon.in/gp/bestsellers/digital-text" },
  ],
  [
    "dg_electronic_gift_card_IN",
    { displayGroupTitle: "Gift Cards", displayGroupLink: "https://www.amazon.in/gp/bestsellers/gift-cards" },
  ],
  [
    "dg_grocery_IN",
    { displayGroupTitle: "Grocery & Gourmet Foods", displayGroupLink: "https://www.amazon.in/gp/bestsellers/grocery" },
  ],
  [
    "dg_health_and_beauty_IN",
    { displayGroupTitle: "Health & Personal Care", displayGroupLink: "https://www.amazon.in/gp/bestsellers/hpc" },
  ],
  [
    "dg_home_improvement_IN",
    {
      displayGroupTitle: "Home Improvement",
      displayGroupLink: "https://www.amazon.in/gp/bestsellers/home-improvement",
    },
  ],
  [
    "dg_jewelry_IN",
    { displayGroupTitle: "Jewellery", displayGroupLink: "https://www.amazon.in/gp/bestsellers/jewelry" },
  ],
  [
    "dg_kitchen_IN",
    { displayGroupTitle: "Home & Kitchen", displayGroupLink: "https://www.amazon.in/gp/bestsellers/kitchen" },
  ],
  [
    "dg_lawn_and_garden_IN",
    { displayGroupTitle: "Garden & Outdoors", displayGroupLink: "https://www.amazon.in/gp/bestsellers/garden" },
  ],
  [
    "dg_luggage_IN",
    {
      displayGroupTitle: "Bags, Wallets and Luggage",
      displayGroupLink: "https://www.amazon.in/gp/bestsellers/luggage",
    },
  ],
  [
    "dg_mobile_application_IN",
    {
      displayGroupTitle: "Apps for Android",
      displayGroupLink: "https://www.amazon.in/gp/bestsellers/mobile-apps%3Ftf=1",
    },
  ],
  ["dg_music_IN", { displayGroupTitle: "Music", displayGroupLink: "https://www.amazon.in/gp/bestsellers/music" }],
  [
    "dg_musical_instruments_IN",
    {
      displayGroupTitle: "Musical Instruments",
      displayGroupLink: "https://www.amazon.in/gp/bestsellers/musical-instruments",
    },
  ],
  [
    "dg_office_product_IN",
    { displayGroupTitle: "Office Products", displayGroupLink: "https://www.amazon.in/gp/bestsellers/office" },
  ],
  [
    "dg_pc_IN",
    {
      displayGroupTitle: "Computers & Accessories",
      displayGroupLink: "https://www.amazon.in/gp/bestsellers/computers",
    },
  ],
  [
    "dg_pet_products_IN",
    { displayGroupTitle: "Pet Supplies", displayGroupLink: "https://www.amazon.in/gp/bestsellers/pet-supplies" },
  ],
  [
    "dg_shoes_IN",
    { displayGroupTitle: "Shoes & Handbags", displayGroupLink: "https://www.amazon.in/gp/bestsellers/shoes" },
  ],
  [
    "dg_software_IN",
    { displayGroupTitle: "Software", displayGroupLink: "https://www.amazon.in/gp/bestsellers/software" },
  ],
  [
    "dg_sports_IN",
    {
      displayGroupTitle: "Sports, Fitness & Outdoors",
      displayGroupLink: "https://www.amazon.in/gp/bestsellers/sports",
    },
  ],
  ["dg_toy_IN", { displayGroupTitle: "Toys & Games", displayGroupLink: "https://www.amazon.in/gp/bestsellers/toys" }],
  [
    "dg_video_games_IN",
    { displayGroupTitle: "Video Games", displayGroupLink: "https://www.amazon.in/gp/bestsellers/videogames" },
  ],
  ["dg_watch_IN", { displayGroupTitle: "Watches", displayGroupLink: "https://www.amazon.in/gp/bestsellers/watches" }],
  [
    "dg_amazon_devices_IT",
    {
      displayGroupTitle: "Dispositivi Amazon & Accessori",
      displayGroupLink: "https://www.amazon.it/gp/bestsellers/amazon-devices",
    },
  ],
  [
    "dg_amazon_renewed_IT",
    { displayGroupTitle: "Amazon Renewed", displayGroupLink: "https://www.amazon.it/gp/bestsellers/amazon-renewed" },
  ],
  [
    "dg_audible_IT",
    {
      displayGroupTitle: "Audiolibri Audible e Original",
      displayGroupLink: "https://www.amazon.it/gp/bestsellers/audible",
    },
  ],
  [
    "dg_automotive_IT",
    { displayGroupTitle: "Auto e Moto", displayGroupLink: "https://www.amazon.it/gp/bestsellers/automotive" },
  ],
  [
    "dg_baby_product_IT",
    { displayGroupTitle: "Prima infanzia", displayGroupLink: "https://www.amazon.it/gp/bestsellers/baby" },
  ],
  ["dg_beauty_IT", { displayGroupTitle: "Bellezza", displayGroupLink: "https://www.amazon.it/gp/bestsellers/beauty" }],
  [
    "dg_biss_basic_IT",
    {
      displayGroupTitle: "Commercio, Industria e Scienza",
      displayGroupLink: "https://www.amazon.it/gp/bestsellers/industrial",
    },
  ],
  ["dg_book_IT", { displayGroupTitle: "Libri", displayGroupLink: "https://www.amazon.it/gp/bestsellers/books" }],
  [
    "dg_boost_IT",
    { displayGroupTitle: "Amazon Launchpad", displayGroupLink: "https://www.amazon.it/gp/bestsellers/boost" },
  ],
  [
    "dg_ce_IT",
    { displayGroupTitle: "Elettronica", displayGroupLink: "https://www.amazon.it/gp/bestsellers/electronics" },
  ],
  [
    "dg_climate_product_IT",
    {
      displayGroupTitle: "Climate Pledge Friendly",
      displayGroupLink: "https://www.amazon.it/gp/bestsellers/climate-pledge",
    },
  ],
  [
    "dg_digital_music_album_IT",
    { displayGroupTitle: "Album", displayGroupLink: "https://www.amazon.it/gp/bestsellers/digital-music-album" },
  ],
  [
    "dg_digital_music_track_IT",
    { displayGroupTitle: "Canzoni", displayGroupLink: "https://www.amazon.it/gp/bestsellers/digital-music-track" },
  ],
  ["dg_dvd_IT", { displayGroupTitle: "Film e TV", displayGroupLink: "https://www.amazon.it/gp/bestsellers/dvd" }],
  [
    "dg_ebooks_IT",
    { displayGroupTitle: "Kindle Store", displayGroupLink: "https://www.amazon.it/gp/bestsellers/digital-text" },
  ],
  ["dg_fashion_IT", { displayGroupTitle: "Moda", displayGroupLink: "https://www.amazon.it/gp/bestsellers/fashion" }],
  [
    "dg_gift_card_IT",
    { displayGroupTitle: "Buoni regalo", displayGroupLink: "https://www.amazon.it/gp/bestsellers/gift-cards" },
  ],
  [
    "dg_grocery_IT",
    {
      displayGroupTitle: "Alimentari e cura della casa",
      displayGroupLink: "https://www.amazon.it/gp/bestsellers/grocery",
    },
  ],
  [
    "dg_guild_product_IT",
    { displayGroupTitle: "Prodotti Handmade", displayGroupLink: "https://www.amazon.it/gp/bestsellers/handmade" },
  ],
  [
    "dg_health_and_beauty_IT",
    { displayGroupTitle: "Salute e cura della persona", displayGroupLink: "https://www.amazon.it/gp/bestsellers/hpc" },
  ],
  [
    "dg_home_improvement_IT",
    { displayGroupTitle: "Fai da te", displayGroupLink: "https://www.amazon.it/gp/bestsellers/tools" },
  ],
  [
    "dg_kitchen_IT",
    { displayGroupTitle: "Casa e cucina", displayGroupLink: "https://www.amazon.it/gp/bestsellers/kitchen" },
  ],
  [
    "dg_lawn_and_garden_IT",
    { displayGroupTitle: "Giardino e giardinaggio", displayGroupLink: "https://www.amazon.it/gp/bestsellers/garden" },
  ],
  [
    "dg_lighting_IT",
    { displayGroupTitle: "Illuminazione", displayGroupLink: "https://www.amazon.it/gp/bestsellers/lighting" },
  ],
  [
    "dg_major_appliances_IT",
    {
      displayGroupTitle: "Grandi elettrodomestici",
      displayGroupLink: "https://www.amazon.it/gp/bestsellers/appliances",
    },
  ],
  [
    "dg_mobile_application_IT",
    { displayGroupTitle: "App e Giochi", displayGroupLink: "https://www.amazon.it/gp/bestsellers/mobile-apps%3Ftf=1" },
  ],
  ["dg_music_IT", { displayGroupTitle: "CD e Vinili", displayGroupLink: "https://www.amazon.it/gp/bestsellers/music" }],
  [
    "dg_musical_instruments_IT",
    {
      displayGroupTitle: "Strumenti Musicali",
      displayGroupLink: "https://www.amazon.it/gp/bestsellers/musical-instruments",
    },
  ],
  [
    "dg_office_product_IT",
    {
      displayGroupTitle: "Cancelleria e prodotti per ufficio",
      displayGroupLink: "https://www.amazon.it/gp/bestsellers/office",
    },
  ],
  ["dg_pc_IT", { displayGroupTitle: "Informatica", displayGroupLink: "https://www.amazon.it/gp/bestsellers/pc" }],
  [
    "dg_pet_products_IT",
    {
      displayGroupTitle: "Prodotti per animali domestici",
      displayGroupLink: "https://www.amazon.it/gp/bestsellers/pet-supplies",
    },
  ],
  [
    "dg_software_IT",
    { displayGroupTitle: "Software", displayGroupLink: "https://www.amazon.it/gp/bestsellers/software" },
  ],
  [
    "dg_sports_IT",
    { displayGroupTitle: "Sport e tempo libero", displayGroupLink: "https://www.amazon.it/gp/bestsellers/sports" },
  ],
  [
    "dg_toy_IT",
    { displayGroupTitle: "Giochi e giocattoli", displayGroupLink: "https://www.amazon.it/gp/bestsellers/toys" },
  ],
  [
    "dg_video_games_IT",
    { displayGroupTitle: "Videogiochi", displayGroupLink: "https://www.amazon.it/gp/bestsellers/videogames" },
  ],
  [
    "dg_amazon_devices_JP",
    {
      displayGroupTitle: "Amazonデバイス・アクセサリ",
      displayGroupLink: "https://www.amazon.jp/gp/bestsellers/amazon-devices",
    },
  ],
  [
    "dg_amazon_renewed_JP",
    { displayGroupTitle: "Amazon整備済み品", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/amazon-renewed" },
  ],
  [
    "dg_audible_JP",
    {
      displayGroupTitle: "Audibleブック・オリジナル",
      displayGroupLink: "https://www.amazon.jp/gp/bestsellers/audible",
    },
  ],
  [
    "dg_automotive_JP",
    { displayGroupTitle: "車＆バイク", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/automotive" },
  ],
  [
    "dg_baby_product_JP",
    { displayGroupTitle: "ベビー＆マタニティ", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/baby" },
  ],
  [
    "dg_beauty_JP",
    { displayGroupTitle: "ビューティー", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/beauty" },
  ],
  [
    "dg_biss_basic_JP",
    { displayGroupTitle: "産業・研究開発用品", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/industrial" },
  ],
  ["dg_book_JP", { displayGroupTitle: "本", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/books" }],
  [
    "dg_ce_JP",
    { displayGroupTitle: "家電＆カメラ", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/electronics" },
  ],
  [
    "dg_digital_music_album_JP",
    { displayGroupTitle: "アルバム", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/digital-music-album" },
  ],
  [
    "dg_digital_music_track_JP",
    { displayGroupTitle: "曲", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/digital-music-track" },
  ],
  [
    "dg_download_movie_JP",
    { displayGroupTitle: "Prime Video", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/instant-video" },
  ],
  ["dg_dvd_JP", { displayGroupTitle: "DVD", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/dvd" }],
  [
    "dg_ebooks_JP",
    { displayGroupTitle: "Kindleストア", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/digital-text" },
  ],
  [
    "dg_english_book_JP",
    { displayGroupTitle: "洋書", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/english-books" },
  ],
  [
    "dg_fashion_JP",
    { displayGroupTitle: "ファッション", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/fashion" },
  ],
  [
    "dg_gift_card_JP",
    { displayGroupTitle: "ギフトカード", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/gift-cards" },
  ],
  [
    "dg_grocery_JP",
    { displayGroupTitle: "食品・飲料・お酒", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/food-beverage" },
  ],
  [
    "dg_health_and_beauty_JP",
    { displayGroupTitle: "ドラッグストア", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/hpc" },
  ],
  ["dg_hobby_JP", { displayGroupTitle: "ホビー", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/hobby" }],
  [
    "dg_home_improvement_JP",
    { displayGroupTitle: "DIY・工具・ガーデン", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/diy" },
  ],
  [
    "dg_kitchen_JP",
    { displayGroupTitle: "ホーム＆キッチン", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/kitchen" },
  ],
  [
    "dg_major_appliances_JP",
    { displayGroupTitle: "大型家電", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/appliances" },
  ],
  [
    "dg_mobile_application_JP",
    {
      displayGroupTitle: "アプリ＆ゲーム",
      displayGroupLink: "https://www.amazon.jp/gp/bestsellers/mobile-apps%3Ftf=1",
    },
  ],
  [
    "dg_music_JP",
    { displayGroupTitle: "ミュージック", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/music" },
  ],
  [
    "dg_musical_instruments_JP",
    {
      displayGroupTitle: "楽器・音響機器",
      displayGroupLink: "https://www.amazon.jp/gp/bestsellers/musical-instruments",
    },
  ],
  [
    "dg_office_product_JP",
    {
      displayGroupTitle: "文房具・オフィス用品",
      displayGroupLink: "https://www.amazon.jp/gp/bestsellers/office-products",
    },
  ],
  [
    "dg_pc_JP",
    { displayGroupTitle: "パソコン・周辺機器", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/computers" },
  ],
  [
    "dg_pet_products_JP",
    { displayGroupTitle: "ペット用品", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/pet-supplies" },
  ],
  [
    "dg_software_JP",
    { displayGroupTitle: "PCソフト", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/software" },
  ],
  [
    "dg_sports_JP",
    { displayGroupTitle: "スポーツ＆アウトドア", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/sports" },
  ],
  ["dg_toy_JP", { displayGroupTitle: "おもちゃ", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/toys" }],
  [
    "dg_video_games_JP",
    { displayGroupTitle: "ゲーム", displayGroupLink: "https://www.amazon.jp/gp/bestsellers/videogames" },
  ],
  [
    "dg_amazon_devices_MX",
    {
      displayGroupTitle: "Dispositivos Amazon y Accesorios",
      displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/amazon-devices",
    },
  ],
  [
    "dg_amazon_renewed_MX",
    {
      displayGroupTitle: "Amazon Renewed : Productos Reacondicionados",
      displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/amazon-renewed",
    },
  ],
  [
    "dg_automotive_MX",
    {
      displayGroupTitle: "Automotriz y Motocicletas",
      displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/automotive",
    },
  ],
  [
    "dg_baby_product_MX",
    { displayGroupTitle: "Bebé", displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/baby" },
  ],
  [
    "dg_beauty_MX",
    { displayGroupTitle: "Belleza", displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/beauty" },
  ],
  [
    "dg_biss_basic_MX",
    {
      displayGroupTitle: "Industria, Empresas y Ciencia",
      displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/industrial",
    },
  ],
  ["dg_book_MX", { displayGroupTitle: "Libros", displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/books" }],
  [
    "dg_ce_MX",
    { displayGroupTitle: "Electrónicos", displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/electronics" },
  ],
  [
    "dg_dvd_MX",
    { displayGroupTitle: "Películas y Series de TV", displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/dvd" },
  ],
  [
    "dg_ebooks_MX",
    { displayGroupTitle: "Tienda Kindle", displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/digital-text" },
  ],
  [
    "dg_electronic_gift_card_MX",
    {
      displayGroupTitle: "Tarjetas de Regalo",
      displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/gift-cards",
    },
  ],
  [
    "dg_grocery_MX",
    { displayGroupTitle: "Alimentos y Bebidas", displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/grocery" },
  ],
  [
    "dg_guild_product_MX",
    { displayGroupTitle: "Productos Handmade", displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/handmade" },
  ],
  [
    "dg_health_and_beauty_MX",
    { displayGroupTitle: "Salud y Cuidado Personal", displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/hpc" },
  ],
  [
    "dg_home_MX",
    { displayGroupTitle: "Hogar y Cocina", displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/kitchen" },
  ],
  [
    "dg_home_improvement_MX",
    {
      displayGroupTitle: "Herramientas y Mejoras del Hogar",
      displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/tools",
    },
  ],
  ["dg_music_MX", { displayGroupTitle: "Música", displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/music" }],
  [
    "dg_musical_instruments_MX",
    {
      displayGroupTitle: "Instrumentos Musicales",
      displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/musical-instruments",
    },
  ],
  [
    "dg_office_product_MX",
    {
      displayGroupTitle: "Oficina y papelería",
      displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/officeproduct",
    },
  ],
  [
    "dg_pet_products_MX",
    {
      displayGroupTitle: "Productos para animales",
      displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/pet-supplies",
    },
  ],
  [
    "dg_shoes_MX",
    {
      displayGroupTitle: "Ropa, Zapatos y Accesorios",
      displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/shoes",
    },
  ],
  [
    "dg_software_MX",
    { displayGroupTitle: "Software", displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/software" },
  ],
  [
    "dg_sports_MX",
    { displayGroupTitle: "Deportes y Aire Libre", displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/sports" },
  ],
  [
    "dg_super_MX",
    { displayGroupTitle: "Todo | Super", displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/super" },
  ],
  [
    "dg_toy_MX",
    { displayGroupTitle: "Juguetes y Juegos", displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/toys" },
  ],
  [
    "dg_video_games_MX",
    { displayGroupTitle: "Videojuegos", displayGroupLink: "https://www.amazon.com.mx/gp/bestsellers/videogames" },
  ],
  [
    "dg_amazon_devices_NL",
    {
      displayGroupTitle: "Amazon-apparaten & accessoires",
      displayGroupLink: "https://www.amazon.nl/gp/bestsellers/amazon-devices",
    },
  ],
  [
    "dg_amazon_renewed_NL",
    { displayGroupTitle: "Amazon Renewed", displayGroupLink: "https://www.amazon.nl/gp/bestsellers/amazon-renewed" },
  ],
  [
    "dg_apparel_NL",
    {
      displayGroupTitle: "Kleding, schoenen & sieraden",
      displayGroupLink: "https://www.amazon.nl/gp/bestsellers/fashion",
    },
  ],
  [
    "dg_art_and_craft_supply_NL",
    { displayGroupTitle: "Knutselen", displayGroupLink: "https://www.amazon.nl/gp/bestsellers/arts-crafts" },
  ],
  [
    "dg_automotive_NL",
    { displayGroupTitle: "Auto & motor", displayGroupLink: "https://www.amazon.nl/gp/bestsellers/automotive" },
  ],
  [
    "dg_baby_product_NL",
    { displayGroupTitle: "Babyproducten", displayGroupLink: "https://www.amazon.nl/gp/bestsellers/baby-products" },
  ],
  ["dg_beauty_NL", { displayGroupTitle: "Beauty", displayGroupLink: "https://www.amazon.nl/gp/bestsellers/beauty" }],
  [
    "dg_biss_NL",
    {
      displayGroupTitle: "Zakelijk, industrie & wetenschap",
      displayGroupLink: "https://www.amazon.nl/gp/bestsellers/industrial",
    },
  ],
  ["dg_book_NL", { displayGroupTitle: "Boeken", displayGroupLink: "https://www.amazon.nl/gp/bestsellers/books" }],
  [
    "dg_ce_NL",
    { displayGroupTitle: "Elektronica", displayGroupLink: "https://www.amazon.nl/gp/bestsellers/electronics" },
  ],
  ["dg_dvd_NL", { displayGroupTitle: "Films & tv", displayGroupLink: "https://www.amazon.nl/gp/bestsellers/dvd" }],
  [
    "dg_ebooks_NL",
    { displayGroupTitle: "Kindle Store", displayGroupLink: "https://www.amazon.nl/gp/bestsellers/digital-text" },
  ],
  [
    "dg_gift_card_NL",
    { displayGroupTitle: "Cadeaubonnen", displayGroupLink: "https://www.amazon.nl/gp/bestsellers/gift-cards" },
  ],
  [
    "dg_grocery_NL",
    { displayGroupTitle: "Levensmiddelen", displayGroupLink: "https://www.amazon.nl/gp/bestsellers/grocery" },
  ],
  [
    "dg_health_and_beauty_NL",
    {
      displayGroupTitle: "Gezondheid & persoonlijke verzorging",
      displayGroupLink: "https://www.amazon.nl/gp/bestsellers/hpc",
    },
  ],
  [
    "dg_home_NL",
    { displayGroupTitle: "Wonen en keuken", displayGroupLink: "https://www.amazon.nl/gp/bestsellers/home" },
  ],
  [
    "dg_home_improvement_NL",
    { displayGroupTitle: "Klussen & gereedschap", displayGroupLink: "https://www.amazon.nl/gp/bestsellers/hi" },
  ],
  [
    "dg_lawn_and_garden_NL",
    {
      displayGroupTitle: "Tuin, terras & gazon",
      displayGroupLink: "https://www.amazon.nl/gp/bestsellers/lawn-and-garden",
    },
  ],
  ["dg_music_NL", { displayGroupTitle: "Muziek", displayGroupLink: "https://www.amazon.nl/gp/bestsellers/music" }],
  [
    "dg_musical_instruments_NL",
    {
      displayGroupTitle: "Muziekinstrumenten",
      displayGroupLink: "https://www.amazon.nl/gp/bestsellers/musical-instruments",
    },
  ],
  [
    "dg_office_product_NL",
    { displayGroupTitle: "Kantoorproducten", displayGroupLink: "https://www.amazon.nl/gp/bestsellers/office-products" },
  ],
  [
    "dg_pet_products_NL",
    {
      displayGroupTitle: "Huisdierbenodigdheden",
      displayGroupLink: "https://www.amazon.nl/gp/bestsellers/pet-supplies",
    },
  ],
  [
    "dg_software_NL",
    { displayGroupTitle: "Software", displayGroupLink: "https://www.amazon.nl/gp/bestsellers/software" },
  ],
  [
    "dg_sports_NL",
    { displayGroupTitle: "Sport & outdoor", displayGroupLink: "https://www.amazon.nl/gp/bestsellers/sports" },
  ],
  [
    "dg_toy_NL",
    { displayGroupTitle: "Speelgoed & spellen", displayGroupLink: "https://www.amazon.nl/gp/bestsellers/toys" },
  ],
  [
    "dg_video_games_NL",
    { displayGroupTitle: "Games", displayGroupLink: "https://www.amazon.nl/gp/bestsellers/videogames" },
  ],
  ["dg_apparel_PL", { displayGroupTitle: "Moda", displayGroupLink: "https://www.amazon.pl/gp/bestsellers/fashion" }],
  [
    "dg_automotive_PL",
    { displayGroupTitle: "Motoryzacja", displayGroupLink: "https://www.amazon.pl/gp/bestsellers/automotive" },
  ],
  [
    "dg_baby_product_PL",
    { displayGroupTitle: "Produkty dziecięce", displayGroupLink: "https://www.amazon.pl/gp/bestsellers/baby" },
  ],
  ["dg_beauty_PL", { displayGroupTitle: "Uroda", displayGroupLink: "https://www.amazon.pl/gp/bestsellers/beauty" }],
  [
    "dg_biss_basic_PL",
    { displayGroupTitle: "Przemysł i nauka", displayGroupLink: "https://www.amazon.pl/gp/bestsellers/industrial" },
  ],
  ["dg_book_PL", { displayGroupTitle: "Książki", displayGroupLink: "https://www.amazon.pl/gp/bestsellers/books" }],
  [
    "dg_ce_PL",
    { displayGroupTitle: "Elektronika", displayGroupLink: "https://www.amazon.pl/gp/bestsellers/electronics" },
  ],
  [
    "dg_grocery_PL",
    { displayGroupTitle: "Artykuły spożywcze", displayGroupLink: "https://www.amazon.pl/gp/bestsellers/grocery" },
  ],
  [
    "dg_health_and_beauty_PL",
    {
      displayGroupTitle: "Zdrowie i gospodarstwo domowe",
      displayGroupLink: "https://www.amazon.pl/gp/bestsellers/health",
    },
  ],
  [
    "dg_home_improvement_PL",
    {
      displayGroupTitle: "Narzędzia i renowacja domu",
      displayGroupLink: "https://www.amazon.pl/gp/bestsellers/home-improvement",
    },
  ],
  [
    "dg_kitchen_PL",
    { displayGroupTitle: "Dom i kuchnia", displayGroupLink: "https://www.amazon.pl/gp/bestsellers/kitchen" },
  ],
  [
    "dg_lawn_and_garden_PL",
    { displayGroupTitle: "Ogród", displayGroupLink: "https://www.amazon.pl/gp/bestsellers/garden" },
  ],
  [
    "dg_musical_instruments_PL",
    {
      displayGroupTitle: "Instrumenty muzyczne",
      displayGroupLink: "https://www.amazon.pl/gp/bestsellers/musical-instruments",
    },
  ],
  [
    "dg_office_product_PL",
    {
      displayGroupTitle: "Artykuły papiernicze i biurowe",
      displayGroupLink: "https://www.amazon.pl/gp/bestsellers/office",
    },
  ],
  [
    "dg_pet_products_PL",
    {
      displayGroupTitle: "Artykuły dla zwierząt",
      displayGroupLink: "https://www.amazon.pl/gp/bestsellers/pet-supplies",
    },
  ],
  [
    "dg_sports_PL",
    { displayGroupTitle: "Sport i turystyka", displayGroupLink: "https://www.amazon.pl/gp/bestsellers/sports" },
  ],
  ["dg_toy_PL", { displayGroupTitle: "Zabawki", displayGroupLink: "https://www.amazon.pl/gp/bestsellers/toys" }],
  [
    "dg_video_games_PL",
    { displayGroupTitle: "Gry wideo", displayGroupLink: "https://www.amazon.pl/gp/bestsellers/videogames" },
  ],
  [
    "dg_amazon_devices_SA",
    {
      displayGroupTitle: "Amazon Devices & Accessories",
      displayGroupLink: "https://www.amazon.sa/gp/bestsellers/amazon-devices",
    },
  ],
  ["dg_apparel_SA", { displayGroupTitle: "Fashion", displayGroupLink: "https://www.amazon.sa/gp/bestsellers/fashion" }],
  [
    "dg_automotive_SA",
    { displayGroupTitle: "Automotive", displayGroupLink: "https://www.amazon.sa/gp/bestsellers/automotive" },
  ],
  [
    "dg_baby_product_SA",
    { displayGroupTitle: "Baby Products", displayGroupLink: "https://www.amazon.sa/gp/bestsellers/baby-products" },
  ],
  ["dg_beauty_SA", { displayGroupTitle: "Beauty", displayGroupLink: "https://www.amazon.sa/gp/bestsellers/beauty" }],
  ["dg_book_SA", { displayGroupTitle: "Books", displayGroupLink: "https://www.amazon.sa/gp/bestsellers/books" }],
  [
    "dg_ce_SA",
    { displayGroupTitle: "Electronics", displayGroupLink: "https://www.amazon.sa/gp/bestsellers/electronics" },
  ],
  [
    "dg_electronic_gift_card_SA",
    { displayGroupTitle: "Gift Cards", displayGroupLink: "https://www.amazon.sa/gp/bestsellers/gift-cards" },
  ],
  ["dg_grocery_SA", { displayGroupTitle: "Grocery", displayGroupLink: "https://www.amazon.sa/gp/bestsellers/grocery" }],
  [
    "dg_health_and_beauty_SA",
    { displayGroupTitle: "Health", displayGroupLink: "https://www.amazon.sa/gp/bestsellers/health" },
  ],
  ["dg_home_SA", { displayGroupTitle: "Home", displayGroupLink: "https://www.amazon.sa/gp/bestsellers/home" }],
  [
    "dg_home_improvement_SA",
    { displayGroupTitle: "DIY & Tools", displayGroupLink: "https://www.amazon.sa/gp/bestsellers/home-improvement" },
  ],
  [
    "dg_lawn_and_garden_SA",
    { displayGroupTitle: "Patio, Lawn & Garden", displayGroupLink: "https://www.amazon.sa/gp/bestsellers/garden" },
  ],
  [
    "dg_office_product_SA",
    { displayGroupTitle: "Office Products", displayGroupLink: "https://www.amazon.sa/gp/bestsellers/office-products" },
  ],
  [
    "dg_pet_products_SA",
    { displayGroupTitle: "Pet Supplies", displayGroupLink: "https://www.amazon.sa/gp/bestsellers/pet-supplies" },
  ],
  [
    "dg_sports_SA",
    { displayGroupTitle: "Sporting Goods", displayGroupLink: "https://www.amazon.sa/gp/bestsellers/sports-goods" },
  ],
  ["dg_toy_SA", { displayGroupTitle: "Toys", displayGroupLink: "https://www.amazon.sa/gp/bestsellers/toys" }],
  [
    "dg_video_games_SA",
    { displayGroupTitle: "Videogames", displayGroupLink: "https://www.amazon.sa/gp/bestsellers/videogames" },
  ],
  [
    "dg_amazon_renewed_SE",
    { displayGroupTitle: "Amazon Renewed", displayGroupLink: "https://www.amazon.se/gp/bestsellers/amazon-renewed" },
  ],
  ["dg_apparel_SE", { displayGroupTitle: "Mode", displayGroupLink: "https://www.amazon.se/gp/bestsellers/fashion" }],
  [
    "dg_automotive_SE",
    { displayGroupTitle: "Fordon", displayGroupLink: "https://www.amazon.se/gp/bestsellers/automotive" },
  ],
  [
    "dg_baby_product_SE",
    { displayGroupTitle: "Babyprodukter", displayGroupLink: "https://www.amazon.se/gp/bestsellers/baby" },
  ],
  ["dg_beauty_SE", { displayGroupTitle: "Skönhet", displayGroupLink: "https://www.amazon.se/gp/bestsellers/beauty" }],
  [
    "dg_biss_basic_SE",
    {
      displayGroupTitle: "Industriella verktyg & produkter",
      displayGroupLink: "https://www.amazon.se/gp/bestsellers/industrial",
    },
  ],
  ["dg_book_SE", { displayGroupTitle: "Böcker", displayGroupLink: "https://www.amazon.se/gp/bestsellers/books" }],
  [
    "dg_ce_SE",
    { displayGroupTitle: "Elektronik", displayGroupLink: "https://www.amazon.se/gp/bestsellers/electronics" },
  ],
  ["dg_dvd_SE", { displayGroupTitle: "Filmer och TV", displayGroupLink: "https://www.amazon.se/gp/bestsellers/dvd" }],
  [
    "dg_gift_card_SE",
    { displayGroupTitle: "Presentkort", displayGroupLink: "https://www.amazon.se/gp/bestsellers/gift-cards" },
  ],
  [
    "dg_grocery_SE",
    { displayGroupTitle: "Livsmedel", displayGroupLink: "https://www.amazon.se/gp/bestsellers/grocery" },
  ],
  [
    "dg_health_and_beauty_SE",
    { displayGroupTitle: "Hälsa, vård & hushåll", displayGroupLink: "https://www.amazon.se/gp/bestsellers/health" },
  ],
  [
    "dg_home_improvement_SE",
    {
      displayGroupTitle: "Bygg, el & verktyg",
      displayGroupLink: "https://www.amazon.se/gp/bestsellers/home-improvement",
    },
  ],
  [
    "dg_kitchen_SE",
    { displayGroupTitle: "Hem & kök", displayGroupLink: "https://www.amazon.se/gp/bestsellers/kitchen" },
  ],
  [
    "dg_lawn_and_garden_SE",
    { displayGroupTitle: "Trädgård", displayGroupLink: "https://www.amazon.se/gp/bestsellers/garden" },
  ],
  [
    "dg_music_SE",
    { displayGroupTitle: "CD-skivor och vinyl", displayGroupLink: "https://www.amazon.se/gp/bestsellers/music" },
  ],
  [
    "dg_musical_instruments_SE",
    {
      displayGroupTitle: "Musikinstrument",
      displayGroupLink: "https://www.amazon.se/gp/bestsellers/musical-instruments",
    },
  ],
  [
    "dg_office_product_SE",
    { displayGroupTitle: "Kontorsprodukter", displayGroupLink: "https://www.amazon.se/gp/bestsellers/office" },
  ],
  [
    "dg_pet_products_SE",
    { displayGroupTitle: "Husdjurstillbehör", displayGroupLink: "https://www.amazon.se/gp/bestsellers/pet-supplies" },
  ],
  [
    "dg_software_SE",
    { displayGroupTitle: "Programvara", displayGroupLink: "https://www.amazon.se/gp/bestsellers/software" },
  ],
  [
    "dg_sports_SE",
    { displayGroupTitle: "Sport & outdoor", displayGroupLink: "https://www.amazon.se/gp/bestsellers/sports" },
  ],
  ["dg_toy_SE", { displayGroupTitle: "Leksaker", displayGroupLink: "https://www.amazon.se/gp/bestsellers/toys" }],
  [
    "dg_video_games_SE",
    { displayGroupTitle: "TV-spel", displayGroupLink: "https://www.amazon.se/gp/bestsellers/videogames" },
  ],
  [
    "dg_automotive_SG",
    { displayGroupTitle: "Automotive", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/automotive" },
  ],
  [
    "dg_baby_product_SG",
    { displayGroupTitle: "Baby Products", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/baby" },
  ],
  ["dg_beauty_SG", { displayGroupTitle: "Beauty", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/beauty" }],
  [
    "dg_biss_SG",
    { displayGroupTitle: "Industrial & Scientific", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/biss" },
  ],
  ["dg_book_SG", { displayGroupTitle: "Books", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/books" }],
  [
    "dg_ce_SG",
    { displayGroupTitle: "Electronics", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/electronics" },
  ],
  ["dg_dvd_SG", { displayGroupTitle: "Movies & TV", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/dvd" }],
  [
    "dg_electronic_gift_card_SG",
    { displayGroupTitle: "Gift Cards", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/gift-cards" },
  ],
  ["dg_fashion_SG", { displayGroupTitle: "Fashion", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/fashion" }],
  ["dg_grocery_SG", { displayGroupTitle: "Grocery", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/grocery" }],
  [
    "dg_health_and_beauty_SG",
    {
      displayGroupTitle: "Health, Household & Personal Care",
      displayGroupLink: "https://www.amazon.sg/gp/bestsellers/hpc",
    },
  ],
  ["dg_home_SG", { displayGroupTitle: "Home", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/home" }],
  [
    "dg_home_improvement_SG",
    { displayGroupTitle: "DIY & Tools", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/diy" },
  ],
  ["dg_kitchen_SG", { displayGroupTitle: "Kitchen", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/kitchen" }],
  [
    "dg_lawn_and_garden_SG",
    { displayGroupTitle: "Garden", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/lawn-and-garden" },
  ],
  ["dg_music_SG", { displayGroupTitle: "Music", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/music" }],
  [
    "dg_musical_instruments_SG",
    {
      displayGroupTitle: "Musical Instruments",
      displayGroupLink: "https://www.amazon.sg/gp/bestsellers/musical-instruments",
    },
  ],
  [
    "dg_office_product_SG",
    { displayGroupTitle: "Office Products", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/office-products" },
  ],
  [
    "dg_pet_products_SG",
    { displayGroupTitle: "Pet Supplies", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/pet-supplies" },
  ],
  [
    "dg_premium_beauty_SG",
    { displayGroupTitle: "Luxury Beauty", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/luxury-beauty" },
  ],
  [
    "dg_software_SG",
    { displayGroupTitle: "Software", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/software" },
  ],
  [
    "dg_sports_SG",
    { displayGroupTitle: "Sporting Goods", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/sporting-goods" },
  ],
  ["dg_toy_SG", { displayGroupTitle: "Toys", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/toys" }],
  [
    "dg_video_games_SG",
    { displayGroupTitle: "Video Games", displayGroupLink: "https://www.amazon.sg/gp/bestsellers/videogames" },
  ],
  [
    "dg_apparel_TR",
    { displayGroupTitle: "Moda", displayGroupLink: "https://www.amazon.com.tr/gp/bestsellers/apparel" },
  ],
  [
    "dg_automotive_TR",
    { displayGroupTitle: "Otomotiv", displayGroupLink: "https://www.amazon.com.tr/gp/bestsellers/automotive" },
  ],
  [
    "dg_baby_product_TR",
    { displayGroupTitle: "Bebek", displayGroupLink: "https://www.amazon.com.tr/gp/bestsellers/baby-products" },
  ],
  [
    "dg_beauty_TR",
    {
      displayGroupTitle: "Kişisel Bakım ve Kozmetik",
      displayGroupLink: "https://www.amazon.com.tr/gp/bestsellers/beauty",
    },
  ],
  ["dg_book_TR", { displayGroupTitle: "Kitap", displayGroupLink: "https://www.amazon.com.tr/gp/bestsellers/books" }],
  [
    "dg_ce_TR",
    { displayGroupTitle: "Elektronik", displayGroupLink: "https://www.amazon.com.tr/gp/bestsellers/electronics" },
  ],
  [
    "dg_electronic_gift_card_TR",
    { displayGroupTitle: "Hediye Kartları", displayGroupLink: "https://www.amazon.com.tr/gp/bestsellers/gift-cards" },
  ],
  [
    "dg_grocery_TR",
    { displayGroupTitle: "Gıda Ürünleri", displayGroupLink: "https://www.amazon.com.tr/gp/bestsellers/grocery" },
  ],
  [
    "dg_home_TR",
    { displayGroupTitle: "Ev ve Yaşam", displayGroupLink: "https://www.amazon.com.tr/gp/bestsellers/home" },
  ],
  [
    "dg_home_improvement_TR",
    { displayGroupTitle: "Yapı Market", displayGroupLink: "https://www.amazon.com.tr/gp/bestsellers/home-improvement" },
  ],
  [
    "dg_kitchen_TR",
    { displayGroupTitle: "Mutfak", displayGroupLink: "https://www.amazon.com.tr/gp/bestsellers/kitchen" },
  ],
  [
    "dg_lawn_and_garden_TR",
    { displayGroupTitle: "Bahçe", displayGroupLink: "https://www.amazon.com.tr/gp/bestsellers/garden" },
  ],
  [
    "dg_musical_instruments_TR",
    {
      displayGroupTitle: "Müzik Enstrümanları ve DJ",
      displayGroupLink: "https://www.amazon.com.tr/gp/bestsellers/musical-instruments",
    },
  ],
  [
    "dg_office_product_TR",
    {
      displayGroupTitle: "Ofis ve Kırtasiye",
      displayGroupLink: "https://www.amazon.com.tr/gp/bestsellers/office-products",
    },
  ],
  [
    "dg_pc_TR",
    { displayGroupTitle: "Bilgisayar", displayGroupLink: "https://www.amazon.com.tr/gp/bestsellers/computers" },
  ],
  [
    "dg_pet_products_TR",
    {
      displayGroupTitle: "Evcil Hayvan Ürünleri",
      displayGroupLink: "https://www.amazon.com.tr/gp/bestsellers/pet-supplies",
    },
  ],
  [
    "dg_pharmacy_TR",
    { displayGroupTitle: "Sağlık ve Bakım", displayGroupLink: "https://www.amazon.com.tr/gp/bestsellers/hpc" },
  ],
  [
    "dg_sports_TR",
    {
      displayGroupTitle: "Spor ve Outdoor",
      displayGroupLink: "https://www.amazon.com.tr/gp/bestsellers/sporting-goods",
    },
  ],
  ["dg_toy_TR", { displayGroupTitle: "Oyuncak", displayGroupLink: "https://www.amazon.com.tr/gp/bestsellers/toys" }],
  [
    "dg_video_games_TR",
    {
      displayGroupTitle: "Video Oyunu ve Konsol",
      displayGroupLink: "https://www.amazon.com.tr/gp/bestsellers/videogames",
    },
  ],
  [
    "dg_amazon_devices_UK",
    {
      displayGroupTitle: "Amazon Devices & Accessories",
      displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/amazon-devices",
    },
  ],
  [
    "dg_amazon_renewed_UK",
    { displayGroupTitle: "Amazon Renewed", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/amazon-renewed" },
  ],
  [
    "dg_audible_UK",
    {
      displayGroupTitle: "Audible Books & Originals",
      displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/audible",
    },
  ],
  [
    "dg_automotive_UK",
    { displayGroupTitle: "Automotive", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/automotive" },
  ],
  [
    "dg_baby_product_UK",
    { displayGroupTitle: "Baby Products", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/baby" },
  ],
  ["dg_beauty_UK", { displayGroupTitle: "Beauty", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/beauty" }],
  [
    "dg_biss_basic_UK",
    {
      displayGroupTitle: "Business, Industry & Science",
      displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/industrial",
    },
  ],
  ["dg_book_UK", { displayGroupTitle: "Books", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/books" }],
  [
    "dg_boost_UK",
    { displayGroupTitle: "Amazon Launchpad", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/boost" },
  ],
  [
    "dg_ce_UK",
    {
      displayGroupTitle: "Electronics & Photo",
      displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/electronics",
    },
  ],
  [
    "dg_climate_product_UK",
    {
      displayGroupTitle: "Climate Pledge Friendly",
      displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/climate-pledge",
    },
  ],
  [
    "dg_digital_music_album_UK",
    { displayGroupTitle: "Albums", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/digital-music-album" },
  ],
  [
    "dg_digital_music_track_UK",
    { displayGroupTitle: "Songs", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/digital-music-track" },
  ],
  [
    "dg_download_movie_UK",
    { displayGroupTitle: "Prime Video", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/instant-video" },
  ],
  [
    "dg_dvd_UK",
    { displayGroupTitle: "DVD & Blu-ray", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/dvd" },
  ],
  [
    "dg_ebooks_UK",
    { displayGroupTitle: "Kindle Store", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/digital-text" },
  ],
  [
    "dg_fashion_UK",
    { displayGroupTitle: "Fashion", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/fashion" },
  ],
  [
    "dg_gift_card_UK",
    { displayGroupTitle: "Gift Cards", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/gift-cards" },
  ],
  [
    "dg_grocery_UK",
    { displayGroupTitle: "Grocery", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/grocery" },
  ],
  [
    "dg_guild_product_UK",
    { displayGroupTitle: "Handmade Products", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/handmade" },
  ],
  [
    "dg_health_and_beauty_UK",
    {
      displayGroupTitle: "Health & Personal Care",
      displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/drugstore",
    },
  ],
  [
    "dg_home_improvement_UK",
    { displayGroupTitle: "DIY & Tools", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/diy" },
  ],
  [
    "dg_kitchen_UK",
    { displayGroupTitle: "Home & Kitchen", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/kitchen" },
  ],
  [
    "dg_lawn_and_garden_UK",
    { displayGroupTitle: "Garden", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/outdoors" },
  ],
  [
    "dg_lighting_UK",
    { displayGroupTitle: "Lighting", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/lighting" },
  ],
  [
    "dg_major_appliances_UK",
    { displayGroupTitle: "Large Appliances", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/appliances" },
  ],
  [
    "dg_mobile_application_UK",
    {
      displayGroupTitle: "Apps & Games",
      displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/mobile-apps%3Ftf=1",
    },
  ],
  [
    "dg_music_UK",
    { displayGroupTitle: "CDs & Vinyl", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/music" },
  ],
  [
    "dg_musical_instruments_UK",
    {
      displayGroupTitle: "Musical Instruments & DJ",
      displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/musical-instruments",
    },
  ],
  [
    "dg_office_product_UK",
    {
      displayGroupTitle: "Stationery & Office Supplies",
      displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/officeproduct",
    },
  ],
  [
    "dg_pc_UK",
    {
      displayGroupTitle: "Computers & Accessories",
      displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/computers",
    },
  ],
  [
    "dg_pet_products_UK",
    { displayGroupTitle: "Pet Supplies", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/pet-supplies" },
  ],
  [
    "dg_software_UK",
    { displayGroupTitle: "Software", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/software" },
  ],
  [
    "dg_sports_UK",
    { displayGroupTitle: "Sports & Outdoors", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/sports" },
  ],
  [
    "dg_toy_UK",
    { displayGroupTitle: "Toys & Games", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/kids" },
  ],
  [
    "dg_video_games_UK",
    { displayGroupTitle: "PC & Video Games", displayGroupLink: "https://www.amazon.co.uk/gp/bestsellers/videogames" },
  ],
  [
    "dg_amazon_devices_US",
    {
      displayGroupTitle: "Amazon Devices & Accessories",
      displayGroupLink: "https://www.amazon.com/gp/bestsellers/amazon-devices",
    },
  ],
  [
    "dg_amazon_renewed_US",
    { displayGroupTitle: "Amazon Renewed", displayGroupLink: "https://www.amazon.com/gp/bestsellers/amazon-renewed" },
  ],
  [
    "dg_art_and_craft_supply_US",
    {
      displayGroupTitle: "Arts, Crafts & Sewing",
      displayGroupLink: "https://www.amazon.com/gp/bestsellers/arts-crafts",
    },
  ],
  [
    "dg_audible_US",
    {
      displayGroupTitle: "Audible Books & Originals",
      displayGroupLink: "https://www.amazon.com/gp/bestsellers/audible",
    },
  ],
  [
    "dg_automotive_US",
    { displayGroupTitle: "Automotive", displayGroupLink: "https://www.amazon.com/gp/bestsellers/automotive" },
  ],
  [
    "dg_baby_product_US",
    { displayGroupTitle: "Baby", displayGroupLink: "https://www.amazon.com/gp/bestsellers/baby-products" },
  ],
  [
    "dg_beauty_US",
    { displayGroupTitle: "Beauty & Personal Care", displayGroupLink: "https://www.amazon.com/gp/bestsellers/beauty" },
  ],
  [
    "dg_biss_US",
    {
      displayGroupTitle: "Industrial & Scientific",
      displayGroupLink: "https://www.amazon.com/gp/bestsellers/industrial",
    },
  ],
  ["dg_book_US", { displayGroupTitle: "Books", displayGroupLink: "https://www.amazon.com/gp/bestsellers/books" }],
  [
    "dg_boost_US",
    { displayGroupTitle: "Unique Finds", displayGroupLink: "https://www.amazon.com/gp/bestsellers/boost" },
  ],
  [
    "dg_ce_US",
    { displayGroupTitle: "Electronics", displayGroupLink: "https://www.amazon.com/gp/bestsellers/electronics" },
  ],
  [
    "dg_climate_product_US",
    {
      displayGroupTitle: "Climate Pledge Friendly",
      displayGroupLink: "https://www.amazon.com/gp/bestsellers/climate-pledge",
    },
  ],
  [
    "dg_coins_US",
    { displayGroupTitle: "Collectible Coins", displayGroupLink: "https://www.amazon.com/gp/bestsellers/coins" },
  ],
  [
    "dg_collectibles_US",
    {
      displayGroupTitle: "Sports Collectibles",
      displayGroupLink: "https://www.amazon.com/gp/bestsellers/sports-collectibles",
    },
  ],
  [
    "dg_digital_music_album_US",
    { displayGroupTitle: "Albums", displayGroupLink: "https://www.amazon.com/gp/bestsellers/digital-music-album" },
  ],
  [
    "dg_digital_music_track_US",
    { displayGroupTitle: "Songs", displayGroupLink: "https://www.amazon.com/gp/bestsellers/digital-music-track" },
  ],
  [
    "dg_dvd_US",
    { displayGroupTitle: "Movies & TV", displayGroupLink: "https://www.amazon.com/gp/bestsellers/movies-tv" },
  ],
  [
    "dg_ebooks_US",
    { displayGroupTitle: "Kindle Store", displayGroupLink: "https://www.amazon.com/gp/bestsellers/digital-text" },
  ],
  [
    "dg_entmnt_collectibles_US",
    {
      displayGroupTitle: "Entertainment Collectibles",
      displayGroupLink: "https://www.amazon.com/gp/bestsellers/entertainment-collectibles",
    },
  ],
  [
    "dg_fashion_US",
    {
      displayGroupTitle: "Clothing, Shoes & Jewelry",
      displayGroupLink: "https://www.amazon.com/gp/bestsellers/fashion",
    },
  ],
  [
    "dg_gift_card_US",
    { displayGroupTitle: "Gift Cards", displayGroupLink: "https://www.amazon.com/gp/bestsellers/gift-cards" },
  ],
  [
    "dg_grocery_US",
    { displayGroupTitle: "Grocery & Gourmet Food", displayGroupLink: "https://www.amazon.com/gp/bestsellers/grocery" },
  ],
  [
    "dg_guild_product_US",
    { displayGroupTitle: "Handmade Products", displayGroupLink: "https://www.amazon.com/gp/bestsellers/handmade" },
  ],
  [
    "dg_health_and_beauty_US",
    { displayGroupTitle: "Health & Household", displayGroupLink: "https://www.amazon.com/gp/bestsellers/hpc" },
  ],
  [
    "dg_home_garden_US",
    { displayGroupTitle: "Home & Kitchen", displayGroupLink: "https://www.amazon.com/gp/bestsellers/home-garden" },
  ],
  [
    "dg_home_improvement_US",
    { displayGroupTitle: "Tools & Home Improvement", displayGroupLink: "https://www.amazon.com/gp/bestsellers/hi" },
  ],
  [
    "dg_kitchen_US",
    { displayGroupTitle: "Kitchen & Dining", displayGroupLink: "https://www.amazon.com/gp/bestsellers/kitchen" },
  ],
  [
    "dg_lawn_and_garden_US",
    {
      displayGroupTitle: "Patio, Lawn & Garden",
      displayGroupLink: "https://www.amazon.com/gp/bestsellers/lawn-garden",
    },
  ],
  [
    "dg_magazine_US",
    {
      displayGroupTitle: "Magazine Subscriptions",
      displayGroupLink: "https://www.amazon.com/gp/bestsellers/magazines",
    },
  ],
  [
    "dg_major_appliances_US",
    { displayGroupTitle: "Appliances", displayGroupLink: "https://www.amazon.com/gp/bestsellers/appliances" },
  ],
  [
    "dg_mobile_application_US",
    { displayGroupTitle: "Apps & Games", displayGroupLink: "https://www.amazon.com/gp/bestsellers/mobile-apps%3Ftf=1" },
  ],
  [
    "dg_music_US",
    { displayGroupTitle: "CDs & Vinyl", displayGroupLink: "https://www.amazon.com/gp/bestsellers/music" },
  ],
  [
    "dg_musical_instruments_US",
    {
      displayGroupTitle: "Musical Instruments",
      displayGroupLink: "https://www.amazon.com/gp/bestsellers/musical-instruments",
    },
  ],
  [
    "dg_office_product_US",
    { displayGroupTitle: "Office Products", displayGroupLink: "https://www.amazon.com/gp/bestsellers/office-products" },
  ],
  [
    "dg_pc_US",
    { displayGroupTitle: "Computers & Accessories", displayGroupLink: "https://www.amazon.com/gp/bestsellers/pc" },
  ],
  [
    "dg_pet_products_US",
    { displayGroupTitle: "Pet Supplies", displayGroupLink: "https://www.amazon.com/gp/bestsellers/pet-supplies" },
  ],
  [
    "dg_photo_US",
    { displayGroupTitle: "Camera & Photo Products", displayGroupLink: "https://www.amazon.com/gp/bestsellers/photo" },
  ],
  [
    "dg_private_brands_US",
    { displayGroupTitle: "Our Brands", displayGroupLink: "https://www.amazon.com/gp/bestsellers/private-brands" },
  ],
  [
    "dg_software_US",
    { displayGroupTitle: "Software", displayGroupLink: "https://www.amazon.com/gp/bestsellers/software" },
  ],
  [
    "dg_sports_US",
    {
      displayGroupTitle: "Sports & Outdoors",
      displayGroupLink: "https://www.amazon.com/gp/bestsellers/sporting-goods",
    },
  ],
  [
    "dg_toy_US",
    { displayGroupTitle: "Toys & Games", displayGroupLink: "https://www.amazon.com/gp/bestsellers/toys-and-games" },
  ],
  [
    "dg_video_games_US",
    { displayGroupTitle: "Video Games", displayGroupLink: "https://www.amazon.com/gp/bestsellers/videogames" },
  ],
  [
    "dg_wireless_US",
    {
      displayGroupTitle: "Cell Phones & Accessories",
      displayGroupLink: "https://www.amazon.com/gp/bestsellers/wireless",
    },
  ],
]);
