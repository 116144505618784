<ng-container *transloco="let t">
  <div class="modal-header bg-primary">
    <h4 class="modal-title pull-left">{{ t("tactic-add-popup.add_a_new_tactic") }}</h4>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label class="col-sm-2 col-form-label"
        >{{ t("tactic-add-popup.tactic_type") }}&nbsp;<fa-icon
          [icon]="faInfo"
          class="lg-tooltip"
          aria-hidden="true"
          matTooltip="{{ t('tactic-add-popup.product_target_explain') }}13;{{
            t('tactic-add-popup.blacklist_explain')
          }}"
        ></fa-icon>
      </label>
      <div class="col-sm-10">
        <ISelect
          [options]="tacticTypesOptions"
          [selected]="selectedTacticType"
          (selectedChange)="selectTacticType($event)"
          testid="btn-tactic-toggle"
          placeholder="{{ t('common.select') }}"
        />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">{{ t("common.segment") }}</label>
      <div class="col-sm-10">
        <ISelect
          [options]="segmentOptions"
          [selected]="segment"
          (selectedChange)="selectSegment($event)"
          testid="btn-segment-toggle"
          placeholder="{{ t('sd-strategy-creation.select_a_segment') }}"
          searchable
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <IButton label="{{ t('common.cancel') }}" color="gray" variant="soft" (onClick)="close()" />
    <IButton
      class="w-2/4"
      label="{{ t('common.next') }}"
      type="submit"
      [disabled]="!creation && !segment"
      testid="btn-confirm"
      (onClick)="create()"
      [block]="true"
    />
  </div>
</ng-container>
