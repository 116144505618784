// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    AudienceExpressionType,
    AudienceMatchType,
    State,
} from './';

/**
 * @export
 * @interface AudienceTargeting
 */
export interface AudienceTargeting {
    /**
     * @type {number}
     * @memberof AudienceTargeting
     */
    audienceTargetId?: number;
    /**
     * @type {AudienceExpressionType}
     * @memberof AudienceTargeting
     */
    expressionType?: AudienceExpressionType;
    /**
     * @type {AudienceMatchType}
     * @memberof AudienceTargeting
     */
    matchType?: AudienceMatchType;
    /**
     * @type {State}
     * @memberof AudienceTargeting
     */
    state?: State;
    /**
     * @type {number}
     * @memberof AudienceTargeting
     */
    lookback?: number;
    /**
     * @type {string}
     * @memberof AudienceTargeting
     */
    idName?: string;
    /**
     * @type {number}
     * @memberof AudienceTargeting
     */
    audienceId?: number;
    /**
     * @type {number}
     * @memberof AudienceTargeting
     */
    categoryId?: number;
}
