import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-input-modal",
  templateUrl: "./input-modal.component.html",
  styleUrls: ["./input-modal.component.scss"],
})
export class InputModalComponent {
  @Input() title: string;
  @Input() maxLength: number;
  @Input() inputControl: FormControl;
  @Output() emitUpdate = new EventEmitter();

  constructor(protected bsModalRef: BsModalRef) {}

  update() {
    this.emitUpdate.emit();
    this.bsModalRef.hide();
  }
}
