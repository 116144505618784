import { CommonModule } from "@angular/common";
import { Component, computed, effect, inject, OnInit, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { NavigationEnd, Router, RouterLink } from "@angular/router";
import { AccountMarketplace } from "@front/m19-api-client";
import { AccountGroup, NotificationEx } from "@front/m19-models";
import {
  AccountSelectionService,
  AuthService,
  BillingService,
  NotificationService,
  UserSelectionService,
} from "@front/m19-services";
import {
  DropdownMenuItem,
  IAccordionComponent,
  IBadgeComponent,
  IButtonComponent,
  IDropdownComponent,
} from "@front/m19-ui";
import { TranslocoDirective } from "@jsverse/transloco";
import { BoardType, LayoutSelectorService } from "@m19-board/layout-selector.service";
import { environment } from "apps/m19-board/src/environments/environment";
import { combineLatest } from "rxjs";
import { filter, map } from "rxjs/operators";
import { OnboardingCtaComponent } from "./onboarding-cta.component";

interface NavSection {
  label: string;
  items: NavItem[];
}

interface NavItem {
  icon?: string;
  label: string;
  id?: string;
  url?: string;
  href?: string;
  children?: NavItem[];
  isChild?: boolean;
  onlyBeta?: boolean;
}

@Component({
  selector: "board-sidebar-v2",
  standalone: true,
  imports: [
    CommonModule,
    IAccordionComponent,
    IButtonComponent,
    TranslocoDirective,
    RouterLink,
    IBadgeComponent,
    OnboardingCtaComponent,
    IDropdownComponent,
  ],
  templateUrl: "./board-sidebar-v2.component.html",
})
export class BoardSidebarV2Component implements OnInit {
  router = inject(Router);
  private readonly authService = inject(AuthService);
  private readonly userSelectionService = inject(UserSelectionService);
  private readonly layoutSelector = inject(LayoutSelectorService);
  private readonly billingService = inject(BillingService);
  private readonly notificationService = inject(NotificationService);
  private readonly accountSelectionService = inject(AccountSelectionService);

  readonly releaseNotesUrl = environment.releaseNotesUrl;
  readonly BoardType = BoardType;
  readonly NAV_ITEM_CLASS =
    "flex h-7 max-h-7 min-h-7 cursor-pointer select-none items-center gap-1.5 rounded-md bg-transparent px-2 no-underline transition-colors duration-100 hover:bg-gray-100 hover:no-underline";

  notifications = toSignal(
    combineLatest([
      this.notificationService.getNotifications$,
      this.accountSelectionService.accountGroupSelection$,
      this.accountSelectionService.accountMarketplacesSelection$,
    ]).pipe(
      map(
        ([notifications, accountGroup, accountMarketplaces]: [
          NotificationEx[],
          AccountGroup,
          AccountMarketplace[],
        ]) => {
          const accountIds = accountGroup.getAccountMarketplaces().map((a) => a.accountId);
          return this.filterNotifications(notifications, accountIds, accountMarketplaces);
        },
      ),
    ),
  );

  notifCountLabel = computed(() => {
    const count = this.notifications()?.length;
    return count && count > 99 ? "99+" : count?.toString();
  });

  isBillingOwnerOfActiveSubscription = toSignal(
    this.billingService.organizationOnwer$.pipe(map((o) => !!o?.hasActiveSubscription())),
    {
      requireSync: true,
    },
  );
  isCollapsed = this.userSelectionService.sidebarCollapsed;
  isBetaTester = toSignal<boolean>(this.authService.loggedUser$.pipe(map((u) => !!u.betaTester)), {
    requireSync: true,
  });

  currentUrl = toSignal(
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event) => event.url),
    ),
  );
  openedItemId = signal<string | undefined>(undefined);
  selectedItemId = computed(() => {
    if (!this.currentUrl()) return undefined;

    for (const section of this.menu()) {
      for (const item of section.items) {
        if (item?.url && this.currentUrl()?.startsWith(item.url)) return item.id;
        if (item?.children) {
          const child = item.children.find((child) => {
            return child?.url && this.currentUrl()?.startsWith(child.url);
          });
          if (child) {
            return this.openedItemId() === item.id ? child.id : item.id;
          }
        }
      }
    }
    return undefined;
  });

  boardType = this.layoutSelector.getBoardType();
  whitelabelContactUsLink = this.layoutSelector.getConctact();

  dashboardMenu = signal<NavSection>({
    label: "Dashboard",
    items: [
      {
        icon: "icon-[lucide--home]",
        label: "v2-sidebar.home",
        url: "/dashboard/home",
        id: "dashboard-home",
      },
      {
        icon: "icon-[lucide--layout-dashboard]",
        label: "v2-sidebar.account_360",
        url: "/dashboard/account360",
        id: "dashboard-account360",
      },
      {
        icon: "icon-[lucide--line-chart]",
        label: "v2-sidebar.advertising",
        url: "/dashboard/advertising",
        id: "dashboard-advertising",
      },
      {
        icon: "icon-[lucide--dollar-sign]",
        label: "v2-sidebar.profit_and_loss",
        url: "/dashboard/profit-and-loss",
        id: "dashboard-profit-and-loss",
      },
    ],
  });

  advertisingMenu = computed<NavSection>(() => ({
    label: "Advertising",
    items: [
      {
        icon: "icon-[lucide--lightbulb]",
        label: "common.strategies",
        id: "strategies",
        children: [
          {
            label: "v2-sidebar.sp",
            url: "/advertising/sponsored-product",
            id: "sponsored-product",
          },
          {
            label: "v2-sidebar.sb",
            url: "/advertising/sponsored-brands",
            id: "sponsored-brands",
          },
          {
            label: "v2-sidebar.sd",
            url: "/advertising/sponsored-display",
            id: "sponsored-display",
          },
          {
            label: "metrics.TACOS_title",
            url: "/advertising/tacos-strategies",
            id: "tacos",
          },
        ],
      },
      {
        label: "v2-sidebar.dsp",
        icon: "icon-[lucide--globe]",
        url: "/advertising/dsp",
        id: "dsp",
      },
      {
        label: "v2-sidebar.amc",
        icon: "icon-[lucide--cloud]",
        url: "/advertising/amc",
        id: "amc",
      },
      {
        label: "v2-sidebar.more",
        id: "advertising-more",
        icon: "icon-[lucide--ellipsis]",
        children: [
          {
            label: "v2-sidebar.hourly_stats",
            url: "/advertising/hourly-stats",
            id: "hourly-stats",
          },
          {
            label: "v2-sidebar.promo_day",
            url: "/advertising/promo-days-boost",
            id: "promo-day",
          },
        ],
      },
    ],
  }));

  productMenu = signal<NavSection>({
    label: "v2-sidebar.product_center",
    items: [
      {
        label: "v2-sidebar.product_analysis",
        icon: "icon-[lucide--search]",
        url: "/product-center/product-analysis",
        id: "product-analysis",
      },
      {
        label: "v2-sidebar.catalog",
        icon: "icon-[lucide--book-open]",
        url: "/product-center/catalog",
        id: "catalog",
      },
      {
        label: "v2-sidebar.inventory",
        url: "/product-center/inventory",
        id: "inventory",
        icon: "icon-[lucide--box]",
      },
      {
        label: "v2-sidebar.more",
        id: "product-more",
        icon: "icon-[lucide--ellipsis]",
        children: [
          {
            label: "v2-sidebar.product_360",
            url: "/product-center/product360",
            id: "product-360",
          },
          {
            label: "v2-sidebar.product_tracker",
            url: "/product-center/product-tracker",
            id: "product-tracker",
          },

          {
            label: "v2-sidebar.product_groups",
            url: "/product-center/product-group",
            id: "product-group",
          },
          {
            label: "v2-sidebar.brand_alias",
            url: "/product-center/brand-alias",
            id: "brand-alias",
          },
        ],
      },
    ],
  });

  keywordMenu = signal<NavSection>({
    label: "v2-sidebar.keyword_center",
    items: [
      {
        label: "v2-sidebar.traffic_analysis",
        icon: "icon-[lucide--mouse-pointer-click]",
        url: "/keyword-center/traffic-analysis",
        id: "traffic-analysis",
      },
      {
        label: "v2-sidebar.keyword_tracker",
        icon: "icon-[lucide--chart-no-axes-column]",
        url: "/keyword-center/keyword-tracker",
        id: "keyword-tracker",
      },
      {
        label: "v2-sidebar.more",
        id: "keyword-more",
        icon: "icon-[lucide--ellipsis]",
        children: [
          {
            label: "v2-sidebar.share_of_voice",
            url: "/keyword-center/share-of-voice",
            id: "share-of-voice",
          },
          {
            label: "v2-sidebar.search_trends",
            url: "/keyword-center/search-trends",
            id: "search-trends",
          },
          {
            label: "v2-sidebar.segments",
            url: "/keyword-center/segments",
            id: "segments",
          },
        ],
      },
    ],
  });

  private readonly settingsMenu = signal<DropdownMenuItem[][]>([
    [
      {
        label: "dashboard360-layout.my_profile",
        icon: "icon-[lucide--user]",
        url: "/profile",
      },
      {
        label: "v2-layout.accounts",
        icon: "icon-[lucide--users]",
        url: "/accounts",
      },
      {
        label: "v2-layout.user_management",
        icon: "icon-[lucide--user-cog]",
        url: "/user-management",
      },
      {
        label: "v2-sidebar.activities",
        icon: "icon-[lucide--newspaper]",
        url: "/activities",
      },
    ],
    [
      {
        label: "v2-layout.billing",
        icon: "icon-[lucide--credit-card]",
        url: "/billing",
      },
      {
        label: "data-sharing.data_hub",
        icon: "icon-[lucide--database]",
        url: "/datahub",
      },
      {
        label: "v2-layout.referral_program",
        icon: "icon-[lucide--gift]",
        href: "https://www.m19.com/get-rewards-for-your-account",
      },
      {
        label: "v2-layout.release_notes",
        icon: "icon-[lucide--sparkles]",
        href: this.releaseNotesUrl,
      },
    ],
    [
      {
        label: "dashboard360-layout.logout",
        icon: "icon-[lucide--log-out]",
        onClick: () => this.logout(),
      },
    ],
  ]);

  readonly filteredSettingsMenu = computed<DropdownMenuItem[][]>(() => {
    return this.settingsMenu().map((section) =>
      section.filter((item) => {
        if (item.label === "v2-layout.referral_program") {
          return this.boardType === BoardType.M19;
        } else if (item.label === "v2-layout.billing") {
          return (
            this.boardType == BoardType.M19 ||
            this.boardType == BoardType.WHITELABEL_SELFSERVICE ||
            this.isBillingOwnerOfActiveSubscription()
          );
        }
        return true;
      }),
    );
  });

  menu = computed<NavSection[]>(() => [
    this.stripBetaItemsSection(this.dashboardMenu(), this.isBetaTester()),
    this.stripBetaItemsSection(this.advertisingMenu(), this.isBetaTester()),
    this.stripBetaItemsSection(this.productMenu(), this.isBetaTester()),
    this.stripBetaItemsSection(this.keywordMenu(), this.isBetaTester()),
  ]);

  constructor() {
    effect(() => {
      localStorage.setItem("sidebar-opened-item", this.openedItemId() ?? "");
      localStorage.setItem("sidebar-collapsed", this.isCollapsed().toString());
    });
  }

  ngOnInit(): void {
    this.openedItemId.set(localStorage.getItem("sidebar-opened-item") ?? undefined);
  }

  private logout() {
    this.authService.logout();
    this.router.navigate(["/login"]);
  }

  private stripBetaItemsSection(items: NavSection, isBetaTester: boolean): NavSection {
    return {
      ...items,
      items: this.stripBetaNavItems(items.items, isBetaTester),
    };
  }

  private stripBetaNavItems(items: NavItem[], isBetaTester: boolean): NavItem[] {
    return items
      .filter((item) => !item.onlyBeta || isBetaTester)
      .map((item) => {
        if (item.children) {
          return {
            ...item,
            children: this.stripBetaNavItems(item.children, isBetaTester),
          };
        }
        return item;
      });
  }

  private filterNotifications(
    notifications: NotificationEx[],
    accountIds: string[],
    accountMarketplaces: AccountMarketplace[],
  ) {
    const result = [];
    for (const notification of notifications) {
      if (notification.dismissed) {
        continue; // do not display dismissed notif
      }
      if (notification.isCritical) {
        result.push(notification); // display critical notif
        continue;
      }
      if (
        notification.marketplace &&
        accountMarketplaces.findIndex(
          (am) => am.accountId == notification.accountId && am.marketplace == notification.marketplace,
        ) > -1
      ) {
        // display notification of the selected marketplaces
        result.push(notification);
        continue;
      }
      if (!notification.marketplace && accountIds.includes(notification.accountId)) {
        // display notification of the selected marketplaces
        result.push(notification);
      }
    }
    return result;
  }

  toggleItem(id: string) {
    this.openedItemId.set(this.openedItemId() === id ? undefined : id);
  }
}
