import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ProductGroupEx } from '@front/m19-models';
import { Marketplace, ProductGroup, ProductGroupApi, Response } from '@front/m19-api-client';
import { accountMarketplaceKey, AccountMarketplaceKey, catchAjaxError } from '@front/m19-utils';

@Injectable({
  providedIn: 'root',
})
export class ProductGroupService {
  private static nameRegexp =
    // eslint-disable-next-line no-useless-escape
    /^[- !$'#%& () * +,./: ;<=>?@`_|{}~0-9a-zA-Z®ÁÉÍÑÓÚÜáéíñóúüÄÖÜßäöüÀÂÆÇÈÉÊËÎÏÔÙÛÜŒŸàâæçèêëîïôùûüÿœ\\[\]\u0022\u3000-\u309F\u30A0-\u30FF\u4E00-\u9FFF\uFF00-\uFFEF]*$/;

  private productGroupsCache$ = new Map<AccountMarketplaceKey, ReplaySubject<ProductGroupEx[]>>();
  private productGroupsCache = new Map<AccountMarketplaceKey, ProductGroupEx[]>();

  constructor(private productGroupService: ProductGroupApi) {}

  public getProductGroups(accountId: string, marketplace: Marketplace): Observable<ProductGroupEx[]> {
    const key = accountMarketplaceKey(accountId, marketplace);
    if (this.productGroupsCache$.has(key)) {
      return this.productGroupsCache$.get(key)!;
    }
    const subject = new ReplaySubject<ProductGroupEx[]>(1);
    this.productGroupsCache$.set(key, subject);
    this.productGroupService
      .listProductGroups({ accountId })
      .pipe(catchAjaxError())
      .subscribe({
        next: (response) => {
          const productGroups = response.map((pg) => new ProductGroupEx(pg));
          subject.next(productGroups);
          this.productGroupsCache.set(key, productGroups);
        },
        error: (e) => subject.error(e),
      });
    return subject;
  }

  public createProductGroup(
    accountId: string,
    marketplace: Marketplace,
    productGroupName: string,
    asins: string[] = [],
  ): Observable<ProductGroupEx> {
    const error = this.isValidProductGroupName(productGroupName, accountId, marketplace, undefined);
    if (error) {
      return throwError(() => error);
    }
    const productGroup: ProductGroup = {
      productGroupId: undefined,
      accountId: accountId,
      productGroupName: productGroupName,
      items: asins,
    };
    return this.productGroupService.createProductGroup({ productGroup }).pipe(
      catchAjaxError(),
      map((response: Response) => {
        const pg = response.entity as ProductGroup;
        const newProductGroup = new ProductGroupEx(pg);
        const key = accountMarketplaceKey(accountId, marketplace);
        // update cache
        if (this.productGroupsCache$.has(key) && this.productGroupsCache.has(key)) {
          this.productGroupsCache.get(key)!.push(newProductGroup);
          this.productGroupsCache$.get(key)!.next(this.productGroupsCache.get(key)!);
        }
        return newProductGroup;
      }),
    );
  }

  public updateProductGroupName(
    accountId: string,
    marketplace: Marketplace,
    productGroupId: number,
    productGroupName: string,
  ): Observable<ProductGroupEx> {
    if (!productGroupName) {
      return throwError(() => 'Missing Product Group name');
    }
    productGroupName = productGroupName.trim();
    const productGroup = this.productGroupsCache
      .get(accountMarketplaceKey(accountId, marketplace))
      ?.find((pg) => pg.productGroupId == productGroupId);
    if (productGroup == undefined) {
      return throwError(() => 'Invalid Product Group Id');
    }
    const newProductGroup = {
      ...productGroup.toProductGroup(),
      productGroupName,
    };
    return this.updateProductGroup(accountId, marketplace, newProductGroup);
  }

  public updateProductGroup(
    accountId: string,
    marketplace: Marketplace,
    productGroup: ProductGroup,
  ): Observable<ProductGroupEx> {
    const key = accountMarketplaceKey(accountId, marketplace);
    return this.productGroupService
      .updateProductGroup({
        productGroup: productGroup,
      })
      .pipe(
        catchAjaxError(),
        map((response: Response) => {
          const pg = response.entity as ProductGroup;
          const updatedProductGroup = new ProductGroupEx(pg);
          // update cache
          if (this.productGroupsCache$.has(key) && this.productGroupsCache.has(key)) {
            const index = this.productGroupsCache
              .get(key)!
              .findIndex((pg) => pg.productGroupId == productGroup.productGroupId);
            this.productGroupsCache.get(key)![index] = updatedProductGroup;
            this.productGroupsCache$.get(key)!.next(this.productGroupsCache.get(key)!);
          }
          return updatedProductGroup;
        }),
      );
  }

  public deleteProductGroup(accountId: string, marketplace: Marketplace, productGroupId: number): Observable<void> {
    return this.productGroupService.deleteProductGroup({ accountId, productGroupId }).pipe(
      catchAjaxError(),
      map(() => {
        const key = accountMarketplaceKey(accountId, marketplace);
        if (this.productGroupsCache$.has(key) && this.productGroupsCache.has(key)) {
          const index = this.productGroupsCache.get(key)!.findIndex((pg) => pg.productGroupId == productGroupId);
          this.productGroupsCache.get(key)!.splice(index, 1);
          this.productGroupsCache$.get(key)!.next(this.productGroupsCache.get(key)!);
        }
        return void 0;
      }),
    );
  }

  public addAsinsToProductGroup(
    accountId: string,
    marketplace: Marketplace,
    productGroupId: number,
    asins: string[],
    accountAsins: string[],
  ): Observable<ProductGroupEx> {
    const key = accountMarketplaceKey(accountId, marketplace);
    const productGroup = this.productGroupsCache.get(key)?.find((pg) => pg.productGroupId == productGroupId);
    if (productGroup == undefined) {
      return throwError(() => 'Invalid Product Group Id');
    }
    if (!asins || asins.length == 0) {
      return throwError(() => 'No ASIN to add to Product Group');
    }
    const errors: string[] = [];
    let updateNeeded = false;
    for (let asin of asins) {
      asin = asin.trim().toUpperCase();
      if (asin == '') continue;
      if (productGroup.containsAsin(asin)) errors.push(asin + ': duplicate');
      else if (!accountAsins.includes(asin)) errors.push(asin + ': unknown - not in catalog');
      else if (productGroup.items.length >= ProductGroupEx.MaxProductGroupItems) {
        errors.push(
          asin + ' not added because the product group is full (Max ' + productGroup.items.length + ' ASINs)',
        );
      } else {
        productGroup.addAsin(asin);
        updateNeeded = true;
      }
    }
    if (errors.length > 0) {
      return throwError(() => errors.join('\n'));
    }
    if (updateNeeded) {
      return this.updateProductGroup(accountId, marketplace, productGroup.toProductGroup());
    }
    return of(productGroup);
  }

  public deleteAsinsFromProductGroup(
    accountId: string,
    marketplace: Marketplace,
    productGroupId: number,
    asins: string[],
  ): Observable<ProductGroupEx> {
    const key = accountMarketplaceKey(accountId, marketplace);
    const productGroup = this.productGroupsCache.get(key)?.find((pg) => pg.productGroupId == productGroupId);
    if (productGroup == undefined) {
      return throwError(() => 'Invalid Product Group Id');
    }
    let updateNeeded = false;
    for (let asin of asins) {
      asin = asin.toUpperCase();
      if (productGroup.containsAsin(asin)) {
        productGroup.removeAsin(asin);
        updateNeeded = true;
      }
    }
    if (updateNeeded) {
      return this.updateProductGroup(accountId, marketplace, productGroup.toProductGroup());
    }
    return of(productGroup);
  }

  public isValidProductGroupName(
    productGroupName: string,
    accountId: string,
    marketplace: Marketplace,
    productGroupId?: number,
  ): string | undefined {
    if (!productGroupName || productGroupName.length == 0) return 'Missing Product Group name';
    if (productGroupName.length > 80) return 'Product Group name must be less than 80 characters';
    for (const pg of this.productGroupsCache.get(accountMarketplaceKey(accountId, marketplace)) ?? []) {
      if (pg.productGroupId != productGroupId && pg.productGroupName == productGroupName && pg.accountId == accountId)
        return 'This Product Group name already exists';
    }
    if (!ProductGroupService.nameRegexp.test(productGroupName)) return 'Invalid character used';
    return undefined;
  }
}
