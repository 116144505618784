import { formatCurrency } from "@angular/common";
import { Component, inject, OnInit, signal } from "@angular/core";
import { AccountMarketplace } from "@front/m19-api-client";
import { AccountMarketplaceService, AuthService, CurrencyService } from "@front/m19-services";
import { IBadgeComponent, IButtonComponent, IInputComponent, MODAL_DATA, ModalRef } from "@front/m19-ui";
import { ToastrService } from "ngx-toastr";
import { TranslocoDirective } from "@jsverse/transloco";

@Component({
  templateUrl: "./update-max-bid.popup.component.html",
  styleUrls: ["./update-max-bid.popup.scss"],
  standalone: true,
  imports: [TranslocoDirective, IInputComponent, IBadgeComponent, IButtonComponent],
})
export class ModalUpdateMaxBidComponent implements OnInit {
  maxBid!: number;
  currencySymbol!: string;
  locale?: string;

  loading = signal(false);

  private data = inject(MODAL_DATA) as UpdateMaxBidData;
  ref = inject(ModalRef);

  constructor(
    private accountMarketplaceService: AccountMarketplaceService,
    private currencyService: CurrencyService,
    private toasterService: ToastrService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.currencySymbol = this.currencyService.getCurrencySymbolFromMarketplace(
      this.data.accountMarketplace.marketplace,
    );
    this.maxBid = this.data.accountMarketplace.maxBid!;
    this.authService.user$.subscribe((user) => {
      this.locale = user?.locale;
    });
  }

  updateMaxBid() {
    this.loading.set(true);
    this.accountMarketplaceService.updateMaxBid(
      this.data.accountMarketplace,
      this.maxBid,
      () => {
        this.toasterService.success(
          "Max Bid successfully changed to " +
            formatCurrency(this.maxBid, this.locale!, this.currencySymbol) +
            " on " +
            this.data.accountMarketplace.accountName +
            ".",
          "Max Bid updated",
        );
        this.loading.set(false);
        this.ref.close();
      },
      (error) => {
        this.toasterService.error(error, "Max bid update error");
        this.loading.set(false);
      },
    );
  }
}

export interface UpdateMaxBidData {
  accountMarketplace: AccountMarketplace;
}
