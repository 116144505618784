import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { StrategyEx } from "@front/m19-models";
import { AccountSelectionService, StrategyService } from "@front/m19-services";
import { IButtonComponent, ISelectComponent, Option } from "@front/m19-ui";
import { ICON_PAUSE, ICON_PLAY } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";
import { map, switchMap } from "rxjs/operators";
import { TranslocoDirective } from "@jsverse/transloco";

@UntilDestroy()
@Component({
  selector: "app-move-strategy-asins-modal",
  templateUrl: "./move-strategy-asins-modal.component.html",
  styleUrls: ["./move-strategy-asins-modal.component.scss"],
  standalone: true,
  imports: [TranslocoDirective, ISelectComponent, IButtonComponent],
})
export class MoveStrategyAsinsModalComponent implements OnInit {
  @Input()
  source!: StrategyEx;

  @Input()
  asinsToMove!: string[];

  @Output()
  selectedStrategy = new EventEmitter<StrategyEx>();

  @Output()
  newStrategyCreation = new EventEmitter();

  strategies: StrategyEx[] = [];
  strategiesOptions: Option<StrategyEx | undefined>[] = [];
  newStrat = false;
  target: Option<StrategyEx | undefined> | undefined;

  readonly ICON_PLAY = ICON_PLAY;
  readonly ICON_PAUSE = ICON_PAUSE;

  constructor(
    private strategyService: StrategyService,
    public bsModalRef: BsModalRef,
    private accountSelectionService: AccountSelectionService,
  ) {}

  ngOnInit(): void {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) => this.strategyService.getStrategyIndex(am.accountId, am.marketplace)),
        untilDestroyed(this),
        map((strategyIndex) => Array.from(strategyIndex.values()).map((s) => new StrategyEx(s))),
      )
      .subscribe((strategies) => {
        this.strategies = strategies.filter(
          (s) =>
            s.campaignType == this.source.campaignType &&
            !s.strategyGroupId && // cannot move ASINs to strategies belonging to a strategy group
            !s.defaultStrategy &&
            !s.asins.find((a) => this.asinsToMove.includes(a.asin)),
        );
        this.strategiesOptions = [
          ...this.strategies.map((s) => ({ label: s.name ?? "", value: s, strategyId: s.strategyId })),
        ];
        // Sort options by state, enabled first
        this.strategiesOptions.sort(
          (a, b) => (a.value?.state === "ENABLED" ? -1 : 1) - (b.value?.state === "ENABLED" ? -1 : 1),
        );
        this.strategiesOptions.unshift({ label: "New strategy", value: undefined, strategyId: undefined });
        this.target = this.strategiesOptions.find((o) => !o.value);
      });
  }

  prettyPrintAsins(asins: string[]): string {
    const maxAsins = 5;
    let message = asins.slice(0, maxAsins).join(", ");
    if (asins.length > maxAsins) {
      message += `… (and ${asins.length - maxAsins} more)`;
    }
    return message;
  }

  createNewStrategy(): void {
    this.newStrat = true;
    this.target = { label: "New strategy", value: undefined };
  }

  selectStrategy(strategy: Option<StrategyEx | undefined>): void {
    if (!strategy.value) {
      this.createNewStrategy();
      return;
    }
    this.newStrat = false;
    this.target = strategy;
  }

  confirm(): void {
    if (this.newStrat) {
      this.newStrategyCreation.emit();
    } else if (this.target?.value) {
      this.selectedStrategy.emit(this.target.value);
    }
    this.bsModalRef.hide();
  }
}
