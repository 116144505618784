<div class="modal-body h-40">
  <h4>Select Brand</h4>

  <span class="mb-2 text-sm font-medium">Select a brand to import targetings from</span>
  <ISelect
    [options]="brands"
    [selectedValue]="selectedBrand?.value"
    (selectedOptionChange)="selectBrand($event)"
    placeholder="Select Brand"
  />

  @if (selectedTargetings) {
    <div>
      <p class="mt-2 text-gray-600">
        {{ selectedTargetings.keywords.length }} keywords and {{ selectedTargetings.products.length }} products will be
        imported
      </p>
    </div>
  }
</div>

<div class="modal-footer flex justify-end gap-x-1">
  <IButton label="Cancel" color="white" (onClick)="close()" />
  <IButton label="Import" (onClick)="emit()" [disabled]="!selectedTargetings" [block]="true" class="w-1/5" />
</div>
