<ng-container *transloco="let t">
  <form [formGroup]="form">
    <div class="mb-3 flex" [class.flex-col]="vertical">
      @for (algo of [AlgoMode.ACOS_TARGET, AlgoMode.MONTHLY_BUDGET_TARGET, AlgoMode.PRODUCT_LAUNCH]; track algo) {
        <div
          [ngClass]="{
            'checkbox-card-selected': form.controls.algoMode.value === algo,
            disabled: disabled,
          }"
          class="checkbox-card"
          (click)="toggleAlgorithm(algo)"
          [attr.data-testid]="
            'toggle-' +
            (algo === AlgoMode.ACOS_TARGET
              ? 'acos-target'
              : algo === AlgoMode.MONTHLY_BUDGET_TARGET
                ? 'monthly-budget'
                : 'product-launch')
          "
        >
          <div class="w-100 flex justify-between">
            <div class="text-md">{{ t(AlgoModeStrKey[algo].shortDescription) }}</div>
            <div [class.checked]="form.controls.algoMode.value === algo" class="radio-btn shrink-0">
              <div></div>
            </div>
          </div>
          <div class="text-gray-700">{{ t(AlgoModeStrKey[algo].description) }}</div>
        </div>
      }
    </div>
    @switch (form.controls.algoMode.value) {
      @case (AlgoMode.ACOS_TARGET) {
        <div class="form-group input-section flex-row">
          <label class="col-sm-3 col-form-label">{{ t("algo-mode-selection.acos_target") }}</label>
          <div class="col-sm-9">
            <app-integer-value
              [disabled]="disabled"
              testid="acos-target"
              [value]="form.controls.acosTarget.value"
              [min]="1"
              [max]="200"
              [unit]="'%'"
              [size]="2.5"
              (onChange)="form.controls.acosTarget.setValue(+$event)"
            ></app-integer-value>
            @if (form.controls.acosTarget.invalid) {
              <div class="error">{{ t("algo-mode-selection.acos_range") }}</div>
            }
          </div>
        </div>
      }
      @case (AlgoMode.MONTHLY_BUDGET_TARGET) {
        <div class="form-group input-section flex-row">
          <label class="col-sm-3 col-form-label">{{ t("algo-mode-selection.monthly_budget_target") }}</label>
          <div class="col-sm-9">
            <app-integer-value
              [disabled]="disabled"
              [testid]="'monthly-budget'"
              [value]="form.controls.monthlyBudget.value"
              [min]="1"
              [max]="null"
              [unit]="currencyCode"
              [size]="2.5"
              (onChange)="form.controls.monthlyBudget.setValue(+$event)"
            ></app-integer-value>
            @if (form.controls.monthlyBudget.invalid) {
              <div class="error">{{ t("algo-mode-selection.positive") }}</div>
            }
            @if (form.controls.monthlyBudget.value > 0) {
              <div class="alert alert-warning mt-3" role="alert">
                {{
                  t("algo-mode-selection.budget_for_month", [
                    form.controls.monthlyBudget.value | currency: currencyCode : "symbol" : "1.2-2" : locale,
                    endOfCurrentMonth | date: "MMMM y" : undefined : "en-US",
                  ])
                }}
              </div>
            }
          </div>
        </div>
      }
      @case (AlgoMode.PRODUCT_LAUNCH) {
        <div class="input-section input-section flex-row">
          <label class="col-sm-3 col-form-label">{{ t("switch-target-algo-modal.suggested_bid") }}</label>
          <div class="col-sm-9">
            <app-decimal-input
              [disabled]="disabled"
              [testid]="'product-launch-bid'"
              [value]="form.controls.suggestedBid.value"
              [precision]="2"
              [min]="0"
              [trash]="false"
              [withActionButtons]="false"
              [unit]="currencyCode"
              [size]="2.5"
              (onChange)="form.controls.suggestedBid.setValue(+$event)"
            ></app-decimal-input>
            @if (form.controls.suggestedBid.invalid) {
              <div class="error" data-testid="min-bid-error">
                {{ t("algo-mode-selection.greater_than_min", [minAllowedBid]) }}
              </div>
            }
          </div>
        </div>
        <div class="input-section input-section mt-2 flex-row">
          <label class="col-sm-3 col-form-label">{{ t("metrics.DAILY_BUDGET_title") }}</label>
          <div class="col-sm-9">
            <app-integer-value
              [disabled]="disabled"
              [testid]="'product-launch-budget'"
              [value]="form.controls.dailyBudget.value"
              [min]="dailyBudgetLowerBound"
              [unit]="currencyCode"
              [size]="2.5"
              (onChange)="form.controls.dailyBudget.setValue(+$event)"
            ></app-integer-value>
            @if (form.controls.dailyBudget.errors?.dailyBudgetBound) {
              <div class="error">{{ t("algo-mode-selection.min_daily_budget", [dailyBudgetLowerBound]) }}</div>
            }
            @if (form.controls.dailyBudget.errors?.fiveTimesGreater) {
              <div class="error" data-testid="daily-budget-error">{{ t("algo-mode-selection.5_time_greater") }}</div>
            }
          </div>
        </div>
      }
    }
  </form>
</ng-container>
