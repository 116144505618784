import { Component, ViewChild } from "@angular/core";
import {
  AccountSelectionService,
  AuthService,
  DataSet,
  DataSetEventAnnotation,
  OrganizationAccountGroupService,
  StatsService,
  UserSelectionService,
} from "@front/m19-services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { AgGridAngular } from "@ag-grid-community/angular";
import {
  ColDef,
  GetRowIdParams,
  GridOptions,
  ICellRendererParams,
  IRowNode,
  ModelUpdatedEvent,
  SortDirection,
} from "@ag-grid-community/core";
import { actionColumnProperties, CurrencyColumn, MarketplaceColumn } from "@m19-board/grid-config/grid-columns";
import {
  ACTIONS_COL_ID,
  exportGridCsv,
  getCsvFileName,
  getMetricsColDef,
  STATUS_BAR,
} from "@m19-board/grid-config/grid-config";

import { AccountMarketplace, AccountState, AccountType, AdStats, Currency } from "@front/m19-api-client";
import { getBasicGridOptions, getFilteredLeafNodes, getGroupRowAgg } from "@front/m19-grid-config";
import {
  ACOS,
  AD_CONVERSIONS,
  AD_SALES,
  CLICK_THROUGH_RATE,
  CLICKS,
  CONVERSION_RATE,
  COST,
  CPC,
  IMPRESSIONS,
  M19_ACOS,
  M19_AD_CONVERSIONS,
  M19_AD_SALES,
  M19_CLICK_THROUGH_RATE,
  M19_CLICKS,
  M19_CONVERSION_RATE,
  M19_COST,
  M19_CPC,
  M19_IMPRESSIONS,
  M19_METRICS,
  M19_ROAS,
  Metric,
  MetricRegistry,
  ROAS,
  TOTAL_SALES,
  UNMANAGED_ACOS,
  UNMANAGED_AD_CONVERSIONS,
  UNMANAGED_AD_SALES,
  UNMANAGED_CLICK_THROUGH_RATE,
  UNMANAGED_CLICKS,
  UNMANAGED_CONVERSION_RATE,
  UNMANAGED_COST,
  UNMANAGED_CPC,
  UNMANAGED_IMPRESSIONS,
  UNMANAGED_ROAS,
} from "@front/m19-metrics";
import { AdStatsEx } from "@front/m19-models";
import {
  DropdownItem,
  IButtonComponent,
  ICardComponent,
  IPopoverContentComponent,
  ModalOptions,
  ModalService,
  Option,
} from "@front/m19-ui";
import {
  addAdStats,
  AggregationFunction,
  convertToCurrency,
  DateAggregation,
  mergeSeveralDates,
  Utils,
} from "@front/m19-utils";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { ActivityService } from "@m19-board/activities/activity.service";
import { SPONSORED_SALES_SHARE, TACOS, TOTAL_ORDERS } from "@m19-board/models/MetricsDef";
import { CsvExportService, fieldExtractor, metricField, simpleField } from "@m19-board/services/csv-export.service";
import {
  ChartData,
  ChartModalComponent,
  ChartModalInputData,
} from "@m19-board/shared/chart-modal/chart-modal.component";
import { LinkComponent } from "@m19-board/shared/link/link.component";
import { ICON_CHART_LINE, ICON_CLOSE } from "@m19-board/utils/iconsLabels";
import { BehaviorSubject, combineLatest, Observable, of, Subject } from "rxjs";
import { filter, map, shareReplay, switchMap } from "rxjs/operators";
import {
  ActionButton,
  ActionButtonsComponent,
} from "../insights/advertising-stats/action-buttons/action-buttons.component";
import { MetricsSelectorLocalStorageKey } from "libs/m19-services/src/lib";
import { MetricSelectorComponent } from "@m19-board/insights/metric-selector/metric-selector.component";
import { DateAggreationComponent } from "@m19-board/shared/switch-button/date-aggregation-switch-button.component";
import { AsyncPipe } from "@angular/common";
import { BaseChartDirective } from "ng2-charts";
import { ExportButtonComponent } from "@m19-board/shared/ui/export-buttons/export-button.component";
import { StatsOverlayComponent } from "@m19-board/overlay/stats-overlay.component";

export interface SuperBoardData extends AdStatsEx {
  rowId: string;
  organizationName: string;
  organizationId: number;
  accountState?: string;
  accountGroupName?: string;
  accountType?: AccountType;
  vendorManufacturingAccess?: boolean;
  dailyStats: AdStatsEx[];
}

@UntilDestroy()
@Component({
  selector: "app-agency-board",
  templateUrl: "./agency-board.component.html",
  imports: [
    MetricSelectorComponent,
    ICardComponent,
    DateAggreationComponent,
    AsyncPipe,
    IButtonComponent,
    BaseChartDirective,
    IPopoverContentComponent,
    ExportButtonComponent,
    AgGridAngular,
    StatsOverlayComponent,
    TranslocoDirective,
  ],
  standalone: true,
})
export class AgencyBoardComponent {
  readonly localStorageKey = MetricsSelectorLocalStorageKey.superBoard;

  readonly METRICS = [
    TOTAL_SALES,
    TOTAL_ORDERS,
    AD_SALES,
    AD_CONVERSIONS,
    COST,
    ACOS,
    CLICKS,
    IMPRESSIONS,
    CLICK_THROUGH_RATE,
    CONVERSION_RATE,
    CPC,
    ROAS,
    SPONSORED_SALES_SHARE,
    TACOS,
  ];
  DETAIL_METRICS_GRID = [
    TOTAL_SALES,
    TOTAL_ORDERS,

    M19_AD_SALES,
    UNMANAGED_AD_SALES,
    M19_COST,
    UNMANAGED_COST,
    M19_ACOS,
    UNMANAGED_ACOS,
    M19_CLICKS,
    UNMANAGED_CLICKS,
    M19_IMPRESSIONS,
    UNMANAGED_IMPRESSIONS,
    M19_AD_CONVERSIONS,
    UNMANAGED_AD_CONVERSIONS,
    M19_CONVERSION_RATE,
    UNMANAGED_CONVERSION_RATE,
    M19_CPC,
    UNMANAGED_CPC,
    M19_ROAS,
    UNMANAGED_ROAS,
    M19_CLICK_THROUGH_RATE,
    UNMANAGED_CLICK_THROUGH_RATE,
    SPONSORED_SALES_SHARE,
    TACOS,
  ];

  metricColDefs = [
    ...getMetricsColDef(this.METRICS).map((def: ColDef) => ({
      ...def,
      headerName: this.translocoService.translate(`metrics.${def["colId"]}_title`, {}, "en"),
      headerValueGetter: (params: any) => {
        return this.translocoService.translate(`metrics.${params.colDef.colId}_title`);
      },
      cellRendererParams: (params: any) => {
        return {
          ...def.cellRendererParams(params),
          previousData: this.getPreviousNodeData(params.node),
          currency: this.currency,
          locale: this.locale,
        };
      },
    })),
  ];
  detailColDefs = [
    ...getMetricsColDef(this.DETAIL_METRICS_GRID).map((def: ColDef) => ({
      ...def,
      headerName: this.translocoService.translate(`metrics.${def.colId}_title`, {}, "en"),
      headerValueGetter: (params: any) => this.translocoService.translate(`metrics.${params.colDef.colId}_title`),
      cellRendererParams: (params: any) => {
        return {
          ...def.cellRendererParams(params),
          previousData: this.getPreviousNodeData(params.node),
          currency: this.currency,
          locale: this.locale,
        };
      },
      hide: !(
        def.colId?.includes("COST") ||
        def.colId?.includes("SALES") ||
        def.colId?.includes("ACOS") ||
        def.colId?.includes("ORDERS")
      ),
      sort: def.colId === "M19_AD_SALES" ? ("desc" as SortDirection) : null, // Default sorting on AD_SALES
    })),
  ];
  readonly M19_SAME_SCALE_METRICS = [
    [M19_ACOS, UNMANAGED_ACOS],
    [TOTAL_SALES, AD_SALES, COST, M19_AD_SALES, UNMANAGED_AD_SALES, M19_COST, UNMANAGED_COST],
    [M19_CLICKS, UNMANAGED_CLICKS],
    [M19_IMPRESSIONS, UNMANAGED_IMPRESSIONS],
    [M19_AD_CONVERSIONS, UNMANAGED_AD_CONVERSIONS],
    [M19_CONVERSION_RATE, UNMANAGED_CONVERSION_RATE],
    [M19_CPC, UNMANAGED_CPC],
    [M19_ROAS, UNMANAGED_ROAS],
    [M19_CLICK_THROUGH_RATE, UNMANAGED_CLICK_THROUGH_RATE],
  ];

  readonly STATUS_BAR = STATUS_BAR;
  readonly ICON_CHART = ICON_CHART_LINE;
  readonly ICON_CLOSE = ICON_CLOSE;

  private locale = "fr";
  private currency: string = Currency.EUR;

  readonly exportCsvDropdownItems: DropdownItem[];

  singleAccountMarketplaceSelection: Option<string> = { label: "", value: "" };
  // top metric selector
  totalData: AdStatsEx = {};
  previousTotalData?: AdStatsEx;
  private selectedMetrics$: BehaviorSubject<Metric<AdStatsEx>[]> = new BehaviorSubject([AD_SALES, COST]);
  // global chart config
  isGlobalChartHidden = false;
  globalDataset: DataSet<AdStatsEx>; // Dataset for global chart
  globalChartData$: Subject<{ data: AdStatsEx[]; previousData: AdStatsEx[] }> = new Subject();
  dateAggregation$ = new BehaviorSubject<DateAggregation>(DateAggregation.daily);

  // grid data
  gridData$: Observable<Map<string, SuperBoardData[]>>;
  previousDataByAccount$ = new BehaviorSubject(
    new Map<string, { totalPreviousData: AdStatsEx; previousDailyStats: AdStatsEx[] }>(),
  );

  minDate: string = "";
  maxDate: string = "";
  previousDateRange?: string[];

  // Grid configuration
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  private GRID_KEY = "agencyBoardGrid";
  private managedUnmanaged = "Managed/Unmanaged";
  private total = "Total";
  displayMode$: BehaviorSubject<string> = new BehaviorSubject(this.total);

  // Col defs common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  public columnDefs: ColDef[] = [
    {
      ...actionColumnProperties<SuperBoardData, string>(),
      cellRendererSelector: (params) => {
        return {
          component: ActionButtonsComponent,
          params: {
            actionButtons: [
              {
                icon: ICON_CHART_LINE,
                tooltip: this.translocoService.translate("agency-board.display_graph"),
                onClick: (params: ICellRendererParams) => {
                  this.displayRowChart(params.node);
                },
              },
            ] as ActionButton[],
          },
        };
      },
    },
    {
      field: "organizationName",
      headerValueGetter: () => this.translocoService.translate("account-setting.organization"),
      headerName: this.translocoService.translate("account-setting.organization", {}, "en"),
      pinned: "left",
      hide: true,
      enableRowGroup: true,
      cellClass: "sensitive-data",
      filter: "agSetColumnFilter",
      floatingFilter: true,
    },
    {
      field: "accountGroupName",
      headerValueGetter: () => this.translocoService.translate("agency-board.account_group"),
      headerName: this.translocoService.translate("agency-board.account_group", {}, "en"),
      pinned: "left",
      hide: true,
      enableRowGroup: true,
      cellClass: "sensitive-data",
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "accountName",
      headerValueGetter: () => this.translocoService.translate("common.account"),
      headerName: this.translocoService.translate("common.account", {}, "en"),
      pinned: "left",
      cellRendererSelector: (params) => {
        const marketplace = params.node.data?.marketplace;
        const accountId = params.node.data?.accountId;
        const orgId = params.node.data?.organizationId;
        return {
          component: LinkComponent,
          params: {
            routerLink: "/dashboard/advertising",
            queryParams: { accountId, marketplace, orgId },
            content: params.value,
            queryParamsHandling: "merge",
          },
        };
      },
      enableRowGroup: true,
      cellClass: "sensitive-data",
      filter: "agTextColumnFilter",
      floatingFilter: true,
    },
    {
      ...MarketplaceColumn(this.translocoService),
      pinned: "left",
      enableRowGroup: true,
    },
    {
      colId: "accountState",
      headerValueGetter: () => this.translocoService.translate("billing-customer.state"),
      headerName: this.translocoService.translate("billing-customer.state", {}, "en"),
      pinned: "left",
      hide: true,
      enableRowGroup: true,
      valueGetter: (params) => {
        switch (params.data?.accountState) {
          case AccountState.BIDDER_ON:
            return this.translocoService.translate("agency-board.ads_automation");
          case AccountState.DOWNLOADER_ON:
            return this.translocoService.translate("agency-board.pull_stats");
          default:
            return "-";
        }
      },
      filter: "agSetColumnFilter",
      floatingFilter: true,
    },
    {
      headerValueGetter: () => this.translocoService.translate("agency-board.account_type"),
      headerName: this.translocoService.translate("agency-board.account_type", {}, "en"),
      pinned: "left",
      hide: true,
      enableRowGroup: true,
      valueGetter: (params) => {
        switch (params.data?.accountType) {
          case AccountType.SELLER:
            return this.translocoService.translate("common.seller");
          case AccountType.VENDOR:
            return this.translocoService.translate("common.vendor");
          default:
            return "-";
        }
      },
      floatingFilter: true,
      filter: "agSetColumnFilter",
    },
    {
      ...CurrencyColumn,
      hide: true,
      valueGetter: () => this.currency,
      headerValueGetter: () => this.translocoService.translate("common.currency"),
    },
    // Metrics columns
  ];

  columnDefsWithMetrics = [...this.columnDefs, ...this.metricColDefs];
  columnDefsWithDetails = [...this.columnDefs, ...this.detailColDefs];

  private commonOptions = getBasicGridOptions(this.GRID_KEY, true, false, true, (a, b) => {
    const t = addAdStats(a, b);
    t.marketplace = undefined; // to remove marketplace flag on total row
    return t;
  });
  public gridOptions: GridOptions = {
    ...this.commonOptions,
    defaultColDef: this.defaultColDef,
    columnDefs: this.columnDefsWithMetrics,
    showOpenedGroup: false,
    context: { componentParent: this, locale: this.locale, currency: this.currency },
    rowGroupPanelShow: "always",
    autoGroupColumnDef: {
      pinned: "left",
    },
    onModelUpdated: (event: ModelUpdatedEvent<any>) => {
      if (this.commonOptions.onModelUpdated) this.commonOptions.onModelUpdated(event);
    },
    getGroupRowAgg: getGroupRowAgg,
    getRowId: (params: GetRowIdParams<SuperBoardData>) => params.data.rowId,
    enableCharts: true,
  };

  constructor(
    private authService: AuthService,
    private userSelectionService: UserSelectionService,
    private statsService: StatsService,
    private modalService: ModalService,
    private organizationAccountGroupService: OrganizationAccountGroupService,
    private activityService: ActivityService,
    private translocoService: TranslocoService,
    private accountSelectionService: AccountSelectionService,
    private csvExportService: CsvExportService,
  ) {
    this.exportCsvDropdownItems = [
      {
        label: this.translocoService.translate("agency-board.export_global_stats"),
        onClick: () => this.exportGlobalDataCsv(),
      },
      {
        label: this.translocoService.translate("agency-board.export_daily_stats"),
        onClick: () => this.exportDailyDataCsv(),
      },
    ];
    this.globalDataset = new DataSet<AdStatsEx>(
      3,
      this.selectedMetrics$.getValue(),
      [AggregationFunction.mergeSeveralDates],
      this.translocoService,
    );
    this.globalDataset.metricsOnSameScale = this.M19_SAME_SCALE_METRICS;

    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((currency: Currency) => {
      this.currency = currency;
      this.globalDataset.currency = currency;
    });
    this.userSelectionService.periodComparison$.pipe(untilDestroyed(this)).subscribe((periodComparison) => {
      this.previousDateRange = periodComparison?.period;
    });

    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.locale = user.locale;
      this.globalDataset.locale = user.locale;
    });

    this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.singleAccountMarketplaceSelection = { value: am.accountId, label: am.accountName };
    });
    // get all marketplaces
    const accountMarketplaces$ = this.organizationAccountGroupService.allOrganizationAccountGroups$.pipe(
      map((organizations) => {
        const accountMarketplaces = new Array<AccountMarketplace & { organizationName: string }>();
        for (const organization of organizations ?? []) {
          for (const accountGroup of organization.accountGroups) {
            for (const accountMarketplace of accountGroup.getAccountMarketplaces()) {
              if (
                accountMarketplace.hasAccessToAdvertising &&
                (accountMarketplace.state == AccountState.BIDDER_ON ||
                  accountMarketplace.state == AccountState.DOWNLOADER_ON)
              ) {
                accountMarketplaces.push({
                  resourceOrganizationId: accountMarketplace.resourceOrganizationId,
                  accountId: accountMarketplace.accountId,
                  marketplace: accountMarketplace.marketplace,
                  accountName: accountMarketplace.accountName,
                  accountGroupId: accountMarketplace.accountGroupId,
                  accountGroupName: accountMarketplace.accountGroupName,
                  state: accountMarketplace.state,
                  accountType: accountMarketplace.accountType,
                  useSourcingMetrics: accountMarketplace.useSourcingMetrics,
                  organizationName: organization.organizationName,
                });
              }
            }
          }
        }
        return accountMarketplaces;
      }),
      filter((accountMarketplaces) => accountMarketplaces.length > 0),
      shareReplay(1),
    );

    // get all data
    const allData$ = combineLatest([this.userSelectionService.dateRange$, accountMarketplaces$]).pipe(
      switchMap(([dateRange, accountMarketplaces]) => {
        this.minDate = dateRange[0];
        this.maxDate = dateRange[1];
        this.agGrid?.api.showLoadingOverlay();

        return combineLatest([
          this.statsService.getAdStatsPerAccountMarketplace(accountMarketplaces, dateRange[0], dateRange[1]),
          accountMarketplaces.find((am) => !am.accountId.startsWith("ENTITY")) // at least one seller (there is a not in the condition)
            ? this.statsService.getSalesPerSellerAccountMarketplace(
                accountMarketplaces.filter((am) => am.accountType == AccountType.SELLER),
                dateRange[0],
                dateRange[1],
              )
            : of(<AdStats[]>[]),
          accountMarketplaces.find((am) => am.accountType == AccountType.VENDOR) // at least one vendor
            ? this.statsService.getSalesPerVendorAccountMarketplace(
                accountMarketplaces.filter((am) => am.accountType == AccountType.VENDOR),
                dateRange[0],
                dateRange[1],
              )
            : of(<AdStats[]>[]),
          this.userSelectionService.selectedCurrency$,
        ]).pipe(
          map(([advStats, allSales, vendorSales, currency]) => {
            advStats = advStats.map((stat) => {
              const ex = stat as AdStatsEx;
              if (ex.isM19) {
                ex.m19Cost = ex.cost;
                ex.m19AdSales = ex.adSales;
                ex.m19Clicks = ex.clicks;
                ex.m19Impressions = ex.impressions;
                ex.m19AdConversions = ex.adConversions;

                ex.unmanagedAdSales = 0;
                ex.unmanagedCost = 0;
                ex.unmanagedAdConversions = 0;
                ex.unmanagedClicks = 0;
                ex.unmanagedImpressions = 0;
              } else {
                ex.m19Cost = 0;
                ex.m19AdSales = 0;
                ex.m19Clicks = 0;
                ex.m19Impressions = 0;
                ex.m19AdConversions = 0;

                ex.unmanagedAdSales = ex.adSales;
                ex.unmanagedCost = ex.cost;
                ex.unmanagedAdConversions = ex.adConversions;
                ex.unmanagedClicks = ex.clicks;
                ex.unmanagedImpressions = ex.impressions;
              }
              return ex;
            });
            convertToCurrency(advStats, currency);
            convertToCurrency(allSales, currency);
            convertToCurrency(vendorSales, currency);
            return {
              advStats,
              allSales,
              vendorSales,
              accountMarketplaces,
              dateRange,
            };
          }),
        );
      }),
      shareReplay(1),
    );
    // get all previous data
    const allPreviousData$ = combineLatest([this.userSelectionService.periodComparison$, accountMarketplaces$]).pipe(
      switchMap(([periodComparison, accountMarketplaces]) => {
        if (!periodComparison?.period) {
          return of({
            previousAdvStats: [] as AdStatsEx[],
            previousAllSales: [] as AdStatsEx[],
            previousVendorSales: [] as AdStatsEx[],
          });
        }
        const dateRange = periodComparison?.period;
        return combineLatest([
          this.statsService.getAdStatsPerAccountMarketplace(accountMarketplaces, dateRange[0], dateRange[1]), // copy to avoid modifying the original data
          accountMarketplaces.find((am) => !am.accountId.startsWith("ENTITY")) // at least one seller (there is a not in the condition)
            ? this.statsService.getSalesPerSellerAccountMarketplace(accountMarketplaces, dateRange[0], dateRange[1])
            : of(<AdStats[]>[]),
          accountMarketplaces.find((am) => am.accountId.startsWith("ENTITY")) // at least one vendor
            ? this.statsService.getSalesPerVendorAccountMarketplace(accountMarketplaces, dateRange[0], dateRange[1])
            : of(<AdStats[]>[]),
          this.userSelectionService.selectedCurrency$,
        ]).pipe(
          map(([previousAdvStats, previousAllSales, previousVendorSales, currency]) => {
            previousAdvStats = previousAdvStats.map((stat) => {
              const ex = stat as AdStatsEx;
              if (ex.isM19) {
                ex.m19Cost = ex.cost;
                ex.m19AdSales = ex.adSales;
                ex.m19Clicks = ex.clicks;
                ex.m19Impressions = ex.impressions;
                ex.m19AdConversions = ex.adConversions;

                ex.unmanagedAdSales = 0;
                ex.unmanagedCost = 0;
                ex.unmanagedAdConversions = 0;
                ex.unmanagedClicks = 0;
                ex.unmanagedImpressions = 0;
              } else {
                ex.m19Cost = 0;
                ex.m19AdSales = 0;
                ex.m19Clicks = 0;
                ex.m19Impressions = 0;
                ex.m19AdConversions = 0;

                ex.unmanagedAdSales = ex.adSales;
                ex.unmanagedCost = ex.cost;
                ex.unmanagedAdConversions = ex.adConversions;
                ex.unmanagedClicks = ex.clicks;
                ex.unmanagedImpressions = ex.impressions;
              }
              return ex;
            });
            convertToCurrency(previousAdvStats, currency);
            convertToCurrency(previousAllSales, currency);
            convertToCurrency(previousVendorSales, currency);
            return {
              previousAdvStats,
              previousAllSales,
              previousVendorSales,
            };
          }),
        );
      }),
      shareReplay(1),
    );

    combineLatest([allData$, allPreviousData$, this.accountSelectionService.multiAccountSelection$])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([
          { advStats, allSales, vendorSales, accountMarketplaces },
          { previousAdvStats, previousAllSales, previousVendorSales },
          multiAccountSelection,
        ]) => {
          const accountMarketplacesMap = new Map<string, (AccountMarketplace & { organizationName: string })[]>();
          for (const accountMarketplace of accountMarketplaces) {
            const key = this.getKeyFromAdStat(accountMarketplace);
            if (!accountMarketplacesMap.has(key)) {
              accountMarketplacesMap.set(key, []);
            }
            if (
              multiAccountSelection.some(
                ({ accountGroup, marketplaces }) =>
                  accountGroup.id == accountMarketplace.accountGroupId &&
                  marketplaces.includes(accountMarketplace.marketplace),
              )
            ) {
              accountMarketplacesMap.get(key)!.push(accountMarketplace);
            }
          }

          this.totalData = {};
          this.previousTotalData = {};
          const chartData: AdStatsEx[] = [];
          const previousChartData: AdStatsEx[] = [];

          for (const data of [advStats, vendorSales, allSales].flat()) {
            const accountMarketplaces = accountMarketplacesMap.get(this.getKeyFromAdStat(data));
            if (accountMarketplaces && accountMarketplaces.length > 0) {
              // duplicate data for each account groups
              for (const am of accountMarketplaces) {
                chartData.push(data);
                mergeSeveralDates(this.totalData, data);
              }
            }
          }
          // previous data
          if (this.previousDateRange) {
            const dateInterval = Utils.getDateIntervalInDays([this.previousDateRange[0], this.minDate]);
            for (const data of [previousAdvStats, previousVendorSales, previousAllSales].flat()) {
              const accountMarketplaces = accountMarketplacesMap.get(this.getKeyFromAdStat(data));
              if (accountMarketplaces && accountMarketplaces.length > 0) {
                // duplicate data for each account groups
                for (const am of accountMarketplaces) {
                  previousChartData.push(data);
                  mergeSeveralDates(this.previousTotalData, data);
                }
              }
            }
          }
          this.globalChartData$.next({ data: chartData, previousData: previousChartData });
        },
      );
    this.gridData$ = combineLatest([allData$, this.accountSelectionService.multiAccountSelection$]).pipe(
      map(([{ advStats, allSales, vendorSales, accountMarketplaces }, multiAccountSelection]) => {
        const gridData = new Map<string, SuperBoardData[]>();

        for (const accountMarketplace of accountMarketplaces) {
          if (
            !multiAccountSelection.some(
              ({ accountGroup, marketplaces }) =>
                accountGroup.id == accountMarketplace.accountGroupId &&
                marketplaces.includes(accountMarketplace.marketplace),
            )
          ) {
            continue;
          }
          const key = this.getKeyFromAdStat(accountMarketplace);
          if (!gridData.has(key)) {
            gridData.set(key, []);
          }
          gridData.get(key)!.push({
            rowId: key + "_" + accountMarketplace.resourceOrganizationId!,
            organizationId: accountMarketplace.resourceOrganizationId!,
            organizationName: accountMarketplace.organizationName,
            accountName: accountMarketplace.accountName,
            accountId: accountMarketplace.accountId,
            marketplace: accountMarketplace.marketplace,
            accountGroupName: accountMarketplace.accountGroupName,
            accountState: accountMarketplace.state,
            accountType: accountMarketplace.accountType,
            vendorManufacturingAccess: accountMarketplace.vendorManufacturingAccess,
            dailyStats: [],
          });
        }
        for (const data of [advStats, allSales, vendorSales].flat()) {
          const key = this.getKeyFromAdStat(data);
          const stats = gridData.get(key);

          if (!stats || stats.length == 0) continue;
          for (const s of stats) {
            mergeSeveralDates(s, data);
            s.dailyStats.push(data);
          }
        }
        return gridData;
      }),
      shareReplay(1),
    );
    allPreviousData$
      .pipe(untilDestroyed(this))
      .subscribe(({ previousAdvStats, previousAllSales, previousVendorSales }) => {
        const previousDataByAccount = new Map<
          string,
          { totalPreviousData: AdStatsEx; previousDailyStats: AdStatsEx[] }
        >();

        this.mergeStats(previousAdvStats, previousDataByAccount);
        this.mergeStats(previousAllSales, previousDataByAccount);
        this.mergeStats(previousVendorSales, previousDataByAccount);

        this.previousDataByAccount$.next(previousDataByAccount);
        this.agGrid?.api.redrawRows();
      });

    this.gridData$.pipe(untilDestroyed(this)).subscribe((gridData) => {
      this.agGrid?.api.setGridOption("rowData", Array.from(gridData.values()).flat());
      this.agGrid?.api.redrawRows();
      this.agGrid?.api.hideOverlay();
    });

    combineLatest([this.globalChartData$, this.selectedMetrics$, this.dateAggregation$])
      .pipe(untilDestroyed(this))
      .subscribe(([{ data, previousData }, metrics, dateAggregation]) => {
        this.globalDataset.buildDataSet(
          data,
          metrics,
          dateAggregation,
          {
            minDate: this.minDate,
            maxDate: this.maxDate,
          },
          previousData.length > 0 ? { data: previousData, period: this.previousDateRange ?? [] } : undefined,
          undefined,
        );
      });
  }

  private mergeStats(
    previousAdvStats: AdStatsEx[],
    previousDataByAccount: Map<
      string,
      {
        totalPreviousData: AdStatsEx;
        previousDailyStats: AdStatsEx[];
      }
    >,
  ) {
    for (const data of previousAdvStats) {
      const key = this.getKeyFromAdStat(data);
      if (!previousDataByAccount.has(key)) {
        previousDataByAccount.set(key, { totalPreviousData: {}, previousDailyStats: [] });
      }

      const stats = previousDataByAccount.get(key)!;
      stats.previousDailyStats.push(data);
      mergeSeveralDates(stats.totalPreviousData, data);
    }
  }

  private getKeyFromAdStat(data: AdStatsEx) {
    return data.accountId + "_" + data.marketplace;
  }

  private getKeyFromIRowNode(row: IRowNode<AdStatsEx | AccountMarketplace>) {
    return this.getKeyFromAdStat(row.data!);
  }

  private getPreviousNodeData(node: IRowNode): AdStatsEx | undefined {
    if (this.previousDataByAccount$.value.size === 0) {
      return undefined;
    }
    const rowsToProcess: IRowNode[] = getFilteredLeafNodes(node);
    const previousData: AdStatsEx = {};
    rowsToProcess.forEach((n: IRowNode) => {
      const previousNodeData = this.previousDataByAccount$.value.get(this.getKeyFromIRowNode(n));
      if (previousNodeData) mergeSeveralDates(previousData, previousNodeData.totalPreviousData);
    });
    return previousData;
  }

  displayRowChart(row: IRowNode<SuperBoardData>) {
    const rowsToProcess: IRowNode[] = getFilteredLeafNodes(row, this.agGrid?.api);
    const chartData$: Observable<ChartData<AdStatsEx>> = combineLatest([
      this.gridData$,
      this.previousDataByAccount$,
    ]).pipe(
      map(([gridData, previousDataByAccount]) => {
        const rowsData = rowsToProcess.map(
          (row) =>
            gridData.get(this.getKeyFromIRowNode(row))!.find((r) => r.organizationId == row.data.organizationId)!,
        );
        const totalData: AdStatsEx = rowsData.reduce((prev, curr) => mergeSeveralDates(prev, curr), {});
        const data = rowsData.flatMap((row) => row.dailyStats);
        const previousData = this.previousDateRange
          ? rowsData.flatMap((row) => previousDataByAccount.get(this.getKeyFromAdStat(row))?.previousDailyStats ?? [])
          : undefined;
        const totalPreviousData: AdStatsEx | undefined = this.previousDateRange
          ? rowsData.reduce((prev, curr) => {
              const previousData = previousDataByAccount.get(this.getKeyFromAdStat(curr));
              if (previousData) {
                mergeSeveralDates(prev, previousData.totalPreviousData);
              }
              return prev;
            }, {})
          : undefined;
        return {
          totalData,
          data,
          previousData,
          totalPreviousData,
        };
      }),
    );
    const title = row.isRowPinned()
      ? "Total"
      : row.data
        ? `${row.data.accountName} (${row.data.marketplace})`
        : row.key;
    let withEventAnnotations = false;
    let annotations$: Observable<DataSetEventAnnotation[]> | undefined = undefined;
    if (rowsToProcess.length === 1) {
      withEventAnnotations = true;
      annotations$ = this.activityService.getStrategiesActivityEventAnnotation(
        rowsToProcess[0].data.accountId,
        rowsToProcess[0].data.marketplace,
      );
    }
    const modalOption: ModalOptions<ChartModalInputData<AdStatsEx>> = {
      modalTitle: title ?? undefined,
      adaptiveWidth: true,
      data: {
        locale: this.locale,
        currency: this.currency as Currency,
        dataSetConfiguration: {
          aggregationFunction: [AggregationFunction.mergeSeveralDates],
          metricsOnSameScale: this.M19_SAME_SCALE_METRICS,
        },
        annotations$,
        chartData$,
        selectedMetrics: this.selectedMetrics$.getValue(),
        metrics: this.METRICS,
        displayManagedUnmanagedMetricsToggle: true,
        displayManagedUnmanagedMetrics: this.displayMode$.getValue() === this.managedUnmanaged,
        localStorageKey: this.localStorageKey,
      },
    };
    this.modalService.openModal<ChartModalInputData<AdStatsEx>, void>(ChartModalComponent, modalOption);
    this.agGrid.api.hideOverlay();
  }

  selectMetrics(metrics: Metric<AdStatsEx>[]) {
    this.selectedMetrics$.next(metrics);
    this.toggleManagedTotalDisplay(this.displayMode$.getValue());
  }

  restoreDefaultColumns() {
    this.agGrid.api?.resetColumnState();
    this.agGrid.api?.autoSizeAllColumns();
  }

  private exportGlobalDataCsv() {
    const fileName = getCsvFileName("global_stats_", "", "", [this.minDate, this.maxDate]);
    const columnKeys: string[] = this.agGrid?.api
      .getAllDisplayedColumns()
      .map((c) => c.getColId())
      .filter((c) => !c.startsWith(ACTIONS_COL_ID))
      .concat(["currency"]);

    exportGridCsv(this.agGrid?.api, {
      fileName,
      columnKeys,
      skipColumnGroupHeaders: true,
    });
  }

  private exportDailyDataCsv() {
    const metrics = this.agGrid.api
      ?.getAllDisplayedColumns()
      .map((c) => MetricRegistry.get(c.getColId()))
      .filter((c) => !!c) as Metric<AdStatsEx>[];
    const data: SuperBoardData[] = [];
    this.agGrid.api?.forEachLeafNode((node: IRowNode<SuperBoardData>) => {
      if (!node.data) {
        return;
      }
      const dataByDate = new Map<string, AdStatsEx>();
      for (const dailyStat of node.data.dailyStats) {
        if (!dataByDate.has(dailyStat.date!)) {
          dataByDate.set(dailyStat.date!, Object.assign({}, dailyStat));
        } else {
          addAdStats(dataByDate.get(dailyStat.date!)!, dailyStat);
        }
      }
      for (const dailyStat of dataByDate.values()) {
        data.push({
          organizationName: node.data.organizationName,
          accountGroupName: node.data.accountGroupName,
          accountName: node.data.accountName,
          marketplace: node.data.marketplace,
          accountState: node.data.accountState,
          accountType: node.data.accountType,
          ...dailyStat,
        } as SuperBoardData);
      }
    });

    this.csvExportService.exportCsv({ prefix: "daily_stats", dateRange: [this.minDate, this.maxDate] }, data, [
      simpleField("organizationName", "Organization"),
      simpleField("accountGroupName", "AccountGroup"),
      simpleField("accountName", "Account"),
      simpleField("marketplace", "Marketplace"),
      fieldExtractor("state", (a) =>
        a.accountState == AccountState.BIDDER_ON
          ? "Ads Automation"
          : a.accountState === AccountState.DOWNLOADER_ON
            ? "Pull Stats"
            : "-",
      ),
      simpleField("accountType", "Type"),
      simpleField("date", "Date"),
      ...metrics.map((m) => metricField(m)),
      simpleField("currency", "Currency"),
    ]);
  }

  selectAggregation(aggregation: DateAggregation) {
    this.dateAggregation$.next(aggregation);
  }

  toggleGlobalChartDisplay(isGlobalChartHidden: boolean) {
    this.isGlobalChartHidden = isGlobalChartHidden;
    this.userSelectionService.setUserChartDisplayedPreference(this.localStorageKey, !this.isGlobalChartHidden);
  }

  toggleManagedTotalDisplay(displayMode: string) {
    this.displayMode$.next(displayMode);
    if (displayMode === this.total) {
      const newSelected: Set<Metric<AdStatsEx>> = new Set();
      this.agGrid?.api?.setGridOption("columnDefs", this.columnDefsWithMetrics);
      for (const m of this.selectedMetrics$.getValue()) {
        if (M19_METRICS.map((metric) => metric.op).includes(m) || M19_METRICS.map((metric) => metric.un).includes(m)) {
          newSelected.add(M19_METRICS.find((metric) => metric.op === m || metric.un === m)!.key);
        } else {
          newSelected.add(m);
        }
      }
      this.selectedMetrics$.next([...newSelected]);
    } else {
      const newSelected: Metric<AdStatsEx>[] = [];
      this.agGrid?.api?.setGridOption("columnDefs", this.columnDefsWithDetails);

      for (const m of this.selectedMetrics$.getValue()) {
        if (M19_METRICS.map((metric) => metric.key).includes(m)) {
          newSelected.push(M19_METRICS.find((metric) => metric.key === m)!.op);
          newSelected.push(M19_METRICS.find((metric) => metric.key === m)!.un);
        } else {
          newSelected.push(m);
        }
      }
      this.selectedMetrics$.next(newSelected);
    }
  }

  selectDisplayMode(displayMode: string) {
    this.displayMode$.next(displayMode);
    this.toggleManagedTotalDisplay(displayMode);
  }
}
