import { ColDef, GridApi, GridOptions, GridReadyEvent } from "@ag-grid-community/core";
import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { AccountMarketplace, Marketplace } from "@front/m19-api-client";
import { ProductGroupEx } from "@front/m19-models";
import { AccountSelectionService, ProductGroupService } from "@front/m19-services";
import { IButtonComponent } from "@front/m19-ui";
import { TranslocoService } from "@jsverse/transloco";
import {
  ActionButton,
  ActionButtonsComponent,
} from "@m19-board/insights/advertising-stats/action-buttons/action-buttons.component";
import { InputModalComponent } from "@m19-board/shared/input-modal/input-modal.component";
import { ICON_ADD, ICON_EDIT_O, ICON_IMPORT, ICON_TRASH_O } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { forkJoin, switchMap } from "rxjs";
import {
  BulkImportService,
  ProductGroupBulkOperations,
  ProductGroupUploadResult,
} from "../strategy-bulk-upload-modal/bulk-import.service";
import { ProductGroupBulkUploadModalComponent } from "./product-group-bulk/product-group-bulk-upload-modal.component";
import { ProductGroupBulkUploadReportModalComponent } from "./product-group-bulk/product-group-bulk-upload-report-modal.component";
import { ProductGroupBulkUploadResultModalComponent } from "./product-group-bulk/product-group-bulk-upload-result-modal.component";
/* Modal component to create a new Product Group*/
@Component({
  selector: "app-modal-content",
  templateUrl: "./product-group-creation.component.html",
  styleUrls: ["./product-group.component.scss"],
})
export class ModalCreateProductGroupComponent {
  pgName?: string;
  accountId?: string;
  marketplace?: Marketplace;

  constructor(
    public productGroupService: ProductGroupService,
    public bsModalRef: BsModalRef,
    private toastrService: ToastrService,
    private router: Router,
    private translocoService: TranslocoService,
  ) {}

  private gotoProductGroupPage(productGroup: ProductGroupEx, marketplace: Marketplace) {
    this.router.navigate([`/product-center/product-group/${productGroup.productGroupId}`], {
      queryParams: { marketplace: marketplace },
    });
  }

  create() {
    this.productGroupService.createProductGroup(this.accountId!, this.marketplace!, this.pgName!).subscribe({
      next: (productGroup) => {
        this.gotoProductGroupPage(productGroup, this.marketplace!);
        this.toastrService.success(this.translocoService.translate("product-group.product_group_created"));
        this.bsModalRef.hide();
      },
      error: (error: string) => {
        this.toastrService.error(error, this.translocoService.translate("product-group.product_group_creation_error"));
        this.bsModalRef.hide();
      },
    });
  }
}

/* Modal component to delete a Product Group*/
@Component({
  selector: "app-modal-content",
  templateUrl: "./product-group-deletion.component.html",
  styleUrls: ["./product-group.component.scss"],
})
export class ModalDeleteProductGroupComponent {
  productGroups!: ProductGroupEx[];
  accountMarketplace!: AccountMarketplace;

  constructor(
    public productGroupService: ProductGroupService,
    public bsModalRef: BsModalRef,
    public toastrService: ToastrService,
    private translocoService: TranslocoService,
  ) {}

  delete() {
    forkJoin(
      this.productGroups.map((pg) =>
        this.productGroupService.deleteProductGroup(
          this.accountMarketplace!.accountId,
          this.accountMarketplace!.marketplace,
          pg.productGroupId!,
        ),
      ),
    ).subscribe({
      next: () => {
        this.toastrService.success(this.translocoService.translate("product-group.product_group_deleted"));
        this.bsModalRef.hide();
      },
      error: (error: string) => {
        this.toastrService.error(error, this.translocoService.translate("product-group.product_group_deletion_error"));
      },
    });
  }
}

//Main component
@UntilDestroy()
@Component({
  selector: "app-product-group",
  templateUrl: "./product-group.component.html",
  styleUrls: ["./product-group.component.scss"],
})
export class ProductGroupComponent implements OnInit {
  accountMarketplace?: AccountMarketplace;
  productGroups: ProductGroupEx[] | undefined = undefined;
  isReadOnly = false;

  readonly ICON_IMPORT = ICON_IMPORT;
  readonly ICON_ADD = ICON_ADD;
  readonly ICON_EDIT_O = ICON_EDIT_O;
  readonly ICON_TRASH_O = ICON_TRASH_O;

  colDef: ColDef[] = [
    {
      field: "productGroupName",
      flex: 6,
      headerValueGetter: (params) => {
        return this.translocoService.translate("product-group-page.product_group_name");
      },
      cellRendererSelector: (params: any) => {
        return {
          component: IButtonComponent,
          params: {
            label: params.value,
            variant: "ghost",
            color: "gray",
            trailing: true,
            tooltipValue: this.translocoService.translate("product-group.edit_product_group_name"),
            icon: ICON_EDIT_O,
            disbaled: this.isReadOnly,
            iconOnHover: true,
            clickAction: () => {
              this.editProductGroupName(params.data);
            },
          },
        };
      },
      checkboxSelection: true,
      headerCheckboxSelection: true,
      resizable: false,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sort: "asc",
    },
    {
      field: "asins",
      flex: 3,
      headerValueGetter: (params) => {
        return this.translocoService.translate("common.products");
      },
      valueFormatter: (params: any) => {
        return params.data.asins.length;
      },
      resizable: false,
    },
    {
      flex: 0.55,
      cellRendererSelector: (params: any) => {
        const productGroup = params.data;
        const actionButtons: ActionButton[] = [];
        actionButtons.push({
          title: "Edit",
          icon: ICON_EDIT_O,
          tooltip: this.translocoService.translate("product-group.edit_product_group", [productGroup.productGroupName]),
          onClick: () => {
            this.openProductGroupPage(productGroup);
          },
          disabled: this.isReadOnly,
        });
        actionButtons.push({
          title: "Remove",
          icon: ICON_TRASH_O,
          tooltip: this.translocoService.translate("product-group.delete_product_group", [
            productGroup.productGroupName,
          ]),
          onClick: () => {
            this.deleteProductGroups([productGroup]);
          },
          disabled: this.isReadOnly,
        });
        return {
          component: ActionButtonsComponent,
          params: {
            actionButtons,
          },
        };
      },
      resizable: false,
    },
  ];

  gridOptions: GridOptions = {
    pagination: true,
    paginationPageSize: 20,
    sideBar: false,
    rowSelection: "multiple",
    suppressRowClickSelection: true,
    onSelectionChanged: (event: any) => {
      this.selectedRows = event.api.getSelectedRows();
    },
  };

  gridApi?: GridApi;
  selectedRows: ProductGroupEx[] = [];

  constructor(
    private accountSelectionService: AccountSelectionService,
    private productGroupService: ProductGroupService,
    private modalService: BsModalService,
    private bulkImportService: BulkImportService,
    private toastrService: ToastrService,
    private router: Router,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.accountMarketplace = am;
    });
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am: AccountMarketplace) => this.productGroupService.getProductGroups(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((pg) => {
        this.productGroups = pg;
        this.gridApi?.updateGridOptions({ rowData: pg });
      });
    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
  }

  createProductGroup(accountId: string, marketplace: Marketplace): void {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: accountId,
        marketplace: marketplace,
      },
      class: "modal-primary",
    };
    this.modalService.show(ModalCreateProductGroupComponent, modalOptions);
  }

  deleteProductGroups(productGroups: ProductGroupEx[]) {
    const modalOptions: ModalOptions = {
      initialState: { productGroups: productGroups, accountMarketplace: this.accountMarketplace },
      class: "modal-danger",
    };
    this.modalService.show(ModalDeleteProductGroupComponent, modalOptions);
  }

  openProductGroupPage(productGroup: ProductGroupEx) {
    this.router.navigate([`/product-center/product-group/${productGroup.productGroupId}`], {
      queryParamsHandling: "merge",
    });
  }

  uploadProductGroups(bulkData = "") {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.accountMarketplace!.accountId,
        marketplace: this.accountMarketplace!.marketplace,
        productGroups: this.productGroups,
        bulkData,
      },
      class: "modal-xl",
    };
    const modalRef = this.modalService.show(ProductGroupBulkUploadModalComponent, modalOptions);

    const subscription = modalRef.content?.productGroupBulkOperations.subscribe((result) => {
      this.displayBulkOperations(result);
      subscription?.unsubscribe();
    });
  }

  editProductGroupName(productGroup: ProductGroupEx) {
    const productGroupNameInput = new FormControl<string>(productGroup.productGroupName);
    const ref = this.modalService.show(InputModalComponent, {
      initialState: {
        title: this.translocoService.translate("product-group.change_product_group_name"),
        inputControl: productGroupNameInput,
        maxLength: 80,
      },
      class: "modal-primary",
    });
    ref.content?.emitUpdate
      .pipe(
        switchMap(() =>
          this.productGroupService.updateProductGroupName(
            this.accountMarketplace!.accountId,
            this.accountMarketplace!.marketplace,
            productGroup.productGroupId,
            productGroupNameInput.value!,
          ),
        ),
      )
      .subscribe({
        next: () => {
          this.toastrService.success(this.translocoService.translate("product-group.product_group_name_updated"));
        },
        error: (error: string) => {
          this.toastrService.error(
            error,
            this.translocoService.translate("product-group.product_group_name_update_error"),
          );
        },
      });
  }

  private displayBulkOperations(bulkOperations: ProductGroupBulkOperations) {
    const modalOptions: ModalOptions = {
      initialState: {
        productGroupBulkOperations: bulkOperations,
        accountId: this.accountMarketplace!.accountId,
        marketplace: this.accountMarketplace!.marketplace,
      },
      class: "modal-xxl",
    };
    const modalRef = this.modalService.show(ProductGroupBulkUploadReportModalComponent, modalOptions);
    const subscriptions = modalRef.content?.bulkOperationResult.subscribe((result) => {
      this.displayUploadResult(result);
      subscriptions?.unsubscribe();
    });
    subscriptions?.add(
      modalRef.content?.uploadCancelled.subscribe(() => {
        this.uploadProductGroups(bulkOperations.bulkData);
        subscriptions?.unsubscribe();
      }),
    );
  }

  private displayUploadResult(uploadResult: ProductGroupUploadResult) {
    const modalOptions: ModalOptions = {
      initialState: {
        uploadResult: uploadResult,
      },
      class: "modal-primary",
    };
    this.modalService.show(ProductGroupBulkUploadResultModalComponent, modalOptions);
  }

  exportProductGroups() {
    this.bulkImportService.exportProductGroupCsv(this.accountMarketplace!, this.productGroups!);
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }
}
