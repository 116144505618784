import { DspApi } from '@front/m19-api-client';
import { Injectable } from '@angular/core';
import { catchAjaxError } from '@front/m19-utils';

@Injectable({
  providedIn: 'root',
})
export class DspService {
  constructor(private dspApi: DspApi) {}

  public getDspCampaigns(dspAdvertiserId: string) {
    return this.dspApi
      .listDspCampaigns({
        dspAdvertiserId,
      })
      .pipe(catchAjaxError());
  }

  public getDspCreatives(dspAdvertiserId: string) {
    return this.dspApi
      .listDspCreatives({
        dspAdvertiserId,
      })
      .pipe(catchAjaxError());
  }

  public getDspAdgroups(dspAdvertiserId: string) {
    return this.dspApi
      .listDspAdgroups({
        dspAdvertiserId,
      })
      .pipe(catchAjaxError());
  }
}
