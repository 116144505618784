import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faClock,
  faImage,
  faInfoCircle,
  faPause,
  faPauseCircle,
  faPlay,
  faPlayCircle,
  faPlusCircle,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import {
  AccountMarketplace,
  AlgoMode,
  BrandAsset,
  CampaignType,
  Currency,
  Marketplace,
  SbCreative,
  SbCreativeType,
  StrategyStateEnum,
  StrategyTactic,
  TacticType,
} from "@front/m19-api-client";
import { AccountSelectionService, AuthService, ConfigService, StrategyService } from "@front/m19-services";

import {
  Marketplaces,
  ProductGroupEx,
  SegmentEx,
  StrategyEx,
  StrategyGroupEx,
  SupportedAudienceMatchType,
} from "@front/m19-models";
import { Utils } from "@front/m19-utils";
import { ConfirmPopupComponent } from "@m19-board/shared/confirm-popup/confirm-popup.component";
import { ICON_BOOST, ICON_PAUSE, ICON_PLAY, ICON_TRASH_O } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Constant } from "libs/m19-services/src/lib/m19-services/constant";
import { SbStrategiesService } from "libs/m19-services/src/lib/m19-services/sb-strategies.service";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { combineLatest, map, Observable, Subject, switchMap, take } from "rxjs";
import { FblModeSize } from "../fbl-mode/fbl-mode.component";
import { StrategyLimitationModalComponent } from "../strategy-limitation-modal/strategy-limitation-modal.component";
import { StrategyStatusFilter } from "../strategy-list/strategy-list.component";
import { SegmentService } from "libs/m19-services/src/lib/m19-services/segmentService";
import { TranslocoService } from "@jsverse/transloco";

// TODO(david) managed brandVideo
// IMO, we could simplify it
interface StrategyLine extends StrategyEx {
  creativesProduct?: SbCreative[];
  creativesVideo?: SbCreative[];
  brandAssetUrl?: string;
}

@UntilDestroy()
@Component({
  selector: "app-strategy-table",
  templateUrl: "./strategy-table.component.html",
  styleUrls: ["./strategy-table.component.scss"],
})
export class StrategyTableComponent implements OnInit {
  readonly faPlayCircle = faPlayCircle;
  readonly faPauseCircle = faPauseCircle;
  readonly faClock = faClock;
  readonly faPause = faPause;
  readonly faPlay = faPlay;
  readonly faTrash = faTrashAlt;
  readonly faPlus = faPlusCircle;
  readonly faImage = faImage;
  readonly faVideo = faVideo;
  readonly faInfo = faInfoCircle;

  readonly ICON_TRASH_O = ICON_TRASH_O;
  readonly ICON_PLAY = ICON_PLAY;
  readonly ICON_PAUSE = ICON_PAUSE;
  readonly ICON_BOOST = ICON_BOOST;

  public AlgoModes = AlgoMode;
  public CampaignType = CampaignType;
  readonly MaxAsins = ProductGroupEx.MaxProductGroupItems;
  readonly supportedAudienceMatchType = SupportedAudienceMatchType;

  @ViewChild(MatSort) set sort(matSort: MatSort) {
    if (this.dataSource) this.dataSource.sort = matSort;
  }

  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    if (this.dataSource) this.dataSource.paginator = value;
  }

  readonly defaultColumns = {
    [CampaignType.SP]: [
      "strategyStatus",
      "strategyName",
      "algoMode",
      "warnings",
      "target",
      "nbAsins",
      "tactics",
      "buttons",
    ],
    [CampaignType.SB]: [
      "strategyStatus",
      "strategyName",
      "algoMode",
      "warnings",
      "target",
      "nbAsins",
      "adLines",
      "buttons",
    ],
    [CampaignType.SD]: [
      "strategyStatus",
      "strategyName",
      "algoMode",
      "warnings",
      "target",
      "nbAsins",
      "sdTargetings",
      "buttons",
    ],
  };

  displayedColumns: string[];
  dataSource = new MatTableDataSource<StrategyLine | StrategyGroupEx>();
  currency: Currency;
  locale: string;
  private strategies$ = new Subject<StrategyLine[]>();
  private sbStrategies$ = new Subject<StrategyEx[]>();
  private strategyGroups$ = new Subject<StrategyGroupEx[]>();

  @Input() set strategies(strats: StrategyLine[]) {
    if (this._campaignType === CampaignType.SB) {
      // specific code for SB
      this.sbStrategies$.next(strats);
    } else {
      this.strategies$.next(strats);
    }
  }

  @Input() set strategyGroups(strategyGroups: StrategyGroupEx[]) {
    this.strategyGroups$.next(strategyGroups);
  }

  _stringFilter = "";

  @Input() set filter(filter: string) {
    this._stringFilter = filter;
    this.dataSource.filter = this._strategyFilter + ":" + filter;
  }

  _strategyFilter = StrategyStatusFilter.ALL;
  @Input() set strategyFilter(filter: StrategyStatusFilter) {
    this._strategyFilter = filter;
    this.dataSource.filter = this._strategyFilter + ":" + this._stringFilter;
  }

  @Input() asinsByStrategy: Map<number, string[]> = new Map();
  _campaignType: CampaignType;
  @Input() set campaignType(campaignType: CampaignType) {
    this._campaignType = campaignType;
    switch (campaignType) {
      case CampaignType.SP:
        this.strategyPage = "sponsored-product";
        break;

      case CampaignType.SD:
      case CampaignType.SDR:
        this.strategyPage = "sponsored-display";
        break;

      case CampaignType.SB:
        this.strategyPage = "sponsored-brands";
        break;
    }
  }

  strategyPage = "";
  strategyPagePrefix = "/strategies/";
  strategyGroupPagePrefix = "/strategies/strategy-group/sponsored-product/";
  isReadOnly = false;

  @Input() set marketplace(marketplace: Marketplace) {
    this.currency = marketplace ? Marketplaces[marketplace].currency : Currency.USD;
  }

  @Output()
  strategyCreationClick = new EventEmitter<void>();

  nbAsinsByStrategy: Map<number, number> = new Map();
  promoBoostActivated$: Observable<boolean>;
  organizationId: number;
  segmentIndex: Map<number, SegmentEx> = new Map();

  constructor(
    private sbStrategyService: SbStrategiesService,
    private configurationService: ConfigService,
    private modalService: BsModalService,
    private router: Router,
    private accountSelectionService: AccountSelectionService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private segmentService: SegmentService,
    private strategyService: StrategyService,
    private translocoService: TranslocoService,
  ) {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((am: AccountMarketplace) => {
        this.organizationId = am.resourceOrganizationId;
      });
    this.promoBoostActivated$ = this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(
      map((am: AccountMarketplace) => {
        if (!am.promoStartDate && !am.promoEndDate) {
          return false;
        }
        const today = Utils.getNow(am.marketplace).startOf("day");
        const promoStart = Utils.toMoment(am.promoStartDate, am.marketplace);
        const promoEnd = Utils.toMoment(am.promoEndDate, am.marketplace);
        return today.isSameOrAfter(promoStart) && today.isSameOrBefore(promoEnd);
      }),
    );
    combineLatest([this.strategies$, this.strategyGroups$])
      .pipe(untilDestroyed(this))
      .subscribe(([strategies, strategyGroups]) => {
        this.dataSource.data = [...(strategyGroups ?? []), ...(strategies ?? [])].sort((a, b) => {
          const aState = isStrategy(a)
            ? a.state
            : this.hasLiveStrategies(a as StrategyGroupEx)
              ? StrategyStateEnum.ENABLED
              : StrategyStateEnum.PAUSED;
          const bState = isStrategy(b)
            ? b.state
            : this.hasLiveStrategies(b as StrategyGroupEx)
              ? StrategyStateEnum.ENABLED
              : StrategyStateEnum.PAUSED;
          return aState.localeCompare(bState);
        });
      });
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.locale = user.locale;
      if (user?.uiVersion > 0) {
        this.strategyPagePrefix = "/advertising/";
        this.strategyGroupPagePrefix = "/advertising/sponsored-product/strategy-group/";
      }
    });
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) => this.segmentService.getSegments(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((segments) => {
        this.segmentIndex = segments;
      });
  }

  ngOnInit(): void {
    if (this._campaignType === CampaignType.SB) {
      combineLatest([
        this.sbStrategies$,
        this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(
          switchMap((am) =>
            combineLatest([
              this.sbStrategyService.getSbCreativesPerStrategy(am.accountId, am.marketplace),
              this.sbStrategyService.getSbCreativeBrandAssets(am.accountId, am.marketplace),
            ]),
          ),
        ),
      ])
        .pipe(untilDestroyed(this))
        .subscribe(([strats, [sbCreatives, brandAssets]]) => {
          for (const strat of strats) {
            const creatives = sbCreatives.get(strat.strategyId) ?? [];
            (strat as StrategyLine).creativesVideo = creatives.filter((x) => x.creativeType == SbCreativeType.video);
            (strat as StrategyLine).creativesProduct = creatives.filter(
              (x) => x.creativeType == SbCreativeType.productCollection,
            );
            if ((strat as StrategyLine).creativesProduct!.length > 0) {
              const brandAsset = brandAssets.get((strat as StrategyLine).creativesProduct![0].creativeId);
              (strat as StrategyLine).brandAssetUrl = brandAsset?.logoAsset?.url ?? brandAsset?.videoAsset?.url;
            }
          }
          this.strategies$.next(strats);
        });
    }
    this.displayedColumns = this.defaultColumns[this._campaignType];
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (row, property) => {
      if (property === "strategyName") return isStrategy(row) ? row.getName() : row.strategyGroupName;
      return "";
    };
    this.dataSource.filter = "";
    this.dataSource.filterPredicate = (row: StrategyEx | StrategyGroupEx, filter: string): boolean => {
      if (isStrategy(row)) {
        if (
          (this._strategyFilter === StrategyStatusFilter.ON && row.state === StrategyStateEnum.PAUSED) ||
          (this._strategyFilter === StrategyStatusFilter.OFF && row.state === StrategyStateEnum.ENABLED)
        ) {
          return false;
        }
        if (this._stringFilter) {
          const rexp = new RegExp(this._stringFilter, "i");
          if (row.getName().search(rexp) != -1) return true;
          else return (this.asinsByStrategy.get(row.strategyId) ?? []).some((x) => x.search(rexp) != -1);
        }
        return true;
      } else {
        const strategyGroup = row as StrategyGroupEx;
        if (
          (this._strategyFilter === StrategyStatusFilter.ON && !this.hasLiveStrategies(strategyGroup)) ||
          (this._strategyFilter === StrategyStatusFilter.OFF && this.hasLiveStrategies(strategyGroup))
        ) {
          return false;
        }
        if (this._stringFilter) {
          const rexp = new RegExp(this._stringFilter, "i");
          if ((row as StrategyGroupEx).strategyGroupName.search(rexp) != -1) return true;
          else return (row as StrategyGroupEx).asins.some((x) => x.search(rexp) != -1);
        }
        return true;
      }
    };
    this.dataSource.paginator = this.paginator;

    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
  }

  hasLiveStrategies(strategyGroup: StrategyGroupEx): boolean {
    return strategyGroup.strategies.some((s) => s.state === StrategyStateEnum.ENABLED);
  }

  isDayPartingEnabled(strategy: StrategyEx): boolean {
    return strategy.daypartingPauseHour != null && strategy.daypartingReactivationHour != null;
  }

  getDayPartingToolTip(strategy: StrategyEx): string {
    return (
      "Strategy paused from " + strategy.daypartingPauseHour + ":00 to " + strategy.daypartingReactivationHour + ":00"
    );
  }

  getStrategyStatusTooltip(strategy: StrategyEx): string {
    const base =
      strategy.state === StrategyStateEnum.ENABLED
        ? this.translocoService.translate("common.active_startegy")
        : this.translocoService.translate("common.paused_strategy");
    return base + (strategy.state === StrategyStateEnum.ENABLED ? ". Click to pause" : ". Click to activate");
  }

  changeStatus(s: StrategyEx | StrategyGroupEx) {
    if (!s["strategyId"] && s["strategies"].length != 1) {
      return;
    }
    const strategy = s["strategyId"] ? (s as StrategyEx) : (s as StrategyGroupEx).strategies[0];
    const newState = strategy.state == StrategyStateEnum.ENABLED ? StrategyStateEnum.PAUSED : StrategyStateEnum.ENABLED;

    if (
      newState == StrategyStateEnum.ENABLED &&
      this.configurationService.isStrategyLimitReached(strategy.campaignType)
    ) {
      if (
        strategy.campaignType == CampaignType.SB &&
        this.configurationService.getNumberOfLiveStrategies(CampaignType.SB) >= Constant.maxSbStrategies
      ) {
        this.toastrService.error(
          `You have reached the maximum number of ${Constant.maxSbStrategies} active Sponsored Brands strategies`,
        );
        return;
      }
      const modalOptions: ModalOptions = {
        initialState: {
          liveStrategyLimit: this.configurationService.getLiveStrategyLimit(),
          campaignType: strategy.campaignType,
        },
        class: "modal-primary",
      };
      this.modalService.show(StrategyLimitationModalComponent, modalOptions);
      return;
    }
    this.strategyService
      .updateStrategyState(strategy.accountId, strategy.marketplace, (strategy as StrategyEx).strategyId, newState)
      .subscribe({
        next: () => {
          this.toastrService.success(`Strategy ${newState === StrategyStateEnum.ENABLED ? "activated" : "paused"}`);
        },
        error: (err) => {
          this.toastrService.error(err, "Strategy state update error");
        },
      });
  }

  deleteStrategy(strategy: StrategyEx) {
    const strategyName = strategy.getName();
    const modalOptions: ModalOptions = {
      initialState: {
        title: "Strategy Deletion",
        message: `Are you sure you want to delete strategy "${strategyName}"?`,
        confirmCta: "Delete",
      },
      class: "modal-danger",
    };
    const modalRef = this.modalService.show(ConfirmPopupComponent, modalOptions);
    modalRef.content.confirm.pipe(switchMap(() => this.configurationService.deleteStrategy(strategy))).subscribe({
      next: () => {
        this.toastrService.success("Strategy " + strategyName + " successfully deleted", "Strategy Deleted");
      },
      error: (err) => {
        this.toastrService.error("Error deleting Strategy " + strategyName + ": " + err, "Strategy Deletion Error");
      },
    });
  }

  deleteStrategyGroup(strategyGroup: StrategyGroupEx) {
    const modalOptions: ModalOptions = {
      initialState: {
        title: "Strategy Group Deletion",
        message: `Are you sure you want to delete strategy group "${strategyGroup.strategyGroupName}"?`,
        confirmCta: "Delete",
      },
      class: "modal-danger",
    };
    const modalRef = this.modalService.show(ConfirmPopupComponent, modalOptions);
    modalRef.content.confirm
      .pipe(switchMap(() => this.configurationService.deleteStrategyGroup(strategyGroup.strategyGroupId).pipe(take(1))))
      .subscribe({
        next: () => {
          this.toastrService.success("Strategy group deleted");
        },
        error: (err) => {
          this.toastrService.error(err, "Strategy group deletion error");
        },
      });
  }

  createStrategy(): void {
    if (this.campaignType === CampaignType.SB) this.router.navigate(["/strategies/create-sponsored-brands"]);
    else this.strategyCreationClick.emit();
  }

  getTactics(strategy: StrategyEx | StrategyGroupEx): StrategyTactic[] {
    return strategy["strategyId"] > 0
      ? (strategy as StrategyEx).tactics
      : ((strategy as StrategyGroupEx).strategies[0].tactics as StrategyTactic[]);
  }

  getSdProductTactics(strategy: StrategyEx): SegmentEx[] {
    return strategy.tactics
      .filter((t) => t.tacticType == TacticType.LEGACY)
      .map((t) => this.segmentIndex.get(t.segmentId));
  }

  protected readonly FblModeSize = FblModeSize;
}

function isStrategy(row: StrategyLine | StrategyGroupEx): row is StrategyLine {
  return (row as StrategyLine).strategyId > 0;
}
