import { Component, inject } from "@angular/core";
import { MatTabsModule } from "@angular/material/tabs";
import { Currency, StrategyType } from "@front/m19-api-client";
import {
  ACOS,
  AD_CONVERSIONS,
  AD_SALES,
  CLICK_THROUGH_RATE,
  CLICKS,
  CONVERSION_RATE,
  COST,
  CPC,
  IMPRESSIONS,
  Metric,
  ROAS,
} from "@front/m19-metrics";
import { AdStatsEx, StrategyEx } from "@front/m19-models";
import {
  AccountSelectionService,
  AuthService,
  convertStrategyConfigToCurrency,
  DataSetEventAnnotation,
  MetricsSelectorLocalStorageKey,
  StatsService,
  UserSelectionService,
} from "@front/m19-services";
import { MODAL_DATA } from "@front/m19-ui";
import { AggregationFunction, convertToCurrency, mergeSeveralDates } from "@front/m19-utils";
import { ActivityService } from "@m19-board/activities/activity.service";
import { DAILY_BUDGET, MIN_DAILY_SPEND, MONTHLY_BUDGET, TARGET_ACOS } from "@m19-board/models/MetricsDef";
import { ChartData, DataSetConfiguration } from "@m19-board/shared/chart-modal/chart-modal.component";
import { ChartRendererComponent } from "@m19-board/shared/chart-modal/chart-renderer.component";
import { TargetingAsinStatsComponent } from "@m19-board/sponsored-product/sp-page/targeting-asin-stats/targeting-asin-stats.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, map, Observable, of, switchMap } from "rxjs";

export type StrategyStatsInputData = {
  strategy: StrategyEx;
  isReadOnly: boolean;
};

@UntilDestroy()
@Component({
  template: `<div class="min-h-[70vh] min-w-[80vw]">
    <mat-tab-group
      animationDuration="0ms"
      [selectedIndex]="selectedTab"
      (selectedIndexChange)="selectedTab = $event; tabChanged = true"
    >
      <mat-tab label="Strategy">
        <chart-renderer
          [locale]="locale"
          [currency]="currency"
          [metrics]="METRIC_COLUMNS"
          [localStorageKey]="localStorageKey"
          [dataSetConfiguration]="dataSetConfiguration"
          [chartData]="chartData$"
          [selectedMetrics]="selectedMetrics"
          [annotations$]="annotations$"
          [strategyTargetHistory]="true"
        ></chart-renderer>
      </mat-tab>
      <mat-tab label="Targeting" class="py-2">
        @if (tabChanged) {
          <app-targeting-asin-stats
            [isReadOnly]="modalData.isReadOnly"
            [strategy]="modalData.strategy"
            [withTOSROSwitch]="withTOSROSwitch"
          ></app-targeting-asin-stats>
        }
      </mat-tab>
    </mat-tab-group>
  </div>`,
  standalone: true,
  imports: [MatTabsModule, ChartRendererComponent, TargetingAsinStatsComponent],
})
export class StrategyStatsComponent {
  readonly modalData = inject<StrategyStatsInputData>(MODAL_DATA);

  readonly METRIC_COLUMNS: Metric<AdStatsEx>[] = [
    AD_SALES,
    AD_CONVERSIONS,
    COST,
    ACOS,
    CLICKS,
    IMPRESSIONS,
    CLICK_THROUGH_RATE,
    CONVERSION_RATE,
    CPC,
    ROAS,
  ];
  readonly localStorageKey = MetricsSelectorLocalStorageKey.strategy;
  withTOSROSwitch = false;
  chartData$!: Observable<ChartData<AdStatsEx>>;
  annotations$!: Observable<DataSetEventAnnotation[]>;
  selectedTab = 0;
  // this boolean is to lazy load the Targeting asin stats component (loaded only once tab is changed)
  tabChanged = false;

  locale!: string;
  currency!: Currency;
  readonly dataSetConfiguration: DataSetConfiguration<AdStatsEx> = {
    aggregationFunction: [AggregationFunction.mergeAdStatsWithTargetHistory],
    metricsOnSameScale: [
      [AD_SALES, COST],
      [ACOS, TARGET_ACOS],
      [COST, MIN_DAILY_SPEND, DAILY_BUDGET, MONTHLY_BUDGET],
    ],
  };
  readonly selectedMetrics = [AD_SALES, COST];

  constructor(
    private authService: AuthService,
    private activityService: ActivityService,
    private userSelectionService: UserSelectionService,
    private accountSelectionService: AccountSelectionService,
    private statsService: StatsService,
  ) {
    this.withTOSROSwitch = this.modalData.strategy.strategyType == StrategyType.PRODUCT;
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.locale = user.locale;
    });
    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((currency) => {
      this.currency = currency;
    });
    const dailyPlacementStats$ = combineLatest([
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      this.userSelectionService.dateRange$,
    ]).pipe(
      switchMap(([am, dr]) =>
        combineLatest([
          this.userSelectionService.selectedCurrency$,
          this.statsService.getDailyPlacementStats(am.accountId, am.marketplace, dr[0], dr[1]),
        ]),
      ),
      map(([currency, data]) => convertToCurrency(data, currency)!),
    );
    const previousDailyPlacementStats$ = combineLatest([
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      this.userSelectionService.periodComparison$,
    ]).pipe(
      switchMap(([am, pc]) => {
        if (!pc?.period) {
          return of([]);
        }
        return combineLatest([
          this.userSelectionService.selectedCurrency$,
          this.statsService.getDailyPlacementStats(am.accountId, am.marketplace, pc.period[0], pc.period[1]),
        ]).pipe(
          map(([currency, data]) => {
            return convertToCurrency(data, currency)!;
          }),
        );
      }),
    );
    this.chartData$ = combineLatest([
      dailyPlacementStats$,
      previousDailyPlacementStats$,
      combineLatest([
        this.accountSelectionService.singleAccountMarketplaceSelection$,
        this.userSelectionService.dateRange$,
      ]).pipe(
        switchMap(([am, dr]) => this.statsService.getStrategyConfigHistory(am.accountId, am.marketplace, dr[0], dr[1])),
        convertStrategyConfigToCurrency(this.userSelectionService.selectedCurrency$),
      ),
    ]).pipe(
      map(([dailyPlacementStats, previousPeriodDailyPlacementStats, targetHistory]) => {
        const strategyTargetHistory = targetHistory.filter((t) => t.strategyId == this.modalData.strategy.strategyId);
        const stats = dailyPlacementStats.filter((d) => d.strategyId == this.modalData.strategy.strategyId);
        const previousStats = previousPeriodDailyPlacementStats.filter(
          (d) => d.strategyId == this.modalData.strategy.strategyId,
        );

        const totalData = stats.reduce((prev, curr) => mergeSeveralDates(prev, curr), {});
        const totalPreviousData = previousStats?.reduce((curr, prev) => mergeSeveralDates(curr, prev), {});
        return {
          data: [...strategyTargetHistory, ...stats],
          previousData: previousStats,
          totalData,
          totalPreviousData,
        };
      }),
    );
    this.annotations$ = this.activityService.getStrategyActivityEventAnnotation(
      this.modalData.strategy.accountId,
      this.modalData.strategy.marketplace,
      this.modalData.strategy,
    );
  }
}
