import { Component, Directive, Input, OnInit } from "@angular/core";
import {
  AccountMarketplace,
  AccountSubType,
  AccountType,
  AlgoMode,
  CampaignType,
  M19Status,
  Marketplace,
  Strategy,
  StrategyStateEnum,
} from "@front/m19-api-client";
import { buildProductEx, NotificationBidderIssueEx, NotificationEx, StrategyEx } from "@front/m19-models";
import { getAmazonProductUrl, OrganizationAccountGroupService, StrategyService } from "@front/m19-services";
import { Utils } from "@front/m19-utils";
import { AccountSettingsService } from "@m19-board/settings/account-settings.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { take } from "rxjs";
import { NotificationCardComponent } from "@m19-board/notifications/notification-card.component";
import { TranslocoDirective } from "@jsverse/transloco";
import { StrategyLinkComponent } from "@m19-board/strategies/strategy-link/strategy-link.component";
import { IButtonComponent } from "@front/m19-ui";
import { MatTooltip } from "@angular/material/tooltip";

type StrategyOverlap = {
  strategies?: number[];
  asins?: string[];
};

type CampaignOverlap = {
  campaigns?: string[];
  asins?: string[];
};

const EmptyStrategy = new StrategyEx({
  accountId: "",
  marketplace: null as unknown as Marketplace,
  state: StrategyStateEnum.ENABLED,
  campaignType: null as unknown as CampaignType,
  algoMode: null as unknown as AlgoMode,
  defaultStrategy: false,
  tactics: [],
  audienceTargetings: [],
});

function getAsinLink(this: NotificationCard, asin: string): string {
  const product = buildProductEx({
    asin: asin,
    marketplace: this.notification.marketplace,
  } as any);

  return getAmazonProductUrl(product);
}

@Directive()
class NotificationCard {
  @Input({ required: true })
  notification!: NotificationEx;

  @Input()
  small = false;
}

@UntilDestroy()
@Component({
  selector: "app-notification-card-invalid-selling-partner",
  templateUrl: "./notification-card-selling-partner.component.html",
  standalone: true,
  imports: [TranslocoDirective, NotificationCardComponent, IButtonComponent, MatTooltip],
})
export class NotificationInvalidSellingPartnerAccessComponent extends NotificationCard implements OnInit {
  account?: AccountMarketplace;

  constructor(
    private accountGroupService: OrganizationAccountGroupService,
    private accountSettingsService: AccountSettingsService,
  ) {
    super();
  }

  logInPlaceForAPIGrantAccess() {
    return this.account?.accountType == AccountType.SELLER ? "Seller Central" : "Vendor Central";
  }

  ngOnInit(): void {
    this.accountGroupService.allOrganizationAccountGroups$.pipe(untilDestroyed(this)).subscribe((organizations) => {
      const accountGroups = organizations?.flatMap((o) => o.accountGroups);
      for (const accountGroup of accountGroups ?? []) {
        const account = accountGroup
          .getAccountMarketplaces()
          .find((account) => account.accountId == this.notification.accountId);
        if (account) {
          this.account = account;
          break;
        }
      }
    });
  }

  requestGrant(): void {
    this.accountSettingsService.grantSellingPartnerAccess(
      this.account!.accountId,
      this.account!.marketplace,
      this.account!.accountType!,
      this.account!.accountSubType == AccountSubType.KDP_AUTHOR,
    );
  }
}

@UntilDestroy()
@Component({
  selector: "app-notification-card-campaign-overlap",
  templateUrl: "./notification-card-campaign-overlap.component.html",
  standalone: true,
  imports: [TranslocoDirective, NotificationCardComponent, StrategyLinkComponent],
})
export class NotificationCardCampaignOverlapComponent extends NotificationCard implements OnInit {
  strategyId = 0;
  overlappingCampaigns: string[] = [];
  asins: string[] = [];

  private json: CampaignOverlap = {};

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.strategyId = this.notification.strategyId;
    if (this.notification && this.notification.message) {
      try {
        this.json = JSON.parse(this.notification.message);
      } catch {
        this.json = {};
      }
      this.asins = this.json.asins ?? [];
      this.overlappingCampaigns = this.json.campaigns ?? [];
    }
  }

  getAsinLink = getAsinLink;
}

@UntilDestroy()
@Component({
  selector: "app-notification-card-strategy-overlap",
  templateUrl: "./notification-card-strategy-overlap.component.html",
  standalone: true,
  imports: [TranslocoDirective, NotificationCardComponent, StrategyLinkComponent],
})
export class NotificationCardStrategyOverlapComponent extends NotificationCard implements OnInit {
  strategyId: number | undefined;
  overlappingStrategies: number[] = [];
  asins: string[] = [];

  private json: StrategyOverlap = {};

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.notification && this.notification.message) {
      try {
        this.json = JSON.parse(this.notification.message);
      } catch {
        this.json = {};
      }
      this.asins = this.json.asins ?? [];
    }
    this.strategyId = this.notification.strategyId;
    this.overlappingStrategies = [];
    if (this.json.strategies) {
      this.overlappingStrategies = this.json.strategies;
    }
  }

  getAsinLink = getAsinLink;
}

@Component({
  selector: "app-notification-card-deleted-brand-asset",
  templateUrl: "./notification-card-deleted-brand-asset.component.html",
  standalone: true,
  imports: [TranslocoDirective, NotificationCardComponent],
})
export class NotificationCardDeletedBrandAssetComponent extends NotificationCard implements OnInit {
  strategy: Strategy = EmptyStrategy;

  constructor(private strategyService: StrategyService) {
    super();
  }

  ngOnInit(): void {
    this.strategyService
      .getStrategyIndex(this.notification.accountId, this.notification.marketplace)
      .pipe(take(1))
      .subscribe((s) => (this.strategy = s.get(this.notification.strategyId) ?? EmptyStrategy));
  }
}

@UntilDestroy()
@Component({
  selector: "app-notification-card-bidder-issue",
  templateUrl: "./notification-card-bidder-issue.component.html",
  standalone: true,
  imports: [NotificationCardComponent, TranslocoDirective, StrategyLinkComponent],
})
export class NotificationCardBidderIssueComponent extends NotificationCard implements OnInit {
  strategyId = 0;
  notif: NotificationBidderIssueEx | undefined;
  reasons = "";
  nb = "some";
  length = 0;
  isCreativeIssue = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.strategyId = this.notification.strategyId;
    this.notif = this.notification as NotificationBidderIssueEx;
    this.isCreativeIssue = this.notif.warningType === M19Status.IGNORED_REJECTED;
    if (this.isCreativeIssue) {
      this.reasons = Utils.m19StatusToReadableStr(this.notif.warningType!);
      this.length = this.notif.moderationDetails ? this.notif.moderationDetails.length : 0;
      this.nb = this.length > 0 ? "" + this.length : "some";
    } else {
      this.reasons = (this.notification as NotificationBidderIssueEx).warningMessage ?? "";
    }
  }
}
