<ng-container *transloco="let t">
  <app-strategy-overlay>
    <app-strategy-page [campaignType]="CampaignType.SP" [strategy$]="strategyLegacy$" #appStrategyPage>
      <span withSpAsins>
        @if (strategy) {
          <app-low-inventory-warning
            [accountId]="strategy.accountId"
            [marketplace]="strategy.marketplace"
            [asins]="strategy.asins | map: getAsins"
          ></app-low-inventory-warning>
        }
        @if (strategy && ineligibleAsins.length > 0) {
          <div class="alert alert-warning" role="alert">
            <div (click)="toggleIneligibleAsinDetails()">
              <span class="icon-prepend float-left mr-2">
                <app-dropdown-toggle-icon [expand]="ineligibleAsinsCollapsed"></app-dropdown-toggle-icon>
              </span>
              {{ t("sd-page.ineligible_asins", { count: ineligibleAsins.length }) }}
            </div>
            <div [collapse]="!ineligibleAsinsCollapsed">
              {{ ineligibleAsins.length > 1 ? t("common.asins") : t("common.asin") }}:
              {{ ineligibleAsins.slice(0, 20).join(", ") }}{{ ineligibleAsins.length > 20 ? ", …" : "" }}
            </div>
          </div>
        }
        @if (strategy) {
          <div class="pg card-perso collapse-card left-child right-child card mb-0">
            <div class="card-perso-header collapse-card-header top-child card-header" (click)="toggleAsinsVisibility()">
              <span class="icon-prepend float-left">
                <app-dropdown-toggle-icon [expand]="asinsVisible"></app-dropdown-toggle-icon>
              </span>
              <h5 class="card-name mb-0">
                {{ t("sd-page.product_asin", { count: asinsList.length }) }}
                {{ brands.size === 0 ? ")" : "" }}
                <app-limit-warning
                  [current]="asinsList ? asinsList.length : 0"
                  [limit]="appStrategyPage.MaxAsins"
                  [message]="t('sd-page.too_many_asins', [appStrategyPage.MaxAsins])"
                >
                </app-limit-warning>
                @if (brands.size > 0) {
                  <span [matTooltip]="getBrands()"> - {{ t("sd-page.num_brand", { count: brands.size }) }}</span>
                }
              </h5>
            </div>
            <div class="card-perso-body bottom-child card-body max-h-[35rem]" [collapse]="!asinsVisible">
              <app-strategy-asins
                class="h-full"
                [asins]="asinsList"
                [accountId]="strategy.accountId"
                [marketplace]="strategy.marketplace"
                [selectable]="!isReadOnly && !strategy.defaultStrategy"
                [deletable]="!isReadOnly && !strategy.defaultStrategy"
                [movable]="!isReadOnly && !strategy.defaultStrategy"
                [allowGroupByParentAsin]="false"
                [asinEligibility]="asinEligibility"
                [withDisplayMode]="true"
                [csvExportFileName]="appStrategyPage.getFileName()"
                (onDelete)="deleteStrategyAsins($event)"
                (move)="moveStrategyAsins($event)"
              >
                @if (!strategy.defaultStrategy) {
                  <span noAsin class="float-right">
                    <button class="btn btn-primary" (click)="showAddAsinModal()" [disabled]="isReadOnly">
                      <fa-icon [icon]="faPlus" class="mr-2"></fa-icon>{{ t("sd-page.add_products") }}
                    </button>
                  </span>
                }
                @if (!strategy.defaultStrategy) {
                  <span withAsins class="float-right">
                    <button class="btn btn-primary" (click)="showAddAsinModal()" [disabled]="isReadOnly">
                      <fa-icon [icon]="faPlus" class="mr-2"></fa-icon>{{ t("sd-page.add_products") }}
                    </button>
                  </span>
                }
              </app-strategy-asins>
            </div>
          </div>
        }
      </span>
      @if (strategy) {
        <div withTargetingStats class="card-perso collapse-card left-child right-child card mb-0">
          <div
            class="card-perso-header collapse-card-header top-child card-header"
            (click)="toggleTargetingStatsVisibility()"
          >
            <span class="icon-prepend float-left">
              <app-dropdown-toggle-icon [expand]="targetingStatsVisible"></app-dropdown-toggle-icon>
            </span>
            <h5 class="card-name mb-0">{{ t("sb-page.targeting_stats") }}</h5>
          </div>
          @if (targetingStatsVisible) {
            <div class="card-perso-body bottom-child card-body">
              <div class="card-body left-child right-child">
                <app-targeting-asin-stats
                  [isReadOnly]="isReadOnly"
                  [strategy]="strategyLegacy"
                ></app-targeting-asin-stats>
              </div>
            </div>
          }
        </div>
      }
    </app-strategy-page>
  </app-strategy-overlay>

  <ng-template #productAdd>
    <div class="modal-header">
      <h5 class="modal-title">{{ t("sd-page.add_products") }}</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="addAsinModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body h-[50vh]">
      <app-asins-selection
        [asins]="asinsList"
        [accountId]="strategy.accountId"
        [marketplace]="strategy.marketplace"
        [csvExportFileName]="appStrategyPage.getFileName()"
        [asinEligibility]="asinEligibility"
        (onAdd)="addStrategyAsins($event)"
        (onDelete)="deleteStrategyAsins($event)"
      />
    </div>
  </ng-template>
</ng-container>
