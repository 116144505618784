import { Component, OnInit, inject } from "@angular/core";
import { faPauseCircle } from "@fortawesome/free-regular-svg-icons";
import { Marketplace, Strategy, StrategyStateEnum } from "@front/m19-api-client";
import { MODAL_DATA } from "@front/m19-ui";

@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.scss"],
})
export class ProductDetailsComponent implements OnInit {
  protected readonly data = inject<ProductDetailData>(MODAL_DATA);

  strategies: Strategy[] | undefined;
  activeStrategies: Strategy[] | undefined;
  asinnumber: string | undefined;
  marketplace: Marketplace | undefined;
  readonly faPauseCircle = faPauseCircle;

  ngOnInit() {
    this.strategies = this.data.strategies;
    this.asinnumber = this.data.asinnumber;
    this.marketplace = this.data.marketplace;
    this.activeStrategies = this.strategies?.filter((x) => x.state == StrategyStateEnum.ENABLED) ?? [];
  }
}

export interface ProductDetailData {
  asinnumber: string;
  marketplace: Marketplace;
  strategies: Strategy[];
}
