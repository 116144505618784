import { AlgoMode, Currency } from "@front/m19-api-client";
import { AbstractControl, FormGroup } from "@angular/forms";
import { currencyRateToEuro } from "@front/m19-utils";

export function dailyBudgetLowerBound(currencyCode: string): number {
  return Math.ceil(1 / currencyRateToEuro(currencyCode as Currency));
}

export function isDailyBudgetLowerInvalid(currencyCode: string, dailyBudget: number, suggestedBid: number) {
  if (dailyBudget < dailyBudgetLowerBound(currencyCode)) {
    return { dailyBudgetBound: true };
  } else if (dailyBudget < 5 * suggestedBid) {
    return { fiveTimesGreater: true };
  }
  return null;
}

// validate the target depending on the selected algorithm
export function algorithmValidator(currencyCode: string, minAllowedBid: number) {
  return (form: FormGroup) => {
    if (form.controls.algoMode.value === AlgoMode.ACOS_TARGET) {
      resetFormControls([form.controls.monthlyBudget, form.controls.dailyBudget, form.controls.suggestedBid]);

      const acosTarget = form.controls.acosTarget;
      let error = null;
      if (!acosTarget.value || acosTarget.value <= 0 || acosTarget.value > 200) {
        error = { invalidAcosTarget: true };
      }
      acosTarget.setErrors(error);
      return error;
    }

    if (form.controls.algoMode.value === AlgoMode.MONTHLY_BUDGET_TARGET) {
      resetFormControls([form.controls.acosTarget, form.controls.dailyBudget, form.controls.suggestedBid]);

      const monthlyBudget = form.controls.monthlyBudget;
      let error = null;
      if (!monthlyBudget.value || monthlyBudget.value < 0) {
        error = { invalidMonthlyBudget: true };
      }
      monthlyBudget.setErrors(error);
      return error;
    }

    if (form.controls.algoMode.value === AlgoMode.PRODUCT_LAUNCH) {
      resetFormControls([form.controls.acosTarget, form.controls.monthlyBudget]);

      const dailyBudget = form.controls.dailyBudget;
      const suggestedBid = form.controls.suggestedBid;

      const error = isDailyBudgetLowerInvalid(currencyCode, dailyBudget.value, suggestedBid.value);
      dailyBudget.setErrors(error);
      if (error) {
        return error;
      }

      if (suggestedBid.value < minAllowedBid) {
        const error = { invalidSuggestedBid: true };
        suggestedBid.setErrors(error);
        return error;
      }
      return error;
    }
  };
}

export function resetFormControls(controls: AbstractControl[]) {
  for (const c of controls) {
    c.setErrors(null);
    c.setValue(undefined, { emitEvent: false, onlySelf: true });
  }
}
