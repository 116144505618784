<div class="main-wrapper flex flex-col" *transloco="let t">
  <div class="header-section mb-2 flex w-full justify-between">
    <div class="flex items-center">
      <button class="btn btn-ghost-secondary" [routerLink]="this.productTrackerUrl">
        {{ t("v2-sidebar.product_tracker") }}
      </button>
      /
      @if (selectedProduct) {
        <button class="btn btn-ghost-secondary icon-right" [matMenuTriggerFor]="menu">
          {{ selectedProduct.asin }}
          <span [class]="ICON_CHEVRON_DOWN" class="ml-1 text-lg text-gray-700"></span>
        </button>
      }

      <mat-menu #menu="matMenu">
        <div class="dd-input-container flex items-center" (click)="$event.stopPropagation()">
          <input
            type="text"
            class="simple-input form-control"
            placeholder="{{ t('common.search') }}"
            [value]="productListFilter"
            (input)="productListFilter = getValueFromInputEvent($event)"
            (click)="$event.stopPropagation()"
            #search
          />
        </div>
        <ul class="product-list flex flex-col">
          @for (p of productList; track p) {
            @if (productPassTextFilter(p)) {
              <li class="dd-item" (click)="navigateToTrackedKeywordsDetails(p)">
                <!-- TODO : change router link url to '/product-tracker' -->
                {{ p }}
              </li>
            }
          }
        </ul>
      </mat-menu>

      @if (selectedProduct) {
        <div class="flex max-w-[25rem] items-center gap-x-2 rounded-md border p-3">
          <app-product-thumbnail [smallImg]="true" [product]="selectedProduct" [marketplace]="marketplace" />
          <div class="flex flex-col gap-y-1 truncate">
            <span class="font-medium">
              {{ selectedProduct.asin }}
            </span>
            <span class="truncate text-sm text-gray-500" [matTooltip]="selectedProduct.title">
              {{ selectedProduct.title }}
            </span>
          </div>
        </div>
      }
    </div>
  </div>
  <div class="content-wrapper flex">
    <div class="card-common flex flex-col p-2">
      <div class="st-list flex flex-1 flex-col">
        @if (!loading) {
          @for (m of metrics; track m) {
            <div class="st-item shrink-0" (click)="selectMetric(m)">
              <div class="st-item-inner flex h-full w-full max-w-full items-center justify-between">
                @if (isMetricSelected(m)) {
                  <div class="color-pill mr-2" [style.background-color]="m.color"></div>
                }
                <div class="w-full truncate">{{ t("metrics." + m.id + "_title") }}</div>
              </div>
            </div>
          }
        }
        @if (loading) {
          <div class="flex h-full w-full items-center justify-center">
            <app-spinner type="default" size="s" [display]="true"></app-spinner>
          </div>
        }
      </div>
    </div>
    <div class="card-common ml-3 flex flex-1 items-center justify-center overflow-auto p-2">
      @if (!loading) {
        <div class="chart-wrapper">
          <div class="w-full">
            <app-date-aggreation-switch-button
              class="aggregation-switch float-right"
              [options]="dateAggregations"
              [selected]="dateAggregation"
              (dateAggSelected)="changeDateAggregation($event)"
            />
          </div>
          @if (dataset) {
            <canvas
              baseChart
              class="chart"
              type="line"
              [datasets]="dataset.chartDataSet"
              [labels]="dataset.labels"
              [options]="dataset.lineChartOptions"
            >
            </canvas>
          }
        </div>
      }
      <app-spinner type="default" size="s" [display]="loading"></app-spinner>
    </div>
  </div>
</div>
