import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { faRocket } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BoardType, LayoutSelectorService } from "@m19-board/layout-selector.service";
import { BehaviorSubject, combineLatest, filter } from "rxjs";
import { computeOverlayMessage, computeOverlayMessageNoAccounts, OverlayMessage } from "./strategy-overlay.component";
import { AccountMarketplace, Marketplace } from "@front/m19-api-client";
import { AccountGroup, OrganizationAccountGroups } from "@front/m19-models";
import { AccountSelectionService, OrganizationAccountGroupService } from "@front/m19-services";

export enum HourlyStatsOverlayMessage {
  NONE = "None",
  UNSUPPORTED_MARKETPLACE = "UnsupportedMarketplace",
  NOT_IN_BILLING_PLAN = "NotInBillingPlan",
}

export const HourlyDataUnsupportedMarketplaces = new Set([Marketplace.IN]);

export function computeHourlyOverlayMessage(
  allOrgs: OrganizationAccountGroups[],
  accountGroup: AccountGroup,
  accountMarketplace: AccountMarketplace,
  boardType: BoardType,
): HourlyStatsOverlayMessage | OverlayMessage {
  if (HourlyDataUnsupportedMarketplaces.has(accountMarketplace.marketplace)) {
    return HourlyStatsOverlayMessage.UNSUPPORTED_MARKETPLACE;
  }
  const selectedOrgId = accountGroup.organizationId;
  const selectedOrg = allOrgs.find((x) => x.id == selectedOrgId);
  const plan = selectedOrg?.getBillingPlan();
  if (plan && !(plan.hourlyDataSupport > 0)) {
    return HourlyStatsOverlayMessage.NOT_IN_BILLING_PLAN;
  }
  return computeOverlayMessage(allOrgs, accountGroup, accountMarketplace, boardType);
}

@UntilDestroy()
@Component({
  selector: "app-hourly-stats-overlay",
  templateUrl: "./hourly-stats-overlay.component.html",
  styleUrls: ["./overlay.component.scss"],
})
export class HourlyStatsOverlayComponent implements OnInit {
  overlayMessage: OverlayMessage | HourlyStatsOverlayMessage;
  readonly OverlayMessage = OverlayMessage;
  readonly HourlyOverlayMessage = HourlyStatsOverlayMessage;
  readonly faRocket = faRocket;

  constructor(
    private organizationService: OrganizationAccountGroupService,
    private accountSelectionService: AccountSelectionService,
    private layoutSelectorService: LayoutSelectorService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const allOrgs$ = new BehaviorSubject<OrganizationAccountGroups[]>([]);
    this.organizationService.allOrganizationAccountGroups$.pipe(untilDestroyed(this)).subscribe((allOrgs) => {
      if (allOrgs.length === 0) {
        this.overlayMessage = computeOverlayMessageNoAccounts(this.layoutSelectorService.getBoardType());
      } else {
        allOrgs$.next(allOrgs);
      }
    });

    combineLatest([
      allOrgs$,
      this.accountSelectionService.accountGroupSelection$,
      this.accountSelectionService.singleAccountMarketplaceSelection$,
    ])
      .pipe(
        untilDestroyed(this),
        filter(([_, accountGroup, accountMarketplace]) => !!accountGroup && !!accountMarketplace),
      )
      .subscribe(([allOrgs, accountGroup, accountMarketplace]) => {
        this.overlayMessage = computeHourlyOverlayMessage(
          allOrgs,
          accountGroup,
          accountMarketplace,
          this.layoutSelectorService.getBoardType(),
        );
      });
  }

  goToAccounts(): void {
    this.router.navigate(["accounts"]);
  }

  billingRegistration(): void {
    this.router.navigate(["billing-registration"]);
  }
}
