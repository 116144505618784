<div class="relative">
  <app-spinner [display]="loading" [main]="true" [logSpinnerTime]="false"></app-spinner>
  @if (!loading && noAccountGroupSetup) {
    <div class="warning-popup">
      <div class="alert alert-primary flex flex-col items-center p-4">
        @if (boardType !== BoardType.WHITELABEL) {
          <span>{{ popupContent }}</span>
        }
        @if (boardType === BoardType.WHITELABEL) {
          <span>{{ popupContentWhitelabel }}</span>
        }
        @if (boardType !== BoardType.WHITELABEL) {
          <app-link-your-account-button class="mt-4"></app-link-your-account-button>
        }
      </div>
    </div>
  }
  <div [class.preview]="noAccountGroupSetup">
    <ng-content></ng-content>
  </div>
</div>
