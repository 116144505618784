import { AgGridAngular } from "@ag-grid-community/angular";
import { ColDef, GridApi, GridOptions, GridReadyEvent, ICellRendererParams } from "@ag-grid-community/core";
import { CurrencyPipe } from "@angular/common";
import { Component, computed, OnInit, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { RouterModule } from "@angular/router";
import { AccountMarketplace, InventoryRule, Marketplace, Strategy, StrategyStateEnum } from "@front/m19-api-client";
import { getBasicGridOptions, restoreGridState, SIDE_BAR_NO_PIVOT } from "@front/m19-grid-config";
import { MetricFormatPipe } from "@front/m19-metrics";
import {
  AdStatsEx,
  BrandingFilter,
  InventoryRules,
  InventoryStats,
  ProductGroupEx,
  RuleExecutionResultEnum,
  StrategyEx,
} from "@front/m19-models";
import {
  AccountSelectionService,
  AsinService,
  AuthService,
  CatalogBrandService,
  InventoryService,
  ProductGroupService,
  SbStrategiesService,
  SdStrategiesService,
  SpStrategiesService,
  StrategyService,
  UserSelectionService,
} from "@front/m19-services";
import { IButtonComponent, Option } from "@front/m19-ui";
import { marketplaceToCurrencyRate } from "@front/m19-utils";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { ImageColumn } from "@m19-board/grid-config/grid-columns";
import { getMetricsColDef } from "@m19-board/grid-config/grid-config";
import {
  FBM_STOCK,
  FBM_STOCK_VALUE,
  FULFILLABLE_STOCK,
  FULFILLABLE_STOCK_VALUE,
  INBOUND_STOCK,
  INBOUND_STOCK_VALUE,
  ORDERS_30D,
  ORDERS_7D,
  RESERVED_STOCK,
  RESERVED_STOCK_VALUE,
  UNFULFILLABLE_STOCK,
  UNFULFILLABLE_STOCK_VALUE,
} from "@m19-board/models/MetricsDef";
import { StatsOverlayComponent } from "@m19-board/overlay/stats-overlay.component";
import { ProductDetailsComponent } from "@m19-board/product-analysis/product-details/product-details.component";
import { AsinLinkComponent } from "@m19-board/product-view/asin-link.component";
import { ProductThumbnailComponent } from "@m19-board/product-view/product-thumbnail.component";
import {
  constantField,
  CsvExportService,
  fieldExtractor,
  metricField,
  simpleField,
} from "@m19-board/services/csv-export.service";
import { AsinFilter } from "@m19-board/shared/filter/asinFilter";
import { Filter, FilterComponent } from "@m19-board/shared/filter/filter.component";
import { ExportButtonComponent } from "@m19-board/shared/ui/export-buttons/export-button.component";
import { StatsFilter } from "@m19-board/stats/stats.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { combineLatest, map, merge, of, switchMap, tap } from "rxjs";
import { AsinInventoryRuleModalComponent } from "../inventory-rules/asin-inventory-rules-modal.component";
import { EstimatedDaysRendererComponent } from "./estimated-days-renderer.component";
import { PauseAdvRendererComponent } from "./pause-adv-renderer.component";
import { StrategyCountRendererComponent } from "./strategy-count-renderer.component";

@UntilDestroy()
@Component({
  selector: "app-seller-inventory",
  templateUrl: "./seller-inventory.component.html",
  styleUrls: ["./seller-inventory.component.scss"],
  providers: [CurrencyPipe, MetricFormatPipe],
  standalone: true,
  imports: [
    FilterComponent,
    IButtonComponent,
    ExportButtonComponent,
    AgGridAngular,
    TranslocoDirective,
    StatsOverlayComponent,
    RouterModule,
  ],
})
export class SellerInventoryComponent implements OnInit {
  SIDE_BAR_NO_PIVOT = SIDE_BAR_NO_PIVOT;
  rowData = [];
  metric: ColDef[] = getMetricsColDef([
    ORDERS_7D,
    ORDERS_30D,
    FULFILLABLE_STOCK,
    INBOUND_STOCK,
    RESERVED_STOCK,
    UNFULFILLABLE_STOCK,
    FULFILLABLE_STOCK_VALUE,
    INBOUND_STOCK_VALUE,
    RESERVED_STOCK_VALUE,
    UNFULFILLABLE_STOCK_VALUE,
  ]).map(({ headerName, ...def }: ColDef) => ({
    ...def,
    hide: def.colId?.includes("VALUE"),
    headerValueGetter: (params: any) => this.translocoService.translate(`metrics.${params.colDef.colId}_title`),
    //flex: 0.3,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    filter: "agNumberColumnFilter",
    floatingFilter: true,
    filterParams: {
      defaultOption: "greaterThan",
    },
    cellRendererParams: (params: ICellRendererParams<AdStatsEx>) => {
      return {
        ...def.cellRendererParams(params),
        previousData: undefined,
        currency: this.currency(),
        locale: this.locale(),
      };
    },
  }));
  colDef: ColDef[] = [
    {
      ...ImageColumn,
      headerName: this.translocoService.translate("catalog-page.image", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("catalog-page.image"),
      pinned: "left",
      cellRendererSelector: (params) => {
        return {
          component: ProductThumbnailComponent,
          params: {
            marketplace: params.data.marketplace,
            asin: params.data.asin,
            smallImg: true,
            customSizeClass: "size-16",
          },
        };
      },
    },
    {
      headerValueGetter: () => this.translocoService.translate("common.product"),
      field: "asin",
      pinned: "left",
      cellRendererSelector: (params: any) => {
        return {
          component: AsinLinkComponent,
          params: {
            product: params.data,
            marketplace: params.data.marketplace,
            asin: params.data.asin,
          },
        };
      },
      filter: "agTextColumnFilter",
      floatingFilter: true,
    },
    {
      headerValueGetter: () => this.translocoService.translate("inventory-grid.fullfilled_by"),
      field: "fulfillmentChannel",
      filter: "agSetColumnFilter",
      floatingFilter: true,
      pinned: "left",
    },
    {
      headerValueGetter: () => this.translocoService.translate("common.title"),
      maxWidth: 400,
      field: "title",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      pinned: "left",
    },
    {
      headerValueGetter: () => this.translocoService.translate("metrics.PRICE_title"),
      field: "price",
      cellStyle: { display: "flex", justifyContent: "end", alignItems: "end" },
      valueFormatter: (params: any) => {
        return this.currencyPipe.transform(params.value, this.currency(), "symbol", "1.2-2") ?? "";
      },
      pinned: "left",
    },
    {
      headerValueGetter: () => this.translocoService.translate("common.strategies"),
      field: "strategies",
      cellStyle: { display: "flex", justifyContent: "end", alignItems: "center" },
      cellRendererSelector: (params: any) => {
        const strategyList = (this.strategies.get(params.data.asin) ?? []).filter(
          (s) => s.state === StrategyStateEnum.ENABLED,
        );
        return {
          component: StrategyCountRendererComponent,
          params: {
            marketplace: this.marketplace,
            strategyList: strategyList,
            inModalName: params.data.asin,
            modalComponent: ProductDetailsComponent,
            modalOptions: {
              modalTitle: "Product Details",
              data: {
                strategies: strategyList,
                asinnumber: params.data.asin,
                marketplace: this.marketplace,
              },
            },
          },
        };
      },
      pinned: "left",
    },
    {
      headerValueGetter: () => this.translocoService.translate("inventory-grid.estimated_days_of_stock"),
      wrapHeaderText: true,
      autoHeaderHeight: true,
      field: "estimatedDaysOfStock",

      cellClass: (params: any) => {
        const state = this.inventoryRules.execute(params.data.asin, params.data as InventoryStats).detail;
        if (state == RuleExecutionResultEnum.LOW_STOCK) {
          return "bg-red-100";
        }
        if (state == RuleExecutionResultEnum.OK) {
          return "bg-green-100";
        }
        if (state == RuleExecutionResultEnum.WARNING) {
          return "bg-orange-100";
        }
        return "bg-inherit";
      },
      cellRendererSelector: (params: any) => {
        return {
          component: EstimatedDaysRendererComponent,
          params: {
            product: params.data,
            inventoryRules: this.inventoryRules,
            locale: this.locale(),
          },
        };
      },
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      filterParams: {
        defaultOption: "greaterThan",
      },
      pinned: "left",
    },
    {
      headerValueGetter: () => this.translocoService.translate("inventory-grid.pause_advertising_threshold"),
      wrapHeaderText: true,
      autoHeaderHeight: true,
      field: "pauseAdv",
      pinned: "left",
      cellStyle: { display: "flex", justifyContent: "end", alignItems: "end" },
      cellRendererSelector: (params: any) => {
        return {
          component: PauseAdvRendererComponent,
          params: {
            product: params.data,
            inventoryRules: this.inventoryRules,
            locale: this.locale(),
            accountId: this.accountId,
            marketplace: this.marketplace,
            isReadOnly: this.isReadOnly,
          },
        };
      },
    },
    ...this.metric,
  ];
  gridOptions: GridOptions = {
    ...getBasicGridOptions("inventory", false),
    columnDefs: this.colDef,
    pagination: true,
    paginationPageSizeSelector: [10, 20, 50, 100],
    paginationPageSize: 20,
    isExternalFilterPresent: () => true,
    doesExternalFilterPass: (node) => {
      return this.passFilter(node);
    },

    sideBar: SIDE_BAR_NO_PIVOT,
  };

  // FILTERS
  asinFilterTags: AsinFilter = new AsinFilter(this.translocoService);
  filters = computed(() => {
    return this.asinFilterTags.getFilterTags(this.selectedFilters());
  });
  selectedFilters = signal<Filter<StatsFilter>[]>([]);
  selectedFilterValue = signal<Set<string> | null>(null);
  // TODO: do we need to have these fields?
  productGroups: ProductGroupEx[] = [];
  customField1Values: Option<string>[] = [];
  customField2Values: Option<string>[] = [];
  customFiel1dMap: Map<string, string[]> = new Map();
  customField2Map: Map<string, string[]> = new Map();
  brandTrafficOptions: Option<BrandingFilter>[] = [];
  stratAsinMap: Map<number, string[]> = new Map();
  asinStratMap: Map<string, Strategy[]> = new Map();

  onlyLowInventory = false;
  lowInventoryNumber = 0;
  lowInventoryPaused = 0;
  dataSource: InventoryStats[] = [];

  gridApi?: GridApi;

  accountGroupName: string = "";
  strategies: Map<string, Strategy[]> = new Map();
  isReadOnly = false;
  locale = toSignal(this.authService.loggedUser$.pipe(map((u) => u.locale)), { initialValue: "" });
  currency = toSignal(this.userSelectionService.selectedCurrency$, { requireSync: true });
  accountId!: string;
  marketplace!: Marketplace;
  inventoryRules: InventoryRules = new InventoryRules([]);
  isManualManaging = false;

  constructor(
    private translocoService: TranslocoService,
    private asinService: AsinService,
    private modalService: BsModalService,
    private toasterService: ToastrService,
    private authService: AuthService,
    private userSelectionService: UserSelectionService,
    private accountSelectionService: AccountSelectionService,
    private csvExportService: CsvExportService,
    private spStrategiesService: SpStrategiesService,
    private sbStrategiesService: SbStrategiesService,
    private sdStrategiesService: SdStrategiesService,
    private productGroupService: ProductGroupService,
    private catalogBrandService: CatalogBrandService,
    private strategyService: StrategyService,
    private currencyPipe: CurrencyPipe,
    private inventoryService: InventoryService,
  ) {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        tap((am: AccountMarketplace) => {
          this.accountGroupName = am.accountGroupName ?? "";
          this.marketplace = am.marketplace;
          this.accountId = am.accountId;
        }),
        switchMap((am) => {
          return combineLatest([
            this.asinService.getInventoryRules(am.accountId, am.marketplace),
            this.asinService.getCatalogManagementMode(am.accountId, am.marketplace),
          ]);
        }),
      )
      .subscribe(([rules, mode]) => {
        this.inventoryRules = rules ?? new InventoryRules([]);
        this.isManualManaging = mode == "manual";
        this.gridApi?.redrawRows();
      });
  }

  ngOnInit(): void {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        tap((am: AccountMarketplace) => {
          this.accountGroupName = am.accountGroupName ?? "";
        }),
        switchMap((am) =>
          combineLatest([
            this.spStrategiesService.getSPStrategiesPerAsin(am.accountId, am.marketplace),
            this.sbStrategiesService.getSBStrategiesPerAsin(am.accountId, am.marketplace),
            this.sdStrategiesService.getSDStrategiesPerAsin(am.accountId, am.marketplace),
            this.inventoryService.getSellerInventoryStats(am.accountId, am.marketplace),
          ]),
        ),
        untilDestroyed(this),
      )
      .subscribe(([spStrategiesPerAsin, sbStrategiesPerAsin, sdStrategiesPerAsin, data]) => {
        data = data.sort((stats1, stats2) => {
          return stats2.fulfillableQuantity - stats1.fulfillableQuantity;
        });
        let rate = 1;
        if (data.length) {
          rate = marketplaceToCurrencyRate(this.marketplace, data[0].currency!);
        }
        this.dataSource = data.map((stat) => ({ ...stat, price: stat.price * rate }) as InventoryStats);
        for (const stat of this.dataSource) {
          const ruleExecution = this.inventoryRules?.execute(stat.asin, stat);
          if (ruleExecution?.detail == RuleExecutionResultEnum.LOW_STOCK) {
            this.lowInventoryNumber++;
          }
          if (ruleExecution?.shouldPauseAdvertising) {
            this.lowInventoryPaused++;
          }
        }
        this.strategies.clear();
        for (const [asin, strategies] of [...spStrategiesPerAsin, ...sbStrategiesPerAsin, ...sdStrategiesPerAsin]) {
          if (this.strategies.has(asin)) {
            this.strategies.get(asin)!.push(...strategies);
          } else {
            this.strategies.set(asin, strategies);
          }
        }
      });
    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am: AccountMarketplace) =>
          combineLatest([
            this.productGroupService.getProductGroups(am.accountId, am.marketplace),
            this.strategyService.getStrategyIndex(am.accountId, am.marketplace),
            this.catalogBrandService.getBrandingFilters(am.accountId, am.marketplace),
          ]),
        ),
        untilDestroyed(this),
      )
      .subscribe(([pgs, strats, brandingFilters]) => {
        this.productGroups = pgs;
        this.brandTrafficOptions = brandingFilters.map((b) => ({ label: b.name ?? "", value: b }));
        this.asinFilterTags.setProductGroups(this.productGroups);
        this.asinFilterTags.setBrandTrafficOptions(this.brandTrafficOptions);
        this.asinFilterTags.setStrategies(Array.from(strats.values()).map((s) => new StrategyEx(s)));
      });

    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am: AccountMarketplace) => {
          return combineLatest([
            this.spStrategiesService.getSPStrategiesPerAsin(am.accountId, am.marketplace),
            this.sbStrategiesService.getSBStrategiesPerAsin(am.accountId, am.marketplace),
            this.sdStrategiesService.getSDStrategiesPerAsin(am.accountId, am.marketplace),
            this.asinService.getCatalog(am.accountId, am.marketplace),
          ]);
        }),
        switchMap(([spAsinStratIndex, sbAsinStratIndex, sdAsinStratIndex, catalog]) => {
          const customField1Values = new Set<string>();
          const customField2Values = new Set<string>();
          for (const product of catalog.products) {
            if (product.productTag1) customField1Values.add(product.productTag1);
            if (product.productTag2) customField2Values.add(product.productTag2);
          }
          this.customField1Values = Array.from(customField1Values).map((v) => ({ label: v, value: v }));
          this.customField2Values = Array.from(customField2Values).map((v) => ({ label: v, value: v }));
          // map custom field values to asin
          this.customFiel1dMap.clear();
          this.customField2Map.clear();
          for (const product of catalog.products) {
            if (product.productTag1) {
              if (!this.customFiel1dMap.has(product.productTag1)) {
                this.customFiel1dMap.set(product.productTag1, []);
              }
              this.customFiel1dMap.get(product.productTag1)!.push(product.asin!);
            }

            if (product.productTag2) {
              if (!this.customField2Map.has(product.productTag2)) {
                this.customField2Map.set(product.productTag2, []);
              }
              this.customField2Map.get(product.productTag2)!.push(product.asin!);
            }
          }
          this.asinFilterTags.setProductTag1Values(this.customField1Values);
          this.asinFilterTags.setProductTag2Values(this.customField2Values);
          return of([spAsinStratIndex, sbAsinStratIndex, sdAsinStratIndex]);
        }),
      )
      .subscribe(([spAsinStratIndex, sbAsinStratIndex, sdAsinStratIndex]) => {
        this.asinStratMap.clear();
        this.stratAsinMap = new Map();
        for (const [asin, strats] of [...spAsinStratIndex, ...sbAsinStratIndex, ...sdAsinStratIndex]) {
          if (this.asinStratMap.has(asin)) {
            this.asinStratMap.get(asin)!.push(...strats);
          } else {
            this.asinStratMap.set(asin, [...strats]);
          }
          for (const s of strats) {
            if (!this.stratAsinMap.has(s.strategyId!)) {
              this.stratAsinMap.set(s.strategyId!, []);
            }
            this.stratAsinMap.get(s.strategyId!)!.push(asin);
          }
        }
      });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    restoreGridState("inventory", this.gridApi);
  }

  setupRuleForAllAsins(): void {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.accountId,
        marketplace: this.marketplace,
        warningMessage: this.translocoService.translate(
          "inventory-grid.warning_this_will_override_the_limit_already_defined_on_the_asins",
        ),
        multipleAsinsBatchLength: this.getNumberOfAsins(),
      },
    };
    const modalRef = this.modalService.show(AsinInventoryRuleModalComponent, modalOptions); // TODO: use the new modal service

    const subscription = merge(modalRef.content!.saveRule, modalRef.content!.deleteRule)
      .pipe(
        map((rule) => {
          const asins = new Set<string>();
          const rules: InventoryRule[] = [];
          for (const stat of this.dataSource) {
            if (asins.has(stat.asin)) {
              continue;
            }
            asins.add(stat.asin);
            rules.push({
              accountId: this.accountId,
              marketplace: this.marketplace,
              asin: stat.asin,
              advertisingPauseThreshold: rule ? rule.advertisingPauseThreshold : -1,
              activateAdvertisingWhenInbound: rule ? rule.activateAdvertisingWhenInbound : false,
            });
          }
          return rules;
        }),
        switchMap((rules) => this.asinService.setInventoryRulesInBulk(rules)),
      )
      .subscribe({
        next: () => {
          this.toasterService.success(`Advertising pausing rule setup for all visible ASINs`, "Inventory rule updated");
          subscription.unsubscribe();
        },
        error: (error) => {
          this.toasterService.error(
            `Error when setting an advertising pausing rule setup for all ASINs: ${error}`,
            "Inventory rule update error",
          );
          subscription.unsubscribe();
        },
      });
  }

  getNumberOfAsins(): number {
    return this.dataSource.length;
  }

  applyFilter(filters: Filter<StatsFilter>[]) {
    this.selectedFilters.set(filters);
    this.selectedFilterValue.set(
      AsinFilter.filterChanged(filters, this.stratAsinMap, this.customFiel1dMap, this.customField2Map),
    );
    if (!this.gridApi?.isDestroyed()) {
      this.gridApi?.onFilterChanged();
    }
  }

  passFilter(node: any) {
    let res = true;
    if (this.onlyLowInventory) {
      res = this.inventoryRules.execute(node.data.asin, node.data as InventoryStats).detail == "low";
    }
    return res && AsinFilter.asinMatchesFilter(node.data.asin, this.selectedFilterValue());
  }

  toggleLowInventoryFilter() {
    this.onlyLowInventory = !this.onlyLowInventory;
    this.gridApi?.onFilterChanged();
  }

  downloadFile() {
    if (!this.dataSource.length) {
      this.toasterService.error("No data to export", "Export error");
      return;
    }
    this.csvExportService.exportCsv(
      {
        prefix: "inventory",
        accountGroupName: this.accountGroupName,
        marketplace: this.marketplace,
      },
      this.dataSource,
      [
        simpleField("asin"),
        fieldExtractor("title", (stat) => stat.title ?? ""),
        constantField("currency", this.currency()),
        fieldExtractor("price", (stat) => stat.price?.toFixed(2) ?? "-"),
        metricField(ORDERS_7D),
        metricField(ORDERS_30D),
        fieldExtractor("estimatedDaysOfStock", (stat) => stat.estimatedDaysOfStock?.toString() ?? ""),
        metricField(FULFILLABLE_STOCK),
        metricField(FULFILLABLE_STOCK_VALUE, "availableValue"),
        metricField(FBM_STOCK),
        metricField(FBM_STOCK_VALUE, "availableFbmValue"),
        metricField(INBOUND_STOCK),
        metricField(INBOUND_STOCK_VALUE, "inboundValue"),
        metricField(RESERVED_STOCK),
        metricField(RESERVED_STOCK_VALUE, "reservedValue"),
        metricField(UNFULFILLABLE_STOCK),
        metricField(UNFULFILLABLE_STOCK_VALUE, "unfulfillableValue"),
        fieldExtractor(
          "pauseAdvertisingThreshold",
          (stat) =>
            this.inventoryRules.asinInventoryRule.get(stat.asin)?.advertisingPauseThreshold?.toFixed(0) ?? "None",
        ),
        fieldExtractor("isAdvertisingPaused", (stat) =>
          this.inventoryRules.execute(stat.asin, stat as InventoryStats).shouldPauseAdvertising ? "Yes" : "No",
        ),
      ],
    );
  }

  restoreDefaultColumns() {
    this.gridApi?.resetColumnState();
    this.gridApi?.autoSizeAllColumns();
  }
}
