import { CommonModule } from "@angular/common";
import { Component, computed, inject, input, signal, TemplateRef, viewChild } from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
  AccountMarketplace,
  AlgoMode,
  CampaignType,
  Strategy,
  StrategyStateEnum,
  TacosStrategyGroupStateEnum,
} from "@front/m19-api-client";
import { StrategyEx } from "@front/m19-models";
import {
  AccountSelectionService,
  Constant,
  SbStrategiesService,
  StrategyService,
  TacosStrategiesService,
  TacosStrategy,
} from "@front/m19-services";
import {
  IAlertComponent,
  IBadgeComponent,
  IButtonComponent,
  IToggleComponent,
  ModalRef,
  ModalService,
} from "@front/m19-ui";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { CreativeDisplayComponent } from "@m19-board/sponsored-brand/creative-display/creative-display.component";
import { FormMode, SbStrategyFormComponent } from "@m19-board/strategies/sb-strategy-form/sb-strategy-form.component";
import { ICON_ADD, ICON_WARNING_TRIANGLE } from "@m19-board/utils/iconsLabels";
import { ToastrService } from "ngx-toastr";
import { switchMap } from "rxjs";

@Component({
  selector: "tacos-ad-types",
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    IToggleComponent,
    MatTooltipModule,
    IBadgeComponent,
    SbStrategyFormComponent,
    CreativeDisplayComponent,
    IButtonComponent,
    IAlertComponent,
  ],
  templateUrl: "./tacos-ad-types.component.html",
})
export class TacosAdTypesComponent {
  readonly CampaignType = CampaignType;
  private readonly strategyService = inject(StrategyService);
  private readonly tacosStrategiesService = inject(TacosStrategiesService);
  private readonly toastrService = inject(ToastrService);
  private readonly translocoService = inject(TranslocoService);
  private readonly modalService = inject(ModalService);
  private readonly accountSelectionService = inject(AccountSelectionService);
  private readonly sbStrategiesService = inject(SbStrategiesService);

  readonly ICON_ADD = ICON_ADD;
  readonly ICON_WARNING_TRIANGLE = ICON_WARNING_TRIANGLE;
  readonly FormMode = FormMode;

  newCreativeModal = viewChild<TemplateRef<any>>("newCreativeModal");
  modalRef: ModalRef<any, any> | undefined;

  am = input.required<AccountMarketplace>();
  tacosStrategy = input.required<TacosStrategy>();
  sbAsins = input<string[] | undefined>();
  missingAsinsInSb = input<string[] | undefined>();
  isReadonly = input.required<boolean>();

  sbStrategyEx = computed(() =>
    this.tacosStrategy().sbStrategy ? new StrategyEx(this.tacosStrategy().sbStrategy!) : undefined,
  );
  canCreateNewCreative = computed(
    () =>
      !this.tacosStrategy().sbCreatives?.length ||
      this.tacosStrategy().sbCreatives!.length < Constant.maxCreativesPerStrategy,
  );

  sdLoadingState = signal(false);
  sbLoadingState = signal(false);
  isTacosStrategyPaused = computed(() => this.tacosStrategy().state === TacosStrategyGroupStateEnum.PAUSED);
  isSdToggleChecked = computed(
    () =>
      this.tacosStrategy().sdStrategy &&
      this.tacosStrategy().sdSpendRepartition !== undefined &&
      this.tacosStrategy().sdSpendRepartition! > 0,
  );

  isSbToggleChecked = computed(
    () =>
      this.tacosStrategy().sbStrategy &&
      this.tacosStrategy().sbSpendRepartition !== undefined &&
      this.tacosStrategy().sbSpendRepartition! > 0,
  );

  brandAssets = toSignal(
    this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(
      switchMap((am) => this.sbStrategiesService.getSbCreativeBrandAssets(am.accountId, am.marketplace)),
      takeUntilDestroyed(),
    ),
  );

  onSdToggleChange(checked: boolean) {
    if (checked && !this.tacosStrategy().sdStrategy) {
      this.activateSdStrategy();
    } else {
      const newState = checked ? StrategyStateEnum.ENABLED : StrategyStateEnum.PAUSED;
      this.updateStrategyState(CampaignType.SD, newState);
    }
  }

  onSbToggleChange(checked: boolean) {
    if (checked && !this.tacosStrategy().sbStrategy) {
      this.openSbForm();
    } else {
      const newState = checked ? StrategyStateEnum.ENABLED : StrategyStateEnum.PAUSED;
      this.updateStrategyState(CampaignType.SB, newState);
    }
  }

  private activateSdStrategy() {
    const strategy: Strategy = {
      accountId: this.am().accountId,
      marketplace: this.am().marketplace,
      state: StrategyStateEnum.ENABLED,
      campaignType: CampaignType.SD,
      defaultStrategy: false,
      name: this.tacosStrategy().spStrategy!.name!,
      asins: this.tacosStrategy().spStrategy!.asins!,
      algoMode: AlgoMode.TACOS_TARGET,
      tacosTarget: this.tacosStrategy().tacosTarget! / 100,
      disableOtherQueries: false,
      tactics: [],
      audienceTargetings: [],
    };

    this.sdLoadingState.set(true);
    this.strategyService
      .createStrategy(strategy, this.am()!.resourceOrganizationId!)
      .pipe(
        switchMap((strategy) =>
          this.tacosStrategiesService.updateTacosStrategyGroup({
            accountId: this.am()!.accountId,
            marketplace: this.am()!.marketplace,
            tacosStrategyGroupId: this.tacosStrategy()!.tacosStrategyGroupId!,
            sdStrategyId: strategy.strategyId!,
          }),
        ),
      )
      .subscribe({
        next: () => {
          this.toastrService.success(this.translocoService.translate("tacos-strategy.sd_strategy_activated"));
          this.sdLoadingState.set(false);
        },
        error: (err) => {
          this.toastrService.error(err);
          this.sdLoadingState.set(false);
        },
      });
  }

  openSbForm() {
    if (!this.canCreateNewCreative()) return;

    this.modalRef = this.modalService.openModal(this.newCreativeModal()!, {
      modalTitle: `New Creative - ${this.tacosStrategy().spStrategy!.name}`,
      closeable: false,
    });
  }

  private updateStrategyState(campaignType: CampaignType, state: StrategyStateEnum) {
    if (campaignType === CampaignType.SD) {
      this.sdLoadingState.set(true);
    } else if (campaignType === CampaignType.SB) {
      this.sbLoadingState.set(true);
    }

    this.tacosStrategiesService
      .updateTacosStrategyGroup({
        accountId: this.am()!.accountId,
        marketplace: this.am()!.marketplace,
        tacosStrategyGroupId: this.tacosStrategy()!.tacosStrategyGroupId!,
        campaignType,
        state,
      })
      .subscribe(() => {
        this.toastrService.success(this.translocoService.translate("tacos-strategy.strategy_state_updated"));
        if (campaignType === CampaignType.SD) {
          this.sdLoadingState.set(false);
        } else if (campaignType === CampaignType.SB) {
          this.sbLoadingState.set(false);
        }
      });
  }
}
