import { CommonModule } from "@angular/common";
import { Component, computed, DestroyRef, inject, signal, TemplateRef, ViewChild } from "@angular/core";
import { takeUntilDestroyed, toObservable, toSignal } from "@angular/core/rxjs-interop";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import {
  CampaignType,
  EntityIdType,
  SbCreative,
  Strategy,
  StrategyStateEnum,
  TacosStrategyGroupStateEnum,
  UpdateTacosStrategyGroupRequest,
} from "@front/m19-api-client";
import { StrategyEx } from "@front/m19-models";
import {
  AccountSelectionService,
  SbStrategiesService,
  TacosStrategiesService,
  TacosStrategy,
} from "@front/m19-services";
import { IButtonComponent, IInputComponent, Itab, ITabsComponent, ModalRef, ModalService } from "@front/m19-ui";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { ActivityComponent, ActivityFilter } from "@m19-board/activities/activity/activity.component";
import { StateBadgeComponent } from "@m19-board/shared/state-badge/state-badge.component";
import { StrategyInfoBlocComponent } from "@m19-board/shared/strategy-info-bloc/strategy-info-bloc.component";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";
import { ICON_ADD, ICON_CHEVRON_DOWN, ICON_PAUSE, ICON_PLAY } from "@m19-board/utils/iconsLabels";
import { ToastrService } from "ngx-toastr";
import { combineLatest, map, of, switchMap, tap } from "rxjs";
import { TacosAdTypesComponent } from "./tacos-ad-types/tacos-ad-types.component";
import { TacosProductsComponent } from "./tacos-products/tacos-products.component";
import { TacosStatsComponent } from "./tacos-stats/tacos-stats.component";

enum SECTIONS {
  PRODUCTS,
  CAMPAIGNS,
  STATS,
  ACTIVITIES,
}

@Component({
  selector: "tacos-strategy-page",
  standalone: true,
  imports: [
    CommonModule,
    StrategyInfoBlocComponent,
    IButtonComponent,
    SpinnerComponent,
    RouterModule,
    IInputComponent,
    ITabsComponent,
    MatTooltipModule,
    StateBadgeComponent,
    ActivityComponent,
    TacosStatsComponent,
    TacosProductsComponent,
    TacosAdTypesComponent,
    TranslocoDirective,
  ],
  templateUrl: "./tacos-strategy-page.component.html",
})
export class TacosStrategyPageComponent {
  readonly ICON_CHEVRON_D = ICON_CHEVRON_DOWN;
  readonly ICON_PLAY = ICON_PLAY;
  readonly ICON_PAUSE = ICON_PAUSE;
  readonly ICON_PLUS = ICON_ADD;

  readonly TacosStrategyGroupState = TacosStrategyGroupStateEnum;
  readonly StrategyState = StrategyStateEnum;
  readonly SECTIONS = SECTIONS;
  readonly CampaignType = CampaignType;
  private readonly modalService = inject(ModalService);
  private readonly accountSelection = inject(AccountSelectionService);
  private readonly tacosStrategiesService = inject(TacosStrategiesService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly translocoService = inject(TranslocoService);
  private readonly toastrService = inject(ToastrService);
  private readonly sbStrategiesService = inject(SbStrategiesService);

  readonly destroyRef = inject(DestroyRef);

  readonly am = toSignal(this.accountSelection.singleAccountMarketplaceSelection$);
  readonly isReadOnly = toSignal(this.accountSelection.readOnlyMode$);

  activityFilter = computed<ActivityFilter[]>(() => {
    const creativeIds = this.sbCreatives()?.map((c) => c.creativeId);

    return [
      { primaryType: EntityIdType.tacosStrategyGroupId, primaryId: this.tacosStrategyEx()?.tacosStrategyGroupId },
      ...(creativeIds?.map((c) => ({ primaryType: EntityIdType.creativeId, primaryId: c })) ?? []),
    ];
  });

  private readonly tacosStrategy$ = this.accountSelection.singleAccountMarketplaceSelection$.pipe(
    takeUntilDestroyed(this.destroyRef),
    switchMap((am) =>
      combineLatest([
        this.route.paramMap,
        this.tacosStrategiesService.getTacosStrategyIndex(am.accountId, am.marketplace),
      ]),
    ),
    map(([params, tacosStrategyIndex]) => tacosStrategyIndex.get(Number(params.get("id")))),
    tap((s) => {
      if (!s) {
        this.router.navigate(["/advertising/tacos-strategies"]);
      }
    }),
  );

  readonly tacosStrategyEx = toSignal<TacosStrategy>(
    this.tacosStrategy$.pipe(
      takeUntilDestroyed(this.destroyRef),
      switchMap((tacosStrategy) =>
        this.tacosStrategiesService.getTacosStrategies(
          tacosStrategy!.accountId!,
          tacosStrategy!.marketplace!,
          tacosStrategy!.tacosStrategyGroupId!,
        ),
      ),
    ),
  );

  readonly spStrategy = computed<Strategy | undefined>(() => this.tacosStrategyEx()?.spStrategy);

  readonly sbStrategy = computed<StrategyEx | undefined>(() => {
    const sbStrategy = this.tacosStrategyEx()?.sbStrategy;
    if (!sbStrategy) return undefined;
    return new StrategyEx(sbStrategy);
  });

  readonly sbCreatives = computed<SbCreative[] | undefined>(() => this.tacosStrategyEx()?.sbCreatives);

  readonly sdStrategy = computed<Strategy | undefined>(() => this.tacosStrategyEx()?.sdStrategy);

  readonly sbAsins = toSignal(
    combineLatest([this.accountSelection.singleAccountMarketplaceSelection$, toObservable(this.sbStrategy)]).pipe(
      takeUntilDestroyed(this.destroyRef),
      switchMap(([am, sbStrategy]) => {
        if (sbStrategy) {
          return this.sbStrategiesService.getSbAsinsAsArray(am.accountId, am.marketplace, sbStrategy.strategyId!);
        }
        return of([]);
      }),
    ),
  );

  sbAsinsString = computed<string[] | undefined>(() => this.sbAsins()?.map((a) => a.asin));

  readonly missingAsinsInSb = computed<string[] | undefined>(() => {
    if (!this.sbStrategy()) return undefined;
    const spAsins: string[] | undefined = this.spStrategy()?.asins?.map((a) => a.asin);
    return spAsins?.filter((a) => !this.sbAsinsString()?.includes(a));
  });

  loading = signal(false);
  modalLoading = signal(false);
  stateUpdateLoading = signal(false);

  tacosTargetInput = signal<number | undefined>(undefined);
  strategyNameInput = signal<string | undefined>(undefined);

  @ViewChild("targetModal") targetModal!: TemplateRef<any>;
  @ViewChild("editNameModal") editNameModal!: TemplateRef<any>;

  createSbStrategy = signal(false);

  modalRef: ModalRef<void> | undefined;

  tabs = signal<Itab<SECTIONS>[]>([
    {
      label: this.translocoService.translate("common.products"),
      value: SECTIONS.PRODUCTS,
      icon: "icon-[mdi--package-variant]",
    },
    {
      label: this.translocoService.translate("tacos-strategy.ad_types"),
      value: SECTIONS.CAMPAIGNS,
      icon: "icon-[mdi--lightbulb-outline]",
    },
    { label: "Stats", value: SECTIONS.STATS, icon: "icon-[mdi--chart-line]" },
    {
      label: this.translocoService.translate("v2-sidebar.activities"),
      value: SECTIONS.ACTIVITIES,
      icon: "icon-[mdi--clock-time-nine-outline]",
    },
  ]);
  activeTab = signal(0);
  selectedSection = computed(() => this.tabs()[this.activeTab()].value);

  hideModal() {
    this.modalRef?.close();
  }

  openTargetModal() {
    this.modalRef = this.modalService.openModal(this.targetModal, {
      modalTitle: this.translocoService.translate("algo-mode-selection.tacos_target"),
    });
  }

  updateTacosTarget() {
    this.modalLoading.set(true);
    if (this.tacosStrategyEx()) {
      if (this.tacosTargetInput()! <= 0 || this.tacosTargetInput()! > 100) {
        this.toastrService.error(
          this.translocoService.translate("tacos-strategy-page.tacos_target_must_be_between_1_and_100"),
        );
        this.modalLoading.set(false);
        return;
      }

      const updateParams: UpdateTacosStrategyGroupRequest = {
        accountId: this.am()!.accountId,
        marketplace: this.am()!.marketplace,
        tacosTarget: this.tacosTargetInput()! / 100,
        tacosStrategyGroupId: this.tacosStrategyEx()!.tacosStrategyGroupId!,
      };

      this.tacosStrategiesService.updateTacosStrategyGroup(updateParams).subscribe({
        next: () => {
          this.modalLoading.set(false);
          this.toastrService.success(this.translocoService.translate("tacos-strategy-page.tacos_target_updated"));
          this.hideModal();
          this.tacosTargetInput.set(undefined);
        },
        error: (err) => {
          this.modalLoading.set(false);
          this.toastrService.error(err);
        },
      });
    }
  }

  openEditNameModal() {
    this.modalRef = this.modalService.openModal(this.editNameModal, {
      modalTitle: this.translocoService.translate("sp-strategy-group-page.edit_strategy_name"),
    });
  }

  updateStrategyName() {
    if (!this.strategyNameInput()) return;
    if (this.strategyNameInput() && this.spStrategy()) {
      this.modalLoading.set(true);
      this.tacosStrategiesService
        .updateTacosStrategyName({
          accountId: this.am()!.accountId,
          marketplace: this.am()!.marketplace,
          tacosStrategyGroupId: this.tacosStrategyEx()!.tacosStrategyGroupId!,
          name: this.strategyNameInput()!,
        })
        .subscribe({
          next: () => {
            this.toastrService.success(this.translocoService.translate("sp-strategy-group-page.strategy_name_updated"));
            this.modalLoading.set(false);
            this.hideModal();
            this.strategyNameInput.set(undefined);
          },
          error: (err) => {
            this.toastrService.error(err);
            this.modalLoading.set(false);
          },
        });
    }
  }

  updateStrategyGroupState(state: TacosStrategyGroupStateEnum) {
    this.stateUpdateLoading.set(true);
    this.tacosStrategiesService
      .updateTacosStrategyGroup({
        accountId: this.am()!.accountId,
        marketplace: this.am()!.marketplace,
        tacosStrategyGroupId: this.tacosStrategyEx()!.tacosStrategyGroupId!,
        state,
      })
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("tacos-strategy-page.strategy_group_state_updated"),
          );
          this.stateUpdateLoading.set(false);
        },
        error: (err) => {
          this.toastrService.error(err);
          this.stateUpdateLoading.set(false);
        },
      });
  }
}
