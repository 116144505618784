// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    Marketplace,
} from './';

/**
 * @export
 * @interface DspAdvertiser
 */
export interface DspAdvertiser {
    /**
     * @type {string}
     * @memberof DspAdvertiser
     */
    dspAdvertiserId: string;
    /**
     * @type {Marketplace}
     * @memberof DspAdvertiser
     */
    marketplace: Marketplace;
    /**
     * @type {string}
     * @memberof DspAdvertiser
     */
    name: string;
    /**
     * @type {string}
     * @memberof DspAdvertiser
     */
    url: string;
    /**
     * @type {number}
     * @memberof DspAdvertiser
     */
    profileId: number;
}
