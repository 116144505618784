import { Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";
import { StrategyCache } from "libs/m19-services/src/lib/m19-services/strategy.cache";
import { StrategyEx } from "@front/m19-models";
import { CampaignType } from "@front/m19-api-client";

@UntilDestroy()
@Component({
  templateUrl: "./tracking-strategy-info-modal.component.html",
})
export class TrackingStrategyInfoModalComponent implements OnInit {
  @Input()
  strategyIds: number[] = [];

  strategies: StrategyEx[];

  readonly CampaignType = CampaignType;

  constructor(
    public bsModalRef: BsModalRef,
    private strategyCache: StrategyCache,
  ) {}

  ngOnInit(): void {
    this.strategyCache.strategyIndex$.pipe(untilDestroyed(this)).subscribe((strategyIndex) => {
      this.strategies = this.strategyIds.map((id) => strategyIndex.get(id));
    });
  }
}
