import {
  AlgoMode,
  AudienceTargeting,
  CampaignType,
  Marketplace,
  SbCreative,
  Strategy,
  StrategyAsin,
  StrategyKeywords,
  StrategyStateEnum,
  StrategyTactic,
  StrategyType,
  Targeting,
} from '../api-client';

export type AlgoTarget = {
  strategyId: number;
  strategyName: string;
  accountId: string;
  marketplace: Marketplace;
  algorithm: AlgoMode;
  acosTarget?: number;
  dailyBudget?: number;
  suggestedBid?: number;
  monthlyBudget?: number;
  nextMonthlyBudget?: number;
  today?: string;
};

export const MaxPriority = Number.MAX_SAFE_INTEGER;
export const MinPriority = Number.MIN_SAFE_INTEGER;

export class StrategyEx implements Strategy {
  // TODO the constructor should directly retrieve an array of SbCreativeEx instead of a map
  constructor(strategy: Strategy, sbCreativeIndex: Map<number, SbCreative[]>) {
    this.accountId = strategy.accountId;
    this.marketplace = strategy.marketplace;
    this.strategyId = strategy.strategyId!;
    this.asins = strategy.asins!;
    this.topOfSearchRankings = strategy.topOfSearchRankings!;
    this.acosTarget = strategy.acosTarget!;
    this.state = strategy.state;
    this.campaignType = strategy.campaignType;
    this.algoMode = strategy.algoMode;
    this.algorithm = strategy.algoMode;
    this.suggestedBid = strategy.suggestedBid!;
    this.dailyBudget = strategy.dailyBudget;
    this.monthlyBudget = strategy.monthlyBudget;
    this.nextMonthlyBudget = strategy.nextMonthlyBudget;
    this.today = strategy.today;
    this.minDailySpend = strategy.minDailySpend;
    this.autoAlgoExplorationEnabled = !strategy.disableOtherQueries;
    this.autoTargetCampainEnabled = !strategy.disableAutoSegment;
    this.productTargetingEnabled = !strategy.disableProductSegment;
    this.defaultStrategy = strategy.defaultStrategy;
    this.name = strategy.name;
    this.strategyLabel = strategy.strategyLabel;
    this.primeDayBoost = strategy.primeDayBoost!;
    this.activatePrimeDayBoost = false;
    this.tactics = strategy.tactics;
    this.audienceTargetings = strategy.audienceTargetings;
    this.sbCreatives = sbCreativeIndex.get(strategy.strategyId!)!;
    this.daypartingPauseHour = strategy.daypartingPauseHour!;
    this.daypartingReactivationHour = strategy.daypartingReactivationHour!;
    this.brandEntityId = strategy.brandEntityId!;
    this.strategyType = strategy.strategyType!;
    this.strategyGroupId = strategy.strategyGroupId;
    this.targetings = strategy.targetings!;
    this.priority = strategy.priority;
    this.strategyName = this.getName();
    // round to 2 decimals
    this.computedDailyBudget = Math.round((strategy.computedDailyBudget! + Number.EPSILON) * 100) / 100;
    this.asinIsolation = strategy.asinIsolation!;
  }

  public toStrategy(): Strategy {
    return {
      accountId: this.accountId,
      marketplace: this.marketplace,
      strategyId: this.strategyId,
      asins: this.asins,
      topOfSearchRankings: this.topOfSearchRankings,
      acosTarget: this.acosTarget,
      state: this.state,
      name: this.name,
      strategyLabel: this.strategyLabel,
      campaignType: this.campaignType,
      algoMode: this.algoMode,
      suggestedBid: this.suggestedBid,
      dailyBudget: this.dailyBudget,
      monthlyBudget: this.monthlyBudget,
      nextMonthlyBudget: this.nextMonthlyBudget,
      today: this.today,
      minDailySpend: this.minDailySpend,
      disableOtherQueries: !this.autoAlgoExplorationEnabled,
      disableAutoSegment: !this.autoTargetCampainEnabled,
      disableProductSegment: !this.productTargetingEnabled,
      defaultStrategy: this.defaultStrategy,
      primeDayBoost: this.primeDayBoost,
      tactics: this.tactics,
      audienceTargetings: this.audienceTargetings,
      daypartingPauseHour: this.daypartingPauseHour,
      daypartingReactivationHour: this.daypartingReactivationHour,
      brandEntityId: this.brandEntityId,
      strategyType: this.strategyType,
      strategyGroupId: this.strategyGroupId,
      targetings: this.targetings,
      priority: this.priority,
      computedDailyBudget: this.computedDailyBudget,
      asinIsolation: this.asinIsolation,
    };
  }

  public getName(): string {
    if (this.defaultStrategy) return this.unique ? 'All products' : 'All other products';
    return this.name ? this.name : 'Unnamed Strategy';
  }

  public isAllOtherProduct(): boolean {
    return this.defaultStrategy;
  }

  accountId: string;
  algorithm: string;
  marketplace: Marketplace;
  strategyId: number;
  asins: StrategyAsin[];
  topOfSearchRankings: StrategyKeywords[];
  acosTarget: number;
  tacosTarget?: number;
  state: StrategyStateEnum;
  campaignType: CampaignType;
  algoMode: AlgoMode;
  suggestedBid: number;
  dailyBudget?: number;
  monthlyBudget?: number;
  nextMonthlyBudget?: number;
  today?: string;
  minDailySpend?: number;
  autoTargetCampainEnabled: boolean;
  autoAlgoExplorationEnabled: boolean;
  productTargetingEnabled: boolean;
  defaultStrategy: boolean;
  name?: string;
  strategyName: string; // required for algoTarget change
  strategyLabel?: string;
  primeDayBoost: number;
  activatePrimeDayBoost: boolean;
  constraint?: number;
  /**
   * @deprecated
   */
  sbCreatives: SbCreative[];
  tactics: Array<StrategyTactic>;
  audienceTargetings: Array<AudienceTargeting>;
  unique = false;
  daypartingPauseHour: number | null;
  daypartingReactivationHour: number | null;
  brandEntityId: string;
  strategyType: StrategyType;
  strategyGroupId?: number;
  targetings: Targeting[];
  priority?: number;
  computedDailyBudget?: number;
  asinIsolation: boolean;

  get disableOtherQueries() {
    return !this.autoAlgoExplorationEnabled;
  }

  get disableAutoSegment() {
    return !this.autoTargetCampainEnabled;
  }

  get disableProductSegment() {
    return !this.productTargetingEnabled;
  }

  public compareState(other: StrategyEx): number {
    return this.state <= other.state ? -1 : 1;
  }

  public static getAlgoModeStr(algoMode: AlgoMode) {
    return AlgoModeStr[algoMode]?.description;
  }

  public static getAlgoModeStrShort(algoMode: AlgoMode) {
    return AlgoModeStr[algoMode]?.shortDescription;
  }
}

export const AlgoModeStr: { [key in AlgoMode]: { description: string; shortDescription: string } } = {
  [AlgoMode.PRODUCT_LAUNCH]: {
    description: 'Force Product Visibility',
    shortDescription: 'algo-mode-selection.constant_bid',
  },
  [AlgoMode.ACOS_TARGET]: {
    description: 'Optimize Sales with ACOS Target',
    shortDescription: 'algo-mode-selection.acos_target',
  },
  [AlgoMode.MONTHLY_BUDGET_TARGET]: {
    description: 'Optimize Sales with Monthly Budget Target',
    shortDescription: 'algo-mode-selection.monthly_budget_target',
  },
  [AlgoMode.TACOS_TARGET]: {
    description: 'Optimize Sales with TACOS Target',
    shortDescription: 'algo-mode-selection.tacos_target',
  },
};

export const StrategyTypeStr: { [key in StrategyType]: string } = {
  [StrategyType.LEGACY]: '',
  [StrategyType.PRODUCT]: 'Main',
  [StrategyType.BRAND]: 'Brand defense',
  [StrategyType.KEYWORD]: 'Focus',
};
