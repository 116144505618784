<ng-container *transloco="let t">
  @if (strategy()) {
    <div class="flex h-full items-center gap-x-3">
      @if (strategy().daypartingPauseHour !== undefined) {
        <span
          matTooltip="{{ t('strategy-info-cell.day_parting_is_activated_on_this_strategy') }}"
          class="icon-[material-symbols--calendar-clock-outline-rounded] text-main text-2xl line-through"
        ></span>
      }
      @if (!strategy().autoAlgoExplorationEnabled && strategy().strategyType === StrategyType.PRODUCT) {
        <span
          matTooltip="{{ t('strategy-info-cell.ai-powered_targeting_is_deactivated') }}"
          class="icon-[material-symbols--warning-rounded] text-2xl text-orange-400"
        ></span>
      }

      @if (strategy().minDailySpend) {
        <span
          [matTooltip]="t('strategy-info-cell.min_daily_spend', [formattedDailySpend()])"
          class="icon-[material-symbols--monetization-on-outline-rounded] text-2xl text-gray-400"
        ></span>
      }
      @if (strategy().primeDayBoost && promoBoostActivated) {
        <span
          [matTooltip]="t('strategy-info-cell.boost_activated_on_this_strategy')"
          [class]="ICON_BOOST"
          class="text-main text-2xl"
        ></span>
      }
    </div>
  }
</ng-container>
