// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    Marketplace,
    SearchResult,
} from './';

/**
 * @export
 * @interface SearchResultValidation
 */
export interface SearchResultValidation {
    /**
     * @type {string}
     * @memberof SearchResultValidation
     */
    id?: string;
    /**
     * @type {Marketplace}
     * @memberof SearchResultValidation
     */
    marketplace?: Marketplace;
    /**
     * @type {number}
     * @memberof SearchResultValidation
     */
    timestamp?: number;
    /**
     * @type {number}
     * @memberof SearchResultValidation
     */
    page?: number;
    /**
     * @type {string}
     * @memberof SearchResultValidation
     */
    type?: SearchResultValidationTypeEnum;
    /**
     * @type {string}
     * @memberof SearchResultValidation
     */
    status?: SearchResultValidationStatusEnum;
    /**
     * @type {string}
     * @memberof SearchResultValidation
     */
    message?: string;
    /**
     * @type {SearchResult}
     * @memberof SearchResultValidation
     */
    searchResult?: SearchResult;
}

/**
 * @export
 * @enum {string}
 */
export enum SearchResultValidationTypeEnum {
    OTHER = 'OTHER',
    ERROR = 'ERROR',
    VALIDATION = 'VALIDATION'
}
/**
 * @export
 * @enum {string}
 */
export enum SearchResultValidationStatusEnum {
    UNKNOWN = 'UNKNOWN',
    PENDING = 'PENDING',
    OK = 'OK',
    INVALID = 'INVALID'
}

