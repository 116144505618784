import { Component, inject } from "@angular/core";
import { Router, RouterOutlet } from "@angular/router";
import { AuthService } from "@front/m19-services";
import { IButtonComponent } from "@front/m19-ui";
import { TranslocoDirective } from "@jsverse/transloco";
import { LogoComponent } from "@m19-board/shared/logo/logo.component";

@Component({
  selector: "onboarding-layout",
  templateUrl: "./onboarding-layout.component.html",
  standalone: true,
  imports: [RouterOutlet, LogoComponent, IButtonComponent, TranslocoDirective],
})
export class OnboardingLayoutComponent {
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);

  logout() {
    this.authService.logout();
    this.router.navigate(["/login"]);
  }
}
