<ng-container *transloco="let t">
  <app-overlay
    popupContent="{{ t('link-your-account-button.start_by_limking_amazon') }}"
    popupContentWhitelabel="{{ t('account-setting.no_marketplace') }}"
  >
    <div class="mb-2 flex items-end justify-between">
      <div>
        <h2>{{ t("account-setting.account_settings") }}</h2>
        <div>
          {{ t("account-setting.account_settings_insctructions") }}
        </div>
      </div>
    </div>
    <div class="ag-theme-quartz">
      <ag-grid-angular
        style="width: 100%; height: 75vh"
        class="grid-height"
        [gridOptions]="gridOptions"
      ></ag-grid-angular>
    </div>
  </app-overlay>

  <app-spinner [display]="loading" [main]="true"></app-spinner>
</ng-container>
