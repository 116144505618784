<ISelect
  [options]="ddOptions"
  [selected]="selectedOption"
  [by]="'id'"
  (selectedChange)="selectOption($event)"
  searchable
>
  <ng-template #optionSlot #labelSlot let-o>
    <div class="flex w-full items-center justify-between truncate">
      <div>
        @if (isStrategyType(o.value)) {
          <span
            [ngStyle]="{ 'background-color': CAMPAIGN_TYPE_COLOR[o.value.campaignType] }"
            class="badge mr-2 shrink-0 rounded-full text-white"
            >{{ o.value.campaignType }}
          </span>
        }
        @if (o.value && !isStrategyType(o.value)) {
          <IBadge color="black" label="PG" class="mr-2" size="xs" />
        }
        <span class="truncate text-sm">{{ o.label }}</span>
      </div>
      @if (isStrategyType(o.value) && o.value.strategyType !== StrategyType.LEGACY) {
        <IBadge color="gray" [label]="StrategyTypeStr[o.value.strategyType]" class="ml-2" size="xs" />
      }
    </div>
  </ng-template>
</ISelect>
