import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { CampaignType } from "@front/m19-api-client";

@Component({
  selector: "app-sp-manager",
  templateUrl: "./sp-manager.component.html",
})
export class SpManagerComponent {
  readonly CampaignType = CampaignType;

  constructor(private router: Router) {}

  createStrategy(): void {
    this.router.navigate(["/advertising/sponsored-product/create"], {
      queryParamsHandling: "merge",
    });
  }
}
