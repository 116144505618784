<ng-container *transloco="let t">
  <div class="ag-theme-quartz flex h-[85%] flex-col">
    @if (loading) {
      <app-spinner [display]="loading"></app-spinner>
    }
    <ag-grid-angular
      [class.preview]="overlayMessage$ | async"
      style="height: 50rem"
      class="w-full flex-auto"
      [gridOptions]="gridOptions"
      [statusBar]="expandedOptions ? STATUS_BAR : null"
      [rowData]="data$ | async"
      [detailRowAutoHeight]="true"
      [animateRows]="true"
    />
  </div>
  <p class="text-xs">{{ t("profit-details-widget.global_fees") }}</p>
  @if (overlayMessage$ | async) {
    <div class="warning-popup">
      <div class="alert alert-primary absolute flex flex-col items-center p-4">
        {{ overlayMessage$ | async }}
      </div>
    </div>
  }
</ng-container>
