import { CogsByAsin } from './cache-utils';
import { emptyOrderStat, orderToOrderStatsInMarketplace, sumOrderStatInPlaceV2 } from './order-stats.utils';
import { marketplaceToCurrencyRate } from './currency.utils';
import { OrderStats } from '@front/m19-models/OrderStats';
import { Currency, Marketplace, Order } from '@front/m19-api-client';

export function buildOrderStats(
  cogsByAsin: CogsByAsin,
  orders: Order[],
  currency: Currency,
  marketplace: Marketplace,
): AggregatedOrderStats {
  const orderStatsByAsin = new Map<string, OrderStats>();
  const orderStatsByDate = new Map<string, OrderStats>();
  const orderStatsByAsinDate = new Map<string, Map<string, OrderStats>>();
  const currencyRate = marketplaceToCurrencyRate(marketplace, currency);

  for (const order of orders) {
    let asinCog = 0;
    if (cogsByAsin.has(order.asin!)) {
      for (const dateCog of cogsByAsin.get(order.asin!)!) {
        const date = dateCog[0];
        // cogs are sorted by date
        if (date > order.day!) break;
        asinCog = dateCog[1];
      }
    }
    asinCog *= currencyRate;
    const orderStat = orderToOrderStatsInMarketplace(order, currency, asinCog, marketplace);

    orderStatsByAsin.set(
      order.asin!,
      sumOrderStatInPlaceV2(
        orderStatsByAsin.get(order.asin!) ?? emptyOrderStat(order.asin!, order.day!, currency),
        orderStat,
      ),
    );

    orderStatsByDate.set(
      order.day!,
      sumOrderStatInPlaceV2(
        orderStatsByDate.get(order.day!) ?? emptyOrderStat(order.asin!, order.day!, currency),
        orderStat,
      ),
    );

    if (!orderStatsByAsinDate.has(order.asin!)) {
      orderStatsByAsinDate.set(order.asin!, new Map());
    }

    orderStatsByAsinDate
      .get(order.asin!)!
      .set(
        order.day!,
        sumOrderStatInPlaceV2(
          orderStatsByAsinDate.get(order.asin!)!.get(order.day!) ?? emptyOrderStat(order.asin!, order.day!, currency),
          orderStat,
        ),
      );
  }

  return { orderStatsByAsin, orderStatsByDate, orderStatsByAsinDate };
}

export interface AggregatedOrderStats {
  orderStatsByAsin: Map<string, OrderStats>;
  orderStatsByDate: Map<string, OrderStats>;
  orderStatsByAsinDate: Map<string, Map<string, OrderStats>>;
}
