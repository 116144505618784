<ng-container *transloco="let t">
  <div class="flex max-h-screen min-h-screen flex-1 flex-col overflow-hidden">
    <header class="fixed top-0 flex flex-col">
      <div class="flex w-full min-w-0 flex-1 items-center justify-between px-4">
        <div class="flex h-14 items-center justify-center"><logo></logo></div>
        <IButton
          [tooltipValue]="t('dashboard360-layout.logout')"
          color="gray"
          variant="ghost"
          icon="icon-[lucide--log-out]"
          (onClick)="logout()"
        />
      </div>
    </header>
    <div class="flex flex-1 flex-col items-center justify-center overflow-auto p-4">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>
