<ng-container *transloco="let t">
  <div class="flex h-full flex-col gap-4">
    @if (ineligibleAsins().length > 0) {
      <IAlert type="warning" [title]="t('sd-page.ineligible_asins', { count: ineligibleAsins().length })">
        <div class="mt-2">
          {{ ineligibleAsins().slice(0, 20).join(", ") }}{{ ineligibleAsins().length > 20 ? ", …" : "" }}
        </div>
      </IAlert>
    }

    @if (missingAsinsInSb()?.length) {
      <IAlert [title]="t('tacos-strategy.missing_asins_in_sb')" type="warning">
        {{ t("tacos-strategy.missing_asins_in_sb_description") }} <br />
        @for (asin of missingAsinsInSb(); track asin) {
          {{ asin + ($last ? "" : ", ") }}
        }
      </IAlert>
    }

    <div class="flex justify-end">
      <IButton
        color="white"
        [icon]="ICON_EDIT_O"
        size="sm"
        [label]="t('product-group-page.edit_products')"
        [disabled]="isReadOnly()"
        (onClick)="openEditAsinsModal()"
      />
    </div>
    <div class="max-h-[30rem] overflow-y-auto">
      <app-strategy-asins
        class="max-h-full"
        [asins]="tacosStrategy().spStrategy?.asins ?? []"
        [isReadOnly]="false"
        [csvExport]="true"
        [accountId]="am().accountId"
        [marketplace]="am().marketplace"
        [selectable]="false"
        [deletable]="false"
        [movable]="false"
        (onDelete)="updateAsins($event, 'remove')"
      />
    </div>
  </div>

  <ng-template #editAsinsModal>
    <div class="relative flex h-[70vh] w-[80vw] flex-col">
      <app-asins-selection
        class="h-full"
        [ngClass]="{ 'pointer-events-none opacity-50': asinEditLoading() }"
        [accountId]="am().accountId"
        [marketplace]="am().marketplace"
        [asins]="tacosStrategy().spStrategy?.asins ?? []"
        [disabledAsins]="unavailableAsins()!"
        (onAdd)="updateAsins($event)"
        (onDelete)="updateAsins($event, 'remove')"
      />
      @if (asinEditLoading()) {
        <div class="absolute inset-0 flex items-center justify-center">
          <app-spinner [display]="true" type="default" />
        </div>
      }
    </div>
  </ng-template>
</ng-container>
