// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    History,
    HistoryComment,
    HistoryKey,
    Marketplace,
    Response,
} from '../models';

export interface GetHistoryRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate?: string;
    maxDate?: string;
    historyKey?: Array<HistoryKey>;
}

export interface GetUserManagementHistoryRequest {
    minDate: string;
    maxDate: string;
}

export interface UpdateHistoryRequest {
    accountId: string;
    marketplace: Marketplace;
    id: number;
    historyComment?: HistoryComment;
}

/**
 * no description
 */
export class HistoryApi extends BaseAPI {

    /**
     * Get history of several entities
     */
    getHistory({ accountId, marketplace, minDate, maxDate, historyKey }: GetHistoryRequest): Observable<Array<History>>
    getHistory({ accountId, marketplace, minDate, maxDate, historyKey }: GetHistoryRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<History>>>
    getHistory({ accountId, marketplace, minDate, maxDate, historyKey }: GetHistoryRequest, opts?: OperationOpts): Observable<Array<History> | AjaxResponse<Array<History>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getHistory');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getHistory');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (minDate != null) { query['minDate'] = minDate; }
        if (maxDate != null) { query['maxDate'] = maxDate; }

        return this.request<Array<History>>({
            url: '/history/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'POST',
            headers,
            query,
            body: historyKey,
        }, opts?.responseOpts);
    };

    /**
     * Get history of user management
     */
    getUserManagementHistory({ minDate, maxDate }: GetUserManagementHistoryRequest): Observable<Array<History>>
    getUserManagementHistory({ minDate, maxDate }: GetUserManagementHistoryRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<History>>>
    getUserManagementHistory({ minDate, maxDate }: GetUserManagementHistoryRequest, opts?: OperationOpts): Observable<Array<History> | AjaxResponse<Array<History>>> {
        throwIfNullOrUndefined(minDate, 'minDate', 'getUserManagementHistory');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getUserManagementHistory');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<History>>({
            url: '/userManagementHistory',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Update the comment for a history entry
     */
    updateHistory({ accountId, marketplace, id, historyComment }: UpdateHistoryRequest): Observable<Response>
    updateHistory({ accountId, marketplace, id, historyComment }: UpdateHistoryRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateHistory({ accountId, marketplace, id, historyComment }: UpdateHistoryRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'updateHistory');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'updateHistory');
        throwIfNullOrUndefined(id, 'id', 'updateHistory');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Response>({
            url: '/historyComment/{accountId}/{marketplace}/{id}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{id}', encodeURI(id)),
            method: 'POST',
            headers,
            body: historyComment,
        }, opts?.responseOpts);
    };

}
