import { Marketplace } from "@front/m19-api-client";

const supportedMarketplaces = new Set([
  Marketplace.AE,
  Marketplace.AU,
  Marketplace.BE,
  Marketplace.BR,
  Marketplace.CA,
  Marketplace.DE,
  Marketplace.EG,
  Marketplace.ES,
  Marketplace.FR,
  Marketplace.IT,
  Marketplace.IN,
  Marketplace.JP,
  Marketplace.MX,
  Marketplace.NL,
  Marketplace.PL,
  Marketplace.SA,
  Marketplace.SE,
  Marketplace.SG,
  Marketplace.TR,
  Marketplace.UK,
  Marketplace.US,
]);

export function isSDAvailable(marketplace: Marketplace) {
  return supportedMarketplaces.has(marketplace);
}
