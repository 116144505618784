// common icons
// from https://icon-sets.iconify.design/material-symbols/
// from https://icon-sets.iconify.design/mdi/
// suffix 'O' means outlined
export const ICON_ADD = "icon-[material-symbols--add-rounded]";
export const ICON_ADD_CIRCLE = "icon-[mdi--add-circle]";
export const ICON_TRASH_O = "icon-[material-symbols--delete-outline-rounded]";
export const ICON_EDIT_O = "icon-[material-symbols--edit-square-outline]";
export const ICON_STAR_O = "icon-[mdi--star-outline]";
export const ICON_PLAY = "icon-[material-symbols--play-circle-rounded]";
export const ICON_PAUSE = "icon-[material-symbols--pause-circle-rounded]";
export const ICON_COPY = "icon-[material-symbols--content-copy-rounded]";
export const ICON_COPY_O = "icon-[material-symbols--content-copy-outline-sharp]";
export const ICON_LINK = "icon-[material-symbols--arrow-outward-rounded]";
export const ICON_360 = "icon-[material-symbols--360]";
export const ICON_CHART_LINE = "icon-[mdi--chart-line]";
export const ICON_CLOSE = "icon-[mdi--close]";
export const ICON_EXPORT = "icon-[mdi--export]";
export const ICON_IMPORT = "icon-[mdi--import]";
export const ICON_UPLOAD = "icon-[material-symbols--upload-rounded]";
export const ICON_LIST = "icon-[mdi--format-list-bulleted]";
export const ICON_CHEVRON_DOWN = "icon-[mdi--chevron-down]";
export const ICON_USER_KEY = "icon-[mdi--user-key]";
export const ICON_GEAR = "icon-[mdi--cog]";
export const ICON_ARROW_RIGHT = "icon-[mdi--arrow-right]";
export const ICON_ARROW_LEFT = "icon-[mdi--arrow-left]";
export const ICON_SEARCH = "icon-[mdi--magnify]";
export const ICON_WARNING_TRIANGLE = "icon-[mdi--warning]";
export const ICON_EYE = "icon-[mdi--eye]";
export const ICON_CHECK = "icon-[mdi--check]";
export const ICON_QUESTION = "icon-[mdi--help-circle]";
export const ICON_BELL = "icon-[mdi--bell]";
export const ICON_USER = "icon-[mdi--account]";
export const ICON_EMAIL = "icon-[mdi--email]";
export const ICON_SPARK = "icon-[mdi--sparkles]";
export const ICON_WRENCH = "icon-[mdi--wrench]";
export const ICON_SYNC = "icon-[mdi--sync]";
export const ICON_LOGOUT = "icon-[mdi--logout]";
export const ICON_BOOST = "icon-[mdi--lightning-bolt]";
