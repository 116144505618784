import { AdStatsEx } from '@front/m19-models/AdStatsEx';
import { CurrencyStat } from '@front/m19-models/CurrencyStat';
import { addAdStats, emptyAdStatEx } from './statsUtils';
import { AdStats } from '@front/m19-api-client';

export enum GroupBy {
  AsinDate,
}

export const groupByAsinDate = (adStats: AdStatsEx): string => {
  return `${adStats.asin}_${adStats.date}`;
};

export const groupers: Record<GroupBy, (stat: CurrencyStat) => string> = {
  [GroupBy.AsinDate]: groupByAsinDate,
};

export function mergeData(data: Array<AdStats[]>, groupBy: GroupBy): AdStats[] {
  const mergedData: Map<string, AdStats> = new Map();
  const getHash = groupers[groupBy];

  data.forEach((adStats) => {
    adStats.forEach((adStat) => {
      const hash = getHash(adStat);
      mergedData.set(hash, addAdStats(adStat, mergedData.get(hash) ?? emptyAdStatEx()));
    });
  });

  return Array.from(mergedData.values());
}
