// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    DspDeliveryStatus,
} from './';

/**
 * @export
 * @interface DspAdgroup
 */
export interface DspAdgroup {
    /**
     * @type {string}
     * @memberof DspAdgroup
     */
    adGroupId?: string;
    /**
     * @type {string}
     * @memberof DspAdgroup
     */
    campaignId?: string;
    /**
     * @type {string}
     * @memberof DspAdgroup
     */
    name?: string;
    /**
     * @type {string}
     * @memberof DspAdgroup
     */
    state?: DspAdgroupStateEnum;
    /**
     * @type {DspDeliveryStatus}
     * @memberof DspAdgroup
     */
    deliveryStatus?: DspDeliveryStatus;
}

/**
 * @export
 * @enum {string}
 */
export enum DspAdgroupStateEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

