import { Component, Input } from "@angular/core";
import { faDollarSign, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { BillingService } from "@front/m19-services";
import { ToastrService } from "ngx-toastr";
import { AjaxError } from "rxjs/ajax";
import { CreditCard, Customer } from "@front/m19-api-client";

@Component({
  selector: "app-credit-card-info",
  templateUrl: "./credit-card-info.component.html",
  styleUrls: ["./credit-card-info.component.scss"],
})
export class CreditCardInfoComponent {
  @Input()
  customer: Customer;
  readonly faDollar = faDollarSign;
  readonly faTrash = faTrashAlt;
  readonly CreditCardBrands = CreditCardBrands;

  constructor(
    private billingService: BillingService,
    private toasterService: ToastrService,
  ) {}

  hasACreditCard(): boolean {
    return Boolean(this.customer) && Boolean(this.customer.creditCards);
  }

  deleteCreditCard(creditCard: CreditCard) {
    const sub = this.billingService.removeCreditCard(this.customer, creditCard.id).subscribe({
      complete: () => {
        this.toasterService.success("Credit card deleted");
        sub.unsubscribe();
      },
      error: (error: AjaxError) => {
        this.toasterService.error(error.response ? error.response.message : "Unknown error", error.name);
        sub.unsubscribe();
      },
    });
  }

  setAsDefaultCreditCard(creditCard: CreditCard) {
    this.billingService.linkCreditCard(
      this.customer,
      creditCard.id,
      () => {
        this.toasterService.success("Default credit card successfully changed");
      },
      (error: AjaxError) => {
        this.toasterService.error(error.response ? error.response.message : "Unknown error", error.name);
      },
    );
  }
}

export const CreditCardBrands = {
  amex: {
    name: "American Express",
    icon: "assets/img/credit_cards/amex.png",
  },
  "American Express": {
    name: "American Express",
    icon: "assets/img/credit_cards/amex.png",
  },
  visa: {
    name: "Visa",
    icon: "assets/img/credit_cards/visa.png",
  },
  mastercard: {
    name: "Mastercard",
    icon: "assets/img/credit_cards/mastercard.png",
  },
  cartes_bancaires: {
    name: "Cartes Bancaires",
    icon: "assets/img/credit_cards/cb.svg",
  },
};
