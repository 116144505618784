import { formatCurrency, formatPercent } from "@angular/common";
import { Injectable } from "@angular/core";
import {
  AccountMarketplace,
  AccountState,
  AlgoMode,
  AudienceTargeting,
  CampaignType,
  Currency,
  EntityIdType,
  EntityType,
  History,
  HistoryActionEnum,
  HistoryApi,
  HistoryKey,
  Marketplace,
  SbCreative,
  Segment,
  State,
  Strategy,
  StrategyGroup,
  StrategyStateEnum,
  StrategyTactic,
  TacticType,
  User,
} from "@front/m19-api-client";
import { I18nService } from "@m19-board/services/i18n.service";
import {
  Currencies,
  IntensityDesc,
  Marketplaces,
  SbCreativeBrandAssets,
  SegmentEx,
  SegmentTypeDec,
  StrategyEx,
  SupportedAudienceMatchType,
} from "@front/m19-models";
import {
  AccountSelectionService,
  AuthService,
  DataSetEventAnnotation,
  UserSelectionService,
} from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { TranslocoService } from "@jsverse/transloco";
import { SbStrategiesService } from "libs/m19-services/src/lib/m19-services/sb-strategies.service";
import { SegmentService } from "libs/m19-services/src/lib/m19-services/segmentService";
import { StrategyCache } from "libs/m19-services/src/lib/m19-services/strategy.cache";
import moment from "moment-timezone";
import { BehaviorSubject, combineLatest, map, Observable, of, ReplaySubject, shareReplay, switchMap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ActivityService {
  public readonly allActivityEventTypes: ActivityEventType[];
  public readonly allActivityEventTypesOptions: Option<ActivityEventType>[] = [];

  public readonly activityEventsAnnotations$: Observable<DataSetEventAnnotation[]>;

  private readonly selectedActivityTypesSubject$: BehaviorSubject<ActivityEventType[]>;
  public readonly selectedActivityTypes$: Observable<ActivityEventType[]>;
  public readonly selectedActivityTypesOptions$: Observable<Option<ActivityEventType>[]>;

  public readonly allUsers$: Observable<string[]>;
  private readonly selectedUsersSubject$: ReplaySubject<string[]> = new ReplaySubject(1);
  public readonly selectedUsers$: Observable<string[]> = this.selectedUsersSubject$.asObservable();

  public readonly allStrategies$: Observable<StrategyEx[]>;
  private readonly selectedStrategiesSubject$: BehaviorSubject<StrategyEx[]>;
  public readonly selectedStrategies$: Observable<StrategyEx[]>;

  // used for dropdowns
  public readonly allStrategiesOptions$: Observable<Option<StrategyEx>[]>;
  public readonly selectedStrategiesOptions$: Observable<Option<StrategyEx>[]>;

  public readonly allUsersOptions$: Observable<Option<string>[]>;
  public readonly selectedUsersOptions$: Observable<Option<string>[]>;

  private segmentIndex: Map<number, SegmentEx> = new Map();
  private brandAssetsIndex: Map<number, SbCreativeBrandAssets> = new Map();

  constructor(
    private historyService: HistoryApi,
    private userSelectionService: UserSelectionService,
    private authService: AuthService,
    private strategyCache: StrategyCache,
    private segmentService: SegmentService,
    private sbStrategieService: SbStrategiesService,
    private accountSelectionService: AccountSelectionService,
    private translocoService: TranslocoService,
    private i18nService: I18nService,
  ) {
    // build activity types
    this.allActivityEventTypes = [];
    for (const entityType of [
      EntityType.Strategy,
      EntityType.Tactic,
      EntityType.SbCreative,
      EntityType.AudienceTargeting,
      EntityType.StrategyGroup,
      EntityType.AccountMarketplace,
    ]) {
      this.allActivityEventTypes.push({
        title: this.translocoService.translate(EntityTypeFormats[entityType] + "_creation"),
        match: (h) => h.entityType == entityType && h.action == HistoryActionEnum.create,
      });
      this.allActivityEventTypes.push({
        title: this.translocoService.translate(EntityTypeFormats[entityType] + "_creation"),
        match: (h) => h.entityType == entityType && h.action == HistoryActionEnum.delete,
      });
      let formatter;
      switch (entityType) {
        case EntityType.Strategy:
          formatter = StrategyPropertyFormatters;
          break;
        case EntityType.Tactic:
          formatter = TacticPropertyFormatters;
          break;
        case EntityType.AudienceTargeting:
          formatter = AudienceTargetingPropertyFormatters;
          break;
        case EntityType.SbCreative:
          formatter = CreativePropertyFormatters;
          break;
        case EntityType.StrategyGroup:
          formatter = StrategyGroupPropertyFormatters;
          break;
        case EntityType.AccountMarketplace:
          formatter = AccountMarketplacePropertyFormatters;
          break;
      }
      for (const prop in formatter.propertyFormatters) {
        if (formatter.propertyFormatters[prop].propertyFormat) {
          const propertyFormatter = formatter.propertyFormatters[prop];
          // check legacy alias
          const legacyAliases = [];
          for (const alias in formatter.propertyFormatters) {
            if (formatter.propertyFormatters[alias] == prop) {
              legacyAliases.push(alias);
            }
          }
          if (legacyAliases.length > 0) {
            this.allActivityEventTypes.push({
              title:
                this.translocoService.translate(EntityTypeFormats[entityType] + "_update") +
                ": " +
                this.translocoService.translate(propertyFormatter.propertyFormat),
              match: (h) =>
                h.action == HistoryActionEnum.update && (h.property == prop || legacyAliases.includes(h.property)),
            });
          } else {
            this.allActivityEventTypes.push({
              title:
                this.translocoService.translate(EntityTypeFormats[entityType] + "_update") +
                ": " +
                this.translocoService.translate(propertyFormatter.propertyFormat),
              match: (h) => h.property == prop && h.action == HistoryActionEnum.update,
            });
          }
        }
      }
      this.allActivityEventTypesOptions = this.allActivityEventTypes.map((t) => ({ label: t.title, value: t }));
    }

    this.selectedActivityTypesSubject$ = new BehaviorSubject(this.allActivityEventTypes);
    this.selectedActivityTypes$ = this.selectedActivityTypesSubject$.asObservable();
    this.selectedActivityTypesOptions$ = this.selectedActivityTypes$.pipe(
      map((allTypes) => allTypes.map((t) => ({ label: t.title, value: t }))),
    );

    const strategyHistory$: Observable<History[]> = combineLatest<[AccountMarketplace[], string[]]>([
      this.accountSelectionService.accountMarketplacesSelection$,
      this.userSelectionService.dateRange$.pipe(map((dateRange) => [dateRange[0], dateRange[1]] as [string, string])),
    ]).pipe(
      switchMap(([accountMarketplace, dateRange]) => {
        if (accountMarketplace.length != 1) {
          return [];
        }
        return this.historyService.getHistory({
          accountId: accountMarketplace[0].accountId,
          marketplace: accountMarketplace[0].marketplace,
          minDate: dateRange[0],
          maxDate: dateRange[1],
          historyKey: [{ primaryType: EntityIdType.strategyId }, { primaryType: EntityIdType.creativeId }],
        }) as Observable<History[]>;
      }),
      shareReplay(1),
    );

    this.allStrategies$ = this.strategyCache.strategyIndex$.pipe(
      map((config: Map<number, StrategyEx>) => Array.from(config.values())),
    );

    this.selectedStrategiesSubject$ = new BehaviorSubject<StrategyEx[]>([]);
    this.selectedStrategies$ = this.selectedStrategiesSubject$.asObservable();
    this.allStrategies$.subscribe((allStrategies) => this.selectedStrategiesSubject$.next(allStrategies));

    this.allStrategiesOptions$ = this.allStrategies$.pipe(
      map((all) => all.map((s: StrategyEx) => ({ label: s.name!, value: s }))),
    );

    this.selectedStrategiesOptions$ = this.selectedStrategies$.pipe(
      map((s) => s.map((strat) => ({ label: strat.name!, value: strat }))),
    );

    this.allUsers$ = strategyHistory$.pipe(
      map((history) => {
        const users = new Set<string>();
        for (const h of history) {
          if (h.userName) {
            users.add(h.userName);
          }
        }
        return Array.from(users.values());
      }),
      shareReplay(1),
    );
    this.allUsers$.subscribe((allUsers) => this.selectedUsersSubject$.next(allUsers));

    this.allUsersOptions$ = this.allUsers$.pipe(
      map((allUsers) => allUsers.map((u) => ({ label: u, value: u }) as Option<string>)),
    );

    this.selectedUsersOptions$ = this.selectedUsers$.pipe(
      map((selected) => selected.map((u) => ({ label: u, value: u }))),
    );

    this.activityEventsAnnotations$ = this.buildStrategyActivityEventAnnotation(strategyHistory$);
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(switchMap((am) => this.segmentService.getSegments(am.accountId, am.marketplace)))
      .subscribe((segmentIndex: Map<number, SegmentEx>) => {
        this.segmentIndex = segmentIndex;
      });
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(switchMap((am) => this.sbStrategieService.getSbCreativeBrandAssets(am.accountId, am.marketplace)))
      .subscribe((brandAssetsIndex) => {
        this.brandAssetsIndex = brandAssetsIndex;
      });
  }

  public getStrategyActivityFilter(strategy: StrategyEx): HistoryKey[] {
    const filter = [
      {
        primaryType: EntityIdType.strategyId,
        primaryId: strategy.strategyId,
      },
    ];
    if (strategy.campaignType == CampaignType.SB) {
      filter.push(
        ...strategy.sbCreatives.map((c) => ({
          primaryType: EntityIdType.creativeId,
          primaryId: c.creativeId,
        })),
      );
    }
    if (strategy.campaignType == CampaignType.SD) {
      filter.push(
        ...strategy.audienceTargetings.map((a) => ({
          primaryType: EntityIdType.audienceTargetId,
          primaryId: a.audienceTargetId!,
        })),
      );
    }
    return filter;
  }

  public getStrategiesActivityEventAnnotation(accountId: string, marketplace: Marketplace, strategies?: StrategyEx[]) {
    if (!strategies) {
      return this.buildStrategyActivityEventAnnotation(
        this.userSelectionService.dateRange$.pipe(
          switchMap(([minDate, maxDate]) =>
            this.historyService.getHistory({
              accountId: accountId,
              marketplace: marketplace,
              minDate: minDate,
              maxDate: maxDate,
              historyKey: [{ primaryType: EntityIdType.strategyId }],
            }),
          ),
        ),
      );
    }

    if (strategies.length == 0) {
      return of([]);
    }
    const activityFilters = strategies.flatMap((strategy) => this.getStrategyActivityFilter(strategy));

    return this.buildStrategyActivityEventAnnotation(
      this.userSelectionService.dateRange$.pipe(
        switchMap(([minDate, maxDate]) =>
          this.historyService.getHistory({
            accountId: accountId,
            marketplace: marketplace,
            minDate: minDate,
            maxDate: maxDate,
            historyKey: activityFilters,
          }),
        ),
        shareReplay(1),
      ),
    );
  }

  public getStrategyActivityEventAnnotation(accountId: string, marketplace: Marketplace, strategy: StrategyEx) {
    return this.getStrategiesActivityEventAnnotation(accountId, marketplace, [strategy]);
  }

  private buildStrategyActivityEventAnnotation(history$: Observable<History[]>) {
    return combineLatest<
      [
        History[],
        ActivityEventType[],
        string[],
        StrategyEx[],
        User,
        AccountMarketplace,
        Map<number, StrategyEx>,
        Map<number, SbCreative>,
      ]
    >([
      history$,
      this.selectedActivityTypes$,
      this.selectedUsers$,
      this.selectedStrategies$,
      this.authService.loggedUser$,
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      this.strategyCache.strategyIndex$,
      this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(
        switchMap((am) => this.sbStrategieService.getSbCreativesIndex(am.accountId, am.marketplace)),
      ),
    ]).pipe(
      map(
        ([history, selectedActivityTypes, userNames, selectedStrategies, user, am, strategyIndex, sbCreativeIndex]) => {
          const currency = Marketplaces[am.marketplace].currency;
          const eventsByDate = new Map<string, string[]>();
          const dataSetAnnotations: DataSetEventAnnotation[] = [];
          for (const historyEvent of history) {
            // only display strategy, tactic or creative activity event annotations
            if (
              historyEvent.entityType !== EntityType.Strategy &&
              historyEvent.entityType !== EntityType.Tactic &&
              historyEvent.entityType !== EntityType.SbCreative &&
              historyEvent.entityType !== EntityType.AudienceTargeting
            ) {
              continue;
            }

            // check if strategy events are selected
            const strategyId =
              historyEvent.entityType == EntityType.SbCreative
                ? sbCreativeIndex.get(historyEvent.primaryId)?.strategyId
                : historyEvent.primaryId;
            if (!selectedStrategies.some((s) => s.strategyId === strategyId)) continue;

            if (!userNames.includes(historyEvent.userName)) {
              // only display events for the selected user names
              continue;
            }
            // only display selected events
            let match = false;
            for (const eventType of selectedActivityTypes) {
              if (eventType.match(historyEvent)) {
                match = true;
                break;
              }
            }
            if (!match) {
              continue;
            }

            const dateStr = moment.utc(historyEvent.timestamp).startOf("date").format("YYYY-MM-DD");

            let label: string;
            if (historyEvent.action == HistoryActionEnum.create) {
              label =
                this.translocoService.translate(EntityTypeFormats[historyEvent.entityType]) +
                ` ${this.translocoService.translate("activity-service.creation")} - ` +
                this.formatHistoryValue(historyEvent, "newValue", user.locale, currency);
            } else if (historyEvent.action == HistoryActionEnum.delete) {
              label =
                this.translocoService.translate(EntityTypeFormats[historyEvent.entityType]) +
                ` ${this.translocoService.translate("activity-service.deletion")} - ${this.formatHistoryValue(historyEvent, "oldValue", user.locale, currency)}`;
            } else {
              const strategyId =
                historyEvent.entityType == EntityType.SbCreative
                  ? sbCreativeIndex.get(historyEvent.primaryId)?.strategyId
                  : historyEvent.primaryId;
              const strategyName = strategyIndex.get(strategyId)?.getName() ?? "Unknown";
              label =
                this.translocoService.translate(EntityTypeFormats[historyEvent.entityType] + "_update") +
                ` update in "${strategyName}" - ${this.translocoService.translate(this.formatHistoryProperty(historyEvent))} changes ${
                  historyEvent.oldValue
                    ? " from " +
                      this.i18nService.translateIfExist(
                        this.formatHistoryValue(historyEvent, "oldValue", user.locale, currency),
                      )
                    : ""
                } to ${this.i18nService.translateIfExist(this.formatHistoryValue(historyEvent, "newValue", user.locale, currency))}`;
            }
            if (eventsByDate.has(dateStr)) {
              eventsByDate.get(dateStr).push(label);
            } else {
              eventsByDate.set(dateStr, [label]);
            }
          }
          for (const [date, label] of eventsByDate.entries()) {
            dataSetAnnotations.push({
              color: "#F5EAEA",
              label: label,
              date: new Date(date),
            });
          }
          return dataSetAnnotations;
        },
      ),
    );
  }

  public selectActivityEventAnnotationTypes(selectedActivityTypes: ActivityEventType[]) {
    this.selectedActivityTypesSubject$.next(selectedActivityTypes);
  }

  public selectUsers(users: string[]) {
    this.selectedUsersSubject$.next(users);
  }

  public selectStrategies(strategies: StrategyEx[]) {
    this.selectedStrategiesSubject$.next(strategies);
  }

  public formatHistoryProperty(h?: History): string {
    if (!h) return "";

    if (!h.property) {
      switch (h.action) {
        case "create":
          return this.translocoService.translate(EntityTypeFormats[h.entityType] + "_creation");
        case "delete":
          return this.translocoService.translate(EntityTypeFormats[h.entityType] + "_deletion");
      }
      return "";
    }
    if (h.entityType == EntityType.Strategy) {
      const formatter = getPropertyFormatter(h.property as string, StrategyPropertyFormatters);
      if (formatter && formatter.propertyFormat) {
        return formatter.propertyFormat;
      }
    } else if (h.entityType == EntityType.Tactic) {
      const formatter = getPropertyFormatter(h.property as string, TacticPropertyFormatters);
      if (formatter && formatter.propertyFormat) {
        return formatter.propertyFormat;
      }
    } else if (h.entityType == EntityType.Segment) {
      const formatter = getPropertyFormatter(h.property as string, SegmentPropertyFormatters);
      if (formatter && formatter.propertyFormat) {
        return formatter.propertyFormat;
      }
    } else if (h.entityType == EntityType.SbCreative) {
      const formatter = getPropertyFormatter(h.property as string, CreativePropertyFormatters);
      if (formatter && formatter.propertyFormat) {
        return formatter.propertyFormat;
      }
    } else if (h.entityType == EntityType.StrategyGroup) {
      const formatter = getPropertyFormatter(h.property as string, StrategyGroupPropertyFormatters);
      if (formatter && formatter.propertyFormat) {
        return formatter.propertyFormat;
      }
    } else if (h.entityType == EntityType.AudienceTargeting) {
      const formatter = getPropertyFormatter(h.property as string, AudienceTargetingPropertyFormatters);
      if (formatter && formatter.propertyFormat) {
        return formatter.propertyFormat;
      }
    } else if (h.entityType == EntityType.AccountMarketplace) {
      const formatter = getPropertyFormatter(h.property as string, AccountMarketplacePropertyFormatters);
      if (formatter && formatter.propertyFormat) {
        return formatter.propertyFormat;
      }
    }
    return h.property;
  }

  public formatHistoryValue(
    h: History | undefined,
    valGetter: "oldValue" | "newValue",
    locale: string,
    currency: Currency,
  ): string {
    if (!h) return "";

    const value = h[valGetter];
    const oldValue = h["oldValue"];
    if (!h.property && value) {
      // TODO: factorize this code
      if (h.entityType == EntityType.Strategy) {
        try {
          const entity: Strategy = JSON.parse(value);
          return StrategyPropertyFormatters.entityFormatter(entity, { locale, currency, oldValue });
        } catch (ex) {
          // invalid JSON
        }
      } else if (h.entityType == EntityType.Tactic) {
        try {
          const entity: StrategyTactic = JSON.parse(value);

          return TacticPropertyFormatters.entityFormatter(entity, { segment: this.segmentIndex.get(entity.segmentId) });
        } catch (ex) {
          // invalid JSON
        }
      } else if (h.entityType == EntityType.Segment) {
        try {
          const entity: Segment = JSON.parse(value);
          return SegmentPropertyFormatters.entityFormatter(entity, {
            segment: this.segmentIndex.get(entity.segmentId),
          });
        } catch (ex) {
          // invalid JSON
        }
      } else if (h.entityType == EntityType.SbCreative) {
        try {
          const entity: SbCreative = JSON.parse(value);
          return CreativePropertyFormatters.entityFormatter(entity, {
            brandAssets: this.brandAssetsIndex.get(entity.creativeId) ?? null,
          });
        } catch (ex) {
          // invalid JSON
        }
      } else if (h.entityType == EntityType.StrategyGroup) {
        try {
          const entity: StrategyGroup = JSON.parse(value);
          return StrategyGroupPropertyFormatters.entityFormatter(entity, {
            strategyGroup: entity,
          });
        } catch (ex) {
          // invalid JSON
        }
      } else if (h.entityType == EntityType.AudienceTargeting) {
        try {
          const entity: AudienceTargeting = JSON.parse(value);
          return AudienceTargetingPropertyFormatters.entityFormatter(entity, {});
        } catch (ex) {
          // invalid JSON
        }
      } else if (h.entityType == EntityType.AccountMarketplace) {
        try {
          const entity: AccountMarketplaceEntity = JSON.parse(value);
          return AccountMarketplacePropertyFormatters.entityFormatter(entity, { locale, marketplace: h.marketplace });
        } catch (ex) {
          // invalid JSON
        }
      }
      return value;
    }

    // TODO: factorize this code
    if (h.entityType == EntityType.Strategy) {
      const formatter = getPropertyFormatter(h.property as string, StrategyPropertyFormatters);
      if (formatter && formatter.valueFormat) {
        return formatter.valueFormat(value, { locale, currency, oldValue });
      }
    } else if (h.entityType == EntityType.Tactic) {
      const formatter = getPropertyFormatter(h.property as string, TacticPropertyFormatters);
      if (formatter && formatter.valueFormat) {
        return formatter.valueFormat(value, { segment: null });
      }
    } else if (h.entityType == EntityType.Segment) {
      const formatter = getPropertyFormatter(h.property as string, SegmentPropertyFormatters);
      if (formatter && formatter.valueFormat) {
        return formatter.valueFormat(value, { segment: null });
      }
    } else if (h.entityType == EntityType.SbCreative) {
      const formatter = getPropertyFormatter(h.property as string, CreativePropertyFormatters);
      if (formatter && formatter.valueFormat) {
        return formatter.valueFormat(value, { brandAssets: null });
      }
    } else if (h.entityType == EntityType.StrategyGroup) {
      const formatter = getPropertyFormatter(h.property as string, StrategyGroupPropertyFormatters);
      if (formatter && formatter.valueFormat) {
        return formatter.valueFormat(value, { strategyGroup: null });
      }
    } else if (h.entityType == EntityType.AudienceTargeting) {
      const formatter = getPropertyFormatter(h.property as string, AudienceTargetingPropertyFormatters);
      if (formatter && formatter.valueFormat) {
        return formatter.valueFormat(value, undefined);
      }
    } else if (h.entityType == EntityType.AccountMarketplace) {
      const formatter = getPropertyFormatter(h.property as string, AccountMarketplacePropertyFormatters);
      if (formatter && formatter.valueFormat) {
        return formatter.valueFormat(value, { locale, marketplace: h.marketplace });
      }
    }
    return value ?? "";
  }
}

type EntityTypeFormat = {
  [key in EntityType]: string;
};

export const EntityTypeFormats: EntityTypeFormat = {
  AuthorizationAccess: "activity-service.authorization_access",
  OrganizationAdmin: "activity-service.organization_admin",
  Strategy: "activity-service.strategy",
  SbCreative: "activity-service.creative",
  ProductGroup: "activity-service.product_group",
  Tactic: "activity-service.strategy_tactic",
  User: "activity-service.user",
  Account: "activity-service.account",
  AmazonUser: "activity-service.amazon_user",
  AccountMarketplace: "activity-service.account_marketplace",
  BillingAccountMarketplace: "activity-service.billing_account_marketplace",
  Segment: "activity-service.segment",
  Organization: "activity-service.organization",
  StrategyGroup: "activity-service.strategy_group",
  AudienceTargeting: "activity-service.remarketing_targeting",
};

type PropertyFormatter<T, P> = {
  propertyFormat: string;
  valueFormat: (value: T, params: P) => string | undefined;
};

function noOpFormater<T, P>(): PropertyFormatter<T, P> {
  return {
    propertyFormat: "",
    valueFormat: () => "",
  };
}

function getPropertyFormatter<T, P, L extends string | symbol, V>(
  property: string,
  formatters: EntityPropertyFormatter<T, P, L>,
): PropertyFormatter<V, P> {
  let formatter = formatters.propertyFormatters[property] as keyof T | PropertyFormatter<V, P>;
  if (typeof formatter == "string") {
    // alias
    formatter = formatters.propertyFormatters[formatter];
  }
  return formatter as PropertyFormatter<V, P>;
}

type EntityPropertyFormatter<T, P, LegacyAlias extends string | symbol> = {
  entityFormatter: (entity: T, params: P) => string;
  propertyFormatters: {
    [key in keyof Required<T>]: PropertyFormatter<T[key], P>;
    // legacy fields still present in history table
  } & Record<LegacyAlias, keyof T>;
};

const StrategyPropertyFormatters: EntityPropertyFormatter<
  Strategy,
  { locale: string; currency: Currency; oldValue: any },
  "minDailyBudget" | "biddingType"
> = {
  entityFormatter: (entity, params) => {
    const result = `[${entity.campaignType}] ${entity.name}`;
    switch (entity.algoMode) {
      case AlgoMode.PRODUCT_LAUNCH:
        return (
          result +
          " - Suggested Bid: " +
          formatCurrency(
            entity.suggestedBid,
            params.locale,
            Currencies[params.currency].currencySymbol,
            params.currency,
            "1.2-2",
          )
        );
      case AlgoMode.MONTHLY_BUDGET_TARGET:
        return (
          result +
          " - Monthly Budget: " +
          formatCurrency(
            entity.monthlyBudget,
            params.locale,
            Currencies[params.currency].currencySymbol,
            params.currency,
            "1.0-0",
          )
        );
      case AlgoMode.ACOS_TARGET:
        return result + " - ACOS Target: " + formatPercent(entity.acosTarget, params.locale, "1.0-0");
      default:
        // this can be called for legacy SALES_AMOUNT algorithm
        return result;
    }
  },
  propertyFormatters: {
    acosTarget: {
      propertyFormat: "algo-mode-selection.acos_target",

      valueFormat: (v, params) => formatPercent(v, params.locale, "1.0-0"),
    },
    state: {
      propertyFormat: "billing-customer.state",
      valueFormat: (v) => (v == StrategyStateEnum.PAUSED ? "strategy-page.paused" : "activity-service.enabled"),
    },
    algoMode: {
      propertyFormat: "sd-strategy-creation.algorithm",
      valueFormat: (v) => StrategyEx.getAlgoModeStrShort(v),
    },
    suggestedBid: {
      propertyFormat: "activity-service.suggested_bid",
      valueFormat: (v, { locale, currency }) =>
        formatCurrency(v, locale, Currencies[currency].currencySymbol, currency, "1.2-2"),
    },
    name: {
      propertyFormat: "common.name",
      valueFormat: (v) => v,
    },
    dailyBudget: {
      propertyFormat: "metrics.DAILY_BUDGET_title",
      valueFormat: (v, { locale, currency }) =>
        formatCurrency(v, locale, Currencies[currency].currencySymbol, currency, "1.0-0"),
    },
    monthlyBudget: {
      propertyFormat: "overview-grid.monthly_budget",
      valueFormat: (v, { locale, currency }) =>
        formatCurrency(v, locale, Currencies[currency].currencySymbol, currency, "1.0-0"),
    },
    nextMonthlyBudget: {
      propertyFormat: "activity-service.next_monthly_budget",
      valueFormat: (v, { locale, currency }) =>
        formatCurrency(v, locale, Currencies[currency].currencySymbol, currency, "1.0-0"),
    },
    minDailySpend: {
      propertyFormat: "metrics.MIN_DAILY_SPEND_title",
      valueFormat: (v, { locale, currency }) =>
        formatCurrency(v, locale, Currencies[currency].currencySymbol, currency, "1.0-0"),
    },

    disableAutoSegment: {
      propertyFormat: "advanced-settings-modal.automatic_targeting_campaign",
      valueFormat: (v) => {
        // in history table, value could be stored as a string 0/1 or false/true
        if (v == false || (v as unknown) == "false" || (v as unknown) == "0") {
          // disableAutoSegment false means "Automatic targeting campaign" is ON
          return "switch-input.on";
        }
        return "switch-input.off";
      },
    },
    disableProductSegment: {
      propertyFormat: "advanced-settings-modal.product_targeting",
      valueFormat: (v) => {
        // in history table, value could be stored as a string...
        if (v == false || (v as unknown) == "false" || (v as unknown) == "0") {
          // disableProductSegment false means "Automatic targeting campaign" is ON
          return "switch-input.on";
        }
        return "switch-input.off";
      },
    },
    disableOtherQueries: {
      propertyFormat: "advanced-settings-modal.ai-powered_targeting",
      valueFormat: (v) => {
        // in history table, value could be stored as a string...
        if (v == false || (v as unknown) == "false" || (v as unknown) == "0") {
          // disableOtherQueries false means "Automatic targeting campaign" is ON
          return "switch-input.on";
        }
        return "switch-input.off";
      },
    },
    daypartingPauseHour: {
      propertyFormat: "activity-service.dayparting_start_hour",
      valueFormat: (v) => {
        if (!v) {
          return "";
        }
        return `${v}:00`;
      },
    },
    daypartingReactivationHour: {
      propertyFormat: "activity-service.dayparting_reactivation_hour",
      valueFormat: (v) => {
        if (!v) {
          return "";
        }
        return `${v}:00`;
      },
    },
    primeDayBoost: {
      propertyFormat: "activity-service.promo_day_boost",
      valueFormat: (v, params) => formatPercent(v / 100, params.locale, "1.0-0"),
    },
    strategyGroupId: {
      propertyFormat: "activity-service.strategy_group_migration",
      valueFormat: (v) => "",
    },
    priority: {
      propertyFormat: "activity-service.priority",
      valueFormat: (v, { oldValue }) => {
        const evolution = v - oldValue;
        if (evolution == 0) {
          return "-";
        }
        if (evolution > 0) {
          return "⬇️";
        }
        return "⬆️";
      },
    },
    // legacy alias
    minDailyBudget: "minDailySpend",
    biddingType: "algoMode",
    // no history on these properties
    audienceTargetings: noOpFormater(),
    campaignType: noOpFormater(),
    strategyLabel: noOpFormater(),
    defaultStrategy: noOpFormater(),
    accountId: noOpFormater(),
    marketplace: noOpFormater(),
    strategyId: noOpFormater(),
    asins: noOpFormater(),
    topOfSearchRankings: noOpFormater(),
    tactics: noOpFormater(),
    brandEntityId: noOpFormater(),
    constraint: noOpFormater(),
    date: noOpFormater(),
    strategyType: noOpFormater(),
    targetings: noOpFormater(),
    today: noOpFormater(),
    computedDailyBudget: noOpFormater(),
    asinIsolation: noOpFormater(),
  },
};

const TacticPropertyFormatters: EntityPropertyFormatter<StrategyTactic, { segment: SegmentEx }, never> = {
  entityFormatter: (entity, { segment }) => {
    return `[${entity.tacticType == TacticType.BLACKLIST ? "Blacklist " : ""}${
      segment ? SegmentTypeDec[segment.segmentType] : "deleted segment"
    }] ${segment?.name ?? ""} - Intensity: ${IntensityDesc[entity.intensity]}`;
  },
  propertyFormatters: {
    segmentId: noOpFormater(),
    tacticType: {
      propertyFormat: "activity-service.tactic_type",
      valueFormat: (v) => (v === TacticType.BLACKLIST ? "Blacklist" : "-"),
    },
    intensity: {
      propertyFormat: "tactic.intensity",
      valueFormat: (v) => IntensityDesc[v],
    },
    boostPlacementTop: {
      propertyFormat: "tactic.only_top_of_search_placement",

      valueFormat: (v) => {
        // value in DB can be "true"/"false" or 0/1
        const strVal = "" + v;
        return strVal == "true" || strVal == "1" ? "ON" : "OFF";
      },
    },
  },
};

const SegmentPropertyFormatters: EntityPropertyFormatter<Segment, { segment: SegmentEx }, never> = {
  entityFormatter: (entity, { segment }) => {
    return `[${segment ? SegmentTypeDec[segment.segmentType] : "deleted segment"}] ${segment?.name}`;
  },
  propertyFormatters: {
    name: {
      propertyFormat: "common.name",
      valueFormat: (v) => v,
    },
    // no history on these properties
    segmentId: noOpFormater(),
    accountId: noOpFormater(),
    marketplace: noOpFormater(),
    items: noOpFormater(),
  },
};

const StrategyGroupPropertyFormatters: EntityPropertyFormatter<
  StrategyGroup,
  { strategyGroup: StrategyGroup },
  "state"
> = {
  entityFormatter: (entity, { strategyGroup }) => {
    return strategyGroup ? strategyGroup.strategyGroupName : "deleted strategy group";
  },
  propertyFormatters: {
    strategyGroupName: {
      propertyFormat: "common.name",
      valueFormat: (v) => v,
    },
    strategyGroupId: noOpFormater(),
    // legacy alias
    // TODO: enable no op formatter for legacy alias
    state: "accountId", // do not display state in history
    // no history on these properties
    blacklist: noOpFormater(),
    accountId: noOpFormater(),
    marketplace: noOpFormater(),
    strategies: noOpFormater(),
  },
};

const CreativePropertyFormatters: EntityPropertyFormatter<
  SbCreative,
  { brandAssets: SbCreativeBrandAssets | null },
  "assetId"
> = {
  entityFormatter: (_entity, { brandAssets }) => {
    return brandAssets != null ? brandAssets.creativeTitle : "deleted creative";
  },
  propertyFormatters: {
    logoAssetId: {
      propertyFormat: "sb-form-creative.logo",
      valueFormat: (v) => v,
    },
    videoAssetId: {
      propertyFormat: "sb-form-creative.video",
      valueFormat: (v) => v,
    },
    customImageAssetId: {
      propertyFormat: "activity-service.custom_image",
      valueFormat: (v) => v,
    },
    customImageAssetId2: {
      propertyFormat: "activity-service.custom_image",
      valueFormat: (v) => v,
    },
    customImageAssetId3: {
      propertyFormat: "activity-service.custom_image",
      valueFormat: (v) => v,
    },
    customImageAssetId4: {
      propertyFormat: "activity-service.custom_image",
      valueFormat: (v) => v,
    },
    customImageAssetId5: {
      propertyFormat: "activity-service.custom_image",
      valueFormat: (v) => v,
    },
    headline: {
      propertyFormat: "sb-form-creative.headline",
      valueFormat: (v) => v,
    },
    state: {
      propertyFormat: "billing-customer.state",
      valueFormat: (v) => (v == State.OFF ? "strategy-page.paused" : "activity-service.enabled"),
    },
    assetId: "logoAssetId",
    // no history on these properties
    creativeId: noOpFormater(),
    accountId: noOpFormater(),
    marketplace: noOpFormater(),
    strategyId: noOpFormater(),
    brandEntityId: noOpFormater(),
    brandName: noOpFormater(),
    storePageId: noOpFormater(),
    creativeType: noOpFormater(),
    creativeAsins: noOpFormater(),
  },
};

const AudienceTargetingPropertyFormatters: EntityPropertyFormatter<AudienceTargeting, any, never> = {
  entityFormatter: (entity) => {
    return `${entity.expressionType.charAt(0).toUpperCase() + entity.expressionType.slice(1)} ${
      SupportedAudienceMatchType[entity.matchType] ?? entity.matchType
    } last ${entity.lookback}d`;
  },
  propertyFormatters: {
    expressionType: {
      propertyFormat: "activity-service.expression_type",
      valueFormat: (v) => v.charAt(0).toUpperCase() + v.slice(1),
    },
    matchType: {
      propertyFormat: "sd-strategy-creation.match_type",
      valueFormat: (v) => SupportedAudienceMatchType[v] ?? v,
    },
    state: {
      propertyFormat: "billing-customer.state",
      valueFormat: (v) => (v == State.OFF ? "Paused" : "Enabled"),
    },
    lookback: {
      propertyFormat: "sd-strategy-creation.lookback",
      valueFormat: (v) => `${v} days`,
    },
    audienceTargetId: noOpFormater(),
    idName: noOpFormater(),
    audienceId: noOpFormater(),
    categoryId: noOpFormater(),
  },
};

const AccountMarketplacePropertyFormatters: EntityPropertyFormatter<
  AccountMarketplaceEntity,
  { locale: string; marketplace: Marketplace },
  never
> = {
  entityFormatter: (entity) => {
    return `${entity.accountId} - ${entity.marketplace}`;
  },
  propertyFormatters: {
    accountId: {
      propertyFormat: "activity-service.account_id",
      valueFormat: (v) => v,
    },
    marketplace: {
      propertyFormat: "common.marketplace",
      valueFormat: (v) => v,
    },
    promoStartDate: {
      propertyFormat: "activity-service.promo_start_date",
      valueFormat: (v) => v,
    },
    promoEndDate: {
      propertyFormat: "activity-service.promo_end_date",
      valueFormat: (v) => v,
    },
    state: {
      propertyFormat: "billing-customer.state",
      valueFormat: (v) => v,
    },
    maxBid: {
      propertyFormat: "update-max-bid.max_bid",
      valueFormat: (v, { locale, marketplace }) => {
        const marketplaceEx = Marketplaces[marketplace];
        const currency = marketplaceEx.currency;
        return formatCurrency(
          v ?? marketplaceEx.defaultMaxBid,
          locale,
          Currencies[currency].currencySymbol,
          currency,
          "1.2-2",
        );
      },
    },
    campaignLabel: {
      propertyFormat: "account-setting.campaign_label",
      valueFormat: (v) => v,
    },
    customCampaignName: {
      propertyFormat: "activity-service.custom_campaign_name",
      valueFormat: (v) => v,
    },
    useSourcingMetrics: {
      propertyFormat: "activity-service.vendor_metrics",
      valueFormat: (v) => {
        // in history table, value could be stored as a string 0/1 or false/true
        if (v == false || (v as unknown) == "false" || (v as unknown) == "0") {
          // disableAutoSegment false means "Automatic targeting campaign" is ON
          return "Manufacturing";
        }
        return "Sourcing";
      },
    },
  },
};

// see AccountAPi.updateAccountMarketplace() in adakazam-services
type AccountMarketplaceEntity = {
  accountId: string;
  marketplace: Marketplace;
  promoStartDate: string;
  promoEndDate: string;
  state: AccountState;
  maxBid: number;
  campaignLabel: string;
  customCampaignName: string;
  useSourcingMetrics: boolean;
};

export type ActivityEventType = {
  title: string;
  match: (h: History) => boolean;
};
