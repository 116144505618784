<ng-container *transloco="let t">
  <div class="container mx-auto flex min-h-full flex-col rounded-lg bg-white px-10 py-6">
    <div class="flex items-center justify-between">
      <h1 class="text-2xl font-medium text-gray-800">Create Tacos Strategy</h1>
      <IButton
        color="white"
        size="sm"
        [label]="t('tacos-strategy.back_to_tacos_strategy_list')"
        to="/advertising/tacos-strategies"
        queryParamsHandling="merge"
      />
    </div>

    <div class="mt-9 flex w-full flex-1 flex-col gap-6 overflow-auto px-1">
      <div class="flex w-full flex-col gap-6 lg:w-[30rem]">
        <IInput
          label="Name"
          [control]="form.controls.name"
          size="sm"
          placeholder="Strategy Name"
          [error]="form.controls.name.errors?.['required'] ? 'Name is required' : null"
        />
        <IInput
          label="Tacos Target"
          [control]="form.controls.tacosTarget"
          size="sm"
          type="number"
          placeholder="Tacos Target"
          icon="icon-[mdi--percent]"
          [trailing]="true"
          [error]="
            form.controls.tacosTarget.errors?.['min'] ||
            form.controls.tacosTarget.errors?.['max'] ||
            form.controls.tacosTarget.errors?.['required']
              ? t('tacos-strategy-page.tacos_target_must_be_between_1_and_100')
              : null
          "
        />
      </div>

      @if (am()) {
        @if (unavailableAsins() && unavailableAsins()!.size) {
          <IAlert class="my-4" typ="info" [title]="t('common.asin_used_in_other_strategies')">
            <IButton
              [label]="t('common.display_unavailable_asin')"
              color="white"
              size="xs"
              (onClick)="openAsinUsageModal()"
            />
          </IAlert>
        }
        <div class="h-[45rem]">
          <app-asins-selection
            class="h-full"
            [accountId]="am()!.accountId"
            [marketplace]="am()!.marketplace"
            [asins]="form.controls.asins.value ?? []"
            [disabledAsins]="unavailableAsins()!"
            (onAdd)="addAsins($event)"
            (onDelete)="removeAsins($event)"
          />
        </div>
      }
    </div>

    <div class="flex justify-end border-t border-gray-200 pt-2">
      <IButton label="Create Strategy" (onClick)="submit()" [disabled]="form.invalid" [loading]="loading()" />
    </div>
  </div>
</ng-container>
