// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    CreditCard,
    Currency,
    Invoice,
} from './';

/**
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * @type {string}
     * @memberof Customer
     */
    customerId?: string;
    /**
     * @type {string}
     * @memberof Customer
     */
    name?: string;
    /**
     * @type {string}
     * @memberof Customer
     */
    addressLine1?: string;
    /**
     * @type {string}
     * @memberof Customer
     */
    addressLine2?: string;
    /**
     * @type {string}
     * @memberof Customer
     */
    city?: string;
    /**
     * @type {string}
     * @memberof Customer
     */
    postalCode?: string;
    /**
     * @type {string}
     * @memberof Customer
     */
    state?: string;
    /**
     * @type {string}
     * @memberof Customer
     */
    country?: string;
    /**
     * @type {Currency}
     * @memberof Customer
     */
    currency?: Currency;
    /**
     * @type {string}
     * @memberof Customer
     */
    vat?: string;
    /**
     * @type {string}
     * @memberof Customer
     */
    stripeTaxId?: string;
    /**
     * @type {number}
     * @memberof Customer
     */
    m19UserId?: number;
    /**
     * @type {string}
     * @memberof Customer
     */
    m19Email?: string;
    /**
     * @type {string}
     * @memberof Customer
     */
    stripeCardId?: string;
    /**
     * @type {string}
     * @memberof Customer
     */
    cardExpirationDate?: string;
    /**
     * @type {number}
     * @memberof Customer
     */
    cardLast4Digits?: number;
    /**
     * @type {string}
     * @memberof Customer
     */
    cardBrand?: string;
    /**
     * @type {string}
     * @memberof Customer
     */
    cardNetwork?: string;
    /**
     * @type {Array<Invoice>}
     * @memberof Customer
     */
    invoices?: Array<Invoice>;
    /**
     * @type {Array<CreditCard>}
     * @memberof Customer
     */
    creditCards?: Array<CreditCard>;
}
