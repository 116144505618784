<h2 class="m-0">AMC</h2>
@if (availableReportsOptions()?.length) {
  <div class="my-4 flex items-center gap-4" *transloco="let t">
    <span class="text-lg text-gray-600"> Sponsored Ads And Dsp Overlap</span>
    <span class="text-sm text-gray-400">|</span>
    <div class="flex items-center gap-2">
      <span>{{ t("amc.report_month") }}</span>
      <ISelect
        [options]="availableReportsOptions()!"
        [selectedValue]="selectedReportDateSubject | async"
        (selectedOptionChange)="selectReportDate($event)"
        popperWidth="20"
      >
        <ng-template #labelSlot #optionSlot let-o>
          <span>{{ o?.label | date: "MMM y" : undefined : locale() }}</span>
        </ng-template>
      </ISelect>
    </div>
  </div>
  <div class="mb-28 flex w-full flex-col justify-center gap-4" *transloco="let t">
    <div class="ag-theme-quartz">
      <ag-grid-angular class="w-full" [gridOptions]="gridOptions()" [rowData]="amcStats()" />
    </div>
    <div class="flex flex-1 justify-center">
      <div class="flex w-[30rem] flex-col gap-1 rounded-lg border bg-white p-2">
        <div class="flex items-center justify-between">
          <div>{{ t("metrics." + selectedMetric().id + "_title") }}</div>
          <ISelect
            popperWidth="20"
            [options]="metricOptions"
            [selectedValue]="selectedMetric()"
            (selectedOptionChange)="selectMetric($event)"
          />
        </div>
        <canvas
          baseChart
          [datasets]="dataSet().chartDataSet"
          [options]="dataSet().donutChartOptions"
          [labels]="dataSet().chartLabels"
        ></canvas>
      </div>
    </div>
  </div>
} @else if (availableReportsOptions() && !availableReportsOptions()?.length) {
  <div class="mt-6 text-sm" *transloco="let t">
    <IAlert type="warning">
      <div
        [innerHTML]="
          t('amc.no_amc_account.header', {
            amcLink,
          })
        "
      ></div>
      <div>{{ t("amc.no_amc_account.description") }}</div>
    </IAlert>
  </div>
} @else {
  <div class="m-6 flex justify-center">
    <ISpinner [display]="true" />
  </div>
}
