import { Component } from "@angular/core";
import { StrategyEx } from "@front/m19-models";
import { IButtonComponent } from "@front/m19-ui";
import { FblModeComponent, FblModeSize } from "@m19-board/strategies/fbl-mode/fbl-mode.component";
import { ICON_PAUSE, ICON_PLAY } from "@m19-board/utils/iconsLabels";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { StrategyStateEnum } from "@front/m19-api-client";
import { TranslocoDirective } from "@jsverse/transloco";

@Component({
  selector: "app-algo-state-cell",
  standalone: true,
  templateUrl: "./algo-state-cell.component.html",
  imports: [IButtonComponent, FblModeComponent, TranslocoDirective],
})
export class AlgoStateCellComponent implements ICellRendererAngularComp {
  readonly StrategyStateEnum = StrategyStateEnum;
  readonly ICON_PLAY = ICON_PLAY;
  readonly ICON_PAUSE = ICON_PAUSE;
  readonly FblModeSize = FblModeSize;

  strategy!: StrategyEx;
  isReadOnly!: boolean;
  onStateChange!: () => void;

  agInit(params: ICellRendererParams & { strategy: StrategyEx; isReadOnly: boolean; onStateChange: () => void }): void {
    if (params.strategy) {
      this.strategy = params.strategy;
    }
    if (params.isReadOnly) {
      this.isReadOnly = params.isReadOnly;
    }
    if (params.onStateChange) {
      this.onStateChange = params.onStateChange;
    }
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }
}
