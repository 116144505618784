<div class="modal-header bg-main-500" *transloco="let t">
  <h4 class="modal-title pull-left">
    {{ t("common.edit") }} <span class="sensitive-data">"{{ catalogBrand.brandAlias }}"</span>
    {{ t("brand-alias-modal.other_brand_name") }}
  </h4>
  <a><h4 class="modal-title pull-right" (click)="close()">&times;</h4></a>
</div>
<div class="modal-body ml-3 mr-2" *transloco="let t">
  <div class="mb-1 flex justify-end">
    <app-export-button
      (export)="downloadFile()"
      tooltipValue="{{ t('brand-alias-modal.export_other_brand_as_csv') }}"
    ></app-export-button>
  </div>
  <div class="keywords-div flex">
    <div class="row ml-1 mr-1 h-full w-1/2">
      <textarea
        [(ngModel)]="bulkKeywords"
        class="simple-input form-control h-[80%]"
        placeholder="{{ t('brand-alias-modal.enter_list_name_brand_placeholder') }}"
      ></textarea>
      <div class="m-1 flex w-full justify-end">
        <div class="relative w-full">
          @if (importErrors && importErrors.length > 0) {
            <div class="list-errors">
              <ul class="list-group">
                <li class="list-group-item list-group-item list-group-item-warning flex justify-between">
                  <span>{{ t("common.following_lines_ignored") }}</span>
                  <button
                    type="button"
                    class="btn text-warning bg-transparent p-0"
                    (click)="clearErrors()"
                    matTooltip="Close"
                  >
                    <span class="icon-[lucide--x] text-lg"></span>
                  </button>
                </li>
                @for (error of importErrors; track error) {
                  <li class="list-group-item list-group-item-secondary py-0">
                    {{ error }}
                  </li>
                }
              </ul>
            </div>
          }
        </div>
        <div>
          <IButton
            label="{{ t('common.add') }}"
            color="main"
            variant="solid"
            [disabled]="keywordToAddInvalid()"
            (onClick)="addKeyword()"
          ></IButton>
        </div>
      </div>
    </div>
    <div class="mx-2" role="button">
      <div class="arrow flex h-full w-full items-center justify-center">
        <span class="icon-[lucide--arrow-right] text-lg" (click)="addKeyword()"></span>
      </div>
    </div>
    <div class="border-shadow flex h-full w-1/2 flex-col">
      <mat-table [dataSource]="datasourceKeyword" matSort class="responsive-table table-hover flex-1 overflow-y-auto">
        <ng-container matColumnDef="selection">
          <mat-header-cell *matHeaderCellDef class="flex-half flex flex-row items-center justify-between">
            <span
              class="select-button-box"
              matTooltip="{{
                allSelected() ? t('brand-alias-modal.unselect_aliases') : t('brand-alias-modal.select_all_aliases')
              }}"
            >
              <span
                class="text-lg"
                [ngClass]="allSelected() ? 'icon-[lucide--square-check]' : 'icon-[lucide--square]'"
                (click)="toggleSelectAll()"
              ></span>
            </span>
            @if (nbSelectedKw() > 0) {
              <span
                class="delete-content"
                (click)="deleteSelectedKeywords()"
                [matTooltip]="t('brand-alias-modal.delete_aliases', { count: nbSelectedKw() })"
              >
                <span class="icon-[lucide--trash-2]"></span>
                ({{ allSelected() ? t("common.all") : nbSelectedKw() }})
              </span>
            }
          </mat-header-cell>
          <mat-cell *matCellDef="let keyword" class="flex-half flex flex-row justify-between">
            <span class="select-button-box">
              <span
                class="text-lg"
                [ngClass]="keyword.selected ? 'icon-[lucide--square-check]' : 'icon-[lucide--square]'"
                (click)="select(keyword); $event.stopPropagation()"
              ></span>
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="keyword">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="flex-3">
            <span class="search-bar" (click)="$event.stopPropagation()">
              <IInput
                (valueChange)="setFilter($event)"
                placeholder="{{ t('common.search') }}"
                [icon]="ICON_SEARCH"
                autocomplete="off"
              />
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let keyword" class="flex-3 flex flex-row justify-between">
            <div>
              <a
                class="text-color"
                [href]="keyword.item.keyword | amazonSearchUrlPipe: marketplace"
                target="_blank"
                (click)="$event.stopPropagation()"
              >
                {{ keyword.item.keyword }}
              </a>
            </div>
            <div class="delete-content mr-3">
              <span
                (click)="deleteKeyword(keyword.item)"
                matTooltip="{{ t('common.delete') }}"
                matTooltipPosition="left"
              >
                <span class="icon-[lucide--trash-2] text-lg"></span>
              </span>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="['selection', 'keyword']"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: ['selection', 'keyword']"
          (click)="select(row)"
          class="pointer"
        ></mat-row>
      </mat-table>
      <mat-paginator
        class="mt-1"
        [pageSize]="5"
        showFirstLastButtons
        [hidden]="(datasourceKeyword.data && datasourceKeyword.data.length === 0) || datasourceKeyword.data.length <= 5"
      ></mat-paginator>
    </div>
  </div>
</div>
<div class="modal-footer" *transloco="let t">
  <IButton label="{{ t('common.close') }}" color="white" (onClick)="close()" />
</div>
