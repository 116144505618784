<div class="modal-header bg-main-500" *transloco="let t">
  <h4 class="modal-title pull-left">
    {{ t("asin-inventory-rules-modal.rule_title", { asin: asin }) }}
  </h4>
  <a>
    <h4 class="modal-title pull-right" (click)="bsModalRef.hide()">&times;</h4>
  </a>
</div>
<div class="m-4" *transloco="let t">
  <form [formGroup]="ruleForm">
    <div class="form-group">
      <label for="pauseThreshold">{{ t("asin-inventory-rules-modal.pause_message", { asin: asin }) }}</label>
      <input
        type="number"
        class="form-control"
        id="pauseThreshold"
        name="pauseThreshold"
        formControlName="pauseThreshold"
        placeholder="{{ t('asin-inventory-rules-modal.threshhold_placeholder') }}"
      />
    </div>
    @if (pauseThreshold.invalid && (pauseThreshold.dirty || !pauseThreshold.untouched)) {
      @if (pauseThreshold.errors?.["min"]) {
        <div class="invalid-feedback">{{ t("asin-inventory-rules-modal.threshold_minimum") }}</div>
      }
      @if (pauseThreshold.errors?.["required"]) {
        <div class="invalid-feedback">{{ t("asin-inventory-rules-modal.threshold_mandatory") }}</div>
      }
      @if (pauseThreshold.errors?.["pattern"]) {
        <div class="invalid-feedback">{{ "Threshold should be an integer" }}</div>
      }
    }

    <div>
      <ICheckbox
        label="{{ t('asin-inventory-rules-modal.advertise_expected_checkbox') }}"
        [disabled]="ruleForm.controls['inbound'].disabled"
        [value]="ruleForm.controls['inbound'].value"
        (valueChange)="ruleForm.controls['inbound'].setValue($event)"
      />
    </div>
    <div class="mt-3">
      <ICheckbox
        label="{{ t('asin-inventory-rules-modal.never_pause_checkbox', { asin: asin }) }}"
        [disabled]="ruleForm.controls.noRule.disabled"
        [value]="ruleForm.controls['noRule'].value"
        (valueChange)="ruleForm.controls['noRule'].setValue($event)"
      />
    </div>

    <hr />
    <button
      type="button"
      class="btn btn-primary pull-right"
      (click)="save()"
      matTooltipPosition="right"
      matTooltip="{{ t('asin-inventory-rules-modal.apply_this_on_button', { asin: asin }) }}"
      [disabled]="ruleForm.invalid"
    >
      {{ t("common.save") }}
    </button>
    @if (warningMessage && ruleForm.valid) {
      <div class="warning-message mb-4 mt-3">{{ warningMessage }}</div>
    }
  </form>
</div>
