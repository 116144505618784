import { AgGridAngular } from "@ag-grid-community/angular";
import {
  ColDef,
  GetContextMenuItemsParams,
  GetGroupRowAggParams,
  GridOptions,
  HeaderValueGetterFunc,
  HeaderValueGetterParams,
  ICellRendererParams,
  IRowNode,
  MenuItemDef,
  ValueFormatterParams,
  ValueGetterParams,
} from "@ag-grid-community/core";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AccountMarketplace,
  Currency,
  KeywordTrackerConfig,
  KeywordTrackingFrequency,
  Marketplace,
  ProductTrackerConfig,
  QueryType,
} from "@front/m19-api-client";
import { getBasicGridOptions, getGroupRowAgg } from "@front/m19-grid-config";
import { ACOS, AD_CONVERSIONS, AD_SALES, CLICKS, CONVERSION_RATE, COST, CPC, Metric, ROAS } from "@front/m19-metrics";
import { AdStatsEx, Catalog, ProductEx, SegmentConfigType, SegmentEx } from "@front/m19-models";
import {
  AccountMarketplaceService,
  AccountSelectionService,
  AdsStatsWithPreviousPeriod,
  AmazonSearchUrlPipe,
  AsinService,
  AuthService,
  KeywordTrackingService,
  SegmentService,
  StatsService,
  TruncatePipe,
  UserSelectionService,
} from "@front/m19-services";
import { addAdStats, Comparison, convertToCurrency, Utils } from "@front/m19-utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ChartDataset } from "chart.js";
import { MetricsSelectorLocalStorageKey } from "libs/m19-services/src/lib";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, defer, Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { AddToSeedsModalComponent, SeedType } from "../catalog/seeds/add-to-seeds-modal.component";
import { actionColumnProperties, CurrencyColumn } from "../grid-config/grid-columns";
import {
  ACTIONS_COL_ID,
  exportGridCsv,
  getCsvFileName,
  getMetricsColDef,
  IMAGE_COL_ID,
  STATUS_BAR,
} from "../grid-config/grid-config";
import {
  ActionButton,
  ActionButtonsComponent,
} from "../insights/advertising-stats/action-buttons/action-buttons.component";
import { PALETTE, QueryStats } from "../models/Metric";
import { ProductTrackerPageQueryParams } from "../product-tracker/product-tracker-views/product-tracker.component";
import { AsinLinkComponent } from "../product-view/asin-link.component";
import { ProductViewComponent } from "../product-view/product-view.component";
import { AddToSegmentModalComponent } from "../segments/add-to-segment-modal.component";
import { KeywordSegmentModalComponent } from "../segments/keyword-segment-modal.component";
import { ProductSegmentModalComponent } from "../segments/product-segment-modal.component";
import { ConfirmPopupComponent } from "../shared/confirm-popup/confirm-popup.component";
import { keywordRankingAvailableFor } from "../tracking/KeywordRankingAvailability";
import { ICON_CHART_LINE, ICON_CLOSE } from "../utils/iconsLabels";
import { DefaultTrafficStatsFilter, TrafficStatsFilter } from "./traffic-stats-filter.component";

const QueryTypeDesc: { [key in QueryType]: string } = {
  [QueryType.KEYWORDS]: "Keywords",
  [QueryType.PRODUCT]: "Products",
  [QueryType.COMPLEX_TARGETING_EXPRESSION]: "Remarketing",
};

type QueryStatsWithPreviousData = {
  data: QueryStats[];
  previousData: QueryStats[];
};

type QueryStatsExtraction = {
  data: QueryStats[];
  nonSegmentData: QueryStats[];
  nonSegmentTargetedProductData: QueryStats[];
  nonSegmentKWData: QueryStats[];
  nonSegmentOtherData: QueryStats[];
};

function buildFakeData(date?: string) {
  const isAsin = Utils.randomBoolean();
  return {
    impressions: Utils.randomInt(30000),
    clicks: Utils.randomInt(200),
    cost: Utils.randomInt(3000) / 100,
    adConversions: Utils.randomInt(20),
    adSales: Utils.randomInt(200),
    allOrderedUnits: Utils.randomInt(50),
    allSales: Utils.randomInt(10000) / 100,
    date: date,
    isAsin: isAsin,
    asin: "B00KNOUYY0",
    query: isAsin ? "B07MMFRH13" : "fake data",
    queryType: isAsin ? QueryType.PRODUCT : QueryType.KEYWORDS,
    currency: Currency.EUR,
    isMultiAsin: false,
    isUnknownAsin: false,
  };
}

function buildFakeDataBetweenDates(start: string, end: string): QueryStats[] {
  const result: QueryStats[] = [];
  for (let dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
    result.push(buildFakeData(Utils.formatDateForApi(dt)));
  }
  return result;
}

const LastWeek = Utils.formatDateForApiFromToday(-7);
const Today = Utils.formatDateForApiFromToday(0);
export const FakeData = buildFakeDataBetweenDates(LastWeek, Today);

@UntilDestroy()
@Component({
  selector: "app-traffic-stats",
  templateUrl: "./traffic-stats.component.html",
  styleUrls: ["./traffic-stats.component.scss"],
})
export class TrafficStatsComponent implements OnInit {
  readonly ChartMetrics: Metric<AdStatsEx>[] = [
    AD_SALES,
    AD_CONVERSIONS,
    COST,
    ACOS,
    CLICKS,
    CONVERSION_RATE,
    ROAS,
    CPC,
  ];
  selectedMetrics: Metric<QueryStats>[] = [];
  CurrencyColumn: ColDef = {
    ...CurrencyColumn,
    headerName: this.translocoService.translate("common.currency", {}, "en"),
    headerValueGetter: () => this.translocoService.translate("common.currency"),
  };
  readonly colors = {
    segment: {
      total: PALETTE[0],
      kw: PALETTE[1],
      targetedProduct: PALETTE[2],
      other: PALETTE[4],
    },
    nonSegment: {
      total: PALETTE[3],
      kw: "#81C6B8",
      targetedProduct: "#F5C683",
      other: "#b6b6b6",
    },
  };

  readonly pieChartColors = [
    this.colors.segment.total, // segment total
    this.colors.nonSegment.total, // non segment total
    this.colors.segment.kw, // segment KW
    this.colors.segment.targetedProduct, // segment Targeted product
    this.colors.segment.other, // segment Other
    this.colors.nonSegment.other, // non segment Other
    this.colors.nonSegment.targetedProduct, // non segment Targeted product
    this.colors.nonSegment.kw, // non segment KW
  ];

  accountMarketplace?: AccountMarketplace;
  marketplace: Marketplace = Marketplace.FR;
  loader$: BehaviorSubject<boolean>;
  topKwLoadingStatus = false;
  topProductLoadingStatus = false;
  currency: Currency = Currency.EUR;
  locale = "fr";
  nonSegmentAggregation?: QueryStats;
  nonSegmentKWAggregation?: QueryStats;
  nonSegmentTargetedProductAgregation?: QueryStats;
  nonSegmentOtherAggregation?: QueryStats;
  aggregatedData?: QueryStats;
  previousAggregatedData?: QueryStats;
  mainMetric: Metric<QueryStats> = AD_SALES;
  topKeywords: QueryStats[] = [];
  topProducts: QueryStats[] = [];
  pieChartData: ChartDataset[] = [];
  barChartData: ChartDataset[] = [];
  chartDisplayed = true;
  keywordTrackingConfig?: KeywordTrackerConfig[];
  productTrackingConfig?: ProductTrackerConfig[];
  catalog?: Catalog;
  isReadOnly = false;
  readonly localStorageKey = MetricsSelectorLocalStorageKey.traffic;
  readonly ICON_CLOSE = ICON_CLOSE;
  readonly ICON_CHART = ICON_CHART_LINE;

  amazonSearchUrlPipe = new AmazonSearchUrlPipe();

  public trafficStatsFilter$ = new BehaviorSubject<TrafficStatsFilter>(DefaultTrafficStatsFilter);
  public initSegment?: SegmentEx;
  private selectedMetric$!: BehaviorSubject<Metric<QueryStats>[]>;
  private queryStatsData$ = new BehaviorSubject<QueryStatsWithPreviousData>({
    data: [],
    previousData: [],
  });

  // AG grid config
  gridData!: QueryStats[];
  previousGridData = new Map<string, QueryStats>();
  @ViewChild(AgGridAngular) grid!: AgGridAngular;
  private GRID_KEY = "trafficGrid";

  readonly gridComponents = {
    productView: ProductViewComponent,

    productSearchUrl: (params: ICellRendererParams) => {
      const link = this.amazonSearchUrlPipe.transform(params.value, this.marketplace);
      return `<a target="_blank" class="text-color" href="${link}">${params.data.query}</a>`;
    },
  };

  // Col defs common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  public columnDefs: ColDef[] = [
    {
      colId: "queryViewCol",
      field: "query",
      enableRowGroup: true,
      headerName: this.translocoService.translate("traffic-stats.query", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("traffic-stats.query"),
      floatingFilter: true,
      filter: "agTextColumnFilter",
      maxWidth: 300,
      pinned: "left",
      cellRendererSelector: (params) => {
        if (params.node.isRowPinned()) return undefined;
        const data = params.data;
        if (data) {
          if (data.isAsin)
            return {
              component: "productView",
              params: { asin: params.value, marketplace: this.marketplace },
            };
          else return { component: "productSearchUrl", params: params };
        }
        return undefined;
      },
    },
    {
      // for CSV export
      colId: "queryTitle",
      field: "query",
      headerName: this.translocoService.translate("traffic-stats.targeted_product_title", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("traffic-stats.targeted_product"),
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "queryType",
      headerName: this.translocoService.translate("traffic-stats.targeting_type", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("traffic-stats.targeting_type"),
      enableRowGroup: true,
      pinned: "left",
      valueFormatter: (params: ValueFormatterParams<QueryType, QueryType>) => QueryTypeDesc[params.value!],
      floatingFilter: true,
      filter: "agSetColumnFilter",
      filterValueGetter: (params: ValueGetterParams) => (QueryTypeDesc as any)[params.data!.queryType],
    },
    {
      colId: "asin",
      field: "asin",
      headerName: this.translocoService.translate("traffic-stats.advertised_product", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("traffic-stats.advertised_product"),
      enableRowGroup: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      pinned: "left",
      cellRendererSelector: (params) => {
        if (params.node.group && params.node.field !== "asin") return undefined;
        if (params.node.isRowPinned()) return undefined;
        else if ((params.node.group ? params.node.key : params.value) == "Multi-ASIN") {
          return undefined;
        } else
          return {
            component: "productView",
            params: { asin: params.node.group ? params.node.key : params.value, marketplace: this.marketplace },
          };
      },
    },
    { colId: "asinTitle", field: "asin", headerName: "ASIN Title", hide: true, suppressColumnsToolPanel: true },
    {
      headerName: this.translocoService.translate("product360.parent_asin", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("product360.parent_asin"),
      floatingFilter: true,
      filter: "agTextColumnFilter",
      pinned: "left",
      enableRowGroup: true,
      hide: true,
      valueGetter: (params: ValueGetterParams) => {
        const key = params.data?.asin || params.node?.key;
        return this.catalog?.getParentAsin(key);
      },
      cellRendererSelector: (params: ICellRendererParams) => {
        return params.node.rowPinned || params.node.group
          ? undefined
          : {
              component: AsinLinkComponent,
              params: {
                asin: params.value || params.node.key,
                marketplace: this.marketplace,
              },
            };
      },
    },
    // Metrics columns
    ...getMetricsColDef(this.ChartMetrics).map(({ headerName, ...def }: ColDef) => ({
      ...def,
      //have to use header directly so the expression changed error is not triggered
      headerName: this.translocoService.translate(`metrics.${def.colId}_title`, {}, "en"),
      headerValueGetter: () => this.translocoService.translate(`metrics.${def.colId}_title`),
      headerTooltip: this.translocoService.translate(`metrics.${def.colId}_tooltip`),
      cellRendererParams: (params: ICellRendererParams) => {
        const key = params.data ? `${params.data.query}_${params.data.asin}` : "";
        return {
          ...def.cellRendererParams(params),
          previousData: this.previousGridData.get(key),
          currency: this.currency,
          locale: this.locale,
        };
      },
    })),
    this.CurrencyColumn,
    {
      ...actionColumnProperties(),
      headerName: this.translocoService.translate("common.currency", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("common.currency"),
      cellRendererSelector: (params) => {
        if (params.node.isRowPinned() || params.node.group) return undefined;
        return {
          component: ActionButtonsComponent,
          params: {
            actionButtons: [
              {
                icon: "icon-[mdi--ellipsis-vertical]",
                tooltip: "Row actions",
                subItems: this.getSubItemsButtons(params.node),
              },
            ] as ActionButton[],
          },
        };
      },
    },
  ];

  autoGroupColumnDef: ColDef = {
    pinned: "left",
  };

  public gridOptions: GridOptions = {
    ...getBasicGridOptions(this.GRID_KEY, true),
    context: { componentParent: this },
    components: this.gridComponents,
    defaultColDef: this.defaultColDef,
    rowGroupPanelShow: "always",
    columnDefs: this.columnDefs,
    groupDisplayType: "multipleColumns",
    autoGroupColumnDef: this.autoGroupColumnDef,
    showOpenedGroup: false,
    getContextMenuItems: (params) => this.getContextMenuItems(params),
  };

  constructor(
    private statsService: StatsService,
    private accountSelection: AccountSelectionService,
    private userSelectionService: UserSelectionService,
    private authService: AuthService,
    private segmentService: SegmentService,
    private accountMarketplaceService: AccountMarketplaceService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private keywordTrackingService: KeywordTrackingService,
    private asinService: AsinService,
    private toasterService: ToastrService,
    private translocoService: TranslocoService,
  ) {
    this.loader$ = new BehaviorSubject(true);
  }

  ngOnInit(): void {
    this.userSelectionService.togglePeriodComparison(undefined, Comparison.None);
    this.selectedMetric$ = new BehaviorSubject<Metric<QueryStats>[]>(
      this.selectedMetrics.length ? this.selectedMetrics : [AD_SALES, COST],
    );

    this.chartDisplayed = this.userSelectionService.getUserChartDisplayedPreference(this.localStorageKey);
    this.loader$.next(true);
    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((currency) => {
      this.currency = currency;
    });
    this.userSelectionService.dateRange$
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.loader$.next(true);
        }),
      )
      .subscribe((_) => {
        this.resetQueryStatsData();
      });
    this.accountSelection.accountGroupSelection$
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.loader$.next(true);
        }),
      )
      .subscribe(() => {
        this.resetQueryStatsData();
      });
    this.accountSelection.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));

    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.locale = user.locale;
    });

    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) =>
          this.keywordTrackingService.getKeywordTrackerConfig(am.accountId, am.marketplace, am.resourceOrganizationId!),
        ),
      )
      .subscribe((s) => (this.keywordTrackingConfig = s));
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) => this.keywordTrackingService.getProductTrackerConfig(am.accountId, am.marketplace)),
      )
      .subscribe((s) => (this.productTrackingConfig = s));
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) => this.asinService.getCatalog(am.accountId, am.marketplace)),
      )
      .subscribe((c) => {
        this.catalog = c;
      });

    combineLatest([
      this.route.queryParams,
      this.accountSelection.singleAccountMarketplaceSelection$.pipe(
        untilDestroyed(this),
        tap((am: AccountMarketplace) => {
          this.accountMarketplace = am;
          this.marketplace = am.marketplace;
          this.grid?.api.showLoadingOverlay();
        }),
        switchMap((am) => this.segmentService.getSegments(am.accountId, am.marketplace)),
      ),
    ]).subscribe(([queryParams, segmentIndex]) => {
      const segmentId = +queryParams["selectedSegment"];
      if (segmentId && segmentIndex.has(segmentId)) {
        const segment = segmentIndex.get(segmentId);
        this.initSegment = segment;
        if (segment?.segmentType === SegmentConfigType.KeywordSegment) {
          this.trafficStatsFilter$.next({
            ...this.trafficStatsFilter$.value,
            keywordSegments: [{ filterValue: [segment], exclude: false }],
          });
        } else {
          this.trafficStatsFilter$.next({
            ...this.trafficStatsFilter$.value,
            productSegments: [{ filterValue: [segment!], exclude: false }],
          });
        }
      }
    });

    combineLatest([
      this.accountSelection.singleAccountMarketplaceSelection$.pipe(
        switchMap((am) =>
          combineLatest([this.userSelectionService.dateRange$, this.userSelectionService.periodComparison$]).pipe(
            tap((_) => {
              this.loader$.next(true);
            }),
            switchMap(([[minDate, maxDate], previousPeriod]) =>
              combineLatest([
                this.statsService.getQueryStats(am.accountId, am.marketplace, minDate, maxDate),
                previousPeriod && previousPeriod.period && previousPeriod.period.length > 0
                  ? this.statsService.getQueryStats(
                      am.accountId,
                      am.marketplace,
                      previousPeriod.period[0],
                      previousPeriod.period[1],
                    )
                  : of([]),
              ]).pipe(
                switchMap(([data, previousData]) => {
                  return this.userSelectionService.selectedCurrency$.pipe(
                    map((currency) => {
                      return {
                        data: convertToCurrency(data, currency, am.marketplace),
                        previousData: convertToCurrency(previousData, currency, am.marketplace),
                      };
                    }),
                  );
                }),
              ),
            ),
          ),
        ),
      ),
      this.trafficStatsFilter$,
    ])
      .pipe(
        tap(() => {
          this.loader$.next(true);
        }),
        map(([{ data, previousData }, filter]) => {
          this.resetQueryStatsData();
          return { data, previousData, filter };
        }),
        untilDestroyed(this),
      )
      .subscribe(({ data, previousData, filter }) => {
        this.initQueryStatsData({ data: data ?? [], previousPeriodData: previousData ?? [] }, filter);
      });

    this.queryStatsData$
      .pipe(
        tap((_) => this.grid?.api.showLoadingOverlay()),
        untilDestroyed(this),
      )
      .subscribe(({ data, previousData }) => this.initAggregatedData(data, previousData));
    this.selectedMetric$.pipe(untilDestroyed(this)).subscribe((m) => (this.mainMetric = m[0]));
    combineLatest([this.selectedMetric$, this.queryStatsData$])
      .pipe(untilDestroyed(this))
      .subscribe(([m, data]) => this.updateGraphs(m, data.data));

    this.queryStatsData$.pipe(untilDestroyed(this)).subscribe((d) => this.updateTableData(d.data, d.previousData));
    // display fake data if no account marketplace available
    this.accountMarketplaceService.accountMarketplaces$.pipe(untilDestroyed(this)).subscribe((accountMarketplaces) => {
      const noAccountGroupSetup =
        accountMarketplaces?.length == 0 || accountMarketplaces!.findIndex((am) => am.accountGroupId! > 0) < 0;
      if (noAccountGroupSetup) {
        this.initQueryStatsData({ data: FakeData, previousPeriodData: [] }, DefaultTrafficStatsFilter);
      }
    });
  }

  // Function given to ag grid to aggregate Adstats on grouping
  getGroupRowAgg(params: GetGroupRowAggParams) {
    return getGroupRowAgg(params);
  }

  updateTrafficStatsFilter(filter: TrafficStatsFilter): void {
    this.trafficStatsFilter$.next(filter);
  }

  selectMetric(metrics: Metric<QueryStats>[]) {
    this.selectedMetric$.next(metrics);
  }

  updateGraphs(m: Metric<QueryStats>[], data: QueryStats[]): void {
    const mainMetric = m[0];
    const kwData = data.filter((d) => d.queryType === QueryType.KEYWORDS);
    const productData = data.filter((d) => d.queryType === QueryType.PRODUCT);
    const otherData = data.filter((d) => d.queryType === QueryType.COMPLEX_TARGETING_EXPRESSION);
    this.topKwLoadingStatus = true;
    this.topProductLoadingStatus = true;
    this.getTopQueries(kwData, mainMetric)
      .pipe(untilDestroyed(this))
      .subscribe((top) => {
        this.topKwLoadingStatus = false;
        this.topKeywords = top;
      });
    this.getTopQueries(productData, mainMetric)
      .pipe(untilDestroyed(this))
      .subscribe((top) => {
        this.topProductLoadingStatus = false;
        this.topProducts = top;
      });
    const aggregatedData = mainMetric.value(this.sumData(data))!;
    const nonSegmentData = mainMetric.value(this.nonSegmentAggregation!)!;
    const kwMetricValue = mainMetric.value(this.sumData(kwData))!;
    const productMetricValue = mainMetric.value(this.sumData(productData))!;
    const otherMetricValue = mainMetric.value(this.sumData(otherData))!;
    const kwMetricValueNonSegment = mainMetric.value(this.nonSegmentKWAggregation!)!;
    const productMetricValueNonSegment = mainMetric.value(this.nonSegmentTargetedProductAgregation!)!;
    const otherMetricValueNonSegment = mainMetric.value(this.nonSegmentOtherAggregation!)!;
    this.pieChartData = [
      {
        type: "doughnut",
        data: [aggregatedData!, nonSegmentData!, 0, 0, 0, 0, 0, 0],
        backgroundColor: this.pieChartColors,
      },
      {
        type: "doughnut",
        data: [
          0,
          0,
          kwMetricValue!,
          productMetricValue!,
          otherMetricValue!,
          otherMetricValueNonSegment!,
          productMetricValueNonSegment!,
          kwMetricValueNonSegment!,
        ],
        backgroundColor: this.pieChartColors,
      },
    ];
    this.barChartData = [
      {
        label: this.translocoService.translate("common.total"),
        data: [aggregatedData, nonSegmentData],
        backgroundColor: [this.colors.segment.total, this.colors.nonSegment.total],
      },
      {
        label: this.translocoService.translate("traffic-stats-chart.kw"),
        data: [kwMetricValue, kwMetricValueNonSegment],
        backgroundColor: [this.colors.segment.kw, this.colors.nonSegment.kw],
      },
      {
        label: this.translocoService.translate("traffic-stats.targeted_product"),
        data: [productMetricValue, productMetricValueNonSegment],
        backgroundColor: [this.colors.segment.targetedProduct, this.colors.nonSegment.targetedProduct],
      },
      {
        label: this.translocoService.translate("dsp-stats.dspCampaignDeliveryStatus_OTHER"),
        data: [otherMetricValue, otherMetricValueNonSegment],
        backgroundColor: [this.colors.segment.other, this.colors.nonSegment.other],
      },
    ];

    const setBorderAndHover = (dataSet: ChartDataset) => {
      dataSet.borderColor = "#fff";
      dataSet.hoverBorderColor = dataSet.backgroundColor;
      dataSet.hoverBackgroundColor = dataSet.backgroundColor;
    };

    this.barChartData.forEach((dataSet) => setBorderAndHover(dataSet));
    this.pieChartData.forEach((dataSet) => setBorderAndHover(dataSet));
  }

  sumData(data: QueryStats[]): QueryStats {
    const tempData = data.slice();
    return tempData.reduce((previous, current) => ({ ...current, ...addAdStats(previous, current) }), {} as QueryStats);
  }

  getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    const result: (string | MenuItemDef)[] = [
      "copy",
      "chartRange",
      {
        name: "Export CSV",
        action: () => {
          this.exportGridCsv();
        },
        icon: "csv",
      },
    ];

    if (params.node?.group) return result;

    result.push(
      "separator",
      ...this.getSubItemsButtons(params.node!)!.map((actionBtn: ActionButton) => ({
        name: actionBtn.title!,
        action: () => actionBtn.onClick!(params),
      })),
    );

    return result;
  };

  private getSubItemsButtons(node: IRowNode): ActionButton[] | undefined {
    const data = node.data;
    if (!data) return undefined;

    const result: ActionButton[] = [];

    if (!this.isReadOnly) {
      result.push(
        {
          title:
            "Add '" +
            (data.queryType === QueryType.KEYWORDS
              ? data.query + "' to a segment"
              : data.query + "' to a product segment"),
          onClick: (params: ICellRendererParams) => {
            this.addToSegment(data);
          },
        },
        {
          title:
            "Add " +
            (data.queryType === QueryType.KEYWORDS ? "'" + data.query + "'" : data.query) +
            " to product seeds",
          onClick: (params: ICellRendererParams) => {
            this.addToSeeds(data);
          },
        },
      );
    }

    if (this.canAccessKeywordRanking()) {
      if (!data.isMultiAsin) {
        result.push({
          title: "Open '" + data.asin + "' in product tracker",
          onClick: () => {
            this.viewProductTracker(data.asin);
          },
        });
      }

      if (data.queryType === QueryType.KEYWORDS) {
        result.push({
          title: "See ranking of keyword '" + data.query + "'",
          onClick: () => {
            this.viewKeywordTracker(data);
          },
        });
        result.push({
          title: "Track keyword '" + data.query + "'",
          onClick: () => {
            this.trackKeyword(data);
          },
        });
      } else if (data.queryType === QueryType.PRODUCT && !data.isUnknownAsin) {
        result.push({
          title: "Open '" + data.query + "' in product tracker",
          onClick: () => {
            this.viewProductTracker(data.query);
          },
        });

        result.push({
          title: "Track product '" + data.query + "'",
          onClick: () => {
            this.trackProduct(data.query);
          },
        });
      }
    }
    return result;
  }

  exportGridCsv(): void {
    this.asinService
      .getCatalog(this.accountMarketplace!.accountId, this.accountMarketplace!.marketplace)
      .pipe(
        map((c: Catalog) => c.products),
        untilDestroyed(this),
      )
      .subscribe((products: ProductEx[]) => {
        const asinOrQueryTitles = new Map<string, string>();
        products.forEach((p: ProductEx) => asinOrQueryTitles.set(p.asin!, p.title!));
        const dateRange = this.userSelectionService.getDateRangeStr();
        const fileName = getCsvFileName(
          "traffic_report",
          this.accountMarketplace!.accountGroupName,
          this.marketplace,
          dateRange,
        );
        const params = {
          fileName,
          columnKeys: this.grid?.api
            ?.getColumns()
            ?.map((c) => c.getColId())
            .filter((c) => c !== IMAGE_COL_ID && c !== ACTIONS_COL_ID),
          processCellCallback: (params: any) => {
            if (params.column.getColId() === "queryTitle") {
              if (params.node.data.queryType === QueryType.PRODUCT) {
                return asinOrQueryTitles.get(params.value); // Get ASIN title
              } else {
                return "";
              }
            }
            if (params.column.getColId() === "asinTitle") return asinOrQueryTitles.get(params.value); // Get asin title

            return params.value;
          },
        };
        for (const key of params.columnKeys ?? []) {
          const colDef = this.grid?.api.getColumnDef(key);
          if (colDef && colDef.headerValueGetter) {
            colDef.headerName = (colDef.headerValueGetter as HeaderValueGetterFunc<any, any>)(
              undefined as unknown as HeaderValueGetterParams<any, any>,
            );
          }
        }
        exportGridCsv(this.grid?.api, params);
      });
  }

  toggleChartDisplay(displayed: boolean): void {
    this.chartDisplayed = displayed;
    this.userSelectionService.setUserChartDisplayedPreference(this.localStorageKey, displayed);
  }

  addToSegment(stat: QueryStats) {
    const modalOptions: ModalOptions = {
      initialState: {
        segmentType:
          stat.queryType == QueryType.KEYWORDS ? SegmentConfigType.KeywordSegment : SegmentConfigType.ProductSegment,
        targetingItemValue: stat.query,
        marketplace: this.accountMarketplace!.marketplace,
        accountId: this.accountMarketplace!.accountId,
      },
      class: "modal-lg",
    };
    const modalRef = this.modalService.show(AddToSegmentModalComponent, modalOptions);
    const subscription = modalRef
      .content!.segmentCreationRequested.pipe(untilDestroyed(this))
      .subscribe((segmentItems) => {
        const modalOptions: ModalOptions = {
          initialState: {
            accountId: this.accountMarketplace!.accountId,
            marketplace: this.accountMarketplace!.marketplace,
            initSegmentItems: segmentItems,
          },
          class: "modal-xl",
        };
        const creationModal =
          stat.queryType == QueryType.KEYWORDS
            ? this.modalService.show(KeywordSegmentModalComponent, modalOptions)
            : this.modalService.show(ProductSegmentModalComponent, modalOptions);
        subscription.add(
          creationModal.content!.segmentCreated.pipe(untilDestroyed(this)).subscribe(() => subscription.unsubscribe()),
        );
        subscription.add(
          creationModal
            .content!.segmentEditionCanceled.pipe(untilDestroyed(this))
            .subscribe(() => subscription.unsubscribe()),
        );
      });
  }

  addToSeeds(stat: QueryStats) {
    const modalOptions: ModalOptions = {
      initialState: {
        seedType: stat.isAsin ? SeedType.Product : SeedType.Keyword,
        asin: stat.asin,
        targetingItemValue: stat.query,
        accountId: this.accountMarketplace!.accountId,
        marketplace: this.accountMarketplace!.marketplace,
      },
      class: "modal-lg",
    };
    this.modalService.show(AddToSeedsModalComponent, modalOptions);
  }

  viewKeywordTracker(stat: QueryStats) {
    if (this.keywordTrackingConfig!.findIndex((s) => s.searchTerm == stat.query) < 0) {
      const toastRef = this.toasterService.warning(
        `Keyword '${new TruncatePipe().transform(stat.query!, 10)}' is not tracked. Click here to start tracking`,
      );
      toastRef.onTap.pipe(untilDestroyed(this)).subscribe(() => this.trackKeyword(stat));
      return;
    }
    this.router.navigate(["/keyword-center/keyword-tracker", stat.query], {
      queryParamsHandling: "merge",
    });
  }

  viewProductTracker(asin: string) {
    if (!this.catalog?.contains(asin) && this.productTrackingConfig!.findIndex((s) => s.asin == asin) < 0) {
      const toastRef = this.toasterService.warning(`Product '${asin}' is not tracked. Click here to start tracking`);
      toastRef.onTap.pipe(untilDestroyed(this)).subscribe(() => this.trackProduct(asin));
      return;
    }
    this.router.navigate(["/product-center/product-tracker"], {
      queryParams: { [ProductTrackerPageQueryParams.asin]: asin },
      queryParamsHandling: "merge",
    });
  }

  canAccessKeywordRanking() {
    if (!this.accountMarketplace) {
      return false;
    }
    return keywordRankingAvailableFor(this.accountMarketplace.marketplace);
  }

  trackKeyword(stat: QueryStats) {
    if (stat.isAsin) {
      return;
    }

    this.modalService
      .show(ConfirmPopupComponent, {
        initialState: {
          title: "Track keyword",
          message: `Do you want to track keyword '${stat.query}'?`,
          confirmCta: "Track",
          type: "success",
        },
      })
      .content!.confirm.pipe(
        untilDestroyed(this),

        switchMap(() => {
          const conf: KeywordTrackerConfig[] = [
            {
              accountId: this.accountMarketplace!.accountId,
              marketplace: this.marketplace,
              organizationId: this.accountMarketplace!.resourceOrganizationId,
              searchTerm: stat.query,
              frequency: KeywordTrackingFrequency.daily,
            },
          ];
          return this.keywordTrackingService.addKeywordTrackerConfig(
            this.accountMarketplace!.accountId,
            this.marketplace,
            this.accountMarketplace!.resourceOrganizationId!,
            conf,
          );
        }),
      )
      .subscribe({
        next: (_) => {
          this.toasterService.success("Keyword successfully tracked", "Keyword tracking");
        },
        error: (e) => {
          this.toasterService.success(`Error when setting keyword tracking. ${e}`, "Keyword tracking");
        },
      });
  }

  trackProduct(asin: string) {
    this.modalService
      .show(ConfirmPopupComponent, {
        initialState: {
          title: "Track Product",
          message: `Do you want to track product '${asin}'?`,
          confirmCta: "Track",
          type: "success",
        },
      })
      .content!.confirm.pipe(
        untilDestroyed(this),

        switchMap(() => {
          const conf: ProductTrackerConfig[] = [
            {
              accountId: this.accountMarketplace!.accountId,
              marketplace: this.marketplace,
              asin: asin,
            },
          ];
          return this.keywordTrackingService.addProductTrackerConfig(
            this.accountMarketplace!.accountId,
            this.marketplace,
            conf,
          );
        }),
      )
      .subscribe({
        next: (_) => {
          this.toasterService.success("Product successfully tracked", "Product tracking");
        },
        error: (e) => {
          this.toasterService.success(`Error when setting product tracking. ${e}`, "Product tracking");
        },
      });
  }

  private resetQueryStatsData() {
    this.queryStatsData$.next({ data: [], previousData: [] });
    this.loader$.next(true);
  }

  // TODO: this method will be used to infer the queryType as it may be null in DB for the moment
  // this should be cleaned up once stats are reimported
  private inferQueryType(stat: QueryStats): QueryType {
    if (stat.queryType) return stat.queryType;
    if (stat.isAsin) return QueryType.PRODUCT;
    // hacky way error-prone to check if this is a complex targeting expression
    if (stat.query?.includes("=")) return QueryType.COMPLEX_TARGETING_EXPRESSION;
    return QueryType.KEYWORDS;
  }

  private initQueryStatsData(data: AdsStatsWithPreviousPeriod, filter: TrafficStatsFilter) {
    const queryStatsExtraction = this.extractQueryStats(data.data, filter);
    const queryStatsExtractionPreviousPeriod = this.extractQueryStats(data.previousPeriodData, filter);
    this.nonSegmentAggregation = this.sumData(queryStatsExtraction.nonSegmentData);
    this.nonSegmentKWAggregation = this.sumData(queryStatsExtraction.nonSegmentKWData);
    this.nonSegmentTargetedProductAgregation = this.sumData(queryStatsExtraction.nonSegmentTargetedProductData);
    this.nonSegmentOtherAggregation = this.sumData(queryStatsExtraction.nonSegmentOtherData);
    this.queryStatsData$.next({
      data: queryStatsExtraction.data,
      previousData: queryStatsExtractionPreviousPeriod.data,
    });
    this.loader$.next(false);
  }

  private extractQueryStats(data: AdStatsEx[], filter: TrafficStatsFilter): QueryStatsExtraction {
    this.loader$.next(true);
    const result: QueryStats[] = [];
    const nonSegmentData: QueryStats[] = [];
    const nonSegmentKWData: QueryStats[] = [];
    const nonSegmentTargetedProductData: QueryStats[] = [];
    const nonSegmentOtherData: QueryStats[] = [];
    for (const stats of data) {
      if (!stats.query) continue;
      // filter multi ASIN or not
      let isMultiAsin = false;
      let isUnknownAsin = false;
      if (!Utils.isValidAsin(stats.asin!)) {
        if (stats.asin!.startsWith("Multi-ASIN")) {
          if (!filter.enableMultiAsin) {
            continue;
          }
          isMultiAsin = true;
        } else {
          isUnknownAsin = true;
        }
      }
      const stat = {
        ...stats,
        isAsin: Utils.isValidAsin(stats.query),
        isMultiAsin: isMultiAsin,
        isUnknownAsin: isUnknownAsin,
      };
      stat.queryType = this.inferQueryType(stat);

      // advertised products filter
      let queryMatch = false;
      if (
        !filter.advertisedProducts.every((filter) =>
          filter.exclude ? !filter.filterValue.includes(stat.asin!) : filter.filterValue.includes(stat.asin!),
        )
      ) {
        queryMatch = false;
      } else if (filter.keywordSegments.length == 0 && filter.productSegments.length == 0) {
        queryMatch = true;
      } else if (stat.queryType == QueryType.PRODUCT) {
        queryMatch =
          filter.productSegments.length > 0 &&
          filter.productSegments.every((filter) =>
            filter.exclude
              ? !filter.filterValue.some((s) => s.matchQuery(stat.query!))
              : filter.filterValue.some((s) => s.matchQuery(stat.query!)),
          );
      } else if (stat.queryType == QueryType.KEYWORDS) {
        queryMatch =
          filter.keywordSegments.length > 0 &&
          filter.keywordSegments.every((filter) =>
            filter.exclude
              ? !filter.filterValue.some((s) => s.matchQuery(stat.query!))
              : filter.filterValue.some((s) => s.matchQuery(stat.query!)),
          );
      } else {
        // segment cannot match complex targeting expression, except if they exclude them
        queryMatch =
          filter.keywordSegments.every((filter) => filter.exclude) &&
          filter.productSegments.every((filter) => filter.exclude);
      }
      if (queryMatch) {
        result.push(stat);
      } else {
        nonSegmentData.push(stat);
        if (stat.queryType == QueryType.PRODUCT) {
          nonSegmentTargetedProductData.push(stat);
        } else if (stat.queryType == QueryType.KEYWORDS) {
          nonSegmentKWData.push(stat);
        } else {
          nonSegmentOtherData.push(stat);
        }
      }
    }
    return { data: result, nonSegmentData, nonSegmentTargetedProductData, nonSegmentKWData, nonSegmentOtherData };
  }

  private initAggregatedData(data: QueryStats[], previousData: QueryStats[]) {
    this.aggregatedData = this.sumData(data);
    this.previousAggregatedData = this.sumData(previousData);
  }

  private updateTableData(data: QueryStats[], previousData: QueryStats[] = []) {
    this.gridData = data;
    this.previousGridData.clear();
    for (const stat of previousData) {
      this.previousGridData.set(`${stat.query}_${stat.asin}`, stat);
    }
  }

  private getTopQueries(stats: QueryStats[], metric: Metric<QueryStats>): Observable<QueryStats[]> {
    return defer(async () => {
      await new Promise((resolve) => setTimeout(resolve, 0));
      // for ACOS metric, sort in the reverse order https://github.com/m19-dev/main-repo/issues/1160
      const order = metric == ACOS ? -1 : 1;
      const aggregation = new Map<string, QueryStats[]>();
      for (const stat of stats) {
        if (!aggregation.has(stat.query!)) {
          aggregation.set(stat.query!, []);
        }
        aggregation.set(stat.query!, [...aggregation.get(stat.query!)!, stat]);
      }
      const result = [...aggregation.entries()]
        .map(([_, v]) => this.sumData(v))
        .filter((d) => {
          if (metric == ACOS) {
            const value = metric.value(d)!;
            return !isNaN(value) && isFinite(value);
          }
          return true;
        })
        .sort((a, b) => order * metric.compare(a, b))
        .slice(0, 5);
      return result;
    });
  }

  protected readonly STATUS_BAR = STATUS_BAR;
}
