import { Component, inject, signal } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@front/m19-services";
import { Angulartics2 } from "angulartics2";
import { LayoutSelectorService } from "@m19-board/layout-selector.service";
import { environment } from "../../../environments/environment";
import { IAlertComponent, IButtonComponent, LoginFormComponent, LoginFormValue } from "@front/m19-ui";
import { HttpStatusCode } from "@angular/common/http";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { SafePipe } from "@m19-board/utils/pipes";
import { AjaxError } from "rxjs/ajax";

@Component({
  selector: "app-login",
  templateUrl: "login.component.html",
  styleUrls: ["./login.component.scss"],
  standalone: true,
  imports: [TranslocoDirective, IAlertComponent, LoginFormComponent, IButtonComponent, SafePipe],
})
export class LoginComponent {
  private readonly authService = inject(AuthService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly angulartics2 = inject(Angulartics2);
  private readonly layoutSelector = inject(LayoutSelectorService);
  private readonly translocoService = inject(TranslocoService);

  loading = signal(false);
  error = signal<string | null>(null);

  agency = this.layoutSelector.getAgencyName();
  isTest = !environment.production;

  login(formValue: LoginFormValue): void {
    const redirectUrl = this.route.snapshot.queryParams["redirect"] ?? "dashboard";

    if (!formValue) return;
    this.error.set(null);
    this.loading.set(true);
    this.authService.doLoginV2(formValue.email, formValue.password).subscribe({
      next: () => {
        this.angulartics2.eventTrack.next({
          action: "login_with_password",
          properties: { category: "login", label: "success_" + formValue.email },
        });
        this.router.navigateByUrl(redirectUrl);
      },
      error: (err: AjaxError) => {
        if (err.status === HttpStatusCode.Unauthorized) {
          let errorMessage = this.translocoService.translate("login.login_error_message");
          errorMessage += "<br/>";
          if (err.response.message.includes("existing autopilot account")) {
            errorMessage += this.translocoService.translate("login.existing_autopilot_account_with_email", {
              autopilotLink: "<a href='https://autopilot.m19.com/'>M19 autopilot</a>",
            });
          }
          this.error.set(errorMessage);
        } else this.error.set(err.message);
        this.loading.set(false);
      },
    });
  }
}
