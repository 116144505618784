import { Component, Input, OnInit } from "@angular/core";
import { AccountMarketplace, CampaignType, Strategy, StrategyStateEnum } from "@front/m19-api-client";
import { ACOS, AD_CONVERSIONS, AD_SALES, CLICKS, CONVERSION_RATE, COST, CPC, Metric, ROAS } from "@front/m19-metrics";
import { AdStatsEx, MarketplaceEx, Marketplaces, StrategyEx } from "@front/m19-models";
import {
  AccountSelectionService,
  AuthService,
  DataSet,
  MetricsSelectorLocalStorageKey,
  SpStrategiesService,
  StatsService,
  UserSelectionService,
} from "@front/m19-services";
import {
  addAdStats,
  AggregationFunction,
  Comparison,
  convertToCurrency,
  divideAdStats,
  mergeSeveralDates,
  Utils,
} from "@front/m19-utils";
import { TranslocoService } from "@jsverse/transloco";
import { DefaultAdStatsMetricsWithSameScale } from "@m19-board/models/DataSet";
import { StrategyStats } from "@m19-board/models/Metric";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import moment, { MomentZone } from "moment-timezone";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, map, Observable, of, Subject, switchMap } from "rxjs";

export interface ComputedData {
  firstQuartileData?: Map<string, AdStatsEx>;
  medianData?: Map<string, AdStatsEx>;
  thirdQuartileData?: Map<string, AdStatsEx>;
  firstQuartileRatioData?: Map<string, Map<string, number>>;
  avgData: Map<string, AdStatsEx>;
  thirdQuartileRatioData?: Map<string, Map<string, number>>;
  daypartingPauseHour?: number;
  daypartingReactivationHour?: number;
}

function buildHourlyFakeData(date: string, hour: number) {
  return {
    impressions: Utils.randomInt(30000),
    clicks: Utils.randomInt(200),
    cost: Utils.randomInt(3000) / 100,
    adConversions: Utils.randomInt(20),
    adSales: Utils.randomInt(200),
    date: date,
    hour: hour,
  };
}

function buildHourlyFakeDataDate(date: string): Map<string, AdStatsEx> {
  const result = new Map<string, AdStatsEx>();
  for (let i = 0; i < 24; i++) {
    result.set(i.toString(), buildHourlyFakeData(date, i));
  }
  return result;
}

const Today = Utils.formatDateForApiFromToday(0);
const FakeDataHourly = buildHourlyFakeDataDate(Today);

@UntilDestroy()
@Component({
  selector: "app-hourly-page",
  templateUrl: "./hourly-page.component.html",
})
export class HourlyPageComponent implements OnInit {
  @Input() hourlyDataSupported!: boolean;

  globalData: AdStatsEx = {};
  dataSet!: DataSet<AdStatsEx>;

  medianByTypeHour!: Map<string, StrategyStats[]>;
  avgByTypeHour!: Map<string, StrategyStats[]>;
  statsByType: Map<string, AdStatsEx[]> = new Map();

  selectedMetric$!: BehaviorSubject<Metric<AdStatsEx>[]>;
  customMetrics$!: BehaviorSubject<Metric<AdStatsEx>[]>;

  timeZone!: MomentZone;
  currency!: string;
  strategies!: StrategyEx[];
  strategiesMap: Map<number, StrategyEx> = new Map();
  private strategyFilter$ = new BehaviorSubject<StrategyEx | undefined>(undefined);
  pendingRequests: boolean = true;
  accountMarketplace$!: Observable<AccountMarketplace>;
  marketplace!: MarketplaceEx;
  getHourlyStats$: Subject<void> = new Subject();
  dataSubject$: Subject<ComputedData> = new Subject();

  includeNonM19Campaign = true;
  isErrorState = false;
  displayWarning = false;
  chartDisplayed = true;
  nbDays = 0;
  nbDaysIgnored = 0;
  nbDaysConsidered = 0;

  readonly localStorageKey = MetricsSelectorLocalStorageKey.hourlyStats;
  readonly CHART_METRICS: Metric<StrategyStats>[] = [
    AD_SALES,
    AD_CONVERSIONS,
    COST,
    ACOS,
    CLICKS,
    CPC,
    CONVERSION_RATE,
    ROAS,
  ];

  constructor(
    private authService: AuthService,
    private userSelectionService: UserSelectionService,
    private accountSelection: AccountSelectionService,
    private toasterService: ToastrService,
    private translocoService: TranslocoService,
    private spStrategyService: SpStrategiesService,
    private statsService: StatsService,
  ) {}

  ngOnInit(): void {
    this.userSelectionService.togglePeriodComparison(undefined, Comparison.None);
    this.chartDisplayed = this.userSelectionService.getUserChartDisplayedPreference(this.localStorageKey);
    this.pendingRequests = true;
    this.selectedMetric$ = new BehaviorSubject<Metric<AdStatsEx>[]>([AD_SALES, COST]);
    this.customMetrics$ = new BehaviorSubject<Metric<AdStatsEx>[]>([]);
    this.dataSet = new DataSet<AdStatsEx>(
      3,
      this.selectedMetric$.value,
      [AggregationFunction.mergeSeveralDates],
      this.translocoService,
    );
    this.dataSet.metricsOnSameScale = DefaultAdStatsMetricsWithSameScale;
    this.accountMarketplace$ = this.accountSelection.singleAccountMarketplaceSelection$;
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => (this.dataSet.locale = user.locale));
    this.userSelectionService.selectedCurrency$
      .pipe(untilDestroyed(this))
      .subscribe((x) => (this.dataSet.currency = x));

    combineLatest([this.dataSubject$, this.selectedMetric$])
      .pipe(untilDestroyed(this))
      .subscribe(([data, metrics]) =>
        this.dataSet.buildHourlyDataSet(
          data.avgData,
          metrics,
          data.daypartingPauseHour,
          data.daypartingReactivationHour,
        ),
      );

    this.setHourlyAsinStatsSubscriptions();

    // fake data when no account selected
    this.accountSelection.noAccountGroupSelected$.pipe(untilDestroyed(this)).subscribe(() => {
      this.globalData = {
        impressions: Utils.randomInt(30000),
        clicks: Utils.randomInt(200),
        cost: Utils.randomInt(3000) / 100,
        adConversions: Utils.randomInt(20),
        adSales: Utils.randomInt(200),
        allOrderedUnits: Utils.randomInt(50),
        allSalesExTax: Utils.randomInt(10000) / 100,
        date: Today,
      };
      this.dataSet.buildHourlyDataSet(FakeDataHourly, this.selectedMetric$.value);
      this.pendingRequests = false;
    });

    combineLatest([this.accountSelection.singleAccountMarketplaceSelection$, this.userSelectionService.dateRange$])
      .pipe(untilDestroyed(this))
      .subscribe((_) => {
        if (this.isErrorState) {
          // recreate the subscriptions
          this.getHourlyStats$.next();
          this.isErrorState = false;
        }
        this.pendingRequests = true;
      });
    this.getHourlyStats$.next();
  }

  setSelectedMetrics(metrics: Metric<AdStatsEx>[]) {
    this.selectedMetric$.next(metrics);
  }

  setCustomMetrics(metrics: Metric<AdStatsEx>[]) {
    this.customMetrics$.next(metrics);
  }

  setStrategy(strategy: StrategyEx | undefined) {
    this.strategyFilter$.next(strategy);
  }

  setChartDisplay(display: boolean) {
    this.chartDisplayed = display;
  }

  setIncludeNonM19Campaign(bool: boolean) {
    this.includeNonM19Campaign = bool;
    this.getHourlyStats$.next();
  }

  private selectByStrategy(stat: AdStatsEx): string {
    return stat.strategyId
      ? stat.strategyId.toString()
      : this.translocoService.translate("hourly-page.not_operated_campaigns");
  }

  private setHourlyAsinStatsSubscriptions() {
    this.getHourlyStats$
      .pipe(
        switchMap((_) =>
          combineLatest([
            this.accountSelection.singleAccountMarketplaceSelection$,
            this.userSelectionService.dateRange$,
          ]).pipe(
            switchMap(([am, dateRange]) => {
              this.marketplace = Marketplaces[am.marketplace];
              this.timeZone = moment.tz.zone(this.marketplace.timeZone)!;
              return combineLatest([
                am.accountId,
                combineLatest([
                  this.userSelectionService.selectedCurrency$,
                  this.statsService.getHourlyCampaignStats(am.accountId, am.marketplace, dateRange[0], dateRange[1]),
                ]).pipe(map(([currency, data]) => convertToCurrency(data, currency)!)),
                this.strategyFilter$.pipe(
                  switchMap((filter) => {
                    if (filter) {
                      return of((stratId: number) => {
                        return (filter as StrategyEx).strategyId === stratId;
                      });
                    } else {
                      if (this.includeNonM19Campaign) return of((stratId: number) => true);
                      return of((stratId: number) => stratId !== undefined);
                    }
                  }),
                ),
                this.spStrategyService.getSPStrategies(am.accountId, am.marketplace),
              ]);
            }),
          ),
        ),
        untilDestroyed(this),
      )
      .subscribe(
        ([accountId, data, filter, strategies]) => {
          this.strategies = Array.from(strategies.values()).map((x) => new StrategyEx(x));
          this.strategiesMap = new Map(this.strategies.map((x) => [x.strategyId, x]));

          data = data.filter((row) => (filter as (x: number) => boolean)(row.strategyId!) && row.impressions !== 0);

          const statsByDate: Map<string, AdStatsEx> = new Map();
          const statsByHourByDate: Map<string, AdStatsEx> = new Map();

          const allStatsByType: Map<string, AdStatsEx[]> = new Map();

          const dateRange = this.userSelectionService.getDateRange();
          this.nbDays = (dateRange[1].getTime() - dateRange[0].getTime()) / 86400000 + 1;
          this.dataSet.nbDays = this.nbDays;

          this.globalData = {};

          for (const stat of data) {
            Utils.insertInMap(statsByDate, stat.date, stat, addAdStats);
            Utils.insertInMap(statsByHourByDate, stat.hour?.toString() + "_" + stat.date, stat, addAdStats);
            Utils.insertInArrayMap(allStatsByType, this.selectByStrategy(stat), stat);
          }

          this.nbDaysIgnored = this.nbDays - statsByDate.size;
          this.nbDaysConsidered = this.nbDays - this.nbDaysIgnored;
          this.displayWarning = this.nbDaysIgnored > 0;

          this.statsByType = allStatsByType;

          this.computeDataChart(accountId, statsByHourByDate);
          this.computeDataType(allStatsByType, this.strategiesMap);

          this.pendingRequests = false;
        },
        (error) => {
          this.toasterService.error(
            `${error.response ? error.response.message : (error.response ?? "Please reload the page")}`,
            `Error fetching stats data from server`,
          );
          this.pendingRequests = false;
          this.dataSet.buildHourlyDataSet(new Map(), []);
          this.isErrorState = true;
        },
      );
  }

  private computeDataChart(accountId: string, statsByHourByDate: Map<string, AdStatsEx>) {
    const avgData = Utils.sumBy(Array.from(statsByHourByDate.values()), (x) => x.hour?.toString() ?? "", addAdStats);
    avgData.forEach((value, _) => divideAdStats(value, this.nbDaysConsidered));

    // computing data for non ratio metric
    if (this.strategyFilter$.value != undefined) {
      this.dataSubject$.next({
        avgData: avgData,
        daypartingPauseHour: (this.strategyFilter$.value as StrategyEx).daypartingPauseHour ?? undefined,
        daypartingReactivationHour: (this.strategyFilter$.value as StrategyEx).daypartingReactivationHour ?? undefined,
      });
    } else {
      this.dataSubject$.next({
        avgData: avgData,
      });
    }

    for (const stat of avgData) this.globalData = mergeSeveralDates(this.globalData, stat[1]);
  }

  private computeDataType(allStatsByType: Map<string, AdStatsEx[]>, strategies: Map<number, Strategy>) {
    const avgStatsByTypeThenHour: Map<string, StrategyStats[]> = new Map();

    allStatsByType.forEach((stats, key, map) => {
      // if there is several campaigns for a strategyId

      stats = Array.from(Utils.sumBy(stats, (x) => x.date + "_" + x.hour?.toString(), addAdStats).values()).map((x) =>
        this.toStrategyStats(x, strategies),
      );
      const avgs = Utils.sumBy(stats, (x) => x.hour?.toString() ?? "", addAdStats);
      avgStatsByTypeThenHour.set(key, Array.from(avgs.values()) as StrategyStats[]);
    });

    avgStatsByTypeThenHour.forEach((value, key, map) => {
      value.forEach((stat, _) => divideAdStats(stat, this.nbDaysConsidered));
      value.sort((a, b) => a.hour! - b.hour!);
    });

    this.medianByTypeHour = avgStatsByTypeThenHour;
    this.avgByTypeHour = avgStatsByTypeThenHour;
  }

  private toStrategyStats(adStats: AdStatsEx, strategyIndex: Map<number, Strategy>): StrategyStats {
    const strategy = strategyIndex.get(adStats.strategyId!);
    return {
      ...adStats,
      strategyName: adStats.strategyId
        ? strategyIndex.get(adStats.strategyId)
          ? strategyIndex.get(adStats.strategyId)!.name!
          : this.translocoService.translate("hourly-page.deleted_strategy")
        : this.translocoService.translate("hourly-page.not_operated_campaigns"),
      acosTarget: strategy?.acosTarget ?? 0,
      state: strategy?.state ?? StrategyStateEnum.ENABLED,
      campaignType: strategy?.campaignType ?? CampaignType.SP,
      dayPartingEnabled: strategy?.daypartingPauseHour != null && strategy?.daypartingReactivationHour != null,
      dayPartingPauseHour: strategy?.daypartingPauseHour ?? null,
      dayPartingReactivationHour: strategy?.daypartingReactivationHour ?? null,
    };
  }
}
