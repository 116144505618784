<div class="modal-header bg-primary" *transloco="let t">
  <h4 class="modal-title pull-left">{{ t("modal-activity-comment.add_comment") }}</h4>
</div>
<div class="modal-body" *transloco="let t">
  <textarea
    class="asins simple-input form-control"
    [(ngModel)]="comment"
    placeholder="{{ t('modal-activity-comment.add_comment_placeholder') }}"
    maxlength="255"
  ></textarea>
</div>
<div class="modal-footer" *transloco="let t">
  <IButton label="{{ t('common.cancel') }}" color="gray" variant="soft" (onClick)="bsModalRef.hide()" />
  <IButton label="{{ t('common.delete') }}" color="red" variant="soft" (onClick)="deleteComment()" />
  <IButton label="{{ t('common.save') }}" (onClick)="save()" />
</div>
