import { Component, DestroyRef, EventEmitter, inject, Input, input, OnInit, Output, signal } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { IInputComponent } from '../../components/iinput/iinput.component';
import { IButtonComponent } from '../../components/ibutton/ibutton.component';
import { ICheckboxComponent } from '../../components/icheckbox/icheckbox.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoDirective } from '@jsverse/transloco';
import { TranslocoService } from '@jsverse/transloco';

export interface RegisterForm {
  firstName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  email: FormControl<string | null>;
  password: FormControl<string | null>;
  confirmPassword: FormControl<string | null>;
  termsAndConditions: FormControl<boolean>;
}

export interface RegisterFormValue {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

type RegisterFormError = Partial<Record<keyof RegisterForm, string | null>>;

export const PASSWORDS_EQUAL_KEY = 'passwordsEqual';

export function passwordsEqualValidator<T>(controlName: keyof T, matchingControlName: keyof T) {
  return (formGroup: AbstractControl): { [key in typeof PASSWORDS_EQUAL_KEY]: boolean } | null => {
    const control = formGroup.get(controlName as string);
    const matchingControl = formGroup.get(matchingControlName as string);

    if (!control || !matchingControl) {
      return null;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ passwordsEqual: true });
      return { passwordsEqual: true };
    } else {
      if (matchingControl.errors && matchingControl.errors[PASSWORDS_EQUAL_KEY]) {
        matchingControl.setErrors(null);
      }
      return null;
    }
  };
}

@Component({
  selector: 'lib-register-form',
  standalone: true,
  imports: [IInputComponent, ReactiveFormsModule, IButtonComponent, ICheckboxComponent, TranslocoDirective],
  templateUrl: './register-form.component.html',
})
export class RegisterFormComponent implements OnInit {
  readonly destroyRef = inject(DestroyRef);

  readonly registerForm = new FormGroup<RegisterForm>(
    {
      firstName: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      lastName: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      email: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required, Validators.email, Validators.pattern(/.*@.+\..+/)],
      }),
      password: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(8)],
      }),
      confirmPassword: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      termsAndConditions: new FormControl(false, {
        nonNullable: true,
        validators: [Validators.requiredTrue],
      }),
    },
    {
      validators: [passwordsEqualValidator<RegisterForm>('password', 'confirmPassword')],
    },
  );

  @Input() agencyName: string = 'M19';
  @Input() termsAndConditionLink: string = 'https://www.m19.com/form/terms-and-conditions';
  @Output() register = new EventEmitter<RegisterFormValue>();

  loading = input<boolean>(false);
  formErrors = signal<RegisterFormError>({});

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.registerForm.valueChanges.pipe(debounceTime(200), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (this.registerForm.invalid) {
        this.formErrors.set({
          email:
            this.registerForm.controls.email.errors?.['email'] || this.registerForm.controls.email.errors?.['pattern']
              ? this.translocoService.translate('profile-settings.invalid_email')
              : null,
          password: this.registerForm.controls.password.errors
            ? this.translocoService.translate('register-form.password_must_be_at_least_8_characters')
            : null,
          confirmPassword: this.registerForm.errors?.[PASSWORDS_EQUAL_KEY]
            ? this.translocoService.translate('password-reset.password-not-match')
            : null,
        });
      }
    });
  }

  onSubmit() {
    if (this.registerForm.invalid) {
      return;
    }
    const formValue = this.registerForm.value as RegisterFormValue;
    this.register.emit(formValue);
  }
}
