<span
  class="inline-flex cursor-pointer select-none items-center gap-x-3 rounded-md px-2 py-1 text-sm font-medium transition-colors duration-100"
  [ngClass]="{
    '!border border-green-200 bg-green-50 text-green-500 hover:border-green-300': isChecked(),
    'border border-gray-200 bg-gray-100 text-gray-500 hover:border-gray-300': !isChecked(),
    '!cursor-not-allowed opacity-75': loading(),
  }"
  (click)="handleClick()"
>
  <span>{{ label() }}</span>
  <IToggle [checked]="isChecked()" [loading]="loading()" size="sm" />
</span>
