import { CurrencyPipe, DecimalPipe } from "@angular/common";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { DatePipe } from "@angular/common";

@UntilDestroy()
@Component({
  selector: "app-spend-progress-bar",
  template: `
    <IMeter
      [color]="_color"
      [max]="monthlyBudget"
      [value]="currentMonthSpend"
      [label]="_label"
      [description]="_description"
    />
  `,
})
export class SpendProgressBarComponent implements OnChanges {
  @Input() currentMonthSpend: number;
  @Input() monthlyBudget: number;
  @Input() endOfCurrentMonth: Date;
  @Input() currencyCode: string;
  @Input() locale: string = "en-US";
  @Input() hideCurrency: boolean;

  _description: string;
  _label: string;
  _color: "main" | "orange";

  ngOnChanges(_changes: SimpleChanges): void {
    const numberPipe = new DecimalPipe(this.locale);
    const currencyPipe = new CurrencyPipe(this.locale);
    const datePipe = new DatePipe(this.locale);

    if (!this.currentMonthSpend || this.currentMonthSpend < this.monthlyBudget) {
      this._color = "main";
      if (!this.hideCurrency) {
        this._description = `${numberPipe.transform(this.currentMonthSpend ?? 0, "1.0-0")} / ${currencyPipe.transform(
          this.monthlyBudget,
          this.currencyCode,
          "symbol",
          "1.0-0",
        )}`;

        this._label = datePipe.transform(this.endOfCurrentMonth, "mediumDate");
      } else {
        this._label = `${this.currentMonthSpend ?? 0} / ${this.monthlyBudget}`;
      }
    } else if (this.currentMonthSpend >= this.monthlyBudget) {
      this._color = "orange";
      this._label = datePipe.transform(this.endOfCurrentMonth, "mediumDate");
      this._description = `Bids on standby. Spend ${numberPipe.transform(
        this.currentMonthSpend,
        "1.0-0",
      )} > ${currencyPipe.transform(this.monthlyBudget, this.currencyCode, "symbol", "1.0-0")}`;
    }
  }
}
