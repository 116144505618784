@if (!small && display) {
  <div class="card notification-box relative" *transloco="let t">
    <div class="flex">
      <span
        class="notif-icon ml-2 mr-4 inline-flex justify-center self-center"
        [class.warning]="notification.isCritical"
      >
        @if (faIcon) {
          <fa-icon [icon]="faIcon"></fa-icon>
        }
        @if (icon && !faIcon) {
          <img [src]="icon" class="icon-img" />
        }
        @if (!icon && !faIcon) {
          <fa-icon [icon]="notification.isCritical ? faExclamationTriangle : faBell"></fa-icon>
        }
      </span>
      <div class="ml-2">
        <div class="notif-icon-ctn absolute">
          @if (!notification.isCritical) {
            <button
              class="close no-outline notif-icon"
              (click)="toggleNotificationDismissed(notification)"
              [matTooltip]="
                notification.dismissed ? t('notification-card.restore_notif') : t('notification-card.dismiss_notif')
              "
              triggers="mouseenter:mouseleave mouseenter:click"
            >
              <fa-icon [icon]="notification.dismissed ? faUndo : faTimes"></fa-icon>
            </button>
          }
        </div>
        <div class="flex" [ngClass]="{ disabled: notification.dismissed }">
          <h5 class="font-bold">
            <ng-content select="[title]"></ng-content>
          </h5>
        </div>
        <div [ngClass]="{ disabled: notification.dismissed }">
          <ng-content select="[action]"></ng-content>
        </div>
        <div class="footer mb-1 mt-2">
          @if (notification.marketplace) {
            <IBadge [label]="notification.marketplace" variant="outline" size="xs" class="mr-2" />
          }
          <div>
            {{ notification.creationTimestamp | date: "mediumDate" : undefined : locale }} at
            {{ notification.creationTimestamp | date: "shortTime" : undefined : locale }}
          </div>
        </div>
        @if (displayHelp()) {
          <span class="notif-help absolute pl-1"
            ><a [href]="helplink" target="_blank"><fa-icon [icon]="faHelp"></fa-icon></a
          ></span>
        }
      </div>
    </div>
  </div>
}
@if (small) {
  <div class="notif-box relative my-2 px-2 pt-1 text-left" *transloco="let t">
    @if (!notification.isCritical) {
      <div class="flex justify-end">
        <button
          class="close no-outline"
          (click)="toggleNotificationDismissed(notification)"
          [matTooltip]="
            notification.dismissed ? t('notification-card.restore_notif') : t('notification-card.dismiss_notif')
          "
        >
          <fa-icon [icon]="faTimes"></fa-icon>
        </button>
      </div>
    }
    <div class="pr-3 font-bold">
      @if (notification.isCritical) {
        <span class="warning pr-1"> <fa-icon [icon]="faExclamationTriangle"></fa-icon> </span>
      }
      <ng-content select="[titleSmall]"></ng-content>
    </div>
    <div class="notif-sub mt-1 pr-3"><ng-content select="[actionSmall]"></ng-content></div>
    <div class="notif-sub mt-2">
      @if (notification.marketplace) {
        <IBadge [label]="notification.marketplace" variant="outline" size="xs" class="mr-1" />
      }
      <div>
        {{ notification.creationTimestamp | date: "mediumDate" : undefined : locale }} at
        {{ notification.creationTimestamp | date: "shortTime" : undefined : locale }}
      </div>
    </div>
    @if (displayHelp()) {
      <span class="notif-help absolute pl-1"
        ><a [href]="helplink" target="_blank"><fa-icon [icon]="faHelp"></fa-icon></a
      ></span>
    }
  </div>
}
