<div
  class="modal-outer fixed left-1/2 top-1/2 z-50 h-auto max-h-[95vh] w-auto origin-top-left rounded-lg duration-300 ease-out"
  style="animation-fill-mode: forwards"
  #modalContent
>
  <div
    class="modal-content h-full rounded-lg border border-gray-200 bg-white shadow-xl dark:border-gray-700 dark:bg-gray-800"
  >
    @if (title) {
      <div class="flex w-full items-center justify-between border-b border-gray-200 p-4 dark:border-gray-700">
        <h1 class="text-xl font-medium dark:text-white">{{ title }}</h1>
        <IButton icon="icon-[mdi--close]" size="sm" variant="ghost" color="gray" (click)="closeModal()" />
      </div>
    }
    <ng-container>
      <div #element class="max-h-[80vh] overflow-y-auto p-4">
        <ng-content></ng-content>
      </div>
    </ng-container>
  </div>
</div>

@if (!fullScreen) {
  <div
    class="fixed inset-0 left-0 top-0 z-10 h-screen w-screen bg-gray-200/75 dark:bg-gray-800/75"
    [@overlayAnimation]
  ></div>
}
