import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { NotificationType } from "@front/m19-api-client";
import { NotificationEx } from "@front/m19-models";

@Component({
  selector: "app-notification-card-switch",
  templateUrl: "./notification-card-switch.component.html",
})
export class NotificationCardSwitchComponent {
  @Input()
  notification: NotificationEx;
  @Input()
  small = false;

  display = true;
  filter_: RegExp;

  @Input()
  set filter(filter: RegExp) {
    this.filter_ = filter;
    if (this.notif) {
      const nativeElement: HTMLElement = this.notif.nativeElement;
      this.display = this.findMatchingChildren(nativeElement);
    }
  }

  findMatchingChildren(element: ChildNode): boolean {
    if (this.filter_.test(element.textContent)) return true;

    element.childNodes.forEach((child) => {
      if (this.findMatchingChildren(child)) return true;
    });

    return false;
  }

  @ViewChild("notif") notif: ElementRef;

  readonly NotificationType = NotificationType;
}
