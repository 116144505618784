<ng-container *transloco="let t">
  @if (!hasInvoices()) {
    <div>
      <div class="col-1"></div>
      <div class="col-5 text">{{ t("billing-invoices.no_invoices_available") }}</div>
    </div>
  }
  <div class="100">
    <table mat-table [dataSource]="dataSource" class="table-striped table-hover mb-0 table" [hidden]="!hasInvoices()">
      <ng-container matColumnDef="created">
        <th *matHeaderCellDef>{{ t("billing-invoices.created") }}</th>
        <td *matCellDef="let item">{{ formatDate(item.created) }}</td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th *matHeaderCellDef>{{ t("metrics.PROFIT_DETAIL_AMOUNT_title") }}</th>
        <td *matCellDef="let item">{{ formatAmount(item) }}</td>
      </ng-container>
      <ng-container matColumnDef="dueDate">
        <th *matHeaderCellDef>{{ t("billing-invoices.due_date") }}</th>
        <td *matCellDef="let item">{{ dueDate(item) }}</td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th *matHeaderCellDef>{{ t("billing-invoices.status") }}</th>
        <td *matCellDef="let item">{{ status(item) }}</td>
      </ng-container>
      <ng-container matColumnDef="paidAt">
        <th *matHeaderCellDef>{{ t("billing-invoices.paid_at") }}</th>
        <td *matCellDef="let item">{{ paidAt(item) }}</td>
      </ng-container>
      <ng-container matColumnDef="pdf">
        <th *matHeaderCellDef>{{ t("billing-invoices.pdf") }}</th>
        <td *matCellDef="let item">
          @if (item.invoicePdf) {
            <a [href]="item.invoicePdf" class="btn btn-ghost-secondary">
              <span class="icon-[material-symbols--picture-as-pdf-outline] text-2xl"></span>
            </a>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="details">
        <th *matHeaderCellDef>{{ t("common.details") }}</th>
        <td *matCellDef="let item">
          <button
            class="btn btn-ghost-secondary custom-btn-dark-hover"
            [disabled]="!hasInvoiceDetails(item.invoiceId)"
            (click)="openInvoiceDetailsModal(item)"
          >
            <fa-icon [icon]="faInfoCircle"></fa-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
      <tr mat-row *matRowDef="let row; columns: getDisplayedColumns()"></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 50, 100]"
      [pageSize]="5"
      showFirstLastButtons
      style="margin-bottom: 2rem"
      [hidden]="!hasInvoices()"
    ></mat-paginator>
  </div>
</ng-container>
