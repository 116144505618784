<div class="flex items-center p-3" (click)="toggleKeyword()" [class.list-separator]="!isLast" *transloco="let t">
  @if (isSelectable) {
    <ICheckbox
      [value]="selected"
      (valueChange)="toggleKeyword()"
      (click)="$event.stopPropagation()"
      [disabled]="isReadOnly"
      [tooltipValue]="t('keyword-item.select_keyword', [keyword.item.targetingValue])"
    />
  }
  <div class="ml-3" style="font-weight: 500">{{ keyword.item.targetingValue }}</div>
  @if (keyword.item.matchType === MatchType.exact) {
    <IBadge class="ml-2" label="{{ t('keyword-item.exact') }}" />
  } @else if (keyword.item.matchType === MatchType.phrase) {
    <IBadge class="ml-2" color="black" label="{{ t('keyword-item.phrase') }}" />
  }
</div>
