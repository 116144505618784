// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    AccountMarketplaceBase,
    DspAdvertiser,
} from './';

/**
 * @export
 * @interface AmazonUser
 */
export interface AmazonUser {
    /**
     * @type {string}
     * @memberof AmazonUser
     */
    amazonUserId?: string;
    /**
     * @type {string}
     * @memberof AmazonUser
     */
    email?: string;
    /**
     * @type {string}
     * @memberof AmazonUser
     */
    token?: string;
    /**
     * @type {Array<AccountMarketplaceBase>}
     * @memberof AmazonUser
     */
    accountMarketplaces?: Array<AccountMarketplaceBase>;
    /**
     * @type {Array<DspAdvertiser>}
     * @memberof AmazonUser
     */
    dspAdvertisers?: Array<DspAdvertiser>;
}
