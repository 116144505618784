import { Component } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { NavData } from "@m19-board/_nav";
import { STRATEGIES_TABS } from "@m19-board/tabbed-nav/tabbed-nav.component";

@UntilDestroy()
@Component({
  selector: "app-strategies",
  template: `<app-tabbed-nav [links]="tabs"> </app-tabbed-nav> <router-outlet></router-outlet>`,
})
export class StrategiesComponent {
  tabs: NavData[] = STRATEGIES_TABS;
}
