import { Component, Input, OnInit, signal } from "@angular/core";
import { AccountMarketplaceService } from "@front/m19-services";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { LinkDspModalComponent, LinkDspModalData } from "./link-dsp.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AccountMarketplace, DspAdvertiser } from "@front/m19-api-client";
import { Marketplaces } from "@front/m19-models";
import { TranslocoService } from "@jsverse/transloco";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";
import { IBadgeComponent, ICardComponent, ModalService } from "@front/m19-ui";

@Component({
  selector: "app-show-dsp",
  template: ` <div class="modal-header">
      <div class="modal-title text-xl ">
        DSP Advertiser linked to
        <span class="font-bold"
          >{{ accountMarketplace.accountName }} {{ Marketplaces[accountMarketplace.marketplace].flag }}</span
        >
      </div>
    </div>
    <div class="modal-body">
      @if (loading()) {
        <div class="flex w-full items-center justify-center">
          <app-spinner [display]="true"></app-spinner>
        </div>
      } @else if (dspAdvertiser === undefined) {
        <div class="p-2">
          <div class="border-l-4 border-orange-500 bg-orange-100 p-4 text-orange-700">
            {{ "Error to get DSP Advertisers." }}
          </div>
        </div>
      } @else {
        <ICard class="!bg-main-50 ring-main-300 ring-2">
          <ng-template #body>
            <div class="mb-2 flex gap-3">
              <div class="text-main-500 font-semibold">{{ dspAdvertiser.name }}</div>
              <a [href]="dspAdvertiser.url" target="_blank" class="text-main-500">{{ dspAdvertiser.url }}</a>
            </div>
            <IBadge
              color="gray"
              size="xs"
              label="ID: {{ dspAdvertiser.dspAdvertiserId }}"
              tooltipValue="This is the DSP Advertiser ID"
            ></IBadge>
          </ng-template>
        </ICard>
      }
    </div>
    <div class="modal-footer">
      @if (!loading()) {
        <button type="button" class="btn btn-primary" (click)="remove()">Unlink</button>
        <button type="button" class="btn btn-primary" (click)="linkAnother()">Link another DSP Advertiser</button>
      }
      <button type="button" class="btn btn-secondary" (click)="close()">Exit</button>
    </div>`,
  standalone: true,
  imports: [SpinnerComponent, ICardComponent, IBadgeComponent],
})
@UntilDestroy()
export class ShowDspComponent implements OnInit {
  @Input({ required: true })
  dspAdvertiserId!: string;
  @Input({ required: true })
  accountMarketplace!: AccountMarketplace;

  readonly Marketplaces = Marketplaces;
  loading = signal(true);
  dspAdvertiser?: DspAdvertiser;

  constructor(
    private accountMarketplaceService: AccountMarketplaceService,
    private toastrService: ToastrService,
    private modalRef: BsModalRef,
    private modalService: ModalService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.accountMarketplaceService
      .getDspAdvertiser(this.dspAdvertiserId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (accountMarketplace) => {
          this.dspAdvertiser = accountMarketplace;
          this.loading.set(false);
        },
        error: (error) => {
          this.toastrService.error(error, this.translocoService.translate("show-dsp.failed_to_load_dsp_advertiser"));
          this.loading.set(false);
        },
      });
  }

  remove(): void {
    this.accountMarketplaceService.linkDspAdvertiser(this.accountMarketplace, undefined).subscribe({
      next: () => {
        this.toastrService.success(this.translocoService.translate("show-dsp.dsp_advertiser_unlinked_successfully"));
        this.modalRef.hide();
      },
      error: (error) => {
        this.toastrService.error(error, this.translocoService.translate("show-dsp.failed_to_unlink_dsp_advertiser"));
      },
    });
  }

  linkAnother(): void {
    this.modalRef.hide();
    this.modalService.openModal<LinkDspModalData, void>(LinkDspModalComponent, {
      data: {
        accountMarketplace: this.accountMarketplace,
      },
    });
  }

  close(): void {
    this.modalRef.hide();
  }
}
