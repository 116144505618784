<div class="flex flex-col items-start" *transloco="let t">
  <app-switch-button
    class="mx-auto mt-2"
    [options]="supportedCurrencies"
    [buttonTexts]="supportedCurrencies"
    [selected]="selectedCurrency"
    (buttonClicked)="selectCurrency($event)"
  ></app-switch-button>

  <div class="plan-wrapper">
    <div class="mt-5 flex w-full flex-wrap gap-8">
      @if (currentBillingPlan && currentBillingPlan.plan === Plan.STARTER) {
        <div class="plan-card w-1/3 rounded-md border-2 border-solid bg-gray-100 p-4 text-gray-500">
          <div class="flex w-full items-center justify-between">
            <div class="mr-2 inline-flex items-center">
              <h4 class="plan-card__title mr-2 text-gray-500">
                {{ currentBillingPlan.plan }}
              </h4>
            </div>

            <IBadge [label]="t('billing-plan-selection.current_plan')" size="xs" color="gray" />
          </div>
          <ul class="plan-card__list">
            <li>
              {{
                currentBillingPlan.accountLimit
                  ? t("billing-plan-selection.limited_account", { count: currentBillingPlan.accountLimit })
                  : t("billing-plan-selection.unlimited_account")
              }}
            </li>
            <li>
              {{
                currentBillingPlan.marketplaceLimitPerAccount
                  ? t("billing-plan-selection.limited_marketplace", {
                      count: currentBillingPlan.marketplaceLimitPerAccount,
                    })
                  : t("billing-plan-selection.unlimited_marketplace")
              }}
            </li>
            <li>
              {{
                currentBillingPlan.userLimit
                  ? t("billing-plan-selection.limited_user", { count: currentBillingPlan.userLimit })
                  : t("billing-plan-selection.unlimited_user")
              }}
            </li>
            <li>
              {{
                currentBillingPlan.strategyLimit
                  ? t("billing-plan-selection.limited_strategy", { count: currentBillingPlan.strategyLimit })
                  : t("billing-plan-selection.unlimited_strategy")
              }}
            </li>
          </ul>
        </div>
      }
      @if (selectedPlan) {
        <div
          class="plan-card bg-main-100 border-main-500 ring-main-400 w-1/3 rounded-md border-2 border-solid p-4 ring-4"
        >
          <div class="flex w-full items-center justify-between">
            <div class="mr-2 inline-flex items-center">
              <h4 class="plan-card__title mr-2">{{ selectedPlan.plan }}</h4>
            </div>
          </div>
          <div class="plan-card__price my-3">
            {{ selectedPlan.flatFee?.amount ?? 0 | currency: selectedCurrency : "symbol" : "1.0-0" : locale }}
            @if (selectedPlan.adSpendFee) {
              <span>
                + {{ selectedPlan.adSpendFee.amount! | percent: "1.0-2" : locale }}
                {{ t("billing-plan-selection.percent_of_your_ad_spend") }}
              </span>
            }
            <span class="plan-card__price__freq">{{ printFrequency(selectedPlan.frequency) }}</span>
          </div>
          <ul class="plan-card__list">
            <li>
              {{
                selectedPlan.accountLimit
                  ? t("billing-plan-selection.limited_account", { count: selectedPlan.accountLimit })
                  : t("billing-plan-selection.unlimited_account")
              }}
            </li>
            <li>
              {{
                selectedPlan.marketplaceLimitPerAccount
                  ? t("billing-plan-selection.limited_marketplace", { count: selectedPlan.marketplaceLimitPerAccount })
                  : t("billing-plan-selection.unlimited_marketplace")
              }}
            </li>
            <li>
              {{
                selectedPlan.userLimit
                  ? t("billing-plan-selection.limited_user", { count: selectedPlan.userLimit })
                  : t("billing-plan-selection.unlimited_user")
              }}
            </li>
            <li>{{ t("billing-plan-selection.onboarding_call") }}</li>
          </ul>
        </div>
      }
    </div>
  </div>
  <div class="mt-3 flex w-full justify-end px-3">
    <div>
      <IButton color="white" [label]="t('common.previous')" (onClick)="cancelSelection()" class="mr-2" />
      <IButton [label]="t('common.next')" (onClick)="saveSelection()" />
    </div>
  </div>
</div>
