import { CommonModule } from "@angular/common";
import { Component, computed, inject, Signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { RouterLink } from "@angular/router";
import {
  Strategy,
  TacosStrategyGroup,
  TacosStrategyGroupApi,
  TacosStrategyGroupStateEnum,
} from "@front/m19-api-client";
import { AccountSelectionService, SpStrategiesService } from "@front/m19-services";
import { IBadgeComponent, IButtonComponent } from "@front/m19-ui";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { ICON_TRASH_O } from "@m19-board/utils/iconsLabels";
import { switchMap } from "rxjs";

@Component({
  selector: "tacos-strategy-list",
  standalone: true,
  imports: [CommonModule, TranslocoRootModule, RouterLink, IBadgeComponent, IButtonComponent],
  templateUrl: "./tacos-strategy-list.component.html",
})
export class TacosStrategyListComponent {
  private readonly tacosApiService = inject(TacosStrategyGroupApi);
  private readonly SpService = inject(SpStrategiesService);
  private readonly accountService = inject(AccountSelectionService);

  readonly TacosStrategyGroupStateEnum = TacosStrategyGroupStateEnum;
  readonly ICON_TRASH = ICON_TRASH_O;

  readonly accountMarketplace = toSignal(this.accountService.singleAccountMarketplaceSelection$);

  strategies: Signal<TacosStrategyGroup[] | undefined> = toSignal(
    this.accountService.singleAccountMarketplaceSelection$.pipe(
      switchMap((am) => {
        return this.tacosApiService.listTacosStrategyGroup({
          accountId: am!.accountId,
          marketplace: am!.marketplace,
        });
      }),
    ),
  );

  private spStrategies: Signal<Map<number, Strategy> | undefined> = toSignal(
    this.accountService.singleAccountMarketplaceSelection$.pipe(
      switchMap((am) => {
        return this.SpService.getSPStrategies(am!.accountId, am!.marketplace);
      }),
    ),
  );

  sbByTacosStrategyGroupId: Signal<Map<number, Strategy> | undefined> = computed(() => {
    if (!this.strategies()) return undefined;

    const sbByTacosStrategyGroupId = new Map<number, Strategy>();
    for (const strategy of this.strategies()!) {
      const sp = this.spStrategies()?.get(strategy.spStrategyId!);
      if (sp) {
        sbByTacosStrategyGroupId.set(strategy.tacosStrategyGroupId!, sp);
      }
    }
    return sbByTacosStrategyGroupId;
  });
}
