<div class="modal-header bg-primary" *transloco="let t">
  <h3 class="modal-title pull-left">{{ t("add-keyword-tracking-modal.track_new_keyword_title") }}</h3>
</div>
<div class="modal-body ml-3 mr-2" *transloco="let t">
  <div>
    <div class="form-group row mb-4 ml-1 items-center">
      <app-switch-button
        [options]="[SelectionModes.Bulk, SelectionModes.FromKeywordSegment]"
        [buttonTexts]="[t('add-keyword-tracking-modal.keyword_list'), t('add-keyword-tracking-modal.keyword_segments')]"
        [buttonTooltips]="['', '']"
        [selected]="selectionMode"
        (buttonClicked)="changeKeywordSelectionMode($event)"
      ></app-switch-button>
    </div>
    <div class="flex flex-row">
      <div class="row mx-1 w-1/2">
        @if (selectionMode === SelectionModes.Bulk) {
          <div class="h-full w-full">
            <textarea
              [(ngModel)]="bulkKeywords"
              class="kw-textarea simple-input form-control inline-block align-top"
              placeholder="{{ t('add-keyword-tracking-modal.keyword_input_placeholder') }}"
              aria-label="Keywords list text area"
            ></textarea>
            <div class="m-1 flex w-full justify-end">
              <button (click)="addKeyword()" [disabled]="keywordToAddInvalid()" class="btn btn-primary ml-2">
                {{ t("common.add") }}
              </button>
            </div>
          </div>
        }
        @if (selectionMode === SelectionModes.FromKeywordSegment) {
          <div class="h-full">
            <div class="card-perso-body bottom-child h-full">
              <div class="card-perso-header collapse-card-header top-child card-header header pb-0 pl-1 pr-1">
                <span
                  class="select-button-box inline-block pl-3"
                  matTooltip="{{
                    selectedKeywordSegments.length === keywordSegments.length
                      ? t('add-keyword-tracking-modal.unselect_all_segments')
                      : t('add-keyword-tracking-modal.select_all_keyword')
                  }}"
                >
                  @if (selectedKeywordSegments.length !== keywordSegments.length) {
                    <fa-icon [icon]="faSquare" size="lg" (click)="selectAllKeywordSegments()"></fa-icon>
                  }
                  @if (selectedKeywordSegments.length === keywordSegments.length) {
                    <fa-icon [icon]="faCheckedSquare" size="lg" (click)="unselectAllKeywordSegments()"></fa-icon>
                  }
                </span>
                <span class="search">
                  <span class="search-bar input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <fa-icon [icon]="faSearch"></fa-icon>
                      </span>
                    </div>
                    <input
                      type="text"
                      class="search-bar simple-input-prepend form-control"
                      placeholder="{{ t('add-keyword-tracking-modal.search_keyword') }}"
                      (input)="setKeywordSegmentFilter($event.target)"
                    />
                  </span>
                </span>
              </div>
              <div class="kw-segments card-perso-body bottom-child card-body top-child mb-3 mt-2 pl-1 pr-1">
                @for (keywordSegment of filteredKeywordSegments; track keywordSegment) {
                  <div class="card-perso left-child right-child border-shadow card mb-2 w-full">
                    <div
                      class="card-perso-header top-child bottom-child card-header flex pb-2 pl-3 pt-2"
                      [class.disabledKwSegments]="
                        getKeywordSegmentItems(keywordSegment).length === 0 ||
                        keywordSegmentAlreadySelected(keywordSegment)
                      "
                    >
                      @if (!keywordSegmentAlreadySelected(keywordSegment)) {
                        <span
                          class="select-button-box"
                          matTooltip="{{
                            t('add-keyword-tracking-modal.select_keyword_tooltip', [keywordSegment.name])
                          }}"
                          (click)="selectKeywordSegment(keywordSegment)"
                        >
                          <fa-icon
                            [icon]="isKeywordSegmentSelected(keywordSegment) ? faCheckedSquare : faSquare"
                            size="lg"
                          ></fa-icon>
                        </span>
                      }
                      @if (getKeywordSegmentItems(keywordSegment).length === 0) {
                        <span
                          class="select-button-box"
                          matTooltip="{{ t('add-keyword-tracking-modal.no_keyword_tooltip', [keywordSegment.name]) }}"
                        >
                          <fa-icon [icon]="faSquare" size="lg"></fa-icon>
                        </span>
                      }
                      @if (
                        getKeywordSegmentItems(keywordSegment).length > 0 &&
                        keywordSegmentAlreadySelected(keywordSegment)
                      ) {
                        <span
                          class="select-button-box"
                          matTooltip="{{
                            t('add-keyword-tracking-modal.already_tracked_error', [keywordSegment.name])
                          }}"
                        >
                          <fa-icon [icon]="faSquare" size="lg"></fa-icon>
                        </span>
                      }
                      <p
                        class="w-85 kw-segment-content inline-block pl-3"
                        (click)="openKeywordSegmentInfo(keywordSegment)"
                        matTooltip="{{
                          t('add-keyword-tracking-modal.open_segment_info_tooltip', {
                            count: getKeywordSegmentItems(keywordSegment).length,
                            name: keywordSegment.name,
                          })
                        }}"
                      >
                        {{ keywordSegment.name }}
                      </p>
                    </div>
                  </div>
                }
              </div>
              <div class="float-right">
                <span matTooltip="{{ t('add-keyword-tracking-modal.add_kw_from_segment') }}">
                  <button
                    class="btn btn-primary mx-1"
                    [disabled]="isKeywordSegmentAddButtonDisabled()"
                    (click)="addKeywordByKeywordSegment()"
                  >
                    {{ t("common.add") }}
                  </button>
                </span>
                <ng-content select="[productSegment]"></ng-content>
              </div>
            </div>
          </div>
        }
      </div>
      <div class="selection-arrow mx-2">
        <div class="flex h-full w-full items-center justify-center">
          <fa-icon
            [icon]="faArrowRight"
            class="arrow pointer"
            size="2x"
            (click)="selectionMode === SelectionModes.FromKeywordSegment ? addKeywordByKeywordSegment() : addKeyword()"
          ></fa-icon>
        </div>
      </div>
      <div class="border-shadow keyword-div w-1/2 overflow-auto">
        <mat-table [dataSource]="datasourceKeyword" class="responsive-table table-hover">
          <ng-container matColumnDef="keyword">
            <mat-cell *matCellDef="let keyword" class="flex flex-row justify-between">
              <div>{{ keyword }}</div>
              <div class="delete-content mr-3">
                <span
                  (click)="deleteKeyword(keyword)"
                  matTooltip="{{ t('add-keyword-tracking-modal.do_not_track') }}"
                  matTooltipPosition="left"
                >
                  <fa-icon [icon]="faTrash" class="font-xl"></fa-icon>
                </span>
              </div>
            </mat-cell>
          </ng-container>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer" *transloco="let t">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
    {{ t("common.cancel") }}
  </button>
  <button type="button" class="btn btn-primary" [disabled]="!canSave()" (click)="onSave()">
    {{ t("common.save") }}
  </button>
</div>
