import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AuthService, DataSet, UserSelectionService } from "@front/m19-services";
import { ActiveElement, Chart, ChartEvent, ChartOptions } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { BehaviorSubject, combineLatest } from "rxjs";
import { formatDate } from "@angular/common";
import { SovBrandDateType } from "../../share-of-voice/share-of-voice.component";
import { DateAggregation, Utils } from "@front/m19-utils";

@UntilDestroy()
@Component({
  selector: "app-sov-main-chart",
  templateUrl: "./sov-main-chart.component.html",
  styleUrls: ["./sov-main-chart.component.scss"],
})
export class SovMainChartComponent implements AfterViewInit {
  isBarChart = false;
  locale: string;
  selectedDateRange: string[] = new Array<string>(2);

  dateAggregation$: BehaviorSubject<DateAggregation> = new BehaviorSubject<DateAggregation>(DateAggregation.daily);

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  chartData = {
    labels: [],
    datasets: [],
  };

  @Input() set hoveredBrands(b: string) {
    for (let i = 0; i < this.chartData.datasets.length; i++) {
      const d = this.chartData.datasets[i];
      const brandColor = this.brandColors?.get(d.label);

      if (!b) {
        d.borderColor = d.backgroundColor = d.pointBackgroundColor = brandColor;
      } else if (d.label !== b) {
        const color = DataSet.addTransparency(brandColor, 10);
        d.borderColor = d.backgroundColor = d.pointBackgroundColor = color;
      }
    }
    this.chart?.update();
  }

  @Input() brandColors: Map<string, string>;

  @Input() set brandSelection(s: string[]) {
    this._brandSelection = s;
    this.updateChart();
  }

  _brandSelection: string[];

  @Input() set sovData(d: SovBrandDateType) {
    this._sovData = d;
    this.updateChart();
  }

  @Input() loading: boolean;

  @Output() emitSnapshotDate = new EventEmitter<Date>();

  private _sovData: SovBrandDateType;

  readonly lineChartOptions: ChartOptions = {
    //aspectRatio: 3,
    responsive: true,
    interaction: !this.isBarChart ? { axis: "x", intersect: false } : {},
    plugins: {
      datalabels: { display: false },
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.dataset.label + ": " + parseFloat(tooltipItem.parsed.y.toFixed(1)) + "%";
          },
          afterBody: function () {
            return "\nClick to set snapshot date";
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          color: "#cccccc",
          callback: function (item) {
            return item + "%";
          },
        },
      },
      x: {
        grid: { display: false },
        ticks: {
          color: "#cccccc",
          callback: (value: number) => {
            const date: string = this.chartData.labels[value];
            return date && this.locale ? formatDate(date, "MM/dd", this.locale) : "--/--";
          },
        },
      },
    },
    onClick: (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
      const labels: string[] = chart.data.labels as string[];
      const xLabel = chart.scales.x.getValueForPixel(event.x);
      const fullDate: Date = new Date(labels[xLabel]);

      this.emitSnapshotDate.emit(fullDate);
    },
  };

  constructor(
    private authService: AuthService,
    private userSelectionService: UserSelectionService,
  ) {}

  ngAfterViewInit(): void {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user: any) => (this.locale = user.locale));

    combineLatest<[string[], DateAggregation]>([this.userSelectionService.dateRange$, this.dateAggregation$]).subscribe(
      ([range, _agg]) => {
        this.selectedDateRange = range.map((d: string) => new Date(d).toDateString());
        this.updateChart();
      },
    );
  }

  private updateChart() {
    // Build chart labels
    const dateArray = this.buildDateRange(new Date(this.selectedDateRange[0]), new Date(this.selectedDateRange[1]));

    // Build new data
    const newLabels = this.buildChartLabels(dateArray);

    const newDatasets = this.buildDataSet(this._sovData, this._brandSelection);

    this.chartData = { labels: newLabels, datasets: newDatasets };
    this.chart?.update();
  }

  private buildDataSet(data: Map<string, Map<string, number>>, brandSelection: string[]) {
    const newData = [];

    brandSelection?.forEach((b: string, index: number) => {
      const brandColor = this.brandColors?.get(b);
      newData.push({
        label: b,
        data: [],
        tension: 0,
        backgroundColor: brandColor,
        borderColor: brandColor,
        pointBackgroundColor: brandColor,
        hoverBackgroundColor: brandColor,
      });
    });

    brandSelection?.forEach((brand: string, index: number) => {
      const sovByDates = data.get(brand);
      const aggregatedData: Map<string, number> = this.reduceMapByAggregation(
        sovByDates,
        this.dateAggregation$.getValue(),
      );

      const chartPoints: any[] = [];
      for (const [date, sov] of aggregatedData) {
        chartPoints.push({ x: date, y: sov });
      }

      newData[index].data.push(...chartPoints);
    });

    return newData;
  }

  // Computes mean of sov depending on date aggregation
  private reduceMapByAggregation(map: Map<string, number>, agg: DateAggregation): Map<string, number> {
    if (!map) return new Map<string, number>();
    const resultedMap = new Map<string, number[]>();
    for (const [date, sov] of map) {
      // Check for date in range
      if (new Date(date) < new Date(this.selectedDateRange[0]) || new Date(date) > new Date(this.selectedDateRange[1]))
        continue;

      const roundedDate = this.getRoundedDate(new Date(date));
      if (!resultedMap.get(roundedDate)) resultedMap.set(roundedDate, [sov]);
      else resultedMap.get(roundedDate).push(sov);
    }

    return new Map<string, number>(
      Array.from(resultedMap.entries()).map(([roundedDate, values]) => {
        const mean: number = values.reduce((sum, value) => sum + value, 0) / values.length;
        return [roundedDate, mean];
      }),
    );
  }

  // Return array filled with correct dates between minDate and maxDate (included)
  private buildDateRange(minDate: Date, maxDate: Date) {
    const dateArray = new Array<Date>();
    const currDate = minDate;
    while (currDate <= maxDate) {
      dateArray.push(new Date(currDate));
      currDate.setDate(currDate.getDate() + 1);
    }

    return dateArray;
  }

  private getRoundedDate(date: Date): string {
    const formatted = Utils.formatDateForApi(date);
    let rounded: Date;

    switch (this.dateAggregation$.getValue()) {
      case DateAggregation.daily:
        return date.toDateString();
      //return formatDate(date, "dd/MM/yyyy", this.locale);
      case DateAggregation.weekly:
        rounded = new Date(Utils.roundDateByWeek(this.selectedDateRange[0], formatted));
        return rounded.toDateString();
      //return formatDate(rounded.toDateString(), "dd/MM/yyyy", this.locale);
      case DateAggregation.monthly:
        rounded = new Date(Utils.roundDateByMonth(this.selectedDateRange[0], formatted));
        return rounded.toDateString();
      //return formatDate(rounded.toDateString(), "dd/MM/yyyy", this.locale);
    }
  }

  private buildChartLabels(dateArray: Date[]) {
    const labels = [];
    for (const date of dateArray) {
      const rounded = this.getRoundedDate(date);
      if (labels.indexOf(rounded) === -1) labels.push(rounded);
    }
    return labels;
  }

  changeChartType(isBarChart?: boolean) {
    if (isBarChart !== undefined) this.isBarChart = isBarChart;
    else this.isBarChart = !this.isBarChart;

    this.lineChartOptions.scales = {
      x: {
        stacked: this.isBarChart,
        grid: { display: false },
        ticks: {
          color: "#cccccc",
          callback: (value: number) => {
            const date: string = this.chartData.labels[value];
            return date ? formatDate(date, "MM/dd", this.locale) : "--/--";
          },
        },
      },
      y: {
        stacked: this.isBarChart,
        ticks: {
          color: "#cccccc",
          callback: function (item) {
            return item + "%";
          },
        },
      },
    };

    this.lineChartOptions.interaction = !this.isBarChart ? { axis: "x", intersect: false } : {};

    this.chart.update();
  }

  selectAggregation(aggregation: DateAggregation): void {
    this.dateAggregation$.next(aggregation);
  }
}
