import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AuthService } from "@front/m19-services";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { delay } from "rxjs/operators";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";

@UntilDestroy()
@Component({
  selector: "app-admin-login",
  template: "<app-spinner [display]='true' [main]='true' ></app-spinner>",
  standalone: true,
  imports: [SpinnerComponent],
})
export class AdminLoginComponent implements OnInit {
  private readonly redirectToLoginPage = new Subject();

  constructor(
    private authService: AuthService,
    private toasterService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params["token"]) {
        const token = params["token"];
        const accountId: string = params["accountId"];
        const marketplace: string = params["marketplace"];
        const orgId: string = params["orgId"];
        const redirect: string = params["redirect"];

        this.authService.setAccessToken(token, 3600, true);
        this.authService
          .isLoggedIn()
          .pipe(untilDestroyed(this))
          .subscribe((logged) => {
            if (logged) {
              this.authService.reloadUser(
                (_) => {
                  if (redirect) {
                    this.router.navigateByUrl(redirect);
                  } else if (accountId && marketplace && orgId) {
                    this.router.navigate(["dashboard"], {
                      queryParams: { accountId, marketplace, orgId },
                    });
                  } else {
                    this.router.navigate(["dashboard"]);
                  }
                },
                (error: string) => {
                  this.toasterService.error("Error: " + error, "Login");
                  this.redirectToLoginPage.next(0);
                  this.redirectToLoginPage.complete();
                },
                true,
              );
            } else {
              this.toasterService.error("Invalid admin login", "Admin Login");
              this.redirectToLoginPage.next(0);
              this.redirectToLoginPage.complete();
            }
          });

        this.redirectToLoginPage.pipe(untilDestroyed(this), delay(1000)).subscribe(() => {
          this.router.navigate(["login"]);
        });
      }
    });
  }
}
