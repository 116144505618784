import { AgGridAngular } from "@ag-grid-community/angular";
import { ColDef, GridOptions } from "@ag-grid-community/core";
import { CommonModule } from "@angular/common";
import { Component, inject, OnInit, signal, TemplateRef, ViewChild } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import {
  AccessLevel,
  AccountMarketplace,
  Strategy,
  StrategyAsin,
  StrategyStateEnum,
  TacosStrategyGroup,
  TacosStrategyGroupApi,
  TacosStrategyGroupStateEnum,
  UpdateTacosStrategyGroupRequest,
} from "@front/m19-api-client";
import { getBasicGridOptions, getMetricsColDef } from "@front/m19-grid-config";
import { AD_SALES, COST } from "@front/m19-metrics";
import { AdStatsEx } from "@front/m19-models";
import {
  AccountSelectionService,
  AuthService,
  SpStrategiesService,
  StrategyService,
  UserSelectionService,
} from "@front/m19-services";
import { IAlertComponent, IBadgeComponent, IButtonComponent, IInputComponent } from "@front/m19-ui";
import { addAdStats } from "@front/m19-utils";
import { TranslocoService } from "@jsverse/transloco";
import { ImageColumn } from "@m19-board/grid-config/grid-columns";
import { STATUS_BAR } from "@m19-board/grid-config/grid-config";
import { MetricEvoComponent } from "@m19-board/metric-evo/metric-evo.component";
import { TACOS, TOTAL_SALES } from "@m19-board/models/MetricsDef";
import { ProductThumbnailComponent } from "@m19-board/product-view/product-thumbnail.component";
import { StrategyInfoBlocComponent } from "@m19-board/shared/strategy-info-bloc/strategy-info-bloc.component";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { ICON_CHEVRON_DOWN, ICON_PAUSE, ICON_PLAY } from "@m19-board/utils/iconsLabels";
import { TacosStrategiesService } from "libs/m19-services/src/lib/m19-services/tacos-strategies.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { combineLatest, map, of, switchMap, tap } from "rxjs";
import { AsinsSelectionComponent } from "../strategy-asins/asins-selection.component";
import { StrategyAsinsComponent } from "../strategy-asins/strategy-asins.component";

enum SECTIONS {
  PRODUCTS = "products",
  CAMPAIGNS = "campaigns",
  STATS = "stats",
}

@Component({
  selector: "tacos-strategy-page",
  standalone: true,
  imports: [
    CommonModule,
    StrategyInfoBlocComponent,
    IBadgeComponent,
    TranslocoRootModule,
    IButtonComponent,
    SpinnerComponent,
    RouterModule,
    StrategyAsinsComponent,
    AgGridAngular,
    IInputComponent,
    IAlertComponent,
    AsinsSelectionComponent,
  ],
  templateUrl: "./tacos-strategy-page.component.html",
})
export class TacosStrategyPageComponent implements OnInit {
  readonly ICON_CHEVRON_D = ICON_CHEVRON_DOWN;
  readonly ICON_PLAY = ICON_PLAY;
  readonly ICON_PAUSE = ICON_PAUSE;

  private readonly METRICS = [TOTAL_SALES, AD_SALES, COST, TACOS];

  readonly TacosStrategyGroupState = TacosStrategyGroupStateEnum;
  readonly StrategyState = StrategyStateEnum;
  private router = inject(Router);
  readonly SECTIONS = SECTIONS;

  private readonly modalService = inject(BsModalService);
  private authService = inject(AuthService);
  private accountSelection = inject(AccountSelectionService);
  private tacosStrategiesService = inject(TacosStrategiesService);
  private tacosStrategyGroupApi = inject(TacosStrategyGroupApi);
  private route = inject(ActivatedRoute);
  private userSelectionService = inject(UserSelectionService);
  private translocoService = inject(TranslocoService);
  private toastrService = inject(ToastrService);

  private spService = inject(SpStrategiesService);
  private strategyService = inject(StrategyService);

  @ViewChild("grid") private grid!: AgGridAngular;
  private currency = toSignal(this.userSelectionService.selectedCurrency$);
  private locale = toSignal(this.authService.loggedUser$.pipe(map((user) => user.locale)));
  am: AccountMarketplace | undefined;
  strategyGroup: TacosStrategyGroup | undefined;
  tacosStrategyGroupId = signal<number | undefined>(undefined);
  spStrategy: Strategy | undefined;

  loading = signal(false);

  allAsins = signal<StrategyAsin[]>([]);

  // asins that are used in other strategies and cannot be added to the strategy
  disabledAsins = signal<Map<string, { status: boolean; reason: string }>>(new Map());

  sectionState: { [key in SECTIONS]: boolean } = {
    [SECTIONS.PRODUCTS]: false,
    [SECTIONS.CAMPAIGNS]: true,
    [SECTIONS.STATS]: true,
  };

  statsByAsin: AdStatsEx[] = [];
  statsByAsinAll: AdStatsEx[] = [];

  tacosTargetInput = signal<number | undefined>(undefined);
  strategyNameInput = signal<string | undefined>(undefined);

  isReadOnly = toSignal(
    this.accountSelection.singleAccountMarketplaceSelection$.pipe(map((am) => am.accessLevel === AccessLevel.READ)),
  );

  private columnDefs: ColDef[] = [
    {
      ...ImageColumn,
      headerName: this.translocoService.translate("catalog-page.image", {}, "en"),
      headerValueGetter: (params) => this.translocoService.translate("catalog-page.image"),
      cellRendererSelector: (params) => {
        if (!params.value) return undefined;
        else {
          return {
            component: ProductThumbnailComponent,
            params: {
              asin: params.value,
              smallImg: true,
              withTitle: true,
              marketplace: this.am!.marketplace,
              customSizeClass: "size-16",
            },
          };
        }
      },
    },
    ...getMetricsColDef(this.METRICS, false, MetricEvoComponent, {
      locale: this.locale(),
      currency: this.currency(),
    }),
  ];

  gridOptions: GridOptions = {
    ...getBasicGridOptions("tacos-strategy", true),
    rowData: this.statsByAsin,
    columnDefs: this.columnDefs,
    statusBar: STATUS_BAR,
  };

  @ViewChild("targetModal") targetModal!: TemplateRef<any>;
  @ViewChild("editNameModal") editNameModal!: TemplateRef<any>;
  @ViewChild("editAsinsModal") editAsinsModal!: TemplateRef<any>;

  ngOnInit() {
    this.loading.set(true);

    combineLatest([
      this.accountSelection.singleAccountMarketplaceSelection$,
      this.userSelectionService.selectedCurrency$,
      this.userSelectionService.selectedDateRange$,
      this.userSelectionService.periodComparison$,
      this.route.paramMap,
    ])
      .pipe(
        tap(([_am, _currency, _dr, _periodComparison, params]) => {
          this.tacosStrategyGroupId.set(Number(params.get("id")));
        }),
        switchMap(([am, currency, dr, periodComparison, params]) =>
          this.tacosStrategiesService.getStats(am, currency, dr, periodComparison?.period),
        ),
      )
      .subscribe((stats: Map<string, AdStatsEx[]>) => {
        const byAsinAll = new Map<string, AdStatsEx>();
        const byAsinStrat = new Map<string, AdStatsEx>();

        for (const [asin, s] of stats.entries()) {
          const mergedAll = s.reduce((acc, curr) => addAdStats(acc, curr), {});
          byAsinAll.set(asin, mergedAll);

          const filtered = s.filter((s) => s.tacosStrategyGroupId === this.tacosStrategyGroupId());
          if (!filtered.length) continue;
          const mergedStrat = filtered.reduce((acc, curr) => addAdStats(acc, curr), {});

          mergedStrat.asin = asin;
          byAsinStrat.set(asin, mergedStrat);
        }

        this.statsByAsin = Array.from(byAsinStrat.values());
      });

    combineLatest([this.accountSelection.singleAccountMarketplaceSelection$, this.route.paramMap])
      .pipe(
        tap(([am, _]) => {
          this.am = am;
        }),
        switchMap(([_am, params]) =>
          this.tacosStrategiesService.getTacosStrategyGroupById(
            this.am!.accountId,
            this.am!.marketplace,
            Number(params.get("id")),
          ),
        ),
        tap((tacosStrategyGroup) => {
          if (tacosStrategyGroup) {
            this.strategyGroup = tacosStrategyGroup;
          } else {
            // TODO: redirect to the list of tacos strategies
            this.router.navigate(["/advertising/tacos-strategies"]);
          }
          this.loading.set(false);
        }),
        switchMap((tacosStrategyGroup) =>
          combineLatest([
            this.tacosStrategiesService.getSpTacosStrategy(
              this.am!.accountId,
              this.am!.marketplace,
              tacosStrategyGroup.tacosStrategyGroupId!,
            ),
            of(undefined), // Future SB strategy
            of(undefined), // Future SD strategy
          ]),
        ),
      )
      .subscribe(
        ([spStrategy, _sbStrategy, _sdStrategy]: [
          Strategy | undefined,
          Strategy | undefined,
          Strategy | undefined,
        ]) => {
          this.spStrategy = spStrategy;
          this.allAsins.set(spStrategy?.asins ?? []);
        },
      );
  }

  hideModal() {
    this.modalService.hide();
  }

  openTargetModal() {
    this.modalService.show(this.targetModal, { class: "modal-primary modal-dialog-centered" });
  }

  updateTacosTarget() {
    if (this.tacosTargetInput() && this.strategyGroup) {
      if (this.tacosTargetInput()! <= 0 || this.tacosTargetInput()! > 100) {
        this.toastrService.error("TACOS target must be between 0 and 100");
        return;
      }

      const updateParams: UpdateTacosStrategyGroupRequest = {
        accountId: this.am!.accountId,
        marketplace: this.am!.marketplace,
        tacosTarget: this.tacosTargetInput()! / 100,
        state: this.strategyGroup!.state,
        tacosStrategyGroupId: this.tacosStrategyGroupId()!,
      };

      this.tacosStrategyGroupApi.updateTacosStrategyGroup(updateParams).subscribe(() => {
        this.toastrService.success("TACOS target updated");
        this.hideModal();
        this.strategyGroup!.tacosTarget = updateParams.tacosTarget;
        this.tacosTargetInput.set(undefined);
      });
    }
  }

  openEditNameModal() {
    this.modalService.show(this.editNameModal, { class: "modal-primary modal-dialog-centered" });
  }

  updateStrategyName() {
    if (!this.strategyNameInput()) return;
    if (this.strategyNameInput() && this.spStrategy) {
      this.strategyService
        .updateStrategyName(
          this.am!.accountId,
          this.am!.marketplace,
          this.spStrategy.strategyId!,
          this.strategyNameInput()!,
        )
        .subscribe((s: Strategy) => {
          this.spStrategy = s;
          this.toastrService.success("Strategy name updated");
          this.hideModal();
          this.strategyNameInput.set(undefined);
        });
    }
  }

  openEditAsinsModal() {
    this.strategyService.getStrategyIndex(this.am!.accountId, this.am!.marketplace).subscribe((index) => {
      const asinsUsedInOtherStrategies = new Set(
        Array.from(index.values())
          .flatMap((s) => s.asins?.map((a) => a.asin) ?? [])
          .filter((asin) => !this.allAsins().some((a) => a.asin === asin)),
      );

      const disabledAsins = new Map<string, { status: boolean; reason: string }>();

      for (const asin of asinsUsedInOtherStrategies) {
        disabledAsins.set(asin, { status: false, reason: "ASINs used in another strategy" });
      }
      this.disabledAsins.set(disabledAsins);
    });

    this.modalService.show(this.editAsinsModal, { class: "modal-primary modal-dialog-centered modal-xl" });
  }

  updateAsins(asins: StrategyAsin[], mode: "add" | "remove" = "add") {
    const asinsToUpdate = asins.map((a) => a.asin);

    if (mode === "add") {
      this.strategyService.addAsinsToStrategy(this.spStrategy!, asinsToUpdate).subscribe(() => {
        this.toastrService.success("ASINs successfully added");
      });
    } else {
      this.strategyService.deleteAsinsFromStrategy(this.spStrategy!, asinsToUpdate).subscribe(() => {
        this.toastrService.success("ASINs successfully removed");
      });
    }
  }
}
