import { formatPercent } from "@angular/common";
import { Component, Input } from "@angular/core";
import { ChartDataset, ChartOptions, LegendItem } from "chart.js";
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels";
import { QueryStats } from "../models/Metric";
import { Metric, MetricType } from "@front/m19-metrics";
import { Currency } from "@front/m19-api-client";
import { TranslocoService } from "@jsverse/transloco";

@Component({
  selector: "app-traffic-stats-chart",
  templateUrl: "./traffic-stats-chart.component.html",
  styleUrls: ["./traffic-stats-chart.component.scss"],
})
export class TrafficStatsChartComponent {
  readonly barChartLabels: string[] = [
    this.translocoService.translate("traffic-stats-chart.filtered_traffic"),
    this.translocoService.translate("traffic-stats-chart.rest_of_traffic"),
  ];
  readonly RATIO = MetricType.RATIO;

  readonly chartPlugins = [ChartDataLabels];

  readonly horizontalBarOptions: ChartOptions = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    scales: {
      xAxis: {
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: false,
        },
        beginAtZero: true,

        ticks: {
          maxTicksLimit: 6,
          padding: 4,
          callback: (value, index, values) => this.metric.format(value as number, this.locale, this.currency),
        },
      },
      yAxis: {
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: "bottom",
      },
      tooltip: {
        padding: 10,
        mode: "index",
        xAlign: "left",
        callbacks: {
          title: (tooltipItem) => tooltipItem[0].label,
          label: (tooltipItem) => {
            return `${tooltipItem.dataset.label}: ${this.metric.format(
              tooltipItem.raw as number,
              this.locale,
              this.currency,
            )} ${this.translocoService.translate("metrics." + this.metric.id + "_title")}`;
          },
        },
      },
    },
  };

  readonly pieChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: "auto",
        color: (ctx) => (this.computePercent(ctx) < 0.08 ? "#181d4d" : "white"),
        anchor: (ctx) => (this.computePercent(ctx) < 0.08 ? "start" : "center"),
        align: (ctx) => (this.computePercent(ctx) < 0.08 ? "start" : "center"),
        formatter: (value, ctx) => {
          const percent = this.computePercent(ctx);
          return percent ? formatPercent(this.computePercent(ctx), this.locale, "1.1-1") : "";
        },
      },
      legend: {
        display: true,
        position: "left",
        labels: {
          padding: 30,
          generateLabels: (chart) => {
            const pieChartLabels: string[] = [
              this.translocoService.translate("traffic-stats-chart.filtered_traffic"),
              this.translocoService.translate("traffic-stats-chart.rest_of_traffic"),
              this.translocoService.translate("traffic-stats-chart.kw"),
              this.translocoService.translate("traffic-stats-chart.targeted_product"),
              this.translocoService.translate("dsp-stats.dspCampaignDeliveryStatus_OTHER"),
            ];
            const colors = chart.data.datasets[0].backgroundColor;
            return pieChartLabels.map(
              (element, i) =>
                <LegendItem>{
                  text: element,
                  datasetIndex: 0,
                  fillStyle: colors[i],
                  strokeStyle: colors[i],
                },
            );
          },
        },
        onClick: null,
      },
      tooltip: {
        caretSize: 0,
        displayColors: false,
        callbacks: {
          title: (tooltipItem) => {
            if (tooltipItem[0].dataIndex === 0) {
              return tooltipItem[0].label as string;
            }
            if (tooltipItem[0].dataIndex === 2) {
              return this.translocoService.translate("traffic-stats-chart.filtered_kw");
            }
            if (tooltipItem[0].dataIndex === 3) {
              return this.translocoService.translate("traffic-stats-chart.filtered_targeted_product");
            }
            if (tooltipItem[0].dataIndex === 4) {
              return this.translocoService.translate("traffic-stats-chart.filtered_other");
            }
            if (tooltipItem[0].dataIndex === 5) {
              return this.translocoService.translate("traffic-stats-chart.rest_kw");
            }
            if (tooltipItem[0].dataIndex === 6) {
              return this.translocoService.translate("traffic-stats-chart.rest_targeted_product");
            }
            return "";
          },
          label: (tooltipItem) =>
            this.metric.format(tooltipItem.raw as number, this.locale, this.currency) +
            " " +
            this.translocoService.translate(`metrics.${this.metric.id}_title`),
        },
      },
    },
    aspectRatio: 4 / 3,
  };

  @Input()
  loader: boolean;
  @Input()
  metric: Metric<QueryStats>;
  @Input()
  barChartData: ChartDataset[] = [];
  @Input()
  pieChartData: ChartDataset[] = [];
  @Input()
  currency: Currency;
  @Input()
  locale: string;

  constructor(private translocoService: TranslocoService) {}

  private computePercent(ctx: Context): number {
    const meta: any = ctx.chart.getDatasetMeta(ctx.datasetIndex);
    return (ctx.dataset.data[ctx.dataIndex] as number) / meta.total;
  }
}
