import { Component, EventEmitter, Input, input, OnInit, Output, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { IButtonComponent, IInputComponent } from '@front/m19-ui';
import { TranslocoDirective } from '@jsverse/transloco';
import { TranslocoService } from '@jsverse/transloco';
export interface LoginForm {
  email: FormControl<string | null>;
  password: FormControl<string | null>;
}

export interface LoginFormValue {
  email: string;
  password: string;
}

type LoginFormError = Partial<Record<keyof LoginForm, string | null>>;

@Component({
  selector: 'lib-login-form',
  standalone: true,
  imports: [ReactiveFormsModule, IButtonComponent, IInputComponent, TranslocoDirective],
  templateUrl: './login-form.component.html',
})
export class LoginFormComponent implements OnInit {
  constructor(private translocoService: TranslocoService) {}
  readonly loginForm = new FormGroup<LoginForm>({
    email: new FormControl(null, {
      nonNullable: true,
      validators: [Validators.required, Validators.email, Validators.pattern(/.*@.+\..+/)],
    }),
    password: new FormControl(null, {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  forgottenPasswordLink = input<string>('/request-password-reset');

  formErrors = signal<LoginFormError>({});

  @Input() isLoading = false;
  @Output() login = new EventEmitter<LoginFormValue>();

  ngOnInit(): void {
    this.loginForm.valueChanges.pipe(debounceTime(200)).subscribe(() => {
      if (this.loginForm.invalid) {
        this.formErrors.set({
          email:
            this.loginForm.controls.email.errors?.['email'] || this.loginForm.controls.email.errors?.['pattern']
              ? this.translocoService.translate('profile-settings.invalid_email')
              : null,
        });
      }
    });
  }

  onSubmit() {
    this.login.emit(this.loginForm.value as LoginFormValue);
  }
}
