import { AgGridAngular } from "@ag-grid-community/angular";
import {
  ColDef,
  ColGroupDef,
  GridOptions,
  ICellRendererParams,
  IRowNode,
  ITooltipParams,
} from "@ag-grid-community/core";
import { CommonModule } from "@angular/common";
import { Component, computed, inject, OnInit, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { AccountMarketplace, Currency, Marketplace, Strategy } from "@front/m19-api-client";
import {
  getBasicGridOptions,
  getFilteredLeafNodes,
  PARENT_ASIN_COL_NAME,
  ProductGridData,
} from "@front/m19-grid-config";
import { Metric, MetricRegistry } from "@front/m19-metrics";
import { AdStatsEx, BrandingFilter, InventoryStats, ProductGroupEx, StrategyEx } from "@front/m19-models";
import {
  AccountSelectionService,
  AsinService,
  AuthService,
  CatalogBrandService,
  DataSet,
  MetricsSelectorLocalStorageKey,
  ProductGroupService,
  SbStrategiesService,
  SdStrategiesService,
  SpStrategiesService,
  StatsService,
  StrategyService,
  UserSelectionService,
} from "@front/m19-services";
import { ModalOptions, ModalService, Option } from "@front/m19-ui";
import {
  AggregationFunction,
  convertToCurrency,
  DateAggregation,
  emptyAdStatEx,
  emptyVendorAdStatEx,
  mergeSeveralDates,
  mergeVendorAdStatsExByAsin,
} from "@front/m19-utils";
import { TranslocoService } from "@jsverse/transloco";
import { CurrencyColumn, ImageColumn } from "@m19-board/grid-config/grid-columns";
import { getMetricsColDef } from "@m19-board/grid-config/grid-config";
import { ActionButtonsComponent } from "@m19-board/insights/advertising-stats/action-buttons/action-buttons.component";
import { MetricEvoComponent } from "@m19-board/metric-evo/metric-evo.component";
import {
  FBM_STOCK,
  FBM_STOCK_VALUE,
  FULFILLABLE_STOCK,
  FULFILLABLE_STOCK_VALUE,
  INBOUND_STOCK,
  INBOUND_STOCK_VALUE,
  ORDERS_30D,
  ORDERS_7D,
  RESERVED_STOCK,
  RESERVED_STOCK_VALUE,
  UNFULFILLABLE_STOCK,
  UNFULFILLABLE_STOCK_VALUE,
  VENDOR_AGED90P_SELLABLE_COST,
  VENDOR_AGED90P_SELLABLE_UNITS,
  VENDOR_OPEN_PURCHASE_ORDER_UNITS,
  VENDOR_OUT_OF_STOCK_VIEWS,
  VENDOR_SELLABLE_COST,
  VENDOR_SELLABLE_UNITS,
  VENDOR_SOROOS,
  VENDOR_UNSELLABLE_COST,
  VENDOR_UNSELLABLE_UNITS,
} from "@m19-board/models/MetricsDef";
import { AsinLinkComponent } from "@m19-board/product-view/asin-link.component";
import { ProductThumbnailComponent } from "@m19-board/product-view/product-thumbnail.component";
import {
  constantField,
  CsvExportService,
  fieldExtractor,
  metricField,
  simpleField,
} from "@m19-board/services/csv-export.service";
import {
  ChartData,
  ChartModalComponent,
  ChartModalInputData,
} from "@m19-board/shared/chart-modal/chart-modal.component";
import { AsinFilter, FilterCategory } from "@m19-board/shared/filter/asinFilter";
import { Filter } from "@m19-board/shared/filter/filter.component";
import {
  StatsComponent,
  StatsComponentGridConfig,
  StatsComponentMetricConfig,
  StatsFilter,
} from "@m19-board/stats/stats.component";
import { ICON_CHART_LINE } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ToastrService } from "ngx-toastr";
import { combineLatest, forkJoin, map, Observable, of, shareReplay, switchMap, tap } from "rxjs";

@Component({
  selector: "app-vendor-inventory",
  standalone: true,
  imports: [CommonModule, StatsComponent],
  templateUrl: "./vendor-inventory.component.html",
})
@UntilDestroy()
export class VendorInventoryComponent implements OnInit {
  private readonly statsService = inject(StatsService);
  private readonly userSelectionService = inject(UserSelectionService);
  private readonly accountSelection = inject(AccountSelectionService);
  private readonly authService = inject(AuthService);
  private readonly asinService = inject(AsinService);
  private readonly translocoService = inject(TranslocoService);
  private readonly modalService = inject(ModalService);

  private readonly METRICS = [
    VENDOR_SELLABLE_COST,
    VENDOR_SELLABLE_UNITS,
    VENDOR_UNSELLABLE_UNITS,
    VENDOR_UNSELLABLE_COST,
    VENDOR_OPEN_PURCHASE_ORDER_UNITS,
    VENDOR_AGED90P_SELLABLE_UNITS,
    VENDOR_AGED90P_SELLABLE_COST,
    VENDOR_SOROOS,
    VENDOR_OUT_OF_STOCK_VIEWS,
  ];
  protected readonly localStorageKey: MetricsSelectorLocalStorageKey = MetricsSelectorLocalStorageKey.vendorInventory;
  loading = signal(true);
  selectedMetrics = signal<Metric<AdStatsEx>[]>([]);
  selectedDateRange = toSignal(this.userSelectionService.dateRange$, { requireSync: true });

  grid = signal<AgGridAngular | undefined>(undefined);

  private readonly _dataSet = this.initDataset();

  productGroups: ProductGroupEx[] = [];
  strategies: StrategyEx[] = [];
  customField1Values: Option<string>[] = [];
  customField2Values: Option<string>[] = [];
  customFiel1dMap: Map<string, string[]> = new Map();
  customField2Map: Map<string, string[]> = new Map();
  brandTrafficOptions: Option<BrandingFilter>[] = [];
  stratAsinMap: Map<number, string[]> = new Map();
  asinStratMap: Map<string, Strategy[]> = new Map();

  filter: Set<string> | null = null;
  asinFilterTags = new AsinFilter(this.translocoService);
  // TODO: mutualize common logic with seller-inventory
  filters = computed(() => {
    return this.asinFilterTags.getFilterTags(this.selectedFilters());
  });
  selectedFilters = signal<Filter<StatsFilter>[]>([]);
  selectedFilterValue = signal<Set<string> | null>(null);

  private dailyInventory$ = combineLatest([
    this.accountSelection.singleAccountMarketplaceSelection$,
    this.userSelectionService.dateRange$,
    this.userSelectionService.selectedCurrency$,
  ]).pipe(
    tap(() => this.loading.set(true)),
    switchMap(([am, dr, currency]) =>
      forkJoin([
        this.statsService.getDailyVendorInventory(am.accountId, am.marketplace, dr[0], dr[1], !!am.useSourcingMetrics),
        of(currency),
        of(am.marketplace),
      ]),
    ),
    map(([data, currency, marketplace]) => convertToCurrency(data, currency, marketplace) ?? []),
    tap(() => this.loading.set(false)),
    shareReplay(1),
  );
  dailyInventory = toSignal<AdStatsEx[]>(this.dailyInventory$);
  dailyInventoryByAsin = computed(() => mergeVendorAdStatsExByAsin(this.dailyInventory() ?? []));

  totalInventorySales = computed(() => {
    this.selectedFilterValue();
    if (!this.dailyInventory()?.length) return emptyVendorAdStatEx();
    return this.dailyInventory()
      ?.filter((data) => AsinFilter.asinMatchesFilter(data.asin ?? "", this.selectedFilterValue()))
      .reduce<AdStatsEx>((acc, curr) => mergeSeveralDates(acc, curr), emptyAdStatEx());
  });
  dataSet = computed(() => {
    this.selectedFilterValue();
    this._dataSet.currency = this.currency();

    this._dataSet.buildDataSet(
      this.dailyInventory()?.filter((data) =>
        AsinFilter.asinMatchesFilter(data.asin ?? "", this.selectedFilterValue()),
      ) ?? [],
      this.selectedMetrics(),
      this.dateAggregation(),
      {
        minDate: this.selectedDateRange()[0],
        maxDate: this.selectedDateRange()[1],
      },
    );
    return this._dataSet;
  });

  currency = toSignal(this.userSelectionService.selectedCurrency$, { requireSync: true });
  accountMarketplace = toSignal(this.accountSelection.singleAccountMarketplaceSelection$);
  locale = toSignal(this.authService.loggedUser$.pipe(map((u) => u.locale)), { initialValue: "" });
  catalog = toSignal(
    this.accountSelection.singleAccountMarketplaceSelection$.pipe(
      switchMap((am) => this.asinService.getCatalog(am.accountId, am.marketplace)),
    ),
  );

  dateAggregation = signal<DateAggregation>(DateAggregation.daily);
  metricsConfig = computed<StatsComponentMetricConfig<AdStatsEx>>(() => {
    return {
      data: this.totalInventorySales(),
      metrics: this.METRICS,
    };
  });
  gridConfig = computed<StatsComponentGridConfig<AdStatsEx>>(() => {
    return {
      gridData: this.dailyInventoryByAsin(),
      columnDefs: this.getColumnDefs(this.currency(), this.locale(), this.accountMarketplace()?.marketplace),
      gridOptions: this.getGridOptions(),
    };
  });

  constructor(
    private productGroupService: ProductGroupService,
    private strategyService: StrategyService,
    private catalogBrandService: CatalogBrandService,
    private spStrategiesService: SpStrategiesService,
    private sbStrategiesService: SbStrategiesService,
    private sdStrategiesService: SdStrategiesService,
    private csvExportService: CsvExportService,
    private toasterService: ToastrService,
  ) {}

  ngOnInit() {
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am: AccountMarketplace) =>
          combineLatest([
            this.productGroupService.getProductGroups(am.accountId, am.marketplace),
            this.strategyService.getStrategyIndex(am.accountId, am.marketplace),
            this.catalogBrandService.getBrandingFilters(am.accountId, am.marketplace),
          ]),
        ),
        untilDestroyed(this),
      )
      .subscribe(([pgs, strategies, brandingFilters]) => {
        this.strategies = Array.from(strategies.values()).map((s) => new StrategyEx(s));
        this.productGroups = pgs;
        this.brandTrafficOptions = brandingFilters.map((b) => ({ label: b.name ?? "", value: b }));
        this.asinFilterTags.setProductGroups(this.productGroups);
        this.asinFilterTags.setStrategies(this.strategies);
        this.asinFilterTags.setBrandTrafficOptions(this.brandTrafficOptions);
      });
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am: AccountMarketplace) => {
          return combineLatest([
            this.spStrategiesService.getSPStrategiesPerAsin(am.accountId, am.marketplace),
            this.sbStrategiesService.getSBStrategiesPerAsin(am.accountId, am.marketplace),
            this.sdStrategiesService.getSDStrategiesPerAsin(am.accountId, am.marketplace),
            this.asinService.getCatalog(am.accountId, am.marketplace),
          ]);
        }),
        switchMap(([spAsinStratIndex, sbAsinStratIndex, sdAsinStratIndex, catalog]) => {
          const customField1Values = new Set<string>();
          const customField2Values = new Set<string>();
          for (const product of catalog.products) {
            if (product.productTag1) customField1Values.add(product.productTag1);
            if (product.productTag2) customField2Values.add(product.productTag2);
          }
          this.customField1Values = Array.from(customField1Values).map((v) => ({ label: v, value: v }));
          this.customField2Values = Array.from(customField2Values).map((v) => ({ label: v, value: v }));
          // map custom field values to asin
          this.customFiel1dMap.clear();
          this.customField2Map.clear();
          for (const product of catalog.products) {
            if (product.productTag1) {
              if (!this.customFiel1dMap.has(product.productTag1)) {
                this.customFiel1dMap.set(product.productTag1, []);
              }
              this.customFiel1dMap.get(product.productTag1)!.push(product.asin!);
            }

            if (product.productTag2) {
              if (!this.customField2Map.has(product.productTag2)) {
                this.customField2Map.set(product.productTag2, []);
              }
              this.customField2Map.get(product.productTag2)!.push(product.asin!);
            }
          }
          this.asinFilterTags.setProductTag1Values(this.customField1Values);
          this.asinFilterTags.setProductTag2Values(this.customField2Values);
          return of([spAsinStratIndex, sbAsinStratIndex, sdAsinStratIndex]);
        }),
      )
      .subscribe(([spAsinStratIndex, sbAsinStratIndex, sdAsinStratIndex]) => {
        this.asinStratMap.clear();
        this.stratAsinMap = new Map();
        for (const [asin, strats] of [...spAsinStratIndex, ...sbAsinStratIndex, ...sdAsinStratIndex]) {
          if (this.asinStratMap.has(asin)) {
            this.asinStratMap.get(asin)!.push(...strats);
          } else {
            this.asinStratMap.set(asin, [...strats]);
          }
          for (const s of strats) {
            if (!this.stratAsinMap.has(s.strategyId!)) {
              this.stratAsinMap.set(s.strategyId!, []);
            }
            this.stratAsinMap.get(s.strategyId!)!.push(asin);
          }
        }
      });
  }

  private initDataset(): DataSet<AdStatsEx> {
    return new DataSet<AdStatsEx>(
      3,
      [VENDOR_SELLABLE_COST, VENDOR_SELLABLE_UNITS],
      [AggregationFunction.sumAdStats],
      this.translocoService,
    );
  }

  private getColumnDefs(currency?: Currency, locale?: string, marketplace?: Marketplace): (ColDef | ColGroupDef)[] {
    if (!currency || !locale || !marketplace) return [];

    return [
      {
        headerName: this.translocoService.translate("sales-advertising.product_info", {}, "en"),
        headerValueGetter: () => this.translocoService.translate("sales-advertising.product_info"),
        marryChildren: true, // prevent column group break
        pinned: "left",
        lockPinned: true,
        children: [
          {
            ...ImageColumn,
            headerName: this.translocoService.translate("catalog-page.image", {}, "en"),
            headerValueGetter: () => this.translocoService.translate("catalog-page.image"),
            cellRendererSelector: (params) => {
              if (!params.value) return undefined;
              else {
                return {
                  component: ProductThumbnailComponent,
                  params: {
                    asin: params.value,
                    smallImg: true,
                    marketplace: marketplace,
                    customSizeClass: "size-16",
                  },
                };
              }
            },
          },
          {
            headerName: this.translocoService.translate("common.product", {}, "en"),
            headerValueGetter: () => this.translocoService.translate("common.product"),
            pinned: "left",
            colId: "asin_col",
            field: "asin",
            floatingFilter: true,
            filter: "agTextColumnFilter",
            cellRendererSelector: (params) => {
              if (params.node.group) return undefined;
              return {
                component: AsinLinkComponent,
                params: {
                  asin: params.value,
                  marketplace: marketplace,
                },
              };
            },
          },
          {
            headerName: this.translocoService.translate("common.title", {}, "en"),
            headerValueGetter: (params) => this.translocoService.translate("common.title"),
            pinned: "left",
            maxWidth: 400,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            valueGetter: (params) =>
              this.catalog()
                ?.getProductTitles()
                ?.get(params.node?.group ? params.node.key : params.data.asin) ?? "-",
            tooltipValueGetter: (p: ITooltipParams) => p.value,
            cellClass: "sensitive-data",
          },
          {
            field: "parentAsin",
            colId: PARENT_ASIN_COL_NAME,
            headerName: this.translocoService.translate("product360.parent_asin", {}, "en"),
            headerValueGetter: () => this.translocoService.translate("product360.parent_asin"),
            hide: true,
            pinned: "left",
            enableRowGroup: true,
            valueGetter: (params) => {
              return (
                this.catalog()?.getParentAsin(params.node?.group ? params.node.key : params.data.asin) ??
                params.data?.asin
              );
            },
            cellClass: "sensitive-data",
          },
        ],
      },
      ...getMetricsColDef(this.METRICS, true).map((def) => ({
        ...def,
        headerName: this.translocoService.translate(`${def.headerName}`, {}, "en"),
        headerValueGetter: () => {
          return this.translocoService.translate(`${def.headerName}`);
        },
        children: def.children.map((c: ColDef) => {
          const m = MetricRegistry.get(c.colId!)!;

          return {
            ...c,
            headerName: this.translocoService.translate(`metrics.${c.colId}_title`, {}, "en"),
            headerValueGetter: () => this.translocoService.translate(`metrics.${m.id}_title`),
            cellRenderer: MetricEvoComponent,
            cellRendererParams: (params: ICellRendererParams) => {
              return {
                ...c.cellRendererParams(params),
                currency: currency,
                locale: locale,
              };
            },
          };
        }),
      })),
      {
        cellRenderer: ActionButtonsComponent,
        pinned: "right",
        cellRendererParams: (params: any) => {
          const actionButtons = [
            {
              icon: ICON_CHART_LINE,
              tooltipValue: `Display ${params.node.isRowPinned() ? "total" : ""} ASIN chart`,
              color: "white",
              onClick: () => {
                this.openChartModal(params.node);
              },
            },
          ];

          return { actionButtons };
        },
      },
      {
        ...CurrencyColumn,
        valueGetter: () => this.currency(),
      },
    ];
  }

  private openChartModal(node: IRowNode<ProductGridData>) {
    const modalOption: ModalOptions<ChartModalInputData<AdStatsEx>> = {
      adaptiveWidth: true,
      data: {
        locale: this.locale(),
        currency: this.currency(),
        dataSetConfiguration: {
          aggregationFunction: [AggregationFunction.sumAdStats],
        },
        chartData$: this.getModalData(node),
        selectedMetrics: [VENDOR_SELLABLE_COST, VENDOR_SELLABLE_UNITS],
        metrics: this.METRICS,
        localStorageKey: this.localStorageKey,
      },
    };
    this.modalService.openModal<ChartModalInputData<AdStatsEx>, void>(ChartModalComponent, modalOption);
  }

  private getModalData(node: IRowNode<ProductGridData>): Observable<ChartData<AdStatsEx>> {
    const targetedNodes = getFilteredLeafNodes(node, this.grid()?.api);
    return this.dailyInventory$.pipe(
      map((dailyInventory) => {
        const asins = new Set(targetedNodes.map((n) => n.data.asin));
        const data: AdStatsEx[] = [];
        for (const d of dailyInventory) {
          if (asins.has(d.asin)) {
            data.push(d);
          }
        }
        const totalData = data.reduce((acc, curr) => mergeSeveralDates(acc, curr), emptyAdStatEx());
        return { data, totalData };
      }),
    );
  }

  private getGridOptions(): GridOptions {
    return {
      ...getBasicGridOptions("vendorInventoryGrid", true),
      autoGroupColumnDef: { pinned: "left" },
      isExternalFilterPresent: () => {
        return true;
      },
      doesExternalFilterPass: (node) => {
        return AsinFilter.asinMatchesFilter(node.data.asin, this.selectedFilterValue());
      },
    };
  }

  filterChanged(filters: Filter<VendorInventoryFilter>[]) {
    this.selectedFilters.set(filters);
    this.selectedFilterValue.set(
      AsinFilter.filterChanged(filters, this.stratAsinMap, this.customFiel1dMap, this.customField2Map),
    );
    if (!this.grid()?.api.isDestroyed()) {
      this.grid()?.api.onFilterChanged();
      this.grid()?.api.redrawRows();
      this.grid()?.api.refreshCells({ force: true });
      this.grid()?.api.hideOverlay();
    }
  }

  metricsChanged(metrics: Metric<AdStatsEx>[]) {
    this.selectedMetrics.set(metrics);
  }

  dateAggregationChanged(dateAggregation: DateAggregation) {
    this.dateAggregation.set(dateAggregation);
  }

  downloadFile() {
    if (!this.dailyInventoryByAsin().length) {
      this.toasterService.error("No data to export", "Export error");
      return;
    }
    this.csvExportService.exportCsv(
      {
        prefix: "inventory",
        accountGroupName: this.accountMarketplace()?.accountGroupName,
        marketplace: this.accountMarketplace()?.marketplace,
      },
      this.dailyInventoryByAsin(),
      [
        simpleField("asin"),
        constantField("currency", this.currency()),
        metricField(VENDOR_SELLABLE_COST),
        metricField(VENDOR_SELLABLE_UNITS),
        metricField(VENDOR_UNSELLABLE_COST),
        metricField(VENDOR_UNSELLABLE_UNITS),
        metricField(VENDOR_OPEN_PURCHASE_ORDER_UNITS),
        metricField(VENDOR_AGED90P_SELLABLE_COST),
        metricField(VENDOR_AGED90P_SELLABLE_UNITS),
        metricField(VENDOR_SOROOS),
        metricField(VENDOR_OUT_OF_STOCK_VIEWS),
      ],
    );
  }
}

type VendorInventoryFilter =
  | FilterCategory.ProductGroup
  | FilterCategory.BrandProducts
  | FilterCategory.ProductTag1
  | FilterCategory.ProductTag2
  | FilterCategory.Strategy;
