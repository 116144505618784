import { Component } from "@angular/core";

@Component({
  selector: "app-layout",
  template: `HELLO<button (click)="toto = !toto">Click me</button>
    @if (toto) {
      <router-outlet name="dashboard360-layout"></router-outlet>
    } @else {
      <router-outlet name="default-layout"></router-outlet>
    }`,
})
export class AppLayoutComponent {
  toto = false;
}
