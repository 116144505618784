import { Component, Input, signal } from "@angular/core";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { Option } from "@front/m19-ui";
import { ICON_UPLOAD } from "@m19-board/utils/iconsLabels";
import { BsModalRef } from "ngx-bootstrap/modal";
import { of } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import { catchError, map } from "rxjs/operators";
import { CsvMatchType, CsvSeedType, SeedCsvRow } from "../catalog-page.component";
import { AsinSeed, CostOfGoods, CustomField, Marketplace } from "@front/m19-api-client";
import { AsinService } from "@front/m19-services";
import { Utils } from "@front/m19-utils";

enum UploadMode {
  UPLOAD_COGS = "UPLOAD_COGS",
  UPLOAD_SEEDS = "UPLOAD_SEEDS",
  UPLOAD_CF = "UPLOAD_CF",
}

const UploadModeLabels: { [key in UploadMode]: string } = {
  UPLOAD_COGS: "Upload Cost Of Goods",
  UPLOAD_SEEDS: "Upload Seed/Blacklist",
  UPLOAD_CF: "Upload Custom Fields",
};

export type UploadResult<T> = {
  uploaded: T[];
  errors: string[];
  warnings: string[];
};

@Component({
  selector: "app-catalog-data-modal",
  templateUrl: "./upload-catalog-data-modal.component.html",
  styleUrls: ["./upload-catalog-data-modal.component.scss"],
})
export class UploadCatalogDataModalComponent {
  @Input() currencySymbol: string;
  @Input() accountId: string;
  @Input() marketplace: Marketplace;
  @Input() catalogAsins: string[];
  // TODO: use event emitters instead of functions
  @Input() onCogsUpload: (result: UploadResult<CostOfGoods>) => void;
  @Input() onSeedsUpload: (result: UploadResult<string>) => void;
  @Input() onCFsUpload: (result: UploadResult<CustomField>) => void;

  fileUploadError = "";
  loading = false;

  uploadModeOptions: Option<UploadMode>[] = [
    { value: UploadMode.UPLOAD_SEEDS, label: UploadModeLabels.UPLOAD_SEEDS },
    { value: UploadMode.UPLOAD_COGS, label: UploadModeLabels.UPLOAD_COGS },
    { value: UploadMode.UPLOAD_CF, label: UploadModeLabels.UPLOAD_CF },
  ];

  selectedUploadMode = signal<Option<UploadMode>>(this.uploadModeOptions[0]);

  readonly ICON_UPLOAD = ICON_UPLOAD;

  readonly UploadMode = UploadMode;
  readonly UploadModeLabels = UploadModeLabels;
  readonly faCloudUpload = faCloudUploadAlt;

  constructor(
    public bsModalRef: BsModalRef,
    private asinService: AsinService,
  ) {}

  changeUploadMode(uploadMode: Option<UploadMode>): void {
    this.selectedUploadMode.set(uploadMode);
  }

  isUploadButtonDisabled(content: string): boolean {
    return !content || content.trim().length === 0;
  }

  addCogs(cogs: string): void {
    const cogsToAdd: CostOfGoods[] = [];
    const errors: string[] = [];
    const warnings: string[] = [];
    const rows = cogs.split(/[\n]+/);
    let lineIndex = 0;
    for (const row of rows) {
      const trimmedRow = row.trim();
      if (trimmedRow === "") {
        continue;
      }
      lineIndex++;
      const sp = trimmedRow.split(/[,; \t]+/);
      if (sp.length < 2) {
        errors.push(`Line ${lineIndex} "${trimmedRow}" is not properly formatted`);
        continue;
      }
      const asin = Utils.normalizeASIN(sp[0]);
      const cog = Number.parseFloat(sp[1]);
      if (asin && Utils.isValidAsin(asin) && !isNaN(cog)) {
        if (!this.catalogAsins.includes(asin)) {
          errors.push(`Catalog does not contain ASIN ${asin} on ${lineIndex} ("${trimmedRow}")`);
          continue;
        }
        cogsToAdd.push({
          accountId: this.accountId,
          marketplace: this.marketplace,
          asin: asin,
          startDate: Utils.formatDateForApiFromToday(0),
          costOfGoods: cog,
        });
      } else {
        errors.push(`Line ${lineIndex} "${trimmedRow}" is not properly formatted`);
      }
    }
    if (cogsToAdd.length == 0) {
      errors.push("No Cost Of Goods to import");
      this.onCogsUpload({ uploaded: cogsToAdd, errors: errors, warnings: warnings });
      this.bsModalRef.hide();
      return;
    }
    const addedCogs: CostOfGoods[] = [];
    this.asinService.setLatestCostOfGoods(this.accountId, this.marketplace, cogsToAdd).subscribe(() => {
      for (const cogToAdd of cogsToAdd) {
        addedCogs.push(cogToAdd);
      }
      this.onCogsUpload({ uploaded: addedCogs, errors: errors, warnings: warnings });
    });
    this.bsModalRef.hide();
  }

  addCFs(cf: string): void {
    const cfToAdd: CustomField[] = [];
    const errors: string[] = [];
    const warnings: string[] = [];
    const rows = cf.split(/[\n]+/);
    let lineIndex = 0;
    for (const row of rows) {
      const trimmedRow = row.trim();
      if (trimmedRow === "") {
        continue;
      }
      lineIndex++;
      const sp = trimmedRow.split(/[,;\t]/);
      if (sp.length < 2 || sp.length > 3) {
        errors.push(`Line ${lineIndex} "${trimmedRow}" is not properly formatted`);
        continue;
      }
      const asin = Utils.normalizeASIN(sp[0]);
      const field1 = sp[1]?.trim();
      const field2 = sp[2]?.trim();
      if (asin && Utils.isValidAsin(asin)) {
        if (!this.catalogAsins.includes(asin)) {
          errors.push(`Catalog does not contain ASIN ${asin} on ${lineIndex} ("${trimmedRow}")`);
          continue;
        }
        cfToAdd.push({
          asin: asin,
          field1: field1,
          field2: field2,
        });
      } else {
        errors.push(`Line ${lineIndex} "${trimmedRow}" is not properly formatted`);
      }
    }
    if (cfToAdd.length == 0) {
      errors.push("No Custom Field to import");
      this.onCFsUpload({ uploaded: cfToAdd, errors: errors, warnings: warnings });
      this.bsModalRef.hide();
      return;
    }
    this.asinService.updateCustomFields(this.accountId, this.marketplace, cfToAdd).subscribe({
      next: () => {
        this.onCFsUpload({ uploaded: cfToAdd, errors: errors, warnings: warnings });
      },
      error: (err) => {
        errors.push("Failed to import every CustomFields");
        this.onCFsUpload({ uploaded: cfToAdd, errors: errors, warnings: warnings });
      },
    });

    this.bsModalRef.hide();
  }

  addSeeds(seeds: string): void {
    const kwTargetings: Map<string, AsinSeed[]> = new Map();
    const productTargetings: Map<string, AsinSeed[]> = new Map();
    const asins: Set<string> = new Set();
    const errors: string[] = [];
    const warnings: string[] = [];
    const rows = seeds.split(/[\n]+/);
    let lineIndex = 0;
    for (const row of rows) {
      const trimmedRow = row.trim();
      if (trimmedRow === "" || trimmedRow === "ASIN,Type,MatchType,Value") {
        // discard empty or header rows
        continue;
      }
      lineIndex++;
      const sp = trimmedRow.split(/[,;\t]/);
      if (sp.length < 4) {
        errors.push(`Line ${lineIndex} "${trimmedRow}" is not properly formatted`);
        continue;
      }
      const asin = Utils.normalizeASIN(sp[0]);
      if (!Utils.isValidAsin(asin)) {
        errors.push(`Invalid ASIN ${asin} on line ${lineIndex} ("${trimmedRow}")`);
        continue;
      }
      if (!this.catalogAsins.includes(asin)) {
        errors.push(`Catalog does not contain ASIN ${asin} on ${lineIndex} ("${trimmedRow}")`);
        continue;
      }
      const type: CsvSeedType = CsvSeedType[sp[1]];
      if (!type) {
        errors.push(`Invalid SEED/BLACKLIST type on line ${lineIndex} ("${trimmedRow}")`);
        continue;
      }
      const matchType = sp[2] as CsvMatchType;
      if (!matchType) {
        errors.push(`Invalid match type on line ${lineIndex} ("${trimmedRow}")`);
        continue;
      }
      if (matchType == CsvMatchType.phrase && type == CsvSeedType.SEED) {
        errors.push(
          `Phrase match type is not supported for SEED keywords (only exact is supported) on line ${lineIndex} ("${trimmedRow}")`,
        );
        continue;
      }
      for (let i = 3; i < sp.length; i++) {
        const value = sp[i];
        if (!value) {
          continue;
        }
        const item: AsinSeed = new SeedCsvRow(asin, type, matchType, value).toAsinSeed(
          this.accountId,
          this.marketplace,
        );
        if (matchType == CsvMatchType.targetedProduct) {
          const items = productTargetings.get(asin) ?? [];
          productTargetings.set(asin, [...items, item]);
        } else {
          const items = kwTargetings.get(asin) ?? [];
          kwTargetings.set(asin, [...items, item]);
        }
      }
      asins.add(asin);
    }
    if (asins.size == 0) {
      errors.push("No seed/blacklist to import");
      this.onSeedsUpload({ uploaded: [], errors: errors, warnings: warnings });
      this.bsModalRef.hide();
      return;
    }
    this.asinService
      .setBulkAsinSeeds(
        this.accountId,
        this.marketplace,
        Array.from(asins).map((asin) => {
          return { asin, keywords: kwTargetings.get(asin) ?? [], products: productTargetings.get(asin) ?? [] };
        }),
      )
      .pipe(
        map((result) => {
          // warnings or errors might be returned
          errors.push(...result.errors);
          result.warnings.forEach((e) => warnings.push(`Some seed/blacklist values have been discarded - ${e}`));
          return result.asins;
        }),
        catchError((err) => {
          const ajaxError = err as AjaxError;
          errors.push(`Impossible to update seed/blacklist: ${ajaxError.response?.message ?? ajaxError.message}`);
          return of([]);
        }),
      )
      .subscribe((updatedAsins) => {
        this.loading = false;
        this.onSeedsUpload({ uploaded: updatedAsins, errors: errors, warnings: warnings });
        this.bsModalRef.hide();
      });
    this.loading = true;
  }

  onPaste(event: ClipboardEvent): void {
    // when pasting data from Excel, trim and replace tabs by commas
    const pastedData = event.clipboardData.getData("text");
    const processedData = pastedData.trim().replaceAll(/\t+/g, ",");
    // this will replace text area selection by the new value
    const textarea = <HTMLInputElement>event.target;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    textarea.value = textarea.value.substring(0, start) + processedData + textarea.value.substring(end);
    event.preventDefault();
  }

  onCsvUpload(files: FileList): void {
    if (files.length > 0) {
      const csvFile = files[0];
      if (csvFile.type !== "text/csv" && csvFile.type !== "application/vnd.ms-excel") {
        this.fileUploadError = `${csvFile.name} is not a CSV file`;
        return;
      }
      const reader: FileReader = new FileReader();
      reader.readAsText(csvFile);
      reader.onload = () => {
        const csv: string = reader.result as string;
        if (this.selectedUploadMode().value === UploadMode.UPLOAD_SEEDS) {
          this.addSeeds(csv);
        } else if (this.selectedUploadMode().value === UploadMode.UPLOAD_COGS) {
          this.addCogs(csv);
        } else {
          this.addCFs(csv);
        }
      };
    }
  }
}
