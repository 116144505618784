import { Component, OnInit } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { AccountSelectionService } from "@front/m19-services";
import { ICON_CHEVRON_DOWN } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AccountMarketplace, Marketplace } from "@front/m19-api-client";
import { StrategyGroupEx } from "@front/m19-models";
import { MatTooltip } from "@angular/material/tooltip";
import { TranslocoDirective } from "@jsverse/transloco";
import { SpStrategyCreationComponent } from "@m19-board/sponsored-product/sp-creation/sp-strategy-creation/sp-strategy-creation.component";
import { IButtonComponent } from "@front/m19-ui";

@UntilDestroy()
@Component({
  template: ` <div class="main-container mx-auto mb-3 flex items-center justify-between px-2" *transloco="let t">
      <div class="flex items-center">
        <IButton
          [label]="t('v2-sidebar.sponsored_products')"
          variant="ghost"
          to="/advertising/sponsored-product"
          queryParamsHandling="merge"
          [tooltipValue]="t('sp-strategy-group-page.back_to_sponsored_products_strategy_list')"
        />
        /
        <IButton
          [label]="t('strategy-group-migration-page.new_strategy_group')"
          variant="ghost"
          queryParamsHandling="merge"
        />
      </div>
      <div>
        {{ t("common.marketplace") }}:&nbsp;<strong>{{ marketplace }}</strong>
      </div>
    </div>
    <app-sp-strategy-creation (strategyGroupCreated)="onStrategyGroupCreated($event)"></app-sp-strategy-creation>`,
  styleUrls: ["../../strategies/strategies/strategy-styles.scss"],
  imports: [TranslocoDirective, SpStrategyCreationComponent, IButtonComponent],
  standalone: true,
})
export class SpStrategyGroupCreationPageComponent implements OnInit {
  marketplace?: Marketplace;
  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;

  constructor(
    private accountSelection: AccountSelectionService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((am: AccountMarketplace) => {
        this.marketplace = am.marketplace;
      });
  }

  onStrategyGroupCreated(strategyGroup: StrategyGroupEx) {
    this.router.navigate(["/advertising/sponsored-product/strategy-group/" + strategyGroup.strategyGroupId], {
      queryParamsHandling: "merge",
    });
  }
}
