import { CommonModule } from "@angular/common";
import { Component, computed, inject, Input, Signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { AccountMarketplace, Strategy } from "@front/m19-api-client";
import { TacosStrategiesService } from "@front/m19-services";
import { ProductThumbnailComponent } from "@m19-board/product-view/product-thumbnail.component";
import { BsModalRef } from "ngx-bootstrap/modal";
import { StrategyLinkComponent } from "@m19-board/strategies/strategy-link/strategy-link.component";

@Component({
  selector: "asin-strategy-usage",
  standalone: true,
  imports: [CommonModule, ProductThumbnailComponent, StrategyLinkComponent],
  templateUrl: "./asin-strategy-usage-modal.component.html",
})
export class AsinStrategyUsageComponent {
  @Input() am: AccountMarketplace | undefined;
  @Input() isTacosStrategy = false;

  private tacosStrategiesService = inject(TacosStrategiesService);

  // unavailable for [TACOS] strategies
  strategyByAsins: Signal<Map<string, Strategy[]> | undefined> = toSignal(this.tacosStrategiesService.strategyByAsins$);

  // unavailable for [SP, SB, SD] strategies
  tacosStrategyByAsins: Signal<Map<string, Strategy[]> | undefined> = toSignal(
    this.tacosStrategiesService.tacosStrategyByAsins$,
  );

  usedStrategies = computed<Map<string, Strategy[]>>(() => {
    if (this.isTacosStrategy) {
      return this.strategyByAsins() ?? new Map();
    }
    return this.tacosStrategyByAsins() ?? new Map();
  });

  ref = inject(BsModalRef);
}
