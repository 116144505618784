<ng-container *transloco="let t">
  <div class="modal-header bg-primary">
    <h4 class="modal-title pull-left">
      {{ t("strategy-group-move-product-modal.move_products_to_another_main_strategy") }}
    </h4>
    <a><h4 class="modal-title pull-right" (click)="close()">&times;</h4></a>
  </div>
  <div class="modal-body ml-3 mr-2">
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">{{ t("strategy-group-move-product-modal.move_to") }}</label>
      <div class="col-sm-10">
        <ISelect
          [options]="productStrategies"
          [selected]="productStrategy"
          (selectedChange)="setProductStrategy($event)"
          searchable
        />
      </div>
    </div>
    @if (productStrategy?.value === "NewProductStrategy") {
      <div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">{{ t("sp-strategy-creation.strategy_name") }}</label>
          <div class="col-sm-10">
            <input
              type="text"
              maxlength="80"
              class="form-control"
              placeholder="{{ t('strategy-group-add-product-modal.please_enter_a_name_for_your_main_strategy') }}"
              (input)="setProductStrategyName(getValueFromInputEvent($event))"
            />
          </div>
        </div>
        <app-algo-mode-selection
          (algoModeConfig)="setAlgoModeConfig($event)"
          (valid)="setAlgoModeConfigValid($event.isValid)"
        ></app-algo-mode-selection>
      </div>
    }
    @if (productStrategy?.value === "NewStrategy") {
      <div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">{{ t("sp-strategy-creation.strategy_name") }}</label>
          <div class="col-sm-10">
            <input
              type="text"
              maxlength="80"
              class="form-control"
              placeholder="{{ t('sp-strategy-creation.please_enter_a_name_for_your_strategy') }}"
              (input)="setProductStrategyName(getValueFromInputEvent($event))"
            />
          </div>
        </div>
        <app-algo-mode-selection
          (algoModeConfig)="setAlgoModeConfig($event)"
          (valid)="setAlgoModeConfigValid($event.isValid)"
        ></app-algo-mode-selection>
      </div>
    }
  </div>
  <div class="modal-footer">
    <IButton label="{{ t('common.cancel') }}" color="gray" variant="ghost" (onClick)="close()" />
    <IButton
      label="{{ t('common.save') }}"
      [disabled]="invalid()"
      [tooltipValue]="invalidTooltip()"
      (onClick)="save()"
      type="submit"
    />
  </div>
</ng-container>
