import { CommonModule } from "@angular/common";
import { Component, computed, Input, input, output, signal } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AccountMarketplace, AccountType, Marketplace } from "@front/m19-api-client";
import { Marketplaces } from "@front/m19-models";
import { IButtonComponent, ICheckboxComponent, IInputComponent } from "@front/m19-ui";
import { TranslocoDirective } from "@jsverse/transloco";

export interface AccountSelectionList {
  accountId: string;
  accountName: string;
  marketplaces: MarketplaceSelection[];
  isSeller: boolean;
  amazonToken: string;
}

interface MarketplaceSelection extends AccountMarketplace {
  isSelected: boolean;
  disableReason?: string;
}

@Component({
  selector: "select-accounts-page",
  standalone: true,
  imports: [CommonModule, IInputComponent, IButtonComponent, TranslocoDirective, ICheckboxComponent, MatTooltipModule],
  templateUrl: "./select-accounts-page.component.html",
})
export class SelectAccountsPageComponent {
  protected readonly Marketplaces = Marketplaces;

  onCancel = output<void>();
  selectionChange = output<AccountMarketplace[]>();

  private readonly orgIdSignal = signal<number | undefined>(undefined);
  @Input() set orgId(value: number | undefined) {
    this.orgIdSignal.set(value);
    this.emitSelectionChange();
  }

  @Input() set accountMarketplaces(value: AccountMarketplace[]) {
    this.accountSelectionList.set(this.toAccountSelectionList(value));
    this.emitSelectionChange();
  }

  @Input() set disabledAccountsMarketplaces(value: Map<string, string | undefined>) {
    this.accountSelectionList.update((lists) =>
      lists.map((list) => ({
        ...list,
        marketplaces: list.marketplaces.map((m) => ({
          ...m,
          disableReason: value.get(list.accountId + "." + m.marketplace),
        })),
      })),
    );
  }

  searchValue = signal<string>("");
  loading = input<boolean>(false);

  accountSelectionList = signal<AccountSelectionList[]>([]);

  filteredAccountSelectionList = computed(() =>
    this.accountSelectionList().filter((list) =>
      list.accountName.toLowerCase().includes(this.searchValue().toLowerCase()),
    ),
  );

  isAllSelected = computed(() =>
    this.accountSelectionList().every(
      (list) => this.selectedMarketplaceNumberByAccountId().get(list.accountId) === list.marketplaces.length,
    ),
  );
  selectedMarketplaceNumberByAccountId = computed<Map<string, number>>(() =>
    this.accountSelectionList().reduce((acc, list) => {
      acc.set(list.accountId, list.marketplaces.filter((m) => m.isSelected).length);
      return acc;
    }, new Map<string, number>()),
  );

  isContinueDisabled = computed(
    () => !this.accountSelectionList().some((list) => list.marketplaces.some((m) => m.isSelected)),
  );

  toggleMarketplaceSelection(accountId: string, marketplace: Marketplace, select: boolean, emit = true) {
    const marketplaceSelection = this.accountSelectionList()
      .find((list) => list.accountId === accountId)
      ?.marketplaces.find((m) => m.marketplace === marketplace);

    if (marketplaceSelection?.disableReason) {
      return;
    }
    this.accountSelectionList.update((lists) => {
      return lists.map((list) => {
        if (list.accountId === accountId) {
          const updatedMarketplaces = list.marketplaces.map((m) => ({
            ...m,
            isSelected: m.marketplace === marketplace ? select : m.isSelected,
          }));
          return {
            ...list,
            marketplaces: updatedMarketplaces,
          };
        }
        return list;
      });
    });
    if (emit) {
      this.emitSelectionChange();
    }
  }

  toggleSelectAllMarketplaces(accountId: string, select: boolean, emit = true) {
    for (const a of this.accountSelectionList().filter((a) => a.accountId === accountId)) {
      for (const m of a.marketplaces) {
        this.toggleMarketplaceSelection(a.accountId, m.marketplace, select, emit);
      }
    }
    if (emit) {
      this.emitSelectionChange();
    }
  }

  toggleSelectAllAccounts() {
    const shouldSelect = !this.isAllSelected();
    for (const a of this.accountSelectionList()) {
      this.toggleSelectAllMarketplaces(a.accountId, shouldSelect, false);
    }
    this.emitSelectionChange();
  }

  private toAccountSelectionList(accountMarketplaces: AccountMarketplace[]): AccountSelectionList[] {
    const groupedByAccount = accountMarketplaces.reduce(
      (acc: Map<string, AccountSelectionList>, current: AccountMarketplace) => {
        const key = current.accountId;
        if (acc.has(key)) {
          acc.get(key)?.marketplaces.push({ ...current, isSelected: true });
        } else {
          acc.set(key, {
            ...current,
            marketplaces: [{ ...current, isSelected: true }],
            isSeller: current.accountType === AccountType.SELLER,
            amazonToken: "",
          });
        }
        return acc;
      },
      new Map<string, AccountSelectionList>(),
    );

    return Array.from(groupedByAccount.values());
  }

  private emitSelectionChange() {
    const toEmit = this.accountSelectionList().flatMap((a) =>
      a.marketplaces
        .filter((m) => m.isSelected)
        .map((m) => ({ ...m, accountId: a.accountId, resourceOrganizationId: this.orgIdSignal() })),
    );
    this.selectionChange.emit(toEmit);
  }
}
