<div class="card-body p-0" (mouseenter)="submenu = true" (mouseleave)="submenu = false" *transloco="let t">
  @if (submenu || submenuHover) {
    <div class="submenu btn-group" (mouseenter)="submenuHover = true" (mouseleave)="submenuHover = false">
      @if (withShowHideToggle) {
        <button
          type="button"
          class="submenu-btn btn btn-secondary"
          (click)="$event.stopPropagation(); toggleShowSearchTerm()"
          [matTooltip]="_show ? t('search-term-card.hide_from_graph') : t('search-term-card.show_in_graph')"
        >
          <fa-icon [icon]="_show ? faEyeSlash : faEye"></fa-icon>
        </button>
      }
      <button
        type="button"
        class="submenu-btn btn btn-secondary"
        (click)="$event.stopPropagation(); delete.emit()"
        matTooltip="{{ t('search-term-card.delete_search_term') }}"
      >
        <fa-icon [icon]="faTrash"></fa-icon>
      </button>
    </div>
  }
  <div class="search-term" [ngClass]="_show ? '' : 'disabled'">
    <span [matTooltip]="searchTerm.length > 20 ? searchTerm : undefined" [ngStyle]="{ color: color }">
      {{ searchTerm }}
    </span>
    <div *transloco="let t" matTooltip="{{ t('search-term-card.estimated_mothly_search') }}">
      <small>{{ searchVolume }}</small>
    </div>
  </div>
</div>
