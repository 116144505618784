<ng-container *transloco="let t">
  <div class="container mx-auto pt-7">
    <h4 class="text-2xl font-bold text-neutral-800">TACOS Strategies</h4>

    <div class="mt-4 flex flex-col divide-y divide-gray-200">
      @for (strategy of strategies() ?? []; track strategy.tacosStrategyGroupId) {
        <a
          class="inline-flex cursor-pointer items-center justify-between p-2 duration-100 hover:bg-gray-100"
          [routerLink]="['/advertising/tacos-strategies', strategy.tacosStrategyGroupId]"
          queryParamsHandling="merge"
        >
          <div class="flex items-center gap-x-4">
            <span class="text-neutral-800">
              <IBadge [label]="'ACTIVE'" [color]="'green'" variant="soft" />
            </span>

            <span class="font-medium text-neutral-800">
              {{ sbByTacosStrategyGroupId()?.get(strategy.tacosStrategyGroupId!)?.name }}
            </span>

            <span class="text-neutral-600">
              {{ sbByTacosStrategyGroupId()?.get(strategy.tacosStrategyGroupId!)?.asins?.length ?? 0 }} products
            </span>
          </div>

          <IButton [icon]="ICON_TRASH" [color]="'red'" variant="outline" size="sm" />
        </a>
      }
      <div class="py-2"></div>
    </div>
  </div>
</ng-container>
