import { ProductGroup } from '@front/m19-api-client';
import { AsinList } from './AsinList';

export class ProductGroupEx implements ProductGroup, AsinList {
  static MaxProductGroupItems = 15000;

  public accountId: string;
  public productGroupId: number;
  public productGroupName: string;
  private asins: string[] = [];

  private itemsSet: Set<string> = new Set();

  public constructor(productGroup: ProductGroup) {
    this.accountId = productGroup.accountId;
    this.productGroupId = productGroup.productGroupId!;
    this.productGroupName = productGroup.productGroupName;
    this.items = productGroup.items!;
  }

  public get items(): string[] {
    return this.asins;
  }

  public set items(items: string[]) {
    this.itemsSet.clear();

    for (const item of items) this.itemsSet.add(item);
    this.updateItemsList();
  }

  public get name(): string {
    return this.productGroupName;
  }

  public set name(name: string) {
    this.productGroupName = name;
  }

  public addAsin(asin: string): void {
    this.itemsSet.add(asin);
    this.updateItemsList();
  }

  public removeAsin(asin: string): void {
    this.itemsSet.delete(asin);
    this.updateItemsList();
  }

  public containsAsin(asin: string): boolean {
    return this.itemsSet.has(asin);
  }

  public toProductGroup(): ProductGroup {
    return {
      accountId: this.accountId,
      productGroupId: this.productGroupId,
      productGroupName: this.productGroupName,
      items: this.items,
    };
  }

  private updateItemsList() {
    this.asins = Array.from(this.itemsSet.values());
  }

  toString(): string {
    return this.productGroupName;
  }

  hidden!: boolean;
}
