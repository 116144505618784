<ng-container *transloco="let t">
  <div class="modal-header bg-primary">
    <h4 class="modal-title pull-left">
      {{
        t("update-custom-campaign-name.custom_campaign_name", [
          accountMarketplace.accountName,
          accountMarketplace.marketplace,
        ])
      }}
    </h4>
  </div>
  <div class="modal-body">
    <div class="form-group row items-center">
      <span class="prepend col-4">{{ t("update-custom-campaign-name.custom_campaign_naming") }}</span>
      <div class="col-8">
        <mat-slide-toggle color="primary" [checked]="canEdit" (change)="canEdit = $event.checked"></mat-slide-toggle>
      </div>
    </div>
    @if (canEdit) {
      <div class="form-group row items-center">
        <span class="prepend col-4">{{ t("update-custom-campaign-name.campaign_name_pattern") }}</span>
        <div class="col-8">
          <span class="input-group">
            @if (edit) {
              <input
                #customCampaignNameElement
                (focusout)="renderCustomCampaignName()"
                type="text"
                class="form-control"
                [value]="customCampaignName ? customCampaignName : ''"
                [maxLength]="256"
                (input)="customCampaignName = getValueFromInputEvent($event)"
                (keydown.enter)="validPattern() && update()"
              />
            }
            @if (!edit) {
              <span
                class="form-control truncate"
                (click)="setEditCustomCampaignName()"
                [innerHTML]="
                  (customCampaignNameHighlighted !== null ? customCampaignNameHighlighted : '')
                    | customCampaignNameHighlightPipe
                "
              ></span>
            }
          </span>
        </div>
      </div>
    }
    @if (canEdit) {
      <div class="mt-4">
        <span> {{ t("update-custom-campaign-name.customize_tokens") }} </span>
        <ul class="p-4">
          @for (token of CustomCampaignNameTokens; track token) {
            <li class="pointer my-1">
              <a (click)="addTokenToInput(token)" class="hover:underline">
                <code>{{ token }}</code>
              </a>
            </li>
          }
        </ul>
      </div>
    }
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
      {{ t("common.cancel") }}
    </button>
    <button type="submit" class="btn btn-primary" [disabled]="!validPattern()" (click)="update()">
      {{ t("common.update") }}
    </button>
  </div>
</ng-container>
