import { CommonModule } from "@angular/common";
import { Component, EventEmitter, HostBinding, input, Output } from "@angular/core";
import { ICON_EDIT_O } from "@m19-board/utils/iconsLabels";

@Component({
  selector: "strategy-info-bloc",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./strategy-info-bloc.component.html",
})
export class StrategyInfoBlocComponent {
  readonly ICON_EDIT = ICON_EDIT_O;

  disabled = input(false);
  testid = input<string | undefined>(undefined);

  @Output() onClick = new EventEmitter<void>();

  @HostBinding("class") classes = "inline-block";

  onClickHandler() {
    if (!this.disabled()) {
      this.onClick.emit();
    }
  }
}
