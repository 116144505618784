import { QueryKeywordSuspicious } from '../api-client';

export class QueryKeywordSuspiciousEx {
  leader?: string;
  query?: string;
  keyword?: string;
  distance?: number;
  blacklisted: boolean;

  public constructor(query: QueryKeywordSuspicious, isBlacklisted: boolean) {
    this.query = query.query;
    this.keyword = query.keyword;
    this.leader = query.leader;
    this.distance = query.distance;
    this.blacklisted = isBlacklisted;
  }
}
