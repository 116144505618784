// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    CatalogBrand,
    CatalogBrandExtraSegment,
    Marketplace,
    Response,
} from '../models';

export interface GetCatalogBrandRequest {
    accountId: string;
    marketplace: Marketplace;
    brandId: number;
}

export interface GetCatalogBrandsRequest {
    accountId: string;
    marketplace: Marketplace;
}

export interface UpdateCatalogBrandRequest {
    accountId: string;
    marketplace: Marketplace;
    brandId: number;
    brandAlias?: string | null;
    parentBrandId?: number | null;
}

export interface UpdateCatalogBrandExtraSegmentsRequest {
    accountId: string;
    marketplace: Marketplace;
    brandId: number;
    action: UpdateCatalogBrandExtraSegmentsActionEnum;
    catalogBrandExtraSegment: Array<CatalogBrandExtraSegment>;
}

/**
 * no description
 */
export class CatalogBrandApi extends BaseAPI {

    /**
     */
    getCatalogBrand({ accountId, marketplace, brandId }: GetCatalogBrandRequest): Observable<CatalogBrand>
    getCatalogBrand({ accountId, marketplace, brandId }: GetCatalogBrandRequest, opts?: OperationOpts): Observable<AjaxResponse<CatalogBrand>>
    getCatalogBrand({ accountId, marketplace, brandId }: GetCatalogBrandRequest, opts?: OperationOpts): Observable<CatalogBrand | AjaxResponse<CatalogBrand>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getCatalogBrand');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getCatalogBrand');
        throwIfNullOrUndefined(brandId, 'brandId', 'getCatalogBrand');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<CatalogBrand>({
            url: '/catalogBrand/{accountId}/{marketplace}/{brandId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{brandId}', encodeURI(brandId)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getCatalogBrands({ accountId, marketplace }: GetCatalogBrandsRequest): Observable<Array<CatalogBrand>>
    getCatalogBrands({ accountId, marketplace }: GetCatalogBrandsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<CatalogBrand>>>
    getCatalogBrands({ accountId, marketplace }: GetCatalogBrandsRequest, opts?: OperationOpts): Observable<Array<CatalogBrand> | AjaxResponse<Array<CatalogBrand>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getCatalogBrands');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getCatalogBrands');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<CatalogBrand>>({
            url: '/catalogBrand/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    updateCatalogBrand({ accountId, marketplace, brandId, brandAlias, parentBrandId }: UpdateCatalogBrandRequest): Observable<Response>
    updateCatalogBrand({ accountId, marketplace, brandId, brandAlias, parentBrandId }: UpdateCatalogBrandRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateCatalogBrand({ accountId, marketplace, brandId, brandAlias, parentBrandId }: UpdateCatalogBrandRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'updateCatalogBrand');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'updateCatalogBrand');
        throwIfNullOrUndefined(brandId, 'brandId', 'updateCatalogBrand');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (brandAlias != null) { query['brandAlias'] = brandAlias; }
        if (parentBrandId != null) { query['parentBrandId'] = parentBrandId; }

        return this.request<Response>({
            url: '/catalogBrand/{accountId}/{marketplace}/{brandId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{brandId}', encodeURI(brandId)),
            method: 'PUT',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Add / delete extra segments from a catalog brand
     */
    updateCatalogBrandExtraSegments({ accountId, marketplace, brandId, action, catalogBrandExtraSegment }: UpdateCatalogBrandExtraSegmentsRequest): Observable<Response>
    updateCatalogBrandExtraSegments({ accountId, marketplace, brandId, action, catalogBrandExtraSegment }: UpdateCatalogBrandExtraSegmentsRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateCatalogBrandExtraSegments({ accountId, marketplace, brandId, action, catalogBrandExtraSegment }: UpdateCatalogBrandExtraSegmentsRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'updateCatalogBrandExtraSegments');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'updateCatalogBrandExtraSegments');
        throwIfNullOrUndefined(brandId, 'brandId', 'updateCatalogBrandExtraSegments');
        throwIfNullOrUndefined(action, 'action', 'updateCatalogBrandExtraSegments');
        throwIfNullOrUndefined(catalogBrandExtraSegment, 'catalogBrandExtraSegment', 'updateCatalogBrandExtraSegments');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'action': action,
        };

        return this.request<Response>({
            url: '/catalogBrandExtraSegment/{accountId}/{marketplace}/{brandId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{brandId}', encodeURI(brandId)),
            method: 'POST',
            headers,
            query,
            body: catalogBrandExtraSegment,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum UpdateCatalogBrandExtraSegmentsActionEnum {
    ADD = 'ADD',
    DELETE = 'DELETE'
}
