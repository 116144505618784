<ng-container *transloco="let t">
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ t("product-group-creation.product_group_creation") }}</h4>
  </div>
  <div class="modal-body">
    <div class="row mt-2">
      <span class="prepend col-2 self-center">{{ t("common.name") }}</span>
      <div class="col-10">
        <input
          class="border-shadow form-control"
          required
          maxlength="80"
          [(ngModel)]="pgName"
          placeholder="{{ t('product-group-creation.give_product_group_name') }}"
        />
      </div>
    </div>
    <div class="modal-footer">
      <IButton label="{{ t('common.cancel') }}" color="gray" variant="ghost" (onClick)="bsModalRef.hide()" />
      <IButton label="{{ t('common.next') }}" (onClick)="create()" />
    </div>
  </div>
</ng-container>
