// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SegmentType {
    auto = 'auto',
    exact = 'exact',
    phrase = 'phrase',
    broad = 'broad',
    product = 'product',
    category = 'category',
    external = 'external',
    remarketing = 'remarketing',
    exact_tos = 'exact-tos',
    exact_ros = 'exact-ros',
    phrase_tos = 'phrase-tos',
    phrase_ros = 'phrase-ros'
}

