import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatMenuModule } from "@angular/material/menu";
import { Marketplace } from "@front/m19-api-client";
import { Catalog, ProductEx } from "@front/m19-models";
import { UntilDestroy } from "@ngneat/until-destroy";
import { TranslocoDirective } from "@jsverse/transloco";

@UntilDestroy()
@Component({
  selector: "app-cluster-asin-card",
  templateUrl: "./cluster-asin-card.component.html",
  styleUrls: ["./cluster-asin-card.component.scss"],
  standalone: true,
  imports: [MatMenuModule, TranslocoDirective],
})
export class ClusterAsinCardComponent implements OnInit {
  @Input() small = false;
  @Input() isVideoCreative = false;
  @Input() groupNumberLimit = 10;
  @Input() groupLength?: number; // number of created group so far
  @Input() catalog?: Catalog;

  @Input() marketplace?: Marketplace;
  @Input() asin?: string;

  @Input() ineligible = false;
  @Input() inCluster = false;
  // index of available clusters
  @Input() availableClusters: number[] = [1, 3, 4];

  @Output() remove = new EventEmitter();
  @Output() add = new EventEmitter<number>();

  product?: ProductEx;

  ngOnInit() {
    this.product = this.catalog?.products.find((p) => p.asin === this.asin);
  }

  addVideoAsin() {
    if (this.availableClusters.length > 0) {
      this.add.emit(0);
    }
  }
}
