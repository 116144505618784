import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { AuthService, CurrencyService } from "@front/m19-services";
import { formatCurrency, formatDate } from "@angular/common";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { InvoiceDetailsModalComponent } from "../invoice-details-modal/invoice-details-modal.component";
import { TranslocoService } from "@jsverse/transloco";
import { Currency, DailyAdSpendFee, Invoice, User } from "@front/m19-api-client";

@UntilDestroy()
@Component({
  selector: "app-billing-invoices",
  templateUrl: "./billing-invoices.component.html",
  styleUrls: ["./billing-invoices.component.scss"],
})
export class BillingInvoicesComponent implements OnInit {
  dataSource: MatTableDataSource<Invoice> = new MatTableDataSource<Invoice>([]);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @Input() dailyAdSpendFees: DailyAdSpendFee[];

  @Input()
  set invoices(invoices: Invoice[]) {
    if (invoices) {
      this.dataSource.data = invoices.sort((a, b) => (a.created < b.created ? 1 : -1));
    } else {
      this.dataSource.data = [];
    }
  }

  locale: string;
  readonly faInfoCircle = faInfoCircle;

  constructor(
    private authService: AuthService,
    public currencyService: CurrencyService,
    private modalService: BsModalService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((x: User) => (this.locale = x ? x.locale : "en"));
  }

  getDisplayedColumns(): string[] {
    if (this.dailyAdSpendFees?.length) return ["created", "amount", "dueDate", "status", "paidAt", "pdf", "details"];
    else return ["created", "amount", "dueDate", "status", "paidAt", "pdf"];
  }

  hasInvoices(): boolean {
    return this.dataSource.data.length > 0;
  }

  formatAmount(invoice: Invoice): string {
    const currency = invoice.currency.toUpperCase() as Currency;
    return formatCurrency(invoice.totalInCent / 100, this.locale, this.currencyService.getCurrencySymbol(currency));
  }

  formatDate(date: string): string {
    if (!date) {
      return "";
    }
    return formatDate(Date.parse(date), "longDate", this.locale);
  }

  dueDate(invoice: Invoice): string {
    if (invoice.status === "paid") {
      return "-";
    }
    return this.formatDate(invoice.dueDate);
  }

  paidAt(invoice: Invoice): string {
    if (invoice.status === "paid" && invoice.paidAt) {
      return this.formatDate(invoice.paidAt);
    }
    return "";
  }

  status(invoice: Invoice): string {
    if (invoice.status === "paid") {
      return this.translocoService.translate("billing-invoices.paid");
    }
    if (Date.now() > Date.parse(invoice.dueDate)) {
      return this.translocoService.translate("billing-invoices.unpaid");
    }
    return this.translocoService.translate("billing-invoices.not_yet_paid");
  }

  hasInvoiceDetails(invoiceId: string): boolean {
    return this.dailyAdSpendFees?.filter((x) => x.stripeInvoiceId === invoiceId).length > 0;
  }

  openInvoiceDetailsModal(invoice: Invoice): void {
    const currency = invoice.currency.toUpperCase() as Currency;
    if (!this.hasInvoiceDetails(invoice.invoiceId)) return;
    const modalOptions: ModalOptions = {
      class: "modal-lg",
      initialState: {
        date: invoice.created,
        locale: this.locale,
        currency: currency,
        dailyAdSpendFees: this.dailyAdSpendFees.filter((x) => x.stripeInvoiceId === invoice.invoiceId),
      },
    };
    this.modalService.show(InvoiceDetailsModalComponent, modalOptions);
  }
}
