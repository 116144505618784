<nav class="relative w-full">
  <div class="inline-block max-w-full" *transloco="let t">
    <button
      [matMenuTriggerFor]="menu"
      (menuOpened)="setFocus()"
      (menuClosed)="search()"
      type="button"
      class="focus:ring-main-300 relative inline-flex w-full cursor-default items-center gap-x-1.5 truncate rounded-full border-0 bg-white px-2.5 py-1.5 text-left text-sm shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 disabled:cursor-not-allowed disabled:opacity-75"
    >
      <span class="pointer-events-none flex items-center">
        <span [class]="ICON_SEARCH + ' size-5 shrink-0 -scale-x-100 text-gray-400'"></span>
      </span>
      @if (input.length) {
        <span class="font-medium text-gray-500">{{ t("search-dropdown.asin_like") }} </span>
        <span>{{ input }}</span>
      } @else {
        <span>{{ t("common.search") }}</span>
      }
    </button>
  </div>
</nav>

<mat-menu #menu="matMenu" xPosition="after" yPosition="below">
  <div class="p-2" *transloco="let t">
    @if (title) {
      <div class="font-bolder mb-2">{{ title }}</div>
    }
    <input
      type="text"
      class="simple-input form-control"
      placeholder="{{ t('common.search') }}"
      [value]="input"
      (input)="input = getValueFromInputEvent($event)"
      (click)="$event.stopPropagation()"
      #inputEl
    />
    <IButton
      [block]="true"
      class="mt-2 w-full"
      tooltipValue="{{ t('common.search') }}"
      (onClick)="search()"
      label="{{ t('common.search') }}"
      size="xs"
    />
  </div>
</mat-menu>
