<app-strategy-overlay>
  @if ((hasBrand() | async) && !isKDP()) {
    <app-strategy-list
      [campaignType]="CampaignType.SB"
      (strategyCreationClick)="createSponsoredBrands($event)"
    ></app-strategy-list>
  }
  @if ((hasBrand() | async) === false) {
    <div class="mt-4" *transloco="let t">
      {{ t("sb-manager.sb_only_for_sellers") }}
      @if (m19BoardType) {
        <span
          >{{ t("sb-manager.find_out_more_on_this") }}
          <a href="https://help.m19.com/i-cant-create-a-sponsored-brand-sb-strategy" target="_blank">{{
            t("common.help_page")
          }}</a
          >.</span
        >
      }
    </div>
  }
  @if (isKDP()) {
    <div class="mt-4" *transloco="let t">{{ t("sb-manager.sb_not_for_KDP") }}</div>
  }
</app-strategy-overlay>
