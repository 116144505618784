<ng-container *transloco="let t">
  <div class="w-[15vw]">
    <IInput
      [disabled]="loading()"
      class="w-full"
      [value]="maxBid"
      type="number"
      (valueChange)="maxBid = +$event"
      [min]="0"
      [label]="t('update-max-bid.max_bid')"
      trailing
    >
      <template #trailing>
        <IBadge [label]="currencySymbol" color="gray" />
      </template>
    </IInput>

    <div class="flex justify-between pt-4">
      <IButton color="white" (onClick)="ref.close()" [label]="t('common.cancel')" [disabled]="loading()" />
      <IButton
        (onClick)="updateMaxBid()"
        [label]="t('common.update')"
        [block]="true"
        class="w-2/3"
        [loading]="loading()"
      />
    </div>
  </div>
</ng-container>
