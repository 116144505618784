<div class="flex h-fit w-[60rem] flex-col justify-center">
  <div class="mx-auto flex-1">
    <div class="row">
      <div class="w-[18rem]">
        <app-strategies-info
          [campaignType]="CampaignType.SP"
          [strategies]="data.linkedStrategies"
        ></app-strategies-info>
      </div>
      <div class="w-[18rem]">
        <app-strategies-info
          [campaignType]="CampaignType.SB"
          [strategies]="data.linkedStrategies"
        ></app-strategies-info>
      </div>
      <div class="w-[18rem]">
        <app-strategies-info
          [campaignType]="CampaignType.SD"
          [strategies]="data.linkedStrategies"
        ></app-strategies-info>
      </div>
    </div>
  </div>
</div>
