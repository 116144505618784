import { InventoryRule } from '@front/m19-api-client';
import { InventoryStats } from './InventoryStats';

/**
 * This function is replicated in `com.m19.common.config.InventoryActionRules.java`
 *
 * Any change to this function should be replicated to the Java one
 *
 * @param asinQuantity quantity of the ASIN (FBA + FBM)
 * @param orders30d 30d orders of the ASIN
 * @returns
 */
export function estimateDaysOfStock(asinQuantity: number, orders30d: number): number {
  if (orders30d == 0) {
    return 1000;
  }
  return Math.floor((asinQuantity * 30) / orders30d);
}

/**
 * This function is replicated in `com.m19.common.config.InventoryActionRules.java`
 * <p>
 * Any change to this method should be replicated to the Java one
 *
 * @param rule       The inventory rule for this ASIN
 * @param orders30d  30d orders of the ASIN
 * @param estimatedDaysOfStock  Number of days of available stock for this ASIN
 * @param inboundQuantity  Inbound quantity of the ASIN
 * @return true iff the advertising should be paused with this rule for the ASIN
 */
export function shouldPauseAdvertising(
  rule: InventoryRule,
  orders30d: number,
  estimatedDaysOfStock: number,
  inboundQuantity: number,
) {
  if (!rule) {
    return false;
  }
  if (orders30d < 10) {
    // rules are not applicable for ASIN with less than 10 orders over the last 30 days
    return false;
  }
  if (rule.activateAdvertisingWhenInbound && inboundQuantity > 0) {
    // do not pause advertising when there is inbound stock
    return false;
  }
  if (estimatedDaysOfStock >= rule.advertisingPauseThreshold!) {
    return false;
  }
  return true;
}

function getLevel(estimatedDaysOfStock: number, threshold: number): RuleExecutionResultEnum {
  if (estimatedDaysOfStock < threshold) {
    return RuleExecutionResultEnum.LOW_STOCK;
  }
  if (estimatedDaysOfStock < threshold * 3) {
    return RuleExecutionResultEnum.WARNING;
  }
  return RuleExecutionResultEnum.OK;
}

export class InventoryRules {
  public static readonly DEFAULT_PAUSE_THRESHOLD = 7;
  public static readonly ORDERS30D_ELLIGIBILITY_THRESHOLD = 10;

  public readonly asinInventoryRule: Map<string, InventoryRule> = new Map();

  constructor(public readonly rules: InventoryRule[]) {
    for (const rule of rules) {
      if (!rule.asin) {
        // TODO: this is a global account-marketplace level rule - to be implemented in a next version
        continue;
      }
      this.asinInventoryRule.set(rule.asin, rule);
    }
  }

  public execute(asin: string, stockLevel: InventoryStats): RuleExecutionResult {
    if (this.asinInventoryRule.has(asin)) {
      const rule = this.asinInventoryRule.get(asin);
      const pauseAdvertising = shouldPauseAdvertising(
        rule!,
        stockLevel.orders30d!,
        stockLevel.estimatedDaysOfStock!,
        stockLevel.inboundQuantity,
      );
      const level = getLevel(stockLevel.estimatedDaysOfStock!, rule!.advertisingPauseThreshold!);
      return { shouldPauseAdvertising: pauseAdvertising, detail: level };
    } else {
      // default rule (only show a warning in the UI)
      const level = getLevel(stockLevel.estimatedDaysOfStock!, InventoryRules.DEFAULT_PAUSE_THRESHOLD);
      return { shouldPauseAdvertising: false, detail: level };
    }
  }
}

export interface RuleExecutionResult {
  shouldPauseAdvertising: boolean;
  detail: RuleExecutionResultEnum;
}

export enum RuleExecutionResultEnum {
  LOW_STOCK = 'low',
  WARNING = 'warning',
  OK = 'ok',
}
