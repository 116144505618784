<ng-container *transloco="let t">
  @if (creativesWithoutCustomImage > 0) {
    <div class="alert alert-danger mt-4">
      <b>{{ t("sb-page.since_may_31_2024") }}</b
      >, {{ t("sb-page.sponsored_brands_product_no_longer_shown") }}
      <br />
      {{ t("sb-page.please_ensure_that_your_ad_lines_contain_a_custom_image") }}
      <b
        >{{ t("sb-creative-warning.impacted_lines", { count: creativesWithoutCustomImage }) }}
        <a
          class="text-underline"
          matTooltip="Show the list of strategies with missing custom image"
          (click)="showStrategiesWithoutCustomImage(); $event.preventDefault()"
          >{{ t("sb-creative-warning.live_strategies", { count: campaignWithoutCustomImage.size }) }}</a
        ></b
      >
    </div>
  }
  <ng-template #missingCustomImgModal>
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{ t("sb-creative-warning.strategies_with_missing_custom_image") }}</h4>
    </div>

    <div class="modal-body">
      @for (strategy of campaignWithoutCustomImageArr; track strategy.strategyId) {
        <div class="mb-2">
          <app-strategy-link [strategyId]="strategy.strategyId" [withCampaignState]="true" />
        </div>
      }
    </div>
  </ng-template>
</ng-container>
