import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  AccountSelectionService,
  groupBy,
  SegmentService,
  StatsService,
  UserSelectionService,
} from "@front/m19-services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { AgGridAngular } from "@ag-grid-community/angular";
import { GridOptions } from "@ag-grid-community/core";
import { AccountMarketplace, CampaignType, Currency, Placement, SegmentType } from "@front/m19-api-client";
import { getBasicGridOptions, SIDE_BAR_NO_PIVOT } from "@front/m19-grid-config";
import {
  ACOS,
  AD_CONVERSIONS,
  AD_SALES,
  CLICK_THROUGH_RATE,
  CLICKS,
  CONVERSION_RATE,
  COST,
  CPC,
  IMPRESSIONS,
  Metric,
  ROAS,
} from "@front/m19-metrics";
import { AdStatsEx, SegmentEx, StrategyEx, SupportedAudienceMatchType } from "@front/m19-models";
import { addAdStats, convertToCurrency } from "@front/m19-utils";
import { TranslocoService } from "@jsverse/transloco";
import { CurrencyColumn } from "@m19-board/grid-config/grid-columns";
import { exportGridCsv, getCsvFileName, getMetricsColDef } from "@m19-board/grid-config/grid-config";
import { StrategyStats, SubStrategyStats } from "@m19-board/models/Metric";
import { combineLatest, map, of, switchMap } from "rxjs";

export const PlacementDef: { [key in Placement]: string } = {
  [Placement.top]: "Top of search",
  [Placement.searchOnAmazon]: "Search on-Amazon,",
  [Placement.productPage]: "Product page",
  [Placement.offAmazon]: "Off-Amazon",
  [Placement.other]: "Other search",
};

@UntilDestroy()
@Component({
  selector: "app-strategy-placement-stats-grid",
  standalone: true,
  template: ` <div class="ag-theme-quartz h-full">
    <ag-grid-angular class="h-full" [gridOptions]="gridOptions" [rowData]="gridData" rowGroupPanelShow="always" />
  </div>`,
  imports: [AgGridAngular],
})
export class StrategyPlacementStatsGridComponent implements OnInit {
  private readonly StrategyPlacementGridKey = "strategyPlacementGrid2";

  PlacementDefKeys: { [key in Placement]: string } = {
    [Placement.top]: this.translocoService.translate("strategy-placement-stats-grid.top_of_search"),
    [Placement.searchOnAmazon]: this.translocoService.translate("strategy-placement-stats-grid.search_on-amazon"),
    [Placement.productPage]: this.translocoService.translate("strategy-placement-stats-grid.product_page"),
    [Placement.offAmazon]: this.translocoService.translate("strategy-placement-stats-grid.off-amazon"),
    [Placement.other]: this.translocoService.translate("strategy-placement-stats-grid.other_search"),
  };

  @Input({ required: true }) strategy!: StrategyEx;
  @Input({ required: true }) currency!: Currency;
  @Input({ required: true }) locale!: string;

  accountMarketplace?: AccountMarketplace;

  gridData?: SubStrategyStats[];
  previousDataPlacementMap: Map<string, AdStatsEx> = new Map<string, AdStatsEx>();

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  readonly METRIC_COLUMNS: Metric<StrategyStats>[] = [
    AD_SALES,
    AD_CONVERSIONS,
    COST,
    ACOS,
    CLICKS,
    IMPRESSIONS,
    CLICK_THROUGH_RATE,
    CONVERSION_RATE,
    CPC,
    ROAS,
  ];

  readonly gridOptions: GridOptions = {
    ...getBasicGridOptions(this.StrategyPlacementGridKey, true),
    groupDisplayType: "singleColumn",
    defaultColDef: {
      sortable: true,
      filter: true,
      resizable: true,
      useValueFormatterForExport: true,
    },
    columnDefs: [
      {
        headerValueGetter: () =>
          this.strategy.campaignType == CampaignType.SD
            ? this.translocoService.translate("strategy-placement-stats-grid.audience")
            : this.translocoService.translate("sp-strategy-group-page.tactic"),
        field: "subStrategyName",
        pinned: "left",
        rowGroup: true,
        hide: true,
        floatingFilter: true,
        enableRowGroup: true,
      },
      {
        headerName: this.translocoService.translate("strategy-placement-stats-grid.placement", {}, "en"),
        headerValueGetter: () => this.translocoService.translate("strategy-placement-stats-grid.placement"),
        field: "placement",
        pinned: "left",
        hide: true,
        floatingFilter: true,
        enableRowGroup: true,
      },
      ...getMetricsColDef(this.METRIC_COLUMNS, false).map(({ headerName, ...def }) => ({
        ...def,
        headerName: this.translocoService.translate(`metrics.${(def as any)["colId"]}_title`, {}, "en"),
        headerValueGetter: (params: any) => this.translocoService.translate(`metrics.${params.colDef.colId}_title`),
        cellRendererParams: (params: any) => {
          const prevData: AdStatsEx = {};
          let empty = true;
          if (params.node.childrenAfterFilter) {
            for (const n of params.node.childrenAfterFilter) {
              const key = n.data?.subStrategyId + "-" + n.data?.placement;
              const data = this.previousDataPlacementMap.get(key);
              if (data) {
                addAdStats(prevData, data);
                empty = false;
              }
            }
          }
          return {
            ...(def as any).cellRendererParams(params),
            previousData: empty ? undefined : prevData,
            currency: this.currency,
            locale: this.locale,
          };
        },
      })),
      { ...CurrencyColumn, headerName: this.translocoService.translate("common.currency") },
    ],
    groupLockGroupColumns: 1,
    getContextMenuItems: () => ["copy", "export", "chartRange"],
    sideBar: SIDE_BAR_NO_PIVOT,
    autoGroupColumnDef: {
      headerValueGetter: () =>
        this.strategy.campaignType == CampaignType.SD
          ? this.translocoService.translate("strategy-placement-stats-grid.audience_placement")
          : this.translocoService.translate("strategy-placement-stats-grid.tactic_placement"),
      field: "placement",
      colId: "placementGroup",
      suppressSizeToFit: true,
      maxWidth: 300,
      valueFormatter: (params) => {
        return params.node?.isRowPinned() ? "" : ((this.PlacementDefKeys as any)[params.value] ?? "Unknown placement");
      },
      floatingFilter: true,
      filter: "agGroupColumnFilter",
    },
  };

  constructor(
    private accountSelectionService: AccountSelectionService,
    private statsService: StatsService,
    private segmentService: SegmentService,
    private userSelectionService: UserSelectionService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    localStorage.removeItem("strategyPlacementGrid_gridConfig");
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((selection) => {
        this.accountMarketplace = selection;
      });
    combineLatest([
      combineLatest([
        this.accountSelectionService.singleAccountMarketplaceSelection$,
        this.userSelectionService.dateRange$,
      ]).pipe(
        switchMap(([am, dr]) =>
          combineLatest([
            this.userSelectionService.selectedCurrency$,
            this.statsService.getDailyPlacementStats(am.accountId, am.marketplace, dr[0], dr[1]),
          ]),
        ),
        map(([currency, data]) => convertToCurrency(data, currency)!),
      ),
      this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(
        switchMap((am) => this.segmentService.getSegments(am.accountId, am.marketplace)),
      ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([data, segmentIndex]) => {
        this.gridData = [];
        const placementData: AdStatsEx[] = Array.from(
          groupBy<string>(
            data.filter((d) => d.strategyId == this.strategy.strategyId),
            (x) => x?.strategyId + "-" + x?.subStrategyId + "-" + x?.placement,
          ).values(),
        );
        for (const data of placementData) {
          this.gridData.push(this.buildSubStrategy(data, segmentIndex));
        }
      });
    combineLatest([
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      this.userSelectionService.periodComparison$,
    ])
      .pipe(
        switchMap(([am, pc]) => {
          if (!pc?.period) {
            return of([]);
          }
          return combineLatest([
            this.userSelectionService.selectedCurrency$,
            this.statsService.getDailyPlacementStats(am.accountId, am.marketplace, pc.period[0], pc.period[1]),
          ]).pipe(
            map(([currency, data]) => {
              return convertToCurrency(data, currency)!;
            }),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe((previousData: AdStatsEx[]) => {
        this.previousDataPlacementMap.clear();
        Array.from(
          groupBy<string>(
            previousData.filter((d) => d.strategyId == this.strategy.strategyId),
            (d) => d.subStrategyId + "-" + d.placement,
          ).values(),
        ).forEach((d) => {
          this.previousDataPlacementMap.set(d.subStrategyId + "-" + d.placement, d);
        });

        // Have to redraw rows because we need all the row data and not just the cells
        this.agGrid?.api.redrawRows();
      });
  }

  private buildSubStrategy(d: AdStatsEx, segmentIndex: Map<number, SegmentEx>): SubStrategyStats {
    let subStrategyName = this.translocoService.translate("strategy-placement-stats-grid.all_other_traffic");
    if (d.subStrategyId) {
      // for remarketing, the subStrategyId is an audience
      if (d.segmentType === SegmentType.remarketing) {
        const audience = this.strategy?.audienceTargetings?.find((a) => a.audienceTargetId === d.subStrategyId);
        if (audience) {
          subStrategyName = `${SupportedAudienceMatchType[audience.matchType!]} last ${audience.lookback} days`;
        } else {
          subStrategyName = "Deleted Audience";
        }
      } else {
        // the subStrategyId is null or a tactic
        const segment = segmentIndex.get(d.subStrategyId);
        const deletedFromStrategy = !this.strategy?.tactics.map((t) => t.segmentId).includes(d.subStrategyId);
        subStrategyName = segment?.name ? segment.name + (deletedFromStrategy ? " (Deleted)" : "") : "Deleted Segment";
      }
    }

    return {
      ...d,
      placement: d.placement,
      strategyName: this.strategy.name!,
      state: this.strategy.state,
      subStrategyName: subStrategyName,
    };
  }

  public exportCsv() {
    const suffix =
      this.strategy.campaignType == CampaignType.SD ? "audience_placement_stats" : "tactic_placement_stats";

    const fileName = getCsvFileName(
      this.strategy.name + "_" + suffix,
      this.accountMarketplace!.accountGroupName,
      this.accountMarketplace!.marketplace,
      this.userSelectionService.getDateRangeStr(),
    );
    const columnKeys: string[] = this.agGrid?.api
      .getAllDisplayedColumns()
      .map((c) => c.getColId())
      .concat(["currency"]);

    exportGridCsv(this.agGrid?.api, { fileName, columnKeys, skipColumnGroupHeaders: true });
  }
}
