@for (metric of availableMetrics; track metric) {
  <div
    class="vertical-bar div-selected mt-2 py-2 pl-2"
    (click)="selectMetric(metric)"
    [ngClass]="{ selected: isSelected(metric) }"
    [ngStyle]="{
      'border-left-color': metric.color,
      'background-image': 'linear-gradient(to right, ' + metric.color + ' 50%, rgba(255,255,255,0) 50%)',
    }"
    *transloco="let t"
  >
    {{ metric.title }}
    <!-- {{ t("metrics." + metric.id + "_title") }} -->
  </div>
}
