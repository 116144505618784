import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  CampaignType,
  MatchType,
  StrategyAsin,
  StrategyStateEnum,
  StrategyType,
  TacticType,
  Targeting,
} from "@front/m19-api-client";
import { ConfirmPopupComponent } from "@m19-board/shared/confirm-popup/confirm-popup.component";
import { StrategyAsinSelectionMode } from "@m19-board/strategies/strategy-asins/asins-selection.component";
import { TargetingLimit } from "@m19-board/strategies/target-utils";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { combineLatest, forkJoin, Observable, switchMap, take } from "rxjs";
import { StrategyGroupMoveProductModalComponent } from "./move-product/strategy-group-move-product-modal.component";
import { Constant } from "libs/m19-services/src/lib/m19-services/constant";
import { SegmentService } from "libs/m19-services/src/lib/m19-services/segmentService";
import { StrategyEx, StrategyGroupEx, StrategyTargetingType, StrategyTypeStr } from "@front/m19-models";
import { AccountSelectionService, AsinService, ConfigService, StrategyService } from "@front/m19-services";
import { TranslocoService } from "@jsverse/transloco";

@UntilDestroy()
@Component({
  selector: "app-sp-substrategy",
  templateUrl: "./sp-substrategy.component.html",
  styleUrls: ["./sp-substrategy-creation/sp-substrategy-creation.component.scss"],
})
export class SpSubStrategyComponent implements OnInit {
  @Input()
  strategy$: Observable<StrategyEx>; // TODO: to remove, do not pass an observable as input

  private _strategy: StrategyEx;
  @Input()
  set strategy(strategy: StrategyEx) {
    this._strategy = strategy;
    if (!strategy) {
      return;
    }
    // warnings
    this.strategyWarnings = [];
    if (strategy.disableOtherQueries) {
      this.strategyWarnings.push("AI-powered targeting is deactivated (not recommended)");
    }

    this.productTargetings = strategy.targetings
      .filter((t) => t.matchType == MatchType.asinSameAs)
      .map((p) => ({
        asin: p.targetingValue,
      }));
    this.keywordTargetings = strategy.targetings.filter(
      (t) => t.matchType == MatchType.exact || t.matchType == MatchType.phrase,
    );
  }

  get strategy(): StrategyEx {
    return this._strategy;
  }

  @Input()
  strategyGroup: StrategyGroupEx;

  isReadOnly = false;
  asinEligibility: Map<string, { status: boolean; reason: string }> = new Map();
  strategyTypeStr: string;
  strategyType: StrategyType;
  withTacticSection: boolean;
  withTOSROSwitch: boolean;
  withAutoCampaignOption: boolean;
  withProductTargetingOption: boolean;
  productSelectionModes: { selectionMode: StrategyAsinSelectionMode; label: string }[] = [];
  productTargetingSelectionModes: { selectionMode: StrategyAsinSelectionMode; label: string }[];
  productTargetingAllowAsinFromOtherCatalog: boolean;
  strategyWarnings: string[] = [];
  strategyTargetingType: StrategyTargetingType;
  productTargetings: StrategyAsin[];
  asinsCanBeMovedToOtherStrat: boolean;
  keywordTargetings: Targeting[];

  @ViewChild("productAdd") productAddModal: TemplateRef<any>;
  addAsinModalRef: BsModalRef;

  readonly maxKwTargetingByStrategy = Constant.maxKwTargetingByStrategy;
  readonly maxAsinTargetingByStrategy = Constant.maxAsinTargetingByStrategy;
  readonly State = StrategyStateEnum;
  readonly faWarning = faExclamationTriangle;
  readonly StrategyTargetingType = StrategyTargetingType;
  readonly sectionVisibility = {
    products: true,
    targetingStats: false,
  };

  protected readonly StrategyType = StrategyType;
  protected readonly CampaignType = CampaignType;

  constructor(
    public bsModalRef: BsModalRef,
    private asinService: AsinService,
    private configService: ConfigService,
    private toastrService: ToastrService,
    private accountMarketplaceSelection: AccountSelectionService,
    private modalService: BsModalService,
    private segmentService: SegmentService,
    private strategyService: StrategyService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.accountMarketplaceSelection.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
    this.asinService
      .getCatalog(this.strategyGroup.accountId, this.strategyGroup.marketplace)
      .pipe(untilDestroyed(this))
      .subscribe((catalog) => {
        this.asinEligibility = catalog.getSPEligibility();
      });
    this.strategyTypeStr = StrategyTypeStr[this.strategy.strategyType];
    this.strategyType = this.strategy.strategyType;
    this.withTacticSection = this.strategy.strategyType == StrategyType.PRODUCT;
    this.withAutoCampaignOption = this.strategy.strategyType == StrategyType.PRODUCT;
    this.withProductTargetingOption = this.strategy.strategyType == StrategyType.PRODUCT;
    this.withTOSROSwitch = this.strategy.strategyType == StrategyType.PRODUCT;
    this.productSelectionModes =
      this.strategy.strategyType != StrategyType.PRODUCT
        ? [
            {
              selectionMode: StrategyAsinSelectionMode.FromCustomAsinList,
              label: "sp-substrategy-creation.from_strategy_group_asins",
            },
          ]
        : [
            { selectionMode: StrategyAsinSelectionMode.FromCatalog, label: "v2-sidebar.catalog" },
            { selectionMode: StrategyAsinSelectionMode.Bulk, label: "sp-substrategy-creation.bulk" },
            {
              selectionMode: StrategyAsinSelectionMode.FromProductGroups,
              label: "product-group-bulk-upload-result-modal.product_group",
            },
          ];
    this.strategyWarnings = [];
    if (this.strategy.disableOtherQueries) {
      this.strategyWarnings.push("AI-powered targeting is deactivated (not recommended)");
    }
    this.asinsCanBeMovedToOtherStrat = this.strategy.strategyType == StrategyType.PRODUCT;

    if (this.strategy.targetings.length > 0 && this.strategy.targetings[0].matchType == MatchType.asinSameAs) {
      this.strategyTargetingType = StrategyTargetingType.PRODUCTS;
    } else {
      this.strategyTargetingType = StrategyTargetingType.KEYWORDS;
    }
    if (this.strategyType == StrategyType.BRAND) {
      this.productTargetingSelectionModes = [
        { selectionMode: StrategyAsinSelectionMode.FromCatalog, label: "sp-substrategy-creation.from_catalog" },
        { selectionMode: StrategyAsinSelectionMode.Bulk, label: "sp-substrategy-creation.from_asin_list" },
        {
          selectionMode: StrategyAsinSelectionMode.FromProductGroups,
          label: "sp-substrategy-creation.from_product_groups",
        },
      ];
      this.productTargetingAllowAsinFromOtherCatalog = false;
    } else {
      this.productTargetingSelectionModes = [
        { selectionMode: StrategyAsinSelectionMode.Bulk, label: "sp-substrategy-creation.asin_list" },
      ];
      this.productTargetingAllowAsinFromOtherCatalog = true;
    }
    combineLatest([
      this.strategy$,
      this.accountMarketplaceSelection.singleAccountMarketplaceSelection$.pipe(
        switchMap((am) => this.segmentService.getSegments(am.accountId, am.marketplace)),
      ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([strategy, segmentIndex]) => {
        const segments = strategy.tactics
          .filter((tactic) => tactic.tacticType == TacticType.LEGACY)
          .map((tactic) => segmentIndex.get(tactic.segmentId!)!);
        const blacklist = strategy.tactics
          .filter((tactic) => tactic.tacticType == TacticType.BLACKLIST)
          .map((tactic) => segmentIndex.get(tactic.segmentId!)!);
        const targetLimit = new TargetingLimit(segments, blacklist);
        if (targetLimit.tooManyTargets()) {
          this.strategyWarnings.push(targetLimit.formatErrorMessage());
        }
      });
  }

  close() {
    this.bsModalRef.hide();
  }

  showAddAsinModal() {
    this.addAsinModalRef = this.modalService.show(this.productAddModal, {
      class: "modal-xl modal-dialog-centered modal-primary",
    });
  }

  toggleSectionVisibility(section: keyof typeof this.sectionVisibility) {
    this.sectionVisibility[section] = !this.sectionVisibility[section];
  }

  changeStatus(): void {
    const newState =
      this.strategy.state == StrategyStateEnum.ENABLED ? StrategyStateEnum.PAUSED : StrategyStateEnum.ENABLED;
    this.strategyService
      .updateStrategyState(this.strategy.accountId, this.strategy.marketplace, this.strategy.strategyId, newState)
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("sp-substrategy.strategy_this_strategy_successfully_updated", [
              this.strategy.getName(),
            ]),
            this.translocoService.translate("sp-strategy-group-page.strategy_updated"),
          );
        },
        error: (err) => {
          this.toastrService.error(err, "Strategy Update Error");
        },
      });
  }

  addProducts(toAdd: StrategyAsin[]) {
    this.strategy$
      .pipe(
        take(1),
        switchMap((strat) =>
          this.strategyService.addAsinsToStrategy(
            strat,
            toAdd.map((a) => a.asin!),
          ),
        ),
      )
      .subscribe({
        next: () => {
          this.toastrService.success(`ASIN${toAdd.length > 1 ? "s" : ""} added to strategy`, "Strategy ASIN Updated");
        },
        error: (error: string) => {
          this.toastrService.error(
            `Error adding Strategy ASIN${toAdd.length > 1 ? "s" : ""}: ${error}`,
            "Strategy ASIN Update Error",
          );
        },
      });
  }

  removeProducts(toRemove: StrategyAsin[]) {
    if (this.strategyType != StrategyType.PRODUCT) {
      this.strategy$
        .pipe(
          take(1),
          switchMap((strat) =>
            this.strategyService.deleteAsinsFromStrategy(
              strat,
              toRemove.map((a) => a.asin!),
            ),
          ),
        )
        .subscribe({
          next: () => {
            this.toastrService.success(
              `ASIN${toRemove.length > 1 ? "s" : ""} removed from strategy`,
              "Strategy ASIN Updated",
            );
          },
          error: (error: string) => {
            this.toastrService.error(
              `Error removing Strategy ASIN${toRemove.length > 1 ? "s" : ""}: ${error}`,
              "Strategy ASIN Update Error",
            );
          },
        });
      return;
    }
    // for product strategy deleting products might impact other strategies
    this.strategy$
      .pipe(
        take(1),
        switchMap((strat) => {
          // for product strategy deleting products might impact other strategies
          const toRemoveAsins = toRemove.map((x) => x.asin!);
          const toRemoveSet = new Set<string>(toRemoveAsins);
          const impactedStrats = new Map<StrategyEx, string[]>();
          for (const otherStrat of this.strategyGroup.brandStrategies.concat(this.strategyGroup.keywordStrategies)) {
            const intersection = otherStrat.asins.map((x) => x.asin).filter((a) => toRemoveSet.has(a));
            if (intersection.length > 0) {
              impactedStrats.set(otherStrat, intersection);
            }
          }
          if (impactedStrats.size == 0) {
            return this.strategyService.deleteAsinsFromStrategy(strat, toRemoveAsins);
          }
          // confirmation modal
          const modalOpts: ModalOptions = {
            initialState: {
              title: "Removing ASINs from Main strategy",
              message: `These ASINs are used in ${impactedStrats.size} brand defense or focus ${
                impactedStrats.size > 1 ? "strategies" : "strategy"
              } (${Array.from(impactedStrats.keys())
                .map((s) => `"${s.name}"`)
                .join(", ")}). They will stop running for these products.`,
            },
          };
          const modalRef = this.modalService.show(ConfirmPopupComponent, modalOpts);
          return modalRef.content.confirm.pipe(
            switchMap(() =>
              forkJoin(
                Array.from(impactedStrats.entries()).map(([strat, asins]) =>
                  this.strategyService.deleteAsinsFromStrategy(strat, asins),
                ),
              ),
            ),
            switchMap(() => this.strategyService.deleteAsinsFromStrategy(strat, toRemoveAsins)),
            // TODO: also stop strategies if no more ASINs
          );
        }),
      )
      .subscribe({
        next: () => {
          this.toastrService.success(
            `ASIN${toRemove.length > 1 ? "s" : ""} removed from strategy`,
            "Strategy ASIN Updated",
          );
        },
        error: (error: string) => {
          this.toastrService.error(
            `Error removing Strategy ASIN${toRemove.length > 1 ? "s" : ""}: ${error}`,
            "Strategy ASIN Update Error",
          );
        },
      });
  }

  moveToOtherStrategy(asins: StrategyAsin[]) {
    const modalOpts: ModalOptions = {
      initialState: {
        strategyGroup: this.strategyGroup,
        source: this.strategy,
      },
      class: "modal-lg",
    };
    const modalRef = this.modalService.show(StrategyGroupMoveProductModalComponent, modalOpts);
    modalRef.content.target
      .pipe(
        switchMap((target) => {
          if (target.type == "NewProductStrategy") {
            // create a new strategy and delete the strategy from the current one
            return this.configService.createStrategyAsync({ ...target.productStrategyToCreate, asins: asins }).pipe(
              switchMap(() =>
                this.strategyService.deleteAsinsFromStrategy(
                  this.strategy,
                  asins.map((a) => a.asin!),
                ),
              ),
            );
          }
          if (target.type == "NewStrategyGroup") {
            return this.configService
              .createStrategyGroup(
                {
                  accountId: target.productStrategyToCreate!.accountId,
                  marketplace: target.productStrategyToCreate!.marketplace,
                  strategyGroupName: target.productStrategyToCreate!.name!,
                },
                { ...target.productStrategyToCreate, asins: asins },
              )
              .pipe(
                switchMap(() =>
                  this.strategyService.deleteAsinsFromStrategy(
                    this.strategy,
                    asins.map((a) => a.asin!),
                  ),
                ),
              );
          }
          return this.strategyService.moveAsinsToStrategy(
            asins.map((a) => a.asin!),
            this.strategy,
            target.productStrategy!,
          );
        }),
      )
      .subscribe({
        next: () => {
          this.toastrService.success(`ASINs moved to another main strategy`);
        },
        error: (error: string) => {
          this.toastrService.error(`Error moving ASINs: ${error}`, "Error moving ASINs");
        },
      });
  }
}
