<ng-container *transloco="let t">
  <app-stats-overlay>
    <div class="my-3 flex justify-between">
      <div class="w-30">
        <div class="input-group">
          <input
            type="text"
            class="form-control simple-input"
            placeholder="{{ t('product-group.search_by_product_group_name_or_asin') }}"
            (input)="setFilter(getValueFromInputEvent($event))"
          />
        </div>
      </div>
      <div class="flex items-center gap-3">
        <IButton
          [disabled]="isReadOnly"
          [icon]="ICON_ADD"
          (onClick)="createProductGroup(accountMarketplace.accountId, accountMarketplace.marketplace)"
          color="main"
          label="{{ t('product-group.create_a_new_product_group') }}"
        />
        <IButton
          [disabled]="isReadOnly"
          tooltipValue="{{ t('product-group.upload_strategy_data') }}"
          [icon]="ICON_IMPORT"
          (onClick)="uploadProductGroups()"
          color="white"
          label="{{ t('common.import') }}"
        />
        <app-export-button
          tooltipValue="{{ t('product-group.export_product_group_list_as_csv') }}"
          (export)="exportProductGroups()"
        ></app-export-button>
      </div>
    </div>
    @if (productGroups) {
      <div class="table-container">
        <mat-table
          [dataSource]="dataSource"
          matSort
          class="responsive-table table-striped-two table-hover"
          multiTemplateDataRows
        >
          <ng-container matColumnDef="selection">
            <mat-header-cell *matHeaderCellDef class="flex-half flex flex-row items-center justify-between">
              <span
                class="select-button-box"
                matTooltip="{{
                  allSelected()
                    ? t('product-group.unselect_all_product_groups')
                    : t('product-group.select_all_product_groups')
                }}"
              >
                <fa-icon
                  [icon]="allSelected() ? faCheckedSquare : faSquare"
                  size="lg"
                  (click)="!isReadOnly && toggleSelectAll()"
                ></fa-icon>
              </span>
              @if (nbSelected() > 0) {
                <span
                  class="delete-content"
                  role="button"
                  (click)="deleteSelected()"
                  matTooltip="{{ t('product-group.delete_n_product_group', { count: nbSelected() }) }}"
                >
                  <fa-icon [icon]="faTrash"></fa-icon>
                  ({{ allSelected() ? t("common.all") : nbSelected() }})
                </span>
              }
            </mat-header-cell>
            <mat-cell *matCellDef="let productGroup" class="flex-half flex flex-row justify-between">
              <span
                class="select-button-box"
                matTooltip="{{ t('product-group.select_product_group') }} '{{ productGroup.productGroupName }}'"
              >
                <fa-icon
                  [icon]="selected(productGroup) ? faCheckedSquare : faSquare"
                  size="lg"
                  (click)="!isReadOnly && select(productGroup)"
                ></fa-icon>
              </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="productGroupName">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="flex-3">{{
              t("product-group.product_group_name")
            }}</mat-header-cell>
            <mat-cell *matCellDef="let productGroup" class="flex-3">
              <button
                class="btn btn-ghost-secondary btn-sm icon-right"
                matTooltip="{{ t('product-group.change_product_group_name') }}"
                (click)="editProductGroupName(productGroup)"
              >
                {{ productGroup.productGroupName }}
                <fa-icon class="ml-2" [icon]="faPencil"></fa-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="productsNb">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="flex-half">{{
              t("common.products")
            }}</mat-header-cell>
            <mat-cell *matCellDef="let productGroup" class="flex-half"> {{ productGroup.items.length }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="products">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="flex-3"></mat-header-cell>
            <mat-cell
              *matCellDef="let productGroup"
              class="flex-3 sensitive-data"
              (click)="productGroup.items.length > 3 && toggleExpansion(productGroup)"
            >
              @if (!expanded(productGroup)) {
                <div>
                  @for (item of productGroup.items.slice(0, 3); track item; let last = $last) {
                    <span
                      (mouseenter)="displayProductDetails(trigger, item)"
                      type="button"
                      cdkOverlayOrigin
                      #trigger="cdkOverlayOrigin"
                    >
                      <a
                        class="text-color"
                        [href]="item | amazonAsinUrlPipe: accountMarketplace.marketplace"
                        target="_blank"
                        (click)="$event.stopPropagation()"
                        >{{ item }}</a
                      >{{ last ? "" : ", " }}</span
                    >
                  }
                  &nbsp;
                  @if (productGroup.items.length > 3) {
                    <button
                      class="btn btn-ghost-secondary btn-sm"
                      matTooltip="{{ t('common.see_more') }}"
                      (click)="toggleExpansion(productGroup); $event.stopPropagation()"
                    >
                      {{ t("common.see_more") }}
                    </button>
                  }
                </div>
              } @else {
                <div class="flex items-end justify-between">
                  <div>
                    @for (item of productGroup.items; track item; let last = $last) {
                      <span
                        (mouseenter)="displayProductDetails(trigger, item)"
                        cdkOverlayOrigin
                        #trigger="cdkOverlayOrigin"
                      >
                        <a
                          class="text-color"
                          [href]="item.targetingValue | amazonAsinUrlPipe: accountMarketplace.marketplace"
                          target="_blank"
                          (click)="$event.stopPropagation()"
                          >{{ item }}</a
                        >{{ last ? "" : ", " }}</span
                      >
                    }
                  </div>
                  <button
                    class="btn btn-ghost-secondary btn-sm pull-right ml-1"
                    matTooltip="{{ t('segment-manager.hide_details') }}"
                    (click)="toggleExpansion(productGroup); $event.stopPropagation()"
                  >
                    <fa-icon [icon]="faAngleUp"></fa-icon>
                  </button>
                </div>
              }
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="flex-2"></mat-header-cell>
            <mat-cell *matCellDef="let productGroup" class="flex-2 justify-end">
              <button
                class="btn btn-ghost-secondary"
                (click)="deleteProductGroup(productGroup)"
                matTooltip="{{ t('product-group.delete_product_group', [productGroup.productGroupName]) }}"
                [disabled]="isReadOnly"
              >
                <fa-icon [icon]="faTrash" class="font-xl"></fa-icon>
              </button>
              <button
                type="button"
                class="btn btn-ghost-secondary"
                (click)="openProductGroupPage(productGroup)"
                matTooltip="{{ t('product-group.edit_product_group', [productGroup.productGroupName]) }}"
              >
                <fa-icon [icon]="faPencil" class="font-xl mx-1 align-middle"></fa-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            class="header mat-header-row-no-space"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ 'h-auto': expanded(row) }"></mat-row>
        </mat-table>
      </div>
    }
    <mat-paginator
      [pageSizeOptions]="[5, 10, 50, 100]"
      [pageSize]="10"
      showFirstLastButtons
      style="margin-bottom: 2rem"
      [hidden]="!dataSource.data || dataSource.data.length === 0"
    ></mat-paginator>
  </app-stats-overlay>
  <!--Product details overlay content connected to ASIN hover -->
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="productDetailsOrigin"
    [cdkConnectedOverlayOpen]="!!productDetailsAsin"
  >
    <div class="product-tooltip border-shadow" #productDetailsPopup>
      <app-product-view [asin]="productDetailsAsin" [marketplace]="accountMarketplace.marketplace"></app-product-view>
    </div>
  </ng-template>
</ng-container>
