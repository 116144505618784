import { NgStyle } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  SimpleChanges,
} from "@angular/core";
import { IButtonComponent } from "@front/m19-ui";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { ICON_CHECK, ICON_CLOSE, ICON_TRASH_O } from "@m19-board/utils/iconsLabels";
import { ToastrService } from "ngx-toastr";

@Pipe({ name: "intInputErrorPipe", standalone: true })
export class IntInputErrorPipe implements PipeTransform {
  transform(value: number, min: number, max: number): string {
    return value < min ? `Must be greater than or equal to ${min}` : `Must be less than or equal to ${max}`;
  }
}

@Component({
  standalone: true,
  selector: "app-int-input",
  imports: [NgStyle, IButtonComponent, IntInputErrorPipe, TranslocoRootModule],
  templateUrl: "./int-input.component.html",
  styleUrls: ["./int-input.component.scss"],
})
export class IntInputComponent implements OnInit, OnChanges {
  @Input() label: string;

  @Input() min: number;

  @Input() max: number;

  @Input() warningMinThreshold: number;

  @Input() step: number;

  @Input() value: number;

  @Input() size: number;

  @Input() inputSize: number;

  @Input() disabled = false;

  @Input() unit: string;

  @Input() trash: boolean;

  @Input() deleteTitle = "Delete value";

  @Input() warningMessage = "";

  oldvalue: number;

  @Output() onFocused = new EventEmitter<boolean>();

  @Output() onNewValue = new EventEmitter<number>();

  @Output() onInput = new EventEmitter<number>();

  @Output() onDelete = new EventEmitter<void>();

  readonly ICON_CHECK = ICON_CHECK;
  readonly ICON_CLOSE = ICON_CLOSE;
  readonly ICON_TRASH = ICON_TRASH_O;

  constructor(private toasterService: ToastrService) {}

  ngOnInit() {
    // Si on me donne null, on ne set pas de valeurs
    if (this.max === null) this.max = undefined;
    if (this.min === null) this.min = undefined;
    if (this.step === null) this.step = 1;

    this.oldvalue = this.value;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["value"]?.previousValue != changes["value"]?.currentValue) {
      this.oldvalue = this.value;
    }
  }

  sendValue() {
    if (this.value === undefined || this.value < this.min || this.value > this.max) return;
    if (this.value != this.oldvalue) {
      this.oldvalue = this.value;
      this.onNewValue.emit(this.value);
    }
  }

  resetValue() {
    this.value = this.oldvalue;
  }

  input(event: any) {
    this.value = event.target.value != "" ? parseInt(event.target.value) : undefined;
    this.onInput.emit(this.value);
  }

  checkkey(event: KeyboardEvent) {
    return event.key >= "0" && event.key <= "9";
  }

  delete() {
    this.oldvalue = undefined;
    this.onDelete.emit();
  }
}
