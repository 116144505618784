<div #popperToggle>
  <ng-content />
</div>

<div
  class="invisible absolute z-[1000] max-h-60 scale-90 scroll-py-1 overflow-y-auto rounded-md bg-white p-1 opacity-0 shadow-lg ring-1 ring-gray-200 duration-100 ease-in-out focus:outline-none dark:bg-gray-800"
  #popper
>
  <ul class="d-flex flex-column text-gray-700">
    @for (item of items; track item) {
      <li class="group inline-flex w-full cursor-pointer items-center p-1">
        @if (item.href) {
          <a
            [href]="item.href"
            target="_blank"
            class="flex w-full items-center whitespace-nowrap rounded px-2 py-1 text-sm no-underline group-hover:bg-gray-100"
            (click)="item.onClick ? item.onClick() : null"
          >
            <ng-template [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{ $implicit: item }"></ng-template>
          </a>
        } @else {
          <a
            [routerLink]="item.routerLink"
            class="flex w-full items-center whitespace-nowrap rounded px-2 py-1 text-sm no-underline group-hover:bg-gray-100"
            (click)="item.onClick ? item.onClick() : null"
          >
            <ng-template [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{ $implicit: item }"></ng-template>
          </a>
        }
      </li>
    }
  </ul>
</div>

<ng-template #content let-item>
  <span [class]="item.icon + ' mr-2 text-xl'"></span>
  {{ item.label }}
</ng-template>
