import { inject, Injectable } from "@angular/core";
import { Translation, TranslocoLoader } from "@jsverse/transloco";
import { HttpClient } from "@angular/common/http";
import { environment } from "../environments/environment";

@Injectable({ providedIn: "root" })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  getTranslation(lang: string) {
    if (lang == "en" || lang == "dev") {
      return this.http.get<Translation>("/assets/i18n/en.json");
    }
    switch (environment.languageServer) {
      case "local":
        return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
      case "i18n-local":
        return this.http.get<Translation>(`http://localhost:8082/backend/translationsExport?languageCode=${lang}`);
      case "board-i18n":
        return this.http.get<Translation>(`https://board-i18n.m19.com/backend/translationsExport?languageCode=${lang}`);
      case "prod":
      default:
        return this.http.get<Translation>(`https://translation.m19.com/i18n/${lang}.json`);
    }
  }
}
