<ng-container *transloco="let t">
  @if (displayEventAnnotation() && !disableEventAnnotation()) {
    <div class="flex min-w-0 flex-1 gap-x-3">
      @if (allEventAnnotationTypes()) {
        <IMultiSelect
          class="min-w-0 flex-1"
          [options]="allEventAnnotationTypes()!"
          [selected]="selectedEventAnnotationTypes()"
          (selectedChange)="selectEventAnnotationTypes($event)"
          placeholder="{{ t('dashboard.select_activites_tooltip') }}"
          searchable
          rounded
          withSelectAll
        >
          <ng-template #labelSlot let-o>
            <span class="truncate">
              <span class="font-medium text-gray-500">{{ t("dashboard.activity") }}</span>
              {{
                selectedEventAnnotationTypes()[0].label +
                  (selectedEventAnnotationTypes().length > 1
                    ? " (+" + (selectedEventAnnotationTypes().length - 1) + ")"
                    : "")
              }}
            </span>
          </ng-template>
        </IMultiSelect>
      }
      @if (allUsers()) {
        <IMultiSelect
          class="min-w-0 flex-1"
          [options]="allUsers()!"
          [selected]="selectedUsers()"
          (selectedChange)="selectUsers($event)"
          placeholder="{{ t('dashboard.select_users') }}"
          searchable
          withSelectAll
          rounded
        >
          <ng-template #labelSlot let-o>
            <span class="truncate">
              <span class="font-medium text-gray-500">{{ t("common.users") }}</span>
              {{
                selectedUsers()[0].label +
                  (selectedUsers().length > 1 ? " (+" + (selectedUsers().length - 1) + ")" : "")
              }}
            </span>
          </ng-template>
        </IMultiSelect>
      }
      @if (allStrategies()) {
        <IMultiSelect
          class="min-w-0 flex-1"
          [options]="allStrategies()!"
          [selected]="selectedStrategies()"
          (selectedChange)="selectStrategies($event)"
          placeholder="{{ t('overview.select_strategy') }}"
          searchable
          withSelectAll
          rounded
        >
          <ng-template #labelSlot let-o>
            <span class="truncate">
              <span class="font-medium text-gray-500">{{ t("common.startegies") }}</span>
              {{
                selectedStrategies()[0].label +
                  (selectedStrategies().length > 1 ? " (+" + (selectedStrategies().length - 1) + ")" : "")
              }}
            </span>
          </ng-template>
        </IMultiSelect>
      }
    </div>
  }
</ng-container>
