<ng-container *transloco="let t">
  <div class="container">
    <app-spinner class="placeholder" [display]="loader"></app-spinner>
    @if (!loader && !barChartData) {
      <div class="placeholder text-center">{{ t("top-queries-list.no_data") }}</div>
    }
    <div class="share-chart-container">
      @if (!loader && metric.type === RATIO) {
        <canvas
          baseChart
          class="chart"
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="horizontalBarOptions"
          [plugins]="chartPlugins"
        ></canvas>
      }
      @if (!loader && metric.type !== RATIO) {
        <canvas
          baseChart
          class="chart"
          [datasets]="pieChartData"
          [options]="pieChartOptions"
          [plugins]="chartPlugins"
        ></canvas>
      }
    </div>
  </div>
</ng-container>
