import { Component } from "@angular/core";

import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AccountSelectionService } from "@front/m19-services";
import { SELLER_PRODUCT_TABS, VENDOR_PRODUCT_TABS } from "@m19-board/tabbed-nav/tabbed-nav.component";
import { NavData } from "@m19-board/_nav";
import { AccountType } from "@front/m19-api-client";

@UntilDestroy()
@Component({
  selector: "app-products",
  template: ` <app-tabbed-nav [links]="tabs"></app-tabbed-nav>
    <router-outlet></router-outlet>`,
})
export class ProductsComponent {
  tabs: NavData[] = SELLER_PRODUCT_TABS;

  constructor(private accountSelectionService: AccountSelectionService) {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((accountMarketplace) => {
        this.tabs = accountMarketplace.accountType == AccountType.SELLER ? SELLER_PRODUCT_TABS : VENDOR_PRODUCT_TABS;
      });
  }
}
