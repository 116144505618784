export interface AlertService {
  /** show toast */
  show(message?: string, title?: string, override?: any, type?: string): any;

  /** show successful toast */
  success(message?: string, title?: string, override?: any): any;

  /** show error toast */
  error(message?: string, title?: string, override?: any): any;

  /** show info toast */
  info(message?: string, title?: string, override?: any): any;

  /** show warning toast */
  warning(message?: string, title?: string, override?: any): any;
}
