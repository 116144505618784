import { Component, OnInit } from "@angular/core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { AccountMarketplace } from "@front/m19-api-client";
import { AccountSelectionService, AsinService } from "@front/m19-services";
import { Utils } from "@front/m19-utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ToastrService } from "ngx-toastr";

@UntilDestroy()
@Component({
  selector: "app-catalog-upload",
  templateUrl: "./catalog-upload.component.html",
  styleUrls: ["./catalog-upload.component.scss"],
})
export class CatalogUploadComponent implements OnInit {
  errors: string[] = [];

  accountMarketplace!: AccountMarketplace;

  isReadOnly = false;

  readonly faTimes = faTimes;

  catalogPage = "/product-center/catalog";

  constructor(
    private asinService: AsinService,
    private accountSelection: AccountSelectionService,
    private toasterService: ToastrService,
  ) {}

  ngOnInit() {
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((accountMarketplace) => {
        this.accountMarketplace = accountMarketplace;
      });

    this.accountSelection.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
  }

  addAsinToCatalog(inputAsins: string) {
    const newAsinsList: string[] = [];
    inputAsins
      .split(/[\s,;\n]+/)
      .map((asin) => Utils.normalizeASIN(asin))
      .filter((asin) => asin != "")
      .forEach((asin) => {
        if (!Utils.isValidAsin(asin)) this.errors.push(asin + ": Invalid ASIN");
        else if (newAsinsList.includes(asin)) this.errors.push(asin + ": Duplicate");
        else newAsinsList.push(asin);
      });
    if (newAsinsList.length == 0) {
      this.toasterService.error(`No asins to add to catalog`, "Catalog update");
      return;
    }
    this.asinService
      .addAsins(this.accountMarketplace.accountId, this.accountMarketplace.marketplace, newAsinsList)
      .subscribe(
        () => {
          this.toasterService.success(`Successfully added ASINs to catalog`, "Catalog update");
          this.asinService.getCatalog(this.accountMarketplace.accountId, this.accountMarketplace.marketplace, true);
        },
        () => {
          this.toasterService.error(`Error adding ASINs to catalog`, "Catalog update");
        },
      );
  }
}
