import { Component, Input, OnInit } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { StrategyEx } from "@front/m19-models";
import { ActivityFilter } from "@m19-board/activities/activity/activity.component";
import { CampaignType, EntityIdType } from "@front/m19-api-client";

@UntilDestroy()
@Component({
  selector: "app-strategy-activity-component",
  template: ` <div class="activity-section">
    <app-activity-component
      [activityFilter]="activityFilter"
      [hideEntityColumn]="true"
      gridConfigStorageKey="strategyActivities"
    ></app-activity-component>
  </div>`,
  styles: [".activity-section {height: 30rem;}"],
})
export class StrategyActivityComponent implements OnInit {
  @Input()
  strategy: StrategyEx;

  activityFilter: ActivityFilter[];

  ngOnInit() {
    this.activityFilter = [{ primaryType: EntityIdType.strategyId, primaryId: this.strategy.strategyId }];
    if (
      this.strategy.campaignType == CampaignType.SB &&
      this.strategy.sbCreatives &&
      this.strategy.sbCreatives.length > 0
    ) {
      this.activityFilter.push(
        ...this.strategy.sbCreatives.map((c) => ({
          primaryType: EntityIdType.creativeId,
          primaryId: c.creativeId,
        })),
      );
    }
    if (this.strategy.campaignType == CampaignType.SD) {
      this.activityFilter.push(
        ...this.strategy.audienceTargetings.map((a) => ({
          primaryType: EntityIdType.audienceTargetId,
          primaryId: a.audienceTargetId,
        })),
      );
    }
  }
}
