import { Pipe, PipeTransform } from "@angular/core";
import { CustomCampaignNameTokens } from "@front/m19-api-client";

@Pipe({
  name: "customCampaignNameHighlightPipe",
  standalone: true,
})
export class CustomCampaignNameHighlightPipe implements PipeTransform {
  transform(campaignNamePattern: any): any {
    if (!campaignNamePattern) return campaignNamePattern;
    return campaignNamePattern.replace(/(%[^%]+%)/g, (value: CustomCampaignNameTokens) =>
      Object.values(CustomCampaignNameTokens).includes(value) ? `<span class="text-main">${value}</span>` : value,
    );
  }
}
