// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    AccountType,
    DashboardType,
    Marketplace,
} from './';

/**
 * @export
 * @interface DashboardConfig
 */
export interface DashboardConfig {
    /**
     * @type {number}
     * @memberof DashboardConfig
     */
    dashboardId?: number;
    /**
     * @type {number}
     * @memberof DashboardConfig
     */
    organizationId?: number;
    /**
     * @type {string}
     * @memberof DashboardConfig
     */
    name?: string;
    /**
     * @type {DashboardType}
     * @memberof DashboardConfig
     */
    dashboardType?: DashboardType;
    /**
     * @type {AccountType}
     * @memberof DashboardConfig
     */
    accountType?: AccountType;
    /**
     * @type {string}
     * @memberof DashboardConfig
     */
    accountId?: string;
    /**
     * @type {Marketplace}
     * @memberof DashboardConfig
     */
    marketplace?: Marketplace;
    /**
     * @type {number}
     * @memberof DashboardConfig
     */
    configVersion?: number;
    /**
     * @type {string}
     * @memberof DashboardConfig
     */
    config?: string;
}
