import { AgGridModule } from "@ag-grid-community/angular";
import { ColDef, GridOptions, HeaderValueGetterParams, ICellRendererParams } from "@ag-grid-community/core";
import { CommonModule } from "@angular/common";
import { Component, computed, DestroyRef, inject, input, signal } from "@angular/core";
import { takeUntilDestroyed, toObservable, toSignal } from "@angular/core/rxjs-interop";
import { CampaignType } from "@front/m19-api-client";
import {
  ACOS,
  AD_CONVERSIONS,
  AD_SALES,
  CLICKS,
  CONVERSION_RATE,
  COST,
  CPC,
  IMPRESSIONS,
  Metric,
  MetricCategory,
  ROAS,
  TOTAL_SALES,
} from "@front/m19-metrics";
import { AdStatsEx } from "@front/m19-models";
import {
  AccountSelectionService,
  AdsStatsWithPreviousPeriod,
  AdStatsWithTargetHistory,
  AuthService,
  convertStrategyConfigToCurrency,
  DataSet,
  groupBy,
  StatsService,
  StrategyTargetHistory,
  TacosStrategiesService,
  TacosStrategy,
  UserSelectionService,
} from "@front/m19-services";
import { IToggleComponent } from "@front/m19-ui";
import { addAdStats, AggregationFunction, DateAggregation, Utils } from "@front/m19-utils";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { ActivityService } from "@m19-board/activities/activity.service";
import { ActivityEventFiltersComponent } from "@m19-board/activity-event-filters.component";
import { getMetricsColDef } from "@m19-board/grid-config/grid-config";
import { MetricSelectorComponent } from "@m19-board/insights/metric-selector/metric-selector.component";
import { TACOS, TARGET_TACOS, TOTAL_ORDERS } from "@m19-board/models/MetricsDef";
import { CampaignTypeBadgeComponent } from "@m19-board/shared/campaign-type-badge/campaign-type-badge.component";
import { Moment } from "moment-timezone";
import { BaseChartDirective } from "ng2-charts";
import { combineLatest, map, of, switchMap } from "rxjs";

@Component({
  selector: "tacos-stats",
  standalone: true,
  imports: [
    CommonModule,
    MetricSelectorComponent,
    BaseChartDirective,
    AgGridModule,
    ActivityEventFiltersComponent,
    IToggleComponent,
    TranslocoDirective,
  ],
  templateUrl: "./tacos-stats.component.html",
})
export class TacosStatsComponent {
  private readonly accountService = inject(AccountSelectionService);
  private readonly tacosStrategiesService = inject(TacosStrategiesService);
  private readonly translocoService = inject(TranslocoService);
  private readonly userSelectionService = inject(UserSelectionService);
  private readonly authService = inject(AuthService);
  private readonly statsService = inject(StatsService);
  private readonly activityService = inject(ActivityService);
  private destroyRef = inject(DestroyRef);

  readonly localStorageKey = "tacos-strategy-page";
  readonly METRICS = [
    TOTAL_SALES,
    TOTAL_ORDERS,
    TACOS,
    AD_SALES,
    AD_CONVERSIONS,
    COST,
    ACOS,
    CLICKS,
    IMPRESSIONS,
    CPC,
    CONVERSION_RATE,
    ROAS,
  ];

  tacosStrategy = input.required<TacosStrategy>();

  private currency = toSignal(this.userSelectionService.selectedCurrency$);
  private locale = toSignal(this.authService.loggedUser$.pipe(map((user) => user.locale)));
  allUsers = toSignal(this.activityService.allUsersOptions$);
  strategyStats = toSignal<AdsStatsWithPreviousPeriod | undefined>(
    combineLatest([
      this.accountService.singleAccountMarketplaceSelection$,
      this.userSelectionService.dateRange$,
      this.userSelectionService.periodComparison$,
      toObservable(this.tacosStrategy),
    ]).pipe(
      takeUntilDestroyed(this.destroyRef),
      switchMap(([am, dr, periodComparison, tacosStrategy]) =>
        this.tacosStrategiesService.getStats(
          am,
          dr[0],
          dr[1],
          periodComparison?.period,
          tacosStrategy?.tacosStrategyGroupId,
        ),
      ),
    ),
  );

  displayEventAnnotation = signal(false);
  allEventAnnotationTypes = this.activityService.allActivityEventTypesOptions;

  strategyEventAnnotation = toSignal(
    combineLatest([toObservable(this.tacosStrategy), toObservable(this.displayEventAnnotation)]).pipe(
      switchMap(([tacosStrategy, d]) =>
        d && tacosStrategy
          ? this.activityService.getStrategiesActivityEventAnnotation(
              tacosStrategy.accountId!,
              tacosStrategy.marketplace!,
              [],
              undefined,
              [tacosStrategy],
            )
          : of([]),
      ),
      takeUntilDestroyed(this.destroyRef),
    ),
  );

  readonly totalStats = computed<AdStatsEx>(() => {
    if (!this.strategyStats()) return {};
    return this.strategyStats()!.data.reduce((acc, curr) => {
      addAdStats(acc, curr);
      return acc;
    }, {});
  });

  readonly previousTotalStats = computed<AdStatsEx>(() => {
    if (!this.strategyStats()) return {};
    return this.strategyStats()!.previousPeriodData.reduce((acc, curr) => {
      addAdStats(acc, curr);
      return acc;
    }, {});
  });

  rowStats = computed(() => {
    if (!this.strategyStats()) return [];
    return Array.from(this.getStatsByCampaignType(this.strategyStats()!.data).values());
  });

  previousPeriodRowStats = computed<Map<CampaignType | undefined, AdStatsEx>>(() => {
    if (!this.strategyStats()) return new Map();
    return this.getStatsByCampaignType(this.strategyStats()!.previousPeriodData);
  });

  periodComparison = toSignal(this.userSelectionService.periodComparison$, { requireSync: true });
  selectedMetrics = signal<Metric<AdStatsWithTargetHistory>[]>([TOTAL_SALES, AD_SALES]);
  selectedDateRange = toSignal<Moment[]>(this.userSelectionService.selectedDateRange$, { requireSync: true });

  private strategyTargetHistory = toSignal<StrategyTargetHistory[] | undefined>(
    combineLatest([
      this.accountService.singleAccountMarketplaceSelection$,
      this.userSelectionService.dateRange$,
      toObservable(this.tacosStrategy),
    ]).pipe(
      switchMap(([am, dr, _t]) =>
        this.statsService.getStrategyConfigHistory(am.accountId, am.marketplace, dr[0], dr[1]),
      ),
      convertStrategyConfigToCurrency(this.userSelectionService.selectedCurrency$),
      map((targetHistory) =>
        targetHistory.filter((t) => t.strategyId == this.tacosStrategy()!.spStrategy!.strategyId!),
      ),
      takeUntilDestroyed(this.destroyRef),
    ),
  );

  private minDate = computed(() => Utils.formatMomentDate(this.selectedDateRange()![0]));
  private maxDate = computed(() => Utils.formatMomentDate(this.selectedDateRange()![1]));

  private readonly dataset = new DataSet(
    3,
    this.selectedMetrics(),
    [AggregationFunction.mergeAdStatsWithTargetHistory],
    this.translocoService,
  );

  builtDataSet = computed(() => {
    if (!this.currency()) return undefined;
    this.dataset.currency = this.currency()!;

    this.dataset.buildDataSet(
      [...(this.strategyStats()?.data ?? []), ...(this.strategyTargetHistory() ?? [])],
      this.selectedMetrics(),
      DateAggregation.daily,
      {
        minDate: this.minDate(),
        maxDate: this.maxDate(),
      },
      this.periodComparison()?.period
        ? { data: this.strategyStats()?.previousPeriodData ?? [], period: this.periodComparison()?.period ?? [] }
        : undefined,
      this.strategyEventAnnotation(),
    );
    return this.dataset;
  });

  gridOptions = signal<GridOptions>({
    columnDefs: [
      {
        headerName: this.translocoService.translate("tacos-strategy.ad_type", {}, "en"),
        field: "campaignType",
        cellRenderer: CampaignTypeBadgeComponent,
        cellRendererParams: (params: ICellRendererParams<AdStatsEx>) => ({
          campaignType: params.value,
        }),
      },
      ...getMetricsColDef<AdStatsEx, AdStatsEx>(
        this.METRICS.filter((m) => m.category !== MetricCategory.SALES_STATS),
      ).map(({ headerName, ...def }) => ({
        ...def,
        headerName: this.translocoService.translate(`metrics.${def.groupId}_title`, {}, "en"),
        headerValueGetter: (params: HeaderValueGetterParams) =>
          this.translocoService.translate(`metrics.${params.column?.getColId()}_title`),
        cellRendererParams: (params: ICellRendererParams<AdStatsEx>) => {
          return {
            ...(def as ColDef<AdStatsEx>).cellRendererParams(params),
            currency: this.currency(),
            locale: this.locale(),
            previousData:
              this.periodComparison()?.period && params.data
                ? this.previousPeriodRowStats().get(params.data.campaignType)
                : undefined,
          };
        },
      })),
    ],
  });

  constructor() {
    this.dataset.metricsOnSameScale = [
      [AD_SALES, COST, TOTAL_SALES],
      [TACOS, TARGET_TACOS],
    ];
  }

  private getStatsByCampaignType(allStats: AdStatsEx[]): Map<CampaignType | undefined, AdStatsEx> {
    const grouped: Map<CampaignType | undefined, AdStatsEx> = groupBy(allStats, (s: AdStatsEx) => s.campaignType);
    grouped.delete(undefined);
    return grouped;
  }

  public selectMetric(metrics: Metric<StrategyTargetHistory>[]) {
    if (metrics.includes(TACOS)) {
      metrics = metrics.includes(TARGET_TACOS) ? metrics : [...metrics, TARGET_TACOS];
    } else {
      metrics = metrics.filter((m) => m !== TARGET_TACOS);
    }
    this.selectedMetrics.set([...metrics]);
  }
}
