@if ((loading$ | async) === false) {
  <div style="height: 88vh" class="flex flex-col" *transloco="let t">
    @if (addProductMessage$ | async; as m) {
      <div class="alert mb-2 w-full" [ngClass]="'alert-' + m.type">
        {{ m.message }}
      </div>
    }
    <div class="mb-2 flex items-center">
      <IButton
        variant="ghost"
        size="sm"
        icon="icon-[lucide--arrow-left]"
        [label]="t('v2-sidebar.keyword_tracker')"
        [tooltipValue]="t('v2-sidebar.keyword_tracker')"
        to=".."
      />
      /
      <IButton
        variant="ghost"
        size="sm"
        icon="icon-[lucide--chevron-down]"
        [trailing]="true"
        [label]="(searchTerm$ | async)?.searchTerm"
        [matMenuTriggerFor]="menu"
      />
      <mat-menu #menu="matMenu">
        <div class="dd-input-container flex items-center" (click)="$event.stopPropagation()">
          <input
            type="text"
            class="simple-input form-control"
            placeholder="{{ t('common.search') }}"
            [value]="keywordFilter"
            (input)="keywordFilter = getValueFromInputEvent($event)"
            (click)="$event.stopPropagation()"
            #search
          />
        </div>
        <ul class="product-list flex flex-col">
          @for (k of keywordTrackerData; track k) {
            @if (keywordPassTextFilter(k.searchTerm)) {
              <li class="dd-item" (click)="navigateToSearchTerm(k)">
                {{ k.searchTerm }}
              </li>
            }
          }
        </ul>
        <ul class="product-list flex flex-col"></ul>
      </mat-menu>
      <div class="alert alert-info ml-5 flex-1">
        {{ t("keyword-tracker-details.display_only_first_search") }}
      </div>
    </div>
    @if ((searchTerm$ | async) !== undefined) {
      <div class="shrink-0 grow">
        <app-keyword-tracking-timeline />
      </div>
    }
  </div>
}

@if ((loading$ | async) === true) {
  <div class="flex h-full w-full items-center justify-center">
    <app-spinner [display]="true"></app-spinner>
  </div>
}
