import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { SimpleDataset } from "../../../models/SimpleDataset";
import { BaseChartDirective } from "ng2-charts";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Point } from "chart.js";
import { Observable } from "rxjs";
import { TrackedSearchTerm } from "../product-tracker.component";
import { getRankFromOption, RankOption } from "@front/m19-services";
import { AccountMarketplace } from "@front/m19-api-client";
import { DateAggregation } from "@front/m19-utils";

@UntilDestroy()
@Component({
  selector: "app-product-tracker-keyword-chart",
  templateUrl: "./product-tracker-keyword-chart.component.html",
  styleUrls: ["./product-tracker-keyword-chart.component.scss"],
})
export class ProductTrackerKeywordChartComponent implements OnInit {
  readonly dateAggregations = [DateAggregation.hourly, DateAggregation.daily, DateAggregation.weekly];

  @Input() accountMarketplace: AccountMarketplace;

  @Input() set searchTermRanks(ranks: TrackedSearchTerm[]) {
    this._ranks = ranks;
    this.buildGraph(this._ranks, this._searchTermsOnGraph, this._rankOption, this._dateAggregation);
  }

  @Input() set searchTermsOnGraph(st: string[]) {
    this._searchTermsOnGraph = st;
    this.buildGraph(this._ranks, this._searchTermsOnGraph, this._rankOption, this._dateAggregation);
  }

  @Input() set rankOption(o: RankOption) {
    this._rankOption = o;
    this.buildGraph(this._ranks, this._searchTermsOnGraph, this._rankOption, this._dateAggregation);
  }

  @Input() set hoveredSearchTerm(st: string) {
    this._hoveredSearchTerm = st;
    if (this._hoveredSearchTerm) this.dataset.highlightDataset(this._hoveredSearchTerm, this.chart);
    else this.dataset.resetDatasetColors(this.chart);
  }

  @Input() loading$: Observable<boolean>;

  _rankOption: RankOption;
  _ranks: TrackedSearchTerm[];
  _searchTermsOnGraph: string[];
  _hoveredSearchTerm: string;
  _dateAggregation = DateAggregation.daily;

  // graph data
  dataset = new SimpleDataset(false);
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  ngOnInit(): void {
    this.dataset.marketplace = this.accountMarketplace.marketplace;
  }

  private buildGraph(ranks: TrackedSearchTerm[], labels: string[], rankOption: RankOption, dateAgg: DateAggregation) {
    if (labels === undefined) labels = [];
    this.dataset.marketplace = this.accountMarketplace?.marketplace;
    if (rankOption == RankOption.BOTH) {
      const targetSearchTerm = ranks.find((r) => r.searchTerm === labels[0]);
      this.buildBothRankOptionGraph(targetSearchTerm, dateAgg);
    } else {
      const points: Point[][] = [];
      for (const searchTerm of labels) {
        points.push(
          ranks
            .find((s) => s.searchTerm == searchTerm)
            ?.timeline.map((r) => ({
              x: r.timestamp * 1000,
              y: getRankFromOption(r, rankOption),
            })),
        );
      }
      this.dataset.buildSearchTermRankingDataSet("Search term ranks", points, labels, rankOption, dateAgg);
    }

    if (this.chart) {
      if (RankOption.BOTH === rankOption) this.chart.legend = true;
      this.chart.options.responsive = true;
      this.chart.options.maintainAspectRatio = false;
      this.chart.update();
    }
  }

  private buildBothRankOptionGraph(rank: TrackedSearchTerm, dateAgg: DateAggregation) {
    const ranks: Point[][] = [];
    if (!rank) {
      return;
    }
    ranks.push(
      rank.timeline.map((r) => ({
        x: r.timestamp * 1000,
        y: r.organic,
      })),
    );
    ranks.push(
      rank.timeline.map((r) => ({
        x: r.timestamp * 1000,
        y: r.sp,
      })),
    );
    this.dataset.buildSearchTermRankingDataSet(
      "Search terms ranks",
      ranks,
      ["Organic", "Sponsored"],
      RankOption.BOTH,
      dateAgg,
    );
  }

  selectDateAggregation(dateAgg: DateAggregation) {
    this._dateAggregation = dateAgg;
    this.buildGraph(this._ranks, this._searchTermsOnGraph, this._rankOption, this._dateAggregation);
  }
}
