export class UtilsFractile {
  public static getFractileArray(data: number[], count: number): number[] {
    data = data.sort((a, b) => a - b);
    const partSize = 1 / count;
    const res = [];
    let q = partSize;

    for (let i = 0; i < count - 1; i++) {
      const p = (data.length - 1) * q;
      const b = Math.floor(p);
      if (data[b] !== -Infinity) res.push(data[b]);
      q += partSize;
    }
    return res;
  }

  public static getFractileBucket(buckets: number[], value: number): number | undefined {
    if (value === undefined) {
      return undefined;
    }
    if (buckets) {
      let i = 0;
      while (i < buckets.length - 1) {
        if (value <= buckets[i]) break;

        i++;
      }
      return i;
    }
    return undefined;
  }
}
