// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    AdSpendFee,
    Currency,
    FlatFee,
    LegacyFee,
    PerAccountFee,
    Plan,
} from './';

/**
 * @export
 * @interface BillingPlan
 */
export interface BillingPlan {
    /**
     * @type {number}
     * @memberof BillingPlan
     */
    billingPlanId?: number;
    /**
     * @type {Plan}
     * @memberof BillingPlan
     */
    plan?: Plan;
    /**
     * @type {boolean}
     * @memberof BillingPlan
     */
    isCustom?: boolean;
    /**
     * @type {Currency}
     * @memberof BillingPlan
     */
    currency?: Currency;
    /**
     * @type {string}
     * @memberof BillingPlan
     */
    frequency?: BillingPlanFrequencyEnum;
    /**
     * @type {number}
     * @memberof BillingPlan
     */
    accountLimit?: number;
    /**
     * @type {number}
     * @memberof BillingPlan
     */
    marketplaceLimitPerAccount?: number;
    /**
     * @type {number}
     * @memberof BillingPlan
     */
    strategyLimit?: number;
    /**
     * @type {number}
     * @memberof BillingPlan
     */
    userLimit?: number;
    /**
     * @type {number}
     * @memberof BillingPlan
     */
    hourlyDataSupport?: number;
    /**
     * @type {number}
     * @memberof BillingPlan
     */
    kwTrackingDailyLimit?: number;
    /**
     * @type {number}
     * @memberof BillingPlan
     */
    kwTrackingHourlyLimit?: number;
    /**
     * @type {number}
     * @memberof BillingPlan
     */
    freeAccounts?: number;
    /**
     * @type {FlatFee}
     * @memberof BillingPlan
     */
    flatFee?: FlatFee;
    /**
     * @type {PerAccountFee}
     * @memberof BillingPlan
     */
    perAccountFee?: PerAccountFee;
    /**
     * @type {AdSpendFee}
     * @memberof BillingPlan
     */
    adSpendFee?: AdSpendFee;
    /**
     * @type {LegacyFee}
     * @memberof BillingPlan
     */
    legacyFee?: LegacyFee;
}

/**
 * @export
 * @enum {string}
 */
export enum BillingPlanFrequencyEnum {
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
    UNKNOWN = 'UNKNOWN'
}

