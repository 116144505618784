import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ICON_GEAR, ICON_LOGOUT, ICON_USER } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AccountSelectionService,
  AuthService,
  CurrencyService,
  NotificationService,
  OrganizationAccountGroupService,
  UserSelectionService,
} from "@front/m19-services";
import { NavData } from "@m19-board/_nav";
import { BoardType, LayoutSelectorService } from "@m19-board/layout-selector.service";
import { Hotkeys } from "@m19-board/services/hotkeys.service";
import { AccountSettingsService } from "@m19-board/settings/account-settings.service";
import { environment } from "../../../environments/environment";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { Observable } from "rxjs";
import { DebugInfoModalComponent } from "../default-layout/debug-info-modal.component";
import { AccountMarketplace, User } from "@front/m19-api-client";
import { OrganizationAccountGroups } from "@front/m19-models";

@UntilDestroy()
@Component({
  templateUrl: "./dashboard360-layout.component.html",
})
export class Dashboard360LayoutComponent implements OnInit {
  isTest = !environment.production;

  readonly ICON_SETTINGS = ICON_GEAR;
  readonly ICON_USER = ICON_USER;
  readonly ICON_LOGOUT = ICON_LOGOUT;

  navbarBrandFull = { src: "assets/img/logo_header.png", height: 40, alt: "m19 Logo" };
  readonly tabs: NavData[] = [
    { url: "dashboard360/account360", name: "Accounts 360", vendorSupport: true },
    { url: "dashboard360/product360", name: "Product 360", vendorSupport: true },
  ];

  // input/app state
  user: User;
  locale: string;
  boardType: BoardType;
  globalWarning$: Observable<string>;
  accountMarketplaces: AccountMarketplace[];
  selectedDateRage: string[];

  // component state
  displaySettingsMenu = false;
  debugInfoModalVisible = false;

  constructor(
    private authService: AuthService,
    private accountGroupService: OrganizationAccountGroupService,
    private userSelectionService: UserSelectionService,
    public currencyService: CurrencyService,
    private router: Router,
    private layoutSelector: LayoutSelectorService,
    private notificationService: NotificationService,
    private accountSelectionService: AccountSelectionService,
    private accountSettingsService: AccountSettingsService,
    private modalService: BsModalService,
    private hotkeys: Hotkeys,
  ) {
    this.globalWarning$ = this.notificationService.globalWarning$;
    this.hotkeys
      .addShortcut({ altKey: "KeyD" })
      .pipe(untilDestroyed(this))
      .subscribe(() => this.printDebugInfo());
  }

  ngOnInit(): void {
    this.boardType = this.layoutSelector.getBoardType();
    this.navbarBrandFull = { src: this.layoutSelector.getLogo(), height: 40, alt: "logo" };
    this.accountSelectionService.accountMarketplacesSelection$
      .pipe(untilDestroyed(this))
      .subscribe((accountMarketplaces) => {
        this.accountMarketplaces = accountMarketplaces;
      });

    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user;
      this.locale = user.locale;
    });
    this.userSelectionService.dateRange$.pipe(untilDestroyed(this)).subscribe((dr) => {
      this.selectedDateRage = dr;
    });
  }

  selectOrganization(organizations: OrganizationAccountGroups[]) {
    this.userSelectionService.setSelectedOrganizations(organizations);
  }

  isAdminLogin(): boolean {
    return this.authService.isAdminLogin();
  }

  printDebugInfo() {
    // prevent modal to open again if already shown
    if (this.debugInfoModalVisible) {
      return;
    }
    this.debugInfoModalVisible = true;
    const modalOptions: ModalOptions = {
      initialState: {
        user: this.user,
        accountMarketplaces: this.accountMarketplaces,
        dateRange: this.selectedDateRage,
      },
      class: "modal-lg",
    };
    const modalRef = this.modalService.show(DebugInfoModalComponent, modalOptions);
    modalRef.onHidden.subscribe(() => {
      this.debugInfoModalVisible = false;
    });
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(["login"]);
  }

  toggleSettings(): void {
    this.displaySettingsMenu = !this.displaySettingsMenu;
  }
}
