<ng-container *transloco="let t">
  @if (init()) {
    @if (user()) {
      <h3 class="mb-8 text-3xl font-bold text-gray-800 dark:text-gray-100">{{ t("common.reset_password") }}</h3>
      @if (error()) {
        <IAlert class="mb-6 block" type="error" [title]="error()!" />
      }
      <lib-reset-password-form [isLoading]="loading()" (formSubmit)="requestPasswordReset($event)" />
    } @else if (user() === null) {
      <h3 class="mb-8 text-3xl font-bold text-gray-800 dark:text-gray-100">
        {{ t("password-reset.link_expired") }}
      </h3>
      <p class="mb-6 text-gray-600 dark:text-gray-400">
        {{ t("password-reset.link_expired_description") }}
      </p>
      <IButton
        class="w-full text-white"
        type="button"
        size="lg"
        [label]="t('password-reset.back_to_login')"
        to="/login"
      />
    }
  } @else {
    <span class="text-red-500"> {{ t("password-reset.link_expired") }}</span>
  }
</ng-container>
