import { Component, DestroyRef, inject, OnInit, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import { OnboardingCallStatus } from "@front/m19-api-client";
import { OrganizationAccountGroups } from "@front/m19-models";
import { OrganizationAccountGroupService, OrganizationService } from "@front/m19-services";
import { IButtonComponent } from "@front/m19-ui";
import { TranslocoDirective } from "@jsverse/transloco";
import { OnboardingService, OnBoardingStepRedirects } from "@m19-board/services/onboarding.service";
import { ToastrService } from "ngx-toastr";
import { combineLatest, filter, take } from "rxjs";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-conboard-call-page",
  template: `<div class="flex flex-col items-center gap-3" *transloco="let t">
    <div>
      @if (onboardingCallStatus !== OnboardingCallStatus.SCHEDULED) {
        <span class="text-xs text-gray-400">3/3</span>
        <div class="mb-2 text-xl font-semibold text-gray-700">
          {{ t("onboarding-call.your_free_onboarding_call") }}
        </div>
        <p>{{ t("onboarding-call.description") }}</p>
      } @else {
        <div class="mb-2 text-xl font-semibold text-gray-700">
          {{ t("onboarding-call.rechedule_your_call") }}
        </div>
      }
    </div>
    <div class="meetings-iframe-container min-h-[700px] w-full" [attr.data-src]="onboardingMeetingUrl"></div>
    @if (meetingBooked()) {
      <IButton [label]="t('common.continue')" (onClick)="continue()"></IButton>
    } @else {
      @if (onboardingCallStatus !== OnboardingCallStatus.SCHEDULED) {
        <a class="text-gray-400 hover:text-gray-500" (click)="skipOnboarding()">{{ t("onboarding-call.skip") }}</a>
      } @else {
        <a class="text-gray-400 hover:text-gray-500" (click)="continue()">{{ t("common.back_to_home") }}</a>
      }
    }
  </div>`,
  standalone: true,
  imports: [TranslocoDirective, IButtonComponent],
})
export class OnboardingCallPageComponent implements OnInit {
  private readonly onboardingService = inject(OnboardingService);
  private readonly router = inject(Router);
  private readonly organizationAccountGroupService = inject(OrganizationAccountGroupService);
  private readonly organizationService = inject(OrganizationService);
  private readonly toastrService = inject(ToastrService);
  private readonly destroyRef = inject(DestroyRef);
  readonly meetingBooked = signal(false);
  readonly onboardingMeetingUrl = environment.onboardingMeetingUrl;
  private organizationId: number | undefined;
  onboardingCallStatus: OnboardingCallStatus | undefined;
  readonly OnboardingCallStatus = OnboardingCallStatus;

  constructor() {
    // check if user is entitled to onboarding call
    combineLatest([
      this.organizationAccountGroupService.allOrganizationAccountGroups$.pipe(
        filter((o): o is OrganizationAccountGroups[] => !!o),
      ),
      this.onboardingService.belowFreeTestAdSpendThreshold$,
    ])
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(([organizationAccountGroups, belowFreeTestAdSpendThreshold]) => {
        if (organizationAccountGroups.length !== 1) {
          // free test activation is not possible in this case
          this.router.navigate(["/dashboard"]);
        }
        if (!this.onboardingService.entitledForFreeTestSubscription(organizationAccountGroups[0])) {
          // free test activation is not possible in this case
          this.router.navigate(["/dashboard"]);
        }
        if (belowFreeTestAdSpendThreshold) {
          // onboarding call is not possible in this case
          this.router.navigate(["/dashboard"]);
        }
        this.organizationId = organizationAccountGroups[0].organization.organizationId;
        this.onboardingCallStatus = organizationAccountGroups[0].organization.onboardingCallStatus;
      });
  }

  ngOnInit(): void {
    const myScriptElement = document.createElement("script");
    myScriptElement.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    document.body.appendChild(myScriptElement);

    // Listen for the message event to see successful scheduling
    window.addEventListener("message", this.meetingBookHandler);
  }

  private readonly meetingBookHandler = (event: MessageEvent) => {
    // Check if the message is from the scheduling widget
    if (event.origin === "https://meetings.hubspot.com") {
      // Get the message data
      const messageData = event.data;
      if (messageData.meetingBookSucceeded) {
        this.meetingBooked.set(true);
        window.removeEventListener("message", this.meetingBookHandler);
        this.organizationService
          .updateOnboardingCallStatus(this.organizationId!, OnboardingCallStatus.SCHEDULED)
          .subscribe({
            next: () => {
              this.toastrService.success("Onboarding call scheduled successfully");
            },
            error: (err) => {
              this.toastrService.error(err, "Error when scheduling your onboarding call");
            },
          });
      }
    }
  };

  continue() {
    window.removeEventListener("message", this.meetingBookHandler);
    this.onboardingService.currentStep$.pipe(take(1)).subscribe((step) => {
      this.router.navigate([OnBoardingStepRedirects[step]]);
    });
  }

  skipOnboarding() {
    this.onboardingService.skipOnboarding();
    this.continue();
  }
}
