import { Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { debounceTime } from 'rxjs';
import { IButtonComponent } from '../../components/ibutton/ibutton.component';
import { IInputComponent } from '../../components/iinput/iinput.component';
import { CommonModule } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';
import { PASSWORDS_EQUAL_KEY, passwordsEqualValidator, RegisterForm } from '../register-form/register-form.component';

export interface ResetPasswordForm {
  password: FormControl<string | null>;
  confirmPassword: FormControl<string | null>;
}

export interface ResetPasswordFormValue {
  password: string;
  confirmPassword: string;
}

@Component({
  selector: 'lib-reset-password-form',
  standalone: true,
  imports: [ReactiveFormsModule, IButtonComponent, IInputComponent, CommonModule, TranslocoDirective],
  templateUrl: './reset-password-form.component.html',
})
export class ResetPasswordFormComponent implements OnInit {
  readonly form = new FormGroup<ResetPasswordForm>(
    {
      password: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(8)],
      }),
      confirmPassword: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required],
      }),
    },
    {
      validators: [passwordsEqualValidator<RegisterForm>('password', 'confirmPassword')],
    },
  );

  formErrors = signal<{ [key: string]: string | null }>({});

  @Input() isLoading = false;
  @Output() formSubmit = new EventEmitter<ResetPasswordFormValue>();

  ngOnInit(): void {
    this.form.valueChanges.pipe(debounceTime(200)).subscribe(() => {
      if (this.form.invalid) {
        this.formErrors.set({
          password: this.form.controls.password.errors?.['minlength'] ? 'Password must be at least 8 characters' : null,
          confirmPassword: this.form.errors?.[PASSWORDS_EQUAL_KEY] ? 'Passwords do not match' : null,
        });
      }
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    this.formSubmit.emit(this.form.value as ResetPasswordFormValue);
  }

  confirmPasswordValidator(form: FormGroup): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value !== form.controls['password']?.value) {
        return { confirmPassword: true };
      }
      return null;
    };
  }
}
