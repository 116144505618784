<ng-container *transloco="let t">
  <div [class.btn-group]="selectedMedia || newFile" class="flex w-full min-w-0">
    <IButton
      class="w-full min-w-0"
      [testid]="testId"
      color="white"
      (onClick)="openMediaSelectionModal()"
      icon="icon-[mdi--file-image-outline]"
      [label]="newFile ? newFile.name : _savedMedia ? _savedMedia.name : t('media-input.select_a_file')"
      [block]="true"
    />
    @if (_savedMedia || newFile) {
      <IButton
        color="white"
        class="ml-2 flex-none"
        tooltipValue="{{ t('media-input.preview_file') }}"
        tooltipPosition="above"
        (onClick)="openMediaToPreview(newFile ? undefined : selectedMedia)"
        icon="icon-[mdi--eye-outline]"
      />
    }
    @if (removable) {
      <IButton
        color="white"
        class="ml-2 flex-none"
        tooltipValue="{{ t('media-input.remove_file') }}"
        tooltipPosition="above"
        (onClick)="removeFile()"
        icon="icon-[mdi--trash-can-outline]"
        [square]="true"
      />
    }
  </div>

  <ng-template #mediaSelectionModal>
    <div class="modal-body">
      <h4 class="m-0 border-b p-3 text-2xl font-medium">{{ t("media-input.select_a_file") }}</h4>
      <ul class="nav-wrapper nav-stretch m-0">
        <li class="nav-element" [class.active]="selectedTab === MediaInputTab.MEDIA">
          <a class="nav-link" (click)="selectedTab = MediaInputTab.MEDIA">{{ t("media-input.medias") }}</a>
        </li>
        <li class="nav-element" [class.active]="selectedTab === MediaInputTab.UPLOAD_NEW">
          <a class="nav-link" (click)="selectedTab = MediaInputTab.UPLOAD_NEW">
            {{ t("media-input.upload_new_file") }}</a
          >
        </li>
      </ul>

      <div class="flex h-[40vh] flex-col">
        @if (selectedTab === MediaInputTab.MEDIA) {
          @if (!_medias?.length) {
            <div class="h-full w-full">
              <h5 class="m-0 text-center">{{ t("media-input.no_media_found") }}</h5>
            </div>
          }
          <div class="p-2">
            <IInput placeholder="Search by file name" [control]="mediaNameControl" icon="icon-[mdi--search]" />
          </div>
          <div class="grid flex-auto grid-cols-2 gap-2 overflow-y-auto p-2" data-testid="media-cards">
            @for (m of filteredMedias; track m) {
              <div
                class="media-item checkbox-card group relative"
                [class.checkbox-card-selected]="selectedMedia?.assetId === m.assetId"
                (click)="selectedMedia = m"
              >
                <ng-container *ngTemplateOutlet="mediaCard; context: { media: m }"></ng-container>
              </div>
            }
          </div>
        }
        @if (selectedTab === MediaInputTab.UPLOAD_NEW) {
          <div class="flex flex-1 flex-col items-center justify-center p-3">
            @if (mediaFile && media) {
              <div class="media-item new-media-item group relative mb-3">
                <ng-container *ngTemplateOutlet="newFileCard; context: { file: mediaFile }"></ng-container>
              </div>
            }
            @if (invalidReason) {
              <p class="text-danger mb-2">
                {{ getInvalidImageMessage() }}
              </p>
            }
            <input
              #fileInput
              hidden
              type="file"
              [accept]="isVideoType() ? 'video/*' : 'image/gif, image/jpeg, image/png'"
              (input)="onNewMedia(fileInput.files)"
              (click)="fileInput.value = ''"
            />
            <IButton
              (onClick)="fileInput.click()"
              label="{{ t('media-input.choose_file') }}"
              color="white"
              [icon]="ICON_IMPORT"
            />
          </div>
        }
      </div>
    </div>
    <div class="modal-footer flex-nowrap">
      @if (selectedTab === MediaInputTab.MEDIA) {
        <button type="button" class="btn btn-ghost-secondary mr-2 w-full" (click)="modalRef.hide()">
          {{ t("common.cancel") }}
        </button>
        <button
          type="button"
          class="btn btn-primary w-full"
          (click)="saveMedia(selectedMedia)"
          [class.disabled]="!selectedMedia"
          data-testid="btn-media-save"
        >
          {{ t("common.save") }}
        </button>
      }
      @if (selectedTab === MediaInputTab.UPLOAD_NEW) {
        <button class="btn btn-ghost-secondary mr-2 w-full" (click)="modalRef.hide()">
          {{ t("common.cancel") }}
        </button>
        <button
          type="button"
          class="btn btn-primary w-full"
          (click)="saveNewFile(mediaFile)"
          [class.disabled]="!mediaFile || invalidReason !== ''"
        >
          {{ t("media-input.save_and_upload_new_file") }}
        </button>
      }
    </div>
  </ng-template>

  <ng-template #mediaCard let-m="media">
    @if (!isVideoType()) {
      <img [src]="m.url" />
    }
    @if (isVideoType()) {
      <div class="max-w-full break-words">{{ m.name }}</div>
    }
    @if (isVideoType()) {
      <IButton
        color="white"
        tooltipValue="{{ t('media-input.preview_video') }}"
        (onClick)="openMediaToPreview(m); $event.stopPropagation()"
        [icon]="ICON_EYE"
        class="absolute left-2 top-2 opacity-0 group-hover:opacity-100"
      />
    }
    <div [class.checked]="selectedMedia?.assetId === m.assetId" class="radio-btn shrink-0">
      <div></div>
    </div>
    @if (!isVideoType()) {
      <div class="media-filename truncate text-sm font-bold">{{ m.name }}</div>
    }
  </ng-template>

  <ng-template #newFileCard let-f="file">
    @if (isVideoType()) {
      <IButton
        color="white"
        [square]="true"
        tooltipValue="{{ t('media-input.preview_video') }}"
        (onClick)="openMediaToPreview(undefined); $event.stopPropagation()"
        [icon]="ICON_EYE"
        class="absolute left-2 top-2 opacity-0 group-hover:opacity-100"
      />
    }
    @if (!isVideoType()) {
      <img [src]="media.src" alt="Uploaded Media" />
      <div class="media-filename truncate text-sm font-bold">{{ f.name }}</div>
    }
    @if (isVideoType()) {
      <div>{{ f.name }}</div>
    }
  </ng-template>

  <ng-template #mediaPreviewModal>
    <div class="modal-header bg-primary">
      <h4 class="modal-title pull-left">{{ mediaToPreview ? mediaToPreview.name : mediaFile.name }}</h4>
    </div>

    <div class="modal-body">
      <div class="media-item preview-media group">
        @if (!isVideoType()) {
          <img [src]="mediaToPreview ? mediaToPreview.url : media.src" />
        }
        @if (isVideoType()) {
          <video controls autoplay [muted]="true" loop [src]="mediaToPreview ? mediaToPreview.url : media.src"></video>
        }
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-ghost-secondary" (click)="previewModalRef.hide()">
        {{ t("common.close") }}
      </button>
    </div>
  </ng-template>
</ng-container>
