import { Component, Input, OnInit } from "@angular/core";
import { faPauseCircle, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CampaignType } from "@front/m19-api-client";

import { StrategyEx } from "@front/m19-models";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { PALETTE } from "@m19-board/models/Metric";
import { StrategyCache } from "libs/m19-services/src/lib/m19-services/strategy.cache";
import { AuthService } from "@front/m19-services";

@UntilDestroy()
@Component({
  selector: "app-strategy-link",
  templateUrl: "./strategy-link.component.html",
})
export class StrategyLinkComponent implements ICellRendererAngularComp, OnInit {
  @Input() strategyId: number;
  @Input() placeholder?: string;
  @Input() withCampaignType?: boolean;
  @Input() withCampaignState?: boolean;
  @Input() color: string;
  @Input() disableLink = false;
  readonly faPlayCircle = faPlayCircle;
  readonly faPauseCircle = faPauseCircle;
  uiVersion = 0;

  readonly CAMPAIGN_TYPE_NAME = {
    SP: "Sponsored Products",
    SB: "Sponsored Brands",
    SD: "Sponsored Display",
  };

  readonly CAMPAIGN_TYPE_COLOR = {
    SP: PALETTE[0],
    SB: PALETTE[1],
    SD: PALETTE[3],
  };

  strategy: StrategyEx;

  constructor(
    private strategyCache: StrategyCache,
    private authService: AuthService,
  ) {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.uiVersion = user?.uiVersion ?? 0;
    });
  }

  agInit(
    params: ICellRendererParams & {
      strategyId: number;
      withCampaignType: boolean;
      placeholder?: string;
      disableLink?: boolean;
    },
  ): void {
    this.strategyId = params.strategyId;
    this.withCampaignType = params.withCampaignType;
    this.placeholder = params.placeholder;
    this.disableLink = params.disableLink;
  }

  refresh(): boolean {
    return false;
  }

  ngOnInit(): void {
    this.strategyCache
      .getStrategyById(this.strategyId)
      .pipe(untilDestroyed(this))
      .subscribe((strategy: StrategyEx) => {
        this.strategy = strategy;
      });
  }

  redirectToStrategy(): string {
    if (this.strategy.strategyGroupId) {
      if (this.uiVersion === 0) {
        return `/strategies/strategy-group/sponsored-product/${this.strategy.strategyGroupId}`;
      }
      return `/advertising/sponsored-product/strategy-group/${this.strategy.strategyGroupId}`;
    }
    const prefix = this.uiVersion === 0 ? "/strategies" : "/advertising";
    switch (this.strategy.campaignType) {
      case CampaignType.SB:
        return `${prefix}/sponsored-brands/` + this.strategyId;
      case CampaignType.SP:
        return `${prefix}/sponsored-product/` + this.strategyId;
      case CampaignType.SD:
        return `${prefix}/sponsored-display/` + this.strategyId;
      default:
        return "#";
    }
  }
}
