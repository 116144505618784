import { Component, Input, signal } from "@angular/core";
import { AsinSeedTypeEnum, Marketplace, MatchType } from "@front/m19-api-client";
import { AsinService } from "@front/m19-services";
import { ISelectComponent, Option } from "@front/m19-ui";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { ProductViewComponent } from "@m19-board/product-view/product-view.component";
import { NgClass } from "@angular/common";
import { SwitchButtonComponent } from "@m19-board/shared/switch-button/switch-button.component";

export enum SeedType {
  Keyword = "Keyword",
  Product = "Product",
}

@UntilDestroy()
@Component({
  selector: "app-add-to-seeds-modal",
  templateUrl: "./add-to-seeds-modal.component.html",
  standalone: true,
  imports: [TranslocoDirective, ProductViewComponent, ISelectComponent, NgClass, SwitchButtonComponent],
})
export class AddToSeedsModalComponent {
  @Input()
  seedType: SeedType = SeedType.Keyword;
  @Input()
  asin!: string;
  @Input()
  targetingItemValue!: string;
  @Input()
  accountId!: string;
  @Input()
  marketplace!: Marketplace;

  matchType = MatchType.exact;

  seedTypeKeys = {
    [SeedType.Keyword]: "common.keyword",
    [SeedType.Product]: "common.product",
  };
  readonly SeedType = SeedType;
  readonly MatchType = MatchType;
  readonly AsinSeedTypeEnum = AsinSeedTypeEnum;

  seedsOptions: Option<AsinSeedTypeEnum>[] = [
    {
      label: "Seed",
      value: AsinSeedTypeEnum.WHITELIST,
    },
    {
      label: "Blacklist",
      value: AsinSeedTypeEnum.BLACKLIST,
    },
  ];
  asinSeedType = signal<Option<AsinSeedTypeEnum>>(this.seedsOptions[0]);

  constructor(
    private bsModalRef: BsModalRef,
    private asinService: AsinService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService,
  ) {}

  close(): void {
    this.bsModalRef.hide();
  }

  setMatchType(matchType: MatchType) {
    this.matchType = matchType;
  }

  setAsinSeedType(asinSeedType: Option<AsinSeedTypeEnum>) {
    this.asinSeedType.set(asinSeedType);
    if (this.seedType == SeedType.Keyword) {
      // reset match type to exact when changing from blacklist to whitelist
      this.setMatchType(MatchType.exact);
    }
  }

  save(): void {
    this.asinService
      .addSeedToAsin(this.accountId, this.marketplace, this.asin, {
        accountId: this.accountId,
        marketplace: this.marketplace,
        asin: this.asin,
        matchType: this.seedType == SeedType.Keyword ? this.matchType : MatchType.asinSameAs,
        targetingValue: this.targetingItemValue,
        type: this.asinSeedType().value,
      })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("add-to-seeds-modal.asin_this_asin_seeds_successfully_updated", [
              this.asin,
            ]),
            this.translocoService.translate("add-to-seeds-modal.product_seeds_added"),
          );
          this.close();
        },
        error: (err) => {
          this.toastrService.error(err, this.translocoService.translate("add-to-seeds-modal.cannot_add_product_seeds"));
          this.close();
        },
      });
  }
}
