import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, EventEmitter, input, Output } from '@angular/core';
import { Option, SelectPopperComponent } from '../popper/selectpopper.component';
import { TranslocoDirective } from '@jsverse/transloco';
@Component({
  selector: 'IMultiSelect',
  standalone: true,
  imports: [NgTemplateOutlet, NgClass, TranslocoDirective],
  templateUrl: './imultiselect.component.html',
})
export class IMultiSelectComponent extends SelectPopperComponent {
  @Output() selectedChange = new EventEmitter<Option[]>();

  selected = input<Option[]>([]);
  minSelectedOptions = input<number>(0);
  selectAllPlaceholder = input(this.translocoService.translate('imultiselect.select_all'));

  label = computed(() => {
    return this.selected()?.length === 0
      ? this.placeholder
      : this.withSelectAll() && this.isAllSelected()
        ? this.translocoService.translate('imultiselect.options_selected_all', { count: this.selected()?.length })
        : this.translocoService.translate('imultiselect.option_selected', { count: this.selected()?.length });
  });

  isAllSelected = computed(() => this.selected()?.length === this.options().length);
  hasSelectedOptions = computed(() => this.selected() && this.selected()!.length > 0);

  isSelected(option: Option) {
    return this.selected()?.some((o) => o[this._by()] === option[this._by()]);
  }

  selectAll() {
    let res: Option[] = [];
    if (this.isAllSelected()) {
      if (this.minSelectedOptions() > 0) {
        res = this.selected().slice(0, this.minSelectedOptions());
      } else {
        res = [];
      }
    } else {
      res = this.filteredOptions(); // Select only filtered options
    }
    this.selectedChange.emit(res);
  }

  selectOption(option: Option) {
    const isSelected = this.isSelected(option);
    let res: Option[] = [];
    if (isSelected) {
      if (this.minSelectedOptions() > 0 && this.selected().length <= this.minSelectedOptions()) return;
      res = this.selected().filter((o) => o[this._by()] !== option[this._by()]);
    } else {
      res = [...this.selected(), option];
    }

    this.selectedChange.emit(res);
  }

  override handleKeyDown(event: KeyboardEvent): void {
    super.handleKeyDown(event, this.filteredOptionsWithCategories());
    if (event.key === 'Enter') {
      event.preventDefault();
      if (this.withSelectAll() && this.activeIndex().j === -1 && this.activeIndex().i === -1) this.selectAll();
      else {
        const option = this.filteredOptionsWithCategories()[this.activeIndex().i].options[this.activeIndex().j];
        this.selectOption(option);
      }
    }
  }
}
