<app-notification-card [notification]="notification" [small]="small" helplink="">
  <span title *transloco="let t">
    @if (isCreativeIssue) {
      {{ t("common.strategy") }}
      <span class="break-all">
        <a href="{{ getStrategyLink(strategy) }}" target="_blank" class="link">{{ strategy?.name }}</a>
      </span>
      has {{ nb }} rejected Ad{{ length === 1 ? "" : "s" }}
    } @else {
      {{ t("notification-card-bidder-issue.issue_with_strategy") }}
      <span class="break-all">
        <a href="{{ getStrategyLink(strategy) }}" target="_blank" class="link">{{ strategy?.name }}</a>
      </span>
    }
  </span>
  <span action *transloco="let t">{{ t("notification-card-bidder-issue.reasons", [reasons]) }} </span>
  <span titleSmall *transloco="let t">
    @if (isCreativeIssue) {
      Strategy
      <span class="break-all">
        <a href="{{ getStrategyLink(strategy) }}" target="_blank" class="link">{{ strategy?.name }}</a>
      </span>
      {{ t("notification-card-bidder-issue.number_rejected", { count: nb }) }}
    } @else {
      {{ t("notification-card-bidder-issue.issue_with_strategy") }}
      <span class="break-all">
        <a href="{{ getStrategyLink(strategy) }}" target="_blank" class="link">{{ strategy?.name }}</a>
      </span>
    }
  </span>
</app-notification-card>
