import { Component, inject, signal } from "@angular/core";
import { AccountMarketplaceService, AmazonUserService } from "@front/m19-services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ToastrService } from "ngx-toastr";
import { AccountMarketplace, AmazonUser, DspAdvertiser } from "@front/m19-api-client";
import { Marketplaces } from "@front/m19-models";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";
import { IBadgeComponent, ICardComponent, MODAL_DATA, ModalRef } from "@front/m19-ui";

@Component({
  selector: "app-link-dsp-modal",
  template: `
    <div class="border-b" *transloco="let t">
      <div class="text-xl">
        {{ t("link-dsp.link_a_dsp_advertiser_to") }}
        <span class="font-bold"
          >{{ modalData.accountMarketplace.accountName }}
          {{ Marketplaces[modalData.accountMarketplace.marketplace].flag }}</span
        >
      </div>
    </div>
    <div class="modal-body" *transloco="let t">
      @if (loading()) {
        <div class="flex w-full items-center justify-center">
          <app-spinner [display]="true"></app-spinner>
        </div>
      } @else if (dspAdvertisers === undefined || dspAdvertisers.length === 0 || errorMessage) {
        @if (errorMessage || dspAdvertisers !== undefined) {
          <div class="p-2">
            <div class="border-l-4 border-orange-500 bg-orange-100 p-4 text-orange-700">
              {{ errorMessage ?? t("link-dsp.no_dsp_advertisers_found_retry_amazon_login") }}
            </div>
          </div>
        }
        <div class="p-2">
          <div class="full flex items-center justify-center gap-2">
            <div>{{ t("link-dsp.login_with_amazon_to_link_your_dsp_advertiser") }}</div>
            <span class="icon-[mdi--arrow-right-thick] text-main-500 float-right animate-pulse text-2xl"></span>
            <input
              class="amazon-login-button"
              type="image"
              (click)="logWithAmazon()"
              src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gry_156x132.png"
            />
          </div>
        </div>
      } @else if (dspAdvertisers.length === 1) {
        <div class="mb-4">{{ t("link-dsp.confirm_link") }}</div>
        <ICard class="!bg-main-50 ring-main-300 ring-2">
          <ng-template #body>
            <div class="mb-2 flex gap-3">
              <div class="text-main-500 font-semibold">{{ dspAdvertisers[0].name }}</div>
              <a [href]="dspAdvertisers[0].url" target="_blank" class="text-main-500">{{ dspAdvertisers[0].url }}</a>
            </div>
            <IBadge
              color="gray"
              size="xs"
              label="ID: {{ dspAdvertisers[0].dspAdvertiserId }}"
              tooltipValue="{{ t('link-dsp.this_is_the_dsp_advertiser_id') }}"
            ></IBadge>
          </ng-template>
        </ICard>
      } @else if (dspAdvertisers.length > 1) {
        <div>{{ t("link-dsp.select_the_dsp_advertiser_you_want_to_link") }}</div>
        @for (dspAdvertiser of dspAdvertisers; track dspAdvertiser.dspAdvertiserId) {
          <div (click)="selectDspAdvertiser(dspAdvertiser)">
            <ICard
              [class]="
                'group relative mt-4 cursor-pointer pl-4 duration-75 hover:bg-gray-100' +
                (dspAdvertiser === selectedDspAdvertiser ? '!bg-main-50 ring-main-300 ring-2' : '')
              "
            >
              <ng-template #body>
                @if (dspAdvertiser === selectedDspAdvertiser) {
                  <span
                    class="icon-[material-symbols--check-circle-rounded] text-main-500 absolute left-1 top-1 text-xl"
                  ></span>
                }
                <div class="mb-2 flex gap-3">
                  <div class="text-main-500 font-semibold">{{ dspAdvertiser.name }}</div>
                  <a [href]="dspAdvertiser.url" target="_blank" class="text-main-500">{{ dspAdvertiser.url }}</a>
                </div>
                <IBadge
                  color="gray"
                  size="xs"
                  label="ID: {{ dspAdvertiser.dspAdvertiserId }}"
                  tooltipValue="{{ t('link-dsp.this_is_the_dsp_advertiser_id') }}"
                ></IBadge>
              </ng-template>
            </ICard>
          </div>
        }
      }
    </div>
    <div class="flex justify-end gap-4" *transloco="let t">
      @if (selectedDspAdvertiser) {
        <button type="button" class="btn btn-primary" (click)="save()">{{ t("common.confirm") }}</button>
      }
      <button type="button" class="btn btn-secondary" (click)="modalRef.close()">{{ t("common.exit") }}</button>
    </div>
  `,
  standalone: true,
  imports: [TranslocoDirective, SpinnerComponent, ICardComponent, IBadgeComponent],
})
@UntilDestroy()
export class LinkDspModalComponent {
  readonly modalData = inject<LinkDspModalData>(MODAL_DATA);
  readonly modalRef = inject(ModalRef);

  readonly Marketplaces = Marketplaces;
  loading = signal(false);
  dspAdvertisers?: DspAdvertiser[];
  selectedDspAdvertiser?: DspAdvertiser;
  errorMessage?: string;

  constructor(
    private amazonUserService: AmazonUserService,
    private toastrService: ToastrService,
    private accountMarketplaceService: AccountMarketplaceService,
    private translocoService: TranslocoService,
  ) {}

  logWithAmazon(): void {
    this.loading.set(true);
    this.selectedDspAdvertiser = undefined;
    this.amazonUserService.getAmazonUser(
      this.modalData.accountMarketplace.resourceOrganizationId!,
      (amazonUser: AmazonUser) => {
        this.loading.set(false);
        this.dspAdvertisers = amazonUser.dspAdvertisers;
        this.dspAdvertisers = (amazonUser.dspAdvertisers ?? []).filter(
          (dspAdvertiser) => dspAdvertiser.marketplace === this.modalData.accountMarketplace.marketplace,
        );
        if (this.dspAdvertisers.length > 0) {
          this.selectedDspAdvertiser = this.dspAdvertisers[0];
        }
      },
      (errorMessage: string) => {
        this.loading.set(false);
        this.toastrService.error(errorMessage);
        this.errorMessage = errorMessage;
      },
    );
  }

  selectDspAdvertiser(dspAdvertiser: DspAdvertiser): void {
    this.selectedDspAdvertiser = dspAdvertiser;
  }

  save(): void {
    this.accountMarketplaceService
      .linkDspAdvertiser(this.modalData.accountMarketplace, this.selectedDspAdvertiser!.dspAdvertiserId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.toastrService.success(this.translocoService.translate("link-dsp.dsp_advertiser_linked_successfully"));
          this.modalRef.close(this.selectedDspAdvertiser);
        },
        error: (error) => {
          this.errorMessage = error;
          this.toastrService.error(error, this.translocoService.translate("link-dsp.error_linking_dsp_advertiser"));
        },
      });
  }
}

export interface LinkDspModalData {
  accountMarketplace: AccountMarketplace;
}
