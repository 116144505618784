<div class="modal-header bg-primary" *transloco="let t">
  <h4 class="modal-title pull-left">
    {{
      segmentType === SegmentType.KeywordSegment
        ? t("add-to-segment-modal.add_search_term_to_segment")
        : t("add-to-segment-modal.add_product_to_segment")
    }}
  </h4>
</div>
<div class="modal-body" *transloco="let t">
  <div class="form-group row items-center">
    <label class="col-sm-3 col-form-label">
      {{
        segmentType === SegmentType.KeywordSegment
          ? t("add-to-segment-modal.target_search_term", { count: targetingItemValues.length })
          : t("add-to-segment-modal.target_product", { count: targetingItemValues.length })
      }}</label
    >

    @if (segmentType === SegmentType.ProductSegment) {
      <div class="col-sm-9">
        @for (targetingItemValue of targetingItemValues.slice(0, 3); track targetingItemValue; let last = $last) {
          <app-product-view [asin]="targetingItemValue" [marketplace]="marketplace"></app-product-view>
        }
      </div>
    }
    @if (segmentType === SegmentType.KeywordSegment) {
      <div class="col-sm-6 col-form-label">
        <div>
          @for (targetingItemValue of targetingItemValues.slice(0, 3); track targetingItemValue; let last = $last) {
            <span>{{ targetingItemValue }}{{ last ? "" : ", " }}</span>
          }
          @if (targetingItemValues.length > 3) {
            <span>…</span>
          }
        </div>
      </div>
      <div class="col-sm-3 flex justify-end">
        <app-switch-button
          [options]="[MatchType.phrase, MatchType.exact]"
          [buttonTexts]="['common.phrase', 'common.exact']"
          [buttonTooltips]="['', '']"
          [selected]="matchType"
          (buttonClicked)="setMatchType($event)"
        />
      </div>
    }
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label">{{ t("common.segment") }}</label>
    <div class="col-sm-9">
      <ISelect
        [options]="segmentOptions"
        [selected]="selectedSegment()"
        (selectedChange)="selectSegment($event)"
        searchable
      >
        <ng-template #optionSlot #labelSlot let-o>
          @if (!o.value) {
            <span class="text-main inline-flex items-center gap-x-2 truncate">
              <span [classList]="ICON_ADD + ' text-2xl'"></span>
              {{ t("add-to-segment-modal.create_new_segment") }}
            </span>
          } @else {
            <span class="inline-flex w-full justify-between truncate text-gray-700">
              {{ o.label }}
              <IBadge
                variant="soft"
                [label]="o.value.segmentType === SegmentType.KeywordSegment ? t('common.keyword') : t('common.product')"
                size="xs"
              />
            </span>
          }
        </ng-template>
      </ISelect>
    </div>
  </div>
</div>
<div class="modal-footer" *transloco="let t">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">
    {{ t("common.cancel") }}
  </button>
  <button type="submit" class="btn btn-primary" [disabled]="!creation && !selectedSegment()" (click)="save()">
    {{ t("common.save") }}
  </button>
</div>
