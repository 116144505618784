@if (!edit) {
  <span class="flex items-center" *transloco="let t">
    <button
      class="btn btn-ghost-secondary btn-lg custom-btn-dark-hover"
      [matTooltip]="disable ? '' : tooltipText"
      [matTooltipPosition]="tooltipPosition"
      (click)="toggleEditMode()"
    >
      <ng-content></ng-content>
      @if (!disable) {
        <fa-icon [icon]="faPencil" class="ml-3"></fa-icon>
      }
    </button>
  </span>
} @else {
  <span class="flex gap-x-2" *transloco="let t">
    <input
      class="simple-input-append form-control input-name edit-name"
      type="text"
      maxlength="200"
      placeholder="{{ placeholder }}"
      [value]="value"
      (keydown.enter)="updateValue(newName.value)"
      (keydown.escape)="toggleEditMode()"
      #newName
    />
    <IButton
      [tooltipValue]="tooltipText"
      (onClick)="updateValue(newName.value)"
      [disabled]="!newName.value"
      [icon]="ICON_CHECK"
      variant="soft"
    />
    <IButton
      tooltipValue="{{ t('common.cancel') }}"
      (onClick)="toggleEditMode()"
      [disabled]="!newName.value"
      [icon]="ICON_CLOSE"
      color="red"
      variant="soft"
    />
  </span>
}
