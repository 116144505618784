import { Component, Input } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
@Component({
  selector: "app-ignored-keywords-modal",
  standalone: true,
  imports: [TranslocoRootModule],
  templateUrl: "./ignored-keywords-modal.component.html",
})
export class IgnoredKeywordsModalComponent {
  @Input() errors: { kw: string; reason: string }[];
  constructor(public bsModalRef: BsModalRef) {}
}
