// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Currency {
    AED = 'AED',
    AUD = 'AUD',
    BRL = 'BRL',
    CAD = 'CAD',
    EGP = 'EGP',
    EUR = 'EUR',
    GBP = 'GBP',
    INR = 'INR',
    JPY = 'JPY',
    MXN = 'MXN',
    PLN = 'PLN',
    USD = 'USD',
    SAR = 'SAR',
    SEK = 'SEK',
    SGD = 'SGD',
    TRY = 'TRY'
}

