import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DateAggregation, DateAggregations } from "@front/m19-utils";
import { SwitchButtonComponent } from "./switch-button.component";

@Component({
  selector: "app-date-aggreation-switch-button",
  standalone: true,
  imports: [SwitchButtonComponent],
  template: ` <app-switch-button
    [options]="aggregations"
    [buttonTexts]="aggregationFormat"
    [buttonTooltips]="['', '', '']"
    [selected]="selected"
    (buttonClicked)="selectAggregation($event)"
  />`,
})
export class DateAggreationComponent {
  @Input()
  set options(value: DateAggregation[]) {
    this.aggregations = value;
    this.aggregationFormat = this.aggregations.map((a) => DateAggregations[a].title);
  }

  @Input()
  selected: DateAggregation = DateAggregation.daily;
  @Input()
  displayTitle = true;

  aggregations: DateAggregation[] = [DateAggregation.daily, DateAggregation.weekly, DateAggregation.monthly];
  aggregationFormat: string[] = this.aggregations.map((a) => {
    switch (a) {
      case DateAggregation.hourly:
        return "common.hourly";
      case DateAggregation.daily:
        return "common.daily";
      case DateAggregation.weekly:
        return "common.weekly";
      case DateAggregation.monthly:
        return "common.monthly";
    }
  });

  @Output()
  dateAggSelected = new EventEmitter<DateAggregation>();

  selectAggregation(aggregation: DateAggregation) {
    this.dateAggSelected.emit(aggregation);
  }
}
