import { Component, inject, signal } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@front/m19-services";
import { Angulartics2 } from "angulartics2";
import { LayoutSelectorService } from "@m19-board/layout-selector.service";
import { environment } from "../../../environments/environment";
import { LoginFormValue } from "@front/m19-ui";
import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";

@Component({
  selector: "app-login",
  templateUrl: "login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  private readonly authService = inject(AuthService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly angulartics2 = inject(Angulartics2);
  private readonly layoutSelector = inject(LayoutSelectorService);

  loading = signal(false);
  error = signal<string | null>(null);

  agency = this.layoutSelector.getAgencyName();
  isTest = !environment.production;
  termsAndConditionLink = this.layoutSelector.getTermsAndConditionLink();

  login(formValue: LoginFormValue): void {
    const redirectUrl = this.route.snapshot.queryParams["redirect"] ?? "dashboard";

    if (!formValue) return;
    this.error.set(null);
    this.loading.set(true);
    this.authService.doLoginV2(formValue.email, formValue.password).subscribe({
      next: () => {
        this.angulartics2.eventTrack.next({
          action: "login_with_password",
          properties: { category: "login", label: "success_" + formValue.email },
        });
        this.router.navigateByUrl(redirectUrl);
      },
      error: (err: HttpErrorResponse) => {
        if (err.status === HttpStatusCode.Unauthorized) this.error.set("Invalid email or password");
        else this.error.set(err.message);
        this.loading.set(false);
      },
    });
  }
}
