<div
  class="group flex flex-col gap-y-1 rounded-md px-4 py-2 disabled:cursor-default"
  (click)="onClickHandler()"
  [attr.data-testid]="testid()"
  [ngClass]="{ 'cursor-pointer duration-100 hover:bg-gray-100': !disabled() }"
>
  <div class="flex items-center gap-2">
    <ng-content select="[title]"></ng-content>
    @if (!disabled()) {
      <span [class]="ICON_EDIT + ' h-4 w-4 text-gray-500 opacity-0 duration-100 group-hover:opacity-100'"></span>
    }
  </div>
  <ng-content select="[body]"></ng-content>
</div>
