// sum number handling undefined
import { AdStatsEx, OrderStats, RequiredAdStatEx } from '../models';
import { divideVendorInventory, sumVendorInventoryStats } from './VendorInventoryUtils';
import { RequiredOrderStats, sumOrderStatInPlace, sumOrderStatInPlaceV2 } from './order-stats.utils';
import { AdStatsWithStrategyHistory } from '@front/m19-services';

export function sum(a: number | undefined, b: number | undefined) {
  return a ? (b ? a + b : a) : b ? b : 0;
}

export function sumV2(a: number, b: number | undefined) {
  return b ? a + b : a;
}

export function divide(a: number, b: number) {
  return a ? (b && b !== 0 ? a / b : NaN) : b ? 0 : NaN;
}

function baseAdStats(stats1: AdStatsEx, other: AdStatsEx, managedStats?: boolean): AdStatsEx {
  stats1.marketplace = stats1.marketplace ?? other.marketplace;
  stats1.campaignType = stats1.campaignType ?? other.campaignType;

  stats1.impressions = sum(stats1.impressions!, other.impressions!);
  stats1.m19Impressions = sum(stats1.m19Impressions!, other.m19Impressions!);
  stats1.unmanagedImpressions = sum(stats1.unmanagedImpressions!, other.unmanagedImpressions!);

  stats1.clicks = sum(stats1.clicks!, other.clicks!);
  stats1.m19Clicks = sum(stats1.m19Clicks!, other.m19Clicks!);
  stats1.unmanagedClicks = sum(stats1.unmanagedClicks!, other.unmanagedClicks!);

  stats1.cost = sum(stats1.cost!, other.cost!);
  stats1.m19Cost = sum(stats1.m19Cost!, other.m19Cost!);
  stats1.unmanagedCost = sum(stats1.unmanagedCost!, other.unmanagedCost!);

  stats1.adConversions = sum(stats1.adConversions!, other.adConversions!);
  stats1.m19AdConversions = sum(stats1.m19AdConversions!, other.m19AdConversions!);
  stats1.unmanagedAdConversions = sum(stats1.unmanagedAdConversions!, other.unmanagedAdConversions!);

  stats1.adSales = sum(stats1.adSales!, other.adSales!);
  stats1.m19AdSales = sum(stats1.m19AdSales!, other.m19AdSales!);
  stats1.unmanagedAdSales = sum(stats1.unmanagedAdSales!, other.unmanagedAdSales!);

  stats1.allOrderedUnits = sum(stats1.allOrderedUnits!, other.allOrderedUnits!);
  stats1.allSales = sum(stats1.allSales!, other.allSales!);
  stats1.browserSessions = sum(stats1.browserSessions!, other.browserSessions!);
  stats1.mobileAppSessions = sum(stats1.mobileAppSessions!, other.mobileAppSessions!);
  stats1.browserPageViews = sum(stats1.browserPageViews!, other.browserPageViews!);
  stats1.mobileAppPageViews = sum(stats1.mobileAppPageViews!, other.mobileAppPageViews!);
  stats1.buyBoxPageViews = sum(stats1.buyBoxPageViews!, other.buyBoxPageViews!);
  stats1.nbAsinsWithRuleFired = sum(stats1.nbAsinsWithRuleFired!, other.nbAsinsWithRuleFired!);

  stats1.vendorCustomerReturns = sum(stats1.vendorCustomerReturns!, other.vendorCustomerReturns!);
  stats1.vendorShippedCogs = sum(stats1.vendorShippedCogs!, other.vendorShippedCogs!);
  stats1.outOfStockGlanceViews = sum(stats1.outOfStockGlanceViews!, other.outOfStockGlanceViews!);
  return stats1;
}

function baseAdStatsV2(stats1: Required<AdStatsEx>, other: AdStatsEx): AdStatsEx {
  stats1.marketplace = stats1.marketplace ?? other.marketplace;
  stats1.campaignType = stats1.campaignType ?? other.campaignType;

  stats1.impressions = sumV2(stats1.impressions, other.impressions);
  stats1.clicks = sumV2(stats1.clicks, other.clicks);
  stats1.cost = sumV2(stats1.cost, other.cost);
  stats1.adConversions = sumV2(stats1.adConversions, other.adConversions);
  stats1.adSales = sumV2(stats1.adSales, other.adSales);
  stats1.allOrderedUnits = sumV2(stats1.allOrderedUnits, other.allOrderedUnits);
  stats1.allSales = sumV2(stats1.allSales, other.allSales);
  stats1.browserSessions = sumV2(stats1.browserSessions, other.browserSessions);
  stats1.mobileAppSessions = sumV2(stats1.mobileAppSessions, other.mobileAppSessions);
  stats1.browserPageViews = sumV2(stats1.browserPageViews, other.browserPageViews);
  stats1.mobileAppPageViews = sumV2(stats1.mobileAppPageViews, other.mobileAppPageViews);
  stats1.buyBoxPageViews = sumV2(stats1.buyBoxPageViews, other.buyBoxPageViews);
  stats1.nbAsinsWithRuleFired = sumV2(stats1.nbAsinsWithRuleFired, other.nbAsinsWithRuleFired);

  stats1.vendorCustomerReturns = sumV2(stats1.vendorCustomerReturns, other.vendorCustomerReturns);
  stats1.vendorShippedCogs = sumV2(stats1.vendorShippedCogs, other.vendorShippedCogs);
  stats1.outOfStockGlanceViews = sumV2(stats1.outOfStockGlanceViews, other.outOfStockGlanceViews);

  return stats1;
}

export function addAdStats(stats1: AdStatsEx, other: AdStatsEx): AdStatsEx {
  baseAdStats(stats1, other);

  // never sum netPureProductMargin
  if (other.netPureProductMargin) {
    stats1.netPureProductMargin = other.netPureProductMargin;
  }

  if (!other.vendorInventoryAdStats) return stats1;

  if (stats1.vendorInventoryAdStats) {
    stats1.vendorInventoryAdStats = sumVendorInventoryStats(
      stats1.vendorInventoryAdStats,
      other.vendorInventoryAdStats,
    );
  } else {
    stats1.vendorInventoryAdStats = sumVendorInventoryStats({}, other.vendorInventoryAdStats);
  }

  return stats1;
}

export function mergeSeveralDates(stats1: AdStatsEx, other: AdStatsEx, managedStats?: boolean): AdStatsEx {
  stats1 = baseAdStats(stats1, other, managedStats);
  if (other.netPureProductMargin) {
    if (!stats1.lastPpmDate || other.date! > stats1.lastPpmDate) {
      stats1.lastPpmDate = other.date;
      stats1.netPureProductMargin = other.netPureProductMargin;
    }
  }

  // for netPureProductMargin & VendorInventory stats
  // aggregation must only take the last available date
  if (!other.vendorInventoryAdStats) return stats1;

  if (
    !stats1?.vendorInventoryAdStats?.lastVendorInventoryDate ||
    other.date! > stats1.vendorInventoryAdStats.lastVendorInventoryDate
  ) {
    stats1.vendorInventoryAdStats = {};
    stats1.vendorInventoryAdStats.lastVendorInventoryDate = other.date;
  }
  if (other.date === stats1.vendorInventoryAdStats?.lastVendorInventoryDate) {
    stats1.vendorInventoryAdStats = sumVendorInventoryStats(
      stats1.vendorInventoryAdStats,
      other.vendorInventoryAdStats,
    );
  }

  return stats1;
}

export function mergeSeveralDatesV2(stats1: AdStatsEx, other: AdStatsEx): AdStatsEx {
  stats1 = baseAdStatsV2(stats1 as Required<AdStatsEx>, other);

  if (other.netPureProductMargin) {
    if (!stats1.lastPpmDate || other.date! > stats1.lastPpmDate) {
      stats1.lastPpmDate = other.date;
      stats1.netPureProductMargin = other.netPureProductMargin;
    }
  }

  // for netPureProductMargin & VendorInventory stats
  // aggregation must only take the last available date
  if (!other.vendorInventoryAdStats) return stats1;

  if (
    !stats1?.vendorInventoryAdStats?.lastVendorInventoryDate ||
    other.date! > stats1.vendorInventoryAdStats.lastVendorInventoryDate
  ) {
    stats1.vendorInventoryAdStats = {};
    stats1.vendorInventoryAdStats.lastVendorInventoryDate = other.date;
  }
  if (other.date === stats1.vendorInventoryAdStats?.lastVendorInventoryDate) {
    stats1.vendorInventoryAdStats = sumVendorInventoryStats(
      stats1.vendorInventoryAdStats,
      other.vendorInventoryAdStats,
    );
  }

  return stats1;
}

export function mergeAllStats(acc: OrderStats & AdStatsEx, curr: OrderStats & AdStatsEx): OrderStats & AdStatsEx {
  acc = mergeSeveralDates(acc, curr) as OrderStats & AdStatsEx;
  acc = sumOrderStatInPlace(acc, curr) as OrderStats & AdStatsEx;
  return acc;
}

export function mergeAllStatsV2(
  acc: OrderStats & AdStatsEx,
  curr: OrderStats & AdStatsEx,
): RequiredAdStatEx & RequiredOrderStats {
  acc = mergeSeveralDatesV2(acc, curr) as RequiredAdStatEx & RequiredOrderStats;
  acc = sumOrderStatInPlaceV2(acc, curr) as RequiredAdStatEx & RequiredOrderStats;
  return acc as RequiredAdStatEx & RequiredOrderStats;
}

export function mergeAdStatsWithOrderStats(stats: (AdStatsEx & OrderStats)[]) {
  return stats.reduce((acc, curr) => mergeAllStatsV2(acc, curr), emptyAdStatExWithOrderStats());
}

export function emptyAdStatExWithOrderStats(): RequiredAdStatEx & RequiredOrderStats {
  return {
    advertising: 0,
    fee: 0,
    globalSales: 0,
    promotion: 0,
    shippingGiftWrap: 0,
    tax: 0,
    adConversions: 0,
    adSales: 0,
    allOrderedUnits: 0,
    allSales: 0,
    asin: '',
    browserPageViews: 0,
    browserSessions: 0,
    buyBoxPageViews: 0,
    clicks: 0,
    cost: 0,
    date: '',
    impressions: 0,
    mobileAppPageViews: 0,
    mobileAppSessions: 0,
    nbAsinsWithRuleFired: 0,
    outOfStockGlanceViews: 0,
    vendorCustomerReturns: 0,
    vendorShippedCogs: 0,
    sales: 0,
    profit: 0,
    fbaStorageFee: 0,
    spAdvertising: 0,
    sbAdvertising: 0,
    sdAdvertising: 0,
    m19Cost: 0,
    m19AdSales: 0,
    m19Clicks: 0,
    m19Impressions: 0,
    m19AdConversions: 0,
    unmanagedAdSales: 0,
    unmanagedCost: 0,
    unmanagedAdConversions: 0,
    unmanagedClicks: 0,
    unmanagedImpressions: 0,
    costOfGoods: 0,
    refunds: 0,
    reimbursement: 0,
    reimbursementClawback: 0,
    quantity: 0,
    refunded: 0,
    chargebackRefunded: 0,
    a2zGuaranteeRefunded: 0,
    orderReturnRefunded: 0,
    replacementRefunded: 0,
    sellableReturns: 0,
    amazonVine: 0,
    baseTax: 0,
    shippingTax: 0,
    giftWrapTax: 0,
    otherTax: 0,
    taxDiscount: 0,
    basePromotion: 0,
    giftWrapPromotion: 0,
    shippingPromotion: 0,
    otherPromotion: 0,
    shipping: 0,
    shippingHB: 0,
    shippingChargeBack: 0,
    giftWrap: 0,
    giftWrapChargeBack: 0,
    referralFee: 0,
    fbaFee: 0,
    giftWrapFee: 0,
    returnFees: 0,
    feeAdjustment: 0,
    exportCharge: 0,
    longTermStorageFee: 0,
    liquidations: 0,
    internationalFreight: 0,
    otherFee: 0,
  };
}

export function emptyAdStatEx(): RequiredAdStatEx {
  return {
    adConversions: 0,
    adSales: 0,
    allOrderedUnits: 0,
    allSales: 0,
    asin: '',
    browserPageViews: 0,
    browserSessions: 0,
    buyBoxPageViews: 0,
    clicks: 0,
    cost: 0,
    date: '',
    impressions: 0,
    mobileAppPageViews: 0,
    mobileAppSessions: 0,
    nbAsinsWithRuleFired: 0,
    outOfStockGlanceViews: 0,
    vendorCustomerReturns: 0,
    vendorShippedCogs: 0,
    m19AdSales: 0,
    m19Clicks: 0,
    m19Cost: 0,
    m19Impressions: 0,
    m19AdConversions: 0,
    unmanagedAdSales: 0,
    unmanagedCost: 0,
    unmanagedAdConversions: 0,
    unmanagedClicks: 0,
    unmanagedImpressions: 0,
  };
}

export function emptyNewMetricsTests(): AdStatsEx {
  return {
    m19AdSales: 0,
    m19Clicks: 0,
    m19Cost: 0,
    m19Impressions: 0,
    m19AdConversions: 0,
    marketplace: undefined,
    unmanagedAdSales: 0,
    unmanagedCost: 0,
    unmanagedAdConversions: 0,
    unmanagedClicks: 0,
    unmanagedImpressions: 0,
  };
}

export function mergeAdStatsWithStrategyHistory(stats1: AdStatsWithStrategyHistory, other: AdStatsWithStrategyHistory) {
  return {
    ...mergeSeveralDates(stats1, other),
    dailyBudget: sum(stats1.dailyBudget!, other.dailyBudget!),
    monthlyBudget: stats1.monthlyBudget ?? other.monthlyBudget,
    computedDailyBudget: sum(stats1.computedDailyBudget!, other.computedDailyBudget!),
    minDailySpend: sum(stats1.minDailySpend!, other.minDailySpend!),
    acosTarget: stats1.acosTarget ?? other.acosTarget,
  };
}

export function divideAdStats(stats1: AdStatsEx, x: number): AdStatsEx {
  stats1.impressions = divide(stats1.impressions!, x);
  stats1.clicks = divide(stats1.clicks!, x);
  stats1.cost = divide(stats1.cost!, x);
  stats1.adConversions = divide(stats1.adConversions!, x);
  stats1.adSales = divide(stats1.adSales!, x);
  stats1.allOrderedUnits = divide(stats1.allOrderedUnits!, x);
  stats1.allSales = divide(stats1.allSales!, x);
  stats1.browserSessions = divide(stats1.browserSessions!, x);
  stats1.mobileAppSessions = divide(stats1.mobileAppSessions!, x);
  stats1.browserPageViews = divide(stats1.browserPageViews!, x);
  stats1.mobileAppPageViews = divide(stats1.mobileAppPageViews!, x);
  stats1.buyBoxPageViews = divide(stats1.buyBoxPageViews!, x);
  stats1.nbAsinsWithRuleFired = divide(stats1.nbAsinsWithRuleFired!, x);

  stats1.vendorCustomerReturns = divide(stats1.vendorCustomerReturns!, x);
  stats1.vendorShippedCogs = divide(stats1.vendorShippedCogs!, x);

  if (stats1.vendorInventoryAdStats) {
    stats1.vendorInventoryAdStats = divideVendorInventory(stats1.vendorInventoryAdStats, x);
  }

  return stats1;
}
