import { Marketplace, MatchType, Segment, Targeting } from '../api-client/models';
import { Utils } from '../utils/utils';

export enum SegmentConfigType {
  ProductSegment = 'ProductSegment',
  KeywordSegment = 'KeywordSegment',
  MixedSegment = 'MixedSegment',
}

export class SegmentEx implements Segment {
  public constructor(segment: Segment) {
    this.accountId = segment.accountId;
    this.segmentId = segment.segmentId!;
    this.name = segment.name;
    this.items = segment.items!;
    this.marketplace = segment.marketplace!;
  }

  public accountId: string;
  public segmentId: number;
  public name: string;
  private _items!: Targeting[];
  public get items(): Targeting[] {
    return this._items;
  }

  public set items(items: Targeting[]) {
    this._items = items;
    this._segmentType = undefined; // force segmentType reload
  }

  public marketplace: Marketplace;

  private _segmentType?: SegmentConfigType;
  public get segmentType(): SegmentConfigType {
    if (this._segmentType) {
      return this._segmentType;
    }
    const hasProductSegment = this.items.findIndex((i) => i.matchType === MatchType.asinSameAs) >= 0;
    if (this.items.findIndex((i) => i.matchType !== MatchType.asinSameAs) >= 0) {
      this._segmentType = hasProductSegment ? SegmentConfigType.MixedSegment : SegmentConfigType.KeywordSegment;
    } else {
      this._segmentType = SegmentConfigType.ProductSegment;
    }
    return this._segmentType;
  }

  public addItems(items: Targeting[]) {
    this._items.push(...items);
    this._segmentType = undefined; // force segmentType reload
  }

  public toSegment(): Segment {
    return {
      accountId: this.accountId,
      segmentId: this.segmentId,
      name: this.name,
      marketplace: this.marketplace,
      items: this.items,
    };
  }

  public getProductTargetingItems() {
    return this.items.filter((i) => i.matchType === MatchType.asinSameAs);
  }

  public getKeywordTargetingItems() {
    return this.items.filter((i) => i.matchType !== MatchType.asinSameAs);
  }

  public matchQuery(query: string): boolean {
    const isAsin = Utils.isValidAsin(query);
    // if the query is an Asin check if a segment item match the Asin
    if (isAsin) {
      return this.items.some((i) => i.matchType == MatchType.asinSameAs && i.targetingValue === query);
    }
    // otherwise the query is a set of keywords to match against the exact and same keywords
    const keyworkRegex = this.keywordQueryMatchingRegex;
    return keyworkRegex != null && keyworkRegex.test(Utils.normalizeKeyword(query));
  }

  private get keywordQueryMatchingRegex(): RegExp | null {
    const exactKeywords: string[] = [];
    const phraseKeywords: string[] = [];
    for (const item of this.items) {
      if (item.matchType === MatchType.exact) {
        exactKeywords.push(item.targetingValue);
      } else if (item.matchType === MatchType.phrase) {
        phraseKeywords.push(item.targetingValue);
      }
    }
    if (exactKeywords.length === 0 && phraseKeywords.length === 0) {
      return null;
    }
    // return a regexp of the form ^exact1$|^exact2$|...|^.*\s(phrase1|phrase2|...)\s.*$
    const regex: string =
      exactKeywords.map((k) => `^${k}$`).join('|') +
      (phraseKeywords.length > 0 && exactKeywords.length > 0 ? '|' : '') +
      (phraseKeywords.length == 0 ? '' : `^((.+\\s)?(${phraseKeywords.join('|')})(\\s.+)?)$`);
    return new RegExp(regex);
  }
}

export const SegmentTypeDec: { [key in SegmentConfigType]: string } = {
  [SegmentConfigType.KeywordSegment]: 'keywords',
  [SegmentConfigType.ProductSegment]: 'product pages',
  [SegmentConfigType.MixedSegment]: 'product pages and keywords',
};
