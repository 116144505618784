// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    Response,
} from '../models';

export interface UploadPDFRequest {
    email: string;
    domain: string;
    subject: string;
    filename: string;
    message?: string;
    asset?: Blob;
}

/**
 * no description
 */
export class PdfApi extends BaseAPI {

    /**
     * Upload a PDF
     */
    uploadPDF({ email, domain, subject, filename, message, asset }: UploadPDFRequest): Observable<Response>
    uploadPDF({ email, domain, subject, filename, message, asset }: UploadPDFRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    uploadPDF({ email, domain, subject, filename, message, asset }: UploadPDFRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(email, 'email', 'uploadPDF');
        throwIfNullOrUndefined(domain, 'domain', 'uploadPDF');
        throwIfNullOrUndefined(subject, 'subject', 'uploadPDF');
        throwIfNullOrUndefined(filename, 'filename', 'uploadPDF');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'email': email,
            'domain': domain,
            'subject': subject,
            'filename': filename,
        };

        if (message != null) { query['message'] = message; }

        const formData = new FormData();
        if (asset !== undefined) { formData.append('asset', asset as any); }

        return this.request<Response>({
            url: '/uploadPdf',
            method: 'POST',
            headers,
            query,
            body: formData,
        }, opts?.responseOpts);
    };

}
