export type ScaleConfiguration = {
  tickSpacing: number;
  min: number;
  max: number;
};

export type ScaleConfigurationInput = {
  minPoint: number;
  maxPoint: number;
};

type ScaleConfigurationInputNormalized = {
  min: NormalizedNumber;
  max: NormalizedNumber;
};

type NormalizedNumber = {
  sign: Sign;
  val: number;
  pow10: number;
};

enum Sign {
  NEG = -1,
  POS = 1,
}

export class ScaleUtils {
  public static computeScaleConfiguration(ticks: number, inputs: ScaleConfigurationInput[]): ScaleConfiguration[] {
    const result: ScaleConfiguration[] = [];
    // number of ticks are not respected if inputs span in negative and positive values
    let signChange = false;
    const normalizedInputs: ScaleConfigurationInputNormalized[] = [];
    let lowestMin: NormalizedNumber = this.normalize(0);
    let highestMax: NormalizedNumber = this.normalize(1);
    for (const { minPoint, maxPoint } of inputs) {
      if (minPoint * maxPoint < 0) {
        signChange = true;
      }
      const minMax = ScaleUtils.normalizeMinMax(minPoint, maxPoint);
      normalizedInputs.push(minMax);

      if (!lowestMin || lowestMin.val * lowestMin.sign > minMax.min.val * minMax.min.sign) {
        lowestMin = minMax.min;
      }
      if (!highestMax || highestMax.val * highestMax.sign < minMax.max.val * minMax.max.sign) {
        highestMax = minMax.max;
      }
    }
    // if 0 <= min < max of min < max <= 0 for all axis, we do not align the 0 axis
    if (!signChange) {
      for (const normalizedInput of normalizedInputs) {
        result.push(this.simpleScaleConfiguration(ticks, normalizedInput));
      }
      return result;
    }
    const ratio = lowestMin.val / highestMax.val; // get the ratio of the linechart
    let niceMin;
    let niceMax;
    let tickSpacing;
    if (ratio <= 1 / 4) {
      niceMax = ScaleUtils.niceRound(highestMax.val, [1, 2, 4, 6, 8, 10]);
      tickSpacing = niceMax / 4;
      niceMin = tickSpacing;
    } else if (ratio <= 2 / 3) {
      niceMax = ScaleUtils.niceRound(highestMax.val, [1.5, 3, 4.5, 6, 7.5, 9, 12]);
      tickSpacing = niceMax / 3;
      niceMin = tickSpacing * 2;
    } else if (ratio <= 4 / 5) {
      niceMax = ScaleUtils.niceRound(highestMax.val, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
      tickSpacing = niceMax / 5;
      niceMin = tickSpacing * 4;
    } else if (ratio < 3 / 2) {
      niceMin = ScaleUtils.niceRound(lowestMin.val, [1, 2, 4, 6, 8, 10]);
      tickSpacing = niceMin / 4;
      niceMax = tickSpacing * 4;
    } else if (ratio > 3 / 2 && ratio < 4) {
      niceMin = ScaleUtils.niceRound(lowestMin.val, [1.5, 3, 4.5, 6, 7.5, 9, 12]);
      tickSpacing = niceMin / 3;
      niceMax = tickSpacing * 2;
    } else {
      niceMin = ScaleUtils.niceRound(lowestMin.val, [1, 2, 4, 6, 8, 10]);
      tickSpacing = niceMin / 4;
      niceMax = tickSpacing;
    }
    for (const normalizedInput of normalizedInputs) {
      const pow10 = normalizedInput.max.pow10;
      result.push({ tickSpacing: tickSpacing * pow10, min: -niceMin * pow10, max: niceMax * pow10 });
    }

    return result;
  }

  private static simpleScaleConfiguration(
    ticks: number,
    normalizedInput: ScaleConfigurationInputNormalized,
  ): ScaleConfiguration {
    const niceMin = ScaleUtils.toNum(ScaleUtils.niceNum(normalizedInput.min, 'Inf'));
    const tickSpacing = ScaleUtils.toNum(
      ScaleUtils.niceNum(this.normalize((ScaleUtils.toNum(normalizedInput.max) - niceMin) / ticks), 'Sup'),
    );
    const niceMax = niceMin + ticks * tickSpacing;
    return { tickSpacing: tickSpacing, min: niceMin, max: niceMax };
  }

  private static niceNum(x: NormalizedNumber, supOrInf: 'Sup' | 'Inf'): NormalizedNumber {
    if (x.val == 0) {
      return x;
    }
    const niceFraction = ScaleUtils.niceFrac(x.val, x.sign > 0 ? supOrInf : supOrInf == 'Sup' ? 'Inf' : 'Sup');
    return { ...x, val: niceFraction };
  }

  private static niceFrac(fraction: number, supOrInf: 'Sup' | 'Inf') {
    // frac is between 1 and 10
    if (supOrInf == 'Sup') {
      if (fraction <= 1) return 1;
      else if (fraction <= 2) return 2;
      else if (fraction <= 2.5) return 2.5;
      else if (fraction <= 5) return 5;
      else return 10;
    } else {
      if (fraction < 1) return 0;
      else if (fraction < 2) return 1;
      else if (fraction < 5) return 2;
      else if (fraction < 10) return 5;
      else return 10;
    }
  }

  private static niceRound(x: number, values: number[]) {
    for (let i = 0; i < values.length; i++) {
      if (x < values[i]) {
        return values[i];
      }
    }
    return values[values.length - 1];
  }

  private static normalizeMinMax(min: number, max: number): ScaleConfigurationInputNormalized {
    const normalized = {
      min: ScaleUtils.normalize(min),
      max: ScaleUtils.normalize(max),
    };
    if (normalized.min.val == 0) {
      normalized.min.pow10 = normalized.max.pow10;
    }
    if (normalized.min.pow10 < normalized.max.pow10) {
      const factor = normalized.max.pow10 / normalized.min.pow10;
      normalized.min.pow10 *= factor;
      normalized.min.val /= factor;
    } else if (normalized.max.pow10 < normalized.min.pow10) {
      const factor = normalized.min.pow10 / normalized.max.pow10;
      normalized.max.pow10 *= factor;
      normalized.max.val /= factor;
    }
    return normalized;
  }

  public static normalize(x: number): NormalizedNumber {
    if (x == 0) {
      return {
        sign: Sign.POS,
        val: 0,
        pow10: 1,
      };
    }
    const sign = x < 0 ? Sign.NEG : Sign.POS;
    const pow10 = Math.pow(10, Math.floor(Math.log10(sign * x)));
    const val = (sign * x) / pow10;
    return { sign, val, pow10 };
  }

  public static toNum(x: NormalizedNumber): number {
    return x.sign * x.val * x.pow10;
  }
}
