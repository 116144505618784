<form [formGroup]="form" (ngSubmit)="onSubmit()" *transloco="let t">
  <div class="flex flex-col gap-4">
    <IInput
      size="lg"
      type="password"
      [label]="t('register.password_field')"
      id="password"
      icon="icon-[mdi--password]"
      autocomplete="on"
      [control]="form.controls.password"
      [error]="formErrors()['password']"
    />
    <IInput
      size="lg"
      type="password"
      [label]="t('register.password_confirm_field')"
      autocomplete="on"
      id="confirmPassword"
      icon="icon-[mdi--password]"
      [control]="form.controls.confirmPassword"
      [error]="formErrors()['confirmPassword']"
    />
  </div>
  <IButton
    class="mt-10"
    type="submit"
    size="lg"
    label="Reset Password"
    [block]="true"
    [loading]="isLoading"
    [disabled]="isLoading || form.invalid"
  />
</form>
