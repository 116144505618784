import { NgClass } from "@angular/common";
import { Component, computed, inject, OnInit, output, signal } from "@angular/core";
import { SbAsins, SbCreative } from "@front/m19-api-client";
import { AccountSelectionService, SbStrategiesService } from "@front/m19-services";
import { IButtonComponent, MODAL_DATA, ModalRef } from "@front/m19-ui";
import { TranslocoModule } from "@jsverse/transloco";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";
import { switchMap } from "rxjs";

interface AdLineResult {
  asin1: { asin: string | undefined; deleted: boolean };
  asin2: { asin: string | undefined; deleted: boolean };
  asin3: { asin: string | undefined; deleted: boolean };
  isDuplicate: boolean;
  isEmpty: boolean;
  impacted?: boolean;
}

interface CreativeAdLineResult {
  creativeId: number;
  adLines: Array<AdLineResult>;
  impacted?: boolean;
}

export interface CreativeSbAsinsResult {
  creative: SbCreative;
  asins: Array<SbAsins>;
}

@Component({
  selector: "tacos-sb-asins-check",
  standalone: true,
  imports: [NgClass, IButtonComponent, TranslocoModule, SpinnerComponent],
  templateUrl: "./tacos-sb-asins-check.component.html",
})
export class TacosSbAsinsCheckComponent implements OnInit {
  private readonly sbStrategiesService = inject(SbStrategiesService);
  private readonly accountSelection = inject(AccountSelectionService);

  protected readonly data = inject<TacosSbAsinsCheckData>(MODAL_DATA);
  private readonly modalRef = inject(ModalRef<Array<CreativeSbAsinsResult>>);

  newSbAsins = output<Array<CreativeSbAsinsResult>>();

  adLinesResult = signal<Array<CreativeAdLineResult>>([]);
  atLeastOneImpacted = computed(() => this.adLinesResult().some((creative) => creative.impacted));

  hasAtLeastOneAdLine = computed(() =>
    this.adLinesResult().some((creative) =>
      creative.adLines.some(
        (adLine) =>
          (adLine.asin1.asin && !adLine.asin1.deleted) ||
          (adLine.asin2.asin && !adLine.asin2.deleted) ||
          (adLine.asin3.asin && !adLine.asin3.deleted),
      ),
    ),
  );

  noAdLineTooltip = computed(() => (this.hasAtLeastOneAdLine() ? "" : "You must have at least one ad line"));

  loading = signal(false);
  sbCreatives = signal<SbCreative[] | undefined>(undefined);

  ngOnInit() {
    this.loading.set(true);
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(switchMap((am) => this.sbStrategiesService.getSbCreativesPerStrategy(am.accountId, am.marketplace)))
      .subscribe((sbCreatives) => {
        const creatives = sbCreatives.get(this.data.sbStrategyId!);
        this.sbCreatives.set(creatives!);

        const creativeAdLineResults: Array<CreativeAdLineResult> = [];
        for (const creative of creatives!) {
          const adLines = this.processRemoval(creative);
          const impacted = adLines.some((adLine) => adLine.impacted);
          creativeAdLineResults.push({
            creativeId: creative.creativeId,
            adLines: adLines,
            impacted: impacted,
          });
        }
        this.adLinesResult.set(creativeAdLineResults);
        this.loading.set(false);
        if (!this.atLeastOneImpacted()) {
          this.confirmDeletion();
          this.modalRef.close();
        }
      });
  }

  private processRemoval(creative: SbCreative): Array<AdLineResult> {
    const asinsToRemove = this.data.asinsToRemove;
    const sbAsins = creative.creativeAsins!;

    const res: Array<AdLineResult> = [];

    for (const group of sbAsins) {
      const adLineResult: AdLineResult = {
        asin1: { asin: group.asin1, deleted: asinsToRemove.includes(group.asin1!) },
        asin2: { asin: group.asin2, deleted: asinsToRemove.includes(group.asin2!) },
        asin3: { asin: group.asin3, deleted: asinsToRemove.includes(group.asin3!) },
        isDuplicate: false,
        isEmpty: false,
      };

      const adLineResultAsinToArray = this.convertToStringAsins(adLineResult);
      adLineResult.isDuplicate = res.some((adLine) => {
        if (adLine.isEmpty) return false;
        const asinToArray = this.convertToStringAsins(adLine);

        return asinToArray.every((asin, index) => asin === adLineResultAsinToArray[index]);
      });

      adLineResult.isEmpty =
        (!adLineResult.asin1.asin || adLineResult.asin1.deleted) &&
        (!adLineResult.asin2.asin || adLineResult.asin2.deleted) &&
        (!adLineResult.asin3.asin || adLineResult.asin3.deleted);

      adLineResult.impacted =
        !adLineResult.isDuplicate &&
        !adLineResult.isEmpty &&
        (adLineResult.asin1.deleted || adLineResult.asin2.deleted || adLineResult.asin3.deleted);

      res.push(adLineResult);
    }

    return res;
  }

  private convertToStringAsins(asins: AdLineResult) {
    const res = [];
    if (asins.asin1.asin && !asins.asin1.deleted) res.push(asins.asin1.asin);
    if (asins.asin2.asin && !asins.asin2.deleted) res.push(asins.asin2.asin);
    if (asins.asin3.asin && !asins.asin3.deleted) res.push(asins.asin3.asin);
    return res;
  }

  confirmDeletion() {
    const creativeSbAsinsResults: Array<CreativeSbAsinsResult> = [];
    const filtered = this.adLinesResult().map((creative) => {
      return {
        creative: this.sbCreatives()!.find((c) => c.creativeId === creative.creativeId)!,
        adLines: creative.adLines.filter((adLine) => !adLine.isEmpty && !adLine.isDuplicate),
      };
    });

    for (const creative of filtered) {
      const asins = creative.adLines.map((adLine) => this.convertToStringAsins(adLine));
      creativeSbAsinsResults.push({
        creative: creative.creative,
        asins: asins.map((asin) => ({
          asin1: asin[0],
          asin2: asin[1],
          asin3: asin[2],
        })),
      });
    }
    this.modalRef.close(creativeSbAsinsResults);
  }

  onCancel() {
    this.modalRef.close();
  }
}

export interface TacosSbAsinsCheckData {
  sbStrategyId: number;
  asinsToRemove: Array<string>;
}
