<ng-container *transloco="let t">
  <div class="modal-header bg-main-500">
    <h4 class="modal-title pull-left">{{ t("sd-targeting-add-popup.add_a_new_targeting") }}</h4>
  </div>
  <div class="modal-body">
    @if (targetingsCount >= maxTargetingsPerSdStrategy) {
      <div class="alert alert-warning" role="alert">
        {{ t("sd-targeting-add-popup.limit_targeting") }}
      </div>
    }
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">{{ t("sd-strategy-creation.targeting_type") }}</label>
      <div class="col-sm-10">
        <ISelect
          [options]="targetingTypeOptions"
          [selectedValue]="defaultTargetingTypeOption.value"
          (selectedOptionChange)="selectTargetingType($event)"
        >
          <ng-template #optionSlot #labelSlot let-o>
            <span class="truncate" [class.disabled]="o.disabled">{{ t(o.label) }}</span>
          </ng-template>
        </ISelect>
      </div>
    </div>
    <div *ngIf="targetingType === SdTargetingType.PRODUCT_AI_TARGETING">
      <div class="modal-footer">
        <IButton label="{{ t('common.cancel') }}" color="gray" variant="ghost" (onClick)="close()" />
        <IButton
          [label]="creation ? t('common.next') : t('sd-targeting-add-popup.enable_ai-powered_targeting')"
          (onClick)="enableAIPoweredTargeting()"
        />
      </div>
    </div>
    <div *ngIf="targetingType === SdTargetingType.PRODUCT || targetingType === SdTargetingType.BLACKLIST_PRODUCT">
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">{{ t("common.segment") }}</label>
        <div class="col-sm-10">
          <ISelect
            [options]="segmentOptions"
            [selectedValue]="defaultSegmentOption.value"
            (selectedOptionChange)="selectSegment($event)"
            searchable
          >
            <ng-template #optionSlot #labelSlot let-o>
              <span class="truncate" [class.disabled]="o.disabled">{{ o.label }}</span>
            </ng-template>
          </ISelect>
        </div>
      </div>
      <div class="modal-footer">
        <IButton label="{{ t('common.cancel') }}" color="gray" variant="ghost" (onClick)="close()" />
        <IButton
          [label]="creation ? t('common.next') : t('common.create')"
          [disabled]="!creation && !segment"
          (onClick)="create()"
        />
      </div>
    </div>
    <div *ngIf="targetingType === SdTargetingType.REMARKETING">
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">{{ t("sd-strategy-creation.event_type") }}</label>
        <div class="col-sm-10">
          <ISelect
            class="w-full min-w-0"
            [options]="eventTypeOptions"
            [selectedValue]="defaultEventTypeOption.value"
            (selectedOptionChange)="selectExpressionType($event)"
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">{{ t("sd-strategy-creation.match_type") }}</label>
        <div class="col-sm-10">
          <ISelect
            class="w-full min-w-0"
            [options]="matchTypeOptions"
            [selectedValue]="defaultMatchTypeOption.value"
            (selectedOptionChange)="selectMatchType($event)"
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">{{ t("sd-strategy-creation.lookback") }}</label>
        <div class="col-sm-10">
          <ISelect
            class="w-full min-w-0"
            [options]="lookbackOptions"
            [selectedValue]="defaultLookbackOption.value"
            (selectedOptionChange)="selectLookback($event)"
          />
        </div>
      </div>
      @if (existingAudience()) {
        <div class="alert alert-danger">{{ t("sd-targeting-add-popup.already_exists") }}</div>
      }
      @if (illegalAudience()) {
        @if (audienceExpressionType === AudienceExpressionType.views) {
          <div class="alert alert-danger">{{ t("sd-targeting-add-popup.create_error_related") }}</div>
        } @else {
          <div class="alert alert-danger">{{ t("sd-targeting-add-popup.create_error_similar") }}</div>
        }
      }
      <div class="modal-footer">
        <IButton label="{{ t('common.cancel') }}" color="gray" variant="ghost" (onClick)="close()" />
        <IButton
          label="{{ t('common.create') }}"
          [disabled]="existingAudience() || illegalAudience()"
          (onClick)="createAudience()"
          type="submit"
        />
      </div>
    </div>
  </div>
</ng-container>
