import { Component, ElementRef, EventEmitter, Input, OnInit, Output, signal, ViewChild } from "@angular/core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Option } from "@front/m19-ui";
import { PALETTE } from "@m19-board/models/Metric";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { AsinQuickFilter, AsinSelectionFilter, KeywordTrackerService } from "../../../services/keyword-tracker.service";
import { RankingDataRow } from "../keyword-tracking-timeline-table/keyword-tracking-timeline-table.component";
import { TranslocoService } from "@jsverse/transloco";
import { Marketplace } from "@front/m19-api-client";
import { AsinService, getAmazonProductUrl, RankOption } from "@front/m19-services";
import { ProductEx } from "@front/m19-models";
import { Utils } from "@front/m19-utils";

@UntilDestroy()
@Component({
  selector: "app-tracked-asin-selection",
  templateUrl: "./tracked-asin-selection.component.html",
  styleUrls: ["./tracked-asin-selection.component.scss", "../../../shared/gen-dropdown/gen-dropdown.component.scss"],
})
export class TrackedAsinSelectionComponent implements OnInit {
  readonly palette = PALETTE;
  readonly faTimes = faTimes;

  readonly childParentSwitchText = ["tracked-asin-selection.child", "tracked-asin-selection.parent"];
  readonly childParentSwitchValues = [false, true];

  @Input()
  marketplace: Marketplace | undefined;

  @Input() datavizScroll: HTMLDivElement | undefined;
  @Input() updateScrollEvent: Observable<Event> | undefined;

  @ViewChild("asinListScroll") asinListScroll: ElementRef | undefined;
  @ViewChild("addAsinInput") addAsinInput: ElementRef | undefined;

  @Output() hover = new EventEmitter<string>();

  @Output() leave = new EventEmitter<number>();

  @Output() deleted = new EventEmitter<string>();

  asinRanks$ = this.keywordTrackerService.asinRankRowData$;
  groupByParent$ = this.keywordTrackerService.groupByParent$;
  graphAsin$ = this.keywordTrackerService.graphAsin$;
  tableView$ = this.keywordTrackerService.tableView$;
  hiddenAsins$ = this.keywordTrackerService.hiddenAsins$;

  selectedRankOption: RankOption = RankOption.BOTH;
  selectedAsin: string = "";

  newProductInput = "";
  trackNewAsinError = false;

  searchAsinInput = "";
  // init quick filter to user product
  quickFilterOptions: Option<AsinQuickFilter>[] = [
    {
      label: this.translocoService.translate("tracked-asin-selection.my_products"),
      value: AsinQuickFilter.MY_PRODUCT,
    },
    {
      label: this.translocoService.translate("tracked-asin-selection.competitors"),
      value: AsinQuickFilter.COMPETITORS,
    },
    {
      label: this.translocoService.translate("tracked-asin-selection.top_10"),
      value: AsinQuickFilter.TOP_10,
    },
  ];
  selectedQuickFilters = signal<Option<AsinQuickFilter>[]>([]);

  products: Map<string, Observable<ProductEx>> = new Map();
  productColor: Map<string, string> = new Map();

  constructor(
    private asinService: AsinService,
    private keywordTrackerService: KeywordTrackerService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.keywordTrackerService.rankOption$.subscribe((r) => (this.selectedRankOption = r));

    this.setupFilter();

    this.keywordTrackerService.graphAsin$.pipe(untilDestroyed(this)).subscribe((a) => (this.selectedAsin = a));
    this.keywordTrackerService.asinColors$.pipe(untilDestroyed(this)).subscribe((c) => (this.productColor = c));

    this.asinRanks$.subscribe((asins: RankingDataRow[]) => {
      for (const asin of asins) {
        this.addAsinToSet(asin.asin);

        if (asin.childrenRow) {
          for (const c of asin.childrenRow) {
            this.addAsinToSet(c.asin);
          }
        }
      }
    });

    this.updateScrollEvent?.pipe(untilDestroyed(this)).subscribe((e: Event) => {
      this.updateScroll("datavizScroll", e);
    });
  }

  setupFilter() {
    const LsQuickFilter: AsinQuickFilter[] = JSON.parse(localStorage.getItem("KeywordTrackerFilter") || "[]");

    this.selectedQuickFilters.set(
      LsQuickFilter && LsQuickFilter.length
        ? this.quickFilterOptions.filter((q) => LsQuickFilter.includes(q.value))
        : [this.quickFilterOptions[0]],
    );

    this.updateQuickFilter(this.selectedQuickFilters());
  }

  hideAsin(event: Event, asin: string) {
    event.stopPropagation();
    this.keywordTrackerService.toggleHiddenAsins(asin);
  }

  addAsinToSet(asin: string) {
    if (this.products.has(asin)) {
      return;
    }
    this.products.set(asin, this.asinService.getProductWithMarketplace(asin, this.marketplace));
  }

  switchGroupBy(groupByParent: boolean) {
    this.keywordTrackerService.setGroupByParent(groupByParent);
  }

  updateScroll(id: string, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.datavizScroll && id === "asinListScroll") {
      this.datavizScroll.scrollTop = this.asinListScroll?.nativeElement.scrollTop || 0;
    } else if (this.asinListScroll && id === "datavizScroll") {
      this.asinListScroll.nativeElement.scrollTop = this.datavizScroll?.scrollTop || 0;
    }
  }

  toggleGraphAsin(asin: string) {
    // prevent deselection in both option
    if (this.selectedRankOption === RankOption.BOTH && this.selectedAsin === asin) return;

    this.keywordTrackerService.toggleGraphAsin(asin);
  }

  updateQuickFilter(options: Option<AsinQuickFilter>[]) {
    this.selectedQuickFilters.set(options);
    const newFilter: AsinSelectionFilter = {
      quickFilter: new Set(this.selectedQuickFilters().map((q) => q.value)),
      searchAsin: this.searchAsinInput,
    };
    this.keywordTrackerService.setAsinSelectionFilter(newFilter);
  }

  searchAsin(asin: string) {
    this.searchAsinInput = asin;
    const newFilter: AsinSelectionFilter = {
      quickFilter: new Set(this.selectedQuickFilters().map((q) => q.value)),
      searchAsin: asin,
    };
    this.keywordTrackerService.setAsinSelectionFilter(newFilter);
  }

  onHover(asin: string) {
    this.hover.emit(asin);
  }

  onLeave() {
    this.leave.emit();
  }

  menuClosed() {
    this.trackNewAsinError = false;
    this.newProductInput = "";
  }

  trackNewProduct(event: Event) {
    if (!Utils.isValidAsin(this.newProductInput)) {
      event.stopPropagation();
      this.trackNewAsinError = true;
      return;
    }
    this.keywordTrackerService.trackNewProduct(this.newProductInput);
  }

  setFocus(): void {
    setTimeout(() => {
      this.addAsinInput?.nativeElement.focus();
    });
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  protected readonly getAmazonProductUrl = getAmazonProductUrl;
  protected readonly PALETTE = PALETTE;
  protected readonly RankOption = RankOption;
}
