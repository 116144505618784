import { Component, Input } from "@angular/core";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { CampaignType } from "@front/m19-api-client";
import { MatTooltip, TooltipPosition } from "@angular/material/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";

@Component({
  standalone: true,
  selector: "app-disable-auto-warning",
  templateUrl: "./disable-auto-warning.component.html",
  styleUrls: ["./disable-auto-warning.component.scss"],
  imports: [MatTooltip, FontAwesomeModule, TranslocoRootModule],
})
export class DisableAutoWarningComponent {
  @Input()
  campaignType: CampaignType;
  @Input()
  tooltipPosition: TooltipPosition = "above";

  readonly faWarning = faExclamationTriangle;
}
