import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { SbAdFormat } from "../sb-form-ad-format/sb-form-ad-format.component";
import { FormStepEvent, SbFormStep } from "../sb-strategy-form.component";
import { ConfigService } from "@front/m19-services";
import { AccountMarketplace, BrandAsset, MediaType, SbAsins, SbCreativeType } from "@front/m19-api-client";

export interface SbAsinGroup {
  asins: SbAsins[];
}

@Component({
  selector: "app-sb-form-asins",
  templateUrl: "./sb-form-asins.component.html",
})
export class SbFormAsinsComponent implements OnInit {
  readonly configService = inject(ConfigService);
  readonly SbCreativeType = SbCreativeType;

  @Input() am: AccountMarketplace;

  @Input() store: BrandAsset;
  @Input() availableStores: BrandAsset[];
  stores: BrandAsset[];

  @Input() creativeType: SbCreativeType;

  @Input() set sbAsins(i: SbAsinGroup) {
    this.form.patchValue(i);
    if (i) this.asinsAsArray = i.asins.map((a: SbAsins) => [a.asin1, a.asin2, a.asin3]);
  }

  @Input() sbAdFormat: SbAdFormat;
  @Input() reviewMode = false;

  @Output() onFormSubmit = new EventEmitter<FormStepEvent<SbAsinGroup>>();
  @Output() onFormPrevious = new EventEmitter<void>();

  // used to display asins in review mode
  asinsAsArray: string[][];

  form = new FormGroup({
    asins: new FormControl<SbAsins[]>(null, [Validators.required, this.AsinsValidator()]),
  });

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      )
      .subscribe((_) => {
        if (this.form.valid) {
          this.submitForm();
        }
      });

    this.stores = this.availableStores?.filter((a: BrandAsset) => {
      return a.mediaType === MediaType.storePage && (!this.store || a.brandEntityId === this.store.brandEntityId);
    });
  }

  submitForm(goNext = false): void {
    if (this.form.valid) {
      this.onFormSubmit.emit({ formData: this.form.value as SbAsinGroup, step: SbFormStep.ASINS, goNext });
    }
  }

  // Check for duplicate sb ASINs considering the order
  private AsinsValidator() {
    return (control: AbstractControl) => {
      const asins = control.value as SbAsins[];
      if (asins) {
        const asinSet = new Set<string>();
        for (const asin of asins) {
          const key = `${asin.asin1}-${asin.asin2}-${asin.asin3}`;
          if (asinSet.has(key)) {
            return { duplicate: true };
          }
          asinSet.add(key);
        }
      }
      return null;
    };
  }
}
