<ng-container *transloco="let t">
  <div class="flex text-gray-900">
    <button
      class="focus:ring-main-300 relative inline-flex cursor-default items-center gap-x-1.5 truncate rounded-l-md border-0 bg-white px-2.5 py-1.5 pe-9 text-left text-sm shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 disabled:cursor-not-allowed disabled:opacity-75"
      [disabled]="disabled"
      [matMenuTriggerFor]="menu"
    >
      <span>{{ t(timeRangeOption) }}</span>
      <span class="pointer-events-none absolute inset-y-0 end-0 flex items-center px-2.5">
        <span class="icon-[mdi--chevron-down] size-6 shrink-0 text-gray-500"></span>
      </span>
    </button>
    <mat-menu #menu="matMenu" xPosition="after" yPosition="below">
      <ul>
        @for (option of ranges; track option) {
          <li class="dd-item" (click)="updateTimeRange(option.label, option.value)">
            {{ t(option.label) }}
          </li>
        }
      </ul>
    </mat-menu>
    <div
      class="focus:ring-main-300 relative inline-flex cursor-default items-center gap-x-1.5 truncate rounded-r-md border-0 bg-white px-2.5 py-1.5 text-left text-sm shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 disabled:cursor-not-allowed disabled:opacity-75"
    >
      <span class="pointer-events-none flex items-center">
        <span class="icon-[mdi--calendar] size-5 shrink-0 text-gray-600"></span>
      </span>
      <div>
        <div class="flex flex-col">
          <input
            class="appearance-none border-0 bg-transparent p-0 outline-none"
            [disabled]="disabled"
            autocomplete="off"
            type="button"
            #_drp
            #drp="bsDaterangepicker"
            bsDaterangepicker
            [bsValue]="(bsRangeValue$ | async) ?? undefined"
            (bsValueChange)="setRangeValue($event)"
            container=".date-range-picker-container-{{ id }}"
            [bsConfig]="datePickerConfig"
            (onShown)="onShown()"
            [outsideClick]="false"
          />
          @if (!disabled && comparedRangeOption?.type !== 0) {
            <div class="flex justify-start text-gray-500" type="button" (click)="drp.show()" #comparePeriodToggle>
              @if (comparedRangeOption?.type !== 5) {
                <span class="vs-btn text-sm"> vs. {{ t(comparedRangeOption.label) }} </span>
              }
              @if (comparedRangeOption?.type === 5) {
                <span class="vs-btn ml-2 text-sm">
                  vs. {{ comparedRangeOption.start }} - {{ comparedRangeOption.end }}
                </span>
              }
            </div>
          }
        </div>
      </div>
    </div>
    <div
      class="date-range-picker-container date-range-picker-container-{{ id }}"
      #drcontainer
      [ngClass]="position"
    ></div>
    <div style="display: none">
      <div class="flex flex-col items-center justify-center" #previousPeriod>
        <div class="flex items-center" *transloco="let t">
          <span>{{ t("date-range-selection.compare_to") }}</span>
          <div
            class="mb-2 ml-2 mr-0 mt-2 flex w-auto"
            [ngClass]="comparedRangeOption.type === 5 ? 'input-group customDatePicker' : 'items-center'"
          >
            <button
              class="input-group-text ml-0 mr-0"
              [ngClass]="[
                !disableComparaison ? 'bg-white' : '',
                comparedRangeOption.type === 5 ? 'input-group-prepend' : 'period-comp-btn',
              ]"
              [disabled]="disableComparaison"
              [matMenuTriggerFor]="compareMenu"
            >
              <span class="mr-2 text-gray-800" [class.bg-white]="!disableComparaison">
                {{ t(comparedRangeOption.label) }}
              </span>
              <span [class]="ICON_CHEVRON_DOWN" class="text-xl text-gray-700"></span>
            </button>
            <mat-menu #compareMenu="matMenu" xPosition="after" yPosition="below">
              <ul #dropdownCompare>
                @for (option of comparaisonPeriodsValues; track option) {
                  <li class="dd-item flex items-center justify-between" (click)="updateComparedPeriod(option)">
                    <span class="text-gray-800">{{ t(option.label) }} </span>
                    @if (option.start && option.end) {
                      <span class="ml-2 text-gray-500"> {{ option.start }} - {{ option.end }} </span>
                    }
                  </li>
                }
              </ul>
            </mat-menu>
            @if (comparedRangeOption.type === 5) {
              <div class="simple-input-prepend input-group-text bg-white">
                <input
                  class="input-reset text-gray-800"
                  type="button"
                  #dp="bsDatepicker"
                  bsDatepicker
                  [bsConfig]="customDatePickerConfig"
                  [bsValue]="customStartDate"
                  (bsValueChange)="setCustomPeriod($event)"
                />
              </div>
            }
          </div>
        </div>
        @if (disableComparaison) {
          <div class="alert alert-warning flex p-1" *transloco="let t">
            <span>{{ t("date-range-selection.cant_compare") }}</span>
          </div>
        }
        @if (comparedRangeOption.type !== 0) {
          <div class="mb-1 flex text-sm" *transloco="let t">
            <span>
              {{
                t("date-range-selection.compare_infos", [range + 1, comparedRangeOption.start, comparedRangeOption.end])
              }}
            </span>
          </div>
        }
      </div>
    </div>
  </div>
</ng-container>
