import { CurrencyPipe } from "@angular/common";
import { Component, computed, inject, Input, OnInit, signal } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  AccountMarketplace,
  AlgoMode,
  CampaignType,
  Currency,
  Organization,
  StrategyType,
  TacticType,
} from "@front/m19-api-client";
import { Currencies, SegmentConfigType, SegmentEx, StrategyEx } from "@front/m19-models";
import { OrganizationService, SegmentService, StrategyService } from "@front/m19-services";
import { IBadgeComponent, IButtonComponent, IInputComponent } from "@front/m19-ui";
import { currencyRateToEuro } from "@front/m19-utils";
import { HourlyDataUnsupportedMarketplaces } from "@m19-board/overlay/hourly-stats-overlay.component";
import { SwitchInputComponent } from "@m19-board/shared/switch-input/switch-input.component";
import { DayPartingInputsComponent } from "@m19-board/strategies/day-parting-inputs/day-parting-inputs.component";
import { ICON_ADD, ICON_CLOSE, ICON_EDIT_O } from "@m19-board/utils/iconsLabels";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { Observable, of } from "rxjs";
import { DisableAutoWarningComponent } from "../../../strategies/disable-auto/disable-auto-warning.component";
import { TranslocoDirective } from "@jsverse/transloco";

@Component({
  selector: "app-advanced-settings-modal",
  standalone: true,
  imports: [
    IButtonComponent,
    IInputComponent,
    DayPartingInputsComponent,
    SwitchInputComponent,
    CurrencyPipe,
    DisableAutoWarningComponent,
    ToastrModule,
    IBadgeComponent,
    TranslocoDirective,
  ],
  templateUrl: "./advanced-settings-modal.component.html",
})
export class AdvancedSettingsModalComponent implements OnInit {
  readonly strategyService = inject(StrategyService);
  readonly orgService = inject(OrganizationService);
  readonly modalRef = inject(BsModalRef);
  readonly segmentService = inject(SegmentService);
  readonly toastr = inject(ToastrService);

  readonly ICON_EDIT = ICON_EDIT_O;
  readonly ICON_ADD = ICON_ADD;
  readonly ICON_CLOSE = ICON_CLOSE;

  @Input() accountMarketplace!: AccountMarketplace;
  @Input() strategy!: StrategyEx;
  @Input() isReadOnly!: boolean;
  @Input() locale!: string;
  @Input() currencyCode!: Currency;

  strategy$!: Observable<StrategyEx>;
  tacticSegments: SegmentEx[] = [];

  currencySymbol: string | undefined;

  // Strategy Label
  showStrategyLabelInput = false;
  newStrategyLabel = new FormControl("");

  // Day parting
  focusDayPartingAccess = false;

  // Min Daily Spend
  showMinDailySpend = false;
  minDailyBudgetLowerBound!: number;
  maxDailySpend!: number;
  minDailySpend = signal<number | undefined>(undefined);
  confirmButtonDisabled = computed(
    () =>
      !this.minDailySpend() ||
      this.minDailySpend()! < this.minDailyBudgetLowerBound ||
      this.minDailySpend()! > this.maxDailySpend,
  );
  minDailySpendLoading = signal(false);
  maxDailyBudgetinEuros!: number;

  autoAlgoExpEnabled = signal(false);
  productTargetingEnabled = signal(false);

  isProductTargetingToggleDisabled = computed(
    () =>
      this.isReadOnly ||
      (!this.autoAlgoExpEnabled() &&
        this.strategy.tactics.length > 0 &&
        this.tacticSegments.every((t) => t.segmentType === SegmentConfigType.ProductSegment)),
  );

  showAutoAlgoExp = computed(
    () =>
      this.strategy &&
      this.strategy.campaignType !== CampaignType.SD &&
      this.strategy.tactics.length > 0 &&
      (!this.autoAlgoExpEnabled() || this.strategy.tactics.some((t) => t.tacticType !== TacticType.BLACKLIST)),
  );

  isAutoAlgoToggleDisabled = computed(
    () =>
      this.isReadOnly ||
      (this.autoAlgoExpEnabled() &&
        !this.productTargetingEnabled() &&
        this.strategy.tactics.length > 0 &&
        this.tacticSegments.every((t) => t.segmentType === SegmentConfigType.ProductSegment)),
  );

  ngOnInit(): void {
    this.currencySymbol = Currencies[this.currencyCode].currencySymbol;
    this.newStrategyLabel.setValue(this.strategy.strategyLabel ?? "");
    this.strategy$ = of(this.strategy); // used for day parting inputs

    this.autoAlgoExpEnabled.set(this.strategy.autoAlgoExplorationEnabled);
    this.productTargetingEnabled.set(this.strategy.productTargetingEnabled);

    this.minDailyBudgetLowerBound = this.getMinDailyBudgetLowerBound();
    this.maxDailySpend = this.getMaxMinDailySpend();
    this.maxDailyBudgetinEuros = this.getUserMaxMinDailyBudgetInEuros();

    this.orgService.organizations$.subscribe((organizations) => {
      const marketplaceOrganization = organizations?.find(
        (org: Organization) => org.organizationId === this.accountMarketplace.resourceOrganizationId,
      );
      const hasAccessHourlyData: boolean = marketplaceOrganization
        ? marketplaceOrganization.billingPlan!.hourlyDataSupport! > 0
        : false;

      if (!HourlyDataUnsupportedMarketplaces.has(this.accountMarketplace.marketplace) && hasAccessHourlyData) {
        this.focusDayPartingAccess = true;
      }
    });
    this.segmentService
      .getSegments(this.accountMarketplace.accountId, this.accountMarketplace.marketplace)
      .subscribe((segments) => {
        const tacticSegmentIds = new Set(this.strategy.tactics.map((tactic) => tactic.segmentId));
        this.tacticSegments = Array.from(segments.values()).filter((segment) =>
          tacticSegmentIds.has(segment.segmentId),
        );
      });
  }

  updateStrategyLabel() {
    if (this.isReadOnly) return;
    this.strategyService
      .updateStrategyLabel(
        this.accountMarketplace.accountId,
        this.accountMarketplace.marketplace,
        this.accountMarketplace.resourceOrganizationId!,
        this.strategy.strategyId,
        this.newStrategyLabel.value ?? "",
      )
      .subscribe({
        next: () => {
          this.strategy.strategyLabel = this.newStrategyLabel.value ?? undefined;
          this.showStrategyLabelInput = false;
          this.toastr.success("Strategy label updated successfully");
        },
        error: (error) => {
          this.toastr.error(error.error.message);
        },
      });
  }

  // Auto targeting campaign
  toggleAutoTargetCampain(strategy: StrategyEx, enabled: boolean) {
    this.strategyService
      .updateStrategyTargetCampain(
        strategy.accountId,
        strategy.marketplace,
        this.accountMarketplace.resourceOrganizationId!,
        strategy.strategyId,
        enabled,
      )
      .subscribe({
        next: () => {
          this.toastr.success("Auto targeting campaign updated successfully");
          this.strategy.autoTargetCampainEnabled = enabled;
          if (enabled && !this.autoAlgoExpEnabled()) this.autoAlgoExpEnabled.set(true);
        },
        error: (error) => {
          this.toastr.error(error.error.message);
        },
      });
  }

  // Product targeting
  toggleProductTargeting(strategy: StrategyEx, enabled: boolean) {
    this.strategyService
      .updateStrategyProductTargeting(
        strategy.accountId,
        strategy.marketplace,
        this.accountMarketplace.resourceOrganizationId!,
        strategy.strategyId,
        enabled,
      )
      .subscribe({
        next: () => {
          this.toastr.success("Product targeting updated successfully");
          this.productTargetingEnabled.set(enabled);
        },
        error: (error) => {
          this.toastr.error(error.error.message);
        },
      });
  }

  // AI-powered targeting
  switchAutoAlgoExploration(strategy: StrategyEx, enabled: boolean) {
    this.strategyService
      .updateStrategyAutoAlgoExploration(
        strategy.accountId,
        strategy.marketplace,
        this.accountMarketplace.resourceOrganizationId!,
        strategy.strategyId,
        enabled,
      )
      .subscribe({
        next: () => {
          this.toastr.success("AI-powered targeting updated successfully");
          if (!enabled && this.strategy.autoTargetCampainEnabled) this.strategy.autoTargetCampainEnabled = false;
          this.autoAlgoExpEnabled.set(enabled);
        },
        error: (error) => {
          this.toastr.error(error.error.message);
        },
      });
  }

  private getMaxMinDailySpend() {
    const minDailySpendLimit = this.accountMarketplace?.minDailyBudgetLimit;

    return this.strategy.dailyBudget
      ? Math.floor(
          Math.min(
            (minDailySpendLimit ?? NaN) / currencyRateToEuro(this.currencyCode as Currency),
            0.5 * this.strategy.dailyBudget,
          ),
        )
      : Math.floor((minDailySpendLimit ?? NaN) / currencyRateToEuro(this.currencyCode as Currency));
  }

  private getMinDailyBudgetLowerBound(): number {
    return Math.ceil(1 / currencyRateToEuro(this.currencyCode as Currency));
  }

  private getUserMaxMinDailyBudgetInEuros(): number {
    const minDailySpendLimit = this.accountMarketplace?.minDailyBudgetLimit;

    return Math.round((minDailySpendLimit ?? NaN) / currencyRateToEuro(this.currencyCode as Currency));
  }

  updateMinDailySpend(value: number): void {
    this.minDailySpendLoading.set(true);
    this.strategyService
      .updateStrategyMinDailySpend(
        this.strategy.accountId,
        this.strategy.marketplace,
        this.accountMarketplace.resourceOrganizationId!,
        this.strategy.strategyId,
        value,
      )
      .subscribe({
        next: () => {
          this.toastr.success("Min daily spend updated successfully");
          this.strategy.minDailySpend = value;
          this.showMinDailySpend = false;
          this.minDailySpendLoading.set(false);
        },
        error: (error) => {
          this.toastr.error(error.error.message);
          this.minDailySpendLoading.set(false);
        },
      });
  }

  canEditCampaignLabel(): boolean {
    return this.accountMarketplace.customCampaignName != null;
  }

  StrategyType = StrategyType;
  CampaignType = CampaignType;
  AlgoMode = AlgoMode;
}
