import { Component, computed, inject, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AmcStatsService } from "@m19-board/amc/amc-stats-service/amc-stats.service";
import { AgGridAngular } from "@ag-grid-community/angular";
import { getBasicGridOptions, getGroupRowAgg } from "@front/m19-grid-config";
import {
  ColDef,
  GetRowIdParams,
  GridOptions,
  LabelFormatterParams,
  SparklineOptions,
  ValueGetterParams,
} from "@ag-grid-community/core";
import { AuthService, UserSelectionService } from "@front/m19-services";
import { toSignal } from "@angular/core/rxjs-interop";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { AmcSponsoredAdsAndDspOverlapEx } from "@front/m19-models/AmcSponsoredAdsAndDspOverlapEx";
import { BehaviorSubject, map, startWith, switchMap } from "rxjs";
import { BaseChartDirective } from "ng2-charts";
import {
  COST,
  Metric,
  TOTAL_PRODUCT_SALES,
  TOTAL_PURCHASES,
  UNIQUE_REACH,
  USERS_THAT_PURCHASED,
} from "@front/m19-metrics";
import { DonutDataSet } from "@m19-board/models/DonutDataSet";
import { ChartOptions } from "chart.js";
import { IAlertComponent, ISelectComponent, Option, SpinnerComponent } from "@front/m19-ui";
import { filter, tap } from "rxjs/operators";

@Component({
  selector: "amc-stats",
  standalone: true,
  imports: [
    CommonModule,
    AgGridAngular,
    BaseChartDirective,
    ISelectComponent,
    TranslocoDirective,
    IAlertComponent,
    SpinnerComponent,
  ],
  templateUrl: "./amc-stats.component.html",
})
export class AmcStatsComponent {
  private readonly amcStatsService = inject(AmcStatsService);
  private readonly authService = inject(AuthService);
  private readonly userSelectionService = inject(UserSelectionService);
  private readonly translocoService = inject(TranslocoService);

  readonly amcLink =
    "<a href='https://advertising.amazon.com/solutions/products/amazon-marketing-cloud' target='_blank'>AMC</a>";
  loggedUser = toSignal(this.authService.loggedUser$);
  locale = computed(() => this.loggedUser()?.locale);
  currency = toSignal(this.userSelectionService.selectedCurrency$, { requireSync: true });

  selectedReportDateSubject = new BehaviorSubject<string | undefined>(undefined);

  availableReportsDates$ = this.amcStatsService.getAvailableReports();
  availableReportsOptions = toSignal<Option<string>[]>(
    this.availableReportsDates$.pipe(
      tap((reportDates) => this.selectedReportDateSubject.next(reportDates[0])),
      map((reportDates) =>
        reportDates.map((d) => ({
          label: d,
          value: d,
        })),
      ),
    ),
  );

  amcStats = toSignal<AmcSponsoredAdsAndDspOverlapEx[]>(
    this.selectedReportDateSubject.pipe(
      filter((reportDate): reportDate is string => !!reportDate),
      switchMap((reportDate) => this.amcStatsService.getMonthlyAmcSponsoredAdsAndDspOverlapStats(reportDate)),
      startWith([]),
    ),
    { requireSync: true },
  );

  metricOptions: Option<Metric<AmcSponsoredAdsAndDspOverlapEx>>[] = AMC_METRICS.map((metric) => ({
    label: metric.title,
    value: metric,
  }));

  selectedMetric = signal<Metric<AmcSponsoredAdsAndDspOverlapEx>>(TOTAL_PRODUCT_SALES);
  private readonly donutChartOptionOverride = (options: ChartOptions) => {
    if (!options.plugins?.tooltip) return;
    options.plugins.tooltip.callbacks = {
      label: function (context) {
        const label = context.dataset.label || "";
        const total = (context.dataset.data as number[]).reduce((acc, curr) => acc + curr, 0);
        const value = (context.raw as number) || 0;
        const percentage = ((value / total) * 100).toFixed(1);

        return `${label}: ${percentage}%`;
      },
    };
  };

  private readonly donutDataSet = this.buildDonutDataset();

  dataSet = computed(() => {
    this.donutDataSet.buildDataSet(this.amcStats(), this.selectedMetric());
    return this.donutDataSet;
  });

  private GRID_KEY = "amcGridKey";
  public columnDefs = computed<ColDef<AmcSponsoredAdsAndDspOverlapEx>[]>(() => [
    {
      field: "exposureGroup",
      valueGetter: (params: ValueGetterParams<AmcSponsoredAdsAndDspOverlapEx>) => {
        return params.data?.exposureGroup || this.translocoService.translate("common.unknown");
      },
    },
    this.getBarChartColDef("totalProductSales", true),
    this.getBarChartColDef("usersThatPurchased"),
    this.getBarChartColDef("uniqueReach"),
    this.getBarChartColDef("totalPurchases"),
    this.getBarChartColDef("cost"),
  ]);

  private commonOptions = getBasicGridOptions(this.GRID_KEY, false, true, true, (a, b) => 42);
  public gridOptions = computed<GridOptions>(() => ({
    ...this.commonOptions,
    domLayout: "autoHeight",
    defaultColDef: {
      sortable: true,
      filter: true,
      resizable: true,
    },
    columnDefs: this.columnDefs(),
    getGroupRowAgg: getGroupRowAgg,
    getRowId: (params: GetRowIdParams<AmcSponsoredAdsAndDspOverlapEx>) => params.data.exposureGroup ?? "unknown",
    enableCharts: true,
  }));

  selectReportDate(date: Option<string> | undefined | null) {
    if (date) this.selectedReportDateSubject.next(date.value);
  }

  selectMetric(metric: Option<Metric<AmcSponsoredAdsAndDspOverlapEx>> | undefined | null) {
    if (metric) {
      this.selectedMetric.set(metric.value);
    }
  }

  private getBarChartColDef(field: FieldWithBarChart, sort = false): ColDef<AmcSponsoredAdsAndDspOverlapEx> {
    return {
      field,
      cellRenderer: "agSparklineCellRenderer",
      comparator: barChartDataComparator,
      cellRendererParams: {
        sparklineOptions: barChartOptions,
      },
      valueGetter: (params: ValueGetterParams<AmcSponsoredAdsAndDspOverlapEx>) => {
        return [this.getPercentage(params.data, field)];
      },
      sort: sort ? "desc" : undefined,
    };
  }

  private getPercentage(stat: AmcSponsoredAdsAndDspOverlapEx | undefined, field: FieldWithBarChart) {
    if (!stat) return 0;
    const total = this.amcStats().reduce((acc, curr) => acc + (curr[field] ?? 0), 0);

    return stat[field] ? Math.round((stat[field]! / total) * 100) : 0;
  }

  private buildDonutDataset() {
    return new DonutDataSet<AmcSponsoredAdsAndDspOverlapEx>(
      (a, _) => a,
      (a) => ({
        key: a.exposureGroup ?? this.translocoService.translate("common.unknown"),
        label: a.exposureGroup ?? this.translocoService.translate("common.unknown"),
      }),
      this.donutChartOptionOverride,
    );
  }
}

const barChartDataComparator = (a: number[], b: number[]) => {
  return a[0] - b[0];
};

const barChartOptions: SparklineOptions = {
  type: "bar",
  fill: "#4bae99",
  highlightStyle: {
    fill: "#4bae99",
    strokeWidth: 0,
  },
  label: {
    enabled: true,
    fontSize: 14,
    color: "#204f48",
    fontWeight: "600",
    formatter: (params: LabelFormatterParams) => {
      return params.value ? `${params.value}%` : "< 0.5%";
    },
  },
  paddingOuter: 0,
  padding: {
    top: 0,
    bottom: 0,
  },
  valueAxisDomain: [-1, 100],
  tooltip: {
    enabled: false,
  },
};

const AMC_METRICS: Metric<AmcSponsoredAdsAndDspOverlapEx>[] = [
  TOTAL_PRODUCT_SALES,
  USERS_THAT_PURCHASED,
  UNIQUE_REACH,
  TOTAL_PURCHASES,
  COST,
];

type FieldWithBarChart = "usersThatPurchased" | "uniqueReach" | "totalPurchases" | "totalProductSales" | "cost";
