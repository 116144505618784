<ng-container *transloco="let t">
  @if (strategy) {
    <div class="flex items-center gap-x-3">
      <IButton
        [icon]="strategy.state === StrategyStateEnum.ENABLED ? ICON_PLAY : ICON_PAUSE"
        [color]="strategy.state === StrategyStateEnum.ENABLED ? 'main' : 'gray'"
        variant="ghost"
        [tooltipValue]="
          strategy.state === StrategyStateEnum.ENABLED
            ? t('algo-state-cell.active_strategy_click_to_pause')
            : t('algo-state-cell.paused_strategy_click_to_activate')
        "
        [square]="true"
        [disabled]="isReadOnly"
        (onClick)="onStateChange()"
        class="!flex"
      />

      <app-fbl-mode [strategy]="strategy" [size]="FblModeSize.small" />
    </div>
  }
</ng-container>
