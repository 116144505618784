import { KeyValue } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output, Pipe, PipeTransform } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BrandAsset, Marketplace, SbCreativeType } from "@front/m19-api-client";
import { Option } from "@front/m19-ui";
import { isVideoAvailable } from "@m19-board/sponsored-brand/SBVideoActivation";
import { debounceTime, distinctUntilChanged } from "rxjs";
import {
  AMAZON_HOME_STORE_PAGE_PATH,
  FormMode,
  FormStepEvent,
  LandingPage,
  SbFormStep,
} from "../sb-strategy-form.component";

export interface SbAdFormat {
  creativeType: SbCreativeType;
  landingPage: LandingPage;
  store: BrandAsset;
}

@Component({
  selector: "app-sb-form-ad-format",
  templateUrl: "./sb-form-ad-format.component.html",
})
export class SbFormAdFormatComponent implements OnInit {
  private readonly creativeTypeOrder = [
    SbCreativeType.productCollection,
    SbCreativeType.storeSpotlight,
    SbCreativeType.video,
    SbCreativeType.brandVideo,
  ];

  readonly AD_FORMATS_DESCRIPTIONS: Record<SbCreativeType, string> = {
    [SbCreativeType.productCollection]: "sb-form-ad-format.carousel_description",
    [SbCreativeType.storeSpotlight]: "sb-form-ad-format.drive_traffic_to_a_store_including_subpages",
    [SbCreativeType.video]: "sb-form-ad-format.video_description",
    [SbCreativeType.brandVideo]: "sb-form-ad-format.brand_video_description",
  };

  @Input() formMode: FormMode;
  @Input() reviewMode = false;
  @Input() selectedBrand: BrandAsset;
  @Input() marketplace: Marketplace;

  @Input() set sbAdFormat(i: SbAdFormat) {
    this._sbAdFormat = i;

    if (this.reviewMode) {
      this.form.disable();
    }
    this.form.patchValue(this._sbAdFormat);
    if (i)
      this.storeNameControl = new FormControl({
        value: i.creativeType === SbCreativeType.storeSpotlight ? this.toPageOption(i.store)?.label : i.store?.name,
        disabled: true,
      });
  }

  _sbAdFormat: SbAdFormat;

  @Output() onFormSubmit = new EventEmitter<FormStepEvent<SbAdFormat>>();
  @Output() onFormPrevious = new EventEmitter<void>();

  @Input() set stores(s: BrandAsset[]) {
    if (!s) return;
    this._stores = s.filter((a: BrandAsset) => {
      return !a.deleted && (!this.selectedBrand || a.brandEntityId === this.selectedBrand.brandEntityId);
    });
    this.setupPages(this._stores);
    // Only set the first store if form store has not already been set before
    if (this.pageOptions.length > 0 && this.form.controls.landingPage.value !== LandingPage.Store) {
      this.form.controls.store.setValue(this.pageOptions[0].value);
    }
  }

  _stores: BrandAsset[] = [];
  pageOptions: Option<BrandAsset>[] = [];
  selectedPage: Option<BrandAsset> | undefined;
  storeOptions: Option<BrandAsset>[] = [];
  selectedStore: Option<BrandAsset> | undefined;

  form: FormGroup<Record<keyof SbAdFormat, FormControl>> = new FormGroup({
    creativeType: new FormControl<SbCreativeType>(null, [Validators.required]),
    landingPage: new FormControl<LandingPage>(null, [Validators.required]),
    store: new FormControl(null, [Validators.required]),
  });

  defaultFormValue: SbAdFormat = {
    creativeType: SbCreativeType.productCollection,
    landingPage: LandingPage.ProductListing,
    store: null,
  };

  storeNameControl: FormControl; // for review mode

  videoDisabled = false;

  ngOnInit(): void {
    this.videoDisabled = !isVideoAvailable(this.marketplace);

    this.form.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      )
      .subscribe((_) => {
        this.toggleValidators();
        this.submitForm();
      });

    if (!this._sbAdFormat) {
      this._sbAdFormat = this.defaultFormValue;
    }
    this.form.patchValue(this._sbAdFormat);

    this.form.controls.landingPage.valueChanges.subscribe((v) => {
      if (this.form.controls.creativeType.value !== SbCreativeType.storeSpotlight) {
        this.form.controls.store.setValue(this.pageOptions?.length ? this.pageOptions[0].value : null);
        this.selectedPage = this.pageOptions?.length ? this.pageOptions[0] : undefined;
      }
    });
  }

  submitForm(goNext = false) {
    if (this.form.valid && !this.reviewMode) {
      this.onFormSubmit.emit({ formData: this.form.value as SbAdFormat, step: SbFormStep.AD_FORMAT, goNext });
    }
  }

  clickAdFormat(creativeType: SbCreativeType) {
    if (this.form.controls.creativeType.value === creativeType) {
      return;
    }

    this.form.controls.creativeType.setValue(creativeType);

    if (creativeType === SbCreativeType.storeSpotlight || creativeType === SbCreativeType.brandVideo) {
      this.form.controls.landingPage.setValue(LandingPage.Store);
      this.setDefaultStoreOption(creativeType);
    } else this.form.controls.landingPage.setValue(LandingPage.ProductListing);
  }

  private setDefaultStoreOption(creativeType: SbCreativeType) {
    let options = this.pageOptions;
    if (creativeType === SbCreativeType.storeSpotlight) {
      options = this.storeOptions;
    }
    this.selectPage(options[0]);
  }

  toggleValidators() {
    if (this.form.controls.landingPage.value === LandingPage.Store) {
      this.form.controls.store.enable();
    } else {
      this.form.controls.store.disable();
    }
  }

  setupPages(stores: BrandAsset[]) {
    this.pageOptions = [];
    this.storeOptions = [];

    if (!stores || stores.length === 0) return;

    for (const s of stores) {
      this.pageOptions.push(this.toPageOption(s));

      // for store spotlight, display only home store pages
      if (
        s.name &&
        s.name.split("/")[1] === AMAZON_HOME_STORE_PAGE_PATH &&
        this.isSpotlightAvailable(s.name.split("/")[0])
      ) {
        this.storeOptions.push(this.toPageOption(s));
      }
    }
  }

  selectPage(page: Option<BrandAsset> | undefined) {
    this.selectedPage = page;
    this.form.controls.store.setValue(page?.value);
  }

  private isSpotlightAvailable(store: string) {
    const pages = this._stores.filter((o) => (o.asinList?.length ?? 0) > 0 && (o.name ?? "").split("/")[0] === store);
    // home page + 3 other store pages with asins
    return pages?.length >= 4;
  }

  toPageOption = (store: BrandAsset): Option<BrandAsset> | null => {
    if (!store) return null;
    return {
      value: store,
      label: store.name ?? "",
    };
  };

  orderAdFormat = (a: KeyValue<SbCreativeType, string>, b: KeyValue<SbCreativeType, string>): number => {
    return (
      this.creativeTypeOrder.indexOf(SbCreativeType[a.key]) - this.creativeTypeOrder.indexOf(SbCreativeType[b.key])
    );
  };

  readonly SbCreativeType = SbCreativeType;
  readonly LandingPage = LandingPage;
  readonly FormMode = FormMode;
}

@Pipe({ standalone: true, name: "creativeTypeName" })
export class CreativeTypeNamePipe implements PipeTransform {
  creativeTypeWordings: Record<SbCreativeType[keyof SbCreativeType & number], string> = {
    [SbCreativeType.productCollection]: "sb-form-ad-format.product_collection_carousel",
    [SbCreativeType.storeSpotlight]: "sb-form-ad-format.store_spotlight",
    [SbCreativeType.video]: "sb-form-creative.video",
    [SbCreativeType.brandVideo]: "sb-form-ad-format.brand_video",
  };

  transform(creativeType: SbCreativeType): string {
    return this.creativeTypeWordings[creativeType];
  }
}
