import { inject, Injectable } from "@angular/core";
import { AmcReportsApi, AmcSponsoredAdsAndDspOverlap, StatsApi } from "@front/m19-api-client";
import { AccountSelectionService } from "@front/m19-services";
import { map, Observable, switchMap } from "rxjs";
import {
  AmcSponsoredAdsAndDspOverlapEx,
  buildAmcSponsoredAdsAndDspOverlapEx,
} from "@front/m19-models/AmcSponsoredAdsAndDspOverlapEx";

@Injectable({
  providedIn: "root",
})
export class AmcStatsService {
  private readonly statsApiService = inject(StatsApi);
  private readonly amcReportsApiService = inject(AmcReportsApi);
  private readonly accountSelectionService = inject(AccountSelectionService);

  getAvailableReports() {
    return this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(
      switchMap((am) => this.amcReportsApiService.getAmcReports(am)),
    );
  }

  getMonthlyAmcSponsoredAdsAndDspOverlapStats(date: string): Observable<AmcSponsoredAdsAndDspOverlapEx[]> {
    return this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(
      switchMap((am) =>
        this.statsApiService.getMonthlyAmcSponsoredAdsAndDspOverlapStats({
          ...am,
          minDate: date,
          maxDate: date,
        }),
      ),
      map(this.convertToAmcSponsoredAdsAndDspOverlapExtended),
    );
  }

  private convertToAmcSponsoredAdsAndDspOverlapExtended(
    amcSponsoredAdsAndDspOverlap: AmcSponsoredAdsAndDspOverlap[],
  ): AmcSponsoredAdsAndDspOverlapEx[] {
    return amcSponsoredAdsAndDspOverlap.map(buildAmcSponsoredAdsAndDspOverlapEx);
  }
}
