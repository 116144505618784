<div class="relative flex max-h-screen min-h-screen w-full overflow-y-auto" *transloco="let t">
  <div
    [style.background]="isWhitelabel ? '' : 'radial-gradient(circle at 50% 50%, #00835c, #444bf700)'"
    class="absolute left-1/2 top-1/2 h-[min(100vh,60rem)] w-[min(100vw,60rem)] -translate-x-1/2 -translate-y-1/2 transform animate-pulse opacity-30 blur-3xl dark:opacity-70"
  ></div>
  <div
    class="z-10 m-auto mx-auto flex w-full border-y bg-white p-4 sm:w-[min(100%,40rem)] sm:rounded-xl sm:border sm:border-gray-200 sm:shadow-lg dark:border-gray-700 dark:bg-gray-800 dark:sm:border-gray-700"
  >
    <div class="w-full bg-inherit p-3">
      <router-outlet />
    </div>
  </div>
</div>
