@if (_keywords.length !== 0) {
  <div class="card-perso collapse-card left-child right-child card mb-0>" *transloco="let t">
    <div class="flex items-center justify-between pl-3">
      <div class="flex flex-1 items-center">
        <ICheckbox
          class="mr-2"
          [value]="nbSelectedKw === filteredKeywords.length"
          (valueChange)="nbSelectedKw !== filteredKeywords.length ? selectAllkeywords() : unselectAllkeywords()"
        />
        @if (nbSelectedKw > 0) {
          <IButton
            color="red"
            variant="ghost"
            (onClick)="deleteKeywords()"
            [tooltipValue]="t('keyword-segment-modal.delete_keywords', { count: nbSelectedKw })"
            [icon]="ICON_TRASH"
          />
        }
        <input
          type="text"
          class="search-bar simple-input form-control ml-3 mr-2"
          placeholder="{{ t('keyword-segment-modal.search_by_keywords') }}"
          (input)="setFilter(getValueFromInputEvent($event))"
        />
      </div>
      <app-export-button tooltipValue="{{ t('common.export_as_csv') }}" (export)="downloadKeywords()" />
    </div>
    @for (kw of shownKeywords; track kw.item.targetingValue) {
      <div class="mb-2">
        <app-keyword-item
          [keyword]="kw"
          [selected]="kw.isSelected"
          (keywordSelected)="updateSelect(kw)"
          [isLast]="$last"
          [isSelectable]="isSelectable"
        />
      </div>
    } @empty {
      {{ t("keywords-list.no_keyword_found") }}
    }
    @if (filteredKeywords.length > itemsShown) {
      <mat-paginator
        class="[&_*]:!text-sm"
        [pageSizeOptions]="[]"
        [hidePageSize]="true"
        [pageSize]="itemsShown"
        [pageIndex]="pageEvent.pageIndex"
        [length]="filteredKeywords.length"
        (page)="changeContent($event)"
        showFirstLastButtons
      />
    }
  </div>
}
