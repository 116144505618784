import { Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AsinService, InventoryService } from "@front/m19-services";
import { combineLatest } from "rxjs";
import { Marketplace } from "@front/m19-api-client";

@UntilDestroy()
@Component({
  selector: "app-low-inventory-warning",
  template: "@if (pausedAsins.length > 0) {<div class='alert alert-warning' role='alert'>{{ pausedMsg }}</div>}",
})
export class LowInventoryWarningComponent implements OnInit {
  @Input()
  accountId: string;

  @Input()
  marketplace: Marketplace;

  @Input()
  asins: string[];

  pausedAsins = [];
  pausedMsg: string;

  constructor(
    private asinService: AsinService,
    private inventoryService: InventoryService,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.inventoryService.getInventoryStats(this.accountId, this.marketplace),
      this.asinService.getInventoryRules(this.accountId, this.marketplace),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([inventoryStats, inventoryRules]) => {
        const asinsSet = new Set(this.asins);
        this.pausedAsins = [];
        for (const stat of inventoryStats) {
          if (asinsSet.has(stat.asin) && inventoryRules.execute(stat.asin, stat).shouldPauseAdvertising) {
            this.pausedAsins.push(stat.asin);
          }
        }
        this.pausedMsg = this.getPausedMsg();
      });
  }

  private getPausedMsg() {
    return (
      `${this.pausedAsins.length} ` +
      (this.pausedAsins.length > 1 ? "ASINs have" : "ASIN has") +
      " been automatically paused because of low inventory: " +
      this.prettyPrintAsinList(this.pausedAsins)
    );
  }

  private prettyPrintAsinList(asins: string[]) {
    const maxAsins = 5;
    let message = asins.slice(0, maxAsins).join(", ");
    if (asins.length > maxAsins) {
      message += "…";
    }
    return message;
  }
}
