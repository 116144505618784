import { Component, OnInit, signal, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortModule } from "@angular/material/sort";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { DataSharingService, OrganizationAccountGroupService, UserSelectionService } from "@front/m19-services";
import { IBadgeComponent, ISelectComponent, Option } from "@front/m19-ui";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { saveAs } from "file-saver";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, filter, Subject, switchMap, tap } from "rxjs";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { AccessLevel, Marketplace, SharedReportType } from "@front/m19-api-client";
import { OrganizationAccountGroups, SharedReportEx, SharedReportTypeEx, SharedReportTypes } from "@front/m19-models";

type ReportFilter = {
  marketplace?: Marketplace | null;
  reportType?: SharedReportTypeEx;
};

@UntilDestroy()
@Component({
  selector: "app-data-sharing",
  templateUrl: "./data-sharing.component.html",
  styleUrls: ["./data-sharing.component.scss"],
  standalone: true,
  imports: [TranslocoDirective, ISelectComponent, IBadgeComponent, MatTableModule, MatSortModule, MatPaginator],
})
export class DataSharingComponent implements OnInit {
  readonly DisplayedColumns = ["reportType", "marketplace", "date", "downloadLink"];
  organizations?: OrganizationAccountGroups[];

  private readonly selectedOrganization$ = new Subject<OrganizationAccountGroups>();
  private readonly reportFilter$ = new BehaviorSubject<ReportFilter>({});
  sharedReports: SharedReportEx[] = [];
  readonly sharedReportTypes = new Map<SharedReportTypeEx, number>();
  readonly marketplaces = new Map<Marketplace, number>();
  readonly AccessLevel = AccessLevel;

  readonly dataSource = new MatTableDataSource<SharedReportEx>();

  @ViewChild("paginator", { static: false }) set paginator(value: MatPaginator) {
    if (this.dataSource) this.dataSource.paginator = value;
  }

  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = ms;
  }

  reportTypeFilter?: SharedReportTypeEx;
  marketplaceFilter?: Marketplace;

  organizationOptions: Option<OrganizationAccountGroups>[] = [];
  selectedOrganization = signal<Option<OrganizationAccountGroups> | undefined>(undefined);

  reportTypeOptions: Option<SharedReportTypeEx | null>[] = [];
  selectedReportType = signal<Option<SharedReportTypeEx | null> | undefined>(undefined);

  marketplaceOptions: Option<Marketplace | null>[] = [];
  selectedMarketplace = signal<Option<Marketplace | null> | undefined>(undefined);

  constructor(
    private accountGroupService: OrganizationAccountGroupService,
    private dataSharingService: DataSharingService,
    private userSelectionService: UserSelectionService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.reportTypeOptions = [{ value: null, label: this.translocoService.translate("data-sharing.all_reports") }];
    for (const k in SharedReportTypes) {
      this.reportTypeOptions.push({
        value: SharedReportTypes[k as SharedReportType]!,
        label: this.translocoService.translate(SharedReportTypes[k as SharedReportType].displayName),
      });
    }
    this.selectedReportType.set(this.reportTypeOptions[0]!);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property): string | number => {
      if (property == "reportType") {
        return item.reportTypeEx.displayName;
      }
      if (property == "marketplace") {
        return item.marketplace!;
      }
      if (property == "date") {
        return item.date!;
      }
      return 0;
    };

    this.accountGroupService.allOrganizationAccountGroups$.pipe(untilDestroyed(this)).subscribe((organizations) => {
      this.organizations = organizations;
      this.organizations?.sort((a, b) => (a.datahubAccess == b.datahubAccess ? a.id - b.id : a.datahubAccess ? -1 : 1));

      this.organizationOptions =
        organizations?.map((o) => ({
          value: o,
          label: o.organizationName,
          disabled: o.accessLevel !== AccessLevel.ADMIN,
        })) ?? [];
    });
    this.selectedOrganization$.pipe(untilDestroyed(this)).subscribe((o) => {
      this.selectedOrganization.set({ value: o, label: o.organizationName });
    });
    this.selectedOrganization$
      .pipe(
        tap(() => {
          this.sharedReports = [];
          this.sharedReportTypes.clear();
          this.marketplaces.clear();
          this.dataSource.data = [];
          this.reportFilter$.next({});
        }),
        untilDestroyed(this),
        filter((o) => o.datahubAccess && o.accessLevel == AccessLevel.ADMIN),
        switchMap((o) =>
          combineLatest<[SharedReportEx[], ReportFilter, string[]]>([
            this.dataSharingService.listSharedReports(o.id).pipe(
              tap((r) => {
                for (const report of r) {
                  const n = this.sharedReportTypes.get(report.reportTypeEx) ?? 0;
                  this.sharedReportTypes.set(report.reportTypeEx, n + 1);
                  const m = this.marketplaces.get(report.marketplace!) ?? 0;
                  this.marketplaces.set(report.marketplace!, m + 1);
                }

                this.marketplaceOptions = [
                  { value: null, label: this.translocoService.translate("data-sharing.all_marketplaces") },
                ];
                for (const [k, v] of this.marketplaces) {
                  if (k) {
                    this.marketplaceOptions.push({ value: k, label: k });
                  }
                }
                if (this.marketplaceOptions[0]) this.selectedMarketplace.set(this.marketplaceOptions[0]);
              }),
            ),
            this.reportFilter$,
            this.userSelectionService.dateRange$,
          ]),
        ),
      )
      .subscribe({
        next: ([r, reportFilter, dateRange]) => {
          this.sharedReports = r;
          this.dataSource.data = this.filterData(r, reportFilter, dateRange);
        },
        error: (e) => {
          this.toastrService.error(e, this.translocoService.translate("data-sharing.shared_reports"));
        },
      });
    this.reportFilter$.pipe(untilDestroyed(this)).subscribe((reportFilter) => {
      this.reportTypeFilter = reportFilter.reportType;
      this.marketplaceFilter = reportFilter.marketplace ?? undefined;
    });
  }

  private filterData(reports: SharedReportEx[], filter: ReportFilter, dateRange: string[]) {
    const result = [];
    for (const report of reports) {
      // Because of stats, maxDatePicker is upperbound to yesterday, if max date is yesterday, also include today
      const maxDatePickerDate = new Date();
      maxDatePickerDate.setDate(maxDatePickerDate.getDate() - 1);
      let maxDate = dateRange[1];
      if (maxDate == maxDatePickerDate.toISOString().substring(0, 10)) {
        maxDatePickerDate.setDate(maxDatePickerDate.getDate() + 1);
        maxDate = maxDatePickerDate.toISOString().substring(0, 10);
      }
      if (report.date!.localeCompare(dateRange[0]) < 0 || report.date!.localeCompare(maxDate) > 0) {
        continue;
      }
      if (filter.marketplace && report.marketplace != filter.marketplace) {
        continue;
      }
      if (filter.reportType && report.reportTypeEx != filter.reportType) {
        continue;
      }
      result.push(report);
    }
    return result;
  }

  selectOrganization(organization: Option<OrganizationAccountGroups>) {
    this.selectedOrganization.set(organization);
    this.selectedOrganization$.next(organization.value);
  }

  selectReportTypeFilter(reportType: Option<SharedReportTypeEx | null>) {
    this.selectedReportType.set(reportType);
    const reportTypeEx = reportType.value as unknown as SharedReportTypeEx;
    this.reportFilter$.next({ marketplace: this.marketplaceFilter, reportType: reportTypeEx });
  }

  selectMarketplaceFilter(marketplace: Option<Marketplace | null>) {
    this.selectedMarketplace.set(marketplace);
    this.reportFilter$.next({ marketplace: marketplace.value, reportType: this.reportTypeFilter });
  }

  downloadReport(report: SharedReportEx) {
    this.dataSharingService
      .downloadReport(report)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: Blob) => {
          saveAs(data, report.fileName);
        },
        error: (e) => {
          this.toastrService.error(e, this.translocoService.translate("data-sharing.report_download"));
        },
      });
  }
}
