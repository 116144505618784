<div class="box card border-shadow mt-2 px-4 py-4" *transloco="let t">
  <div class="flex justify-between">
    <span class="search bar pl-0" style="width: 40%">
      <span class="search-bar input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <fa-icon [icon]="faSearch"></fa-icon>
          </span>
        </div>
        <input
          type="text"
          class="search-bar simple-input-prepend form-control"
          placeholder="{{ t('inventory-table.search_placeholder') }}"
          [ngModel]="productsFilter"
          (ngModelChange)="changeProductsFilter($event)"
        />
      </span>
    </span>
    <div>
      <span class="float-right ml-2">
        <button
          class="btn btn-ghost-secondary pull-right"
          matTooltip="{{ t('inventory-table.tooltip_more_option') }}"
          [matMenuTriggerFor]="menu"
        >
          <fa-icon [icon]="faEllipsis"></fa-icon>
        </button>
        <mat-menu #menu="matMenu" yPosition="above">
          <button mat-menu-item (click)="toggleStockValue()">
            {{
              displayStockValue ? t("inventory-table.stock_quantity_button") : t("inventory-table.stock_value_button")
            }}
          </button>
          <button mat-menu-item (click)="setupRuleForAllAsins()" [disabled]="isReadOnly">
            {{ t("inventory-table.pause_add_limit", [getNumberOfAsins()]) }}
          </button>
        </mat-menu>
      </span>
      <span class="float-right ml-2">
        <app-export-button (export)="downloadFile()" matTooltip="{{ t('common.export_as_csv') }}"></app-export-button>
      </span>
    </div>
  </div>
  <div class="table-container">
    <mat-table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="responsive-table table-striped-two table-hover mt-4"
      multiTemplateDataRows
    >
      <ng-container matColumnDef="asinStockDetailExpandBtn">
        <mat-header-cell *matHeaderCellDef class="flex-half"></mat-header-cell>
        <mat-cell *matCellDef="let product" class="flex-half"> </mat-cell>
      </ng-container>
      <ng-container matColumnDef="productImg">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="ASIN / SKU"
          matTooltipPosition="above"
          class="flex-3"
        >
          <div class="flex flex-wrap">
            <fa-icon [icon]="faShoppingCart" class="mr-1"></fa-icon>{{ t("common.product") }}
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let product" class="flex-3 px-2">
          <div class="flex-xl-row div-product-img flex items-center gap-2">
            <app-product-thumbnail
              class="div-product-img"
              [product]="product"
              [smallImg]="true"
              [fbaBadge]="
                product.fulfillmentChannel === FulfillmentChannel.Amazon ||
                product.fulfillmentChannel === FulfillmentChannel.Both
              "
              [fbmBadge]="
                product.fulfillmentChannel === FulfillmentChannel.Merchant ||
                product.fulfillmentChannel === FulfillmentChannel.Both
              "
            ></app-product-thumbnail>
            @if (!product.sku) {
              <app-asin-link [asin]="product.asin" [marketplace]="marketplace"> </app-asin-link>
            }
            @if (product.sku) {
              <span matTooltip="FnSKU: {{ product.fnSku ?? 'unknown' }}" class="ml-1 mt-1">{{ product.sku }}</span>
            }
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="productTitle">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="{{ t('common.title') }}"
          matTooltipPosition="above"
          class="flex-2"
          >{{ t("common.title") }}</mat-header-cell
        >
        <mat-cell
          *matCellDef="let product"
          matTooltip="{{ product.title }}"
          matTooltipClass="sensitive-data"
          class="flex-2"
        >
          <span class="sensitive-data truncate">{{ product.title }}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="productPrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ t("inventory-table.price_header") }}</mat-header-cell>
        <mat-cell *matCellDef="let product">
          {{ product.price | currency: currency : "symbol" : "1.2-2" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="strategies">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="{{ t('inventory-table.strategie_header') }}"
          matTooltipPosition="above"
        >
          @if (smallMode) {
            <span>{{ t("inventory-table.strategy_header_small") }}</span>
          }
          @if (!smallMode) {
            <span>{{ t("inventory-table.strategie_header") }}</span>
          }
        </mat-header-cell>
        <mat-cell *matCellDef="let product">
          <div
            (click)="$event.stopPropagation(); openInfo(product.asin)"
            [ngClass]="getActiveStrategies(product.asin) > 0 ? 'positive' : 'zero'"
            data-toggle="tooltip"
            data-placement="bottom"
            class="number pointer mx-auto"
            matTooltip="{{
              t('inventory-table.number_activated_strategies', { count: getActiveStrategies(product.asin) })
            }}"
          >
            {{ getActiveStrategies(product.asin) }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="availableStockValue">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="Available inventory value"
          matTooltipPosition="above"
        >
          @if (smallMode) {
            <span>{{ t("inventory-table.available_header_small") }}</span>
          }
          @if (!smallMode) {
            <span>{{ t("inventory-table.available_header") }}</span>
          }
        </mat-header-cell>
        <mat-cell *matCellDef="let product">
          <div matTooltip="{{ product | fulfillmentNetworkStockFormatPipe: locale : currency : true }}">
            {{
              FULFILLABLED_STOCK_VALUE
                | metricFormatPipe
                  : (Number.isNaN(product.fulfillableQuantityValue + product.fbmStockValue)
                      ? 0
                      : product.fulfillableQuantityValue + product.fbmStockValue)
                  : locale
                  : currency
                  : undefined
                  : smallMode
            }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="availableStock">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="{{ t('inventory-table.tooltip_available_inventory') }}"
          matTooltipPosition="above"
        >
          @if (smallMode) {
            <span>{{ t("inventory-table.available_header_small") }}</span>
          }
          @if (!smallMode) {
            <span>{{ t("inventory-table.available_header") }}</span>
          }
        </mat-header-cell>
        <mat-cell *matCellDef="let product">
          <div matTooltip="{{ product | fulfillmentNetworkStockFormatPipe: locale : currency : false }}">
            {{
              FULFILLABLE_STOCK
                | metricFormatPipe
                  : product.fulfillableQuantity + product.fbmStock
                  : locale
                  : currency
                  : undefined
                  : smallMode
            }}
          </div>
        </mat-cell>
      </ng-container>

      @for (metric of metrics; track metric) {
        <ng-container [matColumnDef]="metric.id">
          <mat-header-cell *matHeaderCellDef mat-sort-header [matTooltip]="metric.tooltip" matTooltipPosition="above">
            @if (smallMode) {
              <span> {{ t("metrics." + metric.id + "_titleSmall") }}</span>
            }
            @if (!smallMode) {
              <span> {{ t("metrics." + metric.id + "_title") }}</span>
            }
          </mat-header-cell>
          <mat-cell *matCellDef="let product">
            <div matTooltip="{{ metric | metricFormatPipe: product : locale : currency }}">
              {{ metric | metricFormatPipe: product : locale : currency : undefined : smallMode }}
            </div>
          </mat-cell>
        </ng-container>
      }

      <ng-container matColumnDef="estimatedDaysOfStock">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="{{ t('inventory-table.estimated_stock_day') }}"
          matTooltipPosition="above"
        >
          @if (smallMode) {
            <span>{{ t("inventory-table.componentest_days_of_stock_small") }}</span>
          }
          @if (!smallMode) {
            <span>{{ t("inventory-table.estimated_stock_day") }}</span>
          }
        </mat-header-cell>
        <mat-cell *matCellDef="let product">
          <div [ngClass]="(product | executeRules: inventoryRules).detail + '-stock'" class="stock-days w-full">
            {{ formatNumber(product.estimatedDaysOfStock) }}
            @if ((product | executeRules: inventoryRules).shouldPauseAdvertising) {
              <span matTooltip="{{ t('inventory-table.advertising_paused_tooltip') }}">
                <fa-icon [icon]="faPause" size="lg" class="Pause"></fa-icon>
              </span>
            }
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="advertisingAction">
        <mat-header-cell
          *matHeaderCellDef
          matTooltip="{{ t('inventory-table.automatic_pause_tooltip') }}"
          matTooltipPosition="above"
          class="flex-2 text-center"
          >{{ t("inventory-table.pause_advertising_threshold") }}</mat-header-cell
        >
        <mat-cell *matCellDef="let product" class="flex-2 flex items-center">
          @if (product.asin | asinHasInventoryRule: inventoryRules) {
            <div class="ml-2">
              &lt; {{ (product.asin | asinHasInventoryRule: inventoryRules).advertisingPauseThreshold }} days of stock
              @if (product | asinInelligilibleForInventoryRule) {
                <fa-icon
                  class="warning"
                  [icon]="faWarning"
                  matTooltip="{{ t('inventory-table.pause_threshold_tooltip') }}"
                ></fa-icon>
              }
            </div>
          }
          @if (!(product.asin | asinHasInventoryRule: inventoryRules)) {
            <div class="ml-2">None</div>
          }

          <div
            class="tooltip-wrapper ml-2"
            matTooltip="{{ t('inventory-table.treshold_setup_tooltip', { asin: product.asin }) }}"
          >
            <a
              (click)="setupRuleOnAsin(product); $event.stopPropagation()"
              class="threshold-edit"
              [ngStyle]="isReadOnly ? { 'pointer-events': 'none' } : {}"
              [ngClass]="isReadOnly ? 'text-gray-800' : 'text-primary'"
            >
              <fa-icon size="lg" [icon]="faPencil"></fa-icon>
            </a>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="header"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns; let i = dataIndex"></mat-row>
    </mat-table>
  </div>
  <mat-paginator
    [pageSizeOptions]="[5, 10, 50, 100]"
    [pageSize]="10"
    showFirstLastButtons
    style="margin-bottom: 2rem"
    [hidden]="dataSource.data && dataSource.data.length === 0"
  ></mat-paginator>

  <app-spinner [display]="loading"></app-spinner>
</div>
