import { Component, Input } from "@angular/core";
import { Observable } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import moment from "moment-timezone";
import { TrackedSearchTerm } from "../product-tracker.component";
import { AccountMarketplace } from "@front/m19-api-client";
import { Utils, UtilsFractile } from "@front/m19-utils";
import { Marketplaces } from "@front/m19-models";
import { RankOption } from "@front/m19-services";

type BucketizedRanking = {
  rank: number;
  bucket: number;
};

type RankingDataForDate = {
  organic: BucketizedRanking;
  sponsored: BucketizedRanking;
  global: BucketizedRanking;
};

type SearchTermRankingDataRow = {
  searchTerm: string;
  timeline: {
    [date: string]: RankingDataForDate;
  };
};

@UntilDestroy()
@Component({
  selector: "app-product-tracker-keyword-table",
  templateUrl: "./product-tracker-keyword-table.component.html",
  styleUrls: [
    "./product-tracker-keyword-table.component.scss",
    "../../../keyword-tracker/keyword-tracking-timeline/keyword-tracking-timeline-table/keyword-tracking-timeline-table.component.scss",
  ],
})
export class ProductTrackerKeywordTableComponent {
  @Input() set searchTermRanks(ranks: TrackedSearchTerm[]) {
    this._searchTermRanks = ranks;
    this.computeBucketData(this._searchTermRanks, this._accountMarketplace);
  }

  @Input() set accountMarketplace(am: AccountMarketplace) {
    this._accountMarketplace = am;
    this.computeBucketData(this._searchTermRanks, this._accountMarketplace);
  }

  @Input() set rankOption(o: RankOption) {
    this._rankOption = o;
  }

  @Input() loading$: Observable<boolean>;

  _searchTermRanks: TrackedSearchTerm[];
  _accountMarketplace: AccountMarketplace;
  _rankOption: RankOption;

  tableData: SearchTermRankingDataRow[] = [];
  dateRange: string[];

  computeBucketData(ranks: TrackedSearchTerm[], am: AccountMarketplace) {
    if (!am) return;
    let minDate: number;
    let maxDate: number;
    const tz = Marketplaces[am.marketplace].timeZone;
    const data: SearchTermRankingDataRow[] = [];

    // all ranks to compute buckets
    const organicRanks = [];
    const sponsoredRanks = [];
    const globalRanks = [];

    for (const searchTermRank of ranks) {
      const row: SearchTermRankingDataRow = {
        searchTerm: searchTermRank.searchTerm,
        timeline: {},
      };
      const organicRankPerDay = new Map<string, number[]>();
      const sponsoredRankPerDay = new Map<string, number[]>();
      const globalRankPerDay = new Map<string, number[]>();
      for (const rank of searchTermRank.timeline) {
        if (minDate === undefined || rank.timestamp < minDate) minDate = rank.timestamp;
        if (maxDate === undefined || rank.timestamp > maxDate) maxDate = rank.timestamp;
        const day = moment
          .utc(rank.timestamp * 1000)
          .tz(tz)
          .startOf("day")
          .format();
        if (!organicRankPerDay.has(day)) {
          organicRankPerDay.set(day, []);
          sponsoredRankPerDay.set(day, []);
          globalRankPerDay.set(day, []);
        }
        if (rank.organic > 0) {
          organicRankPerDay.get(day).push(rank.organic);
        }
        if (rank.sp > 0) {
          sponsoredRankPerDay.get(day).push(rank.sp);
        }
        if (rank.global > 0) {
          globalRankPerDay.get(day).push(rank.global);
        }
      }
      // aggregate the median per day
      for (const day of organicRankPerDay.keys()) {
        const organicMedian = Utils.median(organicRankPerDay.get(day), (x) => x);
        const sponsoredMedian = Utils.median(sponsoredRankPerDay.get(day), (x) => x);
        const globalMedian = Utils.median(globalRankPerDay.get(day), (x) => x);
        if (organicMedian > 0) {
          organicRanks.push(organicMedian);
        }
        if (sponsoredMedian > 0) {
          sponsoredRanks.push(sponsoredMedian);
        }
        if (globalMedian > 0) {
          globalRanks.push(globalMedian);
        }
        row.timeline[day] = {
          organic: { rank: organicMedian, bucket: undefined },
          sponsored: { rank: sponsoredMedian, bucket: undefined },
          global: { rank: globalMedian, bucket: undefined },
        };
      }
      data.push(row);
    }
    this.dateRange = Utils.getDateRange(minDate * 1000, maxDate * 1000, tz);
    // compute decile buckets
    const organicDeciles = UtilsFractile.getFractileArray(organicRanks, 10);
    const sponsoredDeciles = UtilsFractile.getFractileArray(sponsoredRanks, 10);
    const globalDeciles = UtilsFractile.getFractileArray(globalRanks, 10);
    for (const row of data) {
      for (const [, rank] of Object.entries(row.timeline)) {
        rank.organic.bucket = 9 - UtilsFractile.getFractileBucket(organicDeciles, rank.organic.rank);
        rank.sponsored.bucket = 9 - UtilsFractile.getFractileBucket(sponsoredDeciles, rank.sponsored.rank);
        rank.global.bucket = 9 - UtilsFractile.getFractileBucket(globalDeciles, rank.global.rank);
      }
    }
    this.tableData = data;
  }

  formatDate(date: string, withYear = false) {
    const toTimestamp = Date.parse(date);
    const format = withYear ? "MM/DD/Y" : "MM/D";
    return moment.utc(toTimestamp).tz(Marketplaces[this._accountMarketplace.marketplace].timeZone).format(format);
  }

  protected readonly RankOption = RankOption;
}
