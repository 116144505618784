<ng-container *transloco="let t">
  <app-notification-card [notification]="notification" [small]="small">
    <span title titleSmall>{{ t("notification-card-deleted-brand-asset.invalid_brand_asset", [strategy.name]) }}</span>
    <span action>
      <div class="mt-1">
        {{ t("notification-card-deleted-brand-asset.more_details_on_the") }}
        <b
          ><a class="alert-link" [href]="'/strategies/sponsored-brands/' + notification.strategyId">{{
            t("notification-card-deleted-brand-asset.startegy_settings")
          }}</a></b
        >
      </div>
    </span>
  </app-notification-card>
</ng-container>
