<div class="modal-header" *transloco="let t">
  <h4 class="modal-title pull-left">{{ t("remove-audience-popup.remove_audience_targeting_from_strategy") }}</h4>
</div>
<div class="modal-body" *transloco="let t">
  <p>
    {{ t("remove-audience-popup.delete_confirm", [audience!.expressionType, audience!.matchType, audience!.lookback]) }}
  </p>
</div>
<div class="modal-footer" *transloco="let t">
  <IButton label="{{ t('common.cancel') }}" color="gray" variant="ghost" (onClick)="bsModalRef.hide()" />
  <IButton label="{{ t('common.delete') }}" color="red" (onClick)="delete()" />
</div>
