// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * state of an account. * OFF: the account is ignored by m19. * NEW: newly discovered account are created in this state. It is technically the same as OFF, excepts that a notification is displayed on m19 board to ask user whether the account should be activated. * DOWNLOADER_ON: account\'s data is pulled from Amazon\'s API but not optimization is performed. * BIDDER_ON: the account is optimized by m19. 
 * @export
 * @enum {string}
 */
export enum AccountState {
    OFF = 'OFF',
    NEW = 'NEW',
    DOWNLOADER_ON = 'DOWNLOADER_ON',
    BIDDER_ON = 'BIDDER_ON',
    PAYMENT_ISSUE = 'PAYMENT_ISSUE'
}

