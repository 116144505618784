import { AsyncPipe, formatDate, NgClass } from "@angular/common";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AccountSelectionService, AmazonSearchUrlPipe, AuthService, BrandAnalyticsService } from "@front/m19-services";
import { AddToSegmentModalComponent } from "@m19-board/segments/add-to-segment-modal.component";
import { KeywordSegmentModalComponent } from "@m19-board/segments/keyword-segment-modal.component";
import { utc } from "moment-timezone";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { BehaviorSubject, combineLatest } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from "rxjs/operators";
import { PALETTE } from "../../models/Metric";
import { SimpleDataset } from "../../models/SimpleDataset";
import { brandAnalyticsAvailableFor } from "../BrandAnalyticsAvailability";
import { searchVolumeEstimationFor } from "../SearchVolumeEstimation";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { DateAggregation } from "@front/m19-utils";
import { AccountMarketplace, Marketplace, SearchTermRank } from "@front/m19-api-client";
import { SegmentConfigType } from "@front/m19-models";
import { Point } from "chart.js";
import { SearchTermCardComponent } from "@m19-board/brand-analytics/search-term-card/search-term-card.component";
import { BsDropdownDirective } from "ngx-bootstrap/dropdown";
import { DateAggreationComponent } from "@m19-board/shared/switch-button/date-aggregation-switch-button.component";
import { BaseChartDirective } from "ng2-charts";
import { MatTooltip } from "@angular/material/tooltip";
import { ICON_ADD, ICON_ADD_CIRCLE } from "@m19-board/utils/iconsLabels";
import { IButtonComponent } from "@front/m19-ui";

const autocomplete = (selector: any) => (source$: any) =>
  source$.pipe(switchMap((...args) => selector(...args).pipe(takeUntil(source$))));

@UntilDestroy()
@Component({
  selector: "app-search-term-ranks",
  templateUrl: "./search-term-ranks.component.html",
  styleUrls: ["./search-term-ranks.component.scss"],
  standalone: true,
  imports: [
    TranslocoDirective,
    SearchTermCardComponent,
    BsDropdownDirective,
    DateAggreationComponent,
    AsyncPipe,
    BaseChartDirective,
    MatPaginator,
    MatTableModule,
    MatTooltip,
    AmazonSearchUrlPipe,
    NgClass,
    IButtonComponent,
  ],
})
export class SearchTermRanksComponent implements OnInit {
  readonly aggregationsAvailable = [DateAggregation.daily, DateAggregation.monthly];

  readonly palette: string[] = PALETTE;
  readonly searchVolumeEstimationFor = searchVolumeEstimationFor;

  @ViewChild("searchTerm", { static: false }) searchTermInput: ElementRef;

  @ViewChild("topSearchTermsPaginator", { static: false }) set keywordPaginator(value: MatPaginator) {
    if (this.topTermsDataSource) this.topTermsDataSource.paginator = value;
  }

  topTermsDataSource = new MatTableDataSource<SearchTermRank>([]);

  marketplace!: Marketplace;
  searchTerms: string[] = [];
  searchTermRanks: Map<string, number> = new Map<string, number>();
  showSearchTerms: BehaviorSubject<Map<string, boolean>> = new BehaviorSubject<Map<string, boolean>>(
    new Map<string, boolean>(),
  );
  isMarketplaceSupported!: boolean;
  inputSearchTermOn = false;
  dataset: SimpleDataset = new SimpleDataset(true, 3);
  locale!: string;

  inputValue: BehaviorSubject<string> = new BehaviorSubject<string>("");
  aggregation$ = this.brandAnalyticsService.aggregation$;
  suggestions: SearchTermRank[] = [];

  selectedAccountMarketplace?: AccountMarketplace; // used to add search terms to config

  constructor(
    private authService: AuthService,
    private brandAnalyticsService: BrandAnalyticsService,
    private modalService: BsModalService,
    private accountSelectionService: AccountSelectionService,
    searchTermInput: ElementRef,
    private translocoService: TranslocoService,
  ) {
    this.searchTermInput = searchTermInput;
  }

  ngOnInit(): void {
    this.brandAnalyticsService.selectedMarketplace$.pipe(untilDestroyed(this)).subscribe((m) => {
      this.brandAnalyticsService.initializeSearchTermsSelection(m);
      this.marketplace = m;
      this.isMarketplaceSupported = brandAnalyticsAvailableFor(m);
    });
    this.brandAnalyticsService
      .getTopSearchTerms()
      .pipe(untilDestroyed(this))
      .subscribe((data) => (this.topTermsDataSource.data = data));
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => (this.locale = user.locale));
    this.inputValue
      .pipe(
        filter((input) => input.length > 2),
        debounceTime(500),
        distinctUntilChanged(),
        autocomplete((searchTerm: string) => this.brandAnalyticsService.getSearchTermsAutoCompletion(searchTerm)),
        untilDestroyed(this),
      )
      .subscribe((suggestions) => (this.suggestions = suggestions as SearchTermRank[]));

    combineLatest([this.brandAnalyticsService.getSearchRanks(), this.showSearchTerms])
      .pipe(untilDestroyed(this))
      .subscribe(([[ranks, searchTerms, range, aggregation], showSearchTerms]) => {
        const isSearchVolume =
          aggregation === DateAggregation.monthly && searchVolumeEstimationFor(1, this.marketplace) !== null;
        const title = isSearchVolume
          ? this.translocoService.translate("search-term-ranks.estimated_search_volume")
          : this.translocoService.translate("search-term-ranks.search_frequency_rank");
        if (isSearchVolume) {
          ranks = ranks.map((arr) => (arr ?? []).map((rank) => searchVolumeEstimationFor(rank, this.marketplace) ?? 0));
        }
        this.searchTerms = searchTerms;
        this.dataset.buildDataSet(
          title,
          ranks as unknown as number | Point[][],
          this.getDateStringsFrom(range, aggregation),
          this.searchTerms,
          showSearchTerms,
          !isSearchVolume,
        );
        this.searchTerms.forEach((term) => {
          this.brandAnalyticsService.getSearchTermsAutoCompletion(term).subscribe((results) => {
            this.searchTermRanks.set(
              term,
              results.find((result) => result.searchTerm == term)?.searchFrequencyRank ?? 0,
            );
          });
        });
      });
    combineLatest([
      this.brandAnalyticsService.selectedMarketplace$,
      this.accountSelectionService.singleAccountMarketplaceSelection$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([marketplace, am]) => {
        if (!am || am.marketplace != marketplace) {
          this.selectedAccountMarketplace = undefined;
        } else {
          this.selectedAccountMarketplace = am;
        }
      });
  }

  addSearchTerm(searchTerm: string): void {
    if (this.searchTerms.length >= 5) return;
    setTimeout(() => {
      this.brandAnalyticsService.addSearchTerm(searchTerm, this.marketplace);
      const showSearchTerms = this.showSearchTerms.getValue();
      this.showSearchTerms.next(showSearchTerms.set(searchTerm, true));
      this.inputValue.next("");
      this.inputSearchTermOn = false;
      this.suggestions = [];
    }, 200);
  }

  toggleSearchTerm(searchTerm: string): void {
    const showSearchTerms = this.showSearchTerms.getValue();
    showSearchTerms.set(searchTerm, showSearchTerms.get(searchTerm) === false);
    this.showSearchTerms.next(showSearchTerms);
  }

  deleteSearchTerm(searchTerm: string): void {
    this.brandAnalyticsService.removeSearchTerm(searchTerm, this.marketplace);
    const showSearchTerms = this.showSearchTerms.getValue();
    showSearchTerms.delete(searchTerm);
    this.showSearchTerms.next(showSearchTerms);
  }

  setFocusInSearchTermInput(): void {
    this.inputSearchTermOn = true;
    setTimeout(() => this.searchTermInput.nativeElement.focus(), 100);
  }

  setFocusOutSearchTermInput(): void {
    setTimeout(() => {
      this.inputValue.next("");
      this.inputSearchTermOn = false;
      this.suggestions = [];
    }, 200);
  }

  private getDateStringsFrom(range: string[], aggregation: DateAggregation): string[] {
    let startDate = utc(range[0]);
    let endDate = utc(range[1]);
    const result = [];
    switch (aggregation) {
      case DateAggregation.daily:
        while (startDate <= endDate) {
          result.push(formatDate(startDate.format("YYYY-MM-DD"), "shortDate", this.locale));
          startDate = startDate.add(1, "day");
        }
        return result;
      case DateAggregation.monthly:
        startDate = startDate.startOf("month");
        endDate = endDate.endOf("month");
        while (startDate <= endDate) {
          result.push(formatDate(startDate.format("YYYY-MM-DD"), "shortDate", this.locale));
          startDate = startDate.add(1, "month");
        }
        return result;
    }
    return [];
  }

  abbreviateNumber(value: number, decimals = 2): string {
    const suffixes = ["", "K", "M", "B"];
    const i = value == 0 ? value : Math.floor(Math.log(value) / Math.log(1000));
    return parseFloat((value / Math.pow(1000, i)).toFixed(decimals)) + suffixes[i];
  }

  getTopSearchTermsTableTooltipText(): string {
    return this.searchTerms.length < 5
      ? this.translocoService.translate("search-term-ranks.add_search_frequency_graph")
      : "";
  }

  getDisplayedColumns(): string[] {
    return ["searchTerm", "searchFrequencyRank", "actions"];
  }

  selectAggregation(aggregation: DateAggregation) {
    this.brandAnalyticsService.setAggregation(aggregation);
  }

  addToSegment(searchTerm: string) {
    if (!this.selectedAccountMarketplace || this.marketplace != this.selectedAccountMarketplace.marketplace) {
      return;
    }
    const modalOptions: ModalOptions = {
      initialState: {
        segmentType: SegmentConfigType.KeywordSegment,
        targetingItemValue: searchTerm,
        marketplace: this.selectedAccountMarketplace.marketplace,
        accountId: this.selectedAccountMarketplace.accountId,
      },
      class: "modal-lg",
    };
    const modalRef = this.modalService.show(AddToSegmentModalComponent, modalOptions);
    const subscription = modalRef.content?.segmentCreationRequested
      .pipe(untilDestroyed(this))
      .subscribe((segmentItems) => {
        const modalOptions: ModalOptions = {
          initialState: {
            accountId: this.selectedAccountMarketplace?.accountId,
            marketplace: this.selectedAccountMarketplace?.marketplace,
            initSegmentItems: segmentItems,
          },
          class: "modal-xl",
        };
        const creationModal = this.modalService.show(KeywordSegmentModalComponent, modalOptions);
        subscription?.add(
          creationModal.content?.segmentCreated.pipe(untilDestroyed(this)).subscribe(() => subscription.unsubscribe()),
        );
        subscription?.add(
          creationModal.content?.segmentEditionCanceled
            .pipe(untilDestroyed(this))
            .subscribe(() => subscription.unsubscribe()),
        );
      });
  }

  protected readonly ICON_ADD = ICON_ADD;
  protected readonly ICON_ADD_CIRCLE = ICON_ADD_CIRCLE;
}
