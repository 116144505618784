<div class="modal-header bg-primary" *transloco="let t">
  <h4 class="modal-title pull-left">{{ t("ignored-keywords-modal.ignored_keywords") }}</h4>
  <a>
    <h4 class="modal-title pull-right hover:scale-105" (click)="bsModalRef.hide()">&times;</h4>
  </a>
</div>

<div class="modal-body" *transloco="let t">
  <div class="flex flex-col flex-wrap">
    @for (error of errors; track error) {
      <div class="text-primary flex flex-1 items-center justify-between">
        <div class="flex-1">
          <p class="flex-1 text-ellipsis break-all font-bold">{{ error.kw }}</p>
        </div>
        <div class="flex-1">
          <p class="ml-5 break-normal">{{ t(error.reason) }}</p>
        </div>
      </div>
    }
  </div>
</div>
