<div class="modal-header bg-main-500" *transloco="let t">
  <h4 class="modal-title pull-left">{{ t("upload-catalog-data-report-modal.catalog_upload_report") }}</h4>
  <a>
    <h4 class="modal-title pull-right" (click)="bsModalRef.hide()">&times;</h4>
  </a>
</div>
<div class="m-4" *transloco="let t">
  @if (uploadResult.uploaded.length > 0) {
    <div class="alert alert-success">
      <div>
        {{
          t("upload-catalog-data-report-modal.add_sucess", {
            count: uploadResult.uploaded.length,
            entity: updatedEntity,
          })
        }}
      </div>
      <div (click)="toggleSuccessDetails()" class="mt-2">
        <span class="icon-prepend float-left">
          <app-dropdown-toggle-icon [expand]="successDetailsVisible"></app-dropdown-toggle-icon>
        </span>
        <span class="ml-2">{{ t("common.details") }}</span>
      </div>
      @if (successDetailsVisible) {
        <div class="mt-2">
          <ul>
            @for (upload of uploadResult.uploaded; track upload) {
              <li>
                {{ uploadResultFormater(upload) }}
              </li>
            }
          </ul>
        </div>
      }
    </div>
  }
  @if (uploadResult.warnings.length > 0) {
    <div class="alert alert-warning break-all">
      <div>
        {{
          t("upload-catalog-data-report-modal.upload_warning", { count: uploadResult.warnings, entity: updatedEntity })
        }}
      </div>
      <div (click)="toggleWarningDetails()" class="mt-2">
        <span class="icon-prepend float-left">
          <app-dropdown-toggle-icon [expand]="warningDetailsVisible"></app-dropdown-toggle-icon>
        </span>
        <span class="ml-2">{{ t("common.details") }}</span>
      </div>
      @if (warningDetailsVisible) {
        <div class="mt-2">
          <ul>
            @for (warning of uploadResult.warnings; track warning) {
              <li>
                {{ warning }}
              </li>
            }
          </ul>
        </div>
      }
    </div>
  }
  @if (uploadResult.errors.length > 0) {
    <div class="alert alert-danger break-all">
      <div>
        {{ t("upload-catalog-data-report-modal.upload_error", { count: uploadResult.errors, entity: updatedEntity }) }}
      </div>
      <div (click)="toggleErrorDetails()" class="mt-2">
        <span class="icon-prepend float-left">
          <app-dropdown-toggle-icon [expand]="errorDetailsVisible"></app-dropdown-toggle-icon>
        </span>
        <span class="ml-2">{{ t("common.details") }}</span>
      </div>
      @if (errorDetailsVisible) {
        <div class="mt-2">
          <ul>
            @for (error of uploadResult.errors; track error) {
              <li>
                {{ error }}
              </li>
            }
          </ul>
        </div>
      }
    </div>
  }
  <div class="flex justify-end">
    <button type="button" class="btn btn-primary pull-right add-button" (click)="bsModalRef.hide()">
      {{ t("common.close") }}
    </button>
  </div>
</div>
