// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    Marketplace,
    SbAsins,
    SbCreativeType,
    State,
} from './';

/**
 * @export
 * @interface SbCreative
 */
export interface SbCreative {
    /**
     * @type {number}
     * @memberof SbCreative
     */
    creativeId: number;
    /**
     * @type {number}
     * @memberof SbCreative
     */
    strategyId: number;
    /**
     * @type {string}
     * @memberof SbCreative
     */
    accountId: string;
    /**
     * @type {Marketplace}
     * @memberof SbCreative
     */
    marketplace: Marketplace;
    /**
     * @type {string}
     * @memberof SbCreative
     */
    brandEntityId?: string;
    /**
     * @type {string}
     * @memberof SbCreative
     */
    brandName: string;
    /**
     * @type {string}
     * @memberof SbCreative
     */
    logoAssetId?: string;
    /**
     * @type {string}
     * @memberof SbCreative
     */
    videoAssetId?: string;
    /**
     * @type {string}
     * @memberof SbCreative
     */
    headline: string;
    /**
     * @type {string}
     * @memberof SbCreative
     */
    storePageId?: string;
    /**
     * @type {string}
     * @memberof SbCreative
     */
    customImageAssetId?: string;
    /**
     * @type {string}
     * @memberof SbCreative
     */
    customImageAssetId2?: string;
    /**
     * @type {string}
     * @memberof SbCreative
     */
    customImageAssetId3?: string;
    /**
     * @type {string}
     * @memberof SbCreative
     */
    customImageAssetId4?: string;
    /**
     * @type {string}
     * @memberof SbCreative
     */
    customImageAssetId5?: string;
    /**
     * @type {SbCreativeType}
     * @memberof SbCreative
     */
    creativeType: SbCreativeType;
    /**
     * @type {Array<SbAsins>}
     * @memberof SbCreative
     */
    creativeAsins?: Array<SbAsins>;
    /**
     * @type {State}
     * @memberof SbCreative
     */
    state: State;
}
