// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    AsinSeed,
    AsinSeeds,
    CostOfGoods,
    CustomField,
    InventoryConfig,
    InventoryRule,
    Marketplace,
    Product,
    Response,
    SetLatestCostOfGoods200Response,
} from '../models';

export interface AddOrDeleteAsinsRequest {
    accountId: string;
    marketplace: Marketplace;
    action: AddOrDeleteAsinsActionEnum;
    requestBody: Array<string>;
}

export interface DeleteCustomFieldRequest {
    accountId: string;
    marketplace: Marketplace;
    asin: string;
    index: number;
}

export interface DeleteInventoryRuleRequest {
    accountId: string;
    marketplace: Marketplace;
    asin?: string;
}

export interface GetAllCostOfGoodsRequest {
    accountId: string;
    marketplace: Marketplace;
}

export interface GetInventoryRequest {
    accountId: string;
    marketplace: Marketplace;
    managed?: boolean;
}

export interface GetInventoryConfigRequest {
    accountId: string;
    marketplace: Marketplace;
}

export interface GetInventoryRulesRequest {
    accountId: string;
    marketplace: Marketplace;
}

export interface GetProductRequest {
    marketplace: Marketplace;
    requestBody: Array<string>;
}

export interface GetProductSeedsRequest {
    accountId: string;
    marketplace: Marketplace;
}

export interface GetVendorAsinsForValidationRequest {
    accountId: string;
    marketplace: Marketplace;
}

export interface SetCostOfGoodsRequest {
    accountId: string;
    marketplace: Marketplace;
    asin: string;
    costOfGoods: Array<CostOfGoods>;
}

export interface SetInventoryConfigRequest {
    accountId: string;
    marketplace: Marketplace;
    blacklistNewAsin?: boolean;
    customField1Name?: string;
    customField2Name?: string;
}

export interface SetInventoryRuleRequest {
    accountId: string;
    marketplace: Marketplace;
    asin?: string;
    advertisingPauseThreshold?: number;
    activateAdvertisingWhenInbound?: boolean;
}

export interface SetInventoryRulesRequest {
    accountId: string;
    marketplace: Marketplace;
    inventoryRule: Array<InventoryRule>;
}

export interface SetLatestCostOfGoodsRequest {
    accountId: string;
    marketplace: Marketplace;
    costOfGoods: Array<CostOfGoods>;
}

export interface SetProductSeedsRequest {
    accountId: string;
    marketplace: Marketplace;
    asinSeeds: Array<AsinSeeds>;
}

export interface SetProductSeedsForAsinRequest {
    accountId: string;
    marketplace: Marketplace;
    asin: string;
    action: SetProductSeedsForAsinActionEnum;
    asinSeed: Array<AsinSeed>;
}

export interface UpdateCustomFieldRequest {
    accountId: string;
    marketplace: Marketplace;
    customField: Array<CustomField>;
}

export interface ValidateVendorCatalogRequest {
    accountId: string;
    marketplace: Marketplace;
    isValid: boolean;
}

/**
 * no description
 */
export class CatalogApi extends BaseAPI {

    /**
     * Add / delete ASINs to / from managed products
     */
    addOrDeleteAsins({ accountId, marketplace, action, requestBody }: AddOrDeleteAsinsRequest): Observable<Response>
    addOrDeleteAsins({ accountId, marketplace, action, requestBody }: AddOrDeleteAsinsRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    addOrDeleteAsins({ accountId, marketplace, action, requestBody }: AddOrDeleteAsinsRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'addOrDeleteAsins');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'addOrDeleteAsins');
        throwIfNullOrUndefined(action, 'action', 'addOrDeleteAsins');
        throwIfNullOrUndefined(requestBody, 'requestBody', 'addOrDeleteAsins');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'action': action,
        };

        return this.request<Response>({
            url: '/catalog/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'POST',
            headers,
            query,
            body: requestBody,
        }, opts?.responseOpts);
    };

    /**
     * Delete a custom field
     */
    deleteCustomField({ accountId, marketplace, asin, index }: DeleteCustomFieldRequest): Observable<Response>
    deleteCustomField({ accountId, marketplace, asin, index }: DeleteCustomFieldRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    deleteCustomField({ accountId, marketplace, asin, index }: DeleteCustomFieldRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'deleteCustomField');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'deleteCustomField');
        throwIfNullOrUndefined(asin, 'asin', 'deleteCustomField');
        throwIfNullOrUndefined(index, 'index', 'deleteCustomField');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'asin': asin,
            'index': index,
        };

        return this.request<Response>({
            url: '/customField/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'DELETE',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Delete inventory rule
     */
    deleteInventoryRule({ accountId, marketplace, asin }: DeleteInventoryRuleRequest): Observable<Response>
    deleteInventoryRule({ accountId, marketplace, asin }: DeleteInventoryRuleRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    deleteInventoryRule({ accountId, marketplace, asin }: DeleteInventoryRuleRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'deleteInventoryRule');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'deleteInventoryRule');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (asin != null) { query['asin'] = asin; }

        return this.request<Response>({
            url: '/inventoryRule/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'DELETE',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Get cost of goods for all asins of an accountId/marketplace
     */
    getAllCostOfGoods({ accountId, marketplace }: GetAllCostOfGoodsRequest): Observable<Array<CostOfGoods>>
    getAllCostOfGoods({ accountId, marketplace }: GetAllCostOfGoodsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<CostOfGoods>>>
    getAllCostOfGoods({ accountId, marketplace }: GetAllCostOfGoodsRequest, opts?: OperationOpts): Observable<Array<CostOfGoods> | AjaxResponse<Array<CostOfGoods>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getAllCostOfGoods');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getAllCostOfGoods');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<CostOfGoods>>({
            url: '/costOfGoods/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * List managed ASINs by account and marketplace
     */
    getInventory({ accountId, marketplace, managed }: GetInventoryRequest): Observable<Array<Product>>
    getInventory({ accountId, marketplace, managed }: GetInventoryRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<Product>>>
    getInventory({ accountId, marketplace, managed }: GetInventoryRequest, opts?: OperationOpts): Observable<Array<Product> | AjaxResponse<Array<Product>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getInventory');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getInventory');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (managed != null) { query['managed'] = managed; }

        return this.request<Array<Product>>({
            url: '/inventory/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Get InventoryConfig
     */
    getInventoryConfig({ accountId, marketplace }: GetInventoryConfigRequest): Observable<InventoryConfig>
    getInventoryConfig({ accountId, marketplace }: GetInventoryConfigRequest, opts?: OperationOpts): Observable<AjaxResponse<InventoryConfig>>
    getInventoryConfig({ accountId, marketplace }: GetInventoryConfigRequest, opts?: OperationOpts): Observable<InventoryConfig | AjaxResponse<InventoryConfig>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getInventoryConfig');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getInventoryConfig');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<InventoryConfig>({
            url: '/inventoryConfig/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * List inventory rules by account and marketplace
     */
    getInventoryRules({ accountId, marketplace }: GetInventoryRulesRequest): Observable<Array<InventoryRule>>
    getInventoryRules({ accountId, marketplace }: GetInventoryRulesRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<InventoryRule>>>
    getInventoryRules({ accountId, marketplace }: GetInventoryRulesRequest, opts?: OperationOpts): Observable<Array<InventoryRule> | AjaxResponse<Array<InventoryRule>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getInventoryRules');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getInventoryRules');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<InventoryRule>>({
            url: '/inventoryRule/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Get product detail per ASIN
     */
    getProduct({ marketplace, requestBody }: GetProductRequest): Observable<Array<Product>>
    getProduct({ marketplace, requestBody }: GetProductRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<Product>>>
    getProduct({ marketplace, requestBody }: GetProductRequest, opts?: OperationOpts): Observable<Array<Product> | AjaxResponse<Array<Product>>> {
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getProduct');
        throwIfNullOrUndefined(requestBody, 'requestBody', 'getProduct');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'marketplace': marketplace,
        };

        return this.request<Array<Product>>({
            url: '/product',
            method: 'POST',
            headers,
            query,
            body: requestBody,
        }, opts?.responseOpts);
    };

    /**
     * List asin seeds for an account/marketplace
     */
    getProductSeeds({ accountId, marketplace }: GetProductSeedsRequest): Observable<Array<AsinSeed>>
    getProductSeeds({ accountId, marketplace }: GetProductSeedsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AsinSeed>>>
    getProductSeeds({ accountId, marketplace }: GetProductSeedsRequest, opts?: OperationOpts): Observable<Array<AsinSeed> | AjaxResponse<Array<AsinSeed>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getProductSeeds');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getProductSeeds');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
        };

        return this.request<Array<AsinSeed>>({
            url: '/seeds',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * List vendor asins by account and marketplace
     */
    getVendorAsinsForValidation({ accountId, marketplace }: GetVendorAsinsForValidationRequest): Observable<Array<Product>>
    getVendorAsinsForValidation({ accountId, marketplace }: GetVendorAsinsForValidationRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<Product>>>
    getVendorAsinsForValidation({ accountId, marketplace }: GetVendorAsinsForValidationRequest, opts?: OperationOpts): Observable<Array<Product> | AjaxResponse<Array<Product>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getVendorAsinsForValidation');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getVendorAsinsForValidation');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<Product>>({
            url: '/vendorValidation/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Bulk edit of cost of goods history for an accountId, marketplace and ASIN
     */
    setCostOfGoods({ accountId, marketplace, asin, costOfGoods }: SetCostOfGoodsRequest): Observable<Array<CostOfGoods>>
    setCostOfGoods({ accountId, marketplace, asin, costOfGoods }: SetCostOfGoodsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<CostOfGoods>>>
    setCostOfGoods({ accountId, marketplace, asin, costOfGoods }: SetCostOfGoodsRequest, opts?: OperationOpts): Observable<Array<CostOfGoods> | AjaxResponse<Array<CostOfGoods>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'setCostOfGoods');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'setCostOfGoods');
        throwIfNullOrUndefined(asin, 'asin', 'setCostOfGoods');
        throwIfNullOrUndefined(costOfGoods, 'costOfGoods', 'setCostOfGoods');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'asin': asin,
        };

        return this.request<Array<CostOfGoods>>({
            url: '/costOfGoods',
            method: 'POST',
            headers,
            query,
            body: costOfGoods,
        }, opts?.responseOpts);
    };

    /**
     * Update InventoryConfig
     */
    setInventoryConfig({ accountId, marketplace, blacklistNewAsin, customField1Name, customField2Name }: SetInventoryConfigRequest): Observable<Response>
    setInventoryConfig({ accountId, marketplace, blacklistNewAsin, customField1Name, customField2Name }: SetInventoryConfigRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    setInventoryConfig({ accountId, marketplace, blacklistNewAsin, customField1Name, customField2Name }: SetInventoryConfigRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'setInventoryConfig');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'setInventoryConfig');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (blacklistNewAsin != null) { query['blacklistNewAsin'] = blacklistNewAsin; }
        if (customField1Name != null) { query['customField1Name'] = customField1Name; }
        if (customField2Name != null) { query['customField2Name'] = customField2Name; }

        return this.request<Response>({
            url: '/inventoryConfig/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'PUT',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Create or update inventory rule
     */
    setInventoryRule({ accountId, marketplace, asin, advertisingPauseThreshold, activateAdvertisingWhenInbound }: SetInventoryRuleRequest): Observable<Response>
    setInventoryRule({ accountId, marketplace, asin, advertisingPauseThreshold, activateAdvertisingWhenInbound }: SetInventoryRuleRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    setInventoryRule({ accountId, marketplace, asin, advertisingPauseThreshold, activateAdvertisingWhenInbound }: SetInventoryRuleRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'setInventoryRule');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'setInventoryRule');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (asin != null) { query['asin'] = asin; }
        if (advertisingPauseThreshold != null) { query['advertisingPauseThreshold'] = advertisingPauseThreshold; }
        if (activateAdvertisingWhenInbound != null) { query['activateAdvertisingWhenInbound'] = activateAdvertisingWhenInbound; }

        return this.request<Response>({
            url: '/inventoryRule/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'PUT',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Insert (or update) a batch of inventory rules
     */
    setInventoryRules({ accountId, marketplace, inventoryRule }: SetInventoryRulesRequest): Observable<Response>
    setInventoryRules({ accountId, marketplace, inventoryRule }: SetInventoryRulesRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    setInventoryRules({ accountId, marketplace, inventoryRule }: SetInventoryRulesRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'setInventoryRules');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'setInventoryRules');
        throwIfNullOrUndefined(inventoryRule, 'inventoryRule', 'setInventoryRules');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Response>({
            url: '/inventoryRule/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'POST',
            headers,
            body: inventoryRule,
        }, opts?.responseOpts);
    };

    /**
     * Insert a bulk of latest cost of goods for an accountId, marketplace and ASIN
     */
    setLatestCostOfGoods({ accountId, marketplace, costOfGoods }: SetLatestCostOfGoodsRequest): Observable<SetLatestCostOfGoods200Response>
    setLatestCostOfGoods({ accountId, marketplace, costOfGoods }: SetLatestCostOfGoodsRequest, opts?: OperationOpts): Observable<AjaxResponse<SetLatestCostOfGoods200Response>>
    setLatestCostOfGoods({ accountId, marketplace, costOfGoods }: SetLatestCostOfGoodsRequest, opts?: OperationOpts): Observable<SetLatestCostOfGoods200Response | AjaxResponse<SetLatestCostOfGoods200Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'setLatestCostOfGoods');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'setLatestCostOfGoods');
        throwIfNullOrUndefined(costOfGoods, 'costOfGoods', 'setLatestCostOfGoods');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
        };

        return this.request<SetLatestCostOfGoods200Response>({
            url: '/costOfGoods',
            method: 'PUT',
            headers,
            query,
            body: costOfGoods,
        }, opts?.responseOpts);
    };

    /**
     * Set product seeds for multiple ASIN for an account/marketplace
     */
    setProductSeeds({ accountId, marketplace, asinSeeds }: SetProductSeedsRequest): Observable<Array<AsinSeeds>>
    setProductSeeds({ accountId, marketplace, asinSeeds }: SetProductSeedsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AsinSeeds>>>
    setProductSeeds({ accountId, marketplace, asinSeeds }: SetProductSeedsRequest, opts?: OperationOpts): Observable<Array<AsinSeeds> | AjaxResponse<Array<AsinSeeds>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'setProductSeeds');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'setProductSeeds');
        throwIfNullOrUndefined(asinSeeds, 'asinSeeds', 'setProductSeeds');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<AsinSeeds>>({
            url: '/seeds/{accountId}/{marketplace}/bulk'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'POST',
            headers,
            body: asinSeeds,
        }, opts?.responseOpts);
    };

    /**
     * Add or delete seeds for an ASIN
     */
    setProductSeedsForAsin({ accountId, marketplace, asin, action, asinSeed }: SetProductSeedsForAsinRequest): Observable<Response>
    setProductSeedsForAsin({ accountId, marketplace, asin, action, asinSeed }: SetProductSeedsForAsinRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    setProductSeedsForAsin({ accountId, marketplace, asin, action, asinSeed }: SetProductSeedsForAsinRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'setProductSeedsForAsin');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'setProductSeedsForAsin');
        throwIfNullOrUndefined(asin, 'asin', 'setProductSeedsForAsin');
        throwIfNullOrUndefined(action, 'action', 'setProductSeedsForAsin');
        throwIfNullOrUndefined(asinSeed, 'asinSeed', 'setProductSeedsForAsin');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'asin': asin,
            'action': action,
        };

        return this.request<Response>({
            url: '/seeds/{accountId}/{marketplace}/update'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'POST',
            headers,
            query,
            body: asinSeed,
        }, opts?.responseOpts);
    };

    /**
     * Update an array of customFields
     */
    updateCustomField({ accountId, marketplace, customField }: UpdateCustomFieldRequest): Observable<Response>
    updateCustomField({ accountId, marketplace, customField }: UpdateCustomFieldRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateCustomField({ accountId, marketplace, customField }: UpdateCustomFieldRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'updateCustomField');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'updateCustomField');
        throwIfNullOrUndefined(customField, 'customField', 'updateCustomField');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Response>({
            url: '/customField/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'PUT',
            headers,
            body: customField,
        }, opts?.responseOpts);
    };

    /**
     * validate vendor catalog
     */
    validateVendorCatalog({ accountId, marketplace, isValid }: ValidateVendorCatalogRequest): Observable<Response>
    validateVendorCatalog({ accountId, marketplace, isValid }: ValidateVendorCatalogRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    validateVendorCatalog({ accountId, marketplace, isValid }: ValidateVendorCatalogRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'validateVendorCatalog');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'validateVendorCatalog');
        throwIfNullOrUndefined(isValid, 'isValid', 'validateVendorCatalog');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'isValid': isValid,
        };

        return this.request<Response>({
            url: '/vendorValidation/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'PUT',
            headers,
            query,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum AddOrDeleteAsinsActionEnum {
    ADD = 'ADD',
    DELETE = 'DELETE'
}
/**
 * @export
 * @enum {string}
 */
export enum SetProductSeedsForAsinActionEnum {
    ADD = 'ADD',
    DELETE = 'DELETE'
}
