import { Translation, TranslocoInterceptor } from "@jsverse/transloco";

export class CustomInterceptor implements TranslocoInterceptor {
  preSaveTranslation(translation: Translation, lang: string): Translation {
    if (lang == "dev") {
      for (const key in translation) {
        translation[key] = "🔑" + key.split(".")[1] + "🔑";
      }
    }
    return translation;
  }

  preSaveTranslationKey(key: string, value: string, lang: string): string {
    return value;
  }
}
