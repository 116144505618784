import moment from 'moment-timezone';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { switchMap, tap } from 'rxjs/operators';
import { AmazonUserService } from './amazonUser.service';
import {
  AccountApi,
  AccountMarketplace,
  AccountState,
  AmazonUser,
  CustomCampaignNameTokens,
  OrganizationAmazonUserAccess,
  OrganizationApi,
  Response,
  User,
} from '@front/m19-api-client';
import { AuthService } from './auth.service';
import { Constant } from './constant';
import { Injectable } from '@angular/core';
import { AccountMarketplaceEx } from '@front/m19-models/AccountMarketplaceEx';
import { catchAjaxError, Utils } from '@front/m19-utils';
import { AccountGroup } from '@front/m19-models/AccountGroup';

@Injectable({
  providedIn: 'root',
})
export class AccountMarketplaceService {
  private organizationResources = new BehaviorSubject<AccountMarketplaceEx[] | undefined>(undefined);
  public accountMarketplaces$: Observable<AccountMarketplaceEx[] | undefined> =
    this.organizationResources.asObservable();

  constructor(
    private orgApi: OrganizationApi,
    private authService: AuthService,
    private accountApi: AccountApi,
    private amazonUserService: AmazonUserService,
  ) {
    authService.isLogged$
      .pipe(
        switchMap((x) =>
          x ? forkJoin([orgApi.organizationResources(), orgApi.organizationAmazonUserAccess()]) : of([[], []]),
        ),
      )
      .subscribe(([resouces, accesses]) => this.buildOrganizationResources(resouces, accesses));
  }

  private buildOrganizationResources(resouces: AccountMarketplace[], accesses: OrganizationAmazonUserAccess[]) {
    const emailAccessesByProfileId = accesses.reduce(function (rv, x) {
      const key = x.organizationId + '_' + x.profileId;
      if (rv.has(key)) {
        rv.get(key)!.push(x);
      } else {
        rv.set(key, [x]);
      }
      return rv;
    }, new Map<string, OrganizationAmazonUserAccess[]>());

    const resoucesEx = resouces.map((x) => {
      const r = x as AccountMarketplaceEx;
      const key = x.resourceOrganizationId + '_' + x.profileId;
      if (emailAccessesByProfileId.has(key)) {
        r.amazonUserAccess = emailAccessesByProfileId.get(key);
        r.hasAccessToAdvertising = r.amazonUserAccess!.some((a) => a.hasAccessToProfile && a.isValidToken);
      } else {
        r.hasAccessToAdvertising = false;
      }
      return r;
    });
    this.organizationResources.next(resoucesEx);
  }

  public reload() {
    forkJoin([this.orgApi.organizationResources(), this.orgApi.organizationAmazonUserAccess()]).subscribe(
      ([resouces, accesses]: [AccountMarketplace[], OrganizationAmazonUserAccess[]]) =>
        this.buildOrganizationResources(resouces, accesses),
    );
  }

  public changeCampaignLabel(
    account: AccountMarketplace,
    newCampaignLabel: string,
    onSuccess: () => void,
    onError: (error: any) => void,
  ) {
    if (!Constant.nameRegexp.test(newCampaignLabel) || Constant.invalidSBSDNameRegexp.test(newCampaignLabel)) {
      onError('Campaign Label can only use characters allowed in Amazon campaign names');
      return;
    }
    this.accountApi
      .updateAccountMarketplace({
        accountId: account.accountId,
        marketplace: account.marketplace,
        campaignLabel: newCampaignLabel,
        organizationId: account.resourceOrganizationId,
        profileId: account.profileId,
      })
      .subscribe(
        () => {
          this.updateAccountMarketplace(account, (x) => (x.campaignLabel = newCampaignLabel));
          onSuccess();
        },
        (error: AjaxError) => {
          onError('Error changing the campaign label to ' + newCampaignLabel);
        },
      );
  }

  public linkDspAdvertiser(account: AccountMarketplace, dspAdvertiserId: string | undefined) {
    return this.accountApi
      .updateAccountMarketplace({
        accountId: account.accountId,
        marketplace: account.marketplace,
        dspAdvertiserId: dspAdvertiserId ?? null,
        organizationId: account.resourceOrganizationId,
        profileId: account.profileId,
      })
      .pipe(
        catchAjaxError(),
        tap(() => {
          this.updateAccountMarketplace(account, (x) => (x.dspAdvertiserId = dspAdvertiserId));
        }),
      );
  }

  public getDspAdvertiser(dspAdvertiserId: string) {
    return this.accountApi.getDspAdvertiser({ dspAdvertiserId }).pipe(catchAjaxError());
  }

  public fetchData(account: AccountMarketplace, onSuccess: () => void, onError: () => void): void {
    this.accountApi.fetchData({ accountId: account.accountId, marketplace: account.marketplace }).subscribe(
      () => {
        this.updateAccountMarketplace(account, (x) => (x.adSyncForced = true));
        onSuccess();
      },
      () => onError(),
    );
  }

  public runBidder(account: AccountMarketplace, onSuccess: () => void, onError: () => void): void {
    this.accountApi.runBidder({ accountId: account.accountId, marketplace: account.marketplace }).subscribe(
      () => {
        this.updateAccountMarketplace(account, (x) => {
          if (!x.bidderRuns) {
            x.bidderRuns = {};
          }
          x.bidderRuns['SP'].bidderRequestTime = Utils.historyNow();
          x.bidderRuns['SP'].bidderRunExpected = true;
          x.bidderRuns['SB'].bidderRequestTime = Utils.historyNow();
          x.bidderRuns['SB'].bidderRunExpected = true;
          x.bidderRuns['SD'].bidderRequestTime = Utils.historyNow();
          x.bidderRuns['SD'].bidderRunExpected = true;
        });
        onSuccess();
      },
      () => onError(),
    );
  }

  public updateMaxBid(
    account: AccountMarketplace,
    maxBid: number,
    onSuccess: () => void,
    onError: (error: any) => void,
  ) {
    if (maxBid === null || maxBid === undefined) {
      onError('Max bid cannot be empty');
      return;
    }
    if (maxBid < account.minBid!) {
      onError('Max bid cannot be less than ' + account.minBid);
      return;
    }
    this.accountApi
      .updateAccountMarketplace({
        accountId: account.accountId,
        marketplace: account.marketplace,
        maxBid: maxBid,
        organizationId: account.resourceOrganizationId,
        profileId: account.profileId,
      })
      .subscribe(
        () => {
          this.updateAccountMarketplace(account, (x) => (x.maxBid = maxBid));
          onSuccess();
        },
        (error: AjaxError) => {
          onError('Error updating the max bid to ' + maxBid);
        },
      );
  }

  public changeCustomCampaignName(
    account: AccountMarketplace,
    newCustomCampaignName: string,
    onSuccess: () => void,
    onError: (error: any) => void,
  ) {
    // If a valid token is found, remove it for name validation
    const cleanPattern = newCustomCampaignName.replace(/%[^%]+%/g, (value) =>
      Object.values(CustomCampaignNameTokens).includes(value as CustomCampaignNameTokens) ? '' : value,
    );
    if (!Constant.nameRegexp.test(cleanPattern) || Constant.invalidSBSDNameRegexp.test(cleanPattern)) {
      onError('Custom campaign name can only contain characters allowed in Amazon campaign names');
      return;
    }
    this.accountApi
      .updateAccountMarketplace({
        accountId: account.accountId,
        marketplace: account.marketplace,
        customCampaignName: newCustomCampaignName,
        organizationId: account.resourceOrganizationId,
        profileId: account.profileId,
      })
      .subscribe(
        () => {
          this.updateAccountMarketplace(
            account,
            (x) =>
              (x.customCampaignName =
                newCustomCampaignName == 'm19 default pattern' ? undefined : newCustomCampaignName),
          );
          onSuccess();
        },
        (error: AjaxError) => {
          onError('Error changing the custom campaign name to ' + newCustomCampaignName);
        },
      );
  }

  public updateAccount(accountId: string, update: (acc: AccountMarketplace) => void) {
    if (!this.organizationResources.getValue()) return;
    this.organizationResources.getValue()!.forEach((x) => {
      if (x.accountId === accountId) update(x);
    });
    this.organizationResources.next(this.organizationResources.getValue());
  }

  public removeAccountMarketplace(account: AccountMarketplace, onSuccess: () => void, onError: () => void) {
    this.accountApi
      .updateAccountMarketplace({
        accountId: account.accountId,
        marketplace: account.marketplace,
        state: AccountState.OFF,
        organizationId: account.resourceOrganizationId,
        profileId: account.profileId,
      })
      .subscribe(() => {
        this.removeAccountMarketplaceFromSubject(account);
        onSuccess();
      }, onError);
  }

  /**
   * @deprecated: use stopBidderV2 instead
   */
  public stopBidder(resource: AccountMarketplace, onSuccess: () => void, onError: () => void) {
    this.accountApi
      .updateAccountMarketplace({
        accountId: resource.accountId,
        marketplace: resource.marketplace,
        state: AccountState.DOWNLOADER_ON,
        previousState: AccountState.BIDDER_ON,
        organizationId: resource.resourceOrganizationId,
        profileId: resource.profileId,
      })
      .subscribe(() => {
        this.updateOrganizationResources([resource], (updatedResource: AccountMarketplace) => {
          updatedResource.bidderEndDate = Utils.formatDateForApi(new Date());
          updatedResource.state = AccountState.DOWNLOADER_ON;
        });
        onSuccess();
      }, onError);
  }

  public stopBidderV2(resource: AccountMarketplace) {
    return this.accountApi
      .updateAccountMarketplace({
        accountId: resource.accountId,
        marketplace: resource.marketplace,
        state: AccountState.DOWNLOADER_ON,
        previousState: AccountState.BIDDER_ON,
        organizationId: resource.resourceOrganizationId,
        profileId: resource.profileId,
      })
      .pipe(
        tap(() =>
          this.updateOrganizationResources([resource], (updatedResource: AccountMarketplace) => {
            updatedResource.bidderEndDate = Utils.formatDateForApi(new Date());
            updatedResource.state = AccountState.DOWNLOADER_ON;
            updatedResource.bidderOrganizationId = undefined;
          }),
        ),
      );
  }

  /**
   * @deprecated: use activateBidderV2 instead
   */
  public activateBidder(resources: AccountMarketplace[], onSuccess: () => void, onError: (error: any) => void) {
    const r = resources.map((x) => {
      // only propagate useful fields to avoid json deserialization issue due to unknown fields
      const o = {} as AccountMarketplace;
      o.resourceOrganizationId = x.resourceOrganizationId;
      o.profileId = x.profileId;
      o.accountId = x.accountId;
      o.accountName = x.accountName;
      o.marketplace = x.marketplace;
      o.profileId = x.profileId;
      return o;
    });
    this.accountApi.bidderActivation({ accountMarketplace: r }).subscribe(
      (updatedResources) => {
        this.updateOrganizationResources(updatedResources, (n: AccountMarketplace) => {
          n.bidderStartDate = updatedResources[0].bidderStartDate;
          n.bidderEndDate = undefined;
          n.state = AccountState.BIDDER_ON;
        });
        onSuccess();
      },
      (error: AjaxError) => {
        onError(error?.response?.message ?? 'Unknown error');
      },
    );
  }

  public activateBidderV2(resources: AccountMarketplace[]): Observable<AccountMarketplace[]> {
    const r: AccountMarketplace[] = resources.map((x) => {
      // only propagate useful fields to avoid json deserialization issue due to unknown fields
      return {
        resourceOrganizationId: x.resourceOrganizationId,
        profileId: x.profileId,
        accountId: x.accountId,
        accountName: x.accountName,
        marketplace: x.marketplace,
      };
    });

    return this.accountApi.bidderActivation({ accountMarketplace: r }).pipe(
      tap((updatedResources) =>
        this.updateOrganizationResources(updatedResources, (n: AccountMarketplace) => {
          n.bidderStartDate = updatedResources[0].bidderStartDate;
          n.bidderEndDate = undefined;
          n.state = AccountState.BIDDER_ON;
          n.bidderOrganizationId = updatedResources[0].resourceOrganizationId;
        }),
      ),
    );
  }

  public moveAccountToGroup(
    account: AccountMarketplace,
    groupId: number,
    groupName: string,
    onSuccess: () => void,
    onError: (error: any) => void,
  ) {
    this.accountApi
      .updateAccountMarketplace({
        accountId: account.accountId,
        marketplace: account.marketplace,
        accountGroupId: groupId,
        organizationId: account.resourceOrganizationId,
        profileId: account.profileId,
      })
      .subscribe({
        next: () => {
          this.updateAccountMarketplace(
            account,
            (x) => {
              x.accountGroupId = groupId;
              x.accountGroupName = groupName;
            },
            (x) => {
              x.accountGroupId = groupId;
              x.accountGroupName = groupName;
            },
          );
          onSuccess();
        },
        error: (error: AjaxError) => {
          onError('Error moving the account ' + account.accountName);
        },
      });
  }

  /**
   * @deprecated use newAccountGroupV2 instead
   */
  public newAccountGroup(
    account: AccountMarketplace,
    name: string,
    onCreation: (gid: number) => void,
    onError: (error: any) => void,
  ) {
    this.accountApi
      .createAccountGroup({
        accountId: account.accountId,
        marketplace: account.marketplace,
        accountGroupName: name,
        organizationId: account.resourceOrganizationId,
        profileId: account.profileId,
      })
      .subscribe(
        (response: Response) => {
          const acc = response.entity as AccountMarketplace;
          this.updateAccountMarketplace(account, (x) => {
            x.accountGroupId = acc.accountGroupId;
            x.accountGroupName = name;
          });
          onCreation(acc.accountGroupId!);
        },
        (error: AjaxError) => {
          onError('Error create the account group ' + name);
        },
      );
  }

  /**
   * Add newly linked accounts to a new or an existing account group.
   * To add to a new group, the accountGroupId param should be undefined.
   * @deprecated
   * */
  public addNewAccountsToGroup(
    accountMarketplaces: AccountMarketplace[],
    name: string,
    amazonToken: string,
    organizationId: number,
    accountGroupId?: number,
    onSuccess?: () => void,
    onError?: (error: any) => void,
  ): void {
    let observable$: Observable<Response | null> = of(null);
    for (const accountMarketplace of accountMarketplaces) {
      observable$ = observable$.pipe(
        switchMap((response) => {
          if (response?.entity) accountGroupId = (response.entity as AccountMarketplace).accountGroupId;
          if (accountGroupId) {
            return this.accountApi.updateAccountMarketplace({
              accountId: accountMarketplace.accountId,
              marketplace: accountMarketplace.marketplace,
              accountGroupId: accountGroupId,
              organizationId: organizationId,
              profileId: accountMarketplace.profileId,
              amazonToken: amazonToken,
            });
          } else {
            return this.accountApi.createAccountGroup({
              accountId: accountMarketplace.accountId,
              marketplace: accountMarketplace.marketplace,
              accountGroupName: name,
              organizationId: organizationId,
              profileId: accountMarketplace.profileId,
              amazonToken: amazonToken,
            });
          }
        }),
      );
    }

    observable$.subscribe(() => {
      this.authService.reloadUser(onSuccess, () => {
        if (onError) {
          onError('Error creating the account group ' + name);
        }
      });
    });
  }

  public addNewAccountsToGroupV2(
    accountMarketplaces: AccountMarketplace[],
    name: string,
    amazonToken: string,
    accountGroupId?: number,
  ): Observable<User> {
    let observable$: Observable<Response | null> = of(null);
    for (const accountMarketplace of accountMarketplaces) {
      observable$ = observable$.pipe(
        switchMap((response) => {
          if (response?.entity) accountGroupId = (response.entity as AccountMarketplace).accountGroupId;
          if (accountGroupId) {
            return this.accountApi.updateAccountMarketplace({
              accountId: accountMarketplace.accountId,
              marketplace: accountMarketplace.marketplace,
              accountGroupId: accountGroupId,
              organizationId: accountMarketplace.resourceOrganizationId,
              profileId: accountMarketplace.profileId,
              amazonToken: amazonToken,
            });
          } else {
            return this.accountApi.createAccountGroup({
              accountId: accountMarketplace.accountId,
              marketplace: accountMarketplace.marketplace,
              accountGroupName: name,
              organizationId: accountMarketplace.resourceOrganizationId,
              profileId: accountMarketplace.profileId,
              amazonToken: amazonToken,
            });
          }
        }),
      );
    }

    return observable$.pipe(switchMap(() => this.authService.reloadUserv2()));
  }

  public renameAccountGroup(
    accountG: AccountGroup,
    newName: string,
    onSuccess: () => void,
    onError: (error: any) => void,
  ) {
    if (
      this.organizationResources.getValue() &&
      this.organizationResources.getValue()!.some((a) => a.accountGroupName == newName)
    ) {
      onError(`Account group "${newName}" already exists. Choose another name.`);
      return;
    }
    if (!accountG.resources || accountG.resources.length == 0) {
      onError("Empty account group can't be renamed");
      return;
    }
    this.accountApi
      .updateAccountMarketplace({
        accountId: accountG.resources[0].accountId,
        marketplace: accountG.resources[0].marketplace,
        accountGroupName: newName,
        accountGroupId: accountG.resources[0].accountGroupId,
        organizationId: accountG.resources[0].resourceOrganizationId,
      })
      .subscribe(
        (response: Response) => {
          if (!this.organizationResources.getValue()) return;

          this.organizationResources
            .getValue()!
            .filter((a) => a.accountGroupId == accountG.id)
            .forEach((a) => (a.accountGroupName = newName));

          this.organizationResources.next(this.organizationResources.getValue());
          onSuccess();
        },
        (error: AjaxError) => {
          onError('Error renaming the account group ' + accountG.name + ' to ' + newName);
        },
      );
  }

  public hasFreshStats(accountMarketplace: AccountMarketplace, days: number): boolean {
    const lastFewDays = moment().tz('UTC').subtract(days, 'days');
    return (
      Boolean(accountMarketplace.lastAllStatsSync) &&
      moment.utc(accountMarketplace.lastAllStatsSync).isAfter(lastFewDays)
    );
  }

  public addAmazonUser(
    organizationId: number,
    accountMarketplace: AccountMarketplace,
    onSuccess?: () => void,
    onError?: (error: any) => void,
    onAmazonResponse?: () => void,
  ) {
    // log with amazon
    this.amazonUserService.getAmazonUser(
      organizationId,
      (amazonUser: AmazonUser) => {
        if (
          amazonUser.accountMarketplaces!.some(
            (x) => x.accountId === accountMarketplace.accountId && x.marketplace === accountMarketplace.marketplace,
          )
        ) {
          this.reload();
          if (onSuccess) {
            onSuccess();
          }
        } else {
          if (onError) {
            onError(
              `Amazon User ${amazonUser.email} doesn't have advertising campaign manager edit access for ${accountMarketplace.accountName} ${accountMarketplace.marketplace}`,
            );
          }
        }
      },
      (errorMessage: string) => {
        if (onError) {
          onError(errorMessage);
        }
      },
      onAmazonResponse,
    );
  }

  public checkAccountActivation(account: AccountMarketplace) {
    return this.accountApi.updateAccountMarketplace({
      accountId: account.accountId,
      marketplace: account.marketplace,
      checkAccountActivation: true,
    });
  }

  private updateAccountMarketplace(
    account: AccountMarketplace,
    update: (x: AccountMarketplace) => void,
    updateOrganizationResource?: (x: AccountMarketplace) => void,
  ) {
    if (!this.organizationResources.getValue()) return;

    const organizationResource = this.organizationResources
      .getValue()!
      .find(
        (x) =>
          x.accountId == account.accountId &&
          x.marketplace == account.marketplace &&
          x.resourceOrganizationId == account.resourceOrganizationId,
      )!;
    update(organizationResource);
    if (updateOrganizationResource) {
      updateOrganizationResource(organizationResource);
    }
    this.organizationResources.next(this.organizationResources.getValue());
  }

  private updateOrganizationResources(
    organizationResouces: AccountMarketplace[],
    update: (OrganizationResource: any) => void,
  ) {
    if (!this.organizationResources.getValue()) return;

    this.organizationResources
      .getValue()!
      .filter((x) => {
        return (
          organizationResouces.findIndex(
            (o) => o.profileId === x.profileId && o.resourceOrganizationId === x.resourceOrganizationId,
          ) > -1
        );
      })
      .forEach((r) => update(r));
    this.organizationResources.next(this.organizationResources.getValue());
  }

  private removeAccountMarketplaceFromSubject(account: AccountMarketplace) {
    this.organizationResources.next(
      this.organizationResources
        .getValue()!
        .filter(
          (x) =>
            !(
              x.accountId == account.accountId &&
              x.marketplace == account.marketplace &&
              x.resourceOrganizationId == account.resourceOrganizationId
            ),
        ),
    );
  }
}
