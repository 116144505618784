import { Marketplace } from "@front/m19-api-client";

export function keywordRankingAvailableFor(marketplace: Marketplace): boolean {
  return KeyworkRankingMarketplaces.includes(marketplace);
}

export const KeyworkRankingMarketplaces = [
  Marketplace.US,
  Marketplace.FR,
  Marketplace.DE,
  Marketplace.CA,
  Marketplace.UK,
  Marketplace.ES,
  Marketplace.IT,
  Marketplace.JP,
].sort();
