import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { StripeCardElement } from "@stripe/stripe-js";
import { AccountMarketplaceService, AuthService, BillingService } from "@front/m19-services";
import { environment } from "../../../../environments/environment";
import { StripeService } from "ngx-stripe";
import { ToastrService } from "ngx-toastr";
import { AjaxError } from "rxjs/ajax";
import { SettingStatus } from "../../../models/Billing";
import { AccountMarketplace, BillingPlan, Customer, Organization, Plan, Response } from "@front/m19-api-client";

@UntilDestroy()
@Component({
  selector: "app-billing-registration",
  templateUrl: "billing-registration.component.html",
  styleUrls: ["billing-registration.component.scss"],
})
export class BillingRegisterComponent implements OnInit {
  readonly steps: string[] = ["Accounts", "Plan", "Customer", "Payment"];
  readonly SettingStatus = SettingStatus;

  isTest = !environment.production;
  step: number;
  organization: Organization;
  currentBillingPlan: BillingPlan;
  nextBillingPlan: BillingPlan;
  customer: Customer;

  locale: string;
  loading = false;
  paymentMethodId: string;
  cardElement: StripeCardElement;
  organizationResources: AccountMarketplace[];

  constructor(
    public stripeService: StripeService,
    private authService: AuthService,
    private billingService: BillingService,
    private toasterService: ToastrService,
    private accountMarketplaceService: AccountMarketplaceService,
    private router: Router,
  ) {
    this.stripeService.changeKey(environment.stripePublicKey);
  }

  ngOnInit(): void {
    this.step = 1;
    this.billingService.organizationOnwer$.pipe(untilDestroyed(this)).subscribe((o) => {
      this.organization = o?.organization;
      this.customer = o?.customer;
      this.currentBillingPlan = o?.getBillingPlan();
      this.nextBillingPlan = undefined;
    });
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((x) => {
      this.locale = x.locale;
    });
  }

  paymentLocked(): boolean {
    return this.loading;
  }

  goToCustomerOrSubscribeForStarter() {
    if (this.nextBillingPlan.plan == Plan.STARTER) {
      this.organization.billingPlan = this.nextBillingPlan;
      this.subscribe(undefined);
    } else {
      this.step = 3;
    }
  }

  goToPayment() {
    this.step = 4;
  }

  setCardElement(cardElement: StripeCardElement) {
    this.cardElement = cardElement;
  }

  setCustomer(customer: Customer) {
    this.customer = customer;
    this.organization.customerId = customer.customerId;
  }

  setOrganizationResources(organizationResources: AccountMarketplace[]): void {
    this.organizationResources = organizationResources;
  }

  setBillingPlan(billingPlan: BillingPlan): void {
    this.nextBillingPlan = billingPlan;
  }

  payAndSubscribe(): void {
    this.loading = true;
    this.stripeService.createPaymentMethod({ type: "card", card: this.cardElement }).subscribe((result) => {
      if (result.paymentMethod) {
        this.subscribe(result.paymentMethod.id);
      } else {
        this.loading = false;
        this.toasterService.error(result.error.message, "Payment issue");
      }
    });
  }

  private subscribe(paymentId: string) {
    this.organization.billingPlan = this.nextBillingPlan;
    this.billingService.createSubscription(
      this.organization,
      paymentId,
      (success: Response) => {
        this.toasterService.success("Subscription successfully created");
        this.accountMarketplaceService.activateBidder(
          this.organizationResources,
          () => this.toasterService.success("Accounts successfully activated"),
          (err) => this.toasterService.error(err, "Accounts activation failure"),
        );
        this.loading = false;
        this.backToBilling();
      },
      (error: AjaxError) => {
        this.loading = false;
        this.backToBilling();
        this.toasterService.error(error.response ? error.response.message : "Unknown error", "Subscription failure");
      },
    );
  }

  backToBilling(): void {
    this.router.navigate(["billing"]);
  }

  goToSettings(): void {
    this.router.navigate(["accounts"]);
  }
}
