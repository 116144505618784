<div class="modal-header bg-main-500" *transloco="let t">
  <h4 class="modal-title pull-left">{{ t("day-parting-pop-up.stategy_dayparting", [strategy.name]) }}</h4>
</div>
<div class="modal-body ml-3 mr-2">
  <app-day-parting-inputs
    [strategy$]="strategy$"
    [locale]="locale"
    [saveButtonPresent]="true"
    [placeholderPause]="dayPartingPauseHour"
    [placeholderReactivation]="dayPartingReactivationHour"
    [placeholderSwitch]="true"
    #dayPartingInputsComponent
  ></app-day-parting-inputs>
</div>
<div class="modal-footer" *transloco="let t">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
    {{ t("common.close") }}
  </button>
  <button type="submit" class="btn btn-primary" (click)="onSave()">{{ t("common.save") }}</button>
</div>
