<ng-container *transloco="let t">
  <div class="w-[30vw]">
    <algo-mode-selection
      [selectedAlgoModeConfig]="selectedAlgoModeConfig"
      [disabled]="updateLoading"
      (algoModeConfig)="setAlgoModeConfig($event)"
      [suggestedAcosTarget]="suggestedAcosTarget"
    />
    <div class="flex justify-between pt-4">
      <IButton label="{{ t('common.cancel') }}" color="white" (onClick)="ref.close()" />
      <IButton
        testid="btn-confirm"
        class="w-2/4"
        label="{{ t('common.save') }}"
        [loading]="updateLoading"
        [disabled]="!selectedAlgoModeConfig?.isValid"
        (onClick)="update()"
        [block]="true"
      />
    </div>
  </div>
</ng-container>
