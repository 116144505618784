// environment variables used on board-test
export const environment = {
  production: false,
  apiEndPoint: "/api",
  spOauthRedirectDomain: "https://board-test.m19.com",
  ga: "G-V4MRSXVCNY",
  ga_whitelabel: "G-V4MRSXVCNY",
  stripePublicKey: "pk_test_6h7RjJK5obxo0YnZwy8m9V5e00YpzVaeoe",
  releaseNotesUrl: "https://www.notion.so/Release-notes-candidate-0cff2cb43699477e828a85ac12c5bf4f",
  build_timestamp: "2024-12-13.18h50", // replaced at build time by set-build-timestamp.js
  commit_hash: "f2dc64ee8e0a89c417580e3d91eea7a58904958b", // replaced at build time by set-build-timestamp.js
  logSpinnerTime: true,
  languageServer: "board-i18n",
};
