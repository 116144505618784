<ul class="nav navbar-nav sensitive-data" *transloco="let t">
  @if (selectedAccount()) {
    <div class="flex items-center">
      <ISelect
        class="w-64"
        [options]="accountOptions()"
        [selected]="selectedAccount()"
        (selectedChange)="selectGroup($event)"
        optionAttribute="label"
        [searchAttributes]="['label', 'accountId', 'category']"
        popperWidth="300px"
        [withCategories]="true"
        [searchable]="true"
      >
        <ng-template #optionSlot let-o>
          <div class="flex w-full items-center justify-between truncate">
            <span class="truncate text-sm text-gray-700">{{ o.label }}</span>
            <div class="flex items-center">
              @if (o.lostAccess) {
                <span
                  id="warning-icon-lost-access"
                  [className]="ICON_WARNING_TRIANGLE + ' text-orange-500 text-lg'"
                  matTooltip="{{ t('account-selector.advertising_lost_warning', [this.listMktplLostAccess(o)]) }}"
                ></span>
              }
              @if (o.bidderOn) {
                <IBadge variant="soft" label="{{ t('account-selector.ads_automation') }}" class="ml-1" size="xs" />
              }
            </div>
          </div>
        </ng-template>
      </ISelect>

      @if (selectedMarketplacesMap().size > 0) {
        <div class="row mx-2" #marketplacesDiv>
          @if (isWrapped) {
            @if (!isSingleMarketplaceMode) {
              <IMultiSelect
                [options]="marketplaceOptions()"
                [selected]="selectedMarketplaces()"
                (selectedChange)="selectMarketplaces($event)"
                [searchAttributes]="['label', 'name']"
                popperWidth="250px"
                [searchable]="true"
                [minSelectedOptions]="1"
                [withSelectAll]="true"
              >
                <ng-template #labelSlot>
                  {{
                    selectedMarketplaces()[0]["flag"] +
                      " " +
                      selectedMarketplaces()[0].label +
                      (selectedMarketplaces().length > 1 ? " (+" + (selectedMarketplaces().length - 1) + ")" : "")
                  }}
                </ng-template>
                <ng-template #optionSlot let-o>
                  <span class="truncate text-sm">{{ o.flag }} {{ o.label }} - {{ o.name }}</span>
                </ng-template>
              </IMultiSelect>
            } @else {
              <ISelect
                class="ml-2 min-w-0"
                [options]="marketplaceOptions()"
                [selected]="selectedMarketplace()"
                (selectedChange)="selectMarketplace($event)"
                [searchAttributes]="['label', 'name']"
                popperWidth="250px"
                [searchable]="true"
              >
                <ng-template #optionSlot #labelSlot let-v>
                  <span class="truncate text-sm">{{ v.flag }} {{ v.label }} - {{ v.name }}</span>
                </ng-template>
              </ISelect>
            }
          } @else {
            <div class="flex gap-2">
              @for (entry of selectedMarketplacesMap() | keyvalue; track entry) {
                <IButton
                  [variant]="entry.value ? 'solid' : 'outline'"
                  [class.active]="entry.value"
                  (onClick)="toggleMarketplace(entry.key)"
                  (dblclick)="doubleClick(entry.key)"
                  [label]="entry.key"
                />
              }
            </div>
          }
        </div>
      }
      @if (scope === "dashboard" && isSingleMarketplaceMode && isReadOnly) {
        <span
          [className]="ICON_WARNING_TRIANGLE + ' text-orange-500 text-lg'"
          matTooltip="{{ t('account-selector.read_only_mode') }}"
        ></span>
      }
    </div>
  }
</ul>
