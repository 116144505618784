import { Component, inject, OnInit } from "@angular/core";
import { StrategyEx } from "@front/m19-models";
import { IButtonComponent, MODAL_DATA, ModalRef } from "@front/m19-ui";
import {
  AlgoModeConfig,
  AlgoModeSelectionComponent,
} from "@m19-board/shared/algo-mode-selection/algo-mode-selection.component";
import { StatsService, StrategyService } from "@front/m19-services";
import { UntilDestroy } from "@ngneat/until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { take } from "rxjs";
import { TranslocoDirective } from "@jsverse/transloco";

@UntilDestroy()
@Component({
  selector: "app-modal-content",
  templateUrl: "./switch-algo-mode.component.html",
  standalone: true,
  imports: [TranslocoDirective, AlgoModeSelectionComponent, IButtonComponent],
})
export class SwitchAlgoModeComponent implements OnInit {
  updateLoading = false;

  selectedAlgoModeConfig?: Partial<AlgoModeConfig>;
  suggestedAcosTarget?: number;

  modalData = inject(MODAL_DATA) as SwitchAlgoModePopupData;
  ref = inject(ModalRef);

  constructor(
    public bsModalRef: BsModalRef,
    private toasterService: ToastrService,
    private strategyService: StrategyService,
    private statsService: StatsService,
  ) {}

  ngOnInit(): void {
    const strategy = this.modalData.strategy;

    this.selectedAlgoModeConfig = {
      algoMode: this.modalData.strategy.algoMode,
      acosTarget: this.modalData.strategy.acosTarget ? Math.round(this.modalData.strategy.acosTarget * 100) : undefined,
      monthlyBudget: this.modalData.strategy.monthlyBudget,
      suggestedBid: this.modalData.strategy.suggestedBid,
      dailyBudget: this.modalData.strategy.dailyBudget,
    };
    if (!this.selectedAlgoModeConfig.acosTarget) {
      this.statsService
        .getLastSevenDaysStatsPerStrategy(strategy.accountId, strategy.marketplace)
        .pipe(take(1))
        .subscribe((stats) => {
          const strategyStats = stats.get(strategy.strategyId);
          if (strategyStats) {
            // no need to convert to the selected currency as we are only interested in the ratio
            this.suggestedAcosTarget = Math.round((strategyStats.cost! / strategyStats.adSales!) * 100);
          }
        });
    }
  }

  setAlgoModeConfig(algoModeConfig: Partial<AlgoModeConfig>) {
    this.selectedAlgoModeConfig = algoModeConfig;
  }

  update(): void {
    this.updateLoading = true;

    this.strategyService
      .switchStrategyAlgoMode(
        this.modalData.strategy.accountId,
        this.modalData.strategy.marketplace,
        this.modalData.strategy.strategyId,
        this.selectedAlgoModeConfig!.algoMode!,
        this.selectedAlgoModeConfig!.acosTarget ? this.selectedAlgoModeConfig!.acosTarget / 100 : undefined,
        this.selectedAlgoModeConfig!.suggestedBid,
        this.selectedAlgoModeConfig!.dailyBudget,
        this.selectedAlgoModeConfig!.monthlyBudget,
      )
      .subscribe({
        next: (strategy) => {
          this.toasterService.success(`Algo Mode updated for strategy ${strategy.name}`, "Strategy updated");
          this.ref.close();
          this.updateLoading = false;
        },
        error: (e) => {
          this.toasterService.error(`Error when updating Algo mode: ${e}`, "Strategy update error");
          this.updateLoading = false;
        },
      });
  }
}

interface SwitchAlgoModePopupData {
  strategy: Pick<
    StrategyEx,
    | "strategyId"
    | "name"
    | "marketplace"
    | "accountId"
    | "algoMode"
    | "acosTarget"
    | "dailyBudget"
    | "monthlyBudget"
    | "suggestedBid"
  >;
}
