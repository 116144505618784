// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    Marketplace,
} from './';

/**
 * @export
 * @interface AllVendorInventory
 */
export interface AllVendorInventory {
    /**
     * Seller or vendor accountId
     * @type {string}
     * @memberof AllVendorInventory
     */
    accountId?: string;
    /**
     * @type {Marketplace}
     * @memberof AllVendorInventory
     */
    marketplace?: Marketplace;
    /**
     * @type {string}
     * @memberof AllVendorInventory
     */
    date?: string;
    /**
     * @type {string}
     * @memberof AllVendorInventory
     */
    asin?: string;
    /**
     * @type {number}
     * @memberof AllVendorInventory
     */
    sourcingSellableUnits?: number;
    /**
     * @type {number}
     * @memberof AllVendorInventory
     */
    sourcingSellableCost?: number;
    /**
     * @type {number}
     * @memberof AllVendorInventory
     */
    manufacturingSellableUnits?: number;
    /**
     * @type {number}
     * @memberof AllVendorInventory
     */
    manufacturingSellableCost?: number;
    /**
     * @type {number}
     * @memberof AllVendorInventory
     */
    sourcingUnsellableUnits?: number;
    /**
     * @type {number}
     * @memberof AllVendorInventory
     */
    sourcingUnsellableCost?: number;
    /**
     * @type {number}
     * @memberof AllVendorInventory
     */
    manufacturingUnsellableUnits?: number;
    /**
     * @type {number}
     * @memberof AllVendorInventory
     */
    manufacturingUnsellableCost?: number;
    /**
     * @type {number}
     * @memberof AllVendorInventory
     */
    sourcingOpenPurchaseOrderUnits?: number;
    /**
     * @type {number}
     * @memberof AllVendorInventory
     */
    manufacturingOpenPurchaseOrderUnits?: number;
    /**
     * @type {number}
     * @memberof AllVendorInventory
     */
    sourcingAged90pSellableUnits?: number;
    /**
     * @type {number}
     * @memberof AllVendorInventory
     */
    sourcingAged90pSellableCost?: number;
    /**
     * @type {number}
     * @memberof AllVendorInventory
     */
    manufacturingAged90pSellableUnits?: number;
    /**
     * @type {number}
     * @memberof AllVendorInventory
     */
    manufacturingAged90pSellableCost?: number;
    /**
     * @type {number}
     * @memberof AllVendorInventory
     */
    sourceableProductOutOfStockRate?: number;
    /**
     * @type {string}
     * @memberof AllVendorInventory
     */
    lastVendorInventoryDate?: string;
}
