// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    Marketplace,
    NotificationType,
} from './';

/**
 * @export
 * @interface Notif
 */
export interface Notif {
    /**
     * @type {string}
     * @memberof Notif
     */
    accountId: string;
    /**
     * @type {Marketplace}
     * @memberof Notif
     */
    marketplace: Marketplace;
    /**
     * @type {number}
     * @memberof Notif
     */
    strategyId: number;
    /**
     * @type {number}
     * @memberof Notif
     */
    subStrategyId?: number;
    /**
     * @type {NotificationType}
     * @memberof Notif
     */
    type: NotificationType;
    /**
     * @type {boolean}
     * @memberof Notif
     */
    dismissed: boolean;
    /**
     * @type {string}
     * @memberof Notif
     */
    message?: string;
    /**
     * @type {string}
     * @memberof Notif
     */
    creationTimestamp: string;
}
