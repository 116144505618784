import { Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AccountMarketplaceService } from "@front/m19-services";
import { BoardType, LayoutSelectorService } from "@m19-board/layout-selector.service";
import { bufferTime, filter, map } from "rxjs/operators";
import { AccountMarketplace } from "@front/m19-api-client";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";
import { LinkYourAccountButtonComponent } from "@m19-board/settings/account-settings/account-group/link-your-account-button.component";

@UntilDestroy()
@Component({
  selector: "app-overlay",
  templateUrl: "./overlay.component.html",
  styleUrls: ["./overlay.component.scss"],
  standalone: true,
  imports: [SpinnerComponent, LinkYourAccountButtonComponent],
})
export class OverlayComponent implements OnInit {
  public readonly BoardType = BoardType;

  @Input({ required: true })
  popupContent!: string;
  @Input({ required: true })
  popupContentWhitelabel!: string;

  noAccountGroupSetup = false;
  boardType!: BoardType;
  loading = true;

  constructor(
    private accountMarketplaceService: AccountMarketplaceService,
    private layoutSelectorService: LayoutSelectorService,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.boardType = this.layoutSelectorService.getBoardType();
    this.accountMarketplaceService.accountMarketplaces$
      .pipe(
        untilDestroyed(this),
        bufferTime(1000),
        filter((b): b is AccountMarketplace[][] => b.length > 0),
        map((b: AccountMarketplace[][]) => b[b.length - 1]),
      )
      .subscribe((accountMarketplaces) => {
        this.noAccountGroupSetup =
          accountMarketplaces.length == 0 || accountMarketplaces.findIndex((am) => am.accountGroupId! > 0) < 0;
        this.loading = false;
      });
  }
}
