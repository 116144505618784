import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatRadioModule } from "@angular/material/radio";
import { MatTooltipModule } from "@angular/material/tooltip";
import { IButtonComponent, IMultiSelectComponent, ISelectComponent, Option } from "@front/m19-ui";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { ICON_CLOSE } from "@m19-board/utils/iconsLabels";
import { CampaignTypeBadgeComponent } from "../campaign-type-badge/campaign-type-badge.component";

export type FilterTagVal<T> = {
  id: string;
  filter: FilterTag<T>;
  values: Option<T>[];
  exclude: boolean;
};

export type FilterTag<T> = {
  type: string;
  label: string;
  noValuePlaceholder: string;
  category?: string;
  tooltip?: string;
  color?: string;
  defaultValues?: () => Option<T>[];
  options: () => Option<T>[];
  unique?: boolean;
  excludeOption: boolean;
  singleValue?: boolean;
};

@Component({
  selector: "app-filter-tags",
  standalone: true,
  imports: [
    ISelectComponent,
    IMultiSelectComponent,
    CampaignTypeBadgeComponent,
    IButtonComponent,
    MatTooltipModule,
    MatRadioModule,
    TranslocoRootModule,
  ],
  templateUrl: "./filter-tags.component.html",
})
export class FilterTagsComponent<T> {
  readonly ICON_CLOSE = ICON_CLOSE;

  @Input() filterValues: FilterTagVal<T>[] = [];

  @Input() set filters(filters: FilterTag<T>[]) {
    this._filters = filters.map((f) => ({ label: f.label, value: f, category: f.category }));
  }

  _filters: Option<FilterTag<T>>[] = []; // filters taking 'unique' into account

  @Output() filterChange = new EventEmitter<FilterTagVal<T>[]>();

  selectFilter(filterOption: Option<FilterTag<T>>) {
    const id = crypto.randomUUID();
    const filter = filterOption.value;
    this.filterValues.push({ id, filter, values: filter.defaultValues ? filter.defaultValues() : [], exclude: false });
    this.filterChange.emit(this.filterValues);
    if (filter.unique) {
      this._filters = this._filters.filter((f) => f.value.type !== filter.type);
    }
  }

  selectValue(filterValue: FilterTagVal<T>, value: Option[]) {
    filterValue.values = value;
    this.filterChange.emit(this.filterValues);
  }

  clearFilter(id: string) {
    const removed = this.filterValues.find((f) => f.id === id);
    this.filterValues = this.filterValues.filter((f) => f.id !== id);
    this.filterChange.emit(this.filterValues);

    if (removed?.filter.unique) {
      this._filters = [...this._filters, { label: removed.filter.label, value: removed.filter }];
    }
  }

  excludeFilter(id: string, exclude: boolean) {
    const filter = this.filterValues.find((f) => f.id === id);
    if (filter) {
      filter.exclude = exclude;
      this.filterChange.emit(this.filterValues);
    }
  }
}
