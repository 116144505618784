<ng-container *transloco="let t">
  <div class="modal-header bg-main-500">
    <h4 class="modal-title pull-left">{{ t("user-limitation-modal.limit_of_users_reached") }}</h4>
  </div>
  <div>
    <div class=""></div>
    @if (whiteLabel) {
      <div class="modal-body">
        <div>{{ t("user-limitation-modal.limit_reached", { count: userLimit }) }}</div>
        @if (linkToUpgrade) {
          <div>
            <a [href]="linkToUpgrade.url" [target]="linkToUpgrade.target">{{ t("common.upgrade") }}</a>
            {{ t("user-limitation-modal.to_register_more_users") }}
          </div>
        }
      </div>
    }
    @if (!whiteLabel) {
      <div class="modal-body">
        <div>{{ t("user-limitation-modal.limit_reached", { count: userLimit }) }}</div>
      </div>
    }
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="close()">{{ t("common.close") }}</button>
    </div>
  </div>
</ng-container>
