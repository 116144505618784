// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    AccountSubType,
    AccountType,
    Marketplace,
} from './';

/**
 * @export
 * @interface AccountMarketplaceBase
 */
export interface AccountMarketplaceBase {
    /**
     * @type {string}
     * @memberof AccountMarketplaceBase
     */
    accountId: string;
    /**
     * @type {Marketplace}
     * @memberof AccountMarketplaceBase
     */
    marketplace: Marketplace;
    /**
     * @type {string}
     * @memberof AccountMarketplaceBase
     */
    accountName: string;
    /**
     * @type {AccountType}
     * @memberof AccountMarketplaceBase
     */
    accountType: AccountType;
    /**
     * @type {AccountSubType}
     * @memberof AccountMarketplaceBase
     */
    accountSubType?: AccountSubType;
    /**
     * @type {number}
     * @memberof AccountMarketplaceBase
     */
    profileId: number;
}
