<div class="mb-3 flex justify-between">
  <app-filter [filters]="filters()" (filterChange)="applyFilter($event)" />
</div>

<div class="sticky top-0 z-[40]">
  <app-metric-selector
    *ngIf="chartMetrics"
    [data]="totalData"
    [previousPeriodData]="previousTotalData"
    (chartMetricsChanges)="selectMetrics($event)"
    [pageMetrics]="$any(chartMetrics)"
    [localStorageKey]="'SalesAndAdvertising_' + accountType"
  ></app-metric-selector>
</div>

<div [class.hidden]="isGlobalChartHidden" class="mt-3" *transloco="let t">
  <ICard>
    <ng-template #header>
      <div class="flex items-center justify-between">
        <div class="flex w-full items-center">
          <mat-slide-toggle
            color="primary"
            [checked]="(displayEventAnnotation | async) ?? false"
            (change)="toggleEventAnnotationDisplay($event.checked)"
            class="mr-4"
            [disabled]="disableEventAnnotation"
            ><span class="events-toggle">{{ t("dashboard.activity_events") }}</span></mat-slide-toggle
          >
          <activity-event-filters
            class="flex-1"
            [displayEventAnnotation]="(displayEventAnnotation | async) ?? false"
            [disableEventAnnotation]="disableEventAnnotation"
            [allEventAnnotationTypes]="allEventAnnotationTypes"
            [allUsers]="(allUsers$ | async) ?? []"
            [allStrategies]="(allStrategies$ | async) ?? []"
          />
        </div>
        <div class="ml-3 flex items-center gap-x-3">
          <app-date-aggreation-switch-button
            class="flex"
            [selected]="dateAggregation"
            (dateAggSelected)="selectAggreation($event)"
          />
          <IButton
            color="gray"
            variant="ghost"
            tooltipValue="{{ t('common.hide_chart') }}"
            [icon]="ICON_CLOSE"
            (onClick)="toggleGlobalChartDisplay(true)"
          />
        </div>
      </div>
    </ng-template>
    <ng-template #body>
      @if (dataLoading) {
        <div class="flex h-44 items-center justify-center">
          <app-spinner type="default" [display]="true" size="s" />
        </div>
      } @else {
        <canvas
          baseChart
          class="chart mb-3"
          [datasets]="globalDataset.chartDataSet"
          type="line"
          [labels]="globalDataset.labels"
          [options]="globalDataset.lineChartOptions"
        ></canvas>
      }
    </ng-template>
  </ICard>
</div>

<div class="my-3 flex justify-between" *transloco="let t">
  <IButton
    color="white"
    [label]="isGroupByParent ? t('product-tracker.split_by_child_asin') : t('product-tracker.group_by_parent_asin')"
    (onClick)="toggleParentAsin()"
  />
  <div class="flex justify-end gap-1">
    <IButton
      tooltipValue="{{ t('common.restore_default_columns') }}"
      label="{{ t('common.restore_columns') }}"
      color="white"
      (onClick)="restoreDefaultColumns()"
    />
    <app-export-button tooltipValue="{{ t('common.export_as_csv') }}" (export)="exportGridCsv()" />
    @if (isGlobalChartHidden) {
      <IButton
        tooltipValue="{{ t('agency-board.show_global_chart') }}"
        [icon]="ICON_CHART"
        [square]="true"
        (onClick)="toggleGlobalChartDisplay(false)"
        color="white"
      />
    }
  </div>
</div>

<div class="ag-theme-quartz grid">
  <ag-grid-angular
    style="width: 100%"
    class="h-[60vh]"
    #grid
    [columnDefs]="columnDefs"
    [rowData]="gridData"
    [gridOptions]="gridOptions"
    [animateRows]="true"
    (columnRowGroupChanged)="onColumnRowGroupChanged($event)"
  />
</div>
