import { M19Status, Marketplace, MatchType, SbCreative, SbCreativeType } from '@front/m19-api-client';
import { Catalog } from '@front/m19-models/Catalog';
import { CurrencyStat } from '@front/m19-models/CurrencyStat';
import { InventoryRules } from '@front/m19-models/InventoryRules';
import { InventoryStats } from '@front/m19-models/InventoryStats';
import { Marketplaces } from '@front/m19-models/MarketplaceEx';
import { NotificationBidderIssueEx } from '@front/m19-models/NotificationEx';
import { Platform } from '@front/m19-models/Platform';
import { SbCreativeBrandAssets } from '@front/m19-models/SbCreativeEx';
import moment from 'moment-timezone';
import { catchError, Observable, OperatorFunction, throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { AdStatsEx } from '../models/AdStatsEx';
import { DateAggregation } from './date.utils';
import { TranslocoService } from '@jsverse/transloco';

export function catchAjaxError<T>(msg?: string): OperatorFunction<T, T> {
  if (msg) {
    return catchError<T, Observable<never>>((error: AjaxError) =>
      throwError(() => msg + (error.response ? error.response.message : error.message)),
    );
  }
  return catchError<T, Observable<never>>((error: AjaxError) =>
    throwError(() => (error.response ? error.response.message : error.message)),
  );
}

export class Utils {
  // ASIN regex
  // when updating this regex, also update its Java counterpart in `m19-common/src/main/java/com/m19/common/utils/Validation.java`
  private static readonly asinRegexp = /^([0-9]{9}[0-9X]|B[0-9T][0-9A-Z]{8})$/;
  // https://advertising.amazon.com/API/docs/en-us/concepts/limits#keyword-character-constraints
  // when updating this regex also update its Java counterpart in `m19-common/src/main/java/com/m19/common/utils/Validation.java`
  private static keywordRegexp =
    /^[-. &+'0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZÁÉÍÑÓÚÜáéíñóúüÄÖŒßäöÀÂÆÇÈÊËÎÏÔÙÛŸàâæçèéêëîïôùûÿœ\u3000-\u309F\u30A0-\u30FF\u4E00-\u9FFF\uFF00-\uFFEF]+$/;
  private static invalidKeywordRegexp = / [-+.]|^[-+. ]|[-+.] |[-+. ]$/;

  private static emailRegEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  private static PALETTES = [
    [
      '#3e3145',
      '#8c32d1',
      '#3b3457',
      '#5535d0',
      '#6d5e80',
      '#ac30c5',
      '#46224e',
      '#5d56d9',
      '#3f3369',
      '#39219d',
      '#784a84',
      '#56208d',
      '#6c5fab',
      '#49175d',
      '#9247b1',
      '#233076',
      '#944597',
      '#424aab',
      '#66217b',
      '#332576',
    ],
    [
      '#738a69',
      '#d6e38c',
      '#2e543c',
      '#96e9aa',
      '#4e5c48',
      '#50c793',
      '#4e5a1a',
      '#c3e3bf',
      '#316520',
      '#92b590',
      '#296437',
      '#939738',
      '#326b50',
      '#9fb66e',
      '#545c37',
      '#419a78',
      '#7d7e3e',
      '#499154',
      '#426037',
      '#648233',
    ],
    [
      '#e4c8a9',
      '#914421',
      '#dfb667',
      '#595242',
      '#e59674',
      '#604d30',
      '#ab7c32',
      '#6b4f42',
      '#b89d7e',
      '#814732',
      '#8d7a6c',
      '#bb6d56',
      '#6f5d23',
      '#947953',
      '#785422',
    ],
    [
      '#5d7670',
      '#62e6db',
      '#3a514c',
      '#b3e1e0',
      '#194f46',
      '#54c8e4',
      '#2d525c',
      '#4ab3a8',
      '#075c62',
      '#84afb0',
      '#33675e',
      '#3d96ab',
      '#408a80',
      '#527681',
      '#2d747a',
    ],
    [
      '#884a5b',
      '#e39fe3',
      '#63444d',
      '#dcbed4',
      '#84367c',
      '#876971',
      '#bc60a1',
      '#5b4e62',
      '#e892b7',
      '#694e79',
      '#d56983',
      '#744781',
      '#b3889d',
      '#983155',
      '#a77eb5',
      '#6a4763',
      '#89446d',
      '#856d86',
    ],
  ];

  public static isValidEmail(email: string): boolean {
    return this.emailRegEx.test(email);
  }

  public static isValidAsin(asin: string): boolean {
    return this.asinRegexp.test(asin);
  }

  public static normalizeKeyword(keyword: string): string {
    return Utils.removeDiacritics(keyword.toLowerCase().replace(/\s\s+/g, ' ').trim());
  }

  public static normalizeASIN(asin: string): string {
    return asin.toUpperCase().trim();
  }

  public static isValidKeyword(keyword: string, matchType: MatchType): string {
    //check if the keyword is okm return the error if it is not

    if (!keyword) return 'utils.no_keyword_detected';
    if (keyword.length > 80) return 'utils.keyword_is_too_long_more_than_80_characters';

    const chunks = keyword.split(' ');
    if (matchType == MatchType.phrase && chunks.length > 4) return 'utils.phrase_is_too_long_max_4_words';
    if (matchType == MatchType.exact && chunks.length > 10) return 'utils.exact_match_is_too_long_max_10_words';
    for (const word of chunks) {
      if (!Utils.keywordRegexp.test(word) || Utils.invalidKeywordRegexp.test(word)) {
        return 'utils.invalid_characters_used';
      }
    }
    return '';
  }

  public static removeDiacritics(str: string) {
    // inspired from https://www.davidbcalhoun.com/2019/matching-accented-strings-in-javascript/
    return str
      .normalize('NFD') // decompose unicode character in the form `letter + combining diacritic`
      .replace(/[\u0300-\u036f]/g, ''); // remove diacritic from the string
  }

  public static formatDateForApi(date: Date): string {
    return moment(date).format('YYYY-MM-DD');
  }

  public static formatMonthForApi(date: Date | string): string {
    return moment(date).format('YYYY-MM');
  }

  public static dateFromToday(delta: number): Date {
    const date = new Date();
    date.setDate(date.getDate() + delta);
    return date;
  }

  public static formatDateForApiFromToday(delta: number): string {
    return Utils.formatDateForApi(Utils.dateFromToday(delta));
  }

  public static incDate(date: Date, agg: DateAggregation, nb: number): Date | undefined {
    switch (agg) {
      case DateAggregation.daily:
        return moment.utc(date).add(nb, 'days').toDate();
      case DateAggregation.weekly:
        return moment
          .utc(date)
          .add(7 * nb, 'days')
          .toDate();
      case DateAggregation.monthly:
        return moment.utc(date).add(nb, 'months').toDate();
    }
    return undefined;
  }

  public static incMomentDate(date: moment.Moment, agg: DateAggregation, nb: number): moment.Moment | undefined {
    switch (agg) {
      case DateAggregation.daily:
        return moment.utc(date).add(nb, 'days');
      case DateAggregation.weekly:
        return moment.utc(date).add(7 * nb, 'days');
      case DateAggregation.monthly:
        return moment.utc(date).add(nb, 'months');
    }
    return undefined;
  }

  public static roundTimestampByHour(minDate: number, currentDate: number, tz: string): number {
    const from = moment.utc(minDate).tz(tz).startOf('day');
    const to = moment.utc(currentDate).tz(tz);
    const nbHours = to.diff(from, 'hours');
    const rounded = from.add(nbHours, 'hours');
    return rounded.valueOf();
  }

  public static roundTimestampByDay(minDate: number, currentDate: number, tz: string): number {
    const res = moment.utc(currentDate).tz(tz).startOf('day').valueOf();
    return res;
  }

  public static roundTimestampByWeek(minDate: number, currentDate: number, tz: string): number {
    const from = moment.utc(minDate).tz(tz).startOf('day');
    const to = moment.utc(currentDate).tz(tz);
    const nbWeek = to.diff(from, 'weeks');
    const rounded = from.add(nbWeek, 'weeks');
    return rounded.valueOf();
  }

  public static roundDateByWeek(minDate: string, currentDate: string): string {
    const from = moment.utc(minDate);
    const to = moment.utc(currentDate);
    const nbWeek = to.diff(from, 'week');
    const rounded = from.add(7 * nbWeek, 'days');
    return Utils.formatMomentDate(rounded);
  }

  public static roundDateByWeekMoment(minDate: moment.Moment, currentDate: string): string {
    const from = minDate.clone();
    const to = moment.utc(currentDate);
    const nbWeek = to.diff(from, 'week');
    const rounded = from.add(7 * nbWeek, 'days');
    return Utils.formatMomentDate(rounded);
  }

  public static roundDateByMonth(minDate: string, currentDate: string): string {
    const from = moment.utc(minDate);
    const to = moment.utc(currentDate);
    const nbMonth = to.diff(from, 'month');
    const rounded = from.add(nbMonth, 'months');
    return Utils.formatDateForApi(rounded.toDate());
  }

  public static roundDateByMonthMoment(minDate: moment.Moment, currentDate: string): string {
    const from = minDate.clone();
    const to = moment.utc(currentDate);
    const nbMonth = to.diff(from, 'month');
    const rounded = from.add(nbMonth, 'months');
    return Utils.formatMomentDate(rounded);
  }

  public static getDateRange(startDate: number, endDate: number, tz?: string): string[] {
    const fromDate = moment.utc(startDate);
    const toDate = moment.utc(endDate);
    const diff = toDate.diff(fromDate, 'days');
    const range = new Set<string>();
    for (let i = 0; i <= diff; i++) {
      const date = moment.utc(startDate).add(i, 'days');
      if (tz) {
        date.tz(tz).startOf('day');
      }
      range.add(date.format());
    }
    return Array.from(range.values()).sort();
  }

  public static aggregate<T>(arr: T[], groupByKey: (x: T) => string, aggregateFun: (group: T[]) => T): Map<string, T> {
    const map = new Map<string, T[]>();
    for (const x of arr) {
      const key = groupByKey(x);
      if (!map.has(key)) {
        map.set(key, [x]);
      } else {
        map.get(key)!.push(x);
      }
    }
    const res = new Map<string, T>();
    for (const key of map.keys()) {
      res.set(key, aggregateFun(map.get(key)!));
    }
    return res;
  }

  public static median<T>(arr: T[], field: (x: T) => number): number | undefined {
    if (arr.length === 0) {
      return undefined;
    }
    const values = arr.map((x) => field(x)).sort();
    if (values.length == 1) {
      return values[0];
    }
    const half = Math.floor(values.length / 2);

    if (arr.length % 2) return values[half];

    return (values[half - 1] + values[half]) / 2.0;
  }

  public static sumBy<T extends CurrencyStat>(
    stats: T[],
    key: (x: T) => string,
    sumFunction: (x: T, y: T) => T,
  ): Map<string, T> {
    return this.genSumBy(stats, key, sumFunction);
  }

  public static genSumBy<T extends CurrencyStat>(
    stats: T[],
    key: (x: T) => string,
    sumFunction: (x: T, y: T) => T,
  ): Map<string, T> {
    const res: Map<string, T> = new Map();
    for (const s of stats) {
      const key_ = key(s);

      if (!res.has(key_)) res.set(key_, { ...s });
      else res.set(key_, sumFunction(res.get(key_)!, s));
    }
    return res;
  }

  public static strCompare(a: string, b: string): number {
    return a < b ? -1 : a > b ? 1 : 0;
  }

  public static strOrderCompare(a: string, b: string): number {
    return a < b ? -1 : 1;
  }

  public static getTopDomainFromMarketplace(marketplace: Marketplace): string {
    return Marketplaces[marketplace]?.topLevelDomain;
  }

  public static compareDateRange(dr1: string[] | undefined, dr2: string[] | undefined): boolean {
    if (!dr1 && !dr2) return true;
    return !!dr1 && !!dr2 && dr1[0] == dr2[0] && dr1[1] == dr2[1];
  }

  public static randomInt(max: number) {
    return Math.floor(Math.random() * max);
  }

  public static randomBoolean() {
    return Math.random() < 0.5;
  }

  public static insertInMap<T>(
    map: Map<T, AdStatsEx>,
    key: T,
    value: AdStatsEx,
    acc: (a: AdStatsEx, b: AdStatsEx) => AdStatsEx,
  ): void {
    const mapElt = map.get(key);
    if (mapElt) map.set(key, acc(mapElt, value));
    else map.set(key, { ...value });
  }

  public static insertInArrayMap<T, D>(map: Map<T, D[]>, key: T, value: D): void {
    const mapElt = map.get(key);
    if (mapElt) mapElt.push(value);
    else map.set(key, [value]);
  }

  public static hex(c: string) {
    const s = '0123456789abcdef';
    let i = parseInt(c);
    if (i == 0 || isNaN(i)) return '00';
    i = Math.round(Math.min(Math.max(0, i), 255));
    return s.charAt((i - (i % 16)) / 16) + s.charAt(i % 16);
  }

  /* Convert an RGB triplet to a hex string */
  public static convertToHex(rgb: string[]) {
    return Utils.hex(rgb[0]) + Utils.hex(rgb[1]) + Utils.hex(rgb[2]);
  }

  /* Remove '#' in color hex string */
  public static trim(s: string) {
    return s.charAt(0) == '#' ? s.substring(1, 7) : s;
  }

  public static truncateString(value: string, limit = 20, trailingChar = '…'): string {
    return value.length > limit ? value.substring(0, limit) + trailingChar : value;
  }

  /* Convert a hex string to an RGB triplet */
  public static convertToRGBA(hex: string, alpha: number) {
    const color = [];
    color[0] = parseInt(Utils.trim(hex).substring(0, 2), 16);
    color[1] = parseInt(Utils.trim(hex).substring(2, 4), 16);
    color[2] = parseInt(Utils.trim(hex).substring(4, 6), 16);
    color[3] = alpha;
    return 'rgba(' + color[0] + ',' + color[1] + ',' + color[2] + ',' + color[3] + ')';
  }

  public static getQuartileAvg(data: number[], q: number): number {
    data = data.sort((a, b) => a - b);
    const pos = (data.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;

    if (data[base + 1] !== undefined) {
      return data[base] + rest * (data[base + 1] - data[base]);
    } else {
      return data[base];
    }
  }

  public static isInHourInterval(hour: number, start: number, end: number): boolean {
    return (start <= end && hour >= start && hour < end) || (start > end && (hour >= start || hour < end));
  }

  public static toCamelCase(str: string) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
      return match.toUpperCase();
    });
  }

  public static camelCaseToHuman(str: string) {
    // convert a camel case string to a human readable one (with spaces)
    return str.split(/(?=[A-Z])/).join(' ');
  }

  public static pluriel<T>(input: Array<T>): string {
    return input.length > 1 ? 's' : '';
  }

  public static m19StatusToReadableStr(status: M19Status): string {
    switch (status) {
      case M19Status.IGNORED_ASINS_NOT_IN_STORE_PAGE:
        return 'ASINs are not in store page';

      case M19Status.PUSH_FAILED_INVALID_ASINS:
      case M19Status.IGNORED_CREATIVE_ASINS_INELIGIBLE:
        return 'ASINs are not SB eligible';

      case M19Status.IGNORED_INVALID_BRAND_LOGO:
        return 'Brand logo is invalid';

      case M19Status.IGNORED_INVALID_CUSTOM_IMAGE:
        return 'Custom image is invalid';

      case M19Status.IGNORED_LOW_INVENTORY_ASINS:
        return 'Not enough stock';

      case M19Status.IGNORED_NOT_ENOUGH_ASINS_FOR_LANDING_PAGE:
        return 'Not enough asins to build landing page';

      case M19Status.REJECTED:
      case M19Status.IGNORED_REJECTED:
        return 'rejected by Amazon';

      case M19Status.PUSH_FAILED_ASSET_NOT_FOUND:
        return 'Assets not found';

      case M19Status.PUSH_FAILED_BILLING_ERROR:
        return 'Billing error';

      case M19Status.PUSH_FAILED_INVALID_HEADLINE:
        return 'Headline is invalid';

      case M19Status.REJECTED_HEADLINE:
        return 'Headline rejected by Amazon';

      case M19Status.REJECTED_VIDEO:
        return 'Video rejected by Amazon';

      case M19Status.REJECTED_IMAGE:
        return 'Image rejected by Amazon';

      case M19Status.REJECTED_ASIN:
        return 'Product Rejected by Amazon';

      default:
        return '';
    }
  }

  public static getSBFaultyReasons(
    creative: SbCreative,
    brandAssets: SbCreativeBrandAssets,
    catalog: Catalog,
    rules: InventoryRules,
    stats: InventoryStats[],
    translocoService: TranslocoService,
    notif?: NotificationBidderIssueEx,
  ): { status: M19Status | undefined; faultyCreativeReason: string } {
    let faultyCreativeReason = '';
    let status: M19Status | undefined;

    if (creative?.creativeType == SbCreativeType.productCollection) {
      const logoAsset = brandAssets.logoAsset;
      const customImageAsset = brandAssets.customImage;
      if (!logoAsset) {
        faultyCreativeReason = translocoService.translate('utils.deleted_brand_logo');
        status = M19Status.IGNORED_INVALID_BRAND_LOGO;
      } else if (logoAsset.deleted) {
        faultyCreativeReason = translocoService.translate('utils.invalid_brand_logo');
        status = M19Status.IGNORED_INVALID_BRAND_LOGO;
      } else if (customImageAsset?.deleted) {
        faultyCreativeReason = translocoService.translate('utils.invalid_custom_image');
        status = M19Status.IGNORED_INVALID_CUSTOM_IMAGE;
      }

      if (faultyCreativeReason) {
        return { status, faultyCreativeReason };
      }
      const storePage = brandAssets.storePage;
      if (!storePage && catalog.nbLandingpageEligibleAsins < 3) {
        faultyCreativeReason = translocoService.translate('utils.at_least_3_asins');
        status = M19Status.IGNORED_NOT_ENOUGH_ASINS_FOR_LANDING_PAGE;
      }
    }

    // get warning from notification
    if (notif) {
      if (notif.warningType !== undefined) {
        faultyCreativeReason = notif.warningMessage!;
        status = notif.warningType;
        if (notif.moderationDetails) {
          const content = notif.moderationDetails.find((d) => !!d.violatingContent);
          if (content?.violatingContent) {
            faultyCreativeReason = `${faultyCreativeReason} ${translocoService.translate('utils.violating_content', [content.violatingContent])}`;
          }
          const type = notif.moderationDetails.find((d) => !!d.violatingType);
          if (type?.violatingType) {
            switch (type.violatingType) {
              case 'VIDEO':
                status = M19Status.REJECTED_VIDEO;
                break;
              case 'IMAGE':
                status = M19Status.REJECTED_IMAGE;
                break;
              case 'TEXT':
                status = M19Status.REJECTED_HEADLINE;
                break;
              case 'ASIN':
                status = M19Status.REJECTED_ASIN;
                break;
              case 'UNKNOWN':
                status = M19Status.REJECTED;
                break;
            }
          }
        }
      }
    }

    if (faultyCreativeReason) return { status, faultyCreativeReason };

    if (brandAssets.videoAsset?.deleted) {
      return {
        status: M19Status.REJECTED_VIDEO,
        faultyCreativeReason: translocoService.translate('utils.video_asset_is_archived_on_amazon'),
      };
    }

    // both for SB and SBV
    const sbEligibility = catalog.getSBEligibility();

    let asinList = creative?.creativeAsins!.flatMap((sbAsin) => [sbAsin.asin1, sbAsin.asin2, sbAsin.asin3]);
    asinList = asinList.filter((asin) => {
      if (asin && brandAssets.storePage && !brandAssets.storePage.asinList?.includes(asin)) {
        faultyCreativeReason = translocoService.translate('utils.some_asins_are_not_in_the_store_page');
        status = M19Status.IGNORED_ASINS_NOT_IN_STORE_PAGE;
        return false;
      }
      if (asin && sbEligibility.get(asin)?.status) return true;
      else if (asin) {
        faultyCreativeReason = translocoService.translate('utils.contains_ineligible_sb_asins');
        status = M19Status.IGNORED_CREATIVE_ASINS_INELIGIBLE;
      }
      return false;
    });
    if (asinList.length === 0) {
      faultyCreativeReason = translocoService.translate('utils.all_asins_are_ineligible');
      status = M19Status.PUSH_FAILED_INVALID_ASINS;
    }

    if (faultyCreativeReason) return { status, faultyCreativeReason };

    asinList.forEach((asin) => {
      if (rules && rules.execute(asin!, stats.find((x) => x.asin === asin)!).shouldPauseAdvertising) {
        faultyCreativeReason = translocoService.translate('utils.asin_is_low_inventory');
        status = M19Status.IGNORED_LOW_INVENTORY_ASINS;
      }
    });

    return { status, faultyCreativeReason };
  }

  public static getTimezoneOffset(timezone: string) {
    if (!timezone) {
      return '';
    }
    const offsetInMin = moment.tz.zone(timezone)!.utcOffset(moment.now()) ?? 0;
    const min = '' + (Math.abs(offsetInMin) % 60);
    const hour = '' + Math.floor(Math.abs(offsetInMin) / 60);
    return 'UTC' + (offsetInMin < 0 ? '+' : '-') + hour.padStart(2, '0') + ':' + min.padStart(2, '0');
  }

  public static getNow(marketplace: Marketplace): moment.Moment {
    return moment.tz(Marketplaces[marketplace]?.timeZone ?? 'Europe/Paris');
  }

  // use to check switch cases are exhaustive
  public static assertUnreachable(x: never): never {
    throw new Error("Didn't expect to get here");
  }

  public static toMoment(date: string, marketplace?: Marketplace): moment.Moment {
    if (marketplace) {
      return moment.tz(date, Marketplaces[marketplace].timeZone).startOf('day');
    }
    return moment.utc(date).startOf('day');
  }

  public static formatMomentDate(date: moment.Moment): string {
    return date.format('YYYY-MM-DD');
  }

  public static getDateFormatString(locale: string) {
    const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());
    return formatObj
      .map((obj) => {
        switch (obj.type) {
          case 'day':
            return 'DD';
          case 'month':
            return 'MM';
          case 'year':
            return 'YYYY';
          default:
            return obj.value;
        }
      })
      .join('');
  }

  public static getDateFormatStringForPrimeNg(locale: string = 'en-US') {
    const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());
    return formatObj
      .map((obj) => {
        switch (obj.type) {
          case 'day':
            return 'dd';
          case 'month':
            return 'mm';
          case 'year':
            return 'yy';
          default:
            return obj.value;
        }
      })
      .join('');
  }

  public static historyNow(): string {
    return moment.utc().format('YYYY-MM-DDTHH:mm:ss');
  }

  public static estimatedNbHoursBeforeNextPush(
    platform: Platform,
    bidderRequest: string | undefined,
    lastBidderStart: string | undefined,
    lastBidderEnd: string | undefined,
    lastUpdate: string,
  ): number | undefined {
    if (!lastUpdate) return undefined;

    let startOfBidder = 0;
    let enfOfBidder = 0;
    switch (platform) {
      case Platform.EU:
        startOfBidder = 7;
        enfOfBidder = 11;
        break;
      case Platform.NA:
        startOfBidder = 13;
        enfOfBidder = 15;
        break;
      case Platform.FE:
        startOfBidder = 22;
        enfOfBidder = 23;
        break;
    }

    const startOfBidderToday = moment.utc().startOf('day').add(startOfBidder, 'hours');
    const endOfBidderToday = moment.utc().startOf('day').add(enfOfBidder, 'hours');
    const endOfBidderTomorrow = moment.utc().startOf('day').add(1, 'day').add(enfOfBidder, 'hours');

    const lastUpdateDate = moment.utc(lastUpdate);
    const bidderRequestDate = bidderRequest ? moment.utc(bidderRequest) : undefined;
    const lastBidderStartDate = lastBidderStart ? moment.utc(lastBidderStart) : undefined;
    const lastBidderEndDate = lastBidderEnd ? moment.utc(lastBidderEnd) : undefined;

    if (lastBidderStartDate! > lastUpdateDate) {
      if (lastBidderEndDate! > lastUpdateDate) {
        // last update already pushed
        return undefined;
      }
      // bidder is running
      return 2;
    }

    // C'est trop complex, on devrait calculer toutes les règles et prendre le min

    const now = moment.utc();

    // bidder triggered & not yet started
    if (
      bidderRequest &&
      bidderRequestDate! > lastUpdateDate &&
      (!lastBidderStartDate || bidderRequestDate! > lastBidderStartDate)
    ) {
      // bidder will start soon
      return 3;
    }

    // bidder already started today & last update after bidder start
    if (lastBidderStartDate && lastUpdateDate > lastBidderStartDate && lastUpdateDate > startOfBidderToday) {
      // last update after today bidder start => update for tomorrow
      return Math.floor(moment.duration(endOfBidderTomorrow.diff(now)).asHours());
    }

    // bidder not yet triggered
    if (!bidderRequest || startOfBidderToday > bidderRequestDate!) {
      if (now > startOfBidderToday) {
        // bidder will run tomorrow
        return Math.floor(moment.duration(endOfBidderTomorrow.diff(now)).asHours());
      }
      // bidder will run today
      return Math.floor(moment.duration(endOfBidderToday.diff(now)).asHours());
    }

    if (now > endOfBidderToday) {
      // bidder will run tomorrow
      return Math.floor(moment.duration(endOfBidderTomorrow.diff(now)).asHours());
    }

    return Math.floor(moment.duration(endOfBidderToday.diff(now)).asHours()) + 1;
  }

  public static genColor(seed: string): string {
    let hash1 = 0;
    let hash2 = 0;

    const brandLen: number = seed.length;
    for (let i = 0; i < seed.length; i++) {
      hash1 = seed.charCodeAt(i) + ((hash1 << 5) - hash1);
      hash2 = seed.charCodeAt((i + 1) % brandLen) + ((hash2 << 5) - hash2);
    }
    hash1 = Math.abs(hash1);
    hash2 = Math.abs(hash2);
    return this.PALETTES[hash1 % this.PALETTES.length][hash2 % this.PALETTES[hash1 % this.PALETTES.length].length];
  }

  public static getDateIntervalInDays(dateRange: string[]) {
    const minDate = Utils.toMoment(dateRange[0]);
    const maxDate = Utils.toMoment(dateRange[1]);
    return maxDate.diff(minDate, 'days');
  }

  public static titleCase(value?: string) {
    if (!value || value.length === 0) {
      return value;
    }
    if (value.length === 1) {
      return value.toUpperCase();
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  // arbitrary strings to generate random names
  private static readonly adjectives = [
    'Adorable',
    'Beautiful',
    'Clean',
    'Drab',
    'Elegant',
    'Fancy',
    'Glamorous',
    'Handsome',
    'Long',
    'Magnificent',
    'Old-fashioned',
    'Plain',
    'Quaint',
    'Sparkling',
    'Ugliest',
    'Wide-eyed',
    'Red',
    'Orange',
    'Yellow',
    'Green',
    'Blue',
    'Purple',
    'Gray',
    'Black',
    'White',
    'Alive',
    'Better',
    'Careful',
    'Clever',
    'Dead',
    'Easy',
    'Famous',
    'Gifted',
    'Helpful',
    'Important',
    'Inexpensive',
    'Mushy',
    'Odd',
    'Powerful',
    'Rich',
    'Shy',
    'Tender',
    'Uninterested',
    'Vast',
    'Wrong',
  ];
  private static readonly nouns = [
    'Waterfall',
    'River',
    'Breeze',
    'Moon',
    'Rain',
    'Wind',
    'Sea',
    'Morning',
    'Snow',
    'Lake',
    'Sunset',
    'Pine',
    'Shadow',
    'Leaf',
    'Dawn',
    'Glitter',
    'Forest',
    'Hill',
    'Cloud',
    'Meadow',
    'Sun',
    'Glade',
    'Bird',
    'Brook',
    'Butterfly',
    'Bush',
    'Dew',
    'Dust',
    'Field',
    'Fire',
    'Flower',
    'Firefly',
    'Feather',
    'Grass',
    'Haze',
    'Mountain',
    'Night',
    'Pond',
    'Darkness',
    'Snowflake',
    'Silence',
    'Sound',
    'Sky',
    'Shape',
    'Surf',
    'Thunder',
    'Violet',
    'Water',
    'Wildflower',
    'Wave',
    'Water',
    'Resonance',
    'Sun',
    'Wood',
    'Dream',
    'Cherry',
    'Tree',
    'Fog',
    'Frost',
    'Voice',
    'Paper',
    'Frog',
    'Smoke',
    'Star',
  ];

  public static generateRandomName() {
    return (
      this.adjectives[Math.floor(Math.random() * this.adjectives.length)] +
      ' ' +
      this.nouns[Math.floor(Math.random() * this.nouns.length)]
    );
  }
}
