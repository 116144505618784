import { AdStats } from '../api-client';
import { CatalogBrandEx } from './CatalogBrandEx';

export interface AdStatsEx extends AdStats {
  // We will have all the VENDORS sourcing OR manufacturing fields here.
  vendorInventoryAdStats?: VendorInventoryAdStats;
  catalogBrand?: CatalogBrandEx;
  catalogBrandName?: string;
  outOfStockGlanceViews?: number;
  m19Cost?: number;
  m19AdSales?: number;
  m19Clicks?: number;
  m19Impressions?: number;
  m19AdConversions?: number;
  unmanagedAdSales?: number;
  unmanagedCost?: number;
  unmanagedAdConversions?: number;
  unmanagedClicks?: number;
  unmanagedImpressions?: number;
}

// Inventory stats
export interface VendorInventoryAdStats {
  inventorySellableUnits?: number;
  inventorySellableCost?: number;
  inventoryUnsellableUnits?: number;
  inventoryUnsellableCost?: number;
  inventoryOpenPurchaseOrderUnits?: number;
  inventoryAged90pSellableUnits?: number;
  inventoryAged90pSellableCost?: number;
  inventorySourceableProductOutOfStockRate?: number;
  lastVendorInventoryDate?: string;
}

export type RequiredAdStatEx = Omit<
  Required<AdStatsEx>,
  | 'marketplace'
  | 'vendorInventoryAdStats'
  | 'catalogBrand'
  | 'catalogBrandName'
  | 'accountId'
  | 'accountName'
  | 'hour'
  | 'query'
  | 'queryType'
  | 'campaignId'
  | 'campaignType'
  | 'placement'
  | 'strategyId'
  | 'creativeId'
  | 'subStrategyId'
  | 'currency'
  | 'keywordText'
  | 'matchType'
  | 'brandId'
  | 'adOrderedUnits'
  | 'netPureProductMargin'
  | 'segmentType'
  | 'lastPpmDate'
  | 'vendorInventory'
  | 'tacosStrategyGroupId'
  | 'isM19'
>;
