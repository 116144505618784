<ng-container *transloco="let t">
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ t("new-account-group-popup.account_group_creation") }}</h4>
  </div>

  @if (!loading) {
    <div class="modal-body">
      <div class="row">
        <span class="col-2 my-auto">{{ t("common.name") }}</span>
        <input
          class="form-control col-10"
          maxlength="200"
          [(ngModel)]="name"
          placeholder="{{ t('new-account-group-popup.give_name_placeholder') }}"
        />
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
        {{ t("common.cancel") }}
      </button>
      <button type="submit" class="btn btn-primary" (click)="create()" [disabled]="name.trim().length === 0">
        {{ t("common.create") }}
      </button>
    </div>
  }
  <app-spinner [display]="loading"></app-spinner>
</ng-container>
