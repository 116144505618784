<div class="p-4" *transloco="let t">
  <div class="row mt-5">
    <div class="col-12">
      @if (organization?.starterPlanBlacklisted) {
        <div class="card-group">{{ t("selfservice-freemium.starter_plan_no_available_please_conctat_us") }}</div>
      } @else {
        @if (!currentBillingPlan) {
          <div class="card-group">
            <div class="card">
              <div class="card-header bg-transparent">
                <h2>{{ t("selfservice-freemium.select_account") }}</h2>
              </div>
              <div class="card-body">
                <app-billing-account-selection
                  [onlyOneAccount]="true"
                  (save)="activate($event)"
                  (cancel)="backToBilling()"
                ></app-billing-account-selection>
              </div>
            </div>
          </div>
        }
        @if (currentBillingPlan) {
          <div class="card-group">{{ t("selfservice-freemium.you_already_have_a_plan") }}</div>
        }
      }
    </div>
  </div>
</div>
