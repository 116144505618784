// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    PreModerationComponent,
} from './';

/**
 * @export
 * @interface PreModeration
 */
export interface PreModeration {
    /**
     * @type {string}
     * @memberof PreModeration
     */
    code?: string;
    /**
     * @type {string}
     * @memberof PreModeration
     */
    details?: string;
    /**
     * @type {string}
     * @memberof PreModeration
     */
    preModerationId?: string;
    /**
     * @type {string}
     * @memberof PreModeration
     */
    adProgram?: string;
    /**
     * @type {string}
     * @memberof PreModeration
     */
    locale?: string;
    /**
     * @type {Array<PreModerationComponent>}
     * @memberof PreModeration
     */
    textComponents?: Array<PreModerationComponent>;
    /**
     * @type {Array<PreModerationComponent>}
     * @memberof PreModeration
     */
    imageComponents?: Array<PreModerationComponent>;
    /**
     * @type {Array<PreModerationComponent>}
     * @memberof PreModeration
     */
    videoComponents?: Array<PreModerationComponent>;
}
