import { CdkOverlayOrigin } from "@angular/cdk/overlay";
import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { faSquare, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faAngleUp, faCheckSquare, faPencilAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { AccountSelectionService, AuthService, ProductGroupService } from "@front/m19-services";
import { InputModalComponent } from "@m19-board/shared/input-modal/input-modal.component";
import { ICON_ADD, ICON_IMPORT } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { forkJoin, switchMap } from "rxjs";
import {
  BulkImportService,
  ProductGroupBulkOperations,
  ProductGroupUploadResult,
} from "../strategy-bulk-upload-modal/bulk-import.service";
import { ProductGroupBulkUploadModalComponent } from "./product-group-bulk/product-group-bulk-upload-modal.component";
import { ProductGroupBulkUploadReportModalComponent } from "./product-group-bulk/product-group-bulk-upload-report-modal.component";
import { ProductGroupBulkUploadResultModalComponent } from "./product-group-bulk/product-group-bulk-upload-result-modal.component";
import { TranslocoService } from "@jsverse/transloco";
import { AccountMarketplace, Marketplace, ProductGroup } from "@front/m19-api-client";
import { ProductGroupEx } from "@front/m19-models";

/* Modal component to create a new Product Group*/
@Component({
  selector: "app-modal-content",
  templateUrl: "./product-group-creation.component.html",
  styleUrls: ["./product-group.component.scss"],
})
export class ModalCreateProductGroupComponent {
  pgName: string;
  accountId: string;
  marketplace: Marketplace;
  productGroupPage = "/products/product-group";

  constructor(
    public productGroupService: ProductGroupService,
    public bsModalRef: BsModalRef,
    private toastrService: ToastrService,
    private router: Router,
    private authService: AuthService,
  ) {
    this.authService.loggedUser$.subscribe((user) => {
      if ((user?.uiVersion ?? 0) > 0) {
        this.productGroupPage = "/product-center/product-group";
      }
    });
  }

  private gotoProductGroupPage(productGroup: ProductGroupEx, marketplace: Marketplace) {
    this.router.navigate([`${this.productGroupPage}/${productGroup.productGroupId}`], {
      queryParams: { marketplace: marketplace },
    });
  }

  create() {
    this.productGroupService.createProductGroup(this.accountId, this.marketplace, this.pgName).subscribe({
      next: (productGroup) => {
        this.gotoProductGroupPage(productGroup, this.marketplace);
        this.toastrService.success("Product Group Created");
        this.bsModalRef.hide();
      },
      error: (error: string) => {
        this.toastrService.error(error, "Product Group Creation Error");
        this.bsModalRef.hide();
      },
    });
  }
}

/* Modal component to delete a Product Group*/
@Component({
  selector: "app-modal-content",
  templateUrl: "./product-group-deletion.component.html",
  styleUrls: ["./product-group.component.scss"],
})
export class ModalDeleteProductGroupComponent {
  productGroup: ProductGroupEx;
  accountMarketplace: AccountMarketplace;

  constructor(
    public productGroupService: ProductGroupService,
    public bsModalRef: BsModalRef,
    public toastrService: ToastrService,
  ) {}

  delete() {
    this.productGroupService
      .deleteProductGroup(
        this.accountMarketplace.accountId,
        this.accountMarketplace.marketplace,
        this.productGroup.productGroupId,
      )
      .subscribe({
        next: () => {
          this.toastrService.success("Product Group deleted");
          this.bsModalRef.hide();
        },
        error: (error: string) => {
          this.toastrService.error(error, "Product Group deletion error");
        },
      });
  }
}

//Main component
@UntilDestroy()
@Component({
  selector: "app-product-group",
  templateUrl: "./product-group.component.html",
  styleUrls: ["./product-group.component.scss"],
})
export class ProductGroupComponent implements OnInit {
  accountMarketplace: AccountMarketplace;
  productGroups: ProductGroupEx[] = undefined;
  isReadOnly = false;

  readonly dataSource = new MatTableDataSource<ProductGroupEx>();
  private readonly selectedProductGroupId = new Set<number>();
  private readonly expandedProductGroupId = new Set<number>();
  readonly displayedColumns = ["selection", "productGroupName", "productsNb", "products", "actions"];

  readonly faPlusCircle = faPlusCircle;
  readonly faPencil = faPencilAlt;
  readonly faTrash = faTrashAlt;
  readonly faCheckedSquare = faCheckSquare;
  readonly faSquare = faSquare;
  readonly faAngleUp = faAngleUp;
  readonly ICON_IMPORT = ICON_IMPORT;
  readonly ICON_ADD = ICON_ADD;

  productDetailsOrigin: CdkOverlayOrigin;
  productDetailsAsin?: string = undefined;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("productDetailsPopup") productDetailsPopup: ElementRef;

  productGroupPage = "/products/product-group";

  constructor(
    private accountSelectionService: AccountSelectionService,
    private productGroupService: ProductGroupService,
    private modalService: BsModalService,
    private bulkImportService: BulkImportService,
    private toastrService: ToastrService,
    private router: Router,
    private authService: AuthService,
    private translocoService: TranslocoService,
  ) {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      if ((user?.uiVersion ?? 0) > 0) {
        this.productGroupPage = "/product-center/product-group";
      }
    });
  }

  ngOnInit() {
    this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.accountMarketplace = am;
    });
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am: AccountMarketplace) => this.productGroupService.getProductGroups(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((pg) => {
        this.productGroups = pg;
        this.dataSource.data = pg;
      });
    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property): string | number => {
      if (property == "productGroupName") return item.name;
      if (property == "productsNb") return item.items.length;
      return 0;
    };
    this.dataSource.filterPredicate = (row, filter) => {
      if (filter) {
        const regexp = new RegExp(filter, "i");
        return regexp.test(row.name) || row.items.findIndex((item) => regexp.test(item)) != -1;
      }
      return true;
    };
  }

  createProductGroup(accountId: string, marketplace: Marketplace): void {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: accountId,
        marketplace: marketplace,
      },
      class: "modal-primary",
    };
    this.modalService.show(ModalCreateProductGroupComponent, modalOptions);
  }

  deleteProductGroup(productGroup: ProductGroupEx) {
    const modalOptions: ModalOptions = {
      initialState: { productGroup: productGroup, accountMarketplace: this.accountMarketplace },
      class: "modal-danger",
    };
    this.modalService.show(ModalDeleteProductGroupComponent, modalOptions);
  }

  openProductGroupPage(productGroup: ProductGroupEx) {
    this.router.navigate([`${this.productGroupPage}/${productGroup.productGroupId}`], {
      queryParamsHandling: "merge",
    });
  }

  deleteSelected() {
    const toDelete: ProductGroup[] = [];
    for (const productGroupId of this.selectedProductGroupId) {
      const productGroup = this.productGroups.find((pg) => pg.productGroupId === productGroupId);
      if (productGroup == undefined) {
        continue;
      }
      toDelete.push(productGroup);
    }
    if (toDelete.length > 0) {
      forkJoin(
        toDelete.map((pg) =>
          this.productGroupService.deleteProductGroup(
            this.accountMarketplace.accountId,
            this.accountMarketplace.marketplace,
            pg.productGroupId,
          ),
        ),
      ).subscribe({
        next: () => {
          this.toastrService.success("Product Groups deleted");
        },
        error: (error: string) => {
          this.toastrService.error(`${error}`, "Product Group deletion error");
        },
      });
    }
  }

  selected(productGroup: ProductGroupEx) {
    return this.selectedProductGroupId.has(productGroup.productGroupId);
  }

  expanded(productGroup: ProductGroupEx) {
    return this.expandedProductGroupId.has(productGroup.productGroupId);
  }

  toggleSelectAll() {
    const selected = this.allSelected();
    if (selected) {
      this.selectedProductGroupId.clear();
    } else {
      for (const pg of this.dataSource.filteredData) {
        this.selectedProductGroupId.add(pg.productGroupId);
      }
    }
  }

  allSelected() {
    return this.dataSource.filteredData.every((s) => this.selected(s));
  }

  nbSelected() {
    return this.selectedProductGroupId.size;
  }

  select(productGroup: ProductGroupEx) {
    if (this.selectedProductGroupId.has(productGroup.productGroupId)) {
      this.selectedProductGroupId.delete(productGroup.productGroupId);
    } else {
      this.selectedProductGroupId.add(productGroup.productGroupId);
    }
  }

  toggleExpansion(productGroup: ProductGroupEx) {
    if (this.expandedProductGroupId.has(productGroup.productGroupId)) {
      this.expandedProductGroupId.delete(productGroup.productGroupId);
    } else {
      this.expandedProductGroupId.add(productGroup.productGroupId);
    }
  }

  setFilter(filter: string) {
    this.dataSource.filter = filter;
  }

  uploadProductGroups(bulkData = "") {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.accountMarketplace.accountId,
        marketplace: this.accountMarketplace.marketplace,
        productGroups: this.productGroups,
        bulkData,
      },
      class: "modal-xl",
    };
    const modalRef = this.modalService.show(ProductGroupBulkUploadModalComponent, modalOptions);

    const subscription = modalRef.content.productGroupBulkOperations.subscribe((result) => {
      this.displayBulkOperations(result);
      subscription.unsubscribe();
    });
  }

  editProductGroupName(productGroup: ProductGroupEx) {
    const productGroupNameInput = new FormControl<string>(productGroup.productGroupName);
    const ref = this.modalService.show(InputModalComponent, {
      initialState: {
        title: this.translocoService.translate("product-group.change_product_group_name"),
        inputControl: productGroupNameInput,
        maxLength: 80,
      },
      class: "modal-primary",
    });
    ref.content.emitUpdate
      .pipe(
        switchMap(() =>
          this.productGroupService.updateProductGroupName(
            this.accountMarketplace.accountId,
            this.accountMarketplace.marketplace,
            productGroup.productGroupId,
            productGroupNameInput.value,
          ),
        ),
      )
      .subscribe({
        next: () => {
          this.toastrService.success("Product Group name updated");
        },
        error: (error: string) => {
          this.toastrService.error(error, "Product Group name update error");
        },
      });
  }

  private displayBulkOperations(bulkOperations: ProductGroupBulkOperations) {
    const modalOptions: ModalOptions = {
      initialState: {
        productGroupBulkOperations: bulkOperations,
        accountId: this.accountMarketplace.accountId,
        marketplace: this.accountMarketplace.marketplace,
      },
      class: "modal-xxl",
    };
    const modalRef = this.modalService.show(ProductGroupBulkUploadReportModalComponent, modalOptions);
    const subscriptions = modalRef.content.bulkOperationResult.subscribe((result) => {
      this.displayUploadResult(result);
      subscriptions.unsubscribe();
    });
    subscriptions.add(
      modalRef.content.uploadCancelled.subscribe(() => {
        this.uploadProductGroups(bulkOperations.bulkData);
        subscriptions.unsubscribe();
      }),
    );
  }

  private displayUploadResult(uploadResult: ProductGroupUploadResult) {
    const modalOptions: ModalOptions = {
      initialState: {
        uploadResult: uploadResult,
      },
      class: "modal-primary",
    };
    this.modalService.show(ProductGroupBulkUploadResultModalComponent, modalOptions);
  }

  exportProductGroups() {
    this.bulkImportService.exportProductGroupCsv(this.accountMarketplace, this.productGroups);
  }

  displayProductDetails(trigger: CdkOverlayOrigin, asin: string) {
    this.productDetailsOrigin = trigger;
    this.productDetailsAsin = asin;
  }

  @HostListener("document:mousemove", ["$event"])
  handleMouseMove(event: MouseEvent) {
    // check if still inside product details elements
    if (
      this.productDetailsOrigin &&
      this.productDetailsAsin &&
      !this.productDetailsOrigin.elementRef.nativeElement.contains(event.target) &&
      !this.productDetailsPopup.nativeElement.contains(event.target)
    ) {
      this.hideProductDetails();
    }
  }

  private hideProductDetails() {
    this.productDetailsOrigin = undefined;
    this.productDetailsAsin = undefined;
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
