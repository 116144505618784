<div class="flex w-full flex-col gap-2">
  <div class="relative w-full">
    <meter
      class="block h-4 w-full appearance-none overflow-y-hidden rounded-full bg-gray-300 bg-none [&::-moz-meter-bar]:h-4 [&::-moz-meter-bar]:rounded-full [&::-moz-meter-bar]:border-none [&::-moz-meter-bar]:bg-current [&::-moz-meter-bar]:bg-none [&::-moz-meter-bar]:transition-all [&::-webkit-meter-bar]:border-none [&::-webkit-meter-bar]:bg-transparent [&::-webkit-meter-bar]:bg-none [&::-webkit-meter-inner-element]:relative [&::-webkit-meter-inner-element]:block [&::-webkit-meter-inner-element]:border-none [&::-webkit-meter-inner-element]:bg-transparent [&::-webkit-meter-inner-element]:bg-none [&::-webkit-meter-optimum-value]:h-4 [&::-webkit-meter-optimum-value]:rounded-full [&::-webkit-meter-optimum-value]:border-none [&::-webkit-meter-optimum-value]:bg-current [&::-webkit-meter-optimum-value]:bg-none [&::-webkit-meter-optimum-value]:transition-all"
      [ngClass]="color === 'main' ? 'text-main' : 'text-amber-500'"
      [min]="min"
      [max]="max"
      [value]="value ?? 0"
    ></meter>
    @if (label) {
      <span class="absolute right-2 top-0 text-xs text-white">{{ label }}</span>
    }
  </div>
  @if (description) {
    <div class="text-left text-xs font-medium" [ngClass]="color === 'main' ? 'text-main' : 'text-amber-500'">
      {{ description }}
    </div>
  }
</div>
