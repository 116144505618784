// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EligibilityStatus {
    ELIGIBLE = 'ELIGIBLE',
    ELIGIBLE_WITH_WARNING = 'ELIGIBLE_WITH_WARNING',
    LAST_PRODUCT_AD_CREATION_FAILED = 'LAST_PRODUCT_AD_CREATION_FAILED',
    INELIGIBLE = 'INELIGIBLE',
    UNKNOWN = 'UNKNOWN',
    NOT_AUTHORIZED = 'NOT_AUTHORIZED'
}

