import { NgClass } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { Marketplace } from "@front/m19-api-client";
import { buildProductEx, ProductEx } from "@front/m19-models";
import { AsinService } from "@front/m19-services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-product-group-thumbnail",
  templateUrl: "product-group-thumbnail.component.html",
  imports: [NgClass],
  standalone: true,
})
export class ProductGroupThumbnailComponent implements OnInit {
  @Input() asins: string[];
  @Input() marketplace: Marketplace;
  @Input() max = 3;

  products: ProductEx[];

  constructor(private asinService: AsinService) {}

  ngOnInit(): void {
    const asins = this.asins.slice(0, this.max);
    this.products = asins.map((a) =>
      buildProductEx({
        asin: a,
        marketplace: this.marketplace,
      } as any),
    );

    asins.forEach((asin, index) => {
      this.asinService
        .getProductWithMarketplace(asin, this.marketplace)
        .pipe(untilDestroyed(this))
        .subscribe((product) => {
          this.products[index] = product;
        });
    });
  }
}
