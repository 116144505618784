import { Component, inject, signal } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { environment } from "../../../environments/environment";
import { IAlertComponent, RequestPasswordResetFormComponent, RequestPasswordResetFormValue } from "@front/m19-ui";
import { AjaxResponse } from "rxjs/ajax";
import { UserApi } from "@front/m19-api-client";
import { TranslocoDirective } from "@jsverse/transloco";

@UntilDestroy()
@Component({
  selector: "app-request-password-reset",
  templateUrl: "request-password-reset.component.html",
  standalone: true,
  imports: [TranslocoDirective, IAlertComponent, RequestPasswordResetFormComponent],
})
export class RequestPasswordResetComponent {
  private readonly userApi = inject(UserApi);

  isTest = !environment.production;

  loading = signal(false);
  success = signal(false);
  error = signal<string | null>(null);

  requestPasswordReset(formValue: RequestPasswordResetFormValue): void {
    if (!formValue) return;
    this.loading.set(true);
    this.userApi
      .forgotPassword({ email: formValue.email, domain: window.location.hostname, color: "#4b5563" })
      .subscribe({
        next: () => {
          this.loading.set(false);
          this.success.set(true);
          this.error.set(null);
        },
        error: (err: AjaxResponse<any>) => {
          this.error.set(err?.response?.message ?? "An error occurred: " + (err as any)["message"]);
          this.loading.set(false);
          this.success.set(false);
        },
      });
  }
}
