import { Component } from "@angular/core";
import { ICellRendererParams } from "@ag-grid-community/core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { Marketplace, Strategy } from "@front/m19-api-client";
import { ModalOptions, ModalService } from "@front/m19-ui";
import { TranslocoDirective } from "@jsverse/transloco";
import { NgClass } from "@angular/common";
import { MatTooltip } from "@angular/material/tooltip";

@Component({
  selector: "app-strategy-count-renderer",
  templateUrl: "./strategy-count-renderer.component.html",
  styleUrls: ["./seller-inventory.component.scss"],
  standalone: true,
  imports: [TranslocoDirective, NgClass, MatTooltip],
})

// This component takes a list of strategy
// the modal that its supposed to open and the options of the modal
// and the inModalName which is the way the modal reference the object (ex : invenotry uses product.asin)
export class StrategyCountRendererComponent implements ICellRendererAngularComp {
  marketplace?: Marketplace;
  strategyList: Strategy[] = [];
  inModalName!: string;
  modalComponent: any;
  modalOptions!: ModalOptions<any>;

  constructor(private modalService: ModalService) {}

  agInit(
    params: ICellRendererParams & {
      marketplace: Marketplace;
      strategyList: Strategy[];
      inModalName: string;
      modalComponent: any;
      modalOptions: ModalOptions<any>;
    },
  ): void {
    this.marketplace = params.marketplace;
    this.strategyList = params.strategyList;
    this.modalComponent = params.modalComponent;
    this.modalOptions = params.modalOptions;
  }

  refresh(params: ICellRendererParams) {
    return true;
  }

  openInfo() {
    this.modalService.openModal(this.modalComponent, this.modalOptions);
  }
}
