import { QueryKeywordGraph } from '@front/m19-api-client';

export class QueryKeywordGraphEx {
  query: string;
  keyword: string;
  impressions: number;
  blacklisted: boolean;

  public constructor(query: QueryKeywordGraph, isBlacklisted: boolean) {
    this.query = query.query!;
    this.keyword = query.keyword!;
    this.impressions = query.impressions!;
    this.blacklisted = isBlacklisted;
  }
}
