// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum M19Status {
    LAST_BIDDER_PUSH_ISSUE = 'LAST_BIDDER_PUSH_ISSUE',
    IGNORED_CREATIVE_ASINS_INELIGIBLE = 'IGNORED_CREATIVE_ASINS_INELIGIBLE',
    IGNORED_ASINS_NOT_IN_STORE_PAGE = 'IGNORED_ASINS_NOT_IN_STORE_PAGE',
    IGNORED_INVALID_BRAND_LOGO = 'IGNORED_INVALID_BRAND_LOGO',
    IGNORED_INVALID_CUSTOM_IMAGE = 'IGNORED_INVALID_CUSTOM_IMAGE',
    IGNORED_NOT_ENOUGH_ASINS_FOR_LANDING_PAGE = 'IGNORED_NOT_ENOUGH_ASINS_FOR_LANDING_PAGE',
    IGNORED_REJECTED = 'IGNORED_REJECTED',
    IGNORED_LOW_INVENTORY_ASINS = 'IGNORED_LOW_INVENTORY_ASINS',
    PUSH_FAILED_INVALID_ASINS = 'PUSH_FAILED_INVALID_ASINS',
    PUSH_FAILED_ASSET_NOT_FOUND = 'PUSH_FAILED_ASSET_NOT_FOUND',
    PUSH_FAILED_BILLING_ERROR = 'PUSH_FAILED_BILLING_ERROR',
    PUSH_FAILED_INVALID_HEADLINE = 'PUSH_FAILED_INVALID_HEADLINE',
    PUSH_FAILED = 'PUSH_FAILED',
    PUSH_FAILED_NO_BRAND_REGISTERED = 'PUSH_FAILED_NO_BRAND_REGISTERED',
    REJECTED_HEADLINE = 'REJECTED_HEADLINE',
    REJECTED_VIDEO = 'REJECTED_VIDEO',
    REJECTED_IMAGE = 'REJECTED_IMAGE',
    REJECTED_ASIN = 'REJECTED_ASIN',
    REJECTED_TEXT = 'REJECTED_TEXT',
    REJECTED = 'REJECTED',
    ENDED_CAMPAIGN = 'ENDED_CAMPAIGN'
}

