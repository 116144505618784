import { Component, inject, Input, OnInit } from "@angular/core";
import { AccountMarketplace, Currency, SbAsins, SbCreativeType } from "@front/m19-api-client";
import { AccountSelectionService, AsinService } from "@front/m19-services";
import { ICON_CHEVRON_DOWN } from "@m19-board/utils/iconsLabels";
import { switchMap } from "rxjs";
import { SbCrea } from "../sb-form-creative/sb-form-creative.component";
import { Catalog, ProductEx } from "@front/m19-models";

@Component({
  selector: "app-sb-form-crea-preview",
  templateUrl: "./sb-form-crea-preview.component.html",
})
export class SbFormCreaPreviewComponent implements OnInit {
  private readonly accountSelection = inject(AccountSelectionService);
  private readonly asinService = inject(AsinService);
  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;
  readonly DEFAULT_AD_LINE_PRODUCTS = [[undefined]];
  readonly range5 = Array.from({ length: 5 }, (_, i) => i);

  @Input() creativeType: string;

  @Input() set asins(a: SbAsins[]) {
    this._asins = a;
    this._adLineProducts = this.toAdLineProducts(a);
  }

  _asins: SbAsins[];
  @Input() formCrea?: SbCrea;
  @Input() brandName: string;
  @Input() isVendor = false;
  @Input() am: AccountMarketplace;
  @Input() currency: Currency;

  _adLineProducts: ProductEx[][] = this.DEFAULT_AD_LINE_PRODUCTS;
  asinOffers: Map<string, ProductEx>;

  ngOnInit(): void {
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(switchMap((am) => this.asinService.getCatalog(am.accountId, am.marketplace)))
      .subscribe((c: Catalog) => {
        this.asinOffers = c.asinOffers;
        if (this._asins) {
          this._adLineProducts = this.toAdLineProducts(this._asins);
        }
      });
  }

  roundToNearestHalf(num: number): number {
    return Math.round(num * 2) / 2;
  }

  private toAdLineProducts(asins: SbAsins[]): ProductEx[][] {
    if (!asins || !this.asinOffers) return this.DEFAULT_AD_LINE_PRODUCTS;
    return asins.map((a) => [this.asinOffers.get(a.asin1), this.asinOffers.get(a.asin2), this.asinOffers.get(a.asin3)]);
  }

  readonly SbCreativeType = SbCreativeType;
}
