@if (!isMarketplaceSupported) {
  <div class="alert alert-warning" role="alert" *transloco="let t">
    {{ t("keyword-extraction.extraction_not_available_here") }}
  </div>
}
<div class="card-group border-shadow mb-4" *transloco="let t">
  @for (searchTerm of searchTerms; track searchTerm; let index = $index) {
    <div class="card">
      <app-search-term-card
        [searchTerm]="searchTerm"
        [searchVolume]="searchVolumeEstimationFor(searchTermRanks.get(searchTerm) ?? 0, marketplace)"
        [color]="palette[index]"
        (delete)="deleteSearchTerm(searchTerm)"
        (showed)="toggleSearchTerm(searchTerm)"
      >
      </app-search-term-card>
    </div>
  }
  @if (searchTerms.length < 5) {
    <div class="card" *transloco="let t">
      @if (inputSearchTermOn) {
        <div class="card-body flex items-center p-3">
          <div class="btn-group dropdown w-full" dropdown>
            <input
              type="text"
              class="simple-input form-control search-term"
              placeholder="{{ t('search-term-ranks.new_search_term_placeholder') }}"
              aria-label="Add a new search term"
              (input)="inputValue.next(searchTerm.value.toLowerCase())"
              (keydown.enter)="addSearchTerm(searchTerm.value.toLowerCase())"
              (keydown.escape)="setFocusOutSearchTermInput()"
              (focusout)="setFocusOutSearchTermInput()"
              #searchTerm
            />
            @if (suggestions.length > 0) {
              <div class="dropdown-menu inline w-full overflow-auto">
                @for (suggestion of suggestions; track suggestion) {
                  <div
                    class="search-term-dropdown-item dropdown-item flex"
                    (click)="suggestion.searchTerm && addSearchTerm(suggestion.searchTerm)"
                  >
                    <div class="autocomplete-text">{{ suggestion.searchTerm }}</div>
                    <div class="ml-auto">
                      <i
                        ><small>{{ abbreviateNumber(suggestion.searchFrequencyRank ?? 0) }}</small></i
                      >
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      }
      @if (!inputSearchTermOn) {
        <IButton
          variant="ghost"
          size="lg"
          icon="icon-[lucide--plus]"
          [label]="t('search-term-ranks.add_search_term')"
          (onClick)="setFocusInSearchTermInput()"
        />
      }
    </div>
  }
</div>

<div class="graph box card border-shadow my-4 p-4" *transloco="let t">
  <div class="form-group row mt-2">
    @if (aggregation$ | async; as aggregation) {
      <app-date-aggreation-switch-button
        class="ml-4"
        [selected]="aggregation"
        [displayTitle]="false"
        [options]="aggregationsAvailable"
        (dateAggSelected)="selectAggregation($event)"
      ></app-date-aggreation-switch-button>
    }
  </div>
  <div class="chart-wrapper">
    <canvas
      baseChart
      type="line"
      [datasets]="dataset.chartDataSet"
      [labels]="dataset.labels"
      [options]="dataset.lineChartOptions"
    >
    </canvas>
  </div>
</div>

<div class="row" *transloco="let t">
  <div class="col-12">
    <div class="box card border-shadow my-2 p-4">
      <h3 class="text-center">{{ t("search-term-ranks.top_search_term") }}</h3>
      <table mat-table [dataSource]="topTermsDataSource" class="table-hover table-striped mt-4 table text-right">
        <ng-container matColumnDef="searchTerm">
          <th mat-header-cell *matHeaderCellDef class="table-header w-60 text-left">
            {{ t("search-term-ranks.search_terms") }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="w-60 text-left"
            (click)="addSearchTerm(element.searchTerm)"
            [matTooltip]="getTopSearchTermsTableTooltipText()"
          >
            <a
              class="text-color"
              [href]="element.searchTerm | amazonSearchUrlPipe: marketplace"
              target="_blank"
              (click)="$event.stopPropagation()"
              >{{ element.searchTerm }}</a
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="searchFrequencyRank">
          <th mat-header-cell *matHeaderCellDef class="table-header w-30 text-center">
            {{ t("search-term-ranks.search_frequency_rank") }}
          </th>
          <td mat-cell *matCellDef="let element" class="w-30">
            {{ element.searchFrequencyRank }}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="table-header w-10 text-center"></th>
          <td mat-cell *matCellDef="let element; let i = index" class="w-10">
            @if (selectedAccountMarketplace) {
              <div class="flex justify-end">
                <IButton
                  variant="ghost"
                  icon="icon-[lucide--star]"
                  [tooltipValue]="t('search-term-ranks.add_search_term_segment_tooltip')"
                  (onClick)="addToSegment(element.searchTerm)"
                />
              </div>
            }
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
        <tr
          mat-row
          [class]="searchTerms.length < 5 ? 'pointer' : ''"
          *matRowDef="let row; columns: getDisplayedColumns()"
        ></tr>
      </table>
      <mat-paginator [pageSize]="10" showFirstLastButtons #topSearchTermsPaginator></mat-paginator>
    </div>
  </div>
</div>
