<ng-container *transloco="let t">
  <div class="w-full">
    <div class="mx-auto w-full max-w-screen-md pt-16">
      <div>
        @if (user()) {
          <lib-profile-form
            [profileFormValue]="user()!"
            (profileFormChanges)="submitForm($event)"
            [loading]="submitLoading()"
            [disabled]="isReadOnly()"
            (onChangePassword)="changePassword()"
          />
        } @else {
          <div class="flex h-full w-full items-center justify-center">
            <ISpinner [display]="true" />
          </div>
        }
      </div>
    </div>
  </div>
</ng-container>
