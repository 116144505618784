import { Component, Input } from "@angular/core";
import { ChildActivationEnd, NavigationEnd, Router } from "@angular/router";
import { NavData } from "@m19-board/_nav";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@Component({
  selector: "app-tabbed-nav",
  template: ` <ul class="nav-wrapper mb-3">
    @for (link of links; track link) {
      <li
        class="nav-element"
        [class.active]="url.startsWith('/' + link.url)"
        attr.data-testid="tabbed-nav-{{ link.name.replaceAll(' ', '_') }}"
      >
        <a queryParamsHandling="merge" [routerLink]="'/' + link.url"
          >{{ link.name }}
          @if (link.badge) {
            <IBadge [label]="link.badge.text" variant="subtle" size="xs" class="ml-2" />
          }
        </a>
      </li>
    }
  </ul>`,
})
@UntilDestroy()
export class TabbedNavComponent {
  @Input() links: NavData[] = [];
  url: string;

  constructor(router: Router) {
    this.url = router.url;
    router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof ChildActivationEnd) {
        this.url = router.url;
      }
    });
  }
}

export const SALES_ADVERTISING_TAB: NavData = {
  url: "products/sales-advertising",
  name: "Sales & Advertising",
  vendorSupport: true,
};

export const CATALOG_TAB: NavData = {
  url: "products/catalog",
  name: "Catalog",
  vendorSupport: true,
};

export const INVENTORY_TAB: NavData = {
  url: "products/inventory",
  name: "Inventory",
  vendorSupport: false,
};

export const PRODUCT_GROUPS_TAB: NavData = {
  url: "products/product-group",
  name: "Product Groups",
  vendorSupport: true,
};

export const SP_TAB: NavData = {
  url: "strategies/sponsored-product",
  name: "Sponsored Products",
};

export const SB_TAB: NavData = {
  url: "strategies/sponsored-brands",
  name: "Sponsored Brands",
};

export const SD_TAB: NavData = {
  url: "strategies/sponsored-display",
  name: "Sponsored Display",
};

export const STRATEGY_BOOST_TAB: NavData = {
  url: "strategies/boost",
  name: "Promo Days Boost",
};

export const BRAND_ALIAS_TAB: NavData = {
  url: "products/brand-alias",
  name: "Brand Alias",
  vendorSupport: true,
};

export const STRATEGIES_TABS: NavData[] = [SP_TAB, SB_TAB, SD_TAB, STRATEGY_BOOST_TAB];
const PRODUCTS_TABS: NavData[] = [
  SALES_ADVERTISING_TAB,
  INVENTORY_TAB,
  CATALOG_TAB,
  PRODUCT_GROUPS_TAB,
  BRAND_ALIAS_TAB,
];
export const SELLER_PRODUCT_TABS = PRODUCTS_TABS;
export const VENDOR_PRODUCT_TABS = PRODUCTS_TABS.filter((t) => t.vendorSupport);
