import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AccountMarketplace,
  CampaignType,
  Strategy,
  StrategyAsin,
  StrategyStateEnum,
  StrategyType,
} from "@front/m19-api-client";
import { Option } from "@front/m19-ui";
import { AlgoModeConfig } from "@m19-board/strategies/strategies/algo-mode-selection/algo-mode-selection.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Catalog, StrategyEx, StrategyGroupEx } from "@front/m19-models";
import { AsinService } from "@front/m19-services";

export type StrategyGroupProductsToAdd = {
  asins: string[];
  type: "NewProductStrategy" | "ExistingStrategy";
  productStrategy?: StrategyEx;
  productStrategyToCreate?: Strategy;
};

type SelectedProductStrategy = "NewProductStrategy" | StrategyEx;

@UntilDestroy()
@Component({
  templateUrl: "./strategy-group-add-product-modal.component.html",
})
export class StrategyGroupAddProductModalComponent implements OnInit {
  @Input()
  accountMarketplace: AccountMarketplace;

  @Input()
  strategyGroup: StrategyGroupEx;

  @Output()
  newAsins = new EventEmitter<"CANCEL" | StrategyGroupProductsToAdd>();

  asinsToAdd: StrategyAsin[] = [];
  asinEligibility: Map<string, { status: boolean; reason: string }> = new Map();
  productStrategy: Option<SelectedProductStrategy> | undefined;
  productStrategies: Option<SelectedProductStrategy>[] = [];
  algoModeConfig: AlgoModeConfig;
  algoModeConfigValid: boolean;
  productStrategyName: string;

  constructor(
    private bsModalRef: BsModalRef,
    private asinService: AsinService,
  ) {}

  ngOnInit(): void {
    this.asinService
      .getCatalog(this.accountMarketplace.accountId, this.accountMarketplace.marketplace)
      .pipe(untilDestroyed(this))
      .subscribe((catalog: Catalog) => {
        this.asinEligibility = catalog.getSPEligibility();
      });
    this.productStrategies = [
      { label: "A New Main Strategy", value: "NewProductStrategy" },
      ...this.strategyGroup.productStrategies.map((s) => ({
        label: s.name ?? "",
        value: s,
      })),
    ];

    this.productStrategy = this.productStrategies[0];
  }

  close() {
    this.newAsins.emit("CANCEL");
    this.bsModalRef.hide();
  }

  addAsins(asins: StrategyAsin[]) {
    this.asinsToAdd = this.asinsToAdd.concat(asins);
  }

  deleteAsins(asins: StrategyAsin[]) {
    this.asinsToAdd = this.asinsToAdd.filter((a) => !asins.map((x) => x.asin).includes(a.asin));
  }

  setProductStrategy(selected: Option<SelectedProductStrategy>) {
    this.productStrategy = selected;
  }

  setAlgoModeConfig(algoModeConfig: Partial<AlgoModeConfig>) {
    this.algoModeConfig = algoModeConfig as AlgoModeConfig;
  }

  setAlgoModeConfigValid(valid: boolean) {
    this.algoModeConfigValid = valid;
  }

  setProductStrategyName(productStrategyName: string) {
    this.productStrategyName = productStrategyName;
  }

  invalid() {
    return (
      this.asinsToAdd.length == 0 ||
      this.productStrategy == undefined ||
      (this.productStrategy.value == "NewProductStrategy" &&
        (!this.algoModeConfigValid || !this.productStrategyName || this.productStrategyName.length == 0))
    );
  }

  invalidTooltip() {
    if (this.asinsToAdd.length == 0) {
      return "Select ASIN to continue";
    }
    if (this.productStrategy == undefined) {
      return "Select a main strategy to continue";
    }
    if (this.productStrategy.value == "NewProductStrategy" && !this.algoModeConfigValid) {
      return "Algorithm configuration is invalid";
    }
    if (
      this.productStrategy.value == "NewProductStrategy" &&
      (!this.productStrategyName || this.productStrategyName.length == 0)
    ) {
      return "Enter a strategy name";
    }
    return "";
  }

  save() {
    if (this.productStrategy.value == "NewProductStrategy") {
      // open new modal to create a strategy
      this.newAsins.emit({
        asins: this.asinsToAdd.map((a) => a.asin),
        type: "NewProductStrategy",
        productStrategyToCreate: {
          accountId: this.accountMarketplace.accountId,
          marketplace: this.accountMarketplace.marketplace,
          state: StrategyStateEnum.ENABLED,
          campaignType: CampaignType.SP,
          strategyGroupId: this.strategyGroup.strategyGroupId,
          strategyType: StrategyType.PRODUCT,
          defaultStrategy: false,
          tactics: [],
          audienceTargetings: [],
          name: this.productStrategyName,
          ...this.algoModeConfig,
          acosTarget: this.algoModeConfig.acosTarget ? this.algoModeConfig.acosTarget / 100 : undefined,
          asins: this.asinsToAdd,
        },
      });
    } else {
      this.newAsins.emit({
        asins: this.asinsToAdd.map((a) => a.asin),
        type: "ExistingStrategy",
        productStrategy: this.productStrategy.value,
      });
    }
    this.bsModalRef.hide();
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
