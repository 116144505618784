// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    AlgoMode,
    AudienceTargeting,
    CampaignType,
    Marketplace,
    StrategyAsin,
    StrategyKeywords,
    StrategyTactic,
    StrategyType,
    Targeting,
} from './';

/**
 * @export
 * @interface Strategy
 */
export interface Strategy {
    /**
     * Amazon Seller or Vendor accountId
     * @type {string}
     * @memberof Strategy
     */
    accountId: string;
    /**
     * @type {Marketplace}
     * @memberof Strategy
     */
    marketplace: Marketplace;
    /**
     * @type {number}
     * @memberof Strategy
     */
    strategyId?: number;
    /**
     * @type {number}
     * @memberof Strategy
     */
    strategyGroupId?: number;
    /**
     * @type {StrategyType}
     * @memberof Strategy
     */
    strategyType?: StrategyType;
    /**
     * @type {number}
     * @memberof Strategy
     */
    priority?: number;
    /**
     * @type {Array<StrategyAsin>}
     * @memberof Strategy
     */
    asins?: Array<StrategyAsin>;
    /**
     * @type {Array<StrategyKeywords>}
     * @memberof Strategy
     */
    topOfSearchRankings?: Array<StrategyKeywords>;
    /**
     * @type {number}
     * @memberof Strategy
     */
    acosTarget?: number;
    /**
     * @type {string}
     * @memberof Strategy
     */
    state: StrategyStateEnum;
    /**
     * @type {CampaignType}
     * @memberof Strategy
     */
    campaignType: CampaignType;
    /**
     * @type {AlgoMode}
     * @memberof Strategy
     */
    algoMode: AlgoMode;
    /**
     * @type {number}
     * @memberof Strategy
     */
    suggestedBid?: number;
    /**
     * @type {string}
     * @memberof Strategy
     */
    name?: string;
    /**
     * @type {string}
     * @memberof Strategy
     */
    strategyLabel?: string;
    /**
     * @type {number}
     * @memberof Strategy
     */
    dailyBudget?: number;
    /**
     * @type {number}
     * @memberof Strategy
     */
    monthlyBudget?: number;
    /**
     * @type {number}
     * @memberof Strategy
     */
    nextMonthlyBudget?: number;
    /**
     * @type {string}
     * @memberof Strategy
     */
    today?: string;
    /**
     * @type {number}
     * @memberof Strategy
     */
    minDailySpend?: number;
    /**
     * @type {boolean}
     * @memberof Strategy
     */
    disableOtherQueries?: boolean;
    /**
     * @type {boolean}
     * @memberof Strategy
     */
    disableAutoSegment?: boolean;
    /**
     * @type {boolean}
     * @memberof Strategy
     */
    disableProductSegment?: boolean;
    /**
     * @type {boolean}
     * @memberof Strategy
     */
    defaultStrategy: boolean;
    /**
     * @type {number}
     * @memberof Strategy
     */
    primeDayBoost?: number;
    /**
     * @type {number}
     * @memberof Strategy
     */
    constraint?: number;
    /**
     * @type {Array<StrategyTactic>}
     * @memberof Strategy
     */
    tactics: Array<StrategyTactic>;
    /**
     * @type {Array<AudienceTargeting>}
     * @memberof Strategy
     */
    audienceTargetings: Array<AudienceTargeting>;
    /**
     * @type {Array<Targeting>}
     * @memberof Strategy
     */
    targetings?: Array<Targeting>;
    /**
     * @type {string}
     * @memberof Strategy
     */
    date?: string;
    /**
     * @type {number}
     * @memberof Strategy
     */
    daypartingPauseHour?: number | null;
    /**
     * @type {number}
     * @memberof Strategy
     */
    daypartingReactivationHour?: number | null;
    /**
     * @type {string}
     * @memberof Strategy
     */
    brandEntityId?: string;
    /**
     * @type {number}
     * @memberof Strategy
     */
    computedDailyBudget?: number;
    /**
     * @type {boolean}
     * @memberof Strategy
     */
    asinIsolation?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum StrategyStateEnum {
    PAUSED = 'PAUSED',
    ENABLED = 'ENABLED'
}

