import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { AudienceExpressionType, AudienceMatchType, TacticType } from "@front/m19-api-client";
import {
  SdTargetingType,
  SegmentConfigType,
  SegmentEx,
  StrategyEx,
  SupportedAudienceLookback,
  SupportedAudienceMatchType,
} from "@front/m19-models";
import { Constant, SdStrategiesService, SegmentService, StrategyService } from "@front/m19-services";
import { IButtonComponent, ISelectComponent, Option } from "@front/m19-ui";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { SegmentCreactionRequest } from "@m19-board/strategies/strategy-page/tactic-add-popup.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { take } from "rxjs";
import { NgIf } from "@angular/common";

/**
 * Modal component to create a new SD targeting
 */
@UntilDestroy()
@Component({
  selector: "app-sd-targeting-add-popup",
  templateUrl: "./sd-targeting-add-popup.component.html",
  standalone: true,
  imports: [TranslocoDirective, ISelectComponent, NgIf, IButtonComponent],
})
export class SdTargetingAddPopupComponent implements OnInit {
  @Input()
  strategy!: StrategyEx;
  @Input()
  organizationId!: number;

  segment?: SegmentEx;
  creation!: boolean;
  lookback: number = 30;
  targetingsCount: number = 0;

  targetingType: SdTargetingType = SdTargetingType.PRODUCT;
  audienceExpressionType: AudienceExpressionType = AudienceExpressionType.views;
  readonly maxTargetingsPerSdStrategy = Constant.maxTargetingsPerSdStrategy;

  readonly supportedLookback = SupportedAudienceLookback;

  audienceMatchType: AudienceMatchType = AudienceMatchType.exactProduct;

  readonly SdTargetingType = SdTargetingType;
  readonly AudienceExpressionType = AudienceExpressionType;

  readonly SegmentType = SegmentConfigType;
  readonly faSearch = faSearch;

  @Output()
  segmentCreationRequested = new EventEmitter<SegmentCreactionRequest>();

  @Output()
  targetingCreationCancelled = new EventEmitter();

  filter?: RegExp;

  targetingTypeOptions!: Option<SdTargetingType>[];
  defaultTargetingTypeOption!: Option<SdTargetingType>;

  segmentOptions!: Option<SegmentEx | null>[];
  defaultSegmentOption!: Option<SegmentEx | null>;

  eventTypeOptions: Option<AudienceExpressionType>[] = [
    {
      label: "Views",
      value: AudienceExpressionType.views,
    },
    {
      label: "Purchases",
      value: AudienceExpressionType.purchases,
    },
  ];
  defaultEventTypeOption: Option<AudienceExpressionType> = this.eventTypeOptions.find(
    (option) => option.value === this.audienceExpressionType,
  )!;

  matchTypeOptions: Option<AudienceMatchType>[] = [];
  defaultMatchTypeOption!: Option<AudienceMatchType>;

  lookbackOptions: Option<number>[] = [];
  defaultLookbackOption!: Option<number>;

  constructor(
    private segmentService: SegmentService,
    private bsModalRef: BsModalRef,
    private toasterService: ToastrService,
    private translationService: TranslocoService,
    private strategyService: StrategyService,
    private sdStrategiesService: SdStrategiesService,
  ) {}

  ngOnInit(): void {
    this.targetingsCount =
      this.strategy.tactics.filter((tactic) => tactic.tacticType === TacticType.LEGACY).length +
      (this.strategy.autoAlgoExplorationEnabled ? 1 : 0) +
      this.strategy.audienceTargetings.length;
    if (this.targetingsCount >= this.maxTargetingsPerSdStrategy) {
      this.targetingType = SdTargetingType.BLACKLIST_PRODUCT;
    }

    this.targetingTypeOptions = [
      {
        label: "sd-targeting-add-popup.product_page_ai-powered_targeting",
        value: SdTargetingType.PRODUCT_AI_TARGETING,
        disabled: this.targetingsCount >= this.maxTargetingsPerSdStrategy || this.strategy.autoAlgoExplorationEnabled,
      },
      {
        label: "sd-targeting.product_page",
        value: SdTargetingType.PRODUCT,
        disabled: this.targetingsCount >= this.maxTargetingsPerSdStrategy,
      },
      {
        label: "sd-targeting-add-popup.blacklist_product_pages",
        value: SdTargetingType.BLACKLIST_PRODUCT,
      },
      {
        label: "sd-targeting.remarketing",
        value: SdTargetingType.REMARKETING,
        disabled: this.targetingsCount >= this.maxTargetingsPerSdStrategy,
      },
    ];
    this.defaultTargetingTypeOption = this.targetingTypeOptions.find((option) => option.value === this.targetingType)!;

    this.segmentOptions = [
      {
        label: this.translationService.translate("sd-targeting-add-popup.create_new_segment"),
        value: null,
      },
    ];
    this.segmentService
      .getAllowedSegmentsForStrategy(this.strategy)
      .pipe(take(1))
      .subscribe((segments) => {
        this.segmentOptions.push(
          ...segments
            .filter((s) => s.segmentType === SegmentConfigType.ProductSegment && s.name)
            .map((segment) => ({
              label: segment.name,
              value: segment,
            })),
        );
      });
    this.defaultSegmentOption = this.segmentOptions[0];
    this.creation = true;

    for (const [key, value] of Object.entries(SupportedAudienceMatchType)) {
      this.matchTypeOptions.push({
        label: value ?? "",
        value: key as AudienceMatchType,
      });
    }
    this.defaultMatchTypeOption = this.matchTypeOptions.find((option) => option.value === this.audienceMatchType)!;

    this.lookbackOptions = this.supportedLookback.map((lookback) => ({
      label: lookback.toString(),
      value: lookback,
    }));
    this.defaultLookbackOption = this.lookbackOptions.find((option) => option.value === this.lookback)!;
  }

  selectTargetingType(targetingType: Option<SdTargetingType>) {
    this.targetingType = targetingType.value;
    this.defaultTargetingTypeOption = targetingType;
    this.segment = undefined;
    this.creation = false;
    this.setFilter(undefined);
  }

  selectSegment(segment: Option<SegmentEx | null>) {
    this.segment = segment.value ?? undefined;
    this.creation = segment.value == undefined;
    this.defaultSegmentOption = segment;
  }

  selectExpressionType(audienceExpressionType: Option<AudienceExpressionType>) {
    this.audienceExpressionType = audienceExpressionType.value;
    this.defaultEventTypeOption = audienceExpressionType;
  }

  selectMatchType(matchType: Option<AudienceMatchType>) {
    this.audienceMatchType = matchType.value;
    this.defaultMatchTypeOption = matchType;
  }

  selectLookback(lookback: Option<number>) {
    this.lookback = lookback.value;
    this.defaultLookbackOption = lookback;
  }

  close(): void {
    this.bsModalRef.hide();
    this.targetingCreationCancelled.emit();
  }

  enableAIPoweredTargeting(): void {
    this.strategyService
      .updateStrategyAutoAlgoExploration(
        this.strategy.accountId,
        this.strategy.marketplace,
        this.organizationId,
        this.strategy.strategyId,
        true,
      )
      .subscribe({
        next: () => {
          this.toasterService.success("AI-powered targeting enabled");
          this.bsModalRef.hide();
        },
        error: (err) => {
          this.toasterService.error(err, "AI-powered targeting error");
          this.bsModalRef.hide();
        },
      });
  }

  create(): void {
    const tacticType =
      this.targetingType === SdTargetingType.BLACKLIST_PRODUCT ? TacticType.BLACKLIST : TacticType.LEGACY;

    if (this.segment) {
      this.strategyService
        .addTacticToStrategy(
          this.strategy.accountId,
          this.strategy.marketplace,
          this.strategy.strategyId,
          this.segment.segmentId,
          tacticType,
        )
        .pipe(untilDestroyed(this))
        .subscribe({
          next: () => {
            this.toasterService.success("Product Page Targeting sucessfully added to strategy");
            this.bsModalRef.hide();
          },
          error: (error) => {
            this.toasterService.error(error, "Product Page Targeting creation error");
            this.bsModalRef.hide();
            this.targetingCreationCancelled.emit();
          },
        });
    } else {
      // request segment creation request
      this.segmentCreationRequested.emit({ tacticType: tacticType, segmentType: SegmentConfigType.ProductSegment });
      this.bsModalRef.hide();
    }
  }

  existingAudience(): boolean {
    const existingAudience = this.strategy.audienceTargetings.find(
      (aud) =>
        aud.expressionType === this.audienceExpressionType &&
        aud.matchType === this.audienceMatchType &&
        aud.lookback === this.lookback,
    );
    return existingAudience !== undefined;
  }

  illegalAudience(): boolean {
    return (
      (this.audienceExpressionType === AudienceExpressionType.views &&
        this.audienceMatchType === AudienceMatchType.relatedProduct) ||
      (this.audienceExpressionType === AudienceExpressionType.purchases &&
        this.audienceMatchType === AudienceMatchType.similarProduct)
    );
  }

  createAudience(): void {
    // check if audience already exists
    if (this.existingAudience()) {
      this.toasterService.error("This audience already exists", "Audience creation error");
      return;
    }

    this.sdStrategiesService
      .addRemarketingAudienceToSdStrategy(
        this.strategy.accountId,
        this.strategy.marketplace,
        this.strategy.strategyId,
        this.audienceExpressionType,
        this.audienceMatchType,
        this.lookback,
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (audience) => {
          this.toasterService.success("Remarketing Targeting sucessfully added to strategy");
          this.bsModalRef.hide();
        },
        (error) => {
          this.toasterService.error(error, "Remarketing Targeting creation error");
          this.bsModalRef.hide();
          this.targetingCreationCancelled.emit();
        },
      );
  }

  setFilter(query: string | undefined): void {
    this.filter = query ? new RegExp(query, "i") : undefined; // i = case insensitive
  }
}
