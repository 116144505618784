import { Component, Input } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { UploadResult } from "./upload-catalog-data-modal.component";
import { TranslocoDirective } from "@jsverse/transloco";
import { DropdownToggleIconComponent } from "@m19-board/shared/expand-icon/dropdown-toggle-icon.component";

@Component({
  selector: "app-upload-catalog-data-report-modal",
  templateUrl: "./upload-catalog-data-report-modal.component.html",
  standalone: true,
  imports: [TranslocoDirective, DropdownToggleIconComponent],
})
export class UploadCatalogDataReportModalComponent<T> {
  @Input()
  public uploadResult!: UploadResult<T>;
  @Input()
  public updatedEntity!: string;
  @Input()
  public uploadResultFormater!: (u: T) => string;

  successDetailsVisible = false;
  errorDetailsVisible = false;
  warningDetailsVisible = false;

  constructor(public bsModalRef: BsModalRef) {}

  toggleSuccessDetails() {
    this.successDetailsVisible = !this.successDetailsVisible;
  }

  toggleErrorDetails() {
    this.errorDetailsVisible = !this.errorDetailsVisible;
  }

  toggleWarningDetails() {
    this.warningDetailsVisible = !this.warningDetailsVisible;
  }
}
