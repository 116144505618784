import { Component, OnInit } from "@angular/core";
import { Currency } from "@front/m19-api-client";
import { CurrencyService, UserSelectionService } from "@front/m19-services";
import { ISelectComponent, Option } from "@front/m19-ui";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-currency-selection",
  templateUrl: "./currency-selection.component.html",
  standalone: true,
  imports: [ISelectComponent],
})
export class CurrencySelectionComponent implements OnInit {
  selectedCurrency: Option<CurrencyOption> | undefined;

  currencies: Option<CurrencyOption>[] = [
    {
      label: this.currencyService.getCurrencySymbol(Currency.EUR),
      value: {
        currency: Currency.EUR,
      },
    },
    {
      label: this.currencyService.getCurrencySymbol(Currency.USD),
      value: {
        currency: Currency.USD,
      },
    },
    {
      label: this.currencyService.getCurrencySymbol(Currency.GBP),
      value: {
        currency: Currency.GBP,
      },
    },
  ];

  constructor(
    public currencyService: CurrencyService,
    public userSelectionService: UserSelectionService,
  ) {}

  ngOnInit() {
    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((x) => {
      this.currencies = this.currencies.concat(this.getOtherCurrencies());

      this.selectedCurrency = this.currencies?.find((c) => c.value.currency === x);
    });
  }

  private getOtherCurrencies(): Option<CurrencyOption>[] {
    return this.currencyService
      .getCurrencies()
      .filter((c: Currency) => !this.currencies.some((ct) => ct.value.currency === c))
      .map((c: Currency) => ({
        label: this.currencyService.getCurrencySymbol(c),
        value: {
          currency: c,
        },
      }));
  }

  currencyChange(currency: Option<CurrencyOption>) {
    this.userSelectionService.setCurrency(Currency[currency.value.currency]);
  }

  protected readonly Currency = Currency;
}

interface CurrencyOption {
  currency: Currency;
}
