import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { IButtonComponent } from '../ibutton/ibutton.component';
import { DialogRef } from '../../services/modal.service';

@Component({
  selector: 'IModal',
  standalone: true,
  imports: [NgClass, IButtonComponent],
  templateUrl: './imodal.component.html',
  styleUrls: ['./imodal.component.scss'],
  animations: [
    trigger('overlayAnimation', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition('void <=> *', animate(200)),
    ]),
  ],
})
export class IModalComponent implements OnInit, OnDestroy {
  private host: HTMLElement = inject(ElementRef).nativeElement;
  private readonly modalOpenClass: string[] = ['overflow-hidden', 'relative', 'top-0', 'right-0', 'left-0', 'bottom-0'];

  @ViewChild('modalContent') modalContent!: ElementRef;
  @ViewChild('element', { static: true }) element!: ElementRef<HTMLElement>;

  @Input() title: string = '';
  @Input() fullScreen: boolean = false;
  @Input() closeable: boolean = true;

  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();

  dialogRef = inject(DialogRef);

  constructor(private renderer: Renderer2) {
    const mousedownEvent = fromEvent<MouseEvent>(document, 'mousedown');

    mousedownEvent.subscribe((event: MouseEvent) => {
      const clickedInside = this.modalContent.nativeElement.contains(event.target);
      if (!clickedInside) {
        this.closeModal();
      }
    });
  }

  ngOnInit(): void {
    document.body.classList.add(...this.modalOpenClass);
  }

  ngOnDestroy() {
    document.body.classList.remove(...this.modalOpenClass);
  }

  closeModal(): void {
    if (!this.closeable) return;
    this.closeEvent.emit();
    if (this.dialogRef) this.dialogRef.close();
  }
}
