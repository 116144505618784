import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faArrowDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { BsModalService } from "ngx-bootstrap/modal";
import { IgnoredKeywordsModalComponent } from "@m19-board/shared/ignored-keywords-modal/ignored-keywords-modal.component";
import { AsinSeed, Marketplace, MatchType, StrategyAsin, Targeting } from "@front/m19-api-client";

@Component({
  selector: "app-targeting-detail",
  templateUrl: "./targeting-detail.component.html",
  styleUrls: ["./targeting-detail.component.scss"],
})
export class TargetingDetailComponent implements OnInit {
  @Input()
  name: string;

  @Input()
  set productTargetingItems(values: Targeting[] | AsinSeed[]) {
    this.asins = values.map((x: Targeting | AsinSeed) => ({
      asin: x.targetingValue,
      matchType: x.matchType,
    }));
  }

  @Input()
  keywordTargetingItems: Targeting[] | AsinSeed[];
  @Input()
  marketplace: Marketplace;
  @Input()
  accountId: string;
  @Input()
  enableKW = true;
  @Input()
  enableProducts = true;
  @Input()
  onlyExactMatchType = false;
  @Input()
  errorsAsin: string[] = [];
  @Input()
  errorsKeyword: string[] = [];
  @Input()
  whitelist = true;

  @Input() isReadOnly = false;

  @Output()
  onAddedAsins = new EventEmitter<Targeting[]>();
  @Output()
  onDeletedAsins = new EventEmitter<Targeting[]>();
  @Output()
  onAddedKeywords = new EventEmitter<Targeting[]>();
  @Output()
  onDeletedKeywords = new EventEmitter<Targeting[]>();

  keywordMatchType = MatchType.phrase;
  readonly faArrowDown = faArrowDown;
  readonly faTimes = faTimes;
  asins: StrategyAsin[] = [];

  constructor(private modalService: BsModalService) {}

  ngOnInit(): void {
    if (this.onlyExactMatchType) {
      this.keywordMatchType = MatchType.exact;
    }
  }

  setKeywordMatchType(matchType: MatchType) {
    this.keywordMatchType = matchType;
  }

  addAsins(asins: string) {
    this.onAddedAsins.emit(
      asins.split(/[\s,;\n]+/).map((p) => {
        return { targetingValue: p.toUpperCase(), matchType: MatchType.asinSameAs };
      }),
    );
  }

  addKeywords(keywords: string) {
    this.onAddedKeywords.emit(
      keywords.split("\n").map((k) => {
        return { targetingValue: k, matchType: this.keywordMatchType };
      }),
    );
  }

  deleteAsins(asins: StrategyAsin[]) {
    this.onDeletedAsins.emit(
      asins.map((x) => {
        return { targetingValue: x.asin, matchType: MatchType.asinSameAs };
      }),
    );
  }

  deleteKeywords(items: Targeting[]) {
    this.onDeletedKeywords.emit(items);
  }

  readonly MatchType = MatchType;

  openIgnoredKeywordModal() {
    const errorObjs: { kw: string; reason: string }[] = [];
    for (const error of this.errorsKeyword) {
      const splitted = error.split(":");
      errorObjs.push({ kw: splitted[0].trim(), reason: splitted[1].trim() });
    }
    this.modalService.show(IgnoredKeywordsModalComponent, {
      initialState: {
        errors: errorObjs,
      },
      class: "modal-lg",
    });
  }
}
