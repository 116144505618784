import { Component, inject, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IButtonComponent, ICheckboxComponent } from "@front/m19-ui";
import { TranslocoDirective } from "@jsverse/transloco";
import { FormControl } from "@angular/forms";
import { LayoutSelectorService } from "@m19-board/layout-selector.service";
import { AuthService } from "@front/m19-services";
import { Router } from "@angular/router";
import { AjaxResponse } from "rxjs/ajax";

@Component({
  selector: "terms-of-use",
  standalone: true,
  imports: [CommonModule, ICheckboxComponent, TranslocoDirective, IButtonComponent],
  templateUrl: "./terms-of-use.component.html",
})
export class TermsOfUseComponent {
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);
  private readonly layoutSelector = inject(LayoutSelectorService);
  readonly termsAndConditionLink = this.layoutSelector.getTermsAndConditionLink();
  readonly agency = this.layoutSelector.getAgencyName();
  readonly termsOfUseControl = new FormControl<boolean>(false);

  loading = signal(false);
  error = signal("");

  acceptTermsOfUse() {
    this.loading.set(true);
    this.authService.updateUserV2({ registered: true }).subscribe({
      next: () => this.router.navigateByUrl(""),
      error: (error: AjaxResponse<any>) => {
        this.loading.set(false);
        this.error.set(error.response.message);
      },
    });
  }
}
