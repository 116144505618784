import {
  ACOS,
  Metric,
  MetricCategory,
  PALETTE,
  RatioMetric,
  ROAS,
  SummableMetric,
  SupportedAccountType,
} from "@front/m19-metrics";
import { DspStats } from "@front/m19-api-client";

export const DSP_SALES: Metric<DspStats> = new SummableMetric<DspStats>({
  id: "DSP_SALES",
  field: "sales",
  title: "Sales",
  category: MetricCategory.DSP,
  color: PALETTE[0],
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
  currency: true,
  requireSellingPartnerAccess: false,
  tooltip: "DSP Sales",
});

export const DSP_UNITS_SOLD: Metric<DspStats> = new SummableMetric<DspStats>({
  id: "DSP_UNITS_SOLD",
  field: "unitsSold",
  title: "Units Sold",
  category: MetricCategory.DSP,
  color: PALETTE[1],
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
  currency: false,
  requireSellingPartnerAccess: false,
  tooltip: "DSP Units Sold",
});

export const DSP_COST: Metric<DspStats> = new SummableMetric<DspStats>({
  id: "DSP_COST",
  field: "totalCost",
  title: "Cost",
  category: MetricCategory.DSP,
  color: PALETTE[2],
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
  currency: true,
  requireSellingPartnerAccess: false,
  tooltip: "DSP Cost",
});

export const DSP_IMPRESSIONS: Metric<DspStats> = new SummableMetric<DspStats>({
  id: "DSP_IMPRESSIONS",
  field: "impressions",
  title: "Impressions",
  category: MetricCategory.DSP,
  color: PALETTE[3],
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
  currency: false,
  requireSellingPartnerAccess: false,
  tooltip: "DSP Impressions",
});

export const DSP_CLICKS: Metric<DspStats> = new SummableMetric<DspStats>({
  id: "DSP_CLICKS",
  field: "clicks",
  title: "Clicks",
  category: MetricCategory.DSP,
  color: PALETTE[4],
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
  currency: false,
  requireSellingPartnerAccess: false,
  tooltip: "DSP Clicks",
});

export const DSP_FEE: Metric<DspStats> = new SummableMetric<DspStats>({
  id: "DSP_FEE",
  field: "totalFee",
  title: "Fee",
  category: MetricCategory.DSP,
  color: PALETTE[5],
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
  currency: true,
  requireSellingPartnerAccess: false,
  tooltip: "DSP Fee",
});

export const DSP_PURCHASE_CLICKS: Metric<DspStats> = new SummableMetric<DspStats>({
  id: "DSP_PURCHASE_CLICKS",
  field: "purchaseClicks",
  title: "Purchase Clicks",
  category: MetricCategory.DSP,
  color: PALETTE[6],
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
  currency: false,
  requireSellingPartnerAccess: false,
  tooltip: "DSP Purchase Clicks",
});

export const DSP_PURCHASES: Metric<DspStats> = new SummableMetric<DspStats>({
  id: "DSP_PURCHASES",
  field: "purchases",
  title: "Purchases",
  category: MetricCategory.DSP,
  color: PALETTE[7],
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
  currency: false,
  requireSellingPartnerAccess: false,
  tooltip: "DSP Purchases",
});

export const DSP_ADD_TO_CART: Metric<DspStats> = new SummableMetric<DspStats>({
  id: "DSP_ADD_TO_CART",
  field: "addToCart",
  title: "Add to Cart",
  category: MetricCategory.DSP,
  color: PALETTE[8],
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
  currency: false,
  requireSellingPartnerAccess: false,
  tooltip: "DSP Add to Cart",
});

export const DSP_PAGE_VIEWS: Metric<DspStats> = new SummableMetric<DspStats>({
  id: "DSP_PAGE_VIEWS",
  field: "pageViews",
  title: "Page Views",
  category: MetricCategory.DSP,
  color: PALETTE[9],
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
  currency: false,
  requireSellingPartnerAccess: false,
  tooltip: "DSP Page Views",
});

export const DSP_NEW_TO_BRAND_PRODUCT_SALES: Metric<DspStats> = new SummableMetric<DspStats>({
  id: "DSP_NEW_TO_BRAND_PRODUCT_SALES",
  field: "newToBrandProductSales",
  title: "New to Brand Product Sales",
  category: MetricCategory.DSP,
  color: PALETTE[10],
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
  currency: true,
  requireSellingPartnerAccess: false,
  tooltip: "DSP New to Brand Product Sales",
});

export const DSP_3P_FEES: Metric<DspStats> = new SummableMetric<DspStats>({
  id: "DSP_3P_FEES",
  field: "_3PFees",
  title: "3P Fees",
  category: MetricCategory.DSP,
  color: PALETTE[11],
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
  currency: true,
  requireSellingPartnerAccess: false,
  tooltip: "DSP 3P Fees",
});

export const DSP_SUPPLY_COST: Metric<DspStats> = new SummableMetric<DspStats>({
  id: "DSP_SUPPLY_COST",
  field: "supplyCost",
  title: "Supply Cost",
  category: MetricCategory.DSP,
  color: PALETTE[12],
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
  currency: true,
  requireSellingPartnerAccess: false,
  tooltip: "DSP Supply Cost",
});

export const DSP_ACOS = new RatioMetric<DspStats>({
  id: "DSP_ACOS",
  numerator: DSP_COST,
  denominator: DSP_SALES,
  title: "ACOS",
  category: MetricCategory.DSP,
  color: PALETTE[13],
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
  isPercent: true,
  inverseColors: true,
  tooltip: "Ratio of DSP ad spend to ad sales",
  higherIsBetter: false,
  requireSellingPartnerAccess: false,
  mustApplyEvolutionStyle: false,
});

export const DSP_ROAS = new RatioMetric<DspStats>({
  id: "DSP_ROAS",
  numerator: DSP_SALES,
  denominator: DSP_COST,
  title: "ROAS",
  category: MetricCategory.DSP,
  color: PALETTE[14],
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
  precision: "1.0-2",
  tooltip: "Ratio of revenue generated from DSP advertising to the cost of advertising",
  requireSellingPartnerAccess: false,
  mustApplyEvolutionStyle: false,
});
