import { Component, Input } from "@angular/core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { StrategyEx } from "@front/m19-models";
import { UntilDestroy } from "@ngneat/until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";

@UntilDestroy()
@Component({
  template: ` <div class="p-3">
    <div class="mb-4 flex w-full items-center justify-between">
      <div class="w-1/2">
        <div>
          <h3>Strategy Activities - {{ strategy.name }}</h3>
        </div>
      </div>
      <div class="flex flex-auto items-center justify-end">
        <app-date-range-selection></app-date-range-selection>
        <button class="btn btn-ghost-secondary custom-btn-dark-hover ml-3" matTooltip="Close" (click)="close()">
          <fa-icon [icon]="faTimes"></fa-icon>
        </button>
      </div>
    </div>
    <app-strategy-activity-component [strategy]="strategy"></app-strategy-activity-component>
  </div>`,
  styles: [".activity-section {height: 30rem;}"],
})
export class StrategyActivityModalComponent {
  @Input()
  strategy: StrategyEx;

  readonly faTimes = faTimes;

  constructor(private bsModalRef: BsModalRef) {}

  close() {
    this.bsModalRef.hide();
  }
}
