import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AccountSelectionService, AsinService, UserSelectionService } from "@front/m19-services";
import { Observable, startWith, switchMap } from "rxjs";
import { PALETTE } from "../../models/Metric";
import { FormControl } from "@angular/forms";
import { SovBrandDateType } from "../../share-of-voice/share-of-voice.component";
import { Catalog } from "@front/m19-models";
import { AccountMarketplace } from "@front/m19-api-client";

@UntilDestroy()
@Component({
  selector: "app-sov-main-table",
  templateUrl: "./sov-main-table.component.html",
  styleUrls: ["./sov-main-table.component.scss"],
})
export class SovMainTableComponent implements OnInit {
  readonly faSearch = faSearch;
  readonly faTimes = faTimes;

  @Input() set sovByBrand(data: SovBrandDateType) {
    if (!data) return;
    const reducedMap = new Map<string, number>();
    data.forEach((innerMap, key) => {
      reducedMap.set(key, this.sumAllDateSovInRange(innerMap));
    });
    this._sovByBrandDate = new Map([...reducedMap.entries()].sort((a, b) => b[1] - a[1]));

    this.setBrands(this._sovByBrandDate);

    this.brandSelection = Array.from(this._sovByBrandDate.keys()).slice(0, 5);
    this.emitBrandSelection.emit(this.brandSelection);
  }

  @Input() brandColors: Map<string, string>;
  @Input() loading: boolean;

  @Output() emitHoveredBrand = new EventEmitter<string>();
  @Output() emitBrandSelection = new EventEmitter<string[]>();

  _sovByBrandDate: Map<string, number>;

  brandSelection: string[];
  hoveredBrand: string;

  filter: FormControl;
  filter$: Observable<string>;

  allBrands: string[];
  filteredBrands: string[];
  catalogBrands: Set<string>;
  userBrands: string[];

  private inventory$: Observable<Catalog> = this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(
    switchMap((am: AccountMarketplace) => {
      return this.asinService.getCatalog(am.accountId, am.marketplace);
    }),
  );

  constructor(
    private userSelectionService: UserSelectionService,
    private accountSelectionService: AccountSelectionService,
    private asinService: AsinService,
  ) {}

  ngOnInit() {
    this.filter = new FormControl("");
    this.filter$ = this.filter.valueChanges.pipe(startWith(""));

    this.inventory$.subscribe((c: Catalog) => {
      this.catalogBrands = c.brands();
    });

    this.filter$.pipe(untilDestroyed(this)).subscribe((f) => {
      if (!this._sovByBrandDate) return;
      this.updateBrandFilter(f);
    });
  }

  private setBrands(sovByBrandDate: Map<string, number>) {
    this.updateBrandFilter(this.filter?.value);
    this.allBrands = [...sovByBrandDate].sort((a, b) => b[1][1] - a[1][0]).map(([b, _s]) => b);
  }

  private updateBrandFilter(filter: string) {
    this.userBrands = this.catalogBrands
      ? [...this.catalogBrands].filter((b: string) => this._sovByBrandDate.has(b) && b.toLowerCase().includes(filter))
      : [];
    this.filteredBrands = [...this._sovByBrandDate]
      .map(([b, _s]) => b)
      .filter((b: string) => !this.userBrands.includes(b) && b.toLowerCase().includes(filter?.toLowerCase()));
  }

  // Sum all the sov for date in date range
  private sumAllDateSovInRange(map: Map<string, number>): number {
    const dateRange = this.userSelectionService.getDateRange();

    // Filter map to keep date in range
    const inRangeMap = new Map<string, number>(
      [...map.entries()].filter(([key, _value]) => {
        // Get rid of GMT stuff
        const dateWithoutTime = new Date(key);
        const rangeWithoutTime = dateRange.map((d) => new Date(d.getFullYear(), d.getMonth(), d.getDate()));

        return dateWithoutTime >= rangeWithoutTime[0] && dateWithoutTime <= rangeWithoutTime[1];
      }),
    );

    const sovArray: number[] = Array.from(inRangeMap.values());
    if (sovArray.length == 0) return 0;

    const mean = sovArray.reduce((acc, curr) => acc + curr, 0) / sovArray.length;

    return Math.ceil(mean);
  }

  toggleBrandSelection(brand: string, selectOnly = false) {
    if (selectOnly) {
      this.brandSelection = [brand];
    } else {
      const brandIndex = this.brandSelection.indexOf(brand);

      if (brandIndex > -1) {
        this.brandSelection.splice(brandIndex, 1);
      } else {
        this.brandSelection.push(brand);
      }
    }
    this.emitBrandSelection.emit(this.brandSelection);
  }

  brandMouseEnter(brand: string) {
    if (this.isBrandSelected(brand) > -1) {
      this.hoveredBrand = brand;
      this.emitHoveredBrand.emit(brand);
    } else this.brandMouseLeave(); // When hovering a non-selected brand, reset colors
  }

  brandMouseLeave() {
    this.hoveredBrand = undefined;
    this.emitHoveredBrand.emit(undefined);
  }

  isBrandSelected(brand: string): number {
    return this.brandSelection?.indexOf(brand);
  }

  protected readonly PALETTE = PALETTE;
}
