// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    AccessLevel,
} from './';

/**
 * @export
 * @interface OrganizationUser
 */
export interface OrganizationUser {
    /**
     * @type {number}
     * @memberof OrganizationUser
     */
    organizationId?: number;
    /**
     * @type {number}
     * @memberof OrganizationUser
     */
    parentOrganizationId?: number;
    /**
     * @type {string}
     * @memberof OrganizationUser
     */
    customerId?: string;
    /**
     * @type {number}
     * @memberof OrganizationUser
     */
    ownerId?: number;
    /**
     * @type {string}
     * @memberof OrganizationUser
     */
    name?: string;
    /**
     * @type {string}
     * @memberof OrganizationUser
     */
    userName?: string;
    /**
     * @type {string}
     * @memberof OrganizationUser
     */
    email?: string;
    /**
     * @type {number}
     * @memberof OrganizationUser
     */
    m19UserId?: number;
    /**
     * @type {boolean}
     * @memberof OrganizationUser
     */
    isLegacy?: boolean;
    /**
     * @type {AccessLevel}
     * @memberof OrganizationUser
     */
    accessLevel?: AccessLevel;
}
