import { Component, OnInit } from "@angular/core";
import { ChildActivationEnd, NavigationEnd, Router, RouterLink, RouterOutlet } from "@angular/router";
import { ICON_GEAR, ICON_LOGOUT, ICON_USER } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AccountSelectionService,
  AuthService,
  CurrencyService,
  NotificationService,
  UserSelectionService,
} from "@front/m19-services";
import { NavData } from "@m19-board/_nav";
import { BoardType, LayoutSelectorService } from "@m19-board/layout-selector.service";
import { Hotkeys } from "@m19-board/services/hotkeys.service";
import { environment } from "../../../environments/environment";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { Observable } from "rxjs";
import { DebugInfoModalComponent } from "../board-layout/debug-info-modal.component";
import { AccountMarketplace, User } from "@front/m19-api-client";
import { OrganizationAccountGroups } from "@front/m19-models";
import { AsyncPipe } from "@angular/common";
import { TranslocoDirective } from "@jsverse/transloco";
import { AccountSelectorComponent } from "@m19-board/account-selector/account-selector.component";
import { IButtonComponent } from "@front/m19-ui";
import { MatMenu, MatMenuTrigger } from "@angular/material/menu";
import { CurrencySelectionComponent } from "@m19-board/currency-selector/currency-selection.component";
import { DateRangeSelectorComponent } from "@m19-board/date-range-selector/date-range-selector.component";
import { LogoComponent } from "@m19-board/shared/logo/logo.component";

@UntilDestroy()
@Component({
  templateUrl: "./dashboard360-layout.component.html",
  standalone: true,
  imports: [
    AsyncPipe,
    RouterLink,
    TranslocoDirective,
    AccountSelectorComponent,
    IButtonComponent,
    MatMenu,
    MatMenuTrigger,
    RouterOutlet,
    CurrencySelectionComponent,
    DateRangeSelectorComponent,
    LogoComponent,
  ],
})
export class Dashboard360LayoutComponent implements OnInit {
  isTest = !environment.production;

  readonly ICON_SETTINGS = ICON_GEAR;
  readonly ICON_USER = ICON_USER;
  readonly ICON_LOGOUT = ICON_LOGOUT;

  readonly tabs: NavData[] = [
    { url: "dashboard360/account360", name: "Accounts 360" },
    { url: "dashboard360/product360", name: "Product 360" },
  ];

  // input/app state
  user?: User;
  locale?: string;
  boardType?: BoardType;
  globalWarning$: Observable<string>;
  accountMarketplaces?: AccountMarketplace[];
  selectedDateRage?: string[];

  // component state
  displaySettingsMenu = false;
  debugInfoModalVisible = false;
  url: string = "";

  constructor(
    private authService: AuthService,
    private userSelectionService: UserSelectionService,
    public currencyService: CurrencyService,
    private router: Router,
    private layoutSelector: LayoutSelectorService,
    private notificationService: NotificationService,
    private accountSelectionService: AccountSelectionService,
    private modalService: BsModalService,
    private hotkeys: Hotkeys,
  ) {
    this.globalWarning$ = this.notificationService.globalWarning$;
    this.hotkeys
      .addShortcut({ altKey: "KeyD" })
      .pipe(untilDestroyed(this))
      .subscribe(() => this.printDebugInfo());
    this.url = router.url;
    router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof ChildActivationEnd) {
        this.url = router.url;
      }
    });
  }

  ngOnInit(): void {
    this.boardType = this.layoutSelector.getBoardType();
    this.accountSelectionService.accountMarketplacesSelection$
      .pipe(untilDestroyed(this))
      .subscribe((accountMarketplaces) => {
        this.accountMarketplaces = accountMarketplaces;
      });

    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user;
      this.locale = user.locale;
    });
    this.userSelectionService.dateRange$.pipe(untilDestroyed(this)).subscribe((dr) => {
      this.selectedDateRage = dr;
    });
  }

  selectOrganization(organizations: OrganizationAccountGroups[]) {
    this.userSelectionService.setSelectedOrganizations(organizations);
  }

  isAdminLogin(): boolean {
    return this.authService.isAdminLogin();
  }

  printDebugInfo() {
    // prevent modal to open again if already shown
    if (this.debugInfoModalVisible) {
      return;
    }
    this.debugInfoModalVisible = true;
    const modalOptions: ModalOptions = {
      initialState: {
        user: this.user,
        accountMarketplaces: this.accountMarketplaces,
        dateRange: this.selectedDateRage,
      },
      class: "modal-lg",
    };
    const modalRef = this.modalService.show(DebugInfoModalComponent, modalOptions);
    modalRef.onHidden?.subscribe(() => {
      this.debugInfoModalVisible = false;
    });
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(["login"]);
  }

  toggleSettings(): void {
    this.displaySettingsMenu = !this.displaySettingsMenu;
  }
}
