// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    DspDeliveryStatus,
} from './';

/**
 * @export
 * @interface DspCampaign
 */
export interface DspCampaign {
    /**
     * @type {string}
     * @memberof DspCampaign
     */
    campaignId?: string;
    /**
     * @type {string}
     * @memberof DspCampaign
     */
    advertiserId?: string;
    /**
     * @type {string}
     * @memberof DspCampaign
     */
    name?: string;
    /**
     * @type {string}
     * @memberof DspCampaign
     */
    state?: DspCampaignStateEnum;
    /**
     * @type {DspDeliveryStatus}
     * @memberof DspCampaign
     */
    deliveryStatus?: DspDeliveryStatus;
}

/**
 * @export
 * @enum {string}
 */
export enum DspCampaignStateEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

