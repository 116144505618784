import { Marketplace } from "@front/m19-api-client";

export function brandAnalyticsAvailableFor(marketplace: Marketplace): boolean {
  return BrandAnalyticsMarketplaces.includes(marketplace);
}

export const BrandAnalyticsMarketplaces = [
  Marketplace.JP,
  Marketplace.US,
  Marketplace.CA,
  Marketplace.MX,
  Marketplace.FR,
  Marketplace.DE,
  Marketplace.UK,
  Marketplace.IT,
  Marketplace.ES,
  Marketplace.NL,
  Marketplace.TR,
  Marketplace.SE,
  Marketplace.AU,
  Marketplace.AE,
  Marketplace.IN,
  Marketplace.SA,
].sort();
