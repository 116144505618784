// TODO: remove for testing
import { MatchType, TacticType } from "@front/m19-api-client";
import { SegmentConfigType, SegmentEx } from "@front/m19-models";

// TODO: remove for testing
const maxNumberOfTargets = 500;
const maxNumberOfNegativeKeywords = 250;
const maxNumberOfNegativeTargets = 500;

interface TargetingCountInfo {
  count: number;
  tacticType: TacticType;
  objectType: "Keywords" | "Asins";
  limit: number;
}

export class TargetingLimit {
  private readonly exceeding: Array<TargetingCountInfo> = [];

  private negativeKeywords: number = 0;
  private negativeAsins: number = 0;
  private keywords: number = 0;
  private asins: number = 0;

  constructor(segments: SegmentEx[], blacklist: SegmentEx[]) {
    const acc = new Map<TacticType, Map<MatchType, number>>();
    for (const segment of segments) {
      if (segment.segmentType == SegmentConfigType.KeywordSegment) {
        this.keywords += segment.items.length;
      }
      if (segment.segmentType == SegmentConfigType.ProductSegment) {
        this.asins += segment.items.length;
      }
    }
    for (const segment of blacklist) {
      if (segment.segmentType == SegmentConfigType.KeywordSegment) {
        this.negativeKeywords += segment.items.length;
      }
      if (segment.segmentType == SegmentConfigType.ProductSegment) {
        this.negativeAsins += segment.items.length;
      }
    }
    if (this.keywords > maxNumberOfTargets) {
      this.exceeding.push({
        count: this.keywords,
        tacticType: TacticType.LEGACY,
        objectType: "Keywords",
        limit: maxNumberOfTargets,
      });
    }
    if (this.asins > maxNumberOfTargets) {
      this.exceeding.push({
        count: this.asins,
        tacticType: TacticType.LEGACY,
        objectType: "Asins",
        limit: maxNumberOfTargets,
      });
    }
    if (this.negativeKeywords > maxNumberOfNegativeKeywords) {
      this.exceeding.push({
        count: this.negativeKeywords,
        tacticType: TacticType.BLACKLIST,
        objectType: "Keywords",
        limit: maxNumberOfNegativeKeywords,
      });
    }
    if (this.negativeAsins > maxNumberOfNegativeTargets) {
      this.exceeding.push({
        count: this.negativeAsins,
        tacticType: TacticType.BLACKLIST,
        objectType: "Asins",
        limit: maxNumberOfNegativeTargets,
      });
    }
  }

  public tooManyTargets(): boolean {
    return this.exceeding.length > 0;
  }

  public formatErrorMessage(): string {
    if (!this.tooManyTargets()) {
      return "";
    }
    const e = this.exceeding[0];
    const type = e.tacticType == TacticType.BLACKLIST ? "blacklisted " : "";

    return (
      `Too many ${type}${e.objectType} in your tactics ` +
      `(${e.count} > limit=${e.limit}). Extra ${e.objectType} will not be taken into account.`
    );
  }
}
