import { Component, computed, DestroyRef, inject, Signal, signal } from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountMarketplace, AmazonUser } from "@front/m19-api-client";
import { Marketplaces } from "@front/m19-models";
import { AccountMarketplaceService, AmazonUserService, AuthService } from "@front/m19-services";
import { IAlertComponent, IButtonComponent } from "@front/m19-ui";
import { TranslocoDirective } from "@jsverse/transloco";
import { LayoutSelectorService } from "@m19-board/layout-selector.service";
import { SelectAccountsPageComponent } from "@m19-board/onboarding/select-accounts-page.component";
import { OnboardingService, OnboardingStep, OnBoardingStepRedirects } from "@m19-board/services/onboarding.service";
import { LogoComponent } from "@m19-board/shared/logo/logo.component";
import { combineLatest, filter, forkJoin, map, Observable, Subscription, switchMap, take } from "rxjs";

@Component({
  templateUrl: "./account-liking-page.component.html",
  standalone: true,
  imports: [IButtonComponent, SelectAccountsPageComponent, IAlertComponent, LogoComponent, TranslocoDirective],
})
export class AccountLinkingPageComponent {
  private readonly amazonUserService = inject(AmazonUserService);
  private readonly authService = inject(AuthService);
  private readonly accountMarketplaceService = inject(AccountMarketplaceService);
  private readonly onboardingService = inject(OnboardingService);
  private readonly router = inject(Router);
  private readonly layoutService = inject(LayoutSelectorService);
  private readonly route = inject(ActivatedRoute);
  private readonly destroyRef = inject(DestroyRef);

  orgId$ = combineLatest([this.authService.loggedUser$, this.route.queryParamMap]).pipe(
    map(([user, params]) => {
      if (params.get("orgId")) {
        return Number(params.get("orgId"));
      }
      return user?.ownedOrganizationId;
    }),
  );

  orgId = toSignal(this.orgId$, { requireSync: true });
  private usedAccountGroupNames = toSignal(
    this.accountMarketplaceService.accountMarketplaces$.pipe(
      map((accountMarketplaces) => new Set(accountMarketplaces?.map((a) => a.accountGroupName!) ?? [])),
    ),
  );
  private usedAccountMarketplaces: Signal<Set<string>> = toSignal(
    combineLatest([this.accountMarketplaceService.accountMarketplaces$, this.orgId$]).pipe(
      map(
        ([accountMarketplaces, orgId]) =>
          new Set(
            accountMarketplaces
              ?.filter((accountMarketplace) => accountMarketplace.resourceOrganizationId === orgId)
              .map((accountMarketplace) => `${accountMarketplace.accountId}.${accountMarketplace.marketplace}`),
          ),
      ),
    ),
    { requireSync: true },
  );

  fromOnboarding = toSignal(this.route.data.pipe(map((data) => data["fromOnboarding"])));

  loading = signal<boolean>(false);
  errorMessage = signal<string | undefined>(undefined);
  accountMarketplaces = signal<AccountMarketplace[] | undefined>(undefined);
  selectedAccountMarketplaces = signal<AccountMarketplace[] | undefined>(undefined);
  selectAccountsStep = computed(() => this.accountMarketplaces());
  agency = this.layoutService.getAgencyName();
  private amazonToken = signal<string | undefined>(undefined);

  private logWithAmazonSub: Subscription | undefined;

  constructor() {
    this.route.data
      .pipe(
        map((data) => data["fromOnboarding"]),
        takeUntilDestroyed(this.destroyRef),
        take(1),
        filter((onboarding) => !!onboarding),
        switchMap(() =>
          this.onboardingService.currentStep$.pipe(
            filter((step) => step !== OnboardingStep.AccountLinking),
            takeUntilDestroyed(this.destroyRef),
            take(1),
          ),
        ),
      )
      .subscribe((step) => {
        this.router.navigate([OnBoardingStepRedirects[step]]);
      });
  }

  loginWithAmazon() {
    this.loading.set(true);

    if (this.logWithAmazonSub) this.logWithAmazonSub.unsubscribe();

    this.logWithAmazonSub = this.logWithAmazonAsync().subscribe({
      next: (amazonUser: AmazonUser) => {
        this.loading.set(false);
        this.errorMessage.set(undefined);
        this.amazonToken.set(amazonUser.token);

        const filteredMarketplaces = amazonUser.accountMarketplaces?.filter(
          (accountMarketplace) =>
            Marketplaces[accountMarketplace.marketplace].isSupported &&
            !this.usedAccountMarketplaces().has(`${accountMarketplace.accountId}.${accountMarketplace.marketplace}`),
        );
        this.accountMarketplaces.set(filteredMarketplaces);
      },
      error: (error: any) => {
        this.loading.set(false);
        this.errorMessage.set(error.response.message);
      },
    });
  }

  onCancel() {
    this.accountMarketplaces.set(undefined);
  }

  createAccounts() {
    if (!this.selectedAccountMarketplaces()?.length) {
      return;
    }
    this.loading.set(true);
    const createBatch: Observable<any>[] = [];

    const usedNames = new Set(this.usedAccountGroupNames());

    const groupedByAccount = this.selectedAccountMarketplaces()!.reduce((acc, current) => {
      const key = current.accountId;
      if (acc.has(key)) {
        acc.get(key)?.push(current);
      } else {
        acc.set(key, [current]);
      }
      return acc;
    }, new Map<string, AccountMarketplace[]>());

    for (const [_, marketplaces] of groupedByAccount.entries()) {
      const name = this.getUniqueName(marketplaces[0].accountName, usedNames);
      usedNames.add(name);
      createBatch.push(this.accountMarketplaceService.addNewAccountsToGroupV2(marketplaces, name, this.amazonToken()!));
    }

    forkJoin(createBatch).subscribe({
      next: () => {
        this.loading.set(false);
        if (this.fromOnboarding()) {
          // navigate to the next step is down automatically
          return;
        } else {
          this.router.navigate(["/accounts"]);
        }
      },
      error: (error: any) => {
        this.loading.set(false);
        this.errorMessage.set(error.response.message);
      },
    });
  }

  selectionChange(accountMarketplaces: AccountMarketplace[]) {
    this.selectedAccountMarketplaces.set(accountMarketplaces);
  }

  private getUniqueName(name: string, usedNames: Set<string>): string {
    if (!usedNames.has(name)) {
      return name;
    }
    let i = 1;
    while (usedNames.has(name + " (" + i + ")")) {
      i++;
    }
    return name + " (" + i + ")";
  }

  private logWithAmazonAsync(): Observable<AmazonUser> {
    return this.orgId$.pipe(
      filter((organizationId): organizationId is number => !!organizationId),
      switchMap((organizationId) => {
        return this.amazonUserService.getAmazonUserV2(organizationId);
      }),
      take(1),
    );
  }
}
