<div class="flex flex-col items-start" *transloco="let t">
  <h4 class="mx-auto">{{ t("billing-plan-selection.select_the_plan_that_fits_you_the_most") }}</h4>
  <app-switch-button
    class="rank-switch mx-auto mt-2"
    [options]="supportedCurrencies"
    [buttonTexts]="supportedCurrencies"
    [buttonTooltips]="['', '']"
    [selected]="Currency.EUR"
    (buttonClicked)="selectCurrency($event)"
  ></app-switch-button>

  <div class="plan-wrapper">
    <div class="mt-5 flex w-full flex-wrap">
      @for (plan of displayedBillingPlans; track plan) {
        <div
          class="checkbox-card plan-card"
          (click)="
            isPlanDisabled(plan) === BillingPlanDisableReason.ENABLED ? selectPlan(plan) : $event.preventDefault()
          "
          [ngClass]="{
            disabled: isPlanDisabled(plan) !== BillingPlanDisableReason.ENABLED,
            'checkbox-card-selected': plan === selectedPlan,
          }"
        >
          <div class="flex w-full items-center justify-between">
            <div class="mr-2 inline-flex items-center">
              <h4 class="plan-card__title mr-2">{{ plan.plan }}</h4>
              @if (isPlanDisabled(plan) === BillingPlanDisableReason.ALREADY_SELECTED_PLAN) {
                <span class="pill pill-sm pill-success pill-no-hover">{{
                  t("billing-plan-selection.current_plan")
                }}</span>
              }
            </div>
            <mat-radio-button color="primary" [name]="plan.plan" [checked]="plan === selectedPlan"></mat-radio-button>
          </div>
          <div class="plan-card__price my-3">
            {{ plan.flatFee?.amount ?? 0 | currency: selectedCurrency : "symbol" : "1.0-0" : locale }}
            @if (plan.adSpendFee) {
              <span>
                + {{ plan.adSpendFee.amount | percent: "1.0-2" : locale }}
                {{ t("billing-plan-selection.percent_of_your_ad_spend") }}
              </span>
            }
            <span class="plan-card__price__freq">{{ printFrequency(plan.frequency) }}</span>
          </div>
          <ul class="plan-card__list">
            <li>
              {{
                plan.accountLimit
                  ? t("billing-plan-selection.limited_account", { count: plan.accountLimit })
                  : t("billing-plan-selection.unlimited_account")
              }}
            </li>
            <li>
              {{
                plan.marketplaceLimitPerAccount
                  ? t("billing-plan-selection.limited_marketplace", { count: plan.marketplaceLimitPerAccount })
                  : t("billing-plan-selection.unlimited_marketplace")
              }}
            </li>
            <li>
              {{
                plan.userLimit
                  ? t("billing-plan-selection.limited_user", { count: plan.userLimit })
                  : t("billing-plan-selection.unlimited_user")
              }}
            </li>
            @if (plan.plan === "STARTER") {
              <li>
                {{
                  plan.strategyLimit
                    ? t("billing-plan-selection.limited_strategy", { count: plan.strategyLimit })
                    : t("billing-plan-selection.unlimited_strategy")
                }}
              </li>
            }
            @if (plan.plan !== "STARTER") {
              <li>{{ t("billing-plan-selection.onboarding_call") }}</li>
            }
          </ul>
          @if (getBillingPlanDisabledReason(isPlanDisabled(plan))) {
            <div class="alert alert-warning mb-0 mt-auto">
              {{ getBillingPlanDisabledReason(isPlanDisabled(plan)) }}
            </div>
          }
        </div>
      }
    </div>

    <div class="mt-3 flex w-full justify-between px-3">
      <div>
        @if (currentBillingPlan?.plan === Plan.STARTER) {
          <IButton
            color="gray"
            variant="ghost"
            (onClick)="openCouponModal()"
            label="{{ t('billing-plan-selection.have_a_coupon') }}"
            size="xs"
          />
        }
      </div>
      <div>
        <button type="button" class="btn btn-ghost-secondary mr-2" (click)="cancelSelection()">
          {{ t("common.previous") }}
        </button>
        <button type="button" class="btn btn-primary" (click)="saveSelection()" [disabled]="!isValidSelection()">
          {{ t("common.next") }}
        </button>
      </div>
    </div>
  </div>
</div>
