import { Component, EventEmitter, Input, OnInit, Output, Signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import {
  AccountMarketplace,
  CampaignType,
  Marketplace,
  Strategy,
  StrategyAsin,
  StrategyStateEnum,
} from "@front/m19-api-client";
import { Catalog, StrategyEx, StrategyGroupEx } from "@front/m19-models";
import { AccountSelectionService, AsinService, StrategyService, TacosStrategiesService } from "@front/m19-services";
import { Utils } from "@front/m19-utils";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import {
  AlgoModeConfig,
  AlgoModeSelectionComponent,
} from "@m19-board/shared/algo-mode-selection/algo-mode-selection.component";
import { AsinStrategyUsageComponent } from "@m19-board/shared/asin-strategy-usage/asin-strategy-usage-modal.component";
import { formControlNames } from "@m19-board/sponsored-display/sd-manager/sd-strategy-creation/sd-strategy-creation.component";
import { ICON_ARROW_LEFT } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { map, switchMap } from "rxjs";
import { IAlertComponent, IButtonComponent } from "@front/m19-ui";
import { AsinsSelectionComponent } from "@m19-board/strategies/strategy-asins/asins-selection.component";

@UntilDestroy()
@Component({
  selector: "app-sp-strategy-creation",
  templateUrl: "./sp-strategy-creation.component.html",
  styleUrls: ["../../../strategies/strategies/strategy-styles.scss"],
  standalone: true,
  imports: [
    TranslocoDirective,
    ReactiveFormsModule,
    AlgoModeSelectionComponent,
    IAlertComponent,
    IButtonComponent,
    AsinsSelectionComponent,
  ],
})
export class SpStrategyCreationComponent implements OnInit {
  @Input() set asins(asins: StrategyAsin[]) {
    if (asins && asins.length) this.spForm.controls.asins.setValue(asins);
  }

  readonly ICON_ARROW = ICON_ARROW_LEFT;

  invalidFields: string[] = [];
  formSubmitted: boolean = false;
  submitOngoing = false;
  accountId!: string;
  accountGroupName!: string;
  marketplace!: Marketplace;
  organizationId!: number;
  asinEligibility: Map<string, { status: boolean; reason: string }> = new Map();
  strategies: Strategy[] = [];

  unavailableAsins: Signal<Set<string> | undefined> = toSignal(
    this.tacosStrategyService.tacosStrategyByAsins$.pipe(
      map((strategyByAsins) => {
        if (!strategyByAsins) return new Set();
        return new Set(strategyByAsins.keys());
      }),
    ),
  );

  @Output()
  public strategyCreated = new EventEmitter<StrategyEx>();
  @Output()
  public strategyGroupCreated = new EventEmitter<StrategyGroupEx>();

  spForm = new FormGroup({
    strategyName: new FormControl<string>(Utils.generateRandomName(), [Validators.required, Validators.maxLength(80)]),
    asins: new FormControl<StrategyAsin[]>([], [this.asinListValidator]),
    algoModeConfig: new FormControl<AlgoModeConfig | undefined>(undefined, [Validators.required]),
  });

  constructor(
    private accountSelection: AccountSelectionService,
    private asinService: AsinService,
    private toastrService: ToastrService,
    private tacosStrategyService: TacosStrategiesService,
    private modalService: BsModalService,
    private translocoService: TranslocoService,
    private strategyService: StrategyService,
  ) {}

  ngOnInit(): void {
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((am: AccountMarketplace) => {
        this.accountId = am.accountId;
        this.accountGroupName = am.accountGroupName!;
        this.marketplace = am.marketplace;
        this.organizationId = am.resourceOrganizationId!;
      });
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am: AccountMarketplace) => this.asinService.getCatalog(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((catalog: Catalog) => {
        this.asinEligibility = catalog.getSPEligibility();
      });
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am: AccountMarketplace) => this.strategyService.getStrategyIndex(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((strategies) => {
        this.strategies = Array.from(strategies.values());
      });
  }

  submitForm() {
    // prevent strategy creation on double click
    if (this.submitOngoing) {
      return;
    }
    this.formSubmitted = true;

    if (this.spForm.invalid) {
      this.invalidFields = this.getInvalidOrMissingFields();
      return;
    }

    this.submitOngoing = true;
    this.formSubmitted = false;
    const limitReached = this.strategyService.isStrategyLimitReached(
      this.accountId,
      this.marketplace,
      this.organizationId,
      this.strategies,
      CampaignType.SP,
    );

    const strategy: Strategy = {
      accountId: this.accountId,
      marketplace: this.marketplace,
      state: limitReached ? StrategyStateEnum.PAUSED : StrategyStateEnum.ENABLED,
      campaignType: CampaignType.SP,
      defaultStrategy: false,
      tactics: [],
      audienceTargetings: [],
      name: this.spForm.controls.strategyName.value ?? undefined,
      asins: this.spForm.controls.asins.value ?? undefined,
      ...this.spForm.controls.algoModeConfig.value!,
      acosTarget: this.spForm.controls.algoModeConfig.value?.acosTarget
        ? this.spForm.controls.algoModeConfig.value.acosTarget / 100
        : undefined,
    };
    const sub = this.strategyService
      .createStrategyGroup(
        this.organizationId,
        {
          accountId: this.accountId,
          marketplace: this.marketplace,
          strategyGroupName: strategy.name!,
        },
        strategy,
      )
      .subscribe({
        next: (strategyGroup) => {
          this.toastrService.success(
            this.translocoService.translate("sp-strategy-creation.strategy_group_created_successfully"),
            this.translocoService.translate("sp-strategy-creation.strategy_group_created"),
          );
          this.strategyGroupCreated.emit(strategyGroup);
          this.submitOngoing = false;
          sub.unsubscribe();
        },
        error: (e: string) => {
          this.toastrService.error(
            e,
            this.translocoService.translate("sp-strategy-creation.strategy_group_creation_error"),
          );
          this.submitOngoing = false;
          sub.unsubscribe();
        },
      });
  }

  openAsinUsageModal() {
    this.modalService.show(AsinStrategyUsageComponent, {
      class: "modal-primary modal-dialog-centered",
      initialState: {
        am: { accountId: this.accountId, marketplace: this.marketplace, accountName: "" },
        isTacosStrategy: false,
      },
    });
  }

  setAlgoModeConfig(algoModeConfig: Partial<AlgoModeConfig>) {
    this.spForm.controls.algoModeConfig.setValue(algoModeConfig as AlgoModeConfig);
    this.spForm.controls.algoModeConfig.setErrors(algoModeConfig.isValid ? null : { invalid: true });
  }

  addStrategyAsins(asins: StrategyAsin[]) {
    const newList = this.spForm.controls.asins.value?.concat(asins) ?? [];
    this.spForm.controls.asins.setValue(newList);
  }

  deleteStrategyAsins(asins: StrategyAsin[]) {
    const newList = this.spForm.controls.asins.value?.filter((a) => !asins.map((a2) => a2.asin).includes(a.asin)) ?? [];
    this.spForm.controls.asins.setValue(newList);
  }

  getInvalidOrMissingFields() {
    const res: string[] = [];
    const keys = Object.keys(this.spForm.controls);

    for (const c of keys) {
      if ((this.spForm.controls as any)[c].invalid) {
        if (c === "asins") continue;
        res.push(formControlNames.get(c)!);
      }
    }

    return res;
  }

  private asinListValidator(control: AbstractControl) {
    if (!control.value || !control.value.length) return { missingAsins: true };
    return null;
  }
}
