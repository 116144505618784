import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router, RouterLink } from "@angular/router";
import { AccountMarketplace, Marketplace, ProductGroup, StrategyAsin } from "@front/m19-api-client";
import { Catalog, ProductGroupEx } from "@front/m19-models";
import { AccountSelectionService, AsinService, ProductGroupService, SearchTextFilterPipe } from "@front/m19-services";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { ICON_ADD, ICON_CHEVRON_DOWN, ICON_CLOSE, ICON_EDIT_O } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { AsinsSelectionComponent, StrategyAsinSelectionMode } from "../../strategy-asins/asins-selection.component";
import { MatTooltip } from "@angular/material/tooltip";
import { MatMenuModule } from "@angular/material/menu";
import { NgForOf } from "@angular/common";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";
import { IButtonComponent } from "@front/m19-ui";
import { StrategyAsinsComponent } from "@m19-board/strategies/strategy-asins/strategy-asins.component";

@UntilDestroy()
@Component({
  selector: "app-product-group-page",
  templateUrl: "./product-group-page.component.html",
  styleUrls: ["./product-group-page.component.scss"],
  standalone: true,
  imports: [
    TranslocoDirective,
    RouterLink,
    MatTooltip,
    MatMenuModule,
    NgForOf,
    SpinnerComponent,
    IButtonComponent,
    AsinsSelectionComponent,
    StrategyAsinsComponent,
    SearchTextFilterPipe,
  ],
})
export class ProductGroupPageComponent implements OnInit {
  productGroup?: ProductGroupEx | null;
  productGroups?: ProductGroupEx[];
  alreadySelectedProducts?: StrategyAsin[];
  marketplace?: Marketplace;
  accountId?: string;
  isReadOnly = false;
  editMode = false;
  productGroupNameFilter = "";
  newProductGroupName = "";

  readonly ICON_EDIT = ICON_EDIT_O;
  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;

  private catalog?: string[];

  readonly selectionModes: { selectionMode: StrategyAsinSelectionMode; label: string }[] = [
    { selectionMode: StrategyAsinSelectionMode.FromCatalog, label: "v2-sidebar.catalog" },
    { selectionMode: StrategyAsinSelectionMode.Bulk, label: "sp-substrategy-creation.bulk" },
  ];

  @ViewChild("search") search!: ElementRef;
  productGroupNameModal?: BsModalRef;

  constructor(
    private route: ActivatedRoute,
    private productGroupService: ProductGroupService,
    private router: Router,
    private asinService: AsinService,
    private accountSelectionService: AccountSelectionService,
    private modalService: BsModalService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((am: AccountMarketplace) => {
        this.marketplace = am.marketplace;
        this.accountId = am.accountId;
      });
    combineLatest<[Params, ProductGroupEx[]]>([
      this.route.params,
      this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(
        switchMap((am) => this.productGroupService.getProductGroups(am.accountId, am.marketplace)),
      ),
    ])
      .pipe(
        map(([params, productGroups]) => {
          const id = Number(params["id"]);
          return productGroups.find((pg) => pg.productGroupId == id);
        }),
      )
      .subscribe((productGroup: ProductGroupEx | undefined) => {
        if (productGroup == undefined) {
          this.productGroup = null;
          return;
        }
        this.productGroup = productGroup;
        this.alreadySelectedProducts = productGroup.items.map((a) => ({ asin: a }));
      });
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am: AccountMarketplace) => this.productGroupService.getProductGroups(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((x) => (this.productGroups = x));
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am: AccountMarketplace) => this.asinService.getCatalog(am.accountId, am.marketplace)),
      )
      .subscribe((catalog: Catalog) => {
        this.catalog = [...catalog.childAsins.values()];
      });
    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
  }

  navigateToProductGroup(productGroup: ProductGroup) {
    this.router.navigate(["/product-center/product-group", productGroup.productGroupId]);
  }

  openProductGroupNameModal(template: TemplateRef<any>) {
    if (this.isReadOnly) {
      return;
    }
    this.newProductGroupName = (this.productGroup as ProductGroupEx).productGroupName;
    this.productGroupNameModal = this.modalService.show(template, { class: "modal-primary modal-dialog-centered" });
  }

  changeName(newname: string) {
    this.productGroupService
      .updateProductGroupName(
        this.accountId!,
        this.marketplace!,
        (this.productGroup as ProductGroup).productGroupId!,
        newname,
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.toastrService.success(this.translocoService.translate("product-group.product_group_name_updated"));
          this.productGroupNameModal?.hide();
        },
        error: (err) => {
          this.toastrService.error(
            err,
            this.translocoService.translate("product-group-page.error_updating_product_group_name"),
          );
        },
      });
  }

  addAsinsArrayToProductGroup(asins: StrategyAsin[]) {
    const target: string[] = asins.map((a) => a.asin);
    this.productGroupService
      .addAsinsToProductGroup(
        this.accountId!,
        this.marketplace!,
        (this.productGroup as ProductGroupEx).productGroupId,
        target,
        this.catalog!,
      )
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("product-group-page.asins_added_to_product_group"),
          );
        },
        error: (err) => {
          this.toastrService.error(
            err,
            this.translocoService.translate("product-group-page.error_adding_asins_to_product_group"),
          );
        },
      });
  }

  deleteAsinArrayFromProductGroup(asins: StrategyAsin[]) {
    if (this.isReadOnly) {
      return;
    }
    const target: string[] = asins.map((a) => a.asin);
    this.productGroupService
      .deleteAsinsFromProductGroup(
        this.accountId!,
        this.marketplace!,
        (this.productGroup as ProductGroupEx).productGroupId,
        target,
      )
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("product-group-page.asins_removed_from_product_group"),
          );
        },
        error: (err) => {
          this.toastrService.error(
            err,
            this.translocoService.translate("product-group-page.error_removing_asins_from_product_group"),
          );
        },
      });
  }

  setFocus(): void {
    setTimeout(() => {
      this.search.nativeElement.value = "";
      this.search.nativeElement.focus();
    });
  }

  // Use for filter
  accessValue(s: ProductGroupEx) {
    return s.productGroupName;
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  protected readonly ICON_ADD = ICON_ADD;
  protected readonly ICON_EDIT_O = ICON_EDIT_O;
  protected readonly ICON_CLOSE = ICON_CLOSE;
}
