// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    Currency,
} from './';

/**
 * @export
 * @interface UserBase
 */
export interface UserBase {
    /**
     * @type {string}
     * @memberof UserBase
     */
    email?: string;
    /**
     * @type {string}
     * @memberof UserBase
     */
    userName?: string;
    /**
     * @type {Currency}
     * @memberof UserBase
     */
    defaultCurrency?: Currency;
    /**
     * @type {string}
     * @memberof UserBase
     */
    locale?: string;
    /**
     * @type {boolean}
     * @memberof UserBase
     */
    registered?: boolean;
    /**
     * @type {string}
     * @memberof UserBase
     */
    newPassword?: string;
}
