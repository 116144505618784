// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    AccountMarketplace,
    AdStats,
    AllVendorInventory,
    DailyAdSpendFee,
    DailyVendorAllSales,
    DailyVendorAsinAllSales,
    DspStats,
    GlobalSettlementFees,
    KeywordTopOfSearchRankings,
    Marketplace,
    SearchTermRank,
    SkuOrderStats,
    Strategy,
} from '../models';

export interface GetAggregatedAdvertisingStatsRequest {
    minDate: string;
    maxDate: string;
    accountMarketplace?: Array<AccountMarketplace>;
}

export interface GetAggregatedAllSalesRequest {
    minDate: string;
    maxDate: string;
    accountMarketplace?: Array<AccountMarketplace>;
}

export interface GetAggregatedVendorSalesRequest {
    minDate: string;
    maxDate: string;
    accountMarketplace?: Array<AccountMarketplace>;
}

export interface GetDailyAllSalesRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
}

export interface GetDailyAsinAllSalesRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
    asin?: Array<string>;
}

export interface GetDailyAsinStatsRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
    asin?: Array<string>;
}

export interface GetDailyAsinTrafficRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
    asin?: Array<string>;
}

export interface GetDailyBrandAsinStatsRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
}

export interface GetDailyDspLineItemStatsRequest {
    accountId: string;
    marketplace: Marketplace;
    dspAdvertiserId: string;
    minDate: string;
    maxDate: string;
}

export interface GetDailyPlacementStatsRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
}

export interface GetDailySbCreativeStatsRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
}

export interface GetDailyStatsRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
}

export interface GetDailyTargetingAsinStatsRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
    strategyId?: number;
}

export interface GetDailyTrafficRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
}

export interface GetDailyVendorAllSalesRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
}

export interface GetDailyVendorAsinAllSalesRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
    asin?: Array<string>;
}

export interface GetDailyVendorInventoryRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
    asin?: Array<string>;
}

export interface GetGlobalSettlementFeesRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
}

export interface GetHourlyCampaignStatsRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
}

export interface GetLastSearchTermRanksRequest {
    marketplace: Marketplace;
    requestBody?: Array<string>;
}

export interface GetMonthlyCostRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
}

export interface GetQueryAsinStatsRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
}

export interface GetSearchTermRanksRequest {
    marketplace: Marketplace;
    searchTerm: string;
    aggregation: GetSearchTermRanksAggregationEnum;
    minDate: string;
    maxDate: string;
}

export interface GetSearchTermsAutoCompleteRequest {
    marketplace: Marketplace;
    searchTerm: string;
}

export interface GetSkuOrderStatsRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
}

export interface GetStrategyConfigurationHistoryRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
}

export interface GetStrategySpendRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
    strategyId?: number;
}

export interface GetStrategyTopOfSearchRankingsHistoryRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
    strategyId?: number;
}

export interface GetTopSearchTermsRequest {
    marketplace: Marketplace;
}

export interface GetVendorNetProductMarginRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
    asin?: Array<string>;
}

/**
 * no description
 */
export class StatsApi extends BaseAPI {

    /**
     * * Get imp, clicks, adv sales by account marketplace 
     * Get imp, clicks, adv sales by account marketplace
     */
    getAggregatedAdvertisingStats({ minDate, maxDate, accountMarketplace }: GetAggregatedAdvertisingStatsRequest): Observable<Array<AdStats>>
    getAggregatedAdvertisingStats({ minDate, maxDate, accountMarketplace }: GetAggregatedAdvertisingStatsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AdStats>>>
    getAggregatedAdvertisingStats({ minDate, maxDate, accountMarketplace }: GetAggregatedAdvertisingStatsRequest, opts?: OperationOpts): Observable<Array<AdStats> | AjaxResponse<Array<AdStats>>> {
        throwIfNullOrUndefined(minDate, 'minDate', 'getAggregatedAdvertisingStats');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getAggregatedAdvertisingStats');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<AdStats>>({
            url: '/stats/aggregatedAdvertisingStats',
            method: 'POST',
            headers,
            query,
            body: accountMarketplace,
        }, opts?.responseOpts);
    };

    /**
     * * Get total sales by account marketplace 
     * Get total sales by account marketplace
     */
    getAggregatedAllSales({ minDate, maxDate, accountMarketplace }: GetAggregatedAllSalesRequest): Observable<Array<AdStats>>
    getAggregatedAllSales({ minDate, maxDate, accountMarketplace }: GetAggregatedAllSalesRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AdStats>>>
    getAggregatedAllSales({ minDate, maxDate, accountMarketplace }: GetAggregatedAllSalesRequest, opts?: OperationOpts): Observable<Array<AdStats> | AjaxResponse<Array<AdStats>>> {
        throwIfNullOrUndefined(minDate, 'minDate', 'getAggregatedAllSales');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getAggregatedAllSales');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<AdStats>>({
            url: '/stats/aggregatedAllSales',
            method: 'POST',
            headers,
            query,
            body: accountMarketplace,
        }, opts?.responseOpts);
    };

    /**
     * * Get total sales by account marketplace 
     * Get total Vendor sales by account marketplace
     */
    getAggregatedVendorSales({ minDate, maxDate, accountMarketplace }: GetAggregatedVendorSalesRequest): Observable<Array<AdStats>>
    getAggregatedVendorSales({ minDate, maxDate, accountMarketplace }: GetAggregatedVendorSalesRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AdStats>>>
    getAggregatedVendorSales({ minDate, maxDate, accountMarketplace }: GetAggregatedVendorSalesRequest, opts?: OperationOpts): Observable<Array<AdStats> | AjaxResponse<Array<AdStats>>> {
        throwIfNullOrUndefined(minDate, 'minDate', 'getAggregatedVendorSales');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getAggregatedVendorSales');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<AdStats>>({
            url: '/stats/aggregatedVendorSales',
            method: 'POST',
            headers,
            query,
            body: accountMarketplace,
        }, opts?.responseOpts);
    };

    /**
     * Get daily ad spend fee for all organizations
     * Get daily ad spend fee for all organizations
     */
    getDailyAdSpendFee(): Observable<Array<DailyAdSpendFee>>
    getDailyAdSpendFee(opts?: OperationOpts): Observable<AjaxResponse<Array<DailyAdSpendFee>>>
    getDailyAdSpendFee(opts?: OperationOpts): Observable<Array<DailyAdSpendFee> | AjaxResponse<Array<DailyAdSpendFee>>> {
        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<DailyAdSpendFee>>({
            url: '/stats/dailyAdSpendFee',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * * get all Sales by date 
     * Get total sales by date
     */
    getDailyAllSales({ accountId, marketplace, minDate, maxDate }: GetDailyAllSalesRequest): Observable<Array<AdStats>>
    getDailyAllSales({ accountId, marketplace, minDate, maxDate }: GetDailyAllSalesRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AdStats>>>
    getDailyAllSales({ accountId, marketplace, minDate, maxDate }: GetDailyAllSalesRequest, opts?: OperationOpts): Observable<Array<AdStats> | AjaxResponse<Array<AdStats>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getDailyAllSales');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getDailyAllSales');
        throwIfNullOrUndefined(minDate, 'minDate', 'getDailyAllSales');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getDailyAllSales');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<AdStats>>({
            url: '/stats/dailyAllSales',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * * Get total sales by ASIN and date 
     * Get total sales by ASIN and date
     */
    getDailyAsinAllSales({ accountId, marketplace, minDate, maxDate, asin }: GetDailyAsinAllSalesRequest): Observable<Array<AdStats>>
    getDailyAsinAllSales({ accountId, marketplace, minDate, maxDate, asin }: GetDailyAsinAllSalesRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AdStats>>>
    getDailyAsinAllSales({ accountId, marketplace, minDate, maxDate, asin }: GetDailyAsinAllSalesRequest, opts?: OperationOpts): Observable<Array<AdStats> | AjaxResponse<Array<AdStats>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getDailyAsinAllSales');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getDailyAsinAllSales');
        throwIfNullOrUndefined(minDate, 'minDate', 'getDailyAsinAllSales');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getDailyAsinAllSales');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        if (asin != null) { query['asin'] = asin; }

        return this.request<Array<AdStats>>({
            url: '/stats/dailyAsinAllSales',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * * Get stats by date and ASIN 
     * Get stats by date and ASIN
     */
    getDailyAsinStats({ accountId, marketplace, minDate, maxDate, asin }: GetDailyAsinStatsRequest): Observable<Array<AdStats>>
    getDailyAsinStats({ accountId, marketplace, minDate, maxDate, asin }: GetDailyAsinStatsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AdStats>>>
    getDailyAsinStats({ accountId, marketplace, minDate, maxDate, asin }: GetDailyAsinStatsRequest, opts?: OperationOpts): Observable<Array<AdStats> | AjaxResponse<Array<AdStats>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getDailyAsinStats');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getDailyAsinStats');
        throwIfNullOrUndefined(minDate, 'minDate', 'getDailyAsinStats');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getDailyAsinStats');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        if (asin != null) { query['asin'] = asin; }

        return this.request<Array<AdStats>>({
            url: '/stats/dailyAsinStats',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * * get traffic stats (sessions, pageview) by date 
     * Get traffic stats by date and by asin
     */
    getDailyAsinTraffic({ accountId, marketplace, minDate, maxDate, asin }: GetDailyAsinTrafficRequest): Observable<Array<AdStats>>
    getDailyAsinTraffic({ accountId, marketplace, minDate, maxDate, asin }: GetDailyAsinTrafficRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AdStats>>>
    getDailyAsinTraffic({ accountId, marketplace, minDate, maxDate, asin }: GetDailyAsinTrafficRequest, opts?: OperationOpts): Observable<Array<AdStats> | AjaxResponse<Array<AdStats>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getDailyAsinTraffic');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getDailyAsinTraffic');
        throwIfNullOrUndefined(minDate, 'minDate', 'getDailyAsinTraffic');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getDailyAsinTraffic');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        if (asin != null) { query['asin'] = asin; }

        return this.request<Array<AdStats>>({
            url: '/stats/dailyAsinTraffic',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * get Stats (impressions, clicks, cost, conversions, sales amount) by date, brandId, campaing type, ASIN and query Type
     * Get ad stats by date, brandId, campaing type, ASIN and query Type
     */
    getDailyBrandAsinStats({ accountId, marketplace, minDate, maxDate }: GetDailyBrandAsinStatsRequest): Observable<Array<AdStats>>
    getDailyBrandAsinStats({ accountId, marketplace, minDate, maxDate }: GetDailyBrandAsinStatsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AdStats>>>
    getDailyBrandAsinStats({ accountId, marketplace, minDate, maxDate }: GetDailyBrandAsinStatsRequest, opts?: OperationOpts): Observable<Array<AdStats> | AjaxResponse<Array<AdStats>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getDailyBrandAsinStats');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getDailyBrandAsinStats');
        throwIfNullOrUndefined(minDate, 'minDate', 'getDailyBrandAsinStats');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getDailyBrandAsinStats');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<AdStats>>({
            url: '/stats/dailyBrandAsinStats',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Get dsp stats by date, orderId, lineItemId, creativeId
     */
    getDailyDspLineItemStats({ accountId, marketplace, dspAdvertiserId, minDate, maxDate }: GetDailyDspLineItemStatsRequest): Observable<Array<DspStats>>
    getDailyDspLineItemStats({ accountId, marketplace, dspAdvertiserId, minDate, maxDate }: GetDailyDspLineItemStatsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<DspStats>>>
    getDailyDspLineItemStats({ accountId, marketplace, dspAdvertiserId, minDate, maxDate }: GetDailyDspLineItemStatsRequest, opts?: OperationOpts): Observable<Array<DspStats> | AjaxResponse<Array<DspStats>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getDailyDspLineItemStats');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getDailyDspLineItemStats');
        throwIfNullOrUndefined(dspAdvertiserId, 'dspAdvertiserId', 'getDailyDspLineItemStats');
        throwIfNullOrUndefined(minDate, 'minDate', 'getDailyDspLineItemStats');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getDailyDspLineItemStats');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'dspAdvertiserId': dspAdvertiserId,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<DspStats>>({
            url: '/stats/dailyDspLineItemStats',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * get Stats (impressions, clicks, cost, conversions, sales amount) by amazon campaign and date and placement
     * Get stats by date and campaing type and boostPlacementTop
     */
    getDailyPlacementStats({ accountId, marketplace, minDate, maxDate }: GetDailyPlacementStatsRequest): Observable<Array<AdStats>>
    getDailyPlacementStats({ accountId, marketplace, minDate, maxDate }: GetDailyPlacementStatsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AdStats>>>
    getDailyPlacementStats({ accountId, marketplace, minDate, maxDate }: GetDailyPlacementStatsRequest, opts?: OperationOpts): Observable<Array<AdStats> | AjaxResponse<Array<AdStats>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getDailyPlacementStats');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getDailyPlacementStats');
        throwIfNullOrUndefined(minDate, 'minDate', 'getDailyPlacementStats');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getDailyPlacementStats');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<AdStats>>({
            url: '/stats/dailyPlacement',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * get Stats (impressions, clicks, cost, conversions, sales amount) by sb creative and date
     * Get stats by date and creative type
     */
    getDailySbCreativeStats({ accountId, marketplace, minDate, maxDate }: GetDailySbCreativeStatsRequest): Observable<Array<AdStats>>
    getDailySbCreativeStats({ accountId, marketplace, minDate, maxDate }: GetDailySbCreativeStatsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AdStats>>>
    getDailySbCreativeStats({ accountId, marketplace, minDate, maxDate }: GetDailySbCreativeStatsRequest, opts?: OperationOpts): Observable<Array<AdStats> | AjaxResponse<Array<AdStats>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getDailySbCreativeStats');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getDailySbCreativeStats');
        throwIfNullOrUndefined(minDate, 'minDate', 'getDailySbCreativeStats');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getDailySbCreativeStats');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<AdStats>>({
            url: '/stats/dailySbCreativeStats',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * * get Stats (impressions, clicks, cost, conversions, sales amount) by amazon campaign and date 
     * Get stats by date and campaign type
     */
    getDailyStats({ accountId, marketplace, minDate, maxDate }: GetDailyStatsRequest): Observable<Array<AdStats>>
    getDailyStats({ accountId, marketplace, minDate, maxDate }: GetDailyStatsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AdStats>>>
    getDailyStats({ accountId, marketplace, minDate, maxDate }: GetDailyStatsRequest, opts?: OperationOpts): Observable<Array<AdStats> | AjaxResponse<Array<AdStats>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getDailyStats');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getDailyStats');
        throwIfNullOrUndefined(minDate, 'minDate', 'getDailyStats');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getDailyStats');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<AdStats>>({
            url: '/stats/daily',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * * Get stats by date and strategyId 
     * Get stats by date and strategyId
     */
    getDailyTargetingAsinStats({ accountId, marketplace, minDate, maxDate, strategyId }: GetDailyTargetingAsinStatsRequest): Observable<Array<AdStats>>
    getDailyTargetingAsinStats({ accountId, marketplace, minDate, maxDate, strategyId }: GetDailyTargetingAsinStatsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AdStats>>>
    getDailyTargetingAsinStats({ accountId, marketplace, minDate, maxDate, strategyId }: GetDailyTargetingAsinStatsRequest, opts?: OperationOpts): Observable<Array<AdStats> | AjaxResponse<Array<AdStats>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getDailyTargetingAsinStats');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getDailyTargetingAsinStats');
        throwIfNullOrUndefined(minDate, 'minDate', 'getDailyTargetingAsinStats');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getDailyTargetingAsinStats');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        if (strategyId != null) { query['strategyId'] = strategyId; }

        return this.request<Array<AdStats>>({
            url: '/stats/dailyTargetingAsinStats',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * * get traffic stats (sessions, pageview) by date 
     * Get traffic stats by date
     */
    getDailyTraffic({ accountId, marketplace, minDate, maxDate }: GetDailyTrafficRequest): Observable<Array<AdStats>>
    getDailyTraffic({ accountId, marketplace, minDate, maxDate }: GetDailyTrafficRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AdStats>>>
    getDailyTraffic({ accountId, marketplace, minDate, maxDate }: GetDailyTrafficRequest, opts?: OperationOpts): Observable<Array<AdStats> | AjaxResponse<Array<AdStats>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getDailyTraffic');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getDailyTraffic');
        throwIfNullOrUndefined(minDate, 'minDate', 'getDailyTraffic');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getDailyTraffic');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<AdStats>>({
            url: '/stats/dailyTraffic',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * * get all Sales by date 
     * Get total sales by date
     */
    getDailyVendorAllSales({ accountId, marketplace, minDate, maxDate }: GetDailyVendorAllSalesRequest): Observable<Array<DailyVendorAllSales>>
    getDailyVendorAllSales({ accountId, marketplace, minDate, maxDate }: GetDailyVendorAllSalesRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<DailyVendorAllSales>>>
    getDailyVendorAllSales({ accountId, marketplace, minDate, maxDate }: GetDailyVendorAllSalesRequest, opts?: OperationOpts): Observable<Array<DailyVendorAllSales> | AjaxResponse<Array<DailyVendorAllSales>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getDailyVendorAllSales');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getDailyVendorAllSales');
        throwIfNullOrUndefined(minDate, 'minDate', 'getDailyVendorAllSales');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getDailyVendorAllSales');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<DailyVendorAllSales>>({
            url: '/stats/dailyVendorAllSales',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * * Get total sales by ASIN and date 
     * Get total sales by ASIN and date
     */
    getDailyVendorAsinAllSales({ accountId, marketplace, minDate, maxDate, asin }: GetDailyVendorAsinAllSalesRequest): Observable<Array<DailyVendorAsinAllSales>>
    getDailyVendorAsinAllSales({ accountId, marketplace, minDate, maxDate, asin }: GetDailyVendorAsinAllSalesRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<DailyVendorAsinAllSales>>>
    getDailyVendorAsinAllSales({ accountId, marketplace, minDate, maxDate, asin }: GetDailyVendorAsinAllSalesRequest, opts?: OperationOpts): Observable<Array<DailyVendorAsinAllSales> | AjaxResponse<Array<DailyVendorAsinAllSales>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getDailyVendorAsinAllSales');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getDailyVendorAsinAllSales');
        throwIfNullOrUndefined(minDate, 'minDate', 'getDailyVendorAsinAllSales');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getDailyVendorAsinAllSales');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        if (asin != null) { query['asin'] = asin; }

        return this.request<Array<DailyVendorAsinAllSales>>({
            url: '/stats/dailyVendorAsinAllSales',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Get vendor inventory by date and ASIN
     * Get Vendor inventory stats by ASIN and date
     */
    getDailyVendorInventory({ accountId, marketplace, minDate, maxDate, asin }: GetDailyVendorInventoryRequest): Observable<Array<AllVendorInventory>>
    getDailyVendorInventory({ accountId, marketplace, minDate, maxDate, asin }: GetDailyVendorInventoryRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AllVendorInventory>>>
    getDailyVendorInventory({ accountId, marketplace, minDate, maxDate, asin }: GetDailyVendorInventoryRequest, opts?: OperationOpts): Observable<Array<AllVendorInventory> | AjaxResponse<Array<AllVendorInventory>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getDailyVendorInventory');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getDailyVendorInventory');
        throwIfNullOrUndefined(minDate, 'minDate', 'getDailyVendorInventory');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getDailyVendorInventory');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        if (asin != null) { query['asin'] = asin; }

        return this.request<Array<AllVendorInventory>>({
            url: '/stats/dailyVendorInventory',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    getGlobalSettlementFees({ accountId, marketplace, minDate, maxDate }: GetGlobalSettlementFeesRequest): Observable<Array<GlobalSettlementFees>>
    getGlobalSettlementFees({ accountId, marketplace, minDate, maxDate }: GetGlobalSettlementFeesRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<GlobalSettlementFees>>>
    getGlobalSettlementFees({ accountId, marketplace, minDate, maxDate }: GetGlobalSettlementFeesRequest, opts?: OperationOpts): Observable<Array<GlobalSettlementFees> | AjaxResponse<Array<GlobalSettlementFees>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getGlobalSettlementFees');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getGlobalSettlementFees');
        throwIfNullOrUndefined(minDate, 'minDate', 'getGlobalSettlementFees');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getGlobalSettlementFees');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<GlobalSettlementFees>>({
            url: '/stats/globalSettlementFees',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * * get Stats (impressions, clicks, cost, conversions, sales amount) by amazon campaign and date and by hours 
     * Get stats by amazon campaignId and date for each hour of the day
     */
    getHourlyCampaignStats({ accountId, marketplace, minDate, maxDate }: GetHourlyCampaignStatsRequest): Observable<Array<AdStats>>
    getHourlyCampaignStats({ accountId, marketplace, minDate, maxDate }: GetHourlyCampaignStatsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AdStats>>>
    getHourlyCampaignStats({ accountId, marketplace, minDate, maxDate }: GetHourlyCampaignStatsRequest, opts?: OperationOpts): Observable<Array<AdStats> | AjaxResponse<Array<AdStats>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getHourlyCampaignStats');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getHourlyCampaignStats');
        throwIfNullOrUndefined(minDate, 'minDate', 'getHourlyCampaignStats');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getHourlyCampaignStats');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<AdStats>>({
            url: '/stats/campaign/hourly',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * * get search term ranks by marketplace 
     * get last search term ranks
     */
    getLastSearchTermRanks({ marketplace, requestBody }: GetLastSearchTermRanksRequest): Observable<Array<SearchTermRank>>
    getLastSearchTermRanks({ marketplace, requestBody }: GetLastSearchTermRanksRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<SearchTermRank>>>
    getLastSearchTermRanks({ marketplace, requestBody }: GetLastSearchTermRanksRequest, opts?: OperationOpts): Observable<Array<SearchTermRank> | AjaxResponse<Array<SearchTermRank>>> {
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getLastSearchTermRanks');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'marketplace': marketplace,
        };

        return this.request<Array<SearchTermRank>>({
            url: '/stats/searchTermRanks',
            method: 'POST',
            headers,
            query,
            body: requestBody,
        }, opts?.responseOpts);
    };

    /**
     * * get cost by amazon campaign type and month 
     * Get cost by month and campaign type
     */
    getMonthlyCost({ accountId, marketplace, minDate, maxDate }: GetMonthlyCostRequest): Observable<Array<AllVendorInventory>>
    getMonthlyCost({ accountId, marketplace, minDate, maxDate }: GetMonthlyCostRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AllVendorInventory>>>
    getMonthlyCost({ accountId, marketplace, minDate, maxDate }: GetMonthlyCostRequest, opts?: OperationOpts): Observable<Array<AllVendorInventory> | AjaxResponse<Array<AllVendorInventory>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getMonthlyCost');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getMonthlyCost');
        throwIfNullOrUndefined(minDate, 'minDate', 'getMonthlyCost');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getMonthlyCost');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<AllVendorInventory>>({
            url: '/stats/monthlyCost',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * * get stats (impressions, clicks, cost, conversions, sales amount) with asin-query stats a given date range * provide stats for a single marketplaces 
     * aggregated stats with query - asin stats
     */
    getQueryAsinStats({ accountId, marketplace, minDate, maxDate }: GetQueryAsinStatsRequest): Observable<Array<AdStats>>
    getQueryAsinStats({ accountId, marketplace, minDate, maxDate }: GetQueryAsinStatsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AdStats>>>
    getQueryAsinStats({ accountId, marketplace, minDate, maxDate }: GetQueryAsinStatsRequest, opts?: OperationOpts): Observable<Array<AdStats> | AjaxResponse<Array<AdStats>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getQueryAsinStats');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getQueryAsinStats');
        throwIfNullOrUndefined(minDate, 'minDate', 'getQueryAsinStats');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getQueryAsinStats');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<AdStats>>({
            url: '/stats/query',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * * get search term ranks by marketplace 
     * get search term ranks
     */
    getSearchTermRanks({ marketplace, searchTerm, aggregation, minDate, maxDate }: GetSearchTermRanksRequest): Observable<Array<number>>
    getSearchTermRanks({ marketplace, searchTerm, aggregation, minDate, maxDate }: GetSearchTermRanksRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<number>>>
    getSearchTermRanks({ marketplace, searchTerm, aggregation, minDate, maxDate }: GetSearchTermRanksRequest, opts?: OperationOpts): Observable<Array<number> | AjaxResponse<Array<number>>> {
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getSearchTermRanks');
        throwIfNullOrUndefined(searchTerm, 'searchTerm', 'getSearchTermRanks');
        throwIfNullOrUndefined(aggregation, 'aggregation', 'getSearchTermRanks');
        throwIfNullOrUndefined(minDate, 'minDate', 'getSearchTermRanks');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getSearchTermRanks');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'marketplace': marketplace,
            'searchTerm': searchTerm,
            'aggregation': aggregation,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<number>>({
            url: '/stats/searchTermRanks',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * * get search terms auto complete words list 
     * get search terms auto complete words list
     */
    getSearchTermsAutoComplete({ marketplace, searchTerm }: GetSearchTermsAutoCompleteRequest): Observable<Array<SearchTermRank>>
    getSearchTermsAutoComplete({ marketplace, searchTerm }: GetSearchTermsAutoCompleteRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<SearchTermRank>>>
    getSearchTermsAutoComplete({ marketplace, searchTerm }: GetSearchTermsAutoCompleteRequest, opts?: OperationOpts): Observable<Array<SearchTermRank> | AjaxResponse<Array<SearchTermRank>>> {
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getSearchTermsAutoComplete');
        throwIfNullOrUndefined(searchTerm, 'searchTerm', 'getSearchTermsAutoComplete');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'marketplace': marketplace,
            'searchTerm': searchTerm,
        };

        return this.request<Array<SearchTermRank>>({
            url: '/stats/searchTermsAutoComplete',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Sum orders for all SKUs for this account on the inventory marketplace
     */
    getSkuOrderStats({ accountId, marketplace, minDate, maxDate }: GetSkuOrderStatsRequest): Observable<Array<SkuOrderStats>>
    getSkuOrderStats({ accountId, marketplace, minDate, maxDate }: GetSkuOrderStatsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<SkuOrderStats>>>
    getSkuOrderStats({ accountId, marketplace, minDate, maxDate }: GetSkuOrderStatsRequest, opts?: OperationOpts): Observable<Array<SkuOrderStats> | AjaxResponse<Array<SkuOrderStats>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getSkuOrderStats');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getSkuOrderStats');
        throwIfNullOrUndefined(minDate, 'minDate', 'getSkuOrderStats');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getSkuOrderStats');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<SkuOrderStats>>({
            url: '/stats/skuOrders/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * * get strategy conf history (acosTarget, state, dailyBudget, suggestedBid, minDailySpend) by strategyId and date 
     * Get strategy configuration history
     */
    getStrategyConfigurationHistory({ accountId, marketplace, minDate, maxDate }: GetStrategyConfigurationHistoryRequest): Observable<Array<Strategy>>
    getStrategyConfigurationHistory({ accountId, marketplace, minDate, maxDate }: GetStrategyConfigurationHistoryRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<Strategy>>>
    getStrategyConfigurationHistory({ accountId, marketplace, minDate, maxDate }: GetStrategyConfigurationHistoryRequest, opts?: OperationOpts): Observable<Array<Strategy> | AjaxResponse<Array<Strategy>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getStrategyConfigurationHistory');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getStrategyConfigurationHistory');
        throwIfNullOrUndefined(minDate, 'minDate', 'getStrategyConfigurationHistory');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getStrategyConfigurationHistory');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<Strategy>>({
            url: '/stats/strategyConfigurationHistory',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * * get strategySpend 
     * Get strategy spend
     */
    getStrategySpend({ accountId, marketplace, minDate, maxDate, strategyId }: GetStrategySpendRequest): Observable<Array<AdStats>>
    getStrategySpend({ accountId, marketplace, minDate, maxDate, strategyId }: GetStrategySpendRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AdStats>>>
    getStrategySpend({ accountId, marketplace, minDate, maxDate, strategyId }: GetStrategySpendRequest, opts?: OperationOpts): Observable<Array<AdStats> | AjaxResponse<Array<AdStats>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getStrategySpend');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getStrategySpend');
        throwIfNullOrUndefined(minDate, 'minDate', 'getStrategySpend');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getStrategySpend');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        if (strategyId != null) { query['strategyId'] = strategyId; }

        return this.request<Array<AdStats>>({
            url: '/stats/strategySpend',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * * get strategy Top Of search rankings history 
     * Get strategy top of search rankings history
     */
    getStrategyTopOfSearchRankingsHistory({ accountId, marketplace, minDate, maxDate, strategyId }: GetStrategyTopOfSearchRankingsHistoryRequest): Observable<Array<KeywordTopOfSearchRankings>>
    getStrategyTopOfSearchRankingsHistory({ accountId, marketplace, minDate, maxDate, strategyId }: GetStrategyTopOfSearchRankingsHistoryRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<KeywordTopOfSearchRankings>>>
    getStrategyTopOfSearchRankingsHistory({ accountId, marketplace, minDate, maxDate, strategyId }: GetStrategyTopOfSearchRankingsHistoryRequest, opts?: OperationOpts): Observable<Array<KeywordTopOfSearchRankings> | AjaxResponse<Array<KeywordTopOfSearchRankings>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getStrategyTopOfSearchRankingsHistory');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getStrategyTopOfSearchRankingsHistory');
        throwIfNullOrUndefined(minDate, 'minDate', 'getStrategyTopOfSearchRankingsHistory');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getStrategyTopOfSearchRankingsHistory');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        if (strategyId != null) { query['strategyId'] = strategyId; }

        return this.request<Array<KeywordTopOfSearchRankings>>({
            url: '/stats/strategyTopOfSearchRankingsHistory',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * * get top search terms 
     * get top search terms list
     */
    getTopSearchTerms({ marketplace }: GetTopSearchTermsRequest): Observable<Array<SearchTermRank>>
    getTopSearchTerms({ marketplace }: GetTopSearchTermsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<SearchTermRank>>>
    getTopSearchTerms({ marketplace }: GetTopSearchTermsRequest, opts?: OperationOpts): Observable<Array<SearchTermRank> | AjaxResponse<Array<SearchTermRank>>> {
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getTopSearchTerms');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'marketplace': marketplace,
        };

        return this.request<Array<SearchTermRank>>({
            url: '/stats/topSearchTerms',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * * get Net PPM by date 
     * Get Net PPM by date and by asin
     */
    getVendorNetProductMargin({ accountId, marketplace, minDate, maxDate, asin }: GetVendorNetProductMarginRequest): Observable<Array<AdStats>>
    getVendorNetProductMargin({ accountId, marketplace, minDate, maxDate, asin }: GetVendorNetProductMarginRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AdStats>>>
    getVendorNetProductMargin({ accountId, marketplace, minDate, maxDate, asin }: GetVendorNetProductMarginRequest, opts?: OperationOpts): Observable<Array<AdStats> | AjaxResponse<Array<AdStats>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getVendorNetProductMargin');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getVendorNetProductMargin');
        throwIfNullOrUndefined(minDate, 'minDate', 'getVendorNetProductMargin');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getVendorNetProductMargin');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        if (asin != null) { query['asin'] = asin; }

        return this.request<Array<AdStats>>({
            url: '/stats/vendorNetProductMargin',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetSearchTermRanksAggregationEnum {
    daily = 'daily',
    monthly = 'monthly'
}
