import { Clipboard } from "@angular/cdk/clipboard";
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { AbstractControl, AsyncValidatorFn, UntypedFormBuilder, ValidationErrors, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare, faSearch, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { AccountMarketplaceService, OrganizationUsersService } from "@front/m19-services";
import { BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { catchError, map, Observable, of } from "rxjs";
import { AccessLevel, AccountMarketplace, AuthorizedAccess, UserApi } from "@front/m19-api-client";
import { TranslocoService } from "@jsverse/transloco";

export enum AuthorizationAccessLevel {
  NO_ACCESS,
  READ_ONLY,
  READ_WRITE_ACCESS,
  STATS_ONLY,
}

export class UserEmailValidator {
  static createValidator(userApi: UserApi): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return userApi
        .doesUserExist({ email: control.value })
        .pipe(
          map((_) => true),
          catchError((err) => of(false)),
        )
        .pipe(map((result) => (result ? null : { userDoesNotExist: true })));
    };
  }
}

@Component({
  selector: "app-manage-authorized-user-modal",
  templateUrl: "./manage-authorized-access-modal.component.html",
  styleUrls: ["./manage-authorized-access-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ManageAuthorizedAccessModalComponent implements OnInit {
  readonly AccessLevel = AuthorizationAccessLevel;
  readonly faSearch = faSearch;
  readonly faSquare = faSquare;
  readonly faCheckSquare = faCheckSquare;
  readonly displayedColumns = ["accountName", "noAccess", "statsOnlyAccess", "readOnlyAccess", "readWriteAccess"];

  @Input()
  isNewUser = true;
  @Input()
  defaultEmail = "";
  @Input()
  userName: string;
  @Input()
  board: string;
  @Input()
  readonly initialAuthorizedOrganizationProfileIds: Map<number, AuthorizedAccess>;
  @Input()
  organizationId: number;
  @Input()
  organizationName: string;
  @Input()
  adminsEmails: string[] = [];
  @Input()
  authorizedUserEmails: string[] = [];

  readonly accessProfileIdsToUpdate = new Map<number, AccessLevel>();
  readonly revokeAccessProfileIds = new Set<number>();
  readonly accessLevelMap = new Map<number, AuthorizationAccessLevel>();

  readonly emailForm = this.fb.group({
    email: [
      this.defaultEmail,
      [Validators.required, Validators.email, Validators.pattern(/.*@.+\..+/)],
      [UserEmailValidator.createValidator(this.userApi)],
    ],
  });

  get email(): AbstractControl {
    return this.emailForm.get("email");
  }

  dataSource = new MatTableDataSource<AccountMarketplace>([]);

  @ViewChild("paginator", { static: false }) set paginator(value: MatPaginator) {
    if (this.dataSource) this.dataSource.paginator = value;
  }

  @ViewChild("sort", { static: false }) set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
        switch (sortHeaderId) {
          case "noAccess":
            return this.getAccessLevel(data.profileId) == AuthorizationAccessLevel.NO_ACCESS;
          case "readWriteAccess":
            return this.getAccessLevel(data.profileId) == AuthorizationAccessLevel.READ_WRITE_ACCESS;
          default:
            return data[sortHeaderId];
        }
      };
      this.dataSource.sort = value;
    }
  }

  constructor(
    private accountMarketplaceService: AccountMarketplaceService,
    public modalService: BsModalService,
    private toasterService: ToastrService,
    private organizationUsersService: OrganizationUsersService,
    private fb: UntypedFormBuilder,
    private userApi: UserApi,
    private clipboard: Clipboard,
    private translocoService: TranslocoService,
  ) {
    this.dataSource.filterPredicate = (data, filter) => new RegExp(filter, "i").test(data.accountName);
  }

  ngOnInit(): void {
    this.accountMarketplaceService.accountMarketplaces$.subscribe((accountMarketplaces) => {
      this.dataSource.data = accountMarketplaces.filter((x) => x.resourceOrganizationId === this.organizationId);
      accountMarketplaces.forEach((accountMarketplace) => {
        let accessLevel = AuthorizationAccessLevel.NO_ACCESS;
        if (this.initialAuthorizedOrganizationProfileIds?.has(accountMarketplace.profileId)) {
          switch (this.initialAuthorizedOrganizationProfileIds.get(accountMarketplace.profileId).accessLevel) {
            case AccessLevel.STATS_ONLY:
              accessLevel = AuthorizationAccessLevel.STATS_ONLY;
              break;
            case AccessLevel.READ:
              accessLevel = AuthorizationAccessLevel.READ_ONLY;
              break;
            default:
              accessLevel = AuthorizationAccessLevel.READ_WRITE_ACCESS;
              break;
          }
        }
        this.accessLevelMap.set(accountMarketplace.profileId, accessLevel);
      });
    });
    if (this.defaultEmail) this.email.setValue(this.defaultEmail);
    if (!this.isNewUser) this.email.disable();
  }

  isValid(): boolean {
    return (
      (!this.isNewUser || this.email.valid) &&
      (this.accessProfileIdsToUpdate.size > 0 || this.revokeAccessProfileIds.size > 0)
    );
  }

  setAccessLevel(profileId: number, accessLevel: AuthorizationAccessLevel): void {
    this.accessLevelMap.set(profileId, accessLevel);
    if (this.isNewUser) {
      switch (accessLevel) {
        case AuthorizationAccessLevel.NO_ACCESS:
          this.accessProfileIdsToUpdate.delete(profileId);
          break;
        case AuthorizationAccessLevel.READ_WRITE_ACCESS:
          this.accessProfileIdsToUpdate.set(profileId, AccessLevel.WRITE);
          break;
        case AuthorizationAccessLevel.READ_ONLY:
          this.accessProfileIdsToUpdate.set(profileId, AccessLevel.READ);
          break;
        case AuthorizationAccessLevel.STATS_ONLY:
          this.accessProfileIdsToUpdate.set(profileId, AccessLevel.STATS_ONLY);
          break;
      }
    } else {
      switch (accessLevel) {
        case AuthorizationAccessLevel.NO_ACCESS:
          this.accessProfileIdsToUpdate.delete(profileId);
          if (this.initialAuthorizedOrganizationProfileIds.has(profileId)) {
            this.revokeAccessProfileIds.add(profileId);
          } else {
            this.revokeAccessProfileIds.delete(profileId);
          }
          break;
        case AuthorizationAccessLevel.STATS_ONLY:
        case AuthorizationAccessLevel.READ_ONLY:
        case AuthorizationAccessLevel.READ_WRITE_ACCESS:
          this.revokeAccessProfileIds.delete(profileId);
          if (
            this.initialAuthorizedOrganizationProfileIds.has(profileId) &&
            this.initialAuthorizedOrganizationProfileIds.get(profileId).accessLevel === this.toAccessLevel(accessLevel)
          ) {
            this.accessProfileIdsToUpdate.delete(profileId);
          } else {
            this.accessProfileIdsToUpdate.set(profileId, this.toAccessLevel(accessLevel));
          }
          break;
      }
    }
  }

  private toAccessLevel(authorizationAccessLevel: AuthorizationAccessLevel) {
    switch (authorizationAccessLevel) {
      case AuthorizationAccessLevel.NO_ACCESS:
        return null;
      case AuthorizationAccessLevel.READ_ONLY:
        return AccessLevel.READ;
      case AuthorizationAccessLevel.READ_WRITE_ACCESS:
        return AccessLevel.WRITE;
      case AuthorizationAccessLevel.STATS_ONLY:
        return AccessLevel.STATS_ONLY;
    }
  }

  getAccessLevel(profileId: number): AuthorizationAccessLevel {
    return this.accessLevelMap.get(profileId);
  }

  save(): void {
    if (this.adminsEmails.includes(this.email.value)) {
      this.toasterService.error("The provided user is already an Admin", "Admin Setting");
      return;
    }
    if (this.authorizedUserEmails.includes(this.email.value)) {
      this.toasterService.error("The provided user is already an Authorized User", "Admin Setting");
      return;
    }
    if (!this.isValid()) return;
    if (this.accessProfileIdsToUpdate.size > 0) this.addAuthorizedAccess();
    if (this.revokeAccessProfileIds.size > 0) this.revokeAuthorizedAccess();
  }

  private addAuthorizedAccess(): void {
    const authorizedAccessUpdates: AuthorizedAccess[] = Array.from(this.accessProfileIdsToUpdate.entries()).map(
      ([profileId, accessLevel]) => ({
        organizationId: this.organizationId,
        profileId: profileId,
        email: this.email.value,
        accessLevel,
      }),
    );
    this.organizationUsersService.addAuthorizedAccessAsync(authorizedAccessUpdates).subscribe({
      next: () => {
        this.toasterService.success("Successfully granted " + this.email.value + " access");
        this.modalService.hide();
      },
      error: (error) => this.toasterService.error(error, "Authorization update error"),
    });
  }

  private revokeAuthorizedAccess(): void {
    const revokedAccesses: AuthorizedAccess[] = Array.from(this.revokeAccessProfileIds.values()).map((x) => ({
      organizationId: this.organizationId,
      profileId: x,
      email: this.email.value,
    }));

    this.organizationUsersService.revokeAuthorizedAccessAsync(revokedAccesses).subscribe({
      next: () => {
        this.toasterService.success(
          "Successfully revoked access from " + this.email.value + " to " + revokedAccesses.length + " accounts",
          "Access revoked",
        );
        this.modalService.hide();
      },
      error: (error) => this.toasterService.error(error, "Access revocation error"),
    });
  }

  setAllAccessLevel(accessLevel: AuthorizationAccessLevel, event: Event): void {
    event.stopPropagation();
    this.dataSource.filteredData.forEach((accountMarketplace) => {
      this.setAccessLevel(accountMarketplace.profileId, accessLevel);
    });
  }

  getSetAllIcon(accessLevel: AuthorizationAccessLevel): IconDefinition {
    if (this.dataSource.data.every((am) => this.accessLevelMap.get(am.profileId) === accessLevel)) {
      return faCheckSquare;
    } else return faSquare;
  }

  changeAccountFilter(accountFilter: string): void {
    this.dataSource.filter = (accountFilter ?? "").trim();
  }

  getRegisterUrl(): string {
    if (!this.board || this.board === "m19") return "https://board.m19.com/register";
    return "https://" + this.board + ".ppc-board.com/register";
  }

  copyToClipboard() {
    this.clipboard.copy(this.getRegisterUrl());
    this.toasterService.success(this.translocoService.translate("common.copied_to_clipboard"));
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
