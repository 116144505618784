import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faExclamationTriangle, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { ConfigService, StrategyService } from "@front/m19-services";
import { TranslocoService } from "@jsverse/transloco";
import { KeywordSegmentModalComponent } from "@m19-board/segments/keyword-segment-modal.component";
import { ProductSegmentModalComponent } from "@m19-board/segments/product-segment-modal.component";
import { ConfirmPopupComponent } from "@m19-board/shared/confirm-popup/confirm-popup.component";
import { ICON_EDIT_O, ICON_TRASH_O } from "@m19-board/utils/iconsLabels";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { SegmentConfigType, SegmentEx, StrategyEx } from "@front/m19-models";
import {
  AlgoMode,
  CampaignType,
  Intensity,
  Marketplace,
  MatchType,
  StrategyTactic,
  TacticType,
} from "@front/m19-api-client";
import { take } from "rxjs";

/* Modal component to delete the Tactic from the Strategy */
@Component({
  selector: "app-modal-content",
  templateUrl: "./remove-tactic-popup.component.html",
})
export class ModalRemoveTacticFromStrategyComponent {
  @Input()
  accountId: string | undefined;
  @Input()
  marketplace: Marketplace | undefined;
  @Input()
  strategyId: number | undefined;
  @Input()
  tactic: StrategyTactic | undefined;
  @Input()
  isSdTargeting: false | undefined;
  @Input()
  segment: SegmentEx | undefined;
  @Output()
  tacticDeleted = new EventEmitter<number>();

  constructor(
    private strategyService: StrategyService,
    public bsModalRef: BsModalRef,
    private toastrService: ToastrService,
  ) {}

  delete() {
    this.strategyService
      .removeTacticFromStrategy(this.accountId!, this.marketplace!, this.strategyId!, this.tactic!.segmentId)
      .subscribe({
        next: () => {
          this.toastrService.success("Tactic removed");
          this.bsModalRef.hide();
          this.tacticDeleted.emit(this.tactic!.segmentId);
        },
        error: (error) => {
          this.toastrService.error(error, "Tactic removal failed");
        },
      });
  }
}

/* Main Component*/
@Component({
  selector: "app-tactic",
  templateUrl: "./tactic.component.html",
  styleUrls: ["./tactic.component.scss"],
})
export class TacticComponent implements OnInit {
  _strategy: StrategyEx;
  AlgoModeTypes = AlgoMode;
  boostTopSearchEnabled = false;
  segmentType: SegmentConfigType;
  readonly faPencil = faPencilAlt;
  readonly faTrash = faTrashAlt;
  readonly faWarning = faExclamationTriangle;
  readonly SegmentType = SegmentConfigType;
  readonly ICON_TRASH = ICON_TRASH_O;
  readonly ICON_EDIT = ICON_EDIT_O;

  @Input() showKW = true;
  @Input() showProducts = true;
  @Input() isReadOnly = false;

  @Input()
  tactic: StrategyTactic;

  @Input()
  segment: SegmentEx;

  @Input()
  set strategy(strategy: StrategyEx) {
    this._strategy = strategy;
    this.boostTopSearchEnabled = strategy.campaignType == CampaignType.SP;
  }

  @Output() tacticDeleted = new EventEmitter<number>();

  constructor(
    public configurationService: ConfigService,
    private modalService: BsModalService,
    private translocoService: TranslocoService,
    private strategyService: StrategyService,
    private toastrService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.segmentType = this.segment.segmentType;
  }

  nbKeywords(): number {
    return this.segment.items.filter((x) => x.matchType == MatchType.exact || x.matchType == MatchType.phrase).length;
  }

  nbProductTargeting(): number {
    return this.segment.items.filter((x) => x.matchType == MatchType.asinSameAs).length;
  }

  lastProductKeyword(): boolean {
    return (
      this._strategy &&
      this.tactic.tacticType != TacticType.BLACKLIST &&
      this._strategy.tactics.filter((x) => x.tacticType != TacticType.BLACKLIST).length == 1
    );
  }

  assertUnreachable(x): never {
    throw new Error("Didn't expect to get here");
  }

  getIntensity() {
    if (this.tactic.intensity) {
      const throwIntensityEnumError: Intensity = <any>Intensity.LOW;
      switch (this.tactic.intensity) {
        case Intensity.LOW:
          return 1;
        case Intensity.MEDIUM_LOW:
          return 2;
        case Intensity.NEUTRAL:
          return 3;
        case Intensity.MEDIUM_HIGH:
          return 4;
        case Intensity.HIGH:
          return 5;
        default:
          this.assertUnreachable(throwIntensityEnumError);
      }
    }
  }

  setIntensity(val: number | string) {
    let tmpIntensity = undefined;
    switch (+val) {
      case 1:
        tmpIntensity = Intensity.LOW;
        break;
      case 2:
        tmpIntensity = Intensity.MEDIUM_LOW;
        break;
      case 3:
        tmpIntensity = Intensity.NEUTRAL;
        break;
      case 4:
        tmpIntensity = Intensity.MEDIUM_HIGH;
        break;
      case 5:
        tmpIntensity = Intensity.HIGH;
        break;
    }

    if (tmpIntensity !== this.tactic.intensity) {
      this.tactic.intensity = tmpIntensity;
      this.strategyService
        .updateTacticIntensity(
          this._strategy.accountId,
          this._strategy.marketplace,
          this._strategy.strategyId,
          this.tactic.segmentId,
          this.tactic.intensity!,
        )
        .subscribe({
          next: () => {
            this.toastrService.success("Intensity updated successfully");
          },
          error: (error) => {
            this.toastrService.error(`Error updating intensity: ${error}`);
          },
        });
    }
  }

  switchBoostPlacementTop(): void {
    this.tactic.boostPlacementTop = !this.tactic.boostPlacementTop;
    this.strategyService
      .updateTacticBoostPlacementTop(
        this._strategy.accountId,
        this._strategy.marketplace,
        this._strategy.strategyId,
        this.tactic.segmentId,
        this.tactic.boostPlacementTop,
      )
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.tactic.boostPlacementTop
            ? this.toastrService.success(
                this.translocoService.translate("tactic.only_top_of_search_placement_activated"),
              )
            : this.toastrService.success(
                this.translocoService.translate("tactic.only_top_of_search_placement_deactivated"),
              );
        },
        error: (error) => {
          this.toastrService.error(error, "Error updating top of search placement boost");
        },
      });
  }

  editSegment() {
    if (this.segmentType == SegmentConfigType.KeywordSegment) {
      const modalOptions: ModalOptions = {
        initialState: {
          segment: this.segment,
          isReadOnly: this.isReadOnly,
        },
        class: "modal-xl",
      };
      this.modalService.show(KeywordSegmentModalComponent, modalOptions);
    } else if (this.segmentType == SegmentConfigType.ProductSegment) {
      const modalOptions: ModalOptions = {
        initialState: {
          segment: this.segment,
          isReadOnly: this.isReadOnly,
        },
        class: "modal-xl",
      };
      this.modalService.show(ProductSegmentModalComponent, modalOptions);
    }
  }

  removeTacticFromStrategy() {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this._strategy.accountId,
        marketplace: this._strategy.marketplace,
        strategyId: this._strategy.strategyId,
        tactic: this.tactic,
        segment: this.segment,
        isSdTargeting: false,
      },
      class: "modal-danger",
    };
    const ref = this.modalService.show(ModalRemoveTacticFromStrategyComponent, modalOptions);
    ref.content!.tacticDeleted.subscribe((segmentId: number) => {
      this.tacticDeleted.emit(segmentId);
    });
  }

  getSegmentTypeLabel() {
    switch (this.segmentType) {
      case SegmentConfigType.KeywordSegment:
        return this.translocoService.translate("common.keyword");
      case SegmentConfigType.ProductSegment:
        return this.translocoService.translate("common.product");
      case SegmentConfigType.MixedSegment:
      default:
        return this.translocoService.translate("tactic.product_and_keyword");
    }
  }

  getSegmentTypeDesc() {
    switch (this.segmentType) {
      case SegmentConfigType.KeywordSegment:
        return this.translocoService.translate("tactic.excludes_kw_from_your_strategy");
      case SegmentConfigType.ProductSegment:
        return this.translocoService.translate("tactic.excludes_product_pages_from_your_strategy");
      case SegmentConfigType.MixedSegment:
      default:
        return this.translocoService.translate("tactic.excludes_product_pages_kw_from_your_strategy");
    }
  }
}
