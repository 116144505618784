<app-hourly-stats-overlay>
  <app-hourly-chart
    [hourlyDataSupported]="hourlyDataSupported"
    [strategies]="strategies"
    [CHART_METRICS]="CHART_METRICS"
    [chartDisplayed]="chartDisplayed"
    [displayWarning]="displayWarning"
    [nbDaysConsidered]="nbDaysConsidered"
    [globalData]="globalData"
    (onSelectedMetric)="setSelectedMetrics($event)"
    (onCustomMetric)="setCustomMetrics($event)"
    (onSelectedStrategyOrProductGroup)="setStrategyOrProductGroup($event)"
    (onChartDisplay)="setChartDisplay($event)"
    [pendingRequests]="pendingRequests"
    [dataSet]="dataSet"
    [timeZone]="timeZone"
    [localStorageKey]="localStorageKey"
    [includeNonM19Campaign]="includeNonM19Campaign"
    (onNonM19CampaignChange)="setIncludeNonM19Campaign($event)"
  ></app-hourly-chart>
  <app-hourly-table
    [statsByHour]="medianByTypeHour"
    [strategiesMap]="strategiesMap"
    [avgByHour]="avgByTypeHour"
    [currency]="dataSet.currency"
    [locale]="dataSet.locale"
    [selectedMetric$]="selectedMetric$"
    [accountMarketplace$]="accountMarketplace$"
    [customMetrics$]="customMetrics$"
    [chartDisplayed]="chartDisplayed"
    (chartDisplayChange)="setChartDisplay($event)"
  ></app-hourly-table>
</app-hourly-stats-overlay>
