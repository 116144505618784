import { Injectable } from '@angular/core';
import {
  AccountMarketplace,
  Currency,
  Marketplace,
  Strategy,
  TacosStrategyGroup,
  TacosStrategyGroupApi,
} from '@front/m19-api-client';
import { AdStatsEx } from '@front/m19-models/AdStatsEx';
import { AccountMarketplaceCache } from '@front/m19-utils';
import { Moment } from 'moment';
import { combineLatest, map, Observable } from 'rxjs';
import { SpStrategiesService } from './sp-strategies.service';
import { arrayGroupBy, StatsApiClientService } from './stats-api-client.service';

@Injectable({
  providedIn: 'root',
})
export class TacosStrategiesService {
  public readonly tacosStrategiesCache = new AccountMarketplaceCache<Map<number, TacosStrategyGroup>>(
    (accountId, marketplace) => this.loadTacosStrategies(accountId, marketplace),
  );

  constructor(
    private readonly tacosStrategyGroupApi: TacosStrategyGroupApi,
    private readonly spStrategiesService: SpStrategiesService,
    private readonly statsApiClientService: StatsApiClientService,
  ) {}

  private loadTacosStrategies(
    accountId: string,
    marketplace: Marketplace,
  ): Observable<Map<number, TacosStrategyGroup>> {
    return this.tacosStrategyGroupApi
      .listTacosStrategyGroup({
        accountId,
        marketplace,
      })
      .pipe(
        map((tacosStrategyGroups) => {
          return tacosStrategyGroups.reduce((acc, tacosStrategyGroup) => {
            acc.set(tacosStrategyGroup.tacosStrategyGroupId!, tacosStrategyGroup);
            return acc;
          }, new Map<number, TacosStrategyGroup>());
        }),
      );
  }

  public getTacosStrategyGroupById(
    accountId: string,
    marketplace: Marketplace,
    tacosStrategyGroupId: number,
  ): Observable<TacosStrategyGroup> {
    return this.tacosStrategiesCache
      .get({ accountId, marketplace })
      .pipe(map((tacosStrategyGroups) => tacosStrategyGroups.get(tacosStrategyGroupId)!));
  }

  public getSpTacosStrategy(
    accountId: string,
    marketplace: Marketplace,
    tacosStrategyGroupId: number,
  ): Observable<Strategy | undefined> {
    return combineLatest([
      this.getTacosStrategyGroupById(accountId, marketplace, tacosStrategyGroupId),
      this.spStrategiesService.getSPStrategies(accountId, marketplace),
    ]).pipe(
      map(([tacosStrategyGroup, spStrategies]) => {
        if (tacosStrategyGroup) {
          return spStrategies.get(tacosStrategyGroup.spStrategyId!)!;
        }
        return undefined;
      }),
    );
  }

  public getStats(
    am: AccountMarketplace,
    currency: Currency,
    dr: Moment[],
    period: string[] | undefined,
  ): Observable<Map<string, AdStatsEx[]>> {
    return this.statsApiClientService
      .getDailyAsinsStats(am.accountId, am.marketplace, currency, !!am.useSourcingMetrics, dr, period)
      .pipe(map((stats) => arrayGroupBy(stats.data, (s) => s.asin!)));
  }
}
