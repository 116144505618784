<!-- This page is only accessible via an URL -->
@if (accountMarketplace && accountMarketplace.accountType !== "SELLER" && !isReadOnly) {
  <div *transloco="let t">
    <div class="flex items-center justify-between">
      <h3 class="card-name mb-0 pb-1 pt-1">{{ t("catalog-upload.add_asins_to_catalog") }}</h3>
      <IButton
        [to]="catalogPage"
        color="gray"
        icon="icon-[material-symbols--arrow-back-rounded]"
        label="{{ t('catalog-upload.back_to_catalog') }}"
        variant="ghost"
        [block]="true"
      />
    </div>
    <div class="mt-4">
      <div class="add-box">
        <textarea
          #asins
          class="asins simple-input form-control"
          placeholder="{{ t('catalog-upload.list_of_asin_input') }}"
          aria-label="Simple textarea"
        ></textarea>
        <button
          type="button"
          class="btn btn-primary va-top ml-2 mt-2"
          matTooltip="{{ t('catalog-upload.add_asins_to_catalog_tooltip') }}"
          (click)="addAsinToCatalog(asins.value); asins.value = ''"
        >
          {{ t("common.add") }}
        </button>
        <div class="list-errors mt-2">
          @if (errors && errors.length > 0) {
            <ul class="list-group">
              <li class="list-group-item list-group-item list-group-item-warning flex justify-between">
                <span>{{ t("catalog-upload.asins_ignored") }}</span>
                <button
                  type="button"
                  class="btn text-warning pull-right bg-transparent p-0"
                  (click)="errors = []"
                  matTooltip="{{ t('catalog-upload.close_error_table') }}"
                >
                  <fa-icon [icon]="faTimes" class="font-lg"></fa-icon>
                </button>
              </li>
              @for (error of errors; track error) {
                <li class="list-group-item list-group-item-secondary py-0">
                  {{ error }}
                </li>
              }
            </ul>
          }
        </div>
      </div>
    </div>
  </div>
}
