<div class="modal-primary" *transloco="let t">
  <div class="modal-header">
    <h3 class="modal-title text-center">{{ t("product-details.active_strategies") }}</h3>
    <a><h3 class="pull-right" aria-hidden="true" (click)="ref.hide()">&times;</h3></a>
  </div>
  <div class="modal-body">
    @if (asinnumber) {
      <div class="row mx-2">
        <div class="card col-12">
          <app-product-view [asin]="asinnumber" [marketplace]="marketplace"></app-product-view>
        </div>
      </div>
    }
    <div class="strategies-list p-2">
      @if (activeStrategies.length === 0) {
        <div class="mb-2 flex justify-center">{{ t("product-details.no_strategy_for_this_asin") }}</div>
      }
      @for (strategy of activeStrategies; track strategy) {
        <ng-container class="card-perso-body bottom-child">
          <div class="card-perso-body bottom-child mb-1">
            <div class="card-perso collapse-card border-shadow left-child right-child">
              <div class="card-perso-header collapse-card-header top-child card-header strategy-row p-2">
                <app-strategy-link
                  [strategyId]="strategy.strategyId"
                  [withCampaignType]="true"
                  class="w-full"
                ></app-strategy-link>
              </div>
            </div>
          </div>
        </ng-container>
      }
    </div>
  </div>
</div>
