// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    AllVendorInventory,
    CampaignType,
    Currency,
    Marketplace,
    MatchType,
    Placement,
    QueryType,
    SegmentType,
} from './';

/**
 * @export
 * @interface AdStats
 */
export interface AdStats {
    /**
     * Seller or vendor accountId
     * @type {string}
     * @memberof AdStats
     */
    accountId?: string;
    /**
     * @type {string}
     * @memberof AdStats
     */
    accountName?: string;
    /**
     * @type {Marketplace}
     * @memberof AdStats
     */
    marketplace?: Marketplace;
    /**
     * @type {string}
     * @memberof AdStats
     */
    date?: string;
    /**
     * @type {number}
     * @memberof AdStats
     */
    hour?: number;
    /**
     * @type {string}
     * @memberof AdStats
     */
    asin?: string;
    /**
     * @type {string}
     * @memberof AdStats
     */
    query?: string;
    /**
     * @type {QueryType}
     * @memberof AdStats
     */
    queryType?: QueryType;
    /**
     * @type {string}
     * @memberof AdStats
     */
    campaignId?: string;
    /**
     * @type {CampaignType}
     * @memberof AdStats
     */
    campaignType?: CampaignType;
    /**
     * @type {Placement}
     * @memberof AdStats
     */
    placement?: Placement;
    /**
     * @type {number}
     * @memberof AdStats
     */
    strategyId?: number;
    /**
     * @type {number}
     * @memberof AdStats
     */
    creativeId?: number;
    /**
     * @type {number}
     * @memberof AdStats
     */
    subStrategyId?: number;
    /**
     * @type {Currency}
     * @memberof AdStats
     */
    currency?: Currency;
    /**
     * @type {string}
     * @memberof AdStats
     */
    keywordText?: string;
    /**
     * @type {MatchType}
     * @memberof AdStats
     */
    matchType?: MatchType;
    /**
     * @type {number}
     * @memberof AdStats
     */
    brandId?: number;
    /**
     * number of impressions
     * @type {number}
     * @memberof AdStats
     */
    impressions?: number;
    /**
     * number of clicks
     * @type {number}
     * @memberof AdStats
     */
    clicks?: number;
    /**
     * ad cost in marketplace currency
     * @type {number}
     * @memberof AdStats
     */
    cost?: number;
    /**
     * number of conversions
     * @type {number}
     * @memberof AdStats
     */
    adConversions?: number;
    /**
     * converted sales amount in marketplace currency
     * @type {number}
     * @memberof AdStats
     */
    adSales?: number;
    /**
     * Ad attributed number of item purchased
     * @type {number}
     * @memberof AdStats
     */
    adOrderedUnits?: number;
    /**
     * total number of item purchased
     * @type {number}
     * @memberof AdStats
     */
    allOrderedUnits?: number;
    /**
     * sales amount in marketplace currency
     * @type {number}
     * @memberof AdStats
     */
    allSales?: number;
    /**
     * the quantity of units returned.
     * @type {number}
     * @memberof AdStats
     */
    vendorCustomerReturns?: number;
    /**
     * revenue based on the cost of goods sold. Adjustments are made for returns.
     * @type {number}
     * @memberof AdStats
     */
    vendorShippedCogs?: number;
    /**
     * number of session in the amazon website
     * @type {number}
     * @memberof AdStats
     */
    browserSessions?: number;
    /**
     * number of session in the amazon app
     * @type {number}
     * @memberof AdStats
     */
    mobileAppSessions?: number;
    /**
     * number of page views in the amazon website
     * @type {number}
     * @memberof AdStats
     */
    browserPageViews?: number;
    /**
     * number of page views in the amazon app
     * @type {number}
     * @memberof AdStats
     */
    mobileAppPageViews?: number;
    /**
     * number of page (web + app) view with the buy box
     * @type {number}
     * @memberof AdStats
     */
    buyBoxPageViews?: number;
    /**
     * @type {number}
     * @memberof AdStats
     */
    nbAsinsWithRuleFired?: number;
    /**
     * @type {number}
     * @memberof AdStats
     */
    netPureProductMargin?: number;
    /**
     * @type {number}
     * @memberof AdStats
     */
    tacosStrategyGroupId?: number;
    /**
     * @type {SegmentType}
     * @memberof AdStats
     */
    segmentType?: SegmentType;
    /**
     * @type {string}
     * @memberof AdStats
     */
    lastPpmDate?: string;
    /**
     * @type {AllVendorInventory}
     * @memberof AdStats
     */
    vendorInventory?: AllVendorInventory;
    /**
     * @type {boolean}
     * @memberof AdStats
     */
    isM19?: boolean;
}
