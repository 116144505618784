import { NgClass, NgIf, NgOptimizedImage } from "@angular/common";
import { Component, computed, Input, OnInit, signal } from "@angular/core";
import { MatTooltip } from "@angular/material/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faAmazon } from "@fortawesome/free-brands-svg-icons";
import { faShoppingBag, faStoreSlash } from "@fortawesome/free-solid-svg-icons";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { BehaviorSubject, switchMap } from "rxjs";
import { AmazonProductImageUrlPipe, AsinService, UtilityPipesModule } from "@front/m19-services";
import { Marketplace } from "@front/m19-api-client";
import { buildProductEx, ProductEx } from "@front/m19-models";

const MULTI_ASIN_LABEL = "Multi-ASIN";

@UntilDestroy()
@Component({
  selector: "app-product-thumbnail",
  templateUrl: "product-thumbnail.component.html",
  styleUrls: ["./product-thumbnail.component.scss"],
  standalone: true,
  imports: [FontAwesomeModule, NgClass, MatTooltip, NgOptimizedImage, UtilityPipesModule, NgIf, TranslocoRootModule],
})
export class ProductThumbnailComponent implements ICellRendererAngularComp, OnInit {
  _marketplace: BehaviorSubject<Marketplace> = new BehaviorSubject(Marketplace.US);
  productImagePipe = new AmazonProductImageUrlPipe();

  @Input() set product(p: ProductEx) {
    this.$product.set(p);
  }

  $product = signal<ProductEx | null>(null);
  $productUrl = computed(() => {
    if (this.$product().asin === "MULTI_ASIN") return "assets/img/Multi-ASIN.svg";
    return this.productImagePipe.transform(this.$product(), 100);
  });

  @Input() set asin(a: string) {
    this._asin = a;
    this.buildProduct();
  }

  _asin: string;

  @Input() set marketplace(marketplace: Marketplace) {
    this._marketplace.next(marketplace);
  }

  @Input() childAsins?: boolean;

  // custom size of type 'size-XX'
  // https://tailwindcss.com/docs/size#fixed-sizes
  @Input() customSizeClass: string;
  @Input() smallImg = false;
  @Input() fbaBadge = false;
  @Input() fbmBadge = false;
  @Input() notBelongCatalogBadge = false;
  @Input() withTitle = false;

  readonly faAmazon = faAmazon;
  readonly faMerchant = faShoppingBag;
  readonly faStoreSlash = faStoreSlash;
  readonly MULTI_ASIN_LABEL = MULTI_ASIN_LABEL;

  display = true;

  constructor(private asinService: AsinService) {}

  agInit(
    params: ICellRendererParams<any, any, any> & {
      marketplace: Marketplace;
      notBelongCatalogBadge: boolean;
      childAsins: boolean;
      asin: string;
      smallImg: boolean;
      withTitle: boolean;
      display: boolean;
    },
  ): void {
    this.marketplace = params.marketplace;
    this.notBelongCatalogBadge = params.notBelongCatalogBadge;
    this._asin = params.asin;
    this.smallImg = params.smallImg;
    this.withTitle = params.withTitle;
    this.childAsins = params.childAsins;
    // use to hide thumbnail in grid footer rows.
    if (params.display === false) this.display = false;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  ngOnInit(): void {
    if (this.$product()) return;
    this.buildProduct();
  }

  private buildProduct() {
    this.$product.set(
      buildProductEx({
        asin: this._asin,
        marketplace: this._marketplace.value,
      } as any),
    );

    this._marketplace
      .pipe(
        untilDestroyed(this),
        switchMap((m) => this.asinService.getProductWithMarketplace(this._asin, m)),
      )
      .subscribe((product: ProductEx) => {
        this.$product.set(product);
      });
  }
}
