import { Component, Input } from "@angular/core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { StrategyEx } from "@front/m19-models";
import { UntilDestroy } from "@ngneat/until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";
import { DateRangeSelectorComponent } from "@m19-board/date-range-selector/date-range-selector.component";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { MatTooltip } from "@angular/material/tooltip";
import { StrategyActivityComponent } from "@m19-board/strategies/strategies/strategy-activities/strategy-activities.component";
import { IButtonComponent } from "@front/m19-ui";

@UntilDestroy()
@Component({
  template: ` <div class="p-3">
    <div class="mb-4 flex w-full items-center justify-between">
      <div class="w-1/2">
        <div>
          <h3>Strategy Activities - {{ strategy?.name }}</h3>
        </div>
      </div>
      <div class="flex flex-auto items-center justify-end">
        <app-date-range-selector />

        <IButton icon="icon-[lucide--x]" variant="ghost" (onClick)="close()" />
      </div>
    </div>
    <app-strategy-activity-component [strategy]="strategy"></app-strategy-activity-component>
  </div>`,
  styles: [".activity-section {height: 30rem;}"],
  standalone: true,
  imports: [DateRangeSelectorComponent, StrategyActivityComponent, IButtonComponent],
})
export class StrategyActivityModalComponent {
  @Input()
  strategy?: StrategyEx;

  readonly faTimes = faTimes;

  constructor(private bsModalRef: BsModalRef) {}

  close() {
    this.bsModalRef.hide();
  }
}
