@if (whitelist) {
  <div class="modal-header bg-main-500" *transloco="let t">
    <h4 class="modal-title pull-left">{{ t("seeds-edition-modal.add_keywords_for_asin", [asin]) }}</h4>
    <a>
      <h4 class="modal-title pull-right" (click)="bsModalRef.hide()">&times;</h4>
    </a>
  </div>
}
@if (!whitelist) {
  <div class="modal-header bg-main-500" *transloco="let t">
    <h4 class="modal-title pull-left">{{ t("seeds-edition-modal.blakclist_for_asin", [asin]) }}</h4>
    <a>
      <h4 class="modal-title pull-right" (click)="bsModalRef.hide()">&times;</h4>
    </a>
  </div>
}
<div class="modal-body" *transloco="let t">
  <div class="row mb-4">
    <div class="col-sm-3">
      <div class="text-main-500 flex flex-wrap items-center">
        <span
          >#{{ t("common.products") }}:&nbsp;{{
            this.whitelist ? this._whitelistedProducts.length : this._blacklistedProducts.length
          }}</span
        >
        <span class="ml-3"
          >#{{ t("common.keywords") }}:&nbsp;{{
            this.whitelist ? this._whitelistedKeywords.length : this._blacklistedKeywords.length
          }}</span
        >
      </div>
    </div>
  </div>
  @if (whitelist) {
    <app-targeting-detail
      [name]="asin"
      [productTargetingItems]="_whitelistedProducts"
      [keywordTargetingItems]="_whitelistedKeywords"
      [marketplace]="marketplace"
      [accountId]="accountId"
      [onlyExactMatchType]="true"
      [errorsAsin]="errorsAsin"
      [errorsKeyword]="errorsKeyword"
      [whitelist]="whitelist"
      [isReadOnly]="isReadOnly"
      (onAddedAsins)="addProducts($event)"
      (onDeletedAsins)="deleteProducts($event)"
      (onAddedKeywords)="addKeywords($event)"
      (onDeletedKeywords)="deleteKeywords($event)"
    >
    </app-targeting-detail>
  }
  @if (!whitelist) {
    <app-targeting-detail
      [name]="asin"
      [productTargetingItems]="_blacklistedProducts"
      [keywordTargetingItems]="_blacklistedKeywords"
      [marketplace]="marketplace"
      [accountId]="accountId"
      [onlyExactMatchType]="false"
      [errorsAsin]="errorsAsin"
      [errorsKeyword]="errorsKeyword"
      [whitelist]="whitelist"
      [isReadOnly]="isReadOnly"
      (onAddedAsins)="addProducts($event)"
      (onDeletedAsins)="deleteProducts($event)"
      (onAddedKeywords)="addKeywords($event)"
      (onDeletedKeywords)="deleteKeywords($event)"
    >
    </app-targeting-detail>
  }
</div>
<div class="modal-footer" *transloco="let t">
  <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="close()">{{ t("common.close") }}</button>
</div>
