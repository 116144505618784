<div class="box card border-shadow mt-2 px-4 py-4" *transloco="let t">
  <div class="flex items-center justify-between">
    <span class="search bar col-3 pl-0">
      <span class="search-bar input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <span [class]="ICON_SEARCH"></span>
          </span>
        </div>
        <input
          type="text"
          class="search-bar simple-input-prepend form-control"
          placeholder="{{ t('hourly-table.search_startegy') }}"
          [ngModel]="filter"
          (ngModelChange)="setFilter($event, selectedCampaignType(), displayOnlyActiveStrategies)"
        />
      </span>
    </span>
    <div class="col-3">
      <ISelect
        [options]="campaignTypeOptions"
        [selectedValue]="selectedCampaignType().value"
        (selectedOptionChange)="setFilter(filter, $event, displayOnlyActiveStrategies)"
      />
    </div>
    <div class="col">
      <ICheckbox
        class="ml-4"
        label="{{ t('hourly-table.only_active') }}"
        [value]="displayOnlyActiveStrategies"
        (valueChange)="setFilter(filter, selectedCampaignType(), $event)"
      />
    </div>
    <div class="flex items-center gap-1">
      <button
        class="btn"
        [matTooltip]="showAbsValues ? 'Show relative values' : 'Show absolute values'"
        [hidden]="selectedMetric$.value[0].type === MetricType.RATIO"
        [ngClass]="!showAbsValues ? 'btn-primary' : 'btn-secondary'"
        (click)="changeAbsRelValues()"
      >
        <span class="icon-[lucide--percent]"></span>
      </button>
      <app-export-button tooltipValue="{{ t('common.export_as_csv') }}" (export)="downloadFile()" />

      <IButton
        [icon]="ICON_CHART"
        [tooltipValue]="chartDisplayed ? t('common.hide_chart') : t('common.show_chart')"
        color="white"
        (onClick)="toggleChartDisplay()"
      />
    </div>
  </div>
  <div class="table-container">
    @if (dataSource.data.length === 0) {
      No data to display
    } @else {
      <mat-table
        [dataSource]="dataSource"
        matSort
        class="responsive-table table-striped-two table-hover mt-4"
        multiTemplateDataRows
      >
        <ng-container matColumnDef="strategyStatus" [sticky]="true">
          <mat-header-cell *matHeaderCellDef class="strategy-status-cell"></mat-header-cell>
          <mat-cell *matCellDef="let adstat" class="strategy-status-cell">
            <span>
              @if (adstat.values().next().value.state === "ENABLED") {
                <span
                  class="activated text-lg"
                  [ngClass]="ICON_PLAY"
                  matTooltip="{{ t('common.active_startegy') }}"
                ></span>
              }
              @if (adstat.values().next().value.state === "PAUSED") {
                <span
                  class="desactivated text-lg"
                  [ngClass]="ICON_PAUSE"
                  matTooltip="{{ t('common.paused_strategy') }}"
                ></span>
              }
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="strategyName" [sticky]="true">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            matTooltip="{{ t('common.strategy') }}"
            matTooltipPosition="above"
            class="strategy-name-cell"
            >{{ t("common.strategy") }}
          </mat-header-cell>
          <mat-cell *matCellDef="let adstat" class="strategy-name-cell">
            @if (
              adstat.values().next().value.strategyId && strategiesMap.has(adstat.values().next().value.strategyId)
            ) {
              <app-strategy-link
                class="w-full"
                [strategyId]="adstat.values().next().value.strategyId"
                [withCampaignType]="true"
              ></app-strategy-link>
            } @else {
              {{ adstat.values().next().value.strategyName }}
            }
          </mat-cell>
        </ng-container>

        @for (hour of this.HOURS; track hour) {
          <ng-container [matColumnDef]="hour">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="items-center" matTooltipPosition="above"
              >{{ hour }}H
            </mat-header-cell>
            <mat-cell
              *matCellDef="let product"
              class="flex justify-center !p-0"
              (click)="!isReadOnly && hourClicked(product, hour)"
              (mouseover)="onHover(hour)"
              (mouseout)="onOut()"
              [matTooltip]="
                product.get(hour)
                  ? showAbsValues || selectedMetric$.value[0].type === MetricType.RATIO
                    ? (selectedMetric$.value[0]
                      | metricFormatPipe: product.get(hour) : locale : currency : '1.2-2' : false : true)
                    : (selectedMetric$.value[0]
                      | metricRelativeValuePipe: product.get(hour) : product.get('total') : locale : false)
                  : ''
              "
            >
              <div
                class="h-full w-full"
                [ngClass]="
                  'gradient-' +
                  (product.get(hour) ? (selectedMetric$.value[0] | metricBucketPipe: product : hour) : 'white')
                "
              >
                <div
                  class="flex h-full w-full items-center justify-center"
                  [style.cursor]="product.get(hour)?.strategyId ? 'cell' : ''"
                  [ngClass]="
                    getStripesClass(product, hour) + getSelectedClass(product, hour) + getSelectableClass(product)
                  "
                >
                  {{
                    product.get(hour)
                      ? showAbsValues || selectedMetric$.value[0].type === MetricType.RATIO
                        ? removePercent(
                            selectedMetric$.value[0]
                              | metricFormatPipe: product.get(hour) : locale : currency : undefined : smallMode : true
                          )
                        : removePercent(
                            selectedMetric$.value[0]
                              | metricRelativeValuePipe: product.get(hour) : product.get("total") : locale : smallMode
                          )
                      : ""
                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>
        }

        <ng-container matColumnDef="total">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="items-center" matTooltipPosition="above"
            >{{ t("common.total") }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let product"
            class="justify-center"
            [matTooltip]="
              selectedMetric$.value[0]
                | metricFormatPipe: product.get('total') : locale : currency : undefined : false : true
            "
          >
            <div class="text-center">
              {{
                selectedMetric$.value[0]
                  | metricFormatPipe: product.get("total") : locale : currency : undefined : smallMode : true
              }}
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="header"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns" class="items-center"></mat-row>
      </mat-table>
    }
    <mat-paginator
      [pageSizeOptions]="[5, 10, 50, 100]"
      [pageSize]="10"
      showFirstLastButtons
      style="margin-bottom: 2rem"
      [hidden]="!dataSource.data || dataSource.data.length === 0"
    ></mat-paginator>
  </div>
</div>
