<ng-container *transloco="let t">
  <div class="modal-header bg-primary">
    <h4 class="modal-title pull-left">
      {{ t("product-group-bulk-upload-result-modal.product_group_bulk_upload_report") }}
    </h4>
    <a>
      <h4 class="modal-title pull-right" (click)="bsModalRef.hide()">&times;</h4>
    </a>
  </div>
  <div class="m-4">
    <!-- Report created product groups -->
    @if (uploadResult.created || uploadResult.updated) {
      <div class="alert alert-success">
        <div>
          {{ t("product-group-bulk-upload-result-modal.create_sucess", { count: uploadResult.created.length }) }}
        </div>
        <div>
          {{ t("product-group-bulk-upload-result-modal.update_sucess", { count: uploadResult.updated.length }) }}
        </div>
        <div (click)="toggleSuccessDetails()" class="mt-2">
          <span class="icon-prepend pointer float-left">
            <app-dropdown-toggle-icon [expand]="successDetailsVisible"></app-dropdown-toggle-icon>
          </span>
          <span class="ml-2">{{ t("common.details") }}</span>
        </div>
        @if (successDetailsVisible) {
          <div class="mt-2">
            <ul>
              @for (upload of uploadResult.created; track upload) {
                <li>
                  {{ t("product-group-bulk-upload-result-modal.product_group") }}
                  <strong>{{ upload.productGroupName }}</strong>
                  {{ t("product-group-bulk-upload-result-modal.product_group_created") }}.
                </li>
              }
            </ul>
            <ul>
              @for (upload of uploadResult.updated; track upload) {
                <li>
                  {{ t("product-group-bulk-upload-result-modal.product_group") }}
                  <strong>{{ upload.productGroupName }}</strong>
                  {{ t("product-group-bulk-upload-result-modal.product_group_updated") }}.
                </li>
              }
            </ul>
          </div>
        }
      </div>
    }
    @if (uploadResult.errors.length > 0) {
      <div class="alert alert-danger break-words">
        <div>
          {{
            t("product-group-bulk-upload-result-modal.n_error_uploading", {
              count: uploadResult.errors.length,
              entity: updatedEntity,
            })
          }}
        </div>
        <div (click)="toggleErrorDetails()" class="mt-2">
          <span class="icon-prepend float-left cursor-pointer">
            <app-dropdown-toggle-icon [expand]="errorDetailsVisible"></app-dropdown-toggle-icon>
          </span>
          <span class="ml-2">{{ t("common.details") }}</span>
        </div>
        @if (errorDetailsVisible) {
          <div class="mt-2">
            <ul>
              @for (error of uploadResult.errors; track error) {
                <li>
                  {{ error }}
                </li>
              }
            </ul>
          </div>
        }
      </div>
    }
    <div class="flex justify-end">
      <button type="button" class="btn btn-primary pull-right add-button" (click)="bsModalRef.hide()">
        {{ t("common.close") }}
      </button>
    </div>
  </div>
</ng-container>
