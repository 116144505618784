<div class="asin-list-wrapper flex flex-col" *transloco="let t">
  <div class="asin-list-header">
    <div class="flex min-w-0 flex-1 items-center">
      <app-switch-button
        class="rank-switch mr-2"
        [buttonTexts]="childParentSwitchText"
        [options]="childParentSwitchValues"
        [buttonTooltips]="['', '']"
        [selected]="childParentSwitchValues[0]"
        (buttonClicked)="switchGroupBy($event)"
      ></app-switch-button>
      <app-search-dropdown
        title="{{ t('tracked-asin-selection.filter_by_asin') }}"
        class="min-w-0 shrink-0"
        (onSearch)="searchAsin($event)"
      ></app-search-dropdown>
      <IMultiSelect
        [options]="quickFilterOptions"
        [selected]="selectedQuickFilters()"
        (selectedChange)="updateQuickFilter($event)"
        placeholder="{{ t('common.products') }}"
        class="ml-2 min-w-0"
        rounded
        popperWidth="250px"
      >
        <ng-template #labelSlot>
          <span class="truncate font-medium text-gray-500">{{ t("common.products") }}</span>
          <span class="truncate">
            {{ t("common.products", { count: selectedQuickFilters.length }) }}
          </span>
        </ng-template>
      </IMultiSelect>
    </div>

    <div style="height: 3.8rem"></div>
    @if (selectedRankOption === RankOption.BOTH) {
      <div style="height: 2rem"></div>
    }
  </div>
  <div class="list-header flex justify-between px-2">
    <div class="inline-flex items-baseline">
      <span>{{ t("common.product") }}</span>
      <IButton
        color="white"
        (onClick)="setFocus()"
        label="{{ t('tracked-asin-selection.add_new_product') }}"
        size="xs"
        class="ml-2"
        [matMenuTriggerFor]="menu"
        (menuOpened)="setFocus()"
      />

      <mat-menu #menu="matMenu" xPosition="after" yPosition="below" (closed)="menuClosed()">
        <div class="p-2">
          <div class="font-bolder mb-2">{{ t("tracked-asin-selection.add_product") }}</div>
          <input
            type="text"
            class="simple-input form-control"
            placeholder="{{ t('common.enter_asin') }}"
            [value]="newProductInput"
            (input)="newProductInput = getValueFromInputEvent($event)"
            (click)="$event.stopPropagation()"
            #addAsinInput
          />
          @if (trackNewAsinError) {
            <span class="text-danger small font-bold">{{ t("common.invalid_asin") }}</span>
          }
          <IButton
            [block]="true"
            class="mt-2 w-full"
            (onClick)="trackNewProduct($event)"
            label="{{ t('tracked-asin-selection.add_product') }}"
            size="xs"
          />
        </div>
      </mat-menu>
    </div>
    <span style="margin-right: 2rem">BSR</span>
  </div>
  <div class="asin-list-content mt-2" #asinListScroll (scroll)="updateScroll('asinListScroll', $event)">
    <div>
      @for (p of asinRanks$ | async; track p; let i = $index) {
        <div>
          @if ((groupByParent$ | async) && !(p.childrenRow.length === 1 && p.childrenRow[0].asin === p.asin)) {
            <ng-container *ngTemplateOutlet="productCard; context: { product: p }"></ng-container>
          }
          @for (a of p.childrenRow; track a; let y = $index) {
            <div
              [ngClass]="{
                'child-asin pl-3': (groupByParent$ | async) && !(p.childrenRow[0].asin === p.asin),
              }"
            >
              <ng-container *ngTemplateOutlet="productCard; context: { product: a }"></ng-container>
            </div>
          }
        </div>
      }
    </div>
    @if ((asinRanks$ | async)?.length === 0) {
      <div class="flex h-full items-center justify-center">
        <span>{{ t("tracked-asin-selection.no_result") }}</span>
      </div>
    }
  </div>
</div>

<ng-template #productCard let-product="product">
  <div
    [ngClass]="{ selected: (graphAsin$ | async) === product.asin }"
    (mouseenter)="onHover(product.asin)"
    (mouseleave)="onLeave()"
    (click)="toggleGraphAsin(product.asin)"
    class="product-card relative flex items-center px-2 py-2"
    *transloco="let t"
  >
    <div class="flex w-full items-center">
      <div class="image-wrapper">
        <img class="product-image" [src]="(products.get(product.asin) | async)?.imageUrl" />

        @if (products.get(product.asin) | async; as pr) {
          <a class="link flex items-center justify-center" target="_blank" [href]="getAmazonProductUrl(pr)">
            <span class="icon-[mdi--external-link] text-white"></span>
          </a>
        }
      </div>
      <div class="product-detail">
        <div class="truncate">{{ (products.get(product.asin) | async)?.title }}</div>
        <div class="truncate" style="font-size: 0.9rem">
          <span class="color-tag" [style.background-color]="productColor.get(product.asin)"></span> {{ product.asin }}
        </div>
      </div>
      <div class="font-xl ml-3 font-bold">{{ (products.get(product.asin) | async)?.categoryRank }}</div>
      @if (selectedRankOption !== RankOption.BOTH || (tableView$ | async) === true) {
        <div
          [matTooltip]="(hiddenAsins$ | async).has(product.asin) ? t('common.display_asin') : t('common.hide_asin')"
          (click)="hideAsin($event, product.asin)"
          class="icon-eye ml-3"
        >
          @if ((hiddenAsins$ | async).has(product.asin)) {
            <span class="icon-[mdi--eye-outline] text-lg"></span>
          } @else {
            <span class="icon-[mdi--eye-off-outline] text-lg"></span>
          }
        </div>
      }
    </div>
  </div>
</ng-template>
