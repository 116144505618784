import { registerLocaleData } from "@angular/common";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import localeDeCh from "@angular/common/locales/de-CH";
import localeEnAu from "@angular/common/locales/en-AU";
import localeEnCa from "@angular/common/locales/en-CA";
import localeEnGb from "@angular/common/locales/en-GB";
import localeEs from "@angular/common/locales/es";
import localeFr from "@angular/common/locales/fr";
import localeFrCa from "@angular/common/locales/fr-CA";
import localeFrCh from "@angular/common/locales/fr-CH";
import localeIt from "@angular/common/locales/it";
import localeItCh from "@angular/common/locales/it-CH";
import localeJa from "@angular/common/locales/ja";
import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { provideCharts, ThemeService } from "ng2-charts";

// Import 3rd party components
import { provideNgxStripe } from "ngx-stripe";
import { provideToastr, ToastrService } from "ngx-toastr";
// Import & Configure the Swagger generated API client
// Import routing module
// Import containers
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { GridChartsModule } from "@ag-grid-enterprise/charts";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { SparklinesModule } from "@ag-grid-enterprise/sparklines";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { AmazonLoginHandler, provideM19APIs } from "@front/m19-services";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Colors,
  DoughnutController,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  PolarAreaController,
  Tooltip,
} from "chart.js";
import Annotation from "chartjs-plugin-annotation";
import { AsyncSubject, Observable } from "rxjs";
import { environment } from "../environments/environment";
import { ActivityService } from "./activities/activity.service";
import { LayoutSelectorService } from "./layout-selector.service";
import { ProfitService } from "./profit-board/profit-service";
import { CsvExportService } from "./services/csv-export.service";
import { I18nService } from "./services/i18n.service";
import { KeywordTrackerService } from "./services/keyword-tracker.service";
import { ObfuscationService } from "./services/obfuscation.service";
import { ProductTrackerService } from "./services/product-tracker.service";
import { AccountSettingsService } from "./settings/account-settings.service";
import { BulkImportService } from "./strategies/strategy-bulk-upload-modal/bulk-import.service";
import { AppUpdateService } from "./update-service/update-service";

import { ALERT_SERVICE, AMAZON_LOGIN_HANDLER, API_BASE_URL, SELLING_PARTNER_DOMAIN } from "@front/m19-injection-token";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { provideAnimations } from "@angular/platform-browser/animations";
import { BsModalService } from "ngx-bootstrap/modal";
import { provideTranslocoConfig } from "@m19-board/transloco.provider";
import { provideRouter, TitleStrategy } from "@angular/router";
import { routes, TemplatePageTitleStrategy } from "@m19-board/app.routes";
import { Angulartics2Module } from "angulartics2";
import { ServiceWorkerModule } from "@angular/service-worker";

registerLocaleData(localeJa);
registerLocaleData(localeEs);
registerLocaleData(localeDe);
registerLocaleData(localeDeCh);
registerLocaleData(localeIt);
registerLocaleData(localeItCh);
registerLocaleData(localeEnAu);
registerLocaleData(localeEnCa);
registerLocaleData(localeEnGb);
registerLocaleData(localeFrCa);
registerLocaleData(localeFrCh);
registerLocaleData(localeFr);

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  CsvExportModule,
  MenuModule,
  RangeSelectionModule,
  RowGroupingModule,
  ClipboardModule,
  SetFilterModule,
  MultiFilterModule,
  StatusBarModule,
  SparklinesModule,
  GridChartsModule,
]);

const CLIENT_ID = "amzn1.application-oa2-client.de308868b4984b2b8ceac09afed2b2dd";
declare const amazon: any;

export function loadAmazonScript(): Observable<AmazonLoginHandler> {
  const amazonHandlerProvider = new AsyncSubject<AmazonLoginHandler>();
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://assets.loginwithamazon.com/sdk/na/login1.js";
  script.onload = function () {
    amazon.Login.setClientId(CLIENT_ID);
    // eslint-disable-next-line no-console
    console.info("Amazon script loaded");
    amazonHandlerProvider.next(amazon.Login);
    amazonHandlerProvider.complete();
  };
  document.getElementsByTagName("head")[0].appendChild(script);
  return amazonHandlerProvider;
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    {
      provide: API_BASE_URL,
      useValue: environment.apiEndPoint,
    },
    {
      provide: SELLING_PARTNER_DOMAIN,
      useValue: environment.spOauthRedirectDomain,
    },
    {
      provide: ALERT_SERVICE,
      useClass: ToastrService,
    },
    {
      provide: AMAZON_LOGIN_HANDLER,
      useValue: loadAmazonScript(),
    },
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy,
    },
    importProvidersFrom(Angulartics2Module.forRoot(), ServiceWorkerModule.register("ngsw-worker.js")),
    provideM19APIs(),
    provideAnimations(),
    provideToastr(),
    provideTranslocoConfig(),
    provideNgxStripe(),
    BsModalService,
    ThemeService,
    LayoutSelectorService,
    AppUpdateService,
    CsvExportService,
    KeywordTrackerService,
    ProductTrackerService,
    ProfitService,
    AccountSettingsService,
    ActivityService,
    BulkImportService,
    ObfuscationService,
    I18nService,
    provideHttpClient(withInterceptorsFromDi()),
    provideCharts({
      registerables: [
        BarController,
        Legend,
        ChartDataLabels,
        Annotation,
        LineController,
        DoughnutController,
        PolarAreaController,
        Colors,
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        BarElement,
        ArcElement,
        Tooltip,
        {
          id: "nodataplugin",
          beforeDraw: (chart) => {
            const { ctx } = chart;
            ctx.save();
            ctx.globalCompositeOperation = "destination-over";
            ctx.fillStyle = "#ffffff";
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
          },
          afterDraw: function (chart) {
            if (chart.data.datasets.length === 0) {
              // No data is present
              const ctx = chart.ctx;
              const width = chart.width;
              const height = chart.height;
              chart.clear();

              ctx.save();
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
              ctx.font = "16px normal 'Helvetica Nueue'";
              ctx.fillText("No data to display", width / 2, height / 2);
              ctx.restore();
            }
          },
        },
      ],
    }),
  ],
};
