<app-spinner [display]="loading" [main]="true"></app-spinner>
<div class="card-perso left-child right-child border-shadow card w-full align-top" *transloco="let t">
  @if (plan) {
    <div class="card-header card-perso-header">
      <div class="flex justify-between">
        <div class="flex flex-row items-center">
          <h5 class="mb-0 ml-2">{{ title | titlecase }}</h5>
        </div>
        <div>
          @if (canBeUpgradedToSelfServiceManagement()) {
            <button type="button" class="btn btn-primary" (click)="upgradeToSelfServiceManagement()">
              {{ t("subscription-card.upgrade_to_self_service_management") }}
            </button>
            <button type="button" class="btn btn-primary ml-2" (click)="stopPlan()">
              {{ t("subscription-card.stop_starter_plan") }}
            </button>
          }
          @if (canDowngradeToStarter()) {
            <button
              type="button"
              class="btn btn-primary"
              (click)="downgradeToStarter()"
              matTooltip="You must ensure that the STARTER limits are not exceeded."
            >
              {{ t("subscription-card.migrate_to_starter") }}
            </button>
          }
          @if (canMigrateToParentOrganization()) {
            <button type="button" class="btn btn-primary ml-2" (click)="migrateToParentOrganization()">
              {{ t("subscription-card.transfer_management_to_agency_organization") }}
            </button>
          }
        </div>
      </div>
    </div>
  }
  <div class="bottom-child card-body">
    @if (!isManualBillingPlan) {
      <div>
        <div class="mx-2 mt-2 flex flex-col">
          @if (plan !== SELF_SERVICE) {
            <div class="row">
              <h5 class="col-1">{{ t("common.price") }}</h5>
              <h5 class="col">
                @if (!_billingPlan?.legacyFee) {
                  <span>
                    {{
                      _billingPlan?.flatFee?.amount ?? 0
                        | currency: _billingPlan?.currency : "symbol" : "1.0-0" : locale
                    }}
                  </span>
                }
                @if (_billingPlan && _billingPlan.legacyFee) {
                  <span>
                    {{
                      _billingPlan.legacyFee.amount ?? 0 | currency: _billingPlan.currency : "symbol" : "1.0-0" : locale
                    }}
                  </span>
                }
                @if (_billingPlan && _billingPlan.adSpendFee) {
                  <span>
                    + {{ _billingPlan.adSpendFee.amount | percent: "1.0-2" : locale }}
                    {{
                      adSpendFeeType === ManagedAdSpendFee
                        ? t("subscription-card.the_managed_ad_spend")
                        : t("billing-plan-selection.percent_of_your_ad_spend")
                    }}
                  </span>
                }
                <small>{{ printFrequency(_billingPlan?.frequency) }}</small>
              </h5>
            </div>
          }
          @if (plan === FREE_TEST && _organization && _organization.freeTestUpgradeDate) {
            <div class="my-2 flex">
              @let freeTestUpgradeDate = _organization.testEndDate | date: "longDate" : undefined : locale;
              <IAlert type="info" [title]="t('billing-settings.free_to_pro_transition_message', [freeTestUpgradeDate])">
                <div class="flex justify-end">
                  <IButton
                    class="mt-2"
                    color="white"
                    [label]="t('subscription-card.cancel_free_test_upgrade')"
                    [tooltipValue]="
                      t('subscription-card.cancel_free_test_upgrade_tooltip', { date: freeTestUpgradeDate })
                    "
                    (onClick)="cancelUpgradeBeforeFreeTestEnd()"
                  />
                </div>
              </IAlert>
            </div>
          }
          @if (hasDiscount()) {
            <div class="row">
              <div class="col-1">{{ t("common.coupon") }}</div>
              <div class="col">{{ getDiscountMessage() }}</div>
            </div>
          }
          @if (_organization && _organization.subscriptionEndDate) {
            <div class="row">
              <div class="col-3">{{ t("subscription-card.end_date") }}</div>
              <div class="col-8">{{ _organization.testEndDate | date: "longDate" : undefined : locale }}</div>
            </div>
          }

          @if (plan !== SELF_SERVICE) {
            <div class="mt-2">
              {{ t("subscription-card.whats_included") }}
              <ul class="list-inside list-disc pl-2">
                <li>
                  {{
                    _billingPlan?.accountLimit
                      ? t("billing-plan-selection.limited_account", { count: _billingPlan?.accountLimit })
                      : t("billing-plan-selection.unlimited_account")
                  }}
                </li>
                <li>
                  {{
                    _billingPlan?.marketplaceLimitPerAccount
                      ? t("billing-plan-selection.limited_marketplace", {
                          count: _billingPlan?.marketplaceLimitPerAccount,
                        })
                      : t("billing-plan-selection.unlimited_marketplace")
                  }}
                </li>
                <li>
                  {{
                    _billingPlan?.userLimit
                      ? t("billing-plan-selection.limited_user", { count: _billingPlan?.userLimit })
                      : t("billing-plan-selection.unlimited_user")
                  }}
                </li>
                <li>
                  {{
                    _billingPlan?.strategyLimit
                      ? t("billing-plan-selection.limited_strategy", { count: _billingPlan?.strategyLimit })
                      : t("billing-plan-selection.unlimited_strategy")
                  }}
                </li>
                <li>
                  {{ t("subscription-card.up_to") }}
                  {{ _billingPlan?.kwTrackingDailyLimit }}
                  {{ t("subscription-card.tracked_keywords_daily") }}
                </li>
                <li>
                  {{ t("subscription-card.up_to") }}
                  {{ _billingPlan?.kwTrackingHourlyLimit }}
                  {{ t("subscription-card.tracked_keywords_hourly") }}
                </li>
              </ul>
            </div>
          }
          @if (canDisplayPoNumber()) {
            <div class="row items-center">
              <div class="col-1">{{ t("subscription-card.po_number") }}</div>
              <div class="flex items-center gap-2">
                <IInput
                  type="number"
                  [value]="_organization?.poNumber ?? ''"
                  [maxLength]="20"
                  (valueChange)="poNumber = $event"
                />
                <IButton
                  [icon]="ICON_CHECK"
                  [disabled]="!poNumber"
                  (onClick)="changePoNumber(poNumber!)"
                  [loading]="poNumberLoading()"
                />
                <IButton
                  [icon]="ICON_TRASH"
                  (onClick)="deletePoNumber()"
                  color="white"
                  [disabled]="poNumberLoading()"
                />
              </div>
            </div>
          }
        </div>
      </div>
    }
    @if (nbAccountMarketplaces && nbAccountMarketplaces > 0) {
      <div class="collapse-card-header card-perso-header top-child card-header" (click)="showAccounts = !showAccounts">
        <span class="icon-prepend float-left">
          <app-dropdown-toggle-icon [expand]="showAccounts" [iconClass]="'font-xl'"></app-dropdown-toggle-icon>
        </span>
        <h5 class="card-name mb-0">
          {{ t("subscription-card.nb_marketplace", { count: nbAccountMarketplaces }) }}
        </h5>
      </div>
    }
    <IAccordion [isOpen]="!!showAccounts" [animated]="false">
      <div class="card-perso-body bottom-child card-body">
        <table class="table-sm table-hover table-striped mt-2 table">
          <thead>
            <tr>
              <th>{{ t("common.account") }}</th>
              <th>{{ t("common.marketplace") }}</th>
              @if (totalAdSpendFees.size) {
                <th>{{ t("subscription-card.total_adspend_fee") }}</th>
              }
              <th>
                <div class="flex flex-col items-center">
                  <span> {{ t("subscription-card.daily_tracked_keyword") }}</span>
                  <div class="keywordProgressBar mt-1">
                    <app-spend-progress-bar
                      [currentMonthSpend]="getTotalDailyKeywords()"
                      [monthlyBudget]="_billingPlan!.kwTrackingDailyLimit!"
                      [hideCurrency]="true"
                    >
                    </app-spend-progress-bar>
                  </div>
                </div>
              </th>
              <th>
                <div class="flex flex-col items-center">
                  <span> {{ t("subscription-card.hourly_tracked_keyword") }} </span>
                  <div class="keywordProgressBar mt-1">
                    <app-spend-progress-bar
                      [currentMonthSpend]="getTotalHourlyKeywords()"
                      [monthlyBudget]="_billingPlan!.kwTrackingHourlyLimit!"
                      [hideCurrency]="true"
                    >
                    </app-spend-progress-bar>
                  </div>
                </div>
              </th>
              @if (totalAdSpendFees.size) {
                <th>
                  <app-export-button
                    (export)="downloadFile()"
                    tooltipValue="{{ t('subscription-card.export_spending_details_as_csv') }}"
                  ></app-export-button>
                </th>
              }
            </tr>
          </thead>
          <tbody>
            @for (acc of accountMarketplaces; track acc) {
              <tr>
                <td class="text-left">
                  {{ acc.accountName }}
                </td>
                <td>
                  <IBadge [label]="acc.marketplace" size="xs" />
                </td>
                @if (totalAdSpendFees.size) {
                  <td>
                    <span>{{
                      totalAdSpendFees.get(acc.accountId + "_" + acc.marketplace)?.adSpendFee ?? 0
                        | currency: currency : "symbol" : "1.0-2" : locale
                    }}</span>
                  </td>
                }
                <td>
                  @if (dailyHourlyKeywords !== undefined) {
                    <span>
                      {{
                        dailyHourlyKeywords.get(acc.accountId + "_" + acc.marketplace + "_daily")?.keywordNumber ?? "-"
                      }}
                    </span>
                  }
                </td>
                <td>
                  @if (dailyHourlyKeywords !== undefined) {
                    <span>
                      {{
                        dailyHourlyKeywords.get(acc.accountId + "_" + acc.marketplace + "_hourly")?.keywordNumber ?? "-"
                      }}
                    </span>
                  }
                </td>
                <td></td>
              </tr>
            }
            @for (acc of extraAccountMarketplaces; track acc) {
              <tr>
                <td class="text-left">
                  {{ totalAdSpendFees.get(acc)!.accountName }}
                  @if (totalAdSpendFees.get(acc)!.parentOrganizationId) {
                    <span> {{ t("subscription-card.self-service") }} </span>
                  }
                </td>
                <td>
                  <IBadge [label]="totalAdSpendFees.get(acc)!.marketplace" size="xs" />
                </td>
                <td>
                  <span>{{
                    totalAdSpendFees.get(acc)?.adSpendFee ?? 0 | currency: currency : "symbol" : "1.0-2" : locale
                  }}</span>
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </IAccordion>
  </div>
</div>
