<ng-container *transloco="let t">
  <h2 class="title mb-4">{{ t("data-sharing.data_hub") }}</h2>
  <div class="row">
    <div class="col-sm-4">
      <ISelect
        [options]="organizationOptions"
        [selectedValue]="selectedOrganization()?.value"
        (selectedOptionChange)="selectOrganization($event)"
        placeholder="{{ t('data-sharing.select_an_organization') }}"
        searchable
      >
        <ng-template #optionSlot let-o>
          <span class="mr-2 truncate text-sm" [class.disabled]="o.disabled">{{ o.label }}</span>
          @if (o.value.accessLevel !== AccessLevel.ADMIN) {
            <IBadge label="{{ t('data-sharing.restricted_to_admin') }}" size="xs" color="orange" variant="subtle" />
          }
          @if (o.value.accessLevel === AccessLevel.ADMIN && o.value.datahubAccess) {
            <IBadge label="{{ t('common.activated') }}" size="xs" variant="subtle" />
          }
        </ng-template>
      </ISelect>
    </div>
  </div>
  @if (
    selectedOrganization() &&
    selectedOrganization()?.value?.datahubAccess &&
    selectedOrganization()?.value?.accessLevel !== AccessLevel.ADMIN
  ) {
    <div class="row text-danger mx-2 mt-4">
      {{ t("data-sharing.only_organization_admin_users_have_access_to_data_hub_reports") }}
    </div>
  }
  @if (selectedOrganization() && !selectedOrganization()?.value?.datahubAccess) {
    <div class="row mx-2 mt-4">
      {{ t("data-sharing.data_hub_not_activated") }}
    </div>
  }
  @if (selectedOrganization() && selectedOrganization()?.value?.datahubAccess) {
    <div>
      <div class="row mb-2 mt-4">
        <div class="col-sm-3">
          <ISelect
            [options]="reportTypeOptions"
            [selectedValue]="selectedReportType()?.value"
            (selectedOptionChange)="selectReportTypeFilter($event)"
            placeholder="{{ t('data-sharing.select_a_report_type') }}"
            searchable
          />
        </div>
        <div class="col-sm-3">
          <ISelect
            [options]="marketplaceOptions"
            [selectedValue]="selectedMarketplace()?.value"
            (selectedOptionChange)="selectMarketplaceFilter($event)"
            placeholder="{{ t('data-sharing.select_a_marketplace') }}"
            searchable
          />
        </div>
      </div>
      <mat-table [dataSource]="dataSource" matSort class="responsive-table table-striped-two table-hover mt-4">
        <ng-container matColumnDef="reportType">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ t("data-sharing.report") }}</mat-header-cell>
          <mat-cell *matCellDef="let report">{{ t(report.reportTypeEx.displayName) }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="marketplace">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ t("common.marketplace") }}</mat-header-cell>
          <mat-cell *matCellDef="let report">
            @if (report.marketplace) {
              <IBadge [label]="report.marketplace" variant="outline" size="xs" />
            }
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ t("common.date") }}</mat-header-cell>
          <mat-cell *matCellDef="let report">{{ report.date }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="downloadLink">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let report"
            ><a class="dl-link" (click)="downloadReport(report); $event.preventDefault()">{{
              report.fileName
            }}</a></mat-cell
          >
        </ng-container>
        <mat-header-row *matHeaderRowDef="DisplayedColumns" class="header"></mat-header-row>
        <mat-row *matRowDef="let row; columns: DisplayedColumns"></mat-row>
      </mat-table>
    </div>
  }
  <mat-paginator
    [pageSizeOptions]="[5, 10, 50, 100]"
    [pageSize]="10"
    showFirstLastButtons
    #paginator
    style="margin-bottom: 2rem"
    [hidden]="!dataSource.data || dataSource.data.length === 0"
  ></mat-paginator>
</ng-container>
