// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    PreModerationPolicyViolation,
    PreModerationStatus,
} from './';

/**
 * @export
 * @interface PreModerationComponent
 */
export interface PreModerationComponent {
    /**
     * @type {string}
     * @memberof PreModerationComponent
     */
    id?: string;
    /**
     * @type {string}
     * @memberof PreModerationComponent
     */
    componentType?: PreModerationComponentComponentTypeEnum;
    /**
     * @type {PreModerationStatus}
     * @memberof PreModerationComponent
     */
    preModerationStatus?: PreModerationStatus;
    /**
     * @type {string}
     * @memberof PreModerationComponent
     */
    text?: string;
    /**
     * @type {string}
     * @memberof PreModerationComponent
     */
    url?: string;
    /**
     * @type {Array<string>}
     * @memberof PreModerationComponent
     */
    corrections?: Array<string>;
    /**
     * @type {Array<PreModerationPolicyViolation>}
     * @memberof PreModerationComponent
     */
    policyViolations?: Array<PreModerationPolicyViolation>;
}

/**
 * @export
 * @enum {string}
 */
export enum PreModerationComponentComponentTypeEnum {
    HEADLINE = 'HEADLINE',
    BRAND_LOGO = 'BRAND_LOGO',
    SPONSORED_BRANDS_VIDEO = 'SPONSORED_BRANDS_VIDEO'
}

