import { inject, Injectable } from "@angular/core";
import { AccountMarketplace, AmcReportsApi, AmcSponsoredAdsAndDspOverlap, StatsApi } from "@front/m19-api-client";
import { AccountSelectionService } from "@front/m19-services";
import { map, Observable } from "rxjs";
import {
  AmcSponsoredAdsAndDspOverlapEx,
  buildAmcSponsoredAdsAndDspOverlapEx,
} from "@front/m19-models/AmcSponsoredAdsAndDspOverlapEx";

@Injectable({
  providedIn: "root",
})
export class AmcStatsService {
  private readonly statsApiService = inject(StatsApi);
  private readonly amcReportsApiService = inject(AmcReportsApi);
  private readonly accountSelectionService = inject(AccountSelectionService);

  getAvailableReports(am: AccountMarketplace) {
    return this.amcReportsApiService.getAmcReports(am);
  }

  getMonthlyAmcSponsoredAdsAndDspOverlapStats(
    date: string,
    am: AccountMarketplace,
  ): Observable<AmcSponsoredAdsAndDspOverlapEx[]> {
    return this.statsApiService
      .getMonthlyAmcSponsoredAdsAndDspOverlapStats({
        ...am,
        minDate: date,
        maxDate: date,
      })
      .pipe(map(this.convertToAmcSponsoredAdsAndDspOverlapExtended));
  }

  private convertToAmcSponsoredAdsAndDspOverlapExtended(
    amcSponsoredAdsAndDspOverlap: AmcSponsoredAdsAndDspOverlap[],
  ): AmcSponsoredAdsAndDspOverlapEx[] {
    return amcSponsoredAdsAndDspOverlap.map(buildAmcSponsoredAdsAndDspOverlapEx);
  }
}
