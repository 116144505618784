import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CampaignType, Strategy, StrategyStateEnum, StrategyType } from "@front/m19-api-client";
import { StrategyEx, StrategyGroupEx } from "@front/m19-models";
import { Option } from "@front/m19-ui";
import { TranslocoService } from "@jsverse/transloco";
import { AlgoModeConfig } from "@m19-board/strategies/strategies/algo-mode-selection/algo-mode-selection.component";
import { UntilDestroy } from "@ngneat/until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";

export type TargetProductStrategy = {
  type: "NewProductStrategy" | "NewStrategyGroup" | "ExistingStrategy";
  productStrategy?: StrategyEx;
  productStrategyToCreate?: Strategy;
};

type SelectedProductStrategy = "NewProductStrategy" | "NewStrategy" | StrategyEx;

@UntilDestroy()
@Component({
  templateUrl: "./strategy-group-move-product-modal.component.html",
})
export class StrategyGroupMoveProductModalComponent implements OnInit {
  @Input()
  strategyGroup: StrategyGroupEx;

  @Input()
  source: StrategyEx;

  @Output()
  target = new EventEmitter<TargetProductStrategy>();

  productStrategy: Option<SelectedProductStrategy>;
  productStrategies: Option<SelectedProductStrategy>[];
  algoModeConfig: AlgoModeConfig;
  algoModeConfigValid: boolean;
  productStrategyName: string;

  constructor(
    private bsModalRef: BsModalRef,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.productStrategies = [
      {
        label: this.translocoService.translate("strategy-group-move-product-modal.a_new_main_strategy"),
        value: "NewProductStrategy",
      },
      {
        label: this.translocoService.translate("strategy-group-move-product-modal.a_new_strategy"),
        value: "NewStrategy",
      },
      ...this.strategyGroup.productStrategies
        .filter((s) => s.strategyId != this.source.strategyId)
        .map((s) => ({
          label: s.name ?? "",
          value: s,
        })),
    ];
    this.productStrategy = this.productStrategies[0];
  }

  close() {
    this.bsModalRef.hide();
  }

  setProductStrategy(selected: Option<SelectedProductStrategy>) {
    this.productStrategy = selected;
  }

  setAlgoModeConfig(algoModeConfig: Partial<AlgoModeConfig>) {
    this.algoModeConfig = algoModeConfig as AlgoModeConfig;
  }

  setAlgoModeConfigValid(valid: boolean) {
    this.algoModeConfigValid = valid;
  }

  setProductStrategyName(productStrategyName: string) {
    this.productStrategyName = productStrategyName;
  }

  invalid() {
    return (
      this.productStrategy == undefined ||
      (this.productStrategy.value == "NewProductStrategy" &&
        (!this.algoModeConfigValid || !this.productStrategyName || this.productStrategyName.length == 0))
    );
  }

  invalidTooltip() {
    if (this.productStrategy == undefined) {
      return "Select a main strategy to continue";
    }
    if (this.productStrategy.value == "NewProductStrategy" && !this.algoModeConfigValid) {
      return "Algorithm configuration is invalid";
    }
    if (
      (this.productStrategy.value == "NewProductStrategy" || this.productStrategy.value == "NewStrategy") &&
      (!this.productStrategyName || this.productStrategyName.length == 0)
    ) {
      return "Enter a strategy name";
    }
    return "";
  }

  save() {
    if (this.productStrategy.value == "NewProductStrategy") {
      // open new modal to create a strategy
      this.target.emit({
        type: "NewProductStrategy",
        productStrategyToCreate: {
          accountId: this.strategyGroup.accountId,
          marketplace: this.strategyGroup.marketplace,
          state: StrategyStateEnum.ENABLED,
          campaignType: CampaignType.SP,
          strategyGroupId: this.strategyGroup.strategyGroupId,
          strategyType: StrategyType.PRODUCT,
          defaultStrategy: false,
          tactics: [],
          audienceTargetings: [],
          name: this.productStrategyName,
          ...this.algoModeConfig,
          acosTarget: this.algoModeConfig.acosTarget ? this.algoModeConfig.acosTarget / 100 : undefined,
          asins: [],
        },
      });
    } else {
      if (this.productStrategy.value == "NewStrategy") {
        const strategyToCreate: Strategy = {
          accountId: this.strategyGroup.accountId!,
          marketplace: this.strategyGroup.marketplace!,
          state: StrategyStateEnum.ENABLED,
          campaignType: CampaignType.SP,
          defaultStrategy: false,
          strategyType: StrategyType.PRODUCT,
          tactics: [],
          audienceTargetings: [],
          name: this.productStrategyName,
          ...this.algoModeConfig,
          acosTarget: this.algoModeConfig.acosTarget ? this.algoModeConfig.acosTarget / 100 : undefined,
          asins: [],
        };
        this.target.emit({
          type: "NewStrategyGroup",
          productStrategyToCreate: strategyToCreate,
        });
      } else {
        this.target.emit({
          type: "ExistingStrategy",
          productStrategy: this.productStrategy.value,
        });
      }
    }
    this.bsModalRef.hide();
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
