import { Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SegmentEx, StrategyEx } from "@front/m19-models";
import { CampaignType } from "@front/m19-api-client";
import { StrategyCache } from "@front/m19-services";

@UntilDestroy()
@Component({
  selector: "app-segment-detail-strategies-modal",
  templateUrl: "./segment-detail-strategies-modal.component.html",
})
export class SegmentDetailStrategiesModalComponent {
  @Input()
  segment: SegmentEx | undefined;

  @Input()
  linkedStrategies: StrategyEx[] = [];

  readonly CampaignType = CampaignType;

  constructor(public bsModalRef: BsModalRef) {}
}
