<ng-container *transloco="let t">
  @if (title) {
    <div class="modal-header" [class]="'bg-' + (type === 'danger' ? 'red' : 'main') + '-500'">
      <h4 class="modal-title pull-left">{{ title }}</h4>
    </div>
  }
  <div class="modal-body">
    <p>{{ message }}</p>
  </div>
  <div class="modal-footer">
    <IButton label="{{ t('common.cancel') }}" color="gray" variant="ghost" (onClick)="clickOnCancel()" />
    <IButton
      label="{{ t('common.confirm') }}"
      [color]="type === 'danger' ? 'red' : 'main'"
      [disabled]="!confirmCta"
      (onClick)="clickOnconfirm()"
      type="submit"
      testid="btn-confirm"
    />
  </div>
</ng-container>
