import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ICON_CHECK, ICON_CLOSE, ICON_TRASH_O } from "@m19-board/utils/iconsLabels";

@Component({
  selector: "app-text-input",
  templateUrl: "./text-input.component.html",
  styleUrls: ["./text-input.component.scss"],
})
export class TextInputComponent implements OnInit, OnChanges {
  @Input() value: string;

  @Input() trash = false;

  @Input() disabled = false;

  @Input() deleteTitle = "Delete value";

  @Input() size: number;

  @Input() inputSize: number;

  @Input() maxLength: number;

  oldvalue: string;

  @Output() newValue = new EventEmitter<string>();

  @Output() onDelete = new EventEmitter<void>();

  readonly ICON_CHECK = ICON_CHECK;
  readonly ICON_CLOSE = ICON_CLOSE;
  readonly ICON_TRASH = ICON_TRASH_O;

  ngOnInit() {
    if (!this.value) this.value = "";
    this.oldvalue = this.value;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["value"]?.previousValue != changes["value"]?.currentValue) {
      this.oldvalue = this.value;
    }
  }

  sendValue() {
    if (this.value === undefined) return;
    if (this.value != this.oldvalue) {
      this.oldvalue = this.value;
      this.newValue.emit(this.value);
    }
  }

  resetValue() {
    this.value = this.oldvalue;
  }

  delete() {
    this.value = "";
    this.oldvalue = "";
    this.onDelete.emit();
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
