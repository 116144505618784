import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { AccountMarketplace, CampaignType, Strategy, StrategyStateEnum, StrategyType } from "@front/m19-api-client";
import { StrategyGroupEx, StrategyTypeStr } from "@front/m19-models";
import { AccountSelectionService, SpStrategiesService, StrategyService } from "@front/m19-services";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { ICON_CHEVRON_DOWN } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ToastrService } from "ngx-toastr";
import { map, switchMap } from "rxjs";
import { MatTooltip } from "@angular/material/tooltip";
import { TitleCasePipe } from "@angular/common";
import { SpSubStrategyCreationComponent } from "@m19-board/sponsored-product/sp-page/sp-substrategy-creation/sp-substrategy-creation.component";
import { IButtonComponent } from "@front/m19-ui";

@UntilDestroy()
@Component({
  template: `@if (strategyGroup && strategyType && accountMarketplace) {
    <div class="mb-3 flex items-center justify-between px-2" *transloco="let t">
      <div class="flex items-center">
        <IButton
          [label]="t('v2-sidebar.sponsored_products')"
          variant="ghost"
          to="/advertising/sponsored-product"
          queryParamsHandling="merge"
          [tooltipValue]="t('sp-strategy-group-page.back_to_sponsored_products_strategy_list')"
        />
        /
        <IButton [label]="strategyGroup.strategyGroupName" variant="ghost" queryParamsHandling="merge" to=".." />
        /
        <IButton
          [label]="t('sp-substrategy-creation.new_strategy', [strategyTypeStr | titlecase])"
          variant="ghost"
          queryParamsHandling="merge"
        />
      </div>
      <div>
        <div>
          {{ t("sp-substrategy-creation-page.account") }}&nbsp;<strong>{{
            accountMarketplace.accountGroupName
          }}</strong>
        </div>
        <div>
          {{ t("sp-substrategy-creation-page.marketplace") }}&nbsp;<strong>{{ accountMarketplace.marketplace }}</strong>
        </div>
      </div>
    </div>
    <app-sp-substrategy-creation
      [strategyGroup]="strategyGroup"
      [accountMarketplace]="accountMarketplace"
      [strategyType]="strategyType"
      (strategyToCreate)="createStrategy($event)"
    ></app-sp-substrategy-creation>
  }`,
  standalone: true,
  imports: [
    RouterLink,
    TranslocoDirective,
    MatTooltip,
    TitleCasePipe,
    SpSubStrategyCreationComponent,
    IButtonComponent,
  ],
})
export class SpSubStrategyCreationPageComponent implements OnInit {
  strategyGroup!: StrategyGroupEx;
  strategyType!: StrategyType;
  accountMarketplace!: AccountMarketplace;
  strategyTypeStr!: string;
  creationOnGoing = false;
  strategies: Strategy[] = [];
  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;

  StrategyTypeStrKeys: { [key in StrategyType]: string } = {
    [StrategyType.LEGACY]: "",
    [StrategyType.PRODUCT]: this.translocoService.translate("sp-substrategy-creation.main"),
    [StrategyType.BRAND]: this.translocoService.translate("sp-substrategy-creation.brand_defense"),
    [StrategyType.KEYWORD]: this.translocoService.translate("sp-substrategy-creation.focus"),
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accountMarketplaceSelection: AccountSelectionService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService,
    private spStrategiesService: SpStrategiesService,
    private strategyService: StrategyService,
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap
      // no need for untildestroyed as it will complete when changing page
      .subscribe((params) => {
        this.strategyType = params.get("strategyType") as StrategyType;
        if (!StrategyTypeStr[this.strategyType]) {
          // invalid strategy type
          this.router.navigate(["/advertising/sponsored-product"]);
          return;
        }
        this.strategyTypeStr = this.StrategyTypeStrKeys[this.strategyType];
      });
    this.route.paramMap
      .pipe(
        switchMap((params) => {
          const id = Number(params.get("id"));
          return this.accountMarketplaceSelection.singleAccountMarketplaceSelection$.pipe(
            switchMap((am) => this.spStrategiesService.getStrategyGroups(am.accountId, am.marketplace)),
            map((strategyGroups) => strategyGroups.get(id)),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe((strategyGroup: StrategyGroupEx | undefined) => {
        // redirect to strategy page if the strategy group cannot be found
        if (!strategyGroup) {
          this.router.navigate(["/advertising/sponsored-product"], { queryParamsHandling: "merge" });
          return;
        }
        this.strategyGroup = strategyGroup;
      });
    this.accountMarketplaceSelection.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.accountMarketplace = am;
    });
    this.accountMarketplaceSelection.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) => this.spStrategiesService.getSPStrategies(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((strategies) => {
        this.strategies = Array.from(strategies.values());
      });
  }

  createStrategy(event: Strategy) {
    if (this.creationOnGoing) {
      return;
    }
    this.creationOnGoing = true;
    const reached = this.strategyService.isStrategyLimitReached(
      this.accountMarketplace.accountId,
      this.accountMarketplace.marketplace,
      this.accountMarketplace.resourceOrganizationId!,
      this.strategies,
      CampaignType.SP,
    );
    if (reached) {
      event.state = StrategyStateEnum.PAUSED;
    }
    this.strategyService.createStrategy(event, this.accountMarketplace.resourceOrganizationId!).subscribe({
      next: () => {
        this.creationOnGoing = false;
        this.toastrService.success(
          this.translocoService.translate("sp-substrategy-creation-page.type_strategy_created", [this.strategyTypeStr]),
        );
        this.router.navigate(["/advertising/sponsored-product/strategy-group/" + this.strategyGroup.strategyGroupId], {
          queryParamsHandling: "merge",
        });
      },
      error: (e: string) => {
        this.creationOnGoing = false;
        this.toastrService.error(
          e,
          this.translocoService.translate("sp-substrategy-creation-page.type_strategy_creation_error", [
            this.strategyTypeStr,
          ]),
        );
      },
    });
  }
}
