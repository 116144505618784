import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AccountMarketplace, AlgoMode, StrategyType } from "@front/m19-api-client";
import { StrategyEx, StrategyGroupEx, StrategyTypeStr } from "@front/m19-models";
import moment from "moment-timezone";
import { StrategyCache } from "libs/m19-services/src/lib/m19-services/strategy.cache";
import { StrategyService } from "@front/m19-services";
import { ToastrService } from "ngx-toastr";

export interface PrimeDayConfig {
  primeDayPeriod: string;
  primeDayAfterPeriod: string;
  primeDayOverDate: string;
}

export const eventName = "Promo Days";

export function primeDayConfig(dates: Date[]): PrimeDayConfig {
  const afterDate = moment(dates[1]).add(1, "days");
  const afterDate2 = moment(dates[1]).add(2, "days");
  const overDate = moment(dates[1]).add(3, "days");

  return {
    primeDayPeriod: "from " + moment(dates[0]).format("MMM Do") + " to " + moment(dates[1]).format("MMM Do"),
    primeDayAfterPeriod: "from " + afterDate.format("MMM Do") + " to " + afterDate2.format("MMM Do"),
    primeDayOverDate: overDate.format("MMM Do"),
  };
}

@UntilDestroy()
@Component({
  selector: "app-strategy-boost-table",
  templateUrl: "./strategy-boost-table.component.html",
  styleUrls: ["./strategy-boost-table.component.scss"],
})
export class StrategyBoostTableComponent implements OnInit {
  readonly eventName = eventName;
  readonly AlgoMode = AlgoMode;
  readonly StrategyType = StrategyType;
  readonly StrategyTypeStr = StrategyTypeStr;
  primeDayPeriod: string;
  primeDayAfterPeriod: string;
  primeDayOverDate: string;

  readonly displayedColumns: string[] = ["name", "acosTarget", "enableBtn", "acosOptimizer", "description"];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<StrategyEx> = new MatTableDataSource([]);

  @Input() set data(data: StrategyEx[]) {
    this.dataSource.data = data;
  }

  @Input() accountMarketplace: AccountMarketplace;

  @Input() set filter(filter: string) {
    this.dataSource.filter = filter;
  }

  @Input() set dates(dates: Date[]) {
    if (dates?.length === 2) {
      const config = primeDayConfig(dates);
      this.primeDayPeriod = config.primeDayPeriod;
      this.primeDayAfterPeriod = config.primeDayAfterPeriod;
      this.primeDayOverDate = config.primeDayOverDate;
    }
  }

  @Input() isReadOnly = false;

  strategyGroupIndex = new Map<number, StrategyGroupEx>();

  constructor(
    private strategyCache: StrategyCache,
    private strategyService: StrategyService,
    private toastr: ToastrService,
  ) {
    this.strategyCache.strategyGroupIndex$.pipe(untilDestroyed(this)).subscribe((index) => {
      this.strategyGroupIndex = index;
    });
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
  }

  getStrategyUrl(strategy: StrategyEx): string {
    switch (strategy.campaignType) {
      case "SP":
        return "/sponsored-product/" + strategy.strategyId;
      case "SB":
        return "/sponsored-brands/" + strategy.strategyId;
      case "SD":
        return "/sponsored-display/" + strategy.strategyId;
    }
  }

  setIntensity(strategyId: number, intensity: number): void {
    let primeDayBoost: number;
    if (intensity == 1) {
      primeDayBoost = -50;
    } else if (intensity == 2) {
      primeDayBoost = 0;
    } else if (intensity == 3) {
      primeDayBoost = 25;
    } else if (intensity == 4) {
      primeDayBoost = 50;
    } else if (intensity == 5) {
      primeDayBoost = 100;
    }
    this.strategyService
      .updatePrimeDayBoost(
        this.accountMarketplace.accountId,
        this.accountMarketplace.marketplace,
        strategyId,
        primeDayBoost,
      )
      .subscribe({
        next: () => {
          this.toastr.success("Prime Day boost updated");
        },
        error: (error) => {
          this.toastr.error(error.error.message);
        },
      });
  }

  formatPrimeDayBoost(primeDayBoost: number): number {
    switch (primeDayBoost) {
      case -50:
        return 1;
      case 0:
      case undefined:
      case null:
        return 2;
      case 25:
        return 3;
      case 50:
        return 4;
      case 100:
        return 5;
    }
  }

  primeDayBoost(acosTarget: number, primeDayBoost: number): number {
    return acosTarget + acosTarget * (primeDayBoost / 100);
  }

  primeDayDecrease(acosTarget: number): number {
    return acosTarget * 0.75;
  }

  toggleBoost(strategy: StrategyEx): void {
    let primeDayBoost;
    if (strategy.activatePrimeDayBoost == false) {
      strategy.activatePrimeDayBoost = true;
      primeDayBoost = 25;
    } else {
      strategy.activatePrimeDayBoost = false;
      primeDayBoost = null;
    }
    this.strategyService
      .updatePrimeDayBoost(
        this.accountMarketplace.accountId,
        this.accountMarketplace.marketplace,
        strategy.strategyId,
        primeDayBoost,
      )
      .subscribe({
        next: () => {
          this.toastr.success("Prime Day boost updated");
        },
        error: (error) => {
          this.toastr.error(error.error.message);
        },
      });
  }

  isRangeDisabled(strategy: StrategyEx): boolean {
    if (strategy.dailyBudget == null) {
      return false;
    }
    return !strategy.activatePrimeDayBoost;
  }
}
