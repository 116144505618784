<ng-container *transloco="let t">
  <div class="flex justify-between">
    <div class="flex items-center">
      <a
        class="btn btn-ghost-secondary btn-lg items-center"
        type="button"
        routerLink="/product-center/product-group"
        queryParamsHandling="merge"
        matTooltip="Back to product group list"
        matTooltipPosition="above"
      >
        {{ t("v2-sidebar.product_groups") }}
      </a>
      @if (productGroup !== undefined && productGroup !== null) {
        <span [class]="ICON_CHEVRON_DOWN + ' ml-1 -rotate-90 text-lg text-gray-700'"></span>

        <button
          class="btn btn-ghost-secondary image-no-filter icon-right"
          [matMenuTriggerFor]="productGroupListMenu"
          (menuOpened)="setFocus()"
        >
          <span class="d-inline-block font-lg">{{ productGroup.productGroupName }}</span>
          <span [class]="ICON_CHEVRON_DOWN" class="ml-1 text-lg text-gray-700"></span>
        </button>

        <mat-menu #productGroupListMenu="matMenu">
          <div class="dd-input-container flex items-center" (click)="$event.stopPropagation()">
            <input
              type="text"
              class="simple-input form-control"
              placeholder="Search"
              [value]="productGroupNameFilter"
              (input)="productGroupNameFilter = getValueFromInputEvent($event)"
              (click)="$event.stopPropagation()"
              #search
            />
          </div>
          <ul class="product-list flex flex-col">
            <ng-container
              *ngFor="let pg of productGroups ?? [] | searchTextFilter: accessValue : productGroupNameFilter"
            >
              <li class="dd-item" (click)="navigateToProductGroup(pg)">
                {{ pg.productGroupName }}
              </li>
            </ng-container>
          </ul>
        </mat-menu>
      }
    </div>
  </div>

  @if (productGroup === undefined) {
    <app-spinner [display]="true"></app-spinner>
  } @else if (productGroup === null) {
    <div class="w-100 h-100 flex items-center justify-center">
      <div class="flex flex-col items-center">
        <h3 class="text-center">{{ t("product-group-page.product_group_not_found") }}</h3>
        <p class="text-center">{{ t("product-group-page.not_found_details") }}</p>
      </div>
    </div>
  } @else {
    <div class="my-3 flex items-center justify-between px-3">
      <div class="flex items-start">
        <div
          class="info-bloc group"
          [class.no-hover]="isReadOnly"
          (click)="openProductGroupNameModal(editProductGroupNameRef)"
        >
          <div class="title mb-2">
            {{ t("product-group-page.product_group_name") }}
            <span [class]="ICON_EDIT + ' text-gray-500-filter ml-2 opacity-0 group-hover:opacity-100'"></span>
          </div>
          <div class="font-sm font-medium">{{ productGroup.productGroupName }}</div>
        </div>
      </div>

      @if (!isReadOnly) {
        <div class="flex justify-end">
          <IButton
            color="white"
            [label]="editMode ? t('common.close') : t('product-group-page.edit_asins')"
            [icon]="!editMode ? ICON_EDIT_O : ICON_CLOSE"
            (onClick)="editMode = !editMode"
          />
        </div>
      }
    </div>

    @if (editMode) {
      <div class="h-[70vh]">
        <app-asins-selection
          [disabled]="isReadOnly"
          [asins]="alreadySelectedProducts!"
          [marketplace]="marketplace!"
          [accountId]="accountId!"
          [selectionModes]="selectionModes"
          (onAdd)="addAsinsArrayToProductGroup($event)"
          (onDelete)="deleteAsinArrayFromProductGroup($event)"
          [csvExport]="false"
        />
      </div>
    } @else {
      <app-strategy-asins
        [asins]="alreadySelectedProducts!"
        [accountId]="accountId!"
        [marketplace]="marketplace!"
        [isReadOnly]="isReadOnly"
        [selectable]="true"
        [deletable]="true"
        [movable]="false"
        [csvExport]="true"
        [csvExportFileName]="productGroup.productGroupName + '_' + marketplace + '_ASINs'"
        (onDelete)="deleteAsinArrayFromProductGroup($event)"
      />
      @if (!alreadySelectedProducts!.length) {
        <div class="mt-4 flex justify-center">
          <IButton [label]="t('common.add_asins')" [icon]="ICON_ADD" (onClick)="editMode = !editMode" />
        </div>
      }
    }
  }

  <ng-template #editProductGroupNameRef>
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{ t("product-group-page.update_product_group_name") }}</h4>
    </div>
    <div class="modal-body">
      <div class="input-block">
        <label>{{ t("product-group-page.product_group_name") }}</label>
        <input
          class="simple-input form-control"
          type="text"
          maxlength="80"
          placeholder="{{ t('product-group-page.product_group_name') }}"
          [value]="productGroup?.name"
          (input)="newProductGroupName = getValueFromInputEvent($event)"
        />
        <span class="input-block__hint">{{ newProductGroupName.length }}/80</span>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-ghost-secondary" (click)="productGroupNameModal?.hide()">
        {{ t("common.cancel") }}
      </button>
      <button type="button" class="btn btn-primary" (click)="changeName(newProductGroupName)">
        {{ t("product-group-page.update_product_group_name") }}
      </button>
    </div>
  </ng-template>
</ng-container>
