import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { StrategyEx } from "@front/m19-models";
import { CampaignType } from "@front/m19-api-client";
import { StrategyLinkComponent } from "@m19-board/strategies/strategy-link/strategy-link.component";

@Component({
  selector: "app-strategies-info",
  templateUrl: "./strategies-info.component.html",
  styleUrls: ["./strategies-info.component.scss"],
  standalone: true,
  imports: [StrategyLinkComponent],
})
export class StrategiesInfoComponent {
  @Input() strategies!: StrategyEx[];
  @Input() campaignType!: CampaignType;

  constructor(private router: Router) {}

  getStrategies(): StrategyEx[] {
    return this.strategies.filter((x) => x.campaignType == this.campaignType);
  }
}
