<ng-container *transloco="let t">
  <div class="modal-header bg-primary">
    <h4 class="modal-title pull-left">{{ t("strategy-limitation-modal.limit_of_live_strategies_reached") }}</h4>
  </div>
  <div>
    <div class=""></div>
    @if (whiteLabel) {
      <div class="modal-body">
        <div>{{ t("strategy-limitation-modal.limit_reached", [liveStrategyLimit, campaignType]) }}</div>

        @if (linkToUpgrade) {
          <div>
            <a [href]="linkToUpgrade.url" [target]="linkToUpgrade.target">{{ t("common.upgrade") }}</a>
            {{ t("strategy-limitation-modal.to_activate_more_strategies") }}
          </div>
        }
      </div>
    }
    @if (!whiteLabel) {
      <div class="modal-body">
        <div>
          {{ t("strategy-limitation-modal.limit_reached", [liveStrategyLimit, campaignType]) }}
        </div>
        <div>{{ t("strategy-limitation-modal.please_contact_us") }}</div>
      </div>
    }
    <div class="modal-footer">
      <IButton label="{{ t('common.close') }}" color="gray" variant="ghost" (onClick)="close()" />
    </div>
  </div>
</ng-container>
