import { CurrencyStat } from "@front/m19-models";
import { Chart, ChartDataset, ChartOptions, LegendItem, Tooltip, TooltipItem } from "chart.js";
import { formatPercent } from "@angular/common";
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels";
import { Currency } from "@front/m19-api-client";
import { Utils } from "@front/m19-utils";
import { Metric, MetricType } from "@front/m19-metrics";
import { TranslocoService } from "@jsverse/transloco";

type ChartLabel = { key: string; label: string; color?: string };

Tooltip.positioners.nearest = function (elements, position) {
  if (!elements.length) return { x: 0, y: position.y };
  const { y, base } = elements[0].element.getProps(["y", "base"]);
  return { x: base as number, y: y as number };
};

export function computePercent(ctx: Context): number {
  const meta: any = ctx.chart.getDatasetMeta(ctx.datasetIndex);
  return (ctx.dataset.data[ctx.dataIndex] as number) / meta.total;
}

Chart.register(ChartDataLabels);

export class DonutDataSet<T extends CurrencyStat> {
  public locale = "en";
  public currency: Currency;
  private metric: Metric<T>;

  public resizeCallback: (width, height) => void;

  // used for chart display
  public chartDataSet: ChartDataset[] = [];
  public chartLabels: string[] = [];
  private chartLabelMap: Map<string, ChartLabel> = new Map();

  private readonly MAX_LEGEND_LENGTH = 30;
  readonly defaultGenerateLabels: (chart: Chart) => LegendItem[] =
    Chart.overrides.polarArea.plugins.legend.labels.generateLabels;
  readonly barChartOptions: ChartOptions = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1.2,
    scales: {
      xAxis: {
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: false,
        },
        beginAtZero: true,
        ticks: {
          maxTicksLimit: 6,
          padding: 4,
          callback: (value, index, values) => this.metric?.format(value as number, this.locale, this.currency),
        },
      },
      yAxis: {
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        backgroundColor: "rgba(0, 0, 0, 0)",
        position: "nearest",
        xAlign: "left",
        yAlign: "center",
        callbacks: {
          title: (tooltipItem) => this.chartLabelMap.get(tooltipItem[0].label).label,
          label: (tooltipItem) =>
            this.metric.format(tooltipItem.raw as number, this.locale, this.currency) + " " + this.metric.title,
        },
      },
    },
  };
  donutChartOptions: ChartOptions = {
    responsive: true,
    aspectRatio: 1.2,
    maintainAspectRatio: true,
    layout: {
      padding: 25,
    },
    scales: {
      xAxis: {
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
        },
        ticks: {
          display: false,
        },
      },
      yAxis: {
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      datalabels: {
        display: "auto",
        color: (ctx) => (computePercent(ctx) < 0.1 ? "#181d4d" : "white"),
        anchor: (ctx) => (computePercent(ctx) < 0.1 ? "end" : "center"),
        align: (ctx) => (computePercent(ctx) < 0.1 ? "end" : "center"),
        formatter: (value, ctx) => {
          const percent = computePercent(ctx);
          return percent ? formatPercent(computePercent(ctx), this.locale, "1.1-1") : "";
        },
      },
      legend: {
        position: "right",
        onClick: () => {
          // do nothing
        },
        labels: {
          usePointStyle: true,
          boxWidth: 6,
          boxHeight: 6,
          generateLabels: (chart: Chart) => {
            const items = this.defaultGenerateLabels(chart);
            for (const item of items) {
              item.text = this.chartLabelMap.get(item.text).label;
              if (item.text && item.text.length > this.MAX_LEGEND_LENGTH)
                item.text = item.text.substring(0, this.MAX_LEGEND_LENGTH - 3) + "…";
            }
            return items;
          },
        },
      },
      tooltip: {
        caretSize: 0,
        displayColors: false,
        callbacks: {
          title: (tooltipItem: TooltipItem<any>[]) => {
            const label = tooltipItem[0].label;
            return this.chartLabelMap.get(label).label;
          },
          label: (tooltipItem) =>
            this.metric.format(tooltipItem.raw as number, this.locale, this.currency) + " " + this.metric.title,
        },
      },
    },
  };

  constructor(
    private sumFunction: (x: T, y: T) => T,
    private groupByFunction: (x: T) => ChartLabel,
    donutChartOptionOverride: (ChartOptions) => void = undefined,
    private maxItems = Infinity,
    private otherLabel = "Other",
    private otherColor = "#cccccc",
  ) {
    if (donutChartOptionOverride) {
      donutChartOptionOverride(this.donutChartOptions);
    }
  }

  public buildDataSet(data: T[], metric: Metric<T>) {
    this.chartLabels = [];
    this.metric = metric;
    this.chartLabelMap.clear();
    if (!data) {
      this.chartDataSet = [];
      return;
    }

    const dataMap = new Map<string, T>();

    for (const d of data) {
      const label = this.groupByFunction(d);
      if (!dataMap.has(label.key)) {
        dataMap.set(label.key, { ...d });
        this.chartLabelMap.set(label.key, label);
      } else {
        dataMap.set(label.key, this.sumFunction(dataMap.get(label.key), d));
      }
    }
    this.chartLabelMap.set(this.otherLabel, { key: this.otherLabel, label: this.otherLabel, color: this.otherColor });

    const partitionedData: number[] = [];
    const colors = [];
    const sortedData = Array.from(dataMap.entries()).sort((a, b) => metric.value(b[1]) - metric.value(a[1]));
    let otherData: T = undefined;
    for (const [key, v] of sortedData) {
      if (partitionedData.length < this.maxItems) {
        const value = metric.value(v);
        partitionedData.push(value);
        this.chartLabels.push(key);
        colors.push(this.chartLabelMap.get(key).color ?? Utils.genColor(key));
      } else {
        if (!otherData) {
          otherData = { ...v };
        } else {
          otherData = this.sumFunction(otherData, v);
        }
      }
    }
    if (otherData) {
      this.chartLabels.push(this.otherLabel);
      partitionedData.push(metric.value(otherData));
      colors.push(this.otherColor);
    }
    this.chartDataSet = [
      {
        data: partitionedData,
        label: metric.title,
        backgroundColor: colors,
        borderWidth: 0,
        hoverBackgroundColor: colors,
        type: metric.type === MetricType.RATIO ? "bar" : "doughnut",
      },
    ];
  }
}
