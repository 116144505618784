<div class="page-wrapper flex flex-col" *transloco="let t">
  <h2>{{ t("v2-sidebar.product_tracker") }}</h2>
  <div class="flex w-full justify-between">
    <div class="mr-2 flex min-w-0 flex-1 items-center">
      <ISelect
        class="ml-2"
        [options]="catalogFilterDdOptions"
        [selected]="catalogFilterSelected()"
        (selectedChange)="catalogFilterChanged($event)"
        popperWidth="250px"
      />
      <IMultiSelect
        class="ml-2 min-w-0"
        popperWidth="250px"
        [options]="segmentOptions"
        [selected]="selectedSegments()"
        (selectedChange)="segmentFilterChanged($event)"
        by="segmentId"
        placeholder="{{ t('add-product-tracking-modal.product_segments') }}"
        searchable
        rounded
      >
        <ng-template #labelSlot let-o>
          <span class="truncate">
            <span class="font-medium text-gray-500">{{ t("add-product-tracking-modal.product_segments") }}</span>
            {{ t("keyword-tracker-page.selected_segment", { count: selectedSegments().length }) }}
          </span>
        </ng-template>
      </IMultiSelect>

      <app-spinner [display]="loading" type="default" size="s" class="ml-4 mt-1"></app-spinner>
    </div>
    <div class="flex items-center gap-2">
      <IButton
        color="white"
        [label]="
          isGroupedByParent ? t('product-tracker.split_by_child_asin') : t('product-tracker.group_by_parent_asin')
        "
        (onClick)="setupGroupBy()"
      />
      @if (keywordTrackingSupported) {
        <IButton
          [disabled]="isReadOnly$ | async"
          [icon]="ICON_ADD"
          (onClick)="openAddProductTrackingModal()"
          color="main"
          label="{{ t('add-product-tracking-modal.track_new_products') }}"
        ></IButton>
      }
      <app-export-button (export)="exportAsCsv()" tooltipValue="{{ t('common.export_as_csv') }}"></app-export-button>
    </div>
  </div>
  <div class="grid-wrapper ag-theme-quartz mt-3 flex-1">
    <ag-grid-angular
      style="width: 100%"
      class="h-full"
      [rowData]="gridData"
      [gridOptions]="gridOptions"
    ></ag-grid-angular>
  </div>
</div>
