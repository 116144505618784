import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AuthService } from "@front/m19-services";
import { CampaignType } from "@front/m19-api-client";
import { StrategyOverlayComponent } from "@m19-board/overlay/strategy-overlay.component";
import { StrategyListComponent } from "@m19-board/strategies/strategy-list/strategy-list.component";

@UntilDestroy()
@Component({
  selector: "app-tacos-manager",
  templateUrl: "./tacos-manager.component.html",
  standalone: true,
  imports: [StrategyOverlayComponent, StrategyListComponent],
})
export class TacosManagerComponent {
  readonly CampaignType = CampaignType;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  createStrategy(): void {
    this.router.navigate(["advertising/tacos-strategies/create"], {
      queryParamsHandling: "merge",
    });
  }
}
