import { CurrencyPipe, PercentPipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AccountMarketplace,
  BillingApi,
  BillingPlan,
  BillingPlanFrequencyEnum,
  Currency,
  Organization,
  Plan,
} from "@front/m19-api-client";
import { AuthService, DEFAULT_BILLING_PLANS } from "@front/m19-services";
import { TranslocoDirective } from "@jsverse/transloco";
import { SwitchButtonComponent } from "@m19-board/shared/switch-button/switch-button.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { IBadgeComponent, IButtonComponent } from "@front/m19-ui";

@UntilDestroy()
@Component({
  selector: "app-billing-plan-selection",
  templateUrl: "./billing-plan-selection.component.html",
  styleUrls: ["billing-plan-selection.component.scss"],
  standalone: true,
  imports: [TranslocoDirective, SwitchButtonComponent, CurrencyPipe, PercentPipe, IBadgeComponent, IButtonComponent],
})
export class BillingPlanSelectionComponent implements OnInit {
  @Output()
  save = new EventEmitter<BillingPlan>();

  @Output()
  cancel = new EventEmitter();

  @Input({ required: true })
  organization!: Organization;

  @Input({ required: true })
  organizationResources!: AccountMarketplace[];

  @Input({ required: true })
  currentBillingPlan?: BillingPlan;

  readonly supportedCurrencies: (Currency.EUR | Currency.USD)[] = [Currency.EUR, Currency.USD];
  readonly Plan = Plan;

  professionalBillingPlans: Record<Currency.EUR | Currency.USD, BillingPlan | undefined> = {
    EUR: undefined,
    USD: undefined,
  };
  locale?: string;
  selectedCurrency: Currency.EUR | Currency.USD = Currency.EUR;
  selectedPlan?: BillingPlan;

  constructor(
    private billingApi: BillingApi,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.billingApi
      .listBillingPlans({ plans: DEFAULT_BILLING_PLANS })
      .pipe(untilDestroyed(this))
      .subscribe((billingPlans) => {
        this.professionalBillingPlans.EUR = billingPlans.find(
          (bp) => bp.currency == Currency.EUR && bp.plan == Plan.PROFESSIONAL,
        );
        this.professionalBillingPlans.USD = billingPlans.find(
          (bp) => bp.currency == Currency.USD && bp.plan == Plan.PROFESSIONAL,
        );
        this.selectedPlan = this.professionalBillingPlans[this.selectedCurrency];
      });
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((x) => {
      this.locale = x.locale;
    });
  }

  selectCurrency(currency: Currency.EUR | Currency.USD): void {
    this.selectedCurrency = currency;
    this.selectedPlan = this.professionalBillingPlans[currency];
  }

  saveSelection(): void {
    this.save.emit(this.selectedPlan);
  }

  cancelSelection(): void {
    this.cancel.emit();
  }

  printFrequency(frequency: BillingPlanFrequencyEnum | undefined): string {
    switch (frequency) {
      case BillingPlanFrequencyEnum.MONTHLY:
        return "/ Month";
      case BillingPlanFrequencyEnum.YEARLY:
        return "/ Year";
      default:
        return "";
    }
  }
}
