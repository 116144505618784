import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, Observable, of, switchMap, tap } from "rxjs";
import { KeywordTrackerRow } from "../keyword-tracker-page.component";
import { KeywordTrackerService } from "../../services/keyword-tracker.service";
import { ICON_CHEVRON_DOWN } from "@m19-board/utils/iconsLabels";
import { AccountMarketplace } from "@front/m19-api-client";
import { Catalog } from "@front/m19-models";
import { AccountSelectionService, AsinService } from "@front/m19-services";

type AddProductMessage = {
  type: "info" | "success" | "danger";
  message: string;
};

@UntilDestroy()
@Component({
  selector: "app-keyword-tracker-details",
  templateUrl: "./keyword-tracker-details.component.html",
  styleUrls: ["./keyword-tracker-details.component.scss"],
})
export class KeywordTrackerDetailsComponent {
  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;
  searchTerm$: Observable<KeywordTrackerRow> = this.keywordTrackerService.selectedSearchTerm$;
  keywordTrackerData: KeywordTrackerRow[] = this.keywordTrackerService.getKeywordTrackerData();

  accountMarketplace$: Observable<AccountMarketplace> = this.accountSelection.singleAccountMarketplaceSelection$;
  am: AccountMarketplace;

  catalog: Catalog;
  keywordFilter = "";

  addProductMessage$: Observable<AddProductMessage> = this.keywordTrackerService.addProductMessage$;
  loading$ = this.keywordTrackerService.searchTermDataLoading$;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accountSelection: AccountSelectionService,
    private keywordTrackerService: KeywordTrackerService,
    private asinService: AsinService,
  ) {
    route.params
      .pipe(
        untilDestroyed(this),
        tap(() => this.keywordTrackerService.setTableView(true)),
        switchMap((_) =>
          this.accountMarketplace$.pipe(
            untilDestroyed(this),
            tap((am: AccountMarketplace) => (this.am = am)),
            switchMap((am: AccountMarketplace) => {
              return combineLatest<[Catalog, KeywordTrackerRow[], string]>([
                this.asinService.getCatalog(am.accountId, am.marketplace),
                this.keywordTrackerService.keywordTrackerData$,
                of(this.route.snapshot.paramMap.get("searchterm")),
              ]);
            }),
          ),
        ),
      )
      .subscribe(([catalog, keywordData, currentSearchTerm]: [Catalog, KeywordTrackerRow[], string]) => {
        this.keywordTrackerData = keywordData;
        const currentSearchTermData = keywordData.filter((d) => d.searchTerm === currentSearchTerm);
        this.keywordTrackerService.setSelectedSearchTerm(currentSearchTermData[0]);
        this.catalog = catalog;
      });
  }

  keywordPassTextFilter(keyword: string): boolean {
    return keyword.search(new RegExp(this.keywordFilter, "i")) !== -1;
  }

  navigateToSearchTerm(searchTerm: KeywordTrackerRow) {
    const relatedData = this.keywordTrackerData.filter((d) => d.searchTerm === searchTerm.searchTerm);
    if (!relatedData || !relatedData.length) return; // should not go there

    this.keywordTrackerService.setSelectedSearchTerm(relatedData[0]);
    this.router.navigate(["/keyword-tracker/" + searchTerm.searchTerm], { queryParamsHandling: "merge" }).then();
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
