import { Marketplace } from '../api-client/models/Marketplace';
import { Currency } from '../api-client/models/Currency';
import { CurrencyStat } from './CurrencyStat';

export interface OrderStats extends CurrencyStat {
  globalSales: number;
  tax: number;
  promotion: number;
  shippingGiftWrap: number;
  fee: number;
  advertising: number;
  costOfGoods?: number;
  profit?: number;

  // dim
  marketplace?: Marketplace;
  sku?: string;
  asin?: string;
  currency?: Currency;
  date?: string;

  // detailed stats

  // globalSales
  sales?: number;
  refunds?: number;
  reimbursement?: number;
  reimbursementClawback?: number;

  // globalSales quantities
  quantity?: number;
  refunded?: number;
  chargebackRefunded?: number;
  a2zGuaranteeRefunded?: number;
  orderReturnRefunded?: number;
  replacementRefunded?: number;
  sellableReturns?: number;
  amazonVine?: number;

  // tax
  baseTax?: number;
  shippingTax?: number;
  giftWrapTax?: number;
  otherTax?: number;
  taxDiscount?: number;

  // promotion
  basePromotion?: number;
  giftWrapPromotion?: number;
  shippingPromotion?: number;
  otherPromotion?: number;

  // shippingGiftWrap
  shipping?: number;
  shippingHB?: number;
  shippingChargeBack?: number;
  giftWrap?: number;
  giftWrapChargeBack?: number;

  // FEES
  // commissions
  referralFee?: number;
  // fba fulfillment fee
  fbaFee?: number;
  giftWrapFee?: number;
  returnFees?: number;
  feeAdjustment?: number;
  fbaStorageFee?: number;
  exportCharge?: number;

  // from global settlement fees
  longTermStorageFee?: number;
  liquidations?: number;
  internationalFreight?: number;

  otherFee?: number;

  // advertising
  spAdvertising?: number;
  sbAdvertising?: number;
  sdAdvertising?: number;
}
