import { Component, Input } from "@angular/core";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";

@Component({
  selector: "app-rank-evolution",
  templateUrl: "./rank-evolution.component.html",
})
export class RankEvolutionComponent implements ICellRendererAngularComp {
  readonly faArrowDown = faArrowDown;
  readonly faArrowUp = faArrowUp;

  @Input()
  set rank(val: number) {
    this._rank = val;
    this.computeEvolution();
  }

  @Input()
  set previousRank(val: number) {
    this._previousRank = val;
    this.computeEvolution();
  }

  _rank: number;
  _previousRank: number;
  evolution: number;
  evolutionDirection: "Increase" | "Decrease" | "Steady";

  agInit(
    params: ICellRendererParams & {
      rank: number;
      previousRank: number;
    },
  ): void {
    this._rank = params.rank;
    this._previousRank = params.previousRank;
    this.computeEvolution();
  }

  refresh(): boolean {
    return false;
  }

  private computeEvolution() {
    if (isFinite(this._rank)) {
      if (isFinite(this._previousRank)) {
        this.evolution = this._previousRank - this._rank;
        if (this.evolution == 0) {
          this.evolutionDirection = "Steady";
        } else if (this.evolution < 0) {
          this.evolutionDirection = "Decrease";
          this.evolution = -this.evolution;
        } else {
          this.evolutionDirection = "Increase";
        }
      } else {
        this.evolution = this._rank;
        this.evolutionDirection = "Increase";
      }
    } else {
      if (isFinite(this._previousRank)) {
        this.evolution = this._previousRank;
        this.evolutionDirection = "Decrease";
      } else {
        this.evolution = 0;
        this.evolutionDirection = "Steady";
      }
    }
  }
}
