// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    ProductGroup,
    Response,
} from '../models';

export interface CreateProductGroupRequest {
    productGroup: ProductGroup;
}

export interface DeleteProductGroupRequest {
    accountId: string;
    productGroupId: number;
}

export interface ListProductGroupsRequest {
    accountId: string;
}

export interface UpdateProductGroupRequest {
    productGroup: ProductGroup;
}

/**
 * no description
 */
export class ProductGroupApi extends BaseAPI {

    /**
     * Create a Product Group
     * Create a Product Group
     */
    createProductGroup({ productGroup }: CreateProductGroupRequest): Observable<Response>
    createProductGroup({ productGroup }: CreateProductGroupRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    createProductGroup({ productGroup }: CreateProductGroupRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(productGroup, 'productGroup', 'createProductGroup');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Response>({
            url: '/productGroup',
            method: 'POST',
            headers,
            body: productGroup,
        }, opts?.responseOpts);
    };

    /**
     * Delete a Product Group
     */
    deleteProductGroup({ accountId, productGroupId }: DeleteProductGroupRequest): Observable<Response>
    deleteProductGroup({ accountId, productGroupId }: DeleteProductGroupRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    deleteProductGroup({ accountId, productGroupId }: DeleteProductGroupRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'deleteProductGroup');
        throwIfNullOrUndefined(productGroupId, 'productGroupId', 'deleteProductGroup');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Response>({
            url: '/productGroup/{accountId}/{productGroupId}'.replace('{accountId}', encodeURI(accountId)).replace('{productGroupId}', encodeURI(productGroupId)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * List Product Groups
     * List Product Groups
     */
    listProductGroups({ accountId }: ListProductGroupsRequest): Observable<Array<ProductGroup>>
    listProductGroups({ accountId }: ListProductGroupsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<ProductGroup>>>
    listProductGroups({ accountId }: ListProductGroupsRequest, opts?: OperationOpts): Observable<Array<ProductGroup> | AjaxResponse<Array<ProductGroup>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'listProductGroups');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
        };

        return this.request<Array<ProductGroup>>({
            url: '/productGroup',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Update a Product Group
     * Update a Product Group
     */
    updateProductGroup({ productGroup }: UpdateProductGroupRequest): Observable<Response>
    updateProductGroup({ productGroup }: UpdateProductGroupRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateProductGroup({ productGroup }: UpdateProductGroupRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(productGroup, 'productGroup', 'updateProductGroup');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Response>({
            url: '/productGroup',
            method: 'PUT',
            headers,
            body: productGroup,
        }, opts?.responseOpts);
    };

}
