// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    GlobalWarning,
    Marketplace,
    Notif,
    NotificationType,
    Response,
} from '../models';

export interface DeleteNotificationRequest {
    accountId: string;
    type: NotificationType;
    marketplace?: Marketplace;
    strategyId?: number;
    subStrategyId?: number;
}

export interface DismissNotificationRequest {
    accountId: string;
    type: NotificationType;
    dismiss: DismissNotificationDismissEnum;
    marketplace?: Marketplace;
    strategyId?: number;
    subStrategyId?: number;
}

/**
 * no description
 */
export class NotificationApi extends BaseAPI {

    /**
     * Delete a notification from the DB
     */
    deleteNotification({ accountId, type, marketplace, strategyId, subStrategyId }: DeleteNotificationRequest): Observable<Response>
    deleteNotification({ accountId, type, marketplace, strategyId, subStrategyId }: DeleteNotificationRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    deleteNotification({ accountId, type, marketplace, strategyId, subStrategyId }: DeleteNotificationRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'deleteNotification');
        throwIfNullOrUndefined(type, 'type', 'deleteNotification');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'type': type,
        };

        if (marketplace != null) { query['marketplace'] = marketplace; }
        if (strategyId != null) { query['strategyId'] = strategyId; }
        if (subStrategyId != null) { query['subStrategyId'] = subStrategyId; }

        return this.request<Response>({
            url: '/notification',
            method: 'DELETE',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Dismiss (or restore) a notification
     */
    dismissNotification({ accountId, type, dismiss, marketplace, strategyId, subStrategyId }: DismissNotificationRequest): Observable<Response>
    dismissNotification({ accountId, type, dismiss, marketplace, strategyId, subStrategyId }: DismissNotificationRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    dismissNotification({ accountId, type, dismiss, marketplace, strategyId, subStrategyId }: DismissNotificationRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'dismissNotification');
        throwIfNullOrUndefined(type, 'type', 'dismissNotification');
        throwIfNullOrUndefined(dismiss, 'dismiss', 'dismissNotification');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'type': type,
            'dismiss': dismiss,
        };

        if (marketplace != null) { query['marketplace'] = marketplace; }
        if (strategyId != null) { query['strategyId'] = strategyId; }
        if (subStrategyId != null) { query['subStrategyId'] = subStrategyId; }

        return this.request<Response>({
            url: '/notification',
            method: 'PUT',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Get a global warning to display on the board
     */
    getGlobalWarning(): Observable<GlobalWarning>
    getGlobalWarning(opts?: OperationOpts): Observable<AjaxResponse<GlobalWarning>>
    getGlobalWarning(opts?: OperationOpts): Observable<GlobalWarning | AjaxResponse<GlobalWarning>> {
        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<GlobalWarning>({
            url: '/warning',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Get all notifications for authenticated user
     */
    getNotifications(): Observable<Array<Notif>>
    getNotifications(opts?: OperationOpts): Observable<AjaxResponse<Array<Notif>>>
    getNotifications(opts?: OperationOpts): Observable<Array<Notif> | AjaxResponse<Array<Notif>>> {
        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<Notif>>({
            url: '/notification',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum DismissNotificationDismissEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}
