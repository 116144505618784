<span
  class="input-group form"
  (focusin)="isFocused.emit(true)"
  (focusout)="isFocused.emit(false)"
  (click)="$event.stopPropagation()"
>
  <input
    [disabled]="disabled"
    [attr.data-testid]="testid"
    type="number"
    inputmode="numeric"
    class="simple-input-append form-control pb-1 pl-2 pr-2 pt-1 text-center"
    [value]="value"
    [ngStyle]="{ 'width.rem': size }"
    (input)="onChange.emit(+getValueFromInputEvent($event))"
    (keypress)="checkkey($event)"
    [min]="min"
    [max]="max"
    [step]="step"
  />

  <div class="input-group-append">
    @if (unit) {
      <span class="input-group-text">{{ unit }}</span>
    }
  </div>
</span>
