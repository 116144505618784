<div class="modal-body flex flex-col gap-y-2" *transloco="let t">
  @if (asin && marketplace) {
    <app-product-view [asin]="asin" [marketplace]="marketplace" />
  }

  @for (m of metrics; track m) {
    <div class="rounded-md border border-gray-300">
      <div class="rounded-t-md border-b border-gray-300 bg-gray-100 px-3 py-1">
        <span class="font-medium text-gray-800">{{ t("metrics." + m.id + "_title") }}</span>
      </div>
      <div class="flex flex-col gap-x-2 px-3 py-1">
        @if (!(m | nullDetailedOrderMetricsPipe: data : true)) {
          @for (detailMetric of m | detailedOrderMetricsPipe: true; track detailMetric) {
            <div class="flex justify-between">
              @if (profitDetails.get(detailMetric.id)) {
                <span
                  class="text-gray-600"
                  [matTooltip]="
                    tooltipsByMetricId.has(detailMetric.id) ? tooltipsByMetricId.get(detailMetric.id) : null
                  "
                  >{{ detailMetric.title }}
                  @if (tooltipsByMetricId.has(detailMetric.id)) {
                    <span class="icon-[mdi--information-variant] size-4 align-top text-gray-600"></span>
                  }
                </span>
                <div class="flex items-center gap-x-2">
                  @if (detailMetric["currency"]) {
                    <span class="font-medium"
                      >&nbsp;{{
                        PROFIT_DETAIL_AMOUNT
                          | metricFormatPipe: profitDetails.get(detailMetric.id) : locale : currency : "1.2-2"
                      }}</span
                    >
                    <IBadge
                      class="inline-flex"
                      [label]="formatShare(profitDetails.get(detailMetric.id))"
                      [color]="getShareValue(profitDetails.get(detailMetric.id)) >= 0 ? 'green' : 'red'"
                      variant="soft"
                      size="xs"
                    />
                  } @else {
                    <span class="font-medium"
                      >&nbsp;{{ detailMetric | metricFormatPipe: data : locale : currency : "1.2-2" }}</span
                    >
                  }
                </div>
              }
            </div>
          }
        } @else {
          <span>-</span>
        }
      </div>
    </div>
  }
</div>
