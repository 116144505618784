import { Component, Input, OnInit } from "@angular/core";
import { faBell, faExclamationTriangle, faQuestionCircle, faTimes, faUndo } from "@fortawesome/free-solid-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AuthService, NotificationService } from "@front/m19-services";
import { BoardType, LayoutSelectorService } from "@m19-board/layout-selector.service";
import { NotificationEx } from "@front/m19-models";
import { TranslocoDirective } from "@jsverse/transloco";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { MatTooltip } from "@angular/material/tooltip";
import { DatePipe, NgClass } from "@angular/common";
import { IBadgeComponent } from "@front/m19-ui";

@UntilDestroy()
@Component({
  selector: "app-notification-card",
  templateUrl: "./notification-card.component.html",
  styleUrls: ["./notification-card.component.scss"],
  standalone: true,
  imports: [TranslocoDirective, FaIconComponent, MatTooltip, NgClass, IBadgeComponent, DatePipe],
})
export class NotificationCardComponent implements OnInit {
  @Input({ required: true })
  notification!: NotificationEx;
  @Input()
  small = false;
  @Input()
  display = true;
  @Input()
  helplink: string | undefined = undefined;
  @Input()
  icon: string | undefined = undefined;
  @Input()
  faIcon?: IconDefinition = undefined;

  locale?: string;

  readonly faExclamationTriangle = faExclamationTriangle;
  readonly faTimes = faTimes;
  readonly faBell = faBell;
  readonly faHelp = faQuestionCircle;
  readonly faUndo = faUndo;

  constructor(
    private notificationService: NotificationService,
    private authService: AuthService,
    private layoutSelectorService: LayoutSelectorService,
  ) {}

  ngOnInit(): void {
    this.authService.loggedUser$.subscribe((user) => (this.locale = user.locale));
  }

  toggleNotificationDismissed(notification: NotificationEx): void {
    this.notificationService.toggleNotificationDismissed(notification).subscribe();
  }

  displayHelp(): boolean {
    return !!this.helplink && this.layoutSelectorService.getBoardType() === BoardType.M19;
  }
}
