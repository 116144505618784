import { faChartBar, faLightbulb } from "@fortawesome/free-regular-svg-icons";
import { faEdit, faFlask, faIceCream, faTags, faTools, IconDefinition } from "@fortawesome/free-solid-svg-icons";

interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name: string;
  url: string;
  icon?: IconDefinition;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  vendorSupport?: boolean;
  inBeta?: boolean;
}

// GOOD TO KNOW: if the url has more than one '/' the sub menu will auto-collapse. Do not use multiple '/' in urls.....
export const navItems: NavData[] = [
  { name: "Dashboard", url: "/home", icon: faChartBar },
  {
    name: "Strategies",
    url: "/strategies",
    icon: faLightbulb,
  },
  { name: "Products", url: "/products", icon: faTags },
  {
    name: "Insights",
    icon: faFlask,
    url: "/insights",
    children: [
      { name: "Overview", url: "/overview" },
      { name: "Traffic Analysis", url: "/traffic" },
      {
        name: "Profit & Loss",
        url: "/profit-and-loss",
      },
      { name: "Hourly Stats", url: "/hourly-stats" },
    ],
  },
  {
    name: "Tools",
    icon: faTools,
    url: "/tools",
    children: [
      {
        name: "Segments",
        url: "/segments",
      },
      {
        name: "Search Trends",
        url: "/search-trends",
      },
      { name: "Keyword Tracker", url: "/keyword-tracker" },

      { name: "Product Tracker", url: "/product-tracker" },
      { name: "Share of Voice", url: "/share-of-voice" },
      { name: "Product 360", url: "/product360" },
      { name: "Account 360", url: "/account360" },
    ],
  },
  { name: "Activities", url: "/activities", icon: faEdit },
];
