import { Injectable } from "@angular/core";
import { Marketplace } from "@front/m19-api-client";
import { Moment, tz } from "moment-timezone";
import { Marketplaces } from "@front/m19-models";

@Injectable({
  providedIn: "root",
})
export class AmazonTimezoneService {
  public getNow(marketplace: Marketplace): Moment {
    return tz(Marketplaces[marketplace]?.timeZone ?? "Europe/Paris");
  }

  public getRemainingDaysOfMonth(marketplace: Marketplace): number {
    const today = this.getNow(marketplace);
    return today.daysInMonth() - today.date();
  }

  public getNbDaysOfTheNextMonth(marketplace: Marketplace): number {
    const today = this.getNow(marketplace);
    return today.add(1, "M").daysInMonth();
  }
}
