import { formatCurrency } from '@angular/common';
import { Injectable } from '@angular/core';
import moment from 'moment-timezone';
import { forkJoin, map, mergeMap, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import {
  AlgoMode,
  CampaignType,
  Intensity,
  Marketplace,
  Response,
  StatsApi,
  Strategy,
  StrategyApi,
  StrategyStateEnum,
  StrategyTactic,
  StrategyType,
  TacticType,
  UpdateStrategyRequest,
} from '@front/m19-api-client';
import {
  AccountMarketplaceCache,
  accountMarketplaceKey,
  AccountMarketplaceKey,
  catchAjaxError,
  currencyRateToEuro,
  marketplaceCurrency,
  Utils,
} from '@front/m19-utils';
import { ConfigService } from './config.service';
import { Constant } from './constant';
import { OrganizationAccountGroupService } from './organizationAccountGroup.service';
import { SbStrategiesService } from './sb-strategies.service';
import { SdStrategiesService } from './sd-strategies.service';
import { SegmentService } from './segmentService';
import { SpStrategiesService } from './sp-strategies.service';
import { StrategyCache } from './strategy.cache';
import { StrategyUpdateParams } from '@front/m19-models/StrategyUpdateParams';
import { ProductGroupEx } from '@front/m19-models/ProductGroupEx';
import { SegmentConfigType } from '@front/m19-models/SegmentEx';
import { AlgoModeStr, StrategyEx } from '@front/m19-models/StrategyEx';
import { Currencies } from '@front/m19-models/CurrencyEx';

type StrategyId = number;
type Spend = number;

export interface CampaignTypeStrategiesService {
  checkStrategyUpdate(strategyUpdateParams: StrategyUpdateParams): Observable<string[]>;
  checkStrategyCreation(strategy: Strategy): Observable<string[]>;
}

@Injectable({
  providedIn: 'root',
})
export class StrategyService {
  private readonly strategyCurrentMonthSpendCache = new AccountMarketplaceCache<Map<StrategyId, Spend>>(
    (accountId, marketplace) => this.getAllStrategyCurrentMonthSpend(accountId, marketplace),
  );

  private readonly accountMarketplaceConfigs = new Map<
    AccountMarketplaceKey,
    {
      liveStrategiesLimit: number;
      minDailyBudgetLimit: number;
      organizationId: number;
    }
  >();

  constructor(
    private statsApi: StatsApi,
    private strategyApi: StrategyApi,
    private segmentService: SegmentService,
    private organizationService: OrganizationAccountGroupService,
    private configService: ConfigService,
    private strategyCache: StrategyCache,
    private spStrategiesService: SpStrategiesService,
    private sdStrategiesService: SdStrategiesService,
    private sbStrategiesService: SbStrategiesService,
  ) {
    // get some account marketplaces config
    this.organizationService.allOrganizationAccountGroups$.subscribe((groups) => {
      this.accountMarketplaceConfigs.clear();
      for (const group of groups ?? []) {
        const billingPlan = group.getBillingPlan();
        const liveStrategyLimit = billingPlan ? (billingPlan.strategyLimit ?? +Infinity) : 0;
        for (const am of group.accountGroups.flatMap((ag) => ag.getAccountMarketplaces())) {
          const key = accountMarketplaceKey(am.accountId, am.marketplace);
          this.accountMarketplaceConfigs.set(key, {
            liveStrategiesLimit: liveStrategyLimit,
            minDailyBudgetLimit: am.minDailyBudgetLimit ?? 100,
            organizationId: am.resourceOrganizationId!,
          });
        }
      }
    });
  }

  public getStrategyIndex(accountId: string, marketplace: Marketplace) {
    return this.strategyCache.strategyListCache.get({ accountId, marketplace });
  }

  public getStrategyCurrentMonthSpend(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
  ): Observable<Spend> {
    return this.strategyCurrentMonthSpendCache
      .get({ accountId, marketplace })
      .pipe(map((spend) => spend.get(strategyId) ?? 0));
  }

  public createStrategy(strategy: Strategy): Observable<Strategy> {
    const accountId = strategy.accountId;
    const marketplace = strategy.marketplace;
    const organizationId = this.accountMarketplaceConfigs.get(
      accountMarketplaceKey(accountId, marketplace),
    )?.organizationId;
    return this.checkStrategyCreation(strategy).pipe(
      switchMap((errors) => {
        if (errors.length > 0) {
          return throwError(() => errors.join(', '));
        }
        return this.strategyApi.createStrategy({
          accountId: strategy.accountId,
          marketplace: strategy.marketplace,
          strategyGroupId: strategy.strategyGroupId ?? null,
          strategyType: strategy.strategyType ?? StrategyType.LEGACY,
          campaignType: strategy.campaignType,
          algoMode: strategy.algoMode,
          priority: strategy.priority ?? 0,
          name: strategy.name!,
          state: strategy.state,
          acosTarget: strategy.acosTarget,
          suggestedBid: strategy.suggestedBid,
          dailyBudget: strategy.dailyBudget,
          monthlyBudget: strategy.monthlyBudget,
          minDailySpend: strategy.minDailySpend,
          disableOtherQueries: strategy.disableOtherQueries,
          disableAutoSegment: strategy.disableAutoSegment,
          disableProductSegment: strategy.disableProductSegment,
          organizationId: organizationId!,
          brandEntityId: strategy.brandEntityId,
        });
      }),
      catchAjaxError('Error creating Strategy: '),
      map((response: Response) => {
        const strat = response.entity as Strategy;
        this.strategyCache.strategyListCache.update({ accountId, marketplace }, (strategies) => {
          strategies.set(strat.strategyId!, strat);
          return strategies;
        });
        // TODO: legacy update to remove after the migration
        const newStrategy = new StrategyEx(strat, this.strategyCache.getCreativesMap(strategy.strategyId!));
        this.strategyCache.updateStrategy(newStrategy);
        return strat;
      }),
      switchMap((s) => {
        if (strategy.campaignType == CampaignType.SP || strategy.campaignType == CampaignType.SD) {
          return this.addAsinsToStrategy(s, strategy.asins!.map((a) => a.asin!)!).pipe(
            map(() => {
              s.asins = strategy.asins!;
              return s;
            }),
            catchAjaxError('Impossible to add ASINs to the strategy: '),
          );
        }
        return of(s);
      }),
      switchMap((s) => {
        if (
          strategy.campaignType == CampaignType.SP &&
          (strategy.strategyType == StrategyType.BRAND || strategy.strategyType == StrategyType.KEYWORD) &&
          strategy.targetings &&
          strategy.targetings.length > 0
        ) {
          return this.spStrategiesService.addTargetingToStrategy(s, strategy.targetings!, organizationId!).pipe(
            map(() => {
              s.targetings = strategy.targetings!;
              return s;
            }),
          );
        }
        return of(s);
      }),
    );
  }

  public updateStrategyName(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    name: string,
  ): Observable<Strategy> {
    return this.updateStrategy({
      accountId,
      marketplace,
      strategyId,
      name,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyLabel(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    strategyLabel: string,
  ): Observable<Strategy> {
    return this.updateStrategy({
      accountId,
      marketplace,
      strategyId,
      strategyLabel,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyDailyBudget(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    dailyBudget: number,
  ): Observable<Strategy> {
    return this.updateStrategy({
      accountId,
      marketplace,
      strategyId,
      dailyBudget,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updatePrimeDayBoost(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    primeDayBoost: number,
  ): Observable<Strategy> {
    return this.updateStrategy({
      accountId,
      marketplace,
      strategyId,
      primeDayBoost,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyState(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    state: StrategyStateEnum,
  ): Observable<Strategy> {
    return this.updateStrategy({
      accountId,
      marketplace,
      strategyId,
      state,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyAutoAlgoExploration(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    autoAlgoExplorationEnabled: boolean,
  ): Observable<Strategy> {
    // The naming is still in the "disable" way but the logic isn't
    // if “automated algorithm’s exploration” = “off” then “automated targeting campaign” has to be “off”
    const disableOtherQueries = !autoAlgoExplorationEnabled;
    return this.updateStrategy({
      accountId,
      marketplace,
      strategyId,
      disableOtherQueries,
      disableAutoSegment: !autoAlgoExplorationEnabled ? true : undefined,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyTargetCampain(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    autoTargetCampainEnabled: boolean,
  ): Observable<Strategy> {
    // The naming is still in the "disable" way but the logic isn't
    const disableAutoSegment = !autoTargetCampainEnabled;
    // if “automated targeting campaign” is on then “automated algorithm’s exploration” have to be “on”
    return this.updateStrategy({
      accountId,
      marketplace,
      strategyId,
      disableAutoSegment,
      disableOtherQueries: autoTargetCampainEnabled ? false : undefined,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyProductTargeting(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    productTargetingEnabled: boolean,
  ): Observable<Strategy> {
    // The naming is still in the "disable" way but the logic isn't
    const disableProductSegment = !productTargetingEnabled;
    return this.updateStrategy({
      accountId,
      marketplace,
      strategyId,
      disableProductSegment,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public disableDayParting(accountId: string, marketplace: Marketplace, strategyId: number): Observable<Strategy> {
    return this.updateStrategy({
      accountId,
      marketplace,
      strategyId,
      daypartingPauseHour: null,
      daypartingReactivationHour: null,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyHoursDayParting(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    daypartingPauseHour: number,
    daypartingReactivationHour: number,
  ): Observable<Strategy> {
    return this.updateStrategy({
      accountId,
      marketplace,
      strategyId,
      daypartingPauseHour,
      daypartingReactivationHour,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyMinDailySpend(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    minDailySpend: number,
  ): Observable<Strategy> {
    return this.updateStrategy({
      accountId,
      marketplace,
      strategyId,
      minDailySpend,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyPriority(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    priority: number,
  ): Observable<Strategy> {
    return this.updateStrategy({
      accountId,
      marketplace,
      strategyId,
      priority,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategy(strategyUpdateParams: StrategyUpdateParams): Observable<Strategy> {
    const accountId = strategyUpdateParams.accountId;
    const marketplace = strategyUpdateParams.marketplace;
    const organizationId = this.accountMarketplaceConfigs.get(
      accountMarketplaceKey(accountId, marketplace),
    )?.organizationId;
    return this.strategyCache.strategyListCache.get({ accountId, marketplace }).pipe(
      take(1),
      switchMap((strategies) => {
        return this.checkStrategyUpdate(strategyUpdateParams, strategies).pipe(
          map((errors) => ({ errors, strategies })),
        );
      }),
      switchMap(({ errors, strategies }) => {
        if (errors.length > 0) {
          return throwError(() => errors);
        }
        const strategy = strategies.get(strategyUpdateParams.strategyId)!;
        let updateNeeded = false;
        const strategyUpdateRequest: UpdateStrategyRequest = {
          accountId,
          marketplace,
          strategyId: strategyUpdateParams.strategyId,
          organizationId: organizationId!,
        };
        for (const key in strategyUpdateParams) {
          // TODO: refactor this
          // prevent update of algo mode and monthly budget in this function
          if (key == 'algoMode' || key == 'monthlyBudget' || key == 'nextMonthlyBudget') {
            continue;
          }
          if (
            strategyUpdateParams[key as keyof StrategyUpdateParams] !== undefined &&
            strategy![key as keyof Strategy] !== strategyUpdateParams[key as keyof StrategyUpdateParams]
          ) {
            strategyUpdateRequest[key as keyof UpdateStrategyRequest] = (strategyUpdateParams[
              key as keyof StrategyUpdateParams
            ] ?? 'null') as never;
            updateNeeded = true;
          }
        }
        if (!updateNeeded) {
          // no change detected
          return of(strategy!);
        }
        return this.strategyApi.updateStrategy(strategyUpdateRequest).pipe(
          catchAjaxError('Error updating Strategy ' + strategy!.name + ': '),
          map((response: Response) => this.parseResponseAndUpdateStrategy(accountId, marketplace, response)),
          // strategy ASIN updates for SD and SP
          mergeMap((s) => {
            if (
              strategy.campaignType != CampaignType.SB &&
              (strategyUpdateParams.asinsToAdd.length > 0 || strategyUpdateParams.asinsToDelete.length > 0)
            ) {
              return this.updateStrategyAsins(s, strategyUpdateParams.asinsToAdd, strategyUpdateParams.asinsToDelete);
            } else {
              return of(s);
            }
          }),
        );
      }),
    );
  }

  public addAsinsToStrategy(strategy: Strategy, asins: string[]): Observable<Strategy> {
    return this.updateStrategyAsins(strategy, asins, []);
  }

  public deleteAsinsFromStrategy(strategy: Strategy, asins: string[]): Observable<Strategy> {
    return this.updateStrategyAsins(strategy, [], asins);
  }

  public moveAsinsToStrategy(
    asins: string[],
    source: Strategy,
    target: Strategy,
  ): Observable<{ source: Strategy; target: Strategy }> {
    return forkJoin([this.addAsinsToStrategy(target, asins), this.deleteAsinsFromStrategy(source, asins)]).pipe(
      map(([target, source]) => ({ source, target })),
    );
  }

  private updateStrategyAsins(strategy: Strategy, toAdd: string[], toDelete: string[]): Observable<Strategy> {
    // asins must be sorted before pushing them to Db
    const allAsins = Array.from(
      new Set(
        strategy
          .asins!.flatMap((x) => x.asin!)
          .concat(toAdd)
          .filter((a) => !toDelete.includes(a)),
      ).values(),
    ).sort();
    if (allAsins.length > ProductGroupEx.MaxProductGroupItems) {
      return throwError(
        () => `Cannot activate a strategy with more than ${ProductGroupEx.MaxProductGroupItems} ASINs.`,
      );
    }
    if (allAsins.length < 1) {
      return throwError(() => 'Cannot delete all ASINs from a strategy');
    }
    return this.strategyApi
      .updateStrategyAsins({
        accountId: strategy.accountId,
        marketplace: strategy.marketplace,
        strategyId: strategy.strategyId!,
        requestBody: allAsins,
      })
      .pipe(
        catchAjaxError('Error updating ASINs of Strategy: '),
        map(() => {
          // create a copy of the Strategy object
          const modifiedStrat = Object.assign({}, strategy);
          modifiedStrat.asins = allAsins.map((a) => ({ asin: a }));
          this.strategyCache.strategyListCache.update(
            { accountId: strategy.accountId, marketplace: strategy.marketplace },
            (strategies) => {
              strategies.set(strategy.strategyId!, modifiedStrat);
              return strategies;
            },
          );
          // TODO: remove this after migration - update config service
          this.configService.updateStrategyAsins(strategy, allAsins);
          return modifiedStrat;
        }),
      );
  }

  private getCampaignTypeStrategyService(campaignType: CampaignType): CampaignTypeStrategiesService {
    switch (campaignType) {
      case CampaignType.SP:
        return this.spStrategiesService;
      case CampaignType.SD:
        return this.sdStrategiesService;
      case CampaignType.SB:
        return this.sbStrategiesService;
      default:
        throw 'Invalid campaign type'; // should never happen
    }
  }

  public addTacticToStrategy(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    segmentId: number,
    tacticType: TacticType,
  ): Observable<StrategyTactic> {
    return this.strategyApi
      .addTactic({
        accountId: accountId,
        marketplace: marketplace,
        strategyId: strategyId,
        segmentId: segmentId,
        tacticType: tacticType,
        intensity: Intensity.NEUTRAL,
        boostPlacementTop: false,
      })
      .pipe(
        catchAjaxError('Error creating tactic: '),
        map((response) => {
          return response.entity as StrategyTactic;
        }),
        tap((strategyTactic) => {
          this.strategyCache.strategyListCache.update({ accountId, marketplace }, (strategies) => {
            const strategy = strategies.get(strategyId)!;
            strategy.tactics.push(strategyTactic);
            return strategies;
          });
          // TODO: update legacy strategy cache
          this.configService.updateStrategyTactic(strategyId, strategyTactic);
        }),
      );
  }

  public removeTacticFromStrategy(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    segmentId: number,
  ): Observable<void> {
    return this.strategyCache.strategyListCache.get({ accountId, marketplace })!.pipe(
      take(1),
      switchMap((strategies) => {
        const strategy = strategies.get(strategyId);
        if (!strategy) {
          return throwError(() => 'Strategy not found');
        }
        const toDelete = strategy.tactics.find((x) => x.segmentId == segmentId);
        if (toDelete == undefined) {
          return throwError(() => 'Tactic not found');
        }
        return this.strategyApi
          .deleteTactic({
            accountId,
            marketplace,
            strategyId,
            segmentId,
          })
          .pipe(
            catchAjaxError('Error deleting tactic: '),
            map(() => strategy),
          );
      }),
      map(() => {
        // TODO: legacy cache reload
        const legacyStrat = this.strategyCache.get(strategyId)!;
        const index = legacyStrat.tactics.findIndex((x) => x.segmentId == segmentId);
        legacyStrat.tactics.splice(index, 1);
        if (legacyStrat.strategyGroupId && legacyStrat.strategyType == StrategyType.PRODUCT) {
          const strategyGroup = this.strategyCache.getStrategyGroupIndex(legacyStrat.strategyGroupId)!;
          strategyGroup.productStrategies = strategyGroup.productStrategies.map((s) =>
            s.strategyId == legacyStrat.strategyId ? new StrategyEx(s, new Map()) : s,
          );
          this.strategyCache.strategyGroupIndexSubjectNext();
        }
        this.strategyCache.buildAccountConfig();

        this.strategyCache.strategyListCache.update({ accountId, marketplace }, (strategies) => {
          const strategy = strategies.get(strategyId);
          if (strategy) {
            const tacticIndex = strategy.tactics.findIndex((t) => t.segmentId == segmentId);
            if (tacticIndex >= 0) {
              strategy.tactics.splice(tacticIndex, 1);
            }
          }
          return strategies;
        });
      }),
    );
  }

  public updateTacticIntensity(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    segmentId: number,
    intensity: Intensity,
  ): Observable<Strategy> {
    return this.strategyApi
      .updateTactic({
        accountId: accountId,
        marketplace: marketplace,
        strategyId: strategyId,
        segmentId: segmentId,
        intensity: intensity,
      })
      .pipe(
        catchAjaxError(),
        switchMap(() => {
          this.strategyCache.strategyListCache.update({ accountId, marketplace }, (strategies) => {
            const strategy = strategies.get(strategyId)!;
            const tactic = strategy.tactics.find((t) => t.segmentId == segmentId);
            if (tactic) {
              tactic.intensity = intensity;
            }
            return strategies;
          });
          return this.strategyCache.strategyListCache.get({ accountId, marketplace });
        }),
        map((strategies) => strategies.get(strategyId)!),
        take(1),
      );
  }

  public updateTacticBoostPlacementTop(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    segmentId: number,
    boostPlacementTop: boolean,
  ): Observable<Strategy> {
    return this.strategyApi
      .updateTactic({
        accountId: accountId,
        marketplace: marketplace,
        strategyId: strategyId,
        segmentId: segmentId,
        boostPlacementTop: boostPlacementTop,
      })
      .pipe(
        catchAjaxError(),
        switchMap(() => {
          this.strategyCache.strategyListCache.update({ accountId, marketplace }, (strategies) => {
            const strategy = strategies.get(strategyId)!;
            const tactic = strategy.tactics.find((t) => t.segmentId == segmentId);
            if (tactic) {
              tactic.boostPlacementTop = boostPlacementTop;
            }
            return strategies;
          });
          return this.strategyCache.strategyListCache.get({ accountId, marketplace });
        }),
        map((strategies) => strategies.get(strategyId)!),
      );
  }

  public switchStrategyAlgoMode(
    accountId: string,
    marketplace: Marketplace,
    strategy: Strategy,
    algoMode: AlgoMode,
    acosTarget: number,
    suggestedBid: number,
    dailyBudget: number,
    monthlyBudget: number,
  ): Observable<Strategy> {
    return this.strategyApi
      .switchStrategyAlgoMode({
        accountId: strategy.accountId,
        marketplace: strategy.marketplace,
        strategyId: strategy.strategyId!,
        algoMode: algoMode,
        acosTarget: acosTarget,
        suggestedBid: suggestedBid,
        dailyBudget: dailyBudget,
        monthlyBudget: monthlyBudget,
      })
      .pipe(
        catchAjaxError('Error updating Algo mode: '),
        map((response: Response) => {
          return this.parseResponseAndUpdateStrategy(accountId, marketplace, response);
        }),
      );
  }

  public updateStrategyMonthlyBudget(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    monthlyBudget: number,
    nextMonthlyBudget: number,
    currentMonth: string,
  ): Observable<Strategy> {
    if (monthlyBudget <= 0) {
      return throwError(() => 'Monthly budget must be greater than 0');
    }
    if (monthlyBudget >= 1_000_000_000) {
      return throwError(() => 'Monthly budget should be lower than 1 billion');
    }
    if (nextMonthlyBudget < 0) {
      return throwError(() => 'Next Monthly budget must be greater than 0');
    }
    if (nextMonthlyBudget >= 1_000_000_000) {
      return throwError(() => 'Next Monthly budget should be lower than 1 billion');
    }
    return this.strategyApi
      .updateStrategyMonthlyBudget({
        accountId,
        marketplace,
        strategyId,
        monthlyBudget: monthlyBudget,
        nextMonthlyBudget: nextMonthlyBudget,
        currentMonth: currentMonth,
      })
      .pipe(
        catchAjaxError('Error updating Monthly budget: '),
        map((response: Response) => {
          return this.parseResponseAndUpdateStrategy(accountId, marketplace, response);
        }),
      );
  }

  private parseResponseAndUpdateStrategy(accountId: string, marketplace: Marketplace, response: Response): Strategy {
    // TODO: update strategy cache until the migration is done
    this.configService.parseResponseAndUpdateStrategy(response);
    const strat = response.entity as Strategy;
    this.strategyCache.strategyListCache.update({ accountId, marketplace }, (strategies) => {
      strategies.set(strat.strategyId!, strat);
      return strategies;
    });
    return strat;
  }

  /**
   *
   * @param strategy Checks when creating a strategy
   */
  public checkStrategyCreation(strategy: Strategy): Observable<string[]> {
    const accountId = strategy.accountId;
    const marketplace = strategy.marketplace;
    return this.strategyCache.strategyListCache.get({ accountId, marketplace }).pipe(
      take(1),
      map((strategyIndex) => {
        const errors: string[] = [];
        errors.push(...this.checkStrategyParams(strategy));
        // check strategy state
        if (strategy.state == StrategyStateEnum.ENABLED) {
          // check if the limit is reached
          if (this.isStrategyLimitReached(accountId, marketplace, strategyIndex, strategy.campaignType)) {
            errors.push(
              'Cannot activate a new strategy as the limit of live strategies of your plan has been reached (please contact us)',
            );
          }
          if (strategy.asins && strategy.asins.length! > ProductGroupEx.MaxProductGroupItems) {
            errors.push(`Cannot activate a strategy with more than ${ProductGroupEx.MaxProductGroupItems} ASINs.`);
          }
        }
        return errors;
      }),
      switchMap((errors) => {
        if (errors.length > 0) {
          return of(errors);
        }
        return this.getCampaignTypeStrategyService(strategy.campaignType).checkStrategyCreation(strategy);
      }),
    );
  }

  /**
   * Checks when updating a strategy
   *
   */
  public checkStrategyUpdate(
    strategyUpdateParams: StrategyUpdateParams,
    strategyIndex: Map<number, Strategy>, // TODO: remove this parameter after migration
  ): Observable<string[]> {
    return this.segmentService.getSegments(strategyUpdateParams.accountId, strategyUpdateParams.marketplace).pipe(
      map((segmentIndex) => {
        const accountId = strategyUpdateParams.accountId;
        const marketplace = strategyUpdateParams.marketplace;
        const strategy = strategyIndex.get(strategyUpdateParams.strategyId);
        if (!strategy) {
          return [`Error updating Strategy: invalid strategyId ${strategyUpdateParams.strategyId}`];
        }
        if (strategyUpdateParams.name && strategy.defaultStrategy) {
          return ['Cannot modify name of "All Other Products" strategy'];
        }

        // check strategy state
        if (strategyUpdateParams.state) {
          if (strategyUpdateParams.state == StrategyStateEnum.ENABLED && strategy.state !== StrategyStateEnum.ENABLED) {
            // check if the limit is reached
            if (this.isStrategyLimitReached(accountId, marketplace, strategyIndex, strategy.campaignType)) {
              return [
                'Cannot activate a new strategy as the limit of live strategies has been reached (please contact us)',
              ];
            }
          }
        }
        // check strategy ASIN modifications
        const updatedStrategy = { ...strategy, ...strategyUpdateParams };
        updatedStrategy.asins = updatedStrategy
          .asins!.concat(strategyUpdateParams.asinsToAdd.map((a) => ({ asin: a })))
          .filter((a) => !strategyUpdateParams.asinsToDelete.includes(a.asin!));
        // check product targeting aka disableProductSegment & disableOtherQueries aka AI-powered targeting
        if (
          updatedStrategy.disableProductSegment &&
          updatedStrategy.disableOtherQueries &&
          updatedStrategy.tactics.length > 0 &&
          updatedStrategy.tactics.every(
            (t) =>
              (segmentIndex.get(t.segmentId!)?.segmentType ?? SegmentConfigType.ProductSegment) ==
              SegmentConfigType.ProductSegment,
          )
        ) {
          return [
            'Not possible to deactivate product targeting when AI-powered targeting is disabled and strategy has only product targeting tactics',
          ];
        }
        return this.checkStrategyParams(updatedStrategy);
      }),
      switchMap((errors) => {
        if (errors.length > 0) {
          return of(errors);
        }
        const strategy = strategyIndex.get(strategyUpdateParams.strategyId)!;
        return this.getCampaignTypeStrategyService(strategy.campaignType).checkStrategyUpdate(strategyUpdateParams);
      }),
    );
  }

  public checkStrategyName(name: string, campaignType: CampaignType): void {
    if (!Constant.nameRegexp.test(name)) {
      throw 'Invalid character used. Strategy Name can only use characters allowed in Amazon campaign names';
    }
    if (
      (campaignType == CampaignType.SB || campaignType == CampaignType.SD) &&
      Constant.invalidSBSDNameRegexp.test(name)
    ) {
      throw "Invalid character used, Strategy Name can only use characters allowed in Amazon campaign names. Characters: '!%#<>.' are not allowed for SD and SB.";
    }
  }

  private checkStrategyParams(strategy: Strategy): string[] {
    const accountId = strategy.accountId;
    const marketplace = strategy.marketplace;
    const errors: string[] = [];
    // check strategy ASINs
    if (
      (strategy.asins == undefined || strategy.asins.length == 0) &&
      !strategy.defaultStrategy &&
      (strategy.campaignType === CampaignType.SP || strategy.campaignType === CampaignType.SD)
    ) {
      errors.push('Strategy should have a list of ASINs');
    }
    // check strategy state
    if (!strategy.state) {
      errors.push('Invalid strategy status');
    }
    // check strategy name
    try {
      this.checkStrategyName(strategy.name!, strategy.campaignType);
    } catch (error) {
      errors.push(error as string);
    }
    // check strategy label
    if (strategy.strategyLabel) {
      if (!Constant.nameRegexp.test(strategy.strategyLabel)) {
        errors.push('Invalid character used, Strategy Label can only use characters allowed in Amazon campaign names');
      }
      if (
        (strategy.campaignType == CampaignType.SB || strategy.campaignType == CampaignType.SD) &&
        Constant.invalidSBSDNameRegexp.test(strategy.strategyLabel)
      ) {
        errors.push(
          "Invalid character used, Strategy Label can only use characters allowed in Amazon campaign names. Characters: '!%#<>.' are not allowed for SD and SB.",
        );
      }
    }
    if (!strategy.algoMode || !AlgoMode[strategy.algoMode]) {
      errors.push('Invalid algorithm');
    }
    // check ACOS target
    if (strategy.algoMode === AlgoMode.ACOS_TARGET && strategy.acosTarget == undefined) {
      errors.push('ACOS target must be specified for ACOS target algorithm');
    }
    if (
      (strategy.algoMode === AlgoMode.PRODUCT_LAUNCH && strategy.acosTarget !== undefined) ||
      (strategy.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET && strategy.acosTarget !== undefined)
    ) {
      errors.push('Impossible to set ACOS target for ' + StrategyEx.getAlgoModeStrShort(strategy.algoMode));
    }
    if (strategy.algoMode === AlgoMode.ACOS_TARGET && (strategy.acosTarget! < 0.01 || strategy.acosTarget! > 2)) {
      errors.push('Invalid ACOS target, must be between 1% and 200%');
    }
    // check Suggested Bid
    if (
      strategy.algoMode === AlgoMode.PRODUCT_LAUNCH &&
      (strategy.suggestedBid == undefined || strategy.dailyBudget == undefined)
    ) {
      errors.push('A daily budget and a suggested bid should be defined for Product Launch');
    }
    if (strategy.algoMode !== AlgoMode.PRODUCT_LAUNCH && strategy.suggestedBid != undefined) {
      errors.push('Not possible to set a suggested bid for ' + StrategyEx.getAlgoModeStrShort(strategy.algoMode));
    }
    if (
      strategy.algoMode === AlgoMode.PRODUCT_LAUNCH &&
      (strategy.suggestedBid! <= 0 ||
        strategy.dailyBudget! < strategy.suggestedBid! ||
        5 * strategy.suggestedBid! > strategy.dailyBudget! ||
        strategy.dailyBudget! > 1_000_000_000)
    ) {
      errors.push(
        'Invalid suggested bid or daily budget, must satisfy 0 < suggested bid <= daily budget, also suggested bid should not exceed 20% of the Daily Budget',
      );
    }
    // check Monthly budget
    if (strategy.monthlyBudget && strategy.algoMode !== AlgoMode.MONTHLY_BUDGET_TARGET) {
      errors.push('Monthtly budget cannot be defined for ' + AlgoModeStr[strategy.algoMode].description);
    }
    if (
      strategy.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET &&
      (strategy.monthlyBudget == undefined || strategy.monthlyBudget <= 0)
    ) {
      errors.push('Monthly budget must be greater than 0');
    }
    if (strategy.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET && strategy.monthlyBudget! > 1_000_000_000) {
      errors.push('Monthly budget should be lower than 1 billion');
    }
    // check daily budget
    if (strategy.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET && strategy.dailyBudget != undefined) {
      errors.push('Impossible to set a daily budget for Monthly budget algorithm');
    }
    if (
      strategy.algoMode === AlgoMode.ACOS_TARGET &&
      strategy.dailyBudget != undefined &&
      strategy.minDailySpend != undefined
    ) {
      if (strategy.dailyBudget < 2 * strategy.minDailySpend) {
        errors.push('Average Daily Budget must be at least 2 times higher than Min Daily Spend');
      } else if (strategy.dailyBudget > 1_000_000_000) {
        errors.push('Average Daily Budget must be less than 1 billion');
      }
    }

    // check min daily spend
    if (strategy.algoMode === AlgoMode.PRODUCT_LAUNCH && strategy.minDailySpend) {
      errors.push('Min daily spend cannot be defined for Force product visibility');
    }
    const currency = marketplaceCurrency(marketplace);
    const minDailyBudgetLimit = Math.round(
      (this.accountMarketplaceConfigs.get(accountMarketplaceKey(accountId, marketplace))?.minDailyBudgetLimit ?? 100) /
        currencyRateToEuro(currency),
    );
    if (strategy.minDailySpend! > minDailyBudgetLimit) {
      const currencySymbol = Currencies[currency].currencySymbol;
      errors.push(`Min daily Spend must be lower than ${formatCurrency(minDailyBudgetLimit, 'en', currencySymbol)}`);
    }
    if (strategy.minDailySpend! < 0) {
      errors.push('Min daily spend should be greater than 0');
    }

    // check disableOtherQueries aka AI-powered targeting
    if (
      strategy.campaignType === CampaignType.SD &&
      strategy.disableOtherQueries &&
      strategy.tactics.length == 0 &&
      strategy.audienceTargetings.length == 0
    ) {
      errors.push('Not possible to disable AI-powered targeting targeting when the strategy has no targetings');
    } else if (
      strategy.campaignType !== CampaignType.SD &&
      strategy.disableOtherQueries &&
      strategy.tactics.length == 0
    ) {
      errors.push('Not possible to disable AI-powered targeting when the strategy has no tactic');
    }
    // check boost
    if (strategy.primeDayBoost && strategy.algoMode !== AlgoMode.ACOS_TARGET) {
      errors.push('Strategy boost can only be activated on strategy with target ACOS');
    }
    if (strategy.dailyBudget! > 0 && strategy.primeDayBoost) {
      errors.push('Cannot add a target daily budget on a strategy with promo boost');
    }
    // check dayparting inputs
    if (strategy.daypartingPauseHour != null && strategy.daypartingPauseHour == strategy.daypartingReactivationHour) {
      errors.push('Dayparting pause and reactivation hours must be different');
    }

    // check strategy type / strategy group
    if (strategy.strategyGroupId !== undefined) {
      if (strategy.campaignType != CampaignType.SP) {
        errors.push('Strategy group can only be set for SP');
      }
    }
    if (strategy.strategyGroupId && (!strategy.strategyType || strategy.strategyType == StrategyType.LEGACY)) {
      errors.push('When attached to a strategy group, a strategy type has to be defined');
    }
    return errors;
  }

  public isStrategyLimitReached(
    accountId: string,
    marketplace: Marketplace,
    strategyIndex: Map<number, Strategy>,
    campaignType: CampaignType,
  ): boolean {
    let liveStrategiesLimit =
      this.accountMarketplaceConfigs.get(accountMarketplaceKey(accountId, marketplace))?.liveStrategiesLimit ?? 0;
    if (campaignType == CampaignType.SB && liveStrategiesLimit > Constant.maxSbStrategies) {
      liveStrategiesLimit = Constant.maxSbStrategies;
    }
    switch (liveStrategiesLimit) {
      case 0:
        return true;
      case +Infinity:
        return false;
      default:
        return (
          Array.from(strategyIndex.values()).filter(
            (s) => s.campaignType == campaignType && s.state == StrategyStateEnum.ENABLED,
          ).length >= liveStrategiesLimit
        );
    }
  }

  /* Loaders */

  private getAllStrategyCurrentMonthSpend(
    accountId: string,
    marketplace: Marketplace,
  ): Observable<Map<StrategyId, Spend>> {
    const now = Utils.getNow(marketplace);
    const m = moment(now.format('YYYY-MM-DD'));
    return this.statsApi
      .getStrategySpend({
        accountId: accountId,
        marketplace: marketplace,
        minDate: Utils.formatDateForApi(m.startOf('month').toDate()),
        maxDate: Utils.formatDateForApi(m.endOf('month').toDate()),
      })
      .pipe(
        map((stats) => {
          const result = new Map<StrategyId, Spend>();
          for (const stat of stats) {
            result.set(stat.strategyId!, stat.cost ?? 0);
          }
          return result;
        }),
      );
  }
}
