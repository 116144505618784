import { AsyncPipe } from "@angular/common";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatTooltip } from "@angular/material/tooltip";
import { ActivatedRouteSnapshot, ActivationEnd, Router, RouterLink, RouterOutlet } from "@angular/router";
import { AccessLevel, AccountMarketplace, AccountSubType, AccountType, Currency, User } from "@front/m19-api-client";
import {
  AccountSelectionService,
  AuthService,
  CurrencyService,
  NotificationService,
  OrganizationAccountGroupService,
  UserSelectionService,
} from "@front/m19-services";
import { IButtonComponent } from "@front/m19-ui";
import { TranslocoDirective } from "@jsverse/transloco";
import { AccountSelectorComponent } from "@m19-board/account-selector/account-selector.component";
import { MultiAccountSelectorComponent } from "@m19-board/account-selector/multi-account-selector.component";
import { SearchTrendsMarketplaceSelectorComponent } from "@m19-board/account-selector/search-trends-marketplace-selector.component";
import { Selector } from "@m19-board/app.routes";
import { CurrencySelectionComponent } from "@m19-board/currency-selector/currency-selection.component";
import { DateRangeSelectorComponent } from "@m19-board/date-range-selector/date-range-selector.component";
import { Hotkeys } from "@m19-board/services/hotkeys.service";
import { ObfuscationService } from "@m19-board/services/obfuscation.service";
import { AccountSettingsService } from "@m19-board/settings/account-settings.service";
import { LogoComponent } from "@m19-board/shared/logo/logo.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { combineLatest, Observable } from "rxjs";
import { delay, filter, switchMap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { BoardSidebarV2Component } from "./board-sidebar-v2/board-sidebar-v2.component";
import { DebugInfoModalComponent } from "./debug-info-modal.component";

@UntilDestroy()
@Component({
  templateUrl: "./board-layout.component.html",
  encapsulation: ViewEncapsulation.None,
  imports: [
    TranslocoDirective,
    AsyncPipe,
    RouterLink,
    AccountSelectorComponent,
    MatTooltip,
    IButtonComponent,
    BoardSidebarV2Component,
    RouterOutlet,
    CurrencySelectionComponent,
    SearchTrendsMarketplaceSelectorComponent,
    MultiAccountSelectorComponent,
    DateRangeSelectorComponent,
    LogoComponent,
  ],
  standalone: true,
})
export class BoardLayoutComponent implements OnInit {
  sideBarOpened = false;
  isTest = !environment.production;

  readonly releaseNotesUrl = environment.releaseNotesUrl;
  readonly Selector = Selector;
  readonly whitelabelContactUsLink?: string;

  user!: User;
  currency!: Currency;
  locale!: string;
  betaTest = false;
  invalidAccountMarketplaces: AccountMarketplace[] = [];
  pendingValidationAccountMarketplaces: AccountMarketplace[] = [];

  globalWarning$: Observable<string>;
  allAccountMarketplaces: AccountMarketplace[] = [];
  accountMarketplaces: AccountMarketplace[] | undefined;
  selectedDateRage!: string[];
  debugInfoModalVisible = false;
  displayedSelector = Selector.None;

  isSidebarCollapsed = this.userSelectionService.sidebarCollapsed;

  constructor(
    private authService: AuthService,
    private accountGroupService: OrganizationAccountGroupService,
    private userSelectionService: UserSelectionService,
    public currencyService: CurrencyService,
    private router: Router,
    private notificationService: NotificationService,
    private accountSelectionService: AccountSelectionService,
    private accountSettingsService: AccountSettingsService,
    private modalService: BsModalService,
    private hotkeys: Hotkeys,
    private obfuscationService: ObfuscationService,
  ) {
    this.globalWarning$ = this.notificationService.globalWarning$;
    this.hotkeys
      .addShortcut({ altKey: "KeyD" })
      .pipe(untilDestroyed(this))
      .subscribe(() => this.printDebugInfo());
    this.hotkeys
      .addShortcut({ altKey: "KeyO" })
      .pipe(untilDestroyed(this))
      .subscribe(() => this.obfuscationService.toggleObfuscationMode(!this.obfuscationService.obfuscation.value));

    // react on route change to change selector display
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof ActivationEnd) {
        if (event.snapshot.data?.["selector"]) {
          this.displayedSelector = event.snapshot.data["selector"];
        }
      }
    });
    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((x) => (this.currency = x));

    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user;
      this.locale = user.locale;
      this.betaTest = user.betaTester ?? false;
    });
    this.userSelectionService.dateRange$.pipe(untilDestroyed(this)).subscribe((dr) => {
      this.selectedDateRage = dr;
    });
  }

  ngOnInit(): void {
    // init account selector based on router state
    let routerState: ActivatedRouteSnapshot[] = [this.router.routerState.snapshot.root];
    while (routerState.length > 0) {
      const state = routerState.pop();
      if (state && state.data?.["selector"]) {
        this.displayedSelector = state.data?.["selector"];
        break;
      }
      if (state && state.children) {
        routerState = [...routerState, ...state.children];
      }
    }

    this.accountGroupService.allOrganizationAccountGroups$
      .pipe(
        untilDestroyed(this),
        filter((organizations) => organizations != undefined),
      )
      .subscribe((organizations) => {
        this.allAccountMarketplaces =
          organizations?.flatMap((o) => o.accountGroups.flatMap((ag) => ag.resources)) ?? [];
      });

    this.accountGroupService.allOrganizationAccountGroups$
      .pipe(
        untilDestroyed(this),
        filter((organizations) => organizations != undefined),
        switchMap(() =>
          combineLatest([
            this.notificationService.getNotifications$.pipe(filter((n) => n != undefined)),
            this.accountSelectionService.accountGroupSelection$.pipe(filter((a) => a != undefined)),
            this.accountSelectionService.accountMarketplacesSelection$,
          ]).pipe(
            delay(10), // delay subscription so as to occur after view check
          ),
        ),
      )
      .subscribe(([notifications, accountGroup, accountMarketplaces]) => {
        this.invalidAccountMarketplaces = accountGroup
          .getAccountMarketplaces()
          .filter((am) => am.accessLevel != AccessLevel.READ && !am.isValidToken);
        this.pendingValidationAccountMarketplaces = accountGroup
          .getAccountMarketplaces()
          .filter(
            (am) =>
              am.accessLevel != AccessLevel.READ &&
              am.accountType == AccountType.VENDOR &&
              am.isValidToken &&
              !am.approvedBy &&
              (am.nbAsinsToValidate ?? 0) > 0,
          );
        this.accountMarketplaces = accountMarketplaces;
      });
  }

  hasInvalidToken() {
    return this.invalidAccountMarketplaces.length > 0;
  }

  logInPlaceForAPIGrantAccess() {
    return this.invalidAccountMarketplaces[0].accountType == AccountType.SELLER ? "Seller Central" : "Vendor Central";
  }

  hasPendingVendorIntegration() {
    return this.pendingValidationAccountMarketplaces.length > 0;
  }

  requestGrant() {
    if (this.invalidAccountMarketplaces.length == 1) {
      const am = this.invalidAccountMarketplaces[0];
      this.accountSettingsService.grantSellingPartnerAccess(
        am.accountId,
        am.marketplace,
        am.accountType!,
        am.accountSubType === AccountSubType.KDP_AUTHOR,
      );
    } else {
      // TODO: expand account groups with invalid access
      this.router.navigate(["accounts"]);
    }
  }

  validateAccountIntegration() {
    // display popup for first account requiring validation
    if (this.pendingValidationAccountMarketplaces.length == 0) {
      return;
    }
    this.accountSettingsService.validateIntegration(this.pendingValidationAccountMarketplaces[0]);
  }

  isAdminLogin(): boolean {
    return this.authService.isAdminLogin();
  }

  printDebugInfo() {
    // prevent modal to open again if already shown
    if (this.debugInfoModalVisible) {
      return;
    }
    this.debugInfoModalVisible = true;
    const modalOptions: ModalOptions = {
      initialState: {
        user: this.user,
        accountMarketplaces: this.accountMarketplaces,
        dateRange: this.selectedDateRage,
      },
      class: "modal-lg",
    };
    const modalRef = this.modalService.show(DebugInfoModalComponent, modalOptions);
    modalRef.onHidden!.subscribe(() => {
      this.debugInfoModalVisible = false;
    });
  }
}
