import { inject, Injectable, NgModule } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterModule,
  RouterStateSnapshot,
  Routes,
  TitleStrategy,
} from "@angular/router";
import { ActivitiesComponent } from "./activities/activities.component";
import { SearchTermRanksComponent } from "./brand-analytics/search-term-ranks/search-term-ranks.component";
import { CatalogUploadComponent } from "./catalog/catalog-upload.component";
import { Title } from "@angular/platform-browser";
import { AuthService } from "@front/m19-services";
import { AdminLoginComponent } from "@m19-board/auth/admin-login/admin-login.component";
import { AuthLayoutComponent } from "@m19-board/auth/auth-layout.component";
import { LoginComponent } from "@m19-board/auth/login/login.component";
import { PasswordResetComponent } from "@m19-board/auth/password-reset/password-reset.component";
import { RegisterComponent } from "@m19-board/auth/register/register.component";
import { RequestPasswordResetComponent } from "@m19-board/auth/request-password-reset/request-password-reset.component";
import { TermsOfUseComponent } from "@m19-board/auth/terms-of-use/terms-of-use.component";
import { of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { AgencyBoardComponent } from "./agency-board/agency-board.component";
import { BrandAliasComponent } from "./brand/brand-alias.component";
import { CatalogPageComponent } from "./catalog/catalog-page.component";
import { Dashboard360LayoutComponent } from "./containers/dashboard360-layout/dashboard360-layout.component";
import { V2LayoutComponent } from "./containers/board-layout/board-layout.component";
import { DspStatsComponent } from "./dsp/dsp-stats.component";
import { HourlyPageComponent } from "./insights/hourly-view/hourly-page/hourly-page.component";
import { AdvertisingStatsComponent } from "./insights/advertising-stats/advertising-stats.component";
import { InventoryComponent } from "./inventory/inventory.component";
import { KeywordTrackerDetailsComponent } from "./keyword-tracker/keyword-tracker-details/keyword-tracker-details.component";
import { KeywordTrackerPageComponent } from "./keyword-tracker/keyword-tracker-page.component";
import { BoardType, LayoutSelectorService } from "./layout-selector.service";
import { NotificationsCenterComponent } from "./notifications-center/notifications-center.component";
import { StatsOverlayWrapperComponent } from "./overlay/stats-overlay-wrapper.component";
import { ProductTrackerTimelineComponent } from "./product-tracker/product-tracker-views/product-tracker-timeline/product-tracker-timeline.component";
import { ProductTrackerTrackedKeywordsComponent } from "./product-tracker/product-tracker-views/product-tracker-tracked-keywords/product-tracker-tracked-keywords.component";
import { ProductTrackerComponent } from "./product-tracker/product-tracker-views/product-tracker.component";
import { ProfitAndLossComponent } from "./profit-and-loss/profit-and-loss.component";
import { ProductAnalysisPageComponent } from "./product-analysis/product-analysis-page.component";
import { SegmentsComponent } from "./segments/segment-manager.component";
import { AccountSettingComponent } from "./settings/account-settings/account-setting.component";
import { BillingRegisterComponent } from "./settings/billing-settings/billing-registration/billing-registration.component";
import { BillingSettingsComponent } from "./settings/billing-settings/billing-settings.component";
import { SelfServiceFreemiumComponent } from "./settings/billing-settings/selfservice-freemium/selfservice-freemium.component";
import { DataSharingComponent } from "./settings/data-sharing/data-sharing.component";
import { ProfileSettingsComponent } from "./settings/profile-settings/profile-settings.component";
import { UserManagementComponent } from "./settings/user-management/user-management.component";
import { ShareOfVoiceComponent } from "./share-of-voice/share-of-voice.component";
import { SbManagerComponent } from "./sponsored-brand/sb-manager/sb-manager.component";
import { SbPageComponent } from "./sponsored-brand/sb-page/sb-page.component";
import { SdManagerComponent } from "./sponsored-display/sd-manager/sd-manager.component";
import { SdStrategyCreationPageComponent } from "./sponsored-display/sd-manager/sd-strategy-creation/sd-strategy-creation-page.component";
import { SdPageComponent } from "./sponsored-display/sd-page/sd-page.component";
import { SpStrategyGroupCreationPageComponent } from "./sponsored-product/sp-creation/sp-strategy-group-creation-page.component";
import { SpManagerComponent } from "./sponsored-product/sp-manager/sp-manager.component";
import { SpPageComponent } from "./sponsored-product/sp-page/sp-page.component";
import { SpStrategyGroupPageComponent } from "./sponsored-product/sp-page/sp-strategy-group-page.component";
import { SpSubStrategyCreationPageComponent } from "./sponsored-product/sp-page/sp-substrategy-creation/sp-substrategy-creation-page.component";
import { SpSubStrategyPageComponent } from "./sponsored-product/sp-page/sp-substrategy-page.component";
import { ProductGroupPageComponent } from "./strategies/product-group/product-group-page/product-group-page.component";
import { ProductGroupComponent } from "./strategies/product-group/product-group.component";
import { SbStrategyFormComponent } from "./strategies/sb-strategy-form/sb-strategy-form.component";
import { StrategyBoostComponent } from "./strategies/strategy-boost/strategy-boost.component";
import { CreateTacosStrategyComponent } from "./strategies/tacos-strategy/create-tacos-strategy.component";
import { TacosStrategyPageComponent } from "./strategies/tacos-strategy/tacos-strategy-page.component";
import { TacosManagerComponent } from "./tacos/tacos-manager.component";
import { TrafficStatsComponent } from "./traffic-stats/traffic-stats.component";
import { AmcStatsComponent } from "@m19-board/amc/amc-stats.component";

export const whiteLabelGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const layoutService = inject(LayoutSelectorService);
  return layoutService.getBoardType() === BoardType.M19;
};

export const onlyWhiteLabelSelfServiceGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const layoutService = inject(LayoutSelectorService);
  return layoutService.getBoardType() === BoardType.WHITELABEL_SELFSERVICE;
};

export const pageGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  return authService
    .isLoggedIn()
    .pipe(map((x) => (x ? true : router.createUrlTree(["login"], { queryParams: { redirect: state.url } }))));
};

export const termsOfUseGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.isLoggedIn().pipe(
    switchMap((loggedIn) => {
      if (loggedIn) {
        return authService.loggedUser$;
      }
      return of(null);
    }),
    map((user) => {
      if (user && !user.registered) return router.createUrlTree(["terms-of-use"]);
      return true;
    }),
  );
};

export enum Selector {
  None = "None",
  MultiAccountSelector = "MultiAccountSelector",
  AccountSelector = "AccountSelector",
  MarketplaceSelector = "MarketplaceSelector",
}

export const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "login",
        component: LoginComponent,
        title: "Login",
      },
      {
        path: "register",
        component: RegisterComponent,
        title: "Register",
      },
      {
        path: "password-reset",
        component: PasswordResetComponent,
        title: "Reset Password",
      },
      {
        path: "request-password-reset",
        component: RequestPasswordResetComponent,
        title: "Request Password Reset",
      },
      {
        path: "terms-of-use",
        component: TermsOfUseComponent,
        title: "Terms & Conditions",
      },
    ],
  },
  {
    path: "admin-login",
    component: AdminLoginComponent, // internal use only
    title: "Admin Login",
  },
  {
    path: "products",
    redirectTo: "products/sales-advertising",
    pathMatch: "full",
  },
  {
    path: "strategies",
    redirectTo: "strategies/sponsored-product",
    pathMatch: "full",
  },
  {
    path: "dashboard360",
    component: Dashboard360LayoutComponent,
    canActivate: [
      pageGuard, // redirect to login if not authenticated
    ],
    children: [
      {
        path: "",
        redirectTo: "account360",
        pathMatch: "full",
      },
      {
        path: "account360",
        loadChildren: () => import("./account360/account360.routes").then((r) => r.A360Routes),
        title: "Account 360",
      },
      {
        path: "product360",
        loadChildren: () => import("./product360/product360.routes").then((r) => r.P360Routes),
        title: "Product 360",
      },
      {
        path: "profile",
        component: ProfileSettingsComponent,
        title: "My Profile",
      },
    ],
  },
  {
    path: "",
    component: V2LayoutComponent,
    canActivate: [
      pageGuard, // redirect to login if not authenticated
      termsOfUseGuard,
    ],
    children: [
      {
        path: "dashboard",
        children: [
          {
            path: "home",
            component: AgencyBoardComponent,
            title: "Home",
            data: {
              selector: Selector.MultiAccountSelector,
            },
          },
          {
            path: "account360",
            component: StatsOverlayWrapperComponent,
            loadChildren: () => import("./account360/account360.routes").then((r) => r.A360Routes),
            title: "Account 360",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          {
            path: "advertising",
            component: AdvertisingStatsComponent,
            title: "Advertising",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          {
            path: "profit-and-loss",
            component: ProfitAndLossComponent,
            title: "Profit & Loss",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          { path: "**", redirectTo: "home" },
        ],
      },
      {
        path: "advertising",
        children: [
          {
            path: "tacos-strategies",
            component: TacosManagerComponent,
            title: "Tacos Strategies",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          {
            path: "tacos-strategies/create",
            component: CreateTacosStrategyComponent,
            title: "Create Tacos Strategy",
            data: {
              selector: Selector.None,
            },
          },
          {
            path: "tacos-strategies/:id",
            component: TacosStrategyPageComponent,
            title: "Tacos Strategy",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          {
            path: "sponsored-product",
            component: SpManagerComponent,
            title: "SP Strategies",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          {
            path: "sponsored-product/create",
            component: SpStrategyGroupCreationPageComponent,
            title: "New SP Strategy Group",
            data: {
              selector: Selector.None,
            },
          },
          // new sponsored product strategy group
          {
            path: "sponsored-product/strategy-group/:id",
            component: SpStrategyGroupPageComponent,
            title: "SP Strategy Group",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          {
            path: "sponsored-product/strategy-group/:id/create-strategy",
            component: SpSubStrategyCreationPageComponent,
            title: "New SP Strategy in Strategy Group",
            data: {
              selector: Selector.None,
            },
          },
          {
            path: "sponsored-product/strategy-group/:id/:subid",
            component: SpSubStrategyPageComponent,
            title: "SP Strategy in Strategy Group",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          // legacy sponsored product strategy
          {
            path: "sponsored-product/:id",
            component: SpPageComponent,
            title: "SP Strategy",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          {
            path: "sponsored-brands",
            component: SbManagerComponent,
            title: "SB Strategies",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          {
            path: "sponsored-brands/create",
            component: SbStrategyFormComponent,
            title: "New SB Strategy",
            data: {
              selector: Selector.None,
            },
          },
          {
            path: "sponsored-brands/:id",
            component: SbPageComponent,
            title: "SB Strategy",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          {
            path: "sponsored-display",
            component: SdManagerComponent,
            title: "SD Strategies",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          {
            path: "sponsored-display/create",
            component: SdStrategyCreationPageComponent,
            title: "New SD Strategy",
            data: {
              selector: Selector.None,
            },
          },
          {
            path: "sponsored-display/:id",
            component: SdPageComponent,
            title: "SD Strategy",
            data: {
              selector: Selector.AccountSelector,
            },
          },

          {
            path: "hourly-stats",
            component: HourlyPageComponent,
            title: "Hourly Stats",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          {
            path: "promo-days-boost",
            component: StrategyBoostComponent,
            title: "Promo Days Boost",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          {
            path: "dsp",
            component: DspStatsComponent,
            title: "DSP Stats",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          {
            path: "amc",
            component: AmcStatsComponent,
            title: "AMC Stats",
            data: {
              selector: Selector.AccountSelector,
            },
          },
        ],
      },
      {
        path: "product-center",
        data: {
          selector: Selector.AccountSelector,
        },
        children: [
          {
            path: "product360",
            component: StatsOverlayWrapperComponent,
            loadChildren: () => import("./product360/product360.routes").then((r) => r.P360Routes),
            title: "Product 360",
          },
          {
            path: "product-analysis",
            component: ProductAnalysisPageComponent,
            title: "Product Analysis",
          },
          {
            path: "product-tracker",
            component: ProductTrackerComponent,
            title: "Product Tracker",
          },
          {
            path: "product-tracker/:product",
            component: ProductTrackerTrackedKeywordsComponent,
            title: "Product Tracker Details",
          },
          {
            path: "product-tracker/product-timeline/:product",
            component: ProductTrackerTimelineComponent,
            title: "Product Timleine",
          },
          {
            path: "inventory",
            component: InventoryComponent,
            title: "Inventory",
          },
          {
            path: "catalog",
            component: CatalogPageComponent,
            title: "Catalog",
          },
          {
            path: "catalog/upload",
            component: CatalogUploadComponent,
            title: "Catalog Upload",
          },
          {
            path: "product-group",
            component: ProductGroupComponent,
            title: "Product Groups",
          },
          {
            path: "product-group/:id",
            component: ProductGroupPageComponent,
            title: "Product Group",
          },
          {
            path: "brand-alias",
            component: BrandAliasComponent,
            title: "Brand Alias",
          },
        ],
      },
      {
        path: "keyword-center",
        children: [
          {
            path: "traffic-analysis",
            component: TrafficStatsComponent,
            title: "Traffic Analysis",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          {
            path: "segments",
            redirectTo: "segments/keyword-segments",
            pathMatch: "full",
            title: "Keyword Segments",
          },
          {
            path: "segments/keyword-segments",
            component: SegmentsComponent,
            title: "Keyword Segments",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          {
            path: "segments/product-segments",
            component: SegmentsComponent,
            title: "Product Segments",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          {
            path: "share-of-voice",
            component: ShareOfVoiceComponent,
            title: "Share of Voice",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          {
            path: "keyword-tracker",
            component: KeywordTrackerPageComponent,
            title: "Keyword Tracker",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          {
            path: "keyword-tracker/:searchterm",
            component: KeywordTrackerDetailsComponent,
            title: "Keyword Tracker",
            data: {
              selector: Selector.AccountSelector,
            },
          },
          {
            path: "search-trends",
            component: SearchTermRanksComponent,
            title: "Search Trends",
            data: {
              selector: Selector.MarketplaceSelector,
            },
          },
        ],
      },
      {
        path: "activities",
        component: ActivitiesComponent,
        title: "Activities",
        data: {
          selector: Selector.AccountSelector,
        },
      },

      {
        path: "profile",
        component: ProfileSettingsComponent,
        title: "My Profile",
        data: {
          selector: Selector.None,
        },
      },
      {
        path: "accounts",
        component: AccountSettingComponent,
        title: "Account Settings",
        data: {
          selector: Selector.None,
        },
      },
      {
        path: "user-management",
        component: UserManagementComponent,
        title: "User Management",
        data: {
          selector: Selector.None,
        },
      },
      {
        path: "billing",
        component: BillingSettingsComponent,
        title: "Billing",
        data: {
          selector: Selector.None,
        },
      },
      {
        path: "billing-registration",
        component: BillingRegisterComponent,
        canActivate: [whiteLabelGuard],
        title: "Billing Registration",
        data: {
          selector: Selector.None,
        },
      },
      {
        path: "freemium",
        component: SelfServiceFreemiumComponent,
        canActivate: [onlyWhiteLabelSelfServiceGuard], // redirect to login if not authenticated
        title: "Freemium",
        data: {
          selector: Selector.None,
        },
      },
      {
        path: "notifications-center",
        component: NotificationsCenterComponent,
        title: "Notifications Center",
        data: {
          selector: Selector.AccountSelector,
        },
      },
      {
        path: "datahub",
        component: DataSharingComponent,
        title: "Data Hub",
        data: {
          selector: Selector.None,
        },
      },
    ],
  },
  { path: "**", redirectTo: "dashboard" },
];

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {
  constructor(
    private readonly title: Title,
    private readonly layoutSelectorService: LayoutSelectorService,
  ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    const pageTitle = this.layoutSelectorService.getPageTitle();
    if (title !== undefined) {
      this.title.setTitle(`${pageTitle} - ${title}`);
    } else {
      this.title.setTitle(pageTitle);
    }
  }
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy,
    },
  ],
})
export class AppRoutingModule {}
