import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { AuthService } from '.';
import { AccessLevel, AccountMarketplace } from '@front/m19-api-client';
import { AccountGroup } from '@front/m19-models/AccountGroup';
import { Utils } from '@front/m19-utils';

@Injectable({
  providedIn: 'root',
})
export class AccountSelectionService {
  private accountGroupSelection = new BehaviorSubject<AccountGroup | undefined>(undefined);
  public accountGroupSelection$: Observable<AccountGroup> = this.accountGroupSelection.asObservable().pipe(
    filter((a): a is AccountGroup => !!a),
    distinctUntilChanged(),
  );
  public noAccountGroupSelected$: Observable<void> = this.accountGroupSelection.pipe(
    filter((a) => !a),
    map(() => void 0),
  );

  private accountMarketplacesSelection = new BehaviorSubject<AccountMarketplace[]>([]);
  public accountMarketplacesSelection$: Observable<AccountMarketplace[]> = this.accountMarketplacesSelection
    .asObservable()
    .pipe(
      filter((am) => am.length > 0),
      distinctUntilChanged(),
    );
  private readOnlyMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readOnlyMode$: Observable<boolean> = this.readOnlyMode.asObservable().pipe(distinctUntilChanged());

  public singleAccountMarketplaceSelection$: Observable<AccountMarketplace> = this.accountMarketplacesSelection
    .asObservable()
    .pipe(
      filter((am) => am && am.length == 1),

      map((am) => am[0]),
      distinctUntilChanged(
        (am, prevAm) =>
          am.accountId === prevAm.accountId &&
          am.marketplace === prevAm.marketplace &&
          am.resourceOrganizationId === prevAm.resourceOrganizationId,
      ),
      shareReplay(1),
    );

  public promoBoostActivated$ = this.singleAccountMarketplaceSelection$.pipe(
    map((am: AccountMarketplace) => {
      if (!am.promoStartDate && !am.promoEndDate) {
        return false;
      }
      const today = Utils.getNow(am.marketplace).startOf('day');
      const promoStart = Utils.toMoment(am.promoStartDate!, am.marketplace);
      const promoEnd = Utils.toMoment(am.promoEndDate!, am.marketplace);
      return today.isSameOrAfter(promoStart) && today.isSameOrBefore(promoEnd);
    }),
  );

  private singleMarketplaceMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public singleMarketplaceMode$: Observable<boolean> = this.singleMarketplaceMode
    .asObservable()
    .pipe(distinctUntilChanged());

  constructor(authService: AuthService) {
    // remove selection when user logs out
    authService.isLogged$.subscribe((logged) => {
      if (!logged) {
        this.accountGroupSelection.next(undefined);
        this.accountMarketplacesSelection.next([]);
      }
    });
  }

  public updateSingleMarketplaceMode(singleMode: boolean) {
    if (this.singleMarketplaceMode.getValue() != singleMode) {
      this.singleMarketplaceMode.next(singleMode);
    }
  }

  public updateAccountMarketplace(selection: AccountMarketplace[]) {
    this.accountMarketplacesSelection.next(selection);
    if (
      selection.length === 1 &&
      (selection[0].accessLevel === AccessLevel.READ || selection[0].accessLevel === AccessLevel.STATS_ONLY)
    )
      this.readOnlyMode.next(true);
    else {
      this.readOnlyMode.next(false);
    }
  }

  public updateAccountGroup(selection: AccountGroup) {
    this.accountGroupSelection.next(selection);
  }
}
