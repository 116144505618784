<ng-container *transloco="let t">
  @if (!loading() && strategyGroup) {
    <div class="container mx-auto h-full overflow-auto rounded-lg bg-white px-10 py-6">
      <div class="flex flex-col gap-2">
        <a
          [routerLink]="['/advertising/tacos-strategies']"
          queryParamsHandling="merge"
          class="inline-flex items-center gap-2 text-sm text-gray-500 underline-offset-4 hover:text-gray-700"
        >
          <span [class]="ICON_CHEVRON_D + ' h-4 w-4 rotate-90'"></span>
          TACOS</a
        >
        <div class="flex items-end gap-4">
          @if (spStrategy) {
            <span (click)="openEditNameModal()" class="text-2xl font-medium text-gray-800">{{ spStrategy.name }}</span>
            <IButton
              color="white"
              icon="icon-[mdi--pencil]"
              size="xs"
              tooltip="Edit Name"
              (onClick)="openEditNameModal()"
            />
          } @else {
            <div class="mt-1 h-8 w-56 animate-pulse rounded-md bg-gray-200"></div>
          }
          @if (strategyGroup) {
            <IBadge
              variant="soft"
              [label]="strategyGroup.state === TacosStrategyGroupState.ENABLED ? 'ACTIVE' : 'PAUSED'"
              [color]="strategyGroup.state === TacosStrategyGroupState.ENABLED ? 'green' : 'gray'"
              [icon]="strategyGroup.state === TacosStrategyGroupState.ENABLED ? ICON_PLAY : ICON_PAUSE"
            />
          }
        </div>
      </div>

      <div class="mb-5 mt-5 flex w-full gap-2 border-b border-gray-200 pb-5">
        <strategy-info-bloc (onClick)="openTargetModal()">
          <div title class="text-sm uppercase text-gray-500">{{ t("strategy-table.target") }}</div>
          @if (strategyGroup) {
            <div body class="ml-0.5 font-medium">{{ strategyGroup.tacosTarget! * 100 }} %</div>
          } @else {
            <div body class="mt-1 h-6 w-14 animate-pulse rounded-md bg-gray-200"></div>
          }
        </strategy-info-bloc>
      </div>

      <div class="flex flex-col gap-5">
        <div>
          <button
            class="w-full p-0 text-left font-medium text-gray-800"
            id="advertised-products"
            type="button"
            (click)="sectionState[SECTIONS.PRODUCTS] = !sectionState[SECTIONS.PRODUCTS]"
            [attr.aria-expanded]="sectionState[SECTIONS.PRODUCTS]"
            aria-controls="advertised-products"
          >
            <div class="flex items-center gap-2">
              <span
                [class]="ICON_CHEVRON_D + ' h-5 w-5 text-gray-500 duration-150'"
                [ngClass]="sectionState[SECTIONS.PRODUCTS] ? 'rotate-0' : '-rotate-90'"
              ></span>
              {{ t("traffic-stats.advertised_product") }}
              @if (spStrategy) {
                <IBadge color="gray" [label]="spStrategy.asins?.length + ''" />
              } @else {
                <div class="h-6 w-7 animate-pulse rounded-md bg-gray-200"></div>
              }
            </div>
          </button>
          <div
            id="advertised-products"
            role="region"
            class="grid overflow-hidden text-sm text-slate-600 transition-all duration-150 ease-in-out"
            [ngClass]="
              sectionState[SECTIONS.PRODUCTS] ? 'mt-5 grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'
            "
          >
            <div class="overflow-hidden">
              @if (am) {
                <div class="mb-2 flex justify-end">
                  <IButton
                    color="white"
                    icon="icon-[mdi--pencil]"
                    size="sm"
                    [label]="t('common.edit')"
                    [disabled]="!!isReadOnly()"
                    (onClick)="openEditAsinsModal()"
                  />
                </div>
                <app-strategy-asins
                  [asins]="allAsins()"
                  [isReadOnly]="false"
                  [csvExport]="true"
                  [accountId]="am.accountId"
                  [marketplace]="am.marketplace"
                  [deletable]="false"
                  [selectable]="false"
                />
              }
            </div>
          </div>
        </div>

        <div>
          <button
            class="w-full p-0 text-left font-medium text-gray-800"
            id="campaigns"
            type="button"
            (click)="sectionState[SECTIONS.CAMPAIGNS] = !sectionState[SECTIONS.CAMPAIGNS]"
            [attr.aria-expanded]="sectionState[SECTIONS.CAMPAIGNS]"
            aria-controls="campaigns"
          >
            <div class="flex items-center gap-2">
              <span
                [class]="ICON_CHEVRON_D + ' h-5 w-5 text-gray-500 duration-150'"
                [ngClass]="sectionState[SECTIONS.CAMPAIGNS] ? 'rotate-0' : '-rotate-90'"
              ></span>
              {{ t("common.startegies") }}
            </div>
          </button>
          <div
            id="campaigns"
            role="region"
            class="grid overflow-hidden text-sm text-slate-600 transition-all duration-150 ease-in-out"
            [ngClass]="
              sectionState[SECTIONS.CAMPAIGNS] ? 'mt-5 grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'
            "
          >
            <div class="overflow-hidden">
              <IAlert
                typ="info"
                title="SB and SB are not yet supported for TACOS Target strategies"
                [animated]="false"
              />
              <table class="mt-2 w-full">
                <thead>
                  <tr>
                    <th class="border-b border-gray-200 py-3 text-left text-xs uppercase text-gray-500">TYPE</th>
                    <th class="border-b border-gray-200 py-3 text-left text-xs uppercase text-gray-500">PRODUCT</th>
                    <th class="border-b border-gray-200 py-3 text-left text-xs uppercase text-gray-500">TACTICS</th>
                    <th class="border-b border-gray-200 py-3 text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  @if (spStrategy) {
                    <tr>
                      <td class="border-b border-gray-200 py-3">
                        <!-- <IBadge [label]="'SPONSORED PRODUCTS'" [color]="'gray'" /> -->
                        <span class="text-sm font-medium text-gray-600">Sponsored Products</span>
                      </td>

                      <td class="border-b border-gray-200 py-3 text-left font-medium text-gray-800">
                        {{ spStrategy.asins?.length }}
                      </td>
                      <td class="border-b border-gray-200 py-3 text-left font-medium text-gray-800">
                        {{ spStrategy.tactics.length }}
                      </td>
                      <td class="w-[1%] whitespace-nowrap border-b border-gray-200">
                        <IButton color="white" icon="icon-[mdi--ellipsis-horizontal]" size="xs" />
                      </td>
                    </tr>
                  } @else {
                    <tr>
                      <td colspan="5" class="py-3 text-left">
                        <div class="h-6 w-full animate-pulse rounded-md bg-gray-200"></div>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div>
          <button
            class="w-full p-0 text-left font-medium text-gray-800"
            id="stats"
            type="button"
            (click)="sectionState[SECTIONS.STATS] = !sectionState[SECTIONS.STATS]"
            [attr.aria-expanded]="sectionState[SECTIONS.STATS]"
            aria-controls="stats"
          >
            <div class="flex items-center gap-2">
              <span
                [class]="ICON_CHEVRON_D + ' h-5 w-5 text-gray-500 duration-150'"
                [ngClass]="sectionState[SECTIONS.STATS] ? 'rotate-0' : '-rotate-90'"
              ></span>
              Stats
            </div>
          </button>
          <div
            id="stats"
            role="region"
            class="grid overflow-hidden text-sm text-slate-600 transition-all duration-150 ease-in-out"
            [ngClass]="sectionState[SECTIONS.STATS] ? 'mt-5 grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'"
          >
            <div class="overflow-hidden">
              <div class="ag-theme-quartz h-[30rem] w-full">
                <ag-grid-angular
                  #grid
                  [gridOptions]="gridOptions"
                  [rowData]="statsByAsin"
                  class="h-full"
                  [detailRowAutoHeight]="true"
                  [animateRows]="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  } @else if (loading()) {
    <div class="flex h-full items-center justify-center">
      <app-spinner [display]="true" [main]="false" size="m" type="default" />
    </div>
  }

  <ng-template #targetModal>
    <div class="flex w-full items-center justify-between p-4">
      <span class="text-2xl font-medium text-gray-800">TACOS Target</span>
      <span class="icon-[mdi--close] cursor-pointer text-gray-500 hover:text-gray-700" (click)="hideModal()"></span>
    </div>

    <div class="modal-body">
      <div class="flex flex-col gap-2">
        <IInput
          [label]="'Target'"
          [placeholder]="'Target'"
          [type]="'number'"
          [min]="0"
          [max]="100"
          [value]="strategyGroup?.tacosTarget! * 100"
          [autoFocus]="true"
          [trailing]="true"
          icon="icon-[mdi--percent]"
          (valueChange)="tacosTargetInput.set(+$event)"
        />
      </div>

      <div class="mt-4 flex justify-end gap-2">
        <IButton color="white" [label]="'Cancel'" (onClick)="hideModal()" />
        <IButton [label]="'Save'" (onClick)="updateTacosTarget()" />
      </div>
    </div>
  </ng-template>

  <ng-template #editNameModal>
    <div class="flex w-full items-center justify-between p-4">
      <span class="text-2xl font-medium text-gray-800">Edit Name</span>
      <span class="icon-[mdi--close] cursor-pointer text-gray-500 hover:text-gray-700" (click)="hideModal()"></span>
    </div>

    <div class="modal-body">
      <div class="flex flex-col gap-2">
        <IInput
          [label]="'Name'"
          [placeholder]="'Name'"
          [value]="spStrategy?.name!"
          [autoFocus]="true"
          [trailing]="true"
          (valueChange)="strategyNameInput.set($event)"
        />
      </div>

      <div class="mt-4 flex justify-end gap-2">
        <IButton color="white" [label]="'Cancel'" (onClick)="hideModal()" />
        <IButton [label]="'Save'" (onClick)="updateStrategyName()" />
      </div>
    </div>
  </ng-template>

  <ng-template #editAsinsModal>
    <div class="modal-header">
      <h5 class="modal-title">{{ t("common.edit") }}</h5>
      <span class="icon-[mdi--close] cursor-pointer text-gray-500 hover:text-gray-700" (click)="hideModal()"></span>
    </div>
    <div class="modal-body h-[55vh]">
      <IAlert
        class="mb-4"
        typ="info"
        title="Only ASINs that are not used in other strategies can be added to this strategy."
        [animated]="false"
      />
      @if (am) {
        <app-asins-selection
          class="h-full"
          [accountId]="am.accountId"
          [marketplace]="am.marketplace"
          [asinEligibility]="disabledAsins()"
          [asins]="allAsins()"
          (onAdd)="updateAsins($event)"
          (onDelete)="updateAsins($event, 'remove')"
        />
      }
    </div>
  </ng-template>
</ng-container>
