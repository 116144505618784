<ng-container *transloco="let t">
  <div
    class="relative flex h-screen flex-col bg-gray-50/80 transition-[width]"
    [ngClass]="[isCollapsed() ? 'w-0 translate-x-[-250px]' : 'w-[250px]']"
    data-testid="sidebar-nav"
  >
    <div class="scrollbar flex-1 shrink-0 overflow-y-auto">
      <div class="flex w-full items-center justify-between border-b px-4 py-2">
        <div></div>
        <IButton
          icon="icon-[lucide--chevrons-left]"
          tooltipValue="Collapse sidebar"
          [size]="'sm'"
          color="gray"
          variant="ghost"
          (click)="isCollapsed.set(!isCollapsed())"
        />
      </div>
      <div class="flex flex-col gap-2 pt-10">
        @for (section of menu(); track section.label) {
          <div class="flex flex-col gap-2 px-4">
            <div>
              <div class="text-xs text-gray-500">{{ t(section.label) }}</div>
              <ul class="pl-2 pt-2">
                @for (menuItem of section.items; track menuItem.label) {
                  <ng-container *ngTemplateOutlet="navItem; context: { $implicit: menuItem }"></ng-container>
                  @if (menuItem.children) {
                    <IAccordion [isOpen]="openedItemId() === menuItem.id">
                      <ul class="ml-4 border-l border-slate-200 pl-2">
                        @for (child of menuItem.children; track child.label) {
                          <ng-container *ngTemplateOutlet="navItem; context: { $implicit: child }"></ng-container>
                        }
                      </ul>
                    </IAccordion>
                  }
                }
              </ul>
            </div>
          </div>
        }
      </div>
    </div>
    <div>
      <onboarding-cta [onlyButton]="false" />
      <div class="flex flex-col gap-1 border-t border-gray-100 p-4">
        <lib-i-dropdown
          [items]="filteredSettingsMenu()"
          [position]="{ x: { originX: 'end', originY: 'bottom' }, y: { overlayX: 'start', overlayY: 'bottom' } }"
          [offsetX]="8"
          [width]="250"
        >
          <div [class]="NAV_ITEM_CLASS">
            <ng-container
              *ngTemplateOutlet="
                navItemContent;
                context: { $implicit: { label: 'Settings', icon: 'icon-[lucide--settings]', id: 'settings' } }
              "
            ></ng-container>
          </div>
        </lib-i-dropdown>

        <ng-container
          *ngTemplateOutlet="
            navItem;
            context: {
              $implicit: {
                label: 'Notifications',
                icon: 'icon-[lucide--bell]',
                id: 'notifications',
                url: '/notifications-center',
              },
            }
          "
        ></ng-container>
        @if (boardType === BoardType.M19 || whitelabelContactUsLink) {
          <ng-container
            *ngTemplateOutlet="
              navItem;
              context: {
                $implicit: {
                  label: 'Help',
                  icon: 'icon-[lucide--help-circle]',
                  href: boardType === BoardType.M19 ? 'https://help.m19.com/' : whitelabelContactUsLink,
                  id: 'help',
                },
              }
            "
          ></ng-container>
        }
      </div>
    </div>
    <div class="absolute right-0 top-0 h-full w-px bg-slate-200"></div>
  </div>

  <ng-template #navItem let-navItem>
    @if (navItem.href) {
      <a [class]="NAV_ITEM_CLASS" [href]="navItem.href" target="_blank">
        <ng-container *ngTemplateOutlet="navItemContent; context: { $implicit: navItem }"></ng-container>
      </a>
    } @else if (!navItem.children) {
      <a
        [class]="NAV_ITEM_CLASS"
        [routerLink]="navItem.url"
        queryParamsHandling="merge"
        [attr.data-testid]="'sidebar-nav-' + navItem.id"
      >
        <ng-container *ngTemplateOutlet="navItemContent; context: { $implicit: navItem }"></ng-container>
      </a>
    } @else {
      <div (click)="toggleItem(navItem.id)" [class]="NAV_ITEM_CLASS" [attr.data-testid]="'sidebar-nav-' + navItem.id">
        <ng-container *ngTemplateOutlet="navItemContent; context: { $implicit: navItem }"></ng-container>
      </div>
    }
  </ng-template>

  <ng-template #navItemContent let-navItem let-preventHighlight="false">
    <div class="flex w-full items-center justify-between">
      <div
        class="flex items-center gap-2"
        [ngClass]="{ '[&>*]:text-main-500 [&>*]:font-medium': selectedItemId() === navItem.id }"
      >
        <span [class]="navItem.icon + ' text-slate-500'"></span>
        <div class="truncate text-sm leading-5 text-gray-700">
          {{ t(navItem.label) }}
        </div>

        @if (navItem.onlyBeta) {
          <div>
            <IBadge [variant]="'subtle'" [color]="'gray'" [label]="'BETA'" size="xs" />
          </div>
        }
      </div>
      @if (navItem.children) {
        <span
          class="icon-[lucide--chevron-right] text-slate-500 transition-transform duration-100"
          [class.rotate-90]="openedItemId() === navItem.id"
        ></span>
      } @else if (navItem.id === "notifications" && notifications() && notifications()!.length > 0) {
        <IBadge [color]="'red'" [label]="notifCountLabel()" size="xs" />
      }
    </div>
  </ng-template>
</ng-container>
