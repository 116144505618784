import { AgGridAngular } from "@ag-grid-community/angular";
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  TooltipRendererParams,
} from "@ag-grid-community/core";
import { formatCurrency, formatDate } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountSelectionService, AsinService, AuthService } from "@front/m19-services";
import { IBadgeComponent, IButtonComponent } from "@front/m19-ui";
import { TranslocoService } from "@jsverse/transloco";
import { ImageColumn } from "@m19-board/grid-config/grid-columns";
import { getBasicSparklineOptions, IMAGE_COL_ID } from "@m19-board/grid-config/grid-config";
import { AsinLinkComponent } from "@m19-board/product-view/asin-link.component";
import { ProductThumbnailComponent } from "@m19-board/product-view/product-thumbnail.component";
import { CsvExportService, simpleField } from "@m19-board/services/csv-export.service";
import { ConfirmPopupComponent } from "@m19-board/shared/confirm-popup/confirm-popup.component";
import { ICON_ADD, ICON_EDIT_O, ICON_EXPORT, ICON_GEAR, ICON_IMPORT } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs/internal/observable/combineLatest";
import { map, switchMap } from "rxjs/operators";
import { CogsUpdateModalComponent } from "./cog-update-modal/cogs-upload-modal.component";
import { CustomFieldEditionComponent } from "./custom-field-edition.component";
import { CustomFieldSettingsComponent } from "./custom-field-settings.component";
import { ImportManagedCatalogModalComponent } from "./import-managed-catalog-modal.component";
import { SeedsEditionModalComponent } from "./seeds/seeds-edition-modal.component";
import {
  UploadCatalogDataModalComponent,
  UploadResult,
} from "./upload-catalog-data-modal/upload-catalog-data-modal.component";
import { UploadCatalogDataReportModalComponent } from "./upload-catalog-data-modal/upload-catalog-data-report-modal.component";
import {
  AccountMarketplace,
  AccountType,
  AsinSeed,
  AsinSeedTypeEnum,
  CostOfGoods,
  CustomField,
  InventoryConfig,
  Marketplace,
  MatchType,
} from "@front/m19-api-client";
import { Catalog, Currencies, Marketplaces, ProductEx } from "@front/m19-models";
import { getBasicGridOptions } from "@front/m19-grid-config";
import { ByAsin, Utils } from "@front/m19-utils";

// remeber to change this constant in the backend code too
export const MANAGED_LIMIT = 20000;

export enum CsvSeedType {
  SEED = "SEED",
  BLACKLIST = "BLACKLIST",
}

export enum CsvMatchType {
  targetedProduct = "targetedProduct",
  exact = "exact",
  phrase = "phrase",
}

export class SeedCsvRow {
  constructor(
    public asin: string,
    public type: CsvSeedType,
    public matchType: CsvMatchType,
    public value: string,
  ) {}

  public toAsinSeed(accountId: string, marketplace: Marketplace): AsinSeed {
    return {
      accountId: accountId,
      marketplace: marketplace,
      asin: this.asin,
      type: this.type === CsvSeedType.SEED ? AsinSeedTypeEnum.WHITELIST : AsinSeedTypeEnum.BLACKLIST,
      matchType: SeedCsvRow.toMatchType(this.matchType),
      targetingValue: this.value,
    };
  }

  private static fromMatchType(matchType: MatchType): CsvMatchType {
    switch (matchType) {
      case MatchType.exact:
        return CsvMatchType.exact;
      case MatchType.phrase:
        return CsvMatchType.phrase;
      case MatchType.asinSameAs:
        return CsvMatchType.targetedProduct;
    }
  }

  private static toMatchType(matchType: CsvMatchType): MatchType {
    switch (matchType) {
      case CsvMatchType.exact:
        return MatchType.exact;
      case CsvMatchType.phrase:
        return MatchType.phrase;
      case CsvMatchType.targetedProduct:
        return MatchType.asinSameAs;
    }
  }

  public static fromAsinSeed(asinSeeds: AsinSeed[]): SeedCsvRow | null {
    if (asinSeeds.length == 0) {
      return null;
    }
    return new SeedCsvRow(
      asinSeeds[0].asin!,
      asinSeeds[0].type === AsinSeedTypeEnum.WHITELIST ? CsvSeedType.SEED : CsvSeedType.BLACKLIST,
      SeedCsvRow.fromMatchType(asinSeeds[0].matchType!),
      asinSeeds.map((a) => a.targetingValue).join(","),
    );
  }
}

type CatalogProduct = ProductEx & {
  seeds?: AsinSeed[];
  blacklist?: AsinSeed[];
  keywordSeeds?: AsinSeed[];
  productSeeds?: AsinSeed[];
  keywordBlacklist?: AsinSeed[];
  productBlacklist?: AsinSeed[];
  lastCostOfGoods?: number;
};

@Component({
  selector: "app-catalog-page",
  templateUrl: "./catalog-page.component.html",
})
@UntilDestroy()
export class CatalogPageComponent implements OnInit {
  // input
  accountMarketplace!: AccountMarketplace;
  isReadOnly = false;
  locale = "en-US";

  // state
  unmanagedCatalogSection = false;
  selectedManagedProducts: CatalogProduct[] = [];
  selectedUnmanagedProducts: ProductEx[] = [];
  catalogManagementMode: "auto" | "manual" = "auto";
  cogsHistoryByAsin: Map<string, CostOfGoods[]> = new Map();
  inventoryConfig?: InventoryConfig;

  // constants
  readonly AccountType = AccountType;
  readonly ICON_IMPORT = ICON_IMPORT;
  readonly ICON_EXPORT = ICON_EXPORT;
  readonly ICON_GEAR = ICON_GEAR;
  readonly ICON_ADD = ICON_ADD;
  readonly editIconProperties = {
    color: "gray",
    variant: "ghost",
    square: true,
    icon: ICON_EDIT_O,
    iconOnHover: true,
    trailing: true,
  };

  // grid data
  managedCatalogGridData: CatalogProduct[] = [];
  unmanagedCatalogGridData: ProductEx[] = [];

  // grid configuration
  catalogGridApi!: GridApi<CatalogProduct>;
  @ViewChild("unmanagedGrid") unamangedCatalogGrid!: AgGridAngular;

  readonly gridOptions: GridOptions<CatalogProduct> = {
    ...getBasicGridOptions("catalog", false, true),
    sideBar: false,
    defaultColDef: {
      flex: 1,
      resizable: true,
      suppressMovable: true,
      menuTabs: ["filterMenuTab"],
    },
    getRowId: (params) => params.data.asin ?? "",
    rowSelection: "multiple",
    rowMultiSelectWithClick: true,
    suppressRowClickSelection: true,
    onSelectionChanged: () => {
      this.selectedManagedProducts = this.catalogGridApi.getSelectedRows();
    },
    columnDefs: [
      {
        ...ImageColumn,
        headerValueGetter: () => this.translocoService.translate("catalog-page.image"),
        headerName: this.translocoService.translate("catalog-page.image", {}, "en"),
        checkboxSelection: () => !this.isReadOnly,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        maxWidth: 130,
        cellRendererSelector: (params) => {
          if (params.value == undefined) {
            return undefined;
          }
          return {
            component: ProductThumbnailComponent,
            params: {
              asin: params.value,
              smallImg: true,
              marketplace: this.accountMarketplace.marketplace,
              customSizeClass: "size-34",
            },
          };
        },
      },
      {
        headerValueGetter: () => this.translocoService.translate("common.asin"),
        headerName: this.translocoService.translate("common.asin", {}, "en"),
        field: "asin",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        maxWidth: 150,
        cellRendererSelector: (params: ICellRendererParams) => {
          if (params.node.group) return undefined;
          return {
            component: AsinLinkComponent,
            params: {
              asin: params.value,
              marketplace: this.accountMarketplace.marketplace,
            },
          };
        },
      },
      {
        headerValueGetter: () => this.translocoService.translate("common.title"),
        headerName: this.translocoService.translate("common.title", {}, "en"),
        maxWidth: 400,
        floatingFilter: true,
        field: "title",
        filter: "agTextColumnFilter",
        tooltipValueGetter: (p) => p.value,
        cellClass: "sensitive-data",
      },
      {
        field: "brand",
        headerValueGetter: () => this.translocoService.translate("common.brand"),
        headerName: this.translocoService.translate("common.brand", {}, "en"),
        floatingFilter: true,
        filter: "agSetColumnFilter",
        cellRendererSelector: (params: ICellRendererParams) => {
          if (params.node.isRowPinned() || !params.value) return undefined;
          return {
            component: IBadgeComponent,
            params: {
              label: params.value,
              color: "gray",
            },
          };
        },
        cellClass: "sensitive-data",
      },
      {
        field: "lastCostOfGoods",
        headerValueGetter: () => this.translocoService.translate("catalog-page.cost_of_goods"),
        headerName: this.translocoService.translate("catalog-page.cost_of_goods", {}, "en"),
        cellRenderer: IButtonComponent,
        cellRendererParams: (params: ICellRendererParams) => {
          let label = "-";
          if (params.value) {
            label = this.getFormattedCogs(params.value);
          }
          const tooltipValue = this.translocoService.translate("catalog-page.edit_cost_of_goods");
          return {
            label,
            ...this.editIconProperties,
            tooltipValue,
            disabled: this.isReadOnly,
            clickAction: () => {
              this.openEditCogsModal(params.data.asin);
            },
          };
        },
      },
      {
        headerValueGetter: () => this.translocoService.translate("catalog-page.cogs_history"),
        headerName: this.translocoService.translate("catalog-page.cogs_history", {}, "en"),
        valueGetter: (params) => {
          return this.cogsHistoryByAsin.get(params.data!.asin!)?.map((c) => ({
            date: c.startDate === "1970-01-01" ? "-" : formatDate(c.startDate!, "mediumDate", this.locale),
            cog: c.costOfGoods,
          }));
        },
        cellRenderer: "agSparklineCellRenderer",
        cellRendererParams: {
          sparklineOptions: {
            ...getBasicSparklineOptions(),
            xKey: "date",
            yKey: "cog",
            tooltip: {
              renderer: (params: TooltipRendererParams) => {
                return {
                  title: params.xValue,
                  content: this.getFormattedCogs(params.yValue),
                };
              },
            },
          },
        },
      },
      this.buildSeedColumn(true),
      this.buildSeedColumn(false),
      this.buildCustomFieldColumn("field1"),
      this.buildCustomFieldColumn("field2"),

      // columns for CSV export
      {
        colId: "currency",
        headerValueGetter: () => this.translocoService.translate("common.currency"),
        headerName: this.translocoService.translate("common.currency", {}, "en"),
        valueGetter: () => Marketplaces[this.accountMarketplace.marketplace].currency,
        hide: true,
        suppressFiltersToolPanel: true,
      },
      this.buildCsvSeedColumn(this.translocoService.translate("catalog-page.seed_keywords", {}, "en"), "keywordSeeds"),
      this.buildCsvSeedColumn(this.translocoService.translate("catalog-page.seed_products", {}, "en"), "productSeeds"),
      this.buildCsvSeedColumn(
        this.translocoService.translate("catalog-page.blacklisted_keywords", {}, "en"),
        "keywordBlacklist",
      ),
      this.buildCsvSeedColumn(
        this.translocoService.translate("catalog-page.blacklisted_products", {}, "en"),
        "productBlacklist",
      ),
    ],
  };

  readonly unmanagedGridOptions: GridOptions<CatalogProduct> = {
    ...getBasicGridOptions("unmanaged_catalog", false, true),
    sideBar: false,
    defaultColDef: {
      flex: 1,
      resizable: true,
      suppressMovable: true,
      menuTabs: ["filterMenuTab"],
    },
    getRowId: (params) => params.data.asin!,
    rowSelection: "multiple",
    rowMultiSelectWithClick: true,
    onSelectionChanged: () => {
      this.selectedUnmanagedProducts = this.unamangedCatalogGrid.api.getSelectedRows();
    },
    suppressRowClickSelection: true,
    columnDefs: [
      {
        ...ImageColumn,
        headerName: this.translocoService.translate("catalog-page.image", {}, "en"),
        headerValueGetter: () => this.translocoService.translate("catalog-page.image"),
        checkboxSelection: () => !this.isReadOnly,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        maxWidth: 130,
        cellRendererSelector: (params) => {
          if (params.value == undefined) {
            return undefined;
          }
          return {
            component: ProductThumbnailComponent,
            params: {
              asin: params.value,
              smallImg: true,
              marketplace: this.accountMarketplace.marketplace,
              customSizeClass: "size-34",
            },
          };
        },
      },
      {
        headerValueGetter: () => this.translocoService.translate("common.asin"),
        headerName: this.translocoService.translate("common.asin", {}, "en"),
        field: "asin",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        maxWidth: 150,
        cellRendererSelector: (params) => {
          if (params.node.group) return undefined;
          return {
            component: AsinLinkComponent,
            params: {
              asin: params.value,
              marketplace: this.accountMarketplace.marketplace,
            },
          };
        },
      },
      {
        headerValueGetter: () => this.translocoService.translate("common.title"),
        headerName: this.translocoService.translate("common.title", {}, "en"),
        maxWidth: 400,
        floatingFilter: true,
        field: "title",
        filter: "agTextColumnFilter",
        tooltipValueGetter: (p) => p.value,
        cellClass: "sensitive-data",
      },
      {
        field: "brand",
        headerValueGetter: () => this.translocoService.translate("common.brand"),
        headerName: this.translocoService.translate("common.brand", {}, "en"),
        floatingFilter: true,
        filter: "agSetColumnFilter",
        cellRendererSelector: (params) => {
          if (params.node.isRowPinned() || !params.value) return undefined;
          return {
            component: IBadgeComponent,
            params: {
              label: params.value,
              color: "gray",
            },
          };
        },
        cellClass: "sensitive-data",
      },
    ],
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private asinService: AsinService,
    private accountSelectionService: AccountSelectionService,
    private modalService: BsModalService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private csvExportService: CsvExportService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((accountMarketplace) => {
        this.accountMarketplace = accountMarketplace;
      });
    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
    this.authService.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.locale = user?.locale ?? "";
    });

    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((accountMarketplace) =>
          combineLatest([
            this.asinService.getCatalog(accountMarketplace.accountId, accountMarketplace.marketplace),
            this.asinService.getAsinSeeds(accountMarketplace.accountId, accountMarketplace.marketplace),
            this.asinService.getLastCostOfGoods(accountMarketplace.accountId, accountMarketplace.marketplace),
          ]),
        ),
        untilDestroyed(this),
        map(([catalog, seeds, costOfGoods]: [Catalog, ByAsin<AsinSeed[]>, CostOfGoods[]]) => {
          const costOfGoodsByAsin = new Map<string, CostOfGoods>();
          for (const cogs of costOfGoods) {
            costOfGoodsByAsin.set(cogs.asin!, cogs);
          }
          return catalog.products.map((product) =>
            this.expandProduct(product, seeds.get(product.asin!), costOfGoodsByAsin.get(product.asin!)),
          );
        }),
      )
      .subscribe((catalogProducts) => {
        this.managedCatalogGridData = catalogProducts;
        this.catalogGridApi?.refreshCells();
      });
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((accountMarketplace) =>
          this.asinService.getUnmanagedCatalog(accountMarketplace.accountId, accountMarketplace.marketplace),
        ),
        untilDestroyed(this),
      )
      .subscribe((catalog) => {
        this.unmanagedCatalogGridData = catalog.products;
      });
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((accountMarketplace) =>
          this.asinService.getCatalogManagementMode(accountMarketplace.accountId, accountMarketplace.marketplace),
        ),
        untilDestroyed(this),
      )
      .subscribe((mode) => {
        this.catalogManagementMode = mode;
      });
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((accountMarketplace) =>
          this.asinService.getCostOfGoods(accountMarketplace.accountId, accountMarketplace.marketplace),
        ),
        untilDestroyed(this),
      )
      .subscribe((cogs) => {
        this.cogsHistoryByAsin.clear();
        for (const [asin, asinCogs] of cogs) {
          const cogsHistory = this.cogsHistoryByAsin.get(asin) ?? [];
          cogsHistory.push(
            ...asinCogs.map(([startDate, costOfGoods]) => ({
              asin,
              startDate,
              costOfGoods,
            })),
          );
          this.cogsHistoryByAsin.set(asin, cogsHistory);
        }
      });
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((accountMarketplace) =>
          this.asinService.getInventoryConfig(accountMarketplace.accountId, accountMarketplace.marketplace),
        ),
        untilDestroyed(this),
      )
      .subscribe((inventoryConfig) => {
        this.inventoryConfig = inventoryConfig;
        // force header and grid refresh for headers and custom field values
        this.catalogGridApi?.refreshHeader();
        this.catalogGridApi?.refreshCells({ force: true });
      });
  }

  onGridReady(params: GridReadyEvent<CatalogProduct>) {
    this.catalogGridApi = params.api;
    // get brand filter from query params
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams["brand"]) {
        params.api.setFilterModel({
          brand: {
            type: "set",
            values: [queryParams["brand"]],
          },
        });
        // remove the query param from the URL
        this.router.navigate([], {
          queryParams: {
            brand: undefined,
          },
          queryParamsHandling: "merge",
        });
      }
    });
  }

  private getFormattedCogs(value: number): string {
    const currencyCode = Marketplaces[this.accountMarketplace.marketplace].currency;
    const currency = Currencies[currencyCode];
    return formatCurrency(value, this.locale, currency.currencySymbol, currencyCode, "1.0-2");
  }

  private expandProduct(product: ProductEx, seeds?: AsinSeed[], costOfGoods?: CostOfGoods): CatalogProduct {
    const catalogProduct = product as CatalogProduct;
    catalogProduct.lastCostOfGoods = costOfGoods?.costOfGoods;
    if (seeds == undefined) {
      return catalogProduct;
    }
    catalogProduct.seeds = [];
    catalogProduct.blacklist = [];
    catalogProduct.keywordSeeds = [];
    catalogProduct.productSeeds = [];
    catalogProduct.keywordBlacklist = [];
    catalogProduct.productBlacklist = [];
    for (const seed of seeds) {
      if (seed.type == AsinSeedTypeEnum.WHITELIST) {
        catalogProduct.seeds.push(seed);
        if (seed.matchType == MatchType.exact || seed.matchType == MatchType.phrase) {
          catalogProduct.keywordSeeds.push(seed);
        } else if (seed.matchType == MatchType.asinSameAs) {
          catalogProduct.productSeeds.push(seed);
        }
      }
      if (seed.type == AsinSeedTypeEnum.BLACKLIST) {
        catalogProduct.blacklist.push(seed);
        if (seed.matchType == MatchType.exact || seed.matchType == MatchType.phrase) {
          catalogProduct.keywordBlacklist.push(seed);
        } else if (seed.matchType == MatchType.asinSameAs) {
          catalogProduct.productBlacklist.push(seed);
        }
      }
    }
    return catalogProduct;
  }

  private buildSeedColumn(whitelist: boolean): ColDef {
    return {
      field: whitelist ? "seeds" : "blacklist",
      headerName: whitelist
        ? this.translocoService.translate("common.seeds", {}, "en")
        : this.translocoService.translate("common.blacklist", {}, "en"),
      headerValueGetter: () => {
        return whitelist
          ? this.translocoService.translate("common.seeds")
          : this.translocoService.translate("common.blacklist");
      },
      valueFormatter: (params) => (params.value?.length > 0 ? params.value?.length : "-"),
      useValueFormatterForExport: true,
      cellRenderer: IButtonComponent,
      cellRendererParams: (params: ICellRendererParams) => {
        const kw = (whitelist ? params.data.keywordSeeds?.length : params.data.keywordBlacklist?.length) ?? 0;
        const pr = (whitelist ? params.data.productSeeds?.length : params.data.productBlacklist?.length) ?? 0;
        let tooltipValue: string;
        if (whitelist) {
          tooltipValue =
            params.value?.length > 0
              ? `${kw} seed keyword${kw > 1 ? "s" : ""} and ${pr} seed product${pr > 1 ? "s" : ""}`
              : "Add seeds";
        } else {
          tooltipValue =
            params.value?.length > 0
              ? `${kw} blacklisted keyword${kw > 1 ? "s" : ""} and ${pr} blacklisted product${pr > 1 ? "s" : ""}`
              : "Add blacklist keywords or products";
        }
        const label = params.value?.length > 0 ? params.value?.length : "-";
        return {
          label,
          ...this.editIconProperties,
          tooltipValue,
          disabled: this.isReadOnly,
          clickAction: () => {
            const modalOptions: ModalOptions = {
              initialState: {
                accountId: this.accountMarketplace.accountId,
                marketplace: this.accountMarketplace.marketplace,
                asin: params.data.asin,
                keywordTargetingItems: [...(params.data.keywordSeeds ?? []), ...(params.data.keywordBlacklist ?? [])],
                productTargetingItems: [...(params.data.productSeeds ?? []), ...(params.data.productBlacklist ?? [])],
                whitelist,
              },
              class: "modal-xl",
            };
            this.modalService.show(SeedsEditionModalComponent, modalOptions);
          },
        };
      },
    };
  }

  private getCustomFieldName(field: "field1" | "field2"): string {
    const name = field == "field1" ? this.inventoryConfig?.customField1Name : this.inventoryConfig?.customField2Name;
    return name
      ? (Utils.titleCase(name) ?? "")
      : `${this.translocoService.translate("common.custom_field")} ${field == "field1" ? 1 : 2}`;
  }

  private buildCustomFieldColumn(field: "field1" | "field2"): ColDef {
    return {
      field: field == "field1" ? "customField1" : "customField2",
      headerName: this.getCustomFieldName(field),
      headerValueGetter: () => this.getCustomFieldName(field),
      floatingFilter: true,
      filter: "agSetColumnFilter",
      cellClass: () => {
        return this.inventoryConfig?.customField1Name || this.inventoryConfig?.customField2Name ? "" : "bg-gray-200";
      },
      cellRenderer: IButtonComponent,
      cellRendererParams: (params: ICellRendererParams) => {
        return {
          label: params.value ?? "-",
          ...this.editIconProperties,
          disabled: this.isReadOnly,
          tooltipValue: `Edit ${this.getCustomFieldName(field)}`,
          clickAction: () => {
            const ref = this.modalService.show(CustomFieldEditionComponent, {
              initialState: {
                asin: params.data.asin,
                marketplace: this.accountMarketplace.marketplace,
                productTag: field,
                value: params.value,
                inventoryConfig: this.inventoryConfig,
              },
              class: "modal-primary modal-dialog-centered",
            });
            ref.content?.valueChange
              .pipe(
                switchMap((value) => {
                  if (value === "deletion") {
                    return this.asinService.deleteCustomField(
                      this.accountMarketplace.accountId,
                      this.accountMarketplace.marketplace,
                      params.data.asin,
                      field,
                    );
                  }
                  return this.asinService.updateCustomField(
                    this.accountMarketplace.accountId,
                    this.accountMarketplace.marketplace,
                    params.data.asin,
                    field,
                    value.newValue?.trim(),
                  );
                }),
              )
              .subscribe({
                next: () => {
                  this.toastrService.success(
                    this.translocoService.translate("catalog-page.value_updated", [this.getCustomFieldName(field)]),
                  );
                },
                error: (err) => {
                  this.toastrService.error(
                    err,
                    this.translocoService.translate("catalog-page.failed_to_update_value", [
                      this.getCustomFieldName(field),
                    ]),
                  );
                },
              });
          },
        };
      },
    };
  }

  groupEditCustomField(field: "field1" | "field2") {
    const ref = this.modalService.show(CustomFieldEditionComponent, {
      initialState: {
        marketplace: this.accountMarketplace.marketplace,
        productTag: field,
        value: undefined,
        inventoryConfig: this.inventoryConfig,
      },
      class: "modal-primary modal-dialog-centered",
    });

    let mode: "update" | "delete" = "update";

    ref.content?.valueChange
      .pipe(
        switchMap((value) => {
          mode = value === "deletion" ? "delete" : "update";
          const customFields: CustomField[] = this.selectedManagedProducts.map((p) => {
            if (value === "deletion") {
              return {
                asin: p.asin!,
                [field]: "",
              };
            } else {
              return {
                asin: p.asin!,
                [field]: value.newValue?.trim(),
              };
            }
          });
          return this.asinService.updateCustomFields(
            this.accountMarketplace.accountId,
            this.accountMarketplace.marketplace,
            customFields,
          );
        }),
      )
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("catalog-page.custom_field_mode", [
              this.selectedManagedProducts.length,
              mode,
            ]),
          );

          this.catalogGridApi?.deselectAll();
          this.selectedManagedProducts = [];
        },
        error: (err) => {
          this.toastrService.error(
            err,
            this.translocoService.translate("catalog-page.failed_to_mode_custom_fields", [mode]),
          );
        },
      });
  }

  private buildCsvSeedColumn(headerName: string, field: string): ColDef {
    return {
      headerName,
      field,
      hide: true,
      suppressFiltersToolPanel: true,
    };
  }

  uploadCatalogData(): void {
    const currency = Currencies[Marketplaces[this.accountMarketplace.marketplace].currency];
    const currencySymbol = currency.currencySymbol;
    const catalogAsins = this.managedCatalogGridData.map((x) => x.asin);
    const modalOptions: ModalOptions = {
      initialState: {
        currencySymbol,
        accountId: this.accountMarketplace.accountId,
        marketplace: this.accountMarketplace.marketplace,
        catalogAsins,
        onCogsUpload: (result: UploadResult<CostOfGoods>) => this.onCogsUpdate(result),
        onSeedsUpload: (result: UploadResult<string>) => this.onSeedsUpload(result),
        onCFsUpload: (result: UploadResult<CustomField>) => this.onCFsUpload(result),
      },
      class: "modal-xl",
    };
    this.modalService.show(UploadCatalogDataModalComponent, modalOptions);
  }

  private openEditCogsModal(asin: string) {
    const ref = this.modalService.show(CogsUpdateModalComponent, {
      initialState: {
        asin,
        marketplace: this.accountMarketplace.marketplace,
        locale: this.locale,
        currency: Marketplaces[this.accountMarketplace.marketplace].currency,
        cogsHistory:
          this.cogsHistoryByAsin.get(asin)?.map((c) => ({ startDate: c.startDate!, costOfGoods: c.costOfGoods! })) ??
          [],
      },
      class: "modal-xxl",
    });
    ref.content?.valueChange
      .pipe(
        switchMap((newCogsHistory) => {
          if (newCogsHistory === "deletion") {
            return this.asinService.setCostOfGoods(
              this.accountMarketplace.accountId,
              this.accountMarketplace.marketplace,
              asin,
              [],
            );
          }
          const cogsHistory = newCogsHistory.map((c) => ({
            ...c,
            accountId: this.accountMarketplace.accountId,
            marketplace: this.accountMarketplace.marketplace,
            asin,
          }));
          return this.asinService.setCostOfGoods(
            this.accountMarketplace.accountId,
            this.accountMarketplace.marketplace,
            asin,
            cogsHistory,
          );
        }),
      )
      .subscribe({
        next: () => {
          this.toastrService.success(this.translocoService.translate("catalog-page.cost_of_goods_updated"));
        },
        error: (err) => {
          this.toastrService.error(err, this.translocoService.translate("catalog-page.failed_to_update_cost_of_goods"));
        },
      });
  }

  openCustomFieldsSettings() {
    const modalOptions: ModalOptions = {
      initialState: {
        inventoryConfig: this.inventoryConfig,
      },
      class: "modal-primary",
    };
    const ref = this.modalService.show(CustomFieldSettingsComponent, modalOptions);
    ref.content?.valueChange
      .pipe(
        switchMap((config) => {
          return this.asinService.setCustomFieldNames(
            this.accountMarketplace.accountId,
            this.accountMarketplace.marketplace,
            config.customField1Name ?? "",
            config.customField2Name ?? "",
          );
        }),
      )
      .subscribe({
        next: () => {
          this.toastrService.success(this.translocoService.translate("catalog-page.custom_fields_settings_updated"));
        },
        error: (err) => {
          this.toastrService.error(
            err,
            this.translocoService.translate("catalog-page.failed_to_update_custom_fields_settings"),
          );
        },
      });
  }

  private displayUploadResult<T>(
    uploadResult: UploadResult<T>,
    updatedEntity: string,
    uploadResultFormater: (u: T) => string,
  ) {
    const modalOptions: ModalOptions = {
      initialState: {
        uploadResult: uploadResult,
        updatedEntity: updatedEntity,
        uploadResultFormater: uploadResultFormater,
      },
      class: "modal-primary",
    };
    this.modalService.show<UploadCatalogDataReportModalComponent<T>>(
      UploadCatalogDataReportModalComponent,
      modalOptions,
    );
  }

  private onCogsUpdate(result: UploadResult<CostOfGoods>) {
    const currencyCode = Marketplaces[this.accountMarketplace.marketplace].currency;
    const currencySymbol = Currencies[currencyCode].currencySymbol;
    this.displayUploadResult(result, "cost of goods", (cog) => {
      const cogValue = formatCurrency(cog.costOfGoods!, this.locale, currencySymbol, currencyCode, "1.0-2");
      return `ASIN ${cog.asin} updated with cost of goods ${cogValue}`;
    });
    this.catalogGridApi?.refreshCells();
  }

  private onSeedsUpload(result: UploadResult<string>) {
    this.displayUploadResult(result, "seed keywords", (asin) => `ASIN ${asin}`);
    this.catalogGridApi?.refreshCells();
  }

  private onCFsUpload(result: UploadResult<CustomField>) {
    this.displayUploadResult(
      result,
      "Custom Fields ",
      (customField) => `ASIN : ${customField.asin}, FIELD1 : ${customField.field1}, FIELD2 : ${customField.field2}`,
    );
    this.catalogGridApi?.refreshCells();
  }

  toggleUnmanagedCatalogSection() {
    this.unmanagedCatalogSection = !this.unmanagedCatalogSection;
  }

  stopManagingProducts() {
    if (this.managedCatalogGridData.length == 0) {
      return;
    }
    let message: string = "";
    if (this.selectedManagedProducts.length > 1) {
      message = this.translocoService.translate("catalog-page.n_deletion_confirmation", [
        this.selectedManagedProducts.length,
      ]);
    } else {
      message = this.translocoService.translate("catalog-page.remove_1_asin", [this.selectedManagedProducts[0].asin]);
    }
    const modalOptions = {
      initialState: {
        title: this.translocoService.translate("catalog-page.stop_managing_products"),
        message,
      },
    };
    const ref = this.modalService.show(ConfirmPopupComponent, modalOptions);
    ref.content?.confirm
      .pipe(
        switchMap(() =>
          this.asinService.deleteAsins(
            this.accountMarketplace.accountId,
            this.accountMarketplace.marketplace,
            this.selectedManagedProducts.map((p) => p.asin!),
          ),
        ),
      )
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("catalog-page.products_removed_from_managed_catalog"),
          );
          this.selectedManagedProducts = [];
          this.catalogGridApi.deselectAll();
        },
        error: (err) => {
          this.toastrService.error(
            err,
            this.translocoService.translate("catalog-page.failed_to_remove_products_from_managed_catalog"),
          );
        },
      });
  }

  restoreToManagedProducts() {
    this.asinService
      .addAsins(
        this.accountMarketplace.accountId,
        this.accountMarketplace.marketplace,
        Array.from(this.selectedUnmanagedProducts.map((p) => p.asin!)),
      )
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("catalog-page.products_are_back_to_managed_catalog"),
          );
          this.selectedUnmanagedProducts = [];
          this.unamangedCatalogGrid.api.deselectAll();
        },
        error: (err) => {
          this.toastrService.error(
            err,
            this.translocoService.translate("catalog-page.failed_to_move_back_products_to_managed_catalog"),
          );
        },
      });
  }

  toggleCatalogManagementMode() {
    const mode = this.catalogManagementMode == "auto" ? "manual" : "auto";
    this.asinService
      .setCatalogManagementMode(this.accountMarketplace.accountId, this.accountMarketplace.marketplace, mode)
      .subscribe({
        next: () => {
          this.toastrService.success(
            mode === "auto"
              ? this.translocoService.translate("catalog-page.catalog_management_mode_set_to_auto")
              : this.translocoService.translate("catalog-page.catalog_management_mode_set_to_manual"),
          );
        },
        error: (err) => {
          this.toastrService.error(
            err,
            this.translocoService.translate("catalog-page.failed_to_set_catalog_management_mode"),
          );
        },
      });
  }

  exportCatalogAsCsv() {
    const fileName = `catalog_asins_${this.accountMarketplace.accountGroupName}_${this.accountMarketplace.marketplace}`;
    const columnKeys: string[] = this.catalogGridApi
      .getAllDisplayedColumns()
      .map((c) => c.getColId())
      .filter((c) => c !== IMAGE_COL_ID);
    this.catalogGridApi.exportDataAsCsv({ fileName, columnKeys });
  }

  exportCogs() {
    const fileName = `cost_of_goods_${this.accountMarketplace.accountGroupName}_${this.accountMarketplace.marketplace}`;
    const columnKeys = ["asin", "lastCostOfGoods"];
    this.catalogGridApi.exportDataAsCsv({ fileName, columnKeys, suppressQuotes: true });
  }

  exportCustomFields() {
    const fileName = `custom_fields_${this.accountMarketplace.accountGroupName}_${this.accountMarketplace.marketplace}`;
    const columnKeys = ["asin", "customField1", "customField2"];
    this.catalogGridApi.exportDataAsCsv({ fileName, columnKeys, suppressQuotes: true });
  }

  exportSeedsAsCsv(): void {
    const data: SeedCsvRow[] = [];
    for (const product of this.managedCatalogGridData) {
      if (product.productSeeds && product.productSeeds?.length > 0) {
        data.push(SeedCsvRow.fromAsinSeed(product.productSeeds)!);
      }
      if (product.keywordSeeds && product.keywordSeeds?.length > 0) {
        const exactKw = product.keywordSeeds.filter((s) => s.matchType == MatchType.exact);
        const phraseKw = product.keywordSeeds.filter((s) => s.matchType == MatchType.phrase);
        if (exactKw.length > 0) {
          data.push(SeedCsvRow.fromAsinSeed(exactKw)!);
        }
        if (phraseKw.length > 0) {
          data.push(SeedCsvRow.fromAsinSeed(phraseKw)!);
        }
      }
      if (product.productBlacklist && product.productBlacklist?.length > 0) {
        data.push(SeedCsvRow.fromAsinSeed(product.productBlacklist)!);
      }
      if (product.keywordBlacklist && product.keywordBlacklist?.length > 0) {
        const exactKw = product.keywordBlacklist.filter((s) => s.matchType == MatchType.exact);
        const phraseKw = product.keywordBlacklist.filter((s) => s.matchType == MatchType.phrase);
        if (exactKw.length > 0) {
          data.push(SeedCsvRow.fromAsinSeed(exactKw)!);
        }
        if (phraseKw.length > 0) {
          data.push(SeedCsvRow.fromAsinSeed(phraseKw)!);
        }
      }
    }
    if (data.length == 0) {
      this.toastrService.info(this.translocoService.translate("catalog-page.no_data_to_export"));
      return;
    }
    this.csvExportService.exportCsv(
      {
        prefix: "catalog_seeds",
        accountGroupName: this.accountMarketplace.accountGroupName,
        marketplace: this.accountMarketplace.marketplace,
        quoteStrings: "",
      },
      data,
      [simpleField("asin"), simpleField("type"), simpleField("matchType"), simpleField("value")],
    );
  }

  bulkImportUnmanagedProducts(mode: "managed" | "unmanaged") {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.accountMarketplace.accountId,
        marketplace: this.accountMarketplace.marketplace,
        mode,
        unmanagedCatalog: this.unmanagedCatalogGridData.map((p) => p.asin),
        managedCatalog: this.managedCatalogGridData.map((p) => p.asin),
      },
      class: "modal-xl",
    };
    this.modalService.show(ImportManagedCatalogModalComponent, modalOptions);
  }

  managedOverLimit() {
    return this.managedCatalogGridData.length >= MANAGED_LIMIT;
  }

  selectedMoreThanLimit() {
    return this.selectedUnmanagedProducts.length + this.managedCatalogGridData.length > MANAGED_LIMIT;
  }

  disableRestoreButton() {
    return this.isReadOnly || this.managedOverLimit() || this.selectedMoreThanLimit();
  }

  getTooltipForRestoreButton() {
    if (this.disableRestoreButton()) {
      return this.translocoService.translate("catalog-page.you_can_only_manage_up_to_managed_limit_products", [
        MANAGED_LIMIT,
      ]);
    }
    return this.translocoService.translate("catalog-page.restore_managed_products_tooltip", {
      count: this.selectedUnmanagedProducts.length,
    });
  }

  getTooltipForImportButton() {
    return this.managedOverLimit()
      ? this.translocoService.translate("catalog-page.you_can_only_manage_up_to_managed_limit_products", [
          MANAGED_LIMIT,
        ])
      : "";
  }

  getTooltipForSwitchManagementMode() {
    return this.managedOverLimit()
      ? `Automatic Management can only be activated with less than ${MANAGED_LIMIT}`
      : this.catalogManagementMode == "auto"
        ? this.translocoService.translate("common.auto")
        : this.translocoService.translate("common.custom");
  }
}
