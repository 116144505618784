import { Marketplace } from "@front/m19-api-client";

export function isVideoAvailable(marketplace: Marketplace) {
  return (
    marketplace == Marketplace.SA ||
    marketplace == Marketplace.AU ||
    marketplace == Marketplace.DE ||
    marketplace == Marketplace.UK ||
    marketplace == Marketplace.FR ||
    marketplace == Marketplace.ES ||
    marketplace == Marketplace.IT ||
    marketplace == Marketplace.NL ||
    marketplace == Marketplace.CA ||
    marketplace == Marketplace.US ||
    marketplace == Marketplace.MX ||
    marketplace == Marketplace.JP ||
    marketplace == Marketplace.BE
  );
}
