import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

import {
  AccountSelectionService,
  AdsStatsWithPreviousPeriod,
  AuthService,
  DataSet,
  DataSetEventAnnotation,
  statGroupByKey,
  StatsApiClientService,
  UserSelectionService,
} from "@front/m19-services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { Option } from "@front/m19-ui";
import { ActivityEventType, ActivityService } from "@m19-board/activities/activity.service";
import { MarketplacesComponent } from "@m19-board/marketplaces/marketplaces.component";
import { CsvExportService, metricField, simpleField } from "@m19-board/services/csv-export.service";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { delay, filter, map, switchMap } from "rxjs/operators";

import { DefaultAdStatsMetricsWithSameScale } from "../models/DataSet";
import {
  APP_RATIO,
  BUY_BOX_RATE,
  PAGE_VIEWS,
  SESSIONS,
  SPONSORED_SALES_SHARE,
  TACOS,
  TOTAL_CONVERSION_RATE,
  TOTAL_ORDERS,
  TOTAL_SALES,
  VENDOR_CUSTOMER_RETURNS,
  VENDOR_SHIPPED_COGS,
} from "../models/MetricsDef";
import { TranslocoService } from "@jsverse/transloco";
import { AccountGroup, AdStatsEx, StrategyEx } from "@front/m19-models";
import {
  ACOS,
  AD_CONVERSIONS,
  AD_SALES,
  CLICK_THROUGH_RATE,
  CLICKS,
  CONVERSION_RATE,
  COST,
  CPC,
  IMPRESSIONS,
  Metric,
  ROAS,
} from "@front/m19-metrics";
import {
  addAdStats,
  Comparison,
  DateAggregation,
  mergeSeveralDates,
  MetricsSelectorLocalStorageKey,
  Utils,
} from "@front/m19-utils";
import { AccountType } from "@front/m19-api-client";
import { AggregationFunction } from "../../../../../libs/m19-services/src/lib/utils/aggregation.utils";

export const FakeGlobalData: AdStatsEx = buildFakeData();

const LastWeek = Utils.formatDateForApiFromToday(-7);
const Today = Utils.formatDateForApiFromToday(0);
export const FakeData = buildFakeDataBetweenDates(LastWeek, Today);

function buildFakeData(date?: string) {
  return {
    impressions: Utils.randomInt(30000),
    clicks: Utils.randomInt(200),
    cost: Utils.randomInt(3000) / 100,
    adConversions: Utils.randomInt(20),
    adSales: Utils.randomInt(200),
    allOrderedUnits: Utils.randomInt(50),
    allSales: Utils.randomInt(10000) / 100,
    date: date,
  };
}

function buildFakeDataBetweenDates(start: string, end: string): AdStatsEx[] {
  const result: AdStatsEx[] = [];
  for (let dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
    result.push(buildFakeData(Utils.formatDateForApi(dt)));
  }
  return result;
}

@UntilDestroy()
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  CHART_METRICS_VENDOR: Metric<AdStatsEx>[] = [
    AD_SALES,
    AD_CONVERSIONS,
    COST,
    ACOS,
    CLICKS,
    IMPRESSIONS,
    CPC,
    CONVERSION_RATE,
    ROAS,
    PAGE_VIEWS,
    CLICK_THROUGH_RATE,
    TOTAL_SALES,
    TOTAL_ORDERS,
    TACOS,
    SPONSORED_SALES_SHARE,
    VENDOR_SHIPPED_COGS,
    VENDOR_CUSTOMER_RETURNS,
  ];

  readonly CHART_METRICS_SELLER: Metric<AdStatsEx>[] = [
    TOTAL_SALES,
    TOTAL_ORDERS,
    TACOS,
    AD_SALES,
    AD_CONVERSIONS,
    COST,
    ACOS,
    CLICKS,
    IMPRESSIONS,
    CPC,
    CONVERSION_RATE,
    ROAS,
    SPONSORED_SALES_SHARE,
    SESSIONS,
    PAGE_VIEWS,
    APP_RATIO,
    BUY_BOX_RATE,
    TOTAL_CONVERSION_RATE,
    CLICK_THROUGH_RATE,
  ];
  readonly AccountType = AccountType;
  readonly MetricsSelectorLocalStorageKey = MetricsSelectorLocalStorageKey;

  selectedMainMetric$: BehaviorSubject<Metric<AdStatsEx>[]>;
  selectedMetrics: Metric<AdStatsEx>[] = [];
  mainSingleMetric$: Observable<Metric<AdStatsEx>>;
  dateAggregation$: BehaviorSubject<DateAggregation> = new BehaviorSubject<DateAggregation>(DateAggregation.daily);
  globalData: AdStatsEx;
  previousPeriodGlobalData: AdStatsEx = {};
  data: AdStatsEx[] = new Array<AdStatsEx>();
  accountGroupName: string;
  dataSet: DataSet<AdStatsEx>;
  minDate: string;
  maxDate: string;
  displayEventAnnotation$ = new BehaviorSubject(false);
  disableEventAnnotation: boolean;
  accountType: AccountType;

  allUsers$: Observable<Option<string>[]> = this.activityService.allUsersOptions$;
  selectedUsers$: Observable<Option<string>[]> = this.activityService.selectedUsersOptions$;

  allStrategies$: Observable<Option<StrategyEx>[]> = this.activityService.allStrategiesOptions$;
  selectedStrategies$: Observable<Option<StrategyEx>[]> = this.activityService.selectedStrategiesOptions$;

  eventAnnotationTypes$: Observable<Option<ActivityEventType>[]> = this.activityService.selectedActivityTypesOptions$;
  allEventAnnotationTypes: Option<ActivityEventType>[] = this.activityService.allActivityEventTypesOptions;

  @ViewChild(MarketplacesComponent) marketplace!: MarketplacesComponent;

  constructor(
    private authService: AuthService,
    private userSelectionService: UserSelectionService,
    private statsService: StatsApiClientService,
    private accountSelectionService: AccountSelectionService,
    private csvExportService: CsvExportService,
    private activityService: ActivityService,
    private translocoService: TranslocoService,
  ) {
    this.dataSet = new DataSet<AdStatsEx>(
      3,
      [AD_SALES, COST],
      [AggregationFunction.mergeSeveralDates],
      this.translocoService,
    );
    this.dataSet.metricsOnSameScale = DefaultAdStatsMetricsWithSameScale;
  }

  ngOnInit(): void {
    this.accountSelectionService.updateSingleMarketplaceMode(false);
    this.selectedMainMetric$ = new BehaviorSubject<Metric<AdStatsEx>[]>([AD_SALES, COST]);

    let init = false;

    // init with fake data when no account selected
    this.accountSelectionService.noAccountGroupSelected$.pipe(untilDestroyed(this), delay(500)).subscribe(() => {
      // do not init fake data if data already initialized
      if (init) {
        return;
      }
      this.globalData = FakeGlobalData;
      this.minDate = LastWeek;
      this.maxDate = Today;
      this.data = FakeData;
      this.dataSet.buildDataSet(this.data, this.selectedMainMetric$.value, this.dateAggregation$.value, {
        minDate: this.minDate,
        maxDate: this.maxDate,
      });
    });
    this.mainSingleMetric$ = this.selectedMainMetric$.pipe(
      filter((m) => m.length > 0),
      map((m) => (m.length === 1 ? m[0] : m[1])),
    );

    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.dataSet.locale = user.locale;
    });
    this.userSelectionService.selectedCurrency$
      .pipe(untilDestroyed(this))
      .subscribe((currency) => (this.dataSet.currency = currency));

    this.accountSelectionService.accountMarketplacesSelection$
      .pipe(untilDestroyed(this))
      .subscribe((accountMarketplaces) => {
        if (accountMarketplaces.length > 0) {
          this.accountType = accountMarketplaces[0].accountType;
        }
      });
    combineLatest([this.accountSelectionService.accountMarketplacesSelection$, this.dateAggregation$])
      .pipe(untilDestroyed(this))
      .subscribe(([accountMarketplaces, dateAggregation]) => {
        if (accountMarketplaces.length > 1) {
          // disable event annotation
          this.disableEventAnnotation = true;
          if (this.displayEventAnnotation$.value) {
            this.displayEventAnnotation$.next(false);
          }
        } else {
          this.disableEventAnnotation = dateAggregation !== DateAggregation.daily;
        }
      });

    combineLatest<
      [AdsStatsWithPreviousPeriod, DateAggregation, Metric<AdStatsEx>[], string[], string[], DataSetEventAnnotation[]]
    >([
      this.statsService.globalDataByDate$,
      this.dateAggregation$,
      this.selectedMainMetric$,
      this.userSelectionService.dateRange$,
      this.userSelectionService.periodComparison$.pipe(map((x: { type: Comparison; period: string[] }) => x?.period)),
      combineLatest([this.dateAggregation$, this.displayEventAnnotation$]).pipe(
        switchMap(([agg, b]) => {
          if (agg == DateAggregation.daily && b) {
            return this.activityService.activityEventsAnnotations$;
          }
          return of([]);
        }),
      ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([adStats, a, m, dateRange, comparisonPeriod, annotations]) => {
        init = true;
        this.minDate = dateRange[0];
        this.maxDate = dateRange[1];
        this.data = adStats.data;
        this.globalData = {};
        for (const d of this.data) mergeSeveralDates(this.globalData, d);

        this.previousPeriodGlobalData = {};
        for (const d of adStats.previousPeriodData) mergeSeveralDates(this.previousPeriodGlobalData, d);

        if (this.previousPeriodGlobalData && Object.keys(this.previousPeriodGlobalData).length != 0)
          this.dataSet.buildDataSet(
            this.data,
            m,
            a,
            {
              minDate: this.minDate,
              maxDate: this.maxDate,
            },
            {
              data: adStats.previousPeriodData,
              period: comparisonPeriod,
            },
            annotations,
          );
        else {
          this.dataSet.buildDataSet(
            this.data,
            m,
            a,
            {
              minDate: this.minDate,
              maxDate: this.maxDate,
            },
            undefined,
            annotations,
          );
        }
      });

    this.accountSelectionService.accountGroupSelection$.pipe(untilDestroyed(this)).subscribe((x: AccountGroup) => {
      this.accountGroupName = x?.name;
    });
  }

  ngOnDestroy(): void {
    this.accountSelectionService.updateSingleMarketplaceMode(true);
  }

  selectMainMetrics(metrics: Metric<AdStatsEx>[]): void {
    this.selectedMainMetric$.next(metrics);
  }

  selectCustomMetrics(metrics: Metric<AdStatsEx>[]): void {
    this.selectedMetrics = [...metrics];
  }

  selectStrategies(strategies: Option[]): void {
    this.activityService.selectStrategies(strategies.map((s) => s.value));
  }

  public selectAggregation(selection: DateAggregation): void {
    this.dateAggregation$.next(selection);
  }

  toggleEventAnnotation(change: MatSlideToggleChange): void {
    this.displayEventAnnotation$.next(change.checked);
  }

  downloadFile(): void {
    const dateRange = this.userSelectionService.getDateRangeStr();
    const data = statGroupByKey(this.data, (x) => x.date + x.marketplace, addAdStats);
    const chartMetrics = this.accountType == AccountType.VENDOR ? this.CHART_METRICS_VENDOR : this.CHART_METRICS_SELLER;
    this.csvExportService.exportCsv(
      { prefix: "global_stats", accountGroupName: this.accountGroupName, dateRange: dateRange },
      data,
      [
        simpleField("marketplace"),
        simpleField("date"),
        simpleField("currency"),
        ...chartMetrics.map((m) => metricField(m)),
      ],
    );
  }
}
