// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    Marketplace,
    SharedReport,
} from '../models';

export interface DownloadSharedReportRequest {
    organizationId: number;
    reportDir: string;
    fileName: string;
    marketplace?: Marketplace;
}

export interface ListSharedReportsRequest {
    organizationId?: number;
}

/**
 * no description
 */
export class SharedDataApi extends BaseAPI {

    /**
     * Download a report
     * Download a report
     */
    downloadSharedReport({ organizationId, reportDir, fileName, marketplace }: DownloadSharedReportRequest): Observable<Blob>
    downloadSharedReport({ organizationId, reportDir, fileName, marketplace }: DownloadSharedReportRequest, opts?: OperationOpts): Observable<AjaxResponse<Blob>>
    downloadSharedReport({ organizationId, reportDir, fileName, marketplace }: DownloadSharedReportRequest, opts?: OperationOpts): Observable<Blob | AjaxResponse<Blob>> {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'downloadSharedReport');
        throwIfNullOrUndefined(reportDir, 'reportDir', 'downloadSharedReport');
        throwIfNullOrUndefined(fileName, 'fileName', 'downloadSharedReport');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'organizationId': organizationId,
            'reportDir': reportDir,
        };

        if (marketplace != null) { query['marketplace'] = marketplace; }

        return this.request<Blob>({
            url: '/data/report/{fileName}'.replace('{fileName}', encodeURI(fileName)),
            method: 'GET',
            headers,
            query,
            responseType: 'blob',
        }, opts?.responseOpts);
    };

    /**
     * List reports shared with organization
     * List reports shared with organization
     */
    listSharedReports({ organizationId }: ListSharedReportsRequest): Observable<Array<SharedReport>>
    listSharedReports({ organizationId }: ListSharedReportsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<SharedReport>>>
    listSharedReports({ organizationId }: ListSharedReportsRequest, opts?: OperationOpts): Observable<Array<SharedReport> | AjaxResponse<Array<SharedReport>>> {

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (organizationId != null) { query['organizationId'] = organizationId; }

        return this.request<Array<SharedReport>>({
            url: '/data/list',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

}
