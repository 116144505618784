import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { faSquare, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faArrowRight, faCheckSquare, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Marketplace, MatchType, Targeting } from "@front/m19-api-client";
import { CsvExportService, simpleField } from "@m19-board/services/csv-export.service";
import { IgnoredKeywordsModalComponent } from "@m19-board/shared/ignored-keywords-modal/ignored-keywords-modal.component";
import { SegmentService } from "libs/m19-services/src/lib/m19-services/segmentService";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { of, switchMap } from "rxjs";
import { SegmentEx } from "@front/m19-models";

interface Selectable<T> {
  selected: boolean;
  item: T;
}

@Component({
  selector: "app-keyword-segment-modal",
  templateUrl: "./keyword-segment-modal.component.html",
  styleUrls: ["./keyword-segment-modal.component.scss"],
})
export class KeywordSegmentModalComponent implements OnInit {
  @Input()
  set segment(value: SegmentEx) {
    this.existingSegment = value;
    this.name = value.name;
    this.datasourceKeyword.data = this.existingSegment
      .getKeywordTargetingItems()
      .map((s) => ({ selected: false, item: s }));
    this.marketplace = value.marketplace;
    this.accountId = value.accountId;
  }

  @Input()
  accountId: string;
  @Input()
  marketplace: Marketplace;
  @Input()
  initSegmentItems: Targeting[] = [];
  @Input() isReadOnly = false;

  @Output()
  segmentCreated = new EventEmitter<SegmentEx>();

  @Output()
  segmentEditionCanceled = new EventEmitter<void>();

  existingSegment: SegmentEx;
  name: string;
  bulkKeywords: string;
  datasourceKeyword = new MatTableDataSource<Selectable<Targeting>>();
  matchType = MatchType.exact;
  importErrors: string[];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  readonly faArrowRight = faArrowRight;
  readonly faTrash = faTrashAlt;
  readonly faSearch = faSearch;
  readonly faSquare = faSquare;
  readonly faCheckedSquare = faCheckSquare;
  readonly faTimes = faTimes;
  readonly MatchType = MatchType;

  constructor(
    public bsModalRef: BsModalRef,
    private segmentService: SegmentService,
    private toastrService: ToastrService,
    private csvExportService: CsvExportService,
    private modalService: BsModalService,
  ) {}

  ngOnInit(): void {
    this.datasourceKeyword.paginator = this.paginator;
    this.datasourceKeyword.sort = this.sort;
    this.datasourceKeyword.sortingDataAccessor = (item, property): string | number => {
      if (property == "matchType") return item.item.matchType;
      if (property == "keyword") return item.item.targetingValue;
    };
    this.datasourceKeyword.filterPredicate = (row, filter) => {
      if (filter) {
        const regexp = new RegExp(filter, "i");
        const result = regexp.test(row.item.targetingValue);
        return result;
      }
      return true;
    };
    if (this.initSegmentItems.length > 0) {
      const { errors, keywords } = SegmentService.checkKeywords(
        this.initSegmentItems,
        this.datasourceKeyword.data.map((s) => s.item),
      );
      this.datasourceKeyword.data = keywords.map((s) => ({ selected: false, item: s }));
      this.importErrors = errors;
    }
  }

  displayType(type: MatchType) {
    if (type == MatchType.exact) {
      return "keyword-segment-modal.exact";
    }
    if (type == MatchType.phrase) {
      return "keyword-segment-modal.phrase";
    }
    return "keyword-segment-modal.asinsameas";
  }
  close() {
    this.segmentEditionCanceled.emit();
    this.bsModalRef.hide();
  }

  addKeyword() {
    if (this.keywordToAddInvalid()) {
      return;
    }
    const kw: Targeting[] = this.bulkKeywords.split("\n").map((k) => {
      return { targetingValue: k, matchType: this.matchType };
    });
    const { errors, keywords } = SegmentService.checkKeywords(
      kw,
      this.datasourceKeyword.data.map((s) => s.item),
    );
    this.datasourceKeyword.data = keywords.map((s) => ({ selected: false, item: s }));
    this.importErrors = errors;
    this.bulkKeywords = "";
  }

  deleteSelectedKeywords() {
    this.datasourceKeyword.data = this.datasourceKeyword.data.filter((s) => !s.selected);
  }

  deleteKeyword(keyword: Targeting) {
    this.datasourceKeyword.data = this.datasourceKeyword.data.filter(
      (k) => k.item.matchType != keyword.matchType || k.item.targetingValue != keyword.targetingValue,
    );
  }

  setFilter(filter: string) {
    this.datasourceKeyword.filter = filter.trim();
  }

  select(keyword: Selectable<Targeting>) {
    keyword.selected = !keyword.selected;
  }

  toggleSelectAll() {
    const selected = this.allSelected();
    this.datasourceKeyword.filteredData.forEach((d) => (d.selected = !selected));
  }

  nbSelectedKw() {
    return this.datasourceKeyword.filteredData.filter((s) => s.selected).length;
  }

  allSelected() {
    return this.datasourceKeyword.filteredData.every((s) => s.selected);
  }

  setMatchType(matchType: MatchType) {
    this.matchType = matchType;
  }

  keywordToAddInvalid() {
    return !this.bulkKeywords || this.bulkKeywords.trim() === "";
  }

  clearErrors() {
    this.importErrors = [];
  }

  saveSegment() {
    if (this.existingSegment) {
      this.updateSegment();
    } else {
      this.createSegment();
    }
  }

  private updateSegment() {
    const subscription = (
      this.name !== this.existingSegment.name
        ? this.segmentService.updateSegmentName(
            this.accountId,
            this.marketplace,
            this.existingSegment.segmentId,
            this.name,
          )
        : of(this.existingSegment)
    )
      .pipe(
        switchMap((segment) => {
          const kwToDelete = [];
          for (const kw of this.existingSegment.getKeywordTargetingItems()) {
            if (
              this.datasourceKeyword.data.findIndex(
                (s) => s.item.matchType == kw.matchType && s.item.targetingValue == kw.targetingValue,
              ) < 0
            ) {
              kwToDelete.push(kw);
            }
          }
          if (kwToDelete.length == 0) {
            return of(segment);
          }
          return this.segmentService.deleteItemsFromSegment(
            this.accountId,
            this.marketplace,
            this.existingSegment.segmentId,
            kwToDelete,
          );
        }),
        switchMap((segment) => {
          const kwToAdd = [];
          for (const kw of this.datasourceKeyword.data) {
            if (
              this.existingSegment
                .getKeywordTargetingItems()
                .findIndex((s) => s.targetingValue == kw.item.targetingValue && s.matchType == kw.item.matchType) < 0
            ) {
              kwToAdd.push(kw.item);
            }
          }
          if (kwToAdd.length == 0) {
            return of(segment);
          }
          return this.segmentService.addKeywordsToSegment(
            this.accountId,
            this.marketplace,
            this.existingSegment.segmentId,
            kwToAdd,
          );
        }),
      )
      .subscribe({
        next: (s) => {
          this.toastrService.success(`Segment ${s.name} updated`, "Segment updated");
          this.bsModalRef.hide();
        },
        error: (err) => {
          this.toastrService.error("Error updating segment: " + err, "Segment update error");
          subscription.unsubscribe();
        },
      });
  }

  private createSegment() {
    const subscription = this.segmentService
      .createSegment(this.accountId, this.marketplace, this.name)
      .pipe(
        switchMap((segment: SegmentEx) => {
          return this.segmentService.addKeywordsToSegment(
            this.accountId,
            this.marketplace,
            segment.segmentId,
            this.datasourceKeyword.data.map((s) => s.item),
          );
        }),
      )
      .subscribe({
        next: (s) => {
          this.toastrService.success(`Segment ${s.name} created`, "Segment created");
          this.segmentCreated.emit(s);
          this.bsModalRef.hide();
        },
        error: (err) => {
          this.toastrService.error("Error creating segment: " + err, "Segment creation error");
          subscription.unsubscribe();
        },
      });
  }

  isInvalid() {
    return !this.name || this.datasourceKeyword.data.length == 0;
  }

  invalidTooltip() {
    if (!this.name) {
      return "Enter a name for this keyword segment";
    }
    if (this.datasourceKeyword.data.length == 0) {
      return "Set keywords for this keyword segment";
    }
    return "";
  }

  downloadFile() {
    this.csvExportService.exportCsv(
      {
        prefix: this.name + "_keywords",
      },
      this.datasourceKeyword.data.map((s) => s.item),
      [simpleField("matchType"), simpleField("targetingValue", "keyword")],
    );
  }

  openIgnoredKeywordModal() {
    const errorObjs: { kw: string; reason: string }[] = [];
    for (const error of this.importErrors) {
      const splitted = error.split(":");
      errorObjs.push({ kw: splitted[0].trim(), reason: splitted[1].trim() });
    }
    this.modalService.show(IgnoredKeywordsModalComponent, {
      initialState: {
        errors: errorObjs,
      },
      class: "modal-lg",
    });
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
