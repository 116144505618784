import { Component, inject } from "@angular/core";
import { LayoutSelectorService } from "@m19-board/layout-selector.service";

@Component({
  selector: "logo",
  template: `<img [src]="navbarBrandFull.src" [height]="navbarBrandFull.height" [alt]="navbarBrandFull.alt" />`,
  standalone: true,
})
export class LogoComponent {
  private readonly layoutSelectorService = inject(LayoutSelectorService);
  readonly navbarBrandFull = {
    src: this.layoutSelectorService.getLogo(),
    height: 45,
    alt: "logo",
  };
}
