import { EligibilityStatus } from '../api-client';

export class EligibilityUtils {
  public static priority: EligibilityStatus[] = [
    EligibilityStatus.ELIGIBLE,
    EligibilityStatus.UNKNOWN,
    EligibilityStatus.ELIGIBLE_WITH_WARNING,
    EligibilityStatus.INELIGIBLE,
    EligibilityStatus.LAST_PRODUCT_AD_CREATION_FAILED,
    EligibilityStatus.NOT_AUTHORIZED,
  ];

  public static compare(a: EligibilityStatus, b: EligibilityStatus | undefined): number {
    if (a === b) return 0;
    if (!b) return 1;
    const pa = EligibilityUtils.priority.reverse().indexOf(a);
    const pb = EligibilityUtils.priority.reverse().indexOf(b);

    return pa - pb;
  }

  public static status(a: EligibilityStatus): boolean {
    return (
      a !== EligibilityStatus.INELIGIBLE &&
      a !== EligibilityStatus.NOT_AUTHORIZED &&
      a !== EligibilityStatus.LAST_PRODUCT_AD_CREATION_FAILED
    );
  }
}
