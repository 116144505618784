import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import { TranslocoDirective } from "@jsverse/transloco";
import { IButtonComponent } from "@front/m19-ui";

@Component({
  selector: "app-input-modal",
  templateUrl: "./input-modal.component.html",
  styleUrls: ["./input-modal.component.scss"],
  standalone: true,
  imports: [TranslocoDirective, ReactiveFormsModule, IButtonComponent],
})
export class InputModalComponent {
  @Input() title?: string;
  @Input() maxLength?: number;
  @Input() inputControl!: FormControl;
  @Output() emitUpdate = new EventEmitter();

  constructor(protected bsModalRef: BsModalRef) {}

  update() {
    this.emitUpdate.emit();
    this.bsModalRef.hide();
  }
}
