// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    Marketplace,
} from './';

/**
 * @export
 * @interface TacosStrategyGroup
 */
export interface TacosStrategyGroup {
    /**
     * Amazon Seller or Vendor accountId
     * @type {string}
     * @memberof TacosStrategyGroup
     */
    accountId?: string;
    /**
     * @type {Marketplace}
     * @memberof TacosStrategyGroup
     */
    marketplace?: Marketplace;
    /**
     * @type {number}
     * @memberof TacosStrategyGroup
     */
    tacosStrategyGroupId?: number;
    /**
     * @type {string}
     * @memberof TacosStrategyGroup
     */
    state?: TacosStrategyGroupStateEnum;
    /**
     * @type {number}
     * @memberof TacosStrategyGroup
     */
    tacosTarget?: number;
    /**
     * @type {number}
     * @memberof TacosStrategyGroup
     */
    spStrategyId?: number;
    /**
     * @type {number}
     * @memberof TacosStrategyGroup
     */
    spSpendRepartition?: number;
    /**
     * @type {number}
     * @memberof TacosStrategyGroup
     */
    sbStrategyId?: number;
    /**
     * @type {number}
     * @memberof TacosStrategyGroup
     */
    sbSpendRepartition?: number;
    /**
     * @type {number}
     * @memberof TacosStrategyGroup
     */
    sdStrategyId?: number;
    /**
     * @type {number}
     * @memberof TacosStrategyGroup
     */
    sdSpendRepartition?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum TacosStrategyGroupStateEnum {
    PAUSED = 'PAUSED',
    ENABLED = 'ENABLED'
}

