// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    AccessLevel,
    AccountState,
    AccountSubType,
    AccountType,
    BidderRun,
    Marketplace,
} from './';

/**
 * @export
 * @interface AccountMarketplace
 */
export interface AccountMarketplace {
    /**
     * @type {string}
     * @memberof AccountMarketplace
     */
    accountId: string;
    /**
     * @type {Marketplace}
     * @memberof AccountMarketplace
     */
    marketplace: Marketplace;
    /**
     * @type {number}
     * @memberof AccountMarketplace
     */
    accountGroupId?: number;
    /**
     * @type {AccountState}
     * @memberof AccountMarketplace
     */
    state?: AccountState;
    /**
     * @type {string}
     * @memberof AccountMarketplace
     */
    accountName: string;
    /**
     * @type {AccountType}
     * @memberof AccountMarketplace
     */
    accountType?: AccountType;
    /**
     * @type {AccountSubType}
     * @memberof AccountMarketplace
     */
    accountSubType?: AccountSubType;
    /**
     * @type {number}
     * @memberof AccountMarketplace
     */
    profileId?: number;
    /**
     * @type {string}
     * @memberof AccountMarketplace
     */
    dspAdvertiserId?: string;
    /**
     * @type {number}
     * @memberof AccountMarketplace
     */
    bidderOrganizationId?: number;
    /**
     * @type {string}
     * @memberof AccountMarketplace
     */
    bidderStartDate?: string;
    /**
     * @type {string}
     * @memberof AccountMarketplace
     */
    bidderEndDate?: string;
    /**
     * @type {number}
     * @memberof AccountMarketplace
     */
    resourceOrganizationId?: number;
    /**
     * @type {boolean}
     * @memberof AccountMarketplace
     */
    resourceDeleted?: boolean;
    /**
     * @type {boolean}
     * @memberof AccountMarketplace
     */
    isLegacy?: boolean;
    /**
     * @type {string}
     * @memberof AccountMarketplace
     */
    accountGroupName?: string;
    /**
     * @type {string}
     * @memberof AccountMarketplace
     */
    campaignLabel?: string;
    /**
     * @type {string}
     * @memberof AccountMarketplace
     */
    customCampaignName?: string;
    /**
     * @type {number}
     * @memberof AccountMarketplace
     */
    maxBid?: number;
    /**
     * @type {number}
     * @memberof AccountMarketplace
     */
    minDailyBudgetLimit?: number;
    /**
     * @type {number}
     * @memberof AccountMarketplace
     */
    minBid?: number;
    /**
     * @type {string}
     * @memberof AccountMarketplace
     */
    customerId?: string;
    /**
     * @type {string}
     * @memberof AccountMarketplace
     */
    customerName?: string;
    /**
     * @type {string}
     * @memberof AccountMarketplace
     */
    lastAllStatsSync?: string;
    /**
     * @type {boolean}
     * @memberof AccountMarketplace
     */
    isValidToken?: boolean;
    /**
     * @type {boolean}
     * @memberof AccountMarketplace
     */
    adSyncForced?: boolean;
    /**
     * @type {boolean}
     * @memberof AccountMarketplace
     */
    mwsSyncForced?: boolean;
    /**
     * @type {boolean}
     * @memberof AccountMarketplace
     */
    activated?: boolean;
    /**
     * @type {boolean}
     * @memberof AccountMarketplace
     */
    hasAccessToAdvertising?: boolean;
    /**
     * @type {boolean}
     * @memberof AccountMarketplace
     */
    vendorManufacturingAccess?: boolean;
    /**
     * @type {boolean}
     * @memberof AccountMarketplace
     */
    useSourcingMetrics?: boolean;
    /**
     * @type {AccessLevel}
     * @memberof AccountMarketplace
     */
    accessLevel?: AccessLevel;
    /**
     * @type {string}
     * @memberof AccountMarketplace
     */
    promoStartDate?: string;
    /**
     * @type {string}
     * @memberof AccountMarketplace
     */
    promoEndDate?: string;
    /**
     * @type {number}
     * @memberof AccountMarketplace
     */
    approvedBy?: number;
    /**
     * @type {string}
     * @memberof AccountMarketplace
     */
    vendorDataCleaningRequested?: string;
    /**
     * @type {number}
     * @memberof AccountMarketplace
     */
    nbAsinsToValidate?: number;
    /**
     * @type {{ [key: string]: BidderRun; }}
     * @memberof AccountMarketplace
     */
    bidderRuns?: { [key: string]: BidderRun; };
}
