import { AgGridAngular } from "@ag-grid-community/angular";
import { GridOptions } from "@ag-grid-community/core";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { AccountSelectionService, CatalogBrandService } from "@front/m19-services";
import { IButtonComponent } from "@front/m19-ui";
import { TranslocoService } from "@jsverse/transloco";
import { actionColumnProperties } from "@m19-board/grid-config/grid-columns";
import { exportGridCsv, getCsvFileName } from "@m19-board/grid-config/grid-config";
import {
  ActionButton,
  ActionButtonsComponent,
} from "@m19-board/insights/overview/action-buttons/action-buttons.component";
import { InputModalComponent } from "@m19-board/shared/input-modal/input-modal.component";
import { ICON_EDIT_O, ICON_LINK } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { switchMap, take, tap } from "rxjs";
import { BrandAliasModalComponent } from "./brand-alias-modal.component";
import { BrandAsinsModalComponent } from "./brand-asins-modal.component";
import { BrandMergeModalComponent } from "./brand-merge-modal.component";
import { CatalogBrandEx } from "@front/m19-models";
import { getBasicGridOptions } from "@front/m19-grid-config";
import { AccessLevel, AccountMarketplace } from "@front/m19-api-client";

@UntilDestroy()
@Component({
  selector: "app-brand-alias",
  templateUrl: "./brand-alias.component.html",
})
export class BrandAliasComponent implements OnInit {
  loading = true;
  brandAliases: CatalogBrandEx[] = [];
  accountMarketplace: AccountMarketplace;
  isReadOnly: boolean;

  @ViewChild(AgGridAngular) brandGrid!: AgGridAngular;

  readonly editIconProperties = {
    color: "gray",
    variant: "ghost",
    square: true,
    icon: ICON_EDIT_O,
    iconOnHover: true,
    trailing: true,
  };
  readonly linkIconProperties = {
    color: "gray",
    variant: "ghost",
    square: true,
    icon: ICON_LINK,
    iconOnHover: true,
    trailing: true,
  };

  readonly gridOptions: GridOptions<CatalogBrandEx> = {
    ...getBasicGridOptions("catalogBrand"),
    sideBar: false,
    defaultColDef: {
      flex: 1,
      resizable: true,
      suppressMovable: true,
      menuTabs: ["filterMenuTab"],
    },
    getRowStyle: (params) => {
      if (params.node.group || params.node.parent.allChildrenCount == 1) {
        return { "background-color": "white !important" };
      }
    },
    suppressRowHoverHighlight: true,
    groupRemoveSingleChildren: true,
    groupDefaultExpanded: -1,
    groupDisplayType: "custom",
    columnDefs: [
      {
        colId: "name",
        headerValueGetter: () => this.translocoService.translate("v2-sidebar.brand_alias"),
        headerName: this.translocoService.translate("v2-sidebar.brand_alias", {}, "en"),
        showRowGroup: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cellClass: "sensitive-data",
        cellRendererSelector: (params) => {
          // if (params.node.group) {
          return {
            component: "agGroupCellRenderer",
            params: {
              innerRendererSelector: (params) => {
                if (!params.node.group && params.node.parent.allChildrenCount > 1) {
                  return;
                }
                const brandId = params.node.group
                  ? params.node.allLeafChildren[0].data.parentBrandId
                  : params.data.brandId;
                return {
                  component: IButtonComponent,
                  params: {
                    tooltipValue: this.translocoService.translate("brand-alias.edit_brand_name"),
                    ...this.editIconProperties,
                    clickAction: () => {
                      const brandNameInput = new FormControl<string>(params.value);
                      const ref = this.modalService.show(InputModalComponent, {
                        initialState: {
                          title: this.translocoService.translate("brand-alias.change_name_title"),
                          inputControl: brandNameInput,
                          maxLength: 80,
                        },
                        class: "modal-primary modal-dialog-centered",
                      });
                      ref.content.emitUpdate
                        .pipe(
                          take(1),
                          switchMap(() => {
                            const newName = brandNameInput.value.trim();
                            return this.catalogBrandService.setBrandAlias(
                              this.accountMarketplace.accountId,
                              this.accountMarketplace.marketplace,
                              brandId,
                              newName,
                            );
                          }),
                        )
                        .subscribe({
                          next: () => {
                            this.toastrService.success(
                              this.translocoService.translate("brand-alias.update_name_success"),
                            );
                          },
                          error: (e) => {
                            this.toastrService.error(
                              e,
                              this.translocoService.translate("brand-alias.update_name_error"),
                            );
                          },
                        });
                    },
                  },
                };
              },
            },
          };
        },
      },
      {
        headerValueGetter: () => this.translocoService.translate("brand-alias.parent_brand"),
        headerName: this.translocoService.translate("brand-alias.parent_brand", {}, "en"),
        field: "parentBrandAlias",
        rowGroup: true,
        hide: true,
      },
      {
        headerValueGetter: () => this.translocoService.translate("brand-alias.brand_from_catalog"),
        headerName: this.translocoService.translate("brand-alias.brand_from_catalog", {}, "en"),
        field: "brandName",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cellClass: "sensitive-data",
      },
      {
        headerValueGetter: () => this.translocoService.translate("common.asins"),
        headerName: this.translocoService.translate("common.asins", {}, "en"),
        field: "brandProducts",
        valueGetter: (params) => {
          if (params.node.group) {
            return params.node.allLeafChildren.map((child) => child.data.brandProducts).reduce((a, b) => a + b, 0);
          }
          return params.data.brandProducts;
        },
        cellRendererSelector: (params) => {
          if (params.node.group) {
            return;
          }

          return {
            component: IButtonComponent,
            params: {
              tooltipValue: this.translocoService.translate("common.view_asin"),
              ...this.linkIconProperties,
              iconOnHover: true,
              label: params.value,
              clickAction: () => {
                this.modalService.show(BrandAsinsModalComponent, {
                  initialState: {
                    accountId: this.accountMarketplace.accountId,
                    marketplace: this.accountMarketplace.marketplace,
                    brandName: params.data.brandName,
                  },
                  class: "modal-primary modal-dialog-centered modal-xl",
                });
              },
            },
          };
        },
      },
      {
        colId: "otherNames",
        headerValueGetter: () => this.translocoService.translate("brand-alias.other_brand_names"),
        headerName: this.translocoService.translate("brand-alias.other_brand_names", {}, "en"),
        valueGetter: (params) => {
          if (params.node.group) {
            return;
          }
          return params.data?.extraSegments.length;
        },
        cellRendererSelector: (params) => {
          if (params.node.group) {
            return;
          }
          const label =
            params.value == 0 ? "" : this.translocoService.translate("brand-alias.other_name", { count: params.value });
          return {
            component: IButtonComponent,
            params: {
              tooltipValue: this.translocoService.translate("brand-alias.edit_other_brand"),
              ...this.editIconProperties,
              iconOnHover: false,
              label,
              clickAction: () => {
                this.modalService.show(BrandAliasModalComponent, {
                  initialState: {
                    catalogBrand: params.data,
                    accountId: this.accountMarketplace.accountId,
                    marketplace: this.accountMarketplace.marketplace,
                    isReadOnly: this.accountMarketplace.accessLevel !== AccessLevel.READ,
                  },
                  class: "modal-primary modal-dialog-centered modal-xl",
                });
              },
            },
          };
        },
      },
      {
        ...actionColumnProperties<CatalogBrandEx, string>(),
        headerValueGetter: () => this.translocoService.translate("brand-alias.actions"),
        headerName: this.translocoService.translate("brand-alias.actions", {}, "en"),
        cellRendererSelector: (params) => {
          const actionButtons: ActionButton[] = [];
          if (!params.data) {
            return;
          }
          if (this.isReadOnly || this.brandAliases?.length <= 1) {
            return;
          }
          if (params.data.parentBrandId == undefined) {
            actionButtons.push({
              icon: "icon-[material-symbols--link]",
              tooltip: this.translocoService.translate("brand-alias.merge_with_other_brand"),
              onClick: () => {
                const modalRef = this.modalService.show(BrandMergeModalComponent, {
                  initialState: {
                    catalogBrand: params.data,
                  },
                  class: "modal-primary modal-dialog-centered",
                });
                modalRef.content.selectedBrand
                  .pipe(
                    untilDestroyed(this),
                    take(1),
                    switchMap((selectedBrand) => {
                      return this.catalogBrandService.mergeBrands(
                        this.accountMarketplace.accountId,
                        this.accountMarketplace.marketplace,
                        params.data.brandId,
                        selectedBrand.brandId,
                      );
                    }),
                  )
                  .subscribe({
                    next: () => {
                      this.toastrService.success(this.translocoService.translate("brand-alias.brand_merge_success"));
                      this.brandGrid.api?.refreshCells();
                    },
                    error: (e) => {
                      this.toastrService.error(e, this.translocoService.translate("brand-alias.brand_merge_error"));
                    },
                  });
              },
            });
          } else if (params.data.parentBrandId !== params.data.brandId) {
            actionButtons.push({
              icon: "icon-[mdi--link-variant-off]",
              tooltip: this.translocoService.translate("brand-alias.unmerge"),
              onClick: () => {
                this.catalogBrandService
                  .unmergeBrands(
                    this.accountMarketplace.accountId,
                    this.accountMarketplace.marketplace,
                    params.data.brandId,
                  )
                  .subscribe({
                    next: () => {
                      this.toastrService.success(this.translocoService.translate("brand-alias.brand_unmerge_success"));
                    },
                    error: (e) => {
                      this.toastrService.error(e, this.translocoService.translate("brand-alias.brand_unmerge_error"));
                    },
                  });
              },
            });
          }
          return {
            component: ActionButtonsComponent,
            params: {
              actionButtons,
            },
          };
        },
      },
    ],
  };

  constructor(
    private accountSelectionService: AccountSelectionService,
    private catalogBrandService: CatalogBrandService,
    private modalService: BsModalService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService,
  ) {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((accountMarketplace) => {
        this.accountMarketplace = accountMarketplace;
      });
    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((readOnly) => {
      this.isReadOnly = readOnly;
    });
  }

  ngOnInit(): void {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        tap(() => (this.loading = true)),
        switchMap((accountMarketplace: AccountMarketplace) => {
          return this.catalogBrandService.getCatalogBrands(
            accountMarketplace.accountId,
            accountMarketplace.marketplace,
          );
        }),
      )
      .subscribe((brandAliases) => {
        this.brandAliases = brandAliases;
        this.brandGrid?.api?.updateGridOptions({ rowData: brandAliases });
        this.loading = false;
      });
  }

  exportAsCsv() {
    const fileName = getCsvFileName(
      "brand_alias",
      this.accountMarketplace.accountGroupName,
      this.accountMarketplace.marketplace,
    );
    exportGridCsv(this.brandGrid.api, {
      fileName,
      skipRowGroups: true,
      columnKeys: ["name", "brandName", "brandProducts", "otherNames"],
    });
  }
}
