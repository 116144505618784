<ng-container *transloco="let t">
  @if (hasACreditCard()) {
    <div class="flex flex-wrap">
      @for (creditCard of customer.creditCards; track creditCard) {
        <div
          [ngClass]="{ 'default-credit-card-container': creditCard.id === customer.stripeCardId }"
          class="card-container flex flex-col p-3"
        >
          @if (creditCard.id !== customer.stripeCardId) {
            <div class="credit-card-buttons absolute"></div>
          }
          <div class="credit-card">
            <div class="credit-card-header">
              <div class="card-brand">
                @if ($any(CreditCardBrands)[creditCard.network ?? creditCard.brand!] !== undefined) {
                  <img
                    [src]="$any(CreditCardBrands)[creditCard.network ?? creditCard.brand!].icon"
                    [alt]="creditCard.network ?? creditCard.brand | uppercase"
                    width="80"
                    [matTooltip]="$any(CreditCardBrands)[creditCard.network ?? creditCard.brand!].name"
                    class="card-brand-logo"
                  />
                } @else {
                  {{ creditCard.network ?? creditCard.brand | uppercase }}
                }
              </div>
              <div class="card-logo">
                <span class="icon-[lucide--dollar-sign]"></span>
              </div>
            </div>
            <div class="card-number coding-font">**** **** **** {{ creditCard.last4Digits }}</div>
            <div class="expiration coding-font">EXP: {{ creditCard.expirationDate | date: "yyyy-MM" }}</div>
          </div>
          <div class="align-items-middle mt-2 flex justify-end">
            @if (creditCard.id !== customer.stripeCardId) {
              <button
                type="button"
                class="submenu-btn btn btn-secondary mr-2"
                (click)="deleteCreditCard(creditCard)"
                matTooltip="{{ t('credit-card-info.delete_this_credit_card') }}"
              >
                <span [class]="ICON_TRASH_O"></span>
              </button>
            }
            @if (creditCard.id === customer.stripeCardId) {
              <div class="btn btn-primary" matTooltip="{{ t('credit-card-info.card_in_charge') }}">
                {{ t("common.default") }}
              </div>
            }
            @if (creditCard.id !== customer.stripeCardId) {
              <button
                type="button"
                class="btn btn-secondary"
                (click)="setAsDefaultCreditCard(creditCard)"
                matTooltip="{{ t('credit-card-info.set_as_default_credit_card') }}"
              >
                {{ t("credit-card-info.set_as_default") }}
              </button>
            }
          </div>
        </div>
      }
    </div>
  }
  @if (!hasACreditCard()) {
    <div>{{ t("credit-card-info.no_credit_card") }}</div>
  }
</ng-container>
