<div class="card-perso left-child right-child border-shadow card relative mb-2 w-full pt-4" *transloco="let t">
  <div class="absolute left-3 top-3">
    <span
      class="mr-2 text-gray-400 hover:text-gray-500"
      matTooltip="{{ t('creative-display.click_to_copy') }}"
      (click)="copyCreativeId()"
    >
      #{{ creative.creativeId }}
    </span>

    <IBadge color="gray" [label]="t(this.getCreativeTypeStr(creative.creativeType))" />
  </div>

  <div class="submenu btn-group">
    <button
      type="button"
      class="submenu-btn btn btn-secondary action-icons"
      (click)="$event.stopPropagation(); toggleStatus()"
      [disabled]="disabled"
      [matTooltip]="
        creative.state === 'ON' ? t('creative-display.pause_ad_line') : t('creative-display.reactivate_ad_line')
      "
      matTooltipPosition="above"
    >
      <fa-icon [icon]="creative.state === 'ON' ? faPause : faPlay"></fa-icon>
    </button>
    <!-- Prevent edition for store spotlight creatives -->
    <button
      type="button"
      class="submenu-btn btn btn-secondary action-icons"
      [disabled]="disabled"
      matTooltip="{{ t('creative-display.edit_ad_line') }}"
      matTooltipPosition="above"
      (click)="editCreative()"
    >
      <fa-icon [icon]="faPencil"></fa-icon>
    </button>
    @if (disableDeletion === false) {
      <button
        type="button"
        class="submenu-btn btn btn-secondary action-icons delete-icon"
        (click)="$event.stopPropagation(); deleteCreative()"
        [disabled]="disabled"
        [matTooltip]="t('creative-display.delete_ad_line')"
        matTooltipPosition="above"
      >
        <fa-icon [icon]="faTrash"></fa-icon>
      </button>
    }
  </div>
  <div class="row w-full items-center pb-3 pt-6" [class.deactivated]="creative.state === 'OFF'">
    <!-- Display Active/Inactive Creative -->
    <div class="col-1 flex items-center">
      @if (creative.state === "ON") {
        <fa-icon
          [icon]="
            status &&
            status !== M19Status.IGNORED_CREATIVE_ASINS_INELIGIBLE &&
            status !== M19Status.IGNORED_LOW_INVENTORY_ASINS
              ? faExclamationCircle
              : faPlayCircle
          "
          class="ml-3"
          [ngClass]="
            status &&
            status !== M19Status.IGNORED_CREATIVE_ASINS_INELIGIBLE &&
            status !== M19Status.IGNORED_LOW_INVENTORY_ASINS
              ? 'danger'
              : 'text-primary'
          "
          size="2x"
          [matTooltip]="
            status &&
            status !== M19Status.IGNORED_CREATIVE_ASINS_INELIGIBLE &&
            status !== M19Status.IGNORED_LOW_INVENTORY_ASINS
              ? faultyReason
              : t('subscription-card.status_active')
          "
        ></fa-icon>
      }
      @if (creative.state !== "ON") {
        <fa-icon
          [icon]="faPauseCircle"
          class="desactivated ml-3"
          size="2x"
          matTooltip="{{ t('creative-display.status_inactive') }}"
        ></fa-icon>
      }
    </div>

    <!-- StorePage and Product Collection -->
    @if (
      creative.creativeType === SbCreativeType.productCollection ||
      creative.creativeType === SbCreativeType.storeSpotlight
    ) {
      <div class="col-1">
        <div class="brand-logo-view sensitive-data flex items-center justify-center">
          @if (brandAssets?.logoAsset?.url; as url) {
            <img
              class="max-h-full w-full max-w-full shadow"
              matTooltip="{{ t('creative-display.brand_logo') }}"
              [src]="url"
            />
          }
          @if (status === M19Status.IGNORED_INVALID_BRAND_LOGO || status === M19Status.REJECTED_IMAGE) {
            <div class="top-left circle absolute flex items-center justify-center bg-white" [style.z-index]="100">
              <fa-icon
                [icon]="faExclamationCircle"
                class="warning"
                size="2x"
                [matTooltip]="faultyCreativeReason"
              ></fa-icon>
            </div>
          }
          @if (!brandAssets?.logoAsset?.url) {
            <div class="flex flex-col justify-center">
              <fa-icon
                [icon]="faImage"
                class="asset-alt mb-1 text-center"
                matTooltip="{{ t('creative-display.no_preview_available') }}"
              ></fa-icon>
              <div>{{ t("creative-display.no_preview_available") }}</div>
            </div>
          }
        </div>
      </div>
      @if (creative.creativeType === SbCreativeType.productCollection) {
        <div class="col-1 ml-4">
          <div class="brand-logo-view flex h-24 items-center justify-center">
            @if (brandAssets?.customImage) {
              @if (brandAssets!.customImage?.url) {
                <app-carousel
                  [images]="[
                    brandAssets!.customImage!.url,
                    brandAssets!.customImage2?.url,
                    brandAssets!.customImage3?.url,
                    brandAssets!.customImage4?.url,
                    brandAssets!.customImage5?.url,
                  ]"
                  class="h-full w-full"
                ></app-carousel>
                <!--<img class="shadow max-w-full max-h-full" matTooltip="Custom Image" [src]="creative.customImage.url"/>-->
              }
              @if (status === M19Status.IGNORED_INVALID_CUSTOM_IMAGE || status === M19Status.REJECTED_IMAGE) {
                <div class="top-left circle absolute flex items-center justify-center bg-white" [style.z-index]="100">
                  <fa-icon
                    [icon]="faExclamationCircle"
                    class="warning"
                    size="2x"
                    [matTooltip]="faultyCreativeReason"
                  ></fa-icon>
                </div>
              }
            } @else {
              @if (!customImageLoading) {
                <div
                  class="custom-img-placeholder relative border-2 border-solid border-red-500 bg-red-100 text-red-500 hover:bg-red-200"
                  matTooltip="{{ t('creative-display.click_to_upload_a_custom_image') }}"
                >
                  Upload custom image
                  <app-media-input
                    [mediaType]="MediaType.customImage"
                    [medias]="customImages"
                    (mediaSaved)="updateCreativeCustomImage($event)"
                    (toUpload)="uploadCreativeCustomImage($event)"
                  />
                </div>
              } @else {
                <ISpinner [display]="true" />
              }
            }
          </div>
        </div>
      }
      <div class="col-2">
        <span class="creative-header"
          >{{ t("sb-form-creative.headline") }}
          @if (status === M19Status.PUSH_FAILED_INVALID_HEADLINE || status === M19Status.REJECTED_HEADLINE) {
            <span
              ><fa-icon
                class="warning ml-1"
                [icon]="faExclamationCircle"
                size="1x"
                [matTooltip]="faultyCreativeReason"
              ></fa-icon
            ></span>
          }
        </span>
        <div class="creative-info" (click)="$event.stopPropagation()">
          <span>{{ creative.headline }}</span>
        </div>
        @if (brandAssets?.storePage) {
          <div>
            <span class="creative-header">{{ t("creative-display.store_page") }}</span>
            <div class="creative-info sensitive-data" (click)="$event.stopPropagation()">
              <span>
                <a [href]="brandAssets!.storePage!.url" target="_blank">{{ brandAssets!.storePage!.name }}</a>
              </span>
            </div>
          </div>
        }
      </div>
      @if (hasNoAsins) {
        <div>{{ t("creative-display.no_asin") }}</div>
      }
      @for (cluster of creative.creativeAsins | slice: 0 : 2; track cluster; let i = $index) {
        <div class="col-2 items-center">
          @if (creative.creativeType === SbCreativeType.productCollection) {
            <span class="creative-header"
              >{{ i === 0 ? t("creative-display.first_ad_line") : t("creative-display.second_ad_line") }}
              @if (
                status === M19Status.IGNORED_CREATIVE_ASINS_INELIGIBLE ||
                status === M19Status.IGNORED_LOW_INVENTORY_ASINS ||
                status === M19Status.PUSH_FAILED_INVALID_ASINS ||
                status === M19Status.IGNORED_ASINS_NOT_IN_STORE_PAGE ||
                status === M19Status.IGNORED_NOT_ENOUGH_ASINS_FOR_LANDING_PAGE ||
                status === M19Status.REJECTED_ASIN
              ) {
                <span
                  ><fa-icon
                    class="warning ml-1"
                    [icon]="faExclamationCircle"
                    size="1x"
                    [matTooltip]="faultyCreativeReason"
                  ></fa-icon
                ></span>
              }
            </span>
          }
          @if (creative.creativeType === SbCreativeType.storeSpotlight) {
            <span class="creative-header"> {{ t("creative-display.store_pages") }} </span>
          }
          <div class="creative-info sensitive-data">
            <img
              class="product-icon"
              [matTooltip]="creative.creativeType === SbCreativeType.productCollection ? cluster.asin1 : cluster.title1"
              src="{{ getProductImage(cluster.asin1) | async }}"
            />
            @if (cluster.asin2) {
              <img
                class="product-icon"
                [matTooltip]="
                  creative.creativeType === SbCreativeType.productCollection ? cluster.asin2 : cluster.title2
                "
                src="{{ getProductImage(cluster.asin2) | async }}"
              />
            }
            @if (cluster.asin3) {
              <img
                class="product-icon"
                [matTooltip]="
                  creative.creativeType === SbCreativeType.productCollection ? cluster.asin3 : cluster.title3
                "
                src="{{ getProductImage(cluster.asin3) | async }}"
              />
            }
          </div>
        </div>
      }
      @if ((creative.creativeAsins?.length ?? 0) > 2) {
        <div class="col-1 text-center">
          <p>{{ t("creative-display.and_creative_more", [creative.creativeAsins!.length - 2]) }}</p>
        </div>
      }
    }

    <!-- Video -->
    @if (creative.creativeType === SbCreativeType.video) {
      <div class="col-2 flex items-center justify-center">
        <div class="relative flex items-center justify-center">
          @if (brandAssets?.videoAsset?.url; as url) {
            <video
              class="max-h-full max-w-full shadow"
              [src]="url"
              controls
              autoplay
              height="100"
              [muted]="'muted'"
              loop
            ></video>
          }
          @if (!brandAssets?.videoAsset?.url) {
            <div class="flex flex-col justify-center">
              <fa-icon
                [icon]="faVideo"
                class="asset-alt mb-1 text-center"
                matTooltip="{{ t('creative-display.no_preview_available') }}"
              ></fa-icon>
              <div>{{ t("creative-display.no_preview_available") }}</div>
            </div>
          }
          @if (status === M19Status.REJECTED_VIDEO) {
            <div class="top-left circle h-30 absolute flex items-center justify-center bg-white">
              <fa-icon
                [icon]="faExclamationCircle"
                class="warning"
                size="2x"
                [matTooltip]="faultyCreativeReason"
              ></fa-icon>
            </div>
          }
        </div>
      </div>
      <div class="col-2">
        @if (brandAssets?.videoAsset?.url) {
          <div>
            <span class="creative-header">{{ t("creative-display.video_name") }}</span>
            <div (click)="$event.stopPropagation()">
              <span>{{ brandAssets!.videoAsset!.name }}</span>
            </div>
          </div>
        }
      </div>
      @if (hasNoAsins) {
        <div>{{ t("creative-display.no_asin") }}</div>
      }
      <div class="col-3 flex flex-col justify-center">
        <span class="creative-header"
          >ASINs
          @if (
            status === M19Status.IGNORED_CREATIVE_ASINS_INELIGIBLE ||
            status === M19Status.IGNORED_LOW_INVENTORY_ASINS ||
            status === M19Status.PUSH_FAILED_INVALID_ASINS ||
            status === M19Status.IGNORED_ASINS_NOT_IN_STORE_PAGE ||
            status === M19Status.IGNORED_NOT_ENOUGH_ASINS_FOR_LANDING_PAGE
          ) {
            <span
              ><fa-icon
                class="warning ml-1"
                [icon]="faExclamationCircle"
                size="1x"
                [matTooltip]="faultyCreativeReason"
              ></fa-icon
            ></span>
          }
        </span>
        <div class="creative-info sensitive-data">
          @for (cluster of creative.creativeAsins | slice: 0 : 6; track cluster) {
            <img class="product-icon" [matTooltip]="cluster.asin1" [src]="getProductImage(cluster.asin1) | async" />
          }
        </div>
      </div>
      <div class="col-2">
        @if ((creative.creativeAsins?.length ?? 0) > 6) {
          <div class="text-center">
            <span>{{ t("creative-display.and_creative_more", [creative.creativeAsins!.length - 6]) }}</span>
          </div>
        }
        @if (hasNoAsins) {
          <div class="text-center">{{ t("creative-display.no_asins_associated_with_this_video") }}</div>
        }
      </div>
    }
    <!-- Brand Video -->
    @if (creative.creativeType === SbCreativeType.brandVideo) {
      <div class="col-2 brand-logo-view sensitive-data flex items-center justify-center">
        @if (brandAssets?.logoAsset?.url; as url) {
          <img class="max-h-full max-w-full shadow" matTooltip="Brand Logo" [src]="url" />
        }
        @if (status === M19Status.IGNORED_INVALID_BRAND_LOGO || status === M19Status.REJECTED_IMAGE) {
          <div class="top-left circle absolute flex items-center justify-center bg-white" [style.z-index]="100">
            <fa-icon
              [icon]="faExclamationCircle"
              class="warning"
              size="2x"
              [matTooltip]="faultyCreativeReason"
            ></fa-icon>
          </div>
        }
        @if (!brandAssets?.logoAsset?.url) {
          <div class="flex flex-col justify-center">
            <fa-icon
              [icon]="faImage"
              class="asset-alt mb-1 text-center"
              matTooltip="{{ t('creative-display.no_preview_available') }}"
            ></fa-icon>
            <div>{{ t("creative-display.no_preview_available") }}</div>
          </div>
        }
      </div>
      <div class="col-2 ml-5">
        <span class="creative-header"
          >Headline
          @if (status === M19Status.PUSH_FAILED_INVALID_HEADLINE || status === M19Status.REJECTED_HEADLINE) {
            <span
              ><fa-icon
                class="warning ml-1"
                [icon]="faExclamationCircle"
                size="1x"
                [matTooltip]="faultyCreativeReason"
              ></fa-icon
            ></span>
          }
        </span>
        <div class="creative-info" (click)="$event.stopPropagation()">
          <span>{{ creative.headline }}</span>
        </div>
        @if (brandAssets?.storePage; as storePage) {
          <span class="creative-header">{{ t("creative-display.store_page") }}</span>
          <div class="creative-info sensitive-data" (click)="$event.stopPropagation()">
            <span>
              <a [href]="storePage.url" target="_blank">{{ storePage.name }}</a>
            </span>
          </div>
        }
      </div>
      <div class="col-2 flex items-center justify-center">
        <div class="relative flex items-center justify-center">
          @if (brandAssets?.videoAsset?.url; as url) {
            <video
              class="max-h-full max-w-full shadow"
              [src]="url"
              controls
              autoplay
              height="100"
              [muted]="'muted'"
              loop
            ></video>
          } @else {
            <div class="flex flex-col justify-center">
              <fa-icon
                [icon]="faVideo"
                class="asset-alt mb-1 text-center"
                matTooltip="{{ t('creative-display.no_preview_available') }}"
              ></fa-icon>
              <div>{{ t("creative-display.no_preview_available") }}</div>
            </div>
          }
          @if (status === M19Status.REJECTED_VIDEO) {
            <div class="top-left circle h-30 absolute flex items-center justify-center bg-white">
              <fa-icon
                [icon]="faExclamationCircle"
                class="warning"
                size="2x"
                [matTooltip]="faultyCreativeReason"
              ></fa-icon>
            </div>
          }
        </div>
      </div>
      @if (hasNoAsins) {
        <div>{{ t("creative-display.no_asin") }}</div>
      }
      @for (cluster of creative.creativeAsins | slice: 0 : 2; track cluster; let i = $index) {
        <div class="col-2 items-center">
          <span class="creative-header"
            >{{ i === 0 ? t("creative-display.first_ad_line") : t("creative-display.second_ad_line") }}
            @if (
              status === M19Status.IGNORED_CREATIVE_ASINS_INELIGIBLE ||
              status === M19Status.IGNORED_LOW_INVENTORY_ASINS ||
              status === M19Status.PUSH_FAILED_INVALID_ASINS ||
              status === M19Status.IGNORED_ASINS_NOT_IN_STORE_PAGE ||
              status === M19Status.IGNORED_NOT_ENOUGH_ASINS_FOR_LANDING_PAGE ||
              status === M19Status.REJECTED_ASIN
            ) {
              <span
                ><fa-icon
                  class="warning ml-1"
                  [icon]="faExclamationCircle"
                  size="1x"
                  [matTooltip]="faultyCreativeReason"
                ></fa-icon
              ></span>
            }
          </span>
          <div class="creative-info sensitive-data">
            <img class="product-icon" [matTooltip]="cluster.asin1" src="{{ getProductImage(cluster.asin1) | async }}" />
            @if (cluster.asin2) {
              <img
                class="product-icon"
                [matTooltip]="cluster.asin2"
                src="{{ getProductImage(cluster.asin2) | async }}"
              />
            }
            @if (cluster.asin3) {
              <img
                class="product-icon"
                [matTooltip]="cluster.asin3"
                src="{{ getProductImage(cluster.asin3) | async }}"
              />
            }
          </div>
        </div>
      }
      @if ((creative.creativeAsins?.length ?? 0) > 2) {
        <div class="col-1 text-center">
          <p>{{ t("creative-display.and_creative_more", [creative.creativeAsins!.length - 2]) }}</p>
        </div>
      }
    }
  </div>
  @if (faultyReason && creative.state === "ON") {
    <div class="alert-card alert-warning m-2 p-2">{{ faultyReason }}</div>
  }
</div>
