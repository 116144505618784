// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface SbAsins
 */
export interface SbAsins {
    /**
     * @type {string}
     * @memberof SbAsins
     */
    asin1: string;
    /**
     * @type {string}
     * @memberof SbAsins
     */
    asin2?: string;
    /**
     * @type {string}
     * @memberof SbAsins
     */
    asin3?: string;
    /**
     * @type {string}
     * @memberof SbAsins
     */
    title1?: string;
    /**
     * @type {string}
     * @memberof SbAsins
     */
    title2?: string;
    /**
     * @type {string}
     * @memberof SbAsins
     */
    title3?: string;
    /**
     * @type {string}
     * @memberof SbAsins
     */
    storePageId1?: string;
    /**
     * @type {string}
     * @memberof SbAsins
     */
    storePageId2?: string;
    /**
     * @type {string}
     * @memberof SbAsins
     */
    storePageId3?: string;
}
