// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    AccessLevel,
    BillingPlan,
} from './';

/**
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * @type {number}
     * @memberof Organization
     */
    organizationId?: number;
    /**
     * @type {number}
     * @memberof Organization
     */
    parentOrganizationId?: number;
    /**
     * @type {string}
     * @memberof Organization
     */
    customerId?: string;
    /**
     * @type {string}
     * @memberof Organization
     */
    customerName?: string;
    /**
     * @type {number}
     * @memberof Organization
     */
    ownerId?: number;
    /**
     * @type {string}
     * @memberof Organization
     */
    ownerUserName?: string;
    /**
     * @type {string}
     * @memberof Organization
     */
    ownerEmail?: string;
    /**
     * @type {string}
     * @memberof Organization
     */
    board?: string;
    /**
     * @type {boolean}
     * @memberof Organization
     */
    isLegacy?: boolean;
    /**
     * @type {boolean}
     * @memberof Organization
     */
    isParentPpcBoard?: boolean;
    /**
     * @type {number}
     * @memberof Organization
     */
    billingPlanId?: number;
    /**
     * @type {BillingPlan}
     * @memberof Organization
     */
    billingPlan?: BillingPlan;
    /**
     * @type {string}
     * @memberof Organization
     */
    couponStripeId?: string;
    /**
     * @type {number}
     * @memberof Organization
     */
    extraBilledAccounts?: number;
    /**
     * first payment state
     * @type {string}
     * @memberof Organization
     */
    firstPaymentValidated?: OrganizationFirstPaymentValidatedEnum;
    /**
     * @type {string}
     * @memberof Organization
     */
    subscriptionStripeId?: string;
    /**
     * @type {string}
     * @memberof Organization
     */
    subscriptionStartDate?: string;
    /**
     * @type {string}
     * @memberof Organization
     */
    subscriptionBillingCycleAnchorDate?: string;
    /**
     * @type {string}
     * @memberof Organization
     */
    subscriptionEndDate?: string;
    /**
     * @type {boolean}
     * @memberof Organization
     */
    starterPlanBlacklisted?: boolean;
    /**
     * @type {AccessLevel}
     * @memberof Organization
     */
    accessLevel?: AccessLevel;
    /**
     * @type {boolean}
     * @memberof Organization
     */
    datahubAccess?: boolean;
    /**
     * @type {number}
     * @memberof Organization
     */
    daysUntilDue?: number;
    /**
     * @type {string}
     * @memberof Organization
     */
    poNumber?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum OrganizationFirstPaymentValidatedEnum {
    NEW = 'NEW',
    PAID = 'PAID',
    PAYMENT_ISSUE = 'PAYMENT_ISSUE'
}

