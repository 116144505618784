import { Component, DestroyRef, inject, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { UpdateUserRequest, User } from "@front/m19-api-client";
import { filter, take } from "rxjs";
import { AccountSelectionService, AuthService } from "@front/m19-services";
import { ReactiveFormsModule } from "@angular/forms";
import {
  ChangePasswordModalData,
  ModalService,
  ProfileFormComponent,
  SpinnerComponent,
  IDropdownComponent,
  DropdownMenuItem,
} from "@front/m19-ui";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { ToastrService } from "ngx-toastr";
import { ChangePasswordComponent } from "@m19-board/settings/profile-settings/change-password/change-password.component";
import { I18nService } from "@m19-board/services/i18n.service";
import { catchAjaxError } from "@front/m19-utils";

@Component({
  selector: "app-profile",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoDirective,
    ProfileFormComponent,
    SpinnerComponent,
    IDropdownComponent,
  ],
  templateUrl: "./profile.component.html",
})
export class ProfileComponent {
  private readonly authService = inject(AuthService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly toasterService = inject(ToastrService);
  private readonly accountMarketplaceSelectionService = inject(AccountSelectionService);
  private readonly modalService = inject(ModalService);
  private readonly translocoService = inject(TranslocoService);
  private readonly i18nService = inject(I18nService);

  user$ = this.authService.loggedUser$.pipe(
    takeUntilDestroyed(this.destroyRef),
    filter((user): user is User => !!user),
  );
  user = toSignal(this.user$);
  isReadOnly = toSignal(
    this.accountMarketplaceSelectionService.readOnlyMode$.pipe(takeUntilDestroyed(this.destroyRef)),
  );
  submitLoading = signal(false);

  submitForm(updatedUser: UpdateUserRequest): void {
    this.submitLoading.set(true);

    const initialLanguage = this.user()?.language;

    const updatedFields = this.getUpdatedField(updatedUser, this.user()!);
    this.authService
      .updateUserV2(updatedFields)
      .pipe(catchAjaxError())
      .subscribe({
        next: () => {
          this.toasterService.success("User settings successfully updated");
          this.submitLoading.set(false);

          if (initialLanguage !== updatedUser.language) {
            this.i18nService.changeLanguageAndReload(updatedUser.language!);
          }
        },
        error: (error: string) => {
          this.submitLoading.set(false);
          this.toasterService.error(error);
        },
      });
  }

  private getUpdatedField(updatedUser: UpdateUserRequest, currentUser: User): UpdateUserRequest {
    const updatedFields: UpdateUserRequest = {};
    if (updatedUser.email !== currentUser.email) updatedFields.email = updatedUser.email;
    if (updatedUser.firstName !== currentUser.firstName) updatedFields.firstName = updatedUser.firstName;
    if (updatedUser.lastName !== currentUser.lastName) updatedFields.lastName = updatedUser.lastName;
    if (updatedUser.locale !== currentUser.locale) updatedFields.locale = updatedUser.locale;
    if (updatedUser.defaultCurrency !== currentUser.defaultCurrency)
      updatedFields.defaultCurrency = updatedUser.defaultCurrency;
    if (updatedUser.language !== currentUser.language) updatedFields.language = updatedUser.language;

    return updatedFields;
  }

  changePassword() {
    if (!this.user()) return;

    this.modalService.openModal<ChangePasswordModalData, void>(ChangePasswordComponent, {
      modalTitle: this.translocoService.translate("profile-settings.change_password_modal_title"),
      data: {
        user: this.user()!,
      },
    });
  }
}
