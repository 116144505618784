<div class="relative" *transloco="let t">
  @if (overlayMessage !== OverlayMessage.NONE) {
    <div class="warning-popup">
      @switch (overlayMessage) {
        @case (OverlayMessage.NO_LINKED_ACCOUNTS) {
          <div class="alert alert-primary flex flex-col items-center p-4">
            <span>{{ t("strategy-overlay.automating_campaign") }}</span>
            <span>{{ t("hourly-stats-overlay.contact_admin") }}</span>
            <app-link-your-account-button class="mt-4"></app-link-your-account-button>
          </div>
        }
        @case (OverlayMessage.NO_LINKED_ACCOUNTS_WHITELABEL) {
          <div class="alert alert-primary flex flex-col items-center p-4">
            <span>{{ t("stats-overlay.no_marketplace_linked") }}</span>
          </div>
        }
        @case (OverlayMessage.BIDDER_OFF_WHITELABEL) {
          <div class="alert alert-primary flex flex-col items-center p-4">
            {{ t("hourly-stats-overlay.bider_not_activated") }}
          </div>
        }
        @case (OverlayMessage.BIDDER_TO_ACTIVATE) {
          <div class="alert alert-primary flex flex-col items-center p-4">
            <span>{{ t("hourly-stats-overlay.no_ful_automation") }}</span>
            <button type="button" class="btn btn-primary mt-2" (click)="goToAccounts()">
              {{ t("hourly-stats-overlay.account_setup") }}
            </button>
          </div>
        }
        @case (OverlayMessage.UPGRADE_YOURSELF) {
          <div class="alert alert-primary flex flex-col items-center p-4">
            <span>{{ t("hourly-stats-overlay.marketplace_not_included") }}</span>
            <app-registration-button class="mt-4"></app-registration-button>
          </div>
        }
        @case (OverlayMessage.CONTACT_US_TO_UPGRADE) {
          <div class="alert alert-primary flex flex-col items-center p-4">
            <span
              >{{ t("hourly-stats-overlay.not_in_subscription") }}
              <a
                class="alert-link"
                href="https://help.m19.com/how-to-add-another-marketplace/account-to-your-billing-plan"
                target="_blank"
                >{{ t("hourly-stats-overlay.this_link") }}</a
              >
              {{ t("hourly-stats-overlay.to_request") }}</span
            >
          </div>
        }
        @case (OverlayMessage.ACTIVATE_FREEPLAN) {
          <div class="alert alert-primary flex flex-col items-center p-4">
            <span>{{ t("strategy-overlay.start_ads_automation") }}</span>
            <button type="button" class="btn btn-primary mt-4" (click)="billingRegistration()">
              <fa-icon [icon]="faRocket" class="font-2xl mr-1 align-middle"></fa-icon>
              {{ t("strategy-overlay.start_ads_button") }}
            </button>
          </div>
        }
        @case (OverlayMessage.ACTIVATE_PAIDPLAN) {
          <div class="alert alert-primary flex flex-col items-center p-4">
            <span>{{ t("strategy-overlay.automation_on_marketplace") }}</span>
            <button type="button" class="btn btn-primary mt-4" (click)="billingRegistration()">
              <fa-icon [icon]="faRocket" class="font-2xl mr-1 align-middle"></fa-icon>
              {{ t("strategy-overlay.start_ads_button") }}
            </button>
          </div>
        }
        @case (OverlayMessage.PAYMENT_ISSUE) {
          <div class="alert alert-primary flex flex-col items-center p-4">
            <span>{{ t("hourly-stats-overlay.payment_issue") }}</span>
          </div>
        }
        @case (OverlayMessage.MANUAL_ACTIVATION_REQUIRED) {
          <div class="alert alert-primary flex flex-col items-center p-4">
            <span> {{ t("strategy-overlay.full_automation_info") }}</span>
          </div>
        }
      }
    </div>
  }
  <div [class.preview]="overlayMessage !== OverlayMessage.NONE">
    <ng-content></ng-content>
  </div>
</div>
