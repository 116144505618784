import { Currency } from '@front/m19-api-client';
import { FulfillmentChannel } from './ProductEx';

export const Both = 'AmazonAndMerchant';

export interface InventoryStats {
  fulfillableQuantity: number;
  fulfillableQuantityValue: number;
  fbmStock: number;
  fbmStockValue: number;
  unsellableQuantity: number;
  unsellableQuantityValue: number;
  inboundQuantity: number;
  inboundQuantityValue: number;
  reservedQuantity: number;
  reservedQuantityValue: number;
  price: number;
  date?: string;
  currency?: Currency;
  asin: string;
  orders7d?: number;
  orders30d?: number;
  estimatedDaysOfStock?: number;
  title?: string;
  fulfillmentChannel: FulfillmentChannel;
}

export function sumInventoryStats<T extends InventoryStats>(stats1: T, stats2: T): T {
  const res = { ...stats1 };
  for (const [k, v] of Object.entries(stats2)) {
    if (res[k as keyof InventoryStats] && typeof res[k as keyof InventoryStats] == 'number') {
      (res[k as keyof InventoryStats] as string) += v;
    } else {
      (res[k as keyof InventoryStats] as string) = v;
    }
  }
  return res;
}
