import { Component, Input } from "@angular/core";
import { AsinService } from "@front/m19-services";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Marketplace } from "@front/m19-api-client";
import { Utils } from "@front/m19-utils";
import { TranslocoService } from "@jsverse/transloco";
import { IButtonComponent } from "@front/m19-ui";
import { MatTooltip } from "@angular/material/tooltip";

@Component({
  selector: "app-import-managed-catalog-modal",
  template: ` <div class="modal-header bg-main-500">
      <h4 class="modal-title pull-left">Catalog data upload report</h4>
      <a>
        <h4 class="modal-title pull-right" (click)="bsModalRef.hide()">&times;</h4>
      </a>
    </div>
    <div class="m-4">
      <textarea
        type="text"
        #asins
        class="textarea-width form-control coding-font"
        placeholder="asin1&#10;asin2"
      ></textarea>
      <div class="mt-2 flex items-center justify-end">
        <IButton
          [label]="mode === 'managed' ? 'Restore To Managed Catalog' : 'Stop Managing Products'"
          [disabled]="isUploadButtonDisabled(asins.value)"
          (onClick)="import(asins.value); asins.value = ''"
        />
        <IButton label="Close" class="ml-2" (onClick)="bsModalRef.hide()" color="white" />
      </div>
      <div class="mt-2">
        @if (errors && errors.length > 0) {
          <ul class="list-group">
            <li class="list-group-item list-group-item list-group-item-warning flex justify-between">
              <span>The following ASINs have been ignored:</span>

              <IButton
                size="sm"
                variant="ghost"
                icon="icon-[lucide--x]"
                [tooltipValue]="'Close error table'"
                (onClick)="errors = []"
              />
            </li>
            @for (error of errors; track error) {
              <li class="list-group-item list-group-item-secondary py-0">
                {{ error }}
              </li>
            }
          </ul>
        }
      </div>
    </div>`,
  styles: [
    `
      textarea {
        resize: none;
        height: 250px;
        border-bottom-right-radius: 0%;
      }
    `,
  ],
  standalone: true,
  imports: [IButtonComponent, MatTooltip],
})
export class ImportManagedCatalogModalComponent {
  @Input() accountId!: string;
  @Input() marketplace!: Marketplace;
  @Input() mode!: "managed" | "unmanaged";
  @Input() unmanagedCatalog!: string[];
  @Input() managedCatalog!: string[];

  errors: string[] = [];

  constructor(
    private asinService: AsinService,
    private toastrService: ToastrService,
    public bsModalRef: BsModalRef,
    private translocoService: TranslocoService,
  ) {}

  isUploadButtonDisabled(content: string): boolean {
    return !content || content.trim().length === 0;
  }

  import(inputAsins: string): void {
    const asins: string[] = [];
    this.errors = [];
    for (const asin of inputAsins.split(/[\s,;\n]+/)) {
      const normalizedAsin = Utils.normalizeASIN(asin);
      if (normalizedAsin === "") {
        this.errors.push(asin + ": " + this.translocoService.translate("seeds-edition-modal.invalid_asin"));
        continue;
      }
      if (asins.includes(normalizedAsin)) {
        this.errors.push(asin + ": " + this.translocoService.translate("import-managed-catalog-modal.duplicated"));
        continue;
      }
      if (this.mode === "managed" && this.managedCatalog.includes(normalizedAsin)) {
        this.errors.push(
          asin + ": " + this.translocoService.translate("import-managed-catalog-modal.already_in_managed_catalog"),
        );
        continue;
      }
      if (this.mode === "managed" && !this.unmanagedCatalog.includes(normalizedAsin)) {
        this.errors.push(asin + ": " + this.translocoService.translate("import-managed-catalog-modal.asin_is_unknown"));
        continue;
      }
      if (this.mode === "unmanaged" && this.unmanagedCatalog.includes(normalizedAsin)) {
        this.errors.push(
          asin + ": " + this.translocoService.translate("import-managed-catalog-modal.already_in_unmanaged_catalog"),
        );
        continue;
      }
      if (this.mode === "unmanaged" && !this.managedCatalog.includes(normalizedAsin)) {
        this.errors.push(asin + ": " + this.translocoService.translate("import-managed-catalog-modal.asin_is_unknown"));
        continue;
      }

      asins.push(normalizedAsin);
    }
    if (asins.length == 0) {
      this.errors.push(this.translocoService.translate("import-managed-catalog-modal.no_asins_to_add_to_catalog"));
      return;
    }

    if (this.mode === "managed") {
      this.asinService.addAsins(this.accountId, this.marketplace, asins).subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("catalog-page.products_are_back_to_managed_catalog"),
          );
        },
        error: (err) => {
          this.toastrService.error(
            err,
            this.translocoService.translate("catalog-page.failed_to_move_back_products_to_managed_catalog"),
          );
        },
      });
    } else {
      this.asinService.deleteAsins(this.accountId, this.marketplace, asins).subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("import-managed-catalog-modal.products_move_to_unmanaged_catalog"),
          );
        },
        error: (err) => {
          this.toastrService.error(
            err,
            this.translocoService.translate(
              "import-managed-catalog-modal.failed_to_move_products_to_unmanaged_catalog",
            ),
          );
        },
      });
    }
  }
}
