import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AccountMarketplace, Marketplace, Strategy } from "@front/m19-api-client";
import { AccountSelectionService } from "@front/m19-services";
import { ICON_CHEVRON_DOWN } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  template: ` <div class="main-container mx-auto mb-3 flex items-center justify-between px-2" *transloco="let t">
      <div class="flex items-center">
        <a
          class="btn btn-ghost-secondary inline-flex items-center"
          type="button"
          routerLink="/advertising/sponsored-display"
          queryParamsHandling="merge"
          matTooltip="{{ t('sd-strategy-creation-page.back_to_sponsored_display_strategy_list') }}"
          matTooltipPosition="above"
        >
          {{ t("v2-sidebar.sponsored_display") }}
        </a>
        <span [class]="ICON_CHEVRON_DOWN + ' -rotate-90'"></span>
        <a
          class="btn btn-ghost-secondary inline-flex items-center"
          type="button"
          routerLink="."
          queryParamsHandling="merge"
        >
          {{ t("sd-strategy-creation-page.new_strategy") }}
        </a>
      </div>
      <div>
        {{ t("common.marketplace") }}:&nbsp;<strong>{{ marketplace }}</strong>
      </div>
    </div>
    <app-sd-strategy-creation (strategyCreated)="onStrategyCreated($event)"></app-sd-strategy-creation>`,
  styleUrls: ["../../../strategies/strategies/strategy-styles.scss"],
})
export class SdStrategyCreationPageComponent implements OnInit {
  marketplace?: Marketplace;
  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;

  constructor(
    private accountSelection: AccountSelectionService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((am: AccountMarketplace) => {
        this.marketplace = am.marketplace;
      });
  }

  onStrategyCreated(strategy: Strategy) {
    this.router.navigate(["/advertising/sponsored-display/" + strategy.strategyId], { queryParamsHandling: "merge" });
  }
}
