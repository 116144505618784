import { CommonModule } from "@angular/common";
import { Component, computed, signal, WritableSignal } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Router } from "@angular/router";
import { AccessLevel, AccountMarketplace, Marketplace } from "@front/m19-api-client";
import { AccountGroup, Marketplaces } from "@front/m19-models";
import {
  AccountSelectionLocalStorageKeys,
  AccountSelectionService,
  OrganizationAccountGroupService,
} from "@front/m19-services";
import { IMultiSelectComponent, Option } from "@front/m19-ui";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { AccountGroupSelectorComponent } from "@m19-board/account-group-selector/account-group-selector.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, filter, take } from "rxjs";

@Component({
  selector: "app-multi-account-selector",
  template: ` <div *transloco="let t" class="flex items-center">
    <account-group-selector
      [multiSelection]="true"
      [selectedAccountGroupId]="0"
      [selectedAccountGroupIds]="selectedAccountGroupIds()"
      (selectAccountGroups)="selectAccountGroups($event)"
      class="sensitive-data w-72"
    ></account-group-selector>
    <IMultiSelect
      [options]="marketplaces()"
      [selected]="selectedMarketplaces()"
      (selectedChange)="selectMarketplace($event)"
      class="sensitive-data mx-2 flex w-60"
      [searchable]="true"
      [minSelectedOptions]="1"
      [withSelectAll]="true"
    >
      <ng-template #labelSlot>
        <div class="flex w-full truncate">
          @if (selectedMarketplaces().length) {
            <div class="flex flex-1">
              {{ marketplaceLabel() }}
            </div>
          }
        </div>
      </ng-template>
    </IMultiSelect>
  </div>`,
  standalone: true,
  imports: [IMultiSelectComponent, TranslocoDirective, CommonModule, AccountGroupSelectorComponent],
})
@UntilDestroy()
export class MultiAccountSelectorComponent {
  readonly selectedAccountGroups: WritableSignal<AccountGroup[]> = signal([]);
  readonly selectedAccountGroupIds = computed(() => this.selectedAccountGroups().map((a) => a.id));
  readonly marketplaces = computed<Option<Marketplace>[]>(() => {
    const marketplaceSet: Set<Marketplace> = new Set(
      this.selectedAccountGroups()
        .flatMap((a) => a.resources)
        .map((r) => r.marketplace),
    );
    return Array.from(marketplaceSet)
      .sort()
      .map((m: Marketplace) => {
        const mktpl = Marketplaces[m];
        return {
          label: mktpl.flag + " - " + this.translocoService.translate(mktpl.translationKey),
          value: m,
          id: m,
        };
      });
  });
  readonly selectedMarketplaces: WritableSignal<Option<Marketplace>[]> = signal([]);
  readonly marketplaceLabel = computed(() => {
    const selectedMarketplaces = this.selectedMarketplaces();
    if (selectedMarketplaces.length == 1) {
      return selectedMarketplaces[0].label;
    }
    if (selectedMarketplaces.length == this.marketplaces().length) {
      return "All Marketplaces";
    }

    if (selectedMarketplaces.length <= 3) {
      return selectedMarketplaces.map((m) => Marketplaces[m.value].flag).join(" ");
    }

    return (
      selectedMarketplaces
        .slice(0, 3)
        .map((m) => Marketplaces[m.value].flag)
        .join(" ") +
      " (+" +
      (selectedMarketplaces.length - 3) +
      ")"
    );
  });

  constructor(
    private accountSelectionService: AccountSelectionService,
    private accountGroupService: OrganizationAccountGroupService,
    private route: ActivatedRoute,
    private router: Router,
    private translocoService: TranslocoService,
  ) {
    toObservable(this.marketplaces)
      .pipe(untilDestroyed(this))
      .subscribe((marketplaces) => {
        this.selectMarketplace(marketplaces);
      }); // select all marketplaces on markeplaces change

    combineLatest([
      this.accountGroupService.allOrganizationAccountGroups$.pipe(
        filter((organizations) => organizations != undefined && organizations.length > 0),
      ),
      this.route.queryParams.pipe(take(1)),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([organizations, params]) => {
        const accountGroups = organizations!.flatMap((o) => o.accountGroups);
        const orgId: number | undefined = params["orgId"];
        const accountId: string | undefined = params["accountId"];
        const accountGroupId = localStorage.getItem(AccountSelectionLocalStorageKeys.accountGroup)
          ? parseInt(localStorage.getItem(AccountSelectionLocalStorageKeys.accountGroup)!)
          : undefined;
        if (orgId && accountId) {
          let elligibleAccountGroup = accountGroups.filter(
            (a) => a.organizationId == orgId && a.resources.findIndex((r) => r.accountId == accountId) >= 0,
          );
          if (elligibleAccountGroup.length == 1) {
            this.selectedAccountGroups.set([elligibleAccountGroup[0]]);
          } else if (elligibleAccountGroup.length > 1) {
            elligibleAccountGroup = elligibleAccountGroup.filter((a) => a.id == accountGroupId);
            if (elligibleAccountGroup.length > 0) {
              this.selectedAccountGroups.set(elligibleAccountGroup);
            } else {
              this.selectedAccountGroups.set(accountGroups);
            }
          } else {
            this.selectedAccountGroups.set(accountGroups);
          }
        } else if (accountGroupId) {
          const selectedAccount = accountGroups.find((a) => a.id == accountGroupId);
          if (selectedAccount) {
            this.selectedAccountGroups.set([selectedAccount]);
          } else {
            this.selectedAccountGroups.set(accountGroups);
          }
        } else {
          this.selectedAccountGroups.set(accountGroups);
        }
      });
  }

  selectAccountGroups(accountGroups: AccountGroup[]) {
    this.selectedAccountGroups.set(accountGroups);
  }

  selectMarketplace(marketplaces: Option<Marketplace>[]) {
    this.selectedMarketplaces.set(marketplaces);
    this.accountSelectionService.setMultiAccountSelection(
      this.selectedAccountGroups().map((a) => ({
        accountGroup: a,
        marketplaces: this.selectedMarketplaces()
          .map((m) => m.value)
          .filter((m) => a.resources.map((r) => r.marketplace).includes(m)),
      })),
    );
    if (this.selectedAccountGroups().length === 0) {
      return;
    }
    // TODO: we should move this to a guard in the router configuration
    // route to dashboard 360 if stats only access level on one of the selected account/marketplace
    const statsOnlySelected = this.selectedAccountGroups()
      .flatMap((a) => a.resources)
      .find((a) => a.accessLevel === AccessLevel.STATS_ONLY);
    if (statsOnlySelected) {
      this.router.navigate(["/dashboard360"], {
        queryParams: {
          accountId: statsOnlySelected.accountId,
          marketplace: statsOnlySelected.marketplace,
          orgId: statsOnlySelected.resourceOrganizationId,
        },
        queryParamsHandling: "merge",
      });
      return;
    }

    // logic to keep a single selected account/marketplace in the url
    const selectedMarketplaceSet = new Set(this.selectedMarketplaces().map((m) => m.value));

    // no selected marketplace, do not update the url
    if (selectedMarketplaceSet.size == 0) {
      return;
    }

    // only one selected marketplace, update the url with the first selected accountId compatible
    if (selectedMarketplaceSet.size == 1) {
      const selectedAccountMarketplace = this.selectedAccountGroups()
        .flatMap((a) => a.resources)
        .find((r) => selectedMarketplaceSet.has(r.marketplace));
      if (!selectedAccountMarketplace) {
        return;
      }
      this.updateUrlAndLocalStorage(selectedAccountMarketplace);
      return;
    }

    // multiple selected marketplaces
    // if the marketplace is already in the URL, try to keep it - otherwise, keep the first selected marketplace
    // then select the first selected account-marketplace with this marketplace
    // and update the URL with this account-marketplace
    const selectedMarketplaceFromUrl =
      this.route.snapshot.queryParams["marketplace"] ??
      localStorage.getItem(AccountSelectionLocalStorageKeys.marketplace);
    const selectedMarketplace = selectedMarketplaceSet.has(selectedMarketplaceFromUrl)
      ? selectedMarketplaceFromUrl
      : selectedMarketplaceSet.values().next().value;

    const selectedAccountMarketplace = this.selectedAccountGroups()
      .flatMap((a) => a.resources)
      .find((r) => selectedMarketplace == r.marketplace);

    if (!selectedAccountMarketplace) {
      return;
    }

    this.updateUrlAndLocalStorage(selectedAccountMarketplace);
  }

  private updateUrlAndLocalStorage(accountMarketplace: AccountMarketplace) {
    this.router.navigate([], {
      queryParams: {
        accountId: accountMarketplace.accountId,
        marketplace: accountMarketplace.marketplace,
        orgId: accountMarketplace.resourceOrganizationId,
      },
      queryParamsHandling: "merge",
    });
    localStorage.setItem(AccountSelectionLocalStorageKeys.accountGroup, accountMarketplace.accountGroupId!.toString());
    localStorage.setItem(AccountSelectionLocalStorageKeys.marketplace, accountMarketplace.marketplace);
    this.accountSelectionService.updateAccountMarketplace([accountMarketplace]);
  }
}
