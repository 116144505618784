<div class="mb-3 flex items-center" *transloco="let t">
  <app-strategy-product-group-hybrid-dropdown
    class="start-dropdown"
    [strategies]="strategies"
    (selectedStrategyOrProductGroup)="setStrategyOrProductGroup($event)"
  ></app-strategy-product-group-hybrid-dropdown>

  <ICheckbox
    class="ml-4"
    label="{{ t('hourly-chart.include_not_operated_campaign') }}"
    [value]="includeNonM19Campaign"
    (valueChange)="toggleIncludeNonM19Campaign($event)"
  />
</div>
<div class="metrics-header">
  <app-entry-selector
    [data]="globalData"
    (chartMetricsChanges)="selectMetrics($event)"
    [pageMetrics]="CHART_METRICS"
    [localStorageKey]="localStorageKey"
    [showCompare]="false"
  ></app-entry-selector>
</div>

<div class="graph box card border-shadow mt-2 pb-0" [hidden]="!chartDisplayed" *transloco="let t">
  <div class="row justify-end">
    <div class="relative float-right mr-3">
      <button
        class="btn btn-ghost-secondary m-2"
        (click)="toggleChartDisplay(false)"
        matTooltip="{{ t('hourly-chart.hide_chart') }}t"
      >
        <fa-icon [icon]="faMinimize"></fa-icon>
      </button>
    </div>
  </div>
  <app-spinner [display]="pendingRequests"></app-spinner>
  <div class="chart-wrapper mt-2">
    @if (!pendingRequests) {
      <canvas
        baseChart
        class="chart"
        [datasets]="dataSet.chartDataSet"
        type="line"
        [labels]="dataSet.labels"
        [options]="dataSet.lineChartOptions"
        #canvas
      >
      </canvas>
    }
  </div>
  <div class="mt-3 flex justify-end">
    <div class="flex flex-col">
      <small class="'text-sm">{{ t("hourly-chart.hourly_display") }}</small>
      @if (timeZone) {
        <small class="text-sm">{{ t("hourly-chart.hours_displayed", [getTimezone(), getTimezonUtcOffset()]) }} </small>
      }
    </div>
  </div>
</div>
