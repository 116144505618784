<div class="modal-header bg-red-500" *transloco="let t">
  <h4 class="modal-title pull-left">{{ t("stop-keyword-tracking-modal.stop_tracking_term", [searchTerm]) }}</h4>
</div>
<div class="modal-body" *transloco="let t">
  <p>{{ t("stop-keyword-tracking-modal.confirmation_stop_tracking", [searchTerm]) }}</p>
</div>
<div class="modal-footer" *transloco="let t">
  <IButton
    type="button"
    label="{{ t('common.cancel') }}"
    data-dismiss="modal"
    color="gray"
    variant="ghost"
    (click)="bsModalRef.hide()"
  />
  <IButton type="submit" label="{{ t('stop-keyword-tracking-modal.stop_tracking') }}" color="red" (click)="delete()" />
</div>
