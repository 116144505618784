// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    Marketplace,
    SbAsins,
} from './';

/**
 * @export
 * @interface SbCreativeCluster
 */
export interface SbCreativeCluster {
    /**
     * @type {string}
     * @memberof SbCreativeCluster
     */
    accountId: string;
    /**
     * @type {Marketplace}
     * @memberof SbCreativeCluster
     */
    marketplace: Marketplace;
    /**
     * @type {number}
     * @memberof SbCreativeCluster
     */
    creativeId: number;
    /**
     * @type {Array<SbAsins>}
     * @memberof SbCreativeCluster
     */
    creativeAsins: Array<SbAsins>;
}
