import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  EmbeddedViewRef,
  EnvironmentInjector,
  inject,
  Injectable,
  InjectionToken,
  Injector,
  TemplateRef,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IModalComponent } from '../components/imodal/imodal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private appRef = inject(ApplicationRef);
  private injector = inject(EnvironmentInjector);

  private containerRef!: ViewContainerRef;

  constructor() {}

  registerContainerRef(containerRef: ViewContainerRef) {
    this.containerRef = containerRef;
  }

  openModal<DataType = any>(content: TemplateRef<any> | Type<any>, modalOptions: ModalOptions<DataType>) {
    const onclose = () => {
      if (modal.location.nativeElement.parentElement) {
        modal.location.nativeElement.parentElement.removeChild(modal.location.nativeElement);
        this.appRef.detachView(modal.hostView);
      }

      modal.destroy();
      view.destroy();
    };

    const dialogRef: DialogRef = new DialogRef(content, onclose);

    const context = {
      $implicit: dialogRef,
    };

    let projectableNodes: Node[][] | undefined;

    let view: EmbeddedViewRef<any> | ComponentRef<any>;
    if (content instanceof TemplateRef) {
      view = this.containerRef.createEmbeddedView(content, context) || content.createEmbeddedView(context);
      projectableNodes = [view.rootNodes];
    } else {
      view = this.containerRef.createComponent(content, {
        environmentInjector: this.injector,
        injector: Injector.create({
          providers: [
            {
              provide: DialogRef,
              useValue: dialogRef,
            },
            {
              provide: DIALOG_DATA,
              useValue: modalOptions.data,
            },
          ],
        }),
      });
      projectableNodes = [[view.location.nativeElement]];
    }

    const modal: ComponentRef<IModalComponent> = createComponent(IModalComponent, {
      elementInjector: Injector.create({
        providers: [
          {
            provide: DialogRef,
            useValue: dialogRef,
          },
        ],
      }),
      environmentInjector: this.injector,
      projectableNodes: projectableNodes,
    });

    modal.instance.title = modalOptions.modalTitle;
    modal.instance.fullScreen = !!modalOptions.fullScreen;
    modal.instance.closeable = modalOptions.closeable != false;

    dialogRef.component = modal.instance;

    this.containerRef.element.nativeElement.appendChild(modal.location.nativeElement);
    this.appRef.attachView(modal.hostView);

    return dialogRef;
  }
}

export class DialogRef<Result = any, Ref extends Type<any> | TemplateRef<any> = Type<any> | TemplateRef<any>> {
  public ref: Ref;
  public component?: IModalComponent;

  private afterClosedSubject = new Subject<Result | undefined>();
  private readonly onClose: () => void;

  constructor(ref: Ref, onClose: () => void) {
    this.ref = ref;
    this.onClose = onClose;
  }

  close(result?: Result): void {
    this.onClose();
    this.afterClosedSubject.next(result);
  }

  afterClosed(): Observable<Result | undefined> {
    return this.afterClosedSubject.asObservable();
  }
}

export const DIALOG_DATA = new InjectionToken('DIALOG_DATA');

export interface ModalOptions<T> {
  modalTitle: string;
  fullScreen?: boolean;
  adaptiveWidth?: boolean;
  closeable?: boolean;
  data?: T;
}
