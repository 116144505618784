import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild, ViewEncapsulation } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AuthService, StatsApiClientService, UserSelectionService } from "@front/m19-services";
import { ICON_CHEVRON_DOWN } from "@m19-board/utils/iconsLabels";
import moment, { Moment } from "moment-timezone";
import { BsDaterangepickerConfig, BsDaterangepickerDirective } from "ngx-bootstrap/datepicker";
import { map, Observable, take } from "rxjs";
import { TranslocoService } from "@jsverse/transloco";
import { Comparison, Utils } from "@front/m19-utils";

export type ComparisonOption = {
  start?: string;
  end?: string;
  startDate?: Moment;
  endDate?: Moment;
  label: string;
  type: Comparison;
};

export const comparisonPeriods: Record<Comparison, ComparisonOption> = {
  [Comparison.None]: {
    label: "common.none",
    type: Comparison.None,
  },
  [Comparison.PreviousPeriod]: {
    label: "date-range-selection.previous_period",
    type: Comparison.PreviousPeriod,
  },
  [Comparison.PreviousWeek]: {
    label: "date-range-selection.previous_week",
    type: Comparison.PreviousWeek,
  },
  [Comparison.PreviousMonth]: {
    label: "date-range-selection.previous_month",
    type: Comparison.PreviousMonth,
  },
  [Comparison.Previousyear]: {
    label: "date-range-selection.previous_year",
    type: Comparison.Previousyear,
  },
  [Comparison.Custom]: {
    label: "common.custom",
    type: Comparison.Custom,
  },
};

@UntilDestroy()
@Component({
  selector: "app-date-range-selection",
  templateUrl: "./date-range-selection.component.html",
  styleUrls: ["./date-range-selection.component.scss", "../../scss/vendors/bs-datepicker/bs-datepicker.scss"],
  encapsulation: ViewEncapsulation.None, // for date picker styling
})
export class DateRangeSelectionComponent implements OnInit {
  readonly comparaisonPeriodsValues = Object.values(comparisonPeriods);

  ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;
  private static gid = 0;

  @Input()
  position: "above" | "below" = "below";

  datePickerConfig: Partial<BsDaterangepickerConfig> = {};

  readonly ranges: { label: string; value: Date[] }[] = [
    {
      value: [
        new Date(new Date().setDate(new Date().getDate() - 7)),
        new Date(new Date().setDate(new Date().getDate() - 1)),
      ],
      label: "date-range-selection.last_7_days",
    },
    {
      value: [
        new Date(new Date().setDate(new Date().getDate() - 14)),
        new Date(new Date().setDate(new Date().getDate() - 1)),
      ],
      label: "date-range-selection.last_14_days",
    },
    {
      value: [
        new Date(new Date().setDate(new Date().getDate() - 30)),
        new Date(new Date().setDate(new Date().getDate() - 1)),
      ],
      label: "date-range-selection.last_30_days",
    },
    {
      value: [
        new Date(new Date().setDate(new Date().getDate() - 60)),
        new Date(new Date().setDate(new Date().getDate() - 1)),
      ],
      label: "date-range-selection.last_60_days",
    },
    {
      value: [
        new Date(new Date().setDate(new Date().getDate() - 90)),
        new Date(new Date().setDate(new Date().getDate() - 1)),
      ],
      label: "date-range-selection.last_90_days",
    },
    {
      value: [
        new Date(new Date().getFullYear(), new Date().getMonth() - 12, new Date().getDate()),
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1),
      ],
      label: "date-range-selection.last_12_months",
    },
  ];

  disabled = false;
  id: string;
  bsRangeValue$: Observable<Date[]>;
  timeRangeOption: string = "";
  comparedRangeOption = comparisonPeriods[Comparison.None];
  disableComparaison = false;
  range: number | undefined;
  customDatePickerConfig!: Partial<BsDaterangepickerConfig>;
  customStartDate?: Date;
  startToStartGap = 0;
  date = new Date();
  format?: string;

  isOpen = false;

  @ViewChild("previousPeriod") previousPeriod!: ElementRef;
  @ViewChild("drcontainer") container!: ElementRef;
  @ViewChild("drp") drp!: BsDaterangepickerDirective;
  @ViewChild("_drp") _drp!: ElementRef;
  @ViewChild("dropdownCompare") compareMenu!: ElementRef;
  @ViewChild("comparePeriodToggle") toggle!: ElementRef;

  constructor(
    private userSelectionService: UserSelectionService,
    private authService: AuthService,
    private renderer: Renderer2,
    private translocoService: TranslocoService,
  ) {
    // unique id to avoid collision within the app
    this.id = `drp_${DateRangeSelectionComponent.gid++}`;
    this.bsRangeValue$ = this.userSelectionService.selectedDateRange$.pipe(
      map((dr: Moment[]) => {
        return dr.map((d) => d.toDate());
      }),
    );
    this.bsRangeValue$.pipe(take(1)).subscribe((dr) => {
      this.customStartDate = dr[0];
    });

    this.renderer.listen("window", "click", (e: Event) => {
      // Only run when dateInput, datePicker or everything inside it is not clicked
      if (
        this.isOpen &&
        e.target != this._drp?.nativeElement &&
        !this.toggle?.nativeElement.contains(e.target) &&
        !this.compareMenu?.nativeElement.contains(e.target) &&
        !this.container?.nativeElement.contains(e.target)
      ) {
        this.isOpen = !this.isOpen;
        this.drp.hide();
      }
    });
  }

  ngOnInit(): void {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.format = Utils.getDateFormatString(user.locale);
      this.datePickerConfig = {
        maxDate: this.date,
        showWeekNumbers: false,
        containerClass: "theme-dark-blue",
        rangeInputFormat: this.format,
        dateInputFormat: this.format,
      };

      this.customDatePickerConfig = {
        ...this.datePickerConfig,
      };
    });

    this.date.setDate(this.date.getDate() - 1);

    this.userSelectionService.disableDateRangeSelection$.pipe(untilDestroyed(this)).subscribe((x) => {
      this.disabled = x;
    });

    this.userSelectionService.periodComparison$.pipe(untilDestroyed(this)).subscribe((x) => {
      if (x) this.comparedRangeOption = comparisonPeriods[x.type];
    });

    this.bsRangeValue$.pipe(untilDestroyed(this)).subscribe((dr) => {
      if (dr) {
        this.customDatePickerConfig.maxDate = dr[0];
        this.updateTimeString(dr);
        this.range = moment(dr[1]).diff(moment(dr[0]), "days");
        if (this.range > StatsApiClientService.maxComp) {
          this.disableComparaison = true;
          this.comparedRangeOption = comparisonPeriods[Comparison.None];
        } else {
          this.disableComparaison = false;
          for (const option of this.comparaisonPeriodsValues) {
            let end: Moment;
            if (option.type === Comparison.None) {
              continue;
            }
            if (option.type === Comparison.PreviousPeriod) {
              end = moment(dr[0]).subtract(1, "day");
            } else if (option.type === Comparison.PreviousWeek) {
              end = moment(dr[1]).subtract(1, "week");
            } else if (option.type === Comparison.PreviousMonth) {
              end = moment(dr[1]).subtract(1, "month");
            } else if (option.type === Comparison.Previousyear) {
              end = moment(dr[1]).subtract(1, "year");
            } else {
              end = moment(dr[1]).subtract(this.startToStartGap, "days");
            }
            const end_copy = moment(end);
            option.startDate = end_copy.subtract(this.range, "day");
            option.start = option.startDate.format(this.format);
            option.endDate = end;
            option.end = end.format(this.format);

            if (option.type === this.comparedRangeOption?.type) {
              this.comparedRangeOption = option;
            }
            if (option.type === Comparison.Custom) {
              this.customStartDate = option.startDate.toDate();
            }
          }
        }
      }
      if (this.comparedRangeOption.type !== Comparison.None) {
        this.updateComparedPeriod(this.comparedRangeOption);
      }
    });
  }

  setRangeValue(dateRange: (Date | undefined)[] | undefined) {
    if (!dateRange) return;
    const startDate = moment(dateRange[0]);
    const endDate = moment(dateRange[1]);
    const yesterday = moment().subtract(1, "days");
    if (endDate.isSame(yesterday, "day")) {
      this.userSelectionService.setRelativeDateRange(endDate.diff(startDate, "day") + 1);
    } else {
      this.userSelectionService.setAbsoluteDateRange(startDate, endDate);
    }
  }

  onShown(): void {
    this.isOpen = true;
    if (!this.container) {
      return;
    }
    this.container.nativeElement
      .getElementsByClassName("bs-datepicker-container")[0]
      .appendChild(this.previousPeriod.nativeElement);
  }

  updateComparedPeriod(option: ComparisonOption): void {
    this.comparedRangeOption = option;
    const periodComparison = option.startDate
      ? option.endDate
        ? [option.startDate, option.endDate]
        : [option.startDate]
      : undefined;
    this.userSelectionService.togglePeriodComparison(
      option?.type !== Comparison.None ? periodComparison : undefined,
      option?.type,
    );
  }

  updateTimeRange(label: string, value: Date[]) {
    const startDate = moment(value[0]);
    const endDate = moment(value[1]);
    if (endDate.diff(startDate, "days") + 1 > StatsApiClientService.maxComp) {
      this.updateComparedPeriod(comparisonPeriods[Comparison.None]);
    }
    this.timeRangeOption = label;
    this.setRangeValue(value);
  }

  updateTimeString(value: Date[]): void {
    const startDate = moment(value[0]);
    const endDate = moment(value[1]);
    this.timeRangeOption = comparisonPeriods[Comparison.Custom].label;

    for (const option of this.ranges) {
      const startDateRange = moment(option.value[0]);
      const endDateRange = moment(option.value[1]);
      if (startDate.isSame(startDateRange, "day") && endDate.isSame(endDateRange, "day")) {
        this.timeRangeOption = option.label;
        return;
      }
    }
  }

  setCustomPeriod(startDate: Date) {
    if (startDate && !moment(startDate).isSame(this.customStartDate)) {
      const _startDate = moment(startDate);
      this.bsRangeValue$.pipe(take(1)).subscribe((dr) => {
        this.startToStartGap = moment(dr[0]).diff(_startDate, "days");
      });
      const endDate = moment(startDate).add(this.range, "days");
      const option = comparisonPeriods[Comparison.Custom];
      option.startDate = _startDate;
      option.endDate = moment(endDate);
      option.start = option.startDate.format(this.format);
      option.end = option.endDate.format(this.format);
      this.updateComparedPeriod(option);
    }
  }
}
