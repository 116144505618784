import { Component, Input } from "@angular/core";
import { getStrategyLink } from "@m19-board/notifications/notification-cards.component";
import { BsModalRef } from "ngx-bootstrap/modal";
import { StrategyUploadResult } from "./bulk-import.service";

@Component({
  selector: "app-strategy-bulk-upload-result-modal",
  templateUrl: "./strategy-bulk-upload-result-modal.component.html",
})
export class StrategyBulkUploadResultModalComponent {
  @Input()
  public uploadResult: StrategyUploadResult;
  @Input()
  public updatedEntity: string;

  successDetailsVisible = false;
  errorDetailsVisible = false;

  constructor(public bsModalRef: BsModalRef) {}

  toggleSuccessDetails(): void {
    this.successDetailsVisible = !this.successDetailsVisible;
  }

  toggleErrorDetails(): void {
    this.errorDetailsVisible = !this.errorDetailsVisible;
  }

  getStrategyLink = getStrategyLink;
}
