// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EntityType {
    AuthorizationAccess = 'AuthorizationAccess',
    Organization = 'Organization',
    OrganizationAdmin = 'OrganizationAdmin',
    Strategy = 'Strategy',
    SbCreative = 'SbCreative',
    ProductGroup = 'ProductGroup',
    Tactic = 'Tactic',
    User = 'User',
    Account = 'Account',
    AmazonUser = 'AmazonUser',
    AccountMarketplace = 'AccountMarketplace',
    BillingAccountMarketplace = 'BillingAccountMarketplace',
    Segment = 'Segment',
    StrategyGroup = 'StrategyGroup',
    AudienceTargeting = 'AudienceTargeting'
}

