<ng-container *transloco="let t">
  <h3 class="mb-8 text-3xl font-bold text-gray-800 dark:text-gray-100">{{ t("common.reset_password") }}</h3>
  @if (error()) {
    <IAlert class="mb-6 block" type="error" [title]="error()!" />
  }
  @if (success()) {
    <IAlert class="mb-6 block" type="success" [title]="t('request-password-reset.password_reset_sent_message')" />
  }
  <lib-request-password-reset-form [isLoading]="loading()" (formSubmit)="requestPasswordReset($event)" />
</ng-container>
