<div class="ag-theme-quartz h-full w-full">
  <ag-grid-angular
    class="h-full"
    [gridOptions]="gridOptions"
    [rowData]="data"
    [detailRowAutoHeight]="true"
    [overlayNoRowsTemplate]="noRowsOverlay"
    [animateRows]="true"
  ></ag-grid-angular>
</div>
