<div class="modal-body" *transloco="let t">
  <ISelect
    class="col-sm-8 p-0"
    [options]="catalogBrands"
    [selectedValue]="brandSelected()?.value"
    placeholder="{{ t('brand-merge-modal.select_brand') }}"
    (selectedOptionChange)="selectBrand($event)"
    searchable
  />
</div>
<div class="modal-footer" *transloco="let t">
  <IButton label="{{ t('common.cancel') }}" color="gray" variant="ghost" (onClick)="close()" />
  <IButton label="{{ t('common.merge') }}" [disabled]="brandSelected === undefined" (onClick)="merge()" />
</div>
