<ng-container *transloco="let t">
  <div class="modal-header bg-primary">
    <h4 class="modal-title pull-left">{{ t("renew-amazon-access-modal.amazon_user_access_emails") }}</h4>
  </div>
  <div class="modal-body">
    @if (!amazonUserAccess || amazonUserAccess.length === 0) {
      {{ t("renew-amazon-access-modal.no_advertising_access") }}
    } @else {
      <li *ngFor="let access of amazonUserAccess">
        @if (!access.isValidToken) {
          {{ t("renew-amazon-access-modal.token_no_longer_valid", [access.email]) }}
        } @else if (!access.hasAccessToProfile) {
          {{ t("renew-amazon-access-modal.no_advertising_campaign_manager", [access.email]) }}
        } @else {
          {{ access.email }}
        }
      </li>
    }
  </div>
  <div class="modal-footer">
    <IButton label="{{ t('common.close') }}" color="gray" variant="ghost" (onClick)="close()" />
    @if (canLoginWithAmazon) {
      <IButton label="{{ t('renew-amazon-access-modal.add_a_new_amazon_email') }}" color="white" (onClick)="renew()" />
    }
  </div>

  <app-spinner [display]="loading" [main]="true"></app-spinner>
</ng-container>
