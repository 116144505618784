<div class="line-bar-chart" *transloco="let t">
  <div style="margin-bottom: 15px" class="flex w-full items-center justify-between pl-5">
    <div class="inline-flex items-center">
      <div class="box-title-sm mr-3">{{ t("sov-main-chart.trend_chart") }}</div>
      <button
        class="btn btn-ghost-secondary custom-btn-dark-hover relative"
        (click)="changeChartType(true)"
        [class.btn-selected]="isBarChart"
      >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_14_386)">
            <path
              d="M2.96875 9.375C3.44147 9.375 3.86932 9.18533 4.18549 8.88077L5.91995 9.74792C5.91125 9.81857 5.89844 9.888 5.89844 9.96094C5.89844 10.9302 6.68701 11.7188 7.65625 11.7188C8.62549 11.7188 9.41406 10.9302 9.41406 9.96094C9.41406 9.6904 9.34753 9.4371 9.23782 9.20792L11.5907 6.85501C11.8199 6.96472 12.0732 7.03125 12.3438 7.03125C13.313 7.03125 14.1016 6.24268 14.1016 5.27344C14.1016 5.09094 14.0657 4.91837 14.0138 4.75281L16.0582 3.21991C16.3371 3.40622 16.6714 3.51562 17.0312 3.51562C18.0005 3.51562 18.7891 2.72705 18.7891 1.75781C18.7891 0.788574 18.0005 0 17.0312 0C16.062 0 15.2734 0.788574 15.2734 1.75781C15.2734 1.94031 15.3093 2.11288 15.3612 2.27844L13.3168 3.81134C13.0379 3.62503 12.7036 3.51562 12.3438 3.51562C11.3745 3.51562 10.5859 4.3042 10.5859 5.27344C10.5859 5.54398 10.6525 5.79727 10.7622 6.02646L8.40927 8.37936C8.18008 8.26965 7.92679 8.20312 7.65625 8.20312C7.18353 8.20312 6.75568 8.39279 6.43951 8.69736L4.70505 7.8302C4.71375 7.75955 4.72656 7.69012 4.72656 7.61719C4.72656 6.64795 3.93799 5.85938 2.96875 5.85938C1.99951 5.85938 1.21094 6.64795 1.21094 7.61719C1.21094 8.58643 1.99951 9.375 2.96875 9.375Z"
              fill="black"
              fill-opacity="0.1"
            />
            <path
              d="M19.4141 18.8281H18.7891V6.44531C18.7891 6.12152 18.5269 5.85938 18.2031 5.85938H15.8594C15.5356 5.85938 15.2734 6.12152 15.2734 6.44531V18.8281H14.1016V9.96094C14.1016 9.63715 13.8394 9.375 13.5156 9.375H11.1719C10.8481 9.375 10.5859 9.63715 10.5859 9.96094V18.8281H9.41406V14.6484C9.41406 14.3246 9.15192 14.0625 8.82812 14.0625H6.48438C6.16058 14.0625 5.89844 14.3246 5.89844 14.6484V18.8281H4.72656V12.3047C4.72656 11.9809 4.46442 11.7188 4.14062 11.7188H1.79688C1.47308 11.7188 1.21094 11.9809 1.21094 12.3047V18.8281H0.585938C0.262146 18.8281 0 19.0903 0 19.4141C0 19.7379 0.262146 20 0.585938 20H19.4141C19.7379 20 20 19.7379 20 19.4141C20 19.0903 19.7379 18.8281 19.4141 18.8281Z"
            />
          </g>
          <defs>
            <clipPath id="clip0_14_386">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <button
        class="btn btn-ghost-secondary custom-btn-dark-hover relative"
        (click)="changeChartType(false)"
        [class.btn-selected]="!isBarChart"
      >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_14_389)">
            <path
              d="M2.96875 9.375C3.44147 9.375 3.86932 9.18533 4.18549 8.88077L5.91995 9.74792C5.91125 9.81857 5.89844 9.888 5.89844 9.96094C5.89844 10.9302 6.68701 11.7188 7.65625 11.7188C8.62549 11.7188 9.41406 10.9302 9.41406 9.96094C9.41406 9.6904 9.34753 9.4371 9.23782 9.20792L11.5907 6.85501C11.8199 6.96472 12.0732 7.03125 12.3438 7.03125C13.313 7.03125 14.1016 6.24268 14.1016 5.27344C14.1016 5.09094 14.0657 4.91837 14.0138 4.75281L16.0582 3.21991C16.3371 3.40622 16.6714 3.51562 17.0312 3.51562C18.0005 3.51562 18.7891 2.72705 18.7891 1.75781C18.7891 0.788574 18.0005 0 17.0312 0C16.062 0 15.2734 0.788574 15.2734 1.75781C15.2734 1.94031 15.3093 2.11288 15.3612 2.27844L13.3168 3.81134C13.0379 3.62503 12.7036 3.51562 12.3438 3.51562C11.3745 3.51562 10.5859 4.3042 10.5859 5.27344C10.5859 5.54398 10.6525 5.79727 10.7622 6.02646L8.40927 8.37936C8.18008 8.26965 7.92679 8.20312 7.65625 8.20312C7.18353 8.20312 6.75568 8.39279 6.43951 8.69736L4.70505 7.8302C4.71375 7.75955 4.72656 7.69012 4.72656 7.61719C4.72656 6.64795 3.93799 5.85938 2.96875 5.85938C1.99951 5.85938 1.21094 6.64795 1.21094 7.61719C1.21094 8.58643 1.99951 9.375 2.96875 9.375Z"
            />
            <path
              d="M19.4141 18.8281H18.7891V6.44531C18.7891 6.12152 18.5269 5.85938 18.2031 5.85938H15.8594C15.5356 5.85938 15.2734 6.12152 15.2734 6.44531V18.8281H14.1016V9.96094C14.1016 9.63715 13.8394 9.375 13.5156 9.375H11.1719C10.8481 9.375 10.5859 9.63715 10.5859 9.96094V18.8281H9.41406V14.6484C9.41406 14.3246 9.15192 14.0625 8.82812 14.0625H6.48438C6.16058 14.0625 5.89844 14.3246 5.89844 14.6484V18.8281H4.72656V12.3047C4.72656 11.9809 4.46442 11.7188 4.14062 11.7188H1.79688C1.47308 11.7188 1.21094 11.9809 1.21094 12.3047V18.8281H0.585938C0.262146 18.8281 0 19.0903 0 19.4141C0 19.7379 0.262146 20 0.585938 20H19.4141C19.7379 20 20 19.7379 20 19.4141C20 19.0903 19.7379 18.8281 19.4141 18.8281Z"
              fill="black"
              fill-opacity="0.1"
            />
          </g>
          <defs>
            <clipPath id="clip0_14_389">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
    <div class="flex">
      <app-date-aggreation-switch-button
        [selected]="dateAggregation$ | async"
        (dateAggSelected)="selectAggregation($event)"
      ></app-date-aggreation-switch-button>
    </div>
  </div>

  @if (!loading) {
    <div class="canvas-ctn">
      <canvas
        baseChart
        class="chart"
        style="height: 100%; max-height: 100%; max-width: 100%"
        [data]="chartData"
        [options]="lineChartOptions"
        [type]="isBarChart ? 'bar' : 'line'"
      ></canvas>
    </div>
  } @else {
    <div class="flex h-full items-center justify-center">
      <app-spinner [main]="false" size="s" [display]="true" type="default"></app-spinner>
    </div>
  }
</div>
