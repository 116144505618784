<ng-container *transloco="let t">
  <div class="relative flex h-full flex-col pb-4">
    @if (accountSelectionList().length === 0) {
      <div class="flex-1">
        {{ t("v2-layout.no_account_available") }}
      </div>
      <IButton [label]="t('common.back')" color="white" (onClick)="onCancel.emit()" />
    } @else {
      <div class="mb-4 flex items-center justify-between">
        <div class="w-[25rem]">
          <IInput
            icon="icon-[lucide--search]"
            [placeholder]="t('common.search')"
            [value]="searchValue()"
            (valueChange)="searchValue.set($event)"
          />
        </div>
        <IButton
          [label]="isAllSelected() ? t('imultiselect.unselect_all') : t('imultiselect.select_all')"
          color="gray"
          variant="ghost"
          (onClick)="toggleSelectAllAccounts()"
        />
      </div>

      <div class="flex flex-1 flex-col gap-4 overflow-auto">
        @for (a of filteredAccountSelectionList(); track a.accountId) {
          @let selectedMarketplaceNumber = selectedMarketplaceNumberByAccountId().get(a.accountId);
          @let hasAllMarketplacesSelected = selectedMarketplaceNumber === a.marketplaces.length;

          <div>
            <div
              class="flex w-full gap-3 rounded-lg border border-gray-200 p-3"
              [ngClass]="[hasAllMarketplacesSelected ? 'bg-main-50/80 border-main-400' : 'border-gray-200 bg-white']"
            >
              <div>
                <ICheckbox
                  [value]="hasAllMarketplacesSelected"
                  (valueChange)="toggleSelectAllMarketplaces(a.accountId, !hasAllMarketplacesSelected)"
                />
              </div>
              <div class="flex-1">
                <div class="flex w-full items-center">
                  <div class="leading-5 text-gray-800">{{ a.accountName }}</div>
                </div>
                <div class="text-sm text-gray-400">
                  {{ a.isSeller ? "Seller" : "Vendor" }} - {{ a.marketplaces.length }} marketplace{{
                    a.marketplaces.length > 1 ? "s" : ""
                  }}
                  @if (selectedMarketplaceNumber && selectedMarketplaceNumber > 0) {
                    <span class="text-main-500 font-medium"> - {{ selectedMarketplaceNumber }} selected</span>
                  }
                </div>
                <div class="mt-2 flex flex-wrap gap-x-3">
                  @for (m of a.marketplaces; track m.marketplace) {
                    <div [matTooltip]="m.disableReason">
                      <button
                        class="relative rounded-xl border px-2 py-1 text-sm text-gray-600 disabled:opacity-50"
                        [ngClass]="[m.isSelected ? 'bg-main-100 border-main-400' : 'border-gray-200 bg-white']"
                        (click)="toggleMarketplaceSelection(a.accountId, m.marketplace, !m.isSelected)"
                        [disabled]="!!m.disableReason"
                      >
                        {{ Marketplaces[m.marketplace].flag }}
                        {{ m.marketplace }}

                        @if (m.isSelected) {
                          <div
                            class="bg-main-400 absolute -right-1.5 -top-1.5 flex h-4 w-4 items-center justify-center rounded-full"
                          >
                            <span class="icon-[lucide--check] text-xs text-white"></span>
                          </div>
                        }
                      </button>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    }
  </div>
</ng-container>
