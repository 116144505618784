// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MediaType {
    brandLogo = 'brandLogo',
    otherImage = 'otherImage',
    productImage = 'productImage',
    customImage = 'customImage',
    storePage = 'storePage',
    videoAsset = 'videoAsset'
}

