import { M19Status, Marketplace, Notif, NotificationType, State, StrategyStateEnum } from '@front/m19-api-client';
import { NotificationMetadata } from '@front/m19-services';
import { StrategyEx } from './StrategyEx';

export class NotificationEx implements Notif {
  accountId: string;
  accountName = '';
  marketplace: Marketplace;
  strategyId: number;
  subStrategyId?: number;
  type: NotificationType;
  dismissed: boolean;
  creationTimestamp: string;
  message?: string;

  // metadata info
  isCritical = false;
  label!: string;
  priority!: number;

  constructor(notification: Notif) {
    this.accountId = notification.accountId;
    this.marketplace = notification.marketplace;
    this.strategyId = notification.strategyId;
    this.subStrategyId = notification.subStrategyId;
    this.type = notification.type;
    this.dismissed = notification.dismissed;
    this.message = notification.message || '';
    this.creationTimestamp = notification.creationTimestamp + 'Z';
  }

  setAccountName(accountName: string) {
    this.accountName = accountName;
  }

  setNotificationMetadata(metadata: NotificationMetadata): void {
    this.isCritical = metadata.critical;
    this.label = metadata.label;
    this.priority = metadata.priority;
  }

  isRelatedToBidder(): boolean {
    return (
      this.type === NotificationType.BIDDER_ISSUE ||
      this.type === NotificationType.OVERLAPPING_STRATEGIES ||
      this.type === NotificationType.OVERLAPPING_WITH_NON_M19_STRATEGIES
    );
  }
}

export type SBModerationDetails = { segment: string; violatingType: string; violatingContent: string };

type IssueMsg = { message: string; type: M19Status; sBModerationDetails: SBModerationDetails[] };

export class NotificationBidderIssueEx extends NotificationEx {
  warningType?: M19Status;
  warningMessage?: string;
  notApplicable = false;
  moderationDetails?: SBModerationDetails[];

  constructor(notification: NotificationEx, strategyIndex: Map<number, StrategyEx>) {
    super(notification);
    this.creationTimestamp = this.creationTimestamp.substring(0, this.creationTimestamp.length - 1);
    this.isCritical = notification.isCritical;
    this.label = notification.label;
    this.priority = notification.priority;

    if (!this.message) {
      return;
    }

    if (notification.dismissed) {
      this.notApplicable = true;
    }

    const strat = strategyIndex.get(this.strategyId);
    if (!strat || strat.state === StrategyStateEnum.PAUSED) {
      this.notApplicable = true;
      return;
    }

    if (strat.sbCreatives) {
      const crea = strat.sbCreatives.find((c) => c.creativeId === this.subStrategyId);
      if (!crea || crea.state === State.OFF) {
        this.notApplicable = true;
        return;
      }
    }
    let msgObj: IssueMsg;
    try {
      msgObj = JSON.parse(this.message);
    } catch (e) {
      msgObj = { message: this.message, type: undefined as unknown as M19Status, sBModerationDetails: [] };
      // eslint-disable-next-line no-console
      console.error('Error parsing notification message ' + this.message, e);
    }
    this.warningMessage = msgObj.message;
    this.warningType = msgObj.type;
    switch (msgObj.type) {
      case M19Status.IGNORED_REJECTED:
        this.notApplicable = false;
        this.moderationDetails = msgObj.sBModerationDetails;
        this.warningMessage =
          msgObj.message ??
          'At least one campaign has been rejected by Amazon. Please check your draft to understand the result.';
        return;
      case M19Status.PUSH_FAILED_NO_BRAND_REGISTERED:
        this.warningMessage = 'Brand registration issue: ' + this.warningMessage;
        this.notApplicable = false;
        return;
      case M19Status.LAST_BIDDER_PUSH_ISSUE:
        this.warningMessage = 'Last updates to Amazon issue: ' + this.warningMessage;
        this.notApplicable = false;
        return;
      case M19Status.ENDED_CAMPAIGN:
        this.warningMessage = `Campaign end date must be removed via the Advertising Console for all campaigns of the portfolio: ${strat.campaignType} - ${strat.name}`;
        this.notApplicable = false;
        return;
      default:
        // not yet implemented
        this.notApplicable = true;
        break;
    }
  }
}
