import { Component, ElementRef, OnInit, Renderer2, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRouteSnapshot, ActivationEnd, Router } from "@angular/router";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import {
  AccountSelectionService,
  AuthService,
  BillingService,
  CurrencyService,
  NotificationService,
  OrganizationAccountGroupService,
  UserSelectionService,
} from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { Selector } from "@m19-board/app.routing";
import { BoardType, LayoutSelectorService } from "@m19-board/layout-selector.service";
import { Hotkeys } from "@m19-board/services/hotkeys.service";
import { ObfuscationService } from "@m19-board/services/obfuscation.service";
import { AccountSettingsService } from "@m19-board/settings/account-settings.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { combineLatest, Observable, of } from "rxjs";
import { delay, filter, switchMap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { DebugInfoModalComponent } from "../default-layout/debug-info-modal.component";
import { AccessLevel, AccountMarketplace, AccountSubType, AccountType, Currency, User } from "@front/m19-api-client";
import { AccountGroup, NotificationEx, OrganizationAccountGroups } from "@front/m19-models";

interface MenuItem {
  label: string;
  icon: string;
  routerLink?: string;
  href?: string;
  target?: string;
  queryParamsHandling: "merge" | "preserve" | "";
  supportedBoards?: BoardType[];
  onClick?: () => void;
}

@UntilDestroy()
@Component({
  selector: "app-v2-layout",
  templateUrl: "./v2-layout.component.html",
  styleUrls: ["./v2-layout.component.scss"],
  encapsulation: ViewEncapsulation.None, // For toastr
})
export class V2LayoutComponent implements OnInit {
  sideBarOpened = false;
  isTest = !environment.production;

  readonly faSquare = faSquare;
  readonly faCheckSquare = faCheckSquare;
  readonly BoardType = BoardType;
  readonly releaseNotesUrl = environment.releaseNotesUrl;
  readonly Selector = Selector;
  navbarBrandFull = { src: "assets/img/logo_header.png", height: 40, alt: "m19 Logo" };
  navbarBrandMinimized = { src: "assets/img/logo.png", width: 40, height: 40, alt: "m19 logo" };
  boardType: BoardType;
  whitelabelContactUsLink = undefined;
  isBillingOwnerOfActiveSubscription = false;

  user: User;
  currency: Currency;
  locale: string;
  betaTest = false;
  notifications: NotificationEx[] = [];
  notificationsCount = 0;
  notificationsOpened = false;
  invalidAccountMarketplaces: AccountMarketplace[] = [];
  pendingValidationAccountMarketplaces: AccountMarketplace[] = [];
  organizations: Option<OrganizationAccountGroups>[] = [];
  selectedOrganizations: Option<OrganizationAccountGroups>[] = [];
  agencyBoardEligible = false;
  globalWarning$: Observable<string>;
  accountMarketplaces: AccountMarketplace[];
  selectedDateRage: string[];
  debugInfoModalVisible = false;
  displayedSelector = Selector.None;

  @ViewChild("notificationsCtn") notificationsCtn: ElementRef;

  readonly PROFILE_ITEMS: MenuItem[] = [
    {
      label: "dashboard360-layout.my_profile",
      icon: "icon-[mdi--user-outline]",
      routerLink: "/profile",
      queryParamsHandling: "merge",
    },
    {
      label: "v2-layout.accounts",
      icon: "icon-[mdi--user-multiple-outline]",
      routerLink: "/accounts",
      queryParamsHandling: "merge",
    },
    {
      label: "v2-layout.user_management",
      icon: "icon-[mdi--user-key-outline]",
      routerLink: "/user-management",
      queryParamsHandling: "merge",
    },
    {
      label: "v2-layout.billing",
      icon: "icon-[mdi--credit-card-outline]",
      routerLink: "/billing",
      queryParamsHandling: "merge",
      supportedBoards: [BoardType.M19, BoardType.WHITELABEL_SELFSERVICE],
    },
    {
      label: "data-sharing.data_hub",
      icon: "icon-[mdi--database-outline]",
      routerLink: "/datahub",
      queryParamsHandling: "merge",
    },
    {
      label: "v2-layout.referral_program",
      icon: "icon-[mdi--gift-outline]",
      href: "https://www.m19.com/get-rewards-for-your-account",
      target: "_blank",
      supportedBoards: [BoardType.M19],
      queryParamsHandling: "",
    },
    {
      label: "v2-layout.release_notes",
      icon: "icon-[mdi--sparkles-outline]",
      href: this.releaseNotesUrl,
      target: "_blank",
      queryParamsHandling: "",
    },
    {
      label: "dashboard360-layout.logout",
      icon: "icon-[mdi--logout]",
      onClick: () => this.logout(),
      queryParamsHandling: "",
    },
  ];

  constructor(
    private authService: AuthService,
    private accountGroupService: OrganizationAccountGroupService,
    private userSelectionService: UserSelectionService,
    public currencyService: CurrencyService,
    private router: Router,
    private layoutSelector: LayoutSelectorService,
    private notificationService: NotificationService,
    private renderer: Renderer2,
    private accountSelectionService: AccountSelectionService,
    private billingService: BillingService,
    private accountSettingsService: AccountSettingsService,
    private modalService: BsModalService,
    private hotkeys: Hotkeys,
    private obfuscationService: ObfuscationService,
  ) {
    this.globalWarning$ = this.notificationService.globalWarning$;
    this.hotkeys
      .addShortcut({ altKey: "KeyD" })
      .pipe(untilDestroyed(this))
      .subscribe(() => this.printDebugInfo());
    this.hotkeys
      .addShortcut({ altKey: "KeyO" })
      .pipe(untilDestroyed(this))
      .subscribe(() => this.obfuscationService.toggleObfuscationMode(!this.obfuscationService.obfuscation.value));

    // react on route change to change selector display
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof ActivationEnd) {
        if (event.snapshot.data?.selector) {
          this.displayedSelector = event.snapshot.data.selector;
        }
      }
    });
  }

  ngOnInit(): void {
    // init account selector based on router state
    let routerState: ActivatedRouteSnapshot[] = [this.router.routerState.snapshot.root];
    while (routerState.length > 0) {
      const state = routerState.pop();
      if (state && state.data?.["selector"]) {
        this.displayedSelector = state.data?.["selector"];
        break;
      }
      if (state && state.children) {
        routerState = [...routerState, ...state.children];
      }
    }

    this.boardType = this.layoutSelector.getBoardType();
    this.whitelabelContactUsLink = this.layoutSelector.getConctact();
    this.navbarBrandFull = { src: this.layoutSelector.getLogo(), height: 40, alt: "logo" };
    this.navbarBrandMinimized = { src: this.layoutSelector.getSmallIcon(), width: 40, height: 40, alt: "logo" };

    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((x) => (this.currency = x));

    this.billingService.organizationOnwer$.pipe(untilDestroyed(this)).subscribe((o) => {
      // used to display the billing page for billing owner of whitelabel boards
      this.isBillingOwnerOfActiveSubscription = !!o?.hasActiveSubscription();
    });

    this.accountGroupService.allOrganizationAccountGroups$
      .pipe(
        untilDestroyed(this),
        switchMap((organizations) =>
          combineLatest<
            [
              NotificationEx[],
              AccountGroup,
              AccountMarketplace[],
              OrganizationAccountGroups[],
              OrganizationAccountGroups[],
            ]
          >([
            this.notificationService.getNotifications$,
            this.accountSelectionService.accountGroupSelection$,
            this.accountSelectionService.accountMarketplacesSelection$,
            of(organizations),
            this.userSelectionService.selectedOrganizations$,
          ]).pipe(
            filter(([n, a]) => n != undefined && a != undefined),
            delay(10), // delay subscription so as to occur after view check
          ),
        ),
      )
      .subscribe(([notifications, account, accountMarketplaces, organizations, selectedOrgs]) => {
        const accountIds = account.getAccountMarketplaces().map((a) => a.accountId);
        this.notifications = this.filterNotifications(notifications, accountIds, accountMarketplaces);
        this.notificationsCount = this.notifications.length;
        this.notifications = this.notifications.slice(0, 50);
        this.invalidAccountMarketplaces = account
          .getAccountMarketplaces()
          .filter((am) => am.accessLevel != AccessLevel.READ && !am.isValidToken);
        this.pendingValidationAccountMarketplaces = account
          .getAccountMarketplaces()
          .filter(
            (am) =>
              am.accessLevel != AccessLevel.READ &&
              am.accountType == AccountType.VENDOR &&
              am.isValidToken &&
              !am.approvedBy &&
              am.nbAsinsToValidate > 0,
          );
        if (organizations.length > 0) {
          this.organizations = organizations.map((o: OrganizationAccountGroups) => ({
            label: o.organizationName,
            value: o,
            id: o.id,
          }));
        }

        // If selected orgnizations is undefined, select all by default
        if (!selectedOrgs) {
          this.userSelectionService.setSelectedOrganizations(this.organizations.map((o) => o.value));
        } else {
          this.selectedOrganizations = selectedOrgs.map((o) => ({
            label: o.organizationName,
            value: o,
            id: o.id,
          }));
        }
        this.agencyBoardEligible = organizations.some((o: OrganizationAccountGroups) => o.accountGroups.length > 1);
        this.accountMarketplaces = accountMarketplaces;
      });

    this.renderer.listen("window", "click", (e: PointerEvent) => {
      if ((e.target as HTMLElement).isConnected && !this.notificationsCtn.nativeElement.contains(e.target)) {
        this.notificationsOpened = false;
      }
    });

    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user;
      this.locale = user.locale;
      this.betaTest = user.betaTester;
    });
    this.userSelectionService.dateRange$.pipe(untilDestroyed(this)).subscribe((dr) => {
      this.selectedDateRage = dr;
    });
  }

  selectOrganization(organizations: Option<OrganizationAccountGroups>[]) {
    this.selectedOrganizations = organizations;
    this.userSelectionService.setSelectedOrganizations(organizations.map((o) => o.value));
  }

  private filterNotifications(
    notifications: NotificationEx[],
    accountIds: string[],
    accountMarketplaces: AccountMarketplace[],
  ) {
    const result = [];
    for (const notification of notifications) {
      if (notification.dismissed) {
        continue; // do not display dismissed notif
      }
      if (notification.isCritical) {
        result.push(notification); // display critical notif
        continue;
      }
      if (
        notification.marketplace &&
        accountMarketplaces.findIndex(
          (am) => am.accountId == notification.accountId && am.marketplace == notification.marketplace,
        ) > -1
      ) {
        // display notification of the selected marketplaces
        result.push(notification);
        continue;
      }
      if (!notification.marketplace && accountIds.includes(notification.accountId)) {
        // display notification of the selected marketplaces
        result.push(notification);
      }
    }
    return result;
  }

  hasInvalidToken() {
    return this.invalidAccountMarketplaces.length > 0;
  }

  logInPlaceForAPIGrantAccess() {
    return this.invalidAccountMarketplaces[0].accountType == AccountType.SELLER ? "Seller Central" : "Vendor Central";
  }

  hasPendingVendorIntegration() {
    return this.pendingValidationAccountMarketplaces.length > 0;
  }

  requestGrant() {
    if (this.invalidAccountMarketplaces.length == 1) {
      const am = this.invalidAccountMarketplaces[0];
      this.accountSettingsService.grantSellingPartnerAccess(
        am.accountId,
        am.marketplace,
        am.accountType,
        am.accountSubType === AccountSubType.KDP_AUTHOR,
      );
    } else {
      // TODO: expand account groups with invalid access
      this.router.navigate(["accounts"]);
    }
  }

  validateAccountIntegration() {
    // display popup for first account requiring validation
    if (this.pendingValidationAccountMarketplaces.length == 0) {
      return;
    }
    this.accountSettingsService.validateIntegration(this.pendingValidationAccountMarketplaces[0]);
  }

  isAdminLogin(): boolean {
    return this.authService.isAdminLogin();
  }

  printDebugInfo() {
    // prevent modal to open again if already shown
    if (this.debugInfoModalVisible) {
      return;
    }
    this.debugInfoModalVisible = true;
    const modalOptions: ModalOptions = {
      initialState: {
        user: this.user,
        accountMarketplaces: this.accountMarketplaces,
        dateRange: this.selectedDateRage,
      },
      class: "modal-lg",
    };
    const modalRef = this.modalService.show(DebugInfoModalComponent, modalOptions);
    modalRef.onHidden.subscribe(() => {
      this.debugInfoModalVisible = false;
    });
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(["login"]);
  }

  openNotifications(event: MouseEvent) {
    this.notificationsOpened = !this.notificationsOpened;
    event.preventDefault();
  }
}
