import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { AdStatsEx, StrategyEx } from "@front/m19-models";
import { UntilDestroy } from "@ngneat/until-destroy";
import moment, { MomentZone } from "moment-timezone";
import { StrategyStats } from "../../../models/Metric";
import { DataSet, MetricsSelectorLocalStorageKey, UserSelectionService } from "@front/m19-services";
import { Metric } from "@front/m19-metrics";
import { Utils } from "@front/m19-utils";
import { TranslocoDirective } from "@jsverse/transloco";
import { StrategyDropdownComponent } from "@m19-board/shared/strategy-dropdown/strategy-dropdown.component";
import { IButtonComponent, ICheckboxComponent } from "@front/m19-ui";
import { MetricSelectorComponent } from "@m19-board/insights/metric-selector/metric-selector.component";
import { MatTooltip } from "@angular/material/tooltip";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";
import { BaseChartDirective } from "ng2-charts";
import { ICON_TIMES } from "@m19-board/utils/iconsLabels";

@UntilDestroy()
@Component({
  selector: "app-hourly-chart",
  templateUrl: "./hourly-chart.component.html",
  styleUrls: ["./hourly-chart.component.scss"],
  imports: [
    TranslocoDirective,
    StrategyDropdownComponent,
    ICheckboxComponent,
    MetricSelectorComponent,
    SpinnerComponent,
    BaseChartDirective,
    IButtonComponent,
  ],
  standalone: true,
})
export class HourlyChartComponent {
  @ViewChild("canvas") canvas!: HTMLCanvasElement;

  @Input() hourlyDataSupported!: boolean;

  @Input() globalData: AdStatsEx = {};
  @Input() dataSet!: DataSet<AdStatsEx>;

  @Input() timeZone!: MomentZone;
  @Input() strategies!: StrategyEx[];
  @Input() pendingRequests!: boolean;

  @Output() onSelectedMetric = new EventEmitter<Metric<AdStatsEx>[]>();
  @Output() onCustomMetric = new EventEmitter<Metric<AdStatsEx>[]>();
  @Output() onSelectedStrategy = new EventEmitter<StrategyEx>();

  @Input() chartDisplayed!: boolean;
  @Output() onChartDisplay = new EventEmitter<boolean>();
  @Output() onNonM19CampaignChange = new EventEmitter<boolean>();
  @Input() displayWarning = false;
  @Input() nbDaysConsidered = 0;

  @Input() localStorageKey!: MetricsSelectorLocalStorageKey;

  @Input() CHART_METRICS!: Metric<StrategyStats>[];

  @Input() includeNonM19Campaign!: boolean;

  constructor(private userSelectionService: UserSelectionService) {}

  selectMetrics(metrics: Metric<AdStatsEx>[]) {
    this.onSelectedMetric.emit(metrics);
  }

  setStrategy(strategy: StrategyEx | undefined): void {
    this.onSelectedStrategy.emit(strategy);
  }

  toggleChartDisplay(displayed: boolean): void {
    this.chartDisplayed = displayed;
    this.userSelectionService.setUserChartDisplayedPreference(this.localStorageKey, displayed);
    this.onChartDisplay.emit(this.chartDisplayed);
  }

  toggleIncludeNonM19Campaign(bool: boolean): void {
    // Reset strategy or product group to all products
    this.includeNonM19Campaign = bool;
    this.onNonM19CampaignChange.emit(bool);
  }

  getTimezone(): string {
    return this.timeZone?.abbr(moment.now());
  }

  getTimezonUtcOffset(): string {
    return Utils.getTimezoneOffset(this.timeZone?.name);
  }

  protected readonly ICON_TIMES = ICON_TIMES;
}
