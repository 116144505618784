import { NgStyle } from "@angular/common";
import { Component, computed, input, output, signal } from "@angular/core";
import { AccountType, StrategyType } from "@front/m19-api-client";
import { StrategyEx, StrategyTypeStr } from "@front/m19-models";
import { IBadgeComponent, ISelectComponent, Option } from "@front/m19-ui";
import { CAMPAIGN_TYPE_COLOR } from "@m19-board/insights/advertising-stats/advertising-stats.component";

@Component({
  selector: "app-strategy-dropdown",
  template: ` <ISelect
    [options]="options()"
    [selectedValue]="selectedOption().value"
    by="strategyId"
    (selectedOptionChange)="selectOption($event)"
    searchable
  >
    <ng-template #optionSlot #labelSlot let-o>
      <div class="flex w-full items-center justify-between">
        <div class="flex items-center truncate">
          @if (o.value) {
            <span
              [ngStyle]="{ 'background-color': $any(CAMPAIGN_TYPE_COLOR)[o.value.campaignType] }"
              class="badge mr-2 shrink-0 rounded-full text-white"
              >{{ o.value.campaignType }}
            </span>
          }
          <span class="truncate text-sm">{{ o.label }}</span>
        </div>
        @if (o.value && o.value.strategyType !== StrategyType.LEGACY) {
          <IBadge color="gray" [label]="$any(StrategyTypeStr)[o.value.strategyType]" class="ml-2" size="xs" />
        }
      </div>
    </ng-template>
  </ISelect>`,
  standalone: true,
  imports: [NgStyle, ISelectComponent, IBadgeComponent],
})
export class StrategyDropdownComponent {
  readonly allStrategiesOption: Option<StrategyEx> = {
    label: "All Strategies",
    value: {
      strategyId: 0,
    } as StrategyEx,
  };

  readonly strategies = input<StrategyEx[]>();
  readonly selectedStrategy = output<StrategyEx>();

  accountType: AccountType | undefined;

  readonly options = computed<Option<StrategyEx>[]>(() => {
    const options: Option<StrategyEx>[] = [this.allStrategiesOption];
    const strategies = this.strategies();
    if (strategies && strategies.length > 0) {
      options.push(
        ...strategies.map((s) => ({
          label: s.name!,
          value: s,
        })),
      );
    }
    return options;
  });
  readonly selectedOption = signal<Option<StrategyEx>>(this.allStrategiesOption);
  readonly CAMPAIGN_TYPE_COLOR = CAMPAIGN_TYPE_COLOR;
  readonly StrategyType = StrategyType;
  readonly StrategyTypeStr = StrategyTypeStr;

  selectOption(option: Option<StrategyEx>) {
    this.selectedOption.set(option);
    this.selectedStrategy.emit(option?.value);
  }
}
