import { Component } from "@angular/core";
import { AbstractControl, ReactiveFormsModule, UntypedFormBuilder, Validators } from "@angular/forms";
import { OrganizationUsersService } from "@front/m19-services";
import { BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { UserEmailValidator } from "../manage-authorized-user-modal/manage-authorized-access-modal.component";
import { UserApi } from "@front/m19-api-client";
import { TranslocoDirective } from "@jsverse/transloco";
import { NgClass } from "@angular/common";

@Component({
  templateUrl: "./add-admin-modal.component.html",
  standalone: true,
  imports: [TranslocoDirective, ReactiveFormsModule, NgClass],
})
export class AddAdminModalComponent {
  organizationId!: number;
  organizationName!: string;

  constructor(
    public modalService: BsModalService,
    private toasterService: ToastrService,
    private organizationUsersService: OrganizationUsersService,
    private userApi: UserApi,
    private fb: UntypedFormBuilder,
  ) {}

  readonly emailForm = this.fb.group({
    email: [
      "",
      [Validators.required, Validators.email, Validators.pattern(/.*@.+\..+/)],
      [UserEmailValidator.createValidator(this.userApi)],
    ],
  });

  get email(): AbstractControl {
    return this.emailForm.get("email")!;
  }

  addAdmin(): void {
    const sub = this.organizationUsersService.addAdmin(this.organizationId, this.email.value).subscribe({
      next: () => {
        this.toasterService.success(
          "Successfully added " + this.email.value + " as an admin in " + this.organizationName,
        );
        this.modalService.hide();
        sub.unsubscribe();
      },
      error: (error) => {
        this.toasterService.error(error, "Admin creation error");
        sub.unsubscribe();
      },
    });
  }
}
