// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    PreModerationPolicyViolationImageEvidencesInner,
    PreModerationPolicyViolationTextEvidencesInner,
    PreModerationPolicyViolationTextEvidencesInnerPosition,
} from './';

/**
 * @export
 * @interface PreModerationPolicyViolation
 */
export interface PreModerationPolicyViolation {
    /**
     * @type {string}
     * @memberof PreModerationPolicyViolation
     */
    policyDescription?: string;
    /**
     * @type {string}
     * @memberof PreModerationPolicyViolation
     */
    name?: string;
    /**
     * @type {string}
     * @memberof PreModerationPolicyViolation
     */
    type?: string;
    /**
     * @type {string}
     * @memberof PreModerationPolicyViolation
     */
    policyLinkUrl?: string;
    /**
     * @type {Array<PreModerationPolicyViolationTextEvidencesInner>}
     * @memberof PreModerationPolicyViolation
     */
    textEvidences?: Array<PreModerationPolicyViolationTextEvidencesInner>;
    /**
     * @type {Array<PreModerationPolicyViolationTextEvidencesInnerPosition>}
     * @memberof PreModerationPolicyViolation
     */
    videoEvidences?: Array<PreModerationPolicyViolationTextEvidencesInnerPosition>;
    /**
     * @type {Array<PreModerationPolicyViolationImageEvidencesInner>}
     * @memberof PreModerationPolicyViolation
     */
    imageEvidences?: Array<PreModerationPolicyViolationImageEvidencesInner>;
}
