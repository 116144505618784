export class Constant {
  // see: https://advertising.amazon.com/API/docs/en-us/reference/concepts/limits#entity-name-character-constraints
  // Japanese characters are allowed in strategy name according to https://amazon-ads-api.zendesk.com/hc/en-us/requests/771
  public static readonly nameRegexp =
    /^[- !$'#%& () * +,./: ;<=>?@`_|{}~0-9a-zA-Z®ÁÉÍÑÓÚÜáéíñóúüÄÖÜßäöüÀÂÆÇÈÉÊËÎÏÔÙÛÜŒŸàâæçèêëîïôùûüÿœ\\[\]\u0022\u3000-\u309F\u30A0-\u30FF\u4E00-\u9FFF\uFF00-\uFFEF]*$/;

  public static readonly invalidSBSDNameRegexp = /[!%#<>.]+/;
  public static readonly maxAsinBySegment = 250;
  public static readonly maxKeywordBySegment = 250;
  public static readonly maxKwTargetingByStrategy = 20;
  public static readonly maxAsinTargetingByStrategy = 250;
  public static readonly maxBrandDefenseStrategiesByStrategyGroup = 5;
  public static readonly maxKeywordStrategiesByStrategyGroup = 10;
  public static readonly maxAdlinesPerCreative = 5;
  public static readonly maxCreativesPerStrategy = 10;
  public static readonly maxAsinPerVideoAdLine = 5;
  public static readonly maxSbStrategies = 150;
  public static readonly maxTargetingsPerSdStrategy = 5;
}
