<ng-container *transloco="let t">
  <div class="flex flex-col gap-8 px-4">
    @if (isTacosStrategyPaused()) {
      <IAlert [title]="t('tacos-strategy.strategies_wont_deliver')" />
    }
    <div class="flex items-center gap-3">
      <h5 class="m-0 font-medium text-gray-800">{{ t("v2-sidebar.sponsored_products") }}</h5>
      <div [matTooltip]="t('tacos-strategy.sp_cannot_be_paused')" class="flex">
        <IToggle [disabled]="true" [checked]="true" />
      </div>
    </div>

    <div class="flex items-center gap-3">
      <h5 class="m-0 font-medium text-gray-800">{{ t("v2-sidebar.sponsored_display") }}</h5>
      <IToggle
        [checked]="isSdToggleChecked()"
        [loading]="sdLoadingState()"
        [matTooltip]="
          isSdToggleChecked() ? t('tacos-strategy.pause_sd_strategy') : t('tacos-strategy.activate_sd_strategy')
        "
        (checkedChange)="onSdToggleChange(!!$event)"
        [disabled]="isReadonly()"
      />
    </div>
    @if (tacosStrategy().sdStrategy) {
      <div class="flex w-full items-center gap-4 rounded-md border border-gray-200 p-4 shadow-sm">
        <IBadge label="Product Page" color="gray" />
        AI powered Targeting
      </div>
    }

    <div class="flex items-center gap-3">
      <h5 class="m-0 font-medium text-gray-800">{{ t("v2-sidebar.sponsored_brand") }}</h5>
      <IToggle
        [checked]="isSbToggleChecked()"
        [loading]="sbLoadingState()"
        [matTooltip]="
          isSbToggleChecked() ? t('tacos-strategy.pause_sb_strategy') : t('tacos-strategy.activate_sb_strategy')
        "
        (checkedChange)="onSbToggleChange(!!$event)"
        [disabled]="isReadonly()"
      />
    </div>

    @if (tacosStrategy().sbCreatives?.length) {
      @if (missingAsinsInSb()?.length) {
        <IAlert [title]="t('tacos-strategy.missing_asins_in_sb')" type="warning">
          {{ t("tacos-strategy.missing_asins_in_sb_description") }} <br />
          @for (asin of missingAsinsInSb(); track asin) {
            {{ asin + ($last ? "" : ", ") }}
          }
        </IAlert>
      }
      <div class="flex justify-end">
        <IButton
          [icon]="ICON_ADD"
          [label]="t('sb-page.new_ad_line')"
          color="white"
          size="sm"
          [disabled]="!canCreateNewCreative()"
          (click)="openSbForm()"
        />
      </div>
      <div class="flex flex-col gap-4">
        @for (creative of tacosStrategy().sbCreatives; track creative.creativeId) {
          <app-creative-display
            [strategy]="sbStrategyEx()!"
            [tacosStrategy]="tacosStrategy()"
            [creative]="creative"
            [brandAssets]="brandAssets()?.get(creative.creativeId)"
          />
        }
      </div>
    }
  </div>

  <ng-template #newCreativeModal>
    <div class="h-[85vh] w-[70vw]">
      <app-sb-strategy-form
        [tacosStrategy]="tacosStrategy()"
        [strategy]="sbStrategyEx()!"
        [formMode]="tacosStrategy().sbCreatives?.length ? FormMode.NEW_CREATIVE : FormMode.NEW_STRATEGY"
        (onStrategyCreated)="modalRef?.close()"
      />
    </div>
  </ng-template>
</ng-container>
