import { Marketplace, StrategyGroup, StrategyType, Targeting } from '../api-client';
import { StrategyEx } from './StrategyEx';

export type StrategyGroupEx = StrategyGroup & {
  productStrategies: StrategyEx[];
  brandStrategies: StrategyEx[];
  keywordStrategies: StrategyEx[];
  asins: string[];
};

export function getStrategies(strategyGroup: StrategyGroupEx, strategyType?: StrategyType) {
  if (!strategyType) {
    return strategyGroup.productStrategies
      .concat(strategyGroup.brandStrategies)
      .concat(strategyGroup.keywordStrategies);
  }
  switch (strategyType) {
    case StrategyType.PRODUCT:
      return strategyGroup.productStrategies;
    case StrategyType.BRAND:
      return strategyGroup.brandStrategies;
    case StrategyType.KEYWORD:
      return strategyGroup.keywordStrategies;
  }
  return [];
}

export type StrategyGroupUpdateParams = {
  accountId: string;
  marketplace: Marketplace;
  organizationId: number;
  strategyGroupId: number;
  strategyGroupName?: string;
};

export type StrategyGroupBlacklistUpdateParams = {
  accountId: string;
  marketplace: Marketplace;
  strategyGroupId: number;
  toAdd: Targeting[];
  toDelete: Targeting[];
};

export enum StrategyTargetingType {
  KEYWORDS = 'Keyword',
  PRODUCTS = 'Product',
}

export function singleStrategyInStrategyGroup(strategyGroup: StrategyGroupEx): boolean {
  return (
    strategyGroup.brandStrategies.length == 0 &&
    strategyGroup.keywordStrategies.length == 0 &&
    strategyGroup.productStrategies.length == 1
  );
}
