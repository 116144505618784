import { Observable, Subject, switchMap, throwError } from 'rxjs';
import { AccountApi, AmazonUser } from '@front/m19-api-client';
import { inject, Injectable } from '@angular/core';
import { AMAZON_LOGIN_HANDLER } from '../injection-token';
import { TranslocoService } from '@jsverse/transloco';
export interface AmazonLoginHandler {
  authorize: (config: { scope: string; response_type: string }, responseHandler: (response: any) => void) => void;
  logout: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class AmazonUserService {
  private readonly amazonLoginHandler = inject(AMAZON_LOGIN_HANDLER);
  private readonly scope = 'profile cpc_advertising:campaign_management';

  constructor(
    private accountApi: AccountApi,
    private translocoService: TranslocoService,
  ) {}

  // doc: https://developer.amazon.com/fr/docs/login-with-amazon/javascript-sdk-reference.html
  /**
   * @deprecated: use getAmazonUserV2 instead
   */
  public getAmazonUser(
    organizationId: number,
    onSuccess?: (user: AmazonUser) => void,
    onError?: (message: string) => void,
    onAmazonResponse?: () => void,
  ): void {
    this.amazonLoginHandler!.subscribe((handler) =>
      handler.authorize({ scope: this.scope, response_type: 'code' }, (response) => {
        if (response.error) {
          if (onError)
            onError(this.translocoService.translate('amazonUser-service.error_adv_campaign', [response.error]));
        } else {
          if (onAmazonResponse) onAmazonResponse();
          this.accountApi.getAmazonUser({ code: response.code, organizationId: organizationId }).subscribe(
            (x: AmazonUser) => {
              if (onSuccess) onSuccess(x);
            },
            (error) => {
              if (onError) onError(error.response ? error.response.message : error.message);
            },
          );
        }
      }),
    );
  }

  public getAmazonUserV2(organizationId: number): Observable<AmazonUser> {
    return this.amazonLoginHandler!.pipe(
      switchMap((handler) => {
        const amazonResponse = new Subject<any>();

        handler.authorize({ scope: this.scope, response_type: 'code' }, (response) => {
          if (response.error) {
            amazonResponse.next(
              throwError(() =>
                this.translocoService.translate('amazonUser-service.error_adv_campaign', [response.error]),
              ),
            );
          } else {
            amazonResponse.next(response);
          }
        });
        return amazonResponse;
      }),
      switchMap((response) => {
        return this.accountApi.getAmazonUser({ code: response.code, organizationId: organizationId });
      }),
    );
  }
}
