// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    DashboardConfig,
    DashboardConfigResponse,
    DashboardType,
    Response,
} from '../models';

export interface CreateDashboardConfigRequest {
    dashboardConfig: DashboardConfig;
}

export interface DeleteDashboardConfigRequest {
    dashboardId: number;
}

export interface ListDashboardConfigsRequest {
    organizationId: number;
    dashboardType: DashboardType;
}

export interface UpdateDashboardConfigRequest {
    dashboardConfig: DashboardConfig;
}

/**
 * no description
 */
export class DashboardConfigApi extends BaseAPI {

    /**
     * Create a dashboardConfig
     */
    createDashboardConfig({ dashboardConfig }: CreateDashboardConfigRequest): Observable<DashboardConfigResponse>
    createDashboardConfig({ dashboardConfig }: CreateDashboardConfigRequest, opts?: OperationOpts): Observable<AjaxResponse<DashboardConfigResponse>>
    createDashboardConfig({ dashboardConfig }: CreateDashboardConfigRequest, opts?: OperationOpts): Observable<DashboardConfigResponse | AjaxResponse<DashboardConfigResponse>> {
        throwIfNullOrUndefined(dashboardConfig, 'dashboardConfig', 'createDashboardConfig');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<DashboardConfigResponse>({
            url: '/dashboardConfig',
            method: 'POST',
            headers,
            body: dashboardConfig,
        }, opts?.responseOpts);
    };

    /**
     * Delete a dashboardConfig
     */
    deleteDashboardConfig({ dashboardId }: DeleteDashboardConfigRequest): Observable<Response>
    deleteDashboardConfig({ dashboardId }: DeleteDashboardConfigRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    deleteDashboardConfig({ dashboardId }: DeleteDashboardConfigRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(dashboardId, 'dashboardId', 'deleteDashboardConfig');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'dashboardId': dashboardId,
        };

        return this.request<Response>({
            url: '/dashboardConfig',
            method: 'DELETE',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * List dashboard configs of an organization
     * List dashboard config of a given organization
     */
    listDashboardConfigs({ organizationId, dashboardType }: ListDashboardConfigsRequest): Observable<Array<DashboardConfig>>
    listDashboardConfigs({ organizationId, dashboardType }: ListDashboardConfigsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<DashboardConfig>>>
    listDashboardConfigs({ organizationId, dashboardType }: ListDashboardConfigsRequest, opts?: OperationOpts): Observable<Array<DashboardConfig> | AjaxResponse<Array<DashboardConfig>>> {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'listDashboardConfigs');
        throwIfNullOrUndefined(dashboardType, 'dashboardType', 'listDashboardConfigs');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'organizationId': organizationId,
            'dashboardType': dashboardType,
        };

        return this.request<Array<DashboardConfig>>({
            url: '/dashboardConfig',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Update a dashboardConfig
     */
    updateDashboardConfig({ dashboardConfig }: UpdateDashboardConfigRequest): Observable<DashboardConfigResponse>
    updateDashboardConfig({ dashboardConfig }: UpdateDashboardConfigRequest, opts?: OperationOpts): Observable<AjaxResponse<DashboardConfigResponse>>
    updateDashboardConfig({ dashboardConfig }: UpdateDashboardConfigRequest, opts?: OperationOpts): Observable<DashboardConfigResponse | AjaxResponse<DashboardConfigResponse>> {
        throwIfNullOrUndefined(dashboardConfig, 'dashboardConfig', 'updateDashboardConfig');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<DashboardConfigResponse>({
            url: '/dashboardConfig',
            method: 'PUT',
            headers,
            body: dashboardConfig,
        }, opts?.responseOpts);
    };

}
