import { Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { IButtonComponent } from '../../components/ibutton/ibutton.component';
import { IInputComponent } from '../../components/iinput/iinput.component';
import { TranslocoDirective } from '@jsverse/transloco';
import { TranslocoService } from '@jsverse/transloco';
export interface RequestPasswordResetForm {
  email: FormControl<string | null>;
}

export interface RequestPasswordResetFormValue {
  email: string;
}

type RequestPasswordResetFormError = Partial<Record<keyof RequestPasswordResetForm, string | null>>;

@Component({
  selector: 'lib-request-password-reset-form',
  standalone: true,
  imports: [ReactiveFormsModule, IButtonComponent, IInputComponent, TranslocoDirective],

  templateUrl: './request-password-reset-form.component.html',
})
export class RequestPasswordResetFormComponent implements OnInit {
  constructor(private translocoService: TranslocoService) {}
  readonly form = new FormGroup<RequestPasswordResetForm>({
    email: new FormControl(null, {
      nonNullable: true,
      validators: [Validators.required, Validators.email, Validators.pattern(/.*@.+\..+/)],
    }),
  });

  formErrors = signal<RequestPasswordResetFormError>({});

  @Input() isLoading = false;
  @Output() formSubmit = new EventEmitter<RequestPasswordResetFormValue>();

  ngOnInit(): void {
    this.form.valueChanges.pipe(debounceTime(200)).subscribe(() => {
      if (this.form.invalid) {
        this.formErrors.set({
          email:
            this.form.controls.email.errors?.['email'] || this.form.controls.email.errors?.['pattern']
              ? this.translocoService.translate('profile-settings.invalid_email')
              : null,
        });
      }
    });
  }

  onSubmit() {
    this.formSubmit.emit(this.form.value as RequestPasswordResetFormValue);
  }
}
