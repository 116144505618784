<ng-container *transloco="let t">
  <div class="flex w-full flex-row justify-between">
    @if (product | asinInelligilibleForInventoryRule) {
      <span
        class="warning"
        [class]="ICON_WARNING_TRIANGLE"
        matTooltip="{{ t('inventory-table.pause_threshold_tooltip') }}"
      ></span>
    }
    <IButton
      class="flex-1"
      [label]="label"
      color="gray"
      variant="ghost"
      [trailing]="true"
      [tooltipValue]="t('inventory-table.treshold_setup_tooltip', [product.asin])"
      (onClick)="setupRuleOnAsin(product); $event.stopPropagation()"
      [icon]="ICON_EDIT_O"
      [iconOnHover]="false"
      [disabled]="isReadOnly"
    />
  </div>
</ng-container>
