import { Component, inject } from "@angular/core";
import { CampaignType } from "@front/m19-api-client";
import { SegmentEx, StrategyEx } from "@front/m19-models";
import { MODAL_DATA } from "@front/m19-ui";
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-segment-detail-strategies-modal",
  templateUrl: "./segment-detail-strategies-modal.component.html",
})
export class SegmentDetailStrategiesModalComponent {
  protected readonly data = inject<SegmentDetailData>(MODAL_DATA);
  readonly CampaignType = CampaignType;
}

export interface SegmentDetailData {
  segment: SegmentEx | undefined;
  linkedStrategies: StrategyEx[];
}
