<ng-container *transloco="let t">
  <div class="flex flex-col">
    @if (strategy) {
      <div class="inline-flex items-center justify-start">
        @if (withCampaignState && strategy.state) {
          <span class="icon-prepend mr-2">
            <fa-icon
              [icon]="strategy.state === 'ENABLED' ? faPlayCircle : faPauseCircle"
              [ngClass]="strategy.state === 'ENABLED' ? 'activated' : 'desactivated'"
              [matTooltip]="strategy.state === 'ENABLED' ? t('common.active_startegy') : t('common.paused_strategy')"
              size="lg"
            ></fa-icon>
          </span>
        }
        @if (withCampaignType) {
          <app-campaign-type-badge [campaignType]="strategy.campaignType" class="mr-1"></app-campaign-type-badge>
        }
        @if (disableLink) {
          {{ strategy.name }}
        } @else {
          <a
            [routerLink]="redirectToStrategy()"
            queryParamsHandling="merge"
            target="_blank"
            class="link truncate"
            matTooltip="{{ strategy.name }}"
            [ngStyle]="{ color: color ?? '' }"
          >
            {{ strategy.name }}
          </a>
        }
      </div>
    } @else {
      {{ placeholder }}
    }
  </div>
</ng-container>
