@if (getStrategies()) {
  <div class="card-perso collapse-card border-shadow left-child right-child card">
    <div class="card-perso-header collapse-card-header top-child card-header">
      <h5>{{ campaignType }} Strategies</h5>
    </div>
    <div class="card-perso-body card-body">
      @for (_strategy of getStrategies(); track _strategy) {
        <div class="mb-2">
          <app-strategy-link [strategyId]="_strategy.strategyId" [withCampaignState]="true"></app-strategy-link>
        </div>
      }
    </div>
  </div>
}
