import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Injectable({
  providedIn: "root",
})
export class ObfuscationService {
  private static readonly obfuscationLocalStorageKey = "obfuscation_mode";

  // obfuscation mode CSS classes are defined in _custom.scss
  private static readonly obfuscationCssClass = "obfuscation-mode-on";

  public readonly obfuscation: BehaviorSubject<boolean>;

  constructor() {
    const obfuscationFromLocalStorage = localStorage.getItem(ObfuscationService.obfuscationLocalStorageKey) === "true";
    this.obfuscation = new BehaviorSubject<boolean>(obfuscationFromLocalStorage);
    document.body.classList.toggle(ObfuscationService.obfuscationCssClass, this.obfuscation.value);
  }

  toggleObfuscationMode(newValue: boolean): void {
    this.obfuscation.next(newValue);
    document.body.classList.toggle(ObfuscationService.obfuscationCssClass, this.obfuscation.value);
    localStorage.setItem(ObfuscationService.obfuscationLocalStorageKey, this.obfuscation.value.toString());
  }
}
