<ng-container *transloco="let t">
  @if (strategyWarnings.length > 0) {
    <div>
      @for (strategyWarning of strategyWarnings; track strategyWarning) {
        <div class="alert alert-warning" role="alert">
          <fa-icon [icon]="faWarning" class="text-warning"></fa-icon>
          <span class="ml-2">{{ strategyWarning }}</span>
        </div>
      }
    </div>
  }

  <app-strategy-page
    [campaignType]="CampaignType.SP"
    [strategyGroup]="strategyGroup"
    [strategy$]="strategy$"
    [withStatsSection]="true"
    [withActivitiesSection]="true"
    [withTacticSection]="withTacticSection"
    [withAutoCampaignOption]="withAutoCampaignOption"
    [withProductTargetingOption]="withProductTargetingOption"
  >
    <div withSpAsins class="card-perso-body bottom-child card-body">
      <div class="mt-2 flex items-center justify-between">
        <h5 class="pointer collapse-card-header flex items-center" (click)="toggleSectionVisibility('products')">
          <app-dropdown-toggle-icon [expand]="sectionVisibility.products"></app-dropdown-toggle-icon>
          <span class="ml-3"
            >{{ t("sd-strategy-creation.advertised_products") }} ({{ (strategy$ | async)?.asins?.length }})</span
          >
        </h5>
      </div>
      <div [hidden]="!sectionVisibility.products" class="max-h-[35rem]">
        <app-strategy-asins
          class="h-full"
          [asins]="(strategy$ | async).asins"
          [accountId]="strategyGroup.accountId"
          [marketplace]="strategyGroup.marketplace"
          [selectable]="!isReadOnly"
          [addProductsBtn]="true"
          (addProducts)="showAddAsinModal()"
          [deletable]="!isReadOnly"
          [movable]="!isReadOnly && asinsCanBeMovedToOtherStrat"
          (move)="moveToOtherStrategy($event)"
          [allowGroupByParentAsin]="false"
          [asinEligibility]="asinEligibility"
          [csvExport]="true"
          [withDisplayMode]="true"
          csvExportFileName="{{ (strategy$ | async).strategyName }}"
          (onDelete)="removeProducts($event)"
        ></app-strategy-asins>
        <!-- <div class="mt-1">
        <IButton
          label="Add Products"
          color="white"
          (onClick)="showAddAsinModal()"
          [disabled]="isReadOnly"
          testid="btn-add-products"
        />
      </div> -->
      </div>
    </div>
    <div withTargetingStats>
      <div class="card-perso collapse-card left-child right-child card mb-0">
        <div
          class="card-perso-header collapse-card-header top-child card-header"
          (click)="toggleSectionVisibility('targetingStats')"
        >
          <span class="icon-prepend float-left">
            <app-dropdown-toggle-icon [expand]="sectionVisibility.targetingStats"></app-dropdown-toggle-icon>
          </span>
          <h5 class="card-name mb-0">{{ t("sb-page.targeting_stats") }}</h5>
        </div>
        @if (sectionVisibility.targetingStats) {
          <div class="card-perso-body bottom-child card-body">
            <div class="card-body left-child right-child">
              <app-targeting-asin-stats
                [isReadOnly]="isReadOnly"
                [strategy]="strategy"
                [withTOSROSwitch]="withTOSROSwitch"
              ></app-targeting-asin-stats>
            </div>
          </div>
        }
      </div>
    </div>
  </app-strategy-page>

  <ng-template #productAdd>
    <div class="modal-header">
      <h5 class="modal-title">{{ t("sp-substrategy.add_products") }}</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="addAsinModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body h-[50vh]">
      <app-asins-selection
        [asins]="(strategy$ | async).asins"
        [accountId]="strategyGroup.accountId"
        [marketplace]="strategyGroup.marketplace"
        [asinEligibility]="asinEligibility"
        [selectionModes]="productSelectionModes"
        [customAsinList]="strategyType === StrategyType.PRODUCT ? undefined : strategyGroup?.asins"
        (onAdd)="addProducts($event)"
        (onDelete)="removeProducts($event)"
        [csvExport]="false"
      />
    </div>
    <div class="modal-footer">
      <IButton label="{{ t('common.close') }}" (onClick)="addAsinModalRef.hide()" testid="btn-close" />
    </div>
  </ng-template>
</ng-container>
