import { formatDate } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AuthService,
  OrganizationAccountGroupService,
  OrganizationUsersService,
  UserSelectionService,
} from "@front/m19-services";
import { AgGridAngular } from "@ag-grid-community/angular";
import { combineLatest, tap } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ColDef, GridOptions, ValueFormatterParams, ValueGetterParams } from "@ag-grid-community/core";
import { Marketplaces, OrganizationAccountGroups } from "@front/m19-models";
import { getMainMenuItems } from "@front/m19-grid-config";
import {
  AccountMarketplace,
  EntityIdType,
  EntityType,
  History,
  HistoryActionEnum,
  HistoryApi,
  Marketplace,
} from "@front/m19-api-client";
import { TranslocoService } from "@jsverse/transloco";

@UntilDestroy()
@Component({
  selector: "app-user-activities-grid",
  templateUrl: "./user-activities-grid.component.html",
  styleUrls: ["./user-activities-grid.component.scss"],
  standalone: true,
  imports: [AgGridAngular],
})
export class UserActivitiesGridComponent implements OnInit {
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  private colDefs: ColDef[] = [
    {
      field: "timestamp",
      filter: "agDateColumnFilter",
      filterValueGetter: (params: ValueGetterParams<History>) => {
        const cellDate: Date = new Date(params.data!.timestamp);
        return new Date(cellDate.toDateString());
      },
      valueFormatter: (params: ValueFormatterParams<History>) => {
        return this.formatUserDate(params.value) ?? "";
      },
      suppressHeaderMenuButton: false,
    },
    {
      field: "userName",
      headerName: this.translocoService.translate("user-activities-grid.triggered_by"),
    },
    {
      headerName: this.translocoService.translate("user-activities-grid.action"),
      valueGetter: (params: ValueGetterParams<History>) => {
        const data: History | undefined = params.node?.data;
        return this.formatAction(data?.action, data?.entityType, data?.newValue);
      },
    },
    {
      headerName: this.translocoService.translate("activity.user"),
      filter: "agTextColumnFilter",

      valueGetter: (params: ValueGetterParams<History>) => {
        return this.formatImpactedUser(params.data!);
      },
    },

    {
      headerName: this.translocoService.translate("user-activities-grid.scope"),
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams<History>) => {
        return this.formatScope(params.data!);
      },
      valueFormatter: (params: ValueFormatterParams<History>) => {
        const marketplace: string | undefined = this.extractMarketplaceIfExists(params.data!);
        if (!marketplace) return this.formatScope(params.data!) ?? "";
        const marketplaceDesc = Marketplaces[marketplace as Marketplace];
        return `${marketplaceDesc.flag} (${marketplace}) ${params.value}`;
      },
    },
  ];

  private defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
    flex: 1,
    suppressMovable: true,
  };

  gridOptions: GridOptions = {
    columnDefs: this.colDefs,
    defaultColDef: this.defaultColDef,
    tooltipShowDelay: 100,
    sideBar: false,
    getMainMenuItems: (params) => getMainMenuItems(params, null),
  };

  private locale?: string;
  private organizationNameMap = new Map<number, string>();
  private profileIdNameMap = new Map<number, string>();
  private profileIdMarketplaceNameMap = new Map<number, string>();

  constructor(
    private historyService: HistoryApi,
    private accountGroupService: OrganizationAccountGroupService,
    private authService: AuthService,
    private organizationUsersService: OrganizationUsersService,
    private userSelectionService: UserSelectionService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((x) => (this.locale = x.locale));
    combineLatest(this.userSelectionService.dateRange$, this.organizationUsersService.listOrganizations())
      .pipe(
        switchMap(([dateRange]) => {
          const yesterday = new Date();
          // Because of stats, maxDatePicker is upperbound to yesterday, but for history we need today
          yesterday.setDate(yesterday.getDate() - 1);
          if (dateRange[1] == yesterday.toISOString().substring(0, 10)) {
            yesterday.setDate(yesterday.getDate() + 1);
            dateRange[1] = yesterday.toISOString().substring(0, 10);
          }
          return this.historyService.getUserManagementHistory({ minDate: dateRange[0], maxDate: dateRange[1] });
        }),
      )
      .subscribe((history: History[]) => this.agGrid?.api.setRowData(history));

    this.accountGroupService.allOrganizationAccountGroups$
      .pipe(
        untilDestroyed(this),
        tap((o: OrganizationAccountGroups[] | undefined) => {
          o?.forEach((o: OrganizationAccountGroups) => this.organizationNameMap.set(o.id, o.organizationName));
        }),
      )
      .subscribe((organizations) => {
        const accountMarketplaces: AccountMarketplace[] | undefined = organizations?.flatMap((o) =>
          o.accountGroups.flatMap((group) => group.resources),
        );
        for (const accountMarketplace of accountMarketplaces ?? []) {
          if (!this.profileIdNameMap.has(accountMarketplace.profileId!)) {
            this.profileIdNameMap.set(accountMarketplace.profileId!, accountMarketplace.accountName);
            this.profileIdMarketplaceNameMap.set(accountMarketplace.profileId!, accountMarketplace.marketplace);
          }
        }
      });
  }

  private formatUserDate(timestamp: string): string | undefined {
    if (timestamp) {
      return formatDate(timestamp + "Z", "short", this.locale!);
    }
    return undefined;
  }

  private formatAction(
    action: HistoryActionEnum | undefined,
    entityType: EntityType | undefined,
    newValue?: string,
  ): string | undefined {
    let readonly = false;
    if (newValue) {
      const json = JSON.parse(newValue);
      readonly = json["readOnly"];
    }
    switch (action) {
      case HistoryActionEnum.create:
        return (
          "Added " +
          (entityType === EntityType.AuthorizationAccess
            ? "Authorized " + (readonly ? "Read" : "Read/Write") + " Access"
            : "Organization Admin")
        );
      case HistoryActionEnum.delete:
        if (entityType === EntityType.AuthorizationAccess) return "Revoked Authorized Access";
        else if (entityType === EntityType.OrganizationAdmin) return "Revoked Admin Access";
    }
    return undefined;
  }

  private formatImpactedUser(history: History): string {
    switch (history.action) {
      case HistoryActionEnum.create:
        return JSON.parse(history.newValue!)["userName"] + " " + `(${JSON.parse(history.newValue!)["email"]})`;
      case HistoryActionEnum.delete:
        return JSON.parse(history.oldValue!)["userName"] + " " + `(${JSON.parse(history.oldValue!)["email"]})`;
    }
    return "";
  }

  private formatScope(history: History): string | undefined {
    if (history.entityType === EntityType.AuthorizationAccess) {
      if (history.primaryType === EntityIdType.profileId) return this.profileIdNameMap.get(history.primaryId!);
      return undefined;
    } else if (history.entityType === EntityType.OrganizationAdmin)
      return this.organizationNameMap.get(history.primaryId!);
    return undefined;
  }

  private extractMarketplaceIfExists(history: History): string | undefined {
    if (history.entityType === EntityType.AuthorizationAccess) {
      if (history.primaryType === EntityIdType.profileId)
        return this.profileIdMarketplaceNameMap.get(history.primaryId!);
    }
    return undefined;
  }
}
