<ng-container *transloco="let t">
  <div class="modal-header bg-primary">
    <h4 class="modal-title pull-left">{{ t("strategy-bulk-upload-report-modal.strategy_upload_summary") }}</h4>
    <a>
      <h4 class="modal-title pull-right" (click)="bsModalRef.hide()">&times;</h4>
    </a>
  </div>
  <div class="modal-body max-h-[75vh] overflow-y-scroll">
    <!-- Strategy creations -->
    <div class="alert alert-success overflow-auto">
      <h5>
        {{
          t("strategy-bulk-upload-report-modal.strategies_created", { count: strategyBulkOperations.creations.length })
        }}
      </h5>
      @if (strategyBulkOperations.creations.length > 0) {
        <table
          mat-table
          [dataSource]="creationsDataSource"
          class="table-striped-two table-hover mt-4 table"
          multiTemplateDataRows
        >
          <ng-container matColumnDef="lineIndex">
            <td *matCellDef="let creation" class="lineIndex py-0">#{{ creation.lineIndex }}</td>
          </ng-container>
          <ng-container matColumnDef="row">
            <td *matCellDef="let creation" colspan="90%" class="csvRow coding-font py-0">
              {{ creation.csvRow }}
            </td>
          </ng-container>
          <ng-container matColumnDef="select">
            <th *matHeaderCellDef></th>
            <td *matCellDef="let creation">
              <span
                class="select-button-box"
                matTooltip="{{ t('strategy-bulk-upload-report-modal.select_this_strategy_creation_operation') }}"
              >
                <fa-icon
                  [icon]="isStrategyCreationSelected(creation) ? faCheckedSquare : faSquare"
                  size="lg"
                  (click)="toggleStrategyCreation(creation)"
                ></fa-icon>
              </span>
            </td>
          </ng-container>
          @for (f of genericBulkCsvFields; track f) {
            <ng-container [matColumnDef]="f.field">
              <th *matHeaderCellDef>{{ f.title }}</th>
              <td *matCellDef="let creation">
                {{ f.csvField.value(creation.strategy) }}
              </td>
            </ng-container>
          }
          <ng-container matColumnDef="asins">
            <th *matHeaderCellDef>{{ t("common.asins") }}</th>
            <td *matCellDef="let creation" class="flex flex-col text-left">
              @for (asin of creation.strategy.asins.slice(0, 3); track asin) {
                <div>
                  <a [href]="asin.asin | amazonAsinUrlPipe: marketplace" target="_blank">
                    {{ asin.asin }}
                  </a>
                </div>
              }
              @if (creation.strategy.asins.length > 3) {
                <div>{{ t("creative-display.and_creative_more", [strategyBulkOperations.creations.length]) }}</div>
              }
              <div>
                <a href="#" (click)="showProductDetails(creation.strategy.asins, $event)">{{
                  t("product-group-bulk-upload-report-modal.more_details")
                }}</a>
              </div>
            </td>
          </ng-container>
          <tr mat-row *matRowDef="let row; columns: ['lineIndex', 'row']" class="lineIndexRow"></tr>
          <tr mat-header-row *matHeaderRowDef="creationColumns" class="header"></tr>
          <tr mat-row *matRowDef="let row; columns: creationColumns"></tr>
        </table>
      }
    </div>
    <!-- Strategy updates -->
    <div class="alert alert-success overflow-auto">
      <h5>
        {{ t("strategy-bulk-upload-report-modal.strategy_update", { count: strategyBulkOperations.updates.length }) }}
        @if (strategyBulkOperations.updates.length > 0) {
          <span>. {{ t("strategy-bulk-upload-report-modal.updated_fields_are_highlighted") }}.</span>
        }
      </h5>
      @if (strategyBulkOperations.updates.length > 0) {
        <table
          mat-table
          [dataSource]="updateDataSource"
          class="table-striped-two table-hover mt-4 table"
          multiTemplateDataRows
        >
          <ng-container matColumnDef="lineIndex">
            <td *matCellDef="let update" class="lineIndex py-0">#{{ update.lineIndex }}</td>
          </ng-container>
          <ng-container matColumnDef="row">
            <td *matCellDef="let update" colspan="90%" class="csvRow coding-font py-0">
              {{ update.csvRow }}
            </td>
          </ng-container>
          <ng-container matColumnDef="select">
            <th *matHeaderCellDef></th>
            <td *matCellDef="let update">
              <span
                class="select-button-box"
                matTooltip="{{ t('strategy-bulk-upload-report-modal.select_this_strategy_update_operation') }}"
              >
                <fa-icon
                  [icon]="isStrategyUpdateSelected(update) ? faCheckedSquare : faSquare"
                  size="lg"
                  (click)="toggleStrategyUpdate(update)"
                ></fa-icon>
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="strategyId">
            <th *matHeaderCellDef>{{ t("strategy-bulk-upload-report-modal.strategy_id") }}</th>
            <td *matCellDef="let update">
              {{ update.strategyToUpdate.strategyId }}
            </td>
          </ng-container>
          @for (f of genericBulkCsvFields; track f) {
            <ng-container [matColumnDef]="f.field">
              <th *matHeaderCellDef>{{ f.title }}</th>
              <td *matCellDef="let update" [ngClass]="{ selected: update.updatedFields[f.field] !== undefined }">
                @if (update.updatedFields[f.field] !== undefined) {
                  <span
                    ><del>{{ f.csvField.value(update.strategyToUpdate) }}</del></span
                  >
                }
                {{
                  update.updatedFields[f.field] !== undefined
                    ? f.csvField.value(update.updatedFields)
                    : f.csvField.value(update.strategyToUpdate)
                }}
              </td>
            </ng-container>
          }
          <ng-container matColumnDef="asins">
            <th *matHeaderCellDef>{{ t("common.asins") }}</th>
            <td
              *matCellDef="let update"
              class="flex flex-col text-left"
              [ngClass]="{
                selected: update.updatedFields.asinsToAdd.length > 0 || update.updatedFields.asinsToDelete.length > 0,
              }"
            >
              @if (update.updatedFields.asinsToAdd.length === 0 && update.updatedFields.asinsToDelete.length === 0) {
                <span>-</span>
              }
              @if (update.updatedFields.asinsToAdd.length > 0 || update.updatedFields.asinsToDelete.length > 0) {
                <div>
                  <div>
                    {{
                      t("common.n_new_asins", {
                        count: update.updatedFields.asinsToAdd.length,
                      })
                    }}
                  </div>
                  <div>
                    {{
                      t("common.n_deleted_asins", {
                        count: update.updatedFields.asinsToDelete.length,
                      })
                    }}
                  </div>
                </div>
              }
              <div>
                <a href="#" (click)="showProductDetailsForUpdate(update, $event)">{{
                  t("product-group-bulk-upload-report-modal.more_details")
                }}</a>
              </div>
            </td>
          </ng-container>
          <tr mat-row *matRowDef="let row; columns: ['lineIndex', 'row']" class="lineIndexRow"></tr>
          <tr mat-header-row *matHeaderRowDef="updateColumns" class="header"></tr>
          <tr mat-row *matRowDef="let row; columns: updateColumns"></tr>
        </table>
      }
    </div>
    @if (strategyBulkOperations.errors.length > 0) {
      <div class="alert alert-danger">
        <h5>
          {{ t("strategy-bulk-upload-report-modal.line_discarded", { count: strategyBulkOperations.errors.length }) }}
        </h5>
        @for (error of strategyBulkOperations.errors; track error) {
          <div>
            <div class="csvRowError coding-font">#{{ error.lineIndex }} {{ error.csvRow }}</div>
            <div>
              <ul>
                @for (errorMsg of error.errors; track errorMsg) {
                  <li>{{ errorMsg }}</li>
                }
              </ul>
            </div>
          </div>
        }
      </div>
    }
  </div>
  <div class="modal-footer">
    <div class="flex justify-end">
      <IButton label="{{ t('common.back') }}" variant="soft" color="gray" (onClick)="cancel()" />
      <IButton
        class="ml-2"
        label="{{ t('common.continue') }}"
        tooltipValue="Upload selected data"
        [disabled]="selectedStrategyCreation.size === 0 && selectedStrategyUpdate.size === 0"
        (onClick)="uploadData()"
      />
    </div>
  </div>
</ng-container>
