<div class="modal-header bg-primary" *transloco="let t">
  <h4 class="modal-title pull-left">{{ t("add-to-seeds-modal.add_to_seeds") }}</h4>
</div>
<div class="modal-body" *transloco="let t">
  <div class="form-group row items-center">
    <label class="col-sm-3 col-form-label">{{ t(seedTypeKeys[seedType]) }}</label>
    @if (seedType === SeedType.Product) {
      <div class="col-sm-9">
        <app-product-view [asin]="targetingItemValue" [marketplace]="marketplace"></app-product-view>
      </div>
    }
    @if (seedType === SeedType.Keyword) {
      <div class="col-sm-9 col-form-label">
        <div class="form-control">{{ targetingItemValue }}</div>
      </div>
    }
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label">{{ t("add-to-seeds-modal.seed_blacklist") }}</label>
    <div [ngClass]="seedType === SeedType.Keyword ? 'col-sm-6' : 'col-sm-9'">
      <ISelect [options]="seedsOptions" [selected]="asinSeedType()" (selectedChange)="setAsinSeedType($event)" />
    </div>
    @if (seedType === SeedType.Keyword) {
      <div class="col-sm-3 flex justify-end">
        <app-switch-button
          [class.disabled]="asinSeedType().value === AsinSeedTypeEnum.WHITELIST"
          [options]="[MatchType.phrase, MatchType.exact]"
          [buttonTexts]="['common.phrase', 'common.exact']"
          [buttonTooltips]="['', '']"
          [selected]="matchType"
          (buttonClicked)="setMatchType($event)"
        />
      </div>
    }
  </div>
  <div class="form-group row items-center">
    <label class="col-sm-3 col-form-label">{{ t("common.for_asins") }}</label>
    <div class="col-sm-9">
      <app-product-view [asin]="asin" [marketplace]="marketplace"></app-product-view>
    </div>
  </div>
</div>
<div class="modal-footer" *transloco="let t">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">
    {{ t("common.cancel") }}
  </button>
  <button type="submit" class="btn btn-primary" (click)="save()">{{ t("common.save") }}</button>
</div>
