<div class="container mx-auto flex h-full flex-col border-x" *transloco="let t">
  <div class="flex items-center justify-between border-b bg-white p-4">
    <div class="flex items-center">
      <h4 class="m-0 text-lg font-medium">
        <span class="text-gray-900">{{ formTitle() }}</span>
      </h4>
      @if (am && am.accountGroupName) {
        <IBadge class="sensitive-data ml-2" color="gray" [label]="am.accountGroupName" />
      }
      @if (am && am.marketplace) {
        <IBadge class="sensitive-data ml-2" color="gray" [label]="t('common.marketplace') + ' ' + am.marketplace" />
      }
    </div>
    <div class="flex items-center">
      <IButton
        tooltipValue="{{ t('sb-strategy-form.check_creative_preview') }}"
        class="mr-2 xl:hidden"
        [label]="(previewHidden ? t('sb-strategy-form.show') : t('sb-strategy-form.hide')) + ' Preview'"
        variant="soft"
        [color]="previewHidden ? 'main' : 'gray'"
        [icon]="previewHidden ? 'icon-[mdi--image-size-select-actual]' : ICON_CLOSE"
        (onClick)="previewHidden = !previewHidden"
      />
      @if (_formMode() === FormMode.NEW_STRATEGY) {
        @if (tacosStrategy) {
          <IButton
            label="{{ t('tacos-strategy.back_to_tacos_strategy') }}"
            color="white"
            [icon]="ICON_ARROW_LEFT"
            (onClick)="onCancel.emit()"
          />
        } @else {
          <IButton
            class="ml-2"
            to="/advertising/sponsored-brands"
            queryParamsHandling="merge"
            label="{{ t('sb-strategy-form.back_to_sb_strategies') }}"
            color="white"
            [icon]="ICON_ARROW_LEFT"
          />
        }
      } @else {
        <IButton
          label="{{ t('common.close') }}"
          color="gray"
          variant="soft"
          [icon]="ICON_CLOSE"
          (onClick)="bsModalRef.hide()"
        />
      }
    </div>
  </div>
  <div class="flex min-h-0 w-full max-w-full flex-auto">
    <div class="min-h-full shrink-0 overflow-y-auto border-r bg-white p-6">
      <ol class="m-0 flex list-none flex-col space-y-8 overflow-hidden p-0">
        @for (s of steps(); track s; let i = $index) {
          <li
            class="relative flex cursor-pointer items-center rounded-md px-4 py-2 duration-150 after:absolute after:left-[1.625rem] after:top-full after:inline-block after:h-8 after:w-0.5 after:bg-gray-200 after:content-['']"
            [ngClass]="{
              'after:!bg-green-500': getStepStatus(s) === StepStatus.VALID || getStepStatus(s) === StepStatus.CURRENT,
              'cursor-pointer hover:bg-gray-200': getStepStatus(s) !== StepStatus.PENDING,
            }"
            (click)="handleStepperClick(s)"
          >
            <div
              class="mr-3 flex size-6 items-center justify-center rounded-full border-2"
              [ngClass]="{
                'border-green-500 !bg-green-500': getStepStatus(s) === StepStatus.VALID,
                '!border-gray-300': getStepStatus(s) === StepStatus.PENDING,
                '!border-green-500': getStepStatus(s) === StepStatus.CURRENT,
              }"
              attr.data-testid="step-icon-{{ i }}"
            >
              @if (getStepStatus(s) === StepStatus.VALID) {
                <span class="icon-[mdi--check] text-white"></span>
              }
            </div>
            <div class="flex flex-col">
              <span class="select-none text-sm text-gray-500">{{ t("sb-strategy-form.step") }} {{ i + 1 }}</span>
              <span
                class="select-none text-gray-800"
                [ngClass]="{
                  '!text-gray-300': getStepStatus(s) === StepStatus.PENDING,
                }"
                >{{ s }}</span
              >
            </div>
          </li>
        }
      </ol>
    </div>
    <div
      class="flex min-w-[450px] flex-1 flex-col overflow-y-auto bg-white"
      [ngClass]="{ '!hidden !p-0': !previewHidden }"
    >
      @switch (formStep()) {
        @case (SbFormStep.INFO) {
          <app-sb-form-info
            class="h-full"
            [am]="am!"
            (onFormSubmit)="onFormChange($event)"
            [sbInfo]="formInfo!"
            [formCompletionStarted]="!!formAdFormat"
            [tacosStrategy]="tacosStrategy"
          />
        }
        @case (SbFormStep.ALGO) {
          <app-sb-form-algo
            class="h-full"
            [am]="am!"
            (onFormSubmit)="onFormChange($event)"
            (onFormPrevious)="handlePreviousStep()"
            [sbAlgo]="formAlgo!"
          />
        }
        @case (SbFormStep.AD_FORMAT) {
          <app-sb-form-ad-format
            class="h-full"
            [formMode]="_formMode()"
            [tacosStrategy]="tacosStrategy"
            [selectedBrand]="formInfo?.brand!"
            [stores]="brandAssets?.storePages"
            (onFormSubmit)="onFormChange($event)"
            [sbAdFormat]="formAdFormat!"
            [marketplace]="am!.marketplace"
            (onFormPrevious)="handlePreviousStep()"
          />
        }
        @case (SbFormStep.CREATIVE) {
          <app-sb-form-creative
            class="h-full"
            [am]="am!"
            (onFormSubmit)="onFormChange($event)"
            (onFormPrevious)="handlePreviousStep()"
            [creativeType]="sbCreativeType"
            [sbCrea]="formCrea!"
            [editMode]="_formMode() === FormMode.EDIT_CREATIVE"
            [brandEntityId]="formInfo?.brand?.brandEntityId"
            [brandAssets]="brandAssets"
          />
        }
        @case (SbFormStep.ASINS) {
          <app-sb-form-asins
            [am]="am!"
            [store]="formAdFormat?.store ?? undefined"
            [tacosStrategy]="tacosStrategy"
            [availableStores]="brandAssets?.storePages"
            (onFormPrevious)="handlePreviousStep()"
            class="h-full"
            (onFormSubmit)="onFormChange($event)"
            [sbAsins]="formAsins!"
            [creativeType]="sbCreativeType"
          />
        }
        @case (SbFormStep.REVIEW) {
          <div class="flex-auto overflow-y-auto">
            @if (_formMode() === FormMode.NEW_STRATEGY && !tacosStrategy) {
              <app-sb-form-info [reviewMode]="true" [am]="am!" [sbInfo]="formInfo!" />
              <app-sb-form-algo [reviewMode]="true" [am]="am!" [sbAlgo]="formAlgo!" />
            }
            @if (_formMode() !== FormMode.EDIT_CREATIVE) {
              <app-sb-form-ad-format
                [stores]="brandAssets?.storePages ?? undefined"
                [marketplace]="am!.marketplace"
                [reviewMode]="true"
                [sbAdFormat]="formAdFormat!"
              />
            }
            <app-sb-form-creative [reviewMode]="true" [am]="am!" [sbCrea]="formCrea!" [creativeType]="sbCreativeType" />
            <app-sb-form-asins [reviewMode]="true" [am]="am!" [sbAsins]="formAsins!" />
          </div>

          <div class="flex justify-end border-t border-gray-300 bg-white p-4">
            <div class="flex gap-x-2">
              <IButton
                label="{{ t('common.previous') }}"
                variant="soft"
                color="gray"
                icon="icon-[material-symbols--chevron-left-rounded]"
                (onClick)="handlePreviousStep()"
              />
              <IButton
                [label]="loadingStatus() ?? submitLabel()"
                [loading]="loadingStatus() !== null"
                (onClick)="handleSubmit()"
                testid="submit-sb-form-button"
              />
            </div>
          </div>
        }
      }
    </div>
    <div
      class="hidden min-h-full w-[35rem] flex-initial overflow-y-auto bg-gray-100 xl:block xl:p-5"
      [ngClass]="{ '!block !flex-1 !p-5': !previewHidden }"
    >
      <span class="text-xl font-medium">{{ t("sb-strategy-form.preview") }}</span>
      <p class="mt-2">{{ t("sb-strategy-form.disclaimer") }}</p>

      @if (currency$ | async; as currency) {
        <app-sb-form-crea-preview
          [formCrea]="formCrea"
          [brandName]="formInfo?.brand?.brandName ?? ''"
          [isVendor]="isVendor"
          [am]="am!"
          [asins]="formAsins?.asins ?? []"
          [creativeType]="sbCreativeType"
          [currency]="currency"
        />
      }
    </div>
  </div>
</div>
