import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { CAMPAIGN_TYPE_COLOR, CAMPAIGN_TYPE_NAME } from "../../insights/overview/overview.component";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
@Component({
  selector: "app-campaign-type-badge",
  standalone: true,
  imports: [CommonModule, MatTooltipModule, TranslocoRootModule],
  template: ` <ng-container *transloco="let t">
    <span
      [matTooltip]="t(CAMPAIGN_TYPE_NAME[campaignType])"
      [ngStyle]="{ 'background-color': CAMPAIGN_TYPE_COLOR[campaignType] }"
      class="badge rounded-full text-white"
      >{{ campaignType }}
    </span>
  </ng-container>`,
  styleUrls: ["./campaign-type-badge.component.scss"],
})
export class CampaignTypeBadgeComponent implements ICellRendererAngularComp {
  @Input() campaignType: string;

  protected readonly CAMPAIGN_TYPE_NAME = CAMPAIGN_TYPE_NAME;
  protected readonly CAMPAIGN_TYPE_COLOR = CAMPAIGN_TYPE_COLOR;

  agInit(params: ICellRendererParams & { campaignType: string }): void {
    this.campaignType = params.campaignType;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }
}
