import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AuthService } from "@front/m19-services";
import { CampaignType } from "@front/m19-api-client";

@UntilDestroy()
@Component({
  selector: "app-sp-manager",
  templateUrl: "./sp-manager.component.html",
})
export class SpManagerComponent {
  readonly CampaignType = CampaignType;
  creationUrl = "/strategies/strategy-group/create-sponsored-products";

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
    this.authService.loggedUser$.subscribe((user) => {
      if ((user?.uiVersion ?? 0) > 0) {
        this.creationUrl = "/advertising/sponsored-product/create";
      }
    });
  }

  createStrategy(): void {
    this.router.navigate([this.creationUrl], {
      queryParamsHandling: "merge",
    });
  }
}
