import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  AccountMarketplace,
  AudienceExpressionType,
  AudienceMatchType,
  AudienceTargeting,
  CampaignType,
  Marketplace,
  Strategy,
  StrategyAsin,
  StrategyStateEnum,
  StrategyTactic,
  TacticType,
} from "@front/m19-api-client";
import { Catalog, SdTargetingType, SegmentConfigType, SegmentEx, SupportedAudienceLookback } from "@front/m19-models";
import {
  AccountSelectionService,
  AsinService,
  ConfigService,
  SdStrategiesService,
  StrategyService,
} from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { Utils } from "@front/m19-utils";
import { TranslocoService } from "@jsverse/transloco";
import {
  AlgoModeConfig,
  AlgoModeConfigValid,
} from "@m19-board/strategies/strategies/algo-mode-selection/algo-mode-selection.component";
import { ICON_ARROW_LEFT } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SegmentService } from "libs/m19-services/src/lib/m19-services/segmentService";
import { ToastrService } from "ngx-toastr";
import { map, of, switchMap } from "rxjs";

export const formControlNames = new Map([
  ["strategyName", "Strategy Name"],
  ["brandName", "Brand Name"],
  ["algoModeConfig", "Algorithm"],
  ["adFormat", "Ad Format"],
  ["landingPage", "Landing Page"],
  ["selectedBrand", "Selected Brand"],
  ["store", "Store"],
  ["headline", "Headline"],
  ["brandLogo", "Brand Logo"],
  ["video", "Video"],
  ["customImage", "Custom Image"],
  ["asins", "ASINs"],
  ["spotlightProduct", "Stores Pages"],
  ["audienceExpressionType", "Event Type"],
  ["segment", "Segment"],
]);

@UntilDestroy()
@Component({
  selector: "app-sd-strategy-creation",
  templateUrl: "./sd-strategy-creation.component.html",
  styleUrls: ["../../../strategies/strategies/strategy-styles.scss"],
})
export class SdStrategyCreationComponent implements OnInit {
  readonly SdTargetingType = SdTargetingType;
  readonly AudienceExpressionType = AudienceExpressionType;
  supportedAudienceMatchTypeKeys = {
    [AudienceMatchType.similarProduct]: this.translocoService.translate("sd-strategy-creation.similar_product"),
    [AudienceMatchType.relatedProduct]: this.translocoService.translate("sd-strategy-creation.related_product"),
    [AudienceMatchType.exactProduct]: this.translocoService.translate("sd-strategy-creation.exact_product"),
  };
  readonly supportedAudienceLookback = SupportedAudienceLookback;
  readonly ICON_ARROW = ICON_ARROW_LEFT;

  @Input() set asins(asins: StrategyAsin[]) {
    if (asins && asins.length) this.sdForm.controls.asins.setValue(asins);
  }

  @Output()
  public strategyCreated = new EventEmitter<Strategy>();
  @Input()
  sdStrategyPageUrl: string = "/strategies/sponsored-display";

  accountId: string;
  accountGroupName: string;
  marketplace: Marketplace;
  invalidFields: string[] = [];
  asinsList: StrategyAsin[] = [];
  formSubmitted: boolean;
  submitOngoing = false;
  asinEligibility: Map<string, { status: boolean; reason: string }> = new Map();
  segments: SegmentEx[] = [];

  readonly sdForm = new FormGroup({
    strategyName: new FormControl<string>(Utils.generateRandomName(), [Validators.required, Validators.maxLength(80)]),
    asins: new FormControl<StrategyAsin[]>([], [this.asinListValidator]),
    algoModeConfig: new FormControl<AlgoModeConfig>(undefined, [Validators.required]),
    targetingType: new FormControl<SdTargetingType>(SdTargetingType.PRODUCT_AI_TARGETING, [Validators.required]),
    segment: new FormControl<SegmentEx>(undefined),
    audienceExpressionType: new FormControl<AudienceExpressionType>(AudienceExpressionType.views),
    audienceMatchType: new FormControl<AudienceMatchType>(AudienceMatchType.exactProduct),
    audienceLookback: new FormControl<number>(30),
  });

  targetingTypeOptions: Option<SdTargetingType>[] = [];
  defaultTargetingTypeOption: Option<SdTargetingType>;

  segmentOptions: Option<SegmentEx>[] = [];
  defaultSegmentOption: Option<SegmentEx>;

  eventTypeOptions: Option<AudienceExpressionType>[] = [
    {
      label: this.translocoService.translate("sd-strategy-creation.views"),
      value: AudienceExpressionType.views,
    },
    {
      label: this.translocoService.translate("metrics.DSP_PURCHASES_title"),
      value: AudienceExpressionType.purchases,
    },
  ];
  defaultEventTypeOption: Option<AudienceExpressionType>;

  matchTypeOptions: Option<AudienceMatchType>[] = [];
  defaultMatchTypeOption: Option<AudienceMatchType>;

  lookbackOptions: Option<number>[] = [];
  defaultLookbackOption: Option<number>;

  constructor(
    public configurationService: ConfigService,
    private segmentService: SegmentService,
    private asinService: AsinService,
    private accountSelection: AccountSelectionService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService,
    private strategyService: StrategyService,
    private sdStrategiesService: SdStrategiesService,
  ) {
    this.sdForm.setValidators((form: typeof this.sdForm) => {
      if (form.controls.targetingType.value === SdTargetingType.REMARKETING) {
        if (
          form.controls.audienceExpressionType.value === AudienceExpressionType.views &&
          form.controls.audienceMatchType.value == AudienceMatchType.relatedProduct
        ) {
          const error = {
            invalidAudience: true,
            invalidAudienceMessage: "You cannot create an audience based on views of related products.",
          };
          form.controls.audienceExpressionType.setErrors(error);
          return error;
        }
        if (
          form.controls.audienceExpressionType.value === AudienceExpressionType.purchases &&
          form.controls.audienceMatchType.value == AudienceMatchType.similarProduct
        ) {
          const error = {
            invalidAudience: true,
            invalidAudienceMessage: "You cannot create an audience based on purchases of similar products.",
          };
          form.controls.audienceExpressionType.setErrors(error);
          return error;
        }
      }
      form.controls.audienceExpressionType.setErrors(null);
      if (form.controls.targetingType.value === SdTargetingType.PRODUCT) {
        if (form.controls.segment.value === null) {
          const error = {
            missingSegment: true,
            missingSegmentMessage: "You must select a segment",
          };
          form.controls.segment.setErrors(error);
          return error;
        }
      }
      form.controls.segment.setErrors(null);
      return null;
    });
  }

  SegmentKeyMap = {};

  ngOnInit(): void {
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((am: AccountMarketplace) => {
        this.accountId = am.accountId;
        this.accountGroupName = am.accountGroupName;
        this.marketplace = am.marketplace;
      });
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am: AccountMarketplace) => this.asinService.getCatalog(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((catalog: Catalog) => {
        // TODO: replace by SD eligibility
        this.asinEligibility = catalog.getSPEligibility();
      });
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) => this.segmentService.getSegments(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((segments) => {
        this.segments = Array.from(segments.values()).filter((s) => s.segmentType === SegmentConfigType.ProductSegment);

        this.segmentOptions = this.segments.map((s) => ({
          label: s.name,
          value: s,
        }));
        this.defaultSegmentOption = this.segmentOptions.find(
          (option) => option.value === this.sdForm.controls.segment.value,
        );
      });

    this.targetingTypeOptions = [
      {
        label: this.translocoService.translate("sd-targeting-add-popup.product_page_ai-powered_targeting"),
        value: SdTargetingType.PRODUCT_AI_TARGETING,
      },
      {
        label: this.translocoService.translate("sd-targeting.product_page"),
        value: SdTargetingType.PRODUCT,
      },
      {
        label: this.translocoService.translate("sd-targeting.remarketing"),
        value: SdTargetingType.REMARKETING,
      },
    ];
    this.defaultTargetingTypeOption = this.targetingTypeOptions.find(
      (option) => option.value === this.sdForm.controls.targetingType.value,
    );

    this.defaultEventTypeOption = this.eventTypeOptions.find(
      (option) => option.value === this.sdForm.controls.audienceExpressionType.value,
    );

    for (const [key, value] of Object.entries(this.supportedAudienceMatchTypeKeys)) {
      this.matchTypeOptions.push({
        label: value,
        value: key as AudienceMatchType,
      });
    }
    this.defaultMatchTypeOption = this.matchTypeOptions.find(
      (option) => option.value === this.sdForm.controls.audienceMatchType.value,
    );
    this.lookbackOptions = this.supportedAudienceLookback.map((lookback) => ({
      label: lookback.toString(),
      value: lookback,
    }));
    this.defaultLookbackOption = this.lookbackOptions.find(
      (option) => option.value === this.sdForm.controls.audienceLookback.value,
    );
  }

  selectTargetingType(targetingType: Option<SdTargetingType>) {
    this.sdForm.controls.targetingType.setValue(targetingType.value);
    this.defaultTargetingTypeOption = targetingType;
  }

  selectSegment(segment: Option<SegmentEx>) {
    this.sdForm.controls.segment.setValue(segment.value);
    this.defaultSegmentOption = segment;
  }

  selectEventType(eventType: Option<AudienceExpressionType>) {
    this.sdForm.controls.audienceExpressionType.setValue(eventType.value);
    this.defaultEventTypeOption = eventType;
  }

  selectMatchType(matchType: Option<AudienceMatchType>) {
    this.sdForm.controls.audienceMatchType.setValue(matchType.value);
    this.defaultMatchTypeOption = matchType;
  }

  selectLookback(lookback: Option<number>) {
    this.sdForm.controls.audienceLookback.setValue(lookback.value);
    this.defaultLookbackOption = lookback;
  }

  submitForm() {
    // prevent duplicated creations on double click
    if (this.submitOngoing) {
      return;
    }
    this.formSubmitted = true;

    if (this.sdForm.invalid) {
      this.invalidFields = this.getInvalidOrMissingFields();
      return;
    }

    this.submitOngoing = true;
    this.formSubmitted = false;
    const limitReached = this.configurationService.isStrategyLimitReached(CampaignType.SD);
    const tactics: StrategyTactic[] =
      this.sdForm.controls.targetingType.value === SdTargetingType.PRODUCT
        ? [
            {
              tacticType: TacticType.LEGACY,
              segmentId: this.sdForm.controls.segment.value.segmentId,
            },
          ]
        : [];
    const audienceTargetings: AudienceTargeting[] =
      this.sdForm.controls.targetingType.value === SdTargetingType.REMARKETING
        ? [
            {
              expressionType: this.sdForm.controls.audienceExpressionType.value,
              matchType: this.sdForm.controls.audienceMatchType.value,
              lookback: this.sdForm.controls.audienceLookback.value,
            },
          ]
        : [];

    const strategy: Strategy = {
      accountId: this.accountId,
      marketplace: this.marketplace,
      state: limitReached ? StrategyStateEnum.PAUSED : StrategyStateEnum.ENABLED,
      campaignType: CampaignType.SD,
      defaultStrategy: false,
      name: this.sdForm.controls.strategyName.value,
      asins: this.sdForm.controls.asins.value,
      ...this.sdForm.controls.algoModeConfig.value,
      acosTarget: this.sdForm.controls.algoModeConfig.value?.acosTarget
        ? this.sdForm.controls.algoModeConfig.value.acosTarget / 100
        : undefined,
      disableOtherQueries: this.sdForm.controls.targetingType.value !== SdTargetingType.PRODUCT_AI_TARGETING,
      tactics,
      audienceTargetings,
    };

    this.strategyService
      .createStrategy(strategy)
      .pipe(
        switchMap((strategy) => {
          if (this.sdForm.controls.targetingType.value === SdTargetingType.PRODUCT) {
            return this.strategyService
              .addTacticToStrategy(
                this.accountId,
                this.marketplace,
                strategy.strategyId!,
                this.sdForm.controls.segment.value.segmentId,
                TacticType.LEGACY,
              )
              .pipe(map(() => strategy));
          }
          if (this.sdForm.controls.targetingType.value === SdTargetingType.REMARKETING) {
            return this.sdStrategiesService
              .addRemarketingAudienceToSdStrategy(
                this.accountId,
                this.marketplace,
                strategy.strategyId!,
                this.sdForm.controls.audienceExpressionType.value!,
                this.sdForm.controls.audienceMatchType.value!,
                this.sdForm.controls.audienceLookback.value!,
              )
              .pipe(map(() => strategy));
          }
          return of(strategy);
        }),
      )
      .subscribe({
        next: (strategy) => {
          this.toastrService.success("Strategy successfully created", "Strategy created");
          this.strategyCreated.emit(strategy);
          this.submitOngoing = false;
        },
        error: (e: string) => {
          this.toastrService.error("Error when creating strategy: " + e, "Strategy creation error");
          this.submitOngoing = false;
        },
      });
  }

  setAlgoModeConfig(algoModeConfig: Partial<AlgoModeConfig>) {
    this.sdForm.controls.algoModeConfig.setValue(algoModeConfig as AlgoModeConfig);
  }

  setAlgoModeConfigValid(valid: AlgoModeConfigValid) {
    this.sdForm.controls.algoModeConfig.setErrors(valid.errors);
  }

  getInvalidOrMissingFields() {
    const res = [];
    const keys = Object.keys(this.sdForm.controls);

    for (const c of keys) {
      if (this.sdForm.controls[c].invalid) {
        if (c === "asins") continue;
        res.push(formControlNames.get(c));
      }
    }

    return res;
  }

  addStrategyAsins(asins: StrategyAsin[]) {
    const newList = this.sdForm.controls.asins.value.concat(asins);
    this.sdForm.controls.asins.setValue(newList);
  }

  deleteStrategyAsins(asins: StrategyAsin[]) {
    const newList = this.sdForm.controls.asins.value.filter((a) => !asins.map((a2) => a2.asin).includes(a.asin));
    this.sdForm.controls.asins.setValue(newList);
  }

  asinListValidator(control: AbstractControl) {
    if (!control.value || !control.value.length) return { missingAsins: true };
    return null;
  }
}
