<ng-container *transloco="let t">
  <app-strategy-overlay>
    @if (hasSponsoredDisplay() && (hasBrand() | async) && !isKDP()) {
      <app-strategy-list
        [campaignType]="CampaignType.SD"
        (strategyCreationClick)="createStrategy()"
      ></app-strategy-list>
    }
    @if (!hasSponsoredDisplay()) {
      <div class="mt-4">{{ t("sd-manager.sd_not_available_marketplace") }}</div>
    }
    @if ((hasBrand() | async) === false) {
      <div class="mt-4">{{ t("sd-manager.sd_only_for_sellers") }}</div>
    }
    @if (isKDP()) {
      <div class="mt-4">{{ t("sd-manager.sd_not_for_kdp") }}</div>
    }
  </app-strategy-overlay>
</ng-container>
