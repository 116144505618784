// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    Marketplace,
    QueryClassGraph,
    QueryKeywordGraph,
    QueryKeywordSuspicious,
    Response,
} from '../models';

export interface BlacklistQueryKeywordRequest {
    marketplace: Marketplace;
    q: string;
    keyword: string;
}

export interface GetAllBlacklistedQueryKeywordsRequest {
    marketplace: Marketplace;
}

export interface GetAllSuspiciousGraphRequest {
    marketplace: Marketplace;
}

export interface GetAllSuspiciousQueryKeywordsRequest {
    marketplace: Marketplace;
}

export interface GetQueryClassGraphRequest {
    marketplace: Marketplace;
    _class: string;
}

export interface GetQueryClassGraphExplorerRequest {
    marketplace: Marketplace;
    like: string;
}

export interface GetQueryKeywordBlacklistedRequest {
    marketplace: Marketplace;
    requestBody?: Array<string>;
}

export interface GetQueryKeywordBlacklistedLikeRequest {
    marketplace: Marketplace;
    like: string;
}

export interface GetQueryKeywordsGraphRequest {
    marketplace: Marketplace;
    _class: string;
}

export interface GetSimilarWordsRequest {
    marketplace: Marketplace;
    wordList: Array<string>;
}

export interface GetSimilarWordsLikeRequest {
    marketplace: Marketplace;
    like: string;
}

export interface WhitelistQueryKeywordRequest {
    marketplace: Marketplace;
    q: string;
    keyword: string;
}

/**
 * no description
 */
export class GraphApi extends BaseAPI {

    /**
     * blacklist a query keyword
     * blacklist a query keyword
     */
    blacklistQueryKeyword({ marketplace, q, keyword }: BlacklistQueryKeywordRequest): Observable<Response>
    blacklistQueryKeyword({ marketplace, q, keyword }: BlacklistQueryKeywordRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    blacklistQueryKeyword({ marketplace, q, keyword }: BlacklistQueryKeywordRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(marketplace, 'marketplace', 'blacklistQueryKeyword');
        throwIfNullOrUndefined(q, 'q', 'blacklistQueryKeyword');
        throwIfNullOrUndefined(keyword, 'keyword', 'blacklistQueryKeyword');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'marketplace': marketplace,
            'q': q,
            'keyword': keyword,
        };

        return this.request<Response>({
            url: '/graph/queryKeywordBlacklist',
            method: 'POST',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Get all blacklisted Query keywords
     * Get all blacklisted Query keywords
     */
    getAllBlacklistedQueryKeywords({ marketplace }: GetAllBlacklistedQueryKeywordsRequest): Observable<Array<QueryKeywordGraph>>
    getAllBlacklistedQueryKeywords({ marketplace }: GetAllBlacklistedQueryKeywordsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<QueryKeywordGraph>>>
    getAllBlacklistedQueryKeywords({ marketplace }: GetAllBlacklistedQueryKeywordsRequest, opts?: OperationOpts): Observable<Array<QueryKeywordGraph> | AjaxResponse<Array<QueryKeywordGraph>>> {
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getAllBlacklistedQueryKeywords');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'marketplace': marketplace,
        };

        return this.request<Array<QueryKeywordGraph>>({
            url: '/graph/queryKeywordBlacklist',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Get all suspicious Graph
     * Get all suspicious Graph
     */
    getAllSuspiciousGraph({ marketplace }: GetAllSuspiciousGraphRequest): Observable<Array<QueryClassGraph>>
    getAllSuspiciousGraph({ marketplace }: GetAllSuspiciousGraphRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<QueryClassGraph>>>
    getAllSuspiciousGraph({ marketplace }: GetAllSuspiciousGraphRequest, opts?: OperationOpts): Observable<Array<QueryClassGraph> | AjaxResponse<Array<QueryClassGraph>>> {
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getAllSuspiciousGraph');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'marketplace': marketplace,
        };

        return this.request<Array<QueryClassGraph>>({
            url: '/graph/suspiciousGraph',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Get all suspicious Query keywords
     * Get all suspicious Query keywords
     */
    getAllSuspiciousQueryKeywords({ marketplace }: GetAllSuspiciousQueryKeywordsRequest): Observable<Array<QueryKeywordSuspicious>>
    getAllSuspiciousQueryKeywords({ marketplace }: GetAllSuspiciousQueryKeywordsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<QueryKeywordSuspicious>>>
    getAllSuspiciousQueryKeywords({ marketplace }: GetAllSuspiciousQueryKeywordsRequest, opts?: OperationOpts): Observable<Array<QueryKeywordSuspicious> | AjaxResponse<Array<QueryKeywordSuspicious>>> {
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getAllSuspiciousQueryKeywords');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'marketplace': marketplace,
        };

        return this.request<Array<QueryKeywordSuspicious>>({
            url: '/graph/suspiciousQueries',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Provide query class
     * Query class
     */
    getQueryClassGraph({ marketplace, _class }: GetQueryClassGraphRequest): Observable<Array<QueryClassGraph>>
    getQueryClassGraph({ marketplace, _class }: GetQueryClassGraphRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<QueryClassGraph>>>
    getQueryClassGraph({ marketplace, _class }: GetQueryClassGraphRequest, opts?: OperationOpts): Observable<Array<QueryClassGraph> | AjaxResponse<Array<QueryClassGraph>>> {
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getQueryClassGraph');
        throwIfNullOrUndefined(_class, '_class', 'getQueryClassGraph');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'marketplace': marketplace,
            'class': _class,
        };

        return this.request<Array<QueryClassGraph>>({
            url: '/graph/queryClass',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Provide query classes
     * Query class
     */
    getQueryClassGraphExplorer({ marketplace, like }: GetQueryClassGraphExplorerRequest): Observable<Array<QueryClassGraph>>
    getQueryClassGraphExplorer({ marketplace, like }: GetQueryClassGraphExplorerRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<QueryClassGraph>>>
    getQueryClassGraphExplorer({ marketplace, like }: GetQueryClassGraphExplorerRequest, opts?: OperationOpts): Observable<Array<QueryClassGraph> | AjaxResponse<Array<QueryClassGraph>>> {
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getQueryClassGraphExplorer');
        throwIfNullOrUndefined(like, 'like', 'getQueryClassGraphExplorer');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'marketplace': marketplace,
            'like': like,
        };

        return this.request<Array<QueryClassGraph>>({
            url: '/graph/queryClassExplorer',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Return query-keywords blacklisted for queries in input
     * Get blacklisted query keywords for queries in input
     */
    getQueryKeywordBlacklisted({ marketplace, requestBody }: GetQueryKeywordBlacklistedRequest): Observable<Array<QueryKeywordGraph>>
    getQueryKeywordBlacklisted({ marketplace, requestBody }: GetQueryKeywordBlacklistedRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<QueryKeywordGraph>>>
    getQueryKeywordBlacklisted({ marketplace, requestBody }: GetQueryKeywordBlacklistedRequest, opts?: OperationOpts): Observable<Array<QueryKeywordGraph> | AjaxResponse<Array<QueryKeywordGraph>>> {
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getQueryKeywordBlacklisted');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'marketplace': marketplace,
        };

        return this.request<Array<QueryKeywordGraph>>({
            url: '/graph/queryKeywordBlacklistedByQueries',
            method: 'POST',
            headers,
            query,
            body: requestBody,
        }, opts?.responseOpts);
    };

    /**
     * Provide Query keywords blacklisted like
     * Query keywords blacklisted
     */
    getQueryKeywordBlacklistedLike({ marketplace, like }: GetQueryKeywordBlacklistedLikeRequest): Observable<Array<QueryKeywordGraph>>
    getQueryKeywordBlacklistedLike({ marketplace, like }: GetQueryKeywordBlacklistedLikeRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<QueryKeywordGraph>>>
    getQueryKeywordBlacklistedLike({ marketplace, like }: GetQueryKeywordBlacklistedLikeRequest, opts?: OperationOpts): Observable<Array<QueryKeywordGraph> | AjaxResponse<Array<QueryKeywordGraph>>> {
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getQueryKeywordBlacklistedLike');
        throwIfNullOrUndefined(like, 'like', 'getQueryKeywordBlacklistedLike');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'marketplace': marketplace,
            'like': like,
        };

        return this.request<Array<QueryKeywordGraph>>({
            url: '/graph/queryKeywordBlacklistedLike',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Provide query keywords of a class
     * Query keywords used to build graph
     */
    getQueryKeywordsGraph({ marketplace, _class }: GetQueryKeywordsGraphRequest): Observable<Array<QueryKeywordGraph>>
    getQueryKeywordsGraph({ marketplace, _class }: GetQueryKeywordsGraphRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<QueryKeywordGraph>>>
    getQueryKeywordsGraph({ marketplace, _class }: GetQueryKeywordsGraphRequest, opts?: OperationOpts): Observable<Array<QueryKeywordGraph> | AjaxResponse<Array<QueryKeywordGraph>>> {
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getQueryKeywordsGraph');
        throwIfNullOrUndefined(_class, '_class', 'getQueryKeywordsGraph');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'marketplace': marketplace,
            'class': _class,
        };

        return this.request<Array<QueryKeywordGraph>>({
            url: '/graph/queryKeywords',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Provide similar words
     * Similar words
     */
    getSimilarWords({ marketplace, wordList }: GetSimilarWordsRequest): Observable<Array<QueryClassGraph>>
    getSimilarWords({ marketplace, wordList }: GetSimilarWordsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<QueryClassGraph>>>
    getSimilarWords({ marketplace, wordList }: GetSimilarWordsRequest, opts?: OperationOpts): Observable<Array<QueryClassGraph> | AjaxResponse<Array<QueryClassGraph>>> {
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getSimilarWords');
        throwIfNullOrUndefined(wordList, 'wordList', 'getSimilarWords');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'marketplace': marketplace,
            'wordList': wordList,
        };

        return this.request<Array<QueryClassGraph>>({
            url: '/graph/similarWords',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Provide similar words like
     * Similar words like
     */
    getSimilarWordsLike({ marketplace, like }: GetSimilarWordsLikeRequest): Observable<Array<QueryClassGraph>>
    getSimilarWordsLike({ marketplace, like }: GetSimilarWordsLikeRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<QueryClassGraph>>>
    getSimilarWordsLike({ marketplace, like }: GetSimilarWordsLikeRequest, opts?: OperationOpts): Observable<Array<QueryClassGraph> | AjaxResponse<Array<QueryClassGraph>>> {
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getSimilarWordsLike');
        throwIfNullOrUndefined(like, 'like', 'getSimilarWordsLike');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'marketplace': marketplace,
            'like': like,
        };

        return this.request<Array<QueryClassGraph>>({
            url: '/graph/similarWordsLike',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * whitelist a query keyword
     * whitelist a query keyword
     */
    whitelistQueryKeyword({ marketplace, q, keyword }: WhitelistQueryKeywordRequest): Observable<Response>
    whitelistQueryKeyword({ marketplace, q, keyword }: WhitelistQueryKeywordRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    whitelistQueryKeyword({ marketplace, q, keyword }: WhitelistQueryKeywordRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(marketplace, 'marketplace', 'whitelistQueryKeyword');
        throwIfNullOrUndefined(q, 'q', 'whitelistQueryKeyword');
        throwIfNullOrUndefined(keyword, 'keyword', 'whitelistQueryKeyword');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'marketplace': marketplace,
            'q': q,
            'keyword': keyword,
        };

        return this.request<Response>({
            url: '/graph/queryKeywordBlacklist',
            method: 'DELETE',
            headers,
            query,
        }, opts?.responseOpts);
    };

}
