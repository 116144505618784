// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    AccessLevel,
} from './';

/**
 * @export
 * @interface AuthorizedAccessUpdate
 */
export interface AuthorizedAccessUpdate {
    /**
     * @type {number}
     * @memberof AuthorizedAccessUpdate
     */
    organizationId: number;
    /**
     * @type {number}
     * @memberof AuthorizedAccessUpdate
     */
    profileId: number;
    /**
     * @type {AccessLevel}
     * @memberof AuthorizedAccessUpdate
     */
    accessLevel?: AccessLevel;
    /**
     * @type {string}
     * @memberof AuthorizedAccessUpdate
     */
    email: string;
}
