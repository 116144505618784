// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    FbaStorageFees,
    Marketplace,
} from '../models';

export interface GetFbaStorageFeesRequest {
    accountId: string;
    marketplace: Marketplace;
    minDate: string;
    maxDate: string;
}

/**
 * no description
 */
export class FbaStorageFeesApi extends BaseAPI {

    /**
     * Get all fba storage fees for all asins of an accountId - marketplace
     */
    getFbaStorageFees({ accountId, marketplace, minDate, maxDate }: GetFbaStorageFeesRequest): Observable<Array<FbaStorageFees>>
    getFbaStorageFees({ accountId, marketplace, minDate, maxDate }: GetFbaStorageFeesRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<FbaStorageFees>>>
    getFbaStorageFees({ accountId, marketplace, minDate, maxDate }: GetFbaStorageFeesRequest, opts?: OperationOpts): Observable<Array<FbaStorageFees> | AjaxResponse<Array<FbaStorageFees>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getFbaStorageFees');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getFbaStorageFees');
        throwIfNullOrUndefined(minDate, 'minDate', 'getFbaStorageFees');
        throwIfNullOrUndefined(maxDate, 'maxDate', 'getFbaStorageFees');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'minDate': minDate,
            'maxDate': maxDate,
        };

        return this.request<Array<FbaStorageFees>>({
            url: '/fbaStorageFees',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

}
