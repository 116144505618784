<ng-container *transloco="let t">
  @if (viewMode === ViewTypeEnum.LIST) {
    <div
      class="group relative flex items-center bg-white p-3 duration-100"
      [ngClass]="{ 'hover:bg-gray-50': _isSelectable }"
      [class.disabled]="_disabled"
      [class.list-separator]="!isLast"
      (click)="toggleAsin()"
    >
      @if ($product()) {
        @if (_isSelectable) {
          <ICheckbox
            [value]="_selected"
            (valueChange)="toggleAsin()"
            (click)="$event.stopPropagation()"
            [disabled]="isReadOnly"
            testid="checkbox-asin-item"
          />
        }
        <app-product-thumbnail class="sensitive-data mx-2" [product]="$product()" [smallImg]="true" />
        <div class="min-w-0 truncate">
          <div
            class="sensitive-data truncate font-medium text-gray-800"
            [matTooltip]="$product().title"
            matTooltipClass="sensitive-data"
            data-testid="asin-item-title"
          >
            {{ $product().title }}
          </div>
          <div class="sensitive-data flex items-center">
            <IBadge class="mr-2" color="gray" [label]="$product().asin" />
            @if ($product().brand) {
              <IBadge color="gray" [label]="$product().brand" />
            }
            @if ($activeStrategies()?.length > 0) {
              <span
                class="text-primary ml-3"
                (mouseenter)="displayActiveStrategies(trigger)"
                (mouseleave)="hideActiveStrategies()"
                type="button"
                cdkOverlayOrigin
                #trigger="cdkOverlayOrigin"
                style="font-weight: 500"
                >{{ $activeStrategiesMessage() }}</span
              >
            }
            @if (isIneligible) {
              <span
                class="icon-[material-symbols--warning-rounded] ml-2 text-xl text-orange-400"
                [matTooltip]="ineligibilityReason"
              ></span>
            }
            @if (isPaused) {
              <span
                class="icon-[material-symbols--pause-circle-rounded] ml-2 text-xl text-gray-300"
                [matTooltip]="t('asin-item.asin_paused')"
              ></span>
            }
            @if (actionsButtons) {
              <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
            }
          </div>
        </div>
      } @else {
        <app-spinner type="default" size="s" [display]="true"></app-spinner>
      }
    </div>
  } @else {
    <ICard [class]="cardStyle" (click)="toggleAsin()">
      <ng-template #body>
        @if ($product()) {
          @if (_selected) {
            <span
              class="icon-[material-symbols--check-circle-rounded] text-main-500 absolute left-1 top-1 text-xl"
            ></span>
          }
          @if (actionsButtons) {
            <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
          }
          <div class="relative flex gap-3">
            <div class="relative flex rounded-sm border border-gray-200 p-1">
              <app-product-thumbnail [product]="$product()" customSizeClass="size-16" class="sensitive-data" />

              @if (isIneligible) {
                <span
                  class="icon-[material-symbols--warning-rounded] absolute right-0 top-0 -mr-1 -mt-3 text-xl text-orange-400"
                  [matTooltip]="ineligibilityReason"
                ></span>
              }
              @if (isPaused) {
                <span
                  class="icon-[material-symbols--pause-circle-rounded] absolute right-0 top-0 -mr-1 -mt-3 text-xl text-gray-300"
                  matTooltip="{{ t('asin-item.asin_paused') }}"
                ></span>
              }
            </div>

            <div class="sensitive-data flex flex-col gap-1 truncate">
              @if ($activeStrategies()?.length > 0) {
                <span
                  class="text-main select-none text-sm font-medium"
                  (mouseenter)="displayActiveStrategies(trigger)"
                  (mouseleave)="hideActiveStrategies()"
                  type="button"
                  cdkOverlayOrigin
                  #trigger="cdkOverlayOrigin"
                >
                  {{ $activeStrategiesMessage() }}
                </span>
              }
              <span
                class="truncate text-sm font-medium"
                [matTooltip]="$product().title"
                matTooltipClass="sensitive-data"
                matTooltipPosition="below"
                >{{ $product().title }}</span
              >
              <div class="flex w-full">
                <IBadge color="gray" [label]="$product().asin" class="mr-2" />

                @if ($product().brand) {
                  <IBadge color="gray" [label]="$product().brand" />
                }
              </div>
            </div>
          </div>
        } @else {
          <div class="flex animate-pulse">
            <div class="h-16 w-16 rounded-sm bg-slate-200"></div>
            <div class="ml-2">
              <div class="mb-3 h-4 w-28 rounded bg-slate-200"></div>
              <div class="h-4 w-40 rounded bg-slate-200"></div>
            </div>
          </div>
        }
      </ng-template>
    </ICard>
  }
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="activeStrategiesOrigin"
    [cdkConnectedOverlayOpen]="overlayOpened"
  >
    <div class="card-common p-2" #activeStrategiesPopup>
      @for (s of $activeStrategies(); track s.strategyId) {
        <div class="flex w-full items-center justify-between" [class.mb-2]="!$last">
          <div>
            <fa-icon
              [icon]="s.state === State.ENABLED ? faPlayCircle : faPauseCircle"
              [ngClass]="s.state === State.ENABLED ? 'activated' : 'desactivated'"
              class="mr-2"
            ></fa-icon
            ><span>{{ s.name }}</span>
          </div>
          <IBadge class="ml-2" color="gray" [label]="StrategyTypeStr[s.strategyType]" size="xs" />
        </div>
      }
    </div>
  </ng-template>

  <ng-template #actionButtons>
    <div class="absolute right-0 top-0 z-50 mr-3 mt-3 opacity-0 group-hover:opacity-100">
      <IButton
        class="mr-1"
        color="white"
        size="xs"
        icon="icon-[material-symbols--content-copy-outline-rounded]"
        matTooltip="{{ t('common.copy_asin') }}"
        (onClick)="copyToClipboard($product().asin); $event.stopPropagation()"
      />

      <IButton
        color="white"
        size="xs"
        [to]="$product360Url()"
        [queryParams]="{ asin: $product().asin }"
        target="_blank"
        icon="icon-[mdi--binoculars]"
        queryParamsHandling="merge"
        matTooltip="{{ t('parent-product-view-widget.view_in_product_360') }}"
        (onClick)="$event.stopPropagation()"
      />
    </div>
  </ng-template>
</ng-container>
