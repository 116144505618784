import {
  CDK_DRAG_CONFIG,
  CdkDragDrop,
  CdkDragEnter,
  copyArrayItem,
  DragDropModule,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { AsyncPipe, NgClass, NgStyle } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  AccountMarketplace,
  BrandAsset,
  Marketplace,
  SbAsins,
  SbCreativeType,
  TacosStrategyGroup,
} from "@front/m19-api-client";
import { Catalog, InventoryRules, InventoryStats } from "@front/m19-models";
import {
  AccountSelectionService,
  AsinService,
  Constant,
  InventoryService,
  TacosStrategiesService,
} from "@front/m19-services";
import { SpinnerComponent } from "@front/m19-ui";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, combineLatest, skipWhile, switchMap, tap } from "rxjs";
import { ClusterAsinCardComponent } from "./cluster-asin-card/cluster-asin-card.component";

const DragConfig = {
  zIndex: 2000,
};

@UntilDestroy()
@Component({
  selector: "app-drag-drop-asins",
  templateUrl: "./drag-drop-asins.component.html",
  styleUrls: ["./drag-drop-asins.component.scss"],
  standalone: true,
  imports: [
    ClusterAsinCardComponent,
    DragDropModule,
    MatTooltipModule,
    NgClass,
    MatPaginatorModule,
    NgStyle,
    FaIconComponent,
    SpinnerComponent,
    AsyncPipe,
    TranslocoRootModule,
  ],
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }],
})
export class DragDropAsinsComponent implements OnInit {
  readonly ASIN_PER_PAGE = 10;

  @Input() ctnHeight: "medium" | "big" = "big";
  @Input() groupLimit = Constant.maxAdlinesPerCreative;

  @Input() set creativeType(type: SbCreativeType) {
    this._creativeType = type;
    if (!type) this._creativeType = SbCreativeType.productCollection;

    this.clusterLists = [[]];
    this.clusterLimit = this._creativeType === SbCreativeType.video ? Constant.maxAsinPerVideoAdLine : 3;
    // this.emitAsinsUpdated();
  }

  _creativeType?: SbCreativeType;

  @Input() set store(s: BrandAsset) {
    this.store$.next(s);
  }

  @Input() set tacosStrategy(strategy: TacosStrategyGroup | undefined) {
    this._tacosStrategyId = strategy?.tacosStrategyGroupId;

    if (this._tacosStrategyId) {
      this.accSelectionService.singleAccountMarketplaceSelection$
        .pipe(
          untilDestroyed(this),
          switchMap((am: AccountMarketplace) =>
            this.tacosStrategiesService.getTacosSPAsins(am.accountId, am.marketplace, this._tacosStrategyId!),
          ),
        )
        .subscribe((asins) => {
          this.asinList$.next(asins);
        });
    }
  }

  _tacosStrategyId?: number;

  store$ = new BehaviorSubject<BrandAsset | undefined>(undefined);
  asinList$ = new BehaviorSubject<string[]>([]);

  @Input() set asins(a: SbAsins[]) {
    this._asins = a;
    if (a?.length) {
      this.setupAsins(a);
    }
  } // sb asins in cluster
  _asins?: SbAsins[]; // sb asins in cluster

  @Output() asinUpdated = new EventEmitter<SbAsins[]>();

  marketplace?: Marketplace;

  asinList: string[] = [];
  filteredAsinList: string[] = [];
  displayedAsins: string[] = []; // for pagination

  hasClusterError = false;
  errorMap?: Map<number, string | undefined>;

  // list containing all clusters list (length >= 1)
  clusterLists: Array<string[]> = new Array<string[]>([]);
  clusterLimit?: number;

  availableClustersByAsin: Map<string, number[]> = new Map();
  eligibilityByAsin: Map<string, { status: boolean; reason: string }> = new Map();
  ineligibilityByCluster: Map<number, boolean> = new Map();
  productsTitles: Map<string, string> = new Map();
  pausedAsins = new Set<string>();
  catalog?: Catalog;

  asinsLoading = false;
  pageEvent = new PageEvent();

  tacosAsins = toSignal(this.tacosStrategiesService.tacosStrategyByAsins$);

  constructor(
    private accSelectionService: AccountSelectionService,
    private inventoryService: InventoryService,
    private asinService: AsinService,
    private tacosStrategiesService: TacosStrategiesService,
  ) {}

  ngOnInit() {
    this.asinList$.pipe(untilDestroyed(this)).subscribe((asins: string[]) => {
      this.filteredAsinList = asins;
      this.displayedAsins = this.filteredAsinList.slice(0, this.ASIN_PER_PAGE);
      this.updateAvailableClusters();
      this.asinsLoading = false;
    });

    this.clusterLimit = this._creativeType === SbCreativeType.video ? Constant.maxAsinPerVideoAdLine : 3;
    if (!this._creativeType) this._creativeType = SbCreativeType.productCollection;

    this.accSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        tap((am: AccountMarketplace) => {
          this.marketplace = am.marketplace;
        }),
        switchMap((am: AccountMarketplace) =>
          combineLatest([
            this.inventoryService.getSellerInventoryStats(am.accountId, am.marketplace),
            this.asinService.getInventoryRules(am.accountId, am.marketplace),
          ]),
        ),
        untilDestroyed(this),
      )
      .subscribe(([inventoryStats, inventoryRules]: [InventoryStats[], InventoryRules | undefined]) => {
        this.pausedAsins.clear();
        for (const s of inventoryStats) {
          if (inventoryRules?.execute(s.asin, s).shouldPauseAdvertising) this.pausedAsins.add(s.asin);
        }
      });

    this.accSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        tap((am: AccountMarketplace) => {
          this.marketplace = am.marketplace;
          this.asinsLoading = true;
        }),
        switchMap((am: AccountMarketplace) =>
          combineLatest([this.asinService.getCatalog(am.accountId, am.marketplace), this.store$]),
        ),
        tap(([c, _store]) => {
          this.catalog = c;
        }),
        skipWhile(([c, _store]) => [...c.getProductTitles().keys()].length <= 0),
        untilDestroyed(this),
      )
      .subscribe(([catalog, store]) => {
        if (store && !this._tacosStrategyId) {
          if (store.asinList) this.asinList$.next(store.asinList);
          else this.asinList$.next([]);
        } else if (!this._tacosStrategyId) {
          this.asinList$.next([...catalog.childAsins.values()]);
        }

        if (this._asins?.length) {
          this.setupAsins(this._asins);
        }
        this.eligibilityByAsin = catalog.getSBEligibility();

        this.productsTitles = catalog.getProductTitles();
        this.updateAvailableClusters();
        this.asinsLoading = false;
      });

    this.errorMap = new Map().set(0, undefined);
  }

  setupAsins(asins: SbAsins[]) {
    if (this._creativeType === SbCreativeType.video) {
      this.clusterLists = [asins.map((a: SbAsins) => a.asin1)];
    } else {
      this.clusterLists = asins.map((a: SbAsins) => {
        const c = [a.asin1];
        if (a.asin2) c.push(a.asin2);
        if (a.asin3) c.push(a.asin3);
        return c;
      });
      // push a new cluster if limit is not reached
      if (this.clusterLists.length < this.groupLimit) this.clusterLists.push([]);
    }
  }

  noDrop() {
    return false;
  }

  drop(event: CdkDragDrop<string[]>) {
    // same cluster
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    // keep asin in asin list after drop
    else if (event.previousContainer.id === "asinList") {
      if (!this.canDrop(event.container.data, event.item.data)) return;
      copyArrayItem(this.displayedAsins, event.container.data, event.previousIndex, event.currentIndex);
      if (event.container.id === this.getLastClusterId()) {
        // create new cluster
        if (this.clusterLists.length < this.groupLimit) this.clusterLists.push([]);
        // add new cluster to error map
        this.errorMap?.set(this.clusterLists.length - 1, undefined);
        // update available clusters for each ASIN
      }
      this.updateAvailableClusters();
    }
    // from cluster to cluster
    else {
      if (!this.canDrop(event.container.data, event.item.data)) return;
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      const pushedToLast = event.container.id === this.getLastClusterId();
      this.handleEmptyClusters();
      // if pushed into the last container and limit is not reached, add a new cluster
      if (pushedToLast && this.clusterLists.length < this.groupLimit) {
        this.clusterLists.push([]);
      }
      this.updateAvailableClusters();
    }
    this.emitAsinsUpdated();
  }

  dropVideo(event: CdkDragDrop<string[]>) {
    // same cluster
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // from ASINs list to cluster
      if (!this.canDrop(event.container.data, event.item.data)) return;
      copyArrayItem(this.displayedAsins, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.updateAvailableClusters();
    this.emitAsinsUpdated();
  }

  manualAsinAdd(clusterIndex: number, asin: string) {
    const container = this.clusterLists[clusterIndex];
    container.push(asin);
    // if push in last cluster, add a cluster
    if (clusterIndex === this.clusterLists.length - 1) {
      if (this._creativeType !== SbCreativeType.video) {
        if (this.clusterLists.length < this.groupLimit) this.clusterLists.push([]);
        this.errorMap?.set(this.clusterLists.length - 1, undefined);
      }
    }
    this.updateAvailableClusters();
    this.emitAsinsUpdated();
  }

  private canDrop(cluster: string[], item: string) {
    return this.clusterLimit && cluster.length < this.clusterLimit && !cluster.includes(item);
  }

  isAsinInCluster(asin: string, cluster: string[]) {
    return cluster.map((a) => a).includes(asin);
  }

  removeAsinFromCluster(asin: string, cluster: string[]) {
    const asinIndex = cluster.map((a) => a).indexOf(asin);
    cluster.splice(asinIndex, 1);
    this.handleEmptyClusters();
    this.updateAvailableClusters();
    this.emitAsinsUpdated();
  }

  resetCluster(index: number) {
    this.clusterLists[index] = [];
    this.handleEmptyClusters();

    this.updateAvailableClusters();

    this.emitAsinsUpdated();
  }

  setFilter(query: string): void {
    const filter = new RegExp(query, "i");
    if (filter)
      this.filteredAsinList = this.asinList$.value.filter(
        (asin: string) =>
          asin.search(filter) != -1 ||
          (this.productsTitles.get(asin) && this.productsTitles.get(asin)!.search(filter) != -1),
      );
    else this.filteredAsinList = this.asinList$.value;
    this.displayedAsins = this.filteredAsinList.slice(0, this.ASIN_PER_PAGE);
  }

  private handleEmptyClusters() {
    for (let i = 0; i < this.clusterLists.length; i++)
      if (this.clusterLists[i].length == 0 && i !== this.clusterLists.length - 1) {
        this.clusterLists.splice(i, 1);
        i--;
      }
  }

  private getLastClusterId() {
    return `drop-list-${this.clusterLists.length - 1}`;
  }

  clusterHovered(event: CdkDragEnter) {
    const cluster = event.container.data;
    const clusterIndex = +event.container.id[event.container.id.length - 1];
    const dragged: string = event.item.data;

    if (this.clusterLimit && cluster.length >= this.clusterLimit) {
      this.errorMap?.set(clusterIndex, "Cluster is full");
      this.hasClusterError = true;
    } else if (cluster.includes(dragged)) {
      this.errorMap?.set(clusterIndex, "Cluster already contains this ASIN");
      this.hasClusterError = true;
    }
  }

  private convertClustersToSbAsins(): SbAsins[] {
    let asins = this.clusterLists.map((c: string[]) => ({
      asin1: c[0],
      asin2: c[1],
      asin3: c[2],
    }));

    if (!this.isGroupLimitReached()) {
      asins = asins.slice(0, -1); // remove last empty cluster if limit was no reached
    }

    return asins;
  }

  private convertVideoClustersToSbAsins(): SbAsins[] {
    const cluster = this.clusterLists[0];
    return cluster.map((a: string) => ({ asin1: a }));
  }

  private emitAsinsUpdated() {
    const sbAsins =
      this._creativeType === SbCreativeType.video
        ? this.convertVideoClustersToSbAsins()
        : this.convertClustersToSbAsins();
    this.asinUpdated.emit(sbAsins);
  }

  clusterLeft() {
    for (let i = 0; i < this.clusterLists.length; i++) {
      this.errorMap?.set(i, undefined);
    }
    this.hasClusterError = false;
  }

  updateAvailableClusters() {
    this.asinList$.value.forEach((a) => {
      const available = this.clusterLists
        .filter((c) => this.clusterLimit && c.length < this.clusterLimit && !c.includes(a))
        .map((c) => this.clusterLists.indexOf(c));
      this.availableClustersByAsin.set(a, available);
    });

    this.clusterLists.forEach((c, index) => {
      const hasInvalid = c.some((a: string) => !this.isAsinEligible(a));
      this.ineligibilityByCluster.set(index, hasInvalid);
    });
  }

  isAsinEligible(asin: string) {
    return (
      (!this.tacosAsins()?.has(asin) || this._tacosStrategyId) &&
      this.asinList$.value.includes(asin) &&
      this.eligibilityByAsin.get(asin)?.status &&
      !this.pausedAsins.has(asin)
    );
  }

  getIneligibilityReason(asin: string) {
    if (!this.asinList$.value.includes(asin))
      return this.store$.value ? "This ASIN is not in the store" : "This ASIN is not in the catalog";
    if (!this.eligibilityByAsin.get(asin)?.status) {
      const reason = this.eligibilityByAsin.get(asin)?.reason;
      return "This ASIN is ineligible for SB" + (reason ? ": " + reason : "");
    } else if (this.pausedAsins.has(asin)) return "This ASIN has been paused automatically because of low inventory";
    else if (this._creativeType === SbCreativeType.video && this.isAsinInCluster(asin, this.clusterLists[0]))
      return "This ASIN is already in the cluster";
    return "";
  }

  changeContent(event: PageEvent): void {
    this.pageEvent = event;
    this.displayedAsins = this.filteredAsinList.slice(
      event.pageIndex * event.pageSize,
      (event.pageIndex + 1) * event.pageSize,
    );
  }

  isGroupLimitReached(): boolean {
    const clusterListLength = this.clusterLists.filter((c) => c.length > 0).length;
    return clusterListLength >= this.groupLimit;
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  protected readonly SbCreativeType = SbCreativeType;
  protected readonly faSearch = faSearch;
  protected readonly untilDestroyed = untilDestroyed;
}
