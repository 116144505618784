import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { AccountType, DashboardConfig, DashboardConfigApi, DashboardType, Marketplace } from '@front/m19-api-client';
import { Injectable } from '@angular/core';
import { catchAjaxError } from '@front/m19-utils';

type Organization_DashboardType = string;
type DashboardConfigId = number;
export type DashboardConfigs = Map<DashboardConfigId, DashboardConfig>;

function organization_dashboardType(organizationId: number, dashboardType: DashboardType) {
  return `${organizationId}_${dashboardType}`;
}

export type DashboardScope = {
  accountType: AccountType;
  accountId?: string;
  marketplace?: Marketplace;
};

@Injectable({
  providedIn: 'root',
})
export class DashboardConfigService {
  private readonly dashboardConfigs$ = new Map<Organization_DashboardType, ReplaySubject<DashboardConfigs>>();
  private readonly dashboardConfigs = new Map<Organization_DashboardType, DashboardConfigs>();

  constructor(private dashboardConfigApi: DashboardConfigApi) {}

  public getDashboardConfig(
    organizationId: number,
    dashboardType: DashboardType,
    accountType: AccountType,
  ): Observable<DashboardConfigs> {
    const key = organization_dashboardType(organizationId, dashboardType);
    if (this.dashboardConfigs$.has(key)) {
      return this.dashboardConfigs$.get(key)!.pipe(
        map((dashboardConfigs) => {
          const filteredDashboardConfigs = new Map<number, DashboardConfig>();
          for (const [key, value] of dashboardConfigs.entries()) {
            if (value.accountType === accountType) {
              filteredDashboardConfigs.set(key, value);
            }
          }
          return filteredDashboardConfigs;
        }),
      );
    }
    const subject = new ReplaySubject<DashboardConfigs>(1);
    this.dashboardConfigs$.set(key, subject);
    this.dashboardConfigApi
      .listDashboardConfigs({ organizationId, dashboardType })
      .pipe(catchAjaxError())
      .subscribe({
        next: (response) => {
          const dashboardConfigs = new Map<DashboardConfigId, DashboardConfig>();
          for (const dashboardConfig of response) {
            dashboardConfigs.set(dashboardConfig.dashboardId!, dashboardConfig);
          }
          this.dashboardConfigs.set(key, dashboardConfigs);
          subject.next(dashboardConfigs);
        },
        error: (e) => subject.error(e),
      });
    return subject.pipe(
      map((dashboardConfigs) => {
        const filteredDashboardConfigs = new Map<number, DashboardConfig>();
        for (const [key, value] of dashboardConfigs.entries()) {
          if (value.accountType === accountType) {
            filteredDashboardConfigs.set(key, value);
          }
        }
        return filteredDashboardConfigs;
      }),
    );
  }

  createDashboardConfig(
    organizationId: number,
    dashboardType: DashboardType,
    { accountType, accountId, marketplace }: DashboardScope,
    dashboardName: string,
    config: string,
  ): Observable<DashboardConfig> {
    return this.dashboardConfigApi
      .createDashboardConfig({
        dashboardConfig: {
          organizationId,
          dashboardType,
          accountType,
          accountId,
          marketplace,
          name: dashboardName,
          config,
          configVersion: 1,
        },
      })
      .pipe(
        catchAjaxError(),
        map((response) => response.entity!),
        tap((dashboardConfig) => {
          const key = organization_dashboardType(organizationId, dashboardType);
          if (!this.dashboardConfigs$.has(key)) {
            return;
          }
          this.dashboardConfigs.get(key)!.set(dashboardConfig.dashboardId!, dashboardConfig);
          this.dashboardConfigs$.get(key)!.next(this.dashboardConfigs.get(key)!);
        }),
      );
  }

  updateDashboardConfig(organizationId: number, dashboardId: number | undefined, dashboardConfig: DashboardConfig) {
    return this.dashboardConfigApi
      .updateDashboardConfig({
        dashboardConfig: {
          dashboardId,
          organizationId,
          name: dashboardConfig.name,
          dashboardType: dashboardConfig.dashboardType,
          accountType: dashboardConfig.accountType,
          accountId: dashboardConfig.accountId,
          marketplace: dashboardConfig.marketplace,
          configVersion: 1,
          config: dashboardConfig.config,
        },
      })
      .pipe(
        catchAjaxError(),
        map((response) => response.entity as DashboardConfig),
        tap((dashboardConfig: DashboardConfig) => {
          const key = organization_dashboardType(organizationId, dashboardConfig.dashboardType!);
          if (!this.dashboardConfigs$.has(key)) {
            return;
          }
          this.dashboardConfigs.get(key)!.set(dashboardConfig.dashboardId!, dashboardConfig);
          this.dashboardConfigs$.get(key)!.next(this.dashboardConfigs.get(key)!);
        }),
      );
  }

  deleteDashboardConfig(organizationId: number, dashboardType: DashboardType, dashboardId: number) {
    return this.dashboardConfigApi
      .deleteDashboardConfig({
        dashboardId,
      })
      .pipe(
        catchAjaxError(),
        tap(() => {
          const key = organization_dashboardType(organizationId, dashboardType);
          if (!this.dashboardConfigs$.has(key)) {
            return;
          }
          this.dashboardConfigs.get(key)!.delete(dashboardId);
          this.dashboardConfigs$.get(key)!.next(this.dashboardConfigs.get(key)!);
        }),
      );
  }
}
