import { AdStatsEx } from "@front/m19-models";
import { PALETTE } from "@m19-board/models/Metric";
import { MetricEvoComponent } from "../metric-evo/metric-evo.component";
import {
  ColDef,
  ColGroupDef,
  CsvExportParams,
  GridApi,
  ProcessCellForExportParams,
  ProcessHeaderForExportParams,
} from "@ag-grid-community/core";
import { Metric, MetricCategory, MetricRegistry } from "@front/m19-metrics";
import { getMetricsColDef as getMetricsColDefAdakazamService } from "@front/m19-grid-config";

export const GRAPH_ICON = "https://www.svgrepo.com/show/506429/chart-bar.svg";

export const STATUS_BAR = {
  statusPanels: [
    {
      key: "rowCount",
      statusPanel: "agTotalRowCountComponent",
      align: "left",
    },
    {
      key: "totalRowCount",
      statusPanel: "agTotalAndFilteredRowCountComponent",
      align: "left",
    },
    { statusPanel: "agSelectedRowCountComponent" },
    { statusPanel: "agAggregationComponent" },
  ],
};

export interface RangeValues {
  metric: string; // Focused metric (first selected)
  sum: string;
  mean: string;
  min: string;
  max: string;
  count: string; // Cells count
  rowCount?: number;
}

export const IMAGE_COL_ID = "image";
export const ACTIONS_COL_ID = "actions";

export function getMetricsColDef<T, TData>(metrics: Metric<T>[], groupMetric = false): ColGroupDef<T>[] {
  return getMetricsColDefAdakazamService(metrics, groupMetric, MetricEvoComponent, {});
}

function groupMetricsByCategory<T>(metrics: Metric<T>[]): Map<MetricCategory, Metric<T>[]> {
  const res = new Map<MetricCategory, Metric<T>[]>();
  for (const m of metrics) {
    if (!res.has(m.category)) res.set(m.category, [m]);
    else res.get(m.category).push(m);
  }
  // TODO: stop sorting here
  // for (const k of res.keys()) {
  //   res.get(k).sort((a, b) => (a.title > b.title ? 1 : a.title === b.title ? 0 : -1));
  // }
  return res;
}

export function exportGridCsv(gridApi: GridApi, exportParams?: CsvExportParams) {
  // Callback to format metrics for csv
  // THE ID OF THE METRICS COLUMNS MUST BE THE METRIC ID (done in getMetricsColDef func.)
  const processCellCallback = (params) => {
    const colId: string = params.column.getId();
    const metric: Metric<AdStatsEx> = MetricRegistry.get(colId);
    if (!metric) return params.value;

    return metric.valueForCsv(params.node.data ?? params.node.aggData);
  };

  const existingProcessCell = exportParams?.processCellCallback;
  exportParams = {
    ...exportParams,
    skipPinnedBottom: true,
    skipPinnedTop: true,
    allColumns: false,
    processHeaderCallback: (params: ProcessHeaderForExportParams) => {
      // remove spaces to respect PascalCase
      return params.column.getColDef().headerName?.replace(/\s/g, "");
    },
    processCellCallback: (params: ProcessCellForExportParams) => {
      // Apply callback given as argument
      if (existingProcessCell) params.value = existingProcessCell(params);
      // Apply metric format one
      return processCellCallback(params);
    },
  };

  gridApi.exportDataAsCsv(exportParams);
}

export function getCsvFileName(
  prefix: string,
  accountGroupName?: string,
  marketplace?: string,
  dateRange?: string[],
): string {
  let fileName: string = prefix;
  if (accountGroupName) {
    fileName += `_${accountGroupName}`;
  }
  if (marketplace) {
    fileName += `_${marketplace}`;
  }
  if (dateRange) {
    fileName += `_${dateRange.join("_")}`;
  }

  return fileName;
}

export function getBasicSparklineOptions() {
  return {
    type: "line",
    line: {
      stroke: PALETTE[1],
      strokeWidth: 2,
    },
    marker: {
      size: 2,
      fill: PALETTE[1],
      stroke: PALETTE[1],
      strokeWidth: 1,
    },
    highlightStyle: {
      size: 10,
      stroke: PALETTE[1],
      fill: PALETTE[1],
    },
  };
}

// returns editable cell config since these properties always comes together
export function getCellEditConfig(editablePredicate: (params) => boolean, tooltip: string): ColDef {
  return {
    editable: (params) => editablePredicate(params),
    cellClass: (params) => (editablePredicate(params) ? "cell-editable" : ""),
    tooltipValueGetter: (params) => (editablePredicate(params) ? tooltip : ""),
  };
}

export function emptyColumnHeader<T, V>(): Partial<ColDef<T, V>> {
  return {
    // empty column header
    headerComponentParams: {
      template: `<div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
            </div>
          </div>`,
    },
  };
}

export class GridConfig {}
