import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { CampaignType } from "@front/m19-api-client";
import { StrategyOverlayComponent } from "@m19-board/overlay/strategy-overlay.component";
import { StrategyListComponent } from "@m19-board/strategies/strategy-list/strategy-list.component";

@Component({
  selector: "app-sp-manager",
  templateUrl: "./sp-manager.component.html",
  standalone: true,
  imports: [StrategyOverlayComponent, StrategyListComponent],
})
export class SpManagerComponent {
  readonly CampaignType = CampaignType;

  constructor(private router: Router) {}

  createStrategy(): void {
    this.router.navigate(["/advertising/sponsored-product/create"], {
      queryParamsHandling: "merge",
    });
  }
}
