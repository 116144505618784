import { formatDate } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AuthService, CurrencyService } from "@front/m19-services";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ICON_CHEVRON_DOWN, ICON_EMAIL, ICON_USER } from "@m19-board/utils/iconsLabels";
import { ActivatedRoute } from "@angular/router";
import { TranslocoService } from "@jsverse/transloco";
import { I18nService } from "@m19-board/services/i18n.service";
import { Currency, UpdateUserRequest, User } from "@front/m19-api-client";
import { Languages } from "@front/m19-models";
@UntilDestroy()
@Component({
  selector: "app-profile-settings",
  templateUrl: "./profile-settings.component.html",
})
export class ProfileSettingsComponent implements OnInit {
  user: User;

  readonly ICON_USER = ICON_USER;
  readonly ICON_EMAIL = ICON_EMAIL;
  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;

  isLoading = false;
  form = new FormGroup({
    userName: new FormControl<string>("", Validators.required),
    email: new FormControl<string>("", [Validators.required, Validators.email]),
    defaultCurrency: new FormControl<Currency>(Currency.EUR),
    locale: new FormControl<string>("", Validators.required),
    language: new FormControl<string>("en"),
  });
  @Input() canChangeLanguage = false;
  language = "en";
  entering_language = "en";
  isLanguageTranslatable = false;

  locales: object = {
    "de-DE": "German (DE)",
    "de-CH": "German (CH)",
    "en-US": "English (US)",
    "en-GB": "English (GB)",
    "en-CA": "English (CA)",
    "en-AU": "English (AU)",
    "es-ES": "Spanish (ES)",
    "fr-FR": "French (FR)",
    "fr-CA": "French (CA)",
    "fr-CH": "French (CH)",
    "it-IT": "Italian (IT)",
    "it-CH": "Italian (CH)",
    "ja-JP": "Japanese (JP)",
  };

  languages: object = {
    en: "English",
    fr: "French",
    ja: "Japanese",
  };

  constructor(
    private authService: AuthService,
    public currencyService: CurrencyService,
    private modalService: BsModalService,
    private toasterService: ToastrService,
    private route: ActivatedRoute,
    public translocoService: TranslocoService,
    private i18nService: I18nService,
  ) {}

  ngOnInit(): void {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = { ...user };
      this.form.patchValue(user);
      this.route.data.pipe(untilDestroyed(this)).subscribe((v) => (this.canChangeLanguage = v["canChangeLanguage"]));
      // checking the box if the user language is the same as the locale
      this.language = this.user.language ?? "en";
      this.entering_language = this.user.language ?? "en";
    });
  }

  saveUser(): void {
    if (this.form.invalid) return;
    this.isLoading = true;

    const updateRequest: UpdateUserRequest = {};
    updateRequest.email = this.form.controls.email.value;
    updateRequest.userName = this.form.controls.userName.value;
    updateRequest.locale = this.form.controls.locale.value;
    updateRequest.defaultCurrency = this.form.controls.defaultCurrency.value;
    updateRequest.language = this.form.controls.language.value;

    this.authService.updateUser(
      updateRequest,
      () => {
        this.toasterService.success("User settings successfully updated");
        this.isLoading = false;
      },
      (error: string) => {
        this.isLoading = false;
        this.toasterService.error("Error updating user settings: " + error, "User settings update error");
      },
    );
    if (this.language != this.entering_language) {
      this.i18nService.changeLanguage(this.language);
      window.location.reload();
    }
  }

  dateExample(locale: string) {
    return formatDate(Date.now(), "shortDate", locale);
  }

  getInitials(): string {
    return this.user?.userName?.charAt(0).toUpperCase();
  }

  changePassword() {
    const modalOptions: ModalOptions = {
      initialState: {
        user: this.user,
      },
      class: "modal-primary",
    };
    this.modalService.show(ChangePasswordComponent, modalOptions);
  }

  changeLanguage(key: any) {
    this.form.controls.language.setValue(key);
    this.language = key;
  }

  isTranslatable(key: string) {
    return this.translocoService.isLang(key);
  }

  displayNameFromCode(code: string) {
    return Languages.find((l) => l.value === code)?.label;
  }
  changeLocale(key: string) {
    this.form.controls.locale.setValue(key);
  }

  readonly Currency = Currency;
}
