import { formatCurrency, formatDate } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AgGridAngular } from "@ag-grid-community/angular";
import { GridOptions } from "@ag-grid-community/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { getBasicGridOptions } from "@front/m19-grid-config";
import { Currencies } from "@front/m19-models";
import { Currency, Marketplace } from "@front/m19-api-client";
import { ProductViewComponent } from "@m19-board/product-view/product-view.component";
import { FormsModule } from "@angular/forms";
import { IButtonComponent } from "@front/m19-ui";

@Component({
  selector: "app-cogs-upload-modal",
  templateUrl: "./cogs-upload-modal.component.html",
  standalone: true,
  imports: [ProductViewComponent, TranslocoDirective, FormsModule, IButtonComponent, AgGridAngular],
})
export class CogsUpdateModalComponent implements OnInit {
  @Input()
  asin!: string;
  @Input()
  marketplace!: Marketplace;
  @Input()
  locale!: string;
  @Input()
  currency!: Currency;
  @Input()
  cogsHistory: { startDate: string; costOfGoods: number }[] = [];
  @Output()
  valueChange = new EventEmitter<{ startDate: string; costOfGoods: number }[] | "deletion">();

  newCogs?: number;
  currencySymbol?: string;
  selectedCogsHistory: { startDate: string; costOfGoods: number }[] = [];

  // cogs history grid config
  @ViewChild(AgGridAngular) grid!: AgGridAngular;
  readonly cogsHistoryGridOptions: GridOptions = {
    ...getBasicGridOptions("cogsHistory", false, true),
    sideBar: false,
    defaultColDef: {
      flex: 1,
      resizable: true,
      suppressMovable: true,
      sortable: false,
    },
    getRowId: (params) => params.data.startDate,
    singleClickEdit: true,
    rowSelection: "multiple",
    rowMultiSelectWithClick: true,
    suppressRowClickSelection: true,
    onSelectionChanged: () => {
      this.selectedCogsHistory = this.grid.api.getSelectedRows();
    },
    columnDefs: [
      {
        headerName: "",
        maxWidth: 50,
        checkboxSelection: (params) => params.data.startDate != "1970-01-01",
      },
      {
        headerName: this.translocoService.translate("common.date"),
        field: "startDate",
        editable: (params) => params.data.startDate != "1970-01-01",
        sortable: false,
        sort: "desc",
        cellEditor: "agDateStringCellEditor",
        cellEditorParams: {
          min: "2020-01-01",
          max: new Date().toISOString().split("T")[0], // today
        },
        cellClass: "cursor-pointer",
        valueFormatter: (params) => {
          if (params.value == "1970-01-01") {
            return "-";
          }
          return formatDate(params.value, "mediumDate", this.locale);
        },
        onCellValueChanged: (params) => {
          if (!params.newValue) {
            // delete the row if invalid date
            this.grid.api.applyTransaction({
              remove: [params.data],
            });
            return;
          }
          if (this.cogsHistory.find((cog) => cog.startDate == params.data.startDate)) {
            // delete the row as date is duplicated
            this.grid.api.applyTransaction({
              remove: [params.data],
            });
            return;
          }
          this.updateLatestCogsValue();
        },
      },
      {
        headerName: this.translocoService.translate("common.cost_of_goods"),
        field: "costOfGoods",
        editable: true,
        sortable: false,
        cellEditor: "agNumberCellEditor",
        cellEditorParams: {
          precision: 2,
          minValue: 0,
        },
        cellClass: "cursor-pointer",
        valueFormatter: (params) => {
          return formatCurrency(params.value, this.locale, this.currencySymbol!, this.currency, "1.0-2");
        },
        onCellValueChanged: (params) => {
          this.updateLatestCogsValue();
        },
      },
    ],
  };

  constructor(
    private modalRef: BsModalRef,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.currencySymbol = Currencies[this.currency].currencySymbol;
    if (this.cogsHistory.length == 0) {
      this.cogsHistory = [{ startDate: "1970-01-01", costOfGoods: 0 }];
    }
    this.updateLatestCogsValue();
  }

  private getMaxDate() {
    let maxDate = "1970-01-01";
    for (const cog of this.cogsHistory) {
      if (cog.startDate == "1970-01-01") {
        continue;
      }
      if (cog.startDate.localeCompare(maxDate) > 0) {
        maxDate = cog.startDate;
      }
    }
    return maxDate;
  }

  setLatestCogsValue(eventTarget: EventTarget | null) {
    const value = +(eventTarget as HTMLInputElement).value;
    this.newCogs = value;
    const maxDate = this.getMaxDate();
    const latest = this.cogsHistory.find((cog) => cog.startDate == maxDate);
    if (latest) {
      latest.costOfGoods = value;
      this.grid.api.refreshCells();
    } else {
      this.cogsHistory.push({
        startDate: maxDate,
        costOfGoods: value ?? 0,
      });
      this.grid.api.refreshCells();
    }
  }

  private updateLatestCogsValue() {
    const maxDate = this.getMaxDate();
    const latest = this.cogsHistory.find((cog) => cog.startDate == maxDate);
    this.newCogs = latest?.costOfGoods ?? 0;
  }

  addCogsHistory() {
    const now = new Date();
    let unusedDate: string | undefined = undefined;
    while (unusedDate == undefined) {
      const date = now.toISOString().split("T")[0];
      if (!this.cogsHistory.find((cog) => cog.startDate == date)) {
        unusedDate = date;
      } else {
        now.setDate(now.getDate() - 1);
      }
    }
    const newCogsHistory = {
      startDate: unusedDate,
      costOfGoods: 0,
    };
    // insert a new row at the top
    const addedRow = this.grid.api.applyTransaction({
      add: [newCogsHistory],
    })?.add[0];
    this.cogsHistory.push(newCogsHistory);
    this.updateLatestCogsValue();

    if (addedRow) {
      this.grid.api.startEditingCell({
        rowIndex: addedRow.rowIndex!,
        colKey: "startDate",
      });
    }
  }

  deleteCogsHistory() {
    this.cogsHistory = this.cogsHistory.filter((cog) => !this.selectedCogsHistory.includes(cog));
    this.selectedCogsHistory = [];
    this.updateLatestCogsValue();
  }

  updateCostOfGood() {
    // this stops the editing of ongoing cells in the grid to save the changes
    this.grid.api.stopEditing();
    this.modalRef.hide();
    this.valueChange.emit(this.cogsHistory);
  }

  deleteCostOfGoods() {
    this.modalRef.hide();
    this.valueChange.emit("deletion");
  }

  cancel() {
    this.modalRef.hide();
  }
}
