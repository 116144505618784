<div class="flex max-h-[950px] flex-col items-start justify-between p-6" *transloco="let t">
  <div class="flex w-full flex-col items-start justify-start">
    <app-product-view
      [asin]="asin"
      [marketplace]="marketplace"
      class="mb-4 w-full rounded-lg border border-solid border-gray-300 p-4"
    ></app-product-view>
    <div class="input-block w-full">
      <label class="mb-2 font-semibold">{{ t("cogs-upload-modal.cost_of_goods", [currencySymbol]) }}</label>
      <input
        class="simple-input form-control"
        placeholder="{{ t('cogs-upload-modal.enter_cost', [currencySymbol]) }}"
        min="0"
        type="number"
        [(ngModel)]="newCogs"
        (input)="setLatestCogsValue($event.target)"
      />
    </div>
    <div class="mb-2 mt-8 flex cursor-pointer items-center font-bold">
      <span class="font-medium">{{ t("cogs-upload-modal.cost_goods_history") }}</span>
    </div>
    <div class="ag-theme-quartz block w-full">
      <p class="mb-2 text-sm text-gray-600">
        {{ t("cogs-upload-modal.asin_cost_change_title") }}
      </p>
      <div class="mb-1 flex items-center justify-start gap-1">
        <IButton
          label="{{ t('cogs-upload-modal.add_row') }}"
          tooltipValue="{{ t('cogs-upload-modal.add_history_tooltip') }}"
          (onClick)="addCogsHistory()"
        />
        @if (selectedCogsHistory.length > 0) {
          <IButton
            label="{{ t('common.deleteRows') }}"
            tooltipValue="{{ t('cogs-upload-modal.deleted_selected') }}"
            (onClick)="deleteCogsHistory()"
          />
        }
      </div>
      <ag-grid-angular
        class="h-[30rem] w-full"
        [rowData]="cogsHistory"
        [gridOptions]="cogsHistoryGridOptions"
        [animateRows]="true"
      />
    </div>
  </div>
  <div class="mt-4 flex w-full justify-end gap-2">
    <IButton label="{{ t('common.cancel') }}" color="white" (onClick)="cancel()"></IButton>
    <IButton
      label="{{ t('common.delete') }}"
      tooltipValue="{{ t('cogs-upload-modal.delete_cost_goods_tooltip') }}"
      color="red"
      (onClick)="deleteCostOfGoods()"
    ></IButton>
    <IButton label="{{ t('common.update') }}" (onClick)="updateCostOfGood()"></IButton>
  </div>
</div>
