import { Component, OnInit, signal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  AccountType,
  BrandAsset,
  CampaignType,
  MediaType,
  SbCreative,
  SbCreativeType,
  StrategyAsin,
} from "@front/m19-api-client";
import { SbCreativeBrandAssets, StrategyEx } from "@front/m19-models";
import { AccountSelectionService, Constant, SbStrategiesService } from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { TranslocoService } from "@jsverse/transloco";
import { FormMode, SbStrategyFormComponent } from "@m19-board/strategies/sb-strategy-form/sb-strategy-form.component";
import { ICON_ADD } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { combineLatest, Observable } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: "app-sb-page",
  templateUrl: "./sb-page.component.html",
})
export class SbPageComponent implements OnInit {
  strategy$!: Observable<StrategyEx | undefined>;

  adLinesVisible = true;

  strategy: StrategyEx | undefined;

  allCreatives: SbCreative[] = [];
  allCreativesFiltered: SbCreative[] = [];

  creativesProduct: SbCreative[] = [];
  creativesVideo: SbCreative[] = [];
  creativesBrandVideo: SbCreative[] = [];
  creativesSpotlight: SbCreative[] = [];
  accountType?: AccountType;
  isReadOnly = false;
  creativesWithoutCustomImage = 0;
  targetingStatsVisible = false;
  creativeBrandAssets: Map<number, SbCreativeBrandAssets> = new Map();

  readonly customImages$: Observable<BrandAsset[]>;
  readonly maxCreativesPerStrategy = Constant.maxCreativesPerStrategy;

  readonly ICON_ADD = ICON_ADD;

  readonly creativeTypeFilterOptions: Option<SbCreativeType>[] = [
    {
      label: this.translocoService.translate("creative-display.product_collection"),
      value: SbCreativeType.productCollection,
    },
    {
      label: this.translocoService.translate("sb-form-creative.video"),
      value: SbCreativeType.video,
    },
    {
      label: this.translocoService.translate("sb-form-ad-format.brand_video"),
      value: SbCreativeType.brandVideo,
    },
    {
      label: this.translocoService.translate("sb-form-ad-format.store_spotlight"),
      value: SbCreativeType.storeSpotlight,
    },
  ];
  selectedCreativeTypes = signal<Option<SbCreativeType>[]>([]);

  constructor(
    private route: ActivatedRoute,
    private sbStrategiesService: SbStrategiesService,
    private modalService: BsModalService,
    private accountSelectionService: AccountSelectionService,
    private translocoService: TranslocoService,
  ) {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) => this.sbStrategiesService.getSbCreativeBrandAssets(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((brandAssets) => {
        this.creativeBrandAssets = brandAssets;
      });
    this.customImages$ = this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(
      untilDestroyed(this),
      switchMap((am) => this.sbStrategiesService.getBrandAssets(am.accountId, am.marketplace)),
      map((brandAssets) => brandAssets.filter((b) => !b.deleted && b.mediaType === MediaType.customImage)),
    );
  }

  ngOnInit(): void {
    this.strategy$ = combineLatest([
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      this.route.paramMap,
    ]).pipe(
      switchMap(([am, params]) => {
        const id = Number(params.get("id"));
        return this.sbStrategiesService.getSBStrategies(am.accountId, am.marketplace).pipe(
          map((strategies) => {
            return strategies.has(id) ? new StrategyEx(strategies.get(id)!) : undefined;
          }),
        );
      }),
    );
    this.strategy$
      .pipe(
        untilDestroyed(this),
        filter((s) => !s),
      )
      .subscribe((_) => {
        this.strategy = undefined;
      });
    this.strategy$
      .pipe(
        untilDestroyed(this),
        filter((s): s is StrategyEx => !!s),
        switchMap((s) =>
          this.sbStrategiesService
            .getSbCreativesPerStrategy(s.accountId, s.marketplace)
            .pipe(map((creatives) => ({ strategy: s, creatives: creatives.get(s.strategyId) ?? [] }))),
        ),
      )
      .subscribe(({ strategy: strat, creatives }) => {
        this.strategy = strat;
        this.creativesVideo = creatives.filter((x) => x.creativeType == SbCreativeType.video);
        this.creativesBrandVideo = creatives.filter((x) => x.creativeType == SbCreativeType.brandVideo);
        this.creativesProduct = creatives.filter((x) => x.creativeType == SbCreativeType.productCollection);
        this.creativesSpotlight = creatives.filter((x) => x.creativeType == SbCreativeType.storeSpotlight);

        this.allCreatives = this.creativesProduct
          .concat(this.creativesVideo)
          .concat(this.creativesBrandVideo)
          .concat(this.creativesSpotlight);
        this.allCreativesFiltered = this.allCreatives;

        if (!this.strategy.brandEntityId) {
          // old Sb strategies have no brandEntityId at strategy level
          // try to retrieve it from creatives when all creatives have the same brandEntityId
          // to prevent client to mix several brandEntityIds in the same strategy (not compatible with new sb structure)
          const brandEntityIds = new Set(creatives.map((x) => x.brandEntityId).filter((x) => !!x));
          if (brandEntityIds.size == 1) {
            this.strategy.brandEntityId = [...brandEntityIds][0]!;
          }
        }

        this.creativesWithoutCustomImage = this.creativesProduct.filter((c) => !c.customImageAssetId).length;
      });
    this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.accountType = am.accountType;
    });

    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
  }

  toggleTargetingStatsVisibility() {
    this.targetingStatsVisible = !this.targetingStatsVisible;
  }

  newCreative() {
    if (!this.canCreateNewCreative()) return;

    const modalOptions: ModalOptions = {
      initialState: {
        strategy: this.strategy,
        formMode: FormMode.NEW_CREATIVE,
      },
      class: "modal-dialog-centered big-modal modal-limit",
      keyboard: false,
      backdrop: "static",
    };
    this.modalService.show(SbStrategyFormComponent, modalOptions);
  }

  creativesLimitSoonReached() {
    const creativesCount = this.creativeCount();
    return (
      creativesCount >= Constant.maxCreativesPerStrategy * 0.8 && creativesCount < Constant.maxCreativesPerStrategy
    );
  }

  creativeCount() {
    return (
      this.creativesVideo.length +
      this.creativesProduct.length +
      this.creativesSpotlight.length +
      this.creativesBrandVideo.length
    );
  }

  creativesLimitReached() {
    return this.creativeCount() >= Constant.maxCreativesPerStrategy;
  }

  canCreateNewCreative(): boolean {
    return !this.creativesLimitReached();
  }

  getAsins(strategyAsin: StrategyAsin): string {
    return strategyAsin.asin;
  }

  applyCreativeTypeFilter(values: Option<SbCreativeType>[]) {
    this.selectedCreativeTypes.set(values);
    if (!values?.length) this.allCreativesFiltered = this.allCreatives;
    else this.allCreativesFiltered = this.allCreatives.filter((c) => values.some((v) => v.value === c.creativeType));
  }

  protected readonly SbCreativeType = SbCreativeType;
  protected readonly CampaignType = CampaignType;
}
