<ng-container *transloco="let t">
  <app-strategy-overlay>
    <app-strategy-page
      [campaignType]="CampaignType.SD"
      [strategy$]="strategy$"
      [withTacticSection]="false"
      #appStrategyPage
    >
      <span withSdAsins>
        @if (strategy) {
          <app-low-inventory-warning
            [accountId]="strategy.accountId"
            [marketplace]="strategy.marketplace"
            [asins]="strategy.asins | map: getAsins"
          ></app-low-inventory-warning>
        }
        @if (strategy && ineligibleAsins.length > 0) {
          <div class="alert alert-warning" role="alert">
            <div (click)="toggleIneligibleAsinDetails()">
              <span class="icon-prepend float-left mr-2">
                <app-dropdown-toggle-icon [expand]="ineligibleAsinsCollapsed"></app-dropdown-toggle-icon>
              </span>
              {{ t("sd-page.ineligible_asins", { count: ineligibleAsins.length }) }}
            </div>
            <IAccordion [isOpen]="!!ineligibleAsinsCollapsed">
              {{ ineligibleAsins.length > 1 ? t("common.asins") : t("common.asin") }}:
              {{ ineligibleAsins.slice(0, 20).join(", ") }}{{ ineligibleAsins.length > 20 ? ", …" : "" }}
            </IAccordion>
          </div>
        }
        @if (strategy) {
          <div class="pg card-perso collapse-card left-child right-child card my-0">
            <div
              class="pg card-perso-header collapse-card-header top-child card-header"
              (click)="toggleAsinsVisibility()"
            >
              <span class="icon-prepend float-left">
                <app-dropdown-toggle-icon [expand]="asinsVisible"></app-dropdown-toggle-icon>
              </span>
              <h5 class="card-name mb-0">
                {{ t("sd-page.product_asin", { count: asinsList.length }) }}
                {{ brands.size === 0 ? ")" : "" }}
                <app-limit-warning
                  [current]="asinsList ? asinsList.length : 0"
                  [limit]="appStrategyPage.MaxAsins"
                  [message]="t('sd-page.too_many_asins', [appStrategyPage.MaxAsins])"
                >
                </app-limit-warning>
                @if (brands.size > 0) {
                  <span [matTooltip]="getBrands()"> - {{ t("sd-page.num_brand", { count: brands.size }) }}</span>
                }
              </h5>
            </div>
            <IAccordion [isOpen]="!!asinsVisible" [animated]="false">
              <div class="card-perso-body bottom-child card-body">
                <div class="max-h-[35rem]">
                  <app-strategy-asins
                    [asins]="asinsList"
                    [accountId]="strategy.accountId"
                    [marketplace]="strategy.marketplace"
                    [selectable]="!isReadOnly && !strategy.defaultStrategy"
                    [deletable]="!isReadOnly && !strategy.defaultStrategy"
                    [movable]="!isReadOnly && !strategy.defaultStrategy"
                    [allowGroupByParentAsin]="false"
                    [asinEligibility]="asinEligibility"
                    [csvExportFileName]="appStrategyPage.getFileName()"
                    (onDelete)="deleteStrategyAsins($event)"
                    (move)="moveStrategyAsins($event)"
                  >
                    @if (!strategy.defaultStrategy) {
                      <span noAsin class="float-right">
                        <button
                          class="btn btn-primary"
                          (click)="toggleProductAdd()"
                          [disabled]="isReadOnly"
                          data-testid="btn-add-products"
                        >
                          <fa-icon [icon]="faPlus" class="mr-2"></fa-icon>
                          {{ t("sd-page.add_products") }}
                        </button>
                      </span>
                    }
                    @if (!strategy.defaultStrategy) {
                      <span withAsins class="float-right">
                        <button
                          class="btn btn-primary"
                          (click)="toggleProductAdd()"
                          [disabled]="isReadOnly"
                          data-testid="btn-add-products"
                        >
                          <fa-icon [icon]="faPlus" class="mr-2"></fa-icon>
                          {{ t("sd-page.add_products") }}
                        </button>
                      </span>
                    }
                  </app-strategy-asins>
                </div>
              </div>
            </IAccordion>
          </div>
        }
      </span>

      <span withSdTargetings>
        @if (strategy) {
          <div class="pg card-perso collapse-card left-child right-child card my-0">
            <div
              class="pg card-perso-header collapse-card-header top-child card-header"
              (click)="targetingsVisible = !targetingsVisible"
            >
              <span class="icon-prepend float-left">
                <app-dropdown-toggle-icon [expand]="targetingsVisible"></app-dropdown-toggle-icon>
              </span>
              <h5 class="card-name mb-0">{{ t("sd-strategy-creation.targetings") }}</h5>
            </div>
            <IAccordion [isOpen]="!!targetingsVisible" [animated]="false">
              <div class="card-perso-body bottom-child card-body">
                @if (!strategy.disableOtherQueries) {
                  <app-sd-targeting
                    [defaultTactic]="true"
                    [strategy]="strategy"
                    [isReadOnly]="isReadOnly"
                    [isLastTargeting]="isLastTargeting"
                    [organizationId]="organizationId"
                  ></app-sd-targeting>
                }
                @for (tactic of filterBlacklist(strategy.tactics, true); track tactic) {
                  <app-sd-targeting
                    [tactic]="tactic"
                    [strategy]="strategy"
                    [isReadOnly]="isReadOnly"
                    [isLastTargeting]="isLastTargeting"
                    [organizationId]="organizationId"
                  ></app-sd-targeting>
                }
                @for (tactic of filterBlacklist(strategy.tactics, false); track tactic) {
                  <app-sd-targeting
                    [tactic]="tactic"
                    [strategy]="strategy"
                    [isReadOnly]="isReadOnly"
                    [isLastTargeting]="isLastTargeting"
                    [organizationId]="organizationId"
                  ></app-sd-targeting>
                }
                @for (audience of strategy.audienceTargetings; track audience) {
                  <app-sd-targeting
                    [audience]="audience"
                    [strategy]="strategy"
                    [isReadOnly]="isReadOnly"
                    [isLastTargeting]="isLastTargeting"
                    [organizationId]="organizationId"
                  ></app-sd-targeting>
                }
                @if (targetingsVisible) {
                  <IButton
                    color="white"
                    label="{{ t('sd-page.add_a_targeting') }}"
                    [icon]="ICON_ADD"
                    [disabled]="isReadOnly"
                    (onClick)="createTargeting()"
                  />
                }
              </div>
            </IAccordion>
          </div>
        }
      </span>
    </app-strategy-page>
  </app-strategy-overlay>

  <ng-template #productAdd>
    <div class="modal-header">
      <h5 class="modal-title">{{ t("sd-page.add_products") }}</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="productModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body h-[50vh]">
      <app-asins-selection
        [asins]="asinsList"
        [accountId]="strategy!.accountId"
        [marketplace]="strategy!.marketplace"
        [csvExportFileName]="appStrategyPage.getFileName()"
        (onAdd)="addStrategyAsins($event)"
        (onDelete)="deleteStrategyAsins($event)"
      />
    </div>
  </ng-template>
</ng-container>
