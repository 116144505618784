// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    AccountMarketplace,
    Customer,
    Response,
} from '../models';

export interface CreateCustomerRequest {
    organizationId: number;
    customer: Customer;
}

export interface LinkCreditCardRequest {
    token: string;
    customer: Customer;
}

export interface RemoveCreditCardRequest {
    token: string;
    customer: Customer;
}

export interface ResourcesWithBidderOffRequest {
    organizationId: number;
}

export interface UpdateCustomerRequest {
    customer: Customer;
    updateVat?: boolean;
}

/**
 * no description
 */
export class CustomerApi extends BaseAPI {

    /**
     * Create a Customer
     * Create a Customer
     */
    createCustomer({ organizationId, customer }: CreateCustomerRequest): Observable<Response>
    createCustomer({ organizationId, customer }: CreateCustomerRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    createCustomer({ organizationId, customer }: CreateCustomerRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'createCustomer');
        throwIfNullOrUndefined(customer, 'customer', 'createCustomer');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'organizationId': organizationId,
        };

        return this.request<Response>({
            url: '/billing/customer',
            method: 'POST',
            headers,
            query,
            body: customer,
        }, opts?.responseOpts);
    };

    /**
     * add a credit card to a Customer
     * add a credit card to a Customer
     */
    linkCreditCard({ token, customer }: LinkCreditCardRequest): Observable<Response>
    linkCreditCard({ token, customer }: LinkCreditCardRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    linkCreditCard({ token, customer }: LinkCreditCardRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(token, 'token', 'linkCreditCard');
        throwIfNullOrUndefined(customer, 'customer', 'linkCreditCard');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'token': token,
        };

        return this.request<Response>({
            url: '/billing/creditCard',
            method: 'PUT',
            headers,
            query,
            body: customer,
        }, opts?.responseOpts);
    };

    /**
     * List the customers available through the current token.
     * List available customers
     */
    listCustomers(): Observable<Array<Customer>>
    listCustomers(opts?: OperationOpts): Observable<AjaxResponse<Array<Customer>>>
    listCustomers(opts?: OperationOpts): Observable<Array<Customer> | AjaxResponse<Array<Customer>>> {
        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<Customer>>({
            url: '/billing/customer',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * remove a credit card from a Customer
     */
    removeCreditCard({ token, customer }: RemoveCreditCardRequest): Observable<Response>
    removeCreditCard({ token, customer }: RemoveCreditCardRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    removeCreditCard({ token, customer }: RemoveCreditCardRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(token, 'token', 'removeCreditCard');
        throwIfNullOrUndefined(customer, 'customer', 'removeCreditCard');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'token': token,
        };

        return this.request<Response>({
            url: '/billing/creditCard',
            method: 'POST',
            headers,
            query,
            body: customer,
        }, opts?.responseOpts);
    };

    /**
     * list of available resources
     * resources with bidder not yet activated by current or another organization
     */
    resourcesWithBidderOff({ organizationId }: ResourcesWithBidderOffRequest): Observable<Array<AccountMarketplace>>
    resourcesWithBidderOff({ organizationId }: ResourcesWithBidderOffRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AccountMarketplace>>>
    resourcesWithBidderOff({ organizationId }: ResourcesWithBidderOffRequest, opts?: OperationOpts): Observable<Array<AccountMarketplace> | AjaxResponse<Array<AccountMarketplace>>> {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'resourcesWithBidderOff');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'organizationId': organizationId,
        };

        return this.request<Array<AccountMarketplace>>({
            url: '/billing/resourcesWithBidderOff',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Update a Customer
     * Update a Customer
     */
    updateCustomer({ customer, updateVat }: UpdateCustomerRequest): Observable<Response>
    updateCustomer({ customer, updateVat }: UpdateCustomerRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateCustomer({ customer, updateVat }: UpdateCustomerRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(customer, 'customer', 'updateCustomer');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (updateVat != null) { query['updateVat'] = updateVat; }

        return this.request<Response>({
            url: '/billing/customer',
            method: 'PUT',
            headers,
            query,
            body: customer,
        }, opts?.responseOpts);
    };

}
