<ng-container *transloco="let t">
  <div class="flex flex-col gap-5">
    <div class="my-5 flex items-center gap-2">
      <div class="inline-flex items-center gap-2">
        <IToggle
          [checked]="displayEventAnnotation()"
          (checkedChange)="displayEventAnnotation.set(!displayEventAnnotation())"
        />
        <span>{{ t("dashboard.activity_events") }}</span>
      </div>
      @if (displayEventAnnotation()) {
        <activity-event-filters
          [displayEventAnnotation]="true"
          [allUsers]="allUsers()"
          [allEventAnnotationTypes]="allEventAnnotationTypes"
        />
      }
    </div>
  </div>
  <app-metric-selector
    [data]="totalStats()"
    [previousPeriodData]="previousTotalStats()"
    [localStorageKey]="localStorageKey"
    [pageMetrics]="METRICS"
    (chartMetricsChanges)="selectMetric($event)"
  />

  @if (builtDataSet(); as ds) {
    <div>
      <canvas
        baseChart
        class="w-full"
        [datasets]="ds.chartDataSet"
        type="line"
        [labels]="ds.labels"
        [options]="ds.lineChartOptions"
      >
      </canvas>
    </div>
  }

  <div class="ag-theme-quartz h-[30rem] w-full">
    <ag-grid-angular [gridOptions]="gridOptions()" [rowData]="rowStats()" class="h-full w-full" />
  </div>
</ng-container>
