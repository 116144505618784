import { Component, computed, EventEmitter, input, Output } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'IToggle',
  standalone: true,
  imports: [NgClass],
  templateUrl: './itoggle.component.html',
})
export class IToggleComponent {
  checked = input<boolean | undefined>();
  size = input<'sm' | 'md' | 'lg'>('md');
  disabled = input<boolean>(false);

  @Output() checkedChange = new EventEmitter<boolean | undefined>();

  checkedValue = computed(() => !!this.checked());

  containerClass = computed(() => {
    return this.checkedValue() ? 'bg-main-500 dark:bg-main-400' : 'bg-gray-200 dark:bg-gray-700';
  });

  innerClass = computed(() => {
    let translateFactor = 0;
    switch (this.size()) {
      case 'sm':
        translateFactor = 4;
        break;
      case 'md':
        translateFactor = 5;
        break;
      case 'lg':
        translateFactor = 6;
        break;
    }

    return this.checkedValue() ? 'translate-x-' + translateFactor : 'translate-x-0';
  });

  check() {
    this.checkedChange.emit(!this.checkedValue());
  }
}
