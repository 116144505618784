import { Component, computed, DestroyRef, inject, Signal, signal } from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountMarketplace, AccountType, AmazonUser } from "@front/m19-api-client";
import { Marketplaces } from "@front/m19-models";
import { AccountMarketplaceService, AmazonUserService, AuthService } from "@front/m19-services";
import { IAlertComponent, IButtonComponent } from "@front/m19-ui";
import { TranslocoDirective } from "@jsverse/transloco";
import { LayoutSelectorService } from "@m19-board/layout-selector.service";
import {
  AccountSelectionList,
  SelectAccountsPageComponent,
} from "@m19-board/onboarding/select-accounts-page.component";
import { OnboardingService, OnboardingStep, OnBoardingStepRedirects } from "@m19-board/services/onboarding.service";
import { LogoComponent } from "@m19-board/shared/logo/logo.component";
import { combineLatest, filter, forkJoin, map, Observable, Subscription, switchMap, take, tap } from "rxjs";

@Component({
  template: `<ng-container *transloco="let t">
    <div class="mx-auto mt-16 h-[760px] w-[1230px] overflow-hidden rounded-3xl border border-slate-100">
      <div class="flex h-full w-full">
        <div class="flex h-full flex-1 flex-col gap-4 px-20 pt-10">
          <div>
            <div class="mb-10 flex w-full items-center justify-center">
              <div class="flex h-[60px] items-center justify-center rounded-sm border border-slate-100 px-1">
                <logo></logo>
              </div>
              <img src="assets/img/onboarding/amz_link.svg" alt="" />
            </div>
            <div>
              <div class="text-xl font-semibold text-gray-700">{{ t("whitelabel-noaccount.title") }}</div>
              <div class="mt-2 text-sm text-gray-500">
                {{ t("whitelabel-noaccount.subtitle", { agency }) }}
              </div>
            </div>
          </div>
        </div>

        <div class="relative flex h-full flex-1 items-center justify-center bg-slate-50">
          <div
            class="absolute bottom-0 left-0 z-10 h-[200px] w-full bg-[linear-gradient(rgba(244,_245,_246,_0)_0%,_rgb(244,_245,_246)_100%)]"
          ></div>
          <div class="translate-x-[15%] translate-y-[26%] scale-125">
            <img src="assets/img/onboarding/board_illustration.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </ng-container>`,
  standalone: true,
  imports: [LogoComponent, TranslocoDirective],
})
export class WhitelabelNoAccountPageComponent {
  private readonly layoutService = inject(LayoutSelectorService);
  private readonly onboardingService = inject(OnboardingService);
  private readonly router = inject(Router);
  private readonly destroyRef = inject(DestroyRef);
  readonly agency = this.layoutService.getAgencyName();

  constructor() {
    this.onboardingService.currentStep$
      .pipe(
        filter((step) => step !== OnboardingStep.WhitelabelNoAccount),
        takeUntilDestroyed(this.destroyRef),
        take(1),
      )
      .subscribe((step) => {
        this.router.navigate([OnBoardingStepRedirects[step]]);
      });
  }
}
