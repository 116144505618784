<ng-container *transloco="let t">
  <div class="modal-header bg-main-500">
    <h4 class="modal-title pull-left">{{ t("invoice-details-modal.invoice_details") }}</h4>
  </div>
  <div class="modal-body m-2">
    <div class="flex justify-end"></div>
    <div class="table-container">
      <mat-table [dataSource]="dataSource" class="table-sm table-striped-two table-hover border-shadow mt-4 table">
        <ng-container matColumnDef="account">
          <mat-header-cell *matHeaderCellDef>{{ t("common.account") }}</mat-header-cell>
          <mat-cell *matCellDef="let item">
            {{ item.accountName && item.accountName.length > 0 ? item.accountName : item.accountId }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="marketplace">
          <mat-header-cell *matHeaderCellDef class="table-header justify-center text-center">
            {{ t("common.marketplace") }}
          </mat-header-cell>
          <mat-cell *matCellDef="let item" class="justify-center">
            <button class="btn btn-primary btn-sm">{{ item.marketplace }}</button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="adSpendFee">
          <mat-header-cell *matHeaderCellDef class="table-header justify-end pr-0 text-center">
            <span class="w-full">{{ t("invoice-details-modal.ad_spend_fee") }}</span>
            <button class="btn" (click)="downloadFile()" matTooltip="{{ t('common.export_as_csv') }}">
              <fa-icon [icon]="faDownload"></fa-icon>
            </button>
          </mat-header-cell>
          <mat-cell *matCellDef="let item" class="justify-center">{{ formatAmount(item.adSpendFee) }}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 50, 100]" [pageSize]="5" showFirstLastButtons></mat-paginator>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="close()">{{ t("common.close") }}</button>
  </div>
</ng-container>
