import { Pipe, PipeTransform } from "@angular/core";
import { InventoryRules, InventoryStats, RuleExecutionResult } from "@front/m19-models";
import { InventoryRule } from "@front/m19-api-client";

@Pipe({ name: "asinInelligilibleForInventoryRule" })
export class AsinInelligilibleForInventoryRulePipe implements PipeTransform {
  transform(stats: InventoryStats): boolean {
    return stats.orders30d < InventoryRules.ORDERS30D_ELLIGIBILITY_THRESHOLD;
  }
}

@Pipe({ name: "asinHasInventoryRule" })
export class AsinHasInventoryRulePipe implements PipeTransform {
  transform(asin: string, inventoryRules: InventoryRules): InventoryRule {
    return inventoryRules.asinInventoryRule.get(asin);
  }
}

@Pipe({ name: "executeRules" })
export class ExecuteRulePipe implements PipeTransform {
  transform(stats: InventoryStats, inventoryRules: InventoryRules): RuleExecutionResult {
    return inventoryRules.execute(stats.asin, stats);
  }
}
