import { state } from '@angular/animations';
import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'IProgressBar',
  standalone: true,
  imports: [NgClass],
  templateUrl: './iprogressbar.component.html',
})
export class IProgressBarComponent {
  _progress: number = 0;
  @Input({ required: true }) set progress(progress: number) {
    progress = progress > 1 ? 1 : progress;
    this._progress = progress * 100;
  }

  @Input()
  color: 'main' | 'red' | 'orange' | 'gray' | 'black' | 'green' | 'purple' = 'main';
  protected readonly state = state;
}
