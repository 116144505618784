import { DspStats } from '@front/m19-api-client';

export function emptyDspStats(): DspStats {
  return {
    impressions: 0,
    clicks: 0,
    totalCost: 0,
    supplyCost: 0,
    totalFee: 0,
    unitsSold: 0,
    sales: 0,
    purchaseClicks: 0,
    purchases: 0,
    _3PFees: 0,
    addToCart: 0,
    pageViews: 0,
    newToBrandProductSales: 0,
  };
}

function sum(a?: number, b?: number): number {
  return a ? (b ? a + b : a) : (b as number);
}

export function sumDspStats(stats1: DspStats, stats2: DspStats): DspStats {
  const res = emptyDspStats();
  res.currency = stats1.currency;
  res.impressions = sum(stats1.impressions, stats2.impressions);
  res.clicks = sum(stats1.clicks, stats2.clicks);
  res.totalCost = sum(stats1.totalCost, stats2.totalCost);
  res.unitsSold = sum(stats1.unitsSold, stats2.unitsSold);
  res.sales = sum(stats1.sales, stats2.sales);
  res.totalFee = sum(stats1.totalFee, stats2.totalFee);
  res.purchaseClicks = sum(stats1.purchaseClicks, stats2.purchaseClicks);
  res.purchases = sum(stats1.purchases, stats2.purchases);
  res.addToCart = sum(stats1.addToCart, stats2.addToCart);
  res.pageViews = sum(stats1.pageViews, stats2.pageViews);
  res.newToBrandProductSales = sum(stats1.newToBrandProductSales, stats2.newToBrandProductSales);
  res._3PFees = sum(stats1._3PFees, stats2._3PFees);
  res.supplyCost = sum(stats1.supplyCost, stats2.supplyCost);

  return res;
}

export function isEmptyDspStats(stats: DspStats): boolean {
  return (
    (stats.impressions ?? 0) == 0 &&
    (stats.clicks ?? 0) == 0 &&
    (stats.totalCost ?? 0) == 0 &&
    (stats.supplyCost ?? 0) == 0 &&
    (stats.totalFee ?? 0) == 0 &&
    (stats.unitsSold ?? 0) == 0 &&
    (stats.sales ?? 0) == 0 &&
    (stats.purchaseClicks ?? 0) == 0 &&
    (stats.purchases ?? 0) == 0 &&
    (stats._3PFees ?? 0) == 0 &&
    (stats.addToCart ?? 0) == 0 &&
    (stats.pageViews ?? 0) == 0 &&
    (stats.newToBrandProductSales ?? 0) == 0
  );
}
