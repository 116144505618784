import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { AccountMarketplace, CustomCampaignNameTokens } from "@front/m19-api-client";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { AccountMarketplaceService } from "@front/m19-services";

@Component({
  templateUrl: "./update-custom-campaign-name.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class UpdateCustomCampaignNameComponent implements OnInit {
  accountMarketplace: AccountMarketplace;
  customCampaignName: string;
  customCampaignNameHighlighted: string;
  CustomCampaignNameTokens = Object.values(CustomCampaignNameTokens);
  canEdit: boolean;
  edit: boolean;

  @ViewChild("customCampaignNameElement") inputElement: ElementRef;

  constructor(
    public bsModalRef: BsModalRef,
    private accountMarketplaceService: AccountMarketplaceService,
    private toasterService: ToastrService,
  ) {}

  ngOnInit() {
    this.customCampaignName = this.accountMarketplace.customCampaignName;
    this.customCampaignNameHighlighted = this.accountMarketplace.customCampaignName;
    this.canEdit = this.accountMarketplace.customCampaignName != null;
  }

  renderCustomCampaignName() {
    this.customCampaignNameHighlighted = this.customCampaignName;
    this.edit = false;
  }

  setEditCustomCampaignName() {
    this.edit = true;
    setTimeout(() => this.inputElement?.nativeElement?.focus(), 10);
  }

  addTokenToInput(token: string): void {
    this.customCampaignName = this.customCampaignName ? this.customCampaignName + token : token;
    this.renderCustomCampaignName();
  }

  validPattern() {
    return !this.canEdit || (this.customCampaignName && this.customCampaignName.trim());
  }

  update() {
    const newCustomCampaignName = this.canEdit ? this.customCampaignName : "m19 default pattern";
    this.accountMarketplaceService.changeCustomCampaignName(
      this.accountMarketplace,
      newCustomCampaignName,
      () => {
        this.toasterService.success(
          "Custom Campaign Name successfully changed to " +
            newCustomCampaignName +
            " on " +
            this.accountMarketplace.accountName +
            ".",
          "Custom campaign name updated",
        );
      },
      (error) => this.toasterService.error(error, "Custom campaign name update error"),
    );
    this.bsModalRef.hide();
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
