<ng-container *transloco="let t">
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ t("switch-algo-mode.change_strategy_algorithm") }}</h4>
  </div>
  <div class="modal-body position-relative">
    <app-algo-mode-selection
      class="w-full"
      [selectedAlgoModeConfig]="selectedAlgoModeConfig"
      [suggestedAcosTarget]="suggestedAcosTarget"
      [today]="strategy.today"
      (algoModeConfig)="setAlgoModeConfig($event)"
      (valid)="setAlgoModeConfigValid($event)"
    />
    <div class="modal-footer mt-4 p-0">
      <IButton label="{{ t('common.cancel') }}" color="gray" variant="soft" (onClick)="bsModalRef.hide()" />
      <IButton
        testid="btn-confirm"
        class="w-2/4"
        label="{{ t('common.save') }}"
        [loading]="updateLoading"
        [disabled]="saveDisabled()"
        (onClick)="update()"
        [block]="true"
      />
    </div>
  </div>
</ng-container>
