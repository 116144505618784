import { Component } from "@angular/core";
import { ICellRendererParams } from "@ag-grid-community/core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { InventoryRules, InventoryStats } from "@front/m19-models";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { formatNumber } from "@angular/common";
import { filter, switchMap } from "rxjs";
import { AsinInventoryRuleModalComponent } from "../inventory-rules/asin-inventory-rules-modal.component";
import { AsinService } from "@front/m19-services";
import { ToastrService } from "ngx-toastr";
import { Marketplace } from "@front/m19-api-client";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { ICON_EDIT_O, ICON_WARNING_TRIANGLE } from "@m19-board/utils/iconsLabels";
import { IButtonComponent } from "@front/m19-ui";
import { MatTooltip } from "@angular/material/tooltip";
import { AsinInelligilibleForInventoryRulePipe } from "@m19-board/inventory/inventory-rules/inventory-rules.pipe";

@Component({
  selector: "app-pause-adv-renderer",
  templateUrl: "./pause-adv-renderer.component.html",
  standalone: true,
  imports: [TranslocoDirective, IButtonComponent, MatTooltip, AsinInelligilibleForInventoryRulePipe],
})
export class PauseAdvRendererComponent implements ICellRendererAngularComp {
  params: any;
  product!: InventoryStats;
  inventoryRules!: InventoryRules;
  locale: string = "US";
  accountId!: string;
  marketplace!: Marketplace;
  isReadOnly = false;
  ICON_EDIT_O = ICON_EDIT_O;
  label = "";

  constructor(
    private asinService: AsinService,
    private modalService: BsModalService,
    private toasterService: ToastrService,
    private translocoService: TranslocoService,
  ) {}

  agInit(
    params: ICellRendererParams & {
      product: InventoryStats;
      inventoryRules: InventoryRules;
      locale: string;
      accountId: string;
      marketplace: Marketplace;
      isReadOnly: boolean;
    },
  ): void {
    this.product = params.product;
    this.inventoryRules = params.inventoryRules;
    this.locale = params.locale;
    this.accountId = params.accountId;
    this.marketplace = params.marketplace;
    this.isReadOnly = params.isReadOnly;
    this.label =
      this.inventoryRules.asinInventoryRule.get(this.product.asin)?.advertisingPauseThreshold == undefined
        ? this.translocoService.translate("common.none")
        : "< " +
          this.inventoryRules.asinInventoryRule.get(this.product.asin)?.advertisingPauseThreshold +
          " " +
          this.translocoService.translate("pause-adv-renderer.days_of_stock");
  }

  refresh(
    params: ICellRendererParams & {
      product: InventoryStats;
      inventoryRules: InventoryRules;
      locale: string;
      accountId: string;
      marketplace: Marketplace;
      isReadOnly: boolean;
    },
  ): boolean {
    this.product = params.product;
    this.inventoryRules = params.inventoryRules;
    this.locale = params.locale;
    this.accountId = params.accountId;
    this.marketplace = params.marketplace;
    this.isReadOnly = params.isReadOnly;
    return true;
  }

  formatNumber(value: number) {
    return formatNumber(value, this.locale, "1.0-0");
  }

  setupRuleOnAsin(product: InventoryStats): void {
    const rule = this.inventoryRules.asinInventoryRule.get(product.asin);
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.accountId,
        marketplace: this.marketplace,
        asin: product.asin,
        rule: rule,
        warningMessage:
          product.orders30d && product.orders30d < InventoryRules.ORDERS30D_ELLIGIBILITY_THRESHOLD
            ? this.translocoService.translate("inventory-table.pause_threshold_tooltip")
            : undefined,
      },
    };
    const modalRef = this.modalService.show(AsinInventoryRuleModalComponent, modalOptions);

    const subscription = modalRef.content?.saveRule
      .pipe(
        filter(
          (newRule) =>
            !rule ||
            rule.advertisingPauseThreshold != newRule.advertisingPauseThreshold ||
            rule.activateAdvertisingWhenInbound != newRule.activateAdvertisingWhenInbound,
        ),
        switchMap((newRule) => this.asinService.setAsinInventoryRules(newRule)),
      )
      .subscribe({
        next: () => {
          this.toasterService.success(
            this.translocoService.translate("pause-adv-renderer.advertising_pausing_rule_setup_for_asin_product_asin", [
              product.asin,
            ]),
            this.translocoService.translate("pause-adv-renderer.inventory_rule_updated"),
          );
          subscription?.unsubscribe();
        },
        error: (error) => {
          this.toasterService.error(
            this.translocoService.translate("pause-adv-renderer.error_setting_rule", [product.asin, error]),
            this.translocoService.translate("pause-adv-renderer.inventory_rule_update_error"),
          );
          subscription?.unsubscribe();
        },
      });
    if (rule) {
      subscription?.add(
        modalRef.content?.deleteRule
          .pipe(
            switchMap(() => this.asinService.deleteAsinInventoryRules(this.accountId, this.marketplace, product.asin)),
          )
          .subscribe(
            () => {
              this.toasterService.success(
                this.translocoService.translate(
                  "pause-adv-renderer.advertising_pausing_rule_removed_for_asin_product_asin",
                  [product.asin],
                ),
                this.translocoService.translate("pause-adv-renderer.inventory_rule_deleted"),
              );
              subscription.unsubscribe();
            },
            (error) => {
              this.toasterService.error(
                this.translocoService.translate("pause-adv-renderer.error_deleting_rule", [product.asin, error]),
                this.translocoService.translate("pause-adv-renderer.inventory_rule_deletion_error"),
              );
              subscription.unsubscribe();
            },
          ),
      );
    }
  }

  protected readonly ICON_WARNING_TRIANGLE = ICON_WARNING_TRIANGLE;
}
