import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { PALETTE } from "@m19-board/models/Metric";

export interface SelectableMetric {
  title: string;
  color: string;
}

export interface SelectedMetrics<T extends SelectableMetric> {
  metric1: T;
  metric2: T;
}

@UntilDestroy()
@Component({
  selector: "app-column-metric-selector",
  templateUrl: "./column-metric-selector.component.html",
  styleUrls: ["./column-metric-selector.component.scss"],
})
export class ColumnMetricSelectorComponent<T extends SelectableMetric> {
  @Input()
  availableMetrics: T[];

  @Input()
  set selection(value: SelectedMetrics<T>) {
    this.metric1 = value.metric1;
    this.metric2 = value.metric2;
  }

  @Output()
  selected = new EventEmitter<SelectedMetrics<T>>();

  readonly palette = PALETTE;

  private metric1: T;
  private metric2: T;

  selectMetric(metric: T) {
    if (this.metric1 == metric) {
      if (this.metric2) {
        this.metric1 = this.metric2;
        this.metric2 = undefined;
      }
    } else if (this.metric2 == metric) {
      this.metric2 = this.metric1;
      this.metric1 = metric;
    } else if (this.metric2 == undefined) {
      this.metric2 = this.metric1;
      this.metric1 = metric;
    } else {
      this.metric2 = this.metric1;
      this.metric1 = metric;
    }
    this.selected.emit({ metric1: this.metric1, metric2: this.metric2 });
  }

  isSelected(metric: T) {
    return metric == this.metric1 || metric == this.metric2;
  }
}
