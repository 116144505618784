<app-keyword-ranking-overlay pageName="keyword tracker" feature="tracking keywords" *transloco="let t">
  <div class="main-wrapper ml-1 flex flex-col" *transloco="let t">
    <div>
      <div class="mt-2 flex w-full items-center justify-between">
        <div class="flex min-w-0 flex-1 items-center">
          <input
            type="text"
            class="simple-input form-control w-1/4"
            placeholder="{{ t('keyword-tracker-page.search_search_term') }}"
            [(ngModel)]="filter"
            (input)="changeFilter()"
          />
          <IMultiSelect
            class="ml-3 min-w-0 max-w-xl"
            [options]="segmentOptions"
            [selected]="selectedSegments()"
            (selectedChange)="setSegmentsFilter($event)"
            searchable
            by="segmentId"
            [searchAttributes]="['label', 'segmentId']"
            placeholder="{{ t('keyword-tracker-page.keyword_segment') }}"
            rounded
          >
            <ng-template #labelSlot>
              <span class="truncate">
                <span class="font-medium text-gray-500">{{ t("keyword-tracker-page.keyword_segment") }}</span>
                {{
                  selectedSegments()[0].label +
                    (selectedSegments().length > 1 ? " (+" + (selectedSegments().length - 1) + ")" : "")
                }}
              </span>
            </ng-template>
          </IMultiSelect>
        </div>

        <div class="flex items-center gap-2">
          <IButton
            [disabled]="isReadOnly"
            [icon]="ICON_ADD"
            (onClick)="addNewKeyword()"
            color="main"
            label="{{ t('add-keyword-tracking-modal.track_new_keyword_title') }}"
          ></IButton>
          <app-export-button
            (export)="exportAsCsv()"
            tooltipValue="{{ t('common.export_as_csv') }}"
          ></app-export-button>
        </div>
      </div>
    </div>

    @if (!loading && datasource.data && datasource.data.length === 0) {
      <div class="alert alert-info mt-4">{{ t("keyword-tracker-page.no_search_tracked") }}</div>
    }
    @if (datasource.data && datasource.data.length > 0) {
      <div class="mt-4 min-h-0 flex-1 overflow-auto">
        <table class="keyword-table w-full" matSort (matSortChange)="sortData($event)">
          <thead>
            <tr>
              <th mat-sort-header="searchTerm">{{ t("keyword-tracker-page.search_term") }}</th>
              <th mat-sort-header="sfr">{{ t("search-term-ranks.search_frequency_rank") }}</th>
              <th
                mat-sort-header="top10"
                matTooltip="{{ t('keyword-tracker-page.top10_tooltip') }}"
                matTooltipPosition="above"
              >
                {{ t("keyword-tracker-page.in_top10") }}
              </th>
              <th mat-sort-header="top25">{{ t("keyword-tracker-page.top25") }}</th>
              <th>
                <div [style.opacity]="bulkDeleteMode ? 1 : 0" class="flex items-center justify-end">
                  <ICheckbox [value]="isAllSelected" (valueChange)="selectAllToDelete()" />

                  <IButton
                    class="ml-4"
                    [icon]="ICON_TRASH"
                    color="red"
                    tooltipValue="{{ t('common.delete') }}"
                    (onClick)="handleBulkDelete()"
                  />
                  <IButton
                    class="ml-2"
                    color="gray"
                    variant="ghost"
                    label="{{ t('common.cancel') }}"
                    (onClick)="cancelBulkDelete()"
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            @for (d of data$ | async; track d) {
              <tr (click)="handleRowClick(d.searchTerm)" class="group">
                <td>{{ d.searchTerm }}</td>
                <td>{{ d.sfr | number: undefined : locale }}</td>
                <td>{{ d.top10 }}</td>
                <td>{{ d.top25 }}</td>
                <td class="flex justify-end pr-5">
                  <div class="inline-flex items-center">
                    <ICheckbox
                      [value]="searchTermToDelete.has(d.searchTerm)"
                      (valueChange)="toggleKeywordToDelete(d.searchTerm)"
                      (click)="$event.stopPropagation()"
                      class="mr-[1.6rem] opacity-0 group-hover:opacity-100"
                      [ngClass]="{ 'opacity-100': searchTermToDelete.has(d.searchTerm) }"
                    />

                    <div [style.opacity]="bulkDeleteMode ? '0' : '1'">
                      <IButton
                        (click)="$event.stopPropagation()"
                        [matMenuTriggerFor]="menu"
                        icon="icon-[mdi--ellipsis-vertical]"
                        color="gray"
                        variant="ghost"
                        [square]="true"
                        class="mr-3"
                      />
                      <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
                        <ul>
                          <li class="dd-item" (click)="toggleTrackingFrequency(d)">
                            {{ t("keyword-tracker-page.change_frequency") }}&nbsp;
                            <span class="font-bolder">{{
                              d.frequency === KeywordTrackingFrequency.daily ? t("common.hourly") : t("common.daily")
                            }}</span>
                          </li>
                          <li class="dd-item" (click)="stopTracking(d.searchTerm)">
                            {{ t("keyword-tracker-page.stop_tracking_search_term") }}
                          </li>
                        </ul>
                      </mat-menu>
                      <span
                        [class]="ICON_ARROW + ' text-xl text-gray-700 duration-75 group-hover:translate-x-2'"
                      ></span>
                    </div>
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    }

    <mat-paginator
      class="left-paginator bg-transparent"
      showFirstLastButtons
      [pageSize]="25"
      [pageSizeOptions]="[5, 10, 25]"
      aria-label="Select page"
      [hidden]="datasource.data && datasource.data.length === 0"
    ></mat-paginator>

    <app-spinner [display]="loading" [main]="true"></app-spinner>
  </div>
</app-keyword-ranking-overlay>
