// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    KeywordTrackerConfig,
    KeywordTrackerQuota,
    Marketplace,
    ProductTrackerConfig,
    Response,
    SearchResultValidation,
    SearchTermRankTimeline,
    ShareOfVoiceTimeline,
} from '../models';

export interface GetHourlyDailyTrackedAsinRequest {
    organizationId: number;
}

export interface GetKeywordTrackerConfigurationRequest {
    accountId: string;
    marketplace: Marketplace;
    organizationId: number;
}

export interface GetProductTrackerConfigurationRequest {
    accountId: string;
    marketplace: Marketplace;
}

export interface GetSOVTimelineRequest {
    accountId: string;
    marketplace: Marketplace;
    organizationId: number;
    searchTerm?: string;
}

export interface GetSearchTermRankTimelineRequest {
    marketplace: Marketplace;
    requestBody?: Array<string>;
}

export interface SetKeywordTrackerConfigurationRequest {
    accountId: string;
    marketplace: Marketplace;
    organizationId: number;
    action: SetKeywordTrackerConfigurationActionEnum;
    keywordTrackerConfig: Array<KeywordTrackerConfig>;
}

export interface SetProductTrackerConfigurationRequest {
    accountId: string;
    marketplace: Marketplace;
    action: SetProductTrackerConfigurationActionEnum;
    requestBody: Array<string>;
}

export interface UpdateScraperValidationStatusRequest {
    searchResultValidation: SearchResultValidation;
}

/**
 * no description
 */
export class KeywordTrackingApi extends BaseAPI {

    /**
     * Get the list of Account Marketplace with number of daily and hourly tracked asin
     */
    getHourlyDailyTrackedAsin({ organizationId }: GetHourlyDailyTrackedAsinRequest): Observable<Array<KeywordTrackerQuota>>
    getHourlyDailyTrackedAsin({ organizationId }: GetHourlyDailyTrackedAsinRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<KeywordTrackerQuota>>>
    getHourlyDailyTrackedAsin({ organizationId }: GetHourlyDailyTrackedAsinRequest, opts?: OperationOpts): Observable<Array<KeywordTrackerQuota> | AjaxResponse<Array<KeywordTrackerQuota>>> {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'getHourlyDailyTrackedAsin');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<KeywordTrackerQuota>>({
            url: '/keywordTrackerQuota/{organizationId}'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Get keyword tracker configuration for specific account, marketplace and organization id
     */
    getKeywordTrackerConfiguration({ accountId, marketplace, organizationId }: GetKeywordTrackerConfigurationRequest): Observable<Array<KeywordTrackerConfig>>
    getKeywordTrackerConfiguration({ accountId, marketplace, organizationId }: GetKeywordTrackerConfigurationRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<KeywordTrackerConfig>>>
    getKeywordTrackerConfiguration({ accountId, marketplace, organizationId }: GetKeywordTrackerConfigurationRequest, opts?: OperationOpts): Observable<Array<KeywordTrackerConfig> | AjaxResponse<Array<KeywordTrackerConfig>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getKeywordTrackerConfiguration');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getKeywordTrackerConfiguration');
        throwIfNullOrUndefined(organizationId, 'organizationId', 'getKeywordTrackerConfiguration');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<KeywordTrackerConfig>>({
            url: '/keywordTracker/{accountId}/{marketplace}/{organizationId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{organizationId}', encodeURI(organizationId)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Get product tracker configuration for specific account, marketplace and organization id
     */
    getProductTrackerConfiguration({ accountId, marketplace }: GetProductTrackerConfigurationRequest): Observable<Array<ProductTrackerConfig>>
    getProductTrackerConfiguration({ accountId, marketplace }: GetProductTrackerConfigurationRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<ProductTrackerConfig>>>
    getProductTrackerConfiguration({ accountId, marketplace }: GetProductTrackerConfigurationRequest, opts?: OperationOpts): Observable<Array<ProductTrackerConfig> | AjaxResponse<Array<ProductTrackerConfig>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getProductTrackerConfiguration');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getProductTrackerConfiguration');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<ProductTrackerConfig>>({
            url: '/productTracker/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getSOVTimeline({ accountId, marketplace, organizationId, searchTerm }: GetSOVTimelineRequest): Observable<Array<ShareOfVoiceTimeline>>
    getSOVTimeline({ accountId, marketplace, organizationId, searchTerm }: GetSOVTimelineRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<ShareOfVoiceTimeline>>>
    getSOVTimeline({ accountId, marketplace, organizationId, searchTerm }: GetSOVTimelineRequest, opts?: OperationOpts): Observable<Array<ShareOfVoiceTimeline> | AjaxResponse<Array<ShareOfVoiceTimeline>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getSOVTimeline');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getSOVTimeline');
        throwIfNullOrUndefined(organizationId, 'organizationId', 'getSOVTimeline');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (searchTerm != null) { query['searchTerm'] = searchTerm; }

        return this.request<Array<ShareOfVoiceTimeline>>({
            url: '/shareOfVoiceTimeline/{accountId}/{marketplace}/{organizationId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{organizationId}', encodeURI(organizationId)),
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    getSearchTermRankTimeline({ marketplace, requestBody }: GetSearchTermRankTimelineRequest): Observable<Array<SearchTermRankTimeline>>
    getSearchTermRankTimeline({ marketplace, requestBody }: GetSearchTermRankTimelineRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<SearchTermRankTimeline>>>
    getSearchTermRankTimeline({ marketplace, requestBody }: GetSearchTermRankTimelineRequest, opts?: OperationOpts): Observable<Array<SearchTermRankTimeline> | AjaxResponse<Array<SearchTermRankTimeline>>> {
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getSearchTermRankTimeline');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'marketplace': marketplace,
        };

        return this.request<Array<SearchTermRankTimeline>>({
            url: '/searchTermRankTimeline',
            method: 'POST',
            headers,
            query,
            body: requestBody,
        }, opts?.responseOpts);
    };

    /**
     * Get all search results to validate
     */
    listAllScraperValidations(): Observable<Array<SearchResultValidation>>
    listAllScraperValidations(opts?: OperationOpts): Observable<AjaxResponse<Array<SearchResultValidation>>>
    listAllScraperValidations(opts?: OperationOpts): Observable<Array<SearchResultValidation> | AjaxResponse<Array<SearchResultValidation>>> {
        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<SearchResultValidation>>({
            url: '/scraperValidation',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Add or delete keyword tracker configuraton
     */
    setKeywordTrackerConfiguration({ accountId, marketplace, organizationId, action, keywordTrackerConfig }: SetKeywordTrackerConfigurationRequest): Observable<Response>
    setKeywordTrackerConfiguration({ accountId, marketplace, organizationId, action, keywordTrackerConfig }: SetKeywordTrackerConfigurationRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    setKeywordTrackerConfiguration({ accountId, marketplace, organizationId, action, keywordTrackerConfig }: SetKeywordTrackerConfigurationRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'setKeywordTrackerConfiguration');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'setKeywordTrackerConfiguration');
        throwIfNullOrUndefined(organizationId, 'organizationId', 'setKeywordTrackerConfiguration');
        throwIfNullOrUndefined(action, 'action', 'setKeywordTrackerConfiguration');
        throwIfNullOrUndefined(keywordTrackerConfig, 'keywordTrackerConfig', 'setKeywordTrackerConfiguration');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'action': action,
        };

        return this.request<Response>({
            url: '/keywordTracker/{accountId}/{marketplace}/{organizationId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{organizationId}', encodeURI(organizationId)),
            method: 'POST',
            headers,
            query,
            body: keywordTrackerConfig,
        }, opts?.responseOpts);
    };

    /**
     * Add or delete product tracker configuraton
     */
    setProductTrackerConfiguration({ accountId, marketplace, action, requestBody }: SetProductTrackerConfigurationRequest): Observable<Response>
    setProductTrackerConfiguration({ accountId, marketplace, action, requestBody }: SetProductTrackerConfigurationRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    setProductTrackerConfiguration({ accountId, marketplace, action, requestBody }: SetProductTrackerConfigurationRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'setProductTrackerConfiguration');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'setProductTrackerConfiguration');
        throwIfNullOrUndefined(action, 'action', 'setProductTrackerConfiguration');
        throwIfNullOrUndefined(requestBody, 'requestBody', 'setProductTrackerConfiguration');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'action': action,
        };

        return this.request<Response>({
            url: '/productTracker/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'POST',
            headers,
            query,
            body: requestBody,
        }, opts?.responseOpts);
    };

    /**
     * Update validation status
     */
    updateScraperValidationStatus({ searchResultValidation }: UpdateScraperValidationStatusRequest): Observable<Response>
    updateScraperValidationStatus({ searchResultValidation }: UpdateScraperValidationStatusRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateScraperValidationStatus({ searchResultValidation }: UpdateScraperValidationStatusRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(searchResultValidation, 'searchResultValidation', 'updateScraperValidationStatus');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Response>({
            url: '/scraperValidation',
            method: 'PUT',
            headers,
            body: searchResultValidation,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum SetKeywordTrackerConfigurationActionEnum {
    ADD = 'ADD',
    DELETE = 'DELETE'
}
/**
 * @export
 * @enum {string}
 */
export enum SetProductTrackerConfigurationActionEnum {
    ADD = 'ADD',
    DELETE = 'DELETE'
}
