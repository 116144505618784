import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AccountMarketplace, AccountState, Plan } from "@front/m19-api-client";
import { AccountGroup, OrganizationAccountGroups } from "@front/m19-models";
import { AccountSelectionService, OrganizationAccountGroupService } from "@front/m19-services";
import { TranslocoDirective } from "@jsverse/transloco";
import { BoardType, LayoutSelectorService } from "@m19-board/layout-selector.service";
import { LinkYourAccountButtonComponent } from "@m19-board/settings/account-settings/account-group/link-your-account-button.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, combineLatest } from "rxjs";
import { filter } from "rxjs/operators";
import { OnboardingCtaComponent } from "../containers/board-layout/board-sidebar-v2/onboarding-cta.component";

export enum OverlayMessage {
  NONE = "None",
  NO_LINKED_ACCOUNTS = "NoLinkedAccounts",
  NO_LINKED_ACCOUNTS_WHITELABEL = "NoLinkedAccountsWhitelabel",
  BIDDER_OFF_WHITELABEL = "BidderOffWhitelabel",
  BIDDER_TO_ACTIVATE = "BidderToActivate",
  UPGRADE_YOURSELF = "UpgradeYourself",
  CONTACT_US_TO_UPGRADE = "ContactUsToUpgrade",
  ACTIVATE_PAIDPLAN = "ActivatePaidPlan",
  MANUAL_ACTIVATION_REQUIRED = "ManualActivationRequired",
}

export function computeOverlayMessageNoAccounts(boardType: BoardType): OverlayMessage {
  return boardType === BoardType.WHITELABEL
    ? OverlayMessage.NO_LINKED_ACCOUNTS_WHITELABEL
    : OverlayMessage.NO_LINKED_ACCOUNTS;
}

export function computeOverlayMessage(
  allOrgs: OrganizationAccountGroups[],
  accountGroup: AccountGroup,
  accountMarketplace: AccountMarketplace,
  boardType: BoardType,
): OverlayMessage {
  if (allOrgs.length == 0) {
    return computeOverlayMessageNoAccounts(boardType);
  }
  const selectedOrgId = accountGroup.organizationId;
  const selectedOrg = allOrgs.find((x) => x.id == selectedOrgId);
  const isBidderOn = selectedOrg?.getAccountState(accountMarketplace) == AccountState.BIDDER_ON;
  if (isBidderOn) {
    return OverlayMessage.NONE;
  }
  if (boardType !== BoardType.M19) {
    return OverlayMessage.BIDDER_OFF_WHITELABEL;
  }
  if (selectedOrg?.canActivateBidder(accountMarketplace)) {
    return OverlayMessage.BIDDER_TO_ACTIVATE;
  }
  if (!accountMarketplace.activated) {
    return OverlayMessage.MANUAL_ACTIVATION_REQUIRED;
  }
  if (!selectedOrg?.hasSubscription()) {
    return OverlayMessage.ACTIVATE_PAIDPLAN;
  }
  const plan = selectedOrg?.getBillingPlan()?.plan;
  if (selectedOrg?.hasActiveSubscription() && (plan == Plan.STARTER || plan == Plan.GROWTH)) {
    return OverlayMessage.UPGRADE_YOURSELF;
  }

  return OverlayMessage.CONTACT_US_TO_UPGRADE; // legacy and professional plans (or vendor accounts)
}

@UntilDestroy()
@Component({
  selector: "app-strategy-overlay",
  templateUrl: "./strategy-overlay.component.html",
  styleUrls: ["./overlay.component.scss"],
  standalone: true,
  imports: [LinkYourAccountButtonComponent, TranslocoDirective, OnboardingCtaComponent],
})
export class StrategyOverlayComponent implements OnInit {
  overlayMessage?: OverlayMessage;
  readonly OverlayMessage = OverlayMessage;

  constructor(
    private organizationService: OrganizationAccountGroupService,
    private accountSelectionService: AccountSelectionService,
    private layoutSelectorService: LayoutSelectorService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const allOrgs$ = new BehaviorSubject<OrganizationAccountGroups[]>([]);
    this.organizationService.allOrganizationAccountGroups$.pipe(untilDestroyed(this)).subscribe((allOrgs) => {
      if (allOrgs?.length === 0) {
        this.overlayMessage = computeOverlayMessageNoAccounts(this.layoutSelectorService.getBoardType());
        if (this.overlayMessage === OverlayMessage.NO_LINKED_ACCOUNTS) {
          this.router.navigate(["onboarding/account-linking"]);
        } else {
          this.router.navigate(["onboarding/agency-no-account"]);
        }
      } else {
        allOrgs$.next(allOrgs ?? []);
      }
    });

    combineLatest([
      allOrgs$,
      this.accountSelectionService.accountGroupSelection$,
      this.accountSelectionService.singleAccountMarketplaceSelection$,
    ])
      .pipe(
        untilDestroyed(this),
        filter(([_, accountGroup, accountMarketplace]) => !!accountGroup && !!accountMarketplace),
      )
      .subscribe(([allOrgs, accountGroup, accountMarketplace]) => {
        this.overlayMessage = computeOverlayMessage(
          allOrgs,
          accountGroup,
          accountMarketplace,
          this.layoutSelectorService.getBoardType(),
        );
      });
  }

  goToAccounts(): void {
    this.router.navigate(["accounts"]);
  }
}
