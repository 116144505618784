import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { TranslocoDirective } from "@jsverse/transloco";
import { LayoutSelectorService } from "@m19-board/layout-selector.service";

@Component({
  selector: "auth.layout",
  standalone: true,
  imports: [CommonModule, RouterOutlet, TranslocoDirective],
  templateUrl: "./auth-layout.component.html",
})
export class AuthLayoutComponent {
  private readonly layoutSelector = inject(LayoutSelectorService);

  readonly isWhitelabel = this.layoutSelector.isWhitelabel;
}
