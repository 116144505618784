<ng-container *transloco="let t">
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ t("move-strategy-asins-modal.move_asins_to_strategy") }}</h4>
  </div>
  <div class="modal-body">
    <p>{{ t("move-strategy-asins-modal.move_asin_to", [prettyPrintAsins(asinsToMove)]) }}</p>
    <div class="row mx-2">
      <ISelect
        class="w-full"
        [options]="strategiesOptions"
        [selected]="target"
        placeholder="{{ t('move-strategy-asins-modal.select_a_strategy') }}"
        (selectedChange)="selectStrategy($event)"
        searchable
      >
        <ng-template #optionSlot #labelSlot let-o>
          <div class="flex items-center gap-2 truncate">
            @if (o.value) {
              @if (o.value?.state === "ENABLED") {
                <span [class]="ICON_PLAY + ' size-5 shrink-0 text-green-500'"></span>
              } @else {
                <span [class]="ICON_PAUSE + ' size-5 shrink-0 text-gray-500'"></span>
              }
            }
            <span class="truncate text-sm">{{ o.label }}</span>
          </div>
        </ng-template>
      </ISelect>
    </div>
  </div>
  <div class="modal-footer">
    <IButton label="{{ t('common.cancel') }}" color="gray" variant="ghost" (onClick)="bsModalRef.hide()" />
    <IButton label="{{ t('common.confirm') }}" [disabled]="!target && !newStrat" (onClick)="confirm()" />
  </div>
</ng-container>
