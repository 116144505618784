<ng-container *transloco="let t">
  <div class="modal-header bg-primary">
    <h4 class="modal-title pull-left">{{ t("strategy-group-add-product-modal.add_products_to_strategy_group") }}</h4>
    <a><h4 class="modal-title pull-right" (click)="close()">&times;</h4></a>
  </div>
  <div class="modal-body flex h-[70vh] flex-col">
    <app-asins-selection
      class="mb-2 flex-1"
      [asins]="asinsToAdd"
      [asinEligibility]="asinEligibility"
      [accountId]="accountMarketplace.accountId"
      [marketplace]="accountMarketplace.marketplace"
      [notDisplayedAsins]="strategyGroup.asins"
      (onAdd)="addAsins($event)"
      (onDelete)="deleteAsins($event)"
      [csvExport]="false"
    >
    </app-asins-selection>
    <div class="flex flex-col gap-y-2 rounded-md bg-gray-50 p-3">
      <div class="flex w-full">
        <label class="w-1/4 font-medium">{{ t("strategy-group-add-product-modal.add_to") }}</label>
        <div class="w-3/4">
          <ISelect
            [options]="productStrategies"
            [selected]="productStrategy"
            (selectedChange)="setProductStrategy($event)"
            searchable
          />
        </div>
      </div>
      @if (productStrategy?.value === "NewProductStrategy") {
        <div class="flex w-full">
          <label class="w-1/4 font-medium">{{ t("sp-strategy-creation.strategy_name") }}</label>
          <div class="w-3/4">
            <input
              type="text"
              maxlength="80"
              class="form-control"
              placeholder="{{ t('strategy-group-add-product-modal.please_enter_a_name_for_your_main_strategy') }}"
              (input)="setProductStrategyName(getValueFromInputEvent($event))"
            />
          </div>
        </div>
        <app-algo-mode-selection
          (algoModeConfig)="setAlgoModeConfig($event)"
          (valid)="setAlgoModeConfigValid($event.isValid)"
        ></app-algo-mode-selection>
      }
    </div>
  </div>
  <div class="modal-footer">
    <IButton label="{{ t('common.cancel') }}" color="gray" variant="ghost" (onClick)="close()" />
    <IButton
      label="{{ t('common.save') }}"
      [disabled]="invalid()"
      [tooltipValue]="invalidTooltip()"
      (onClick)="save()"
      type="submit"
    />
  </div>
</ng-container>
