<div class="mx-auto mt-16 h-[760px] w-[1230px] overflow-hidden rounded-3xl border border-slate-100" *transloco="let t">
  <div class="flex h-full w-full">
    <div class="flex h-full flex-1 flex-col justify-center gap-10 px-20 pt-10">
      <div>
        <div class="mb-12 flex w-full justify-center">
          <!-- icon comes from https://animatedicons.co/icons/minimalistic?type=free -->
          <img src="assets/img/download-stats.gif" alt="downloading your stats" class="h-20 w-20" />
        </div>
        <div>
          <span class="text-xs text-gray-400">2/3</span>
          <div class="mb-2 text-xl font-semibold text-gray-700">{{ t("stats-downloading-page.title") }}</div>

          <p>{{ t("stats-downloading-page.stats_fetching_message") }}</p>
          @if (!isWhiteLabel) {
            <p [innerHTML]="helpCenterMessage"></p>
          }
        </div>
        <div class="mt-16">
          <IProgressBar [progress]="progress()" color="main"></IProgressBar>
        </div>
      </div>
    </div>

    <div class="h-full flex-1 overflow-auto bg-gray-50 px-8">
      @if (!isWhiteLabel) {
        @if (sideInfo() === "help-center-links") {
          <div class="flex h-full flex-col justify-center gap-6 py-2">
            <div class="mb-4 text-xl font-semibold text-gray-700">
              {{ t("stats-downloading-page.get_started_with_m19") }}
            </div>
            <div class="flex flex-col gap-2">
              @for (item of m19GettingStartedLinks; track $index) {
                <a
                  [href]="item.link ?? '#'"
                  target="_blank"
                  class="no-underline"
                  (click)="!item.link ? displayTeamInfo($event) : null"
                >
                  <div
                    class="group flex h-28 items-center gap-4 rounded-md border border-gray-200 p-2 hover:bg-gray-100"
                  >
                    <div class="h-24 w-24 rounded-sm bg-slate-100 p-4 group-hover:bg-slate-200">
                      <span
                        [class]="item.icon + ' flex h-full w-full items-center justify-center text-slate-500'"
                      ></span>
                    </div>
                    <div class="flex flex-1 flex-col gap-2">
                      <div class="font-semibold text-gray-700">{{ item.title }}</div>
                      <div class="text-sm text-gray-500">{{ item.description }}</div>
                    </div>
                  </div>
                </a>
              }
            </div>
          </div>
        } @else {
          <div class="sticky top-0 mb-4 flex items-center justify-between bg-gray-50/90 py-2 backdrop-blur-sm">
            <div class="text-xl font-semibold text-gray-700">{{ t("stats-downloading-page.who_is_behind_m19") }}</div>
            <IButton
              [label]="t('common.back')"
              icon="icon-[mdi--arrow-left]"
              size="sm"
              color="white"
              (onClick)="closeTeamInfo()"
            ></IButton>
          </div>
          <img src="assets/img/m19_team.jpg" alt="m19 team" class="mb-4 w-full rounded-md" />
          <p>
            {{ t("stats-downloading-page.team_presentation") }}
          </p>
          <div class="mb-4 text-xl font-semibold text-gray-700">{{ t("stats-downloading-page.our_values") }}</div>
          <div class="mb-2 font-bold uppercase text-gray-700">{{ t("stats-downloading-page.liberty") }}</div>
          <p>
            {{ t("stats-downloading-page.liberty_description") }}
          </p>
          <div class="mb-2 font-bold uppercase text-gray-700">{{ t("stats-downloading-page.simplicity") }}</div>
          <p>
            {{ t("stats-downloading-page.simplicity_description") }}
          </p>
          <div class="mb-2 font-bold uppercase text-gray-700">{{ t("stats-downloading-page.efficiency") }}</div>
          <p>
            {{ t("stats-downloading-page.efficiency_description") }}
          </p>
          <div class="mb-2 font-bold uppercase text-gray-700">
            {{ t("stats-downloading-page.discernment_and_integrity") }}
          </div>
          <p>
            {{ t("stats-downloading-page.discernment_and_integrity_description") }}
          </p>
          <div class="mb-4 flex items-center gap-2">
            <a href="https://www.m19.com/experts-in-amazon-ppc" target="_blank" class="text-main font-bold">{{
              t("stats-downloading-page.read_out_full_manifesto")
            }}</a
            ><span class="icon-[mdi--arrow-right]"></span>
          </div>
        }
      }
    </div>
  </div>
</div>
