import { DOCUMENT } from "@angular/common";
import { Component, Inject, Input, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Angulartics2GoogleGlobalSiteTag } from "angulartics2";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-spinner",
  template: `@if (display) {
    <span
      class="loader"
      [class.default]="type === 'default'"
      [class.size-s]="size === 's'"
      [class.main]="main"
      #spinner
    ></span>
  }`,
  styleUrls: ["./spinner.component.scss"],
  standalone: true,
  imports: [],
})
export class SpinnerComponent implements OnDestroy {
  @Input() display: boolean;
  @Input() size: "s" | "m" = "m"; // size in px
  @Input() type: "primary" | "default" = "primary";
  // Whether the spinner is main or not => centered in page
  @Input() main = false;
  @Input() logSpinnerTime: boolean = true;

  // this is to track spinner loading time
  private readonly trackSpinnerLoadingTime;
  private readonly startMark: string;
  private readonly stopMark: string;
  private readonly measure: string;
  private static gid = 0; // used to generate a unique id for metric measurement

  constructor(
    private angulartics2GoogleGlobalSiteTag: Angulartics2GoogleGlobalSiteTag,
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
  ) {
    // check performance API is supported
    if (performance) {
      this.trackSpinnerLoadingTime = true;
      SpinnerComponent.gid++;
      this.startMark = "spinner_start_" + SpinnerComponent.gid;
      this.stopMark = "spinner_stop_" + SpinnerComponent.gid;
      this.measure = "spinner_" + SpinnerComponent.gid;
    } else {
      this.trackSpinnerLoadingTime = false;
    }
  }

  @ViewChild("spinner")
  set spinner(spinner) {
    if (!this.trackSpinnerLoadingTime) {
      return;
    }
    if (spinner) {
      // spinner is present
      performance.mark(this.startMark);
    } else if (performance.getEntriesByName(this.startMark).length > 0) {
      // loading has ended
      this.logPerformanceMeasure();
    }
  }

  ngOnDestroy(): void {
    if (!this.trackSpinnerLoadingTime) {
      return;
    }
    // log perf when component is destroyed
    if (this.display && performance.getEntriesByName(this.startMark).length > 0) {
      // loading has ended
      this.logPerformanceMeasure();
    }
    // clear performance mark
    performance.clearMarks(this.startMark);
    performance.clearMarks(this.stopMark);
  }

  private logPerformanceMeasure() {
    performance.mark(this.stopMark);
    const measure = performance.measure(this.measure, this.startMark, this.stopMark);
    const urlPath = this.route.snapshot.routeConfig?.path ?? this.document.location.href.split(/[?#]/)[1];
    const accountId = this.route.snapshot.queryParamMap.get("accountId");
    const marketplace = this.route.snapshot.queryParamMap.get("marketplace");
    // log spinner time for performance audit
    if (this.logSpinnerTime && environment.logSpinnerTime) {
      // eslint-disable-next-line no-console
      console.log(`spinner_timing ${accountId}.${marketplace} ${urlPath} ${measure.duration}`);
    }
    if (measure.duration > 20000) {
      return; // discard if spinner time display duration is more than 20s
    }
    this.angulartics2GoogleGlobalSiteTag.eventTrack("spinner_timing", {
      gstCustom: {
        name: "spinner",
        value: measure.duration,
        event_category: "Performance",
        event_label: "Spinner Display",
        page_title: this.document.title,
        page_path: urlPath,
        page_location: this.document.location.href,
        accountId: accountId,
        marketplace: marketplace,
      },
    });
  }
}
