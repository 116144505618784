import { Component } from "@angular/core";
import { ICellRendererParams } from "@ag-grid-community/core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { StrategyEx, StrategyGroupEx, StrategyTypeStr } from "@front/m19-models";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AlgoMode, StrategyType } from "@front/m19-api-client";
import { TranslocoDirective } from "@jsverse/transloco";
import { IBadgeComponent } from "@front/m19-ui";
import { RouterLink } from "@angular/router";
import { StrategyLinkComponent } from "@m19-board/strategies/strategy-link/strategy-link.component";

@UntilDestroy()
@Component({
  selector: "app-strategy-name-group",
  templateUrl: "./strategy-name-group.renderer.component.html",
  styleUrls: ["./strategy-boost-grid.component.scss"],
  standalone: true,
  imports: [TranslocoDirective, IBadgeComponent, RouterLink, StrategyLinkComponent],
})
export class StrategyNameGroupComponent implements ICellRendererAngularComp {
  strategy!: StrategyEx;
  strategyGroupId!: number;
  strategyGroupName!: string;
  strategyGroupIndex!: Map<number, StrategyGroupEx>;
  StrategyType = StrategyType;
  StrategyTypeStr = StrategyTypeStr;
  AlgoMode = AlgoMode;

  agInit(
    params: any & {
      strategy: StrategyEx;
      stratgeyGroupId: number;
      strategyGroupName: string;
    },
  ): void {
    this.strategy = params.strategy;
    this.strategyGroupId = params.stratgeyGroupId;
    this.strategyGroupName = params.strategyGroupName;
  }

  refresh(params: ICellRendererParams) {
    return true;
  }
}
