import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, combineLatest, ReplaySubject } from "rxjs";

export type Filter<T> = {
  filter: (filter: string, items: T[], maxSuggestions: number) => T[];
};

export const RegexFilter: Filter<string> = {
  filter: (filter: string, items: string[], maxSuggestions: number) => {
    const regex = new RegExp(filter, "i");
    const res: string[] = [];
    for (const item of items) {
      if (regex.test(item)) {
        res.push(item);
      }
      if (maxSuggestions > 0 && res.length >= maxSuggestions) {
        return res;
      }
    }
    return res;
  },
};

export const ProductFilter: Filter<{ asin: string; title: string }> = {
  filter: (filter: string, items: { asin: string; title: string }[], maxSuggestions: number) => {
    const regex = new RegExp(filter, "i");
    const res: { asin: string; title: string }[] = [];
    for (const item of items) {
      if (regex.test(item.asin) || (item.title && regex.test(item.title))) {
        res.push(item);
      }
      if (maxSuggestions > 0 && res.length >= maxSuggestions) {
        return res;
      }
    }
    return res;
  },
};

enum AutocompleteKey {
  Escape = "Escape",
  Up = "ArrowUp",
  Down = "ArrowDown",
  Enter = "Enter",
}

@UntilDestroy()
@Component({
  selector: "app-autocomplete",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "autocomplete.component.html",
  styleUrls: ["autocomplete.component.scss"],
})
export class AutocompleteComponent<T> implements OnInit, AfterViewInit {
  // component inputs
  @Input()
  placeholder: string;

  @Input()
  set availableSuggestions(value: T[]) {
    this.availableSuggestions$.next(value);
  }

  @Input()
  filter: Filter<T>;

  @Input()
  maxSuggestions: number;

  @Input()
  focusOnInit = false;

  @ContentChild("item", { static: false }) itemTemplateRef: TemplateRef<{ $implicit: T }>;
  @ViewChild("inputField") inputField: ElementRef;

  // component outputs
  @Output()
  selected: EventEmitter<T> = new EventEmitter();

  @Output()
  cancel: EventEmitter<void> = new EventEmitter();

  // component state
  availableSuggestions$ = new ReplaySubject<T[]>(1);
  inputValue$ = new BehaviorSubject<string>("");
  suggestions: T[];
  focus = false;
  hoveredItemIndex = -1;

  ngOnInit(): void {
    this.focus = this.focusOnInit;
    combineLatest([this.availableSuggestions$, this.inputValue$])
      .pipe(untilDestroyed(this))
      .subscribe(([availableSuggestions, inputValue]) => {
        this.hoveredItemIndex = -1;
        this.suggestions = this.filter.filter(inputValue, availableSuggestions, this.maxSuggestions);
      });
  }

  ngAfterViewInit(): void {
    if (this.focusOnInit) {
      this.inputField.nativeElement.focus();
    }
  }

  keydown(event: KeyboardEvent) {
    switch (event.key) {
      case AutocompleteKey.Escape:
        this.focus = false;
        this.escape();
        break;
      case AutocompleteKey.Down:
        this.down();
        this.focus = true;
        break;
      case AutocompleteKey.Up:
        this.up();
        this.focus = true;
        break;
      case AutocompleteKey.Enter:
        this.pressEnter();
        this.focus = true;
        break;
      default:
        this.focus = true;
    }
  }

  private up() {
    if (this.hoveredItemIndex > -1) {
      this.hoveredItemIndex--;
    }
  }

  private down() {
    if (this.hoveredItemIndex < this.suggestions.length) {
      this.hoveredItemIndex++;
    }
  }

  private pressEnter() {
    if (this.hoveredItemIndex > -1) {
      this.selectValue(this.suggestions[this.hoveredItemIndex]);
    }
  }

  selectValue(item: T) {
    this.inputValue$.next("");
    this.focus = false;
    this.selected.emit(item);
  }

  setFocusIn() {
    this.focus = true;
  }

  setFocusOut() {
    setTimeout(() => {
      this.focus = false;
    }, 200);
  }

  escape() {
    this.cancel.emit();
    this.inputValue$.next("");
  }
}
