import { Component, Input } from "@angular/core";
import { Currency, Marketplace, QueryType } from "@front/m19-api-client";

import { Metric } from "@front/m19-metrics";
import { QueryStats } from "../models/Metric";

@Component({
  selector: "app-top-queries-list",
  templateUrl: "./top-queries-list.component.html",
  styleUrls: ["./top-queries-list.component.scss"],
})
export class TopQueriesListComponent {
  @Input()
  loadingStatus: boolean;
  @Input()
  metric: Metric<QueryStats>;
  @Input()
  data: QueryStats[];
  @Input()
  currency: Currency;
  @Input()
  locale: string;
  @Input()
  marketplace?: Marketplace;

  get noData() {
    return this.data.length === 0;
  }

  isProductQueryType(stat: QueryStats) {
    return stat.queryType === QueryType.PRODUCT;
  }
}
