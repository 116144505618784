<ng-container *transloco="let t">
  <app-stats-overlay>
    <div class="my-3 flex justify-end">
      <div class="flex items-center gap-3">
        <IButton
          [disabled]="isReadOnly"
          [icon]="ICON_TRASH_O"
          (onClick)="deleteProductGroups(selectedRows)"
          color="red"
          label="{{ t('segment-manager.remove_selected_selectedrows_length', { count: selectedRows.length }) }}"
          [hidden]="selectedRows.length === 0"
        ></IButton>
        <IButton
          [disabled]="isReadOnly"
          [icon]="ICON_ADD"
          (onClick)="
            accountMarketplace && createProductGroup(accountMarketplace.accountId, accountMarketplace.marketplace)
          "
          color="main"
          label="{{ t('product-group.create_a_new_product_group') }}"
        />
        <IButton
          [disabled]="isReadOnly"
          tooltipValue="{{ t('product-group.upload_strategy_data') }}"
          [icon]="ICON_IMPORT"
          (onClick)="uploadProductGroups()"
          color="white"
          label="{{ t('common.import') }}"
        />
        <app-export-button
          tooltipValue="{{ t('product-group.export_product_group_list_as_csv') }}"
          (export)="exportProductGroups()"
        ></app-export-button>
      </div>
    </div>
    @if (productGroups) {
      <ag-grid-angular
        #grid
        class="ag-theme-quartz base-theme mb-2 h-[70rem] flex-1"
        [gridOptions]="gridOptions"
        [columnDefs]="colDef"
        [rowData]="productGroups"
        (gridReady)="onGridReady($event)"
      ></ag-grid-angular>
    }
  </app-stats-overlay>
</ng-container>
