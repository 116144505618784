import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AsinService } from "@front/m19-services";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Marketplace, StrategyAsin } from "@front/m19-api-client";
import { Catalog } from "@front/m19-models";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";
import { StrategyAsinsComponent } from "@m19-board/strategies/strategy-asins/strategy-asins.component";
import { IButtonComponent } from "@front/m19-ui";
import { TranslocoDirective } from "@jsverse/transloco";

@UntilDestroy()
@Component({
  templateUrl: "./brand-asins-modal.component.html",
  standalone: true,
  imports: [SpinnerComponent, StrategyAsinsComponent, IButtonComponent, TranslocoDirective],
})
export class BrandAsinsModalComponent implements OnInit {
  @Input()
  brandName!: string;
  @Input()
  accountId!: string;
  @Input()
  marketplace!: Marketplace;

  asins?: StrategyAsin[];

  constructor(
    private modalRef: BsModalRef,
    private asinService: AsinService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.asinService
      .getCatalog(this.accountId, this.marketplace)
      .pipe(untilDestroyed(this))
      .subscribe((catalog: Catalog) => {
        this.asins = [];
        for (const p of catalog.products) {
          // ignore case
          if (p.brand?.toLowerCase() === this.brandName.toLowerCase()) {
            this.asins.push({ asin: p.asin! });
          }
        }
      });
  }

  openCatalog() {
    this.router.navigate(["/products/catalog"], {
      queryParams: { brand: this.brandName },
      queryParamsHandling: "merge",
    });
    this.modalRef.hide();
  }

  close() {
    this.modalRef.hide();
  }
}
