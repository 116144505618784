<ng-container *transloco="let t">
  <mat-table mat-table [dataSource]="dataSource" matSort class="responsive-table table-striped table-hover text-right">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef class="flex-2 text-left" mat-sort-header>{{
        t("sp-strategy-creation.strategy_name")
      }}</mat-header-cell>
      <mat-cell *matCellDef="let strategy" class="flex-2 flex flex-col justify-center">
        <div class="flex w-full">
          <app-strategy-link
            [strategyId]="strategy.strategyId"
            [withCampaignType]="true"
            class="w-full"
            [color]="strategy.algoMode !== AlgoMode.ACOS_TARGET ? 'black' : ''"
          ></app-strategy-link>
          @if (strategy.strategyType !== StrategyType.LEGACY) {
            <div class="ml-2">
              <IBadge [label]="StrategyTypeStr[strategy.strategyType]" size="xs" />
            </div>
          }
        </div>

        @if (strategy.strategyType !== StrategyType.LEGACY) {
          <div class="mt-2 w-full">
            {{ t("strategy-boost-table.strategy_group") }}&nbsp;<a
              [routerLink]="'/strategies/strategy-group/sponsored-product/' + strategy.strategyGroupId"
              queryParamsHandling="merge"
              class="text-dark"
              >{{ strategyGroupIndex.get(strategy.strategyGroupId)?.strategyGroupName }}
            </a>
          </div>
        }
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="acosTarget">
      <mat-header-cell *matHeaderCellDef class="flex-half text-center">{{
        t("algo-mode-selection.acos_target")
      }}</mat-header-cell>
      <mat-cell *matCellDef="let strategy" class="flex-half flex items-center justify-center"
        >{{ strategy.algoMode === AlgoMode.ACOS_TARGET ? (strategy.acosTarget | percent) : "" }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="enableBtn">
      <mat-header-cell *matHeaderCellDef class="flex items-center text-center"
        >{{ t("strategy-boost-table.boost", [eventName]) }}
      </mat-header-cell>
      <mat-cell *matCellDef="let strategy" class="flex items-center justify-center">
        <mat-slide-toggle
          color="primary"
          [checked]="strategy.activatePrimeDayBoost"
          (toggleChange)="toggleBoost(strategy)"
          [disabled]="strategy.algoMode !== AlgoMode.ACOS_TARGET || isReadOnly || strategy.dailyBudget > 0"
        >
        </mat-slide-toggle>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="acosOptimizer">
      <mat-header-cell *matHeaderCellDef class="flex-2 flex items-center">{{
        t("strategy-boost-table.acos_optimizer")
      }}</mat-header-cell>
      <mat-cell class="flex-2" *matCellDef="let strategy">
        <div class="intensity m-3">
          @if (strategy.activatePrimeDayBoost) {
            <span>
              <input
                type="range"
                #intensity
                min="1"
                max="5"
                step="1"
                class="slider"
                [disabled]="isReadOnly"
                [ngClass]="!strategy.dailyBudget || strategy.dailyBudget === 0 ? '' : 'inactive-range'"
                [value]="formatPrimeDayBoost(strategy.primeDayBoost)"
                (mouseup)="setIntensity(strategy.strategyId, +intensity.value)"
              />
              <div class="labels">
                <span class="label label-L">
                  <span class="mt-1">-50%</span>
                </span>
                <span class="label">
                  <span class="mt-1">+0%</span>
                </span>
                <span class="label label-N">
                  <span class="mt-1">+25%</span>
                </span>
                <span class="label">
                  <span class="mt-1">+50%</span>
                </span>
                <span class="label label-H">
                  <span class="mt-1">+100%</span>
                </span>
              </div>
            </span>
          }
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef class="flex-3"></mat-header-cell>
      <mat-cell class="flex-3 text-left" *matCellDef="let strategy">
        @if (strategy.primeDayBoost) {
          <p>
            {{
              t("strategy-boost-table.statey_boost", [
                strategy.acosTarget | percent,
                eventName,
                primeDayPeriod,
                primeDayBoost(strategy.acosTarget, strategy.primeDayBoost) | percent,
                primeDayAfterPeriod,
                primeDayDecrease(strategy.acosTarget) | percent,
                primeDayOverDate,
                strategy.acosTarget | percent,
              ])
            }}
          </p>
        }
        @if (!strategy.primeDayBoost && strategy.algoMode === AlgoMode.ACOS_TARGET && !strategy.dailyBudget) {
          <p>
            {{ t("strategy-boost-table.dont_benefit", [eventName]) }}
          </p>
        }
        @if (!strategy.primeDayBoost && strategy.algoMode === AlgoMode.ACOS_TARGET && strategy.dailyBudget > 0) {
          <p>{{ t("strategy-boost-table.avg_daily_spend") }}</p>
        }
        @if (strategy.algoMode === AlgoMode.PRODUCT_LAUNCH) {
          <p>{{ t("strategy-boost-table.daily_target") }}</p>
        }
        @if (strategy.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET) {
          <p>{{ t("strategy-boost-table.monthly_budget") }}</p>
        }
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="
        row.algoMode === AlgoMode.ACOS_TARGET && !row.dailyBudget
          ? !row.primeDayBoost
            ? '!text-gray-800'
            : ''
          : '!bg-gray-300'
      "
      class="table-body"
    ></mat-row>
  </mat-table>
</ng-container>
