import { Component, Input, OnInit } from "@angular/core";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { CampaignType, M19Status, NotificationType } from "@front/m19-api-client";
import { NotificationBidderIssueEx, NotificationEx, SbCreativeBrandAssets, StrategyEx } from "@front/m19-models";
import { AsinService, InventoryService, NotificationService, SbStrategiesService } from "@front/m19-services";
import { Utils } from "@front/m19-utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, map, of } from "rxjs";

@UntilDestroy()
@Component({
  selector: "app-strategy-warning",
  templateUrl: "./strategy-warning.component.html",
  styleUrls: ["./strategy-warning.component.scss"],
})
export class StrategyWarningComponent implements OnInit {
  @Input()
  strategy: StrategyEx | undefined;

  readonly faWarning = faExclamationTriangle;

  faultyReason: string | undefined;
  atLeastOne = false;

  constructor(
    private asinService: AsinService,
    private inventoryService: InventoryService,
    private notifications: NotificationService,
    private sbStrategyService: SbStrategiesService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    if (this.strategy == undefined) {
      return;
    }
    combineLatest([
      this.asinService.getInventoryRules(this.strategy.accountId, this.strategy.marketplace),
      this.inventoryService.getInventoryStats(this.strategy.accountId, this.strategy.marketplace),
      this.asinService.getCatalog(this.strategy.accountId, this.strategy.marketplace),
      this.notifications.getNotifications$.pipe(
        map((x: NotificationEx[]) =>
          x
            .filter((n) => n.type === NotificationType.BIDDER_ISSUE)
            .filter((n) => n.strategyId === this.strategy!.strategyId),
        ),
      ),
      this.strategy.campaignType === CampaignType.SB
        ? this.sbStrategyService
            .getSbCreativesPerStrategy(this.strategy.accountId, this.strategy.marketplace)
            .pipe(map((x) => x.get(this.strategy!.strategyId)))
        : of([]),
      this.strategy.campaignType === CampaignType.SB
        ? this.sbStrategyService.getSbCreativeBrandAssets(this.strategy.accountId, this.strategy.marketplace)
        : of(new Map<number, SbCreativeBrandAssets>()),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([rules, stats, catalog, notifs, sbCreatives, sbCreativeBrandAssets]) => {
        if (!rules || !stats || !catalog) {
          return;
        }
        this.faultyReason = undefined;
        this.atLeastOne = false;

        // first check bidder issue
        const bidderIssue = notifs
          .filter((n) => n.type === NotificationType.BIDDER_ISSUE)
          .find((n) => n.strategyId === this.strategy!.strategyId) as NotificationBidderIssueEx;
        if (bidderIssue && bidderIssue.warningType !== M19Status.IGNORED_REJECTED) {
          this.atLeastOne = true;
          this.faultyReason = bidderIssue.warningMessage;
          return;
        }

        // then creative issue
        for (const crea of sbCreatives ?? []) {
          const { status } = Utils.getSBFaultyReasons(
            crea,
            sbCreativeBrandAssets.get(crea.creativeId) ?? { creativeTitle: "" },
            catalog,
            rules,
            stats,
            this.translocoService,
            notifs.find((n) => n.subStrategyId === crea.creativeId) as NotificationBidderIssueEx,
          );
          if (status) {
            this.faultyReason = "Ads " + Utils.m19StatusToReadableStr(status);
            this.atLeastOne = true;
            break;
          }
        }
      });
  }
}
