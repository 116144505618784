<div class="notification-center-container" *transloco="let t">
  <h2 class="mb-3">{{ t("notifications-center.notifications_center") }}</h2>
  <app-spinner class="w-full" [display]="isLoading"></app-spinner>
  <div class="notif-center">
    <div class="notification-filter">
      <div>
        <IButton
          [label]="t('notifications-center.all_notif', { count: notificationsCount })"
          (onClick)="selectType()"
          [color]="notificationTypeSelection ? 'white' : 'main'"
          [block]="true"
          class="w-full"
        />
      </div>
      @for (type of notificationTypes; track type) {
        <div>
          <IButton
            [label]="
              t(getLabel(type)) +
              ' (' +
              (notificationsCountPerType!.get(type)! > 0 ? notificationsCountPerType!.get(type) : '') +
              ')'
            "
            (onClick)="selectType(type)"
            [color]="notificationTypeSelection === type ? 'main' : 'white'"
            [block]="true"
            class="w-full"
          />
        </div>
      }
    </div>
    <div class="notifications">
      <span class="search bar sticky mb-3 mt-1 pl-0" style="width: 65%; display: inline-block; max-width: 36rem">
        <span class="search-bar input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <fa-icon [icon]="faSearch"></fa-icon>
            </span>
          </div>
          <input
            type="text"
            class="search-bar simple-input-prepend form-control"
            placeholder="{{ t('notifications-center.search_in_notif') }}"
            (input)="setFilter($event.target)"
          />
        </span>
      </span>
      @for (notification of notifications; track notification) {
        <app-notification-card-switch
          [hidden]="notificationTypeSelection !== undefined && notification.type !== notificationTypeSelection"
          [notification]="notification"
          [filter]="regExp"
        ></app-notification-card-switch>
      }
      @if (!notifications.length && !isLoading) {
        <h3 class="text-center">{{ t("notifications-center.no_notif") }}</h3>
      }
    </div>
  </div>
</div>
