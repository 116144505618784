import { Component, inject } from "@angular/core";
import { Router } from "@angular/router";
import { faPlusCircle, faRocket } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AuthService, BillingService, KeywordTrackingService, StatsApiClientService } from "@front/m19-services";
import { BoardType, LayoutSelectorService } from "@m19-board/layout-selector.service";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { SettingStatus } from "../../models/Billing";
import { ModalStripeCreateTokenComponent } from "./credit-card/stripe-create-token.component";
import { BillingCustomerComponent } from "./customer/billing-customer.component";
import { toSignal } from "@angular/core/rxjs-interop";
import { map, tap } from "rxjs/operators";
import { AccessLevel, AccountState, DailyAdSpendFee, KeywordTrackerQuota, Plan } from "@front/m19-api-client";
import { OrganizationAccountGroups } from "@front/m19-models";

@UntilDestroy()
@Component({
  selector: "app-billing-settings",
  templateUrl: "billing-settings.component.html",
  styleUrls: ["./billing-settings.component.scss"],
})
export class BillingSettingsComponent {
  private readonly authService = inject(AuthService);
  private readonly billingService = inject(BillingService);
  private readonly modalService = inject(BsModalService);
  private readonly router = inject(Router);
  private readonly statsService = inject(StatsApiClientService);
  private readonly layoutSelector = inject(LayoutSelectorService);
  private readonly keywordTrackerService = inject(KeywordTrackingService);
  private readonly toasterService = inject(ToastrService);

  organizationVisible: Map<number, boolean> = new Map();
  invoiceVisible: Map<number, boolean> = new Map();
  infoVisible: Map<number, boolean> = new Map();
  creditCardVisible: Map<number, boolean> = new Map();

  locale$ = this.authService.loggedUser$.pipe(map((u) => u.locale));

  whiteLabel = this.layoutSelector.getBoardType() !== BoardType.M19;
  private user$ = this.authService.user$;
  user = toSignal(this.user$);

  organizationKeywordMap: Map<number, Map<string, KeywordTrackerQuota>> = new Map();

  organizations$ = this.billingService.organizations$.pipe(
    map((organizations: OrganizationAccountGroups[]) => {
      return this.whiteLabel
        ? organizations.filter(
            (x) =>
              x.isParentPpcBoard ||
              (x.hasSubscription() &&
                x.accountGroups
                  .flatMap((ag) => ag.getAccountMarketplaces())
                  .filter((am) => am.state == AccountState.BIDDER_ON).length > 0),
          )
        : organizations;
    }),
    tap((organizations: OrganizationAccountGroups[]) => {
      let visible = true;

      this.organizationVisible.clear();
      this.invoiceVisible.clear();
      this.infoVisible.clear();
      this.creditCardVisible.clear();

      organizations.forEach((item) => {
        this.organizationVisible.set(item.id, visible);
        this.invoiceVisible.set(item.id, true);
        this.infoVisible.set(item.id, !!item.customer);
        this.creditCardVisible.set(item.id, true);
        visible = false;
      });

      this.initDailyHourlyKeywordMap(organizations);
    }),
  );

  dailyAdSpendFeeMap$ = this.statsService.getDailyAdSpendFee().pipe(
    map((data: DailyAdSpendFee[]) => {
      const dailyAdSpendFeeMap = new Map<number, DailyAdSpendFee[]>();
      data.forEach((dailyAdSpend) => {
        // move self service ad spend fee to parent organization
        // billing is by parent organization
        const orgId = dailyAdSpend.parentOrganizationId
          ? dailyAdSpend.parentOrganizationId
          : dailyAdSpend.organizationId!;

        if (!dailyAdSpendFeeMap.has(orgId)) {
          dailyAdSpendFeeMap.set(orgId, []);
        }
        dailyAdSpendFeeMap.get(orgId)!.push(dailyAdSpend);
      });
      return dailyAdSpendFeeMap;
    }),
  );

  readonly faPlusCircle = faPlusCircle;
  readonly faRocket = faRocket;
  readonly SettingStatus = SettingStatus;

  canDisplayBilling(organization: OrganizationAccountGroups): boolean {
    return organization.accessLevel == AccessLevel.ADMIN;
  }

  updateCreditCard(): void {
    const modalOptions: ModalOptions = {
      class: "modal-primary",
    };
    this.modalService.show(ModalStripeCreateTokenComponent, modalOptions);
  }

  hasACreditCard(organization: OrganizationAccountGroups): boolean {
    return !!organization.customer && !!organization.customer.stripeCardId;
  }

  isOrganizationOwner(organization: OrganizationAccountGroups): boolean {
    return organization.organization.ownerId == this.user()?.userId;
  }

  canUpgrade(organization: OrganizationAccountGroups): boolean {
    // TODO: for the moment, only allow upgrade from a STARTER plan
    return organization.organization.billingPlan?.plan === Plan.STARTER && !this.whiteLabel;
  }

  editCustomer(organization: OrganizationAccountGroups): void {
    const modalOptions: ModalOptions = {
      initialState: {
        settingStatus: SettingStatus.EDITION,
        customer: organization.customer,
        wizard: false,
      },
      class: "modal-primary modal-lg",
    };
    const modalRef = this.modalService.show(BillingCustomerComponent, modalOptions);
    modalRef.content?.onCancel.subscribe(() => modalRef.hide());
    modalRef.content?.onSave.subscribe(() => modalRef.hide());
  }

  createSubscription(): void {
    switch (this.layoutSelector.getBoardType()) {
      case BoardType.M19:
        this.router.navigate(["billing-registration"]);
        break;
      case BoardType.WHITELABEL_SELFSERVICE:
        this.router.navigate(["freemium"]);
        break;
    }
  }

  createCustomer(organization: OrganizationAccountGroups): void {
    const modalOptions: ModalOptions = {
      initialState: {
        settingStatus: SettingStatus.CREATION,
        customer: organization.customer,
        wizard: false,
      },
      class: "modal-primary modal-lg",
    };
    const modalRef = this.modalService.show(BillingCustomerComponent, modalOptions);
    modalRef.content?.onCancel.subscribe(() => modalRef.hide());
    modalRef.content?.onSave.subscribe(() => modalRef.hide());
  }

  initDailyHourlyKeywordMap(organizations: OrganizationAccountGroups[]) {
    this.organizationKeywordMap = new Map();
    for (const organisation of organizations) {
      if (organisation.accessLevel !== AccessLevel.ADMIN) {
        continue;
      }
      this.keywordTrackerService.getDailyHourlyKeywordNumber(organisation.id).subscribe({
        next: (data) => {
          this.organizationKeywordMap.set(
            organisation.id,
            new Map(
              data.map((x: KeywordTrackerQuota) => {
                return [x.accountId?.toString() + "_" + x.marketplace?.toString() + "_" + x.frequency, x];
              }),
            ),
          );
        },
        error: (err) => {
          this.toasterService.error(err);
        },
      });
    }
  }
}
