<ng-container *transloco="let t">
  @if (!small) {
    <div class="card-ctn flex items-center" [style.cursor]="ineligible ? 'not-allowed' : 'grab'">
      <div class="drag-icon">
        @if (!ineligible) {
          <img src="assets/img/chart_icons/drag-icon.svg" alt="Drag handle" />
        }
        @if (ineligible) {
          <div style="width: 25px"></div>
        }
      </div>
      <div class="flex min-w-0 grow">
        @if (product) {
          <div class="asin-img sensitive-data mr-2"><img [src]="product.imageUrl" [alt]="product.asin" /></div>
        }
        <div class="min-w-0 grow">
          <div class="mb-2 flex justify-between">
            @if (product) {
              <span class="sensitive-data font-bold" [style.color]="ineligible ? '#dedede' : '#000'">
                {{ product.asin }}
              </span>
            } @else {
              <span class="text-gray-500">Cannot find product</span>
            }
            @if (!inCluster) {
              @if (isVideoCreative && !ineligible) {
                <div (click)="addVideoAsin()">
                  <span class="card-action" [class.action-disabled]="availableClusters.length === 0">{{
                    t("common.add")
                  }}</span>
                </div>
              }
              @if (!isVideoCreative && !ineligible) {
                <span class="card-action" [matMenuTriggerFor]="menu" data-testid="btn-add-group">{{
                  t("common.add")
                }}</span>
                <mat-menu #menu="matMenu">
                  <ul>
                    @for (c of availableClusters; track c; let i = $index) {
                      <li class="dd-item" (click)="add.emit(c)" [attr.data-testid]="'group-' + i">
                        {{ t("cluster-asin-card.group", [c + 1]) }}
                      </li>
                    }
                  </ul>
                </mat-menu>
              }
            }
            @if (inCluster) {
              <div (click)="remove.emit()">
                <span class="card-action">{{ t("common.remove") }}</span>
              </div>
            }
            @if (ineligible) {
              <div class="warning-ineligible">
                <img src="assets/img/chart_icons/warning-circle.svg" alt="Ineligible ASIN" />
              </div>
            }
          </div>
          <div class="description sensitive-data">
            {{ product ? product.title : "-" }}
          </div>
        </div>
      </div>
    </div>
  }

  @if (small && product; as p) {
    <div class="card-small-ctn">
      <div class="abs-delete-btn" (click)="remove.emit()">
        <img src="assets/img/chart_icons/close-icon.svg" alt="" />
      </div>
      <div class="small-card-img sensitive-data mx-auto flex"><img [src]="p.imageUrl" [alt]="p.asin" /></div>
      <div class="asin-small sensitive-data text-center" [class.ineligible]="ineligible">{{ p.asin }}</div>
    </div>
  }
</ng-container>
