import { Component, Input } from "@angular/core";
import { Currency, Marketplace, QueryType } from "@front/m19-api-client";

import { Metric, MetricFormatPipe } from "@front/m19-metrics";
import { QueryStats } from "../models/Metric";
import { TranslocoDirective } from "@jsverse/transloco";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";
import { ProductViewComponent } from "@m19-board/product-view/product-view.component";
import { AmazonSearchUrlPipe } from "@front/m19-services";

@Component({
  selector: "app-top-queries-list",
  templateUrl: "./top-queries-list.component.html",
  styleUrls: ["./top-queries-list.component.scss"],
  standalone: true,
  imports: [TranslocoDirective, SpinnerComponent, ProductViewComponent, MetricFormatPipe, AmazonSearchUrlPipe],
})
export class TopQueriesListComponent {
  @Input({ required: true })
  loadingStatus!: boolean;
  @Input({ required: true })
  metric!: Metric<QueryStats>;
  @Input({ required: true })
  data!: QueryStats[];
  @Input({ required: true })
  currency!: Currency;
  @Input({ required: true })
  locale!: string;
  @Input()
  marketplace?: Marketplace;

  get noData() {
    return this.data.length === 0;
  }

  isProductQueryType(stat: QueryStats) {
    return stat.queryType === QueryType.PRODUCT;
  }
}
