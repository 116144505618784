// TODO merge dateRange & date Aggregation into a single param
export enum DateAggregation {
  hourly = 'hourly',
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
}

export enum Comparison {
  None = 0,
  PreviousPeriod = 1,
  PreviousWeek = 2,
  PreviousMonth = 3,
  Previousyear = 4,
  Custom = 5,
}

export type DateAggregationsDef = {
  [key in DateAggregation]: { title: string; abbreviation: string };
};
export const DateAggregations: DateAggregationsDef = {
  [DateAggregation.hourly]: {
    title: 'common.hourly',
    abbreviation: 'H',
  },
  [DateAggregation.daily]: {
    title: 'common.daily',
    abbreviation: 'D',
  },
  [DateAggregation.weekly]: {
    title: 'common.weekly',
    abbreviation: 'W',
  },
  [DateAggregation.monthly]: {
    title: 'common.monthly',
    abbreviation: 'M',
  },
};

export enum MetricsSelectorLocalStorageKey {
  strategy = 'Strategy',
  dashboard = 'Dashboard',
  overview = 'Overview',
  overviewDetails = 'OverviewDetails',
  traffic = 'Traffic',
  salesAndAdvertising = 'SalesAndAdvertising',
  profitAnalytics = 'ProfitAnalytics',
  hourlyStats = 'HourlyStats',
  hourlyCampaignStats = 'HourlyCampaignStats',
  superBoard = 'SuperBoard',
  keywordAsinStats = 'KeywordAsinStats',
  dspStats = 'DspStats',
}

export type DateRange = RelativeDateRange | CustomDateRange;
export type RelativeDateRange = {
  type: 'relative';
  days: number;
};
export type CustomDateRange = {
  type: 'absolute';
  startDate: string; // format YYYY-MM-DD
  endDate: string; // format YYYY-MM-DD
};
export const DateRangeLocalStorageKey = 'dateRange';
export const OverviewChartTypeStorageKey = 'overviewChartType';
