import { Clipboard, ClipboardModule } from "@angular/cdk/clipboard";

import { Component, Input, OnInit } from "@angular/core";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { Router, RouterModule } from "@angular/router";
import { IButtonComponent, ISelectComponent, Option } from "@front/m19-ui";
import { BoardType, LayoutSelectorService } from "@m19-board/layout-selector.service";
import { I18nService } from "@m19-board/services/i18n.service";
import { ObfuscationService } from "@m19-board/services/obfuscation.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { environment } from "../../../environments/environment";
import { AccountMarketplace, Marketplace, User } from "@front/m19-api-client";
import { Languages } from "@front/m19-models";
import { AuthService } from "@front/m19-services";
import { TranslocoService } from "@jsverse/transloco";
import { ICON_CLOSE, ICON_CLOSE_CIRCLE, ICON_COPY_O } from "@m19-board/utils/iconsLabels";

@UntilDestroy()
@Component({
  template: ` <div class="p-4">
    <h2 class="flex items-center justify-between">
      <span>Debug info</span>
      <div class="flex items-center justify-end gap-2">
        <IButton
          variant="ghost"
          size="sm"
          icon="icon-[lucide--copy]"
          tooltipValue="Copy to clipboard"
          (onClick)="copy()"
        />

        @if (isAdminLogin()) {
          <IButton
            variant="ghost"
            size="sm"
            icon="icon-[lucide--github]"
            tooltipValue="Open Issue"
            (onClick)="createIssue()"
          />
        }
      </div>
    </h2>
    <pre class="mt-4 rounded bg-gray-800 p-4 text-white"><code>{{ debugInfo }}</code></pre>
    @if (isAdminLogin()) {
      <div class="flex flex-row justify-between">
        <mat-slide-toggle
          color="primary"
          class="mt-2"
          [checked]="obfuscationMode"
          (change)="toggleObfuscationMode($event.checked)"
          >Scramble sensitive data
        </mat-slide-toggle>

        <div class="flex w-52 items-center justify-center align-middle">
          <IButton
            class="mr-2"
            variant="ghost"
            size="sm"
            icon="icon-[lucide--x]"
            tooltipValue="Remove language from local storage"
            (onClick)="removeLanguage()"
          />
          <ISelect
            class="col-sm-8 p-0"
            [options]="languages"
            placeholder="Select a language"
            (selectedOptionChange)="selectLanguage($event)"
            [selectedValue]="getLanguage().value"
          />
        </div>
      </div>
    }
  </div>`,
  standalone: true,
  providers: [LayoutSelectorService, ISelectComponent],
  imports: [
    ClipboardModule,
    MatTooltipModule,
    ToastrModule,
    RouterModule,
    MatSlideToggleModule,
    ISelectComponent,
    IButtonComponent,
  ],
})
export class DebugInfoModalComponent implements OnInit {
  @Input()
  user!: User;

  @Input()
  accountMarketplaces: AccountMarketplace[] = [];

  @Input()
  dateRange: string[] = [];

  languages: Option<string>[] = Languages.map((l) => ({ value: l.value, label: l.label }));
  debugInfo: string = "";
  obfuscationMode?: boolean;

  constructor(
    private clipboard: Clipboard,
    private toastrService: ToastrService,
    private layoutSelectionService: LayoutSelectorService,
    private router: Router,
    private authService: AuthService,
    private obfuscationService: ObfuscationService,
    private i18nService: I18nService,
    private translocoService: TranslocoService,
  ) {
    this.obfuscationService.obfuscation.pipe(untilDestroyed(this)).subscribe((obfuscation) => {
      this.obfuscationMode = obfuscation;
    });
  }

  ngOnInit(): void {
    const debugPayload = {
      timestamp: new Date().toString(),
      boardVersion: environment.commit_hash,
      boardBuildTimestamp: environment.build_timestamp,
      userId: this.user?.userId ?? "",
      userEmail: this.user?.email ?? "",
      accountMarketplaces: (this.accountMarketplaces ?? []).map((am) => `${am.accountId}.${am.marketplace}`).join(" "),
      dspAdvertiserId: this.accountMarketplaces?.length > 0 ? (this.accountMarketplaces[0].dspAdvertiserId ?? "") : "",
      dateRange: this.dateRange.join(" - "),
      url: window.location.href,
      userAgent: window.navigator.userAgent,
      quickConnectLink:
        this.accountMarketplaces && this.accountMarketplaces.length > 0
          ? this.buildQuickConnectLink(
              this.user?.userId,
              this.accountMarketplaces[0]?.accountId,
              this.accountMarketplaces[0]?.marketplace,
              this.router.routerState.snapshot.url,
              this.isAdminLogin(),
            )
          : "",
      // TODO: not sure if useful, but we could add this:
      // localStorage: JSON.stringify(localStorage),
    };
    this.debugInfo = "// debug info\n";
    for (const k in debugPayload) {
      this.debugInfo += `${k}: ${debugPayload[k as keyof typeof debugPayload]}\n`;
    }
  }

  isAdminLogin(): boolean {
    return this.authService.isAdminLogin();
  }

  private buildQuickConnectLink(
    userId: number,
    accountId: string,
    marketplace: Marketplace,
    redirect: string,
    isAdmin: boolean,
  ): string {
    if (!userId || !accountId || !marketplace || !redirect) {
      return "";
    }
    const path = `/#/quick-connect?userId=${userId}&accountId=${accountId}&marketplace=${marketplace}&redirect=${encodeURIComponent(
      redirect,
    )}`;
    if (this.layoutSelectionService.getBoardType() == BoardType.M19 || isAdmin) {
      return `http://board-admin.m19.com${path}`;
    }
    return path;
  }

  copy() {
    this.clipboard.copy(this.debugInfo);
    this.toastrService.success(this.translocoService.translate("common.copied_to_clipboard"));
  }

  // see https://docs.github.com/en/issues/tracking-your-work-with-issues/creating-an-issue#creating-an-issue-from-a-url-query
  createIssue() {
    window.open(
      "https://github.com/m19-dev/main-repo/issues/new?labels=bug&projects=m19-dev/10&type=bug&body=" +
        encodeURIComponent(this.debugInfo),
      "_blank",
    );
  }

  toggleObfuscationMode(checked: boolean) {
    this.obfuscationService.toggleObfuscationMode(checked);
  }

  selectLanguage(lg: Option<string>) {
    this.i18nService.changeLanguageAndReload(lg.value, true);
  }

  getLanguage(): Option<string> {
    return (
      this.languages.find((lg) => lg.value === localStorage.getItem("language")) ??
      this.languages.find((lg) => lg.value === this.user.language) ??
      this.languages[0]
    );
  }

  removeLanguage() {
    localStorage.removeItem("language");
    window.location.reload();
  }

  protected readonly ICON_COPY_O = ICON_COPY_O;
  protected readonly ICON_CLOSE = ICON_CLOSE;
  protected readonly ICON_CLOSE_CIRCLE = ICON_CLOSE_CIRCLE;
}
