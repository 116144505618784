import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TranslocoDirective } from "@jsverse/transloco";
import { MatTooltip } from "@angular/material/tooltip";
import { NgClass, NgStyle } from "@angular/common";
import { ICON_EYE, ICON_EYE_CLOSED, ICON_TRASH_O } from "@m19-board/utils/iconsLabels";

@Component({
  selector: "app-search-term-card",
  templateUrl: "./search-term-card.component.html",
  styleUrls: ["./search-term-card.component.scss"],
  standalone: true,
  imports: [TranslocoDirective, MatTooltip, NgClass, NgStyle],
})
export class SearchTermCardComponent {
  submenu = false;
  submenuHover = false;
  _show = true;

  @Input() searchTerm!: string;
  @Input() searchVolume: number | null = null;
  @Input() color!: string;
  @Input() withShowHideToggle = true;

  @Output() delete = new EventEmitter<void>();
  @Output() showed = new EventEmitter<boolean>();

  toggleShowSearchTerm(): void {
    this._show = !this._show;
    this.showed.emit(this._show);
  }

  protected readonly ICON_TRASH_O = ICON_TRASH_O;
  protected readonly ICON_EYE = ICON_EYE;
  protected readonly ICON_EYE_CLOSED = ICON_EYE_CLOSED;
}
