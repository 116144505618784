// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    Marketplace,
    MatchType,
} from './';

/**
 * @export
 * @interface AsinSeed
 */
export interface AsinSeed {
    /**
     * @type {string}
     * @memberof AsinSeed
     */
    accountId?: string;
    /**
     * @type {Marketplace}
     * @memberof AsinSeed
     */
    marketplace?: Marketplace;
    /**
     * @type {string}
     * @memberof AsinSeed
     */
    asin?: string;
    /**
     * @type {MatchType}
     * @memberof AsinSeed
     */
    matchType?: MatchType;
    /**
     * @type {string}
     * @memberof AsinSeed
     */
    targetingValue?: string;
    /**
     * @type {string}
     * @memberof AsinSeed
     */
    type?: AsinSeedTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum AsinSeedTypeEnum {
    WHITELIST = 'WHITELIST',
    BLACKLIST = 'BLACKLIST'
}

