import { Component, Input, OnInit, Pipe, PipeTransform, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NavData, navItems } from "../_nav";
import { AuthService } from "@front/m19-services";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  @ViewChild("iconDashboard", { static: true }) iconDashboard: TemplateRef<any>;
  @ViewChild("iconStrategies", { static: true }) iconStrategies: TemplateRef<any>;
  @ViewChild("iconProducts", { static: true }) iconProducts: TemplateRef<any>;
  @ViewChild("iconActivities", { static: true }) iconActivities: TemplateRef<any>;
  @ViewChild("iconTools", { static: true }) iconTools: TemplateRef<any>;
  @ViewChild("iconInsights", { static: true }) iconInsights: TemplateRef<any>;

  @Input()
  public betaTest = false;

  readonly navItems: NavData[] = navItems;

  isExpended = false;
  selectedMenu: string;
  displayedMenu: string;

  constructor(
    protected router: Router,
    private authService: AuthService,
    private toasterService: ToastrService,
  ) {}

  ngOnInit(): void {
    const selectedUrl: string = navItems.find((d: NavData) => {
      return (
        this.router.url.startsWith(d.url) ||
        (d.children && d.children.some((d: NavData) => this.router.url.startsWith(d.url)))
      );
    })?.url;

    this.displayedMenu = this.selectedMenu = selectedUrl;
  }

  toggleSubMenu(parentUrl: string) {
    if (this.displayedMenu === parentUrl) this.displayedMenu = undefined;
    else this.displayedMenu = parentUrl;
  }

  getIconRefByUrl(url: string): TemplateRef<any> {
    switch (url.toLowerCase()) {
      case "dashboard":
        return this.iconDashboard;
      case "insights":
        return this.iconInsights;
      case "strategies":
        return this.iconStrategies;
      case "products":
        return this.iconProducts;
      case "activities":
        return this.iconActivities;
      case "tools":
        return this.iconTools;
    }
  }

  useNewUi() {
    // 1 = new version
    this.authService.setUiVersionPreference(1).subscribe({
      next: () => {
        // reload to home page
        window.location.replace("/dashboard");
      },
      error: (err: string) => {
        this.toasterService.error(err);
      },
    });
  }
}

@Pipe({ name: "betaTesterFilterPipe" })
export class BetaTesterFilterPipe implements PipeTransform {
  transform(items: NavData[], betaTest: boolean): NavData[] {
    if (!items) {
      return items;
    }
    return items.filter((item) => !item.inBeta || betaTest);
  }
}
