import { Component, OnInit } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { AccountMarketplace, Marketplace, Strategy } from "@front/m19-api-client";
import { AccountSelectionService } from "@front/m19-services";
import { ICON_CHEVRON_DOWN } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SdStrategyCreationComponent } from "@m19-board/sponsored-display/sd-manager/sd-strategy-creation/sd-strategy-creation.component";
import { MatTooltip } from "@angular/material/tooltip";
import { TranslocoDirective } from "@jsverse/transloco";
import { IButtonComponent } from "@front/m19-ui";

@UntilDestroy()
@Component({
  template: ` <div class="main-container mx-auto mb-3 flex items-center justify-between px-2" *transloco="let t">
      <div class="flex items-center">
        <IButton
          [label]="t('v2-sidebar.sponsored_display')"
          variant="ghost"
          to="/advertising/sponsored-display"
          queryParamsHandling="merge"
          [tooltipValue]="t('sd-strategy-creation-page.back_to_sponsored_display_strategy_list')"
        />
        /
        <IButton [label]="t('sd-strategy-creation-page.new_strategy')" variant="ghost" queryParamsHandling="merge" />
      </div>
      <div>
        {{ t("common.marketplace") }}:&nbsp;<strong>{{ marketplace }}</strong>
      </div>
    </div>
    <app-sd-strategy-creation (strategyCreated)="onStrategyCreated($event)"></app-sd-strategy-creation>`,
  styleUrls: ["../../../strategies/strategies/strategy-styles.scss"],
  standalone: true,
  imports: [SdStrategyCreationComponent, TranslocoDirective, IButtonComponent],
})
export class SdStrategyCreationPageComponent implements OnInit {
  marketplace?: Marketplace;
  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;

  constructor(
    private accountSelection: AccountSelectionService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((am: AccountMarketplace) => {
        this.marketplace = am.marketplace;
      });
  }

  onStrategyCreated(strategy: Strategy) {
    this.router.navigate(["/advertising/sponsored-display/" + strategy.strategyId], { queryParamsHandling: "merge" });
  }
}
