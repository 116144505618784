// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, COLLECTION_FORMATS } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    AllFees,
    BillingPlan,
    Coupon,
    Currency,
    Fee,
    Organization,
    Plan,
    Response,
} from '../models';

export interface CreateBillingPlanRequest {
    billingPlan: BillingPlan;
}

export interface CreatePriceRequest {
    feeType: CreatePriceFeeTypeEnum;
    fee?: Fee;
}

export interface CreateSubscriptionRequest {
    organization: Organization;
    paymentMethodId?: string;
}

export interface GetCouponRequest {
    coupon: string;
}

export interface GetCustomerCurrencyRequest {
    customerId: string;
}

export interface ListBillingPlansRequest {
    plans: Array<string>;
}

export interface SetCouponRequest {
    organizationId: number;
    coupon: string;
}

export interface StopSelfServiceSubscriptionRequest {
    organizationId: number;
}

export interface TransferManagementServiceRequest {
    organizationId: number;
}

export interface UpdateDaysUntilDueRequest {
    organizationId: number;
    daysUntilDue: string;
}

export interface UpdateSubscriptionRequest {
    organizationId: number;
    plan: Plan;
}

/**
 * no description
 */
export class BillingApi extends BaseAPI {

    /**
     * Create a Billing Plan
     * Create a Billing Plan
     */
    createBillingPlan({ billingPlan }: CreateBillingPlanRequest): Observable<BillingPlan>
    createBillingPlan({ billingPlan }: CreateBillingPlanRequest, opts?: OperationOpts): Observable<AjaxResponse<BillingPlan>>
    createBillingPlan({ billingPlan }: CreateBillingPlanRequest, opts?: OperationOpts): Observable<BillingPlan | AjaxResponse<BillingPlan>> {
        throwIfNullOrUndefined(billingPlan, 'billingPlan', 'createBillingPlan');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<BillingPlan>({
            url: '/billing/billingPlans',
            method: 'POST',
            headers,
            body: billingPlan,
        }, opts?.responseOpts);
    };

    /**
     * Create a Price
     * Create a Price
     */
    createPrice({ feeType, fee }: CreatePriceRequest): Observable<Fee>
    createPrice({ feeType, fee }: CreatePriceRequest, opts?: OperationOpts): Observable<AjaxResponse<Fee>>
    createPrice({ feeType, fee }: CreatePriceRequest, opts?: OperationOpts): Observable<Fee | AjaxResponse<Fee>> {
        throwIfNullOrUndefined(feeType, 'feeType', 'createPrice');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'feeType': feeType,
        };

        return this.request<Fee>({
            url: '/billing/price',
            method: 'POST',
            headers,
            query,
            body: fee,
        }, opts?.responseOpts);
    };

    /**
     * Create a subscription
     * create a subscription
     */
    createSubscription({ organization, paymentMethodId }: CreateSubscriptionRequest): Observable<Response>
    createSubscription({ organization, paymentMethodId }: CreateSubscriptionRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    createSubscription({ organization, paymentMethodId }: CreateSubscriptionRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(organization, 'organization', 'createSubscription');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (paymentMethodId != null) { query['paymentMethodId'] = paymentMethodId; }

        return this.request<Response>({
            url: '/billing/subscribe',
            method: 'POST',
            headers,
            query,
            body: organization,
        }, opts?.responseOpts);
    };

    /**
     * Coupon
     */
    getCoupon({ coupon }: GetCouponRequest): Observable<Coupon>
    getCoupon({ coupon }: GetCouponRequest, opts?: OperationOpts): Observable<AjaxResponse<Coupon>>
    getCoupon({ coupon }: GetCouponRequest, opts?: OperationOpts): Observable<Coupon | AjaxResponse<Coupon>> {
        throwIfNullOrUndefined(coupon, 'coupon', 'getCoupon');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'coupon': coupon,
        };

        return this.request<Coupon>({
            url: '/billing/coupon',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Get currency of a given customer
     */
    getCustomerCurrency({ customerId }: GetCustomerCurrencyRequest): Observable<Currency>
    getCustomerCurrency({ customerId }: GetCustomerCurrencyRequest, opts?: OperationOpts): Observable<AjaxResponse<Currency>>
    getCustomerCurrency({ customerId }: GetCustomerCurrencyRequest, opts?: OperationOpts): Observable<Currency | AjaxResponse<Currency>> {
        throwIfNullOrUndefined(customerId, 'customerId', 'getCustomerCurrency');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'customerId': customerId,
        };

        return this.request<Currency>({
            url: '/customerCurrency',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * List all fees
     */
    listAllFees(): Observable<AllFees>
    listAllFees(opts?: OperationOpts): Observable<AjaxResponse<AllFees>>
    listAllFees(opts?: OperationOpts): Observable<AllFees | AjaxResponse<AllFees>> {
        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<AllFees>({
            url: '/allFees',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * list available billing Plans
     * get available billing Plans
     */
    listBillingPlans({ plans }: ListBillingPlansRequest): Observable<Array<BillingPlan>>
    listBillingPlans({ plans }: ListBillingPlansRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<BillingPlan>>>
    listBillingPlans({ plans }: ListBillingPlansRequest, opts?: OperationOpts): Observable<Array<BillingPlan> | AjaxResponse<Array<BillingPlan>>> {
        throwIfNullOrUndefined(plans, 'plans', 'listBillingPlans');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'plans': plans.join(COLLECTION_FORMATS['csv']),
        };

        return this.request<Array<BillingPlan>>({
            url: '/billing/billingPlans',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Coupon
     */
    setCoupon({ organizationId, coupon }: SetCouponRequest): Observable<Organization>
    setCoupon({ organizationId, coupon }: SetCouponRequest, opts?: OperationOpts): Observable<AjaxResponse<Organization>>
    setCoupon({ organizationId, coupon }: SetCouponRequest, opts?: OperationOpts): Observable<Organization | AjaxResponse<Organization>> {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'setCoupon');
        throwIfNullOrUndefined(coupon, 'coupon', 'setCoupon');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'organizationId': organizationId,
            'coupon': coupon,
        };

        return this.request<Organization>({
            url: '/billing/coupon',
            method: 'POST',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Stop a self service subscription
     * stop a self service subscription
     */
    stopSelfServiceSubscription({ organizationId }: StopSelfServiceSubscriptionRequest): Observable<Response>
    stopSelfServiceSubscription({ organizationId }: StopSelfServiceSubscriptionRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    stopSelfServiceSubscription({ organizationId }: StopSelfServiceSubscriptionRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'stopSelfServiceSubscription');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'organizationId': organizationId,
        };

        return this.request<Response>({
            url: '/billing/subscribe',
            method: 'DELETE',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * migrate childOrganization to parent organization
     * migrate childOrganization to parent organization
     */
    transferManagementService({ organizationId }: TransferManagementServiceRequest): Observable<Response>
    transferManagementService({ organizationId }: TransferManagementServiceRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    transferManagementService({ organizationId }: TransferManagementServiceRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'transferManagementService');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'organizationId': organizationId,
        };

        return this.request<Response>({
            url: '/billing/managementTransfer',
            method: 'POST',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Update a days until due
     * update a days until due
     */
    updateDaysUntilDue({ organizationId, daysUntilDue }: UpdateDaysUntilDueRequest): Observable<Response>
    updateDaysUntilDue({ organizationId, daysUntilDue }: UpdateDaysUntilDueRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateDaysUntilDue({ organizationId, daysUntilDue }: UpdateDaysUntilDueRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'updateDaysUntilDue');
        throwIfNullOrUndefined(daysUntilDue, 'daysUntilDue', 'updateDaysUntilDue');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'organizationId': organizationId,
            'daysUntilDue': daysUntilDue,
        };

        return this.request<Response>({
            url: '/billing/daysUntilDue',
            method: 'PUT',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Update a subscription
     * update a subscription
     */
    updateSubscription({ organizationId, plan }: UpdateSubscriptionRequest): Observable<Response>
    updateSubscription({ organizationId, plan }: UpdateSubscriptionRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateSubscription({ organizationId, plan }: UpdateSubscriptionRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'updateSubscription');
        throwIfNullOrUndefined(plan, 'plan', 'updateSubscription');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'organizationId': organizationId,
            'plan': plan,
        };

        return this.request<Response>({
            url: '/billing/subscribe',
            method: 'PUT',
            headers,
            query,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum CreatePriceFeeTypeEnum {
    flatFee = 'flatFee',
    adSpendFee = 'adSpendFee',
    perAccountFee = 'perAccountFee'
}
