<div class="modal-header">
  <h4 class="modal-title pull-left">{{ algoTarget.strategyName }} - {{ getTargetName() }}</h4>
</div>

<div class="modal-body" *transloco="let t">
  @if (algoTarget.algorithm === AlgoMode.ACOS_TARGET) {
    <div class="mt-2">
      <div class="flex items-center justify-between">
        <span class="prepend">{{ t("algo-target-renderer.acos_target") }}</span>
        <div class="w-40">
          <app-integer-value
            testid="acos-target"
            [value]="Math.round(acosTarget * 100)"
            [min]="1"
            [max]="200"
            [unit]="'%'"
            [size]="2.5"
            (onChange)="acosTarget = $event / 100"
          ></app-integer-value>
        </div>
      </div>
      @if (!acosTarget || acosTarget <= 0 || acosTarget > 2) {
        <div class="invalid col-6 pt-2">{{ t("switch-target-algo-modal.range_error") }}</div>
      }
    </div>
  }
  @if (algoTarget.algorithm === AlgoMode.PRODUCT_LAUNCH) {
    <div class="mt-2 w-full">
      <div class="flex items-center justify-between">
        <span class="prepend flex items-center">{{ t("switch-target-algo-modal.suggested_bid") }}</span>
        <div class="w-40">
          <app-decimal-input
            [value]="suggestedBid"
            [precision]="2"
            [trash]="false"
            [withActionButtons]="false"
            [unit]="currencyCode"
            [size]="2.5"
            (onChange)="suggestedBid = $event"
            [invalidInputMessage]="
              t('switch-target-algo-modal.invalid_input_error', [
                minBid + currencyCode,
                dailyBudget | maxAllowedBidPipe,
              ])
            "
          ></app-decimal-input>
        </div>
      </div>
    </div>

    @if (suggestedBid < minBid) {
      <div class="invalid col-6 pt-2">
        {{ t("switch-target-algo-modal.suggestes_bid_min", [minBid + " " + currencySymbol]) }}
      </div>
    }
  }

  @if (algoTarget.dailyBudget || algoTarget.algorithm === AlgoMode.ACOS_TARGET) {
    <div class="mt-2 w-full" *transloco="let t">
      <div class="flex items-center justify-between">
        <span class="prepend"
          >{{
            t("switch-target-algo-modal.average_daily_budget", { avg: algoTarget.algorithm === AlgoMode.ACOS_TARGET })
          }}
        </span>
        <div class="flex w-1/2 gap-x-2">
          <app-integer-value
            class="flex-1"
            [value]="dailyBudget"
            [min]="getDailyBudgetLowerBound()"
            [unit]="currencyCode"
            [size]="2.5"
            (onChange)="dailyBudget = $event"
          />

          @if (algoTarget.algorithm === AlgoMode.ACOS_TARGET && algoTarget.dailyBudget) {
            <IButton
              tooltipValue="{{ t('switch-target-algo-modal.delete_daily_budget') }}"
              [icon]="ICON_TRASH"
              color="gray"
              variant="soft"
              (onClick)="deleteDailyBudget()"
            />
          }
        </div>
      </div>

      @if (
        (!dailyBudget || dailyBudget < getDailyBudgetLowerBound()) && algoTarget.algorithm !== AlgoMode.ACOS_TARGET
      ) {
        <div class="invalid col-6 pt-2">
          {{ t("switch-target-algo-modal.dialy_budget_min", [getDailyBudgetLowerBound()]) }}
        </div>
      }
      @if (dailyBudget >= getDailyBudgetLowerBound() && 5 * suggestedBid > dailyBudget) {
        <div class="invalid col-6 pt-2">{{ t("switch-target-algo-modal.budget_five_time_bid") }}</div>
      }
    </div>
  }

  @if (algoTarget.algorithm === AlgoMode.MONTHLY_BUDGET_TARGET) {
    <div class="mt-2 w-full" *transloco="let t">
      <div class="mb-3 flex items-center justify-between">
        <span class="prepend flex items-center">{{ t("strategy-page.current_spend") }}</span>
        <app-spend-progress-bar
          class="w-40"
          [currentMonthSpend]="currentMonthSpend"
          [monthlyBudget]="monthlyBudget"
          [endOfCurrentMonth]="endOfCurrentMonth"
          [currencyCode]="currencyCode"
          [locale]="locale"
        >
        </app-spend-progress-bar>
      </div>
      <div class="flex items-center justify-between">
        <span class="prepend flex items-center">{{ t("metrics.MONTHLY_BUDGET_tooltip") }}</span>
        <div class="w-40">
          <app-integer-value
            [value]="monthlyBudget"
            [min]="1"
            [max]="null"
            [unit]="currencyCode"
            [size]="2.5"
            (onChange)="monthlyBudget = $event"
          ></app-integer-value>
        </div>
      </div>
      <div class="date">{{ getEndOfMonth() }}</div>
      @if (!monthlyBudget || monthlyBudget <= 0) {
        <div class="invalid col-6 pt-2">{{ t("switch-target-algo-modal.positive_budget") }}</div>
      }
      <div class="mt-2 flex items-center justify-between" *transloco="let t">
        <span class="prepend flex items-center">{{ t("switch-target-algo-modal.next_monthly_budget") }}</span>
        <div class="flex w-40">
          <div class="w-full">
            <app-integer-value
              [value]="nextMonthlyBudget ?? monthlyBudget"
              [min]="1"
              [max]="null"
              [unit]="currencyCode"
              [size]="2.5"
              (onChange)="nextMonthlyBudget = $event"
            ></app-integer-value>
          </div>
          @if (nextMonthlyBudget) {
            <IButton
              [icon]="ICON_SYNC"
              color="gray"
              variant="ghost"
              (onClick)="nextMonthlyBudget = undefined"
              tooltipValue="{{ t('switch-target-algo-modal.keep_mothly_budget_tooltip') }}"
            />
          }
        </div>
      </div>
      <div class="date">{{ getNextMonth() }}</div>
      @if (!nextMonthlyBudget && nextMonthlyBudget <= 0) {
        <div class="invalid col-6 pt-2">{{ t("switch-target-algo-modal.positive_budget") }}</div>
      }
    </div>
  }
</div>
<div class="modal-footer" *transloco="let t">
  <IButton label="{{ t('common.cancel') }}" color="gray" variant="soft" (onClick)="bsModalRef.hide()" />
  <IButton
    testid="btn-confirm"
    class="w-2/4"
    label="{{ t('common.update') }}"
    type="submit"
    [loading]="updateLoading"
    (onClick)="update()"
    [block]="true"
  />
</div>
