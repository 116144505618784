import { Clipboard } from "@angular/cdk/clipboard";
import { AfterViewInit, Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { FormControl, FormsModule } from "@angular/forms";

import {
  AccountMarketplaceService,
  AuthService,
  OrganizationAccountGroupService,
  OrganizationService,
  OrganizationUsersService,
} from "@front/m19-services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { AgGridAngular } from "@ag-grid-community/angular";
import {
  ColDef,
  FirstDataRenderedEvent,
  GetRowIdParams,
  GridOptions,
  ICellRendererParams,
  IRowNode,
  RowGroupOpenedEvent,
  SelectionChangedEvent,
  ValueGetterParams,
} from "@ag-grid-community/core";
import { Router } from "@angular/router";
import {
  AccessLevel,
  AccountMarketplace,
  AccountState,
  AccountSubType,
  AccountType,
  AuthorizedAccess,
  BidderRun,
  Marketplace,
  OrganizationUser,
  User,
} from "@front/m19-api-client";
import { getBasicGridOptions } from "@front/m19-grid-config";
import { AccountGroup, AccountMarketplaceEx, OrganizationAccountGroups, organizationCompare } from "@front/m19-models";
import { IButtonComponent, ModalService } from "@front/m19-ui";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { MarketplaceColumn } from "@m19-board/grid-config/grid-columns";
import { LayoutSelectorService } from "@m19-board/layout-selector.service";
import { OverlayComponent } from "@m19-board/overlay/overlay.component";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";
import { ICON_CHEVRON_DOWN, ICON_COPY_O, ICON_EDIT_O, ICON_TRASH_O, ICON_USER_KEY } from "@m19-board/utils/iconsLabels";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { combineLatest, forkJoin, map, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import {
  ActionButton,
  ActionButtonsComponent,
} from "../../insights/advertising-stats/action-buttons/action-buttons.component";
import { InputModalComponent } from "../../shared/input-modal/input-modal.component";
import { AccountSettingsService } from "../account-settings.service";
import { UpdateCustomCampaignNameComponent } from "./account-group/custom-campaign-name-pop-up/update-custom-campaign-name.component";
import { ManageAuthorizedUsersModalComponent } from "./account-group/manage-authorized-users-modal/manage-authorized-users-modal.component";
import { ModalUpdateMaxBidComponent, UpdateMaxBidData } from "./account-group/max-bid-pop-up/update-max-bid.popup";
import { AccountNameLinkComponent } from "./account-name-link/account-name-link.component";
import { ModalUpdateVendorMetricsComponent } from "./custom-campaign-name-pop-up/vendor-metrics.popup";
import { LinkDspModalComponent, LinkDspModalData } from "./link-dsp.component";
import { RenewAmazonAccessModalComponent } from "./renew-amazon-access/renew-amazon-access-modal.component";
import { ShowDspComponent } from "./show-dsp.component";

export interface AccountGridData {
  rowId: string;
  organizationId: number;
  profileId: number;
  organizationName: string;
  accountGroupName: string;
  accountName: string;
  marketplace: Marketplace;
  accountType: AccountType;
  accountGroupId: number;
  accountId: string;
  bidderEndDate: string;
  state: AccountState;
  campaignLabel: string;
  vendorValidationData?: number;
  accessLevel: AccessLevel;
  dspAdvertiserId?: string;
}

@Component({
  templateUrl: "new-account-group-popup.component.html",
  standalone: true,
  imports: [TranslocoDirective, FormsModule, SpinnerComponent],
})
export class ModalCreateAccountGroupComponent {
  name = "";
  selectedAccounts?: AccountMarketplace[];
  selection?: Set<string>;
  type?: AccountType;
  loading = false;

  @Output() createAccountGroup = new EventEmitter<boolean>();

  constructor(
    public bsModalRef: BsModalRef,
    private accountMarketplaceService: AccountMarketplaceService,
    private toasterService: ToastrService,
  ) {}

  moveToNewAccountGroup() {
    this.loading = true;
    this.accountMarketplaceService.newAccountGroup(
      this.selectedAccounts![0],
      this.name,
      (gid) => {
        this.toasterService.success("Account group " + this.name + " successfully created.", "Account group created");
        for (let i = 0; i < this.selectedAccounts!.length; i++) {
          this.accountMarketplaceService.moveAccountToGroup(
            this.selectedAccounts![i],
            gid,
            this.name,
            () => {
              this.bsModalRef.hide();
            },
            (error: string) => {
              this.toasterService.error(error, "Account group creation error");
              this.bsModalRef.hide();
            },
          );
          this.loading = false;
        }
        this.selection?.clear();
      },
      (error: string) => {
        this.toasterService.error(error, "Account Group Creation");
        this.bsModalRef.hide();
        this.loading = false;
      },
    );
  }

  create() {
    this.moveToNewAccountGroup();
    this.createAccountGroup.emit(true);
  }
}

function accountMarketplaceOrganizationKey(accountId: string, marketplace: Marketplace, organizationId: number) {
  return `${accountId}_${marketplace}_${organizationId}`;
}

function organizationRessourceKey(organizationId: number, ressourceId: number) {
  return `${organizationId}_${ressourceId}`;
}

@UntilDestroy()
@Component({
  selector: "app-account-setting",
  templateUrl: "./account-setting.component.html",
  standalone: true,
  imports: [TranslocoDirective, OverlayComponent, AgGridAngular, SpinnerComponent],
})
export class AccountSettingComponent implements AfterViewInit {
  readonly M19_LABEL = "m19";

  // BIDDER_ON_TEXT_STATUS = this.translocoService.translate("account-setting.active_full_automation");

  GRID_KEY = "accountSettingsGrid";

  private orgHasActiveSub?: Map<string, boolean>;
  isAllowedWhiteLabel: boolean | undefined = false;
  isAllowedCustomCampaignName?: boolean;
  adminOrganizationIds: Set<number> = new Set<number>();
  authorizedAccessByProfileId = new Map<number, AuthorizedAccess[]>();
  userId: number | undefined = undefined;

  organizationMap = new Map<number, OrganizationAccountGroups>();
  accountGroupMap = new Map<number, AccountGroup>();
  accountMpMap = new Map<string, AccountMarketplace>();
  orgProfileIdMap = new Map<string, AccountMarketplaceEx>();

  accountGroupSelectionId = -1; // The account group related to user row selection

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  canLoginWithAmazon = new Map<number, boolean>();
  loading = false;
  marketplaceColumn: ColDef = {
    ...MarketplaceColumn(this.translocoService),
    headerName: this.translocoService.translate("common.marketplace", {}, "en"),
    headerValueGetter: (params) => this.translocoService.translate("common.marketplace"),
  };

  constructor(
    private organizationAccountGroupService: OrganizationAccountGroupService,
    private authService: AuthService,
    private organizationUsersService: OrganizationUsersService,
    private accountMarketplaceService: AccountMarketplaceService,
    private organizationService: OrganizationService,
    private toasterService: ToastrService,
    private modalService: BsModalService,
    private newModalService: ModalService,
    private clipboard: Clipboard,
    private accountSettingsService: AccountSettingsService,
    private layoutSelectorService: LayoutSelectorService,
    private translocoService: TranslocoService,
    private router: Router,
  ) {
    this.layoutSelectorService.canLoginWithAmazon$.pipe(untilDestroyed(this)).subscribe((canLoginWithAmazon) => {
      this.canLoginWithAmazon = canLoginWithAmazon;
    });
  }

  private columnDefs: ColDef<AccountGridData>[] = [
    {
      field: "organizationId",
      headerName: this.translocoService.translate("account-setting.organization", {}, "en"),
      headerValueGetter: (params) => this.translocoService.translate("account-setting.organization"),
      filter: "agTextColumnFilter",
      colId: "orgName",
      rowGroup: true,
      hide: true,
      valueFormatter: (params) => {
        return params.node?.allLeafChildren[0].data?.organizationName ?? "";
      },
    },
    {
      field: "accountGroupName",
      headerName: this.translocoService.translate("account-setting.account_group", {}, "en"),
      headerValueGetter: (params) => this.translocoService.translate("account-setting.account_group"),
      filter: "agTextColumnFilter",
      colId: "accGroupName",
      rowGroup: true,
      hide: true,
    },
    this.marketplaceColumn,
    {
      colId: "campLabelCol",
      headerName: this.translocoService.translate("account-setting.campaign_label", {}, "en"),
      headerValueGetter: (params) => this.translocoService.translate("account-setting.campaign_label"),
      filter: false,
      floatingFilter: false,
      valueGetter: (params) => {
        return params.data?.state == AccountState.BIDDER_ON ? (params.data?.campaignLabel ?? this.M19_LABEL) : "";
      },
      cellRendererSelector: (params) => {
        if (params.node.group) return undefined;
        if (!params.value) return undefined;
        if (this.isAllowedWhiteLabel) {
          return {
            component: IButtonComponent,
            params: {
              color: "gray",
              icon: ICON_EDIT_O,
              trailing: true,
              iconOnHover: true,
              variant: "ghost",
              label: params.value ?? this.M19_LABEL,
              tooltipValue: this.translocoService.translate("account-setting.edit_campaign_label"),
              clickAction: () => {
                const formInput = new FormControl(params.value ?? this.M19_LABEL);

                const modalOptions: ModalOptions = {
                  initialState: {
                    title: this.translocoService.translate("account-setting.edit_campaign_label"),
                    inputControl: formInput,
                  },
                  class: "modal-primary",
                };
                const ref = this.modalService.show(InputModalComponent, modalOptions);
                ref.content?.emitUpdate.subscribe(() => {
                  const newName: string = formInput.value;
                  const accMp: AccountMarketplace = this.accountMpMap.get(
                    accountMarketplaceOrganizationKey(
                      params.node.data!.accountId!,
                      params.node.data!.marketplace!,
                      params.node.data!.organizationId!,
                    ),
                  )!;
                  this.changeCampaignLabel(accMp, newName);
                });
              },
            },
          };
        }
        return undefined;
      },
    },
    {
      headerName: this.translocoService.translate("billing-invoices.status", {}, "en"),
      headerValueGetter: (params) => this.translocoService.translate("billing-invoices.status"),
      colId: "statusCol",
      suppressHeaderMenuButton: false,
      floatingFilter: true,
      field: "state",
      filterValueGetter: (params: ValueGetterParams<AccountGridData>) => {
        return this.getAccountStatusText(params.node!);
      },
      cellRendererSelector: (params: ICellRendererParams<AccountGridData>) => {
        if (params.node.group) return undefined;

        const isAdmin = this.adminOrganizationIds.has(params.data!.organizationId);
        const dropdown: ActionButton = this.getAccountStatusItems(params, isAdmin);

        return {
          component: ActionButtonsComponent,
          params: {
            actionButtons: [dropdown],
          },
        };
      },
    },
    {
      colId: "manageCol",
      headerName: this.translocoService.translate("account-setting.permissions", {}, "en"),
      headerValueGetter: (params) => this.translocoService.translate("account-setting.permissions"),
      filter: false,
      suppressHeaderMenuButton: true,
      floatingFilter: false,
      cellRendererSelector: (params) => {
        if (params.node.group) return undefined;
        const accessUserCount: number = this.getAuthorizedUsersCount(params.data!.profileId);
        if (
          !this.isAdminOrOwner(params.node.data!.organizationId) ||
          !this.orgHasActiveSub?.get(params.node.data!.organizationName)
        )
          return undefined;
        const isReadOnly = this.isReadOnly({ accessLevel: params.data!.accessLevel });
        return {
          component: ActionButtonsComponent,
          params: {
            actionButtons: [
              {
                icon: ICON_USER_KEY,
                tooltip: isReadOnly ? "" : this.translocoService.translate("account-setting.manage_access"),
                disabled: isReadOnly,
                onClick: (params: any) => {
                  if (isReadOnly) return;
                  this.manageAuthorizedUsers(params.data);
                },
                badge: accessUserCount > 0 ? accessUserCount : undefined,
              },
            ],
          },
        };
      },
    },
    {
      field: "accountType",
      headerName: this.translocoService.translate("dsp-stats.creativeType", {}, "en"),
      headerValueGetter: (params) => this.translocoService.translate("dsp-stats.creativeType"),
      colId: "accType",
      enableRowGroup: true,
      valueGetter: (params) => {
        switch (params.data?.accountType) {
          case AccountType.SELLER:
            return this.translocoService.translate("common.seller");
          case AccountType.VENDOR:
            return this.translocoService.translate("common.vendor");
          default:
            return "-";
        }
      },
      floatingFilter: true,
      filter: "agSetColumnFilter",
    },
    {
      filter: false,
      floatingFilter: false,
      pinned: "right",
      headerName: this.translocoService.translate("common.settings", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("common.settings"),
      suppressHeaderMenuButton: true,
      colId: "actionCol",
      cellRendererSelector: (params: ICellRendererParams<AccountGridData>) => {
        if (!params.node.group) {
          const accMp: AccountMarketplace = this.accountMpMap.get(
            accountMarketplaceOrganizationKey(
              params.data!.accountId,
              params.data!.marketplace,
              params.data!.organizationId,
            ),
          )!;

          const actionButtons: ActionButton[] = [
            {
              icon: ICON_COPY_O,
              tooltip: this.translocoService.translate("account-setting.copy_account_id"),
              onClick: (params: ICellRendererParams<AccountGridData>) => {
                const accountId: string = params.data!.accountId;
                this.clipboard.copy(accountId);
                this.toasterService.success(
                  this.translocoService.translate("account-setting.accountid_copied_to_clipboard", [accountId]),
                  undefined,
                  { timeOut: 2000 },
                );
              },
            },
            {
              icon: "icon-[mdi--cloud-arrow-up-outline]",
              tooltip: this.bidderTooltip(accMp),
              disabled: !this.canRunBidder(accMp) || this.isReadOnly({ accessLevel: accMp.accessLevel! }),
              onClick: (params) => {
                this.runBidder(accMp);
              },
            },
          ];

          const subItems: ActionButton[] = [];
          subItems.push({
            title: this.translocoService.translate("account-setting.display_amazon_user_access_emails"),
            onClick: (c) => {
              this.displayAmazonUserAccess(
                this.orgProfileIdMap.get(
                  organizationRessourceKey(params.data!.organizationId, params.data!.profileId),
                )!,
              );
            },
          });
          if (
            (accMp.accessLevel == AccessLevel.ADMIN || accMp.accessLevel == AccessLevel.WRITE) &&
            accMp.state == AccountState.BIDDER_ON
          ) {
            subItems.push({
              title: this.translocoService.translate("account-setting.update_max_bid"),
              onClick: (params) => {
                this.updateMaxBid(accMp);
              },
            });
          }
          if (this.isAllowedCustomCampaignName && accMp.state == AccountState.BIDDER_ON)
            subItems.push({
              title: this.translocoService.translate("account-setting.update_custom_campaign_name"),
              onClick: (params) => {
                this.updateCustomCampaignName(accMp);
              },
            });
          if (accMp.accessLevel == AccessLevel.ADMIN || accMp.accessLevel == AccessLevel.WRITE) {
            if (accMp.accountType == AccountType.VENDOR)
              subItems.push({
                title: this.translocoService.translate("account-setting.select_vendor_metrics_sourcing_manufacturing"),
                onClick: (params) => this.updateVendorSourcingMetric(accMp),
              });
          }
          if (accMp.accessLevel == AccessLevel.ADMIN) {
            if (!accMp.dspAdvertiserId) {
              subItems.push({
                title: this.translocoService.translate("account-setting.link_your_dsp_advertising_account"),
                onClick: (params) => {
                  this.newModalService.openModal<LinkDspModalData, void>(LinkDspModalComponent, {
                    data: {
                      accountMarketplace: accMp,
                    },
                  });
                },
              });
            } else {
              subItems.push({
                title: this.translocoService.translate("account-setting.display_dsp_advertising_account_information"),
                onClick: (params) => {
                  this.modalService.show(ShowDspComponent, {
                    initialState: { dspAdvertiserId: accMp.dspAdvertiserId, accountMarketplace: accMp },
                    class: "modal-lg modal-primary",
                  });
                },
              });
            }
          }
          actionButtons.push({
            icon: "icon-[mdi--ellipsis-horizontal]",
            tooltip: this.translocoService.translate("common.more_actions"),
            subItems,
          });

          if (!this.isReadOnly({ accessLevel: accMp.accessLevel! })) {
            const mainAction: ActionButton = this.getAccountMainAction(accMp, params.data!.vendorValidationData);
            if (mainAction) actionButtons.push(mainAction);
          }

          return {
            component: ActionButtonsComponent,
            params: {
              actionButtons,
            },
          };
        }
        if (params.node.group && params.node.rowGroupColumn!.getId() === "orgName") {
          const buttons: ActionButton[] = [
            {
              text: this.translocoService.translate("account-setting.add_account"),
              disabled: !this.canAddAccounts(params.node),
              onClick: (params) => {
                const orgId = this.getOrgIdFromNode(params.node!);
                this.router.navigate(["/dashboard/account-linking"], {
                  queryParams: { orgId },
                });
              },
            },
            {
              icon: "icon-[lucide--tag]",
              tooltip: this.translocoService.translate("account-setting.edit_default_campaign_label"),
              onClick: (params) => {
                const orgId = this.getOrgIdFromNode(params.node!);
                const org = this.organizationMap.get(orgId);
                if (!org) return;
                const formInput = new FormControl(org.organization.defaultCampaignLabel ?? "");
                const modalOptions: ModalOptions = {
                  initialState: {
                    title: this.translocoService.translate("account-setting.edit_default_campaign_label"),
                    inputControl: formInput,
                    maxLength: 10,
                  },
                  class: "modal-primary",
                };
                const ref = this.modalService.show(InputModalComponent, modalOptions);
                ref.content?.emitUpdate.subscribe(() => {
                  const newDefaultCampaignLabel: string = formInput.value ?? "";
                  this.changeDefaultCampaignLabel(orgId, newDefaultCampaignLabel);
                });
              },
            },
          ];
          return {
            component: ActionButtonsComponent,
            params: {
              actionButtons: buttons,
            },
          };
        }
        if (
          params.node.group &&
          params.node.rowGroupColumn!.getId() === "accGroupName" &&
          this.accountGroupSelectionId !== -1 &&
          this.accountGroupSelectionId === this.getGroupIdFromNode(params.node)
        ) {
          const allowedGroups = this.getAllowedGroupsForGroup(params.node);
          return {
            component: ActionButtonsComponent,
            params: {
              actionButtons: [
                {
                  text: this.translocoService.translate("strategy-group-move-product-modal.move_to"),
                  subItems: [
                    {
                      title: this.translocoService.translate("account-setting.new_account_group"),
                      onClick: () => {
                        this.moveToNewAccountGroup();
                      },
                    },
                  ].concat(
                    allowedGroups.map((g: AccountGroup) => ({
                      title: g.name,
                      onClick: () => {
                        this.moveToGroup(g);
                      },
                    })),
                  ),
                },
                {
                  icon: ICON_TRASH_O,
                  tooltip: this.invalidStatusChange()
                    ? this.translocoService.translate("account-setting.fully_automated_accounts_cant_be_deleted")
                    : this.translocoService.translate("account-setting.remove_selected_marketplace_s"),
                  disabled: this.invalidStatusChange(),
                  onClick: () => {
                    this.removeSelected();
                  },
                },
              ],
            },
          };
        }
        return undefined;
      },
    },
  ];

  readonly autoGroupColDef: ColDef = {
    filter: "agTextColumnFilter",
    floatingFilter: true,
    flex: 1,
    headerName: this.translocoService.translate("account-setting.organization_group_account", {}, "en"),
    headerValueGetter: (params) => this.translocoService.translate("account-setting.organization_group_account"),
    filterValueGetter: (params: ValueGetterParams<AccountGridData>) => {
      // concat organization, account group name, account name to enable filtering on all column values
      return (
        params.data?.organizationName +
        " " +
        params.data?.accountGroupName +
        " " +
        params.data?.accountName +
        " " +
        params.data?.accountId
      );
    },
    cellClass: "sensitive-data",
    field: "accountName",
    minWidth: 250,
    cellRendererSelector: (params: ICellRendererParams<AccountGridData>) => {
      if (!params.node.group) {
        const accMp: AccountMarketplace = this.accountMpMap.get(
          accountMarketplaceOrganizationKey(
            params.data!.accountId,
            params.data!.marketplace,
            params.data!.organizationId,
          ),
        )!;
        return {
          component: "agGroupCellRenderer",
          params: {
            innerRenderer: AccountNameLinkComponent,
            innerRendererParams: {
              accountMarketplace: accMp,
            },
            checkbox: true,
          },
        };
      }
      return {
        component: "agGroupCellRenderer",
        params: this.isAccountGroupNode(params) ? this.getAccountGroupRenderer(params) : undefined,
      };
    },
  };

  readonly defaultColDefs: ColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    suppressMovable: true,
    menuTabs: ["filterMenuTab"],
  };

  gridOptions: GridOptions = {
    ...getBasicGridOptions(this.GRID_KEY),
    rowSelection: "multiple",
    suppressScrollOnNewData: true,
    suppressRowClickSelection: true,
    showOpenedGroup: false,
    columnDefs: this.columnDefs,
    context: { componentParent: this },
    defaultColDef: this.defaultColDefs,
    autoGroupColumnDef: this.autoGroupColDef,
    groupDisplayType: "singleColumn",
    enableRangeSelection: false,
    groupDefaultExpanded: -1,
    onFirstDataRendered: (event: FirstDataRenderedEvent) => {
      event.api.autoSizeColumns(["manageCol", "marketplace"]);
    },
    onSelectionChanged: (event: SelectionChangedEvent<any>) => this.onSelectionChanged(event),
    onRowGroupOpened: (event: RowGroupOpenedEvent) => {
      // auto size the column action when expanding / collapsing row
      event.api.autoSizeColumns(["actionCol"]);
    },
    getRowId: (params: GetRowIdParams) => params.data.rowId,
    sideBar: false,
  };

  ngAfterViewInit(): void {
    const gridData$: Observable<AccountGridData[]> =
      this.organizationAccountGroupService.allOrganizationAccountGroups$.pipe(
        untilDestroyed(this),
        tap((orgs: OrganizationAccountGroups[] | undefined) => {
          this.orgHasActiveSub = new Map();
          orgs?.forEach((o: OrganizationAccountGroups) =>
            this.orgHasActiveSub!.set(o.organizationName, o.hasActiveSubscription()),
          );
        }),
        map((orgs: OrganizationAccountGroups[] | undefined) => {
          const gridData: AccountGridData[] = [];
          orgs
            ?.filter((o: OrganizationAccountGroups) => o.accountGroups && o.accountGroups.length > 0)
            .sort(organizationCompare)
            .forEach((o: OrganizationAccountGroups) => {
              this.organizationMap.set(o.id, o);
              o.accountGroups.forEach((a: AccountGroup) => {
                this.accountGroupMap.set(a.id, a);
                a.resources.forEach((r) => {
                  this.orgProfileIdMap.set(organizationRessourceKey(r.resourceOrganizationId!, r.profileId!), r);
                  this.accountMpMap.set(
                    accountMarketplaceOrganizationKey(r.accountId, r.marketplace, r.resourceOrganizationId!),
                    r,
                  );
                  gridData.push({
                    rowId: o.id + "_" + r.accountId + "_" + r.marketplace,
                    organizationId: o.id,
                    profileId: r.profileId!,
                    organizationName: o.organizationName,
                    accountGroupName: a.name,
                    accountName: r.accountName,
                    marketplace: r.marketplace,
                    accountType: r.accountType!,
                    accountGroupId: a.id,
                    accountId: r.accountId,
                    bidderEndDate: r.bidderEndDate!,
                    state: r.state!,
                    campaignLabel: r.campaignLabel!,
                    vendorValidationData: r.nbAsinsToValidate,
                    accessLevel: r.accessLevel!,
                    dspAdvertiserId: r.dspAdvertiserId,
                  });
                });
              });
            });
          return gridData;
        }),
      );

    combineLatest([
      this.authService.loggedUser$,
      this.organizationUsersService.listOrganizations(),
      gridData$,
      this.organizationUsersService.listAuthorizedAccess(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([user, orgUsers, gridData, accesses]: [User, OrganizationUser[], AccountGridData[], AuthorizedAccess[]]) => {
          this.userId = user?.userId;
          this.isAllowedWhiteLabel = user?.allowWhiteLabel;
          if (!this.isAllowedWhiteLabel) {
            this.agGrid?.api.setGridOption(
              "columnDefs",
              this.agGrid?.api.getColumnDefs()?.filter((c: ColDef) => c.colId !== "campLabelCol"),
            );
          }
          this.isAllowedCustomCampaignName = user?.allowCustomCampaignName;
          this.adminOrganizationIds = new Set(
            orgUsers.filter((u) => u.m19UserId == this.userId).map((a) => a.organizationId!),
          );
          this.authorizedAccessByProfileId.clear();
          for (const access of accesses) {
            if (!this.authorizedAccessByProfileId.has(access.profileId!)) {
              this.authorizedAccessByProfileId.set(access.profileId!, []);
            }
            this.authorizedAccessByProfileId.get(access.profileId!)!.push(access);
          }
          this.agGrid?.api.setGridOption("rowData", gridData);
        },
      );
  }

  private getAccountGroupRenderer(params: ICellRendererParams<AccountGridData>) {
    return {
      innerRenderer: IButtonComponent,
      innerRendererParams: {
        label: params.value,
        variant: "ghost",
        color: "gray",
        icon: ICON_EDIT_O,
        iconOnHover: true,
        tooltipValue: this.translocoService.translate("account-setting.edit_account_group_name"),
        trailing: true,
        clickAction: () => {
          const testInput = new FormControl(params.value);

          const ref = this.modalService.show(InputModalComponent, {
            initialState: { title: "Change Account Group Name", inputControl: testInput, maxLength: 150 },
            class: "modal-primary",
          });
          ref.content?.emitUpdate.subscribe(() => {
            const newName: string = testInput.value;
            const accGroupId: number = this.getGroupIdFromNode(params.node);

            if (newName.trim() != "") {
              this.accountMarketplaceService.renameAccountGroup(
                this.accountGroupMap.get(accGroupId)!,
                newName.trim(),
                () => {
                  this.toasterService.success(
                    "Successfully renamed account group to " + newName,
                    "Account group renamed",
                  );
                },
                (error: string) => this.toasterService.error(error, "Account group renaming error"),
              );
            }
          });
        },
      },
    };
  }

  getAuthorizedUsersCount(profileId: number): number {
    return this.authorizedAccessByProfileId.get(profileId)?.length ?? 0;
  }

  private isAccountGroupNode(params: ICellRendererParams<AccountGridData>): boolean {
    return !!params.node.group && params.node.field === "accountGroupName";
  }

  isAdminOrOwner(orgId: number): boolean {
    return !!orgId && this.adminOrganizationIds.has(orgId);
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    const selectedNodes = event.api.getSelectedNodes();
    if (selectedNodes.length > 0) this.accountGroupSelectionId = selectedNodes[0].data.accountGroupId;
    else this.accountGroupSelectionId = -1;
    event.api.redrawRows();
    event.api.autoSizeColumns(["actionCol"]);
  }

  private getOrgIdFromNode(node: IRowNode) {
    let orgId;
    if (node.group) {
      orgId = node.allLeafChildren[0].data.organizationId;
    } else orgId = node.data.organizationId;

    return orgId;
  }

  private getGroupIdFromNode(node: IRowNode) {
    let groupId;
    if (node.group) {
      groupId = node.allLeafChildren[0].data.accountGroupId;
    } else groupId = node.data.accountGroupId;
    return groupId;
  }

  canAddAccounts(node: IRowNode): boolean {
    // We are on a grouped row by organization or non-grouped row
    return this.adminOrganizationIds.has(this.getOrgIdFromNode(node));
  }

  moveToGroup(newGroup: AccountGroup) {
    let count = 0;
    this.agGrid?.api
      .getSelectedNodes()
      .map((node: IRowNode) =>
        this.accountMpMap.get(
          accountMarketplaceOrganizationKey(node.data.accountId, node.data.marketplace, node.data.organizationId),
        ),
      )
      .forEach((accMp: AccountMarketplace | undefined) => {
        if (!accMp) return;
        if (newGroup.containsMktp(accMp.marketplace))
          this.toasterService.error(
            accMp.accountName +
              " has not been moved as there is an account with the same marketplace in " +
              newGroup.name,
            "Move Account",
          );
        else {
          this.moveSingleAccount(accMp, newGroup);
          count++;
        }
      });
    // Success toaster shows without knowing if the API call successfully got through
    if (count > 0) {
      this.agGrid?.api.deselectAll(); // deselect all rows
      this.toasterService.success(
        count + " account(s) have successfully been moved to " + newGroup.name,
        "Account moved",
      );
    }
  }

  moveSingleAccount(acc: AccountMarketplace, newGroup: AccountGroup) {
    this.accountMarketplaceService.moveAccountToGroup(
      acc,
      newGroup.id,
      newGroup.name,
      () => {
        this.toasterService.success("Successfully moved the account to " + newGroup.name, "Account group moved");
        this.accountGroupSelectionId = -1;
        this.agGrid?.api.refreshCells();
      },
      (error: string) => {
        this.toasterService.error(error, "Account group move error");
        this.accountGroupSelectionId = -1;
        this.agGrid?.api.refreshCells();
      },
    );
  }

  moveToNewAccountGroup() {
    const selectedAccounts: AccountMarketplace[] = this.agGrid?.api
      .getSelectedNodes()
      .map(
        (node: IRowNode) =>
          this.accountMpMap.get(
            accountMarketplaceOrganizationKey(node.data.accountId, node.data.marketplace, node.data.organizationId),
          )!,
      );

    const modalOptions: ModalOptions = {
      initialState: {
        selectedAccounts: selectedAccounts,
      },
      class: "modal-primary",
    };
    const ref = this.modalService.show(ModalCreateAccountGroupComponent, modalOptions);
    ref.content?.createAccountGroup.subscribe(() => {
      this.accountGroupSelectionId = -1;
      this.agGrid?.api.deselectAll(); // deselect all rows
      this.agGrid?.api.refreshCells();
    });
  }

  invalidStatusChange(): boolean {
    const selectedAccounts: AccountGridData[] = this.agGrid?.api.getSelectedNodes().map((node: IRowNode) => node.data);
    return selectedAccounts.some((acc) => {
      const accMp: AccountMarketplace = this.accountMpMap.get(
        accountMarketplaceOrganizationKey(acc.accountId, acc.marketplace, acc.organizationId),
      )!;
      return acc.state === AccountState.BIDDER_ON && accMp.hasAccessToAdvertising;
    });
  }

  getAllowedGroupsForGroup(node: IRowNode) {
    const relatedOrg: OrganizationAccountGroups = this.organizationMap.get(this.getOrgIdFromNode(node))!;
    const relatedGroup: AccountGroup = this.accountGroupMap.get(this.getGroupIdFromNode(node))!;

    return relatedOrg.accountGroups.filter((group) => group.type == relatedGroup.type && group.id != relatedGroup.id);
  }

  removeAccounts(accounts: AccountMarketplace[]) {
    forkJoin(accounts.map((am) => this.accountMarketplaceService.removeAccountMarketplaceV2(am))).subscribe({
      next: () => {
        this.toasterService.success("Account(s) have been removed", "Account state updated");
        window.location.reload(); // reload the page to refresh the grid
      },
      error: (error: string) => {
        this.toasterService.error(error, "Account state update error");
      },
    });
  }

  removeSelected() {
    const toRemove = this.agGrid?.api
      .getSelectedNodes()
      .map((node: IRowNode) =>
        this.accountMpMap.get(
          accountMarketplaceOrganizationKey(node.data.accountId, node.data.marketplace, node.data.organizationId),
        ),
      )
      .filter((acc: AccountMarketplace | undefined): acc is AccountMarketplace => acc !== undefined);
    this.removeAccounts(toRemove);
  }

  changeCampaignLabel(acc: AccountMarketplace, newCampaignLabel: string) {
    if (!newCampaignLabel?.trim()) return;
    newCampaignLabel = newCampaignLabel.trim();
    if ((!acc.campaignLabel && newCampaignLabel == this.M19_LABEL) || newCampaignLabel == acc.campaignLabel) return;
    this.accountMarketplaceService.changeCampaignLabel(
      acc,
      newCampaignLabel,
      () =>
        this.toasterService.success(
          "Successfully changed the campaign label to " + newCampaignLabel,
          "Campaign label updated",
        ),
      (error: string) => this.toasterService.error(error, "Campaign label update error"),
    );
  }

  changeDefaultCampaignLabel(organizationId: number, newCampaignLabel: string) {
    newCampaignLabel = newCampaignLabel?.trim() ?? null;
    this.organizationService.updateDefaultCampaignLabel(organizationId, newCampaignLabel).subscribe({
      next: () => {
        this.toasterService.success("Default campaign label updated");
      },
      error: (error: string) => this.toasterService.error(error, "Campaign label update error"),
    });
  }

  getAccountStatusText(node: IRowNode) {
    const data: AccountGridData = node.data;
    const accountMp: AccountMarketplace = this.accountMpMap.get(
      accountMarketplaceOrganizationKey(data.accountId, data.marketplace, data.organizationId),
    )!;

    if (!accountMp.hasAccessToAdvertising)
      return this.translocoService.translate("account-setting.advertising_access_lost");
    if (accountMp.state == AccountState.BIDDER_ON)
      return this.translocoService.translate("account-setting.active_full_automation");
    if (accountMp.state == AccountState.PAYMENT_ISSUE)
      return this.translocoService.translate("account-setting.payment_issue");
    if (!accountMp.activated) return this.translocoService.translate("account-setting.manual_setup_required");
    return this.translocoService.translate("account-setting.active_pull_stats");
  }

  private getAccountStatusItems(params: ICellRendererParams, isAdmin: boolean): ActionButton {
    const accMp: AccountMarketplace = this.accountMpMap.get(
      accountMarketplaceOrganizationKey(params.data.accountId, params.data.marketplace, params.data.organizationId),
    )!;
    const accState: AccountState = params.value;
    const dropdownToggle: ActionButton = {
      icon: ICON_CHEVRON_DOWN,
      text: this.getAccountStatusText(params.node),
      color: !accState || !accMp.hasAccessToAdvertising || accState === "PAYMENT_ISSUE" ? "red" : "white",
      tooltip: this.statusTooltip(accMp),
      disabled: !isAdmin,
    };

    const subItems: ActionButton[] = [];

    if (accState === "BIDDER_ON") {
      subItems.push({
        title: "Deactivate Full Automation",
        disabled: !isAdmin,
        onClick: () => {
          this.stopBidder(params.data);
        },
      });
    }

    if (this.canBeRemoved(accMp)) {
      subItems.push({
        title: this.translocoService.translate("common.delete"),
        disabled: !isAdmin,
        onClick: () => {
          this.removeAccounts([accMp]);
        },
      });
    }

    if (!accMp.hasAccessToAdvertising && this.canLoginWithAmazon.get(params.data.organizationId)) {
      subItems.push({
        title: "Renew access by login with Amazon",
        disabled: !isAdmin,
        onClick: () =>
          this.displayAmazonUserAccess(
            this.orgProfileIdMap.get(organizationRessourceKey(params.data.organizationId, params.data.profileId))!,
          ),
      });
    }

    if (this.canBeActivated(params.data)) {
      subItems.push({
        title: this.translocoService.translate("account-setting.active_full_automation"),
        disabled: !isAdmin,
        onClick: () => {
          this.startBidder(params.data);
        },
      });
    }

    return { ...dropdownToggle, subItems: subItems, icon: subItems?.length > 0 ? ICON_CHEVRON_DOWN : undefined };
  }

  stopBidder(data: AccountGridData) {
    const resource: AccountMarketplace = this.orgProfileIdMap.get(
      organizationRessourceKey(data.organizationId, data.profileId),
    )!;

    this.accountMarketplaceService.stopBidder(
      resource,
      () => this.toasterService.success(resource.accountName + " is now Active - Pull Stats", "Account state updated"),
      () => this.toasterService.error(resource.accountName + " has not been changed", "Account state update error"),
    );
  }

  private statusTooltip(acc: AccountMarketplace) {
    return !acc.activated ? this.translocoService.translate("account-setting.unable_automate_tooltip") : undefined;
  }

  private canBeRemoved(accMp: AccountMarketplace): boolean {
    return accMp.accessLevel === AccessLevel.ADMIN && accMp.state != AccountState.BIDDER_ON;
  }

  private canBeActivated(data: AccountGridData): boolean {
    const org: OrganizationAccountGroups = this.organizationMap.get(data.organizationId)!;
    const accMp: AccountMarketplace = this.accountMpMap.get(
      accountMarketplaceOrganizationKey(data.accountId, data.marketplace, data.organizationId),
    )!;
    return org.canActivateBidder(accMp);
  }

  private startBidder(data: AccountGridData) {
    const am = this.accountMpMap.get(
      accountMarketplaceOrganizationKey(data.accountId, data.marketplace, data.organizationId),
    )!;
    const org = this.organizationMap.get(data.organizationId)!;
    const resource = this.orgProfileIdMap.get(organizationRessourceKey(data.organizationId, data.profileId))!;

    if (org.canActivateBidder(am)) {
      this.accountMarketplaceService.activateBidder(
        [resource],
        () => {
          this.toasterService.success(am.accountName + " full automation successfully activated");
        },
        (err) => {
          this.toasterService.error(err, "Full automation failed");
        },
      );
    }
  }

  private canRunBidder(accountMarketplace: AccountMarketplace): boolean {
    return accountMarketplace.state == AccountState.BIDDER_ON && !this.bidderInProgress(accountMarketplace);
  }

  private runBidder(accountMarketplace: AccountMarketplace): void {
    this.accountMarketplaceService.runBidder(
      accountMarketplace,
      () =>
        this.toasterService.success(
          this.translocoService.translate("account-setting.push_requested", [
            accountMarketplace.accountId,
            accountMarketplace.marketplace,
          ]),
        ),
      () =>
        this.toasterService.error(
          this.translocoService.translate("account-setting.request_unable", [
            accountMarketplace.accountId,
            accountMarketplace.marketplace,
          ]),
          this.translocoService.translate("account-setting.campaign_structure_push_error"),
        ),
    );
  }

  private bidderTooltip(accountMarketplace: AccountMarketplace): string {
    if (accountMarketplace.state != AccountState.BIDDER_ON)
      return this.translocoService.translate("account-setting.only_auto_tooltip");
    if (!accountMarketplace.bidderRuns) {
      return this.translocoService.translate("account-setting.push_updates_to_amazon");
    }

    const sortedlastBidderEnd = Object.values(accountMarketplace.bidderRuns)
      .map((x) => x.lastBidderEnd)
      .filter((x) => x)
      .sort((a, b) => (a! > b! ? -1 : 1));

    if (sortedlastBidderEnd.length === 0) {
      return this.translocoService.translate("account-setting.push_updates_to_amazon");
    }

    if (this.bidderInProgress(accountMarketplace))
      return (
        this.translocoService.translate("account-setting.updates_in_progress_last_push_to_amazon") +
        sortedlastBidderEnd[0] +
        " UTC"
      );
    return (
      this.translocoService.translate("account-setting.sh_updates_to_amazon_last_push_to_amazon") +
      sortedlastBidderEnd[0] +
      " UTC"
    );
  }

  private bidderInProgress(accountMarketplace: AccountMarketplace): boolean {
    return !!(
      accountMarketplace.bidderRuns &&
      Object.values(accountMarketplace.bidderRuns).some((x: BidderRun) => x.bidderRunExpected) &&
      accountMarketplace.state == AccountState.BIDDER_ON
    );
  }

  private getAccountMainAction(accMp: AccountMarketplace, vendorValidationData?: number): ActionButton {
    if (this.cleaningInProgress(accMp))
      return {
        text: this.translocoService.translate("account-setting.cleaning_in_progress"),
        tooltip: this.translocoService.translate("account-setting.api_access_soon_tooltip"),
      };
    else if (!accMp.isValidToken)
      return {
        text: this.translocoService.translate("account-setting.grant_access"),
        tooltip: this.translocoService.translate("default-layout.login_tooltip", [
          this.logInPlaceForAPIGrantAccess(accMp),
        ]),
        disabled: this.isReadOnly({ accessLevel: accMp.accessLevel! }),
        onClick: (params) => {
          this.requestGrant(
            accMp.accountId,
            accMp.marketplace,
            accMp.accountType!,
            accMp.accountSubType == AccountSubType.KDP_AUTHOR,
          );
        },
      };
    else if (this.computingValidation(accMp, vendorValidationData)) {
      return {
        tooltip: this.translocoService.translate("account-setting.integration_with_selling_partner_api_is_ongoing"),
        text: this.translocoService.translate("account-setting.integration_ongoing"),
        onClick: (params) => {
          return;
        },
      };
    } else if (this.hasPendingValidation(accMp, vendorValidationData))
      return {
        disabled: this.isReadOnly({ accessLevel: accMp.accessLevel! }),
        tooltip: this.translocoService.translate("default-layout.validate_seeling_partner_api"),
        text: this.translocoService.translate("account-setting.validate_your_catalog"),
        onClick: (params) => {
          this.accountSettingsService.validateIntegration(accMp);
        },
      };
    else
      return {
        tooltip: this.translocoService.translate("account-setting.renew_access_on_mktpl", [
          this.logInPlaceForAPIGrantAccess(accMp),
        ]),
        disabled: this.isReadOnly({ accessLevel: accMp.accessLevel! }),
        text: this.translocoService.translate("account-setting.renew"),
        onClick: (params) => {
          this.requestGrant(
            accMp.accountId,
            accMp.marketplace,
            accMp.accountType!,
            accMp.accountSubType == AccountSubType.KDP_AUTHOR,
          );
        },
      };
  }

  private computingValidation(account: AccountMarketplace, vendorValidationData?: number) {
    return (
      account.accountType == AccountType.VENDOR &&
      account.isValidToken &&
      !account.vendorDataCleaningRequested &&
      !account.approvedBy &&
      vendorValidationData === undefined
    );
  }

  private hasPendingValidation(account: AccountMarketplace, vendorValidationData?: number) {
    return (
      account.accountType == AccountType.VENDOR &&
      account.isValidToken &&
      !account.vendorDataCleaningRequested &&
      !account.approvedBy &&
      vendorValidationData &&
      vendorValidationData > 0
    );
  }

  private cleaningInProgress(account: AccountMarketplace) {
    return account.accountType == AccountType.VENDOR && !!account.vendorDataCleaningRequested;
  }

  private logInPlaceForAPIGrantAccess(am: AccountMarketplace) {
    return am.accountType == AccountType.SELLER ? "Seller Central" : "Vendor Central";
  }

  private requestGrant(accountId: string, marketplace: Marketplace, accountType: AccountType, isKDP: boolean) {
    this.accountSettingsService.grantSellingPartnerAccess(accountId, marketplace, accountType, isKDP);
  }

  private manageAuthorizedUsers(data: AccountGridData): void {
    const org: OrganizationAccountGroups = this.organizationMap.get(data.organizationId)!;
    // todo(oussama) add check can manage authorized users
    const modalOptions: ModalOptions = {
      initialState: {
        organizationId: data.organizationId,
        profileId: data.profileId,
        accountName: data.accountName,
        marketplace: data.marketplace,
        userLimit: org.getBillingPlan()?.userLimit,
      },
      class: "modal-xl",
    };
    this.modalService.show(ManageAuthorizedUsersModalComponent, modalOptions);
  }

  private displayAmazonUserAccess(resource: AccountMarketplaceEx): void {
    const modalOptions: ModalOptions = {
      initialState: {
        amazonUserAccess: resource?.amazonUserAccess,
        organizationId: resource?.resourceOrganizationId,
        accountMarketplace: resource,
        canLoginWithAmazon: this.canLoginWithAmazon.get(resource!.resourceOrganizationId!) ?? false,
      },
      class: "modal-lg",
    };

    this.modalService.show(RenewAmazonAccessModalComponent, modalOptions);
  }

  private updateMaxBid(accountMarketplace: AccountMarketplace): void {
    this.newModalService.openModal<UpdateMaxBidData, void, ModalUpdateMaxBidComponent>(ModalUpdateMaxBidComponent, {
      modalTitle: `${this.translocoService.translate("update-max-bid.max_bid")} - ${accountMarketplace.accountName} - ${accountMarketplace.marketplace}`,
      data: {
        accountMarketplace: accountMarketplace,
      },
    });
  }

  private updateCustomCampaignName(accountMarketplace: AccountMarketplace): void {
    const modalOptions: ModalOptions = {
      initialState: {
        accountMarketplace: accountMarketplace,
      },
      class: "modal-lg",
    };
    this.modalService.show(UpdateCustomCampaignNameComponent, modalOptions);
  }

  private updateVendorSourcingMetric(accountMarketplace: AccountMarketplace): void {
    const modalOptions: ModalOptions = {
      initialState: {
        accountMarketplace: accountMarketplace,
      },
      class: "modal-lg",
    };
    this.modalService.show(ModalUpdateVendorMetricsComponent, modalOptions);
  }

  private isReadOnly(obj: { accessLevel: AccessLevel }): boolean {
    return obj.accessLevel === AccessLevel.READ;
  }
}
