import {
  AsinRank,
  Marketplace,
  SearchResult,
  SearchResultValidation,
  SearchResultValidationStatusEnum,
  SearchResultValidationTypeEnum,
} from '../api-client';
import moment from 'moment-timezone';

export class SearchResultValidationEx implements SearchResultValidation {
  id?: string;
  marketplace?: Marketplace;
  date?: string;
  timestamp?: number;
  page?: number;
  type?: SearchResultValidationTypeEnum;
  status?: SearchResultValidationStatusEnum;
  message?: string;
  searchResult?: SearchResult;
  asinRanks?: AsinRank[];
  url: string;

  constructor(validation: SearchResultValidation) {
    this.id = validation.id;
    this.marketplace = validation.marketplace;
    this.timestamp = validation.timestamp;
    this.date = moment.unix(this.timestamp!).format('YYYY-MM-DD');
    this.page = validation.page;
    this.type = validation.type;
    this.status = validation.status;
    this.message = validation.message;
    this.searchResult = validation.searchResult;
    if (!!validation.searchResult!.pages && validation.searchResult!.pages.length >= this.page!) {
      this.asinRanks = validation.searchResult?.pages[this.page! - 1]?.asinRanks;
    }
    this.url =
      'https://m19-scraper-validation-prod.s3.eu-west-1.amazonaws.com/GLOBAL/' +
      validation.marketplace +
      '/' +
      validation.id +
      '.html.gz';
  }
}
