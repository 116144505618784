import { AsinService, StatsApiClientService, UserSelectionService } from '.';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { InventoryStats } from '@front/m19-models';
import { Marketplace } from '@front/m19-api-client';
import { AccountMarketplaceDataCacheNoContext } from '@front/m19-utils';

@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  private inventoryStatsCache: AccountMarketplaceDataCacheNoContext<InventoryStats[]>;

  constructor(
    private asinService: AsinService,
    private statsService: StatsApiClientService,
    private userSelectionService: UserSelectionService,
  ) {
    this.inventoryStatsCache = new AccountMarketplaceDataCacheNoContext((accountId, marketplace) => {
      return this.asinService.getInventoryStats(
        accountId,
        marketplace,
        this.statsService.getAsinOrderStats(accountId, marketplace),
        this.userSelectionService.selectedCurrency$,
      );
    });
  }

  public getInventoryStats(accountId: string, marketplace: Marketplace): Observable<InventoryStats[]> {
    return this.inventoryStatsCache.get(accountId, marketplace);
  }
}
