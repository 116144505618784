import { CommonModule } from "@angular/common";
import { Component, computed, inject, input, signal, TemplateRef, viewChild } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { AccountMarketplace, SbCreative, Strategy, StrategyAsin, TacosStrategyGroup } from "@front/m19-api-client";
import {
  AccountSelectionService,
  AsinService,
  SbStrategiesService,
  StrategyService,
  TacosStrategiesService,
} from "@front/m19-services";
import { IAlertComponent, IButtonComponent, ModalRef, ModalService } from "@front/m19-ui";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import {
  CreativeSbAsinsResult,
  TacosSbAsinsCheckComponent,
  TacosSbAsinsCheckData,
} from "@m19-board/shared/tacos-sb-asins-check/tacos-sb-asins-check.component";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";
import { AsinsSelectionComponent } from "@m19-board/strategies/strategy-asins/asins-selection.component";
import { StrategyAsinsComponent } from "@m19-board/strategies/strategy-asins/strategy-asins.component";
import { ICON_EDIT_O } from "@m19-board/utils/iconsLabels";
import { ToastrService } from "ngx-toastr";
import { forkJoin, switchMap, take } from "rxjs";

@Component({
  selector: "tacos-products",
  standalone: true,
  imports: [
    CommonModule,
    IAlertComponent,
    TranslocoDirective,
    IButtonComponent,
    StrategyAsinsComponent,
    AsinsSelectionComponent,
    SpinnerComponent,
  ],
  templateUrl: "./tacos-products.component.html",
})
export class TacosProductsComponent {
  private readonly accountSelection = inject(AccountSelectionService);
  private readonly asinService = inject(AsinService);
  private readonly tacosStrategiesService = inject(TacosStrategiesService);
  private readonly modalService = inject(ModalService);
  private readonly translocoService = inject(TranslocoService);
  private readonly strategyService = inject(StrategyService);
  private readonly toastrService = inject(ToastrService);
  private readonly sbStrategiesService = inject(SbStrategiesService);

  readonly ICON_EDIT_O = ICON_EDIT_O;

  tacosStrategy = input.required<TacosStrategyGroup>();
  spStrategy = input.required<Strategy>();
  sdStrategy = input.required<Strategy | undefined>();
  sbStrategy = input.required<Strategy | undefined>();
  sbAsins = input.required<StrategyAsin[] | undefined>();
  sbCreatives = input.required<SbCreative[] | undefined>();
  am = input.required<AccountMarketplace>();
  isReadOnly = input.required<boolean>();
  missingAsinsInSb = input<string[] | undefined>();

  editAsinsModal = viewChild<TemplateRef<any>>("editAsinsModal");
  editAsinsModalRef: ModalRef<StrategyAsin[]> | undefined;

  asinEditLoading = signal(false);

  unavailableAsins = toSignal(
    this.accountSelection.singleAccountMarketplaceSelection$.pipe(
      switchMap((am) => this.tacosStrategiesService.getUnavailableAsinsForTacos(am.accountId, am.marketplace)),
    ),
  );

  catalog = toSignal(
    this.accountSelection.singleAccountMarketplaceSelection$.pipe(
      switchMap((am) => this.asinService.getCatalog(am.accountId, am.marketplace)),
    ),
  );

  ineligibleAsins = computed<string[]>(() => {
    if (!this.catalog() || !this.spStrategy()?.asins) return [];
    const eligibility = this.catalog()!.getSPEligibility();
    return this.spStrategy()!
      .asins!.filter((asin) => !eligibility.get(asin.asin!)?.status)
      .map((asin) => asin.asin!);
  });

  constructor() {}

  openEditAsinsModal() {
    this.editAsinsModalRef = this.modalService.openModal(this.editAsinsModal()!, {
      modalTitle: `${this.translocoService.translate("product-group-page.edit_products")} - ${this.spStrategy()?.name}`,
    });
  }

  updateAsins(asins: StrategyAsin[], mode: "add" | "remove" = "add") {
    const asinsToUpdate = asins.map((a) => a.asin);

    if (mode === "add") {
      const addAsinsCalls = [this.strategyService.addAsinsToStrategy(this.spStrategy(), asinsToUpdate)];
      if (this.sdStrategy()) {
        addAsinsCalls.push(this.strategyService.addAsinsToStrategy(this.sdStrategy()!, asinsToUpdate));
      }

      this.asinEditLoading.set(true);
      forkJoin(addAsinsCalls).subscribe(() => {
        this.toastrService.success(
          `${asinsToUpdate.length} ${this.translocoService.translate("tacos-strategy-page.asins_successfully_added")}`,
        );
        this.asinEditLoading.set(false);
      });
    } else {
      if (asinsToUpdate.length === this.spStrategy()?.asins?.length) {
        return;
      }

      const removeAsinsCalls = [this.strategyService.deleteAsinsFromStrategy(this.spStrategy(), asinsToUpdate)];
      if (this.sdStrategy()) {
        removeAsinsCalls.push(this.strategyService.deleteAsinsFromStrategy(this.sdStrategy()!, asinsToUpdate));
      }

      if (this.sbStrategy() && this.sbAsins()?.some((asin) => asinsToUpdate.includes(asin.asin!))) {
        this.editAsinsModalRef?.close();

        const ref = this.modalService.openModal<TacosSbAsinsCheckData, Array<CreativeSbAsinsResult>>(
          TacosSbAsinsCheckComponent,
          {
            modalTitle: "Impacted SB Ad Lines",
            data: {
              sbStrategyId: this.sbStrategy()!.strategyId!,
              asinsToRemove: asinsToUpdate,
            },
          },
        );

        ref
          .afterClosed()
          .pipe(
            take(1),
            switchMap((result) =>
              forkJoin(
                result.map((creative) =>
                  this.sbStrategiesService.updateSbCreativeClusterAsync(creative.creative, creative.asins),
                ),
              ),
            ),
            switchMap(() => forkJoin(removeAsinsCalls)),
          )
          .subscribe(() => {
            this.toastrService.success(
              `${asinsToUpdate.length} ${this.translocoService.translate("tacos-strategy-page.asins_successfully_removed")}`,
            );
            this.asinEditLoading.set(false);
          });
      }
    }
  }
}
