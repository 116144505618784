// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CustomCampaignNameTokens {
    CAMPAIGN_LABEL = '%CAMPAIGN_LABEL%',
    ACCOUNT_NAME = '%ACCOUNT_NAME%',
    MARKETPLACE = '%MARKETPLACE%',
    STRATEGY_NAME = '%STRATEGY_NAME%',
    STRATEGY_LABEL = '%STRATEGY_LABEL%',
    TACTIC_NAME = '%TACTIC_NAME%',
    CAMPAIGN_TYPE = '%CAMPAIGN_TYPE%',
    MATCH_TYPE = '%MATCH_TYPE%'
}

