import { NgModule } from "@angular/core";
import { provideTransloco, TRANSLOCO_INTERCEPTOR, TranslocoModule } from "@jsverse/transloco";
import { TranslocoHttpLoader } from "./transloco-loader";
import { provideTranslocoMessageformat } from "@jsverse/transloco-messageformat";
import { environment } from "../environments/environment";
import { CustomInterceptor } from "./transloco-interceptor";
import { Languages } from "@front/m19-models";

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: Languages.map((lang) => lang.value),
        defaultLang: "en",
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
        fallbackLang: "en",
        missingHandler: {
          allowEmpty: true,
          useFallbackTranslation: true,
        },
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoMessageformat({ locales: "en" }),
    {
      provide: TRANSLOCO_INTERCEPTOR,
      useClass: CustomInterceptor,
    },
  ],
})
export class TranslocoRootModule {}
