<ng-container *transloco="let t">
  @if (am) {
    <div class="space-y-2">
      @if (
        (creativeType !== SbCreativeType.video && !formCrea?.brandLogo) ||
        (creativeType === SbCreativeType.video && !formCrea?.video)
      ) {
        <div class="alert alert-info">
          @if (creativeType === SbCreativeType.productCollection) {
            {{ t("sb-form-crea-preview.select_one_image") }}
          } @else if (creativeType === SbCreativeType.video || creativeType === SbCreativeType.brandVideo) {
            {{ t("sb-form-crea-preview.select_one_video") }}
          } @else {
            {{ t("sb-form-crea-preview.select_one_logo") }}
          }
        </div>
      } @else {
        @for (adLine of _adLineProducts; track $index; let i = $index) {
          <div class="overflow-x-auto rounded-md bg-white p-3">
            <div class="sensitive-data flex flex-col gap-3">
              @if (creativeType !== SbCreativeType.video) {
                <ng-container [ngTemplateOutlet]="creativeHeader"></ng-container>
              }
              <div class="flex w-full flex-1">
                @if (creativeType === SbCreativeType.productCollection) {
                  <ng-container [ngTemplateOutlet]="customImage"></ng-container>
                } @else if (creativeType !== SbCreativeType.storeSpotlight && formCrea.video) {
                  <ng-container [ngTemplateOutlet]="video"></ng-container>
                }
                <div class="h-auto flex-1">
                  <div
                    class="flex h-full gap-x-2"
                    [class.flex-col]="formCrea.video && formCrea.video.height > formCrea.video.width"
                  >
                    @if (creativeType !== SbCreativeType.storeSpotlight) {
                      @for (a of adLine; track $index) {
                        <ng-container
                          [ngTemplateOutlet]="product"
                          [ngTemplateOutletContext]="{ product: a }"
                        ></ng-container>
                      }
                    } @else if (_asins) {
                      <ng-container
                        [ngTemplateOutlet]="store"
                        [ngTemplateOutletContext]="{ sbAsin: _asins[i] }"
                      ></ng-container>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      }
    </div>
  }

  <!-- Brand Logo and Headline -->
  <ng-template #creativeHeader>
    <div class="flex w-full items-center">
      <div class="mr-3 size-14 shrink-0 border-r-2 pr-3">
        <img class="h-auto max-w-full" [src]="formCrea?.brandLogo?.url || 'assets/img/img_placeholder.svg'" />
      </div>
      <div class="flex-1 font-medium">
        @if (formCrea?.headline) {
          <p class="m-0 text-base">{{ formCrea.headline }}</p>
        }
        @if (formCrea?.brandName) {
          <span class="text-xs text-blue-700">{{ t("common.shop") }} {{ formCrea.brandName }}</span>
        }
      </div>
    </div>
  </ng-template>

  <!-- Review Score -->
  <ng-template #reviewScore let-score="score" let-reviews="reviews">
    <div class="mt-1 flex text-lg">
      @for (s of range5; track s) {
        @if (s < roundToNearestHalf(score)) {
          <span
            [ngClass]="{
              'icon-[mdi--star]': roundToNearestHalf(score) - s >= 1,
              'icon-[mdi--star-half-full]': roundToNearestHalf(score) - s === 0.5,
              'text-sm text-yellow-500': true,
            }"
          >
          </span>
        } @else if (s >= roundToNearestHalf(score)) {
          <span class="icon-[mdi--star-outline] text-sm text-yellow-500"></span>
        }
      }
      <span
        class="text-xs font-medium"
        [ngClass]="{
          'ml-1 text-blue-500': reviews > 0,
          'text-gray-400': reviews === 0,
        }"
        >{{ reviews > 0 ? reviews : t("sb-form-crea-preview.no_review") }}
      </span>
    </div>
  </ng-template>

  <ng-template #product let-p="product">
    @if (p) {
      <div class="h-full flex-1 rounded-sm border bg-white" [class.flex]="creativeType === SbCreativeType.video">
        <div
          class="flex h-3/5 items-center justify-center bg-gray-50"
          [ngClass]="{ 'h-full p-2': creativeType === SbCreativeType.video }"
          [matTooltip]="p.asin"
        >
          <app-product-thumbnail [marketplace]="am?.marketplace" [product]="p" />
        </div>
        <!-- ASIN description -->
        <div class="p-2">
          <span class="line-clamp-2 text-xs font-medium" [matTooltip]="p.title">
            {{ p.title }}
          </span>
          <ng-container
            [ngTemplateOutlet]="reviewScore"
            [ngTemplateOutletContext]="{ score: p.reviewScore, reviews: p.reviews }"
          >
          </ng-container>
          @if (creativeType === SbCreativeType.video) {
            <span class="text-lg font-medium">{{ p.price | currency: currency : "symbol" : "1.2-2" }}</span>
          }
        </div>
      </div>
    }
  </ng-template>

  <ng-template #store let-a="sbAsin">
    <div class="flex h-full flex-1 flex-col rounded-sm bg-white">
      <div class="flex flex-1 items-center justify-center bg-gray-50">
        <app-product-thumbnail [marketplace]="am?.marketplace" [asin]="a.asin1" customSizeClass="size-32" />
      </div>
      <span class="mt-1 text-sm font-medium">{{ a.title1 }}</span>
    </div>
    <div class="flex h-full flex-1 flex-col rounded-sm bg-white">
      <div class="flex flex-1 items-center justify-center bg-gray-50">
        <app-product-thumbnail [marketplace]="am?.marketplace" [asin]="a.asin2" customSizeClass="size-32" />
      </div>
      <span class="mt-1 text-sm font-medium">{{ a.title2 }}</span>
    </div>
    <div class="flex h-full flex-1 flex-col rounded-sm bg-white">
      <div class="flex flex-1 items-center justify-center bg-gray-50">
        <app-product-thumbnail [marketplace]="am?.marketplace" [asin]="a.asin3" customSizeClass="size-32" />
      </div>
      <span class="mt-1 text-sm font-medium">{{ a.title3 }}</span>
    </div>
  </ng-template>

  <ng-template #customImage>
    <div class="mr-3 min-h-[10rem] w-[15rem] min-w-[15rem]">
      @if (formCrea?.customImage2) {
        <app-carousel
          [images]="[
            formCrea.customImage?.url,
            formCrea.customImage2?.url,
            formCrea.customImage3?.url,
            formCrea.customImage4?.url,
            formCrea.customImage5?.url,
          ]"
          [cover]="true"
        ></app-carousel>
      } @else {
        <img class="h-auto max-w-full" [src]="formCrea?.customImage?.url ?? 'assets/img/img_placeholder.svg'" />
      }
    </div>
  </ng-template>

  <ng-template #video>
    <div class="mr-3 flex basis-1/2 items-center">
      <video class="h-auto max-w-full" autoplay [muted]="true" [loop]="true">
        <source [src]="formCrea.video.url" type="video/mp4" />
      </video>
    </div>
  </ng-template>
</ng-container>
