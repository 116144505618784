import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { faSquare, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare, faSearch } from "@fortawesome/free-solid-svg-icons";
import { AsinSeed, Targeting } from "@front/m19-api-client";
import { ExportToCsv } from "export-to-csv";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { ICON_TRASH_O } from "@m19-board/utils/iconsLabels";
import { IButtonComponent, ICheckboxComponent } from "@front/m19-ui";
import { TranslocoDirective } from "@jsverse/transloco";
import { ExportButtonComponent } from "@m19-board/shared/ui/export-buttons/export-button.component";
import { KeywordItemComponent } from "@m19-board/shared/keyword-item/keyword-item.component";

export class TargetingItemSelection {
  item: Targeting;
  isSelected: boolean;

  constructor(item: Targeting) {
    this.item = item;
    this.isSelected = false;
  }
}

@Component({
  selector: "app-keywords-list",
  templateUrl: "./keywords-list.component.html",
  styleUrls: ["./keywords-list.component.scss"],
  standalone: true,
  imports: [
    ICheckboxComponent,
    TranslocoDirective,
    IButtonComponent,
    ExportButtonComponent,
    KeywordItemComponent,
    MatPaginator,
  ],
})
export class KeywordsListComponent implements OnInit, OnChanges {
  @Input()
  set keywords(keywords: Targeting[] | AsinSeed[]) {
    this._keywords = keywords?.map((x: Targeting | AsinSeed) => new TargetingItemSelection(x as Targeting));
    this.nbSelectedKw = 0;
  }

  @Input()
  set name(name: string) {
    this._name = name;
  }

  @Input() isSelectable = false;

  _name!: string;
  _keywords!: TargetingItemSelection[];
  filteredKeywords?: TargetingItemSelection[];
  shownKeywords?: TargetingItemSelection[];
  itemsShown = 10;
  nbSelectedKw = 0;
  readonly faSearch = faSearch;
  readonly faSquare = faSquare;
  readonly faCheckedSquare = faCheckSquare;
  readonly faTrash = faTrashAlt;
  readonly ICON_TRASH = ICON_TRASH_O;
  pageEvent = new PageEvent();

  @Output()
  selectedItems = new EventEmitter<Targeting[]>();

  filter?: RegExp;

  ngOnInit() {
    this.updateFilteredKeywords();
  }

  ngOnChanges() {
    this.unselectAllkeywords();
    this.updateFilteredKeywords();
  }

  changeContent(event: PageEvent) {
    this.pageEvent = event;
    this.shownKeywords = this.filteredKeywords?.slice(
      event.pageIndex * event.pageSize,
      (event.pageIndex + 1) * event.pageSize,
    );
  }

  setFilter(query: string): void {
    this.filter = new RegExp(query, "i");
    this.updateFilteredKeywords();
  }

  private updateFilteredKeywords() {
    this.filteredKeywords = this._keywords.filter((kw) =>
      this.filter ? kw.item.targetingValue.search(this.filter) != -1 : true,
    );
    this.shownKeywords = this.filteredKeywords.slice(0, this.itemsShown);
  }

  countSelectedKw() {
    this.nbSelectedKw = this._keywords.filter((x) => x.isSelected).length;
  }

  updateSelect(kw: TargetingItemSelection) {
    kw.isSelected = !kw.isSelected;
    this.countSelectedKw();
  }

  selectAllkeywords() {
    this.filteredKeywords?.forEach((x) => (x.isSelected = true));
    this.countSelectedKw();
  }

  unselectAllkeywords() {
    this._keywords.forEach((x) => (x.isSelected = false));
    this.countSelectedKw();
  }

  deleteKeywords() {
    this.selectedItems.emit(this._keywords.filter((x) => x.isSelected).map((x) => x.item));
  }

  downloadKeywords() {
    const allKeywords = [];
    for (const keyword of this._keywords) {
      const keywords: any = {};
      keywords["Type"] = keyword.item.matchType;
      keywords["Keyword"] = keyword.item.targetingValue;
      allKeywords.push(keywords);
    }

    const options = {
      fieldSeparator: ",",
      quoteStrings: "",
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: this._name + "_keywords",
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(allKeywords);
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
