import { NgStyle } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatTooltip } from "@angular/material/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IButtonComponent } from "@front/m19-ui";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { ICON_CHECK, ICON_CLOSE, ICON_TRASH_O } from "@m19-board/utils/iconsLabels";
@Component({
  selector: "app-decimal-input",
  standalone: true,
  imports: [NgStyle, MatTooltip, FontAwesomeModule, FormsModule, IButtonComponent, TranslocoRootModule],
  templateUrl: "./decimal-input.component.html",
  styleUrls: ["./decimal-input.component.scss"],
})
export class DecimalInputComponent implements OnInit, OnChanges {
  @Input() placeholder = "";
  @Input() testid: string;

  @Input() withActionButtons: boolean;

  @Input() invalidInputMessage: string;

  @Input() precision: number;

  @Input() min: number;

  @Input() max: number;

  @Input() fullWidth = true;

  @Input() disabled = false;

  oldValue: number;

  value_: number;

  @Input()
  set value(value: number) {
    this.value_ = value;
  }

  @Input() label: string;
  @Input() size: number;
  @Input() inputSize: number;

  @Input() unit: string;

  @Input() trash: boolean;

  @Input() updateOnlyOnKeypress: boolean;

  typed = false;

  @Output() isFocused = new EventEmitter<boolean>();

  @Output() onChange = new EventEmitter<number>();

  @Output() onDelete = new EventEmitter<void>();

  readonly ICON_CHECK = ICON_CHECK;
  readonly ICON_CLOSE = ICON_CLOSE;
  readonly ICON_TRASH = ICON_TRASH_O;

  ngOnInit() {
    if (this.max === null) this.max = undefined;
    if (this.min === null) this.min = undefined;
    if (!this.precision) this.precision = 2;
    if (this.withActionButtons === null) this.withActionButtons = false;
    if (!this.invalidInputMessage) this.invalidInputMessage = "Invalid value";

    this.oldValue = this.value_;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["value"]?.previousValue != changes["value"]?.currentValue) {
      this.oldValue = this.value_;
    }
  }

  sendValue(value) {
    this.typed = false;
    this.value_ = value;
    if (this.value_ < this.min || this.value_ > this.max) return;
    if (this.value_ != this.oldValue) {
      this.oldValue = this.value_;
      this.onChange.emit(this.value_);
    }
  }

  roundValue(value: number) {
    return value ? parseFloat(value.toFixed(this.precision)) : value;
  }

  resetValue() {
    this.value_ = this.oldValue;
  }

  checkKey(key) {
    this.typed = true;
    return key.keyCode == 46 || (key.keyCode <= 57 && key.keyCode >= 48); // allow digits and dots;
  }

  delete() {
    this.value = undefined;
    this.oldValue = undefined;
    this.onDelete.emit();
  }
}
