<div cdkDropListGroup class="h-full" *transloco="let t">
  <div class="drag-drop-ctn h-full">
    <div class="asins-list">
      <div class="list-title">
        {{ t("common.asins") }}
        <span class="product-count">
          {{ t("drag-drop-asins.num_products", { count: (asinList$ | async)?.length }) }}
        </span>
      </div>
      <div class="p-2">
        <span class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><fa-icon [icon]="faSearch"></fa-icon></span>
          </div>
          <input
            type="text"
            class="search-bar simple-input-prepend form-control"
            placeholder="{{ t('drag-drop-asins.search_by_asin_or_product_title') }}"
            (input)="setFilter(getValueFromInputEvent($event))"
          />
        </span>
      </div>
      <div class="grow" style="min-height: 0">
        @if (!asinsLoading) {
          <div
            cdkDropList
            id="asinList"
            class="asin-drag-list h-full overflow-auto"
            [cdkDropListData]="displayedAsins"
            (cdkDropListDropped)="_creativeType === SbCreativeType.video ? dropVideo($event) : drop($event)"
            [cdkDropListEnterPredicate]="noDrop"
          >
            @for (a of displayedAsins; track a) {
              <div
                cdkDrag
                [cdkDragDisabled]="
                  !isAsinEligible(a) || (_creativeType === SbCreativeType.video && isAsinInCluster(a, clusterLists[0]))
                "
                [cdkDragData]="a"
                [ngStyle]="{ 'user-select': !isAsinEligible(a) ? 'none' : 'auto' }"
                (cdkDragEntered)="clusterHovered($event)"
                (cdkDragExited)="clusterLeft()"
                (cdkDragEnded)="clusterLeft()"
              >
                <div class="drop-placeholder" *cdkDragPlaceholder></div>
                <app-cluster-asin-card
                  [catalog]="catalog"
                  [asin]="a"
                  [ineligible]="
                    !isAsinEligible(a) ||
                    (_creativeType === SbCreativeType.video && isAsinInCluster(a, clusterLists[0]))
                  "
                  [marketplace]="marketplace"
                  [isVideoCreative]="_creativeType === SbCreativeType.video"
                  [availableClusters]="availableClustersByAsin.get(a)"
                  (add)="manualAsinAdd($event, a)"
                  [matTooltip]="getIneligibilityReason(a)"
                  [groupLength]="clusterLists.length"
                ></app-cluster-asin-card>
              </div>
            }
          </div>
        }
        @if (asinsLoading) {
          <div class="flex h-full items-center justify-center">
            <app-spinner [display]="true"></app-spinner>
          </div>
        }
      </div>
      <div style="flex-shrink: 0" class="flex items-center justify-center">
        @if (filteredAsinList.length > ASIN_PER_PAGE) {
          <mat-paginator
            class="[&_*]:!text-sm"
            [pageSizeOptions]="[]"
            [hidePageSize]="true"
            [pageSize]="ASIN_PER_PAGE"
            [pageIndex]="pageEvent.pageIndex"
            [length]="filteredAsinList.length"
            (page)="changeContent($event)"
            showFirstLastButtons
          />
        }
      </div>
    </div>
    <div class="clusters-list">
      @if (_creativeType === SbCreativeType.productCollection || _creativeType === SbCreativeType.brandVideo) {
        <div class="list-title">
          {{ t("drag-drop-asins.group_limit", [clusterLists.length, groupLimit]) }}
        </div>
      } @else if (_creativeType === SbCreativeType.video) {
        <div class="list-title">{{ clusterLists[0].length }}/{{ clusterLimit }} {{ t("common.asins") }}</div>
      }
      <div class="flex grow flex-col overflow-auto p-2 pt-3">
        @if (_creativeType === SbCreativeType.productCollection || _creativeType === SbCreativeType.brandVideo) {
          @for (c of clusterLists; track c; let i = $index) {
            <div
              class="cluster-drag-list relative"
              [class.cluster-drag-list-full]="errorMap.get(i)"
              [class.cluster-video-drag-list]="false"
            >
              @if (c.length > 0) {
                <div
                  class="abs-delete-btn"
                  matTooltip="{{ t('drag-drop-asins.reset_group') }}"
                  (click)="resetCluster(i)"
                >
                  <img src="assets/img/chart_icons/close-icon.svg" alt="" />
                </div>
              }
              @if (ineligibilityByCluster?.get(i)) {
                <div
                  class="cluster-warning"
                  matTooltip="{{ t('drag-drop-asins.this_creative_contains_ineligible_asin') }}"
                >
                  <img src="assets/img/chart_icons/warning-circle.svg" alt="Warning circle" />
                </div>
              }
              <span class="cluster-count">{{ c.length }}/{{ clusterLimit }}</span>
              <div class="cluster-index flex items-center justify-center">{{ i + 1 }}</div>
              @if (!c.length) {
                <div class="empty-state">
                  {{ t("drag-drop-asins.select_asins_to_advertise") }}
                </div>
              }
              <div
                [id]="'drop-list-' + i"
                cdkDropList
                class="flex h-full items-center overflow-auto px-2"
                [ngClass]="errorMap.get(i) ? 'cluster-error' : 'cluster'"
                [class.flex-wrap]="false"
                cdkDropListOrientation="horizontal"
                [cdkDropListData]="c"
                (cdkDropListDropped)="drop($event)"
              >
                @for (a of c; track a) {
                  <div
                    cdkDrag
                    [cdkDragData]="a"
                    (cdkDragEntered)="clusterHovered($event)"
                    (cdkDragExited)="clusterLeft()"
                    (cdkDragEnded)="clusterLeft()"
                  >
                    <div class="drop-placeholder" *cdkDragPlaceholder></div>
                    <app-cluster-asin-card
                      [catalog]="catalog"
                      [asin]="a"
                      [small]="true"
                      [matTooltip]="getIneligibilityReason(a)"
                      [ineligible]="!isAsinEligible(a)"
                      [marketplace]="marketplace"
                      [inCluster]="isAsinInCluster(a, c)"
                      (remove)="removeAsinFromCluster(a, c)"
                    ></app-cluster-asin-card>
                  </div>
                }
              </div>
            </div>
          }
        }

        @if (_creativeType === SbCreativeType.video) {
          <div class="grow">
            <div
              [id]="'drop-list-0'"
              cdkDropList
              class="h-full overflow-auto px-2"
              [class.flex-wrap]="_creativeType === SbCreativeType.video"
              cdkDropListOrientation="vertical"
              [cdkDropListData]="clusterLists[0]"
              (cdkDropListDropped)="dropVideo($event)"
            >
              @for (a of clusterLists[0]; track a) {
                <div
                  cdkDrag
                  [cdkDragData]="a"
                  (cdkDragEntered)="clusterHovered($event)"
                  (cdkDragExited)="clusterLeft()"
                  (cdkDragEnded)="clusterLeft()"
                >
                  <div class="drop-placeholder" *cdkDragPlaceholder></div>
                  <app-cluster-asin-card
                    [asin]="a"
                    [catalog]="catalog"
                    [matTooltip]="getIneligibilityReason(a)"
                    [ineligible]="!isAsinEligible(a)"
                    [marketplace]="marketplace"
                    [inCluster]="isAsinInCluster(a, clusterLists[0])"
                    (remove)="removeAsinFromCluster(a, clusterLists[0])"
                  ></app-cluster-asin-card>
                </div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
