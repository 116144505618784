// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    Brand,
    BrandAsset,
    Marketplace,
    MediaType,
    Response,
} from '../models';

export interface GetBrandRequest {
    accountId: string;
    marketplace: Marketplace;
}

export interface GetBrandAssetRequest {
    accountId: string;
    marketplace: Marketplace;
}

export interface UploadAssetRequest {
    accountId: string;
    marketplace: Marketplace;
    brandEntityId: string;
    mediaType: MediaType;
    assetName: string;
    asset?: Blob;
}

/**
 * no description
 */
export class BrandApi extends BaseAPI {

    /**
     */
    getBrand({ accountId, marketplace }: GetBrandRequest): Observable<Array<Brand>>
    getBrand({ accountId, marketplace }: GetBrandRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<Brand>>>
    getBrand({ accountId, marketplace }: GetBrandRequest, opts?: OperationOpts): Observable<Array<Brand> | AjaxResponse<Array<Brand>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getBrand');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getBrand');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<Brand>>({
            url: '/brand/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getBrandAsset({ accountId, marketplace }: GetBrandAssetRequest): Observable<Array<BrandAsset>>
    getBrandAsset({ accountId, marketplace }: GetBrandAssetRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<BrandAsset>>>
    getBrandAsset({ accountId, marketplace }: GetBrandAssetRequest, opts?: OperationOpts): Observable<Array<BrandAsset> | AjaxResponse<Array<BrandAsset>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getBrandAsset');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getBrandAsset');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<BrandAsset>>({
            url: '/brandAsset/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Upload an Asset
     */
    uploadAsset({ accountId, marketplace, brandEntityId, mediaType, assetName, asset }: UploadAssetRequest): Observable<Response>
    uploadAsset({ accountId, marketplace, brandEntityId, mediaType, assetName, asset }: UploadAssetRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    uploadAsset({ accountId, marketplace, brandEntityId, mediaType, assetName, asset }: UploadAssetRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'uploadAsset');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'uploadAsset');
        throwIfNullOrUndefined(brandEntityId, 'brandEntityId', 'uploadAsset');
        throwIfNullOrUndefined(mediaType, 'mediaType', 'uploadAsset');
        throwIfNullOrUndefined(assetName, 'assetName', 'uploadAsset');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
            'brandEntityId': brandEntityId,
            'mediaType': mediaType,
            'assetName': assetName,
        };

        const formData = new FormData();
        if (asset !== undefined) { formData.append('asset', asset as any); }

        return this.request<Response>({
            url: '/uploadBrandAsset',
            method: 'POST',
            headers,
            query,
            body: formData,
        }, opts?.responseOpts);
    };

}
