import type { AmcSponsoredAdsAndDspOverlap } from '@front/m19-api-client';

export interface AmcSponsoredAdsAndDspOverlapEx extends AmcSponsoredAdsAndDspOverlap {
  exposureGroup: string | undefined;
}

export function buildAmcSponsoredAdsAndDspOverlapEx(
  amcSponsoredAdsAndDspOverlap: AmcSponsoredAdsAndDspOverlap,
): AmcSponsoredAdsAndDspOverlapEx {
  return { ...amcSponsoredAdsAndDspOverlap, exposureGroup: getExposureGroup(amcSponsoredAdsAndDspOverlap) };
}

function getExposureGroup(amcSponsoredAdsAndDspOverlap: AmcSponsoredAdsAndDspOverlap): string | undefined {
  const exposureGroups: string[] = [];

  if (amcSponsoredAdsAndDspOverlap.sp) {
    exposureGroups.push('SP');
  }

  if (amcSponsoredAdsAndDspOverlap.sb) {
    exposureGroups.push('SB');
  }

  if (amcSponsoredAdsAndDspOverlap.sd) {
    exposureGroups.push('SD');
  }

  if (amcSponsoredAdsAndDspOverlap.st) {
    exposureGroups.push('ST');
  }

  if (amcSponsoredAdsAndDspOverlap.dsp) {
    exposureGroups.push('DSP');
  }

  return exposureGroups.length ? exposureGroups.join(', ') : undefined;
}
