import { Component, inject, signal } from "@angular/core";
import { AuthService } from "@front/m19-services";
import { ToastrService } from "ngx-toastr";
import { catchError, switchMap, throwError } from "rxjs";
import { TranslocoDirective } from "@jsverse/transloco";
import {
  MODAL_DATA,
  ModalRef,
  ChangePasswordFormComponent,
  ChangePasswordFormValue,
  ChangePasswordModalData,
} from "@front/m19-ui";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  standalone: true,
  imports: [TranslocoDirective, ChangePasswordFormComponent],
})
export class ChangePasswordComponent {
  private readonly authService = inject(AuthService);
  private readonly toasterService = inject(ToastrService);

  private readonly ref = inject(ModalRef);
  private readonly data = inject<ChangePasswordModalData>(MODAL_DATA);

  submitLoading = signal(false);
  error = signal<string | undefined>(undefined);

  updatePassword(formValue: ChangePasswordFormValue) {
    this.submitLoading.set(true);

    if (!this.data.user) return;

    this.authService
      .fetchTokenPassword(this.data.user.email!, formValue.oldPassword)
      .pipe(
        catchError(() => throwError(() => "Your password is invalid")),
        switchMap(() =>
          this.authService.updatePasswordAsync({ ...this.data.user, newPassword: formValue.newPassword }),
        ),
      )
      .subscribe({
        next: () => {
          this.toasterService.success("Password successfully updated");
          this.closeRef();
          this.submitLoading.set(false);
        },
        error: (error: string) => {
          this.error.set(error);
          this.submitLoading.set(false);
        },
      });
  }

  closeRef() {
    this.ref.close();
  }
}
