<div *transloco="let t">
  @if (accountMarketplace?.accountType !== AccountType.SELLER) {
    <div class="p-2">
      <div class="border-l-4 border-orange-500 bg-orange-100 p-4 text-orange-700">
        {{ t("inventory.no_inventory_for_vendor") }}
      </div>
    </div>
  } @else {
    <app-stats-overlay [checkOrganicDataAccess]="true">
      @if (lowInventoryAsins.length > 0) {
        <div class="inventory-warning alert alert-warning" role="alert">
          <div>
            <b>{{ t("inventory.low_inventory_warning_title", { count: lowInventoryAsins.length }) }}</b>
            @if (lowInventoryAsinsPaused.length === 0) {
              <span> {{ t("inventory.low_inventory_warning", { count: lowInventoryAsins.length }) }}</span>
            }
            @if (lowInventoryAsinsPaused.length > 0) {
              <span> {{ t("inventory.low_inventory_paused", { count: lowInventoryAsinsPaused.length }) }}</span>
            }
          </div>
          <div>
            <button
              class="btn"
              [ngClass]="filterLowInventory ? 'btn-warning' : 'btn-outline-warning'"
              matTooltip="{{
                filterLowInventory
                  ? t('inventory.filter_low_inventory_tooltip_remove')
                  : t('inventory.filter_low_inventory_tooltip_only')
              }}"
              (click)="toggleLowInventoryFilter()"
            >
              <fa-icon [icon]="faFilter" size="lg"></fa-icon>
            </button>
          </div>
        </div>
      }
      @if (isManualManaging) {
        <div class="alert alert-warning my-2 w-full p-3 text-sm">
          {{ t("asins-selection.missing_an_asin") }}
          <a routerLink="/product-center/catalog" queryParamsHandling="merge" target="_blank">{{
            t("asins-selection.go_to_your_catalog_to_see_if_it_is_a_managed_asin")
          }}</a
          >.
        </div>
      }
      <div class="form-group row">
        <app-strategy-product-group-hybrid-dropdown
          class="col-sm-3"
          [strategies]="strategies"
          [productGroups]="productGroups"
          (selectedStrategyOrProductGroup)="setStrategyOrProductGroup($event)"
        ></app-strategy-product-group-hybrid-dropdown>
      </div>
      <app-inventory-table
        [currency]="currency"
        [locale]="locale"
        [data]="filteredInventoryStats"
        [marketplace]="marketplace"
        [accountId]="accountId"
        [loading]="loading"
        [inventoryRules]="inventoryRules"
      ></app-inventory-table>
    </app-stats-overlay>
  }
</div>
