<app-stats-overlay [checkAccountMarketplaceProperties]="false">
  <div class="entry-selector-wrapper">
    <app-entry-selector
      [data]="totalData"
      [previousPeriodData]="previousTotalData"
      [localStorageKey]="localStorageKey"
      [pageMetrics]="METRICS"
      (chartMetricsChanges)="selectMetrics($event)"
      (displayModeChange)="selectDisplayMode($event)"
      [hasManagedToggle]="true"
    />
  </div>

  <div [class.hidden]="isGlobalChartHidden" class="mt-3" *transloco="let t">
    <ICard>
      <ng-template #header>
        <div class="flex items-center justify-end">
          <div class="ml-3 flex items-center gap-x-3">
            <app-date-aggreation-switch-button
              class="flex"
              [selected]="dateAggregation$ | async"
              (dateAggSelected)="selectAggregation($event)"
            />
            <IButton
              color="gray"
              variant="ghost"
              tooltipValue="{{ t('common.hide_chart') }}"
              [icon]="ICON_CLOSE"
              (onClick)="toggleGlobalChartDisplay(true)"
            />
          </div>
        </div>
      </ng-template>
      <ng-template #body>
        <canvas
          baseChart
          class="chart mb-3"
          [datasets]="globalDataset.chartDataSet"
          type="line"
          [labels]="globalDataset.labels"
          [options]="globalDataset.lineChartOptions"
        ></canvas>
      </ng-template>
    </ICard>
  </div>

  <div class="my-3 flex justify-end" *transloco="let t">
    <div class="flex justify-end gap-1">
      <IButton
        tooltipValue="{{ t('common.restore_default_columns') }}"
        label="{{ t('common.restore_columns') }}"
        color="white"
        (onClick)="restoreDefaultColumns()"
      />
      <app-export-button tooltipValue="{{ t('common.export_as_csv') }}" (export)="exportGridCsv()" />
      @if (isGlobalChartHidden) {
        <IButton
          tooltipValue="{{ t('agency-board.show_global_chart') }}"
          [icon]="ICON_CHART"
          [square]="true"
          (onClick)="toggleGlobalChartDisplay(false)"
          color="white"
        />
      }
    </div>
  </div>

  <div class="ag-theme-quartz grid">
    <ag-grid-angular class="h-[45rem] w-full" [gridOptions]="gridOptions" [statusBar]="STATUS_BAR" />
  </div>
</app-stats-overlay>
