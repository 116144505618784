import { LocaleService } from "@ag-grid-community/core";
import { Injectable } from "@angular/core";
import { AuthService } from "@front/m19-services";
import { getBrowserLang, Translation, TranslocoService } from "@jsverse/transloco";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { Observable } from "rxjs";
import { defineLocale, esLocale, jaLocale } from "ngx-bootstrap/chronos";
import { frLocale } from "ngx-bootstrap/locale";
defineLocale("fr", frLocale);
defineLocale("es", esLocale);
defineLocale("ja", jaLocale);

@Injectable({
  providedIn: "root",
})
export class I18nService {
  private static readonly languageStorageKey = "language";

  public readonly language: BehaviorSubject<string>;

  constructor(
    private translocoService: TranslocoService,
    private authService: AuthService,
    private bsLocaleService: BsLocaleService,
  ) {
    this.language = new BehaviorSubject<string>(
      localStorage.getItem(I18nService.languageStorageKey) ?? getBrowserLang() ?? "en",
    );
    this.authService.user$.subscribe((user) => {
      if (!user || localStorage.getItem(I18nService.languageStorageKey) !== null) {
        this.changeLanguage(localStorage.getItem(I18nService.languageStorageKey) ?? getBrowserLang() ?? "en");
      } else {
        this.changeLanguage(user.language!);
      }
    });
  }

  // should be called in app.component.ts
  public initLanguage(): Observable<Translation> {
    this.translocoService.setActiveLang(this.language.value);

    if (this.language.value != "dev") this.bsLocaleService.use(this.language.value);
    return this.translocoService.load(this.language.value); // force the language to be loaded before the app starts
  }

  // call this method to change the language
  public changeLanguage(lang: string): void {
    if (lang == this.language.value) {
      return;
    }
    this.translocoService.setActiveLang(lang);
    localStorage.setItem("agGridLanguage", lang);
    if (this.language.value != "dev") this.bsLocaleService.use(lang);
    this.language.next(lang);
  }

  // used in debug mode
  public changeLanguageInLocalStorage(lang: string): void {
    localStorage.setItem(I18nService.languageStorageKey, lang);
    this.changeLanguage(lang);
  }

  public translateIfExist(key: string): string {
    const tr: Translation = this.translocoService.getTranslation(this.translocoService.getActiveLang());
    if (!tr[key]) {
      return key;
    }
    return this.translocoService.translate(key);
  }
}
