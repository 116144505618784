// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    Marketplace,
    ProductTimeline,
} from '../models';

export interface GetProductTimelineRequest {
    marketplace: Marketplace;
    requestBody: Array<string>;
}

/**
 * no description
 */
export class ProductTimelineApi extends BaseAPI {

    /**
     * Get product timeline history per ASIN for a given marketplace
     */
    getProductTimeline({ marketplace, requestBody }: GetProductTimelineRequest): Observable<Array<ProductTimeline>>
    getProductTimeline({ marketplace, requestBody }: GetProductTimelineRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<ProductTimeline>>>
    getProductTimeline({ marketplace, requestBody }: GetProductTimelineRequest, opts?: OperationOpts): Observable<Array<ProductTimeline> | AjaxResponse<Array<ProductTimeline>>> {
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getProductTimeline');
        throwIfNullOrUndefined(requestBody, 'requestBody', 'getProductTimeline');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'marketplace': marketplace,
        };

        return this.request<Array<ProductTimeline>>({
            url: '/productTimeline',
            method: 'POST',
            headers,
            query,
            body: requestBody,
        }, opts?.responseOpts);
    };

}
