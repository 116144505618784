<div class="switch-ctn flex items-center" *transloco="let t">
  @if (type === SwitchButtonType.TEXT) {
    @for (txt of buttonTexts; track txt; let index = $index) {
      <div
        class="switch-item"
        [attr.data-testid]="testids[index]"
        [class.active-item]="selectedIndex === index"
        (click)="selectButton(index); $event.stopPropagation()"
        [matTooltip]="buttonTooltips[index]"
      >
        {{ t(txt) }}
      </div>
    }
  } @else {
    @for (i of buttonIcons; track i; let index = $index) {
      <div
        class="switch-item inline-flex items-center justify-center"
        [attr.data-testid]="testids[index]"
        [class.active-item]="selectedIndex === index"
        (click)="selectButton(index); $event.stopPropagation()"
        [matTooltip]="buttonTooltips[index]"
      >
        <span class="{{ i }} text-xl"></span>
      </div>
    }
  }
</div>
