<ng-container *transloco="let t">
  <div class="modal-header bg-primary">
    <h4 class="modal-title pull-left">
      {{ existingSegment ? t("common.update") : t("common.create") }} {{ t("product-segment-modal.product_segment") }}
    </h4>
    <a><h4 class="modal-title pull-right" (click)="close()">&times;</h4></a>
  </div>
  <div class="modal-body ml-3 mr-2 flex h-[70vh] flex-col overflow-auto">
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">{{ t("keyword-segment-modal.segment_name") }}</label>
      <div class="col-sm-9">
        <input
          data-testid="segment-name-input"
          type="text"
          maxlength="80"
          class="border-shadow form-control"
          placeholder="{{ t('keyword-segment-modal.name_input_tooltip') }}"
          [(ngModel)]="name"
        />
      </div>
    </div>

    <app-asins-selection
      class="flex-1"
      [onlyAddParent]="true"
      [allowAsinFromOtherCatalog]="true"
      bulkPlaceholderText="{{ t('catalog-upload.list_of_asin_input') }}"
      [selectionModes]="productSelectionModes"
      [asins]="asinsList"
      [accountId]="accountId"
      [marketplace]="marketplace"
      [csvExport]="true"
      [csvExportFileName]="name + '_products'"
      (onAdd)="addAsins($event)"
      (onDelete)="deleteAsins($event)"
    >
    </app-asins-selection>
  </div>
  <div class="modal-footer">
    <IButton label="{{ t('common.cancel') }}" color="gray" variant="soft" (onClick)="close()" />
    <IButton
      testid="btn-confirm-segment"
      class="w-2/4"
      type="submit"
      label="{{ t('common.save') }}"
      [tooltipValue]="invalidTooltip()"
      [disabled]="isInvalid() || isReadOnly"
      (onClick)="saveSegment()"
      [block]="true"
    />
  </div>
</ng-container>
