import { Component, computed, DestroyRef, inject, signal, WritableSignal } from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import { OrganizationAccountGroupService, OrganizationService, StatsService } from "@front/m19-services";
import { IButtonComponent, IProgressBarComponent } from "@front/m19-ui";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { LayoutSelectorService } from "@m19-board/layout-selector.service";
import { OnboardingService, OnboardingStep, OnBoardingStepRedirects } from "@m19-board/services/onboarding.service";
import { delay, filter, interval, Subject, switchMap, take, tap } from "rxjs";

@Component({
  selector: "stats-downloading-page",
  templateUrl: "stats-downloading-page.component.html",
  standalone: true,
  imports: [TranslocoDirective, IButtonComponent, IProgressBarComponent, IProgressBarComponent],
})
export class StatsDownloadingPageComponent {
  private readonly translocoService = inject(TranslocoService);
  private readonly layoutSelectorService = inject(LayoutSelectorService);
  private readonly organizationService = inject(OrganizationService);
  private readonly organizationAccountGroupService = inject(OrganizationAccountGroupService);
  private readonly onboardingService = inject(OnboardingService);
  private readonly statsService = inject(StatsService);
  private readonly router = inject(Router);
  private readonly destroyRef = inject(DestroyRef);

  readonly helpCenterMessage;
  readonly isWhiteLabel = this.layoutSelectorService.isWhitelabel;
  readonly every10seconds = interval(10000);

  readonly m19GettingStartedLinks = [
    {
      title: this.translocoService.translate("stats-downloading-page.getting-started-first-startegy-title"),
      link: "https://help.m19.com/onboarding",
      description: this.translocoService.translate("stats-downloading-page.getting-started-first-startegy-description"),
      icon: "icon-[mdi--university-outline]",
    },
    {
      title: this.translocoService.translate("stats-downloading-page.getting-started-reco-title"),
      link: "https://help.m19.com/recommendations-to-a-successful-start",
      description: this.translocoService.translate("stats-downloading-page.getting-started-reco-description"),
      icon: "icon-[mdi--lightbulb-on-outline]",
    },
    {
      title: this.translocoService.translate("stats-downloading-page.getting-started-ai-title"),
      link: "https://help.m19.com/standard-strategy-set-up",
      description: this.translocoService.translate("stats-downloading-page.getting-started-ai-description"),
      icon: "icon-[mdi--robot-happy-outline]",
    },
    {
      title: this.translocoService.translate("stats-downloading-page.getting-started-kw-title"),
      link: "https://help.m19.com/how-does-the-m19-ai-collect-keywords-at-launch",
      description: this.translocoService.translate("stats-downloading-page.getting-started-kw-description"),
      icon: "icon-[mdi--brain]",
    },
    {
      title: this.translocoService.translate("stats-downloading-page.meet_the_team"),
      description: this.translocoService.translate("stats-downloading-page.meet_the_team-description"),
      icon: "icon-[mdi--account-group-outline]",
    },
  ];

  readonly sideInfo: WritableSignal<"help-center-links" | "team-info"> = signal("help-center-links");
  private readonly allOrganizationAccountGroups = toSignal(
    this.organizationAccountGroupService.allOrganizationAccountGroups$.pipe(filter((o) => o != undefined)),
  );
  readonly progress = computed(() => {
    const orgs = this.allOrganizationAccountGroups();
    if (!orgs) {
      return 0;
    }
    if (orgs.length !== 1) {
      // going to next step is done automatically
      return 1;
    }
    const org = orgs![0];
    if (org.organization.adStatsReadyDatetime) {
      // going to next step is done automatically
      return 1;
    }
    const expectedReports = org.accountGroups.flatMap((g) => g.resources).length * 30;
    const reportDownloaded = org.organization.adStatsReportDownloaded ?? 0;
    return reportDownloaded / expectedReports;
  });

  constructor() {
    const helpCenterLink = `<a href="https://help.m19.com" target="_blank">${this.translocoService.translate("stats-downloading-page.help_center")}</a>`;
    this.helpCenterMessage = this.translocoService.translate("stats-downloading-page.help_center_exploration_message", {
      helpCenter: helpCenterLink,
    });
    // refresh organizations periodically
    this.every10seconds.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.organizationService.refreshOrganizations();
    });
    const redirect = new Subject<void>();
    this.onboardingService.currentStep$
      .pipe(
        filter((step) => step !== OnboardingStep.StatsDownloading),
        takeUntilDestroyed(this.destroyRef),
        take(1),
      )
      .subscribe((step) => {
        // reload stats and organizations
        this.statsService.reloadAdStatsPerAccountMarketplace();
        redirect.next();
        redirect.complete();
      });
    redirect
      .pipe(
        delay(1000 * 15), // wait for 15 seconds before redirecting and ensure stats are in DB
        switchMap(() => this.onboardingService.currentStep$),
      )
      .subscribe((step) => {
        this.router.navigate([OnBoardingStepRedirects[step]]);
      });
  }

  displayTeamInfo(event: MouseEvent) {
    event.preventDefault();
    this.sideInfo.set("team-info");
  }

  closeTeamInfo() {
    this.sideInfo.set("help-center-links");
  }
}
