import { Component, inject, OnInit } from "@angular/core";
import { Marketplace, Strategy, StrategyStateEnum } from "@front/m19-api-client";
import { MODAL_DATA } from "@front/m19-ui";
import { ProductViewComponent } from "@m19-board/product-view/product-view.component";
import { TranslocoDirective } from "@jsverse/transloco";
import { StrategyLinkComponent } from "@m19-board/strategies/strategy-link/strategy-link.component";

@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.scss"],
  standalone: true,
  imports: [ProductViewComponent, TranslocoDirective, StrategyLinkComponent],
})
export class ProductDetailsComponent implements OnInit {
  protected readonly data = inject<ProductDetailData>(MODAL_DATA);

  strategies: Strategy[] | undefined;
  activeStrategies: Strategy[] | undefined;
  asinnumber: string | undefined;
  marketplace: Marketplace | undefined;

  ngOnInit() {
    this.strategies = this.data.strategies;
    this.asinnumber = this.data.asinnumber;
    this.marketplace = this.data.marketplace;
    this.activeStrategies = this.strategies?.filter((x) => x.state == StrategyStateEnum.ENABLED) ?? [];
  }
}

export interface ProductDetailData {
  asinnumber: string;
  marketplace: Marketplace;
  strategies: Strategy[];
}
