<div class="flex flex-col" *transloco="let t">
  <ul class="nav-wrapper">
    <li class="nav-element" [class.active]="(segmentType$ | async) === SegmentType.KeywordSegment">
      <a class="nav-link x-1 mt-1" (click)="toggleSegmentType(SegmentType.KeywordSegment)">
        {{ t("add-keyword-tracking-modal.keyword_segments") }}
      </a>
    </li>
    <li
      class="nav-element"
      data-testid="product-segment-btn"
      [class.active]="(segmentType$ | async) === SegmentType.ProductSegment"
    >
      <a class="nav-link x-1 mt-1" (click)="toggleSegmentType(SegmentType.ProductSegment)">
        {{ t("add-product-tracking-modal.product_segments") }}
      </a>
    </li>
  </ul>
  <app-stats-overlay>
    <div class="my-3 flex justify-end">
      <div class="flex items-center gap-2">
        <IButton
          [disabled]="isReadOnly"
          [icon]="ICON_TRASH_O"
          (onClick)="deleteSegments(selectedRows)"
          testid="btn-delete-all-segments"
          color="red"
          label="{{ t('segment-manager.remove_selected_selectedrows_length', { count: selectedRows.length }) }}"
          [hidden]="selectedRows.length === 0"
        ></IButton>
        <IButton
          [disabled]="isReadOnly"
          [icon]="ICON_ADD"
          (onClick)="createNewSegment()"
          color="main"
          label="{{
            (segmentType$ | async) === SegmentType.KeywordSegment
              ? t('segment-manager.create_new_keyword_segment')
              : t('segment-manager.create_new_product_segment')
          }}"
        ></IButton>
        <app-export-button
          (export)="downloadCsv()"
          tooltipValue="{{ t('segment-manager.export_segment_list_as_csv') }}"
        ></app-export-button>
      </div>
    </div>
    <div class="table-container">
      <ag-grid-angular
        #grid
        class="ag-theme-quartz base-theme mb-2 h-[65rem] flex-1"
        [gridOptions]="gridOptions"
        [columnDefs]="colDef"
        [rowData]="segments"
        (gridReady)="onGridReady($event)"
      ></ag-grid-angular>
    </div>
  </app-stats-overlay>
</div>
