<div class="slider relative h-full w-full overflow-hidden">
  @for (image of _images; track image; let idx = $index) {
    <img
      [src]="image"
      class="absolute left-full top-0 h-full w-full opacity-0 transition-all duration-1000 ease-in-out"
      [class.deactivated]="idx < activeImage"
      [class.active]="idx === activeImage"
      [class.object-cover]="cover"
      [class.object-contain]="!cover"
    />
  }
</div>
