<div class="modal-header bg-primary" *transloco="let t">
  <h4 class="modal-title pull-left">{{ t("coupon-modal.coupon_code") }}</h4>
</div>
<div class="modal-body" *transloco="let t">
  <input
    type="text"
    class="simple-input form-control"
    placeholder="{{ t('coupon-modal.enter_a_coupon_code') }}"
    aria-label="Coupon code input"
    (input)="setCouponCode(getValueFromInputEvent($event))"
  />
</div>
<div class="modal-footer" *transloco="let t">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
    {{ t("common.cancel") }}
  </button>
  <button type="submit" class="btn btn-primary" (click)="submit()">{{ t("common.submit") }}</button>
</div>
