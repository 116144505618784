// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    AlgoMode,
    AudienceExpressionType,
    AudienceMatchType,
    CampaignType,
    Intensity,
    Marketplace,
    PreModeration,
    PreModerationRequest,
    Response,
    SbCreative,
    SbCreativeCluster,
    SbCreativeType,
    Segment,
    State,
    Strategy,
    StrategyType,
    TacticType,
    Targeting,
} from '../models';

export interface AddAudienceTargetingRequest {
    accountId: string;
    marketplace: Marketplace;
    strategyId: number;
    expressionType: AudienceExpressionType;
    matchType: AudienceMatchType;
    audienceId?: number;
    categoryId?: number;
    lookback?: number;
    idName?: string;
}

export interface AddTacticRequest {
    accountId: string;
    marketplace: Marketplace;
    strategyId: number;
    segmentId: number;
    tacticType: TacticType;
    intensity: Intensity;
    boostPlacementTop: boolean;
}

export interface CreateSbCreativeRequest {
    accountId: string;
    marketplace: Marketplace;
    state: State;
    strategyId: number;
    creativeType: SbCreativeType;
    logoAssetId?: string;
    videoAssetId?: string;
    brandEntityId?: string | null;
    brandName?: string | null;
    headline?: string | null;
    storePageId?: string | null;
    customImageAssetId?: string | null;
    customImageAssetId2?: string | null;
    customImageAssetId3?: string | null;
    customImageAssetId4?: string | null;
    customImageAssetId5?: string | null;
}

export interface CreateSegmentRequest {
    accountId: string;
    marketplace: Marketplace;
    name?: string;
}

export interface CreateStrategyRequest {
    accountId: string;
    marketplace: Marketplace;
    strategyType: StrategyType;
    campaignType: CampaignType;
    algoMode: AlgoMode;
    name: string;
    state: string;
    organizationId: number;
    strategyGroupId?: number | null;
    priority?: number;
    strategyLabel?: string;
    acosTarget?: number;
    suggestedBid?: number | null;
    dailyBudget?: number | null;
    monthlyBudget?: number | null;
    minDailySpend?: number;
    disableOtherQueries?: boolean;
    disableAutoSegment?: boolean;
    disableProductSegment?: boolean;
    daypartingPauseHour?: number | null;
    daypartingReactivationHour?: number | null;
    version?: number;
    brandEntityId?: string;
    asinIsolation?: boolean;
}

export interface DeleteAudienceTargetingRequest {
    accountId: string;
    marketplace: Marketplace;
    audienceTargetId: number;
    strategyId: number;
}

export interface DeleteSbCreativeRequest {
    accountId: string;
    marketplace: Marketplace;
    creativeId: number;
}

export interface DeleteSegmentRequest {
    accountId: string;
    marketplace: Marketplace;
    segmentId: number;
}

export interface DeleteStrategyRequest {
    accountId: string;
    marketplace: Marketplace;
    strategyId: number;
    organizationId: number;
}

export interface DeleteTacticRequest {
    accountId: string;
    marketplace: Marketplace;
    strategyId: number;
    segmentId: number;
}

export interface GetSegmentRequest {
    accountId: string;
    marketplace: Marketplace;
    segmentId: number;
}

export interface GetStrategyByIdRequest {
    accountId: string;
    marketplace: Marketplace;
    strategyId: number;
}

export interface ListSbCreativeRequest {
    accountId: string;
    marketplace: Marketplace;
}

export interface ListSegmentsRequest {
    accountId: string;
    marketplace: Marketplace;
}

export interface ListStrategiesRequest {
    accountId: string;
    marketplace: Marketplace;
}

export interface SbPreModerationRequest {
    accountId: string;
    marketplace: Marketplace;
    preModerationRequest: PreModerationRequest;
}

export interface SwitchStrategyAlgoModeRequest {
    accountId: string;
    marketplace: Marketplace;
    strategyId: number;
    algoMode: string;
    acosTarget?: number | null;
    dailyBudget?: number | null;
    suggestedBid?: number | null;
    monthlyBudget?: number | null;
}

export interface UpdateAudienceTargetingRequest {
    accountId: string;
    marketplace: Marketplace;
    audienceTargetId: number;
    strategyId: number;
    state: State;
}

export interface UpdateSbCreativeRequest {
    accountId: string;
    marketplace: Marketplace;
    creativeId: number;
    logoAssetId?: string;
    videoAssetId?: string;
    customImageAssetId?: string;
    customImageAssetId2?: string;
    customImageAssetId3?: string;
    customImageAssetId4?: string;
    customImageAssetId5?: string;
    headline?: string;
    state?: State;
}

export interface UpdateSbCreativeClusterRequest {
    accountId: string;
    marketplace: Marketplace;
    creativeId: number;
    sbCreativeCluster: SbCreativeCluster;
}

export interface UpdateSegmentRequest {
    accountId: string;
    marketplace: Marketplace;
    segmentId: number;
    name?: string;
}

export interface UpdateSegmentItemsRequest {
    accountId: string;
    marketplace: Marketplace;
    segmentId: number;
    action: UpdateSegmentItemsActionEnum;
    targeting: Array<Targeting>;
}

export interface UpdateStrategyRequest {
    accountId: string;
    marketplace: Marketplace;
    strategyId: number;
    organizationId: number;
    acosTarget?: number;
    name?: string;
    strategyLabel?: string;
    state?: string;
    suggestedBid?: number | null;
    dailyBudget?: number | null;
    primeDayBoost?: number | null;
    minDailySpend?: number;
    disableOtherQueries?: boolean;
    disableAutoSegment?: boolean;
    disableProductSegment?: boolean;
    daypartingPauseHour?: number | null;
    daypartingReactivationHour?: number | null;
    priority?: number;
    asinIsolation?: boolean;
}

export interface UpdateStrategyAsinsRequest {
    accountId: string;
    marketplace: Marketplace;
    strategyId: number;
    requestBody: Array<string>;
}

export interface UpdateStrategyKwTargetingRequest {
    accountId: string;
    marketplace: Marketplace;
    strategyId: number;
    action: UpdateStrategyKwTargetingActionEnum;
    targeting: Array<Targeting>;
}

export interface UpdateStrategyMonthlyBudgetRequest {
    accountId: string;
    marketplace: Marketplace;
    strategyId: number;
    monthlyBudget?: number | null;
    nextMonthlyBudget?: number | null;
    currentMonth?: string | null;
}

export interface UpdateStrategyTopOfSearchRankingsRequest {
    accountId: string;
    marketplace: Marketplace;
    strategyId: number;
    action: UpdateStrategyTopOfSearchRankingsActionEnum;
    organizationId: number;
    requestBody: Array<string>;
}

export interface UpdateTacticRequest {
    accountId: string;
    marketplace: Marketplace;
    strategyId: number;
    segmentId: number;
    intensity?: Intensity;
    boostPlacementTop?: boolean;
}

/**
 * no description
 */
export class StrategyApi extends BaseAPI {

    /**
     * Add an audience targeting to a SD strategy
     */
    addAudienceTargeting({ accountId, marketplace, strategyId, expressionType, matchType, audienceId, categoryId, lookback, idName }: AddAudienceTargetingRequest): Observable<Response>
    addAudienceTargeting({ accountId, marketplace, strategyId, expressionType, matchType, audienceId, categoryId, lookback, idName }: AddAudienceTargetingRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    addAudienceTargeting({ accountId, marketplace, strategyId, expressionType, matchType, audienceId, categoryId, lookback, idName }: AddAudienceTargetingRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'addAudienceTargeting');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'addAudienceTargeting');
        throwIfNullOrUndefined(strategyId, 'strategyId', 'addAudienceTargeting');
        throwIfNullOrUndefined(expressionType, 'expressionType', 'addAudienceTargeting');
        throwIfNullOrUndefined(matchType, 'matchType', 'addAudienceTargeting');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'strategyId': strategyId,
            'expressionType': expressionType,
            'matchType': matchType,
        };

        if (audienceId != null) { query['audienceId'] = audienceId; }
        if (categoryId != null) { query['categoryId'] = categoryId; }
        if (lookback != null) { query['lookback'] = lookback; }
        if (idName != null) { query['idName'] = idName; }

        return this.request<Response>({
            url: '/strategy/audience-targeting/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'POST',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Add a tactic to a strategy
     */
    addTactic({ accountId, marketplace, strategyId, segmentId, tacticType, intensity, boostPlacementTop }: AddTacticRequest): Observable<Response>
    addTactic({ accountId, marketplace, strategyId, segmentId, tacticType, intensity, boostPlacementTop }: AddTacticRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    addTactic({ accountId, marketplace, strategyId, segmentId, tacticType, intensity, boostPlacementTop }: AddTacticRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'addTactic');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'addTactic');
        throwIfNullOrUndefined(strategyId, 'strategyId', 'addTactic');
        throwIfNullOrUndefined(segmentId, 'segmentId', 'addTactic');
        throwIfNullOrUndefined(tacticType, 'tacticType', 'addTactic');
        throwIfNullOrUndefined(intensity, 'intensity', 'addTactic');
        throwIfNullOrUndefined(boostPlacementTop, 'boostPlacementTop', 'addTactic');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'tacticType': tacticType,
            'intensity': intensity,
            'boostPlacementTop': boostPlacementTop,
        };

        return this.request<Response>({
            url: '/tactic/{accountId}/{marketplace}/{strategyId}/{segmentId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{strategyId}', encodeURI(strategyId)).replace('{segmentId}', encodeURI(segmentId)),
            method: 'POST',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Create a SB Creative
     * Create a SB Creative
     */
    createSbCreative({ accountId, marketplace, state, strategyId, creativeType, logoAssetId, videoAssetId, brandEntityId, brandName, headline, storePageId, customImageAssetId, customImageAssetId2, customImageAssetId3, customImageAssetId4, customImageAssetId5 }: CreateSbCreativeRequest): Observable<Response>
    createSbCreative({ accountId, marketplace, state, strategyId, creativeType, logoAssetId, videoAssetId, brandEntityId, brandName, headline, storePageId, customImageAssetId, customImageAssetId2, customImageAssetId3, customImageAssetId4, customImageAssetId5 }: CreateSbCreativeRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    createSbCreative({ accountId, marketplace, state, strategyId, creativeType, logoAssetId, videoAssetId, brandEntityId, brandName, headline, storePageId, customImageAssetId, customImageAssetId2, customImageAssetId3, customImageAssetId4, customImageAssetId5 }: CreateSbCreativeRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'createSbCreative');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'createSbCreative');
        throwIfNullOrUndefined(state, 'state', 'createSbCreative');
        throwIfNullOrUndefined(strategyId, 'strategyId', 'createSbCreative');
        throwIfNullOrUndefined(creativeType, 'creativeType', 'createSbCreative');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'state': state,
            'strategyId': strategyId,
            'creativeType': creativeType,
        };

        if (logoAssetId != null) { query['logoAssetId'] = logoAssetId; }
        if (videoAssetId != null) { query['videoAssetId'] = videoAssetId; }
        if (brandEntityId != null) { query['brandEntityId'] = brandEntityId; }
        if (brandName != null) { query['brandName'] = brandName; }
        if (headline != null) { query['headline'] = headline; }
        if (storePageId != null) { query['storePageId'] = storePageId; }
        if (customImageAssetId != null) { query['customImageAssetId'] = customImageAssetId; }
        if (customImageAssetId2 != null) { query['customImageAssetId2'] = customImageAssetId2; }
        if (customImageAssetId3 != null) { query['customImageAssetId3'] = customImageAssetId3; }
        if (customImageAssetId4 != null) { query['customImageAssetId4'] = customImageAssetId4; }
        if (customImageAssetId5 != null) { query['customImageAssetId5'] = customImageAssetId5; }

        return this.request<Response>({
            url: '/sbCreative/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'POST',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Create a Segment
     * Create a Segment
     */
    createSegment({ accountId, marketplace, name }: CreateSegmentRequest): Observable<Response>
    createSegment({ accountId, marketplace, name }: CreateSegmentRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    createSegment({ accountId, marketplace, name }: CreateSegmentRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'createSegment');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'createSegment');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (name != null) { query['name'] = name; }

        return this.request<Response>({
            url: '/segment/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'POST',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Create a new strategy
     */
    createStrategy({ accountId, marketplace, strategyType, campaignType, algoMode, name, state, organizationId, strategyGroupId, priority, strategyLabel, acosTarget, suggestedBid, dailyBudget, monthlyBudget, minDailySpend, disableOtherQueries, disableAutoSegment, disableProductSegment, daypartingPauseHour, daypartingReactivationHour, version, brandEntityId, asinIsolation }: CreateStrategyRequest): Observable<Response>
    createStrategy({ accountId, marketplace, strategyType, campaignType, algoMode, name, state, organizationId, strategyGroupId, priority, strategyLabel, acosTarget, suggestedBid, dailyBudget, monthlyBudget, minDailySpend, disableOtherQueries, disableAutoSegment, disableProductSegment, daypartingPauseHour, daypartingReactivationHour, version, brandEntityId, asinIsolation }: CreateStrategyRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    createStrategy({ accountId, marketplace, strategyType, campaignType, algoMode, name, state, organizationId, strategyGroupId, priority, strategyLabel, acosTarget, suggestedBid, dailyBudget, monthlyBudget, minDailySpend, disableOtherQueries, disableAutoSegment, disableProductSegment, daypartingPauseHour, daypartingReactivationHour, version, brandEntityId, asinIsolation }: CreateStrategyRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'createStrategy');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'createStrategy');
        throwIfNullOrUndefined(strategyType, 'strategyType', 'createStrategy');
        throwIfNullOrUndefined(campaignType, 'campaignType', 'createStrategy');
        throwIfNullOrUndefined(algoMode, 'algoMode', 'createStrategy');
        throwIfNullOrUndefined(name, 'name', 'createStrategy');
        throwIfNullOrUndefined(state, 'state', 'createStrategy');
        throwIfNullOrUndefined(organizationId, 'organizationId', 'createStrategy');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'strategyType': strategyType,
            'campaignType': campaignType,
            'algoMode': algoMode,
            'name': name,
            'state': state,
            'organizationId': organizationId,
        };

        if (strategyGroupId != null) { query['strategyGroupId'] = strategyGroupId; }
        if (priority != null) { query['priority'] = priority; }
        if (strategyLabel != null) { query['strategyLabel'] = strategyLabel; }
        if (acosTarget != null) { query['acosTarget'] = acosTarget; }
        if (suggestedBid != null) { query['suggestedBid'] = suggestedBid; }
        if (dailyBudget != null) { query['dailyBudget'] = dailyBudget; }
        if (monthlyBudget != null) { query['monthlyBudget'] = monthlyBudget; }
        if (minDailySpend != null) { query['minDailySpend'] = minDailySpend; }
        if (disableOtherQueries != null) { query['disableOtherQueries'] = disableOtherQueries; }
        if (disableAutoSegment != null) { query['disableAutoSegment'] = disableAutoSegment; }
        if (disableProductSegment != null) { query['disableProductSegment'] = disableProductSegment; }
        if (daypartingPauseHour != null) { query['daypartingPauseHour'] = daypartingPauseHour; }
        if (daypartingReactivationHour != null) { query['daypartingReactivationHour'] = daypartingReactivationHour; }
        if (version != null) { query['version'] = version; }
        if (brandEntityId != null) { query['brandEntityId'] = brandEntityId; }
        if (asinIsolation != null) { query['asinIsolation'] = asinIsolation; }

        return this.request<Response>({
            url: '/strategy/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'POST',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Delete an audience targeting
     */
    deleteAudienceTargeting({ accountId, marketplace, audienceTargetId, strategyId }: DeleteAudienceTargetingRequest): Observable<Response>
    deleteAudienceTargeting({ accountId, marketplace, audienceTargetId, strategyId }: DeleteAudienceTargetingRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    deleteAudienceTargeting({ accountId, marketplace, audienceTargetId, strategyId }: DeleteAudienceTargetingRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'deleteAudienceTargeting');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'deleteAudienceTargeting');
        throwIfNullOrUndefined(audienceTargetId, 'audienceTargetId', 'deleteAudienceTargeting');
        throwIfNullOrUndefined(strategyId, 'strategyId', 'deleteAudienceTargeting');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'strategyId': strategyId,
        };

        return this.request<Response>({
            url: '/strategy/audience-targeting/{accountId}/{marketplace}/{audienceTargetId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{audienceTargetId}', encodeURI(audienceTargetId)),
            method: 'DELETE',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Delete a SB Creative
     */
    deleteSbCreative({ accountId, marketplace, creativeId }: DeleteSbCreativeRequest): Observable<Response>
    deleteSbCreative({ accountId, marketplace, creativeId }: DeleteSbCreativeRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    deleteSbCreative({ accountId, marketplace, creativeId }: DeleteSbCreativeRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'deleteSbCreative');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'deleteSbCreative');
        throwIfNullOrUndefined(creativeId, 'creativeId', 'deleteSbCreative');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Response>({
            url: '/sbCreative/{accountId}/{marketplace}/{creativeId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{creativeId}', encodeURI(creativeId)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Delete a segment
     */
    deleteSegment({ accountId, marketplace, segmentId }: DeleteSegmentRequest): Observable<Response>
    deleteSegment({ accountId, marketplace, segmentId }: DeleteSegmentRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    deleteSegment({ accountId, marketplace, segmentId }: DeleteSegmentRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'deleteSegment');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'deleteSegment');
        throwIfNullOrUndefined(segmentId, 'segmentId', 'deleteSegment');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Response>({
            url: '/segment/{accountId}/{marketplace}/{segmentId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{segmentId}', encodeURI(segmentId)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Delete a strategy
     */
    deleteStrategy({ accountId, marketplace, strategyId, organizationId }: DeleteStrategyRequest): Observable<Response>
    deleteStrategy({ accountId, marketplace, strategyId, organizationId }: DeleteStrategyRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    deleteStrategy({ accountId, marketplace, strategyId, organizationId }: DeleteStrategyRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'deleteStrategy');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'deleteStrategy');
        throwIfNullOrUndefined(strategyId, 'strategyId', 'deleteStrategy');
        throwIfNullOrUndefined(organizationId, 'organizationId', 'deleteStrategy');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'organizationId': organizationId,
        };

        return this.request<Response>({
            url: '/strategy/{accountId}/{marketplace}/{strategyId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{strategyId}', encodeURI(strategyId)),
            method: 'DELETE',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Delete a tactic
     */
    deleteTactic({ accountId, marketplace, strategyId, segmentId }: DeleteTacticRequest): Observable<Response>
    deleteTactic({ accountId, marketplace, strategyId, segmentId }: DeleteTacticRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    deleteTactic({ accountId, marketplace, strategyId, segmentId }: DeleteTacticRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'deleteTactic');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'deleteTactic');
        throwIfNullOrUndefined(strategyId, 'strategyId', 'deleteTactic');
        throwIfNullOrUndefined(segmentId, 'segmentId', 'deleteTactic');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Response>({
            url: '/tactic/{accountId}/{marketplace}/{strategyId}/{segmentId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{strategyId}', encodeURI(strategyId)).replace('{segmentId}', encodeURI(segmentId)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Get segment by segmentId
     * Get segment by segmentId
     */
    getSegment({ accountId, marketplace, segmentId }: GetSegmentRequest): Observable<Segment>
    getSegment({ accountId, marketplace, segmentId }: GetSegmentRequest, opts?: OperationOpts): Observable<AjaxResponse<Segment>>
    getSegment({ accountId, marketplace, segmentId }: GetSegmentRequest, opts?: OperationOpts): Observable<Segment | AjaxResponse<Segment>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getSegment');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getSegment');
        throwIfNullOrUndefined(segmentId, 'segmentId', 'getSegment');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Segment>({
            url: '/segment/{accountId}/{marketplace}/{segmentId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{segmentId}', encodeURI(segmentId)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Get a strategy by its id
     */
    getStrategyById({ accountId, marketplace, strategyId }: GetStrategyByIdRequest): Observable<Strategy>
    getStrategyById({ accountId, marketplace, strategyId }: GetStrategyByIdRequest, opts?: OperationOpts): Observable<AjaxResponse<Strategy>>
    getStrategyById({ accountId, marketplace, strategyId }: GetStrategyByIdRequest, opts?: OperationOpts): Observable<Strategy | AjaxResponse<Strategy>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getStrategyById');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getStrategyById');
        throwIfNullOrUndefined(strategyId, 'strategyId', 'getStrategyById');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Strategy>({
            url: '/strategy/{accountId}/{marketplace}/{strategyId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{strategyId}', encodeURI(strategyId)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * List SB Creatives
     * List SB Creatives
     */
    listSbCreative({ accountId, marketplace }: ListSbCreativeRequest): Observable<Array<SbCreative>>
    listSbCreative({ accountId, marketplace }: ListSbCreativeRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<SbCreative>>>
    listSbCreative({ accountId, marketplace }: ListSbCreativeRequest, opts?: OperationOpts): Observable<Array<SbCreative> | AjaxResponse<Array<SbCreative>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'listSbCreative');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'listSbCreative');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<SbCreative>>({
            url: '/sbCreative/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * List Segments
     * List Segments
     */
    listSegments({ accountId, marketplace }: ListSegmentsRequest): Observable<Array<Segment>>
    listSegments({ accountId, marketplace }: ListSegmentsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<Segment>>>
    listSegments({ accountId, marketplace }: ListSegmentsRequest, opts?: OperationOpts): Observable<Array<Segment> | AjaxResponse<Array<Segment>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'listSegments');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'listSegments');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<Segment>>({
            url: '/segment/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * List strategies
     * List strategies by accountId / marketplace
     */
    listStrategies({ accountId, marketplace }: ListStrategiesRequest): Observable<Array<Strategy>>
    listStrategies({ accountId, marketplace }: ListStrategiesRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<Strategy>>>
    listStrategies({ accountId, marketplace }: ListStrategiesRequest, opts?: OperationOpts): Observable<Array<Strategy> | AjaxResponse<Array<Strategy>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'listStrategies');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'listStrategies');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<Strategy>>({
            url: '/strategy/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Return the pre moderation result for a given headline, brand logo and/or video
     */
    sbPreModeration({ accountId, marketplace, preModerationRequest }: SbPreModerationRequest): Observable<PreModeration>
    sbPreModeration({ accountId, marketplace, preModerationRequest }: SbPreModerationRequest, opts?: OperationOpts): Observable<AjaxResponse<PreModeration>>
    sbPreModeration({ accountId, marketplace, preModerationRequest }: SbPreModerationRequest, opts?: OperationOpts): Observable<PreModeration | AjaxResponse<PreModeration>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'sbPreModeration');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'sbPreModeration');
        throwIfNullOrUndefined(preModerationRequest, 'preModerationRequest', 'sbPreModeration');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
        };

        return this.request<PreModeration>({
            url: '/sbPreModeration',
            method: 'POST',
            headers,
            query,
            body: preModerationRequest,
        }, opts?.responseOpts);
    };

    /**
     * switch strategy algo mode
     */
    switchStrategyAlgoMode({ accountId, marketplace, strategyId, algoMode, acosTarget, dailyBudget, suggestedBid, monthlyBudget }: SwitchStrategyAlgoModeRequest): Observable<Response>
    switchStrategyAlgoMode({ accountId, marketplace, strategyId, algoMode, acosTarget, dailyBudget, suggestedBid, monthlyBudget }: SwitchStrategyAlgoModeRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    switchStrategyAlgoMode({ accountId, marketplace, strategyId, algoMode, acosTarget, dailyBudget, suggestedBid, monthlyBudget }: SwitchStrategyAlgoModeRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'switchStrategyAlgoMode');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'switchStrategyAlgoMode');
        throwIfNullOrUndefined(strategyId, 'strategyId', 'switchStrategyAlgoMode');
        throwIfNullOrUndefined(algoMode, 'algoMode', 'switchStrategyAlgoMode');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'algoMode': algoMode,
        };

        if (acosTarget != null) { query['acosTarget'] = acosTarget; }
        if (dailyBudget != null) { query['dailyBudget'] = dailyBudget; }
        if (suggestedBid != null) { query['suggestedBid'] = suggestedBid; }
        if (monthlyBudget != null) { query['monthlyBudget'] = monthlyBudget; }

        return this.request<Response>({
            url: '/strategy/switchAlgoMode/{accountId}/{marketplace}/{strategyId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{strategyId}', encodeURI(strategyId)),
            method: 'PUT',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Update an audience targeting (state)
     */
    updateAudienceTargeting({ accountId, marketplace, audienceTargetId, strategyId, state }: UpdateAudienceTargetingRequest): Observable<Response>
    updateAudienceTargeting({ accountId, marketplace, audienceTargetId, strategyId, state }: UpdateAudienceTargetingRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateAudienceTargeting({ accountId, marketplace, audienceTargetId, strategyId, state }: UpdateAudienceTargetingRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'updateAudienceTargeting');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'updateAudienceTargeting');
        throwIfNullOrUndefined(audienceTargetId, 'audienceTargetId', 'updateAudienceTargeting');
        throwIfNullOrUndefined(strategyId, 'strategyId', 'updateAudienceTargeting');
        throwIfNullOrUndefined(state, 'state', 'updateAudienceTargeting');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'strategyId': strategyId,
            'state': state,
        };

        return this.request<Response>({
            url: '/strategy/audience-targeting/{accountId}/{marketplace}/{audienceTargetId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{audienceTargetId}', encodeURI(audienceTargetId)),
            method: 'PUT',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Update a SB Creative
     * Update a SB Creative
     */
    updateSbCreative({ accountId, marketplace, creativeId, logoAssetId, videoAssetId, customImageAssetId, customImageAssetId2, customImageAssetId3, customImageAssetId4, customImageAssetId5, headline, state }: UpdateSbCreativeRequest): Observable<Response>
    updateSbCreative({ accountId, marketplace, creativeId, logoAssetId, videoAssetId, customImageAssetId, customImageAssetId2, customImageAssetId3, customImageAssetId4, customImageAssetId5, headline, state }: UpdateSbCreativeRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateSbCreative({ accountId, marketplace, creativeId, logoAssetId, videoAssetId, customImageAssetId, customImageAssetId2, customImageAssetId3, customImageAssetId4, customImageAssetId5, headline, state }: UpdateSbCreativeRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'updateSbCreative');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'updateSbCreative');
        throwIfNullOrUndefined(creativeId, 'creativeId', 'updateSbCreative');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (logoAssetId != null) { query['logoAssetId'] = logoAssetId; }
        if (videoAssetId != null) { query['videoAssetId'] = videoAssetId; }
        if (customImageAssetId != null) { query['customImageAssetId'] = customImageAssetId; }
        if (customImageAssetId2 != null) { query['customImageAssetId2'] = customImageAssetId2; }
        if (customImageAssetId3 != null) { query['customImageAssetId3'] = customImageAssetId3; }
        if (customImageAssetId4 != null) { query['customImageAssetId4'] = customImageAssetId4; }
        if (customImageAssetId5 != null) { query['customImageAssetId5'] = customImageAssetId5; }
        if (headline != null) { query['headline'] = headline; }
        if (state != null) { query['state'] = state; }

        return this.request<Response>({
            url: '/sbCreative/{accountId}/{marketplace}/{creativeId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{creativeId}', encodeURI(creativeId)),
            method: 'PUT',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Update a SB Creative Cluster
     * Update a SB Creative Cluster
     */
    updateSbCreativeCluster({ accountId, marketplace, creativeId, sbCreativeCluster }: UpdateSbCreativeClusterRequest): Observable<Response>
    updateSbCreativeCluster({ accountId, marketplace, creativeId, sbCreativeCluster }: UpdateSbCreativeClusterRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateSbCreativeCluster({ accountId, marketplace, creativeId, sbCreativeCluster }: UpdateSbCreativeClusterRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'updateSbCreativeCluster');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'updateSbCreativeCluster');
        throwIfNullOrUndefined(creativeId, 'creativeId', 'updateSbCreativeCluster');
        throwIfNullOrUndefined(sbCreativeCluster, 'sbCreativeCluster', 'updateSbCreativeCluster');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Response>({
            url: '/sbCreativeCluster/{accountId}/{marketplace}/{creativeId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{creativeId}', encodeURI(creativeId)),
            method: 'PUT',
            headers,
            body: sbCreativeCluster,
        }, opts?.responseOpts);
    };

    /**
     * Update a Segment
     * Update a Segment
     */
    updateSegment({ accountId, marketplace, segmentId, name }: UpdateSegmentRequest): Observable<Response>
    updateSegment({ accountId, marketplace, segmentId, name }: UpdateSegmentRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateSegment({ accountId, marketplace, segmentId, name }: UpdateSegmentRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'updateSegment');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'updateSegment');
        throwIfNullOrUndefined(segmentId, 'segmentId', 'updateSegment');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (name != null) { query['name'] = name; }

        return this.request<Response>({
            url: '/segment/{accountId}/{marketplace}/{segmentId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{segmentId}', encodeURI(segmentId)),
            method: 'PUT',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Add / delete segmentItems from a segment
     */
    updateSegmentItems({ accountId, marketplace, segmentId, action, targeting }: UpdateSegmentItemsRequest): Observable<Response>
    updateSegmentItems({ accountId, marketplace, segmentId, action, targeting }: UpdateSegmentItemsRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateSegmentItems({ accountId, marketplace, segmentId, action, targeting }: UpdateSegmentItemsRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'updateSegmentItems');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'updateSegmentItems');
        throwIfNullOrUndefined(segmentId, 'segmentId', 'updateSegmentItems');
        throwIfNullOrUndefined(action, 'action', 'updateSegmentItems');
        throwIfNullOrUndefined(targeting, 'targeting', 'updateSegmentItems');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'action': action,
        };

        return this.request<Response>({
            url: '/segmentItems/{accountId}/{marketplace}/{segmentId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{segmentId}', encodeURI(segmentId)),
            method: 'POST',
            headers,
            query,
            body: targeting,
        }, opts?.responseOpts);
    };

    /**
     * Update single field of a strategy by its id
     */
    updateStrategy({ accountId, marketplace, strategyId, organizationId, acosTarget, name, strategyLabel, state, suggestedBid, dailyBudget, primeDayBoost, minDailySpend, disableOtherQueries, disableAutoSegment, disableProductSegment, daypartingPauseHour, daypartingReactivationHour, priority, asinIsolation }: UpdateStrategyRequest): Observable<Response>
    updateStrategy({ accountId, marketplace, strategyId, organizationId, acosTarget, name, strategyLabel, state, suggestedBid, dailyBudget, primeDayBoost, minDailySpend, disableOtherQueries, disableAutoSegment, disableProductSegment, daypartingPauseHour, daypartingReactivationHour, priority, asinIsolation }: UpdateStrategyRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateStrategy({ accountId, marketplace, strategyId, organizationId, acosTarget, name, strategyLabel, state, suggestedBid, dailyBudget, primeDayBoost, minDailySpend, disableOtherQueries, disableAutoSegment, disableProductSegment, daypartingPauseHour, daypartingReactivationHour, priority, asinIsolation }: UpdateStrategyRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'updateStrategy');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'updateStrategy');
        throwIfNullOrUndefined(strategyId, 'strategyId', 'updateStrategy');
        throwIfNullOrUndefined(organizationId, 'organizationId', 'updateStrategy');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'organizationId': organizationId,
        };

        if (acosTarget != null) { query['acosTarget'] = acosTarget; }
        if (name != null) { query['name'] = name; }
        if (strategyLabel != null) { query['strategyLabel'] = strategyLabel; }
        if (state != null) { query['state'] = state; }
        if (suggestedBid != null) { query['suggestedBid'] = suggestedBid; }
        if (dailyBudget != null) { query['dailyBudget'] = dailyBudget; }
        if (primeDayBoost != null) { query['primeDayBoost'] = primeDayBoost; }
        if (minDailySpend != null) { query['minDailySpend'] = minDailySpend; }
        if (disableOtherQueries != null) { query['disableOtherQueries'] = disableOtherQueries; }
        if (disableAutoSegment != null) { query['disableAutoSegment'] = disableAutoSegment; }
        if (disableProductSegment != null) { query['disableProductSegment'] = disableProductSegment; }
        if (daypartingPauseHour != null) { query['daypartingPauseHour'] = daypartingPauseHour; }
        if (daypartingReactivationHour != null) { query['daypartingReactivationHour'] = daypartingReactivationHour; }
        if (priority != null) { query['priority'] = priority; }
        if (asinIsolation != null) { query['asinIsolation'] = asinIsolation; }

        return this.request<Response>({
            url: '/strategy/{accountId}/{marketplace}/{strategyId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{strategyId}', encodeURI(strategyId)),
            method: 'PUT',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * update strategy ASINs
     */
    updateStrategyAsins({ accountId, marketplace, strategyId, requestBody }: UpdateStrategyAsinsRequest): Observable<Response>
    updateStrategyAsins({ accountId, marketplace, strategyId, requestBody }: UpdateStrategyAsinsRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateStrategyAsins({ accountId, marketplace, strategyId, requestBody }: UpdateStrategyAsinsRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'updateStrategyAsins');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'updateStrategyAsins');
        throwIfNullOrUndefined(strategyId, 'strategyId', 'updateStrategyAsins');
        throwIfNullOrUndefined(requestBody, 'requestBody', 'updateStrategyAsins');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Response>({
            url: '/strategy/asins/{accountId}/{marketplace}/{strategyId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{strategyId}', encodeURI(strategyId)),
            method: 'POST',
            headers,
            body: requestBody,
        }, opts?.responseOpts);
    };

    /**
     * Add / delete targeting item to / from a strategy (Best KW or Brand defense)
     */
    updateStrategyKwTargeting({ accountId, marketplace, strategyId, action, targeting }: UpdateStrategyKwTargetingRequest): Observable<Response>
    updateStrategyKwTargeting({ accountId, marketplace, strategyId, action, targeting }: UpdateStrategyKwTargetingRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateStrategyKwTargeting({ accountId, marketplace, strategyId, action, targeting }: UpdateStrategyKwTargetingRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'updateStrategyKwTargeting');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'updateStrategyKwTargeting');
        throwIfNullOrUndefined(strategyId, 'strategyId', 'updateStrategyKwTargeting');
        throwIfNullOrUndefined(action, 'action', 'updateStrategyKwTargeting');
        throwIfNullOrUndefined(targeting, 'targeting', 'updateStrategyKwTargeting');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'action': action,
        };

        return this.request<Response>({
            url: '/strategy/targeting/{accountId}/{marketplace}/{strategyId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{strategyId}', encodeURI(strategyId)),
            method: 'POST',
            headers,
            query,
            body: targeting,
        }, opts?.responseOpts);
    };

    /**
     * update strategy monthly budget
     */
    updateStrategyMonthlyBudget({ accountId, marketplace, strategyId, monthlyBudget, nextMonthlyBudget, currentMonth }: UpdateStrategyMonthlyBudgetRequest): Observable<Response>
    updateStrategyMonthlyBudget({ accountId, marketplace, strategyId, monthlyBudget, nextMonthlyBudget, currentMonth }: UpdateStrategyMonthlyBudgetRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateStrategyMonthlyBudget({ accountId, marketplace, strategyId, monthlyBudget, nextMonthlyBudget, currentMonth }: UpdateStrategyMonthlyBudgetRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'updateStrategyMonthlyBudget');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'updateStrategyMonthlyBudget');
        throwIfNullOrUndefined(strategyId, 'strategyId', 'updateStrategyMonthlyBudget');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (monthlyBudget != null) { query['monthlyBudget'] = monthlyBudget; }
        if (nextMonthlyBudget != null) { query['nextMonthlyBudget'] = nextMonthlyBudget; }
        if (currentMonth != null) { query['currentMonth'] = currentMonth; }

        return this.request<Response>({
            url: '/strategy/monthlyBudget/{accountId}/{marketplace}/{strategyId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{strategyId}', encodeURI(strategyId)),
            method: 'PUT',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Add / delete keywords to / from a strategy topOfSearchRankings
     */
    updateStrategyTopOfSearchRankings({ accountId, marketplace, strategyId, action, organizationId, requestBody }: UpdateStrategyTopOfSearchRankingsRequest): Observable<Response>
    updateStrategyTopOfSearchRankings({ accountId, marketplace, strategyId, action, organizationId, requestBody }: UpdateStrategyTopOfSearchRankingsRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateStrategyTopOfSearchRankings({ accountId, marketplace, strategyId, action, organizationId, requestBody }: UpdateStrategyTopOfSearchRankingsRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'updateStrategyTopOfSearchRankings');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'updateStrategyTopOfSearchRankings');
        throwIfNullOrUndefined(strategyId, 'strategyId', 'updateStrategyTopOfSearchRankings');
        throwIfNullOrUndefined(action, 'action', 'updateStrategyTopOfSearchRankings');
        throwIfNullOrUndefined(organizationId, 'organizationId', 'updateStrategyTopOfSearchRankings');
        throwIfNullOrUndefined(requestBody, 'requestBody', 'updateStrategyTopOfSearchRankings');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'action': action,
            'organizationId': organizationId,
        };

        return this.request<Response>({
            url: '/strategy/topOfSearchRankings/{accountId}/{marketplace}/{strategyId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{strategyId}', encodeURI(strategyId)),
            method: 'POST',
            headers,
            query,
            body: requestBody,
        }, opts?.responseOpts);
    };

    /**
     * Update the bid modifer or boostPlacementTop of a tactic
     */
    updateTactic({ accountId, marketplace, strategyId, segmentId, intensity, boostPlacementTop }: UpdateTacticRequest): Observable<Response>
    updateTactic({ accountId, marketplace, strategyId, segmentId, intensity, boostPlacementTop }: UpdateTacticRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateTactic({ accountId, marketplace, strategyId, segmentId, intensity, boostPlacementTop }: UpdateTacticRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'updateTactic');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'updateTactic');
        throwIfNullOrUndefined(strategyId, 'strategyId', 'updateTactic');
        throwIfNullOrUndefined(segmentId, 'segmentId', 'updateTactic');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (intensity != null) { query['intensity'] = intensity; }
        if (boostPlacementTop != null) { query['boostPlacementTop'] = boostPlacementTop; }

        return this.request<Response>({
            url: '/tactic/{accountId}/{marketplace}/{strategyId}/{segmentId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{strategyId}', encodeURI(strategyId)).replace('{segmentId}', encodeURI(segmentId)),
            method: 'PUT',
            headers,
            query,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum UpdateSegmentItemsActionEnum {
    ADD = 'ADD',
    DELETE = 'DELETE'
}
/**
 * @export
 * @enum {string}
 */
export enum UpdateStrategyKwTargetingActionEnum {
    ADD = 'ADD',
    DELETE = 'DELETE'
}
/**
 * @export
 * @enum {string}
 */
export enum UpdateStrategyTopOfSearchRankingsActionEnum {
    ADD = 'ADD',
    DELETE = 'DELETE'
}
