<div class="flex flex-col items-center gap-3" *transloco="let t">
  <div>
    <span class="text-xs text-gray-400">3/3</span>
    <div class="mb-2 text-xl font-semibold text-gray-700">
      {{ t("free-test-subscription-page.start_my_free_test_heading") }}
    </div>
    <p>{{ t("free-test-subscription-page.start_my_free_test_subheading") }}</p>
  </div>
  <div class="mt-4 flex flex-col items-center gap-3">
    <IButton [label]="t('common.start_free_test_cta')" (onClick)="openFreeTestModal()"></IButton>
    <a class="text-gray-400 hover:text-gray-500" (click)="skipOnboarding()">{{ t("common.i_will_do_it_later") }}</a>
  </div>
</div>
<ng-template #accountSelection *transloco="let t">
  <div class="flex max-h-full flex-col gap-4 overflow-hidden">
    <div class="space-y-2 text-sm text-gray-500">
      <div>{{ t("onboarding.start_ads_auto_info") }}</div>
      <div>{{ t("onboarding.start_ads_auto_info_2") }}</div>
      <div class="mt-2">{{ t("onboarding.start_free_test_disclaimer") }}</div>
    </div>
    <hr class="my-4 border-gray-200" />
    <div class="min-h-0 flex-1 shrink-0 p-1">
      <select-accounts-page
        [accountMarketplaces]="accountMarketplaces()!"
        [disabledAccountsMarketplaces]="disabledAccountsMarketplaces()"
        [orgId]="organization?.organizationId"
        (selectionChange)="selectedAccountMarketplaces.set($event)"
      />
    </div>
    <div class="mt-2 flex justify-end gap-4">
      <IButton [label]="t('common.cancel')" color="white" (onClick)="accountSelectionModalRef()?.close()" />
      <IButton
        [label]="t('common.start_free_test_cta')"
        (onClick)="activateFreeTest()"
        [loading]="activatingFreeTest()"
        [disabled]="selectedAccountMarketplaces().length === 0"
      />
    </div>
  </div>
</ng-template>
