// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationType {
    ADS_API_ACCESS_LOST = 'ADS_API_ACCESS_LOST',
    SELLING_PARTNER_API_ACCESS_LOST = 'SELLING_PARTNER_API_ACCESS_LOST',
    NEW_ACCOUNT_TO_SETUP = 'NEW_ACCOUNT_TO_SETUP',
    OVERLAPPING_STRATEGIES = 'OVERLAPPING_STRATEGIES',
    OVERLAPPING_WITH_NON_M19_STRATEGIES = 'OVERLAPPING_WITH_NON_M19_STRATEGIES',
    ASINS_WITH_LOW_INVENTORY = 'ASINS_WITH_LOW_INVENTORY',
    ASINS_WITH_BACK_INVENTORY = 'ASINS_WITH_BACK_INVENTORY',
    DELETED_BRAND_ASSET = 'DELETED_BRAND_ASSET',
    BIDDER_ISSUE = 'BIDDER_ISSUE',
    UNKNOWN = 'UNKNOWN'
}

