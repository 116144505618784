<ng-container *transloco="let t">
  @if (form) {
    <form [classList]="['flex flex-col ' + (!reviewMode && 'h-full')]" [formGroup]="form" (ngSubmit)="submitForm(true)">
      <div [classList]="['p-5 ' + (!reviewMode && 'h-full')]">
        <h4 class="text-xl font-medium">{{ t("sb-form-info.strategy_info") }}</h4>
        @if (!reviewMode) {
          <p class="m-0 text-gray-500">{{ t("sb-form-info.enter_brand_for_strategy") }}</p>
        }
        @if (!tacosStrategy) {
          <div class="mt-5">
            <IInput
              label="{{ t('sp-strategy-creation.strategy_name') }}"
              placeholder="{{ t('sp-strategy-creation.strategy_name') }}"
              [counter]="80"
              [control]="form.controls.name"
              autoFocus
              testid="strategy-name-input"
            />
          </div>
        }
        @if (!isVendor) {
          <div class="mt-4">
            @if (reviewMode) {
              <IInput label="{{ t('common.brand') }}" [control]="brandNameControl!" class="sensitive-data" />
            } @else {
              <label
                class="m-0 mb-1 block pl-1 text-sm font-medium text-gray-700 after:ms-0.5 after:text-red-500 after:content-['*']"
                >{{ t("common.brand") }}</label
              >
              @if (brandsLoading || !_am) {
                <div class="h-[42px] w-full animate-pulse rounded-md bg-gray-100"></div>
              } @else if (brands && brands.length > 0) {
                <ISelect
                  [options]="brandOptions"
                  [selectedValue]="selectedBrand?.value"
                  (selectedOptionChange)="selectBrand($event)"
                  testid="brand-selection"
                  class="sensitive-data"
                  searchable
                />
              } @else {
                <p class="m-0 text-gray-500">{{ t("sb-form-info.no_brands_available") }}</p>
              }
            }
          </div>
        }
      </div>
      @if (!reviewMode) {
        <div class="flex justify-end border-t border-gray-300 bg-white p-4">
          <IButton
            [disabled]="form.invalid"
            label="{{ t('common.next') }}"
            type="submit"
            icon="icon-[material-symbols--chevron-right-rounded]"
            [trailing]="true"
            testid="next-button"
          />
        </div>
      }
    </form>
  }
</ng-container>
