import { CommonModule } from '@angular/common';
import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'ICard',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icard.component.html',
})
export class ICardComponent {
  @Input() class: string = ''; // custom css class

  @ContentChild('header', { static: false }) header: TemplateRef<any> | undefined;
  @ContentChild('body', { static: false }) body: TemplateRef<any> | undefined;
  @ContentChild('footer', { static: false }) footer: TemplateRef<any> | undefined;
}
