import { CommonModule } from "@angular/common";
import { Component, computed, inject, input, output } from "@angular/core";
import { TacosStrategyGroupStateEnum } from "@front/m19-api-client";
import { IToggleComponent } from "@front/m19-ui";
import { TranslocoService } from "@jsverse/transloco";
import { ICON_PAUSE, ICON_PLAY } from "@m19-board/utils/iconsLabels";

@Component({
  selector: "state-badge",
  standalone: true,
  imports: [CommonModule, IToggleComponent],
  templateUrl: "./state-badge.component.html",
})
export class StateBadgeComponent {
  readonly TacosStrategyGroupStateEnum = TacosStrategyGroupStateEnum;
  readonly translocoService = inject(TranslocoService);

  state = input<TacosStrategyGroupStateEnum | undefined>();
  loading = input<boolean>(false);

  onClick = output<TacosStrategyGroupStateEnum>();

  isChecked = computed(() => this.state() === TacosStrategyGroupStateEnum.ENABLED);

  label = computed(() => {
    if (this.state() === TacosStrategyGroupStateEnum.ENABLED)
      return this.translocoService.translate("common.activated");
    else return this.translocoService.translate("strategy-page.paused");
  });

  icon = computed(() => {
    if (this.state() === TacosStrategyGroupStateEnum.ENABLED) return ICON_PLAY;
    else return ICON_PAUSE;
  });

  handleClick() {
    if (this.loading()) return;
    this.onClick.emit(
      this.state() === TacosStrategyGroupStateEnum.ENABLED
        ? TacosStrategyGroupStateEnum.PAUSED
        : TacosStrategyGroupStateEnum.ENABLED,
    );
  }
}
