import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { TranslocoService } from "@jsverse/transloco";
import { InventoryConfig, Marketplace } from "@front/m19-api-client";
import { Utils } from "@front/m19-utils";

@Component({
  selector: "app-custom-field-edition",
  template: ` <div
    class="flex flex-col items-start justify-between p-6"
    [ngClass]="asin ? 'h-72' : 'h-48'"
    *transloco="let t"
  >
    @if (asin) {
      <app-product-view
        [asin]="asin"
        [marketplace]="marketplace"
        class="mb-4 w-full rounded-lg border border-solid border-gray-300 p-4"
      ></app-product-view>
    }
    <div class="input-block w-full">
      <label class="mb-2 font-semibold">{{ customFieldText }}</label>
      <input
        class="simple-input form-control"
        [value]="value ?? ''"
        placeholder="{{ t('custom-field-edition.enter_value_for_this_custom_field') }}"
        maxlength="100"
        (keydown.enter)="updateCustomField()"
        (input)="newCustomFieldValue = getValueFromInputEvent($event)"
      />
      <span class="input-block__hint">{{ newCustomFieldValue?.length ?? "0" }}/100</span>
    </div>
    <div class="mt-4 flex w-full justify-end gap-2">
      <IButton
        label="{{ t('common.delete') }}"
        tooltipValue="Delete {{ customFieldText }} for {{ asin ? 'this ASIN' : 'selected products' }}"
        color="red"
        (onClick)="deleteCustomField()"
      ></IButton>
      <IButton label="{{ t('common.update') }}" (onClick)="updateCustomField()"></IButton>
    </div>
  </div>`,
})
export class CustomFieldEditionComponent implements OnInit {
  @Input() asin: string;
  @Input() marketplace: Marketplace;
  @Input() customField: "field1" | "field2";
  @Input() value: string;
  @Input() inventoryConfig: InventoryConfig;
  @Output() valueChange = new EventEmitter<{ newValue: string } | "deletion">();

  customFieldText: string;
  newCustomFieldValue: string;

  constructor(
    private modalRef: BsModalRef,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.customFieldText =
      this.customField === "field1"
        ? (Utils.titleCase(this.inventoryConfig?.customField1Name) ??
          this.translocoService.translate("custom-field-edition.custom_field_1"))
        : (Utils.titleCase(this.inventoryConfig?.customField2Name) ??
          this.translocoService.translate("custom-field-edition.custom_field_2"));
  }

  updateCustomField() {
    this.modalRef.hide();
    this.valueChange.emit({ newValue: this.newCustomFieldValue });
  }

  deleteCustomField() {
    this.modalRef.hide();
    this.valueChange.emit("deletion");
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
