import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IButtonComponent } from "@front/m19-ui";
import { AgGridModule, ICellRendererAngularComp } from "@ag-grid-community/angular";
import { GetContextMenuItemsParams, ICellRendererParams } from "@ag-grid-community/core";

export interface ActionButton {
  icon?: string;
  text?: string;
  tooltip?: string;
  disabled?: boolean;
  subItems?: ActionButton[];
  title?: string;
  classes?: string[];
  badge?: string;
  color?: "main" | "red" | "orange" | "white" | "gray";

  onClick?(params: ICellRendererParams | GetContextMenuItemsParams): void;
}

@Component({
  selector: "app-action-buttons",
  standalone: true,
  imports: [CommonModule, AgGridModule, FontAwesomeModule, MatTooltipModule, MatMenuModule, IButtonComponent],
  templateUrl: "./action-buttons.component.html",
})
export class ActionButtonsComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  actionButtons: ActionButton[];

  agInit(params: ICellRendererParams & { actionButtons: ActionButton[] }): void {
    this.params = params;
    this.actionButtons = params.actionButtons;
  }

  refresh(): boolean {
    return false;
  }
}
