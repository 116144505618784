@if (isTest || isAdminLogin()) {
  <div class="flex w-full justify-between bg-orange-700 px-4 text-white">
    <div class="flex gap-6">
      @if (isTest) {
        <span class="font-bold">Demo environment</span>
        @if (isAdminLogin()) {
          <span class="font-bold">|</span>
        }
      }
      @if (isAdminLogin()) {
        <span>Logged via Admin Board</span>
      }
    </div>
    <a (click)="printDebugInfo()">Debug Info</a>
  </div>
}
@if (globalWarning$ | async) {
  <div class="border-sha w-full rounded-sm bg-red-100 px-4 py-1 text-red-700">
    {{ globalWarning$ | async }}
  </div>
}
<div class="flex max-h-screen flex-col">
  <header class="fixed top-0 flex flex-col">
    <div class="flex w-full min-w-0 flex-1 items-center justify-between" *transloco="let t">
      <div class="flex items-center">
        <a class="navbar-brand h-14" routerLink="/dashboard360" queryParamsHandling="merge">
          <logo></logo>
        </a>
        <app-account-selector scope="dashboard360"></app-account-selector>
      </div>

      <div class="nav-sub flex justify-end px-2" style="flex: initial !important">
        <ul class="nav navbar-nav">
          <app-currency-selection></app-currency-selection>

          <li>
            <app-date-range-selector />
          </li>
          <li class="nav-item mx-0">
            <div class="settings-menu">
              <IButton
                [icon]="ICON_SETTINGS"
                tooltipValue="{{ t('common.settings') }}"
                variant="ghost"
                [matMenuTriggerFor]="settingsMenu"
              />
              <mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
                <ul class="flex flex-col">
                  <li class="dd-item">
                    <a routerLink="/dashboard360/profile" queryParamsHandling="merge">
                      <span [class]="ICON_USER + ' mr-2 text-gray-500'"></span>
                      {{ t("dashboard360-layout.my_profile") }}
                    </a>
                  </li>
                  <li class="dd-item">
                    <div (click)="logout()" class="w-full">
                      <span [class]="ICON_LOGOUT + ' mr-2 text-gray-500'"></span>
                      {{ t("dashboard360-layout.logout") }}
                    </div>
                  </li>
                </ul>
              </mat-menu>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="ml-4 pt-1">
      <ul class="nav-wrapper mb-3">
        @for (link of tabs; track link) {
          <li
            class="nav-element"
            [class.active]="url.startsWith('/' + link.url)"
            attr.data-testid="tabbed-nav-{{ link.name.replaceAll(' ', '_') }}"
          >
            <a queryParamsHandling="merge" [routerLink]="'/' + link.url">{{ link.name }} </a>
          </li>
        }
      </ul>
    </div>
  </header>

  <div class="overflow-auto p-4">
    <router-outlet></router-outlet>
  </div>
</div>
