<ng-container *transloco="let t">
  <div class="modal-header bg-main-500">
    <h4 class="modal-title">
      @if (title !== undefined) {
        <span>{{ title }}</span>
      }
    </h4>
    <h4 class="text-secondary pointer" (click)="close()">&times;</h4>
  </div>
  <div class="modal-body">
    <div class="px-4">
      @if (withCreativeTab) {
        <mat-tab-group
          animationDuration="0ms"
          [selectedIndex]="selectedTab"
          (selectedIndexChange)="selectedTab = $event; tabChanged = true"
        >
          <mat-tab label="{{ t('common.strategy') }}">
            <ng-container [ngTemplateOutlet]="gridContent"></ng-container>
          </mat-tab>
          <mat-tab label="{{ t('common.creatives') }}">
            <div class="h-[40rem]">
              <app-sb-creative-stats-grid [strategy]="strategy" [currency]="currency" [locale]="locale" />
            </div>
          </mat-tab>
        </mat-tab-group>
      } @else {
        <ng-container [ngTemplateOutlet]="gridContent"></ng-container>
      }
    </div>
  </div>

  <ng-template #gridContent>
    <div class="mb-2 flex items-center justify-end">
      <app-export-button
        (export)="onExportCsvClicked()"
        tooltipValue="{{ t('common.export_as_csv') }}"
      ></app-export-button>
    </div>

    <div class="ag-theme-quartz">
      <ag-grid-angular style="height: 40rem" [rowData]="gridData" [gridOptions]="gridOptions"></ag-grid-angular>
    </div>
  </ng-template>
</ng-container>
