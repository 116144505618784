<div class="row" *transloco="let t">
  <div class="col-sm-6">
    @if (!enableProducts) {
      <h4 class="disabled-kw absolute">{{ t("targeting-detail.products_targeting_is_disabled") }}</h4>
    }
    @if (whitelist) {
      <h5 class="mb-4">{{ t("targeting-detail.landing_pages_to_target") }}</h5>
    }
    @if (!whitelist) {
      <h5 class="mb-4">{{ t("targeting-detail.landing_pages_to_blacklist") }}</h5>
    }
    <div class="mb-4" [class.disabled-noclick-hard]="!enableProducts">
      <textarea
        type="text"
        #asinspages
        class="textarea-width form-control"
        placeholder="{{ t('targeting-detail.add_asins_list') }}"
      ></textarea>
      <button
        type="button"
        class="btn btn-primary pull-right add-button"
        [disabled]="isReadOnly"
        (click)="addAsins(asinspages.value); asinspages.value = ''"
      >
        {{ t("common.add") }}
      </button>
      @if (errorsAsin && errorsAsin.length > 0) {
        <div class="list-errors">
          <ul class="list-group">
            <li class="list-group-item list-group-item list-group-item-warning flex justify-between">
              <span>{{ t("catalog-upload.asins_ignored") }}</span>
              <button
                type="button"
                class="btn text-warning bg-transparent p-0"
                (click)="errorsAsin = []"
                matTooltip="{{ t('catalog-upload.close_error_table') }}"
              >
                <fa-icon [icon]="faTimes" class="font-lg"></fa-icon>
              </button>
            </li>
            @for (error of errorsAsin; track error) {
              <li class="list-group-item list-group-item-secondary py-0">
                {{ error }}
              </li>
            }
          </ul>
        </div>
      }
    </div>
    <div class="arrow">
      <fa-icon [icon]="faArrowDown" size="2x"></fa-icon>
    </div>
    <div class="mt-5">
      <div class="max-h-[40vh]">
        <app-strategy-asins
          [asins]="asins"
          [selectable]="true"
          [movable]="false"
          [csvExportFileName]="name"
          [csvExport]="true"
          [accountId]="accountId"
          [marketplace]="marketplace"
          (onDelete)="deleteAsins($event)"
        />
      </div>
    </div>
  </div>
  <div class="col-sm-6 relative">
    @if (!enableKW) {
      <h4 class="disabled-kw absolute">
        {{ t("targeting-detail.keywords_are_not_available_for_sd_strategies") }}
      </h4>
    }
    <div [class.disabled-noclick-hard]="!enableKW">
      @if (whitelist) {
        <h5 class="mb-4">{{ t("targeting-detail.keywords_to_target") }}</h5>
      }
      @if (!whitelist) {
        <h5 class="mb-4">{{ t("targeting-detail.keywords_to_blacklist") }}</h5>
      }
      <div class="keywords-inputs mb-4">
        <div class="button-group">
          <div
            class="btn-group"
            matTooltip="{{ t('targeting-detail.select_the_keywords_type_to_add') }}"
            data-toggle="buttons-radio"
          >
            @if (!onlyExactMatchType) {
              <app-switch-button
                [options]="[MatchType.phrase, MatchType.exact]"
                [buttonTexts]="['common.phrase', 'common.exact']"
                [buttonTooltips]="['', '']"
                [selected]="keywordMatchType"
                (buttonClicked)="setKeywordMatchType($event)"
              />
            }
          </div>
        </div>
        <textarea
          type="text"
          #keywordsTextArea
          class="textarea-width form-control simple-input mb-2"
          [placeholder]="
            keywordMatchType.toString() === 'exact'
              ? t('targeting-detail.add_keywords_no_more_than_10_words')
              : t('targeting-detail.add_keywords_no_more_than_4_words')
          "
        ></textarea>
        <button
          type="button"
          class="btn btn-primary pull-right add-button"
          [disabled]="isReadOnly"
          (click)="addKeywords(keywordsTextArea.value); keywordsTextArea.value = ''"
        >
          {{ t("common.add") }}
        </button>
        @if (errorsKeyword && errorsKeyword.length > 0) {
          <div>
            <div class="text-warning ml-1 flex content-start">
              <span>
                {{ t("keyword-segment-modal.ignored_keywords", { count: errorsKeyword.length }) }}
              </span>
              <div>
                <a class="ml-3 text-inherit hover:underline" (click)="openIgnoredKeywordModal()">
                  {{ t("common.see_more") }}</a
                >
              </div>
            </div>
          </div>
        }
      </div>
      <div class="arrow">
        <fa-icon [icon]="faArrowDown" size="2x"></fa-icon>
      </div>
      <div class="mt-5">
        <app-keywords-list
          [keywords]="keywordTargetingItems"
          [name]="name"
          [isSelectable]="true"
          (selectedItems)="deleteKeywords($event)"
        >
        </app-keywords-list>
      </div>
    </div>
  </div>
</div>
