// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    AdSpendFee,
    FlatFee,
    PerAccountFee,
} from './';

/**
 * @export
 * @interface AllFees
 */
export interface AllFees {
    /**
     * @type {Array<FlatFee>}
     * @memberof AllFees
     */
    flatFees?: Array<FlatFee>;
    /**
     * @type {Array<PerAccountFee>}
     * @memberof AllFees
     */
    perAccountFees?: Array<PerAccountFee>;
    /**
     * @type {Array<AdSpendFee>}
     * @memberof AllFees
     */
    adSpendFees?: Array<AdSpendFee>;
}
