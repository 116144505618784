// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    Marketplace,
} from './';

/**
 * @export
 * @interface GlobalSettlementFees
 */
export interface GlobalSettlementFees {
    /**
     * @type {string}
     * @memberof GlobalSettlementFees
     */
    accountId?: string;
    /**
     * @type {Marketplace}
     * @memberof GlobalSettlementFees
     */
    marketplace?: Marketplace;
    /**
     * @type {string}
     * @memberof GlobalSettlementFees
     */
    date?: string;
    /**
     * @type {number}
     * @memberof GlobalSettlementFees
     */
    liquidations?: number;
    /**
     * @type {number}
     * @memberof GlobalSettlementFees
     */
    reimbursement?: number;
    /**
     * @type {number}
     * @memberof GlobalSettlementFees
     */
    reimbursementClawback?: number;
    /**
     * @type {number}
     * @memberof GlobalSettlementFees
     */
    longTermStorageFee?: number;
    /**
     * @type {number}
     * @memberof GlobalSettlementFees
     */
    internationalFreight?: number;
    /**
     * @type {number}
     * @memberof GlobalSettlementFees
     */
    other?: number;
}
