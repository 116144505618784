import { Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CurrencyService, StatsApiClientService, StrategyService } from "@front/m19-services";
import {
  AlgoModeConfig,
  AlgoModeConfigValid,
} from "@m19-board/strategies/strategies/algo-mode-selection/algo-mode-selection.component";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { AlgoMode } from "@front/m19-api-client";
import { StrategyEx } from "@front/m19-models";

@UntilDestroy()
@Component({
  selector: "app-modal-content",
  templateUrl: "./switch-algo-mode.component.html",
})
export class SwitchAlgoModeComponent implements OnInit {
  readonly AlgoModes = AlgoMode;

  @Input()
  strategy: StrategyEx;

  updateLoading = false;

  suggestedAcosTarget: number;
  selectedAlgoModeConfig: Partial<AlgoModeConfig>;
  algoModeConfigValid: AlgoModeConfigValid;

  constructor(
    public bsModalRef: BsModalRef,
    private toasterService: ToastrService,
    private statsApiClientService: StatsApiClientService,
    public currencyService: CurrencyService,
    private strategyService: StrategyService,
  ) {}

  ngOnInit(): void {
    this.statsApiClientService.lastSevenDaysStatsPerStrategy$.pipe(untilDestroyed(this)).subscribe((stats) => {
      const strategyStats = stats.get(this.strategy.strategyId);
      if (strategyStats) {
        this.suggestedAcosTarget = Math.round((strategyStats.cost / strategyStats.adSales) * 100);
      }
    });
    this.selectedAlgoModeConfig = {
      algoMode: this.strategy.algoMode,
      acosTarget: this.strategy.acosTarget ? Math.round(this.strategy.acosTarget * 100) : undefined,
      monthlyBudget: this.strategy.monthlyBudget,
      suggestedBid: this.strategy.suggestedBid,
      dailyBudget: this.strategy.dailyBudget,
    };
  }

  setAlgoModeConfig(algoModeConfig: Partial<AlgoModeConfig>) {
    this.selectedAlgoModeConfig = algoModeConfig;
  }

  setAlgoModeConfigValid(valid: AlgoModeConfigValid) {
    this.algoModeConfigValid = valid;
  }

  update(): void {
    // ensure that all fields are valid
    if (!this.algoModeConfigValid.isValid) {
      return;
    }
    this.updateLoading = true;

    this.strategyService
      .switchStrategyAlgoMode(
        this.strategy.accountId,
        this.strategy.marketplace,
        this.strategy,
        this.selectedAlgoModeConfig.algoMode,
        this.selectedAlgoModeConfig.acosTarget ? this.selectedAlgoModeConfig.acosTarget / 100 : undefined,
        this.selectedAlgoModeConfig.suggestedBid,
        this.selectedAlgoModeConfig.dailyBudget,
        this.selectedAlgoModeConfig.monthlyBudget,
      )
      .subscribe({
        next: (strategy: StrategyEx) => {
          this.toasterService.success(`Algo Mode updated for strategy ${strategy.name}`, "Strategy updated");
          this.bsModalRef.hide();
          this.updateLoading = false;
        },
        error: (e) => {
          this.toasterService.error(`Error when updating Algo mode: ${e}`, "Strategy update error");
          this.bsModalRef.hide();
          this.updateLoading = false;
        },
      });
  }

  saveDisabled(): boolean {
    return !this.algoModeConfigValid || !this.algoModeConfigValid.isValid;
  }
}
