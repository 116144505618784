import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AccountMarketplace, AccountSubType, AccountType, CampaignType } from "@front/m19-api-client";
import { AccountSelectionService, SbStrategiesService } from "@front/m19-services";
import { BoardType, LayoutSelectorService } from "@m19-board/layout-selector.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, of } from "rxjs";
import { StrategyOverlayComponent } from "@m19-board/overlay/strategy-overlay.component";
import { AsyncPipe } from "@angular/common";
import { StrategyListComponent } from "@m19-board/strategies/strategy-list/strategy-list.component";
import { TranslocoDirective } from "@jsverse/transloco";

@UntilDestroy()
@Component({
  selector: "app-sb-manager",
  templateUrl: "./sb-manager.component.html",
  standalone: true,
  imports: [StrategyOverlayComponent, AsyncPipe, StrategyListComponent, TranslocoDirective],
})
export class SbManagerComponent implements OnInit {
  accountMarketplace?: AccountMarketplace;
  m19BoardType = false;
  readonly CampaignType = CampaignType;

  constructor(
    private sbStrategiesService: SbStrategiesService,
    private accountSelectionService: AccountSelectionService,
    private layoutSelectorService: LayoutSelectorService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.accountMarketplace = am;
    });
    this.m19BoardType = this.layoutSelectorService.getBoardType() == BoardType.M19;
  }

  createSponsoredBrands(accountMarketplace: AccountMarketplace) {
    this.router.navigate(["advertising/sponsored-brands/create"], { queryParamsHandling: "merge" });
  }

  hasBrand(): Observable<boolean> {
    if (!this.accountMarketplace) return of(true);
    else if (this.accountMarketplace.accountType == AccountType.VENDOR) return of(true);
    else
      return this.sbStrategiesService.hasBrands(this.accountMarketplace.accountId, this.accountMarketplace.marketplace);
  }

  isKDP() {
    return this.accountMarketplace?.accountSubType === AccountSubType.KDP_AUTHOR;
  }
}
