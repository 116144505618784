// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    TokenResponse,
} from '../models';

export interface GetAdminTokenRequest {
    userId?: number;
}

/**
 * no description
 */
export class TokenApi extends BaseAPI {

    /**
     * This authentication endpoint provide an admin m19 access token requiered to authenticate on all other API endpoints. In order to authenticate the request must contain an access token from an admin uer.
     * Get an m19 access token, the user info and list of managed accounts
     */
    getAdminToken({ userId }: GetAdminTokenRequest): Observable<TokenResponse>
    getAdminToken({ userId }: GetAdminTokenRequest, opts?: OperationOpts): Observable<AjaxResponse<TokenResponse>>
    getAdminToken({ userId }: GetAdminTokenRequest, opts?: OperationOpts): Observable<TokenResponse | AjaxResponse<TokenResponse>> {

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
        };

        const query: HttpQuery = {};

        if (userId != null) { query['userId'] = userId; }

        return this.request<TokenResponse>({
            url: '/adminToken',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * This authentication endpoint provide an m19 access token required to authenticate on all other API endpoints. In order to authenticate the request must contain a [Basic Authorization HTTP header](https://tools.ietf.org/html/rfc7617) with the m19 user / password defined on board.m19.com. Please be sure to use it on a secure HTTPS connection to correctly encrypt your password.
     * Get an m19 access token, the user info and list of managed accounts
     */
    getToken(): Observable<TokenResponse>
    getToken(opts?: OperationOpts): Observable<AjaxResponse<TokenResponse>>
    getToken(opts?: OperationOpts): Observable<TokenResponse | AjaxResponse<TokenResponse>> {
        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<TokenResponse>({
            url: '/token',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

}
