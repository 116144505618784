// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    AccountMarketplace,
    AccountState,
    AmazonUser,
    DspAdvertiser,
    Marketplace,
    Response,
    UserAccount,
} from '../models';

export interface BidderActivationRequest {
    accountMarketplace: Array<AccountMarketplace>;
}

export interface CreateAccountGroupRequest {
    accountId: string;
    marketplace: Marketplace;
    accountGroupName: string;
    organizationId?: number;
    profileId?: number;
    amazonToken?: string;
}

export interface FetchDataRequest {
    accountId: string;
    marketplace: Marketplace;
}

export interface GetAmazonUserRequest {
    code: string;
    organizationId: number;
}

export interface GetDspAdvertiserRequest {
    dspAdvertiserId: string;
}

export interface RunBidderRequest {
    accountId: string;
    marketplace: Marketplace;
}

export interface UpdateAccountMarketplaceRequest {
    accountId: string;
    marketplace: Marketplace;
    state?: AccountState;
    previousState?: AccountState;
    accountGroupName?: string;
    accountGroupId?: number;
    campaignLabel?: string;
    maxBid?: number;
    customCampaignName?: string;
    useSourcingMetrics?: boolean;
    organizationId?: number;
    profileId?: number;
    amazonToken?: string;
    promoStartDate?: string;
    promoEndDate?: string;
    dspAdvertiserId?: string | null;
    checkAccountActivation?: boolean | null;
}

/**
 * no description
 */
export class AccountApi extends BaseAPI {

    /**
     * Update the state, the campaign label or accountGroup of an account-marketplace
     */
    bidderActivation({ accountMarketplace }: BidderActivationRequest): Observable<Array<AccountMarketplace>>
    bidderActivation({ accountMarketplace }: BidderActivationRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<AccountMarketplace>>>
    bidderActivation({ accountMarketplace }: BidderActivationRequest, opts?: OperationOpts): Observable<Array<AccountMarketplace> | AjaxResponse<Array<AccountMarketplace>>> {
        throwIfNullOrUndefined(accountMarketplace, 'accountMarketplace', 'bidderActivation');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<AccountMarketplace>>({
            url: '/billing/bidderActivation',
            method: 'POST',
            headers,
            body: accountMarketplace,
        }, opts?.responseOpts);
    };

    /**
     * Create a new account group populated with a inital account or account-marketplace
     * Create a new account group
     */
    createAccountGroup({ accountId, marketplace, accountGroupName, organizationId, profileId, amazonToken }: CreateAccountGroupRequest): Observable<Response>
    createAccountGroup({ accountId, marketplace, accountGroupName, organizationId, profileId, amazonToken }: CreateAccountGroupRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    createAccountGroup({ accountId, marketplace, accountGroupName, organizationId, profileId, amazonToken }: CreateAccountGroupRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'createAccountGroup');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'createAccountGroup');
        throwIfNullOrUndefined(accountGroupName, 'accountGroupName', 'createAccountGroup');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountGroupName': accountGroupName,
        };

        if (organizationId != null) { query['organizationId'] = organizationId; }
        if (profileId != null) { query['profileId'] = profileId; }
        if (amazonToken != null) { query['amazonToken'] = amazonToken; }

        return this.request<Response>({
            url: '/account/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'POST',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Ask for a run a the bidder
     * Run bidder
     */
    fetchData({ accountId, marketplace }: FetchDataRequest): Observable<Response>
    fetchData({ accountId, marketplace }: FetchDataRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    fetchData({ accountId, marketplace }: FetchDataRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'fetchData');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'fetchData');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Response>({
            url: '/action/{accountId}/{marketplace}/fetchData'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'POST',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * This authentication endpoint provide an m19 access token requiered to authenticate on all other API endpoints. in order to authenticate the request must contain one of:   - an Amazon Oauth code obtained from an Amazon user granting advertizin management right. This is the only way to create a new m19 user.   - a [Basic Authorization HTTP header](https://tools.ietf.org/html/rfc7617) containing the m19 user / password defined on board.m19.com. Please be sure to use it on a secure HTTPS connection to correctly encrypt your password.
     * Get an AmazonUser with list of manage accounts
     */
    getAmazonUser({ code, organizationId }: GetAmazonUserRequest): Observable<AmazonUser>
    getAmazonUser({ code, organizationId }: GetAmazonUserRequest, opts?: OperationOpts): Observable<AjaxResponse<AmazonUser>>
    getAmazonUser({ code, organizationId }: GetAmazonUserRequest, opts?: OperationOpts): Observable<AmazonUser | AjaxResponse<AmazonUser>> {
        throwIfNullOrUndefined(code, 'code', 'getAmazonUser');
        throwIfNullOrUndefined(organizationId, 'organizationId', 'getAmazonUser');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'code': code,
            'organizationId': organizationId,
        };

        return this.request<AmazonUser>({
            url: '/amazonUser',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Get DSP advertiser for a given DSP advertiser id
     */
    getDspAdvertiser({ dspAdvertiserId }: GetDspAdvertiserRequest): Observable<DspAdvertiser>
    getDspAdvertiser({ dspAdvertiserId }: GetDspAdvertiserRequest, opts?: OperationOpts): Observable<AjaxResponse<DspAdvertiser>>
    getDspAdvertiser({ dspAdvertiserId }: GetDspAdvertiserRequest, opts?: OperationOpts): Observable<DspAdvertiser | AjaxResponse<DspAdvertiser>> {
        throwIfNullOrUndefined(dspAdvertiserId, 'dspAdvertiserId', 'getDspAdvertiser');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'dspAdvertiserId': dspAdvertiserId,
        };

        return this.request<DspAdvertiser>({
            url: '/dspAdvertiser',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * List all accounts with amazon user and user infos
     * List all accounts with user infos
     */
    listAllAccounts(): Observable<Array<UserAccount>>
    listAllAccounts(opts?: OperationOpts): Observable<AjaxResponse<Array<UserAccount>>>
    listAllAccounts(opts?: OperationOpts): Observable<Array<UserAccount> | AjaxResponse<Array<UserAccount>>> {
        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<UserAccount>>({
            url: '/accounts',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Ask for a run a the bidder
     * Fetch Amazon data
     */
    runBidder({ accountId, marketplace }: RunBidderRequest): Observable<Response>
    runBidder({ accountId, marketplace }: RunBidderRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    runBidder({ accountId, marketplace }: RunBidderRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'runBidder');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'runBidder');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Response>({
            url: '/action/{accountId}/{marketplace}/runBidder'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'POST',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Allow to perform one of the following exclusive update operations of an account-marketplace entity - update the state to NEW, OFF, DOWNLOADER_ON, BIDDER_ON - rename the accountGroup name of the account-marketplace - add the account-marketplace to another already existinga Account Group - add a campaign label to the account-marketplace - update the max bid on the account-marketplace - set or update a custom regex for campaign names for the account-marketplace. - update promo start and end date - update the dspAdvertiserId - check the account activation
     * Update the state, the campaign label or accountGroup of an account-marketplace
     */
    updateAccountMarketplace({ accountId, marketplace, state, previousState, accountGroupName, accountGroupId, campaignLabel, maxBid, customCampaignName, useSourcingMetrics, organizationId, profileId, amazonToken, promoStartDate, promoEndDate, dspAdvertiserId, checkAccountActivation }: UpdateAccountMarketplaceRequest): Observable<Response>
    updateAccountMarketplace({ accountId, marketplace, state, previousState, accountGroupName, accountGroupId, campaignLabel, maxBid, customCampaignName, useSourcingMetrics, organizationId, profileId, amazonToken, promoStartDate, promoEndDate, dspAdvertiserId, checkAccountActivation }: UpdateAccountMarketplaceRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateAccountMarketplace({ accountId, marketplace, state, previousState, accountGroupName, accountGroupId, campaignLabel, maxBid, customCampaignName, useSourcingMetrics, organizationId, profileId, amazonToken, promoStartDate, promoEndDate, dspAdvertiserId, checkAccountActivation }: UpdateAccountMarketplaceRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'updateAccountMarketplace');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'updateAccountMarketplace');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (state != null) { query['state'] = state; }
        if (previousState != null) { query['previousState'] = previousState; }
        if (accountGroupName != null) { query['accountGroupName'] = accountGroupName; }
        if (accountGroupId != null) { query['accountGroupId'] = accountGroupId; }
        if (campaignLabel != null) { query['campaignLabel'] = campaignLabel; }
        if (maxBid != null) { query['maxBid'] = maxBid; }
        if (customCampaignName != null) { query['customCampaignName'] = customCampaignName; }
        if (useSourcingMetrics != null) { query['useSourcingMetrics'] = useSourcingMetrics; }
        if (organizationId != null) { query['organizationId'] = organizationId; }
        if (profileId != null) { query['profileId'] = profileId; }
        if (amazonToken != null) { query['amazonToken'] = amazonToken; }
        if (promoStartDate != null) { query['promoStartDate'] = promoStartDate; }
        if (promoEndDate != null) { query['promoEndDate'] = promoEndDate; }
        if (dspAdvertiserId != null) { query['dspAdvertiserId'] = dspAdvertiserId; }
        if (checkAccountActivation != null) { query['checkAccountActivation'] = checkAccountActivation; }

        return this.request<Response>({
            url: '/account/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'PUT',
            headers,
            query,
        }, opts?.responseOpts);
    };

}
