<div class="mb-3 flex items-center" *transloco="let t">
  <app-strategy-dropdown
    class="start-dropdown"
    [strategies]="strategies"
    (selectedStrategy)="setStrategy($event)"
  ></app-strategy-dropdown>

  <ICheckbox
    class="ml-4"
    label="{{ t('hourly-chart.include_not_operated_campaign') }}"
    [value]="includeNonM19Campaign"
    (valueChange)="toggleIncludeNonM19Campaign($event)"
  />
</div>
<div>
  <app-metric-selector
    [data]="globalData"
    (chartMetricsChanges)="selectMetrics($event)"
    [pageMetrics]="$any(CHART_METRICS)"
    [localStorageKey]="localStorageKey"
    [showCompare]="false"
  ></app-metric-selector>
</div>

<div class="graph box card border-shadow mt-2 pb-0" [hidden]="!chartDisplayed" *transloco="let t">
  <div class="row justify-end">
    <div class="relative float-right mr-3">
      <IButton
        variant="ghost"
        size="sm"
        icon="icon-[lucide--x]"
        [tooltipValue]="t('hourly-chart.hide_chart')"
        (onClick)="toggleChartDisplay(false)"
      />
    </div>
  </div>
  <app-spinner [display]="pendingRequests"></app-spinner>
  <div class="chart-wrapper mt-2">
    @if (!pendingRequests) {
      <canvas
        baseChart
        class="chart"
        [datasets]="dataSet.chartDataSet"
        type="line"
        [labels]="dataSet.labels"
        [options]="dataSet.lineChartOptions"
        #canvas
      >
      </canvas>
    }
  </div>
  <div class="mt-3 flex justify-end">
    <div class="flex flex-col">
      <small class="'text-sm">{{ t("hourly-chart.hourly_display") }}</small>
      @if (timeZone) {
        <small class="text-sm">{{ t("hourly-chart.hours_displayed", [getTimezone(), getTimezonUtcOffset()]) }} </small>
      }
    </div>
  </div>
</div>
