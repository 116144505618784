<ng-container *transloco="let t">
  <div class="modal-header bg-main-500">
    <h4 class="modal-title pull-left">
      {{ t("manage-authorized-users-modal.authorized_users", { name: accountName, marketplace: marketplace }) }}
    </h4>
  </div>
  <div class="modal-body m-4">
    <div class="flex flex-row justify-between">
      <div class="flex flex-1 flex-col">
        <div class="flex flex-row">
          <label class="col-form-label">{{ t("common.email") }}</label>
          <input
            type="text"
            class="border-shadow form-control mx-2"
            placeholder="{{ t('manage-authorized-users-modal.please_enter_the_email_of_the_user') }}"
            [value]="email ?? ''"
            (input)="setEmail(newEmail.value)"
            #newEmail
          />
        </div>
        <div class="flex w-2/3 flex-row items-center justify-between">
          <mat-radio-button
            class="radio-button-center my-2"
            color="primary"
            [checked]="accessLevel === AccessLevel.STATS_ONLY"
            (click)="setAccessLevelNewUser(AccessLevel.STATS_ONLY)"
          >
            <div class="-mb-2">{{ t("manage-authorized-users-modal.360_dashboards_access") }}</div>
          </mat-radio-button>
          <mat-radio-button
            class="radio-button-center my-2"
            color="primary"
            [checked]="accessLevel === AccessLevel.READ"
            (click)="setAccessLevelNewUser(AccessLevel.READ)"
          >
            <div class="-mb-2">{{ t("common.read") }}</div>
          </mat-radio-button>
          <mat-radio-button
            class="radio-button-center my-2"
            color="primary"
            [checked]="accessLevel === AccessLevel.WRITE"
            (click)="setAccessLevelNewUser(AccessLevel.WRITE)"
          >
            <div class="-mb-2">{{ t("common.read_write") }}</div>
          </mat-radio-button>
        </div>
      </div>
      <div class="flex flex-col justify-end">
        <button class="btn btn-primary" (click)="addAuthorizedUser()" [disabled]="!isValidEmail()">
          {{ t("manage-authorized-users-modal.authorize_user") }}
        </button>
      </div>
    </div>

    <hr class="my-4" />

    <div class="flex justify-between">
      <span class="search bar pl-0" style="width: 65%; display: inline-block; max-width: 36rem">
        <span class="search-bar input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <span [class]="ICON_SEARCH"></span>
            </span>
          </div>
          <input
            type="text"
            class="search-bar simple-input-prepend form-control"
            placeholder="{{ t('manage-authorized-users-modal.search_by_name_or_email') }}"
            (input)="changeAuthorizedUserFilter(getValueFromInputEvent($event))"
          />
        </span>
      </span>
    </div>
    <div class="table-container">
      <mat-table
        matSort
        [dataSource]="dataSource"
        class="table-sm table-striped-two table-hover border-shadow mt-4 table"
        #sort="matSort"
      >
        <ng-container matColumnDef="userName">
          <mat-header-cell *matHeaderCellDef class="table-header text-left" mat-sort-header
            >{{ t("common.name") }}
          </mat-header-cell>
          <mat-cell *matCellDef="let user" class="text-left">
            <span class="truncate-text">{{ user.userName }}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef class="table-header text-left" mat-sort-header
            >{{ t("common.email") }}
          </mat-header-cell>
          <mat-cell *matCellDef="let user" class="text-left">
            <span class="truncate-text">{{ user.email }}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="statsOnlyAccess">
          <mat-header-cell *matHeaderCellDef class="table-header">
            {{ t("manage-authorized-users-modal.360_dashboards_access") }}
          </mat-header-cell>
          <mat-cell *matCellDef="let user" class="justify-center">
            <mat-radio-button
              class="radio-button-center my-2"
              color="primary"
              [name]="user.email"
              [checked]="user.accessLevel === AccessLevel.STATS_ONLY"
              (click)="setAccessLevel(user.email, AccessLevel.STATS_ONLY)"
            ></mat-radio-button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="readonlyMode">
          <mat-header-cell *matHeaderCellDef class="table-header"
            >{{ t("manage-authorized-users-modal.read_only") }}
          </mat-header-cell>
          <mat-cell *matCellDef="let user" class="justify-center">
            <mat-radio-button
              class="radio-button-center my-2"
              color="primary"
              [name]="user.email"
              [checked]="user.accessLevel === AccessLevel.READ"
              (click)="setAccessLevel(user.email, AccessLevel.READ)"
            ></mat-radio-button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="readWriteMode">
          <mat-header-cell *matHeaderCellDef class="table-header">{{ t("common.read_write") }}</mat-header-cell>
          <mat-cell *matCellDef="let user" class="justify-center">
            <mat-radio-button
              class="radio-button-center my-2"
              color="primary"
              [name]="user.email"
              [checked]="user.accessLevel === AccessLevel.WRITE"
              (click)="setAccessLevel(user.email, AccessLevel.WRITE)"
            ></mat-radio-button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="revokeAccess">
          <mat-header-cell *matHeaderCellDef class="table-header justify-center"></mat-header-cell>
          <mat-cell *matCellDef="let user" class="justify-center">
            <IButton
              variant="ghost"
              icon="icon-[lucide--trash]"
              size="lg"
              [tooltipValue]="t('manage-authorized-users-modal.revoke_access')"
              (onClick)="revokeAccess(user.email)"
            />
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator [pageSize]="5" showFirstLastButtons #paginator></mat-paginator>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">
      {{ t("common.close") }}
    </button>
  </div>
</ng-container>
