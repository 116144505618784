<div [class]="alertClasses()" [@bounce] [@.disabled]="!animated()">
  <div class="flex items-center gap-3">
    <span [class]="icon()" class="h-5 w-5 flex-shrink-0"></span>

    <div class="flex-1" data-testid="alert">
      <span class="text-sm font-medium">{{ title() }}</span>
      <ng-content />
    </div>
  </div>
</div>
