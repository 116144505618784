<app-stats-overlay>
  <div class="mb-2 flex flex-wrap justify-between gap-2" *transloco="let t">
    <div class="flex items-center gap-x-3">
      @if (selectedManagedProducts.length > 0) {
        <IButton
          [disabled]="isReadOnly"
          tooltipValue="{{ t('catalog-page.stop_managing_product', { count: selectedManagedProducts.length }) }}"
          (onClick)="stopManagingProducts()"
          label="{{ t('catalog-page.stop_managing_button') }}"
        />

        <IButton
          [disabled]="isReadOnly"
          tooltipValue="{{ t('catalog-page.edit_custom_field_tooltip') }}"
          label="{{
            t('catalog-page.edit_custom_field_button', { name: inventoryConfig?.customField1Name ?? '1', number: 1 })
          }}"
          icon="icon-[mdi--playlist-edit]"
          color="white"
          (onClick)="groupEditCustomField('field1')"
        />
        <IButton
          [disabled]="isReadOnly"
          tooltipValue="{{ t('catalog-page.edit_custom_field_tooltip') }}"
          label="{{
            t('catalog-page.edit_custom_field_button', { name: inventoryConfig?.customField2Name ?? '2', number: 2 })
          }}"
          icon="icon-[mdi--playlist-edit]"
          color="white"
          (onClick)="groupEditCustomField('field2')"
        />
      }
    </div>
    <div class="flex items-center justify-end gap-1">
      <div class="mr-2 text-sm">
        {{ t("catalog-page.catalog_length", { count: managedCatalogGridData.length }) }}
      </div>
      @if (accountMarketplace && accountMarketplace.accountType !== "SELLER") {
        <IButton
          [icon]="ICON_ADD"
          label="{{ t('common.add_asins') }}"
          to="/product-center/catalog/upload"
          queryParamsHandling="merge"
        />
      }
      <IButton
        [disabled]="isReadOnly"
        tooltipValue="{{ t('catalog-page.update_custom_field_names') }}"
        [icon]="ICON_GEAR"
        (onClick)="openCustomFieldsSettings()"
        color="white"
        label="{{ t('catalog-page.custom_field_settings') }}"
      ></IButton>
      <IButton
        [disabled]="isReadOnly"
        tooltipValue="{{ t('catalog-page.upload_catalog_data') }}"
        [icon]="ICON_IMPORT"
        (onClick)="uploadCatalogData()"
        color="gray"
        variant="ghost"
      />
      <IButton
        [disabled]="isReadOnly"
        tooltipValue="{{ t('common.export_as_csv') }}"
        [icon]="ICON_EXPORT"
        [matMenuTriggerFor]="menu"
        color="gray"
        variant="ghost"
      />
    </div>
    <mat-menu #menu="matMenu" yPosition="above">
      <button mat-menu-item (click)="exportCatalogAsCsv()">{{ t("catalog-page.export_catalog_as_csv") }}</button>
      <button mat-menu-item (click)="exportSeedsAsCsv()">{{ t("catalog-page.export_seed_blacklist_as_csv") }}</button>
      <button mat-menu-item (click)="exportCogs()">{{ t("catalog-page.export_cost_of_goods_as_csv") }}</button>
      <button mat-menu-item (click)="exportCustomFields()">{{ t("catalog-page.export_custom_fields_as_csv") }}</button>
    </mat-menu>
  </div>
  <div class="ag-theme-quartz">
    <ag-grid-angular
      class="h-[40rem] w-full"
      #grid
      [rowData]="managedCatalogGridData"
      [gridOptions]="gridOptions"
      [animateRows]="true"
      (gridReady)="onGridReady($event)"
    />
  </div>
  <div class="mb-2 mt-4 flex items-center justify-between" *transloco="let t">
    <div class="flex cursor-pointer items-center text-xl" (click)="toggleUnmanagedCatalogSection()">
      <span class="font-medium">{{ t("catalog-page.unmanaged_products") }}</span>
      @if (unmanagedCatalogGridData && unmanagedCatalogGridData.length > 0) {
        <span class="ml-2 text-gray-500"> ({{ unmanagedCatalogGridData.length }})</span>
      }
      <span
        class="icon-[material-symbols--chevron-right-rounded] ml-3 text-3xl text-gray-600 duration-75"
        [class.rotate-90]="unmanagedCatalogSection"
      ></span>
    </div>
    <div class="flex items-center justify-end">
      <IButton
        [disabled]="isReadOnly"
        tooltipValue="{{ t('common.import') }}"
        [icon]="ICON_IMPORT"
        color="white"
        label="{{ t('common.import') }}"
        [matMenuTriggerFor]="bulkManageCatalogMode"
      />
      <mat-menu #bulkManageCatalogMode="matMenu" yPosition="above">
        <button
          mat-menu-item
          (click)="bulkImportUnmanagedProducts('managed')"
          [disabled]="this.managedOverLimit()"
          [matTooltip]="this.getTooltipForImportButton()"
        >
          {{ t("catalog-page.import_managed_products") }}
        </button>
        <button mat-menu-item (click)="bulkImportUnmanagedProducts('unmanaged')">
          {{ t("catalog-page.import_unmanaged_product") }}
        </button>
      </mat-menu>
      @if (selectedUnmanagedProducts.length > 0) {
        <IButton
          class="ml-2"
          [disabled]="disableRestoreButton()"
          [matTooltip]="getTooltipForRestoreButton()"
          (onClick)="restoreToManagedProducts()"
          label="{{ t('catalog-page.restore_managed_products') }}"
        />
      }
      <div
        [matMenuTriggerFor]="catalogModeMenu"
        class="pill ml-2"
        [ngClass]="{
          'pill-primary': catalogManagementMode === 'auto',
          'pill-info': catalogManagementMode !== 'auto',
        }"
      >
        <span
          class="bg- mr-2 text-gray-500"
          [ngClass]="
            catalogManagementMode === 'auto'
              ? 'icon-[material-symbols--play-circle-rounded]'
              : 'icon-[material-symbols--pause-circle-rounded]'
          "
        ></span>

        {{ catalogManagementMode === "auto" ? t("common.auto") : t("common.custom") }}
        <span class="icon-[mdi--chevron-down] ml-2 text-gray-500">{{ t("catalog-page.catalog_management") }}</span>
      </div>
      <mat-menu #catalogModeMenu="matMenu">
        <div class="dd-input-container flex items-center" [matTooltip]="this.getTooltipForSwitchManagementMode()">
          <ul class="flex flex-col">
            <li
              class="dd-item"
              (click)="toggleCatalogManagementMode()"
              [class.disabled]="this.isReadOnly || this.managedOverLimit()"
            >
              {{
                catalogManagementMode === "auto"
                  ? t("catalog-page.custom_catalog_management")
                  : t("catalog-page.auto_catalog_management")
              }}
            </li>
          </ul>
        </div>
      </mat-menu>
    </div>
  </div>
  <div class="ag-theme-quartz" [ngClass]="unmanagedCatalogSection ? 'block' : 'hidden'">
    <ag-grid-angular
      class="h-[40rem] w-full"
      #unmanagedGrid
      [rowData]="unmanagedCatalogGridData"
      [gridOptions]="unmanagedGridOptions"
      [animateRows]="true"
    />
  </div>
</app-stats-overlay>
