import { Component, Input, OnInit } from "@angular/core";
import { OrderStats } from "@front/m19-models";
import { Metric, MetricFormatPipe } from "@front/m19-metrics";
import { FBA_STORAGE_FEE, getOrderMetricDetailsWithQuantities, GROSS_SALES } from "@m19-board/models/MetricsDef";
import { ProfitDetail } from "@m19-board/product360/product360.service";
import {
  PROFIT_DETAIL_AMOUNT,
  PROFIT_DETAIL_SHARE,
} from "@m19-board/widget/profit-detail-widget/profit-details-widget.component";
import { Marketplace } from "@front/m19-api-client";
import { TranslocoDirective } from "@jsverse/transloco";
import { ProductViewComponent } from "@m19-board/product-view/product-view.component";
import { MatTooltip } from "@angular/material/tooltip";
import { IBadgeComponent } from "@front/m19-ui";

export type ProfitDetailsParams = {
  metrics: Metric<OrderStats>[];
  data: OrderStats;
  totalData: OrderStats;
  locale: string;
  currency: string;
  fullDetail: boolean;
  asin: string;
  marketplace: Marketplace;
};

@Component({
  selector: "app-profit-details",
  templateUrl: "./profit-details.component.html",
  standalone: true,
  imports: [TranslocoDirective, ProductViewComponent, MatTooltip, MetricFormatPipe, IBadgeComponent],
})
export class ProfitDetailsComponent implements OnInit {
  @Input() asin!: string;
  @Input() marketplace!: Marketplace;
  @Input() totalData!: OrderStats;

  @Input() metrics: Metric<OrderStats>[] = [];

  @Input() data!: OrderStats;

  @Input() locale!: string;

  @Input() currency!: string;

  tooltipsByMetricId: Map<string, string> = new Map();

  profitDetails!: Map<string, ProfitDetail>;
  globalMetrics: Metric<OrderStats>[] = [];
  subMetrics: Map<Metric<OrderStats>, Metric<OrderStats>[]> = new Map();

  constructor() {
    this.tooltipsByMetricId.set(FBA_STORAGE_FEE.id, "Doesn't include long term storage");
    // This events get called by all clicks on the page
  }

  ngOnInit(): void {
    this.globalMetrics = [];
    this.subMetrics.clear();

    this.profitDetails = this.dataToProfitDetails(this.data);
  }

  getShareValue(share: ProfitDetail): number {
    return PROFIT_DETAIL_SHARE.value(share)!;
  }

  formatShare(share: ProfitDetail) {
    let val = this.getShareValue(share);
    val = isNaN(val) ? 0 : val < 0 ? -val : val;
    return PROFIT_DETAIL_SHARE.format(val, this.locale, this.currency);
  }

  private dataToProfitDetails(data: OrderStats): Map<string, ProfitDetail> {
    const res = new Map();
    const totalRevenue = GROSS_SALES.value(this.data);

    for (const m of this.metrics) {
      const detailedMetrics: Metric<OrderStats>[] = [];
      const aggValue = m.value(data);
      for (const m2 of getOrderMetricDetailsWithQuantities(m)) {
        // only keep currency metric
        // only keep non null values
        const val = m2.value(this.data);

        if (val != 0) {
          res.set(m2.id, {
            field: m2.title,
            category: m.title,
            value: m2.value(data),
            currency: data.currency,
            aggValue,
            totalRevenue,
          });
          detailedMetrics.push(m2);
        }
      }
      if (detailedMetrics.length > 0) {
        this.globalMetrics.push(m);
        this.subMetrics.set(m, detailedMetrics);
      }
    }
    return res;
  }

  readonly PROFIT_DETAIL_SHARE = PROFIT_DETAIL_SHARE;
  readonly PROFIT_DETAIL_AMOUNT = PROFIT_DETAIL_AMOUNT;
}
