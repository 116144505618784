import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faPauseCircle, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faExclamationCircle,
  faImage,
  faPause,
  faPencilAlt,
  faPlay,
  faPlayCircle,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import {
  BrandAsset,
  M19Status,
  MediaType,
  NotificationType,
  PreModeration,
  PreModerationStatus,
  SbCreative,
  SbCreativeType,
  State,
  TacosStrategyGroup,
} from "@front/m19-api-client";
import {
  NotificationBidderIssueEx,
  NotificationEx,
  ProductEx,
  SbCreativeBrandAssets,
  StrategyEx,
} from "@front/m19-models";
import { AsinService, InventoryService, NotificationService, SbStrategiesService } from "@front/m19-services";
import { ConfirmPopupComponent, IBadgeComponent, ModalService, SpinnerComponent } from "@front/m19-ui";
import { Utils } from "@front/m19-utils";
import { TranslocoModule, TranslocoService } from "@jsverse/transloco";
import { CarouselComponent } from "@m19-board/shared/carousel/carousel.component";
import { MediaInputComponent } from "@m19-board/shared/media-input/media-input.component";
import { FormMode, SbStrategyFormComponent } from "@m19-board/strategies/sb-strategy-form/sb-strategy-form.component";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { combineLatest, tap } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";

@Component({
  selector: "app-creative-display",
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    IBadgeComponent,
    FontAwesomeModule,
    CarouselComponent,
    MediaInputComponent,
    SpinnerComponent,
    TranslocoModule,
  ],
  templateUrl: "./creative-display.component.html",
  styleUrls: ["./creative-display.component.scss"],
})
export class CreativeDisplayComponent implements OnInit {
  @Input({ required: true }) strategy!: StrategyEx;
  @Input({ required: true }) creative!: SbCreative;
  @Input() tacosStrategy: TacosStrategyGroup | undefined;
  @Input() disabled = false;
  @Input() disableDeletion = false;
  @Input() brandAssets: SbCreativeBrandAssets | undefined;

  @Input() customImages: BrandAsset[] = [];
  toUpload?: BrandAsset;

  faultyCreativeReason: string | undefined;
  faultyReason: string | undefined;
  status: M19Status | undefined;
  SbCreativeType = SbCreativeType;
  hasNoAsins = false;
  customImageLoading = false;
  uniqueActiveCreative = false;
  readonly faPause = faPause;
  readonly faPlay = faPlay;
  readonly faPauseCircle = faPauseCircle;
  readonly faPlayCircle = faPlayCircle;
  readonly faExclamationCircle = faExclamationCircle;
  readonly faImage = faImage;
  readonly faVideo = faVideo;
  readonly faTrash = faTrashAlt;
  readonly faPencil = faPencilAlt;
  readonly M19Status = M19Status;

  constructor(
    private asinService: AsinService,
    private modalService: BsModalService,
    private modalServiceV2: ModalService,
    private sbStrategiesService: SbStrategiesService,
    private toastrService: ToastrService,
    private notificationService: NotificationService,
    private inventoryService: InventoryService,
    private toasterService: ToastrService,
    private translocoService: TranslocoService,
  ) {}

  public getCreativeTypeStr(creativeType: SbCreativeType): string {
    switch (creativeType) {
      case SbCreativeType.productCollection:
        return "creative-display.product_collection";
      case SbCreativeType.video:
        return "sb-form-creative.video";
      case SbCreativeType.brandVideo:
        return "sb-form-ad-format.brand_video";
      case SbCreativeType.storeSpotlight:
        return "sb-form-ad-format.store_spotlight";
    }
  }

  ngOnInit() {
    combineLatest([
      this.asinService.getInventoryRules(this.strategy.accountId, this.strategy.marketplace),
      this.inventoryService.getSellerInventoryStats(this.strategy.accountId, this.strategy.marketplace),
      this.asinService.getCatalog(this.strategy.accountId, this.strategy.marketplace),
      this.notificationService.getNotifications$.pipe(
        map((x: NotificationEx[]) =>
          x
            .filter((n) => n.type === NotificationType.BIDDER_ISSUE)
            .find((n) => n.subStrategyId === this.creative.creativeId),
        ),
      ),
      this.sbStrategiesService
        .getSbCreativeBrandAssets(this.strategy.accountId, this.strategy.marketplace)
        .pipe(map((x) => x.get(this.creative.creativeId) ?? ({ creativeTitle: "" } as SbCreativeBrandAssets))),
    ]).subscribe(([rules, stats, catalog, notif, brandAssets]) => {
      const sbNotif = notif as NotificationBidderIssueEx;
      const { status, faultyCreativeReason } = Utils.getSBFaultyReasons(
        this.creative,
        brandAssets,
        catalog,
        rules!,
        stats,
        this.translocoService,
        sbNotif,
      );
      this.status = status;
      this.faultyCreativeReason = faultyCreativeReason;
      this.faultyReason = undefined;
      this.hasNoAsins = false;
      if (sbNotif) {
        if (sbNotif?.moderationDetails && sbNotif?.moderationDetails.length > 0) {
          const unique = [...new Set(sbNotif.moderationDetails.map((x) => x.segment))];
          const nb =
            `${sbNotif.moderationDetails.length} Ad${Utils.pluriel(sbNotif.moderationDetails)} ` +
            `(campaign${Utils.pluriel(unique)}: ${unique.join(", ")}) `;
          this.faultyReason = nb + Utils.m19StatusToReadableStr(this.status) + ": " + this.faultyCreativeReason;
        } else {
          this.faultyReason = `Some Ads ${Utils.m19StatusToReadableStr(
            this.status,
          )} (More details should be available in the following days)`;
        }
      }
      if (this.creative?.creativeAsins && this.creative?.creativeAsins.length === 0) {
        this.faultyReason = "This Ad Line is missing ASINs, please edit your Ad Line to add ASINs";
        this.hasNoAsins = true;
      }
    });
    this.sbStrategiesService
      .getSbCreativesPerStrategy(this.strategy.accountId, this.strategy.marketplace)
      .subscribe((creatives) => {
        this.uniqueActiveCreative =
          (creatives.get(this.strategy.strategyId) ?? []).filter((c) => c.state == State.ON).length === 1;
      });
  }

  editCreative() {
    const modaloptions: ModalOptions = {
      initialState: {
        strategy: this.strategy,
        creative: this.creative,
        formMode: FormMode.EDIT_CREATIVE,
        tacosStrategy: this.tacosStrategy,
      },
      class: "big-modal modal-dialog-centered modal-limit",
      keyboard: false,
      backdrop: "static",
    };
    this.modalService.show(SbStrategyFormComponent, modaloptions);
  }

  uploadCreativeCustomImage(customImage: File) {
    this.customImageLoading = true;
    return this.sbStrategiesService
      .uploadBrandAsset({
        accountId: this.creative.accountId,
        marketplace: this.creative.marketplace,
        brandEntityId: this.creative.brandEntityId!,
        mediaType: MediaType.customImage,
        file: customImage,
      })
      .pipe(
        filter((asset: BrandAsset | undefined): asset is BrandAsset => !!asset),
        tap((asset: BrandAsset) => {
          this.toUpload = asset;
          this.customImageLoading = true;
        }),
        switchMap((asset: BrandAsset) =>
          this.sbStrategiesService.sbPreModeration(
            this.creative.accountId,
            this.creative.marketplace,
            undefined,
            undefined,
            [asset.url!],
          ),
        ),
      )
      .subscribe({
        next: (premod: PreModeration) => {
          const res = premod.textComponents?.find((component) => component.id == "customImage");
          if (res && res.preModerationStatus === PreModerationStatus.REJECTED) {
            this.toastrService.error(
              res.policyViolations ? res.policyViolations[0]?.policyDescription : undefined,
              this.translocoService.translate("creative-display.asset_pre_moderation"),
            );
            this.customImageLoading = false;
          } else this.updateCreativeCustomImage(this.toUpload);
        },
        error: (err) => {
          this.toasterService.error(err, this.translocoService.translate("creative-display.sb_creative_update_error"));
          this.customImageLoading = false;
        },
      });
  }

  copyCreativeId() {
    navigator.clipboard.writeText(this.creative.creativeId.toString());
    this.toastrService.success(this.translocoService.translate("creative-display.creative_id_copied_to_clipboard"));
  }

  updateCreativeCustomImage(image: BrandAsset | undefined) {
    const newCreative: SbCreative = { ...this.creative, customImageAssetId: image?.assetId };
    this.sbStrategiesService.updateSbCreative(this.creative, newCreative).subscribe({
      next: () => {
        this.toasterService.success(this.translocoService.translate("creative-display.sb_creative_updated"));
        this.customImageLoading = false;
      },
      error: (err) => {
        this.toasterService.error(err, this.translocoService.translate("creative-display.sb_creative_update_error"));
        this.customImageLoading = false;
      },
    });
  }

  toggleStatus() {
    if (this.creative.state == State.ON) {
      if (this.uniqueActiveCreative) {
        this.toastrService.error(this.translocoService.translate("creative-display.cannot_pause_all"));
        return;
      }
      this.creative.state = State.OFF;
    } else this.creative.state = State.ON;
    this.sbStrategiesService.updateSbCreativeStatus(this.creative).subscribe({
      next: () => {
        this.toastrService.success(
          this.translocoService.translate("creative-display.status_updated_to_this_creative_state", [
            this.creative.state,
          ]),
        );
      },
      error: (err) => {
        this.toastrService.error(
          err,
          this.translocoService.translate("creative-display.sb_creative_status_update_error"),
        );
      },
    });
  }

  deleteCreative() {
    const modalRef = this.modalServiceV2.openModal(ConfirmPopupComponent, {
      modalTitle: this.translocoService.translate("activity-service.creative_deletion"),
      data: {
        message: this.translocoService.translate("creative-display.delete_creative_confirm"),
        confirmLabel: "Delete",
      },
    });
    modalRef
      .afterClosed()
      .pipe(switchMap(() => this.sbStrategiesService.deleteSbCreative(this.creative)))
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("creative-display.sb_creative_successfully_deleted"),
          );
        },
        error: (err) => {
          this.toastrService.error(err, this.translocoService.translate("creative-display.sb_creative_deletion_error"));
        },
      });
  }

  getProductImage(asin: string) {
    return this.asinService
      .getProductWithMarketplace(asin, this.strategy.marketplace)
      .pipe(map((p: ProductEx) => p.imageUrl));
  }

  protected readonly MediaType = MediaType;
}
