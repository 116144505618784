import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { faExclamationTriangle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { AdStatsEx, ProductGroupEx, StrategyEx } from "@front/m19-models";
import { UntilDestroy } from "@ngneat/until-destroy";
import moment, { MomentZone } from "moment-timezone";
import { StrategyStats } from "../../../models/Metric";
import { MetricsSelectorLocalStorageKey, Utils } from "@front/m19-utils";
import { DataSet, UserSelectionService } from "@front/m19-services";
import { Metric } from "@front/m19-metrics";

@UntilDestroy()
@Component({
  selector: "app-hourly-chart",
  templateUrl: "./hourly-chart.component.html",
  styleUrls: ["./hourly-chart.component.scss"],
})
export class HourlyChartComponent {
  @ViewChild("canvas") canvas: HTMLCanvasElement;

  @Input() hourlyDataSupported: boolean;

  @Input() globalData: AdStatsEx = {};
  @Input() dataSet: DataSet<AdStatsEx>;

  @Input() timeZone: MomentZone;
  @Input() strategies: StrategyEx[];
  @Input() pendingRequests: boolean;

  @Output() onSelectedMetric = new EventEmitter<Metric<StrategyStats>[]>();
  @Output() onCustomMetric = new EventEmitter<Metric<AdStatsEx>[]>();
  @Output() onSelectedStrategyOrProductGroup = new EventEmitter<StrategyEx | ProductGroupEx>();

  @Input() chartDisplayed: boolean;
  @Output() onChartDisplay = new EventEmitter<boolean>();
  @Output() onNonM19CampaignChange = new EventEmitter<boolean>();
  @Input() displayWarning = false;
  @Input() nbDaysConsidered = 0;

  @Input() localStorageKey: MetricsSelectorLocalStorageKey;

  @Input() CHART_METRICS: Metric<StrategyStats>[];

  @Input() includeNonM19Campaign: boolean;

  readonly faMinimize = faTimes;
  readonly faWarning = faExclamationTriangle;

  constructor(private userSelectionService: UserSelectionService) {}

  selectMetrics(metrics: Metric<StrategyStats>[]) {
    this.onSelectedMetric.emit(metrics);
  }

  setStrategyOrProductGroup(strategyOrProductGroup: StrategyEx | ProductGroupEx): void {
    this.onSelectedStrategyOrProductGroup.emit(strategyOrProductGroup);
  }

  customSelectedMetrics(metrics: Metric<StrategyStats>[]) {
    this.onCustomMetric.emit(metrics);
  }

  toggleChartDisplay(displayed: boolean): void {
    this.chartDisplayed = displayed;
    this.userSelectionService.setUserChartDisplayedPreference(this.localStorageKey, displayed);
    this.onChartDisplay.emit(this.chartDisplayed);
  }

  toggleIncludeNonM19Campaign(bool: boolean): void {
    // Reset strategy or product group to all products
    this.includeNonM19Campaign = bool;
    this.onSelectedStrategyOrProductGroup.emit(undefined);
    this.onNonM19CampaignChange.emit(bool);
  }

  getTimezone(): string {
    return this.timeZone?.abbr(moment.now());
  }

  getTimezonUtcOffset(): string {
    return Utils.getTimezoneOffset(this.timeZone?.name);
  }
}
