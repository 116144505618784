import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SimpleDataset } from "@m19-board/models/SimpleDataset";
import { ChartTypeRegistry, Point } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { KeywordTrackerService } from "../../services/keyword-tracker.service";
import {
  AccountSelectionService,
  getRankFromOption,
  RANK_OPTIONS_ARRAY,
  RANK_OPTIONS_DESC,
  RankOption,
} from "@front/m19-services";
import { AccountMarketplace, AsinRanks, Marketplace } from "@front/m19-api-client";
import { Marketplaces } from "@front/m19-models";
import { DateAggregation, Utils } from "@front/m19-utils";

export type AsinRanksEx = AsinRanks & {
  hasParent: boolean;
  inCatalog: boolean;
  isTop10: boolean;
  childAsins: string[];
};

@UntilDestroy()
@Component({
  selector: "app-keyword-tracking-timeline",
  templateUrl: "./keyword-tracking-timeline.component.html",
  styleUrls: ["./keyword-tracking-timeline.component.scss"],
})
export class KeywordTrackingTimelineComponent implements OnInit, AfterViewInit {
  readonly faTimes = faTimes;
  readonly dateAggregations = [DateAggregation.hourly, DateAggregation.daily, DateAggregation.weekly];

  // input observables
  selectedAccountMarketplace$: Observable<AccountMarketplace> =
    this.accSelectionService.singleAccountMarketplaceSelection$;
  asinRanks$ = this.keywordTrackerService.asinRanksFiltered$;
  _asinRanks: AsinRanksEx[] = [];
  tableView$ = this.keywordTrackerService.tableView$;
  rankOption$ = this.keywordTrackerService.rankOption$;
  _hiddenAsins?: Set<string>;
  _graphAsin?: string;

  viewModeDefault = true;

  // component state
  marketplace?: Marketplace;
  dateAggregation$ = new BehaviorSubject<DateAggregation>(DateAggregation.daily);
  private _dateAggregation?: DateAggregation;
  private _rankOption?: RankOption;
  dateAggregationAbbr?: string;
  timezone?: string;
  timezoneOffset?: string;
  productColor: Map<string, string> = new Map();
  groupByParent?: boolean;

  // graph data
  dataset = new SimpleDataset(false, 2.5);
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  @ViewChild("datavizScroll") datavizScroll!: ElementRef;
  updateScroll: Subject<Event> = new Subject<Event>();

  constructor(
    private keywordTrackerService: KeywordTrackerService,
    private accSelectionService: AccountSelectionService,
  ) {}

  ngAfterViewInit(): void {
    this.asinRanks$.pipe(untilDestroyed(this)).subscribe((_) => {
      scrollToRight(this.datavizScroll);
    });
  }

  ngOnInit(): void {
    this.dataset.lineChartOptions.responsive = false;
    this.keywordTrackerService.tableView$.pipe(untilDestroyed(this)).subscribe((v) => {
      if (!v) this.viewModeDefault = true; // default table view
    });

    this.dateAggregation$.pipe(untilDestroyed(this)).subscribe((dateAggregation) => {
      this.dateAggregationAbbr = this.dateAggregationToAbbrv(dateAggregation);
      this._dateAggregation = dateAggregation;
    });
    this.selectedAccountMarketplace$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.marketplace = am.marketplace;
      this.timezone = Marketplaces[am.marketplace].timeZone;
      this.timezoneOffset = Utils.getTimezoneOffset(this.timezone);
      this.dataset.marketplace = am.marketplace;
    });

    this.keywordTrackerService.groupByParent$.pipe(untilDestroyed(this)).subscribe((g) => {
      this.groupByParent = g;
      if (this._rankOption !== RankOption.BOTH) this.hideDatasets();
    });

    combineLatest([this.keywordTrackerService.graphAsin$, this.keywordTrackerService.hiddenAsins$])
      .pipe(untilDestroyed(this))
      .subscribe(([graphAsin, hiddenAsins]: [string | undefined, Set<string>]) => {
        this._graphAsin = graphAsin;
        this._hiddenAsins = hiddenAsins;
        this.hideDatasets();

        if (this._rankOption == RankOption.BOTH) {
          this.buildBothRankOptionGraph(this._asinRanks, graphAsin);
        }
      });

    this.keywordTrackerService.graphAsin$.pipe(untilDestroyed(this)).subscribe((graphAsin: string | undefined) => {
      this._graphAsin = graphAsin;
      this.hideDatasets();

      if (this._rankOption == RankOption.BOTH) {
        this.buildBothRankOptionGraph(this._asinRanks, graphAsin);
      }
    });

    combineLatest<[AsinRanksEx[], RankOption, DateAggregation, Map<string, string>]>([
      this.asinRanks$,
      this.rankOption$,
      this.dateAggregation$,
      this.keywordTrackerService.asinColors$,
    ]).subscribe(([asinRanks, rankOption, dateAggregation, colors]) => {
      this._rankOption = rankOption;
      this._asinRanks = asinRanks;
      this.productColor = colors;

      if (rankOption == RankOption.BOTH) {
        const selectedAsin = this.selectCorrectAsin(asinRanks);
        this.keywordTrackerService.toggleGraphAsin(selectedAsin, true);
        this.buildBothRankOptionGraph(asinRanks, selectedAsin);
      } else {
        let ranks: Point[][] = [];
        for (const asinRank of asinRanks) {
          if (asinRank.ranks) {
            ranks.push(
              asinRank.ranks.map((r) => ({
                x: r.timestamp! * 1000,
                y: getRankFromOption(r, rankOption) ?? 0,
              })),
            );
          }
        }
        // remove undefined values (where product it above 3 first pages)
        ranks = ranks.map((r) => r.filter((point) => point.y !== undefined));
        const legend = asinRanks.map((r) => r.asin!);
        this.dataset.buildSearchTermRankingDataSet("Search terms rank", ranks, legend, rankOption, dateAggregation);
        this.hideDatasets();

        this.onAsinSelectorLeave();
      }
    });
  }

  selectRankOption(option: RankOption | null) {
    if (option) this.keywordTrackerService.setRankOption(option);
  }

  // when choosing 'BOTH' option, we want to :
  // - keep the ASIN selected if there is already one
  // - selected the first ASIN otherwise taking grouping into account
  selectCorrectAsin(ranks: AsinRanksEx[]): string {
    if (!this._graphAsin) {
      if (this.groupByParent) return ranks[0]?.asin ?? "";
      else
        for (const r of ranks) {
          if (r.hasParent) return r.asin ?? "";
        }
    }
    return this._graphAsin ?? "";
  }

  setTableView(set: boolean) {
    this.keywordTrackerService.setTableView(set);
    if (set) {
      scrollToRight(this.datavizScroll);
    }
  }

  // hide dataset relating to selected ASIN
  private hideDatasets() {
    for (const d of this.dataset.chartDataSet) {
      const rank = this._asinRanks?.find((a) => a.asin === d.label);
      const isParent = !rank?.hasParent && rank?.childAsins.length;
      const hideParent = isParent && !this.groupByParent;

      d.hidden = !(!this._graphAsin || d.label === this._graphAsin) || hideParent || this._hiddenAsins?.has(d.label!);

      // current graph asin is now hidden : reset the graph asin
      if (d.hidden && this._graphAsin === d.label) {
        this.keywordTrackerService.toggleGraphAsin(this._graphAsin ?? "");
      }
    }

    this.chart?.update();
  }

  private buildBothRankOptionGraph(asinRanks: AsinRanksEx[], graphAsin: string | undefined) {
    const ranks: Point[][] = [];
    const asinRank: AsinRanksEx = asinRanks.filter((r) => r.asin === graphAsin)[0];
    if (!asinRank || !asinRank.ranks) return;

    ranks.push(
      asinRank.ranks
        .map((r) => ({
          x: r.timestamp! * 1000,
          y: r.organic!,
        }))
        .filter((point) => point.y !== undefined),
    );
    ranks.push(
      asinRank.ranks
        .map((r) => ({
          x: r.timestamp! * 1000,
          y: r.sp!,
        }))
        .filter((point) => point.y !== undefined),
    );

    this.dataset.buildSearchTermRankingDataSet(
      "Search terms rank",
      ranks,
      ["Organic", "Sponsored"],
      RankOption.BOTH,
      this._dateAggregation,
    );
  }

  selectDateAggregation(selection: DateAggregation) {
    this.dateAggregation$.next(selection);
  }

  private dateAggregationToAbbrv(dateAggregation: DateAggregation) {
    switch (dateAggregation) {
      case DateAggregation.hourly:
        return "H";

      case DateAggregation.daily:
        return "D";

      case DateAggregation.weekly:
        return "W";
    }
    return "";
  }

  onAsinSelectorHover(asin: string) {
    if (this._graphAsin) return;
    if (this._rankOption == RankOption.BOTH) {
      return;
    }
    for (const dataset of this.dataset.chartDataSet) {
      const d = dataset as ChartTypeRegistry["line"]["datasetOptions"];

      if (d.label === asin) {
        d.borderWidth = 3;
        d.pointRadius = 2.5;
      } else {
        d.borderWidth = 0.5;
        d.pointRadius = 1.5;
        d.borderColor += "80";
        d.backgroundColor += "80";
        d.pointBackgroundColor += "80";
      }
    }
    this.chart?.update();
  }

  onAsinSelectorLeave() {
    if (this._rankOption == RankOption.BOTH) {
      return;
    }
    for (let i = 0; i < this.dataset.chartDataSet.length; i++) {
      const dataset = this.dataset.chartDataSet[i] as ChartTypeRegistry["line"]["datasetOptions"];

      dataset.borderWidth = 1.5;
      dataset.pointRadius = 2;
      // remove alpha channel from colors
      dataset.borderColor = this.productColor.get(dataset.label) ?? "";
      dataset.backgroundColor = this.productColor.get(dataset.label) ?? "";
      dataset.pointBackgroundColor = this.productColor.get(dataset.label) ?? "";
    }
    this.chart?.update();
  }

  protected readonly RankOption = RankOption;
  protected readonly RANK_OPTIONS_ARRAY = RANK_OPTIONS_ARRAY;
  protected readonly RANK_OPTIONS_DESC = RANK_OPTIONS_DESC;
}

export function scrollToRight(el: ElementRef) {
  setTimeout(() => {
    const element = el as ElementRef<HTMLDivElement>;
    if (!element?.nativeElement) return;
    const left = element.nativeElement.scrollWidth;
    if (left != undefined && left > 0) {
      element.nativeElement.scroll({ left: left, behavior: "smooth" });
    }
  });
}
