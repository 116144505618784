import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { KeywordTrackingApi, SearchResultValidation, SearchResultValidationStatusEnum } from '@front/m19-api-client';
import { SearchResultValidationEx } from '@front/m19-models/SearchResultValidationEx';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScraperValidationService {
  private validationIndex: BehaviorSubject<Map<string, SearchResultValidationEx>> = new BehaviorSubject<
    Map<string, SearchResultValidationEx>
  >(new Map());
  private validation: BehaviorSubject<SearchResultValidationEx[]> = new BehaviorSubject<SearchResultValidationEx[]>([]);

  constructor(private keywordTrackingApi: KeywordTrackingApi) {}

  public getValidations(): Observable<Array<SearchResultValidationEx>> {
    return this.keywordTrackingApi.listAllScraperValidations().pipe(
      map((data) => {
        const tmp: Map<string, SearchResultValidationEx> = new Map();
        const res = data.map((x) => {
          const r = new SearchResultValidationEx(x);
          tmp.set(r.id as string, r);
          this.validationIndex.next(tmp);
          return r;
        });
        this.validation.next(res);
        return res;
      }),
    );
  }

  public getById(id: string): Observable<SearchResultValidationEx> {
    if (this.validationIndex.getValue().size == 0) {
      return this.getValidations().pipe(map((x) => x.find((v) => v.id == id)!));
    }
    return of(this.validationIndex.getValue().get(id)!);
  }

  public nextPending(id: string): string {
    const data = this.validation.getValue();
    if (data.length == 0) return '';
    const idx = data.findIndex((o) => o.id == id);
    if (idx == -1 || idx >= data.length - 1) return '';
    for (let i = idx + 1; i < data.length; i++) {
      if (data[i].status == SearchResultValidationStatusEnum.PENDING) return data[i].id!;
    }
    return '';
  }

  public updateStatus(
    id: string,
    status: SearchResultValidationStatusEnum,
    message: string,
    onSuccess?: () => void,
    onError?: (error: any) => void,
  ) {
    const validation: SearchResultValidation = {
      id: id,
      status: status,
      message: message,
    };
    this.keywordTrackingApi.updateScraperValidationStatus({ searchResultValidation: validation }).subscribe(
      () => {
        const tmp: Map<string, SearchResultValidationEx> = this.validationIndex.getValue();
        tmp.get(id)!.status = status;
        tmp.get(id)!.message = message;
        this.validationIndex.next(tmp);
        if (onSuccess) {
          onSuccess();
        }
      },
      (error) => {
        if (onError) {
          onError(error);
        }
      },
    );
  }
}
