// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    SOVTimeline,
} from './';

/**
 * @export
 * @interface ShareOfVoiceTimeline
 */
export interface ShareOfVoiceTimeline {
    /**
     * @type {string}
     * @memberof ShareOfVoiceTimeline
     */
    searchTerm?: string;
    /**
     * @type {string}
     * @memberof ShareOfVoiceTimeline
     */
    marketplace?: string;
    /**
     * @type {SOVTimeline}
     * @memberof ShareOfVoiceTimeline
     */
    timeline?: SOVTimeline;
}
