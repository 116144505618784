<div class="modal-header bg-main-500" *transloco="let t">
  <h4 class="modal-title pull-left">{{ t("upload-catalog-data-modal.upload_catalog_data") }}</h4>
  <a>
    <h4 class="modal-title pull-right" (click)="bsModalRef.hide()">&times;</h4>
  </a>
</div>
<app-spinner [display]="loading" [main]="true"></app-spinner>
<div class="m-4" [class.disabled]="loading" *transloco="let t">
  <div class="form-group row">
    <div class="col-sm-12">
      <ISelect
        [options]="uploadModeOptions"
        [selectedValue]="selectedUploadMode().value"
        (selectedOptionChange)="changeUploadMode($event)"
      />
    </div>
    @if (selectedUploadMode().value === UploadMode.UPLOAD_COGS) {
      <div class="mt-2 w-full">
        <textarea
          type="text"
          #cogs
          class="textarea-width form-control coding-font"
          placeholder="asin1,cog1&#10;asin2,cog2&#10;COGS in {{
            currencySymbol
          }} must be entered as number only&#10;e.g. 'B07V1L8JL9,10.99' to set COGS for ASIN B07V1L8JL9 to {{
            10.99 | currency: currencySymbol
          }}"
          (paste)="onPaste($event)"
        ></textarea>
        <div class="flex items-center justify-between">
          <div class="mt-1">
            <input
              #fileInput
              hidden
              type="file"
              accept="text/csv"
              (input)="onCsvUpload(fileInput.files)"
              (click)="fileInput.value = ''"
            />
            <IButton
              [icon]="ICON_UPLOAD"
              tooltipValue="{{ t('common.upload_csv') }}"
              color="white"
              (click)="fileInput.click()"
            />
          </div>
          <div class="danger">{{ fileUploadError }}</div>
          <IButton
            label="{{ t('common.upload') }}"
            tooltipValue="{{ t('upload-catalog-data-modal.uplaod_cost_of_goods') }}"
            [disabled]="isUploadButtonDisabled(cogs.value)"
            (onClick)="addCogs(cogs.value); cogs.value = ''"
          />
        </div>
      </div>
    }
    @if (selectedUploadMode().value === UploadMode.UPLOAD_CF) {
      <div class="mt-2 w-full">
        <textarea
          type="text"
          #cf
          class="textarea-width form-control coding-font"
          placeholder="asin1,field1,field2&#10;asin2,field1,field2"
          (paste)="onPaste($event)"
        ></textarea>
        <div class="flex items-center justify-between">
          <div class="mt-1">
            <input
              #fileInput
              hidden
              type="file"
              accept="text/csv"
              (input)="onCsvUpload(fileInput.files)"
              (click)="fileInput.value = ''"
            />
            <IButton
              [icon]="ICON_UPLOAD"
              tooltipValue="{{ t('common.upload_csv') }}"
              color="white"
              [square]="true"
              (click)="fileInput.click()"
            />
          </div>
          <div class="danger">{{ fileUploadError }}</div>
          <IButton
            label="{{ t('common.upload') }}"
            tooltipValue="{{ t('upload-catalog-data-modal.upload_custom_fields') }}"
            [disabled]="isUploadButtonDisabled(cf.value)"
            (onClick)="addCFs(cf.value); cf.value = ''"
          />
        </div>
      </div>
    }
    @if (selectedUploadMode().value === UploadMode.UPLOAD_SEEDS) {
      <div class="mt-2">
        <div class="alert alert-warning">
          {{ t("upload-catalog-data-modal.warning_upload") }}
        </div>
        <textarea
          type="text"
          #seeds
          class="textarea-width form-control coding-font"
          placeholder="asin1,SEED,targetedProduct,asinToTarget1,asinToTarget2,…&#10;asin1,SEED,exact,keywordToTarget1,keywordToTarget2,…&#10;asin1,BLACKLIST,targetedProduct,asinToBlacklist1,asinToBlacklist2,asinToBlacklist3,…&#10;asin1,BLACKLIST,exact,keywordToBlacklist1,keywordToBlacklist2,…&#10;asin1,BLACKLIST,phrase,keywordToBlacklist1,keywordToBlacklist2,…&#10;asin2,SEED,targetedProduct,asinToTarget1,asinToTarget2,…&#10;…"
          (paste)="onPaste($event)"
        ></textarea>
        <div class="flex items-center justify-between">
          <div class="mt-1">
            <input
              #fileInput
              hidden
              type="file"
              accept="text/csv"
              (input)="onCsvUpload(fileInput.files)"
              (click)="fileInput.value = ''"
            />
            <IButton
              [icon]="ICON_UPLOAD"
              tooltipValue="{{ t('common.upload_csv') }}"
              color="white"
              (click)="fileInput.click()"
            />
          </div>
          <div class="danger">{{ fileUploadError }}</div>
          <IButton
            label="{{ t('common.upload') }}"
            tooltipValue="{{ t('upload-catalog-data-modal.upload_keywords') }}"
            [disabled]="isUploadButtonDisabled(seeds.value)"
            (onClick)="addSeeds(seeds.value); seeds.value = ''"
          />
        </div>
      </div>
    }
  </div>
</div>
