<div class="modal-body">
  @if (asins === undefined) {
    <app-spinner [display]="true"></app-spinner>
  } @else {
    <div class="max-h-[70vh]">
      <app-strategy-asins
        [asins]="asins"
        [withDisplayMode]="true"
        [isReadOnly]="true"
        [csvExportFileName]="brandName"
        [csvExport]="true"
        [accountId]="accountId"
        [marketplace]="marketplace"
        [deletable]="false"
        [movable]="false"
      ></app-strategy-asins>
    </div>
  }
</div>
<div class="modal-footer" *transloco="let t">
  <IButton label="{{ t('common.cancel') }}" color="gray" variant="ghost" (onClick)="close()" />
  <IButton label="{{ t('common.see_in_catalog') }}" (onClick)="openCatalog()" />
</div>
