import { Component, Input } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";

type ImageComponentAgGridParams = {
  imageUrl: string;
  altText: string;
  width: number;
  height: number;
  tooltip: string;
};

@Component({
  selector: "app-image",
  template: ` <img [src]="imageUrl" [alt]="altText" [width]="width" [height]="height" [matTooltip]="tooltip" />`,
  standalone: true,
  imports: [MatTooltipModule],
})
export class ImageComponent implements ICellRendererAngularComp {
  @Input() imageUrl: string;
  @Input() altText: string;
  @Input() width: number;
  @Input() height: number;
  @Input() tooltip: string;

  agInit(params: ICellRendererParams & ImageComponentAgGridParams): void {
    this.imageUrl = params.imageUrl;
    this.altText = params.altText;
    this.width = params.width;
    this.height = params.height;
    this.tooltip = params.tooltip;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
