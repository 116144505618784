<ng-container *transloco="let t">
  <div class="flex h-[40vh] max-h-[70vh] w-[30vw] flex-col">
    @if (atLeastOneImpacted()) {
      <p class="border-b border-gray-200 pb-4 text-sm text-gray-500">
        {{ t("tacos-strategy.impacted_ad_lines") }}
        <br />
        {{ t("tacos-strategy.please_review_changes") }}
      </p>

      <div class="flex w-full flex-1 flex-col gap-4 overflow-auto py-4">
        @for (creative of adLinesResult(); track creative.creativeId) {
          @if (creative.impacted) {
            <div class="text-lg font-medium text-gray-600">Creative #{{ creative.creativeId }}</div>
            @for (adLine of creative.adLines; track adLine.asin1) {
              @if (adLine.impacted) {
                <div>
                  <div class="mb-2 text-gray-600">{{ t("sb-form-asins.ad_line") }} {{ $index + 1 }}</div>
                  <div class="flex items-end gap-2">
                    <div class="flex-1 rounded-lg border border-gray-200 p-3">
                      <div [ngClass]="{ 'bg-red-100 line-through': adLine.asin1.deleted }">{{ adLine.asin1.asin }}</div>
                      <div [ngClass]="{ 'bg-red-100 line-through': adLine.asin2.deleted }">{{ adLine.asin2.asin }}</div>
                      <div [ngClass]="{ 'bg-red-100 line-through': adLine.asin3.deleted }">{{ adLine.asin3.asin }}</div>
                    </div>
                    <span class="icon-[mdi--arrow-right] size-8 text-gray-300"></span>
                    <div class="flex w-full flex-1 flex-col">
                      <div class="flex-1 rounded-lg border border-gray-200 p-3">
                        <div [ngClass]="{ invisible: adLine.asin1.deleted }">
                          {{ adLine.asin1.asin }}
                        </div>
                        <div [ngClass]="{ invisible: adLine.asin2.deleted }">
                          {{ adLine.asin2.asin }}
                        </div>
                        <div [ngClass]="{ invisible: adLine.asin3.deleted }">
                          {{ adLine.asin3.asin }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            }
          }
        }
      </div>
    } @else {
      <app-spinner [display]="true" [main]="false" size="s" />
    }
     

    <div class="flex justify-end gap-2 border-t border-gray-200 pt-4">
      <IButton [label]="t('common.cancel')" variant="ghost" color="gray" (onClick)="onCancel()" />
      <IButton
        [label]="t('common.delete')"
        color="red"
        (onClick)="confirmDeletion()"
        [disabled]="!hasAtLeastOneAdLine()"
        [tooltipValue]="noAdLineTooltip()"
      />
    </div>
  </div>
</ng-container>
