<ISelect
  class="mr-2 inline-block w-32"
  [options]="currencies"
  [selected]="selectedCurrency"
  (selectedChange)="currencyChange($event)"
  [searchable]="true"
  [searchAttributes]="['label', 'value']"
  by="value"
  popperWidth="200px"
  withCategories
>
  <ng-template #labelSlot #optionSlot let-v>
    <span class="truncate">
      <span class="text-gray-500">
        {{ v?.label }}
      </span>
      {{ v?.value }}
    </span>
  </ng-template>
</ISelect>
