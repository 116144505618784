// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    AccountMarketplace,
    Organization,
    OrganizationAmazonUserAccess,
    Response,
} from '../models';

export interface AdminCreateSubscriptionRequest {
    sendFirstInvoice: boolean;
    organization: Organization;
    overrideBillingCycle?: string;
}

export interface SetSubscriptionEndDateRequest {
    organizationId: number;
    endDate: string;
}

export interface UpdateOrganizationRequest {
    organizationId: number;
    poNumber?: string;
}

/**
 * no description
 */
export class OrganizationApi extends BaseAPI {

    /**
     * create subscription
     */
    adminCreateSubscription({ sendFirstInvoice, organization, overrideBillingCycle }: AdminCreateSubscriptionRequest): Observable<Organization>
    adminCreateSubscription({ sendFirstInvoice, organization, overrideBillingCycle }: AdminCreateSubscriptionRequest, opts?: OperationOpts): Observable<AjaxResponse<Organization>>
    adminCreateSubscription({ sendFirstInvoice, organization, overrideBillingCycle }: AdminCreateSubscriptionRequest, opts?: OperationOpts): Observable<Organization | AjaxResponse<Organization>> {
        throwIfNullOrUndefined(sendFirstInvoice, 'sendFirstInvoice', 'adminCreateSubscription');
        throwIfNullOrUndefined(organization, 'organization', 'adminCreateSubscription');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'sendFirstInvoice': sendFirstInvoice,
        };

        if (overrideBillingCycle != null) { query['overrideBillingCycle'] = overrideBillingCycle; }

        return this.request<Organization>({
            url: '/subscription',
            method: 'POST',
            headers,
            query,
            body: organization,
        }, opts?.responseOpts);
    };

    /**
     * List all organization Resources
     */
    listAllOrganizationResources(): Observable<Array<AccountMarketplace>>
    listAllOrganizationResources(opts?: OperationOpts): Observable<AjaxResponse<Array<AccountMarketplace>>>
    listAllOrganizationResources(opts?: OperationOpts): Observable<Array<AccountMarketplace> | AjaxResponse<Array<AccountMarketplace>>> {
        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<AccountMarketplace>>({
            url: '/allOrganizationResources',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * List all organizations
     */
    listAllOrganizations(): Observable<Array<Organization>>
    listAllOrganizations(opts?: OperationOpts): Observable<AjaxResponse<Array<Organization>>>
    listAllOrganizations(opts?: OperationOpts): Observable<Array<Organization> | AjaxResponse<Array<Organization>>> {
        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<Organization>>({
            url: '/allOrganizations',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * List amazon email and profiles available from user token
     */
    organizationAmazonUserAccess(): Observable<Array<OrganizationAmazonUserAccess>>
    organizationAmazonUserAccess(opts?: OperationOpts): Observable<AjaxResponse<Array<OrganizationAmazonUserAccess>>>
    organizationAmazonUserAccess(opts?: OperationOpts): Observable<Array<OrganizationAmazonUserAccess> | AjaxResponse<Array<OrganizationAmazonUserAccess>>> {
        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<OrganizationAmazonUserAccess>>({
            url: '/organizationAmazonUserAccess',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * List organization Resources from user token
     */
    organizationResources(): Observable<Array<AccountMarketplace>>
    organizationResources(opts?: OperationOpts): Observable<AjaxResponse<Array<AccountMarketplace>>>
    organizationResources(opts?: OperationOpts): Observable<Array<AccountMarketplace> | AjaxResponse<Array<AccountMarketplace>>> {
        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<AccountMarketplace>>({
            url: '/organizationResources',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * List organizations from user token
     */
    organizations(): Observable<Array<Organization>>
    organizations(opts?: OperationOpts): Observable<AjaxResponse<Array<Organization>>>
    organizations(opts?: OperationOpts): Observable<Array<Organization> | AjaxResponse<Array<Organization>>> {
        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<Organization>>({
            url: '/organization',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * set subscription end date
     */
    setSubscriptionEndDate({ organizationId, endDate }: SetSubscriptionEndDateRequest): Observable<Response>
    setSubscriptionEndDate({ organizationId, endDate }: SetSubscriptionEndDateRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    setSubscriptionEndDate({ organizationId, endDate }: SetSubscriptionEndDateRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'setSubscriptionEndDate');
        throwIfNullOrUndefined(endDate, 'endDate', 'setSubscriptionEndDate');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'organizationId': organizationId,
            'endDate': endDate,
        };

        return this.request<Response>({
            url: '/subscription',
            method: 'PUT',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * update poNumber of a organization by its id
     */
    updateOrganization({ organizationId, poNumber }: UpdateOrganizationRequest): Observable<Response>
    updateOrganization({ organizationId, poNumber }: UpdateOrganizationRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateOrganization({ organizationId, poNumber }: UpdateOrganizationRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'updateOrganization');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (poNumber != null) { query['poNumber'] = poNumber; }

        return this.request<Response>({
            url: '/organization/{organizationId}'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'PUT',
            headers,
            query,
        }, opts?.responseOpts);
    };

}
