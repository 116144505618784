import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AccountSelectionService, AsinService, SpStrategiesService, StrategyService } from "@front/m19-services";
import { MoveStrategyAsinsModalComponent } from "@m19-board/strategies/strategy-asins/move-strategy-asins-modal.component";
import { StrategyCache } from "libs/m19-services/src/lib/m19-services/strategy.cache";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { combineLatest, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { SpStrategyCreationComponent } from "../sp-creation/sp-strategy-creation/sp-strategy-creation.component";
import { StrategyEx } from "@front/m19-models";
import { CampaignType, Strategy, StrategyAsin } from "@front/m19-api-client";

@UntilDestroy()
@Component({
  selector: "app-sp-page",
  templateUrl: "./sp-page.component.html",
})
export class SpPageComponent implements OnInit {
  /**
   * @deprecated
   */
  strategyLegacy$: Observable<StrategyEx | undefined>;
  strategy$: Observable<Strategy | undefined>;
  strategy: Strategy | undefined;
  strategyLegacy: StrategyEx | undefined;
  asinsVisible = true;
  asinsList: StrategyAsin[] = [];
  ineligibleAsins: string[] = [];
  asinEligibility: Map<string, { status: boolean; reason: string }> = new Map();
  isReadOnly = false;
  ineligibleAsinsCollapsed = true;
  brands: Set<string> = new Set();
  targetingStatsVisible = false;

  readonly faPlus = faPlusCircle;
  readonly CampaignType = CampaignType;

  @ViewChild("productAdd") productAddModal!: TemplateRef<any>;
  addAsinModalRef: BsModalRef | undefined;

  constructor(
    private route: ActivatedRoute,
    private strategyCache: StrategyCache,
    private toasterService: ToastrService,
    private asinService: AsinService,
    private modalService: BsModalService,
    private accountSelectionService: AccountSelectionService,
    private spStrategiesService: SpStrategiesService,
    private strategyService: StrategyService,
  ) {
    this.strategyLegacy$ = this.route.paramMap.pipe(
      switchMap((params) => {
        const id = Number(params.get("id"));
        return this.strategyCache.getStrategyById(id);
      }),
      map((s) => (s && s.campaignType == CampaignType.SP ? s : undefined)),
    );
    this.strategyLegacy$.pipe(untilDestroyed(this)).subscribe((s) => {
      this.strategyLegacy = s;
    });
    this.strategy$ = combineLatest([
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      this.route.paramMap,
    ]).pipe(
      switchMap(([am, params]) => {
        const id = Number(params.get("id"));
        return this.spStrategiesService
          .getSPStrategies(am.accountId, am.marketplace)
          .pipe(map((strategies) => strategies.get(id)));
      }),
    );
    this.strategy$.pipe(untilDestroyed(this)).subscribe((s) => {
      this.strategy = s;
    });
    const catalog$ = this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(
      switchMap((am) => this.asinService.getCatalog(am.accountId, am.marketplace)),
    );

    combineLatest([this.strategy$, catalog$])
      .pipe(untilDestroyed(this))
      .subscribe(([strategy, catalog]) => {
        this.asinsList = [];
        this.brands.clear();

        this.ineligibleAsins = [];
        if (!strategy) {
          return;
        }
        this.asinsList = strategy.asins ?? [];
        if (catalog) {
          this.asinEligibility = catalog.getSPEligibility();
          this.asinsList.forEach((item) => {
            if (!this.asinEligibility.has(item.asin!) || this.asinEligibility.get(item.asin!)?.status == false)
              this.ineligibleAsins.push(item.asin!);
            if (catalog.asinOffers.has(item.asin!)) {
              const brand = catalog.asinOffers.get(item.asin!)?.brand;
              if (brand) this.brands.add(brand);
            }
          });
        }
      });

    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
  }

  ngOnInit() {
    this.ineligibleAsinsCollapsed = true;
  }

  toggleAsinsVisibility() {
    this.asinsVisible = !this.asinsVisible;
  }

  showAddAsinModal() {
    this.addAsinModalRef = this.modalService.show(this.productAddModal, {
      class: "modal-xl modal-primary modal-dialog-centered",
    });
  }

  toggleTargetingStatsVisibility() {
    this.targetingStatsVisible = !this.targetingStatsVisible;
  }

  private prettyPrintAsinList(asins: string[]) {
    const maxAsins = 5;
    let message = asins.slice(0, maxAsins).join(", ");
    if (asins.length > maxAsins) {
      message += "…";
    }
    return message;
  }

  getAsins(strategyAsin: StrategyAsin): string {
    return strategyAsin.asin!;
  }

  deleteStrategyAsins(toDelete: StrategyAsin[]) {
    if (!this.strategy || toDelete.length === 0) {
      return;
    }
    this.strategyService
      .deleteAsinsFromStrategy(
        this.strategy,
        toDelete.map((a) => a.asin!),
      )
      .subscribe({
        next: () => {
          const toDeleteMsg = this.prettyPrintAsinList(toDelete.map((a) => a.asin!));
          this.toasterService.success(
            `ASIN${toDelete.length > 1 ? "s" : ""} ${toDeleteMsg} removed from SP strategy "${this.strategy!.name}"`,
            "Strategy ASIN updated",
          );
        },
        error: (error) => {
          const toDeleteMsg = this.prettyPrintAsinList(toDelete.map((a) => a.asin!));
          this.toasterService.error(
            `Error removing Strategy ASIN${toDelete.length > 1 ? "s" : ""} ${toDeleteMsg}: ${error}`,
            "Strategy ASIN update error",
          );
        },
      });
  }

  moveStrategyAsins(toMove: StrategyAsin[]): void {
    const asinsToMove = toMove.map((sa) => sa.asin!);
    const modalOptions: ModalOptions = {
      initialState: {
        source: this.strategy,
        asinsToMove: asinsToMove,
      },
      class: "modal-primary",
    };
    const modalRef = this.modalService.show(MoveStrategyAsinsModalComponent, modalOptions);
    let creationModalRef: BsModalRef;

    const subscription = modalRef
      .content!.selectedStrategy.pipe(
        switchMap((target) => this.strategyService.moveAsinsToStrategy(asinsToMove, this.strategy!, target)),
      )
      .subscribe({
        next: () => {
          this.toasterService.success("ASINs succesfully moved to other strategy");
          subscription.unsubscribe();
        },
        error: (error: string) => {
          this.toasterService.error(`Error moving ASINs to other strategy: ${error}`, "Error moving ASINs");
          subscription.unsubscribe();
        },
      });
    subscription.add(
      modalRef
        .content!.newStrategyCreation.pipe(
          switchMap(() => {
            const modalOptions: ModalOptions = {
              initialState: {
                asins: toMove,
              },
              class: "modal-xl",
            };
            creationModalRef = this.modalService.show(SpStrategyCreationComponent, modalOptions);
            return creationModalRef.content.strategyCreated;
          }),
          switchMap(() => this.strategyService.deleteAsinsFromStrategy(this.strategy!, asinsToMove)),
        )
        .subscribe({
          next: () => {
            this.toasterService.success("ASINs succesfully moved to new strategy");
            subscription.unsubscribe();
            creationModalRef.hide();
          },
          error: (error: string) => {
            this.toasterService.error(`Error moving ASINs to new strategy: ${error}`, "Error moving ASINs");
            subscription.unsubscribe();
            creationModalRef.hide();
          },
        }),
    );
  }

  addStrategyAsins(toAdd: StrategyAsin[]) {
    if (!this.strategy || toAdd.length === 0) {
      return;
    }
    this.strategyService
      .addAsinsToStrategy(
        this.strategy,
        toAdd.map((a) => a.asin!),
      )
      .subscribe({
        next: () => {
          const toAddMsg = this.prettyPrintAsinList(toAdd.map((a) => a.asin!));
          this.toasterService.success(
            `ASIN${toAdd.length > 1 ? "s" : ""} ${toAddMsg} added to SP strategy "${this.strategy!.name}"`,
            "Strategy ASIN updated",
          );
        },
        error: (error: string) => {
          const toAddMsg = this.prettyPrintAsinList(toAdd.map((a) => a.asin!));
          this.toasterService.error(
            `Error adding Strategy ASIN${toAdd.length > 1 ? "s" : ""} ${toAddMsg}: ${error}`,
            "Strategy ASIN update error",
          );
        },
      });
  }

  toggleIneligibleAsinDetails() {
    this.ineligibleAsinsCollapsed = !this.ineligibleAsinsCollapsed;
  }

  getBrands(): string {
    return Array.from(this.brands).join(" - ");
  }
}
