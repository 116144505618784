import { SharedReport, SharedReportType } from '../api-client';

export class SharedReportTypeEx {
  public readonly displayName: string = '';
  public readonly dir: string = '';
}

type SharedReportTypeDefinitions = {
  [key in SharedReportType]: SharedReportTypeEx;
};

export const SharedReportTypes: SharedReportTypeDefinitions = {
  'keyword-ranking': {
    displayName: 'sharedReport.keyword_ranking',
    dir: 'keyword-ranking',
  },
  'account-status': {
    displayName: 'sharedReport.account_status',
    dir: 'account-status',
  },
  'adgroup-daily-stats': {
    displayName: 'sharedReport.daily_adgroup_stats',
    dir: 'adgroup-daily-stats',
  },
  'asin-daily-stats': {
    displayName: 'sharedReport.daily_asin_stats',
    dir: 'asin-daily-stats',
  },
  'query-daily-stats': {
    displayName: 'sharedReport.daily_query_stats',
    dir: 'query-daily-stats',
  },
};

export function fromSharedReport(sharedReport: SharedReport): SharedReportEx {
  const reportTypeEx = SharedReportTypes[sharedReport.reportType!]!;
  return { ...sharedReport, reportTypeEx };
}

export interface SharedReportEx extends SharedReport {
  readonly reportTypeEx: SharedReportTypeEx;
}
