<ng-container *transloco="let t">
  <div class="container">
    <app-spinner class="placeholder" [display]="loadingStatus"></app-spinner>
    @if (!loadingStatus && noData) {
      <div class="placeholder text-center">{{ t("top-queries-list.no_data") }}</div>
    }
    @if (!loadingStatus && !noData) {
      <table class="mt-4 table">
        @for (stat of data; track stat) {
          <tr class="top-query-row">
            @if (!isProductQueryType(stat)) {
              <td class="text-left">
                <a class="text-color" [href]="stat.query | amazonSearchUrlPipe: marketplace" target="_blank">{{
                  stat.query
                }}</a>
              </td>
            }
            @if (isProductQueryType(stat) && marketplace) {
              <td>
                <app-product-view
                  [withTitle]="false"
                  [asin]="stat.query"
                  [marketplace]="marketplace"
                ></app-product-view>
              </td>
            }
            <td class="text-right">
              {{ metric | metricFormatPipe: stat : locale : currency }}
            </td>
          </tr>
        }
      </table>
    }
  </div>
</ng-container>
