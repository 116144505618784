import { Currency, Marketplace, Order } from '@front/m19-api-client';
import { currencyRate, currencyRateForOrder, marketplaceToCurrencyRate } from './currency.utils';
import { OrderStats } from '@front/m19-models/OrderStats';
import { sumV2 } from './statsUtils';

function sum(a: number | undefined, b: number | undefined) {
  return a ? (b ? a + b : a) : b ? b : 0;
}

export function sumOrderStat(orderStat: OrderStats, other: OrderStats): OrderStats {
  const sumRes: OrderStats = emptyOrderStat(orderStat.asin!, orderStat.date!, orderStat.currency! ?? other.currency);

  if (orderStat.currency && other.currency && orderStat.currency != other.currency) {
    throw "Can't merge orders with different currency " + orderStat.currency + '!=' + other.currency;
  }
  return sumInPlace(sumRes, orderStat, other);
}

export function sumOrderStatInPlace(orderStat: OrderStats, other: OrderStats): OrderStats {
  if (orderStat.currency && other.currency && orderStat.currency != other.currency) {
    throw "Can't merge orders with different currency " + orderStat.currency + '!=' + other.currency;
  }

  return sumInPlace(orderStat, orderStat, other);
}

export function sumOrderStatInPlaceV2(orderStat: OrderStats, other: OrderStats): OrderStats {
  if (orderStat.currency && other.currency && orderStat.currency != other.currency) {
    throw "Can't merge orders with different currency " + orderStat.currency + '!=' + other.currency;
  }

  return sumInPlaceV2(orderStat as Required<OrderStats>, other);
}

function sumInPlace(result: OrderStats, orderStat: OrderStats, other: OrderStats) {
  result.globalSales = sum(orderStat.globalSales, other.globalSales) as number;
  result.tax = sum(orderStat.tax, other.tax) as number;
  result.promotion = sum(orderStat.promotion, other.promotion) as number;
  result.shippingGiftWrap = sum(orderStat.shippingGiftWrap, other.shippingGiftWrap) as number;
  result.fee = sum(orderStat.fee, other.fee) as number;
  result.advertising = sum(orderStat.advertising, other.advertising) as number;

  result.sales = sum(orderStat.sales, other.sales);
  result.refunds = sum(orderStat.refunds, other.refunds);
  result.refunded = sum(orderStat.refunded, other.refunded);
  result.chargebackRefunded = sum(orderStat.chargebackRefunded, other.chargebackRefunded);
  result.a2zGuaranteeRefunded = sum(orderStat.a2zGuaranteeRefunded, other.a2zGuaranteeRefunded);
  result.orderReturnRefunded = sum(orderStat.orderReturnRefunded, other.orderReturnRefunded);
  result.replacementRefunded = sum(orderStat.replacementRefunded, other.replacementRefunded);
  result.sellableReturns = sum(orderStat.sellableReturns, other.sellableReturns);
  result.amazonVine = sum(orderStat.amazonVine, other.amazonVine);

  result.baseTax = sum(orderStat.baseTax, other.baseTax);
  result.shippingTax = sum(orderStat.shippingTax, other.shippingTax);
  result.giftWrapTax = sum(orderStat.giftWrapTax, other.giftWrapTax);
  result.otherTax = sum(orderStat.otherTax, other.otherTax);
  result.taxDiscount = sum(orderStat.taxDiscount, other.taxDiscount);

  result.basePromotion = sum(orderStat.basePromotion, other.basePromotion);
  result.giftWrapPromotion = sum(orderStat.giftWrapPromotion, other.giftWrapPromotion);
  result.shippingPromotion = sum(orderStat.shippingPromotion, other.shippingPromotion);
  result.otherPromotion = sum(orderStat.otherPromotion, other.otherPromotion);

  result.shipping = sum(orderStat.shipping, other.shipping);
  result.shippingHB = sum(orderStat.shippingHB, other.shippingHB);
  result.shippingChargeBack = sum(orderStat.shippingChargeBack, other.shippingChargeBack);
  result.giftWrap = sum(orderStat.giftWrap, other.giftWrap);
  result.giftWrapChargeBack = sum(orderStat.giftWrapChargeBack, other.giftWrapChargeBack);

  result.reimbursement = sum(orderStat.reimbursement, other.reimbursement);
  result.reimbursementClawback = sum(orderStat.reimbursementClawback, other.reimbursementClawback);

  result.referralFee = sum(orderStat.referralFee, other.referralFee);
  result.fbaFee = sum(orderStat.fbaFee, other.fbaFee);
  result.liquidations = sum(orderStat.liquidations, other.liquidations);
  result.giftWrapFee = sum(orderStat.giftWrapFee, other.giftWrapFee);
  result.otherFee = sum(orderStat.otherFee, other.otherFee);
  result.fbaStorageFee = sum(orderStat.fbaStorageFee, other.fbaStorageFee);
  result.feeAdjustment = sum(orderStat.feeAdjustment, other.feeAdjustment);
  result.returnFees = sum(orderStat.returnFees, other.returnFees);

  result.spAdvertising = sum(orderStat.spAdvertising, other.spAdvertising);
  result.sbAdvertising = sum(orderStat.sbAdvertising, other.sbAdvertising);
  result.sdAdvertising = sum(orderStat.sdAdvertising, other.sdAdvertising);

  result.exportCharge = sum(orderStat.exportCharge, other.exportCharge);

  result.quantity = sum(orderStat.quantity, other.quantity);

  result.costOfGoods = sum(orderStat.costOfGoods, other.costOfGoods);

  result.profit = sum(orderStat.profit, other.profit);

  result.currency = orderStat.currency;
  result.marketplace = orderStat.marketplace;

  return result;
}

function sumInPlaceV2(orderStat: Required<OrderStats>, other: OrderStats) {
  orderStat.globalSales = sumV2(orderStat.globalSales, other.globalSales) as number;
  orderStat.tax = sumV2(orderStat.tax, other.tax) as number;
  orderStat.promotion = sumV2(orderStat.promotion, other.promotion) as number;
  orderStat.shippingGiftWrap = sumV2(orderStat.shippingGiftWrap, other.shippingGiftWrap) as number;
  orderStat.fee = sumV2(orderStat.fee, other.fee) as number;
  orderStat.advertising = sumV2(orderStat.advertising, other.advertising) as number;

  orderStat.sales = sumV2(orderStat.sales, other.sales);
  orderStat.refunds = sumV2(orderStat.refunds, other.refunds);
  orderStat.refunded = sumV2(orderStat.refunded, other.refunded);
  orderStat.chargebackRefunded = sumV2(orderStat.chargebackRefunded, other.chargebackRefunded);
  orderStat.a2zGuaranteeRefunded = sumV2(orderStat.a2zGuaranteeRefunded, other.a2zGuaranteeRefunded);
  orderStat.orderReturnRefunded = sumV2(orderStat.orderReturnRefunded, other.orderReturnRefunded);
  orderStat.replacementRefunded = sumV2(orderStat.replacementRefunded, other.replacementRefunded);
  orderStat.sellableReturns = sumV2(orderStat.sellableReturns, other.sellableReturns);
  orderStat.amazonVine = sumV2(orderStat.amazonVine, other.amazonVine);

  orderStat.baseTax = sumV2(orderStat.baseTax, other.baseTax);
  orderStat.shippingTax = sumV2(orderStat.shippingTax, other.shippingTax);
  orderStat.giftWrapTax = sumV2(orderStat.giftWrapTax, other.giftWrapTax);
  orderStat.otherTax = sumV2(orderStat.otherTax, other.otherTax);
  orderStat.taxDiscount = sumV2(orderStat.taxDiscount, other.taxDiscount);

  orderStat.basePromotion = sumV2(orderStat.basePromotion, other.basePromotion);
  orderStat.giftWrapPromotion = sumV2(orderStat.giftWrapPromotion, other.giftWrapPromotion);
  orderStat.shippingPromotion = sumV2(orderStat.shippingPromotion, other.shippingPromotion);
  orderStat.otherPromotion = sumV2(orderStat.otherPromotion, other.otherPromotion);

  orderStat.shipping = sumV2(orderStat.shipping, other.shipping);
  orderStat.shippingHB = sumV2(orderStat.shippingHB, other.shippingHB);
  orderStat.shippingChargeBack = sumV2(orderStat.shippingChargeBack, other.shippingChargeBack);
  orderStat.giftWrap = sumV2(orderStat.giftWrap, other.giftWrap);
  orderStat.giftWrapChargeBack = sumV2(orderStat.giftWrapChargeBack, other.giftWrapChargeBack);

  orderStat.reimbursement = sumV2(orderStat.reimbursement, other.reimbursement);
  orderStat.reimbursementClawback = sumV2(orderStat.reimbursementClawback, other.reimbursementClawback);

  orderStat.referralFee = sumV2(orderStat.referralFee, other.referralFee);
  orderStat.fbaFee = sumV2(orderStat.fbaFee, other.fbaFee);
  orderStat.liquidations = sumV2(orderStat.liquidations, other.liquidations);
  orderStat.giftWrapFee = sumV2(orderStat.giftWrapFee, other.giftWrapFee);
  orderStat.otherFee = sumV2(orderStat.otherFee, other.otherFee);
  orderStat.fbaStorageFee = sumV2(orderStat.fbaStorageFee, other.fbaStorageFee);
  orderStat.feeAdjustment = sumV2(orderStat.feeAdjustment, other.feeAdjustment);
  orderStat.returnFees = sumV2(orderStat.returnFees, other.returnFees);

  orderStat.spAdvertising = sumV2(orderStat.spAdvertising, other.spAdvertising);
  orderStat.sbAdvertising = sumV2(orderStat.sbAdvertising, other.sbAdvertising);
  orderStat.sdAdvertising = sumV2(orderStat.sdAdvertising, other.sdAdvertising);

  orderStat.exportCharge = sumV2(orderStat.exportCharge, other.exportCharge);

  orderStat.quantity = sumV2(orderStat.quantity, other.quantity);

  orderStat.costOfGoods = sumV2(orderStat.costOfGoods, other.costOfGoods);

  orderStat.profit = sumV2(orderStat.profit, other.profit);

  orderStat.currency = orderStat.currency ?? other.currency;
  orderStat.marketplace = orderStat.marketplace ?? other.marketplace;

  return orderStat;
}

export function currencyRateOrderStat(orderStat: OrderStats, currency: Currency) {
  const rate = currencyRate(orderStat, currency);
  orderStat.currency = currency;

  orderStat.globalSales *= rate;
  orderStat.tax *= rate;
  orderStat.promotion *= rate;
  orderStat.shippingGiftWrap *= rate;
  orderStat.fee *= rate;
  orderStat.advertising *= rate;

  orderStat.sales! *= rate;
  orderStat.refunded! *= rate;

  orderStat.baseTax! *= rate;
  orderStat.shippingTax! *= rate;
  orderStat.giftWrapTax! *= rate;
  orderStat.otherTax! *= rate;
  orderStat.taxDiscount! *= rate;

  orderStat.basePromotion! *= rate;
  orderStat.giftWrapPromotion! *= rate;
  orderStat.shippingPromotion! *= rate;
  orderStat.otherPromotion! *= rate;

  orderStat.shipping! *= rate;
  orderStat.shippingHB! *= rate;
  orderStat.shippingChargeBack! *= rate;
  orderStat.giftWrap! *= rate;
  orderStat.giftWrapChargeBack! *= rate;

  orderStat.referralFee! *= rate;
  orderStat.fbaFee! *= rate;
  orderStat.giftWrapFee! *= rate;
  orderStat.otherFee! *= rate;
  orderStat.feeAdjustment! *= rate;
  orderStat.liquidations! *= rate;
  orderStat.returnFees! *= rate;

  orderStat.reimbursement! *= rate;
  orderStat.reimbursementClawback! *= rate;

  orderStat.spAdvertising! *= rate;
  orderStat.sbAdvertising! *= rate;
  orderStat.sdAdvertising! *= rate;

  orderStat.exportCharge! *= rate;

  orderStat.costOfGoods! *= rate;

  orderStat.profit! *= rate;
}

export function orderToOrderStatsInMarketplace(
  order: Order,
  selectedCurrency: Currency,
  cog: number,
  marketplace: Marketplace,
) {
  const currencyRate_ = marketplaceToCurrencyRate(marketplace, selectedCurrency);
  return getOrderFromOrderStats(order, selectedCurrency, cog, currencyRate_);
}

export function orderToOrderStats(order: Order, selectedCurrency: Currency, cog: number) {
  const currencyRate_ = currencyRateForOrder(order, selectedCurrency);
  return getOrderFromOrderStats(order, selectedCurrency, cog, currencyRate_);
}

function getOrderFromOrderStats(
  order: Order,
  selectedCurrency: Currency,
  cog: number,
  currencyRate: number,
): OrderStats {
  // Sales
  const sales = order.sales! * currencyRate || 0;
  const chargebackRefunded = order.chargebackRefunded || 0;
  const a2zGuaranteeRefunded = order.a2zGuaranteeRefunded || 0;
  const orderReturnRefunded = order.orderReturnRefunded || 0;
  const refunds = chargebackRefunded + a2zGuaranteeRefunded + orderReturnRefunded;
  const refundedSales = order.refundSales! * currencyRate || 0;

  const sellableReturns = order.sellableReturns || 0;
  const replacementRefunded = order.replacementRefunded || 0;
  const amazonVine = order.amazonVine || 0;

  const reimbursement = order.reimbursement! * currencyRate || 0;

  // Tax
  const baseTax = order.tax! * currencyRate || 0;
  const taxShipping = order.taxShipping! * currencyRate || 0;
  const taxGiftWrap = order.taxGiftWrap! * currencyRate || 0;
  const taxOther = order.otherTax! * currencyRate || 0;

  // Promotion
  const basePromotion = order.promotion! * currencyRate || 0;
  const giftWrapPromotion = order.promotionGiftWrap! * currencyRate || 0;
  const shippingPromotion = order.promotionShipping! * currencyRate || 0;
  const taxDiscount = order.taxDiscount! * currencyRate || 0;
  const otherPromotion = order.otherPromotion! * currencyRate || 0;

  // Shipping & gift wrap
  const shipping = order.shipping! * currencyRate || 0;
  const shippingHB = order.shippingHB! * currencyRate || 0;
  const shippingChargeBack = order.shippingChargeBack! * currencyRate || 0;
  const giftWrap = order.giftWrap! * currencyRate || 0;
  const giftWrapChargeBack = order.giftWrapChargeBack! * currencyRate || 0;

  // Fee
  let commission = order.commission! * currencyRate || 0;
  // quick fix for positive refundCommission
  // the code has been fixed in dailyOrderGenerator
  // we just need to backfill the data
  let refundCommission = order.refundCommission! * currencyRate || 0;
  if (refundCommission > 0) refundCommission *= -1;
  // TODO
  // merge refundCommission and commission should be done in dailyOrderGenerator
  commission += refundCommission;

  const fbaFee = order.fBAPerUnitFulfillmentFee! * currencyRate || 0;
  const giftWrapCommission = order.giftWrapCommission! * currencyRate || 0;

  const otherFee = order.otherItemFees! * currencyRate || 0;
  const returnFees = order.returnFees! * currencyRate || 0;
  const feeAdjustment = order.feeAdjustment! * currencyRate || 0;

  // Charge
  const exportCharge = 0;

  //Global stats
  const globalSales = sales + refundedSales + reimbursement; // refunded sales is negative

  const tax = baseTax + taxShipping + taxGiftWrap + taxOther + taxDiscount;

  const promotion = basePromotion + giftWrapPromotion + shippingPromotion + otherPromotion;

  let shippingGiftWrap = shipping + shippingHB + shippingChargeBack + giftWrap + giftWrapChargeBack;
  if (order.shippingAndGiftWrap) {
    shippingGiftWrap = order.shippingAndGiftWrap * currencyRate || 0;
  }

  const fee = commission + fbaFee + giftWrapCommission + otherFee + returnFees + feeAdjustment + exportCharge;

  const costOfGoods = -cog * (order.quantity! + replacementRefunded + refunds - sellableReturns) || 0;

  const profit = globalSales + promotion + shippingGiftWrap + fee + costOfGoods;

  return {
    // Global stats
    globalSales: globalSales,
    tax: tax,
    promotion: promotion,
    shippingGiftWrap: shippingGiftWrap,
    fee: fee,
    advertising: 0,

    // Sales
    sales: sales,
    refunds: refunds,
    reimbursement: reimbursement,
    refunded: refundedSales,
    chargebackRefunded: chargebackRefunded,
    a2zGuaranteeRefunded: a2zGuaranteeRefunded,
    orderReturnRefunded: orderReturnRefunded,
    replacementRefunded: replacementRefunded,
    sellableReturns: sellableReturns,
    amazonVine: amazonVine,

    // Tax
    baseTax: baseTax,
    shippingTax: taxShipping,
    giftWrapTax: taxGiftWrap,
    otherTax: taxOther,
    taxDiscount: taxDiscount,

    // Promotion
    basePromotion: basePromotion,
    giftWrapPromotion: giftWrapPromotion,
    shippingPromotion: shippingPromotion,
    otherPromotion: otherPromotion,

    // Shipping & gift wrap
    shipping: shipping,
    shippingHB: shippingHB,
    shippingChargeBack: shippingChargeBack,
    giftWrap: giftWrap,
    giftWrapChargeBack: giftWrapChargeBack,

    // Fee
    referralFee: commission,
    fbaFee: fbaFee,
    giftWrapFee: giftWrapCommission,
    otherFee: otherFee,
    fbaStorageFee: 0,
    returnFees: returnFees,
    feeAdjustment: feeAdjustment,
    exportCharge: exportCharge,

    // Advertising
    spAdvertising: 0,
    sbAdvertising: 0,
    sdAdvertising: 0,

    // Quantity
    quantity: order.quantity,

    // COG
    costOfGoods: costOfGoods,
    profit: profit,

    currency: selectedCurrency,
    sku: order.sku || '',
    asin: order.asin || '',
    marketplace: order.marketplace,
    date: order.day ?? '',
  };
}

export function emptyOrderStat(asin: string, date: string, currency: Currency): OrderStats {
  return {
    globalSales: 0,
    tax: 0,
    promotion: 0,
    shippingGiftWrap: 0,
    fee: 0,
    advertising: 0,
    asin: asin,
    date: date,
    costOfGoods: 0,
    profit: 0,
    currency: currency,
    sales: 0,

    refunds: 0,
    reimbursement: 0,
    reimbursementClawback: 0,

    quantity: 0,
    refunded: 0,
    chargebackRefunded: 0,
    a2zGuaranteeRefunded: 0,
    orderReturnRefunded: 0,
    replacementRefunded: 0,
    sellableReturns: 0,
    amazonVine: 0,

    baseTax: 0,
    shippingTax: 0,
    giftWrapTax: 0,
    otherTax: 0,
    taxDiscount: 0,

    basePromotion: 0,
    giftWrapPromotion: 0,
    shippingPromotion: 0,
    otherPromotion: 0,

    shipping: 0,
    shippingHB: 0,
    shippingChargeBack: 0,
    giftWrap: 0,
    giftWrapChargeBack: 0,

    referralFee: 0,
    fbaFee: 0,
    giftWrapFee: 0,
    returnFees: 0,
    feeAdjustment: 0,
    fbaStorageFee: 0,
    exportCharge: 0,

    longTermStorageFee: 0,
    liquidations: 0,
    internationalFreight: 0,

    otherFee: 0,

    spAdvertising: 0,
    sbAdvertising: 0,
    sdAdvertising: 0,
  };
}

export type RequiredOrderStats = Omit<Required<OrderStats>, 'marketplace' | 'sku' | 'hour' | 'currency'>;
