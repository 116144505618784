<div class="flex flex-row-reverse items-center">
  @if (products.length > 2) {
    <div class="flex size-10 max-h-10 items-center justify-center rounded-lg border-2 border-gray-100 bg-gray-50">
      <span class="text-black">+{{ asins.length - 2 }}</span>
    </div>
  }
  @for (product of products.slice(0, 2); track product; let i = $index) {
    @if (product.imageUrl) {
      <div
        class="flex size-10 max-h-10 rounded-lg border-2 border-gray-100"
        [ngClass]="{
          '-mr-3': !$first || products.length > 2,
        }"
      >
        <img class="h-auto w-full rounded-lg object-contain" [src]="product.imageUrl" [alt]="product.asin" />
      </div>
    }
  }
</div>
