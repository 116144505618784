import { Component, inject, Input, Type, ViewContainerRef } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Observable, of } from "rxjs";
import { Metric } from "@front/m19-metrics";
import { TranslocoService } from "@jsverse/transloco";
import { DataSource } from "@m19-board/widget/widget.service";

export type MetricColumn<T> = { metric: Metric<T>; hide: boolean };

@Component({
  selector: "base-widget",
  standalone: true,
  imports: [CommonModule],
  template: "",
})
export abstract class BaseWidgetComponent<T, P extends BaseWidgetParams<any, any> = BaseWidgetParams<any, any>> {
  protected readonly translocoService = inject(TranslocoService);

  @Input()
  id?: string;

  @Input() data$?: Observable<T[] | Map<string, T> | T | undefined>;

  @Input() previousData$?: Observable<T[] | Map<string, T> | T | undefined>;

  @Input()
  abstract metrics: (Metric<T> | MetricColumn<T>)[];

  @Input()
  title$!: Observable<string | undefined>;

  @Input()
  showTitle = true;

  @Input()
  expandedOptions = false;

  static build<TComponent extends BaseWidgetComponent<any>>(
    viewContainerRef: ViewContainerRef,
    component: Type<TComponent>,
  ): TComponent {
    return viewContainerRef.createComponent(component).instance;
  }

  initialize(config: P) {
    this.metrics = config.metrics ?? [];
    this.data$ = config.datasource?.dataSource ?? of(undefined);
    this.previousData$ = config.datasource?.previousDataSource ?? of(undefined);
    this.title$ = config.title$ ?? of(undefined);
    this.id = config.id ?? undefined;
    this.showTitle = config.showTitle ?? true;
    this.expandedOptions = config.expandedOptions ?? false;
    return this;
  }
}

export interface BaseWidgetParams<TData, TPrevData> {
  metrics?: (Metric<any> | MetricColumn<any>)[];
  datasource?: DataSource<TData, TPrevData>;
  title$?: Observable<string>;
  id?: string;
  showTitle?: boolean;
  expandedOptions?: boolean;
}
