<ng-container *transloco="let t">
  <div class="mx-auto h-[80vh] w-[1230px] overflow-hidden rounded-3xl border border-slate-100">
    <div class="flex h-full w-full">
      <div class="flex h-full flex-1 flex-col gap-4 px-20 pt-10">
        <div>
          <div class="mb-10 flex w-full items-center justify-center">
            <div class="flex h-[60px] items-center justify-center rounded-sm border border-slate-100 px-1">
              <logo></logo>
            </div>
            <img src="assets/img/onboarding/amz_link.svg" alt="" />
          </div>
          @if (errorMessage()) {
            <IAlert [title]="errorMessage()!" type="error" />
          }
          <div>
            @if (fromOnboarding()) {
              <span class="text-xs text-gray-400">1/3</span>
            }
            <div class="text-xl font-semibold text-gray-700">{{ t("link-your-account-button.link_account") }}</div>

            @if (!selectAccountsStep()) {
              <div class="mt-2 text-sm text-gray-500">
                {{ t("link-your-account-button.to_get_started", [agency]) }}
              </div>
            } @else {
              <div class="mt-2 text-sm text-gray-500">
                {{ t("link-your-account-button.select_accounts", [agency]) }}
              </div>
            }
          </div>
        </div>

        @if (!selectAccountsStep()) {
          <div>
            <IButton
              [label]="t('onboarding.login_with_amazon')"
              color="white"
              icon="icon-[mdi--amazon]"
              (onClick)="loginWithAmazon()"
              [loading]="loading()"
            />

            <div class="mt-2 text-xs text-gray-500">
              {{ t("link-your-account-button.closed_popup_window") }}
              <span class="cursor-pointer underline" (click)="loginWithAmazon()">{{
                t("link-your-account-button.click_here_to_reopen")
              }}</span>
            </div>
          </div>
        } @else {
          <div class="flex min-h-0 flex-1 flex-col py-3">
            <div class="min-h-0 flex-1">
              <select-accounts-page
                [accountMarketplaces]="accountMarketplaces()!"
                [orgId]="orgId()"
                [loading]="loading()"
                (onCancel)="onCancel()"
                (selectionChange)="selectionChange($event)"
              />
            </div>
            <div class="flex justify-between">
              <IButton [label]="t('common.cancel')" color="white" (onClick)="onCancel()" [disabled]="loading()" />
              <IButton
                [label]="t('common.continue')"
                [disabled]="!selectedAccountMarketplaces()?.length"
                (onClick)="createAccounts()"
                [loading]="loading()"
              />
            </div>
          </div>
        }
      </div>

      @if (!selectAccountsStep()) {
        <div class="relative flex h-full flex-1 items-center justify-center bg-slate-50">
          <div
            class="absolute bottom-0 left-0 z-10 h-[200px] w-full bg-[linear-gradient(rgba(244,_245,_246,_0)_0%,_rgb(244,_245,_246)_100%)]"
          ></div>
          <div class="translate-x-[15%] translate-y-[26%] scale-125">
            <img src="assets/img/onboarding/board_illustration.svg" alt="" />
          </div>
        </div>
      }
    </div>
  </div>
</ng-container>
