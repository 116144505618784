<ng-container *transloco="let t">
  <div
    (click)="$event.stopPropagation(); openInfo()"
    [ngClass]="strategyList.length > 0 ? 'positive' : 'zero'"
    data-toggle="tooltip"
    data-placement="bottom"
    class="number pointer mx-auto"
    matTooltip="{{ t('inventory-table.number_activated_strategies', { count: strategyList.length }) }}"
  >
    {{ strategyList.length }}
  </div>
</ng-container>
