import { CatalogBrand } from '../api-client';
import { SegmentEx } from './SegmentEx';

export type CatalogBrandEx = CatalogBrand & {
  parentBrandAlias?: string;
};

export type BrandingFilter = {
  name: string;
  asins: string[];
  productSegment: SegmentEx;
  keywordSegment: SegmentEx;
};
