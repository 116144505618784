<div class="custom-control custom-switch custom-switch-md flex flex-col justify-center" *transloco="let t">
  <input
    [attr.data-testid]="testid"
    type="checkbox"
    class="custom-control-input"
    [checked]="checked"
    [disabled]="disabled"
    (input)="changeStatus($event)"
    [id]="switchId"
  />
  <label
    class="custom-control-label"
    [for]="switchId"
    [attr.data-checked]="onText"
    [attr.data-unchecked]="offText"
  ></label>
</div>
